import { DOCUMENT_TYPE } from 'state/collectionList/collectionList.types'

const readFile = (file: File) =>
  new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader()

    // Register event listeners
    reader.addEventListener('loadend', (e: any) => resolve(e.target.result))
    reader.addEventListener('error', reject)

    // Read file
    reader.readAsArrayBuffer(file)
  })

export const getAsByteArray = async (file: File) => {
  // @ts-ignore
  return new Uint8Array(await readFile(file))
}

export const getDocumentTypeFromExtension = (ext: string) => {
  const extension = ext.toLocaleLowerCase()
  if (extension === 'xlsx' || extension === 'xls') {
    return DOCUMENT_TYPE.EXCEL
  } else if (extension === 'csv') {
    return DOCUMENT_TYPE.CSV
  }
  return DOCUMENT_TYPE.UNKNOWN_FILE_TYPE
}
