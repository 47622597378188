import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { CaretRightIcon } from '@okcredit/web-lib'
import { IAppState } from 'infra/AppState'
import { THEME } from 'components/theme'
import { switchBusinessAccountAction } from 'state/dashboard/dashboard.actions'
import { BusinessListStyles } from './styles'

const BusinessList = () => {
  const { businessAccounts, merchantProfile } = useSelector((app: IAppState) => app.Dashboard)
  const dispatch = useDispatch()
  const classes = BusinessListStyles()
  const currentBusinessId = merchantProfile?.data?.id

  const handleChangeBusinessId = (newBusinessId: string) => {
    if (newBusinessId === currentBusinessId) {
      return
    }
    dispatch(switchBusinessAccountAction(newBusinessId))
  }

  return (
    <div className={classes.selectBusinessContainer}>
      <div className={classNames(classes.businessItem, classes.bgGrey)}>Other Businesses</div>
      {businessAccounts?.businessIds
        .filter((businessId) =>
          Boolean(
            businessAccounts?.businessDetails?.[businessId] && businessId !== currentBusinessId
          )
        )
        .map((businessId) => {
          const user = businessAccounts?.businessDetails?.[businessId]
          return (
            <div
              key={businessId}
              className={classes.businessItem}
              onClick={() => handleChangeBusinessId(businessId)}
            >
              {user?.name || ''}
              <CaretRightIcon fill={THEME.brandGreen} size={24} />
            </div>
          )
        })}
    </div>
  )
}

export default BusinessList
