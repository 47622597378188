import * as Effects from 'redux-saga/effects'
import {
  COLLECTION_LIST_TYPE,
  IReplacements,
  CL_VERSIONS
} from 'state/collectionList/collectionList.types'
import { isInvoice } from 'state/collectionList/helper'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import {
  constructOrderBillRequestPayload,
  getFormattedItemDetails,
  getIsIGST
} from 'state/new-summary/orders/orders.formatter'
import { Navigation } from 'services/navigation'
import { SettlementPayload } from 'state/new-summary/orders/orders.types'
import { convertRupeeToPaisa, convertPaisaToRupee } from 'state/billing/billing.helpers'
import { IOrderBillRequestPayload } from 'services/Api/StaffLinkApiFolder/type'
import { convertDayToEpocCurrent } from 'utils/dateTransformer'
import { getToday } from 'utils/time'
import { getStaffFormattedItemsPayload } from 'experiments/StaffLink/StaffLinkOrder.helper'
import { API_RESPONSE_TYPE, IApiResponse } from '../../constants'
import { getTransactionTypeLabel } from './components/CollectionSummaryDrawer/CollectionSummaryDrawer.helpers'
import {
  Action,
  IRootState,
  ITransactionsAction,
  IUpdateCashCollectionAction,
  ISetLinkIdAndMerchantIdAction,
  ITransactions,
  INewCashCollectionApi,
  IDeleteTransectionAction,
  ICancelInvoiceAction,
  IUndoCancelInvoiceAction,
  INewReplacementRecordAction,
  IUpdateReplacementRecordAction,
  IUpdateMultipleTxnAction,
  UPDATE_MULTIPLE_TXN_ACTION_TYPE,
  CreateOrderPayloadType,
  OrderStatus,
  IStaffLinkState
} from './StaffLink.types'
import {
  fetchStaffLinkDetails,
  fetchPaymentStatus,
  fetchMerchatCustomerUsingPhoneNo,
  registerAutoDebitLinkReq,
  scheduleAutoDebitLinkReq,
  fetchMerchantProfileReq,
  updateCashCollectionReq,
  fetchCollectionSummary,
  fetchCollectionSummaryV2,
  createNewTransactionReq,
  deleteTransectionReq,
  fetchBillPaymentStatus,
  cancelInvoiceReq,
  undoCancelInvoiceReq,
  createReplacementRecordReq,
  updateReplacementRecordReq,
  fetchStaffDetails,
  updateStaffDetails,
  fetchStaffLinkBillDetails,
  fetchStaffLinkShortDetail,
  createNewMultipleTransactionReq,
  updateMultipleTxnReq,
  setStaffName,
  fetchBusinessAccounts,
  fetchCatalogItems,
  createNewOrder,
  getStaffOrdersApi,
  updateStaffOrderApi,
  staffCreateProformaSettlementApi,
  createNewInvoiceApi,
  getStaffBusinessUser,
  createNewMultipleTransactionV2Req
} from './StaffLink.apis'
import {
  getDateAfterNDays,
  precisionRound,
  getLocalStorageInstance,
  getAuthLoginUrl,
  formatStaffLinkInvoices,
  getStaffNumberFromLocal,
  handleAuthLogout,
  getFormattedOrderHistoryItems,
  getStaffFormattedBusinessDetails
} from './StaffLink.helpers'
import { AuthLocalStoragerEnum } from './StaffLink.constants'
import {
  setStaffLinkDetailsState,
  setPageErrorState,
  setPaymentStatus,
  getMerchatCustomerUsingPhoneNoSuccess,
  getMerchatCustomerUsingPhoneNoError,
  setLinkIdandMerchantId,
  setNewAutoDebitLinkToUsingAccountId,
  setMerchantFeatures,
  setTransactionError,
  setLoadingState,
  setTransaction,
  handleCollectionSummarySuccess,
  updateTransaction,
  deleteTransaction,
  cancelInvoice,
  undoCancelInvoice,
  setReplacementRecord,
  updateReplacementRecord,
  setStaffDetails,
  updateStaffDetailsSuccessAction,
  updateStaffDetailsFailureAction,
  setStaffDetailsDrawerAction,
  setMultipleTransactions,
  updateMultipleTransactions,
  setIsStaffBlockedToAccessList,
  setBusinessAccountsAction,
  setCatalogItemsAction,
  fetchBusinessAccountsAction,
  setCartItemsAction,
  saveOrderHistoryDetails,
  saveBusinessDetailsAction,
  saveSelectedBill,
  fetchStaffBusinessUserAction,
  staffPlacedOrderSuccessAction
} from './StaffLink.actions'
import { checkIfCompleted } from './helperFunc'

const put: any = Effects.put
const call: any = Effects.call
const select: any = Effects.select
const all: any = Effects.all

// @ts-ignore
export function* handleFetchStaffLinkDataEffect(action: Action<ISetLinkIdAndMerchantIdAction>) {
  try {
    const [merchantProfile, staffShortDetail] = yield all([
      call(fetchMerchantProfileReq, action?.payload?.merchantId),
      call(fetchStaffLinkShortDetail, action?.payload?.merchantId, action?.payload?.linkId)
    ])
    const clName = staffShortDetail?.data?.collection_list?.name

    if (
      clName &&
      action?.payload?.isNewUser &&
      merchantProfile?.data?.profile?.features?.cash_deposit
    ) {
      yield call(setStaffName, clName)
    }

    if (merchantProfile?.data?.profile?.features?.restrict_stafflink) {
      const staffPhoneNumber = getStaffNumberFromLocal()
      if (!staffPhoneNumber || staffPhoneNumber?.length !== 10) {
        handleAuthLogout()
      }
      if (!staffShortDetail?.data?.collection_list?.name.includes(staffPhoneNumber)) {
        yield put(setIsStaffBlockedToAccessList(true))
        return null
      }
    }

    const clVersion =
      action.payload.version === 0 ? 0 : action.payload.version || CL_VERSIONS.CURRENT_ASSIGNMENT
    const isListOfInvoices =
      merchantProfile?.data?.profile?.features?.collection_list_of_invoices_v1 &&
      (staffShortDetail?.data?.collection_list?.due_config?.config?.toString() ===
        COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2 ||
        staffShortDetail?.data?.collection_list?.due_config?.config?.toString() ===
          COLLECTION_LIST_TYPE.AUTO_TAG_LIST)
    const staffLinkDetailsFetcher = isListOfInvoices
      ? fetchStaffLinkBillDetails
      : fetchStaffLinkDetails
    const [staffLinkData, staffDetails] = yield all([
      call(
        staffLinkDetailsFetcher,
        action?.payload?.merchantId,
        action?.payload?.linkId,
        clVersion
      ),
      call(fetchStaffDetails, action?.payload?.merchantId)
    ])
    if (
      staffLinkData?.data &&
      staffLinkData?.data?.response &&
      staffLinkData?.data?.response?.status === 401
    ) {
      const localStorage = getLocalStorageInstance()
      if (!localStorage?.clearItems) {
        return null
      }
      localStorage.clearItems([
        AuthLocalStoragerEnum.staffLinkExpiresIn,
        AuthLocalStoragerEnum.staffLinkAccessToken,
        AuthLocalStoragerEnum.staffLinkRefreshToken
      ])
      window.location.href = getAuthLoginUrl({
        businessId: action?.payload?.merchantId,
        staffLinkId: action?.payload?.linkId
      })
      return null
    }
    yield put(setMerchantFeatures(merchantProfile?.data?.profile?.features))
    yield put(
      setLinkIdandMerchantId({
        merchantId: action?.payload?.merchantId || '',
        linkId: action?.payload?.linkId || ''
      })
    )
    if (
      staffLinkData?.type === API_RESPONSE_TYPE.SUCCESS &&
      staffDetails?.type === API_RESPONSE_TYPE.SUCCESS
    ) {
      const data = isListOfInvoices
        ? formatStaffLinkInvoices(staffLinkData.data)
        : staffLinkData.data
      yield put(setStaffLinkDetailsState({ ...data, currentVersion: clVersion }))
      yield put(setStaffDetails(staffDetails?.data?.staff))
    } else {
      const localStorage = getLocalStorageInstance()
      if (!localStorage?.clearItems) {
        return null
      }
      localStorage.clearItems([
        AuthLocalStoragerEnum.staffLinkExpiresIn,
        AuthLocalStoragerEnum.staffLinkAccessToken,
        AuthLocalStoragerEnum.staffLinkRefreshToken
      ])
      window.location.href = getAuthLoginUrl({
        businessId: action?.payload?.merchantId,
        staffLinkId: action?.payload?.linkId
      })
      yield put(setPageErrorState(staffLinkData.data.message))
    }
    yield put(setLoadingState(false))
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

/*
 *   On GET STATUS button click on QRDrawer
 */
export function* handleFetchPaymentStatus<T>(action: Action<T>) {
  try {
    const res: IApiResponse = yield call(fetchPaymentStatus, action.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      if (res.data.collections.length > 0) {
        yield put(setPaymentStatus('SUCCESS'))
      } else {
        yield put(setPaymentStatus('PROCESSING'))
      }
    } else {
      // yield put(setPageErrorState(res.data.message)) // This will set the failure page so don't use this
      console.error('Payment status fetch failed with: ', res.data.message)
    }
  } catch (error) {
    // yield put(setPageErrorState(error)) // This will set the failure page so don't use this
    console.error('Payment status fetch failed with: ', error)
  }
}

/*
 *   On GET STATUS button click on QRDrawer
 */
export function* getMerchatCustomerUsingPhoneNoEffect<T>(action: Action<T>) {
  try {
    const res: IApiResponse = yield call(fetchMerchatCustomerUsingPhoneNo, action.payload)
    if (res?.data?.account_details?.balance) {
      yield put(getMerchatCustomerUsingPhoneNoSuccess(res?.data?.account_details))
    } else {
      yield put(
        getMerchatCustomerUsingPhoneNoError(
          'No customer found with this number in merchants customer list'
        )
      )
    }
  } catch (error) {
    // yield put(setPageErrorState(error)) // This will set the failure page so don't use this
    console.error('Payment status fetch failed with: ', error)
    yield put(
      getMerchatCustomerUsingPhoneNoError(
        'No customer found with this number in merchants customer list'
      )
    )
  }
}

export function* handleCreateAutoDebitLinkEffect(action: Action<string>) {
  try {
    const accountId = action.payload
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId } = StaffLink
    const accountDetails = StaffLink?.staffLinkDetails?.account_details

    if (!accountDetails?.length) {
      return null
    }
    const specificAccountDetails = accountDetails.filter((item) => item.account_id === accountId)
    if (!specificAccountDetails[0]) {
      return null
    }
    const amount = Math.abs(Number(specificAccountDetails[0].balance) / 100) * 100 // amount paisa
    const threeDayLater = getDateAfterNDays(3)

    const res: IApiResponse = yield call(registerAutoDebitLinkReq, {
      merchantId,
      accountId,
      amount,
      date: threeDayLater
    })
    if (!res?.data?.registration_link) {
      return null
    }
    const autoDebitUrl = res?.data?.registration_link
    const scheduleRes: IApiResponse = yield call(scheduleAutoDebitLinkReq, {
      merchantId,
      accountId,
      amount,
      date: threeDayLater,
      frequency: 'ONCE'
    })
    if (scheduleRes?.data?.error) {
      return null
    }
    yield put(
      setNewAutoDebitLinkToUsingAccountId({
        accountId,
        regLink: autoDebitUrl,
        dueDate: threeDayLater
      })
    )
  } catch (error) {
    console.log(error)
  }
}

export function* handleUpdateTransactionEffect(action: Action<IUpdateCashCollectionAction>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { cashTxnId, amount, accountId, txnType, dueAmount } = action.payload

    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId, selectedStaffBill } = StaffLink

    const formatedAmount = precisionRound(Math.abs(Number(amount)) * 100, 2) // amount paisa

    const res: IApiResponse = yield call(updateCashCollectionReq, {
      cashTxnId,
      amount: formatedAmount,
      merchantId
    })
    if (res?.data?.response?.status >= 400) {
      yield put(
        setTransactionError(
          res?.data?.response?.data?.error
            ? `Try again, ${res?.data?.response?.data?.error}`
            : 'Something went wrong try again later'
        )
      )
      return null
    }
    yield put(setTransactionError(''))
    checkIfCompleted(selectedStaffBill, dueAmount || 0, amount, accountId)
    history.push({
      search: `?cashcollection=false&cashcollectionstatus=${accountId}&cashcollectionamount=${amount}&transactionType=${txnType}`
    })
    if (res?.data?.transaction) {
      yield put(updateTransaction(res?.data?.transaction))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* handleUpdateMultipleTransactionEffect(action: Action<IUpdateMultipleTxnAction>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { accountId, txnType, updateItems, chequeDetails, items, catalogItems, returnItems } =
      action.payload

    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId } = StaffLink

    const apiPayload = updateItems.map((item) => {
      const advance = Number(item?.advanceAmount || 0)
      return {
        id: item.txnId,
        business_id: merchantId,
        ...(item.actionType === UPDATE_MULTIPLE_TXN_ACTION_TYPE.UPDATE_AMOUNT &&
          item.amount && {
            notes: item.notes,
            amount: precisionRound(Math.abs(Number(item.amount)) * 100, 2),
            advance_amount: precisionRound(Math.abs(advance) * 100, 2),
            add_as_advance: advance === 0 ? false : !!advance
          }),
        ...(item.actionType === UPDATE_MULTIPLE_TXN_ACTION_TYPE.REMOVE && {
          is_deleted: true
        }),
        ...(chequeDetails && { cheque_details: chequeDetails }),
        ...(items && {
          items: items.map((itm) => ({
            name: itm.name,
            quantity: Number(itm.quantity)
          }))
        }),
        ...(catalogItems && {
          catalog_items: catalogItems.map((itm) => ({
            ...itm,
            quantity: Number(itm.quantity)
          }))
        }),
        ...(returnItems && {
          return_items: returnItems
        })
      }
    })

    const res: IApiResponse = yield call(updateMultipleTxnReq, apiPayload)
    if (res?.data?.response?.status >= 400) {
      yield put(
        setTransactionError(
          res?.data?.response?.data?.error
            ? `Try again, ${res?.data?.response?.data?.error}`
            : 'Something went wrong try again later'
        )
      )
      return null
    }
    yield put(setTransactionError(''))
    history.push({
      search:
        accountId && txnType
          ? `?cashcollection=false&cashcollectionstatus=${accountId}&transactionType=${txnType}`
          : ''
    })
    if (res?.data?.errors.length) {
      yield put(setTransactionError('Unable to update some transactions'))
      return null
    }
    if (res?.data?.transactions) {
      yield put(updateMultipleTransactions(res?.data?.transactions))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* handleCancelInvoiceEffect(action: Action<ICancelInvoiceAction>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { accountId, billId } = action.payload

    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId, linkId } = StaffLink

    const res: IApiResponse = yield call(cancelInvoiceReq, {
      merchantId,
      linkId,
      accountId,
      billId
    })
    if (res?.data?.response?.status >= 400) {
      if (
        // @ts-ignore
        // eslint-disable-next-line no-alert
        window.alert(res?.data?.response?.data?.error || 'Unable to cancel invoice, please contiue')
      ) {
        window.location.reload()
      } else {
        window.location.reload()
      }
      return null
    }
    history.push({
      search: ''
    })
    if (res?.data?.cancel_bill_request) {
      yield put(cancelInvoice(res?.data?.cancel_bill_request))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* handleUndoCancelInvoiceEffect(action: Action<IUndoCancelInvoiceAction>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId } = StaffLink

    const { billId } = action.payload

    const res: IApiResponse = yield call(undoCancelInvoiceReq, { billId, merchantId })
    if (res?.data?.response?.status >= 400) {
      return null
    }
    if (res?.data?.cancel_bill_request?.request_deleted) {
      yield put(undoCancelInvoice(res?.data?.cancel_bill_request))
    }
    history.push({
      search: ''
    })
  } catch (error) {
    console.log(error)
  }
}

export function* handleDeleteTransactionEffect(action: Action<IDeleteTransectionAction>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { txnId } = action.payload

    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId } = StaffLink

    const res: IApiResponse = yield call(deleteTransectionReq, {
      txnId,
      merchantId
    })
    if (res?.data?.response?.status >= 400) {
      yield put(
        setTransactionError(
          res?.data?.response?.data?.error
            ? `Try again, ${res?.data?.response?.data?.error}`
            : 'Something went wrong try again later'
        )
      )
      return null
    }
    yield put(setTransactionError(''))
    history.push({
      search: ''
    })
    if (res?.data?.transaction) {
      yield put(deleteTransaction(res?.data?.transaction))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* createNewTransactionEffect(action: Action<INewCashCollectionApi>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId, linkId, selectedStaffBill } = StaffLink

    const { accountId, amount, notes, txnType, dueAmount } = action.payload

    const formatedAmount = precisionRound(Math.abs(Number(amount)) * 100, 2) // amount paisa

    const reqPayload: any = {
      associated_account_id: accountId,
      amount: formatedAmount,
      business_id: merchantId,
      collection_list_id: linkId,
      notes,
      txn_type: txnType,
      ...(selectedStaffBill?.bill?.bill_id && {
        is_bill_settled: true,
        settlement_bill_ids: [selectedStaffBill?.bill?.bill_id]
      })
    }

    if (StaffLink.dueConfig && StaffLink.selectedStaffBill && isInvoice(StaffLink.dueConfig)) {
      reqPayload.is_bill_settled = true
      reqPayload.settlement_bill_ids = [StaffLink.selectedStaffBill?.bill?.bill_id]
    }

    const res: IApiResponse = yield call(createNewTransactionReq, reqPayload)
    if (res?.data?.response?.status >= 400) {
      yield put(
        setTransactionError(
          res?.data?.response?.data?.error
            ? `Try again, ${res?.data?.response?.data?.error}`
            : 'Something went wrong try again later'
        )
      )
      return null
    }
    yield put(setTransactionError(''))
    if (res?.data?.transaction) {
      yield put(setTransaction(res?.data?.transaction as ITransactions))
    }
    checkIfCompleted(selectedStaffBill, dueAmount || 0, amount, accountId)
    history.push({
      search: `?cashcollection=false&cashcollectionstatus=${accountId}&cashcollectionamount=${amount}&transactionType=${txnType}`
    })
  } catch (error) {
    console.log(error)
  }
}

export function* createNewMultipleTransactionEffect(action: Action<INewCashCollectionApi[]>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId, linkId, selectedStaffBill } = StaffLink
    const staffSettleMultipleInvoices = StaffLink.merchantFeatures.staff_settle_multiple_invoices
    const txnsToCreate = action.payload

    const payload = txnsToCreate.map(
      ({
        accountId,
        amount,
        notes,
        txnType,
        advanceAmount,
        txnDate,
        chequeDetails,
        items,
        catalogItems,
        paymentAccountId,
        returnItems,
        settlementBillIds
      }) => {
        const formatedAmount = precisionRound(Math.abs(Number(amount)) * 100, 2) // amount paisa
        const advance = Number(advanceAmount || 0)
        const formatedAdvanceAmount = precisionRound(Math.abs(advance) * 100, 2) // amount paisa
        const reqPayload: any = {
          associated_account_id: accountId,
          amount: formatedAmount,
          advance_amount: formatedAdvanceAmount,
          add_as_advance: advance === 0 ? false : !!formatedAdvanceAmount,
          business_id: merchantId,
          collection_list_id: linkId,
          notes,
          ...(chequeDetails && { cheque_details: chequeDetails }),
          txn_type: txnType,
          ...(selectedStaffBill?.bill?.bill_id && {
            is_bill_settled: true,
            settlement_bill_ids: [selectedStaffBill?.bill?.bill_id]
          }),
          ...(txnDate && { transaction_time: Number(txnDate) }),
          ...(items && {
            items: items.map((itm) => ({
              name: itm.name,
              quantity: Number(itm.quantity)
            }))
          }),
          ...(catalogItems && {
            catalog_items: catalogItems.map((itm) => ({
              ...itm,
              quantity: Number(itm.quantity)
            }))
          }),
          ...(paymentAccountId && { payment_account_id: paymentAccountId }),
          ...(returnItems && {
            return_items: returnItems
          }),
          ...(settlementBillIds && {
            // is_bill_settled: true, // check if required
            settlement_bill_ids: settlementBillIds
          })
        }
        // if (StaffLink.dueConfig && StaffLink.selectedStaffBill && isInvoice(StaffLink.dueConfig)) {
        //   reqPayload.is_bill_settled = true
        //   reqPayload.settlement_bill_ids = [StaffLink.selectedStaffBill?.bill?.bill_id]
        // }
        return reqPayload
      }
    )
    const res: IApiResponse = yield call(
      staffSettleMultipleInvoices
        ? createNewMultipleTransactionV2Req
        : createNewMultipleTransactionReq,
      payload
    )
    if (res?.data?.response?.status >= 400) {
      yield put(
        setTransactionError(
          res?.data?.response?.data?.error
            ? `Try again, ${res?.data?.response?.data?.error}`
            : 'Something went wrong try again later'
        )
      )
      return null
    }
    yield put(setTransactionError(''))
    if (res?.data?.errors?.length) {
      yield put(setTransactionError('Unable to update some transactions'))
      return null
    }
    if (res?.data?.transactions) {
      yield put(setMultipleTransactions(res?.data?.transactions as ITransactions[]))
    }
    history.push({
      search: '?cashcollection=false'
    })
  } catch (error) {
    console.log(error)
  }
}

export function* handleCreateReplacementRecordEffect(action: Action<INewReplacementRecordAction>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId, linkId } = StaffLink

    const { accountId, amount, replacementItems, billId, catalogItems } = action.payload

    const formatedAmount = precisionRound(Math.abs(Number(amount)) * 100, 2) // amount paisa

    const res: IApiResponse = yield call(createReplacementRecordReq, {
      accountId: accountId,
      amount: formatedAmount,
      merchantId,
      replacementItems,
      billId,
      staffLinkId: linkId,
      catalogItems
    })
    if (res?.data?.response?.status >= 400) {
      if (
        // @ts-ignore
        // eslint-disable-next-line no-alert
        window.alert(res?.data?.response?.data?.error || 'Unable to cancel invoice, please contiue')
      ) {
        window.location.reload()
      } else {
        window.location.reload()
      }
      return null
    }
    yield put(setTransactionError(''))
    if (res?.data?.replacement) {
      yield put(setReplacementRecord(res?.data?.replacement as IReplacements))
    }
    history.push({
      search: ''
    })
  } catch (error) {
    console.log(error)
  }
}

export function* handleUpdateReplacementRecordEffect(
  action: Action<IUpdateReplacementRecordAction>
) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const {
      accountId,
      amount,
      replacementItems,
      billId,
      merchantId,
      replacementId,
      status,
      catalogItems
    } = action.payload
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { linkId } = StaffLink

    const formatedAmount = precisionRound(Math.abs(Number(amount)) * 100, 2) // amount paisa

    const res: IApiResponse = yield call(updateReplacementRecordReq, {
      accountId,
      amount: formatedAmount,
      merchantId,
      replacementItems,
      catalogItems,
      billId,
      status,
      replacementId,
      staffLinkId: linkId
    })

    if (res?.data?.replacement) {
      yield put(updateReplacementRecord(res?.data?.replacement as IReplacements))
    }
    history.push({
      search: ''
    })
  } catch (error) {
    console.log(error)
  }
}

export function* handleCollectionSummaryEffect(action: Action<ITransactionsAction>) {
  try {
    const history = action.history
    if (!history) {
      return null
    }
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId, linkId, staffLinkDetails } = StaffLink
    const isCurrentVersion = staffLinkDetails.currentVersion === CL_VERSIONS.CURRENT_ASSIGNMENT

    const apiReq = isInvoice(staffLinkDetails?.collection_list?.due_config?.config)
      ? fetchCollectionSummaryV2
      : fetchCollectionSummary

    const res: IApiResponse = yield call(apiReq, {
      merchantId,
      linkId,
      version: staffLinkDetails.currentVersion
    })
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      const newCollections = res.data?.collections?.map((item: any) => ({
        ...item,
        id: item.account_id,
        name: item.buyer_name,
        due: Number(Math.abs(item.account_due) / 100),
        payment: Number(item.transaction_amount / 100),
        balance: Number(Math.abs(item.balance) / 100),
        transactionTime: Number(item.transaction_time),
        transactionType: getTransactionTypeLabel(item.transaction_type)
      }))
      yield put(
        handleCollectionSummarySuccess({
          ...res.data,
          collections: newCollections
        })
      )
    }
  } catch (error) {
    console.log(error)
  }
}

/*
 *   On GET STATUS button click on QRDrawer for Bill/Invoice
 */
export function* handleFetchBillPaymentStatus<T>(action: Action<T>) {
  try {
    const res: IApiResponse = yield call(fetchBillPaymentStatus, action.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      if (
        [
          'PAYMENT_RECEIVED',
          'PRIMARY_PAYOUT_MADE',
          'PRIMARY_PAYOUT_SUCCEEDED',
          'SECONDARY_PAYOUT_MADE',
          'SECONDARY_PAYOUT_SUCCEEDED',
          'PAYOUT_MADE',
          'PAYOUT_SUCCEEDED'
        ].includes(res.data.payment.status)
      ) {
        yield put(setPaymentStatus('SUCCESS'))
      } else {
        yield put(setPaymentStatus('PROCESSING'))
      }
    } else {
      console.error('Payment status fetch failed with: ', res.data.message)
    }
  } catch (error) {
    console.error('Payment status fetch failed with: ', error)
  }
}

export function* updateStaffNameEffect(action: Action<string>) {
  try {
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const { merchantId } = StaffLink
    const res: IApiResponse = yield call(updateStaffDetails, {
      merchantId,
      name: action.payload
    })
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(updateStaffDetailsSuccessAction(res.data?.staff))
      yield put(setStaffDetailsDrawerAction(false))
    } else {
      yield put(updateStaffDetailsFailureAction())
    }
  } catch (error) {
    yield put(updateStaffDetailsFailureAction())
    console.log(error)
  }
}

export function* fetchBusinessAccountsEffect() {
  try {
    const { StaffLink }: IRootState = yield select((state: IRootState) => state)
    const fallbackBusinessId =
      localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId) || ''
    const linkId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkId) || ''
    if (linkId) {
      const response: IApiResponse = yield call(fetchBusinessAccounts, {
        businessId: StaffLink.merchantId || fallbackBusinessId,
        list_id: linkId
      })
      if (response.type === API_RESPONSE_TYPE.SUCCESS) {
        yield put(setBusinessAccountsAction(response.data?.accounts))
      }
    }
  } catch (error) {}
}

export function* fetchCatalogItemsEffect(action: Action<string>) {
  const customerId = action?.payload
  const { StaffLink }: IRootState = yield select((state: IRootState) => state)
  const fallbackBusinessId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId) || ''
  const response: IApiResponse = yield call(
    fetchCatalogItems,
    StaffLink.merchantId || fallbackBusinessId
  )
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Items not Loaded, please refresh', {
      cause: 'customError'
    })
  }

  if (response?.data?.items) {
    const filteredItems = response.data.inv_items.filter(({ item }: any) => !item.deleted_at)
    yield put(
      setCatalogItemsAction(
        getFormattedItemDetails(
          filteredItems,
          [],
          {
            customerId,
            customerAddress: '',
            customerMobile: '',
            customerName: ''
          },
          !!StaffLink.businessSettings?.offer?.apply_multiple
        )
      )
    )
  }
  Navigation().to(`/staff-order/customer/${customerId}`)
}

export function* createNewOrderEffect(
  action: Action<Pick<CreateOrderPayloadType, 'customerId' | 'amount' | 'items'>>
) {
  const { staffOrder, merchantId, businessDetails }: IStaffLinkState = yield select(
    (state: IRootState) => state.StaffLink
  )
  const fallbackBusinessId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId) || ''
  const businessGst = businessDetails?.businessGst || ''
  const addedItems = action.payload.items.map((item) => {
    const itemDetails = staffOrder?.items?.find((_) => _.itemId === item.catalogue_item_id)
    return {
      ...itemDetails,
      item: item.itemName || '',
      itemId: item.catalogue_item_id || '',
      quantity: item.quantity,
      discount: item?.discount.amount as number,
      netRate: item?.itemNetRate || 0,
      rate: item?.rate,
      amountPayable: item?.amount,
      taxAmount: item?.tax?.amount
    }
  })
  const customer = staffOrder?.selectedCustomer || { gst: '', supply_state_code: '' }
  const isIGST = getIsIGST(businessGst || '', customer?.gst, customer?.supply_state_code)
  const requestPayload = {
    business_id: merchantId || fallbackBusinessId,
    account_id: action.payload.customerId,
    inventory_items: getStaffFormattedItemsPayload(
      addedItems || [],
      addedItems as any,
      action.payload.customerId || '',
      isIGST
    )?.map((i) => {
      const findItem = action.payload.items.find((_) => _.catalogue_item_id === i.catalog_item_id)
      return {
        ...i,
        inventory_item_id: findItem?.item_id,
        info: {
          ...i.info
        }
      }
    })
  }
  const response: IApiResponse = yield call(createNewOrder, {
    ...requestPayload,
    amount: requestPayload.inventory_items.reduce((prev, curr) => prev + curr.amount, 0)
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Failed to create order', {
      cause: 'customError'
    })
  }
  yield put(staffPlacedOrderSuccessAction(true))
  const history = action.history
  yield put(setCartItemsAction([]))
  yield Effects.delay(300)
  history?.replace && history?.replace(`/staff-order/orders/${action.payload.customerId}`)
}

export function* getOrderHistoryEffect(action: Action<Pick<CreateOrderPayloadType, 'customerId'>>) {
  const { StaffLink }: IRootState = yield select((state: IRootState) => state)
  const fallbackBusinessId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId) || ''
  const response: IApiResponse = yield call(getStaffOrdersApi, {
    businessId: StaffLink.merchantId || fallbackBusinessId,
    customerId: action.payload.customerId
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Failed to create order', {
      cause: 'customError'
    })
  }
  const localItems = StaffLink.staffOrder?.items
  yield put(
    saveOrderHistoryDetails(
      getFormattedOrderHistoryItems(
        response?.data.orders,
        response?.data.proforma_invoices,
        localItems || []
      )
    )
  )
}

export function* updateStaffOrderEffect(action: Action<any>) {
  const { StaffLink }: IRootState = yield select((state: IRootState) => state)
  const fallbackBusinessId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId) || ''
  const payload = {
    action: 'status',
    order: {
      id: action.payload?.id,
      status: OrderStatus.CANCELLED,
      business_id: StaffLink.merchantId || fallbackBusinessId
    }
  }
  const response: IApiResponse = yield call(updateStaffOrderApi, payload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Failed to create order', {
      cause: 'customError'
    })
  }
  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: 'Order Cancelled Successfully!'
    })
  )
  const history = action.history
  history?.goBack && history?.goBack()
}

export function* setupStaffOrdersEffect() {
  yield put(fetchBusinessAccountsAction())
  yield put(setCartItemsAction([]))
}

export function* staffSettleProformaEffect(
  action: Action<Omit<SettlementPayload, 'settlementType'> & { currentTransactionType: number }>
) {
  const { StaffLink }: IRootState = yield select((state: IRootState) => state)
  const fallbackBusinessId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId) || ''
  const requestPayload = {
    proforma_id: action.payload.proformaId,
    settlement_info: {
      ...(action.payload?.chequeNumber ? { cheque_number: action.payload?.chequeNumber } : {}),
      ...(action.payload?.chequeDate ? { cheque_date: action.payload?.chequeDate } : {})
    },
    settlement_type: action.payload.currentTransactionType,
    amount: convertRupeeToPaisa(action.payload?.amount),
    settlement_date: action.payload?.settlementDate,
    ...(action.payload?.chequeNumber ? { cheque_number: action.payload?.chequeNumber } : {}),
    business_id: StaffLink?.merchantId || fallbackBusinessId || ''
  }
  const response: IApiResponse = yield call(staffCreateProformaSettlementApi, requestPayload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while Adding the transaction! Try Again.', {
      cause: 'customError'
    })
  }
  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: 'Proforma Settlement Success!'
    })
  )
  const history = action.history
  history?.push({
    search: '?cashcollection=false&isSettled=true'
  })
}

export function* createNewInvoiceEffect(
  action: Action<Pick<CreateOrderPayloadType, 'customerId' | 'amount' | 'items'>>
) {
  const { staffOrder, merchantId, linkId, staffLinkDetails, businessDetails }: IStaffLinkState =
    yield select((state: IRootState) => state.StaffLink)
  const fallbackBusinessId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId) || ''
  const businessGst = businessDetails?.businessGst || ''
  const addedItems = action.payload.items.map((item) => {
    const itemDetails = staffOrder?.items?.find((_) => _.itemId === item.catalogue_item_id)
    return {
      ...itemDetails,
      item: item.itemName || '',
      itemId: item.catalogue_item_id || '',
      quantity: item.quantity,
      discount: item?.discount.amount as number,
      netRate: item?.itemNetRate || 0,
      rate: item?.rate,
      taxAmount: item?.tax?.amount
    }
  })
  const customer = staffOrder?.selectedCustomer
  const data = {
    customerId: action.payload?.customerId || '',
    customerDetails: {
      customerName: customer?.name || '',
      customerAddress: '',
      customerMobile: customer?.mobile || '',
      customerGst: customer?.gst || '',
      customerStateSupplyCode: customer?.supply_state_code || ''
    },
    invoiceDetails: {
      invoiceNumber: `${Math.floor(1000 + Math.random() * 9000)}`,
      invoiceDate: convertDayToEpocCurrent(getToday() || 0),
      invoicePrefix: 'INV'
    },
    discountAmount: 0,
    payableAmount: convertPaisaToRupee(action.payload?.amount),
    discounts: [],
    charges: [],
    itemDetails: addedItems || []
  }
  const requestPayload: IOrderBillRequestPayload = constructOrderBillRequestPayload(
    data,
    addedItems as any[],
    businessGst
  )
  const response: IApiResponse = yield call(createNewInvoiceApi, {
    bill: requestPayload,
    business_id: merchantId || fallbackBusinessId,
    collection_list_id: linkId || ''
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Failed to create invoice', {
      cause: 'customError'
    })
  }
  const billsResponse: IApiResponse = yield call(
    fetchStaffLinkBillDetails,
    merchantId,
    linkId,
    CL_VERSIONS.CURRENT_ASSIGNMENT
  )
  const billCreated = formatStaffLinkInvoices({
    data: {
      account_details: [],
      bill_details: billsResponse.data.bill_details.filter(
        (_: any) => _.bill.bill_id === response.data.bill_id
      )
    }
  })
  const linkType = staffLinkDetails?.collection_list?.due_config?.config
  yield put(saveSelectedBill(billCreated.data.bill_details[0] || [], linkType))
  yield put(
    addAutoFadeNotification({
      type: NotificationType.INFO,
      bodyText: 'Invoice Created Successfully!'
    })
  )
  yield put(setCartItemsAction([]))
  yield Navigation().replace(
    `/staff/${merchantId}/${linkId}?menudrawer=${action.payload.customerId}`
  )
}

export function* fetchStaffBusinessEffect(action: Action<string>) {
  const businessId = action.payload
  const response: IApiResponse = yield call(getStaffBusinessUser, businessId)
  if (response.type !== API_RESPONSE_TYPE.FAILURE) {
    yield put(saveBusinessDetailsAction(getStaffFormattedBusinessDetails(response.data)))
  }
}
