import { Action } from 'infra/types'
import { damageActions } from './damage.actions'
import { IDamageDrawerState } from './damage.types'

const DAMAGE_DRAWER_STATE: IDamageDrawerState = {
  drawers: {
    showDamageDrawer: false
  }
}

export const DamageReducer = (
  state: IDamageDrawerState = DAMAGE_DRAWER_STATE,
  action: Action<any>
): IDamageDrawerState => {
  switch (action.type) {
    case damageActions.SET_DAMAGE_DRAWER_DATA: {
      return {
        ...state,
        drawers: {
          showDamageDrawer: action.payload.status
        },
        selectedDamageData: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}
