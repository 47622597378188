import { takeCustomLatest } from 'state/common/common.effects'
import {
  setDownloadGroupSummaryStatusAction,
  setDownloadSummaryStatusAction,
  supplyAction
} from './supply.actions'
import {
  downloadStatementEffect,
  fetchGroupDataEffect,
  fetchInvoicesEffect,
  fetchStatementGroupEffect
} from './supply.effect'

function* fetchStatementGroupSaga() {
  yield takeCustomLatest(supplyAction.FETCH_STATEMENT_GROUP_DATA, fetchStatementGroupEffect)
}

function* fetchGroupDataSaga() {
  yield takeCustomLatest(supplyAction.FETCH_GROUP_DATA, fetchGroupDataEffect)
}

function* fetchCustomerInvoicesSaga() {
  yield takeCustomLatest(supplyAction.FETCH_CUSTOMER_INVOICES, fetchInvoicesEffect)
}

function* downloadStatementSummarySaga() {
  yield takeCustomLatest(supplyAction.DOWNLOAD_STATEMENT_SUMMARY, downloadStatementEffect, () => {
    setDownloadSummaryStatusAction('uninitiated')
    setDownloadGroupSummaryStatusAction('uninitiated')
  })
}

export const SupplyStatementGroupWatchers = [
  fetchStatementGroupSaga(),
  fetchGroupDataSaga(),
  downloadStatementSummarySaga(),
  fetchCustomerInvoicesSaga()
]
