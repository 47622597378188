import { IBeatsState } from './beats.types'

export const BeatsState: IBeatsState = {
  loading: false,
  normalisedBeats: {
    byIds: {},
    allIds: []
  },
  normalisedRoutes: {
    byIds: {},
    allIds: []
  },
  currentInvoiceBeats: [],
  drawers: {
    invoiceBeatSelection: false
  }
}
