export const THEME = {
  brandDarkerGrey: '#757575',
  brandDarkGrey: '#bdbdbd',
  brandDarkGrey2: '#dae2e6',
  brandMediumDarkGrey: '#e5ebee',
  brandMedLightGrey: '#eff3f5',
  brandLightGrey: '#e0e0e0',
  lightGrey: '#f2f2f2',
  darkGrey: '#444444',
  brandMediumGrey: '#9e9e9e',
  brandGrey: '#f0f4f5',
  brandSelectedGrey: '#eff3f5',
  brandLighterGrey: '#f9fafb',
  brandLightestGrey: '#fafafa',
  brandGreyPrimary: '#cbd7dc',
  brandBorderGrey: '#e0dddd',
  brandActivityGrey: '#E7EAEE',
  infoGrey: '#616161',
  brandGreySuperLite: '#4E4E4E',

  brandBGLight: '#f5f5f5',
  brandBGMedium: '#eeeeee',
  brandLinkBlue: '#0000ff',

  brandBlack: '#212121',
  brandBlackAlt: '#424242',

  brandWhite: '#fff',

  brandRed: '#ed4017',
  brandDarkRed: '#d3340f',
  brandMaroon: 'rgba(0, 0, 0, 0.8)',
  brandGreen: '#1c873b',
  brandDarkGreen: '#14742d',
  brandMediumGreen: '#c2e4c8',
  brandLightGreen: '#e6f4e9',
  brandTintGreen: '#117631',
  brandHeartRed: '#E72814',

  brandBlue: '#0b62d1',
  brandAquaBlue: '#0574e3',
  brandMediumBlue: '#c1ddfc',
  brandLightBlue: '#e2f2ff',

  brandOrange: '#ffa558',
  brandLightOrange: '#ffcd00',
  brandLighterRed: '#f1ccc1',
  brandLightestRed: '#fbe5de',
  brandDarkOrange: '#e88c00',
  brandLightRed: '#ffe4dd',
  brandLightRedBorder: '#f8cabf',
  brandTintOrangeBorder: '#ffe3b9',
  brandTintOrange: '#fdefda',
  brandDarkerOrange: '#ff5e38',
  brandWarmOrange: '#fab958',
  brandLightGold: '#fdeeda',
  brandYellow: '#f9aa33',
  lightBlue: '#b9deff',
  brandYellowOrange: '#eead16',
  brandCyanPrimary: '#00abbc',
  brandLightPrimary: '#aaeff5'
}
