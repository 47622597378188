import { Action } from 'infra/types'
import { DUE_CONFIG, LIST_USAGE_TYPE } from 'services/Api/StaffLinkApiFolder/type'

export const listSettingsDrawerActions = {
  SET_LIST_SETTINGS_DRAWER: 'listSettingsDrawer/setShowListSettingsDrawer',
  SET_DRAWER: 'listSettingsDrawer/setDrawer',
  RESET_LIST_SETTINGS_STATE: 'assignStaff/resetListSettingsState'
}

export const setListSettingsDrawer = (payload: {
  listId?: string
  newListName?: string
  configType?: DUE_CONFIG
  usageType?: LIST_USAGE_TYPE
  drawerName: 'showListSettingsDrawer' | 'editListSettingsDrawer'
  drawerStatus: boolean
  filterByKey?: 'tagsAND' | 'tagsOR'
  filterByValues?: string[]
  requiredData?:
    | {
        key: 'associated_account_ids'
        data: string[]
      }
    | {
        key: 'bills'
        data: { bill_id: string; customer_id: string }[]
      }
  successCallbackRef?: React.MutableRefObject<{
    cb: () => any
  }>
}): Action<{
  listId?: string
  newListName?: string
  configType?: DUE_CONFIG
  usageType?: LIST_USAGE_TYPE
  drawerStatus: boolean
  filterByKey?: 'tagsAND' | 'tagsOR'
  filterByValues?: string[]
  requiredData?:
    | {
        key: 'associated_account_ids'
        data: string[]
      }
    | {
        key: 'bills'
        data: { bill_id: string; customer_id: string }[]
      }
  successCallbackRef?: React.MutableRefObject<{
    cb: () => any
  }>
}> => {
  return {
    type: listSettingsDrawerActions.SET_LIST_SETTINGS_DRAWER,
    payload
  }
}

export const setListSettingsDrawers = (payload: {
  drawerName: 'showListSettingsDrawer' | 'editListSettingsDrawer'
  status: boolean
}): Action<{
  drawerName: 'showListSettingsDrawer' | 'editListSettingsDrawer'
  status: boolean
}> => ({
  type: listSettingsDrawerActions.SET_DRAWER,
  payload
})

export const resetListSettingsState = (): Action<null> => ({
  type: listSettingsDrawerActions.RESET_LIST_SETTINGS_STATE,
  payload: null
})
