import { getEnvConfig } from 'utils/getEnvConfig'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor,
  businessIdInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const BUSINESS_IDS = '/v3/me'
const GET_BUSINESS_USER = '/v1/GetBusinessUser'
const CREATE_BUSINESS_USER = '/v1/CreateBusinessUser'
const UPDATE_BUSINESS_USER = '/v1/UpdateBusinessUser'
const BUSINESS_MANAGER = 'v1/BusinessManagers'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('IDENTITY_ENDPOINT') || ''

const api = new ApiService(endpoint, headers)
api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

const apiV2 = new ApiService(endpoint, headers)
apiV2.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
apiV2.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
apiV2.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const getBusinessIds = () => {
  return api.getRequest(BUSINESS_IDS, {})
}

export const getBusinessUser = async (businessId: any) => {
  return apiV2.postRequest(
    GET_BUSINESS_USER,
    { business_user_id: businessId },
    { 'OKC-Business-Id': businessId }
  )
}

export const createBusinessUser = async (name: string) => {
  return apiV2.postRequest(CREATE_BUSINESS_USER, { name })
}

export const updateBusinessUser = async (payload: any) => {
  return apiV2.postRequest(UPDATE_BUSINESS_USER, payload)
}

export const getBusinessManager = async (businessId: string) => {
  return apiV2.getRequest(BUSINESS_MANAGER, {}, { 'OKC-Business-Id': businessId })
}

export const updateBusinessManager = async (mobile: string) => {
  return apiV2.postRequest(BUSINESS_MANAGER, { mobile })
}

export const revokeBusinessManager = async (mobile: string) => {
  return apiV2.deleteRequest(BUSINESS_MANAGER, { mobile })
}

export const IdentityApi = {
  getBusinessIds,
  getBusinessUser,
  createBusinessUser,
  updateBusinessUser,
  getBusinessManager,
  updateBusinessManager,
  revokeBusinessManager
}
