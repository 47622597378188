import { takeCustomLatest } from 'state/common/common.effects'
import { ordersAction } from 'state/new-summary/orders/orders.action'
import {
  createOrderBillEffect,
  downloadPickListEffect,
  fetchItemDetailsEffect,
  fetchOrdersEffect,
  updateMultipleOrdersEffect,
  updateOrdersEffect,
  fetchItemsDetailsCatalogueEffects,
  createOrderProformaEffect,
  createInvoiceFromProformaEffect,
  createOrderProformaSettlementEffect,
  updateProformaStatusEffect,
  printProformaEffect,
  shareProformaEffect,
  downloadProformaPickListEffect,
  printOrdersEffect,
  fetchSelectedItemsDetailsEffects
} from 'state/new-summary/orders/orders.effects'

function* fetchOrdersSaga() {
  yield takeCustomLatest(ordersAction.FETCH_ORDERS, fetchOrdersEffect)
}

function* updatePendingOrderSaga() {
  yield takeCustomLatest(ordersAction.UPDATE_ORDERS, updateOrdersEffect)
}

function* fetchSelectedOrderItemDetailsSaga() {
  yield takeCustomLatest(ordersAction.FETCH_SELECTED_ORDER_DETAILS, fetchItemDetailsEffect)
}

function* updateMultipleOrdersSaga() {
  yield takeCustomLatest(ordersAction.UPDATE_MULTIPLE_ORDERS, updateMultipleOrdersEffect)
}

function* createOrderBillSaga() {
  yield takeCustomLatest(ordersAction.CREATE_AND_INVOICE_ORDER, createOrderBillEffect)
}

function* createOrderProformaSaga() {
  yield takeCustomLatest(ordersAction.CREATE_PROFORMA, createOrderProformaEffect)
}

function* downloadPickListSaga() {
  yield takeCustomLatest(ordersAction.DOWNLOAD_PICK_LIST, downloadPickListEffect)
}

function* downloadProformaPickListSaga() {
  yield takeCustomLatest(ordersAction.DOWNLOAD_PROFORMA_PICK_LIST, downloadProformaPickListEffect)
}

function* printProformaSaga() {
  yield takeCustomLatest(ordersAction.PRINT_PROFORMA_MULTIPLE, printProformaEffect)
}

function* shareProformaSaga() {
  yield takeCustomLatest(ordersAction.SHARE_PROFORMA_MULTIPLE, shareProformaEffect)
}

function* fetchItemsDetailsCatalogueSaga() {
  yield takeCustomLatest(
    ordersAction.FETCH_ITEM_DETAILS_BY_ORDER,
    fetchItemsDetailsCatalogueEffects
  )
}

function* createInvoiceFromProformaSaga() {
  yield takeCustomLatest(ordersAction.CREATE_INVOICE_PROFORMA, createInvoiceFromProformaEffect)
}

function* createOrderProformaSettlementSaga() {
  yield takeCustomLatest(
    ordersAction.CREATE_ORDER_PROFORMA_SETTLEMENT,
    createOrderProformaSettlementEffect
  )
}

function* updateProformaStatusSaga() {
  yield takeCustomLatest(ordersAction.UPDATE_PROFORMA_STATUS, updateProformaStatusEffect)
}

function* printOrdersSaga() {
  yield takeCustomLatest(ordersAction.PRINT_ORDERS_MULTIPLE, printOrdersEffect)
}

function* fetchSelectedItemDetailsSaga() {
  yield takeCustomLatest(
    ordersAction.FETCH_SELECTED_ITEMS_DETAILS,
    fetchSelectedItemsDetailsEffects
  )
}

export const OrdersWatcher = [
  fetchOrdersSaga(),
  updatePendingOrderSaga(),
  fetchSelectedOrderItemDetailsSaga(),
  updateMultipleOrdersSaga(),
  createOrderBillSaga(),
  downloadPickListSaga(),
  fetchItemsDetailsCatalogueSaga(),
  createOrderProformaSaga(),
  createInvoiceFromProformaSaga(),
  createOrderProformaSettlementSaga(),
  updateProformaStatusSaga(),
  printProformaSaga(),
  downloadProformaPickListSaga(),
  printOrdersSaga(),
  fetchSelectedItemDetailsSaga(),
  shareProformaSaga()
]
