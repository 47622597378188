import { Action } from 'infra/types'
import { SORT_ORDER_TYPE } from 'state/collectionSummary/collectionSummary.types'
import {
  FetchStatementGroupPayload,
  IBillsData,
  ISpecificGroupTableData,
  IStatementGroupTable,
  SpecificGroupSummary,
  IStatementGroup
} from './supply.types'

export const supplyAction = {
  FETCH_STATEMENT_GROUP_DATA: 'supply/fetchStatementGroupData',
  FETCH_GROUP_DATA: 'supply/fetchGroupData',
  IS_FETCHING_STATEMENT_GROUP: 'supply/isFetching',
  SET_STATEMENT_GROUPS_DATA: 'supply/setStatementGroupsData',
  SET_SPECIFIC_GROUP_SUMMARY: 'supply/setSpecificGroupSummary',
  SET_SPECIFIC_GROUP_TABLE_DATA: 'supply/setSpecificGroupTableData',
  SET_SPECIFIC_GROUP_NAME: 'supply/setSpecificGroupName',
  SET_STATEMENT_GROUP_FILTER_PAGINATION_DATA: 'supply/setStatementGroupFilterAndPaginationData',
  SET_STATEMENT_DOWNLOAD_DRAWER: 'supply/setStatementDownloadDrawer',
  SET_GROUP_STATEMENT_DOWNLOAD_DRAWER: ' supply/setGroupStatementDownloadDrawer',
  DOWNLOAD_STATEMENT_SUMMARY: ' supply/downloadStatementSummary',
  SET_DOWNLOAD_STATEMENT_STATUS: 'supply/setDownloadStatementStatus',
  SET_DOWNLOAD_GROUP_STATEMENT_STATUS: 'supply/setDownloadGroupStatementStatus',
  FETCH_CUSTOMER_INVOICES: 'supply/fetchCustomerInvoices',
  SET_INVOICES_DATA: 'supply/setInvoicesData',
  SET_SPECIFIC_STATEMENT_GROUP: 'supply/setSpecificStatementGroup',
  UNSET_SPECIFIC_STATEMENT_GROUP: 'supply/unsetSpecificStatementGroup',
  CLEAR_GROUP_SPECIFIC_DATA: 'supply/clearGroupSpecificData',
  SET_SORT_BY: 'supply/setSortBy',
  SET_INNER_TABLE_SORT_BY: 'supply/setInnerTableSortBy'
}

export const setSortById = (
  sortBy: string,
  sortOrder: null | SORT_ORDER_TYPE
): Action<{
  sortBy: string
  sortOrder: null | SORT_ORDER_TYPE
}> => ({
  type: supplyAction.SET_SORT_BY,
  payload: { sortBy, sortOrder }
})

export const setInnerTableSortById = (
  sortBy: string,
  sortOrder: null | SORT_ORDER_TYPE
): Action<{
  sortBy: string
  sortOrder: null | SORT_ORDER_TYPE
}> => ({
  type: supplyAction.SET_INNER_TABLE_SORT_BY,
  payload: { sortBy, sortOrder }
})

export const fetchStatementGroupAction = (
  payload: FetchStatementGroupPayload
): Action<FetchStatementGroupPayload> => {
  return {
    type: supplyAction.FETCH_STATEMENT_GROUP_DATA,
    payload
  }
}

export const clearGroupSpecificDataFromStoreAction = () => {
  return {
    type: supplyAction.CLEAR_GROUP_SPECIFIC_DATA
  }
}

export const fetchGroupDataAction = (
  payload: FetchStatementGroupPayload & { groupId: string } & {
    updatedGroupNameWhileEditing?: string
  }
): Action<
  FetchStatementGroupPayload & { groupId: string } & { updatedGroupNameWhileEditing?: string }
> => {
  return {
    type: supplyAction.FETCH_GROUP_DATA,
    payload
  }
}

export const fetchCustomerInvoicesAction = (
  payload: FetchStatementGroupPayload & { groupId: string; customerId: string }
): Action<FetchStatementGroupPayload & { groupId: string; customerId: string }> => {
  return {
    type: supplyAction.FETCH_CUSTOMER_INVOICES,
    payload
  }
}

export const setSpecificGroupSummary = (payload: SpecificGroupSummary) => {
  return {
    type: supplyAction.SET_SPECIFIC_GROUP_SUMMARY,
    payload
  }
}

export const isFetchingStatementGroupAction = (payload: boolean) => {
  return {
    type: supplyAction.IS_FETCHING_STATEMENT_GROUP,
    payload
  }
}

export const setStatementGroupsData = (payload: IStatementGroupTable[]) => {
  return {
    type: supplyAction.SET_STATEMENT_GROUPS_DATA,
    payload
  }
}

export const setSpecificStatementGroup = (
  data: IStatementGroup | { name: string; id: string }
): Action<IStatementGroup | { name: string; id: string }> => ({
  type: supplyAction.SET_SPECIFIC_STATEMENT_GROUP,
  payload: data
})

export const unsetSpecificStatementGroup = (): Action<any> => ({
  type: supplyAction.UNSET_SPECIFIC_STATEMENT_GROUP,
  payload: undefined
})

export const setSpecificTableData = (payload: ISpecificGroupTableData[]) => {
  return {
    type: supplyAction.SET_SPECIFIC_GROUP_TABLE_DATA,
    payload
  }
}

export const setSpecificGroupName = (payload: string) => {
  return {
    type: supplyAction.SET_SPECIFIC_GROUP_NAME,
    payload
  }
}

export const setStatementGroupFilterAndPaginationData = (payload: FetchStatementGroupPayload) => {
  return {
    type: supplyAction.SET_STATEMENT_GROUP_FILTER_PAGINATION_DATA,
    payload
  }
}

export const setStatmentDownloadDrawer = (payload: boolean): Action<boolean> => {
  return {
    type: supplyAction.SET_STATEMENT_DOWNLOAD_DRAWER,
    payload
  }
}

export const setGroupStatmentDownloadDrawer = (payload: boolean): Action<boolean> => {
  return {
    type: supplyAction.SET_GROUP_STATEMENT_DOWNLOAD_DRAWER,
    payload
  }
}

export const downloadStatementSummaryAction = (
  payload: Pick<FetchStatementGroupPayload, 'startTime' | 'endTime'> & {
    fileName: string
    format: string
  } & { groupIds?: string[] }
): Action<
  Pick<FetchStatementGroupPayload, 'startTime' | 'endTime'> & {
    fileName: string
    format: string
  } & { groupIds?: string[] }
> => {
  return {
    type: supplyAction.DOWNLOAD_STATEMENT_SUMMARY,
    payload
  }
}

export const setDownloadSummaryStatusAction = (
  payload: 'uninitiated' | 'initiated' | 'completed'
) => {
  return {
    type: supplyAction.SET_DOWNLOAD_STATEMENT_STATUS,
    payload
  }
}

export const setDownloadGroupSummaryStatusAction = (
  payload: 'uninitiated' | 'initiated' | 'completed'
) => {
  return {
    type: supplyAction.SET_DOWNLOAD_GROUP_STATEMENT_STATUS,
    payload
  }
}

export const setInvoicesData = (payload: IBillsData[]): Action<IBillsData[]> => {
  return {
    type: supplyAction.SET_INVOICES_DATA,
    payload
  }
}
