import { Action } from 'infra/types'
import { chequeActions } from './cheque.actions'
import { IChequeState } from './cheque.types'
import { DEFAULT_PAGE_SIZE } from './cheque.helpers'

const defaultChequeReducer: IChequeState = {
  receivedCheque: [],
  depositedCheque: [],
  clearedCheque: [],
  resetSelectedRowRef: {
    receivedCheque: null,
    otherCheque: null
  },
  receivedChequeSortedBy: '',
  depositedChequeSortedBy: '',
  clearedChequeSortedBy: '',
  editChequeDrawer: {
    showDrawer: false,
    chequeDetails: null
  },
  pagination: {
    receivedCheque: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEntries: 0
    },
    depositedCheque: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEntries: 0
    },
    clearedCheque: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEntries: 0
    }
  }
}

export const ChequeReducer = (
  state: IChequeState = defaultChequeReducer,
  action: Action<any>
): IChequeState => {
  switch (action.type) {
    case chequeActions.setReceivedChequesAction: {
      return {
        ...state,
        receivedCheque: action.payload.cheques || [],
        pagination: {
          ...state.pagination,
          receivedCheque: action.payload.pagination
        }
      }
    }
    case chequeActions.setReceivedSortedBy: {
      return {
        ...state,
        receivedChequeSortedBy: action.payload.sortBy
      }
    }
    case chequeActions.setDepositedSortedBy: {
      return {
        ...state,
        depositedChequeSortedBy: action.payload.sortBy
      }
    }
    case chequeActions.setClearedSortedBy: {
      return {
        ...state,
        clearedChequeSortedBy: action.payload.sortBy
      }
    }
    case chequeActions.setChequesAction: {
      return {
        ...state,
        [action.payload.chequeType]: action.payload.data || []
      }
    }
    case chequeActions.setEditChequeData: {
      return {
        ...state,
        editChequeDrawer: {
          showDrawer: true,
          chequeDetails: action.payload.chequeDetail
        }
      }
    }
    case chequeActions.resetEditChequeState: {
      return {
        ...state,
        editChequeDrawer: {
          showDrawer: false,
          chequeDetails: null
        }
      }
    }
    case chequeActions.setOtherChequesAction: {
      return {
        ...state,
        [action.payload.chequeType]: action.payload.cheques || [],
        pagination: {
          ...state.pagination,
          [action.payload.chequeType]: action.payload.pagination
        }
      }
    }
    case chequeActions.setFilterTime: {
      return {
        ...state,
        filterTime: action.payload
      }
    }
    case chequeActions.setByChequeDateFilter: {
      return {
        ...state,
        [action.payload.byChequeDateFilterType]: action.payload.filterValue
      }
    }
    default: {
      return state
    }
  }
}
