import mixpanel, { Dict } from 'mixpanel-browser'
import { isWebView, trackAndroidEvent } from 'utils/android'
import { IPlatform } from './Mixpanel.types'

// make env_check = true while developing to check the events
const env_check = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development'

const getCurrentTime = () => ~~(Date.now() / 1000)

const actions = {
  // Current platform
  platform: IPlatform.DESKTOP,
  // Set the platform to be used
  setPlatform(platform: IPlatform) {
    this.platform = platform
  },
  // initiate mixpanel with key from .env or from artifacts when in production
  init: (key: string) => {
    if (env_check && !isWebView()) {
      mixpanel.init(key)
    }
    // if (env_check) {
    //   mixpanel.init('b8f375c8df48bb563b24d19af10ffe58') // Stage mixpanel // TODO: Comment this line before going to prod
    // }
  },
  // Identify a user with a unique ID to track user activity across devices
  identify: (id: string) => {
    if (env_check && !isWebView()) {
      mixpanel.identify(id)
    }
  },
  reset: () => {
    if (env_check && !isWebView()) {
      mixpanel.reset()
    }
  },
  // To register super properties
  register: (props: Dict) => {
    if (env_check && !isWebView()) {
      mixpanel.register(props)
    }
  },
  // To Track events
  track: (event_name: string, properties?: Dict) => {
    if (env_check) {
      if (window.androidObj && window.androidObj.trackEvent) {
        window.androidObj.trackEvent(event_name, JSON.stringify(properties))
      } else {
        mixpanel.track(event_name, properties)
      }
    }
  },
  // To track events with some preset values (Take out from common usecase to avoid repetition)
  trackWithPreset(event_name: string, properties?: Dict) {
    if (env_check) {
      const mixpanelProps = {
        ...properties,
        time: getCurrentTime(),
        Platform: this.platform,
        Lang: localStorage.getItem('i18nextLng'),
        Version: 'V2'
      }
      trackAndroidEvent(event_name, mixpanelProps, () => {
        mixpanel.track(event_name, mixpanelProps)
      })
    }
  },
  // To set a group of people in a set
  people: {
    set: (props: Dict) => {
      if (env_check && !isWebView()) {
        mixpanel.people.set(props)
      }
    }
  }
}

export const Mixpanel = actions
