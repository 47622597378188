import { Action } from 'infra/types'
import { IBlindpayState } from './blindpay.types'
import { BlindpayState } from './blindpay.state'
import { BlindpayActions } from './blindpay.actions'

export const BlindpayReducer = (
  state: IBlindpayState = BlindpayState,
  action: Action<any>
): IBlindpayState => {
  switch (action.type) {
    case BlindpayActions.FETCH_PAYMENT_DETAILS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case BlindpayActions.FETCH_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        paymentDetail: action.payload,
        isLoading: false
      }
    }
    case BlindpayActions.FETCH_PAYMENT_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case BlindpayActions.SEND_OTP_SUCCESS: {
      return {
        ...state,
        otpId: action.payload
      }
    }
    case BlindpayActions.VERIFY_OTP: {
      return {
        ...state,
        authDevise: null
      }
    }
    case BlindpayActions.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        authDevise: null
      }
    }
    case BlindpayActions.VERIFY_OTP_FAILURE: {
      return {
        ...state,
        authDevise: 'OTP Incorrect'
      }
    }
    case BlindpayActions.VERIFY_DESTINATION_DETAILS: {
      return {
        ...state,
        verifiedPaymentAddress: {
          name: '',
          isValid: false,
          isVerifying: true
        }
      }
    }
    case BlindpayActions.VERIFY_DESTINATION_DETAILS_SUCCESS: {
      return {
        ...state,
        verifiedPaymentAddress: {
          name: action.payload.name,
          isValid: action.payload.isValid,
          isVerifying: false
        }
      }
    }
    case BlindpayActions.VERIFY_DESTINATION_DETAILS_FAILURE: {
      return {
        ...state,
        verifiedPaymentAddress: {
          name: 'NA',
          isValid: false,
          isVerifying: false
        }
      }
    }
    case BlindpayActions.SUBMIT_DESTINATION_DETAILS_SUCCESS: {
      return {
        ...state,
        paymentId: action.payload.paymentId
      }
    }
    case BlindpayActions.SESSION_EXPIRED: {
      return {
        ...state,
        otpId: '',
        txnToken: '',
        expiryTime: 0,
        verifiedPaymentAddress: {
          name: '',
          isValid: false,
          isVerifying: false
        },
        sessionExpired: true
      }
    }
    case BlindpayActions.SESSION_RESTART: {
      return {
        ...state,
        sessionExpired: false
      }
    }
    default: {
      return state
    }
  }
}
