import { createHistory } from './../infra/history'

export const Navigation = () => {
  const history = typeof window !== 'undefined' ? window._history_ : createHistory()
  return {
    to: (route: string) => {
      history.push(route)
    },
    back: () => {
      history.goBack()
    },
    to404: () => {
      history.push('/404')
    },
    replace: (route: string) => {
      history.replace(route)
    },
    toWebsite: () => {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(window.location.origin, '_self')
    }
  }
}
