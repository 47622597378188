const FixedHeaderStyles = {
  header: {
    width: '100%',
    position: 'relative',
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    position: 'fixed',
    top: 0,
    zIndex: 99,
    left: 0,
    right: 0,
    backgroundColor: '#fff'
  }
}

export default FixedHeaderStyles
