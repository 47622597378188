import { Action } from 'infra/types'
import { INudge, IMigrationState } from './migration.types'
import { MigrationState } from './migration.state'
import { MigrationActions } from './migration.actions'

export const MigrationReducers = (
  state: IMigrationState = MigrationState,
  action: Action<INudge>
): IMigrationState => {
  switch (action.type) {
    case MigrationActions.NUDGE_INIT: {
      return {
        ...state,
        show: true,
        data: action.payload
      }
    }
    case MigrationActions.NUDGE_RESET: {
      return {
        ...state,
        show: false
      }
    }
    default: {
      return state
    }
  }
}
