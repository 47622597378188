import { Action } from 'infra/types'
import { IOfferAndDiscountState } from './items-offer-and-discount.types'
import { offerAndDiscountsDrawerActions } from './items-offer-and-discount.actions'

const OFFER_AND_DISCOUNT: IOfferAndDiscountState = {
  drawers: {
    showOffersAndDiscountDrawer: false
  },
  inventoryItemIds: [],
  areOffersClickable: true,
  previousOffers: undefined,
  businessOffers: [],
  handleSaveCallback: undefined,
  showOnlyPreviousOffer: false
}

export const ItemOfferAndDiscountsReducer = (
  state: IOfferAndDiscountState = OFFER_AND_DISCOUNT,
  action: Action<any>
): IOfferAndDiscountState => {
  switch (action.type) {
    case offerAndDiscountsDrawerActions.SET_OFFERS_AND_DISCOUNTS_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showOffersAndDiscountDrawer: action.payload.drawerStatus
        },
        ...('areOffersClickable' in action.payload && {
          areOffersClickable: action.payload.areOffersClickable
        })
      }
    }
    case offerAndDiscountsDrawerActions.SET_OFFERS_AND_DISCOUNTS_DRAWER_DATA: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showOffersAndDiscountDrawer: true
        },
        inventoryItemIds: action.payload.inventoryItemIds,
        previousOffers: action.payload.previousOffers,
        handleSaveCallback: action.payload.handleSaveCallback,
        ...('areOffersClickable' in action.payload && {
          areOffersClickable: action.payload.areOffersClickable
        }),
        showOnlyPreviousOffer: action.payload.showOnlyPreviousOffer
      }
    }
    case offerAndDiscountsDrawerActions.SET_HANDLE_SAVE_CALLBACK_DRAWER: {
      return {
        ...state,
        handleSaveCallback: action.payload.handleSaveCallback,
        handleBackArrowClick: action.payload.handleBackArrowClick
      }
    }
    case offerAndDiscountsDrawerActions.SET_ALL_BUSINESS_OFFERS_AND_DISCOUNTS: {
      return {
        ...state,
        businessOffers: action.payload.businessOffers
      }
    }
    case offerAndDiscountsDrawerActions.RESET_OFFERS_AND_DISCOUNTS_DRAWER: {
      return { ...OFFER_AND_DISCOUNT, businessOffers: state.businessOffers }
    }
    default: {
      return state
    }
  }
}
