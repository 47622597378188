import { TRANSACTION_TYPE } from 'utils/common/common.types'
import { BUTTON_TYPE } from 'state/new-summary/list/list.types'
import {
  FetchPendingActionResponse,
  IPendingActionsRowData,
  TRANSACTION_TYPE_KEY_MAPPER,
  Transaction
} from './approve.types'

const getButtonType = (rootData: IPendingActionsRowData, item: any): BUTTON_TYPE => {
  if (item.cancel_bill_params) {
    return BUTTON_TYPE.DELETE_BUTTON
  }

  const keysToCheck = [
    'damages',
    'cash',
    'neft',
    'cheque',
    'advanceUsed',
    'qrLink',
    'returns',
    'others'
  ]
  let keyCounter = 0
  keysToCheck.forEach((key) => {
    if ((rootData as any)[key]) {
      keyCounter++
    }
  })

  if (keyCounter >= 1) {
    return BUTTON_TYPE.APPROVE_BUTTON
  } else if (keyCounter === 0 && !!rootData.replace) {
    return BUTTON_TYPE.REPLACEMENT_BUTTON
  } else if (keyCounter === 0 && rootData?.deliveryAttemptId) {
    return BUTTON_TYPE.DELIVERY_BUTTON
  }

  return BUTTON_TYPE.APPROVE_BUTTON
}

export const formatPendingActions = (
  data: FetchPendingActionResponse
): IPendingActionsRowData[] => {
  const formattedResponse: IPendingActionsRowData[] = data.pending_actions.map((item) => {
    let rootData: any = {
      assignedTo: item.list_name,
      customerName: item.customer_name,
      invoiceNumber: item.bill_number,
      invoiceDate: item.bill_date,
      invoiceAmount: Number(item.bill_amount || 0) / 100,
      invoicePaidAmmount: Number(item.total_paid_amount || 0) / 100,
      billId: item.bill_id,
      beats: item.beats,
      collectedOn: item.latest_transaction_date
    }

    if (item.cancel_bill_params) {
      rootData = {
        ...rootData,
        cancelBillTxnId: item.cancel_bill_params.bill_id
      }
    }
    // TODO: Fix type
    item.transactions.forEach((txn: any) => {
      rootData = {
        ...rootData,
        [TRANSACTION_TYPE_KEY_MAPPER[txn.txn_type]]: {
          ...txn,
          amount: Number(txn.amount) / 100,
          ...('return_items' in txn && txn.txn_type === TRANSACTION_TYPE.RETURN
            ? {
                return_items: txn?.return_items?.map((returnItem: any) => ({
                  ...returnItem,
                  name: item.bill_info?.items?.[returnItem?.index]?.name
                }))
              }
            : {})
        }
      }
    })

    if (item?.delivery_attempts.length) {
      item?.delivery_attempts.forEach((attempt) => {
        rootData = {
          ...rootData,
          deliveryAttemptId: attempt.id
        }
      })
    }

    if ((item as any).replacement) {
      rootData = {
        ...rootData,
        replace: { ...item.replacement, amount: String(Number(item.replacement?.amount) / 100) }
      }
    }

    const btnType = getButtonType(rootData, item)
    const isSelectable =
      btnType === BUTTON_TYPE.APPROVE_BUTTON || btnType === BUTTON_TYPE.DROPDOWN_BUTTON

    return {
      ...rootData,
      buttonType: btnType,
      isSelectable
    }
  })
  return formattedResponse
}
