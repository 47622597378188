import { ISummaryV2DateFilter } from 'pages/CollectionSummary/CollectionSummary.types'
import { COLLECTION_FILTER_TYPE } from 'pages/NewDashboard/components/Summary/components/CollectionContainer/CollectionHeader/constants'

export interface IPagination {
  pageSize: number
  currentPage: number
  totalEntries: number
}

export interface ILoaders {
  listSummary?: boolean
}

export interface IListViewRowData {
  id: string
  listName: string
  totalCustomers: number
  totalInvoices: number
  totalCollection: number
  cash: number
  neft: number
  online: number
  cheque: number
  return: number
  damage: number
  others: number
  advance: number
}

export interface IListGroupViewRowData {
  listId: string
  listName: string
  customerId: string
  customerName: string
  latestSettlementDate: number
  invoiceDate: number
  invoiceNumber: string
  invoiceAmount: number
  cash: number
  neft: number
  online: number
  cheque: number
  return: number
  damage: number
  others: number
  advance: number
}

export interface IOverallGroupViewRowData {
  customerCount: string
  invoiceCount: string
  totalCollection: number
  cash: number
  neft: number
  online: number
  cheque: number
  return: number
  damage: number
  others: number
  advance: number
}

export interface ISummaryResponse {
  list_name: string
  list_id: string
  total_customers: number
  total_invoices: number
  total_collected_amount: number
  total_cash_amount: number
  total_qr_amount: number
  total_cheque_amount: number
  total_neft_amount: number
  total_damage_amount: number
  total_returns_amount: number
  total_others_amount: number
  total_advance_amount: number
}

export interface ITransactionSummaryResponse {
  list_name: string
  list_id: string
  customer_id: string
  invoice_number: string
  invoice_date: number
  invoice_amount: number
  collection_date: number
  cash_amount: number
  qr_amount: number
  cheque_amount: number
  neft_amount: number
  damage_amount: number
  returns_amount: number
  others_amount: number
  advance_amount: number
}
interface PaginationDetails {
  page: string
  limit: string
  total_items: string
  total_pages: string
  per_page: string
}

export interface IOverallApprovedTransactionsResponse {
  overall_summary: ISummaryResponse
  listwise_summary: ISummaryResponse[]
  pagination_details: PaginationDetails
}

export interface IApprovedTransactionsResponse {
  overall_summary: ISummaryResponse
  list_summaries: ITransactionSummaryResponse[]
  pagination_details: PaginationDetails
}

export interface IListViewData {
  rowData: IListViewRowData[]
  pagination: IPagination | null
}

export interface IFetchPayload {
  listViewData: IListViewRowData[]
  overallSummaryData: IListViewRowData
  pagination?: IPagination
}

export interface IGroupViewData {
  rowData: IListGroupViewRowData[]
  overallSummaryData: IListViewRowData
  pagination: IPagination | null
  sortBy?: string
  sortOrder?: SORT_ORDER_TYPE | null
}

export interface IFilter {
  filter_condition: 'or' | 'and'
  tags: string[]
}

export type SORT_ORDER = {
  ascending: 1
  descending: 0
}

export enum SORT_ORDER_TYPE {
  ascending = 'ascending',
  descending = 'descending'
}

export interface IApprovedCollectionSummaryState {
  loaders: {
    listSummary: boolean
    groupSummary: boolean
  }
  listViewData: IListViewData | null
  overallSummaryData: IListViewRowData | null
  activeFilter: ISummaryV2DateFilter | null
  groupViewData: IGroupViewData | null
  sortBy?: string
  sortOrder?: null | SORT_ORDER_TYPE
  drawers: {
    downloadDialog: boolean
  }
}

export interface IFetchApprovedCollectionSummaryActionPayload {
  startTime: number
  endTime: number
  page: number
  perPage: number
  sortBy?: null | string
  sortOrder?: null | SORT_ORDER_TYPE
  filterType: COLLECTION_FILTER_TYPE
}

export interface IFetchApprovedCollectionGroupSummaryActionPayload
  extends IFetchApprovedCollectionSummaryActionPayload {
  listId: string
}

export interface IDownloadApprovedCollectionSummaryActionPayload {
  startTime: number
  endTime: number
  sortBy?: null | string
  sortOrder?: null | SORT_ORDER_TYPE
  filterType: COLLECTION_FILTER_TYPE
  fileName: string
  format: string
}
