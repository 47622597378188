import { IInvoiceBeat } from './beats.types'

export const formatInvoiceBeats = (beats: IInvoiceBeat[]) => {
  const accountBeats: IInvoiceBeat[] = []
  const invoiceBeats: IInvoiceBeat[] = []
  beats.forEach((beat) => {
    if (beat.account_transaction_id) {
      invoiceBeats.push(beat)
    } else {
      accountBeats.push(beat)
    }
  })
  return [...accountBeats, ...invoiceBeats]
}
