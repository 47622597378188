import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Button, TickIcon } from '@okcredit/web-lib'
import classNames from 'classnames'
import { THEME } from 'components/theme'

const styles = createUseStyles({
  container: {
    backgroundColor: THEME.brandWhite,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '8%',
    borderBottom: `solid 1px ${THEME.brandDarkGrey2}`
  },
  content: {
    boxSizing: 'border-box',
    height: '84%',
    overflowY: 'scroll',
    background: THEME.brandWhite
  },
  footer: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    height: '8%',
    borderTop: `solid 1px ${THEME.brandDarkGrey2}`
  }
})

const DrawerInnerContentSkeleton = ({
  header,
  mainContainer,
  footer,
  classList
}: {
  header: ReactNode
  mainContainer: ReactNode
  footer: ReactNode
  classList?: { footer?: string }
}) => {
  const classes = styles()
  return (
    <div className={classes.container}>
      <div className={classes.header}>{header}</div>
      <div className={classes.content}>{mainContainer}</div>
      <div className={classNames(classes.footer, classList?.footer)}>{footer}</div>
    </div>
  )
}

const footerStyles = createUseStyles({
  buttonContainer: {
    padding: '0 20px',
    boxSizing: 'border-box',
    width: '100%'
  }
})

export const Footer = ({
  title,
  isSavedisabled,
  startIcon,
  className,
  handleConfirmClick
}: {
  title: string
  isSavedisabled: boolean
  startIcon?: React.ReactNode | null
  className?: string
  handleConfirmClick: any
}) => {
  const classes = footerStyles()
  return (
    <div className={classes.buttonContainer}>
      <Button
        variant="filled"
        fullWidth
        onClick={handleConfirmClick}
        startIcon={startIcon ? startIcon : <TickIcon fill="#fff" size={20} />}
        disabled={isSavedisabled}
        className={className}
      >
        {title}
      </Button>
    </div>
  )
}

export default DrawerInnerContentSkeleton
