import React from 'react'
import useTabs from 'pages/NewDashboard/hooks/useTabs'
import { VerticalHeaderStyles } from './styles'
import TabsContainer from './TabsContainer'

const VerticalHeader = () => {
  const classes = VerticalHeaderStyles()
  const { topTabs, bottomTabs } = useTabs()
  return (
    <div className={classes.container}>
      <TabsContainer tabs={topTabs} />
      <TabsContainer tabs={bottomTabs} />
    </div>
  )
}

export default VerticalHeader
