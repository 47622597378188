import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'

export const KEY_TO_LABEL_MAPPER: Record<string, string> = {
  staffName: 'Staff',
  itemName: 'Item Name',
  quantity: 'Quantity',
  amountPaid: 'Amount',
  modeOfPayment: 'Payment Account',
  payeeType: 'Expense Type',
  particulars: 'Notes'
}
export const eventMap: { [key in 'item' | 'hsnCode' | 'mrp' | 'rate' | 'taxPercent']: string } = {
  item: NEWDASHBOARD_EVENTS.WEB_BILLS_ADD_NEW_ITEM_ITEM_NAME_CLICKED,
  hsnCode: NEWDASHBOARD_EVENTS.WEB_BILLS_ADD_NEW_ITEM_HSN_CLICKED,
  mrp: NEWDASHBOARD_EVENTS.WEB_BILLS_ADD_NEW_ITEM_MRP_CLICKED,
  rate: NEWDASHBOARD_EVENTS.WEB_BILLS_ADD_NEW_ITEM_RATE_CLICKED,
  taxPercent: NEWDASHBOARD_EVENTS.WEB_BILLS_ADD_NEW_ITEM_GST_BRACKET
}

export const DECIMAL_NUMBER_REGEX = /^\d*\.?\d{0,2}$/

export const validateDecimalNumberInput = (inputValue: string, integerLength: number = 0) => {
  if (integerLength) {
    const regex = new RegExp(`^\\d{0,${integerLength}}(?:\\.\\d{0,2})?$`)
    return regex.test(inputValue) || inputValue === ''
  }

  return DECIMAL_NUMBER_REGEX.test(inputValue) || inputValue === ''
}

export const getCount = (value: string) => {
  try {
    if (value === '-2') {
      return '0'
    }

    if (value.includes('.')) {
      return value.split('.')[0].length
    }

    return value.length || '0'
  } catch (error) {
    return '0'
  }
}

export const payeeType = [
  {
    name: 'Salary',
    id: 'payroll'
  },
  {
    name: 'Advance',
    id: 'advance'
  },
  {
    name: 'Misc',
    id: 'miscellaneous'
  },
  {
    name: 'Payout',
    id: 'payout'
  },
  {
    name: 'Transfer',
    id: 'transfer'
  }
]

export enum TYPES {
  GENERAL = 'General',
  STAFF = 'Staff',
  BANK_CHARGES = 'Bank Charges'
}

export const templateFormatOptions = [
  {
    label: 'Staff',
    value: TYPES.STAFF
  },
  {
    label: 'General',
    value: TYPES.GENERAL
  },
  {
    label: 'Bank Charges',
    value: TYPES.BANK_CHARGES
  }
]
