import { takeLatest, takeEvery } from 'redux-saga/effects'
import { OrderQRActions, OrderQRAnalyticsActions } from './OrderQR.actions'
import {
  handleGetUpiId,
  handleGetAddress,
  handleUpdateUpiId,
  handleGetKycStatus,
  handleConfirmOrder,
  handleGetMerchantProfile,
  handleGetRetrieveSubscription,
  handleGetCreateSubscription,
  handleGetQrVpa
} from './OrderQR.effects'
import OrderQRAnalytics from './OrderQR.analytics'

/*
 * Events watcher
 */
function* orderQRGetUpiSaga() {
  yield takeLatest(OrderQRActions.GET_UPI_ID, handleGetUpiId)
}

function* orderQRUpdateUpiSaga() {
  yield takeLatest(OrderQRActions.UPDATE_UPI_ID, handleUpdateUpiId)
}

function* orderQRGetAddressSaga() {
  yield takeLatest(OrderQRActions.GET_SHIPPING_ADDRESS, handleGetAddress)
}

function* getKycStatusSaga() {
  yield takeLatest(OrderQRActions.GET_KYC_STATUS, handleGetKycStatus)
}

function* confirmOrderSaga() {
  yield takeLatest(OrderQRActions.CONFIRM_ORDER, handleConfirmOrder)
}

function* getMerchantProfileSaga() {
  yield takeLatest(OrderQRActions.GET_MERCHANT_DETAILS, handleGetMerchantProfile)
}

function* getMerchantRetrieveSubscriptionSaga() {
  yield takeLatest(OrderQRActions.GET_RETRIEVE_SUBSCRIPTION, handleGetRetrieveSubscription)
}

function* getMerchantCreateSubscriptionSaga() {
  yield takeLatest(OrderQRActions.GET_CREATE_SUBSCRIPTION, handleGetCreateSubscription)
}

function* getMerchantQrVpaSaga() {
  yield takeLatest(OrderQRActions.GET_QR_VPA, handleGetQrVpa)
}
/*
 * Events watcher
 */
function* orderQRAnalyticsSaga() {
  yield takeEvery([...Object.values(OrderQRAnalyticsActions)], OrderQRAnalytics)
}

export const OrderQREffects = [
  orderQRGetUpiSaga(),
  orderQRUpdateUpiSaga(),
  orderQRGetAddressSaga(),
  getKycStatusSaga(),
  confirmOrderSaga(),
  orderQRAnalyticsSaga(),
  getMerchantProfileSaga(),
  getMerchantRetrieveSubscriptionSaga(),
  getMerchantCreateSubscriptionSaga(),
  getMerchantQrVpaSaga()
]
