import { call, put, select } from 'redux-saga/effects'
import { Action } from 'infra/types'
import { fetchList } from 'services/Api/StafflinkApi'
import { CL_VERSIONS } from 'utils/common/common.types'
import { IAppState } from 'infra/AppState'
import {
  addSelectedOrderSettingAccountIds,
  deSelectedOrderSettingAccountIds
} from 'services/Api/StaffLinkApiFolder/api'
import { IExpState } from 'state/experiment/experiment.types'
import { showListCustomerForOrderSettingAction } from 'state/new-summary/root-drawers/create-new-list/create-new-list.actions'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { LIST_USAGE_TYPE } from 'services/Api/StaffLinkApiFolder/type'
import { API_RESPONSE_TYPE, IApiResponse } from '../../../constants'
import {
  setCollectionListStore,
  setCollectionLists,
  setCollectionLoaders,
  updateAccountIdToOrderSettingListAction
} from './collection-list.actions'
import { fetchListWithId, fetchOrderSettingAccountIds } from './list.effect'
import { getVersion } from './list.helpers'
export function* fetchCollectionListsEffect() {
  const response: IApiResponse = yield call(fetchList, 0)
  const formattedCollectionList = response.data.collections_lists
    .map((list: any) => ({
      listName: list.name,
      listId: list.id,
      hasPendingActions: list?.summary?.pending_actions?.length > 0
    }))
    .sort(
      (
        a: {
          listName: string
          listId: string
          hasPendingActions: boolean
        },
        b: {
          listName: string
          listId: string
          hasPendingActions: boolean
        }
      ) => (b.hasPendingActions ? 1 : 0) - (a.hasPendingActions ? 1 : 0)
    )
  yield put(setCollectionLists(formattedCollectionList))
}

export function* addAccountIdToOrderSettingListEffect(
  action: Action<{ accountId: string[]; listId: string }>
) {
  try {
    const { listId, accountId: newAccountIds } = action.payload
    const orderSettingAccountIds: string[] = yield select(
      (app: IAppState) => app.NewCollectionList?.orderSettingAccountIds || []
    )
    const supplyListAccountIds: string[] = yield select(
      (app: IAppState) => app.SupplyList?.orderSettingAccountIds || []
    )

    const { selectedList } = yield select((app: IAppState) => app.NewCollectionList)
    const previousAccountIds =
      selectedList?.usage_type === LIST_USAGE_TYPE.SUPPLY
        ? supplyListAccountIds
        : orderSettingAccountIds
    const deSelectedIds = previousAccountIds.filter((id) => !newAccountIds.includes(id))
    const selectedIds = newAccountIds.filter((id) => !previousAccountIds.includes(id))
    if (deSelectedIds.length > 0) {
      const response1: IApiResponse = yield call(
        deSelectedOrderSettingAccountIds,
        listId,
        deSelectedIds
      )
      if (response1.type === API_RESPONSE_TYPE.FAILURE) {
        throw new Error('Failed to deselect account IDs')
      }
    }
    if (selectedIds.length > 0) {
      const response: IApiResponse = yield call(
        addSelectedOrderSettingAccountIds,
        listId,
        selectedIds
      )
      if (response.type === API_RESPONSE_TYPE.SUCCESS) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.SUCCESS,
            bodyText: 'Added successfully'
          })
        )
      } else {
        throw new Error('Failed to add account IDs')
      }
    }
    yield put(showListCustomerForOrderSettingAction({ isShowCustomerListForOrderSetting: false }))
    const fetchOrderSettingAccountIdsResponse: string[] = yield call(fetchOrderSettingAccountIds, {
      listId
    })
    yield put(
      updateAccountIdToOrderSettingListAction({
        accountId: fetchOrderSettingAccountIdsResponse || []
      })
    )
  } catch (error) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Something went wrong!'
      })
    )
  }
}

export function* fetchCollectionListWithId(
  action: Action<{ listId: string; version?: CL_VERSIONS }>
) {
  const { listId, version: versionFromPayload } = action.payload
  yield put(setCollectionLoaders({ loaderName: 'isFetchingListData', status: true }))
  const { selectedAssignmentFilter } = yield select((app: IAppState) => app.NewCollectionList)
  const {
    ab: { okDebitOrderManagement }
  }: IExpState = yield select((app: IAppState) => app.Experiment)
  const version = getVersion(versionFromPayload, selectedAssignmentFilter)
  const {
    noActionsTableData,
    approveTableData,
    pendingTableData,
    onHoldBillsData,
    assignmentFilter,
    summaryData,
    list,
    beatsforNoActionTable,
    beatsforApproveTable,
    beatsforPendingActionTable,
    routesforNoActionTable,
    routesforApproveTable,
    routesforPendingActionTable,
    existingAccountIds,
    existingBills,
    rescheduledDeliveries,
    addedCredit,
    listPendingDue
  }: Record<string, any> = yield fetchListWithId({ listId, version })

  let orderSettingAccountIds: string[] = []
  if (okDebitOrderManagement) {
    orderSettingAccountIds = yield fetchOrderSettingAccountIds({ listId })
  }
  yield put(
    setCollectionListStore({
      pendingTable: pendingTableData,
      noActionsTable: noActionsTableData,
      approveTable: approveTableData,
      rescheduledDeliveries: rescheduledDeliveries,
      onHoldBillsData,
      allAssignmentFilter: assignmentFilter,
      summaryData,
      selectedList: list,
      selectedAssignmentFilter: version,
      beatsforNoActionTable,
      beatsforApproveTable,
      beatsforPendingActionTable,
      routesforNoActionTable,
      routesforApproveTable,
      routesforPendingActionTable,
      existingAccountIds,
      existingBills,
      addedCredit,
      listPendingDue,
      orderSettingAccountIds
    })
  )

  yield put(setCollectionLoaders({ loaderName: 'isFetchingListData', status: false }))
}
