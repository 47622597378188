import { IAgentDashboardState } from './agent-dashboard.types'

export const AgentDashboardState: IAgentDashboardState = {
  metaData: {
    loading: false
  },
  onboardedMerchants: {
    loading: true,
    paginateLoader: false,
    data: {
      onboardings: [],
      totalCount: 0
    }
  },
  onboardFormLoading: {
    loading: false
  },
  checkBoxList: {
    loading: false,
    data: {}
  },
  summary: {
    loading: false
  },
  revisitForm: {
    loading: false
  },
  listFilter: {
    revisitsOnly: false,
    statusFilter: 0
  }
}
