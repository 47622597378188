import { CallEffect, call, put, select } from 'redux-saga/effects'
import { fetchList, fetchSelectedOrderSettingAccountIds } from 'services/Api/StaffLinkApiFolder/api'
import {
  DUE_CONFIG,
  FETCH_LIST_API_RESPONSE,
  LIST_USAGE_TYPE,
  NewList
} from 'services/Api/StaffLinkApiFolder/type'
import { CL_VERSIONS } from 'utils/common/common.types'
import { Action } from 'infra/types'
import { IAppState } from 'infra/AppState'
import { newPaths } from 'routes'
import { IExpState } from 'state/experiment/experiment.types'
import { API_RESPONSE_TYPE, IApiResponse } from '../../../constants'
import {
  IApproveTable,
  INoActionsTable,
  IOnHoldBillData,
  IPendingTable,
  SummaryKeys,
  IRescheduledDeliverieTable
} from './list.types'
import {
  computeAddedCreditAndPendingDue,
  createApprovedTable,
  createNoActionsTableAndOnHoldData,
  createPendingTable,
  extractSummaryData,
  getAssignmentFilters,
  getDataMappedById,
  getExistingAccountIdsAndBills,
  getVersion
} from './list.helpers'
import { setSupplyListStore, setSupplyLoaders } from './supply-list.actions'
import { setCollectionLoaders, setCollectionListStore } from './collection-list.actions'

type DataType = {
  noActionsTableData: INoActionsTable[]
  approveTableData: IApproveTable[]
  pendingTableData: IPendingTable[]
  rescheduledDeliveries: IRescheduledDeliverieTable[]
  onHoldBillsData: IOnHoldBillData[]
  assignmentFilter?: {
    [CL_VERSIONS.PREVIOUS_ASSIGNMENT]?: number
    [CL_VERSIONS.PAST_ASSIGNMENT]?: number
    [CL_VERSIONS.CURRENT_ASSIGNMENT]?: number
  }
  summaryData: Record<
    SummaryKeys,
    {
      totalApprovedAmount: number
      totalPendingAmount: number
      transactionIdsForApproving: string[]
    }
  >
  list: NewList
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
  beatsforNoActionTable: { id: string; name: string }[]
  beatsforApproveTable: { id: string; name: string }[]
  beatsforPendingActionTable: { id: string; name: string }[]
  routesforNoActionTable: { id: string; name: string }[]
  routesforApproveTable: { id: string; name: string }[]
  routesforPendingActionTable: { id: string; name: string }[]
  addedCredit?: number
  listPendingDue: number
}

export function* fetchOrderSettingAccountIds({ listId }: { listId: string }) {
  const orderSettingAccountIdsResponse: IApiResponse = yield call(
    fetchSelectedOrderSettingAccountIds,
    listId
  )
  if (orderSettingAccountIdsResponse.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went Wrong !!!', {
      cause: 'customError'
    })
  }
  const orderSettingAccountIds: string[] = orderSettingAccountIdsResponse.data.account_ids || []
  return orderSettingAccountIds
}
export function* fetchListWithId({
  listId,
  version
}: {
  listId: string
  version?: CL_VERSIONS
}): Generator<CallEffect, DataType, IApiResponse<FETCH_LIST_API_RESPONSE>> {
  const responseNew: IApiResponse<FETCH_LIST_API_RESPONSE> = yield call(fetchList, listId, version)
  if (responseNew.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went Wrong !!!', {
      cause: 'customError'
    })
  }
  const { accountDetailsByAccountId, billDetailsByBillId } = getDataMappedById(responseNew.data)
  const { existingAccountIds, existingBills } = getExistingAccountIdsAndBills(responseNew.data)
  const {
    collections,
    replacements,
    list,
    accounts,
    bills = [],
    unapproved_cancel_requests: unapprovedCancelRequests,
    unapproved_delivery_attempts: unApprovedDeliveryAttempts,
    bills_to_redeliver: billToRedeliver
  } = responseNew.data

  /** @description
   * Bills were filtered because, customer object was manually deleted in
   * the BE but relevant transactions/bills were not deleted
   *
   * Ideally this should be handled at BE, but to ensure smooth user experience, this temporary fix
   * is incorporate
   */
  const filteredBills = (bills || []).filter((bill) =>
    Object.keys(accountDetailsByAccountId).includes(bill.customer_id)
  )

  const {
    pendingTableData,
    pendingTableRowDataMappedWithBilldId,
    beatsforPendingActionTable,
    routesforPendingActionTable
  } = createPendingTable({
    isCustomerList:
      list.due_config.config === DUE_CONFIG.CUSTOMER_LIST ||
      list.due_config.config === DUE_CONFIG.BALANCE_DUE,
    collections,
    accountDetailsByAccountId,
    billDetailsByBillId,
    unapprovedCancelRequests,
    replacements,
    unApprovedDeliveryAttempts,
    billBeats: responseNew.data.bill_beats
  })
  const { approveTableData, beatsforApproveTable, routesforApproveTable } = createApprovedTable({
    collections,
    billDetailsByBillId,
    accountDetailsByAccountId,
    bills: filteredBills,
    billBeats: responseNew.data.bill_beats
  })

  const {
    noActionsTableData,
    beatsforNoActionTable,
    onHoldBillsData,
    rescheduledTableData,
    routesforNoActionTable
  } = createNoActionsTableAndOnHoldData({
    isCustomerList:
      list.due_config.config === DUE_CONFIG.CUSTOMER_LIST ||
      list.due_config.config === DUE_CONFIG.BALANCE_DUE,
    accounts,
    bills: filteredBills,
    accountDetailsByAccountId,
    pendingTableRowDataMappedWithBilldId,
    billToRedeliver,
    billBeats: responseNew.data.bill_beats,
    pendingTableData
  })
  const assignmentFilter = getAssignmentFilters(responseNew.data.versions)
  const summaryData = extractSummaryData(pendingTableData, approveTableData)
  const { addedCredit, totalPendingDue } = computeAddedCreditAndPendingDue({
    dueConfig: list.due_config.config,
    bills: filteredBills,
    accounts,
    summaryData
  })
  return {
    noActionsTableData,
    approveTableData,
    pendingTableData,
    onHoldBillsData,
    rescheduledDeliveries: rescheduledTableData,
    assignmentFilter,
    summaryData,
    list,
    existingAccountIds,
    existingBills,
    beatsforNoActionTable,
    beatsforApproveTable,
    beatsforPendingActionTable,
    routesforNoActionTable,
    routesforApproveTable,
    routesforPendingActionTable,
    addedCredit,
    listPendingDue: totalPendingDue
  }
}

export function* refetchListWithIdAndPopulateStore(
  action: Action<{ listId: string; version?: CL_VERSIONS }>
) {
  yield put(setSupplyLoaders({ loaderName: 'isFetchingListData', status: true }))
  yield put(setCollectionLoaders({ loaderName: 'isFetchingListData', status: true }))
  const { listId, version: versionFromPayload } = action.payload

  const { resetSelectedRowRef, selectedAssignmentFilter: supplyAssignmentFilter } = yield select(
    (app: IAppState) => app.SupplyList
  )
  const {
    resetSelectedRowRef: resetCollectionTableSelection,
    selectedAssignmentFilter: collectionAssignmentFilter
  } = yield select((app: IAppState) => app.NewCollectionList)
  const { currentRoute: { path } = { path: '' } } = yield select((app: IAppState) => app.Navigation)

  const selectAssignmentFilterBasisRoute = () => {
    if (path.includes(newPaths.supplyList)) {
      return supplyAssignmentFilter
    }

    if (path.includes(newPaths.collectionList)) {
      return collectionAssignmentFilter
    }

    return CL_VERSIONS.CURRENT_ASSIGNMENT
  }

  const {
    ab: { okDebitOrderManagement }
  }: IExpState = yield select((app: IAppState) => app.Experiment)

  const version = getVersion(versionFromPayload, selectAssignmentFilterBasisRoute())

  const {
    noActionsTableData,
    approveTableData,
    pendingTableData,
    onHoldBillsData,
    assignmentFilter,
    summaryData,
    list,
    beatsforNoActionTable,
    beatsforApproveTable,
    beatsforPendingActionTable,
    existingBills,
    existingAccountIds,
    rescheduledDeliveries,
    addedCredit,
    listPendingDue,
    routesforNoActionTable,
    routesforApproveTable,
    routesforPendingActionTable
  }: Record<string, any> = yield fetchListWithId({ listId, version })

  let orderSettingAccountIds: string[] = []
  if (okDebitOrderManagement) {
    orderSettingAccountIds = yield fetchOrderSettingAccountIds({ listId })
  }

  if (list.usage_type === LIST_USAGE_TYPE.SUPPLY) {
    yield put(
      setSupplyListStore({
        pendingTable: pendingTableData,
        noActionsTable: noActionsTableData,
        approveTable: approveTableData,
        rescheduledDeliveries: rescheduledDeliveries,
        onHoldBillsData,
        allAssignmentFilter: assignmentFilter,
        summaryData,
        selectedList: list,
        selectedAssignmentFilter: version,
        existingAccountIds,
        existingBills,
        addedCredit,
        listPendingDue,
        orderSettingAccountIds
      })
    )

    resetSelectedRowRef.actionPendingResetRef?.current?.resetRowSelection({})
    resetSelectedRowRef.noActionResetRef?.current?.resetRowSelection({})
    resetSelectedRowRef.approveAndAddedResetRef?.current?.resetRowSelection({})
    resetSelectedRowRef.rescheduledDeliveriesRestRowRef?.current?.resetRowSelection({})

    yield put(setSupplyLoaders({ loaderName: 'isFetchingListData', status: false }))
  } else {
    yield put(
      setCollectionListStore({
        pendingTable: pendingTableData,
        noActionsTable: noActionsTableData,
        approveTable: approveTableData,
        rescheduledDeliveries: rescheduledDeliveries,
        onHoldBillsData,
        allAssignmentFilter: assignmentFilter,
        summaryData,
        selectedList: list,
        selectedAssignmentFilter: version,
        beatsforNoActionTable,
        beatsforApproveTable,
        beatsforPendingActionTable,
        existingAccountIds,
        existingBills,
        addedCredit,
        listPendingDue,
        routesforNoActionTable,
        routesforApproveTable,
        routesforPendingActionTable,
        orderSettingAccountIds
      })
    )

    resetCollectionTableSelection?.actionPendingResetRef?.current?.resetRowSelection({})
    resetCollectionTableSelection?.noActionResetRef?.current?.resetRowSelection({})
    resetCollectionTableSelection?.rescheduledDeliveriesRestRowRef?.current?.resetRowSelection({})
    resetCollectionTableSelection?.approveAndAddedResetRef?.current?.resetRowSelection({})

    yield put(setCollectionLoaders({ loaderName: 'isFetchingListData', status: false }))
  }
}
