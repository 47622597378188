import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Chip,
  CloseIcon,
  IconButton,
  Switch,
  TimesIcon,
  TitleBar,
  Typography
} from '@okcredit/web-lib'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IDefaultProps } from 'utils/common/common.types'
import { FixedHeader } from 'components/FixedHeader'
import Logo from 'assets/logo_full.svg'
import { THEME } from 'components/theme'
import { formatCurrency } from 'utils/formatCurrency'
import getWhatsappLink from 'utils/getWhatsappLink'
import ErrorGraphic from 'assets/errorGraphic.svg'
import { getLatestLoan, getRepaymentLink } from 'services/Api/LoanApi'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { LinkManager } from 'utils'
import { getDaysSince } from 'utils/time'
import { IExpState } from 'state/experiment/experiment.types'
import { IAppState } from 'infra/AppState'
import { getAbProfileV1Api } from 'services/Api/abServiceApi'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { changeUserLanguage } from 'state/auth/auth.actions'
import { DefaulterPageStyles } from './DefaulterPage.styles'
export const DEFAULT_HELP_TEXT = 'I have a question on OkLoan. Please contact me back.'
export const WHATSAPP_PHONE_NUMBER = '9916515152'

const DefaulterPage: FC<IDefaultProps> = () => {
  const classes = DefaulterPageStyles()
  const [linkError, setLinkError] = useState<string | null>(null)
  const [loanData, setLoanData] = useState<any>(null)
  const [repaymentLinkId, setRepaymentLinkId] = useState<string | null>(null)
  const {
    ab: { okTermLoanAppBlock, ready }
  }: IExpState = useSelector((app: IAppState) => app.Experiment)
  const currentBusinessId = AuthStorageInstance.getCurrentBusinessId()
  const [languageId, setLanguageId] = useState<number>(1)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (currentBusinessId) {
      getAbProfileV1Api(currentBusinessId).then((res: IApiResponse) => {
        if (
          res.type !== API_RESPONSE_TYPE.FAILURE &&
          !res.data?.profile?.features?.oktermloan_app_block
        ) {
          setLanguageId(1)
          dispatch(changeUserLanguage(1, 'Onboarding Page'))
          history.replace('/')
        }
      })
    }
  }, [currentBusinessId])

  useEffect(() => {
    if (ready && !okTermLoanAppBlock) {
      history.push('/')
    }
  }, [okTermLoanAppBlock, ready])

  useEffect(() => {
    getRepaymentLink()
      .then((response: IApiResponse) => {
        if (response.type !== API_RESPONSE_TYPE.FAILURE) {
          const { repayment_link_id: linkId } = response.data || {}
          setRepaymentLinkId(linkId)
        }
      })
      .catch((error: any) => {
        setLinkError(error)
      })
    getLatestLoan().then((response: IApiResponse) => {
      if (response.type !== API_RESPONSE_TYPE.FAILURE) {
        setLoanData({
          ...response.data,
          total_due: response.data.total_due / 100,
          late_penalties_added: response.data.late_penalties_added / 100,
          total_paid: response.data.total_paid / 100,
          late_penalties_paid: response.data.late_penalties_paid / 100
        })
      }
    })
  }, [])

  const maxAmount = useMemo((): number => {
    if (loanData) {
      const { total_due, late_penalties_added, total_paid, late_penalties_paid } = loanData
      return total_due + late_penalties_added - (total_paid + late_penalties_paid)
    }
    return 0
  }, [loanData])

  const openRepaymentWeb = useCallback(
    ({ external = false } = {}) => {
      if (repaymentLinkId) {
        window.location.href =
          LinkManager.getLoanRepaymentLink(repaymentLinkId, maxAmount) +
          (external ? '&browser=true' : '')
      }
    },
    [repaymentLinkId, maxAmount]
  )

  const makeContact = () => {
    window.location.href = getWhatsappLink(WHATSAPP_PHONE_NUMBER, DEFAULT_HELP_TEXT)
  }

  const handleSubmit = () => {
    openRepaymentWeb({ external: true })
  }

  const getDueSince = () => {
    if (loanData) {
      return getDaysSince(Number(loanData.create_time) * 1000)
    }
    return 0
  }

  const changeLanguageHandler = (langId: number) => {
    setLanguageId(langId)
    dispatch(changeUserLanguage(langId, 'Onboarding Page'))
  }

  return (
    <div className={classes.root}>
      <FixedHeader>
        <TitleBar
          startIcon={
            <IconButton onClick={() => {}}>
              <CloseIcon fill="#000" size={24} />
            </IconButton>
          }
          primary={<img src={Logo} alt="OkLoan" />}
        />
      </FixedHeader>
      <div className={classes.langContainer}>
        <Typography variant={'subtitle2'}>Select Language</Typography>
        <div>
          <Chip
            className={classes.chip}
            clickable
            onClick={() => changeLanguageHandler(1)}
            active={languageId === 1}
            label="English"
          />
          <Chip
            className={classes.chip}
            clickable
            onClick={() => changeLanguageHandler(2)}
            active={languageId === 2}
            label="हिंदी"
          />
        </div>
      </div>
      <main role="main" className={classes.main}>
        {linkError ? (
          <div className={classes.errorSection}>
            <TimesIcon className={classes.timesIcon} fill={THEME.brandRed} size={60} />
            <p>{linkError}</p>
            <Button
              className={classes.supportBtn}
              color="secondary"
              variant="outlined"
              onClick={makeContact}
            >
              Contact Support
            </Button>
          </div>
        ) : (
          <>
            <img className={classes.hentry} src={ErrorGraphic} />
            <h2 className={classes.title}>{t('dashboard:DefaulterTitle')}</h2>
            <p className={classes.desc}>
              {t('dashboard:DefaulterDesc', {
                amount: formatCurrency(maxAmount),
                daysSince: getDueSince()
              })}
            </p>
            <Button size="large" onClick={handleSubmit}>
              Pay Instalment Now
            </Button>
          </>
        )}
      </main>
    </div>
  )
}

export default DefaulterPage
