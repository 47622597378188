import React, { ReactNode, useMemo } from 'react'
import {
  LedgerIcon,
  RupeeCircleIcon,
  StatementIcon,
  HelpCircleIcon,
  CogIcon
} from '@okcredit/web-lib'
import ReconciledIcon from 'assets/icons/icon-file-tree.svg'
import { IconDoneCircle, IconReceiptWithBeta, IconTruck } from 'assets/icons'
import { newPaths } from 'routes'
import { THEME } from 'components/theme'
import { useAbExperiment } from 'utils/useAbExperiment'

type TABS = {
  icon: ReactNode
  title: string
  routeName: string
}[]

const useTabs = (): { topTabs: TABS; bottomTabs: TABS } => {
  const { billingWeb, staffLink, loading } = useAbExperiment()

  const ledgerTabItem = {
    icon: <LedgerIcon size={24} />,
    title: 'Ledger',
    routeName: newPaths.ledger
  }

  const topTabs = useMemo(() => {
    if (loading) {
      return []
    }
    const tabs: TABS = [
      ledgerTabItem,
      {
        icon: <img src={IconDoneCircle} height={24} width={24} />,
        title: 'Approve',
        routeName: newPaths.approve
      },
      {
        icon: <img src={IconTruck} height={24} width={24} />,
        title: 'Supply',
        routeName: newPaths.supplyList
      },
      {
        icon: <RupeeCircleIcon size={24} />,
        title: 'Collection',
        routeName: newPaths.collectionList
      },
      {
        icon: <StatementIcon fill="black" size={24} />,
        title: 'Summary',
        routeName: newPaths.summary
      },
      {
        icon: <img src={ReconciledIcon} height={24} width={24} />,
        title: 'Reconcile',
        routeName: newPaths.reconcile
      }
    ]

    if (staffLink) {
      tabs.shift()
      tabs.push(ledgerTabItem)
    }

    if (billingWeb) {
      tabs.push({
        icon: <img src={IconReceiptWithBeta} height={38} width={38} style={{ paddingTop: 18 }} />,
        title: 'Billing',
        routeName: newPaths.billing
      })
    }

    return tabs
  }, [billingWeb, loading, staffLink])

  const bottomTabs: TABS = [
    {
      icon: <HelpCircleIcon size={24} fill={THEME.brandBlack} />,
      title: 'Help',
      routeName: newPaths.help
    },
    {
      icon: <CogIcon size={24} fill={THEME.brandBlack} />,
      title: 'Setting',
      routeName: newPaths.settings
    }
  ]

  return {
    topTabs,
    bottomTabs
  }
}

export default useTabs
