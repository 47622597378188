import { call, put } from 'redux-saga/effects'
import dayjs from 'dayjs'
import { utils, writeFileXLSX } from 'xlsx'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { Action } from 'infra/types'
import { getCashByStaffSummary } from 'services/Api/StafflinkApi'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { setCashByStaffModalsAction } from 'state/summary/cashByStaff/cashByStaff.actions'
import { DownloadCashByStaffType, ModalNameEnum } from 'state/summary/cashByStaff/cashByStaff.types'
import {
  formatStaffSummaryDataForSheet,
  getFormattedBankDataForSheet
} from 'pages/NewDashboard/components/Summary/components/CashByStaff/formatter'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import { getBankWiseSummaryApi } from 'services/Api/StaffLinkApiFolder/api'
import { BankWiseResponse } from 'services/Api/StaffLinkApiFolder/type'

export function* downloadCashByStaffEffect(action: Action<DownloadCashByStaffType>) {
  try {
    const { fileName, filter } = action.payload
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const { startDate, startTime, endTime } = filter
    const requestPayload = {
      start_time: startTime,
      end_time: endTime,
      start_date: startDate,
      business_id: currentBusinessId
    }
    const response: IApiResponse = yield call(getCashByStaffSummary, requestPayload)
    const { items } = formatStaffSummaryDataForSheet(response.data)
    const sortedOrder = [...items]
    const ws = utils.json_to_sheet(sortedOrder, { origin: 'A6' })
    const wb = utils.book_new()
    const excelHeading = `Cash By Staff Summary (${dayjs
      .unix(filter.startTime)
      .format('DD MMM, YYYY')}) to (${dayjs.unix(filter.endTime).format('DD MMM, YYYY')})`
    utils.sheet_add_aoa(
      ws,
      [[excelHeading], [], [`Printed On: ${dayjs().format('DD MMM, YYYY')}`]],
      {
        origin: 'A1'
      }
    )

    if (items.length) {
      ws['!cols'] = getMaxColumnWidths(items)
    }

    utils.book_append_sheet(wb, ws, 'Sheet 1')
    writeFileXLSX(wb, `${fileName}.xlsx`)
    yield put(
      setCashByStaffModalsAction({
        modalName: ModalNameEnum.downloadCashByStaff,
        status: false
      })
    )
  } catch (error) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Something Went Wrong! Please try again.'
      })
    )
  }
}

export function* downloadBankWiseSummaryEffect(action: Action<DownloadCashByStaffType>) {
  const { fileName, filter } = action.payload
  const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
  const { startDate, startTime, endTime } = filter
  const requestPayload = {
    start_time: startTime,
    end_time: endTime,
    start_date: startDate,
    business_id: currentBusinessId
  }
  const response: IApiResponse<BankWiseResponse> = yield call(getBankWiseSummaryApi, requestPayload)
  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Unable to fetch data! Please try again.', {
      cause: 'customError'
    })
  }
  const items = getFormattedBankDataForSheet(response.data.staff_data)

  const ws = utils.json_to_sheet(items, { origin: 'A6' })
  const wb = utils.book_new()
  const excelHeading = `BankWise Summary (${dayjs
    .unix(filter.startTime)
    .format('DD MMM, YYYY')}) to (${dayjs.unix(filter.endTime).format('DD MMM, YYYY')})`
  utils.sheet_add_aoa(ws, [[excelHeading], [], [`Printed On: ${dayjs().format('DD MMM, YYYY')}`]], {
    origin: 'A1'
  })

  if (items.length) {
    ws['!cols'] = getMaxColumnWidths(items)
  }
  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)
  yield put(
    setCashByStaffModalsAction({
      modalName: ModalNameEnum.downloadCashByStaffBankWise,
      status: false
    })
  )
}
