import { IStaffLinkDetails, IStaffLinkState } from './StaffLink.types'

export const StaffLinkState: IStaffLinkState = {
  staffLinkDetails: {} as IStaffLinkDetails,
  versionLoader: false,
  loading: true, // Tracks the global loading state
  pageError: '', // Tracks the global error message state
  paymentStatus: '',
  isPhoneNumberSearch: false,
  searchedUser: undefined,
  isSearchingUser: false,
  searchUserError: '',
  linkId: '',
  merchantId: '',
  merchantFeatures: {},
  transactionsError: '',
  collectionSummary: {},
  isStaffBlockedToAccessList: false,
  transactionType: {
    NA: 0,
    CASH: 1,
    ONLINE: 2,
    CREDIT_DUES: 3,
    CHEQUE: 4,
    NEFT: 5,
    RETURN: 6,
    DAMAGED: 7,
    UPI: 8,
    WALLET: 9,
    QR: 10
  },
  currentTransactionType: 0,
  selectedStaffBill: undefined,
  dueConfig: undefined,
  staffDetails: {
    data: null,
    isLoading: false,
    isDrawerOpen: false
  },
  staffOrder: {
    customers: null,
    items: null,
    cartItems: [],
    loaders: {},
    selectedCustomer: null,
    selectedOrder: null,
    selectedProforma: null,
    orderHistory: {
      pending: [],
      proformaInvoiced: [],
      approved: [],
      cancelled: [],
      rejected: [],
      invoiced: []
    },
    staffPlacedOrderSuccess: false
  },
  businessDetails: {
    name: '',
    businessGst: ''
  },
  businessSettings: {
    loading: false,
    ready: false,
    bill: {
      canStaffEditRate: false
    },
    order: {
      restrictStaffLowInv: false
    }
  }
}
