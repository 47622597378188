import { call, put, select } from 'redux-saga/effects'
import { utils, writeFileXLSX } from 'xlsx'
import dayjs from 'dayjs'
import {
  getCollectionListInvoiceSummaryApi,
  getInvoiceLevelDownloadDataApi,
  getInvoiceLevelGroupSummaryDataApi,
  getSingleCollectionListInvoiceSummaryApi,
  getStatementGroupAccountsApi,
  getStatementGroupInvoicesApi,
  getStatementGroupsApi
} from 'services/Api/StafflinkApi'
import { Action } from 'infra/types'
import {
  formatForInvoiceExcelExport,
  getFormattedInvoiceLevelDownloadData,
  getMaxColumnWidths
} from 'state/statements/statements.helpers'
import { IAppState } from 'infra/AppState'
import { fetchBillsByCustomer } from 'services/Api/BillingApi'
import { SORT_ORDER_TYPE } from 'state/collectionSummary/collectionSummary.types'
import { IApiResponse } from '../../../constants'
import {
  isFetchingStatementGroupAction,
  setDownloadGroupSummaryStatusAction,
  setDownloadSummaryStatusAction,
  setGroupStatmentDownloadDrawer,
  setInnerTableSortById,
  setInvoicesData,
  setSortById,
  setSpecificGroupName,
  setSpecificGroupSummary,
  setSpecificStatementGroup,
  setSpecificTableData,
  setStatementGroupFilterAndPaginationData,
  setStatementGroupsData,
  setStatmentDownloadDrawer
} from './supply.actions'
import { FetchStatementGroupPayload, IStatementGroupTable, ResponseData } from './supply.types'
import {
  formatGroupDataHeading,
  formatInvoices,
  formatSpecificGroupsData,
  formatStatementGroupsData
} from './supply.helpers'

export function* fetchStatementGroupEffect(action: Action<FetchStatementGroupPayload>) {
  yield put(isFetchingStatementGroupAction(true))
  const {
    payload: { startTime, endTime, sortBy, sortOrder }
  } = action

  const {
    ab: { dynamicStatements }
  } = yield select((state: IAppState) => {
    return state.Experiment
  })

  const response: IApiResponse = yield call(
    dynamicStatements ? getStatementGroupsApi : getCollectionListInvoiceSummaryApi,
    {
      start_time: startTime,
      end_time: endTime,
      page: 1,
      perPage: 1000,
      ...(sortBy &&
        sortOrder && {
          sort_by: sortBy,
          sort_descending: sortOrder === SORT_ORDER_TYPE.descending
        })
    }
  )
  const statementGroupData: IStatementGroupTable[] = formatStatementGroupsData(
    response.data as ResponseData
  )

  yield put(setSortById(sortBy || '', sortOrder || null))
  yield put(setStatementGroupFilterAndPaginationData({ ...action.payload }))
  yield put(setStatementGroupsData(statementGroupData))
  yield put(isFetchingStatementGroupAction(false))
}

export function* fetchGroupDataEffect(
  action: Action<
    FetchStatementGroupPayload & { groupId: string } & { updatedGroupNameWhileEditing?: string }
  >
) {
  yield put(isFetchingStatementGroupAction(true))
  const {
    payload: {
      startTime,
      endTime,
      groupId,
      filterType,
      updatedGroupNameWhileEditing,
      sortBy,
      sortOrder
    }
  } = action

  const {
    ab: { dynamicStatements }
  } = yield select((state: IAppState) => {
    return state.Experiment
  })

  const response: IApiResponse = yield call(
    dynamicStatements ? getStatementGroupAccountsApi : getSingleCollectionListInvoiceSummaryApi,
    {
      start_time: startTime,
      end_time: endTime,
      page: 1,
      per_page: 1000,
      ...(dynamicStatements ? { group_id: groupId } : { collection_list_id: groupId }),
      ...(sortBy &&
        sortOrder && {
          sort_options: {
            sort_by: sortBy,
            sort_order: sortOrder === SORT_ORDER_TYPE.descending ? 1 : 0
          }
        })
    }
  )

  const specificGroupSummary = formatGroupDataHeading(response.data.accounts)
  const specificGroupTableData = formatSpecificGroupsData(response.data)

  if (response.data.statement_group && dynamicStatements) {
    yield put(setSpecificStatementGroup(response.data.statement_group))
  }

  const {
    currentRoute: {
      queryParams: { gn: groupName }
    }
  } = yield select((app: IAppState) => app.Navigation)

  if (!dynamicStatements) {
    yield put(
      setSpecificStatementGroup({ name: updatedGroupNameWhileEditing || groupName, id: groupId })
    )
  }

  yield put(setInnerTableSortById(sortBy || '', sortOrder || null))
  yield put(setSpecificGroupSummary(specificGroupSummary))
  yield put(setSpecificTableData(specificGroupTableData))
  yield put(setSpecificGroupName(updatedGroupNameWhileEditing || groupName))
  yield put(
    setStatementGroupFilterAndPaginationData({
      startTime,
      endTime,
      filterType
    })
  )
  yield put(isFetchingStatementGroupAction(false))
}

export function* fetchInvoicesEffect(
  action: Action<FetchStatementGroupPayload & { groupId: string; customerId: string }>
) {
  yield put(isFetchingStatementGroupAction(true))
  let response: IApiResponse
  const {
    payload: { startTime, endTime, groupId, filterType, customerId }
  } = action
  const {
    ab: { dynamicStatements }
  } = yield select((state: IAppState) => {
    return state.Experiment
  })

  if (dynamicStatements) {
    response = yield call(getStatementGroupInvoicesApi, {
      account_id: customerId,
      group_id: groupId,
      start_time: startTime,
      end_time: endTime,
      page: 1,
      per_page: 1000
    })
  } else {
    response = yield call(fetchBillsByCustomer, {
      customer_id: customerId,
      from_date: startTime,
      to_date: endTime,
      page: 1,
      per_page: 1000
    })
  }

  const formattedInvoicesData = formatInvoices(response.data?.bills)
  yield put(setInvoicesData(formattedInvoicesData))
  yield put(
    setStatementGroupFilterAndPaginationData({
      startTime,
      endTime,
      filterType
    })
  )
  yield put(isFetchingStatementGroupAction(false))
}

export function* downloadStatementEffect(
  action: Action<
    Pick<FetchStatementGroupPayload, 'startTime' | 'endTime'> & {
      fileName: string
      format: string
    } & { groupIds?: string[] }
  >
) {
  const {
    payload: { startTime, endTime, fileName, format, groupIds }
  } = action

  yield put(setDownloadSummaryStatusAction('initiated'))
  if (groupIds) {
    yield put(setDownloadGroupSummaryStatusAction('initiated'))
  }

  const {
    ab: { dynamicStatements }
  } = yield select((state: IAppState) => {
    return state.Experiment
  })

  const response: IApiResponse = yield call(
    dynamicStatements ? getInvoiceLevelGroupSummaryDataApi : getInvoiceLevelDownloadDataApi,
    {
      start_time: startTime,
      end_time: endTime,
      ...(groupIds && {
        [dynamicStatements ? 'group_ids' : 'collection_list_ids']: groupIds
      })
    }
  )

  const { summaryStats, totalInvoiceAmount, totalInvoicePaid, totalInvoiceDue } =
    getFormattedInvoiceLevelDownloadData(response?.data, dynamicStatements)
  const data = formatForInvoiceExcelExport(summaryStats, 0)
  const ws = utils.json_to_sheet(data, { origin: 'A10' })
  utils.sheet_add_aoa(
    ws,
    [
      [],
      ['Statement Created On:', dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')],
      [],
      ['Amount Payable', 'Amount Paid ', 'Amount Due'],
      [totalInvoiceAmount, totalInvoicePaid, totalInvoiceDue],
      ...(data.length === 0 ? [[], [], ['No invoices found']] : [])
    ],
    {
      origin: 'A1'
    }
  )
  const wb = utils.book_new()
  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }
  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}${format}`)

  yield put(setDownloadSummaryStatusAction('completed'))
  yield put(setStatmentDownloadDrawer(false))
  if (groupIds) {
    yield put(setDownloadGroupSummaryStatusAction('completed'))
    yield put(setGroupStatmentDownloadDrawer(false))
  }
}
