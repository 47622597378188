import { Action } from 'infra/types'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { getLanguage } from 'utils/getLanguage'
import { AuthActions } from './auth.actions'

export default function* authAnalytics(action: Action<any>): any {
  switch (action.type) {
    case AuthActions.AUTH_PAGE_LOADED:
      yield Mixpanel.trackWithPreset('View Onboarding Screen', {
        Flow: 'login'
      })
      break
    case AuthActions.ENTER_PHONE_NUMBER: {
      if (
        action.payload &&
        (action.payload.toString().length === 0 || action.payload.toString().length === 10)
      ) {
        yield Mixpanel.trackWithPreset('Mobile Number Entered', {
          Flow: 'login'
        })
      }
      break
    }
    case AuthActions.GET_OTP: {
      yield Mixpanel.trackWithPreset('Request OTP', {
        Flow: 'login',
        Type: 'Manual',
        Screen: 'Mobile Update/Mobile Screen'
      })
      break
    }
    case AuthActions.VERIFY_OTP: {
      yield Mixpanel.trackWithPreset('Login Started', {
        Flow: 'login',
        Type: 'Manual'
        // Mobile: action.payload.number
      })
      break
    }
    case AuthActions.OPEN_LANGUAGE_DROPDOWN: {
      yield Mixpanel.trackWithPreset('View Language', {
        Screen: action.payload
      })
      break
    }
    case AuthActions.CHANGE_LANGUAGE: {
      yield Mixpanel.trackWithPreset('Select Language', {
        Screen: action.payload.screen,
        SetValue: getLanguage(action.payload.languageId).key,
        List: action.payload.screen === 'Settings Page' ? false : true
      })
      if (action.payload.screen === 'Settings Page') {
        yield Mixpanel.trackWithPreset('LanguageSelectedInApp', {
          Screen: action.payload.screen,
          SetValue: getLanguage(action.payload.languageId).key,
          List: false
        })
      }
      break
    }
    case AuthActions.CLICK_TERMS: {
      yield Mixpanel.trackWithPreset('View terms', {
        Flow: 'login',
        Type: 'Privacy/terms'
      })
      break
    }
    case AuthActions.CLICK_PRIVACY: {
      yield Mixpanel.trackWithPreset('View policy', {
        Flow: 'login',
        Type: 'Privacy/terms'
      })
      break
    }
    case AuthActions.EDIT_MOBILE: {
      yield Mixpanel.trackWithPreset('Edit Mobile', {
        Flow: 'login',
        Type: 'Manual'
        // Mobile: action.payload
      })
      break
    }
    case AuthActions.VERIFY_OTP_SUCCESS: {
      yield Mixpanel.trackWithPreset('Login Success', {
        Flow: 'login',
        Type: 'SMS',
        Register: action.payload.newUser
      })
      if (action.payload.newUser) {
        yield Mixpanel.trackWithPreset('Register Successful', {
          Flow: 'login',
          Type: 'SMS'
        })
      }
      break
    }
    case AuthActions.VERIFY_OTP_FAILURE: {
      yield Mixpanel.trackWithPreset('OTP Error', {
        Flow: 'login',
        Reason: 'OTP Error',
        Type: 'Manual'
      })
      break
    }
    case AuthActions.OPEN_SIGNOUT_MODAL: {
      yield Mixpanel.trackWithPreset('Signout', {
        Screen: 'Drawer'
      })
      break
    }
    case AuthActions.SIGNOUT_FROM_ALL_DEVICES: {
      yield Mixpanel.trackWithPreset('Signout From All Devices Confirm', {
        Screen: 'Settings Page'
      })
      break
    }
    case AuthActions.SIGNOUT_FROM_ALL_DEVICES_CANCEL: {
      yield Mixpanel.trackWithPreset('Signout From All Devices Cancel', {})
      yield Mixpanel.reset()
      break
    }
    case AuthActions.SIGNOUT: {
      yield Mixpanel.trackWithPreset('Confirm Signout', {
        Screen: 'Drawer',
        Method: 'Button'
      })
      yield Mixpanel.reset()
      break
    }
    case AuthActions.SIGNOUT_CANCEL: {
      yield Mixpanel.trackWithPreset('Cancel Signout', {
        Screen: 'Drawer',
        Method: 'Button'
      })
      break
    }
    default: {
      return
    }
  }
  return
}
