import { getEnvConfig } from 'utils/getEnvConfig'
import { IApiResponse } from '../../constants'
import {
  apiRequestInterceptor,
  businessIdInterceptor,
  apiResponseSuccessInterceptor,
  apiResponseErrorInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'
import { ADD_NEW_CUSTOMER_VIA_BILL_RESPONSE } from './types'

const ADD_NEW_CUSTOMER = '/api/account2/web/v1/AddCustomer'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('WEB_BASE_URL') || ''

const api = new ApiService(endpoint, headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const addNewCustomerViaCreateBill = (payload: {
  name: string
  mobile?: string
  customerCode?: string
  gstNumber?: string
  address?: string
  email?: string
  contact_no?: string
}): Promise<IApiResponse<ADD_NEW_CUSTOMER_VIA_BILL_RESPONSE>> => {
  const requestPayload = {
    name: payload.name,
    ...(payload?.mobile && { mobile: payload.mobile }),
    ...(payload?.customerCode && { customer_code: payload.customerCode }),
    ...(payload?.gstNumber && { gstin: payload.gstNumber }),
    ...(payload?.address && { address: payload.address }),
    ...(payload?.email && { email: payload.email }),
    ...(payload?.contact_no && { contact_no: payload.contact_no })
  }
  return api.postRequest(ADD_NEW_CUSTOMER, requestPayload)
}
