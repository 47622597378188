import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import classNames from 'classnames'
import { listItemType } from '../ThreeDotsComponent/type'

const styles = createUseStyles({
  parent: {
    position: 'relative',
    cursor: 'pointer'
  },
  displayBlock: {
    display: 'block'
  },
  displayNone: {
    display: 'none'
  },
  listWrapper: {
    position: 'absolute',
    right: 0,
    backgroundColor: 'white',
    minWidth: '160px',
    width: 'max-content',
    overflow: 'auto',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    zIndex: 9999
  },
  listElement: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px',
    textDecoration: 'none',
    border: '1px solid rgb(161 156 156 / 25%)',
    cursor: 'pointer'
  },
  listContentDiv: {
    display: 'flex',
    gap: '8px',
    width: '100%',
    alignItems: 'center'
  },
  shadowDiv: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 999
  },
  titleStyle: {
    padding: '12px 16px',
    margin: 'unset'
  },
  adjustUp: {
    position: 'absolute',
    bottom: 0
  },
  listElementDisabled: {
    opacity: 0.3,
    cursor: 'not-allowed'
  }
})

const DropDownModalLayout = ({
  display,
  children,
  listItem,
  closeModal
}: {
  display: 'none' | 'block'
  children: ReactElement
  listItem: listItemType
  closeModal: any
}) => {
  const classes = styles()
  const ref = useRef(null)
  const parentRef = useRef(null)

  const [adjustUp, setAdjustUp] = useState(false)

  useEffect(() => {
    const positionDropdown = () => {
      if (display === 'none') {
        return
      }

      if (!ref?.current || !parentRef?.current) {
        return
      }

      const dropdownContent = ref.current
      const rect = (dropdownContent as any).getBoundingClientRect()
      const parentRect = (parentRef.current as any).getBoundingClientRect()
      if (parentRect.bottom + rect.height > window.innerHeight) {
        setAdjustUp(true)
      } else {
        setAdjustUp(false)
      }
    }

    positionDropdown()
  }, [display])

  const handleOnItemClick = (item: listItemType[0]) => {
    if (item.disabled) {
      return null
    }
    item.clickHandler()
  }

  return (
    <div ref={parentRef} className={cx(classes.parent, classes.displayBlock)}>
      {children}
      <div
        className={cx(classes.listWrapper, {
          [classes.displayBlock]: display === 'block',
          [classes.displayNone]: display === 'none',
          [classes.adjustUp]: adjustUp
        })}
        ref={ref}
      >
        {listItem?.map((item, index) => (
          <div
            key={`key-${index}`}
            className={classNames({
              [classes.listElement]: true,
              [classes.listElementDisabled]: item.disabled
            })}
            onClick={() => handleOnItemClick(item)}
          >
            <div className={classes.listContentDiv}>
              {item.icon}
              {item.title}
            </div>
            {item.endAdornment}
          </div>
        ))}
      </div>
      {display === 'block' && <div className={classes.shadowDiv} onClick={closeModal} />}
    </div>
  )
}

export const DropDownModalLayoutWithMultipleLists = ({
  display,
  children,
  closeModal,
  listOne,
  listTwo,
  titleStyleClass
}: {
  display: 'none' | 'block'
  children: ReactElement
  closeModal: any
  listOne: {
    listOneTitle: string
    list: listItemType
  }
  listTwo: {
    listTwoTitle: string
    list: listItemType
  }
  titleStyleClass?: any
}) => {
  const classes = styles()
  return (
    <div className={cx(classes.parent, classes.displayBlock)}>
      {children}
      <div
        className={cx(classes.listWrapper, {
          [classes.displayBlock]: display === 'block',
          [classes.displayNone]: display === 'none'
        })}
      >
        <p className={classNames(classes.titleStyle, titleStyleClass && titleStyleClass)}>
          {listOne.listOneTitle}
        </p>
        {listOne.list?.map((item, index) => (
          <div
            key={`listone_key-${index}`}
            className={classNames({
              [classes.listElement]: true,
              [classes.listElementDisabled]: item.disabled
            })}
            onClick={item.disabled ? undefined : item.clickHandler}
          >
            <div className={classes.listContentDiv}>
              {item.icon}
              {item.title}
            </div>
            {item.endAdornment}
          </div>
        ))}
        <p className={classNames(classes.titleStyle, titleStyleClass && titleStyleClass)}>
          {listTwo.listTwoTitle}
        </p>
        {listTwo.list?.map((item, index) => (
          <div
            key={`listtwo_key-${index}`}
            className={classNames({
              [classes.listElement]: true,
              [classes.listElementDisabled]: item.disabled
            })}
            onClick={item.disabled ? undefined : item.clickHandler}
          >
            <div className={classes.listContentDiv}>
              {item.icon}
              {item.title}
            </div>
            {item.endAdornment}
          </div>
        ))}
      </div>
      {display === 'block' && <div className={classes.shadowDiv} onClick={closeModal} />}
    </div>
  )
}

export default DropDownModalLayout
