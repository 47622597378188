import { CL_VERSIONS, COLLECTION_LIST_TYPE } from './collectionList.types'

export const isInvoice = (dueConfig: string | undefined) => {
  if (!dueConfig) {
    return false
  }
  const config = `${dueConfig}` as COLLECTION_LIST_TYPE
  if (
    config === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE ||
    config === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2 ||
    config === COLLECTION_LIST_TYPE.AUTO_TAG_LIST
  ) {
    return true
  }
  return false
}

export const checkIfInvoiceV2 = (collectionListType: COLLECTION_LIST_TYPE | undefined) => {
  if (!collectionListType) {
    return false
  }
  return (
    collectionListType.toString() === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2 ||
    collectionListType.toString() === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2 ||
    collectionListType.toString() === COLLECTION_LIST_TYPE.AUTO_TAG_LIST
  )
}

export const versionApiKeysMap = {
  [CL_VERSIONS.CURRENT_ASSIGNMENT]: 'current_config',
  [CL_VERSIONS.PREVIOUS_ASSIGNMENT]: 'previous_config',
  [CL_VERSIONS.PAST_ASSIGNMENT]: 'past_config'
}
