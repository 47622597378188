import { Action } from '../../infra/types'
import { BillsActions } from './bills.actions'
import { BillsState } from './bills.state'
import { IBillsState } from './bills.types'

export const BillsReducer = (state: IBillsState = BillsState, action: Action<any>): IBillsState => {
  switch (action.type) {
    case BillsActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case BillsActions.FETCH_BILLS_BY_CUSTOMER_SUCCESS: {
      const { bills, hasMore, replaceExisting } = action.payload
      return {
        ...state,
        bills: replaceExisting ? bills : [...(state.bills || []), ...bills],
        hasMore: hasMore
      }
    }
    default: {
      return state
    }
  }
}
