import { ICustomer, ISupplier } from '../dashboard.types'
import { ENTITY_TYPE } from '../../../constants'

export const applySearch = (
  searchString: string,
  selectedTab: ENTITY_TYPE,
  customers: ICustomer[] | undefined,
  suppliers: ISupplier[] | undefined
) => {
  const isCustomerTabSelected = selectedTab === ENTITY_TYPE.CUSTOMER
  const isSupplierTabSelected = selectedTab === ENTITY_TYPE.SUPPLIER
  if (
    searchString === '' ||
    (isCustomerTabSelected && (!customers || customers.length === 0)) ||
    (isSupplierTabSelected && (!suppliers || suppliers.length === 0))
  ) {
    return {
      customers,
      suppliers
    }
  }

  if (isCustomerTabSelected) {
    const filteredCustomers = customers?.filter(
      (customer: ICustomer) =>
        customer.description.toLowerCase().includes(searchString.toLowerCase()) ||
        customer.mobile.toLowerCase().includes(searchString.toLowerCase()) ||
        customer.customerCode.toLowerCase().includes(searchString.toLowerCase())
    )
    return {
      customers: filteredCustomers,
      suppliers
    }
  } else if (isSupplierTabSelected) {
    const filteredSuppliers = suppliers?.filter(
      (supplier: ISupplier) =>
        supplier.name.toLowerCase().includes(searchString.toLowerCase()) ||
        supplier.mobile.toLowerCase().includes(searchString.toLowerCase())
    )
    return {
      suppliers: filteredSuppliers,
      customers
    }
  }
  return {
    customers,
    suppliers
  }
}
