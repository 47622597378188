import { Action } from 'infra/types'
import { IRoute } from './navigation.types'

export const NavigationActions = {
  ROUTE_CHANGE: 'navigation/routeChange'
}

export const routeChange = (payload: IRoute): Action<IRoute> => ({
  type: NavigationActions.ROUTE_CHANGE,
  payload
})
