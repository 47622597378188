// TODO(v0): Remove interfaces to separate files
export type ITranslate = (x: string, y?: { [key: string]: any }) => string

export enum API_RESPONSE_TYPE {
  SUCCESS,
  FAILURE
}
export interface IApiResponse<T = any> {
  data: T
  type: API_RESPONSE_TYPE
}

export interface IUserAuthData {
  access_token: string
  refresh_token: string
  expires_in: number
  new_user: boolean
  app_lock: boolean
}

export interface ILanguageEntity {
  id: number
  language: string
  label: string
  key: string
  color?: string
  bgColor?: string
}

export const LANGUAGES: ILanguageEntity[] = [
  {
    id: 1,
    language: 'English',
    label: 'English',
    key: 'en',
    color: '#b0121a',
    bgColor: '#ffe6e6'
  },
  {
    id: 2,
    language: 'Hindi',
    label: 'हिंदी (Hindi)',
    key: 'hi',
    color: '#005fa3',
    bgColor: '#e6f4ff'
  },
  {
    id: 3,
    language: 'Hinglish',
    label: 'Hinglish',
    key: 'afh'
  },
  {
    id: 4,
    language: 'Punjabi',
    label: 'ਪੰਜਾਬੀ (Punjabi)',
    key: 'pa'
  },
  {
    id: 5,
    language: 'Marathi',
    label: 'मराठी (Marathi)',
    key: 'mr',
    color: '#3945b3',
    bgColor: '#e9f0ff'
  },
  {
    id: 6,
    language: 'Gujarati',
    label: 'ગુજરાતી (Gujarati)',
    key: 'gu',
    color: '#007a29',
    bgColor: '#e5f6eb'
  },
  {
    id: 7,
    language: 'Tamil',
    label: 'தமிழ் (Tamil)',
    key: 'ta',
    color: '#b36500',
    bgColor: '#fdf8dc'
  },
  {
    id: 8,
    language: 'Telugu',
    label: 'తెలుగు (Telugu)',
    key: 'te',
    color: '#632bb3',
    bgColor: '#f0e6ff'
  },
  {
    id: 9,
    language: 'Kannada',
    label: 'ಕನ್ನಡ (Kannada)',
    key: 'kn',
    color: '#525252',
    bgColor: '#f4f4f4'
  },
  {
    id: 10,
    language: 'Malayalam',
    label: 'മലയാളം (Malayalam)',
    key: 'ml',
    color: '#9d14b3',
    bgColor: '#fce6ff'
  },
  {
    id: 11,
    language: 'Bengali',
    label: 'বাংলা (Bengali)',
    key: 'bn',
    color: '#70493e',
    bgColor: '#f5e2dc'
  }
]

export enum ENTITY_TYPE {
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier'
}
export interface IFilterUnit {
  id: number
  label: string
  selected: boolean
}
export interface IFilterEntity {
  id: number
  label: string
  name: string
  entries: IFilterUnit[]
}

export enum FILTER_TYPES {
  SORT_FILTER = 0,
  SELECT_FILTER = 1,
  SEARCH_BY = 2
}
export enum SORT_FILTERS {
  NAME = 0,
  AMOUNT = 1,
  DUE_DATE = 2
}

export enum SEARCH_FILTER {
  CUSTOMERS = 0,
  INVOICE = 1
}

export enum SELECT_FILTER {
  DUE_TODAY = 0,
  DUE_PENDING = 1,
  DUE_UPCOMING = 2
}

export const InitialFilterValues: IFilterEntity[] = [
  {
    id: FILTER_TYPES.SORT_FILTER,
    label: 'Sort By',
    name: 'sortBy',
    entries: [
      {
        id: SORT_FILTERS.NAME,
        label: 'Name',
        selected: false
      },
      {
        id: SORT_FILTERS.AMOUNT,
        label: 'Amount',
        selected: false
      },
      {
        id: SORT_FILTERS.DUE_DATE,
        label: 'Latest',
        selected: true
      }
    ]
  }
  // {
  //   id: FILTER_TYPES.SELECT_FILTER,
  //   label: 'Filter By',
  //   entries: [
  //     {
  //       id: SELECT_FILTER.DUE_TODAY,
  //       label: 'Due Today',
  //       selected: false
  //     },
  //     {
  //       id: SELECT_FILTER.DUE_PENDING,
  //       label: 'Due Pending',
  //       selected: false
  //     },
  //     {
  //       id: SELECT_FILTER.DUE_UPCOMING,
  //       label: 'Due Upcoming',
  //       selected: false
  //     }
  //   ]
  // }
  // TODO(v1): Uncomment and use filters once apis are done!
]

export const InitialSearchByValues: IFilterEntity[] = [
  {
    id: FILTER_TYPES.SEARCH_BY,
    label: 'Search By',
    name: 'searchBy',
    entries: [
      {
        id: SEARCH_FILTER.CUSTOMERS,
        label: 'Customers',
        selected: true
      },
      {
        id: SEARCH_FILTER.INVOICE,
        label: 'Invoice',
        selected: false
      }
    ]
  }
]

export enum APP_CONSTANT {
  APP_NAME = 'okcweb-merchant'
}

export const StatesCodeMapper: Record<string, string> = {
  '01': 'JAMMU AND KASHMIR',
  '02': 'HIMACHAL PRADESH',
  '03': 'PUNJAB',
  '04': 'CHANDIGARH',
  '05': 'UTTARAKHAND',
  '06': 'HARYANA',
  '07': 'DELHI',
  '08': 'RAJASTHAN',
  '09': 'UTTAR PRADESH',
  '10': 'BIHAR',
  '11': 'SIKKIM',
  '12': 'ARUNACHAL PRADESH',
  '13': 'NAGALAND',
  '14': 'MANIPUR',
  '15': 'MIZORAM',
  '16': 'TRIPURA',
  '17': 'MEGHALAYA',
  '18': 'ASSAM',
  '19': 'WEST BENGAL',
  '20': 'JHARKHAND',
  '21': 'ORISSA',
  '22': 'CHHATTISGARH',
  '23': 'MADHYA PRADESH',
  '24': 'GUJARAT',
  '26': 'DADAR AND NAGAR HAVELI & DAMAN AND DIU',
  '27': 'MAHARASHTRA',
  '29': 'KARNATAKA',
  '30': 'GOA',
  '31': 'LAKSHADWEEP',
  '32': 'KERALA',
  '33': 'TAMIL NADU',
  '34': 'PUDUCHERRY',
  '35': 'ANDAMAN AND NICOBAR',
  '36': 'TELANGANA',
  '37': 'ANDHRA PRADESH',
  '38': 'LADAKH',
  '97': 'OTHER TERRITORY',
  '99': 'OTHER COUNTRY'
}
