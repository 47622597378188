import {
  BankStatementDocumentResponsePayload,
  BankStatementDocumentType,
  BankStatementReconTxnResponsePayload,
  BankStatementReconTxnType,
  BankStatementTemplateResponsePayload,
  BankStatementTemplateType
} from './bankStatement.types'

export const formatBankStatementTemplateData = (
  data: BankStatementTemplateResponsePayload
): BankStatementTemplateType => {
  const {
    config: { bank_statement_meta, column_mapping, sheet_details }
  } = data
  return {
    id: data.id,
    name: data.name,
    fileType: data.fileType,
    uploadType: data.upload_type,
    config: {
      bankDetails: {
        bankName: bank_statement_meta.bank_name
      },
      columnDetails: {
        bankCustomer: column_mapping.bank_customer,
        bankMode: column_mapping.bank_mode,
        bankReferenceId: column_mapping.bank_reference_id,
        date: column_mapping.date,
        depositAmount: column_mapping.deposit_amount,
        narration: column_mapping.narration
      },
      sheetDetails: {
        headerRow: sheet_details.header_row,
        sheetName: sheet_details.sheet_name
      }
    }
  }
}

export const formatBankStatementTemplatesData = (
  data: BankStatementTemplateResponsePayload[]
): BankStatementTemplateType[] => {
  return data.filter((item) => item.is_active).map(formatBankStatementTemplateData)
}

export const formatDocumentData = (
  data: BankStatementDocumentResponsePayload
): BankStatementDocumentType => {
  const { id, template_id, state, created_at, updated_at, file_name, error_meta } = data
  return {
    id,
    templateId: template_id,
    state: state,
    createdAt: created_at,
    updatedAt: updated_at,
    fileName: file_name,
    errorMeta: error_meta
      ? {
          err: error_meta.Err,
          errInRows: error_meta.ErrInRows,
          errors: error_meta.Errors,
          missingHeaders: error_meta.MissingHeaders
        }
      : null
  }
}

export const formatDocumentsData = (
  data: BankStatementDocumentResponsePayload[]
): BankStatementDocumentType[] => {
  return data.map(formatDocumentData)
}

export const formatSingleReconciledTxnData = (
  data: BankStatementReconTxnResponsePayload
): BankStatementReconTxnType => {
  return {
    id: data.id,
    date: data.date,
    bank: data.bank,
    narration: data.narration,
    referenceNumber: data.reference_number,
    amount: Number(data.amount) / 100,
    customerName: data.customer_name,
    invoiceNumber: data.invoice_number,
    collectedBy: data.collected_by,
    txnType: data.txn_type,
    isReconciled: data.is_reconciled
  }
}

export const formatReconciledTxnsData = (
  data: BankStatementReconTxnResponsePayload[]
): { reconciled: BankStatementReconTxnType[]; unReconciled: BankStatementReconTxnType[] } => {
  const reconciledTransactions: BankStatementReconTxnType[] = []
  const unReconciledTransactions: BankStatementReconTxnType[] = []
  data.forEach((transaction) => {
    const formattedTransaction = formatSingleReconciledTxnData(transaction)
    if (formattedTransaction.isReconciled) {
      reconciledTransactions.push(formattedTransaction)
    } else {
      unReconciledTransactions.push(formattedTransaction)
    }
  })
  return {
    reconciled: reconciledTransactions,
    unReconciled: unReconciledTransactions
  }
}

export const getBankStatementFiltersFromData = (
  txns: BankStatementReconTxnType[],
  isReconciled: boolean
) => {
  return {
    transactionType: isReconciled ? Array.from(new Set(txns.map((item) => item.txnType))) : [],
    bank: Array.from(new Set(txns.map((item) => item.bank)))
  }
}

export const BANK_STATEMENT_DOCUMENT_STATUS_POLL_INTERVAL = 3000
export const BANK_STATEMENT_DOCUMENT_STATUS_POLL_COUNT = 15

export const convertEpochToDate = (epochTime: number) => {
  const date = new Date(epochTime * 1000)
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const year = date.getUTCFullYear()
  return `${day}/${month}/${year}`
}

export const filterDataByDateRange = (data: any, startDate: number, endDate: number) => {
  // Convert start and end dates from Unix timestamp to Date objects
  const start = new Date(startDate * 1000)
  const end = new Date(endDate * 1000)

  // Set the time of start date to the beginning of the day
  start.setHours(0, 0, 0, 0)
  // Set the time of end date to the end of the day
  // end.setHours(23, 59, 59, 999)

  // Function to parse Unix timestamp (seconds) into a Date object
  function parseUnixTimestamp(timestamp: number) {
    return new Date(timestamp * 1000)
  }

  // Filter the data based on the date range
  return data.filter((item: any) => {
    const billDate = parseUnixTimestamp(item.bill_date)
    // Compare the date part (ignoring the time part)
    return billDate >= start && billDate <= end
  })
}
