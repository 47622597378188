import { Action } from 'infra/types'
import { CL_VERSIONS } from 'utils/common/common.types'
import { INewCollectionListState } from './collection-list.types'
import { CollectionListActions } from './collection-list.actions'

const COLLECTION_LIST_INITIAL_STATE: INewCollectionListState = {
  loaders: {
    isFetchingListData: false
  },
  collectionLists: [],
  noActionsTable: [],
  pendingTable: [],
  approveTable: [],
  rescheduledDeliveries: [],
  onHoldBillsData: [],
  allAssignmentFilter: {},
  selectedAssignmentFilter: CL_VERSIONS.CURRENT_ASSIGNMENT,
  selectedSettingsForAddTxn: null,
  showReplacementDrawer: false,
  summaryData: {
    cash: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    cheque: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    neft: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    returns: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    damages: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    others: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    qrLink: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    }
  },
  drawers: {
    addToCollectionDrawer: false,
    shiftToAnotherList: false,
    showWhatsappSuccessDrawer: false,
    autoTagListDrawer: false,
    showBeatFilterDrawer: false,
    showRouteFilterDrawer: false
  },
  beatsforNoActionTable: [],
  beatsforApproveTable: [],
  beatsforPendingActionTable: [],
  routesforNoActionTable: [],
  routesforApproveTable: [],
  routesforPendingActionTable: [],
  resetSelectedRowRef: {
    actionPendingResetRef: null,
    approveAndAddedResetRef: null,
    noActionResetRef: null,
    rescheduledDeliveriesRestRowRef: null
  },
  printRef: {
    tableRef: null
  },
  orderSettingAccountIds: []
}

// TODO: After migration, rename to CollectionListReducer
export const NewCollectionListReducer = (
  state: INewCollectionListState = COLLECTION_LIST_INITIAL_STATE,
  action: Action<any>
): INewCollectionListState => {
  switch (action.type) {
    case CollectionListActions.SET_COLLECTION_LIST: {
      return {
        ...state,
        collectionLists: action.payload
      }
    }
    case CollectionListActions.SET_COLLECTION_LIST_STORE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case CollectionListActions.SET_ON_HOLD_BILL_DATA: {
      return {
        ...state,
        onHoldBillsData: action.payload.onHoldBillsData
      }
    }
    case CollectionListActions.SET_REPLACEMENT_DRAWER_DATA: {
      return {
        ...state,
        showReplacementDrawer: action.payload.status,
        selectedReplacementData: action.payload.data
      }
    }
    case CollectionListActions.SET_SELECTED_LIST: {
      return {
        ...state,
        selectedList: action.payload
      }
    }
    case CollectionListActions.SET_TABLE_DATA: {
      const { pendingTable, noActionsTable, approveTable } = action.payload
      return {
        ...state,
        noActionsTable,
        pendingTable,
        approveTable
      }
    }
    case CollectionListActions.ADD_TXN_TO_COLLECTION: {
      return {
        ...state,
        selectedSettingsForAddTxn: action.payload,
        drawers: {
          ...state.drawers,
          addToCollectionDrawer: true
        }
      }
    }
    case CollectionListActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case CollectionListActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.loaderName]: action.payload.status
        }
      }
    }
    case CollectionListActions.SET_SUMMARY_DATA: {
      return {
        ...state,
        summaryData: action.payload
      }
    }
    case CollectionListActions.SET_ALL_ASSIGNMENT_FILTER: {
      return {
        ...state,
        allAssignmentFilter: action.payload
      }
    }
    case CollectionListActions.SET_SELECTED_ASSIGNMENT_FILTER: {
      return {
        ...state,
        selectedAssignmentFilter: action.payload
      }
    }
    case CollectionListActions.SET_BEATS_FOR_TABLE: {
      return {
        ...state,
        beatsforNoActionTable: action.payload.beatsforNoActionTable,
        beatsforApproveTable: action.payload.beatsforApproveTable,
        beatsforPendingActionTable: action.payload.beatsforPendingActionTable
      }
    }
    case CollectionListActions.SET_EXISTING_ACCOUNT_IDS_OR_BILLS: {
      return {
        ...state,
        existingAccountIds: action.payload.existingAccountIds,
        existingBills: action.payload.existingBills
      }
    }
    case CollectionListActions.SET_BEAT_FILTER_DRAWER_DATA: {
      return {
        ...state,
        beatFilterDrawerData: action.payload
      }
    }
    case CollectionListActions.RESET_BEAT_FILTER_DRAWER_DATA: {
      return {
        ...state,
        beatFilterDrawerData: null
      }
    }
    case CollectionListActions.SET_ROUTE_FILTER_DRAWER_DATA: {
      return {
        ...state,
        routeFilterDrawerData: action.payload
      }
    }
    case CollectionListActions.RESET_ROUTE_FILTER_DRAWER_DATA: {
      return {
        ...state,
        routeFilterDrawerData: null
      }
    }
    case CollectionListActions.UPDATE_ACCOUNT_ID_TO_ORDER_SETTING_LIST: {
      return {
        ...state,
        orderSettingAccountIds: action.payload.accountId
      }
    }
    default: {
      return state
    }
  }
}
