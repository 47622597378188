import { SUMMARY_FILTER_OPTION } from 'pages/CollectionSummary/CollectionSummary.constants'
import { ICollectionSummaryState } from './collectionSummary.types'

export const CollectionSummaryState: ICollectionSummaryState = {
  loaders: {
    listSummary: false,
    groupSummary: false,
    transactionDetails: false,
    allCollectionSummary: false
  },
  listViewData: null,
  activeFilter: null,
  groupViewData: null,
  selectedSummary: undefined,
  statementGroup: null,
  selectedTransaction: undefined,
  drawers: {
    transactionDetails: false,
    downloadDialogOpen: false,
    replacementDialogOpen: false,
    paymentsToTallyDrawer: false,
    creditsToTallyDrawer: false,
    purchaseSummaryDrawer: false
  },
  modals: {
    downloadSingleGroupModal: false
  },
  invoiceCollectionFilter: SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS,
  overallSummaryData: null
}
