import { Action } from 'infra/types'
import { CL_VERSIONS } from 'utils/common/common.types'
import { CollectionListActions } from 'state/new-summary/list/collection-list.actions'
import { ISupplyListState } from './supply-list.types'
import { SupplyListActions } from './supply-list.actions'

const SUPPLY_LIST_INITIAL_STATE: ISupplyListState = {
  loaders: {
    isFetchingListData: false
  },
  supplyLists: [],
  noActionsTable: [],
  pendingTable: [],
  approveTable: [],
  rescheduledDeliveries: [],
  onHoldBillsData: [],
  allAssignmentFilter: {},
  selectedAssignmentFilter: CL_VERSIONS.CURRENT_ASSIGNMENT,
  selectedSettingsForAddTxn: null,
  // showReplacementDrawer: false,
  summaryData: {
    cash: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    cheque: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    neft: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    returns: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    damages: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    others: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    },
    qrLink: {
      totalApprovedAmount: 0,
      totalPendingAmount: 0,
      transactionIdsForApproving: []
    }
  },
  drawers: {
    addToCollectionDrawer: false,
    shiftToAnotherList: false,
    showWhatsappSuccessDrawer: false
  },
  resetSelectedRowRef: {
    actionPendingResetRef: null,
    approveAndAddedResetRef: null,
    noActionResetRef: null,
    rescheduledDeliveriesRestRowRef: null
  },
  printRef: {
    tableRef: null
  },
  orderSettingAccountIds: []
}

export const SupplyListReducer = (
  state: ISupplyListState = SUPPLY_LIST_INITIAL_STATE,
  action: Action<any>
): ISupplyListState => {
  switch (action.type) {
    case SupplyListActions.SET_SUPPLY_LIST: {
      return {
        ...state,
        supplyLists: action.payload
      }
    }
    case SupplyListActions.SET_ON_HOLD_BILL_DATA: {
      return {
        ...state,
        onHoldBillsData: action.payload.onHoldBillsData
      }
    }
    case SupplyListActions.SET_SELECTED_LIST: {
      return {
        ...state,
        selectedList: action.payload
      }
    }
    case SupplyListActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.loaderName]: action.payload.status
        }
      }
    }
    case SupplyListActions.SET_SUPPLY_LIST_STORE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case SupplyListActions.SET_TABLE_DATA: {
      const { pendingTable, noActionsTable, approveTable } = action.payload
      return {
        ...state,
        noActionsTable,
        pendingTable,
        approveTable
      }
    }
    case SupplyListActions.ADD_TXN_TO_COLLECTION: {
      return {
        ...state,
        selectedSettingsForAddTxn: action.payload,
        drawers: {
          ...state.drawers,
          addToCollectionDrawer: true
        }
      }
    }
    case SupplyListActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case SupplyListActions.SET_SUMMARY_DATA: {
      return {
        ...state,
        summaryData: action.payload
      }
    }
    case SupplyListActions.SET_ALL_ASSIGNMENT_FILTER: {
      return {
        ...state,
        allAssignmentFilter: action.payload
      }
    }
    case SupplyListActions.SET_SELECTED_ASSIGNMENT_FILTER: {
      return {
        ...state,
        selectedAssignmentFilter: action.payload
      }
    }
    case SupplyListActions.SET_EXISTING_ACCOUNT_IDS_OR_BILLS: {
      return {
        ...state,
        existingAccountIds: action.payload.existingAccountIds,
        existingBills: action.payload.existingBills
      }
    }
    case CollectionListActions.UPDATE_ACCOUNT_ID_TO_ORDER_SETTING_LIST: {
      return {
        ...state,
        orderSettingAccountIds: action.payload.accountId
      }
    }
    default: {
      return state
    }
  }
}
