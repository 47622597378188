import { API_RESPONSE_TYPE } from 'shared/constants'

export const formatBusinessDetail = (data: any) => {
  return {
    id: data?.business_user?.user?.id,
    upiVpa: data?.business_user?.upi_vpa,
    profileImage: data?.business_user?.user?.profile_image,
    name: data?.business_user?.user?.display_name,
    mobile: data?.business_user?.user?.mobile,
    lang: data?.business_user?.user?.lang,
    email: data?.business_user?.user?.email,
    contactName: data?.business_user?.contact_name,
    address: data?.business_user?.user?.address?.text,
    about: data?.business_user?.user?.about,
    businessTypeId: data?.business_user?.business_type?.id,
    categoryId: data?.business_user?.business_category?.id,
    businessGst: data?.business_user?.gst_number
  }
}

export const formatBusinessDetailsResponse = (data: any) => {
  const formattedResponses: any = {}
  data.forEach((businessDetail: any) => {
    if (businessDetail.type === API_RESPONSE_TYPE.SUCCESS) {
      formattedResponses[businessDetail?.data?.business_user?.user?.id] = formatBusinessDetail(
        businessDetail?.data
      )
    }
  })
  return formattedResponses
}
