import { Action } from 'infra/types'
import { FILTER_OPTION } from 'services/Api/types'

export const beatFilterDrawerActions = {
  SET_BEAT_FILTER_DRAWER: 'beatFilterDrawer/setBeatFilterDrawer',
  SET_SELECTED_BEATS_DATA: 'beatFilterDrawer/setSelectedBeatsData',
  RESET_BEAT_FILTER: 'beatFilterDrawer/resetBeatFilter'
}

export const setBeatFilterDrawer = (payload: {
  drawerStatus: boolean
}): Action<{
  drawerStatus: boolean
}> => {
  return {
    type: beatFilterDrawerActions.SET_BEAT_FILTER_DRAWER,
    payload
  }
}

export const resetBeatFilterState = () => {
  return {
    type: beatFilterDrawerActions.RESET_BEAT_FILTER
  }
}

export const setSelectedBeatsDataAndButtonType = (payload: {
  selectedBeatsFilter: string[]
  selectedBeatsFilterType: FILTER_OPTION
  buttonType?: 'save_and_apply' | 'apply'
  drawerStatus?: boolean
}): Action<{
  selectedBeatsFilter: string[]
  selectedBeatsFilterType: FILTER_OPTION
  buttonType?: 'save_and_apply' | 'apply'
  drawerStatus?: boolean
}> => {
  return {
    type: beatFilterDrawerActions.SET_SELECTED_BEATS_DATA,
    payload
  }
}
