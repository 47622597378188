import { Action } from 'infra/types'
import { DUE_CONFIG, LIST_USAGE_TYPE } from 'services/Api/StaffLinkApiFolder/type'

export const createNewListDrawerActions = {
  SET_CREATE_NEW_LIST_DRAWER: 'createNewList/setCreateNewListDrawer',
  SET_UPDATE_LIST_DRAWER: 'createNewList/setUpdateListDrawer',
  RESET_CREATE_NEW_LIST_DRAWER: 'createNewList/resetCreateNewListState',
  SHOW_LIST_CUSTOMER_FOR_ORDER_SETTING: 'createNewList/showListCustomerForOrderSetting'
}

export const setCreateNewListDrawer = (payload: {
  drawerStatus: boolean
  newListName?: string
}): Action<{ drawerStatus: boolean; newListName?: string }> => {
  return {
    type: createNewListDrawerActions.SET_CREATE_NEW_LIST_DRAWER,
    payload
  }
}

export const resetCreateNewListState = () => {
  return {
    type: createNewListDrawerActions.RESET_CREATE_NEW_LIST_DRAWER
  }
}

export const setUpdateListDrawer = (payload: {
  drawerStatus: boolean
  existingUsageType: LIST_USAGE_TYPE
  existingListName: string
  existingListType: DUE_CONFIG
  isClearAndAdd?: boolean
}): Action<{
  drawerStatus: boolean
  existingUsageType: LIST_USAGE_TYPE
  existingListName: string
  existingListType: DUE_CONFIG
  isClearAndAdd?: boolean
}> => {
  return {
    type: createNewListDrawerActions.SET_UPDATE_LIST_DRAWER,
    payload
  }
}

export const showListCustomerForOrderSettingAction = (payload: {
  isShowCustomerListForOrderSetting: boolean
}): Action<{ isShowCustomerListForOrderSetting: boolean }> => {
  return {
    type: createNewListDrawerActions.SHOW_LIST_CUSTOMER_FOR_ORDER_SETTING,
    payload
  }
}
