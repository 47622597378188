import { API_RESPONSE_TYPE } from '../../constants'
import { Action, IOrderQRState } from './OrderQR.types'
import { OrderQRState } from './OrderQR.state'
import { OrderQRActions } from './OrderQR.actions'

export const OrderQRReducers = (
  state: IOrderQRState = OrderQRState,
  action: Action<any>
): IOrderQRState => {
  switch (action.type) {
    case OrderQRActions.GET_UPI_ID: {
      return {
        ...state,
        merchantId: action.payload.businessId
      }
    }
    case OrderQRActions.SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.payload
      }
    }
    case OrderQRActions.SET_MERCHANT_DETAILS: {
      return {
        ...state,
        merchantDetails: action.payload
      }
    }
    case OrderQRActions.UPDATE_UPI_SUCCESS: {
      return {
        ...state,
        updateUpiSuccess: true
      }
    }
    case OrderQRActions.SET_SHIPPING_ADDRESS: {
      return {
        ...state,
        shippingAddress: action.payload
      }
    }
    case OrderQRActions.SET_KYC_STATUS: {
      return {
        ...state,
        kycStatus: action.payload
      }
    }
    case OrderQRActions.SET_BANK_STATUS: {
      return {
        ...state,
        bankStatus: action.payload
      }
    }
    case OrderQRActions.FETCHED_ADDRESS: {
      return {
        ...state,
        fetchedAddress: action.payload
      }
    }
    case OrderQRActions.SET_AUTH_V3: {
      return {
        ...state,
        authV3: action.payload
      }
    }
    case OrderQRActions.SET_BACK_CLICK: {
      return {
        ...state,
        backClick: action.payload
      }
    }
    case OrderQRActions.SET_DEFAULT_LANGUAGE: {
      return {
        ...state,
        defLang: action.payload
      }
    }
    case OrderQRActions.SET_CURRENT_LIMIT: {
      return {
        ...state,
        currentLimit: action.payload
      }
    }
    case OrderQRActions.UPDATE_SHIPPING_ADDRESS: {
      return {
        ...state,
        shippingAddress: action.payload
      }
    }
    case OrderQRActions.CONFIRM_ORDER_STATUS: {
      return {
        ...state,
        orderConfirmed: action.payload
      }
    }
    case OrderQRActions.SET_MERCHANT_PROFILE_DATA: {
      return {
        ...state,
        merchantProfile: {
          ...action.payload,
          code: API_RESPONSE_TYPE.SUCCESS
        }
      }
    }
    case OrderQRActions.SET_RETRIEVE_SUBSCRIPTION: {
      return {
        ...state,
        retrieveSubscription: action.payload
      }
    }
    case OrderQRActions.SET_CREATE_SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.payload
      }
    }
    case OrderQRActions.SET_PAGE_ERROR_STATE: {
      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          error: action.payload
        }
      }
    }
    case OrderQRActions.SET_QR_VPA: {
      return {
        ...state,
        qrVpa: action.payload
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
