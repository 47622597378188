interface IStorage {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
}

export enum IStorageType {
  LOCAL_STORAGE = 'localStorage',
  SESSION_STORAGE = 'sessionStorage'
}

const getStorageObject = (storageType: IStorageType): IStorage => {
  if (typeof window !== 'undefined') {
    return window[storageType]
  }
  return null as unknown as IStorage
}

export default abstract class Storage<T extends string> {
  private readonly storage: IStorage

  public constructor(storageType: IStorageType = IStorageType.LOCAL_STORAGE) {
    this.storage = getStorageObject(storageType)
  }

  protected get(key: T): string | null {
    if (!this.storage) {
      return null
    }
    return this.storage.getItem(key)
  }

  protected set(key: T, value: string): void {
    this.storage.setItem(key, value)
  }

  protected clearItem(key: T): void {
    this.storage.removeItem(key)
  }

  protected clearItems(keys: T[]): void {
    keys.forEach((key) => this.clearItem(key))
  }
}
