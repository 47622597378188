import { Action } from 'infra/types'
import { ISummaryV2DateFilter } from 'pages/CollectionSummary/CollectionSummary.types'
import {
  CollectionSummaryModalStatus,
  IDownloadCollectionSingleSummaryPayload,
  IFetchCollectionGroupInvoiceSummaryV2ActionPayload,
  IFetchCollectionListInvoiceSummaryV2ActionPayload,
  IFetchPayload,
  IGroupViewData,
  IListViewRowData,
  IStatementGroup,
  ITransactionAction,
  ITransactionDetails,
  SORT_ORDER_TYPE
} from './collectionSummary.types'

export const CollectionSummaryActions = {
  GET_COLLECTION_LIST_INVOICE_SUMMARY_V2:
    'collectionSummary/GET_COLLECTION_LIST_INVOICE_SUMMARY_V2',
  GET_COLLECTION_LIST_INVOICE_SUMMARY_V2_SUCCESS:
    'collectionSummary/GET_COLLECTION_LIST_INVOICE_SUMMARY_V2_SUCCESS',
  SET_OVERALL_SUMMARY_DATA: 'collectionSummary/SET_OVERALL_SUMMARY_DATA',
  GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2: 'GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2',
  GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2_SUCCESS:
    'GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2_SUCCESS',

  SET_SUMMARY_ACTIVE_FILTER: 'collectionSummary/SET_SUMMARY_ACTIVE_FILTER',
  SAVE_SELECTED_SUMMARY_ROW_DATA: 'collectionSummary/SAVE_SELECTED_SUMMARY_ROW_DATA',
  FETCH_TRANSACTION_DETAILS: 'collectionSummary/FETCH_TRANSACTION_DETAILS',
  FETCH_TRANSACTION_DETAILS_SUCCESS: 'collectionSummary/FETCH_TRANSACTION_DETAILS_SUCCESS',
  SET_DRAWER: 'collectionSummary/SET_DRAWER',

  SET_LOADER: 'collectionSummary/SET_LOADER',
  SET_STATEMENT_GROUP: 'collectionSummary/SET_STATEMENT_GROUP',
  SET_SORT_BY: 'collectionSummary/SET_SORT_BY',
  SET_COLLECTION_SUMMARY_MODALS: 'collectionSummary/SET_COLLECTION_SUMMARY_MODALS',

  DOWNLOAD_COLLECTION_SINGLE_GROUP_SUMMARY:
    'collectionSummary/DOWNLOAD_COLLECTION_SINGLE_GROUP_SUMMARY'
}

export const setLoaderAction = (payload: {
  id: string
  value: boolean
}): Action<{ id: string; value: boolean }> => ({
  type: CollectionSummaryActions.SET_LOADER,
  payload
})

export const getCollectionListInvoiceSummaryV2Action = (
  payload: IFetchCollectionListInvoiceSummaryV2ActionPayload
): Action<IFetchCollectionListInvoiceSummaryV2ActionPayload> => ({
  type: CollectionSummaryActions.GET_COLLECTION_LIST_INVOICE_SUMMARY_V2,
  payload
})

export const getCollectionListInvoiceSummaryV2SuccessAction = (
  payload: IFetchPayload
): Action<IFetchPayload> => ({
  type: CollectionSummaryActions.GET_COLLECTION_LIST_INVOICE_SUMMARY_V2_SUCCESS,
  payload
})

export const setOverallSummaryData = (payload: IListViewRowData): Action<IListViewRowData> => ({
  type: CollectionSummaryActions.SET_OVERALL_SUMMARY_DATA,
  payload
})

export const setSummaryActiveFilter = (
  filter: ISummaryV2DateFilter | null
): Action<ISummaryV2DateFilter | null> => ({
  type: CollectionSummaryActions.SET_SUMMARY_ACTIVE_FILTER,
  payload: filter
})

export const setSortById = (
  sortBy: string,
  sortOrder: null | SORT_ORDER_TYPE
): Action<{
  sortBy: string
  sortOrder: null | SORT_ORDER_TYPE
}> => ({
  type: CollectionSummaryActions.SET_SORT_BY,
  payload: { sortBy, sortOrder }
})

export const getCollectionGroupInvoiceSummaryV2Action = (
  payload: IFetchCollectionGroupInvoiceSummaryV2ActionPayload
): Action<IFetchCollectionGroupInvoiceSummaryV2ActionPayload> => ({
  type: CollectionSummaryActions.GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2,
  payload
})

export const getCollectionGroupInvoiceSummaryV2ActionSuccess = (payload: {
  groupViewData: IGroupViewData
  invoiceCollectionFilter: number
  sortOptions?: any
  shortSummaryData?: any
}): Action<{
  groupViewData: IGroupViewData
  invoiceCollectionFilter: number
  sortOptions?: any
  shortSummaryData?: any
}> => ({
  type: CollectionSummaryActions.GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2_SUCCESS,
  payload
})

export const saveSelectedSummary = (data: IListViewRowData): Action<IListViewRowData> => ({
  type: CollectionSummaryActions.SAVE_SELECTED_SUMMARY_ROW_DATA,
  payload: data
})

export const setStatementGroup = (data: IStatementGroup): Action<IStatementGroup> => ({
  type: CollectionSummaryActions.SET_STATEMENT_GROUP,
  payload: data
})

export const fetchTransactionDetailsAction = ({
  accountId,
  listId,
  invoiceId,
  startTime,
  endTime
}: ITransactionAction): Action<ITransactionAction> => ({
  type: CollectionSummaryActions.FETCH_TRANSACTION_DETAILS,
  payload: {
    accountId,
    listId,
    invoiceId,
    startTime,
    endTime
  }
})

export const fetchTransactionDetailsSuccessAction = (
  payload: Array<ITransactionDetails>
): Action<Array<ITransactionDetails>> => ({
  type: CollectionSummaryActions.FETCH_TRANSACTION_DETAILS_SUCCESS,
  payload
})

export const setDrawerAction = (payload: {
  id: string
  value: boolean
}): Action<{ id: string; value: boolean }> => ({
  type: CollectionSummaryActions.SET_DRAWER,
  payload
})

export const setCollectionSummaryModalsAction = (
  payload: CollectionSummaryModalStatus
): Action<CollectionSummaryModalStatus> => ({
  type: CollectionSummaryActions.SET_COLLECTION_SUMMARY_MODALS,
  payload
})

export const downloadCollectionGroupInvoiceSummaryAction = (
  payload: IDownloadCollectionSingleSummaryPayload
): Action<IDownloadCollectionSingleSummaryPayload> => ({
  type: CollectionSummaryActions.DOWNLOAD_COLLECTION_SINGLE_GROUP_SUMMARY,
  payload
})
