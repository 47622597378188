import { Action } from 'infra/types'
import { CL_VERSIONS } from 'utils/common/common.types'

export const listActions = {
  REFETCH_LIST_AND_POPULATE_STORE: 'listAction/refetchListAndPopulateStore'
}

export const refetchListAndPopulateStore = (payload: {
  listId: string
  version?: CL_VERSIONS
}): Action<{ listId: string; version?: CL_VERSIONS }> => {
  return {
    type: listActions.REFETCH_LIST_AND_POPULATE_STORE,
    payload
  }
}
