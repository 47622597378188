import { getEnvConfig } from './getEnvConfig'

/*
 *   Handle all proxy APIs on stage(.app, .in), prod(.app, .in), alpha(.app, .in) and localhost
 *   NOTE: THIS FUNCTIONS ONLY WORKS FOR PROXY APIs
 */
export const getProxyBaseUrl = (key = '') => {
  if (typeof window !== 'undefined') {
    const { origin } = window.location

    if (origin.includes('localhost')) {
      // returns original API base URL. CORS may be required to be disabled in browser.
      return getEnvConfig(key)
      // return origin
      // eslint-disable-next-line no-else-return
    } else {
      // returns current base URL of webapp.
      return origin
    }
  }
}
