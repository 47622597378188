import dayjs, { OpUnitType, QUnitType } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export const addHours = (hours: number): number => {
  return new Date().getTime() + hours * 60 * 60 * 1000
}

export const addMinutes = (minutes: number): number => {
  return new Date().getTime() + minutes * 60000
}

export const addSeconds = (seconds: number): number => {
  const d = new Date()
  d.setSeconds(d.getSeconds() + seconds)
  return d.getTime()
}

export const getToday = () => {
  const today = new Date()
  return {
    day: today.getDate(),
    month: today.getMonth() + 1, //JS months are zero-indexed
    year: today.getFullYear()
  }
}

export const convertDayToString = (value: any) => {
  if (!value) {
    return ''
  }
  return `${value.day}/${value.month}/${value.year}`
}

export const getLastOneMonth = () => {
  dayjs.extend(customParseFormat)
  const currentDate = dayjs() // Current date and time
  const thirtyDaysAgo = currentDate.subtract(30, 'day')

  return {
    day: thirtyDaysAgo.date(),
    month: thirtyDaysAgo.month() + 1, //JS months are zero-indexed
    year: thirtyDaysAgo.year()
  }
}

export const formatDateToString = (timeStamp: Date, weekdayType: 'short' | 'long' = 'long') => {
  try {
    const newDate = new Date(timeStamp).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: weekdayType
    })
    const [weekday, monthAndDay, year] = newDate.split(', ')
    const [month, day] = monthAndDay.split(' ')
    const returnData = `${weekday}, ${day} ${month} ${year.trim()}`
    return returnData
  } catch (error) {
    return ''
  }
}

/**
 * Get time difference between two days in relative time format
 * @param d1
 * @param d2
 * @returns number
 */
export const getTimeDiff = (d1: any, d2: any, unit: QUnitType | OpUnitType = 'day'): number => {
  const x = dayjs(d1)
  const y = dayjs(d2)
  return y.diff(x, unit)
}

/**
 * Get the number of days that have passed since the loan
 * was created.
 * @param createdAt Loan Start Date
 * @returns number
 */
export const getDaysSince = (createdAt: any): number => {
  const diff = getTimeDiff(new Date(createdAt), new Date())
  return Math.abs(diff)
}

export const isWithin24Hours = (timestamp: string | number) => {
  // Convert timestamp to milliseconds
  const timeInMs = Number(timestamp) * 1000

  // Create a dayjs object from milliseconds
  const targetTime = dayjs(timeInMs)

  const now = dayjs()
  const diff = now.diff(targetTime, 'millisecond')

  return Math.abs(diff) <= 24 * 60 * 60 * 1000
}
