import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import classNames from 'classnames'
import { Spinner } from '@okcredit/web-lib'
import { IAppState } from 'infra/AppState'
import { IconArrowDropWithBg } from 'assets/icons'
import { THEME } from 'components/theme'
import { getIsAdmin } from 'utils/common/common.helpers'
import BodyHeader from './BodyHeader'
import BusinessList from './BusinessList'
import NewBusinessInput from './NewBusinessInput'

import { BusinessSelectorStyles } from './styles'

const BusinessSelector = () => {
  const classes = BusinessSelectorStyles()
  const history = useHistory()
  const location = useLocation()
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    setIsAdmin(getIsAdmin())
  }, [])

  const { merchantProfile, businessAccounts } = useSelector((app: IAppState) => app.Dashboard)

  const isDrawerOpen = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('selectBusiness') === 'true'
  }, [location.search])

  const handleDrawerClose = () => history.push({ search: '' })
  const handleDrawerOpen = () => history.push({ search: '?selectBusiness=true' })

  const handleToggleDrawer = () => {
    if (isDrawerOpen) {
      handleDrawerClose()
    } else {
      handleDrawerOpen()
    }
  }

  const stopPropgartion = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <div className={classes.container}>
      {merchantProfile?.data?.name ? (
        <h4 className={classes.selectorHeader} onClick={handleToggleDrawer}>
          {merchantProfile.data.name}
          <img
            src={IconArrowDropWithBg}
            alt="Arrow Drop"
            height="32px"
            width="32px"
            className={classNames(classes.selectorIcon, {
              [classes.selectorIconOpen]: isDrawerOpen
            })}
          />
        </h4>
      ) : null}
      {isDrawerOpen ? (
        <div className={classes.selectorContainerOverlay} onClick={handleDrawerClose}>
          <div className={classes.selectorContainer} onClick={stopPropgartion}>
            <BodyHeader />
            {isAdmin ? (
              <>
                <BusinessList />
                <NewBusinessInput />
              </>
            ) : null}
          </div>
        </div>
      ) : null}
      {businessAccounts?.loading && (
        <div className={classes.overlay}>
          <Spinner size={48} className={classes.spinner} color={THEME.brandGreen} />
        </div>
      )}
    </div>
  )
}

export default BusinessSelector
