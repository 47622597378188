export interface ITag {
  business_id: string
  create_time: string
  id: string
  is_active: boolean
  name: string
  update_time: string
}

export interface IAccountTag {
  account_id: string
  business_id: string
  create_time: string
  is_active: boolean
  tag_id: string
  update_time: string
}

export interface IInvoiceTag {
  bill_id: string
  account_id: string
  account_transaction_id: string
  tag_id: string
  tag_name: string
  business_id: string
  is_active: boolean
  create_time: string
  update_time: string
}

export interface ITags {
  allIds: string[]
  byIds: {
    [id: string]: ITag
  }
  pagination?: {
    page: number
    perPage: number
    total: number
  }
}

export interface IDrawers {
  invoiceTagSelection?: boolean
}
export interface ITagsState {
  loading: boolean
  accountTags: IAccountTag[]
  normalisedTags: ITags
  currentInvoiceTags?: IInvoiceTag[]
  showEditTagDialog: boolean
  drawers: {
    invoiceTagSelection: boolean
  }
}

export enum TAG_SELECTION_TYPE {
  AND = 'AND',
  OR = 'OR'
}

export enum TAG_FILTER_TYPE {
  AND = 'tagsAnd',
  OR = 'tagsOr'
}

export enum INVOICE_TAG_ACTION_TYPE {
  LINK = 'link',
  DELINK = 'de_link'
}

export type IlinkDelinkInvoiceTagPayload = {
  account_id: string
  bill_tags_actions: Array<{
    action: INVOICE_TAG_ACTION_TYPE
    account_transaction_id?: string
    bill_id?: string
    tag_ids: string[]
  }>
}

export type linkDelinkAccountTagsPayload = {
  action: 'link' | 'de_link'
  account_ids: string[]
  tag_ids: string[]
}[]
