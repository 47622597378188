export const ItemType = {
  sale: {
    name: 'Sales',
    id: 'sale'
  },
  purchase: {
    name: 'Purchase',
    id: 'purchase'
  }
}

export type ItemTypeKey = keyof typeof ItemType

export const PAGE_LIMIT = 15
