import { Action } from 'infra/types'
import {
  IAgentDashboardMetaData,
  ICheckData,
  IDailySummaryResponse,
  IOnboardedMerchant,
  IOnboardFormData,
  IOnboardFormResponseData,
  IOnboardingListRequestParams,
  IRevisitFormData,
  OnboardedMerchantStatus
} from './agent-dashboard.types'

export const AgentDashboardActions = {
  // Agent Dashboard Actions
  GET_AGENT_DASHBOARD_META: 'agentDashboard/getAgentDashboardMeta',
  GET_AGENT_DASHBOARD_META_SUCCESS: 'agentDashboard/getAgentDashboardMetaSuccess',

  // Onboarding Form Actions
  POST_ONBOARDING_DATA: 'agentDashboard/postOnboardingData',
  POST_ONBOARDING_DATA_SUCCESS: 'agentDashboard/postOnboardingDataSuccess',
  POST_ONBOARDING_FORM_LOADING: 'agentDashboard/postOnboardingFormLoading',
  CLEAR_ONBOARDING_FORM_LOADING: 'agentDashboard/clearOnboardingFormData',

  // Fetching the checklist
  GET_CHECKLIST_DATA: 'agentDashboard/getChecklistData',
  GET_CHECKLIST_DATA_SUCCESS: 'agentDashboard/getChecklistDataSuccess',

  // paginated merchants list
  GET_ONBOARDED_MERCHANTS_LIST: 'agentDashboard/getOnboardedMerchantsList',
  GET_ONBOARDED_MERCHANTS_LIST_PAGES: 'agentDashboard/getOnboardedMerchantsListPage',
  GET_ONBOARDED_MERCHANTS_LIST_SUCCESS: 'agentDashboard/getOnboardedMerchantsListSuccess',
  CLEAR_ONBOARDED_MERCHANTS_LIST: 'agentDashboard/clearOnboardedMerchantsList',

  // revisit form post
  POST_REVISIT_FORM_DATA: 'agentDashboard/postRevisitFormData',
  POST_REVISIT_FORM_DATA_SUCCESS: 'agentDashboard/postRevisitFormDataSuccess',
  CLEAR_REVISIT_FORM_LOADING: 'agentDashboard/clearRevisitFormData',

  // daily summary data
  GET_DAILY_SUMMARY_DATA: 'agentDashboard/getDailySummaryData',
  GET_DAILY_SUMMARY_DATA_SUCCESS: 'agentDashboard/getDailySummaryDataSuccess',

  //filters
  SET_REVISIT_FILTER: 'agentDashboard/setRevisitFilter',
  SET_STATUS_FILTER: 'agentDashboard/setStatusFilter'
}

export const getOnboardingMetaData = (): Action<null> => ({
  type: AgentDashboardActions.GET_AGENT_DASHBOARD_META,
  payload: null
})

export const getOnboardingMetaDataSuccess = (
  payload: IAgentDashboardMetaData
): Action<IAgentDashboardMetaData> => ({
  type: AgentDashboardActions.GET_AGENT_DASHBOARD_META_SUCCESS,
  payload
})

export const addOnboardingDataForCustomer = (
  payload: IOnboardFormData
): Action<IOnboardFormData> => ({
  type: AgentDashboardActions.POST_ONBOARDING_DATA,
  payload
})

export const addOnboardingDataForCustomerSuccess = (
  payload: IOnboardFormResponseData
): Action<IOnboardFormResponseData> => ({
  type: AgentDashboardActions.POST_ONBOARDING_DATA_SUCCESS,
  payload
})

export const onboardFormLoadingAction = (payload: boolean): Action<boolean> => ({
  type: AgentDashboardActions.POST_ONBOARDING_FORM_LOADING,
  payload
})

export const fetchChecklistAction = (payload: string): Action<string> => ({
  type: AgentDashboardActions.GET_CHECKLIST_DATA,
  payload
})

export const fetchChecklistSuccessAction = (
  type: string,
  list: Array<ICheckData>
): Action<Record<string, Array<ICheckData>>> => ({
  type: AgentDashboardActions.GET_CHECKLIST_DATA_SUCCESS,
  payload: { [type]: list }
})

export const fetchOnboardedMerchantsListAction = (
  page: number,
  size: number = 20,
  revisit_status = 0,
  category = 0
): Action<IOnboardingListRequestParams> => ({
  type: AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST,
  payload: {
    page,
    size,
    revisit_status,
    category
  }
})

export const fetchOnboardedMerchantsListPageAction = (
  page: number,
  size: number = 20,
  revisit_status = 0,
  category = 0
): Action<IOnboardingListRequestParams> => ({
  type: AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST_PAGES,
  payload: {
    page,
    size,
    revisit_status,
    category
  }
})

export const fetchOnboardedMerchantsListSuccessAction = (
  list: Array<IOnboardedMerchant>,
  totalCount: number,
  page: number = 1
): Action<{
  onboardings: Array<IOnboardedMerchant>
  totalCount: number
  page: number
}> => ({
  type: AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST_SUCCESS,
  payload: {
    onboardings: list,
    totalCount,
    page
  }
})

export const fetchDailySummaryAction = (): Action<null> => ({
  type: AgentDashboardActions.GET_DAILY_SUMMARY_DATA,
  payload: null
})

export const fetchDailySummarySuccessAction = (
  payload: IDailySummaryResponse
): Action<IDailySummaryResponse> => ({
  type: AgentDashboardActions.GET_DAILY_SUMMARY_DATA_SUCCESS,
  payload
})

export const addRevisitDataAction = (payload: IRevisitFormData): Action<IRevisitFormData> => ({
  type: AgentDashboardActions.POST_REVISIT_FORM_DATA,
  payload
})

export const addRevisitDataSuccessAction = (payload: string): Action<string> => ({
  type: AgentDashboardActions.POST_REVISIT_FORM_DATA_SUCCESS,
  payload
})

export const clearOnboardedMerchantsList = (): Action<null> => ({
  type: AgentDashboardActions.CLEAR_ONBOARDED_MERCHANTS_LIST,
  payload: null
})

export const setRevisitFilterAction = (payload: boolean): Action<boolean> => ({
  type: AgentDashboardActions.SET_REVISIT_FILTER,
  payload
})

export const setStatusFilterAction = (
  payload: 0 | OnboardedMerchantStatus
): Action<0 | OnboardedMerchantStatus> => ({
  type: AgentDashboardActions.SET_STATUS_FILTER,
  payload
})

export const clearOnboardingFormDataAction = (): Action<null> => ({
  type: AgentDashboardActions.CLEAR_ONBOARDING_FORM_LOADING,
  payload: null
})

export const clearRevisitFormDataAction = (): Action<null> => ({
  type: AgentDashboardActions.CLEAR_REVISIT_FORM_LOADING,
  payload: null
})
