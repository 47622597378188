import { Action } from 'infra/types'
import { DUE_CONFIG } from 'services/Api/StaffLinkApiFolder/type'

export const accountantSummaryActions = {
  SET_DRAWER: 'accountantSummary/setDrawers',
  SET_LIST_DETAILS: 'accountantSummary/setListDetails',
  RESET_ACCOUNTANT_SUMMARY_STATE: 'accountantSummary/resetAccountantSummaryState'
}

export const setAccountantSummaryDrawers = (payload: {
  drawerName: 'showSummaryDrawer'
  status: boolean
}): Action<{
  drawerName: 'showSummaryDrawer'
  status: boolean
}> => ({
  type: accountantSummaryActions.SET_DRAWER,
  payload
})

export const setListDetails = (payload: {
  listId: string
  listType: DUE_CONFIG
  showSummaryDrawerStatus: boolean
}): Action<{
  listId: string
  listType: DUE_CONFIG
  showSummaryDrawerStatus: boolean
}> => ({
  type: accountantSummaryActions.SET_LIST_DETAILS,
  payload
})

export const resetAccountantSummaryState = (): Action<null> => ({
  type: accountantSummaryActions.RESET_ACCOUNTANT_SUMMARY_STATE,
  payload: null
})
