import { COLLECTION_FILTER_TYPE } from 'pages/NewDashboard/components/Summary/components/CollectionContainer/CollectionHeader/constants'

// https://okcredit.atlassian.net/wiki/spaces/CL/blog/2023/10/17/2363654145/Cheque+Management+Requirement+Terminologies+Concepts+and+Flow+Diagram
export enum ChequeState {
  UNKNOWN = 0,
  COLLECTED = 1,
  RECEIVED = 2, // 'Collected Cheque' in UI
  DEPOSITED = 3, // 'Deposited to Bank' in UI
  CLEARED = 4, // 'Received Cash' in UI
  BOUNCED = 5,
  DELETED = 6
}

export type AllChequeType =
  | 'unknownCheque'
  | 'receivedCheque'
  | 'depositedCheque'
  | 'collectedCheque'
  | 'clearedCheque'
  | 'bouncedCheque'
  | 'deleteCheque'

export type ChequeType = Extract<
  AllChequeType,
  'receivedCheque' | 'depositedCheque' | 'clearedCheque'
>

export interface IFetchChequeRequestPayload {
  page: number
  per_page: number
  filters: {
    cheque_state?: ChequeState
    collected_on_interval?: {
      start_time: number
      end_time: number
    }
    byChequeDate?: number
  }
  sort_options?: {
    sort_by: string
    sort_order: 1
  }
}

export interface BillInfo {
  bill_id: string
  bill_number: string
  bill_date: string
  customer_id: string
  transaction_id: string
  total_amount: string
  status: number
  bill_info: null | any
  due_date: string
  created_at: string
  updated_at: string
  total_paid_amount: string
  deleted_at: string
  latest_settlement_date: string
}

interface AccountInfo {
  account_id: string
  name: string
}

export interface ChequeDetail {
  cheque_id: string
  cheque_number: string
  cheque_date: string
  collected_on: string
  amount: string
  state: ChequeState
  bills: BillInfo[]
  account_info: AccountInfo
}

interface PaginationDetails {
  page: string
  limit: string
  total_items: string
  total_pages: string
  per_page: string
}

export interface IFetchChequeResponsePayload {
  cheque_details: ChequeDetail[]
  pagination_details: PaginationDetails
}

export type PaginationType = {
  pageSize: number
  currentPage: number
  totalEntries: number
}

interface BaseActionPayload {
  filterTime: ChequeFilterTime
  chequeState?: ChequeState
}

export interface IFetchReceivedActionPayload {
  searchString?: string
  sortOptions?: {
    sortBy: string
    sortOrder: 1
  }
  byChequeDate?: ChequeFilterTime
  page?: number
  perPage?: number
}

export interface IFetchOtherActionPayload extends BaseActionPayload {
  chequeState: ChequeState.DEPOSITED | ChequeState.CLEARED
  searchString?: string
  sortOptions?: {
    sortBy: string
    sortOrder: 1
  }
  byChequeDate?: ChequeFilterTime
  page?: number
  perPage?: number
}

export type Cheque = {
  chequeId: string
  customerName: string
  invoiceNumber: string
  invoiceDate: string
  collectedOn: string
  chequeDate: string
  chequeNo: string
  amount: number
}

export interface IChequeState {
  receivedCheque: Cheque[]
  depositedCheque: Cheque[]
  clearedCheque: Cheque[]
  filterTime?: ChequeFilterTime
  receivedByChequeDateFilter?: ChequeFilterTime
  depositedByChequeDateFilter?: ChequeFilterTime
  clearedByChequeDateFilter?: ChequeFilterTime
  resetSelectedRowRef: {
    receivedCheque: RowSelectionType
    otherCheque: RowSelectionType
  }
  receivedChequeSortedBy: string
  depositedChequeSortedBy: string
  clearedChequeSortedBy: string
  editChequeDrawer: {
    showDrawer: boolean
    chequeDetails: any | null
  }
  pagination: {
    receivedCheque: PaginationType
    depositedCheque: PaginationType
    clearedCheque: PaginationType
  }
}

type RowSelectionType =
  | React.MutableRefObject<
      | {
          resetRowSelection: (_: any) => void
        }
      | undefined
      | null
    >
  | undefined
  | null

export type ChequeFilterTime = {
  startTime: number
  endTime: number
  filterType?: COLLECTION_FILTER_TYPE
}

export type UpdateChequeDetailsType = {
  cheque_id: string
  cheque_date: number | string
  collected_on: number | string
  amount: number
  cheque_number?: string
}

export type UpdateChequeRequestPayload = {
  cheque_details: UpdateChequeDetailsType[]
}
