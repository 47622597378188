import { IApprovedCollectionSummaryState } from './approvedCollectionSummary.types'

export const ApprovedCollectionSummaryState: IApprovedCollectionSummaryState = {
  loaders: {
    listSummary: false,
    groupSummary: false
  },
  listViewData: null,
  activeFilter: null,
  overallSummaryData: null,
  groupViewData: null,
  drawers: {
    downloadDialog: false
  }
}
