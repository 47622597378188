import { Action } from 'infra/types'
import { IDrawers, IInvoiceBeat } from './beats.types'

export const BeatsActions = {
  FETCH_BEATS: 'beats/fetchBeats',
  FETCH_BEATS_SUCCESS: 'beats/fetchBeatsSuccess',

  CREATE_BEAT_ROUTE: 'beats/createBeat',
  CREATE_BEAT_SUCCESS: 'beats/createBeatSuccess',
  CREATE_BEAT_FAILURE: 'beats/createBeatFailure',

  FETCH_INVOICE_BEATS: 'beats/fetchInvoiceBeats',
  FETCH_INVOICE_BEATS_SUCCESS: 'beats/fetchInvoiceBeatsSuccess',

  LINK_DELINK_INVOICE_BEATS: 'beats/linkDelinkInvoiceBeats',
  LINK_DELINK_INVOICE_BEATS_SUCCESS: 'beats/linkDelinkInvoiceBeatsSuccess',

  HANDLE_DRAWER_TOGGLE: 'beats/handleDrawerToggle'
}

export const getBeats = (): Action<null> => ({
  type: BeatsActions.FETCH_BEATS,
  payload: null
})

export const getBeatsSuccess = (payload: any): Action<any> => ({
  type: BeatsActions.FETCH_BEATS_SUCCESS,
  payload
})

export const createBeatRoute = (payload: {
  beats?: string[]
  routes?: string[]
}): Action<{ beats?: string[]; routes?: string[] }> => ({
  type: BeatsActions.CREATE_BEAT_ROUTE,
  payload
})

export const createBeatSuccess = (payload: any): Action<any> => ({
  type: BeatsActions.CREATE_BEAT_SUCCESS,
  payload
})

export const createBeatFailure = (): Action<null> => ({
  type: BeatsActions.CREATE_BEAT_FAILURE,
  payload: null
})

export const getInvoiceBeats = (payload: {
  transactionId: string
  accountId: string
}): Action<{ transactionId: string; accountId: string }> => ({
  type: BeatsActions.FETCH_INVOICE_BEATS,
  payload
})

export const getInvoiceBeatsSuccess = (payload: IInvoiceBeat[]): Action<IInvoiceBeat[]> => ({
  type: BeatsActions.FETCH_INVOICE_BEATS_SUCCESS,
  payload
})

export const linkDelinkInvoiceBeats = (payload: any): Action<any> => ({
  type: BeatsActions.LINK_DELINK_INVOICE_BEATS,
  payload
})

export const linkDelinkInvoiceBeatsSuccess = (payload: IInvoiceBeat[]): Action<IInvoiceBeat[]> => ({
  type: BeatsActions.LINK_DELINK_INVOICE_BEATS_SUCCESS,
  payload
})

export const handleDrawerToggle = (payload: IDrawers): Action<IDrawers> => ({
  type: BeatsActions.HANDLE_DRAWER_TOGGLE,
  payload
})
