import { THEME } from 'components/theme'

export default {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1000,

    ['& > first-child']: {
      zIndex: 1100
    }
  },
  main: {
    backgroundColor: THEME.brandWhite,
    minWidth: '100px',
    height: '100%',
    overflowY: 'scroll',
    width: '30%',
    boxShadow: '0 8px 8px rgba(0,0,0,0.5)',
    transition: 'transform 0.5s',
    willChange: 'transform',
    position: 'fixed',
    zIndex: 1000
  },
  left: {
    marginRight: 'auto',
    left: '0px'
  },
  right: {
    marginLeft: 'auto',
    right: '0px'
  },
  isOpen: {
    transform: 'translateX(0px)'
  },
  slideFromLeft: {
    transform: 'translateX(-100%)'
  },
  slideFromRight: {
    transform: 'translateX(100%)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '22px',
    color: THEME.brandBlack,
    borderBottom: `1px solid ${THEME.brandLightGrey}`,
    backgroundColor: THEME.brandWhite
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginLeft: '12px'
  },
  closeIcon: {
    cursor: 'pointer'
  }
}
