import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { IAppState } from 'infra/AppState'
import {
  fetchBusinessCategoriesAction,
  fetchBusinessIdsAction,
  fetchBusinessSettingsAction,
  fetchBusinessTypesAction,
  fetchEntityList,
  fetchHelpSections,
  fetchLocalData
} from 'state/dashboard/dashboard.actions'
import { getAccountTags, getTags } from 'state/tags/tags.actions'
import { useAbExperiment } from 'utils/useAbExperiment'
import { ENTITY_TYPE } from 'shared/constants'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { newPaths } from 'routes'
import { getBeats } from 'state/beats/beats.actions'

export const useHeader = () => {
  const { loading, ready, billingWeb, staffLink } = useAbExperiment()

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const dashboardState = useSelector((app: IAppState) => app.Dashboard)

  useEffect(() => {
    dispatch(fetchLocalData())
    if (dashboardState?.businessAccounts?.businessIds?.length === 0) {
      dispatch(fetchBusinessIdsAction({ isSigningIn: false }))
    }
    dispatch(fetchBusinessCategoriesAction())
    dispatch(fetchBusinessTypesAction())
    dispatch(getTags())
    dispatch(getBeats())
    dispatch(getAccountTags())
    dispatch(fetchEntityList(ENTITY_TYPE.CUSTOMER))
    dispatch(fetchEntityList(ENTITY_TYPE.SUPPLIER))
  }, [])

  useEffect(() => {
    if (!loading && ready) {
      dispatch(pushEvent(NEWDASHBOARD_EVENTS.PAGE_LOADED))
      dispatch(fetchBusinessSettingsAction())
    }

    if (location.pathname.includes('billing') && !billingWeb && ready) {
      history.push(staffLink ? newPaths.approve : newPaths.ledger)
      return
    }
  }, [loading, ready, billingWeb])

  useEffect(() => {
    if (dashboardState?.merchantProfile?.data?.id) {
      dispatch(fetchHelpSections(dashboardState.merchantProfile.data.id))
    }
  }, [dashboardState?.merchantProfile?.data?.id])

  return {
    loading,
    ready
  }
}
