import { ENTITY_TYPE, InitialFilterValues, InitialSearchByValues } from 'shared/constants'
import { CustomerProfileBulkUpdateEntity, IDashboardState } from './dashboard.types'

export const DashboardState: IDashboardState = {
  entityAction: {
    loading: false
  },
  customers: {
    byIds: {},
    allIds: [],
    loading: false
  },
  suppliers: {
    byIds: {},
    allIds: [],
    loading: false
  },
  businessSettings: {},
  merchantRegistration: {
    loading: false,
    show: false,
    optedOut: false
  },
  merchantProfile: {
    loading: false
  },
  businessTypes: [],
  businessCategories: [],
  filters: InitialFilterValues,
  currentTab: ENTITY_TYPE.CUSTOMER,
  searchString: '',
  phonebookContacts: {
    loading: false,
    data: []
  },
  helpSections: {
    loading: false,
    data: []
  },
  currentCustomer: null,
  showLeadgenBanner: false,
  customersByTags: undefined,
  tagsFilter: InitialFilterValues,
  searchFilters: InitialSearchByValues,
  billsFiltered: undefined,
  businessAccounts: {
    businessIds: [],
    asStaffBusinessIds: [],
    loading: false,
    drawers: {
      newBusinessName: false
    }
  },
  accountsDocument: null,
  loaders: {
    bulkUploadCustomers: false,
    downloadCustomerProfiles: false
  },
  paidSoonBanner: {
    isShow: false,
    feedback: '',
    isDone: false
  },
  businessManagers: [],
  customerProfiles: {
    customers: [],
    paginationData: {
      page: 1,
      per_page: 50,
      total_items: 0
    },
    resetSelectedRowRef: {
      customersTable: null
    },
    drawers: {
      [CustomerProfileBulkUpdateEntity.tag]: {
        status: false,
        replace: false
      },
      [CustomerProfileBulkUpdateEntity.beat]: {
        status: false,
        replace: false
      },
      [CustomerProfileBulkUpdateEntity.route]: {
        status: false,
        replace: false
      }
    }
  }
}
