import { Action } from 'infra/types'
import { applyTagDrawerActions } from 'state/new-summary/root-drawers/apply-tags/apply-tags.actions'
import { IApplyTagState } from 'state/new-summary/root-drawers/apply-tags/apply-tags.types'

const TAG_FILTER: IApplyTagState = {
  drawers: {
    showApplyTagDrawer: false
  },
  handleSaveCallback: undefined
}

export const ApplyTagsReducer = (
  state: IApplyTagState = TAG_FILTER,
  action: Action<any>
): IApplyTagState => {
  switch (action.type) {
    case applyTagDrawerActions.SET_APPLY_TAG_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showApplyTagDrawer: action.payload.drawerStatus
        }
      }
    }
    case applyTagDrawerActions.SET_HANDLE_SAVE_CALLBACK_DRAWER: {
      return {
        ...state,
        handleSaveCallback: action.payload.handleSaveCallback,
        handleBackArrowClick: action.payload.handleBackArrowClick
      }
    }
    case applyTagDrawerActions.RESET_APPLY_TAG_DRAWER: {
      return TAG_FILTER
    }
    default: {
      return state
    }
  }
}
