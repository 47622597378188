import { ICollectionList } from 'state/collectionList/collectionList.types'
import { DOCUMENT_TYPE_VS_LABEL } from 'pages/NewDashboard/components/CommonDrawers/UploadSheetDrawers/UploadSupplySheet/components/DocumentFormatConfigure/DocumentFormatConfigure.helpers'
import { DOCUMENT_TYPE, TemplateResponseType } from './uploadSheet.types'

export const getFormattedGlobalTemplates = (globalTemplates: TemplateResponseType[]) => {
  return globalTemplates.map((template) => ({
    ...template,
    id: template.name,
    name: `Default ${
      DOCUMENT_TYPE_VS_LABEL[template.document_type as DOCUMENT_TYPE.EXCEL | DOCUMENT_TYPE.CSV]
    } Template`,
    isGlobal: true
  }))
}

export const getFormattedCurrentTemplates = (
  globalTemplates: TemplateResponseType[],
  templates: TemplateResponseType[]
) => {
  return [...globalTemplates, ...templates]
    .filter((template) => template.is_default)
    .map((template) => ({
      id: template.id,
      document_type: template.document_type,
      name: template.name
    }))
}

export const getDefaultCollectionListName = (collectionList: ICollectionList[]) => {
  const collectionListNames = collectionList?.map((itm) => itm?.name?.toLocaleLowerCase())
  let listLength = collectionList.length
  let listName = `list ${listLength}`

  while (collectionListNames?.includes(listName)) {
    listName = `list ${listLength}`
    listLength += 1
  }
  return listName
}
