/* eslint-disable security/detect-object-injection */
import { Action } from 'infra/types'
import { ExpState } from './experiment.state'
import { IExpState } from './experiment.types'
import { ExpActions } from './experiment.action'

export const ExpReducers = (state: IExpState = ExpState, action: Action<any>): IExpState => {
  switch (action.type) {
    case ExpActions.GET_AB_PROFILE_REQUEST: {
      return {
        ...state,
        ab: {
          ...state.ab,
          loading: true,
          ready: false
        }
      }
    }
    case ExpActions.GET_AB_PROFILE_SUCCESS: {
      return {
        ...state,
        ab: {
          ...state.ab,
          ...action.payload.feature,
          loading: false,
          ready: true
        },
        experiment: {
          ...state.experiment,
          ...action.payload.experiment
        }
      }
    }
    case ExpActions.GET_AB_PROFILE_FAIL: {
      return {
        ...state,
        ab: {
          ...state.ab,
          loading: false,
          error: action.payload,
          ready: true
        }
      }
    }

    default: {
      return state
    }
  }
}
