import { Action } from '../../infra/types'
import { CollectionSummaryActions } from './collectionSummary.actions'
import { CollectionSummaryState } from './collectionSummary.state'
import { ICollectionSummaryState } from './collectionSummary.types'

export const CollectionSummaryReducer = (
  state: ICollectionSummaryState = CollectionSummaryState,
  action: Action<any>
): ICollectionSummaryState => {
  switch (action.type) {
    case CollectionSummaryActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case CollectionSummaryActions.SET_SORT_BY: {
      const { sortBy, sortOrder } = action.payload
      return {
        ...state,
        sortBy,
        sortOrder
      }
    }
    case CollectionSummaryActions.SET_OVERALL_SUMMARY_DATA: {
      return {
        ...state,
        overallSummaryData: action.payload
      }
    }
    case CollectionSummaryActions.GET_COLLECTION_LIST_INVOICE_SUMMARY_V2_SUCCESS: {
      return {
        ...state,
        listViewData: action.payload.data,
        selectedSummary: action.payload.shortSummaryData,
        invoiceCollectionFilter: action.payload.invoiceCollectionFilter
      }
    }
    case CollectionSummaryActions.GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2_SUCCESS: {
      return {
        ...state,
        groupViewData: action.payload.groupViewData,
        invoiceCollectionFilter: action.payload.invoiceCollectionFilter,
        innerTableSortOption: action.payload.sortOptions,
        selectedSummary: action.payload.shortSummaryData
      }
    }
    case CollectionSummaryActions.SET_SUMMARY_ACTIVE_FILTER: {
      return {
        ...state,
        activeFilter: action.payload
      }
    }
    case CollectionSummaryActions.SAVE_SELECTED_SUMMARY_ROW_DATA: {
      return {
        ...state,
        selectedSummary: action.payload
      }
    }
    case CollectionSummaryActions.SET_STATEMENT_GROUP: {
      return {
        ...state,
        statementGroup: action.payload
      }
    }
    case CollectionSummaryActions.FETCH_TRANSACTION_DETAILS_SUCCESS: {
      return {
        ...state,
        selectedTransaction: action.payload
      }
    }
    case CollectionSummaryActions.FETCH_TRANSACTION_DETAILS: {
      return {
        ...state,
        selectedTransaction: undefined
      }
    }
    case CollectionSummaryActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case CollectionSummaryActions.SET_COLLECTION_SUMMARY_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, [action.payload.modalName]: action.payload.status }
      }
    }
    default: {
      return state
    }
  }
}
