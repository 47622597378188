import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { matchPath, useRouteMatch } from 'react-router'
import { routeChange } from 'state/navigation/navigation.actions'
import { broadcastEvent } from 'state/dashboard/dashboard.actions'
import { AuthSessionStorageInstance } from 'services/Storage/AuthSessionStorage'
import { paths, blindpayPaths, newPaths } from 'routes'

const _useQuery = (location: any) => {
  return parse(location.search)
}

const _getPathName = (currentPath: string) => {
  return (
    Object.keys({ ...paths, ...blindpayPaths, ...newPaths }).find((key) => {
      return matchPath(currentPath, {
        path: ({ ...paths, ...blindpayPaths, ...newPaths } as any)[key],
        exact: true
      })
    }) || ''
  )
}

export const NavigationWatcher: React.FC = () => {
  const location = useLocation()
  const path = location.pathname
  const pathName = _getPathName(path)
  const queryParams = _useQuery(location)
  const match = useRouteMatch({
    path: ({ ...paths, ...blindpayPaths, ...newPaths } as any)[pathName],
    exact: true
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      routeChange({
        path,
        navParams: match?.params || {},
        queryParams,
        pathName
      })
    )
  }, [location])

  useEffect(() => {
    const hasSessionStarted: boolean = AuthSessionStorageInstance.getSessionStarted()
    if (!hasSessionStarted) {
      dispatch(
        broadcastEvent('Mobile Site Open', {
          route: path
        })
      )
      AuthSessionStorageInstance.setSessionStarted(true)
    }
  }, [])

  return null
}
