import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const TemplateItemStyles = createUseStyles({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: THEME.brandBlack,
    padding: '16px 8px',
    fontSize: 14,
    borderBottom: `solid 1px ${THEME.brandGreyPrimary}`,
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none'
    },
    ['&:hover']: {
      background: THEME.brandMedLightGrey
    }
  },
  extension: {
    color: THEME.brandDarkerGrey
  }
})
