import { call, put } from 'redux-saga/effects'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { Action } from 'infra/types'
import { addCustomerDetails, patchCustomer } from 'services/Api/MerchantApi'
import { updateEntity } from 'state/dashboard/dashboard.actions'
import { getFormattedCustomerProfile } from 'state/dashboard/helpers/CustomerSupplierProfile'
import { setCustomerDetailsFromDrawer } from 'state/billing/billing.actions'
import { addNewCustomerViaCreateBill } from 'services/Api/AddCustomerViaCreateBillApi'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { IApiResponse, API_RESPONSE_TYPE } from '../../../../constants'
import { CustomerDetailsActionPayload } from './create-edit-customer.types'
import {
  setCreateCustomerLoader,
  setCreateEditCustomerDrawers
} from './create-edit-customer.actions'

export function* createUpdateCustomer(
  action: Action<{
    customerDetails: CustomerDetailsActionPayload
    customerId?: string
  }>
) {
  try {
    const {
      payload: { customerDetails, customerId }
    } = action
    const updatedExistingCustomer = !!customerId
    yield put(setCreateCustomerLoader({ loaderName: 'isCreatingCustomer', status: true }))

    let response: IApiResponse
    if (updatedExistingCustomer) {
      response = yield call(patchCustomer, customerId, customerDetails)
    } else {
      response = yield call(addNewCustomerViaCreateBill, customerDetails)
    }

    if (response?.type === API_RESPONSE_TYPE.FAILURE) {
      throw new Error('Something went wrong, Please retry !!!', {
        cause: 'customError'
      })
    }

    const formattedCustomerData = getFormattedCustomerProfile(
      response.data.customer || response.data
    )

    yield put(
      setCustomerDetailsFromDrawer({
        customerId: formattedCustomerData.id,
        customerName: formattedCustomerData.description,
        customerMobile: formattedCustomerData.mobile,
        customerAddress: formattedCustomerData.address,
        customerGST: formattedCustomerData.customerGST,
        customerCode: formattedCustomerData.customerCode
      })
    )

    yield put(updateEntity(formattedCustomerData))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.SUCCESS,
        bodyText: 'Customer Created Successfully'
      })
    )
    yield put(pushEvent(NEWDASHBOARD_EVENTS.WEB_BILLS_NEW_CUSTOMER_CREATED))
    yield put(
      pushEvent(NEWDASHBOARD_EVENTS.WEB_BILLS_CUSTOMER_ADDED, {
        source: 'manual',
        name: formattedCustomerData.description,
        address: formattedCustomerData.address,
        gst: formattedCustomerData.customerGST,
        id: formattedCustomerData.id,
        code: formattedCustomerData.customerCode
      })
    )
    yield put(setCreateCustomerLoader({ loaderName: 'isCreatingCustomer', status: false }))
    yield put(
      setCreateEditCustomerDrawers({ drawerName: 'showCreateEditCustomerDrawer', status: false })
    )
  } catch (error) {
    yield put(setCreateCustomerLoader({ loaderName: 'isCreatingCustomer', status: false }))
    throw new Error('Something went wrong, Please retry !!!', {
      cause: 'customError'
    })
  }
}
