import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IAppState } from 'infra/AppState'

let intervalId: NodeJS.Timeout

export const useWaitForMerchantId = () => {
  const [merchantId, setMerchantId] = useState<string | null>(null)
  const [individualUserId, setIndividualUserId] = useState<string | null>(null)
  const dashboardState = useSelector((state: IAppState) => state.Dashboard)
  const merchantProfileId = dashboardState.merchantProfile?.data?.id
  const individualUserIdState = dashboardState.businessAccounts?.individualUser?.id

  useEffect(() => {
    intervalId = setInterval(() => {
      if (merchantProfileId && individualUserIdState) {
        setMerchantId(merchantProfileId)
        setIndividualUserId(individualUserIdState)
        clearInterval(intervalId)
      }
    }, 300)
    return () => {
      clearInterval(intervalId)
    }
  }, [merchantProfileId, individualUserIdState])

  return { merchantId, individualUserId }
}
