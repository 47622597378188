import React, { useRef, useState } from 'react'
import { CaretRightIcon, DocIcon, UploadIcon } from '@okcredit/web-lib'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'

import { THEME } from 'components/theme'
import { listItemType } from 'pages/CommonSummary/components/ThreeDotsComponent/type'
import {
  handleDrawerToggle,
  saveFileHtmlData,
  saveRawFile
} from 'state/new-summary/uploadSheet/uploadSheet.actions'
import { getAsByteArray } from 'utils/fileUpload'
import { uploadStaffLinkFile } from 'services/Api/growthExperimentApi'
import { getDocumentTypeFromExtension } from 'pages/CollectionList/components/UploadedDocumentSection/UploadedDocumentSection.helpers'
import { IAppState } from 'infra/AppState'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { useAbExperiment } from 'utils/useAbExperiment'

const useInfoList = () => {
  const dispatch = useDispatch()
  const dialogRef = useRef<{ resetDialog: () => void }>(null)
  const [file, setFile] = useState<File | null>(null)
  const merchantProfile = useSelector((state: IAppState) => state.Dashboard.merchantProfile)
  const { uploadTempFile } = useSelector((app: IAppState) => app.RootDrawers.UploadSheet)
  const { previewUpload } = useAbExperiment()
  const merchantId = merchantProfile.data?.id

  const handleOpenTemplates = () => {
    dialogRef?.current?.resetDialog()
    dispatch(pushEvent(NEWDASHBOARD_EVENTS.SELECT_TEMPLATE))
    dispatch(handleDrawerToggle({ documentListConfiguration: true }))
  }

  const handleViewUploadedFiles = () => {
    dialogRef?.current?.resetDialog()
    dispatch(pushEvent(NEWDASHBOARD_EVENTS.VIEW_UPLOADED_FILES))
    dispatch(handleDrawerToggle({ uploadedFiles: true }))
  }

  const listItems: listItemType = [
    {
      icon: <DocIcon size={24} width={24} />,
      title: 'Manage Templates',
      endAdornment: <CaretRightIcon fill={THEME.brandGreen} size={24} />,
      clickHandler: handleOpenTemplates
    },
    {
      icon: <UploadIcon size={24} width={24} fill={THEME.brandBlack} />,
      title: 'View Uploaded Files',
      endAdornment: <CaretRightIcon fill={THEME.brandGreen} size={24} />,
      clickHandler: handleViewUploadedFiles
    }
  ]

  const previewUploadedFiles = async (e: any) => {
    try {
      if (!e?.target?.files?.length) {
        return null
      }
      const fileTemp = e.target.files[0]
      setFile(fileTemp)

      const reader = new FileReader()
      reader.onload = (event: any) => {
        const data = new Uint8Array(event.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]

        const jsonData: string[] = XLSX.utils.sheet_to_json(worksheet)

        dispatch(saveFileHtmlData(jsonData))
      }
      reader.readAsArrayBuffer(fileTemp)
      dispatch(handleDrawerToggle({ uploadedFilesPreview: true }))
    } catch (error) {
      dispatch(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Something went wrong in uploading files, Please retry'
        })
      )
    }
  }

  const handleUploadDocument = async (e: any) => {
    try {
      if (!e?.target?.files?.length) {
        return null
      }
      const fileTemp = e.target.files[0]
      setFile(fileTemp)
      if (previewUpload) {
        dispatch(saveRawFile(fileTemp))
        previewUploadedFiles(e)
        return null
      }
      const byteFile: Uint8Array = await getAsByteArray(fileTemp)
      const array = []
      for (let i = 0; i < byteFile.byteLength; i++) {
        array[i] = byteFile[i]
      }
      const documentExtension = fileTemp?.name?.split('.')?.pop() || 'xlsx'
      const { data } = await uploadStaffLinkFile({
        document: array,
        document_type: getDocumentTypeFromExtension(documentExtension),
        document_extension: documentExtension,
        business_id: merchantId as string,
        file_name: fileTemp.name
      })
      if (data?.response?.status >= 400) {
        setFile(null)
        return null
      }
      dispatch(pushEvent(NEWDASHBOARD_EVENTS.UPLOAD_SUPPLY_SHEET_SUCCESS))
      dispatch(handleDrawerToggle({ uploadedFiles: true }))
    } catch (error) {
      dispatch(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Something went wrong in uploading files, Please retry'
        })
      )
    }
  }

  const handelSaveFile = async () => {
    try {
      if (uploadTempFile === null || uploadTempFile === undefined) {
        return null
      }
      const byteFile: Uint8Array = await getAsByteArray(uploadTempFile)
      const array = []
      for (let i = 0; i < byteFile.byteLength; i++) {
        array[i] = byteFile[i]
      }
      const documentExtension = uploadTempFile?.name?.split('.')?.pop() || 'xlsx'
      const { data } = await uploadStaffLinkFile({
        document: array,
        document_type: getDocumentTypeFromExtension(documentExtension),
        document_extension: documentExtension,
        business_id: merchantId as string,
        file_name: uploadTempFile.name
      })
      if (data?.response?.status >= 400) {
        setFile(null)
        return null
      }
      dispatch(pushEvent(NEWDASHBOARD_EVENTS.UPLOAD_SUPPLY_SHEET_SUCCESS))
      dispatch(handleDrawerToggle({ uploadedFilesPreview: false }))
      dispatch(handleDrawerToggle({ uploadedFiles: true }))
    } catch (error) {
      dispatch(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Something went wrong in uploading files, Please retry'
        })
      )
    }
  }

  return {
    listItems,
    dialogRef,
    handleUploadDocument,
    file,
    handelSaveFile
  }
}

export default useInfoList
