import { takeCustomLatest } from 'state/common/common.effects'
import { createUpdateCustomer } from './create-edit-customer.effect'
import { createEditCustomerActions, setCreateCustomerLoader } from './create-edit-customer.actions'

function* createCustomerSaga() {
  yield takeCustomLatest(
    createEditCustomerActions.CREATE_UPDATE_CUSTOMER,
    createUpdateCustomer,
    () => {
      setCreateCustomerLoader({ loaderName: 'isCreatingCustomer', status: false })
    }
  )
}

export const CreateCustomerWatchers = [createCustomerSaga()]
