import { ITaxComponents } from 'experiments/StaffLink/StaffLink.types'
import { TransactionTypeEnum } from 'pages/NewDashboard/components/ApproveRoutes/components/OrderPending/components/Drawers/OrderCollectionDrawer/AddToTransectionDrawer.types'
import {
  SelectedChargeType,
  SelectedDiscountType
} from 'pages/NewDashboard/components/Billing/hooks/constant'
import { IUnitOffer } from 'services/Api/BillingApiFolder.ts/type'
import {
  BillCharges,
  BillDiscounts,
  BillInfoItemType,
  CustomerDetails,
  InvoiceDetails,
  ItemsDataType,
  NewItemDetailEntity
} from 'state/billing/billing.types'

export enum OrderedByType {
  staff = 'staff',
  customer = 'customer'
}

export interface OrderItems {
  itemId: string
  catalogueItemId: string
  inventoryItemId: string
  quantity: number
  createTime: number
  updateTime: number
  amount: number
  proformaInvoicedQuantity?: number
  invoicedQuantity?: number
  info: {
    name: string
    rate: number
    quantity: number
    tax: number
    amount: number
    catalogItemId: string
    discount: number
    taxComponents: ITaxComponents[]
    unitOffer: IUnitOffer
  } | null
}

export interface IPendingOrdersRowData {
  orderId: string
  orderedByType: UserType
  orderedBy: string
  customerName: string
  customerBalance?: number | null
  orderDate: number
  orderAmount: number
  id: string
  items: OrderItems[]
  customerId: string
  accountId: string
  proformaIds?: string[]
}

export interface OrdersRowData {
  orderId: string
  orderNumber: string
  orderedByType: UserType
  orderedBy: string
  customerName: string
  customerBalance?: number | null
  customer: CustomerDetails
  orderDate: number
  orderAmount: number
  id: string
  items: OrderItems[]
  customerId: string
  accountId: string
  proformaIds: string[]
  updateTime: number
}

export interface ProformaRowData {
  proformaId: string
  proformaAmount: number
  proformaDate: number
  buyerId: string
  buyerInfo: {
    name: string
    address?: string
    gstNumber?: string
    mobile?: string
  }
  buyerType: BUYER_TYPE
  referenceId: string
  referenceSource: string
  sellerId: string
  sellerInfo: {
    name: string
  }
  sellerType: SELLER_TYPE
  status: PROFORMA_STATUS
  proformaItems: ItemsDataType[]
  orderDetails: OrdersRowData
  proformaNumber: string
  selectedDiscounts: SelectedDiscountType[]
  selectedCharges: SelectedChargeType[]
}

export enum PENDING_ORDERS_FILTER {
  ALL = 'All',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM = 'Custom',
  DEFAULT = 'All'
}

export enum SORT_OPTION {
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  ORDER_DATE = 'ORDER_DATE'
}

type RowSelectionType = React.MutableRefObject<
  | {
      resetRowSelection: (_: any) => void
    }
  | undefined
  | null
> | null

export enum ORDERS_ENTITY {
  INVOICE = 'invoice',
  PROFORMA = 'proforma',
  PROFORMA_INVOICED = 'proforma_invoiced',
  NONE = ''
}

export interface IOrdersState {
  pendingOrdersFilter: PendingOrdersFilterType
  approvalOrdersFilter: PendingOrdersFilterType
  proformaOrdersFilter: PendingOrdersFilterType
  pendingOrdersRowData: OrdersRowData[]
  approvalOrdersRowData: OrdersRowData[]
  proformaInvoicesRowData: ProformaRowData[]
  pageSize: number
  currentPage: number
  totalEntries: number
  sortOption: SORT_OPTION
  resetSelectedRowRef: {
    ordersPendingTable: RowSelectionType
    ordersApprovalTable: RowSelectionType
    proformaInvoicesTable: RowSelectionType
  }
  selectedOrderIds: Array<string>
  selectedApprovedOrderIds: Array<string>
  selectedProformaOrderIds: Array<string>
  showPendingOrdersApproveBtn: boolean
  showApprovalOrdersApproveBtn: boolean
  proformaInvoicesApproveBtn: boolean
  drawers: {
    orderPendingFilter: boolean
    orderApprovalFilter: boolean
    rejectOrder: boolean
    rejectMultipleOrders: boolean
    showOrderItemsPreview: boolean
    orderCollectionDrawer: boolean
    deleteProforma: boolean
    deleteMultipleProformas: boolean
  }
  loaders: {
    isFetchingOrders: boolean
    isFetchingApprovedOrders: boolean
    isFetchingSelectedCatalogue: boolean
    isFetchingProforma: boolean
    isCreatingBill: boolean
  }
  isCreateBillFromOrders: boolean
  isCreateProformaFromOrder: boolean
  isCreateBillFromProforma: boolean
  selectedOrderDetails: OrdersRowData | null
  searchString: string
  addedItems: Array<OrderItem['addedItemsItem']>
  customerDetails: CustomerDetails
  invoiceDetails: InvoiceDetails
  discountAmount: number
  selectedMultipleOrders: FetchMultipleCatalogueItems | null
  payableAmount: number
  selectedDiscounts: SelectedDiscountType[]
  selectedCharges: SelectedChargeType[]
  selectedItemIndices: number[]
  activeOrdersEntity: ORDERS_ENTITY
  selectedProformaInvoices: ProformaRowData[]
  proformasHtmls: string[]
  ordersHtml: string[]
}

export type PendingOrdersFilterType = {
  startTime: number
  endTime: number
  filterType: PENDING_ORDERS_FILTER
}

export const ORDERS_DEFAULT_TIME_VALUE_FOR_ALL = 0

export interface FetchOrdersPayload extends PendingOrdersFilterType {
  searchString?: string
  sortOption?: SORT_OPTION
  statuses?: Array<OrderStatus>
  staffIds?: Array<string>
}

export enum OrderStatus {
  DEFAULT = 0,
  PENDING = 1,
  APPROVED = 2,
  PROFORMA_CREATED = 3,
  INVOICED = 4,
  CANCELLED = 5,
  REJECTED = 6,
  OUT_FOR_DELIVERY = 7,
  COMPLETED = 8
}

export enum UserType {
  UNKNOWN = 0,
  STAFF = 1,
  CUSTOMER = 2,
  ACCOUNTANT = 3
}

export const OrderedByMapper = {
  0: 'Unknown',
  1: 'Staff',
  2: 'Customer',
  3: 'Accountant'
}

export interface ItemsResponse {
  amount: number
  catalog_item_id: string
  create_time: number
  update_time: number
  inventory_item_id: string
  proforma_invoiced_quantity: number
  invoiced_quantity: number
  info: {
    name: string
    rate: number
    quantity: number
    tax: number
    amount: number
    catalog_item_id: string
    discount: number
    tax_components: ITaxComponents[]
    unit_offer: IUnitOffer
  }
  quantity: number
}

export interface OrdersResponse {
  id: string
  business_id: string
  account_id: string
  created_by_id: string
  created_by_type: UserType
  amount: number
  inventory_items: Array<ItemsResponse>
  create_time: number
  update_time: number
  proforma_ids: Array<string>
  order_number: string
}

export enum BUYER_TYPE {
  BUYER_ACCOUNT = 'BUYER_ACCOUNT'
}

export enum SELLER_TYPE {
  SELLER_BUSINESS = 'SELLER_BUSINESS'
}

export enum PROFORMA_STATUS {
  PROFORMA_UNKNOWN = 0,
  PROFORMA_ACTIVE = 1,
  PROFORMA_PARTIALLY_PAID = 2,
  PROFORMA_PAID = 3,
  PROFORMA_STATUS_BILL_CREATED = 4,
  PROFORMA_CANCELLED = 5
}

export interface ProformaResponse {
  buyer_id: string
  proforma_number: string
  buyer_info: {
    name: string
    mobile: string
    gst_number: string
    address: string
  }
  buyer_type: BUYER_TYPE
  proforma_id: string
  reference_id: string
  reference_source: string
  seller_id: string
  seller_info: {
    name: string
  }
  seller_type: SELLER_TYPE
  proforma_amount: number
  status: PROFORMA_STATUS
  proforma_date: number
  proforma_info: {
    items: Array<any>
    discounts?: Array<BillDiscounts>
    add_charges?: Array<BillCharges>
  }
}

export interface FetchOrdersApiResponse {
  orders: Array<OrdersResponse>
  proforma: Array<ProformaResponse>
}

export enum OrderApiActionEnum {
  status = 'status',
  items = 'items'
}

export interface UpdateOrderPayload {
  action: OrderApiActionEnum
  order: {
    status?: OrderStatus
    id: string
    bills?: {
      billId: string
    }
    shouldPrintBill?: boolean
    inventory_items?: BillInfoItemType[]
  }
  fetchStatuses?: OrderStatus[] // To fetch orders of status
}

export interface CreateInvoicePayload {
  action: OrderApiActionEnum
  order: {
    status: OrderStatus
    id: string
    bills?: {
      billId: string
    }
    shouldPrintBill?: boolean
    proformaId?: string
  }
}

export interface UpdateMultipleOrderPayload {
  updatePayload: {
    action: OrderApiActionEnum.status
    order: {
      status?: OrderStatus
      id: string
      inventory_items?: BillInfoItemType[]
    }
  }[]
  fetchStatuses?: OrderStatus[] // To fetch orders of status
}

export interface OrderItem {
  addedItemsItem: NewItemDetailEntity & {
    amountPayable: number
    unit?: string
    inventoryItemId?: string
    proformaInvoicedQuantity?: number
    invoicedQuantity?: number
    sku?: string
    catalogueItemId: string
  }
}

export interface FetchMultipleCatalogueItems {
  items: Array<OrdersRowData>
  source?: 'multiple' | 'single' | 'approved' | 'proforma'
}

export const enum OrderCollectionTypeEnum {
  CASH = 'CASH',
  ONLINE = 'ONLINE',
  CHEQUE = 'CHEQUE',
  NEFT = 'NEFT',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
  WALLET = 'WALLET'
}

export interface SettlementPayload {
  proformaId: string
  settlementType: OrderCollectionTypeEnum
  amount: number
  settlementDate: number
  chequeNumber?: string
  chequeDate?: number
}

export const TransactionTypeEnumMapper = {
  [OrderCollectionTypeEnum.UNKNOWN]: 0,
  [OrderCollectionTypeEnum.CASH]: 1,
  [OrderCollectionTypeEnum.ONLINE]: 2,
  [OrderCollectionTypeEnum.CHEQUE]: 3,
  [OrderCollectionTypeEnum.NEFT]: 4,
  [OrderCollectionTypeEnum.OTHER]: 5,
  [OrderCollectionTypeEnum.WALLET]: 6
}

export interface UpdateProformaStatusPayload {
  proformaId: string
  status: PROFORMA_STATUS
  billId?: string
}
