import { COLLECTION_FILTER_TYPE } from 'pages/NewDashboard/components/Summary/components/CollectionContainer/CollectionHeader/constants'

export interface IBankStatementState {
  templates: BankStatementTemplateType[]
  documents: BankStatementDocumentType[]
  pollingDocument: BankStatementDocumentType | null
  transactions: {
    reconciledTransactions: BankStatementReconTxnType[]
    unReconciledTransactions: BankStatementReconTxnType[]
    filters: BankStatementFilter
    selectedFilters: BankStatementFilter
  }
  loaders: ILoaders
  drawers: IDrawers
  filterTime?: FilterTime
  editAssetId: string
  depositHistory: IDepositHistory
  cashDepositHistory: ICashDepositHistory[]
  cashDepositApiCallAndResponse?: ICashDepositApiCallAndResponse
  bankHistoryType: IBankHistoryType
  bankHistoryImage: string
  downloadStatementPopup: boolean
  uploadedBankStatementData: IUploadedBankStatementData[]
  transactionData: ITransactionData[]
  uploadedFileDate: IUploadedFileDate
  computedBankTransactionAction: IComputedBankTransactionAction
  transactionOpeningBalance: number
  transactionClosingBalance: number
}

export interface IComputedBankTransactionAction {
  common: []
  unmatchedLedger: []
  unmatchedBank: []
  bankStatementClosingBalance: number
}

export interface IUploadedFileDate {
  startTime: number
  endTime: number
}

export interface ITransactionData {
  id: string
  account_id: string
  type: string
  amount: string
  note: string
  bill_date: string
  bill_number: string
  images: any[]
}

export interface IUploadedBankStatementData {
  amount: string
  notes: string
  type: string
  bill_date: string
  balance?: string
}
export enum IBankHistoryType {
  CASH = 'cash',
  BANK = 'bank'
}

export interface ICashDepositHistory {
  id: string
  business_id: string
  amount: number
  source_account_id: string
  source_type: string
  bank_account_id: string
  depositor_type: string
  depositor_name: string
  depositor_id: string
  approved: boolean
  create_time: number
  bank_name: string
  account_number: string
  ifsc: string
  receipt_url: string
  deposit_date: number
  notes: string
}

export interface ICashDepositApiCallAndResponse {
  isApiCall: boolean
  isAllDataIsFetched: boolean
  isApiError: boolean
}

export interface IDepositHistory {
  limit: number
  startTime: number
  endTime: number
  offset: number
}

export type FilterTime = {
  startTime?: number
  endTime?: number
  filterType?: COLLECTION_FILTER_TYPE
}

export enum FileType {
  FT_UNKNOWN = 0,
  XLS = 1,
  XLSX = 2,
  CSV = 3
}

export enum BankNameType {
  HDFC = 'HDFC',
  ICICI = 'ICICI',
  SBI = 'SBI',
  KOTAK = 'KOTAK',
  AXIS = 'AXIS',
  PNB = 'PNB',
  BOB = 'BOB'
}

export enum UploadType {
  UT_UNKNOWN = 0,
  BANK_STATEMENT = 1
}

export type BankStatementDocumentResponsePayload = {
  id: string
  business_id: string
  template_id: string
  state: BankStatementDocumentState
  created_at: number
  updated_at: number
  is_active: boolean
  file_name: string
  error_meta: {
    Err: any
    ErrInRows: any
    Errors: any
    MissingHeaders: any
  }
}

export type BankStatementDocumentType = {
  id: string
  templateId: string
  state: BankStatementDocumentState
  createdAt: number
  updatedAt: number
  fileName: string
  errorMeta: {
    err: any
    errInRows: any
    errors: any
    missingHeaders: any
  } | null
}

export type CreateBankStatementTemplatePayloadType = {
  template: Pick<
    BankStatementTemplateResponsePayload,
    'business_id' | 'fileType' | 'upload_type' | 'config'
  >
}

export type UpdateBankStatementTemplatePayloadType = {
  template: Pick<
    BankStatementTemplateResponsePayload,
    'id' | 'business_id' | 'fileType' | 'upload_type' | 'config'
  >
}

export type BankStatementTemplateResponsePayload = {
  id: string
  business_id: string
  name: string
  fileType: FileType
  upload_type: UploadType
  config: {
    bank_statement_meta: {
      bank_name: BankNameType
    }
    column_mapping: {
      bank_customer: string
      bank_mode: string
      bank_reference_id: string
      date: string
      deposit_amount: string
      narration: string
    }
    sheet_details: {
      header_row: number
      sheet_name: string
    }
  }
  created_at: string
  updated_at: string
  is_active: boolean
}

export type BankStatementTemplateType = {
  id: string
  name: string
  fileType: FileType
  uploadType: UploadType
  config: {
    bankDetails: {
      bankName: BankNameType
    }
    sheetDetails: {
      sheetName: string
      headerRow: number
    }
    columnDetails: {
      narration: string
      date: string
      depositAmount: string
      bankReferenceId: string
      bankMode: string
      bankCustomer: string
    }
  }
}

export type StatementReconTxnActionPayloadType = {
  startTime: number
  endTime: number
  filterType?: COLLECTION_FILTER_TYPE
}

export type StatementReconTxnRequestPayloadType = {
  startTime: number
  endTime: number
}

export type BankStatementTxnType = 'upi' | 'neft' | 'cheque' | ''

export type BankStatementReconTxnResponsePayload = {
  id: string
  narration: string
  bank: string
  date: string
  reference_number: string
  txn_type: BankStatementTxnType
  is_reconciled: boolean
  amount: number
  customer_name: string
  invoice_number: string
  collected_by: string
}

export type BankStatementReconTxnType = {
  id: string
  date: string
  bank: string
  narration: string
  referenceNumber: string
  amount: number
  customerName: string
  invoiceNumber: string
  collectedBy: string
  txnType: BankStatementTxnType
  isReconciled: boolean
}

export type BankStatementFilter = {
  transactionType: BankStatementTxnType[]
  bank: string[]
}

export enum BankStatementDocumentState {
  'DB_UPLOADING',
  'UPLOADED',
  'UPLOAD_FAILED',
  'RECONCILIATION_IN_PROGRESS',
  'RECONCILIATION_COMPLETE'
}

export interface ILoaders {
  bankStatementTemplate: boolean
  bankStatementDocument: boolean
  bankStatementDocumentStatus: boolean
  bankStatementReconciledTxns: boolean
  bankReconciliationTxns: boolean
}

export interface IDrawers {
  downloadBankStatement: boolean
  templates: boolean
  uploadedFiles: boolean
  showLogAssetAccountDrawer: boolean
  showEditAssetAccount: boolean
}
