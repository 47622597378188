import { takeCustomLatest } from 'state/common/common.effects'
import { unidentifiedCollectionAction } from './unidentifiedCollection.actions'
import {
  fetchUnidentifiedCollectionEffect,
  addBillToCollectionsEffect,
  createInitiateBankDepositEffect,
  createInitiateCashDepositEffect,
  editBankDepositEffect,
  editCashDepositEffect
} from './unidentifiedCollection.effect'

function* fetchUnidentifiedCollectionSaga() {
  yield takeCustomLatest(
    unidentifiedCollectionAction.FETCH_UNIDENTIFIED_COLLECTION,
    fetchUnidentifiedCollectionEffect
  )
}

function* addBillToCollectionsSaga() {
  yield takeCustomLatest(
    unidentifiedCollectionAction.ADD_BILL_TO_COLLECTION,
    addBillToCollectionsEffect
  )
}

function* createInitiateBankDepositActionSaga() {
  yield takeCustomLatest(
    unidentifiedCollectionAction.INITIATE_BANK_DEPOSIT,
    createInitiateBankDepositEffect
  )
}

function* createInitiateCashDepositActionSaga() {
  yield takeCustomLatest(
    unidentifiedCollectionAction.INITIATE_CASH_DEPOSIT,
    createInitiateCashDepositEffect
  )
}

function* editBankDepositSaga() {
  yield takeCustomLatest(unidentifiedCollectionAction.EDIT_BANK_DEPOSIT, editBankDepositEffect)
}

function* editCashDepositSaga() {
  yield takeCustomLatest(unidentifiedCollectionAction.EDIT_CASH_DEPOSIT, editCashDepositEffect)
}
export const UnidentifiedCollectionWatchers = [
  fetchUnidentifiedCollectionSaga(),
  addBillToCollectionsSaga(),
  createInitiateBankDepositActionSaga(),
  createInitiateCashDepositActionSaga(),
  editBankDepositSaga(),
  editCashDepositSaga()
]
