import { SetStateAction } from 'react'
import { Action } from 'infra/types'
import {
  CreditNoteListPagination,
  FetchCreditNotePayload,
  ICreditNote,
  ICreditNoteState
} from 'state/new-summary/creditNote/creditNote.types'

export const creditNoteActions = {
  FETCH_CREDIT_NOTES_LIST: 'creditNotes/FETCH_CREDIT_NOTES_LIST',
  FETCH_CREDIT_NOTES_LIST_SUCCESS: 'creditNotes/FETCH_CREDIT_NOTES_LIST_SUCCESS',
  SET_CREDIT_NOTES_LOADER: 'creditNotes/SET_CREDIT_NOTES_LOADER',
  SET_CREDIT_NOTES_SEARCH_STRING: 'creditNotes/SET_CREDIT_NOTES_SEARCH_STRING',
  SET_CREDIT_NOTES_LIST_PAGINATION: 'creditNotes/SET_CREDIT_NOTES_LIST_PAGINATION',
  FETCH_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS:
    'creditNotes/FETCH_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS',
  SET_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS: 'creditNotes/SET_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS'
}

export const fetchCreditNotesListAction = (
  payload: FetchCreditNotePayload
): Action<FetchCreditNotePayload> => ({
  type: creditNoteActions.FETCH_CREDIT_NOTES_LIST,
  payload
})

export const fetchCreditNotesListSuccessAction = (
  payload: Array<ICreditNote>
): Action<Array<ICreditNote>> => ({
  type: creditNoteActions.FETCH_CREDIT_NOTES_LIST_SUCCESS,
  payload
})

export const setCreditNotesLoaderAction = (payload: {
  id: keyof ICreditNoteState['loaders']
  value: boolean
}): Action<{
  id: keyof ICreditNoteState['loaders']
  value: boolean
}> => ({
  type: creditNoteActions.SET_CREDIT_NOTES_LOADER,
  payload
})

export const setCreditNoteSearchStringAction = (
  payload: SetStateAction<string>
): Action<SetStateAction<string>> => ({
  type: creditNoteActions.SET_CREDIT_NOTES_SEARCH_STRING,
  payload
})

export const saveCreditNotePaginationDetails = (payload: {
  pagination: CreditNoteListPagination
  sort: ICreditNoteState['cnSortBy']
}): Action<{
  pagination: CreditNoteListPagination
  sort: ICreditNoteState['cnSortBy']
}> => ({
  type: creditNoteActions.SET_CREDIT_NOTES_LIST_PAGINATION,
  payload
})

export const fetchPreviewCreditEntityAction = (payload: {
  ids: Array<string>
  isInvoice?: boolean
}): Action<{ ids: Array<string>; isInvoice?: boolean }> => ({
  type: creditNoteActions.FETCH_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS,
  payload
})

export const setPreviewCreditNoteEntityAction = (
  payload: Array<string>
): Action<Array<string>> => ({
  type: creditNoteActions.SET_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS,
  payload
})
