export const isBetween2pmAnd4pm = () => {
  try {
    const currentDate = new Date()
    const currentHour = currentDate.getHours()
    const currentMinutes = currentDate.getMinutes()

    // Check if current time is between 2pm and 4pm
    return currentHour === 14 || (currentHour === 15 && currentMinutes <= 59)
  } catch (error) {
    return false
  }
}
export const saveLocalStorageData = () => {
  try {
    const isBannerSeen: string | null = window.localStorage.getItem('isBannerSeen')
    const lastBannerShownDate: string | null = window.localStorage.getItem('lastBannerShownDate')

    const currentDate = new Date().toISOString().slice(0, 10)

    // if not set then making to set to true if true is send then call the api and check
    if (isBannerSeen === null || lastBannerShownDate === null) {
      window.localStorage.setItem('isBannerSeen', 'false')
      window.localStorage.setItem('lastBannerShownDate', currentDate)
      return true
    }

    if (lastBannerShownDate !== currentDate && isBannerSeen === 'false') {
      window.localStorage.setItem('lastBannerShownDate', currentDate)
      return true
    }

    // if true then user has done the action now no need to call api and show the banner so returning false
    if (isBannerSeen === 'true') {
      return false
    }

    return false
  } catch (e: any) {
    return true
  }
}
