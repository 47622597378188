import { Action } from '../../infra/types'
import { pendingDueSummaryActions } from './pendingDueSummary.actions'
import { IPendingDueSummary } from './pendingDueSummary.types'

const defaultPendingSummaryReducer: IPendingDueSummary = {
  has_more: false,
  filter: 0,
  sortBy: 'pending_due',
  pendingDueGroupData: [],
  pendingDueGroupIdData: [],
  detailedDueSortBy: '',
  detailedDueTotalInvoiceCount: 0,
  detailedDueTotalPendingDue: 0,
  detailedDueGroupName: '',
  detailedDueWalletBalance: 0,
  pendingDueRangeFilter: {
    lte: -1,
    gte: -1
  },
  billsCountGt: -1,
  modals: {
    downloadSummaryGroup: false,
    downloadSummary: false,
    editGroupName: false,
    deleteGroup: false
  },
  detailedDuePageSize: 50,
  detailedDueCurrentPage: 1,
  detailedDuetotalEntries: 0,

  groupDuePageSize: 9999,
  groupDueCurrentPage: 1,
  groupDuetotalEntries: 0,

  loaders: {
    isPendingDueSummaryLoading: false,
    editNameLoading: false,
    isOverallPendingDueSummaryLoading: false
  }
}

export const PendingDueSummaryReducer = (
  state: IPendingDueSummary = defaultPendingSummaryReducer,
  action: Action<any>
): IPendingDueSummary => {
  switch (action.type) {
    case pendingDueSummaryActions.PENDING_DUE_SELECTED_FILTER: {
      return { ...state, filter: action.payload.filter }
    }
    case pendingDueSummaryActions.SET_PENDING_DUE_GROUP_DATA: {
      return { ...state, ...action.payload }
    }
    case pendingDueSummaryActions.SET_PENDING_DUE_OVERALL_GROUP_DATA: {
      return {
        ...state,
        ...action.payload,
        overallPendingDueSummary: {
          ...action.payload.overallPendingDueSummary,
          listName: 'All Pending Dues'
        }
      }
    }
    case pendingDueSummaryActions.SET_PENDING_DUE_GROUP_ID_DATA: {
      return { ...state, ...action.payload }
    }
    case pendingDueSummaryActions.SET_PENDING_DUE_OVERALL_INNER_GROUP: {
      return { ...state, ...action.payload }
    }
    case pendingDueSummaryActions.UPDATE_GROUP_NAME: {
      return { ...state, detailedDueGroupName: action.payload.updatedName }
    }
    case pendingDueSummaryActions.RESET_DETAILED_GROUP_DATA: {
      return {
        ...state,
        detailedDuePageSize: 50,
        detailedDueCurrentPage: 1,
        detailedDuetotalEntries: 0,
        detailedDueGroupName: ''
      }
    }
    case pendingDueSummaryActions.SET_DRAWER: {
      return {
        ...state,
        modals: { ...state.modals, [action.payload.modalName]: action.payload.status }
      }
    }
    case pendingDueSummaryActions.SET_PENDING_DUE_SUMMARY_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...action.payload
        }
      }
    }
    case pendingDueSummaryActions.EDIT_GROUP_NAME_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          editNameLoading: action.payload.editNameLoading
        }
      }
    }
    case pendingDueSummaryActions.FETCH_PENDING_DUE_OVERALL_INNER_GROUP: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isOverallPendingDueSummaryLoading: true
        }
      }
    }
    default: {
      return state
    }
  }
}
