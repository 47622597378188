import { takeLatest } from '@redux-saga/core/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import {
  StatementsActions,
  setReplacementSummaryDownloadStatus,
  setV2SummaryDownloadDrawerStatus
} from './statements.actions'
import {
  createGroupEffect,
  getOwnerSummaryEffect,
  removeGroupEffect,
  updateGroupEffect,
  addToLedgerEffect,
  approveAllTransactionsEffect,
  updateOwnerSummaryEffect,
  getCollectionListSummaryEffect,
  getSingleCollectionListSummaryEffect,
  getUnapprovedTransactionsSagaEffects,
  getAccountAllocationDetailsEffects,
  getFinalBalanceDownloadDataEffect,
  getInvoiceLevelDownloadDataEffect,
  getV2InvoiceDownload,
  getReplacementListEffects,
  updatePendingReplacementEffects,
  getCollectionListInvoiceSummaryEffect,
  getCollectionListAccountInvoiceSummaryEffect,
  createGroupByTagsEffect,
  removeGroupByTagsEffect,
  updateGroupByTagsEffect,
  getReplacementSummaryDownloadEffect
} from './statements.effects'

function* createGroupSaga() {
  yield takeLatest(StatementsActions.CREATE_GROUP, createGroupEffect)
}

function* getOwnerSummarySaga() {
  yield takeLatest(StatementsActions.GET_OWNER_SUMMARY, getOwnerSummaryEffect)
}

function* updateOwnerSummarySaga() {
  yield takeLatest(StatementsActions.UPDATE_OWNER_SUMMARY, updateOwnerSummaryEffect)
}

function* updateGroupSaga() {
  yield takeLatest(StatementsActions.UPDATE_GROUP, updateGroupEffect)
}

function* removeGroupSaga() {
  yield takeLatest(StatementsActions.REMOVE_GROUP, removeGroupEffect)
}

function* addToLedgerSaga() {
  yield takeLatest(StatementsActions.ADD_TO_LEDGER, addToLedgerEffect)
}

function* approveAllTransactionsSaga() {
  yield takeLatest(StatementsActions.BULK_ADD_TO_LEDGER, approveAllTransactionsEffect)
}

function* getCollectionListSummarySaga() {
  yield takeLatest(StatementsActions.GET_COLLECTION_LIST_SUMMARY, getCollectionListSummaryEffect)
}

function* getCollectionListAccountSummarySaga() {
  yield takeLatest(
    StatementsActions.GET_SINGLE_COLLECTION_LIST_SUMMARY,
    getSingleCollectionListSummaryEffect
  )
}

function* getCollectionListInvoiceSummarySaga() {
  yield takeLatest(
    StatementsActions.GET_COLLECTION_LIST_INVOICE_SUMMARY,
    getCollectionListInvoiceSummaryEffect
  )
}

function* getCollectionListAccountInvoiceSummarySaga() {
  yield takeLatest(
    StatementsActions.GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY,
    getCollectionListAccountInvoiceSummaryEffect
  )
}

function* getUnapprovedTransactionsSaga() {
  yield takeLatest(
    StatementsActions.FETCH_UNAPPROVED_TRANSACTION_SUMMARY,
    getUnapprovedTransactionsSagaEffects
  )
}

function* getAccountAllocationDetailsSaga() {
  yield takeLatest(
    StatementsActions.FETCH_ACCOUNT_ALLOCATION_DETAILS,
    getAccountAllocationDetailsEffects
  )
}

function* getFinalBalanceDownloadDataSaga() {
  yield takeLatest(
    StatementsActions.GET_FINAL_BALANCE_DOWNLOAD_DATA,
    getFinalBalanceDownloadDataEffect
  )
}

function* getInvoiceLevelDownloadDataSaga() {
  yield takeLatest(
    StatementsActions.GET_INVOICE_LEVEL_DOWNLOAD_DATA,
    getInvoiceLevelDownloadDataEffect
  )
}

function* getV2InvoiceDownloadSaga() {
  yield takeCustomLatest(
    StatementsActions.GET_V2_INVOICE_LEVEL_DOWNLOAD_DATA,
    getV2InvoiceDownload,
    () => setV2SummaryDownloadDrawerStatus('uninitiated')
  )
}

function* getReplacementSummaryDownloadSaga() {
  yield takeCustomLatest(
    StatementsActions.REPLACEMENT_LEVEL_SUMMARY_DOWNLOAD,
    getReplacementSummaryDownloadEffect,
    () => setReplacementSummaryDownloadStatus('uninitiated')
  )
}

function* getReplacementListSaga() {
  yield takeLatest(StatementsActions.FETCH_PENDING_REPLACEMENT_LIST, getReplacementListEffects)
}

function* updatePendingReplacementSaga() {
  yield takeLatest(StatementsActions.UPDATE_PENDING_REPLACEMENTS, updatePendingReplacementEffects)
}

function* createGroupByTagsSaga() {
  yield takeLatest(StatementsActions.CREATE_GROUP_BY_TAGS, createGroupByTagsEffect)
}

function* updateGroupByTagsSaga() {
  yield takeLatest(StatementsActions.UPDATE_GROUP_BY_TAGS, updateGroupByTagsEffect)
}

function* removeGroupByTagsSaga() {
  yield takeLatest(StatementsActions.REMOVE_GROUP_BY_TAGS, removeGroupByTagsEffect)
}

export const StatementsWatchers = [
  createGroupSaga(),
  getOwnerSummarySaga(),
  updateGroupSaga(),
  removeGroupSaga(),
  addToLedgerSaga(),
  approveAllTransactionsSaga(),
  updateOwnerSummarySaga(),
  getCollectionListSummarySaga(),
  getCollectionListAccountSummarySaga(),
  getCollectionListInvoiceSummarySaga(),
  getCollectionListAccountInvoiceSummarySaga(),
  getUnapprovedTransactionsSaga(),
  getAccountAllocationDetailsSaga(),
  getFinalBalanceDownloadDataSaga(),
  getInvoiceLevelDownloadDataSaga(),
  getReplacementListSaga(),
  updatePendingReplacementSaga(),
  createGroupByTagsSaga(),
  updateGroupByTagsSaga(),
  removeGroupByTagsSaga(),
  getV2InvoiceDownloadSaga(),
  getReplacementSummaryDownloadSaga()
]
