import { Action } from 'infra/types'
import {
  CustomerDetailsActionPayload,
  ICreateEditCustomerState
} from './create-edit-customer.types'

export const createEditCustomerActions = {
  SET_DRAWER: 'createEditCustomerActions/setDrawers',
  SET_LOADER: 'createEditCustomerActions/setLoaders',
  CREATE_UPDATE_CUSTOMER: 'createEditCustomerActions/createUpdateCustomer',
  SET_DRAWER_WITH_EDIT_DETAILS: 'createEditCustomerActions/setEditData',
  RESET_CUSTOMER_DETAILS: 'createEditCustomerActions/resetCustomerDetails'
}

export const setCreateEditCustomerDrawers = (payload: {
  drawerName: 'showCreateEditCustomerDrawer'
  status: boolean
}): Action<{
  drawerName: 'showCreateEditCustomerDrawer'
  status: boolean
}> => ({
  type: createEditCustomerActions.SET_DRAWER,
  payload
})

export const setCreateCustomerLoader = (payload: {
  loaderName: 'isCreatingCustomer'
  status: boolean
}): Action<{
  loaderName: 'isCreatingCustomer'
  status: boolean
}> => ({
  type: createEditCustomerActions.SET_LOADER,
  payload
})

export const createUpdateCustomer = (payload: {
  customerDetails: CustomerDetailsActionPayload
  customerId?: string
}): Action<{
  customerDetails: CustomerDetailsActionPayload
  customerId?: string
}> => {
  return {
    type: createEditCustomerActions.CREATE_UPDATE_CUSTOMER,
    payload
  }
}

export const setEditCustomerDetailsDrawer = (payload: {
  editCustomerDetails: ICreateEditCustomerState['editCustomerDetails']
}): Action<{
  editCustomerDetails: ICreateEditCustomerState['editCustomerDetails']
}> => {
  return {
    type: createEditCustomerActions.SET_DRAWER_WITH_EDIT_DETAILS,
    payload
  }
}

export const resetCustomerDetails = () => {
  return {
    type: createEditCustomerActions.RESET_CUSTOMER_DETAILS
  }
}
