import {
  COLLECTION_LIST_SORT_TYPE,
  IBIllListData,
  IBillV2
} from 'state/collectionList/collectionList.types'

interface ISelBill {
  bill_id: string
  customer_id: string
}

export function areAllBillsSelected(selectedBills: ISelBill[], allBills: IBIllListData[]) {
  // Check if the length of both arrays is the same
  if (selectedBills.length !== allBills.length) {
    return false
  }

  // Create a copy of the allBills array to avoid modifying the original array
  const allBillsCopy = [...allBills]

  // Check if each bill from allBills exists in selectedBills
  for (const bill of selectedBills) {
    const billIndex = allBillsCopy.findIndex((item) => isEqualBill(item, bill))
    if (billIndex === -1) {
      // If the bill is not found in allBillsCopy, return false
      return false
    }
    // Remove the bill from allBillsCopy to avoid duplicate matches
    allBillsCopy.splice(billIndex, 1)
  }

  // If all bills have been found in selectedBills, return true
  return true
}

// Helper function to compare two bills (objects) for equality
function isEqualBill(bill1: IBIllListData, bill2: ISelBill) {
  // Implement your comparison logic here
  // For example, you might compare some unique identifier of the bills
  // For simplicity, let's assume there is a 'billId' property in each bill object
  return bill1.billId === bill2.bill_id
}

export function isBillIdExists(billDetails: IBillV2[], billId: string) {
  const res = billDetails.some((__) => {
    return __.bill.bill_id === billId
  })
  return res
}

export const getSortedInvoices = (invoices: IBIllListData[], selectedSortOption: string): any => {
  switch (selectedSortOption) {
    case COLLECTION_LIST_SORT_TYPE.NAME: {
      return invoices?.sort((invoice1, invoice2) => {
        if (invoice1.billNumber.toUpperCase() < invoice2.billNumber.toUpperCase()) {
          return -1
        }
        if (invoice1.billNumber.toUpperCase() > invoice2.billNumber.toUpperCase()) {
          return 1
        }
        return 0
      })
    }
    case COLLECTION_LIST_SORT_TYPE.AMOUNT: {
      return invoices?.sort(
        (invoice1, invoice2) => Number(invoice2.billDueAmount) - Number(invoice1.billDueAmount)
      )
    }
    default: {
      return invoices
    }
  }
}
