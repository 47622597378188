import { Action } from 'infra/types'
import { OFFER_RESPONSE } from 'services/Api/BillingApiFolder.ts/type'

export const offerAndDiscountsDrawerActions = {
  SET_OFFERS_AND_DISCOUNTS_DRAWER: 'offerAndDiscountsDrawer/setofferAndDiscountsDrawer',
  SET_OFFERS_AND_DISCOUNTS_DRAWER_DATA: 'offerAndDiscountsDrawer/setofferAndDiscountsDrawerData',
  SET_HANDLE_SAVE_CALLBACK_DRAWER: 'offerAndDiscountsDrawer/setHandleSaveCallbackDrawer',
  RESET_OFFERS_AND_DISCOUNTS_DRAWER: 'offerAndDiscountsDrawer/resetofferAndDiscountsDrawer',
  SET_ALL_BUSINESS_OFFERS_AND_DISCOUNTS: 'offerAndDiscountsDrawer/setAllBusinessOffersAndDiscounts'
}

export const setofferAndDiscountsDrawer = (payload: {
  drawerStatus: boolean
  areOffersClickable?: boolean
}): Action<{
  drawerStatus: boolean
  areOffersClickable?: boolean
}> => {
  return {
    type: offerAndDiscountsDrawerActions.SET_OFFERS_AND_DISCOUNTS_DRAWER,
    payload
  }
}

export const setofferAndDiscountsDrawerData = (payload: {
  inventoryItemIds: string[]
  previousOffers?: OFFER_RESPONSE[]
  handleSaveCallback?: () => void
  areOffersClickable?: boolean
  showOnlyPreviousOffer?: boolean
}): Action<{
  inventoryItemIds: string[]
  previousOffers?: OFFER_RESPONSE[]
  handleSaveCallback?: () => void
  areOffersClickable?: boolean
  showOnlyPreviousOffer?: boolean
}> => {
  return {
    type: offerAndDiscountsDrawerActions.SET_OFFERS_AND_DISCOUNTS_DRAWER_DATA,
    payload
  }
}

export const setAllBusinessOffers = (payload: { businessOffers: any[] }) => {
  return {
    type: offerAndDiscountsDrawerActions.SET_ALL_BUSINESS_OFFERS_AND_DISCOUNTS,
    payload
  }
}

export const setHandleSaveCallback = (payload: {
  handleSaveCallback: (tagIds: string[]) => Promise<void>
  handleBackArrowClick: () => void
}): Action<{
  handleSaveCallback: (tagIds: string[]) => Promise<void>
  handleBackArrowClick: () => void
}> => {
  return {
    type: offerAndDiscountsDrawerActions.SET_HANDLE_SAVE_CALLBACK_DRAWER,
    payload
  }
}

export const resetofferAndDiscountsDrawer = () => {
  return {
    type: offerAndDiscountsDrawerActions.RESET_OFFERS_AND_DISCOUNTS_DRAWER
  }
}
