import dayjs from 'dayjs'
import { Action } from 'infra/types'
import { ICashByStaffState } from 'state/summary/cashByStaff/cashByStaff.types'
import { cashByStaffActions } from './cashByStaff.actions'

const defaultCashByStaffReducer: ICashByStaffState = {
  modals: {
    downloadCashByStaff: false,
    downloadCashByStaffBankWise: false
  },
  filter: {
    startDate: dayjs().startOf('day').unix(),
    endTime: dayjs().endOf('day').unix(),
    startTime: Math.trunc(new Date().getTime() / 1000)
  }
}

export const CashByStaffReducer = (
  state: ICashByStaffState = defaultCashByStaffReducer,
  action: Action<any>
): ICashByStaffState => {
  switch (action.type) {
    case cashByStaffActions.SET_CASH_BY_STAFF_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, [action.payload.modalName]: action.payload.status }
      }
    }
    case cashByStaffActions.SET_CASH_BY_STAFF_ACTIVE_FILTER: {
      return {
        ...state,
        filter: action.payload
      }
    }
    default: {
      return state
    }
  }
}
