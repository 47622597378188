import { validatePhoneNumber } from 'utils/validators/phoneNumberValidator'
import { validateEmail } from 'utils/validators/emailValidator'
import { validateGST } from 'pages/NewDashboard/components/Billing/Helper'
import {
  IMerchantProfile,
  IMerchantProfileBusinessType,
  IMerchantProfileEdited,
  IMerchantProfileBusinessCategory
} from '../dashboard.types'
import { IApiResponse } from '../../../constants'

export const getFormattedMerchantProfile = (response: IApiResponse): IMerchantProfile => {
  const { category, business_type } = response.data

  const merchantProfile: IMerchantProfile = {
    id: response.data.id,
    upiVpa: response.data.upi_vpa,
    profileImage: response.data.profile_image,
    name: response.data.name,
    mobile: response.data.mobile,
    lang: response.data.lang,
    email: response.data.email,
    contactName: response.data.contact_name,
    address: response.data.address,
    about: response.data.about,
    businessTypeId: business_type?.id,
    categoryId: category?.id
  } as IMerchantProfile

  return merchantProfile
}

interface IProfilePatchPayload {
  name?: string
  mobile?: string
  address?: string
  email?: string
  about?: string
  contact_name?: string
  business_type?: IMerchantProfileBusinessType
  category?: IMerchantProfileBusinessCategory
}

export const getMerchantProfilePatchPayload = (
  editedState: IMerchantProfileEdited,
  originalState: IMerchantProfile
): IProfilePatchPayload => {
  const payload: any = {}
  const { values, errors } = editedState

  if (!errors.name) {
    payload.name = values.name
  }

  if (!errors.address) {
    payload.address = values.address
  }

  if (!errors.email) {
    payload.email = values.email
  }

  if (!errors.contactName) {
    payload.contact_name = values.contactName
  }

  if (!errors.about) {
    payload.about = values.about
  }

  if (values.businessTypeId !== originalState.businessTypeId) {
    payload.business_type_id = values.businessTypeId
    payload.update_business_type = true
  }

  if (values.categoryId !== originalState.categoryId) {
    payload.category_id = values.categoryId
    payload.update_category = true
  }

  return payload
}

export const getUpdatedMerchantProfile = (
  merchantProfile: IMerchantProfile,
  editedState: IMerchantProfileEdited,
  patchPayload?: any
) => {
  const updatedMerchantProfile = { ...merchantProfile }
  let updatedEditedState = editedState

  if (patchPayload) {
    if (patchPayload.name !== undefined) {
      updatedMerchantProfile.name = patchPayload.name
    }

    if (patchPayload.address !== undefined) {
      updatedMerchantProfile.address = patchPayload.address
    }

    if (patchPayload.email !== undefined) {
      updatedMerchantProfile.email = patchPayload.email
    }

    if (patchPayload.contact_name !== undefined) {
      updatedMerchantProfile.contactName = patchPayload.contact_name
    }

    if (patchPayload.about !== undefined) {
      updatedMerchantProfile.about = patchPayload.about
    }

    if (editedState.values.businessTypeId) {
      updatedMerchantProfile.businessTypeId = editedState.values.businessTypeId
    }

    if (editedState.values.categoryId) {
      updatedMerchantProfile.categoryId = editedState.values.categoryId
    }
  } else {
    updatedEditedState = {
      values: {
        name: updatedMerchantProfile.name,
        mobile: updatedMerchantProfile.mobile,
        address: updatedMerchantProfile.address,
        email: updatedMerchantProfile.email,
        contactName: updatedMerchantProfile.contactName,
        about: updatedMerchantProfile.about,
        businessTypeId: updatedMerchantProfile.businessTypeId,
        categoryId: updatedMerchantProfile.categoryId
      },
      errors: {}
    }
  }

  return { profile: updatedMerchantProfile, edited: updatedEditedState }
}

export const validateMerchantProfileField = ({
  key,
  value
}: {
  key: string
  value: any
}): boolean => {
  let isValid = true

  switch (key) {
    case 'email':
      isValid = validateEmail(value)
      break
    case 'mobile':
      isValid = validatePhoneNumber(value)
      break
    case 'name':
      isValid = value.length
      break
  }
  return isValid
}

export const getTaxonomy = <
  T extends IMerchantProfileBusinessType | IMerchantProfileBusinessCategory
>(
  data: T[],
  taxonomyId?: string
): T | null => {
  return data.find(({ id }) => id === taxonomyId) || null
}

export const getMerchantProfilePatchPayloadV2 = (
  editedState: IMerchantProfileEdited,
  originalState: IMerchantProfile
): IProfilePatchPayload => {
  const payload: any = {
    business_user_id: originalState.id,
    business_user: {
      user: {
        mobile: originalState.mobile,
        display_name: originalState.name
      }
    }
  }
  const { values, errors } = editedState

  if (!errors.name && values.name !== originalState.name) {
    payload.business_user.user.display_name = values.name
    payload.update_display_name = true
  }

  if (values.gst && validateGST(values.gst) && values.gst !== originalState.businessGst) {
    payload.business_user.gst_number = values.gst
    payload.update_gst_number = true
  }

  if (!errors.address && values.address !== originalState.address) {
    payload.business_user.user.address = {
      text: values.address
    }
    payload.update_address = true
  }

  if (!errors.email && values.email !== originalState.email) {
    payload.business_user.user.email = values.email
    payload.update_email = true
  }

  if (!errors.contactName && values.contactName !== originalState.contactName) {
    payload.business_user.contact_name = values.contactName
    payload.update_contact_name = true
  }

  if (!errors.about && values.about !== originalState.about) {
    payload.business_user.user.about = values.about
    payload.update_about = true
  }

  if (values.businessTypeId !== originalState.businessTypeId) {
    payload.business_user.business_type = {
      id: values.businessTypeId
    }
    payload.update_business_type = true
  }

  if (values.categoryId !== originalState.categoryId) {
    payload.business_user.business_category = {
      id: values.categoryId
    }
    payload.update_category = true
  }

  return payload
}

export const MerchantProfileHelpers = {
  getFormattedMerchantProfile,
  getMerchantProfilePatchPayload,
  validateMerchantProfileField,
  getUpdatedMerchantProfile,
  getMerchantProfilePatchPayloadV2
}
