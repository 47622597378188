import { Action } from 'infra/types'
import { FILTER_OPTION } from 'services/Api/StaffLinkApiFolder/type'
import { IBeatFilterState } from './beat-filter.types'
import { beatFilterDrawerActions } from './beat-filter.actions'

const BEAT_FILTER: IBeatFilterState = {
  selectedBeatsFilter: [],
  selectedBeatsFilterType: FILTER_OPTION.OR,
  buttonType: '',
  drawers: {
    showBeatFilterDrawer: false
  }
}

export const BeatFilterReducer = (
  state: IBeatFilterState = BEAT_FILTER,
  action: Action<any>
): IBeatFilterState => {
  switch (action.type) {
    case beatFilterDrawerActions.SET_BEAT_FILTER_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showBeatFilterDrawer: action.payload.drawerStatus
        }
      }
    }
    case beatFilterDrawerActions.RESET_BEAT_FILTER: {
      return {
        selectedBeatsFilter: [],
        selectedBeatsFilterType: FILTER_OPTION.OR,
        buttonType: '',
        drawers: {
          showBeatFilterDrawer: false
        }
      }
    }
    case beatFilterDrawerActions.SET_SELECTED_BEATS_DATA: {
      return {
        ...state,
        drawers: {
          showBeatFilterDrawer: action.payload.drawerStatus || state.drawers.showBeatFilterDrawer
        },
        selectedBeatsFilter: action.payload.selectedBeatsFilter,
        selectedBeatsFilterType: action.payload.selectedBeatsFilterType,
        buttonType: action.payload.buttonType || state.buttonType
      }
    }
    default: {
      return state
    }
  }
}
