import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ITemplate } from 'state/new-summary/uploadSheet/uploadSheet.types'
import UnselectedIcon from 'assets/payments/unselected_checkbox.svg'
import SelectedIcon from 'assets/payments/selected_checkbox.svg'
import { updateDefaultTemplate } from 'state/new-summary/uploadSheet/uploadSheet.actions'
import TemplateItem from '../TemplateItem'
import { OtherTemplatesStyles } from './styles'

const OtherTemplates = ({ templates }: { templates: ITemplate[] }) => {
  const classes = OtherTemplatesStyles()

  return (
    <div className={classes.container}>
      <div className={classes.header}>Other Templates</div>
      <div className={classes.templateContainer}>
        {templates.map((template) => (
          <Template key={template.id} template={template} />
        ))}
      </div>
    </div>
  )
}

const Template = ({ template }: { template: ITemplate }) => {
  const dispatch = useDispatch()
  const [isSelected, setIsSelected] = useState(false)

  const handleSelectTemplate = (template: ITemplate) => {
    setIsSelected(true)
    dispatch(
      updateDefaultTemplate({
        document_type: Number(template.document_type),
        ...(!template.isGlobal ? { templateId: template.id } : {})
      })
    )
  }

  return (
    <TemplateItem
      key={template.id}
      name={template.name}
      documentType={template.document_type}
      onClick={() => handleSelectTemplate(template)}
    >
      <img src={isSelected ? SelectedIcon : UnselectedIcon} height={20} width={20} />
    </TemplateItem>
  )
}

export default OtherTemplates
