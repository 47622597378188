import { takeLatest } from '@redux-saga/core/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import { CollectionSummaryActions, setLoaderAction } from './collectionSummary.actions'
import {
  downloadCollectionGroupInvoiceSummaryEffect,
  fetchTransactionDetailsEffect,
  getCollectionGroupInvoiceSummaryV2Effect,
  getCollectionListInvoiceSummaryV2Effect
} from './collectionSummary.effects'

function* getCollectionListInvoiceSummaryV2Saga() {
  yield takeCustomLatest(
    CollectionSummaryActions.GET_COLLECTION_LIST_INVOICE_SUMMARY_V2,
    getCollectionListInvoiceSummaryV2Effect,
    () => setLoaderAction({ id: 'listSummary', value: false })
  )
}

function* getCollectionGroupInvoiceSummaryV2Saga() {
  yield takeCustomLatest(
    CollectionSummaryActions.GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2,
    getCollectionGroupInvoiceSummaryV2Effect,
    () => setLoaderAction({ id: 'groupSummary', value: false })
  )
}

function* fetchTransactionDetailsSaga() {
  yield takeLatest(
    CollectionSummaryActions.FETCH_TRANSACTION_DETAILS,
    fetchTransactionDetailsEffect
  )
}

function* downloadCollectionGroupInvoiceSummarySaga() {
  yield takeCustomLatest(
    CollectionSummaryActions.DOWNLOAD_COLLECTION_SINGLE_GROUP_SUMMARY,
    downloadCollectionGroupInvoiceSummaryEffect
  )
}

export const CollectionSummaryWatchers = [
  getCollectionListInvoiceSummaryV2Saga(),
  getCollectionGroupInvoiceSummaryV2Saga(),
  fetchTransactionDetailsSaga(),
  downloadCollectionGroupInvoiceSummarySaga()
]
