import { Action } from 'infra/types'

export const applyTagDrawerActions = {
  SET_APPLY_TAG_DRAWER: 'applyTagDrawer/setApplyTagDrawer',
  SET_HANDLE_SAVE_CALLBACK_DRAWER: 'applyTagDrawer/setHandleSaveCallbackDrawer',
  RESET_APPLY_TAG_DRAWER: 'applytagDrawer/resetApplyTagDrawer'
}

export const setApplyTagDrawer = (payload: {
  drawerStatus: boolean
}): Action<{
  drawerStatus: boolean
}> => {
  return {
    type: applyTagDrawerActions.SET_APPLY_TAG_DRAWER,
    payload
  }
}

export const setHandleSaveCallback = (payload: {
  handleSaveCallback: (tagIds: string[]) => Promise<void>
  handleBackArrowClick: () => void
}): Action<{
  handleSaveCallback: (tagIds: string[]) => Promise<void>
  handleBackArrowClick: () => void
}> => {
  return {
    type: applyTagDrawerActions.SET_HANDLE_SAVE_CALLBACK_DRAWER,
    payload
  }
}

export const resetApplyTagDrawer = () => {
  return {
    type: applyTagDrawerActions.RESET_APPLY_TAG_DRAWER
  }
}
