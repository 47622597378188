/* eslint-disable no-extra-semi */
import dayjs from 'dayjs'
import {
  DUE_CONFIG,
  FETCH_LIST_API_RESPONSE,
  IDamage,
  IReplacement,
  IReturns,
  IUnapprovedCancelRequest,
  IUnapprovedDeliveryAttempts,
  NewAccount,
  NewBill,
  NewCollection
} from 'services/Api/StaffLinkApiFolder/type'
import { BILL_STATUS, CL_VERSIONS, TRANSACTION_TYPE } from 'utils/common/common.types'
import { getInvoiceAge } from 'utils/common/common.helpers'
import {
  BUTTON_TYPE,
  DEFAULT_BEAT,
  DEFAULT_ROUTE,
  IApproveTable,
  IBillBeat,
  INoActionsTable,
  IPendingTable,
  IRescheduledDeliverieTable,
  SummaryKeys,
  TRANSACTION_TYPE_KEY_MAPPER
} from './list.types'
import { ISupplyListState } from './supply-list.types'

export const extractSummaryData = (
  pendingTableData: IPendingTable[],
  approvedTableData: IApproveTable[]
): ISupplyListState['summaryData'] => {
  const cash: ISupplyListState['summaryData']['cash'] = {
    totalApprovedAmount: 0,
    totalPendingAmount: 0,
    transactionIdsForApproving: []
  }

  const qrLink: ISupplyListState['summaryData']['qrLink'] = {
    totalApprovedAmount: 0,
    totalPendingAmount: 0,
    transactionIdsForApproving: []
  }

  const cheque: ISupplyListState['summaryData']['cheque'] = {
    totalApprovedAmount: 0,
    totalPendingAmount: 0,
    transactionIdsForApproving: []
  }
  const returns: ISupplyListState['summaryData']['returns'] = {
    totalApprovedAmount: 0,
    totalPendingAmount: 0,
    transactionIdsForApproving: []
  }
  const neft: ISupplyListState['summaryData']['neft'] = {
    totalApprovedAmount: 0,
    totalPendingAmount: 0,
    transactionIdsForApproving: []
  }
  const damages: ISupplyListState['summaryData']['damages'] = {
    totalApprovedAmount: 0,
    totalPendingAmount: 0,
    transactionIdsForApproving: []
  }
  const others: ISupplyListState['summaryData']['others'] = {
    totalApprovedAmount: 0,
    totalPendingAmount: 0,
    transactionIdsForApproving: []
  }

  pendingTableData.forEach((row) => {
    cash.totalPendingAmount = cash.totalPendingAmount + Number(row.cash?.amount || 0)
    row.cash && cash.transactionIdsForApproving.push(row.cash.id)

    cheque.totalPendingAmount = cheque.totalPendingAmount + Number(row.cheque?.amount || 0)
    row.cheque && cheque.transactionIdsForApproving.push(row.cheque.id)

    returns.totalPendingAmount = returns.totalPendingAmount + Number(row.returns?.amount || 0)
    row.returns && returns.transactionIdsForApproving.push(row.returns.id)

    neft.totalPendingAmount = neft.totalPendingAmount + Number(row.neft?.amount || 0)
    row.neft && neft.transactionIdsForApproving.push(row.neft.id)

    damages.totalPendingAmount = damages.totalPendingAmount + Number(row.damages?.amount || 0)
    row.damages && damages.transactionIdsForApproving.push(row.damages.id)

    others.totalPendingAmount = others.totalPendingAmount + Number(row.others?.amount || 0)
    row.others && others.transactionIdsForApproving.push(row.others.id)
  })

  approvedTableData.forEach((row) => {
    cash.totalApprovedAmount += (row.cash || [])?.reduce((acc, txn) => {
      return acc + Number(txn.amount || 0)
    }, 0)

    cheque.totalApprovedAmount += (row.cheque || [])?.reduce((acc, txn) => {
      return acc + Number(txn.amount || 0)
    }, 0)

    returns.totalApprovedAmount += (row.returns || [])?.reduce((acc, txn) => {
      return acc + Number(txn.amount || 0)
    }, 0)

    neft.totalApprovedAmount += (row.neft || [])?.reduce((acc, txn) => {
      return acc + Number(txn.amount || 0)
    }, 0)

    damages.totalApprovedAmount += (row.damages || [])?.reduce((acc, txn) => {
      return acc + Number(txn.amount || 0)
    }, 0)

    others.totalApprovedAmount += (row.others || [])?.reduce((acc, txn) => {
      return acc + Number(txn.amount || 0)
    }, 0)

    qrLink.totalApprovedAmount += (row.qrLink || [])?.reduce((acc, txn) => {
      return acc + Number(txn.amount || 0)
    }, 0)
  })

  return {
    cash,
    cheque,
    returns,
    neft,
    damages,
    others,
    qrLink
  }
}

export const getAssignmentFilters = (versions: FETCH_LIST_API_RESPONSE['versions']): any => {
  let assignmentFilter = undefined
  if (versions) {
    const { curr, past, prev } = versions
    assignmentFilter = {
      ...(curr && { [CL_VERSIONS.CURRENT_ASSIGNMENT]: curr }),
      ...(past && { [CL_VERSIONS.PAST_ASSIGNMENT]: past }),
      ...(prev && { [CL_VERSIONS.PREVIOUS_ASSIGNMENT]: prev })
    }
  }
  return assignmentFilter
}

export const getDataMappedById = (
  responseData: FETCH_LIST_API_RESPONSE
): {
  accountDetailsByAccountId: Record<string, NewAccount>
  billDetailsByBillId: Record<string, NewBill>
} => {
  const accountDetailsByAccountId: Record<string, NewAccount> = responseData.accounts.reduce(
    (acc, account) => Object.assign(acc, { [account.id]: account }),
    {}
  )

  const billDetailsByBillId: Record<string, NewBill> =
    responseData?.bills?.reduce((acc, bill) => Object.assign(acc, { [bill.bill_id]: bill }), {}) ||
    {}

  return { accountDetailsByAccountId, billDetailsByBillId }
}

export function getExistingAccountIdsAndBills(responseData: FETCH_LIST_API_RESPONSE) {
  if (responseData.list.due_config.config === DUE_CONFIG.BALANCE_DUE) {
    return {
      existingAccountIds: responseData.accounts.map((account) => account.id)
    }
  }
  return {
    existingBills:
      responseData.bills?.map((bill) => ({
        bill_id: bill.bill_id,
        customer_id: bill.customer_id
      })) || []
  }
}

const getBeatsForTable = (showUnassignedBeat: boolean, beatsMap: Map<string, string>) => {
  return [
    ...(showUnassignedBeat ? [{ id: DEFAULT_BEAT, name: DEFAULT_BEAT }] : []),
    ...Array.from(beatsMap, ([id, name]) => ({ id, name }))
  ]
}

const getRoutesForTable = (showUnassignedRoute: boolean, routesMap: Map<string, string>) => {
  return [
    ...(showUnassignedRoute ? [{ id: DEFAULT_ROUTE, name: DEFAULT_ROUTE }] : []),
    ...Array.from(routesMap, ([id, name]) => ({ id, name }))
  ]
}

export const createNoActionsTableAndOnHoldData = ({
  isCustomerList,
  accounts,
  bills,
  accountDetailsByAccountId,
  pendingTableRowDataMappedWithBilldId,
  billToRedeliver,
  billBeats,
  pendingTableData
}: {
  isCustomerList: boolean
  accounts: NewAccount[]
  bills: NewBill[]
  accountDetailsByAccountId: Record<string, NewAccount>
  pendingTableRowDataMappedWithBilldId: Record<
    string,
    (NewCollection | IReplacement | IUnapprovedCancelRequest | IUnapprovedDeliveryAttempts)[]
  >
  billToRedeliver: string[]
  billBeats?: IBillBeat[]
  pendingTableData: IPendingTable[]
}) => {
  let beatsforNoActionTable: { id: string; name: string }[] = []
  const beatsMap = new Map()
  let routesforNoActionTable: { id: string; name: string }[] = []
  const routesMap = new Map()
  let showUnassignedBeat = false
  let showUnassignedRoute = false
  const noActionsTableData: INoActionsTable[] = []
  const rescheduledTableData: IRescheduledDeliverieTable[] = []

  const onHoldBillsData: ISupplyListState['onHoldBillsData'] = []
  if (isCustomerList && accounts) {
    const accounttIdsWithPendingActions = pendingTableData?.map((row) => row.accountId)
    accounts.forEach((account) => {
      if ((account.account_beats || []).length === 0) {
        showUnassignedBeat = true
      }
      account.account_beats?.forEach((beat) => {
        if (!beatsMap.has(beat.beat_id)) {
          beatsMap.set(beat.beat_id, beat.name)
        }
      })
      if ((account.account_routes || []).length === 0) {
        showUnassignedRoute = true
      }
      account.account_routes?.forEach((route) => {
        if (!routesMap.has(route.route_id)) {
          routesMap.set(route.route_id, route.name)
        }
      })
      const hideRowIfBalIsZero = account.bal !== 0
      if (hideRowIfBalIsZero || !accounttIdsWithPendingActions.includes(account.id)) {
        noActionsTableData.push({
          customerName: account.name,
          invoiceNumber: '-',
          invoiceAge: 'Today',
          invoiceAmount: account.bal / 100,
          buttonType: BUTTON_TYPE.ADD_TO_COLLECTION,
          remainingAmount: account.bal / 100,
          customerId: account.id,
          billId: '',
          beats: account.beats || [],
          accountBeats: account.account_beats,
          accountRoutes: account.account_routes
        })
      }
    })
  }

  const isBillPresentInPendingTable = (billId: string) => {
    return pendingTableRowDataMappedWithBilldId[billId]
  }

  if (bills) {
    bills.forEach((bill) => {
      if (bill.status === BILL_STATUS.HOLD) {
        onHoldBillsData.push({
          billId: bill.bill_id,
          billNumber: bill.bill_number,
          billAmount: Number(bill.total_amount) / 100
        })
      }

      if (billToRedeliver?.length && billToRedeliver?.includes(bill.bill_id)) {
        rescheduledTableData.push({
          customerName: accountDetailsByAccountId[bill.customer_id].name,
          invoiceNumber: bill.bill_number,
          invoiceAge: getInvoiceAge(bill.bill_date),
          invoiceAmount:
            (Number(bill?.total_amount || 0) - Number(bill?.total_paid_amount || 0)) / 100,
          invoiceAmountPaid: Number(bill?.total_paid_amount || 0) / 100,
          billDate: String(bill.bill_date),
          customerId: bill.customer_id,
          billId: bill.bill_id
        })
      }

      if (
        bill.status !== BILL_STATUS.HOLD &&
        bill.status !== BILL_STATUS.PAID &&
        bill?.status !== BILL_STATUS.REMOVED &&
        !isBillPresentInPendingTable(bill.bill_id) &&
        !billToRedeliver?.includes(bill.bill_id)
        // should not be present in redilvery table
      ) {
        const accountData = accountDetailsByAccountId[bill.customer_id]
        const { beats = [], name, account_beats, account_routes } = accountData
        account_beats?.forEach((beat) => {
          if (!beatsMap.has(beat.beat_id)) {
            beatsMap.set(beat.beat_id, beat.name)
          }
        })
        const rowBillBeats = billBeats?.filter((beat) => beat.bill_id === bill.bill_id)
        rowBillBeats?.forEach((beat) => {
          if (!beatsMap.has(beat.beat_id)) {
            beatsMap.set(beat.beat_id, beat.name)
          }
        })
        if ((account_beats || []).length + (rowBillBeats || []).length === 0) {
          showUnassignedBeat = true
        }
        account_routes?.forEach((route) => {
          if (!routesMap.has(route.route_id)) {
            routesMap.set(route.route_id, route.name)
          }
        })
        if ((account_routes || []).length === 0) {
          showUnassignedRoute = true
        }
        noActionsTableData.push({
          customerName: name,
          invoiceNumber: bill.bill_number,
          invoiceAge: getInvoiceAge(bill.bill_date),
          invoiceAmount: Number(bill?.total_amount || 0),
          buttonType: BUTTON_TYPE.ADD_TO_COLLECTION,
          remainingAmount:
            (Number(bill?.total_amount || 0) - Number(bill?.total_paid_amount || 0)) / 100,
          customerId: bill.customer_id,
          billId: bill.bill_id,
          beats: beats || [],
          billDate: String(bill.bill_date),
          accountBeats: account_beats,
          billBeats: rowBillBeats,
          accountRoutes: account_routes
        })
      }
    })
  }
  beatsforNoActionTable = getBeatsForTable(showUnassignedBeat, beatsMap)
  routesforNoActionTable = getRoutesForTable(showUnassignedRoute, routesMap)
  return {
    noActionsTableData,
    onHoldBillsData,
    beatsforNoActionTable,
    rescheduledTableData,
    routesforNoActionTable
  }
}

export const createApprovedTable = ({
  collections,
  billDetailsByBillId,
  accountDetailsByAccountId,
  bills,
  billBeats
}: {
  collections: NewCollection[] | null
  billDetailsByBillId: Record<string, NewBill>
  accountDetailsByAccountId: Record<string, NewAccount>
  bills: NewBill[]
  billBeats?: IBillBeat[]
}) => {
  const approveTableRowDataForDeletedInvoices: Record<string, NewBill> = {}
  const approveTableRowDataMappedWithBillId: Record<string, NewCollection[]> = {}

  ;(function addTransactionsForRow() {
    if (collections) {
      collections.forEach((collection) => {
        if (collection.is_approved) {
          const rowId = collection.bill_id || collection.account_id
          if (!approveTableRowDataMappedWithBillId[rowId]) {
            approveTableRowDataMappedWithBillId[rowId] = [collection]
          } else {
            approveTableRowDataMappedWithBillId[rowId] = [
              ...approveTableRowDataMappedWithBillId[rowId],
              collection
            ]
          }
        }
      })
    }
  })()
  ;(function addRowForRemovedBills() {
    if (bills) {
      bills.forEach((bill) => {
        if (bill.status === BILL_STATUS.REMOVED) {
          const rowId = bill.bill_id
          approveTableRowDataForDeletedInvoices[rowId] = bill
        }
      })
    }
  })()

  const { approveTableData, beatsforApproveTable, routesforApproveTable } = createApproveTableData({
    approveTableRowDataMappedWithBillId,
    billDetailsByBillId,
    accountDetailsByAccountId,
    approveTableRowDataForDeletedInvoices,
    billBeats
  })

  return { approveTableData, beatsforApproveTable, routesforApproveTable }
}

const createApproveTableData = ({
  approveTableRowDataMappedWithBillId,
  billDetailsByBillId,
  accountDetailsByAccountId,
  approveTableRowDataForDeletedInvoices,
  billBeats
}: {
  approveTableRowDataMappedWithBillId: Record<string, NewCollection[]>
  billDetailsByBillId: Record<string, NewBill>
  accountDetailsByAccountId: Record<string, NewAccount>
  approveTableRowDataForDeletedInvoices: Record<string, NewBill>
  billBeats?: IBillBeat[]
}) => {
  let beatsforApproveTable: { id: string; name: string }[] = []
  const beatsMap = new Map()
  let routesforApproveTable: { id: string; name: string }[] = []
  const routesMap = new Map()
  let showUnassignedBeat = false
  let showUnassignedRoute = false
  const approveTableData: IApproveTable[] = []

  if (!approveTableRowDataMappedWithBillId.length) {
    for (const billId in approveTableRowDataForDeletedInvoices) {
      const billDetails = approveTableRowDataForDeletedInvoices[billId]
      const customerDetails = accountDetailsByAccountId[billDetails.customer_id]

      const rowData = {
        billId: billDetails.bill_id,
        customerName: customerDetails.name,
        invoiceNumber: billDetails.bill_number || '-',
        invoiceDate: Number(billDetails.updated_at || 0),
        invoiceAmount: Number(billDetails?.total_amount) / 100,
        invoiceAmountPaid: Number(billDetails?.total_paid_amount || 0) / 100,
        buttonType: BUTTON_TYPE.REMOVED_BUTTON,
        beats: customerDetails.beats,
        pendingDue:
          (Number(billDetails?.total_amount || 0) - Number(billDetails?.total_paid_amount || 0)) /
          100
      }

      approveTableData.push(rowData as IApproveTable)
    }
  }

  for (const billIdOrAccountId in approveTableRowDataMappedWithBillId) {
    const rowDataWithAllTransactions = approveTableRowDataMappedWithBillId[billIdOrAccountId]
    const billDetails = billDetailsByBillId[billIdOrAccountId]
    const accountDetails = accountDetailsByAccountId[billIdOrAccountId]
    const {
      name,
      beats = [],
      account_beats,
      account_routes
    } = accountDetailsByAccountId[billDetails?.customer_id] || accountDetails
    account_beats?.forEach((beat) => {
      if (!beatsMap.has(beat.beat_id)) {
        beatsMap.set(beat.beat_id, beat.name)
      }
    })
    const rowBillBeats = billBeats?.filter((beat) => beat.bill_id === billDetails?.bill_id)
    rowBillBeats?.forEach((beat) => {
      if (!beatsMap.has(beat.beat_id)) {
        beatsMap.set(beat.beat_id, beat.name)
      }
    })
    if ((account_beats || []).length + (rowBillBeats || []).length === 0) {
      showUnassignedBeat = true
    }
    account_routes?.forEach((route) => {
      if (!routesMap.has(route.route_id)) {
        routesMap.set(route.route_id, route.name)
      }
    })
    if ((account_routes || []).length === 0) {
      showUnassignedRoute = true
    }
    const { buttonType, transactionId = '' } =
      rowDataWithAllTransactions.length > 1
        ? { buttonType: BUTTON_TYPE.DROPDOWN_BUTTON }
        : { buttonType: BUTTON_TYPE.CANCEL_BUTTON, transactionId: rowDataWithAllTransactions[0].id }

    let rowData: any = {
      billId: billDetails?.bill_id || '-',
      customerName: name,
      invoiceNumber: billDetails?.bill_number || '-',
      invoiceDate: Number(billDetails?.latest_settlement_date || 0),
      invoiceAmount: Number(billDetails?.total_amount || accountDetails?.bal) / 100,
      invoiceAmountPaid: Number(billDetails?.total_paid_amount || 0) / 100,
      buttonType,
      cancelBillTxnId: transactionId,
      beats,
      pendingDue:
        (Number(billDetails?.total_amount || -accountDetails?.bal || 0) -
          Number(billDetails?.total_paid_amount || 0)) /
        100,
      accountBeats: account_beats,
      billBeats: rowBillBeats,
      accountRoutes: account_routes
    }
    const transactionTimeArray: number[] = []
    const approvedTxnSummationAmount = 0
    rowDataWithAllTransactions.forEach((txn) => {
      if (TRANSACTION_TYPE_KEY_MAPPER[txn.type] in rowData) {
        // multiple payments for same type of transaction for eg cash 20Rs, cash 21Rs
        rowData = {
          ...rowData,
          [TRANSACTION_TYPE_KEY_MAPPER[txn.type]]: [
            ...rowData[TRANSACTION_TYPE_KEY_MAPPER[txn.type]],
            {
              id: txn.id,
              amount: `${Number(txn.amount) / 100}`,
              txn_type: txn.type,
              txn_time: dayjs(txn.transaction_time).unix()
            }
          ]
        }
        transactionTimeArray.push(dayjs(txn.updated_at).unix())
      } else {
        rowData = {
          ...rowData,
          [TRANSACTION_TYPE_KEY_MAPPER[txn.type]]: [
            {
              id: txn.id,
              amount: `${Number(txn.amount) / 100}`,
              txn_type: txn.type,
              txn_time: dayjs(txn.transaction_time).unix()
            }
          ]
        }
        transactionTimeArray.push(dayjs(txn.updated_at).unix())
      }
    })

    if (transactionTimeArray.length > 0) {
      const maxTime = Math.max(...transactionTimeArray)
      rowData.invoiceDate = maxTime
    }

    if (billDetails?.status !== BILL_STATUS.PAID) {
      rowData.pendingDue = rowData.pendingDue - approvedTxnSummationAmount
    }
    approveTableData.push(rowData as IApproveTable)
  }
  beatsforApproveTable = getBeatsForTable(showUnassignedBeat, beatsMap)
  routesforApproveTable = getRoutesForTable(showUnassignedRoute, routesMap)
  return { approveTableData, beatsforApproveTable, routesforApproveTable }
}

const isBillOnHold = (billId: string, billDetailsByBillId: Record<string, NewBill>) => {
  return billDetailsByBillId[billId]?.status === BILL_STATUS.HOLD
}
const isBillDeleted = (billId: string, billDetailsByBillId: Record<string, NewBill>) => {
  return billDetailsByBillId[billId]?.status === BILL_STATUS.REMOVED
}

const isAnyOtherTransactionPresent = (data: Record<string, any>) => {
  return (
    'damages' in data ||
    'cash' in data ||
    'neft' in data ||
    'cheque' in data ||
    'advance' in data ||
    'qrLink' in data ||
    'returns' in data ||
    'others' in data
  )
}

export const createPendingTable = ({
  isCustomerList,
  collections,
  billDetailsByBillId,
  unapprovedCancelRequests,
  replacements,
  accountDetailsByAccountId,
  unApprovedDeliveryAttempts,
  billBeats
}: {
  isCustomerList: boolean
  collections: NewCollection[] | null
  billDetailsByBillId: Record<string, NewBill>
  unapprovedCancelRequests: IUnapprovedCancelRequest[]
  replacements: IReplacement[]
  accountDetailsByAccountId: Record<string, NewAccount>
  unApprovedDeliveryAttempts: IUnapprovedDeliveryAttempts[] | null
  billBeats?: IBillBeat[]
}) => {
  const pendingTableRowDataMappedWithBilldId: Record<
    string,
    (NewCollection | IReplacement | IUnapprovedCancelRequest | IUnapprovedDeliveryAttempts)[]
  > = {}

  ;(function addTransactionsForRow() {
    if (collections) {
      collections.forEach((collection) => {
        if (!collection.is_approved) {
          const rowId = collection.bill_id || collection.account_id
          if (collection.bill_id && isBillOnHold(collection.bill_id, billDetailsByBillId)) {
            return
          }
          if (!pendingTableRowDataMappedWithBilldId[rowId]) {
            pendingTableRowDataMappedWithBilldId[rowId] = [collection]
          } else {
            pendingTableRowDataMappedWithBilldId[rowId] = [
              ...pendingTableRowDataMappedWithBilldId[rowId],
              collection
            ]
          }
        }
      })
    }
  })()
  function addUnApprovedCancelRequestInRowData() {
    if (unapprovedCancelRequests) {
      unapprovedCancelRequests
        .filter((request) => {
          return (
            !!request.bill_id && billDetailsByBillId[request.bill_id].status !== BILL_STATUS.REMOVED
          )
        })
        .forEach((request) => {
          const rowId = request.bill_id || request.account_id
          if (!pendingTableRowDataMappedWithBilldId[rowId]) {
            pendingTableRowDataMappedWithBilldId[rowId] = [request]
          } else {
            pendingTableRowDataMappedWithBilldId[rowId] = [
              ...pendingTableRowDataMappedWithBilldId[rowId],
              request
            ]
          }
        })
    }
  }
  function addUnReplacementInRowData() {
    if (replacements) {
      replacements.forEach((replacement) => {
        if (replacement.bill_id) {
          if (isBillOnHold(replacement.bill_id, billDetailsByBillId)) {
            return
          }

          if (!pendingTableRowDataMappedWithBilldId[replacement.bill_id]) {
            pendingTableRowDataMappedWithBilldId[replacement.bill_id] = [replacement]
          } else {
            pendingTableRowDataMappedWithBilldId[replacement.bill_id] = [
              ...pendingTableRowDataMappedWithBilldId[replacement.bill_id],
              replacement
            ]
          }
        }
      })
    }
  }
  function addUnApprovedDeliveryAttempts() {
    if (unApprovedDeliveryAttempts) {
      unApprovedDeliveryAttempts.forEach((deliveryAttempts) => {
        if (deliveryAttempts.bill_id) {
          if (
            isBillOnHold(deliveryAttempts.bill_id, billDetailsByBillId) ||
            isBillDeleted(deliveryAttempts.bill_id, billDetailsByBillId)
          ) {
            return
          }

          if (!pendingTableRowDataMappedWithBilldId[deliveryAttempts.bill_id]) {
            pendingTableRowDataMappedWithBilldId[deliveryAttempts.bill_id] = [deliveryAttempts]
          } else {
            pendingTableRowDataMappedWithBilldId[deliveryAttempts.bill_id] = [
              ...pendingTableRowDataMappedWithBilldId[deliveryAttempts.bill_id],
              deliveryAttempts
            ]
          }
        }
      })
    }
  }
  if (!isCustomerList) {
    addUnApprovedDeliveryAttempts()
    addUnReplacementInRowData()
    addUnApprovedCancelRequestInRowData()
  }

  const { pendingTableData, beatsforPendingActionTable, routesforPendingActionTable } =
    createPendingTableData({
      pendingTableRowDataMappedWithBilldId,
      billDetailsByBillId,
      accountDetailsByAccountId,
      billBeats
    })
  return {
    pendingTableData,
    pendingTableRowDataMappedWithBilldId,
    beatsforPendingActionTable,
    routesforPendingActionTable
  }
}

const createPendingTableData = ({
  pendingTableRowDataMappedWithBilldId,
  billDetailsByBillId,
  accountDetailsByAccountId,
  billBeats
}: {
  pendingTableRowDataMappedWithBilldId: Record<
    string,
    (
      | NewCollection
      | IReplacement
      | IUnapprovedCancelRequest
      | IUnapprovedDeliveryAttempts
      | IReturns
      | IDamage
    )[]
  >
  billDetailsByBillId: Record<string, NewBill>
  accountDetailsByAccountId: Record<string, NewAccount>
  billBeats?: IBillBeat[]
}) => {
  let beatsforPendingActionTable: { id: string; name: string }[] = []
  const beatsMap = new Map()
  let routesforPendingActionTable: { id: string; name: string }[] = []
  const routesMap = new Map()
  let showUnassignedBeat = false
  let showUnassignedRoute = false
  const pendingTableData: IPendingTable[] = []
  for (const billIdOrAccountId in pendingTableRowDataMappedWithBilldId) {
    const rowDataWithAllTransactions = pendingTableRowDataMappedWithBilldId[billIdOrAccountId]
    const billDetails = billDetailsByBillId[billIdOrAccountId]
    const accountDetails = accountDetailsByAccountId[billIdOrAccountId]

    const {
      name,
      beats = [],
      account_beats,
      account_routes
    } = accountDetailsByAccountId[billDetails?.customer_id] ||
      accountDetails || { name: '', beats: [] }

    account_beats?.forEach((beat) => {
      if (!beatsMap.has(beat.beat_id)) {
        beatsMap.set(beat.beat_id, beat.name)
      }
    })
    const rowBillBeats = billBeats?.filter((beat) => beat.bill_id === billDetails?.bill_id)
    rowBillBeats?.forEach((beat) => {
      if (!beatsMap.has(beat.beat_id)) {
        beatsMap.set(beat.beat_id, beat.name)
      }
    })
    if ((account_beats || []).length + (rowBillBeats || []).length === 0) {
      showUnassignedBeat = true
    }
    account_routes?.forEach((route) => {
      if (!routesMap.has(route.route_id)) {
        routesMap.set(route.route_id, route.name)
      }
    })
    if ((account_routes || []).length === 0) {
      showUnassignedRoute = true
    }
    let rowData: any = {
      customerName: name,
      beats,
      accountId: accountDetails?.id,
      billId: billDetails?.bill_id,
      invoiceNumber: billDetails?.bill_number || '',
      invoiceDate: Number(billDetails?.latest_settlement_date || 0) || '',
      invoiceAmountPaid: Number(billDetails?.total_paid_amount || 0) / 100,
      invoiceAmount:
        Number(
          Number(billDetails?.total_amount) - Number(billDetails?.total_paid_amount || 0) ||
            accountDetails?.bal ||
            0
        ) / 100,
      buttonType: BUTTON_TYPE.APPROVE_BUTTON,
      isSelectable: true,
      pendingDue:
        (Number(billDetails?.total_amount || -accountDetails?.bal || 0) -
          Number(billDetails?.total_paid_amount || 0)) /
        100,
      accountBeats: account_beats,
      billBeats: rowBillBeats,
      accountRoutes: account_routes
    }

    const transactionTimeArray: number[] = []
    let sumationOfTransactionAmount = 0
    rowDataWithAllTransactions.forEach((txn) => {
      if ('type' in txn) {
        // if type is in txn then its of type  NewCollection
        rowData = {
          ...rowData,
          [TRANSACTION_TYPE_KEY_MAPPER[txn.type]]: {
            id: txn.id,
            amount: `${Number(txn.amount) / 100}`,
            txn_type: txn.type,
            txn_time: dayjs(txn.transaction_time).unix(),
            ...(txn.type === TRANSACTION_TYPE.RETURN || txn.type === TRANSACTION_TYPE.DAMAGED
              ? {
                  items: txn.items,
                  catalog_items: txn.catalog_items,
                  return_items: txn.return_items.map((returnItem) => ({
                    ...returnItem,
                    name: billDetails.bill_info?.items?.[returnItem?.index || 0]?.name
                  }))
                }
              : {})
          }
        }
        transactionTimeArray.push(dayjs(txn.transaction_time).unix())
        if (txn.type !== TRANSACTION_TYPE.WALLET) {
          sumationOfTransactionAmount = sumationOfTransactionAmount + Number(txn.amount) / 100
        }
      } else if ('cancel_requested' in txn) {
        // if cancel_requested is in txn then its of type IUnapprovedCancelRequest
        rowData = {
          ...rowData,
          cancelBillTxnId: txn.bill_id,
          buttonType: BUTTON_TYPE.DELETE_BUTTON,
          isSelectable: false,
          invoiceDate: Math.floor(new Date(txn.requested_at).getTime() / 1000) || ''
        }
      } else if ('staff_uid' in txn && 'list_id' in txn) {
        // if staff_uid & list_id is in txn then its of type IUnapprovedDeliveryAttempts
        rowData = {
          ...rowData,
          unapprovedDeliveryAttemptId: txn.id,
          buttonType: BUTTON_TYPE.DELIVERY_BUTTON,
          isSelectable: false,
          invoiceDate: Number(txn.created_at || 0) || ''
        }
      } else {
        // NewReplacement
        rowData = {
          ...rowData,
          replace: { ...txn, amount: String(Number(txn.amount) / 100) },
          buttonType: BUTTON_TYPE.REPLACEMENT_BUTTON,
          isSelectable: false
        }
      }
    })
    ;(function setLatestTransactionDate() {
      if (transactionTimeArray.length > 0) {
        const maxTime = Math.max(...transactionTimeArray)
        rowData.invoiceDate = maxTime
      }
    })()

    if ('replace' in rowData) {
      const showSelectButton = isAnyOtherTransactionPresent(rowData)
      if (showSelectButton) {
        rowData.buttonType = BUTTON_TYPE.APPROVE_BUTTON
        rowData.isSelectable = true
      }
    }
    ;(function setPendingDue() {
      // FE computes the pending due
      // Pending Due = total_Amount - total_unpaid_amount - sumation_Of_All_Transactions_Amount)
      rowData.pendingDue = rowData.pendingDue - sumationOfTransactionAmount
    })()
    pendingTableData.push(rowData)
  }
  beatsforPendingActionTable = getBeatsForTable(showUnassignedBeat, beatsMap)
  routesforPendingActionTable = getRoutesForTable(showUnassignedRoute, routesMap)
  return { pendingTableData, beatsforPendingActionTable, routesforPendingActionTable }
}

export const getVersion = (version?: CL_VERSIONS, storeVersion?: CL_VERSIONS) => {
  if (version !== undefined) {
    return version
  }

  if (storeVersion !== undefined) {
    return storeVersion
  }

  return CL_VERSIONS.CURRENT_ASSIGNMENT
}

export const computeAddedCreditAndPendingDue = ({
  dueConfig,
  bills,
  accounts,
  summaryData
}: {
  dueConfig: DUE_CONFIG
  bills: NewBill[]
  accounts: NewAccount[]
  summaryData: Record<
    SummaryKeys,
    {
      totalApprovedAmount: number
      totalPendingAmount: number
      transactionIdsForApproving: string[]
    }
  >
}) => {
  let addedCredit
  if (dueConfig !== DUE_CONFIG.BALANCE_DUE) {
    addedCredit = bills.reduce((acc, bill) => acc + Number(bill.total_amount / 100), 0)
  } else {
    addedCredit = Object.values(summaryData).reduce(
      (acc, data) => acc + data.totalApprovedAmount,
      0
    )
  }

  let totalPendingDue: number
  if (dueConfig !== DUE_CONFIG.BALANCE_DUE) {
    totalPendingDue = bills.reduce(
      (acc: number, bill) =>
        acc + (Number(bill.total_amount) - Number(bill.total_paid_amount || 0)) / 100,
      0
    )
  } else {
    totalPendingDue = -accounts.reduce((acc: number, account) => acc + Number(account.bal) / 100, 0)
  }

  return { addedCredit, totalPendingDue }
}
