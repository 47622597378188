import { ISummaryType } from 'pages/Statements/Statements.constants'
import { IStatementsState, VIEW } from './statements.types'

export const StatementsState: IStatementsState = {
  loaders: {
    createGroup: false,
    getOwnerSummary: false,
    approveTransactions: false,
    txnAdvanceAndRemoveAction: false,
    download: false,
    replacements: false,
    replacementAction: false,
    isAppending: false
  },
  drawers: {
    createGroup: false,
    approveTransactions: false,
    txnAdvanceAndRemovePopup: false,
    removeGroup: false,
    replacements: false
  },
  ownerSummary: {
    groupSummary: null,
    customersSummary: null
  },
  unapprovedTxns: [],
  selectedDataForTxnAdvanceAndRemove: null,
  accountAllocationDetails: {
    allocatedAccounts: [],
    unallocatedAccounts: []
  },
  currentView: VIEW.GROUP_SUMMARY_VIEW,
  currentSummaryType: ISummaryType.INVOICE_DUE,
  currentCollectionListId: null,
  downloadData: {
    status: 'default'
  },
  replacements: [],
  v2SummaryDownloadDrawer: 'uninitiated',
  replacementSummaryDownloadDrawer: 'uninitiated'
}
