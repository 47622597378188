export interface ICollectionSummaryDrawer {
  isOpen: boolean
  toggleOpen: () => void
  dueCustomersList: Array<any>
  paidCustomersList: Array<any>
}

export enum TRANSACTION_TYPE {
  NA = 0,
  CASH = 1,
  ONLINE = 2,
  CREDIT_DUES = 3,
  CHEQUE = 4,
  NEFT = 5,
  RETURN = 6,
  DAMAGED = 7,
  UPI = 8,
  WALLET = 9
}
