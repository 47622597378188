import {
  BillInfo,
  Cheque,
  ChequeState,
  ChequeType,
  IFetchChequeResponsePayload,
  PaginationType
} from './cheque.types'

export const DEFAULT_PAGE_SIZE = 100

export const formatChequesData = (
  data: IFetchChequeResponsePayload
): { cheques: Cheque[]; pagination: PaginationType } => {
  const {
    pagination_details: { per_page, page, total_items },
    cheque_details
  } = data

  const cheques = cheque_details.reduce((result: Cheque[], cheque) => {
    cheque.bills.forEach((bill: BillInfo) => {
      result.push({
        customerName: cheque.account_info.name,
        invoiceNumber: bill.bill_number,
        invoiceDate: bill.bill_date,
        collectedOn: cheque.collected_on,
        chequeDate: cheque.cheque_date,
        chequeNo: cheque.cheque_number,
        amount: Number(cheque.amount || 0) / 100,
        chequeId: cheque.cheque_id
      })
    })
    return result
  }, [])
  return {
    cheques,
    pagination: {
      pageSize: Number(per_page),
      currentPage: Number(page),
      totalEntries: Number(total_items)
    }
  }
}

export const CheuqueStateToStoreMapper: Record<any, ChequeType> = {
  [ChequeState.RECEIVED]: 'receivedCheque',
  [ChequeState.DEPOSITED]: 'depositedCheque',
  [ChequeState.CLEARED]: 'clearedCheque'
}
