import { Action } from 'infra/types'
import { IStaffBusinessSettings } from 'experiments/StaffLink/StaffLink.types'
import { IAbExp, IAbExperiment } from './experiment.types'
export const ExpActions = {
  GET_AB_PROFILE_REQUEST: 'experiment/GET_AB_PROFILE_REQUEST',
  GET_AB_PROFILE_SUCCESS: 'experiment/GET_AB_PROFILE_SUCCESS',
  GET_AB_PROFILE_FAIL: 'experiment/GET_AB_PROFILE_FAIL',

  // Business settings
  GET_STAFF_BUSINESS_SETTINGS: 'experiment/GET_STAFF_BUSINESS_SETTINGS',
  GET_STAFF_BUSINESS_SETTINGS_SUCCESS: 'experiment/GET_STAFF_BUSINESS_SETTINGS_SUCCESS'
}
export const getAbProfile = (): Action<null> => ({
  type: ExpActions.GET_AB_PROFILE_REQUEST,
  payload: null
})

export const getAbProfileSuccess = (feature: IAbExp, experiment: IAbExperiment): Action<any> => ({
  type: ExpActions.GET_AB_PROFILE_SUCCESS,
  payload: { feature, experiment }
})

export const getAbProfileFail = (payload: string): Action<string> => ({
  type: ExpActions.GET_AB_PROFILE_FAIL,
  payload
})

export const getStaffBusinessSettingsAction = (merchantId: string): Action<string> => ({
  type: ExpActions.GET_STAFF_BUSINESS_SETTINGS,
  payload: merchantId
})

export const getStaffBusinessSettingsSuccess = (
  payload: IStaffBusinessSettings
): Action<IStaffBusinessSettings> => ({
  type: ExpActions.GET_STAFF_BUSINESS_SETTINGS_SUCCESS,
  payload
})
