/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put, delay } from '@redux-saga/core/effects'
import { Action } from 'infra/types'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import {
  fetchTags,
  createTag,
  updateTag,
  linkDelinkAccountTags,
  fetchAccountTags,
  fetchInvoiceTags,
  linkDelinkInvoiceTags
} from 'services/Api/StafflinkApi'
import { forwardTo } from 'state/common'
import { IDispatchType } from 'services/Api/types'
import { API_RESPONSE_TYPE, IApiResponse } from './../../constants'
import {
  createTagFailure,
  createTagSuccess,
  getAccountTags,
  getAccountTagsSuccess,
  getInvoiceTagsSuccess,
  getTagsSuccess,
  handleDrawerToggle,
  linkDelinkAccountTagsFailure,
  linkDelinkInvoiceTagsSuccess,
  toggleEditTagDialog,
  updateTagFailure,
  updateTagSuccess
} from './tags.actions'
import { formatInvoiceTags } from './tags.helpers'
import { IlinkDelinkInvoiceTagPayload, linkDelinkAccountTagsPayload } from './tags.types'

export function* getTagsEffect() {
  try {
    const response: IApiResponse = yield call(fetchTags)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getTagsSuccess(response?.data?.tags))
    } else {
      console.log()
    }
  } catch (e) {
    console.log(e)
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* createTagEffect(
  action: Action<{
    tags: Array<string>
  }>
) {
  try {
    const response: IApiResponse = yield call(createTag, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(createTagSuccess(response?.data?.tags))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Tag created successfully.'
        })
      )
    } else {
      yield put(createTagFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not create the tag. Please try again later.'
        })
      )
    }
  } catch (e) {
    console.log(e)
    yield put(createTagFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* updateTagEffect(
  action: Action<{
    action: 'name' | 'mark_inactive'
    tag: { id: string; name: string } | { id: string; is_active: boolean }
  }>
) {
  try {
    const response: IApiResponse = yield call(updateTag, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(updateTagSuccess(response?.data?.tag))
      yield put(getAccountTags())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText:
            action.payload.action === 'name'
              ? 'Updated tag successfully.'
              : 'Tag deleted successfully.'
        })
      )
      yield put(toggleEditTagDialog(false))
    } else {
      yield put(updateTagFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText:
            action.payload.action === 'name'
              ? 'Could not update the tag. Please try again later.'
              : 'Could not delete the tag. Please try again later.'
        })
      )
    }
  } catch (e) {
    console.log(e)
    yield put(updateTagFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getAccountTagsEffect() {
  try {
    const response: IApiResponse = yield call(fetchAccountTags)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getAccountTagsSuccess(response?.data?.account_tags))
    } else {
      console.log()
    }
  } catch (e) {
    console.log(e)
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* linkDelinkAccountTagsEffect(action: Action<linkDelinkAccountTagsPayload>) {
  try {
    const response: IApiResponse = yield call(linkDelinkAccountTags, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Updated tag(s) successfully.'
        })
      )
    } else {
      yield put(linkDelinkAccountTagsFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not update tag(s). Please try again later.'
        })
      )
      return
    }
    yield delay(1500)
    yield put(getAccountTags())
    yield call(forwardTo, IDispatchType.GO_BACK)
  } catch (e) {
    console.log(e)
    yield put(linkDelinkAccountTagsFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getInvoiceTagsEffect(
  action: Action<{ transactionId: string; accountId: string }>
) {
  try {
    const response: IApiResponse = yield call(fetchInvoiceTags, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getInvoiceTagsSuccess(formatInvoiceTags(response?.data?.bill_tags)))
    } else {
      console.log()
    }
  } catch (e) {
    console.log(e)
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* linkDelinkInvoiceTagsEffect(action: Action<IlinkDelinkInvoiceTagPayload>) {
  try {
    const response: IApiResponse = yield call(linkDelinkInvoiceTags, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(linkDelinkInvoiceTagsSuccess(response?.data?.bill_tags))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Updated tag(s) successfully.'
        })
      )
      yield put(handleDrawerToggle({ invoiceTagSelection: false }))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not update tag(s). Please try again later.'
        })
      )
      return
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}
