import { takeCustomLatest } from 'state/common/common.effects'
import { SupplyListActions } from './supply-list.actions'
import { fetchSupplyListWithId, fetchSupplyListsEffect } from './supply-list.effect'

function* fetchSupplyListSaga() {
  yield takeCustomLatest(SupplyListActions.FETCH_SUPPLY_LIST, fetchSupplyListsEffect)
}

function* fetchSpecificListSaga() {
  yield takeCustomLatest(SupplyListActions.FETCH_SPECIFIC_LIST, fetchSupplyListWithId)
}

export const SupplyListWatchers = [fetchSupplyListSaga(), fetchSpecificListSaga()]
