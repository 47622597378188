import dayjs from 'dayjs'

export const CONSTANTS = {
  HEADER_TITLE: 'Collection List Summary'
}
export enum SUMMARY_FILTER_OPTION {
  SAME_DAY_COLLECTIONS = 0,
  OLDER_COLLECTIONS = 1,
  ALL_COLLECTIONS = 2
}

export enum DATE_FILTER_TYPE {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  _7DAYS = '_7DAYS',
  _30DAYS = '_30DAYS',
  _3M = '_3M',
  CUSTOM_DATE = 'CUSTOM_DATE'
}

export const DATE_FILTER_TYPE_VS_LABEL = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  _7DAYS: '7D',
  _30DAYS: '30D',
  _3M: '3M',
  CUSTOM_DATE: 'Custom'
}

export const getDateFilterDetails = (dateFilterType: DATE_FILTER_TYPE) => {
  switch (dateFilterType) {
    case DATE_FILTER_TYPE.TODAY:
      return {
        startTime: dayjs().startOf('day').unix(),
        endTime: dayjs().unix()
      }
    case DATE_FILTER_TYPE.YESTERDAY:
      return {
        startTime: dayjs().subtract(1, 'day').startOf('day').unix(),
        endTime: dayjs().subtract(1, 'day').endOf('day').unix()
      }
    case DATE_FILTER_TYPE._7DAYS:
      return {
        startTime: dayjs().subtract(7, 'day').startOf('day').unix(),
        endTime: dayjs().unix()
      }
    case DATE_FILTER_TYPE._30DAYS:
      return {
        startTime: dayjs().subtract(30, 'day').startOf('day').unix(),
        endTime: dayjs().unix()
      }
    case DATE_FILTER_TYPE._3M:
      return {
        startTime: dayjs().subtract(3, 'month').startOf('month').unix(),
        endTime: dayjs().unix()
      }
    default:
      return {
        startTime: 0,
        endTime: dayjs().endOf('day').unix()
      }
  }
}
