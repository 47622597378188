import { takeLatest } from '@redux-saga/core/effects'
import {
  getTagsEffect,
  createTagEffect,
  updateTagEffect,
  linkDelinkAccountTagsEffect,
  getAccountTagsEffect,
  getInvoiceTagsEffect,
  linkDelinkInvoiceTagsEffect
} from './tags.effects'
import { TagsActions } from './tags.actions'

function* getTagsSaga() {
  yield takeLatest(TagsActions.FETCH_TAGS, getTagsEffect)
}

function* createTagSaga() {
  yield takeLatest(TagsActions.CREATE_TAG, createTagEffect)
}

function* updateTagSaga() {
  yield takeLatest(TagsActions.UPDATE_TAG, updateTagEffect)
}

function* getAccountTagsSaga() {
  yield takeLatest(TagsActions.FETCH_ACCOUNT_TAGS, getAccountTagsEffect)
}

function* getInvoiceTagsSaga() {
  yield takeLatest(TagsActions.FETCH_INVOICE_TAGS, getInvoiceTagsEffect)
}

function* linkDelinkAccountTagsSaga() {
  yield takeLatest(TagsActions.LINK_DELINK_ACCOUNT_TAGS, linkDelinkAccountTagsEffect)
}

function* linkDelinkInvoiceTagsSaga() {
  yield takeLatest(TagsActions.LINK_DELINK_INVOICE_TAGS, linkDelinkInvoiceTagsEffect)
}

export const TagsEffects = [
  getTagsSaga(),
  createTagSaga(),
  updateTagSaga(),
  linkDelinkAccountTagsSaga(),
  getAccountTagsSaga(),
  getInvoiceTagsSaga(),
  linkDelinkInvoiceTagsSaga()
]
