import { Action } from 'shared/infra/types'
import { ApprovedCollectionSummaryActions } from './approvedCollectionSummary.actions'
import { ApprovedCollectionSummaryState } from './approvedCollectionSummary.state'
import { IApprovedCollectionSummaryState } from './approvedCollectionSummary.types'

export const ApprovedCollectionSummaryReducer = (
  state: IApprovedCollectionSummaryState = ApprovedCollectionSummaryState,
  action: Action<any>
): IApprovedCollectionSummaryState => {
  switch (action.type) {
    case ApprovedCollectionSummaryActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case ApprovedCollectionSummaryActions.SET_SORT_BY: {
      const { sortBy, sortOrder } = action.payload
      return {
        ...state,
        sortBy,
        sortOrder
      }
    }
    case ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTIONS_SUMMARY_SUCCESS: {
      return {
        ...state,
        listViewData: {
          rowData: action.payload.listViewData,
          pagination: action.payload.pagination
        },
        overallSummaryData: action.payload.overallSummaryData
      }
    }
    case ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTION_GROUP_SUMMARY_SUCCESS: {
      return {
        ...state,
        groupViewData: {
          rowData: action.payload.rowData,
          overallSummaryData: action.payload.overallSummaryData,
          pagination: action.payload.pagination,
          sortBy: action.payload.sortBy,
          sortOrder: action.payload.sortOrder
        }
      }
    }
    case ApprovedCollectionSummaryActions.SET_SUMMARY_ACTIVE_FILTER: {
      return {
        ...state,
        activeFilter: action.payload
      }
    }
    case ApprovedCollectionSummaryActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.id]: action.payload.value
        }
      }
    }
    default: {
      return state
    }
  }
}
