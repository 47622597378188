import { Action } from './OrderQR.types'

export const OrderQRActions = {
  GET_UPI_ID: 'orderQR/GET_UPI_ID',
  SET_AUTH_TOKEN: 'orderQR/SET_AUTH_TOKEN',
  SET_MERCHANT_DETAILS: 'orderQR/SET_MERCHANT_DETAILS',
  UPDATE_UPI_ID: 'orderQR/UPDATE_UPI_ID',
  UPDATE_UPI_SUCCESS: 'orderQR/UPDATE_UPI_SUCCESS',
  GET_SHIPPING_ADDRESS: 'orderQR/GET_SHIPPING_ADDRESS',
  SET_SHIPPING_ADDRESS: 'orderQR/SET_SHIPPING_ADDRESS',
  SET_BANK_STATUS: 'orderQR/SET_BANK_STATUS',
  SET_CURRENT_LIMIT: 'orderQR/SET_CURRENT_LIMIT',
  GET_KYC_STATUS: 'orderQR/GET_KYC_STATUS',
  SET_KYC_STATUS: 'orderQR/SET_KYC_STATUS',
  UPDATE_SHIPPING_ADDRESS: 'orderQR/UPDATE_SHIPPING_ADDRESS',
  CONFIRM_ORDER: 'orderQR/CONFIRM_ORDER',
  CONFIRM_ORDER_STATUS: 'orderQR/CONFIRM_ORDER_STATUS',
  FETCHED_ADDRESS: 'orderQR/FETCHED_ADDRESS',
  SET_AUTH_V3: 'orderQR/SET_AUTH_V3',
  SET_BACK_CLICK: 'orderQR/SET_BACK_CLICK',
  SET_DEFAULT_LANGUAGE: 'orderQR/SET_DEFAULT_LANGUAGE',
  SET_PAGE_ERROR_STATE: 'orderQR/SET_PAGE_ERROR_STATE',
  GET_MERCHANT_DETAILS: 'orderQR/GET_MERCHANT_DETAILS',
  SET_MERCHANT_PROFILE_DATA: 'orderQR/SET_MERCHANT_PROFILE_DATA',
  GET_RETRIEVE_SUBSCRIPTION: 'orderQR/GET_RETRIEVE_SUBSCRIPTION',
  SET_RETRIEVE_SUBSCRIPTION: 'orderQR/SET_RETRIEVE_SUBSCRIPTION',
  GET_CREATE_SUBSCRIPTION: 'orderQR/CREATE_SUBSCRIPTION',
  SET_CREATE_SUBSCRIPTION: 'orderQR/SET_CREATE_SUBSCRIPTION',
  GET_QR_VPA: 'orderQR/GET_QR_VPA',
  SET_QR_VPA: 'orderQR/SET_QR_VPA'
}

export const OrderQRAnalyticsActions = {
  TRACK_ORDERQR_HOME_PAGE: 'orderQR/TRACK_ORDERQR_HOME_PAGE',
  TRACK_ORDERQR_BANK_POPUP: 'orderQR/TRACK_ORDERQR_BANK_POPUP',
  TRACK_ORDERQR_CONFIRM_BANK: 'orderQR/TRACK_ORDERQR_CONFIRM_BANK',
  TRACK_ORDERQR_ADDRESS_PAGE: 'orderQR/TRACK_ORDERQR_ADDRESS_PAGE',
  TRACK_ORDERQR_SHOP_NUMBER: 'orderQR/TRACK_ORDERQR_SHOP_NUMBER',
  TRACK_ORDERQR_ADDRESS: 'orderQR/TRACK_ORDERQR_ADDRESS',
  TRACK_ORDERQR_LANDMARK: 'orderQR/TRACK_ORDERQR_LANDMARK',
  TRACK_ORDERQR_PINCODE: 'orderQR/TRACK_ORDERQR_PINCODE',
  TRACK_ORDERQR_CITY: 'orderQR/TRACK_ORDERQR_CITY',
  TRACK_ORDERQR_STATE: 'orderQR/TRACK_ORDERQR_STATE',
  TRACK_ORDERQR_PREVIEWQR_PAGE: 'orderQR/TRACK_ORDERQR_PREVIEWQR_PAGE',
  TRACK_ORDERQR_KYC_CARD: 'orderQR/TRACK_ORDERQR_KYC_CARD',
  TRACK_ORDERQR_CONFIRM_ORDER: 'orderQR/TRACK_ORDERQR_CONFIRM_ORDER',
  TRACK_YOUR_QR_NOW_CLICK: 'orderQR/TRACK_YOUR_QR_NOW_CLICK',
  TRACK_ORDERQR_PRINT_OR_DOWNLOAD: 'orderQR/TRACK_ORDERQR_PRINT_OR_DOWNLOAD',
  TRACK_PRE_ORDER_CLICK: 'orderQR/TRACK_PRE_ORDER_CLICK',
  TRACK_ORDER_DONE_CLICK: 'orderQR/TRACK_ORDER_DONE_CLICK',
  TRACK_ORDER_STATUS_PAGE: 'orderQR/TRACK_ORDER_STATUS_PAGE'
}

export const handleGetUpiId = (data: object): Action<object> => ({
  type: OrderQRActions.GET_UPI_ID,
  payload: data
})

export const GetMerchantProfile = (data: object): Action<object> => ({
  type: OrderQRActions.GET_MERCHANT_DETAILS,
  payload: data
})

export const getRetrieveSubscription = (data: object): Action<object> => ({
  type: OrderQRActions.GET_RETRIEVE_SUBSCRIPTION,
  payload: data
})

export const handleSetAuthToken = (data: string): Action<string> => ({
  type: OrderQRActions.SET_AUTH_TOKEN,
  payload: data
})

export const setMerchantDetails = (data: object): Action<object> => ({
  type: OrderQRActions.SET_MERCHANT_DETAILS,
  payload: data
})

export const SetMerchantProfileData = (data: object): Action<object> => ({
  type: OrderQRActions.SET_MERCHANT_PROFILE_DATA,
  payload: data
})

export const GetQrVpa = (data: object): Action<object> => ({
  type: OrderQRActions.GET_QR_VPA,
  payload: data
})

export const SetQrVpa = (data: object): Action<object> => ({
  type: OrderQRActions.SET_QR_VPA,
  payload: data
})

export const createSubscription = (data: object): Action<object> => ({
  type: OrderQRActions.GET_CREATE_SUBSCRIPTION,
  payload: data
})

export const setMerchantRetrieveSubscription = (data: object): Action<object> => ({
  type: OrderQRActions.SET_RETRIEVE_SUBSCRIPTION,
  payload: data
})

export const setMerchantCreateSubscription = (data: object): Action<object> => ({
  type: OrderQRActions.SET_CREATE_SUBSCRIPTION,
  payload: data
})

export const handleUpdateUpiId = (data: object): Action<object> => ({
  type: OrderQRActions.UPDATE_UPI_ID,
  payload: data
})

export const setUpdateUpiSuccess = (data: boolean): Action<boolean> => ({
  type: OrderQRActions.UPDATE_UPI_SUCCESS,
  payload: data
})

export const getShippingAddress = (data: object): Action<object> => ({
  type: OrderQRActions.GET_SHIPPING_ADDRESS,
  payload: data
})

export const setShippingAddress = (data: object): Action<object> => ({
  type: OrderQRActions.SET_SHIPPING_ADDRESS,
  payload: data
})

export const setBankAccountStatus = (data: string): Action<string> => ({
  type: OrderQRActions.SET_BANK_STATUS,
  payload: data
})

export const getKycStatus = (data: object): Action<object> => ({
  type: OrderQRActions.GET_KYC_STATUS,
  payload: data
})

export const setKycStatus = (data: boolean): Action<boolean> => ({
  type: OrderQRActions.SET_KYC_STATUS,
  payload: data
})

export const setCurrentLimit = (data: string): Action<string> => ({
  type: OrderQRActions.SET_CURRENT_LIMIT,
  payload: data
})

export const updateShippingAddress = (data: object): Action<object> => ({
  type: OrderQRActions.UPDATE_SHIPPING_ADDRESS,
  payload: data
})

export const confirmOrder = (data: object): Action<object> => ({
  type: OrderQRActions.CONFIRM_ORDER,
  payload: data
})

export const confirmOrderStatus = (data: boolean): Action<boolean> => ({
  type: OrderQRActions.CONFIRM_ORDER_STATUS,
  payload: data
})

export const fetchedAddress = (data: boolean): Action<boolean> => ({
  type: OrderQRActions.FETCHED_ADDRESS,
  payload: data
})

export const setAuthV3 = (data: boolean): Action<boolean> => ({
  type: OrderQRActions.SET_AUTH_V3,
  payload: data
})

export const setBackClick = (data: boolean): Action<boolean> => ({
  type: OrderQRActions.SET_BACK_CLICK,
  payload: data
})

export const setdefaultLanguage = (data: string): Action<string> => ({
  type: OrderQRActions.SET_DEFAULT_LANGUAGE,
  payload: data
})

export const setPageErrorState = <T>(data: T): Action<T> => ({
  type: OrderQRActions.SET_PAGE_ERROR_STATE,
  payload: data
})

/*
 * Mixpanel event actions start
 */

export const trackOrderQrHomePage = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_HOME_PAGE,
  payload: data
})

export const trackOrderQrBankPopup = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_BANK_POPUP,
  payload: data
})

export const trackOrderQrConfirmBank = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_CONFIRM_BANK,
  payload: data
})

export const trackOrderQrAddressPage = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_ADDRESS_PAGE,
  payload: data
})

export const trackOrderQrShopNumber = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_SHOP_NUMBER,
  payload: data
})

export const trackOrderQrAddress = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_ADDRESS,
  payload: data
})

export const trackOrderQrLandmark = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_LANDMARK,
  payload: data
})

export const trackOrderQrPincode = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_PINCODE,
  payload: data
})

export const trackOrderQrCity = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_CITY,
  payload: data
})

export const trackOrderQrState = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_STATE,
  payload: data
})

export const trackOrderQrPreviewQrPage = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_PREVIEWQR_PAGE,
  payload: data
})

export const trackOrderQrKycCard = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_KYC_CARD,
  payload: data
})

export const trackOrderQrConfirmOrder = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_CONFIRM_ORDER,
  payload: data
})

export const trackYourQrNowClick = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_YOUR_QR_NOW_CLICK,
  payload: data
})

export const trackOrderQrPrintOrDownload = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDERQR_PRINT_OR_DOWNLOAD,
  payload: data
})

export const trackPreOrderClick = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_PRE_ORDER_CLICK,
  payload: data
})

export const trackOrderDoneClick = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDER_DONE_CLICK,
  payload: data
})

export const trackOrderStatusPage = <T>(data: T): Action<T> => ({
  type: OrderQRAnalyticsActions.TRACK_ORDER_STATUS_PAGE,
  payload: data
})

/*
 * Mixpanel event actions end
 */
