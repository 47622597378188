import { IDateFilter, ISummaryType } from 'pages/Statements/Statements.constants'
import { REPLACMENT_STATES } from 'state/collectionList/collectionList.types'

interface IBaseSummaryStat {
  id: string
  name: string
  invoiceCount: number
  totalCredit: number
  totalPayment: number
  netBalanceDue: number
  totalCash: number
  totalNEFT: number
  totalOnline: number
  totalCheque: number
  totalReturn: number
  totalDamage: number
}

export interface ISummaryStatForInvoiceSummary extends IBaseSummaryStat {
  pendingActionRequired: boolean
  pendingReplacements: boolean
}

export interface ISummaryStatForInvoiceAccountSummary extends IBaseSummaryStat {
  customerCode: string
}
export type ISummaryStat = ISummaryStatForInvoiceSummary | ISummaryStatForInvoiceAccountSummary

export interface IOwnerSummaryOverall {
  totalCredit: number
  totalPayment: number
  netBalanceDue: number
  accountCount: number
}

export interface IPagination {
  total: number
  page: number
  perPage: number
}

export interface ITxnAcceptOrRemove {
  merchantId: string
  txnId: string
  amount: string
  startTime?: number
  endTime?: number
}

export interface ISummary {
  level: number
  summaryStats: ISummaryStat[]
  ownerSummaryOverall: IOwnerSummaryOverall
  summaryType: ISummaryType
  pagination: IPagination
  statementGroupDetails?: any // TODO: Fix type after initial release
}

export enum VIEW {
  GROUP_SUMMARY_VIEW,
  CUSTOMERS_SUMMARY_VIEW,
  CUSTOMER_SUMMARY_VIEW
}

export interface IReplacementsItem {
  name: string
  quantity: string
  notes?: string
  state: REPLACMENT_STATES
}

export interface IUpdateReplacementRecordAction {
  replacementId: string
  merchantId: string
  accountId: string
  status: number
  amount: string | number
  billId: string
  replacementItems: IReplacementsItem[]
  activeFilter: IDateFilter
  collectionListId: string
}
export interface IStatementsState {
  loaders: {
    createGroup: boolean
    getOwnerSummary: boolean
    approveTransactions: boolean
    txnAdvanceAndRemoveAction: boolean
    download: boolean
    replacements: boolean
    replacementAction: boolean
    isAppending: boolean
  }
  drawers: {
    createGroup: boolean
    removeGroup: boolean
    approveTransactions: boolean
    txnAdvanceAndRemovePopup: boolean
    replacements: boolean
  }
  ownerSummary: {
    groupSummary: ISummary | null
    customersSummary: ISummary | null
  }
  currentView: VIEW
  currentSummaryType: ISummaryType
  currentCollectionListId: string | null
  unapprovedTxns: IUnapprovedTransaction[]
  selectedDataForTxnAdvanceAndRemove: ITxnAcceptOrRemove | null
  accountAllocationDetails: {
    allocatedAccounts: Array<string>
    unallocatedAccounts: Array<string>
  }
  downloadData: {
    status: 'default' | 'downloading' | 'downloaded'
  }
  v2SummaryDownloadDrawer: 'uninitiated' | 'initiated' | 'completed'
  replacementSummaryDownloadDrawer: 'uninitiated' | 'initiated' | 'completed'
  replacements: Array<any>
}

export type Invoice = {
  customer_name: string
  customer_code: string
  customer_id: string
  invoice_date: string
  invoice_number: string
  invoice_amount: string
  total_cash_payments: string
  total_neft_payments: string
  total_cheque_payments: string
  total_online_payments: string
  total_returns: string
  total_damages: string
  total_collections: string
  total_pending_dues: string
  invoice_id: string
  latest_settlement_date: string
  total_other_payments: string
  total_wallet_payments: string
  total_advance_collected: string
}

export type Transaction = {
  id: string
  collection_list_id: string
  amount: string
  transaction_time: string
  account_txn_id: string
  notes: {
    payment_transaction_id: string
    payment_channel: string
    psp_payment_id: string
    payer_vpa: string
    payer_name: string
    timestamp: string
    utr: string
    cheque_num: string
    cheque_date: string
    settlement_utr?: string
  }
  txn_type: number
  online_bill_settled?: boolean
}

export type InvoiceWithTransactions = {
  invoice: Invoice
  transactions: Transaction[]
}

export type CollectionInvoiceList = {
  list_id: string
  list_name: string
  invoices: InvoiceWithTransactions[]
}

export type SummaryData = {
  total_cash_payments: string
  total_cheque_payments: string
  total_collections: string
  total_damages: string
  total_neft_payments: string
  total_online_payments: string
  total_pending_dues: string
  total_returns: string
}

export type RowJSON = {
  'Business Name'?: any
  'Group Name': any
  'Customer Code': any
  Cash: any
  'QR / Link': any
  Cheque: any
  NEFT: any
  Damage: any
  Return: any
  'Total Collections': any
  'Pending Due': any
  'Txn Amount': any
  UTR: any
  'Paid By': any
  'Transaction Date': any
  'Settlement UTR'?: any
}

export interface ISummaryStatForInvoiceDownload {
  groupName: string
  customerName: string
  customerCode: string
  invoiceDate: string
  invoiceNumber: string
  invoiceAmount: number
  invoicePaid: number
  totalCash: number
  totalNEFT: number
  totalOnline: number
  totalCheque: number
  totalReturn: number
  totalDamage: number
  invoiceDue: number
  beats: string
  routes: string
}

export interface IInvoiceDownloadData {
  summaryStats: ISummaryStatForInvoiceDownload[]
  totalInvoiceAmount: number
  totalInvoicePaid: number
  totalInvoiceDue: number
}

export interface IUnapprovedTransaction {
  id: string
  associated_account_id: string
  business_id: string
  amount: string
  transaction_time: string
  approved_by_merchant: boolean
  account_txn_id: string
  txn_type: number
  collection_list_id: number
  notes: string
  staff_id: string
  is_deleted: boolean
  delete_time: string
  created_by: number
  is_bill_settled: boolean
  settlement_bill_ids: string[]
  added_as_advance: boolean
  staff_name: string
  staff_mobile: string
  advance_amount: string
  add_as_advance: boolean
}

export type ReplacementDataType = {
  group: string
  customer_name: string
  customer_code: string
  invoice_date: string
  invoice_number: string
  invoice_amount: string
  status: string
  items: {
    name: string
    quantity: string
  }[]
  staff_name: string
  staff_mobile: string
  replacementAmount: string
}

export type ReplacementRowJson = {
  'Group Name'?: string
  'Customer Name'?: string
  'Customer Code'?: string
  'Invoice Date'?: string
  'Invoice Number'?: string
  'Invoice Amount'?: number | string
  Replacement?: string
  'Replacement Item': string
  Quantity: string
  'Replacement Amount': number | string
}

export type OnlineCollectionTypeResponse = {
  business_name: string
  customer_name: string
  amount: number
  utr: string
  transaction_date: string
  paid_by: string
  payment_provider: string
}[]
