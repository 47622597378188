/* eslint-disable security/detect-object-injection */
import { CollectionListState } from './collectionList.state'
import {
  COLLECTION_LIST_TYPE,
  ICollectionListState,
  ActionWithPagination,
  COLLECTION_LIST_FILTER_TYPE
} from './collectionList.types'
import { CollectionListActions } from './collectionList.actions'
import {
  addOrRemoveId,
  addOrRemoveInvoice,
  // getCurrentDate,
  // getDateMonthYear,
  getDefaultCollectionListName
} from './collectionList.helpers'

export const CollectionListReducer = (
  state: ICollectionListState = CollectionListState,
  action: ActionWithPagination<any>
): ICollectionListState => {
  switch (action.type) {
    case CollectionListActions.HANDLE_CLOSE_NEW_COLLECTION_LIST: {
      return {
        ...state,
        createNewList: null,
        drawers: {
          ...state.loaders,
          collectionListSelector: false
        }
      }
    }
    case CollectionListActions.FETCH_DOCUMENTS_LIST_SUCCESS: {
      return {
        ...state,
        documentsList: [...state.documentsList, ...action.payload],
        pagination: {
          ...state.pagination,
          documents: action.pagination
        },
        loaders: {
          ...state.loaders,
          fetchDocumentsList: false
        }
      }
    }
    case CollectionListActions.DOCUMENTS_LIST_REFRESH_SUCCESS: {
      return {
        ...state,
        documentsList: action.payload,
        loaders: {
          ...state.loaders,
          fetchDocumentsList: false
        }
      }
    }
    case CollectionListActions.SET_SHIFT_ACCOUNT: {
      return {
        ...state,
        selectedIdsToShiftAccount: action.payload
      }
    }
    case CollectionListActions.SHIFT_ACCOUNT_CLICK: {
      const selectedIdsArrayPayload = addOrRemoveId(action.payload, state.selectedIdsToShiftAccount)
      return {
        ...state,
        selectedIdsToShiftAccount: selectedIdsArrayPayload
        // drawers: {
        //   ...state.drawers,
        //   collectionListShiftAccount: true
        // }
      }
    }
    case CollectionListActions.SET_SHIFT_INVOICE: {
      return {
        ...state,
        selectedInvoicesToShift: action.payload
      }
    }
    case CollectionListActions.SHIFT_INVOICE_CLICK: {
      const newSelectedInvoices = addOrRemoveInvoice(action.payload, state.selectedInvoicesToShift)
      return {
        ...state,
        selectedInvoicesToShift: newSelectedInvoices
      }
    }
    case CollectionListActions.OPEN_MOVE_LIST_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          collectionListShiftAccount: true
        }
      }
    }
    case CollectionListActions.RESET_COLLECTION_LIST_TO_SHIFT: {
      return {
        ...state,
        selectedIdsToShiftAccount: [],
        selectedInvoicesToShift: []
      }
    }
    case CollectionListActions.HANDLE_TXN_ACCEPT_OR_REMOVE: {
      return {
        ...state,
        selectedDataForTxnAdvanceAndRemove: action.payload,
        drawers: {
          ...state.drawers,
          txnAdvanceAndRemovePopup: true
        }
      }
    }
    case CollectionListActions.HANDLE_REMOVE_TXN_POPUP: {
      return {
        ...state,
        selectedTxnToRemove: action.payload,
        drawers: {
          ...state.drawers,
          removeTxnPopup: true
        }
      }
    }
    case CollectionListActions.ADD_TXN_TO_ACCOUNT_CLICK: {
      return {
        ...state,
        selectedSettingsForAddTxn: action.payload,
        drawers: {
          ...state.drawers,
          collectionListAddTxn: true
        }
      }
    }
    case CollectionListActions.COLLECTION_LIST_FROM_DOCUMENT: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          collectionListFromDocument: true
        },
        ...(action.payload?.isCreateNew && {
          createNewList: {
            creating: true,
            accountIds: [],
            dueConfig: {
              type: COLLECTION_LIST_TYPE.BALANCE_DUE
              // startTime: null,
              // endTime: null
            }
          }
        })
      }
    }
    case CollectionListActions.VIEW_REPLACEMENTS: {
      return {
        ...state,
        selectedReplacementRecords: action.payload,
        drawers: {
          ...state.drawers,
          recordReplacement: true
        }
      }
    }
    case CollectionListActions.CURRENT_DOCUMENT_SELECTED: {
      return {
        ...state,
        currentDocumentSelected: action.payload,
        drawers: {
          ...state.drawers,
          documentToCollectionList: true,
          isOnboarding: !!action.payload?.isOnboarding
        }
      }
    }
    case CollectionListActions.FETCH_COLLECTION_LISTS_SUCCESS: {
      return {
        ...state,
        allCollectionList: action.payload.allCollectionList,
        summaryCollectionList: action.payload.summaryCollectionList,
        collectionList: action.payload.collectionList,
        loaders: {
          ...state.loaders,
          collectionList: false
        }
      }
    }
    case CollectionListActions.FETCH_CL_QUICK_SUMMARY_SUCCESS: {
      return {
        ...state,
        collectionList: state.collectionList.map((list) => {
          if (list.id === action.payload.id) {
            return {
              ...list,
              summary: action.payload
            }
          }
          return list
        })
      }
    }
    case CollectionListActions.CREATE_COLLECTION_LIST_SUCCESS: {
      return {
        ...state,
        allCollectionList: [...state.allCollectionList, action.payload],
        collectionList: [...state.collectionList, action.payload],
        createNewList: null
      }
    }
    case CollectionListActions.UPDATE_COLLECTION_LIST: {
      return {
        ...state,
        selectedCollectionList: !state?.selectedCollectionList
          ? state?.selectedCollectionList
          : {
              ...state.selectedCollectionList,
              name: action.payload.name,
              dueConfig: {
                ...state.selectedCollectionList.dueConfig
                // startTime: getDateMonthYear(action.payload.due_config.start_time, true),
                // endTime: getDateMonthYear(action.payload.due_config.end_time, false)
              }
            },
        collectionList: [
          ...state.collectionList.map((list) => {
            if (list.id === action.payload.id) {
              return action.payload
            }
            return list
          })
        ]
      }
    }
    case CollectionListActions.FETCH_SINGLE_COLLECTION_LISTS:
    case CollectionListActions.FETCH_COLLECTION_LISTS_V2: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          singleCollectionList: true,
          singleCollectionListId: action.payload.collectionListId
        }
      }
    }
    case CollectionListActions.FETCH_SINGLE_COLLECTION_LISTS_SUCCESS: {
      return {
        ...state,
        selectedCollectionList: action.payload,
        selectedSettingsForAddTxn: null,
        selectedTxnToRemove: null,
        selectedReplacementRecords: null,
        selectedDataForTxnAdvanceAndRemove: null,
        filtersCollectionList: {
          byTagORIds:
            action.payload?.filters?.filter_by?.find(
              ({ key }: any) =>
                key === COLLECTION_LIST_FILTER_TYPE.TAGS_OR ||
                key === COLLECTION_LIST_FILTER_TYPE.TAGS // For backward compatability
            )?.values || [],
          byTagANDIds:
            action.payload?.filters?.filter_by?.find(
              ({ key }: any) => key === COLLECTION_LIST_FILTER_TYPE.TAGS_AND
            )?.values || []
        },
        sortCollectionList: {
          sortBy: action.payload?.filters?.sort_by
        },
        drawers: {
          ...state.drawers,
          ...(!state.loaders.savingFilters && { collectionListSelector: false }),
          collectionListDetails: true,
          collectionListDueConfig: false,
          collectionListAddTxn: false,
          filters: false,
          documentList: false,
          documentListConfiguration: false,
          documentToCollectionList: false,
          txnAdvanceAndRemovePopup: false,
          recordReplacement: false,
          removeTxnPopup: false,
          removeCollectionListPopup: false,
          autoTagList: false
        },
        loaders: {
          ...state.loaders,
          saveCollectionListDueConfig: false,
          collectionListFromDocument: false,
          acceptingCollectionTxn: false,
          approveCancelInvoice: false,
          creatingTxnForAccountInCollectionList: false,
          singleCollectionList: false,
          singleCollectionListId: null,
          txnAdvanceAndRemoveAction: false,
          recordReplacement: false,
          removeTxnPopup: false,
          savingFilters: false
        }
      }
    }
    case CollectionListActions.CREATE_NEW_LIST_BTN_CLICK: {
      return {
        ...state,
        selectedCollectionList: null,
        drawers: {
          collectionListSelector: true,
          collectionListDueConfig: true,
          collectionListDetails: false,
          filters: false,
          documentList: false,
          documentListConfiguration: false,
          documentToCollectionList: false,
          isOnboarding: action.payload?.isOnboarding
        },
        createNewList: {
          listName: getDefaultCollectionListName(state.combinedCollectionList),
          creating: true,
          accountIds: [],
          dueConfig: {
            type: COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2
            // startTime: getCurrentDate(),
            // endTime: getCurrentDate()
          },
          selectedBills: []
        },
        filtersCollectionList: {},
        sortCollectionList: {}
      }
    }
    case CollectionListActions.UPDATE_NEW_COLLECTION_LIST_NAME: {
      if (!state.createNewList) {
        return state
      }
      return {
        ...state,
        createNewList: {
          ...state.createNewList,
          listName: action.payload
        }
      }
    }
    case CollectionListActions.UPDATE_NEW_COLLECTION_LIST_TYPE: {
      if (!state.createNewList) {
        return state
      }
      return {
        ...state,
        createNewList: {
          ...state.createNewList,
          dueConfig: { type: action.payload }
        }
      }
    }
    case CollectionListActions.SAVE_FILTERS_CREATE_NEW_LIST: {
      if (!state.createNewList) {
        return state
      }
      return {
        ...state,
        createNewList: {
          ...state.createNewList,
          sortBy: action.payload.sortBy,
          filterBy: action.payload.filterBy
        }
      }
    }
    case CollectionListActions.REMOVE_COLLECTION_LIST: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          removeCollectionList: true
        }
      }
    }
    case CollectionListActions.REMOVE_COLLECTION_LIST_SUCCESS: {
      return {
        ...state,
        collectionList: state?.collectionList?.filter((itm) => itm.id !== action.payload) || [],
        selectedCollectionList: null,
        loaders: {
          ...state.loaders,
          removeCollectionList: false
        },
        drawers: {
          ...state.drawers,
          collectionListSelector: false,
          collectionListDetails: false,
          successDrawer: false,
          removeCollectionListPopup: false
        }
      }
    }
    case CollectionListActions.CLOSE_COLLECTION_LIST: {
      return {
        ...state,
        selectedCollectionList: null,
        drawers: {
          collectionListSelector: false,
          collectionListDetails: false,
          successDrawer: false
        }
      }
    }
    case CollectionListActions.COLLECTION_LIST_SET_ACCOUNT: {
      if (state?.selectedCollectionList) {
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            accountIds: action.payload
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_ADD_ACCOUNT: {
      if (state?.selectedCollectionList) {
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            accountIds: [...state?.selectedCollectionList?.accountIds, ...action.payload]
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_ADD_BILLS: {
      if (state?.selectedCollectionList) {
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            billDetails: [
              ...state?.selectedCollectionList?.billDetails,
              { bill: action.payload, replacements: [], transactions: [] }
            ]
          }
        }
      }
      return state
    }
    case CollectionListActions.REMOVE_BILL_SUCCESS: {
      if (state?.selectedCollectionList) {
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            accountDetails: state?.selectedCollectionList?.accountDetails?.map((itm) => {
              if (action.payload.accountIds.includes(itm.account_id)) {
                const newBills = itm?.bills?.filter(
                  (bill) => !action.payload.billIds.includes(bill?.bill?.bill_id)
                )
                return { ...itm, bills: newBills }
              }
              return itm
            })
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_REMOVE_ACCOUNT: {
      if (state?.selectedCollectionList) {
        const accountToRemove = state?.selectedCollectionList?.accountDetails?.find((itm) =>
          action.payload.includes(itm.account_id)
        ) || { balance: 0 }
        const totalAmountDue =
          state.selectedCollectionList.totalAmountDue +
          Number(Number(accountToRemove?.balance) / 100)
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            totalAmountDue: totalAmountDue,
            accountIds: state?.selectedCollectionList?.accountIds?.filter(
              (itm) => !action.payload.includes(itm)
            ),
            accountDetails: state?.selectedCollectionList?.accountDetails?.filter(
              (itm) => !action.payload.includes(itm.account_id)
            )
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_REMOVE_BILLS: {
      if (state?.selectedCollectionList) {
        const accountToRemove = state?.selectedCollectionList?.billDetails?.find(
          (itm) => itm.bill.bill_id === action.payload.bill_id
        )
        const balance =
          Number(accountToRemove?.bill?.total_amount || 0) +
          Number(accountToRemove?.bill?.total_paid_amount || 0)
        const totalAmountDue = state.selectedCollectionList.totalAmountDue + Number(balance / 100)
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            totalAmountDue: totalAmountDue,
            billDetails: state?.selectedCollectionList?.billDetails?.filter(
              (itm) => itm.bill.bill_id !== action.payload.bill_id
            )
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_SET_ACCOUNT_TO_NEW_LIST: {
      if (state?.createNewList) {
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            accountIds: action.payload
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_ADD_ACCOUNT_TO_NEW_LIST: {
      if (state?.createNewList) {
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            accountIds: [...state?.createNewList?.accountIds, ...action.payload]
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_BILL_CLICK: {
      if (!action.payload?.bill_id) {
        return state
      }
      if (state?.createNewList) {
        const billsSelected = state?.createNewList?.selectedBills
        if (billsSelected.some((__) => __.bill_id === action.payload.bill_id)) {
          const updatedBillsSelected = billsSelected.filter(
            (__) => __.bill_id !== action.payload.bill_id
          )
          return {
            ...state,
            createNewList: {
              ...state.createNewList,
              selectedBills: updatedBillsSelected
            }
          }
        }
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            selectedBills: [...billsSelected, action.payload]
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_CLICK: {
      const { areAllInvoicesSelected, customerId } = action.payload
      if (state.createNewList && state.collectionListBills?.data) {
        if (areAllInvoicesSelected) {
          const newSelectedBills = state.createNewList.selectedBills.filter(
            (invoice) => invoice.customer_id !== customerId
          )
          return {
            ...state,
            createNewList: {
              ...state.createNewList,
              selectedBills: newSelectedBills
            }
          }
        }

        const existingBillIds = state.createNewList.selectedBills.map((invoice) => invoice.bill_id)
        const billsToAdd = state.collectionListBills?.data
          .filter(
            (invoice) =>
              invoice.customerId === customerId && !existingBillIds.includes(invoice.billId)
          )
          .map((itm) => {
            return {
              bill_id: itm.billId,
              customer_id: itm.customerId
            }
          })
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            selectedBills: [...state.createNewList.selectedBills, ...billsToAdd]
          }
        }
      }
      return state
    }

    case CollectionListActions.COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_SUCCESS: {
      const { bills, apiAction } = action.payload
      if (!state.selectedCollectionList) {
        return state
      }
      const newBillDetails = bills.map((bill: any) => ({
        bill: bill,
        replacements: [],
        transactions: []
      }))
      if (apiAction === 'add_bills') {
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            billDetails: [...state.selectedCollectionList?.billDetails, ...newBillDetails]
          }
        }
      } else if (apiAction === 'remove_bills') {
        const updatedBillDetails = state.selectedCollectionList.billDetails.filter(
          (item) =>
            !bills.some(
              (bill: any) =>
                bill.customer_id === item.bill.customer_id && bill.bill_id === item.bill.bill_id
            )
        )
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            billDetails: updatedBillDetails
          }
        }
      }
      return state
    }

    case CollectionListActions.COLLECTION_LIST_BILL_ALL_SELECT: {
      if (state?.createNewList) {
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            selectedBills: action.payload
          }
        }
      }
      if (state.selectedCollectionList) {
        const bills = action.payload
        return {
          ...state,
          selectedCollectionList: {
            ...state.selectedCollectionList,
            billDetails: bills.map((bill: any) => ({
              bill: bill,
              replacements: [],
              transactions: []
            }))
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_REMOVE_ACCOUNT_TO_NEW_LIST: {
      if (state?.createNewList) {
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            accountIds: state?.createNewList?.accountIds?.filter(
              (itm) => !action.payload.includes(itm)
            )
          }
        }
      }
      return state
    }
    case CollectionListActions.UPDATE_COLLECTION_LIST_DUE_CONFIG: {
      if (state?.createNewList) {
        return {
          ...state,
          loaders: {
            ...state.loaders,
            saveCollectionListDueConfig: true
          }
        }
      }
      return state
    }
    case CollectionListActions.UPDATE_COLLECTION_LIST_DUE_CONFIG_TO_NEW_LIST: {
      if (state?.createNewList) {
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            dueConfig: action.payload
          },
          loaders: {
            ...state.loaders,
            saveCollectionListDueConfig: true
          }
        }
      }
      return state
    }
    case CollectionListActions.SET_SORT_FILTER_COLLECTION_LIST: {
      return {
        ...state,
        filtersCollectionList: action?.payload?.filter || {},
        sortCollectionList: action?.payload?.sort || {},
        drawers: {
          ...state.drawers,
          filters: false
        }
      }
    }
    case CollectionListActions.HANDLE_HELPER_TOGGLE: {
      return {
        ...state,
        helpers: {
          ...state.helpers,
          ...action.payload
        }
      }
    }
    case CollectionListActions.HANDLE_LOADER_TOGGLE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...action.payload
        }
      }
    }
    case CollectionListActions.HANDLE_DRAWER_TOGGLE: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          ...action.payload
        }
      }
    }
    case CollectionListActions.FETCH_LIST_SUMMARY: {
      return {
        ...state,
        listSummary: undefined,
        listSummaryInvoice: undefined,
        loaders: {
          ...state.loaders,
          collectionListSummary: true
        }
      }
    }
    case CollectionListActions.FETCH_LIST_SUMMARY_SUCCESS: {
      return {
        ...state,
        listSummary: action.payload,
        loaders: {
          ...state.loaders,
          collectionListSummary: false
        }
      }
    }
    case CollectionListActions.FETCH_LIST_SUMMARY_INVOICE_SUCCESS: {
      return {
        ...state,
        listSummaryInvoice: action.payload,
        loaders: {
          ...state.loaders,
          collectionListSummary: false
        }
      }
    }
    case CollectionListActions.SELECT_CUSTOMER_FOR_ACTION: {
      return {
        ...state,
        collectionListSelected: action.payload
      }
    }
    case CollectionListActions.FETCH_TEMPLATES_LIST: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchTemplatesList: true
        }
      }
    }
    case CollectionListActions.FETCH_TEMPLATES_LIST_SUCCESS: {
      return {
        ...state,
        templatesList: action.payload?.templatesList,
        globalTemplatesList: action.payload?.globalTemplatesList,
        loaders: {
          ...state.loaders,
          fetchTemplatesList: false
        }
      }
    }
    case CollectionListActions.CREATE_TEMPLATE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          createTemplate: true
        }
      }
    }
    case CollectionListActions.UPDATE_DEFAULT_TEMPLATE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          updateDefaultTemplate: true
        }
      }
    }
    case CollectionListActions.SET_CURRENT_TEMPLATES: {
      return {
        ...state,
        currentTemplates: action.payload
      }
    }
    case CollectionListActions.BULK_UPLOAD_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accountsDocument: {
          documentId: action.payload
        }
      }
    }
    case CollectionListActions.BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS: {
      if (!state.accountsDocument) {
        return state
      }
      return {
        ...state,
        accountsDocument: {
          ...state.accountsDocument,
          document_status: action.payload
        }
      }
    }
    case CollectionListActions.FETCH_COLLECTION_LIST_BILLS_SUCCESS: {
      const invoices = action.payload.data
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
      const sortedInvoices = invoices.sort((a: any, b: any) =>
        collator.compare(a.billNumber || '', b.billNumber || '')
      )

      return {
        ...state,
        collectionListBills: { data: sortedInvoices, pagination: 0 }
      }
    }
    case CollectionListActions.COLLECTION_LIST_REMOVE_ALL_ACCOUNTS_FROM_NEW_LIST: {
      if (state?.createNewList) {
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            accountIds: []
          }
        }
      }
      return state
    }
    case CollectionListActions.COLLECTION_LIST_REMOVE_ALL_BILLS_FROM_NEW_LIST: {
      if (state?.createNewList) {
        return {
          ...state,
          createNewList: {
            ...state.createNewList,
            selectedBills: []
          }
        }
      }
      return state
    }
    case CollectionListActions.SET_COLLECTION_LIST_TYPE_TAB: {
      if (state?.createNewList) {
        return {
          ...state,
          selectedCollectionListTypeTab: action.payload
        }
      }
      return state
    }
    case CollectionListActions.SET_SELECTED_LIST_TAB: {
      return {
        ...state,
        selectedListTab: action.payload
      }
    }
    case CollectionListActions.FETCH_ALL_COLLECTION_LISTS_SUCCESS: {
      return {
        ...state,
        combinedCollectionList: action.payload.combinedCollectionList
      }
    }
    case CollectionListActions.FETCH_STAFF_MEMBERS_SUCCESS: {
      return {
        ...state,
        staffMemberList: action.payload
      }
    }
    case CollectionListActions.UPDATE_STAFF_MEMBER: {
      const { id, name } = action.payload
      const updateStaffDetails = state.staffMemberList?.map((staff: any) =>
        staff.id === id ? { ...staff, name } : staff
      )
      if (updateStaffDetails) {
        return {
          ...state,
          staffMemberList: updateStaffDetails
        }
      }
      return state
    }
    case CollectionListActions.FETCH_ALL_PENDING_ACTIONS_SUCCESS: {
      return {
        ...state,
        allPendingActions: action.payload
      }
    }
    default: {
      return state
    }
  }
}
