/* eslint-disable security/detect-object-injection */
import { call, delay, put, select } from 'redux-saga/effects'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { getErrorMessage } from 'services/Api/Errors'
import { IApiResponse, API_RESPONSE_TYPE, ENTITY_TYPE } from 'shared/constants'
import { Action } from 'infra/types'
import { IAppState } from 'infra/AppState'
import { IDashboardState } from 'state/dashboard/dashboard.types'
import {
  fetchCollectionListSummaryDetails,
  fetchCollectionListApi,
  fetchSingleCollectionListApi,
  updateStaffLink,
  removeCollectionList,
  updateStaffLinkName,
  updateCollectionListDueConfig,
  createStaffLinkApi,
  fetchCollectionListInvoiceSummaryDetails,
  removeBillStaffLink,
  createNewCollectionListV2Api,
  fetchSingleCollectionListV2Api,
  updateStaffLinkV2,
  fetchCollectionListInvoiceSummaryV2Details,
  fetchClQuickSummaryApi,
  updateUsageType,
  getStaffMembersApi
} from 'services/Api/StafflinkApi'

import {
  addToMultipleCollectionListFromDocument,
  bulkUploadCustomersApi,
  createDocumentTemplateConfig,
  getAllUploadDocuments,
  getBulkUploadCustomersStatusApi,
  getDefaultDocumentTemplateConfig,
  getDocumentAccounts,
  getDocumentBills,
  getDocumentTemplateConfig,
  updateDefaultTemplateConfig
} from 'services/Api/growthExperimentApi'

import { fetchEntityList } from 'state/dashboard/dashboard.actions'
import { getCollectionBillsDetailsApi } from 'services/Api/BillingApi'
import { formatStaffLinkInvoices } from 'experiments/StaffLink/StaffLink.helpers'
import { isBillIdExists } from 'pages/CollectionList/components/AddCustomersToList/helper'
import { resetAllOnboardingDrawers } from 'state/onboarding/onboarding.actions'
import { DOCUMENT_STATUS } from 'pages/NewDashboard/components/CommonDrawers/UploadSheetDrawers/UploadSupplySheet/components/DocumentCard/DocumentCard.types'
import { signout } from '../auth/auth.actions'
import {
  ICollectionListState,
  ICollectionListNameAndUsageTypeChangeAction,
  ICollectionList,
  COLLECTIONS_LIST_TYPE,
  IUpdateCollectionListDueConfigAction,
  ICollectionListFromDocument,
  COLLECTION_LIST_TYPE,
  ISingleCollectionList,
  IFetchCollectionListSummary,
  IBill,
  IMultipleCollectionListFromDocument,
  DOCUMENT_TYPE,
  BILL_STATUS,
  IRemoveBill,
  IFetchSingleCollectionListAction,
  ISelectedCollectionList,
  COLLECTION_LIST_TAB,
  CL_VERSIONS,
  IFetchClV2Action,
  COLLECTION_LIST_TYPE_TAB
} from './collectionList.types'
import {
  fetchCollectionListSuccess,
  fetchCollectionListSummarySuccess,
  fetchSingleCollectionLists,
  fetchSingleCollectionListSuccess,
  collectionListAddAccount,
  collectionListRemoveAccount,
  collectionListAddAccountToNewList,
  collectionListRemoveAccountToNewList,
  removeCollectionListSuccess,
  createCollectionListSuccess,
  fetchDocumentsListSuccess,
  documentsListRefreshSuccess,
  collectionListSetAccountToNewList,
  updateCollectionListDueConfigToNewList,
  collectionListSetAccount,
  resetShiftingIdsList,
  fetchTemplatesListSuccess,
  setCurrentTemplateS,
  handleLoaderToggle,
  fetchTemplatesList,
  handleDrawerToggle,
  fetchCollectionLists,
  bulkUploadCustomersSuccessAction,
  fetchCollectionListInvoiceSummarySuccess,
  stopPollBulkUploadAccountsStatus,
  bulkUploadCustomersStatusSuccessAction,
  updateNewCollectionListName,
  updateCollectionList,
  removeBillSuccess,
  helperToggle,
  fetchCollectionListBillDetailsSuccess,
  fetchSingleCollectionListsV2,
  collectionListAddBills,
  collectionListRemoveBills,
  updateCollectionListV2DueConfig,
  createNewCollectionListV2,
  fetchClQuickSummarySuccess,
  fetchAllCollectionListSuccess,
  fetchAllCollectionLists,
  fetchStaffMembersSuccess,
  setCurrentDocumentSelected,
  selectAllBillsCollectionListClick,
  collectionListAllBillsOfAccountSuccess
} from './collectionList.actions'
import {
  // timeStampToDateMonthYear,
  sortByNameAccountDetailsSingleCollectionList,
  getFormattedGlobalTemplates,
  getFormattedCurrentTemplates,
  // getCurrentDate,
  BULK_UPLOAD_ACCOUNTS_STATUS_POLL_INTERVAL,
  BULK_UPLOAD_ACCOUNTS_STATUS_POLL_COUNT,
  getDefaultCollectionListName,
  formatStaffMembers
} from './collectionList.helpers'
import { getFormattedBills } from './formatter'
import { isInvoice as CheckIfInvoice, isInvoice } from './helper'

export function* fetchCollectionListEffect() {
  try {
    const { selectedListTab }: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    const response: IApiResponse = yield call(fetchCollectionListApi, selectedListTab)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const filteredCollectionsSummary = response?.data?.collections_lists.filter(
        (_: ICollectionList) => _.type === COLLECTIONS_LIST_TYPE.SUMMARY
      )
      const filteredCollections = response?.data?.collections_lists.filter(
        (_: ICollectionList) => _.type === COLLECTIONS_LIST_TYPE.COLLECTION
      )
      yield put(
        fetchCollectionListSuccess({
          allCollectionList: response?.data?.collections_lists,
          summaryCollectionList: filteredCollectionsSummary,
          collectionList: filteredCollections
        })
      )
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchDocumentsListEffect() {
  try {
    const { Dashboard, CollectionList } = yield select((app: IAppState) => app)
    const merchantId: string = Dashboard?.merchantProfile?.data?.id
    const collectionData: ICollectionListState = CollectionList
    const paginationData = collectionData?.pagination?.documents
    if (!merchantId || collectionData?.pagination?.documents?.isAllDataLoaded) {
      return null
    }
    const nextPage = paginationData?.currentPage ? paginationData?.currentPage + 1 : 1
    const pageLimit = paginationData?.pageLimit || 10

    const response: IApiResponse = yield call(getAllUploadDocuments, nextPage, pageLimit)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        fetchDocumentsListSuccess(response.data.Docs, {
          currentPage: nextPage,
          pageLimit,
          isAllDataLoaded:
            response.data.totalDocs <=
            response.data.Docs.length + collectionData?.documentsList?.length
        })
      )
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* documentsListRefreshSagaEffect() {
  try {
    const { Dashboard, CollectionList } = yield select((app: IAppState) => app)
    const merchantId: string = Dashboard?.merchantProfile?.data?.id
    const collectionData: ICollectionListState = CollectionList
    const paginationData = collectionData?.pagination?.documents
    if (!merchantId) {
      return null
    }
    const pageLimit = paginationData?.pageLimit
      ? paginationData?.pageLimit * paginationData?.currentPage
      : 10

    const response: IApiResponse = yield call(getAllUploadDocuments, 1, pageLimit)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(documentsListRefreshSuccess(response.data.Docs))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchSingleCollectionListEffect(action: Action<IFetchSingleCollectionListAction>) {
  try {
    yield put(resetShiftingIdsList())
    const dashboardState: IDashboardState = yield select((app: IAppState) => app.Dashboard)
    const CollectionListState: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    if (
      action.payload.type !== 'only-accounts' &&
      (CollectionListState.selectedCollectionList?.dueConfig.type ===
        COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2 ||
        CollectionListState.selectedCollectionList?.dueConfig.type ===
          COLLECTION_LIST_TYPE.AUTO_TAG_LIST)
    ) {
      yield put(fetchSingleCollectionListsV2(CollectionListState.selectedCollectionList.id))
      return null
    }
    const response: IApiResponse = yield call(
      fetchSingleCollectionListApi,
      dashboardState?.merchantProfile?.data?.id || '',
      action.payload.collectionListId
    )

    let sortAccountDetails = response?.data?.account_details
    let holdedAccountDetails = []

    const invoiceList: any[] = []
    const isInvoiceList = CheckIfInvoice(response.data.collection_list.due_config.config)
    if (isInvoiceList) {
      sortAccountDetails?.forEach((itm: any) => {
        if (itm?.bills?.length) {
          itm?.bills.map((bill: IBill) => {
            invoiceList.push({
              ...itm,
              balance: `${
                Number(bill?.bill?.total_amount || 0) + Number(bill?.bill?.total_paid_amount || 0)
              }`,
              bills: [bill],
              transactions: itm.transactions.filter((txn: any) =>
                txn.settlement_bill_ids.includes(bill.bill.bill_id)
              ),
              replacements: itm.replacements?.filter(
                (replacement: any) => replacement.bill_id === itm?.bills?.[0]?.bill?.bill_id
              )
            })
          })
        }
      })
      sortAccountDetails = invoiceList.filter(
        (itm: any) => itm.bills?.[0]?.bill?.status !== BILL_STATUS.HOLD
      )
      holdedAccountDetails = invoiceList.filter(
        (itm: any) => itm.bills?.[0]?.bill?.status === BILL_STATUS.HOLD
      )
    }

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const autoAsignConfigTags = response.data.collection_list.auto_assign_config?.find(
        (itm: any) => itm.field === 'tags'
      )
      const autoAsignConfigBills = response.data.collection_list.auto_assign_config?.find(
        (itm: any) => itm.field === 'bill_date'
      )
      yield put(
        fetchSingleCollectionListSuccess({
          holdedAccountDetails,
          accountDetails: sortByNameAccountDetailsSingleCollectionList(
            sortAccountDetails || [],
            isInvoiceList
          ),
          accountIds: response.data.collection_list.associated_account_ids,
          filters: response.data.collection_list.filters,
          totalAmountDue:
            sortAccountDetails?.reduce((acc: number, accountDetails: ISingleCollectionList) => {
              if (accountDetails?.bills?.[0]?.bill?.status === BILL_STATUS.REMOVED) {
                return acc
              }
              return acc + -Number(accountDetails.balance)
            }, 0) / 100 || 0,
          dueConfig: {
            // @ts-ignore
            type: `${response.data.collection_list.due_config.config}`
            // startTime: timeStampToDateMonthYear(
            //   response?.data.collection_list?.due_config?.start_time || 0
            // ),
            // endTime: timeStampToDateMonthYear(
            //   response?.data?.collection_list?.due_config?.end_time || 0
            // )
          },
          id: response.data.collection_list.id,
          name: response.data.collection_list.name,
          url: response.data.collection_list.url,
          pendingDeliveryAttempts: response.data.pending_delivery_attempts,
          ...(response.data.collection_list.auto_assign_config && {
            autoAssignConfig: {
              tags: autoAsignConfigTags?.value?.split(',') || [],
              tagsOperator: autoAsignConfigTags?.operator || 'or',
              startDate: Number(autoAsignConfigBills?.value.split(' ')[0]) || 0,
              endDate: Number(autoAsignConfigBills?.value.split(' ')[4]) || 0
            }
          })
        })
      )
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchClQuickSimmaryEffect(action: Action<ISelectedCollectionList>) {
  try {
    const { Experiment } = yield select((app: IAppState) => app)
    if (!Experiment?.ab?.clQuickSummary) {
      return null
    }
    yield delay(1000)
    const dashboardState: IDashboardState = yield select((app: IAppState) => app.Dashboard)
    const response: IApiResponse = yield call(
      fetchClQuickSummaryApi,
      dashboardState?.merchantProfile?.data?.id || '',
      [action.payload.id]
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (response?.data?.data?.[0]) {
        yield put(fetchClQuickSummarySuccess(response?.data?.data?.[0]))
      }
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* collectionListClickEffect(action: Action<string[]>) {
  try {
    const clickClickedAccountIds = action.payload
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )

    if (!collectionData?.selectedCollectionList?.id) {
      if (!collectionData?.createNewList?.creating) {
        return null
      }
      if (
        !collectionData?.createNewList?.accountIds.some((itm) =>
          clickClickedAccountIds.includes(itm)
        )
      ) {
        yield put(collectionListAddAccountToNewList(clickClickedAccountIds))
      } else {
        yield put(collectionListRemoveAccountToNewList(clickClickedAccountIds))
      }
      return null
    }

    let apiAction = ''
    if (
      !collectionData?.selectedCollectionList?.accountIds.some((itm) =>
        clickClickedAccountIds.includes(itm)
      )
    ) {
      apiAction = 'edit_associations'
      yield put(collectionListAddAccount(clickClickedAccountIds))
    } else {
      apiAction = 'remove_associations'
      yield put(collectionListRemoveAccount(clickClickedAccountIds))
    }

    yield put(helperToggle({ isChangeInAccountSelection: true }))

    const response: IApiResponse = yield call(
      updateStaffLink,
      collectionData.selectedCollectionList.id,
      clickClickedAccountIds,
      apiAction
    )
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      if (response?.data?.response && response?.data?.response?.status === 401) {
        yield put(signout())
      } else {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: response?.data?.message || 'Unable to fetch Collection lists'
          })
        )
      }
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* removeBillEffect(action: Action<IRemoveBill>) {
  try {
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )

    if (!collectionData?.selectedCollectionList?.id) {
      return null
    }
    const response: IApiResponse = yield call(removeBillStaffLink, {
      accountIds: action.payload.accountIds,
      billIds: action.payload.billIds,
      clId: collectionData?.selectedCollectionList?.id
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        removeBillSuccess({
          accountIds: action.payload.accountIds,
          billIds: action.payload.billIds
        })
      )
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Bill Removed Successfully'
        })
      )
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to remove bill'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* selectAllCollectionListClickEffect(action: Action<string[] | null>) {
  try {
    const allAccountIds = action.payload
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )

    if (!collectionData?.selectedCollectionList?.id) {
      if (!collectionData?.createNewList?.creating) {
        return null
      }
      if (allAccountIds?.length) {
        yield put(collectionListSetAccountToNewList(allAccountIds))
      } else {
        yield put(collectionListSetAccountToNewList([]))
      }
      return null
    }

    yield put(helperToggle({ isChangeInAccountSelection: true }))
    let apiAction = ''
    let response: IApiResponse | null = null
    if (
      `${collectionData?.selectedCollectionList.dueConfig.type}` ===
      COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2
    ) {
      if (allAccountIds) {
        apiAction = 'add_bills'
        yield put(collectionListSetAccount(allAccountIds))
      } else {
        apiAction = 'remove_bills'
        yield put(collectionListSetAccount([]))
      }
      const payload = {
        collection_list: {
          id: collectionData.selectedCollectionList.id,
          ...(allAccountIds && { associated_account_ids: allAccountIds, bills: [] })
        },
        action: apiAction
      }
      // @ts-ignore
      response = yield call(updateStaffLinkV2, payload)
    } else {
      if (allAccountIds) {
        apiAction = 'edit_associations'
        yield put(collectionListSetAccount(allAccountIds))
      } else {
        apiAction = 'remove_associations'
        yield put(collectionListSetAccount([]))
      }

      response = yield call(
        updateStaffLink,
        collectionData.selectedCollectionList.id,
        allAccountIds,
        apiAction
      )
    }
    if (response?.type === API_RESPONSE_TYPE.FAILURE || !response) {
      if (response?.data?.response && response?.data?.response?.status === 401) {
        yield put(signout())
      } else {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: response?.data?.message || 'Unable to fetch Collection lists'
          })
        )
      }
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* selectAllBillCollectionListClickEffect(
  action: Action<Array<{ bill_id: string; customer_id: string }>>
) {
  try {
    const bills = action.payload
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    if (collectionData?.createNewList?.creating || !collectionData?.selectedCollectionList) {
      return null
    }
    const payload = {
      collection_list: {
        id: collectionData.selectedCollectionList.id,
        ...(bills.length && { bills, associated_account_ids: [] })
      },
      action: bills.length ? 'add_bills' : 'remove_bills'
    }
    // @ts-ignore
    const response: IApiResponse = yield call(updateStaffLinkV2, payload)
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* createCollectionListEffect(action: Action<IUpdateCollectionListDueConfigAction>) {
  try {
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )

    if (!collectionData?.createNewList?.creating || !action?.payload?.listType) {
      return null
    }

    const response: IApiResponse = yield call(
      createStaffLinkApi,
      collectionData?.createNewList?.listName,
      collectionData?.createNewList?.accountIds,
      action.payload?.listType,
      collectionData?.selectedListTab,
      // action.payload?.typeMetaData,
      collectionData?.createNewList?.sortBy,
      collectionData?.createNewList?.filterBy
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const collectionList: ICollectionList = response?.data?.collection_list
      yield put(createCollectionListSuccess(collectionList))
      yield put(fetchSingleCollectionLists(collectionList?.id))
      yield put(fetchAllCollectionLists())
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to remove Collection lists, contect support'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* collectionListFromDocumentEffect(action: Action<ICollectionListFromDocument>) {
  try {
    const { CollectionList, Experiment } = yield select((app: IAppState) => app)
    const collectionData: ICollectionListState = CollectionList

    if (!collectionData?.currentDocumentSelected?.documentId) {
      return null
    }

    const response: IApiResponse = yield call(
      Experiment?.ab?.collectionListOfInvoicesV1 ? getDocumentBills : getDocumentAccounts,
      collectionData?.currentDocumentSelected?.documentId
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (!response?.data?.account_ids?.length && !response?.data?.bill_details?.length) {
        return null
      }

      let accountIds = response.data.account_ids || response.data.bill_details

      if (response?.data?.bill_details?.length && !response?.data?.account_ids?.length) {
        accountIds = response?.data?.bill_details.map(
          ({ account_id, bill_id }: { account_id: string; bill_id: string }) => ({
            bill_id,
            customer_id: account_id
          })
        )
      }

      if (action.payload.isCreateNew) {
        if (Experiment?.ab?.collectionListOfInvoicesV1) {
          yield put(selectAllBillsCollectionListClick(accountIds))
        } else {
          yield put(collectionListSetAccountToNewList(accountIds))
        }
        yield put(
          updateNewCollectionListName(
            getDefaultCollectionListName(collectionData?.combinedCollectionList)
          )
        )
        if (Experiment?.ab?.collectionListOfInvoicesV1) {
          yield put(
            createNewCollectionListV2({
              listType: COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2,
              collectionListTypeTab: COLLECTION_LIST_TYPE_TAB.ACCOUNT
            })
          )
        } else {
          yield put(
            updateCollectionListDueConfigToNewList({
              listType: COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE
            })
          )
        }
      } else if (action.payload.collectionListId) {
        const collectionList: ICollectionList = CollectionList.collectionList.find(
          (cl: ICollectionList) => cl.id === action.payload.collectionListId
        )
        if (action.payload.isReplaceOldItemsinCl) {
          if (
            `${collectionList.due_config?.config}` === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2
          ) {
            yield call(updateStaffLinkV2, {
              collection_list: {
                id: action.payload.collectionListId,
                associated_account_ids: [],
                bills: accountIds
              },
              action: 'replace_bills'
            })
          } else {
            yield call(
              updateStaffLink,
              action.payload.collectionListId,
              null,
              'remove_associations'
            )
          }
        }
        if (`${collectionList.due_config?.config}` === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2) {
          if (!action.payload.isReplaceOldItemsinCl) {
            yield call(updateStaffLinkV2, {
              collection_list: {
                id: action.payload.collectionListId,
                associated_account_ids: [],
                bills: accountIds
              },
              action: 'add_bills'
            })
          }
          yield put(fetchSingleCollectionListsV2(action.payload.collectionListId))
        } else {
          yield call(
            updateStaffLink,
            action.payload.collectionListId,
            accountIds,
            'edit_associations'
          )
          yield put(fetchSingleCollectionLists(action.payload.collectionListId))
        }
      }
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to remove Collection lists, contect support'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* removeCollectionListEffect() {
  try {
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )

    if (!collectionData?.selectedCollectionList?.id) {
      return null
    }

    const response: IApiResponse = yield call(
      removeCollectionList,
      collectionData?.selectedCollectionList?.id
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(removeCollectionListSuccess(collectionData?.selectedCollectionList?.id || ''))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to remove Collection lists, contect support'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* collectionListNameAndUsageChangeEffect(
  action: Action<ICollectionListNameAndUsageTypeChangeAction>
) {
  let finalResponse: IApiResponse | undefined = undefined
  // if (action.payload.typeMetaData && action.payload.listType) {
  if (action.payload.listType) {
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    if (!collectionData?.selectedCollectionList?.id) {
      return null
    }
    finalResponse = yield call(
      updateCollectionListDueConfig,
      collectionData.selectedCollectionList.id,
      action.payload.listType
      // action.payload.typeMetaData
    )
    yield put(fetchSingleCollectionLists(collectionData?.selectedCollectionList?.id))
  }

  if (action.payload.newName) {
    finalResponse = yield call(
      updateStaffLinkName,
      action.payload.collectionListId,
      action.payload.newName
    )
  }
  if (action.payload.usageType || action.payload.usageType === 0) {
    finalResponse = yield call(
      updateUsageType,
      action.payload.collectionListId,
      action.payload.usageType
    )
  }

  yield handleError(finalResponse)
  yield put(updateCollectionList(finalResponse?.data.collection_list))
}

function* handleError(finalResponse: IApiResponse | undefined) {
  if (!finalResponse) {
    throw new Error('Unable to update Collection', {
      cause: 'customError'
    })
  }
  if (finalResponse?.type === API_RESPONSE_TYPE.FAILURE) {
    if (finalResponse?.data?.response && finalResponse?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      throw new Error(finalResponse?.data?.message || 'Unable to update Collection', {
        cause: 'customError'
      })
    }
  }
}

export function* updateCollectionListDueConfigEffect(
  action: Action<IUpdateCollectionListDueConfigAction>
) {
  try {
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    if (!collectionData?.selectedCollectionList?.id) {
      return null
    }
    if (
      collectionData?.selectedCollectionList.dueConfig.type ===
      COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2
    ) {
      yield put(updateCollectionListV2DueConfig(action.payload))
      return
    }
    const response: IApiResponse = yield call(
      updateCollectionListDueConfig,
      collectionData.selectedCollectionList.id,
      action.payload.listType
      // action.payload.typeMetaData
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(updateCollectionList(response?.data?.collection_list))
      yield put(fetchSingleCollectionLists(collectionData.selectedCollectionList.id))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to remove Collection lists, contect support'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchCollectionListSummary(action: Action<IFetchCollectionListSummary>) {
  try {
    const { CollectionList: collectionData, Dashboard } = yield select((app: IAppState) => app)
    const merchantId: string = Dashboard?.merchantProfile?.data?.id
    if (!merchantId || !collectionData?.selectedCollectionList?.id) {
      return null
    }
    const isInvoice = CheckIfInvoice(collectionData?.selectedCollectionList?.dueConfig?.type)
    const isInvoiceV2 =
      `${collectionData?.selectedCollectionList?.dueConfig?.type}` ===
      COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2
    const payload = {
      collection_list_id: action.payload.collectionListId,
      start_time: action?.payload?.startTime || 0,
      ...(action?.payload?.endTime && { end_time: action.payload.endTime }),
      ...(isInvoice && {
        business_id: merchantId,
        apply_filter: action?.payload?.applyTxnFilter,
        ...(action?.payload?.txnFiltersTimeStamps?.startTime &&
          action?.payload?.txnFiltersTimeStamps?.endTime && {
            filter_time_interval: {
              start_time: action?.payload?.txnFiltersTimeStamps?.startTime,
              end_time: action?.payload?.txnFiltersTimeStamps?.endTime
            }
          })
      })
    }
    const response: IApiResponse = yield call(
      isInvoice
        ? isInvoiceV2
          ? fetchCollectionListInvoiceSummaryV2Details
          : fetchCollectionListInvoiceSummaryDetails
        : fetchCollectionListSummaryDetails,
      payload
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (isInvoice) {
        yield put(
          fetchCollectionListInvoiceSummarySuccess({
            overallSummary: response.data.summary,
            summaryStats: response.data.accounts
          })
        )
      } else {
        yield put(
          fetchCollectionListSummarySuccess({
            overallSummary: response.data.owner_summary_overall,
            summaryStats: response.data.summary_stats
          })
        )
      }
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchTemplatesListEffect() {
  try {
    const response: IApiResponse = yield call(getDocumentTemplateConfig)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const formattedGlobalTemplates = getFormattedGlobalTemplates(response.data?.global_template)
      yield put(
        fetchTemplatesListSuccess({
          templatesList: response.data.templates,
          globalTemplatesList: formattedGlobalTemplates
        })
      )
      const currentTemplates = getFormattedCurrentTemplates(
        formattedGlobalTemplates,
        response?.data?.templates
      )
      yield put(setCurrentTemplateS(currentTemplates))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(handleLoaderToggle({ fetchTemplatesList: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch templates'
        })
      )
    }
  } catch (e) {
    yield put(handleLoaderToggle({ fetchTemplatesList: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchDefaultTemplateEffect() {
  try {
    const response: IApiResponse = yield call(getDefaultDocumentTemplateConfig)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const formattedGlobalTemplates = getFormattedGlobalTemplates(response.data?.global_template)
      const currentTemplates = getFormattedCurrentTemplates(
        formattedGlobalTemplates,
        response?.data?.templates
      )
      yield put(setCurrentTemplateS(currentTemplates))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch default template'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* createNewTemplateEffect(action: Action<any>) {
  try {
    const { name, sheetDetails, columnMapping, document_type, columnFormula, amountRoundingType } =
      action.payload

    const response: IApiResponse = yield call(createDocumentTemplateConfig, {
      name,
      document_type,
      sheetDetails,
      columnMapping,
      ...(columnFormula?.applyInvoiceNumberFormula && {
        columnFormula: { invoiceNumber: '^\\S+\\s(.+)$' }
      }),
      amountRoundingType
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Template Configuration Created'
        })
      )
      yield put(fetchTemplatesList())
      yield put(handleLoaderToggle({ createTemplate: false }))
      yield put(handleDrawerToggle({ templateDetails: false }))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(handleLoaderToggle({ createTemplate: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to create new template'
        })
      )
    }
  } catch (e) {
    yield put(handleLoaderToggle({ createTemplate: true }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* updateDefaultTemplateEffect(action: Action<any>) {
  try {
    const { templateId, document_type } = action.payload

    const response: IApiResponse = yield call(updateDefaultTemplateConfig, {
      document_type,
      templateId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Default Template Updated'
        })
      )
      yield put(fetchTemplatesList())
      yield put(handleLoaderToggle({ updateDefaultTemplate: false }))
      yield put(handleDrawerToggle({ templateDetails: false }))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(handleLoaderToggle({ updateDefaultTemplate: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to update default template'
        })
      )
    }
  } catch (e) {
    yield put(handleLoaderToggle({ updateDefaultTemplate: true }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* addToMultipleCollectionListFromDocumentEffect(
  action: Action<IMultipleCollectionListFromDocument>
) {
  try {
    const { CollectionList } = yield select((app: IAppState) => app)
    const collectionData: ICollectionListState = CollectionList

    if (!collectionData?.currentDocumentSelected?.documentId) {
      return null
    }
    const { routes, action: updateAction } = action.payload

    yield put(handleLoaderToggle({ collectionListFromDocument: true }))
    const response: IApiResponse = yield call(
      addToMultipleCollectionListFromDocument,
      collectionData?.currentDocumentSelected?.documentId,
      routes,
      updateAction
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(fetchCollectionLists())
      yield put(handleLoaderToggle({ collectionListFromDocument: false }))
      yield put(handleDrawerToggle({ documentList: false, documentToCollectionList: false }))
      yield put(setCurrentDocumentSelected(null))
      yield put(resetAllOnboardingDrawers())
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to add to staff link, contact support'
        })
      )
      yield put(handleLoaderToggle({ collectionListFromDocument: false }))
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
    yield put(handleLoaderToggle({ collectionListFromDocument: false }))
  }
}

export function* bulkUploadCustomersEffect(action: Action<any>) {
  try {
    yield put(handleLoaderToggle({ bulkUploadCustomers: true }))
    const response: IApiResponse = yield call(bulkUploadCustomersApi, {
      document: action.payload.document,
      document_extension: action.payload.documentExtension,
      file_name: action.payload.fileName,
      document_type: DOCUMENT_TYPE.EXCEL
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(bulkUploadCustomersSuccessAction(response?.data?.document_id))
      yield put(handleLoaderToggle({ bulkUploadCustomers: false }))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(handleLoaderToggle({ bulkUploadCustomers: false }))
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to upload document, please contact support'
        })
      )
      yield put(handleLoaderToggle({ bulkUploadCustomers: false }))
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
    yield put(handleLoaderToggle({ bulkUploadCustomers: false }))
  }
}

export function* startBulkUploadCustomersStatusPollingEffect(action: Action<any>) {
  let statusPollCount = BULK_UPLOAD_ACCOUNTS_STATUS_POLL_COUNT
  while (statusPollCount > 0) {
    statusPollCount--
    try {
      const response: IApiResponse = yield call(getBulkUploadCustomersStatusApi, action.payload)
      if (response.type === API_RESPONSE_TYPE.FAILURE) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: 'Document upload failed, please try again.'
          })
        )
        yield put(stopPollBulkUploadAccountsStatus())
        // TODO: Update document status in redux
        return
      }
      yield put(bulkUploadCustomersStatusSuccessAction(response?.data?.document_status))
      // Polling can be stopped if status is >=4
      if (response?.data?.document_status >= DOCUMENT_STATUS.SYNC_COMPLETE) {
        // Upload successful
        if (response?.data?.document_status === DOCUMENT_STATUS.SYNC_COMPLETE) {
          if (response?.data?.unSynced_rows === 0) {
            yield put(
              addAutoFadeNotification({
                type: NotificationType.SUCCESS,
                bodyText: 'Document uploaded successfully.'
              })
            )
          } else {
            yield put(
              addAutoFadeNotification({
                type: NotificationType.NEUTRAL,
                bodyText: `Failed to update ${response?.data?.unSynced_rows} rows`
              })
            )
          }
          yield put(fetchEntityList(ENTITY_TYPE.CUSTOMER))
        } else {
          yield put(
            addAutoFadeNotification({
              type: NotificationType.ERROR,
              bodyText:
                response?.data?.document_status === DOCUMENT_STATUS.READ_FAILED
                  ? 'Incorrect file format.'
                  : 'Document upload failed, please try again.'
            })
          )
        }
        yield put(stopPollBulkUploadAccountsStatus())
      }
      yield delay(BULK_UPLOAD_ACCOUNTS_STATUS_POLL_INTERVAL)
    } catch (error) {
      // TODO: Update document status in redux
      yield put(stopPollBulkUploadAccountsStatus())
    }
  }
}

export function* fetchCollectionBillsDetailsEffects(
  action: Action<{
    startTime: number
    endTime: number
  }>
) {
  try {
    const Dashboard: IDashboardState = yield select((store: IAppState) => store.Dashboard)
    const accountsByIds = Dashboard?.customers.byIds
    const response: IApiResponse = yield call(getCollectionBillsDetailsApi, action.payload)
    const data =
      Object.keys(response.data).length === 0
        ? {
            bills: [],
            total_invoices: 0
          }
        : response.data
    const formattedBills = getFormattedBills(data, accountsByIds)
    yield put(fetchCollectionListBillDetailsSuccess(formattedBills))
  } catch (error) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Unable to fetch Invoices'
      })
    )
  }
}

export function* createNewCollectionListV2Effects(action: Action<any>) {
  try {
    const CollectionListState: ICollectionListState = yield select(
      (store: IAppState) => store.CollectionList
    )
    const due_config = {
      config: parseInt(action.payload.listType)
    }

    const { sortBy, filterBy } = CollectionListState?.createNewList || {}
    // const { collectionListTypeTab = CollectionListState.selectedCollectionListTypeTab } =
    //   action.payload
    const payload = {
      // ...(collectionListTypeTab === COLLECTION_LIST_TYPE_TAB.ACCOUNT
      ...(CollectionListState.createNewList?.accountIds.length
        ? { associated_account_ids: CollectionListState.createNewList?.accountIds }
        : null),
      ...(CollectionListState.createNewList?.selectedBills.length
        ? { bills: CollectionListState.createNewList?.selectedBills }
        : null),
      name: CollectionListState.createNewList?.listName,
      due_config: due_config,
      usage_type: CollectionListState?.selectedListTab,
      ...((sortBy || filterBy) && {
        filters: {
          ...(sortBy && { sort_by: sortBy }),
          ...(filterBy && { filter_by: filterBy })
        }
      })
    }
    const response: IApiResponse = yield call(createNewCollectionListV2Api, payload)
    const collectionList = response.data.collection_list
    yield put(createCollectionListSuccess(collectionList))
    yield put(fetchSingleCollectionListsV2(collectionList?.id))
    yield put(fetchAllCollectionLists())
  } catch (error) {
    console.log(error)
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Unable to fetch Invoices'
      })
    )
  }
}

export function* fetchCollectionListV2Effects(action: Action<IFetchClV2Action>) {
  try {
    const dashboardState: IDashboardState = yield select((app: IAppState) => app.Dashboard)
    const CollectionListState: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    let clVersion = null

    if (action.payload.version === 0 || action.payload.version) {
      clVersion = action.payload.version
    }
    if (clVersion === null) {
      clVersion =
        CollectionListState?.selectedCollectionList?.version === 0
          ? 0
          : CollectionListState?.selectedCollectionList?.version || CL_VERSIONS.CURRENT_ASSIGNMENT
    }
    const response: IApiResponse = yield call(
      fetchSingleCollectionListV2Api,
      dashboardState.merchantProfile.data?.id || '',
      action.payload.collectionListId,
      clVersion
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const updatedResponse = formatStaffLinkInvoices(response.data)

      let sortAccountDetails = updatedResponse?.account_details
      let holdedAccountDetails = []
      const isInvoiceList = CheckIfInvoice(response.data.collection_list.due_config.config)
      if (isInvoiceList) {
        const invoiceList: any[] = []
        sortAccountDetails?.forEach((itm: any) => {
          if (itm?.bills?.length) {
            itm?.bills.map((bill: IBill) => {
              invoiceList.push({
                ...itm,
                balance: `${
                  Number(bill?.bill?.total_amount || 0) + Number(bill?.bill?.total_paid_amount || 0)
                }`,
                bills: [bill],
                transactions: itm.transactions.filter((txn: any) =>
                  txn.settlement_bill_ids.includes(bill.bill.bill_id)
                )
              })
            })
          }
        })
        sortAccountDetails = invoiceList.filter(
          (itm: any) => itm.bills?.[0]?.bill?.status !== BILL_STATUS.HOLD
        )
        holdedAccountDetails = invoiceList.filter(
          (itm: any) => itm.bills?.[0]?.bill?.status === BILL_STATUS.HOLD
        )
      }

      const autoAsignConfigTags = response.data.collection_list.auto_assign_config?.find(
        (itm: any) => itm.field === 'tags'
      )
      const autoAsignConfigBills = response.data.collection_list.auto_assign_config?.find(
        (itm: any) => itm.field === 'bill_date'
      )

      yield put(
        fetchSingleCollectionListSuccess({
          holdedAccountDetails,
          accountDetails: sortByNameAccountDetailsSingleCollectionList(
            sortAccountDetails || [],
            isInvoiceList
          ),
          accountIds: updatedResponse.accountIds,
          filters: updatedResponse.collection_list.filters,
          totalAmountDue:
            updatedResponse.bill_details?.reduce((acc: number, accountDetails: any) => {
              if (accountDetails?.bill?.status === BILL_STATUS.REMOVED) {
                return acc
              }
              const balance =
                Number(accountDetails?.bill?.total_amount || 0) +
                Number(accountDetails?.bill?.total_paid_amount || 0)
              return acc + -balance
            }, 0) / 100 || 0,
          dueConfig: {
            type: `${updatedResponse.collection_list.due_config.config}`
          },
          id: updatedResponse.collection_list.id,
          name: updatedResponse.collection_list.name,
          url: updatedResponse.collection_list.url,
          billDetails: updatedResponse.bill_details,
          version: clVersion,
          historyDueConfigs: updatedResponse.history_due_configs,
          pendingDeliveryAttempts: response.data.pending_delivery_attempts,
          ...(response.data.collection_list.auto_assign_config && {
            autoAssignConfig: {
              tags: autoAsignConfigTags?.value?.split(',') || [],
              tagsOperator: autoAsignConfigTags?.operator || 'or',
              startDate: Number(autoAsignConfigBills?.value.split(' ')[0]) || 0,
              endDate: Number(autoAsignConfigBills?.value.split(' ')[4]) || 0
            }
          })
        })
      )
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* collectionListEditEffect(
  action: Action<{ bill_id?: string; customer_id: string }>
) {
  try {
    const clickClickedBillId = action.payload
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )

    if (!collectionData?.selectedCollectionList?.id) {
      if (collectionData?.createNewList?.creating) {
        if (!clickClickedBillId.bill_id) {
          if (!collectionData?.createNewList?.accountIds.includes(clickClickedBillId.customer_id)) {
            yield put(collectionListAddAccountToNewList([clickClickedBillId.customer_id]))
          } else {
            yield put(collectionListRemoveAccountToNewList([clickClickedBillId.customer_id]))
          }
        }
      }
      return null
    }

    if (
      `${collectionData.selectedCollectionList?.dueConfig.type}` !==
      COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2
    ) {
      return null
    }

    let apiAction = ''
    if (clickClickedBillId.bill_id) {
      if (
        !isBillIdExists(
          collectionData?.selectedCollectionList?.billDetails,
          clickClickedBillId.bill_id
        )
      ) {
        apiAction = 'add_bills'
        // @ts-ignore
        yield put(collectionListAddBills(clickClickedBillId))
      } else {
        apiAction = 'remove_bills'
        // @ts-ignore
        yield put(collectionListRemoveBills(clickClickedBillId))
        yield put(
          removeBillSuccess({
            accountIds: [clickClickedBillId.customer_id],
            billIds: [clickClickedBillId.bill_id]
          })
        )
      }
    } else {
      if (
        !collectionData?.selectedCollectionList?.accountIds.includes(clickClickedBillId.customer_id)
      ) {
        apiAction = 'add_bills'
        // @ts-ignore
        yield put(collectionListAddAccount([clickClickedBillId.customer_id]))
      } else {
        apiAction = 'remove_bills'
        // @ts-ignore
        yield put(collectionListRemoveAccount([clickClickedBillId.customer_id]))
      }
    }
    yield put(helperToggle({ isChangeInAccountSelection: true }))
    const payload = {
      collection_list: {
        id: collectionData.selectedCollectionList.id,
        ...(clickClickedBillId.bill_id
          ? { bills: [clickClickedBillId], associated_account_ids: [] }
          : { associated_account_ids: [clickClickedBillId.customer_id], bills: [] })
      },
      action: apiAction
    }
    // @ts-ignore
    const response: IApiResponse = yield call(updateStaffLinkV2, payload)
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      if (response?.data?.response && response?.data?.response?.status === 401) {
        yield put(signout())
      } else {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: response?.data?.message || 'Unable to fetch Collection lists'
          })
        )
      }
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* toggleAllBillsOfAccountEffect(
  action: Action<{ customerId?: string; areAllInvoicesSelected: boolean }>
) {
  try {
    const { customerId, areAllInvoicesSelected } = action.payload
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    const existingBills = collectionData?.selectedCollectionList?.billDetails

    if (
      collectionData?.createNewList?.creating ||
      !collectionData?.selectedCollectionList?.id ||
      !customerId ||
      !existingBills
    ) {
      return
    }

    let apiAction
    let bills
    if (areAllInvoicesSelected) {
      bills = existingBills
        .filter((item) => item.bill.customer_id === customerId)
        .map((item) => ({
          customer_id: item.bill.customer_id,
          bill_id: item.bill.bill_id
        }))
      apiAction = 'remove_bills'
    } else {
      const existingBillIds = existingBills.map((invoice) => invoice.bill.bill_id)
      bills = collectionData.collectionListBills?.data
        .filter((item) => item.customerId === customerId && !existingBillIds.includes(item.billId))
        .map((item) => ({
          customer_id: item.customerId,
          bill_id: item.billId
        }))
      apiAction = 'add_bills'
    }

    if (!bills || !bills.length) {
      return
    }
    const payload = {
      collection_list: {
        id: collectionData.selectedCollectionList.id,
        bills,
        associated_account_ids: []
      },
      action: apiAction
    }
    const response: IApiResponse = yield call(updateStaffLinkV2, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(collectionListAllBillsOfAccountSuccess({ bills, apiAction }))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to update Collection list'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}
export function* updateCollectionListV2DueConfigEffect(
  action: Action<IUpdateCollectionListDueConfigAction>
) {
  try {
    const collectionData: ICollectionListState = yield select(
      (app: IAppState) => app.CollectionList
    )
    if (!collectionData?.selectedCollectionList?.id) {
      return null
    }
    // let startTime = null
    // let endTime = null
    const listType = action.payload.listType
    if (
      listType === COLLECTION_LIST_TYPE.CREDIT_DUE ||
      listType === COLLECTION_LIST_TYPE.BALANCE_DUE_FILTER ||
      isInvoice(listType)
    ) {
      // if (action.payload.typeMetaData?.from) {
      //   const { from } = action.payload.typeMetaData
      //   startTime = new Date(from.year, from.month - 1, from.day, 0, 0, 0).getTime() / 1000
      // }
      // if (action.payload.typeMetaData?.to) {
      //   const { to } = action.payload.typeMetaData
      //   endTime = new Date(to.year, to.month - 1, to.day, 23, 59, 59).getTime() / 1000
      // }
    }
    const payload = {
      collection_list: {
        id: collectionData.selectedCollectionList.id,
        due_config: {
          config: parseInt(listType)
          // ...(startTime && { start_time: startTime }),
          // ...(endTime && { end_time: endTime })
        }
      },
      action: 'due_config'
    }
    const response: IApiResponse = yield call(updateStaffLinkV2, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(updateCollectionList(response?.data?.collection_list))
      yield put(fetchSingleCollectionListsV2(collectionData.selectedCollectionList.id))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to remove Collection lists, contect support'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchAllCollectionListEffect() {
  try {
    const collectionResponse: IApiResponse = yield call(
      fetchCollectionListApi,
      COLLECTION_LIST_TAB.COLLECTION_LIST
    )
    if (collectionResponse.type === API_RESPONSE_TYPE.SUCCESS) {
      const supplyListResponse: IApiResponse = yield call(
        fetchCollectionListApi,
        COLLECTION_LIST_TAB.SUPPLY_LIST
      )
      if (supplyListResponse.type === API_RESPONSE_TYPE.SUCCESS) {
        const combinedCollectionList = [
          ...collectionResponse.data?.collections_lists,
          ...supplyListResponse.data?.collections_lists
        ]
        yield put(
          fetchAllCollectionListSuccess({
            combinedCollectionList
          })
        )
      } else {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: supplyListResponse?.data?.message || 'Unable to fetch Collection lists'
          })
        )
      }
    } else if (
      collectionResponse?.data?.response &&
      collectionResponse?.data?.response?.status === 401
    ) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: collectionResponse?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchStaffMembersEffect() {
  try {
    const response: IApiResponse = yield call(getStaffMembersApi)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(fetchStaffMembersSuccess(formatStaffMembers(response?.data?.staff_members)))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch staff members at this moment.'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

/** @deprecated */

export function* fetchAllPendingActionsEffect() {
  yield put(handleLoaderToggle({ allPendingActions: true }))
  // const response: IApiResponse = yield call(fetchPendingActionBillDetailsApi)
  // if (response.type === API_RESPONSE_TYPE.FAILURE) {
  //   throw new Error('Problem while fetching pending actions, please try after sometime', {
  //     cause: 'customError'
  //   })
  // }
  // yield put(fetchAllPendingActionsSuccess(formatPendingActions(response?.data)))
  // yield put(handleLoaderToggle({ allPendingActions: false }))
}
