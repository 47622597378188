import { takeEvery, takeLatest } from 'redux-saga/effects'
import { AuthActions } from './auth.actions'
import authAnalytics from './auth.analytics'
import {
  getOtp,
  navigateToDashboard,
  startCountDownTimer,
  verifyOtp,
  syncWithLocalStorage,
  signoutUser,
  signoutUserFromAllDevices,
  clickTerms,
  clickPrivacy,
  clickAbout,
  verifyPin
} from './auth.effects'

function* getOtpSaga() {
  yield takeLatest(AuthActions.GET_OTP, getOtp)
}

function* verifyOtpSaga() {
  yield takeLatest(AuthActions.VERIFY_OTP, verifyOtp)
}

function* startTimerSaga() {
  yield takeLatest(AuthActions.START_TIMER, startCountDownTimer)
}

function* navigateToDashboardSaga() {
  yield takeLatest(AuthActions.NAVIGATE_TO_DASHBOARD, navigateToDashboard)
}
function* syncWithLocalStorageSaga() {
  yield takeLatest(AuthActions.SYNC_WITH_LOCAL_STORAGE, syncWithLocalStorage)
}

function* signoutUserSaga() {
  yield takeLatest(AuthActions.SIGNOUT, signoutUser)
}

function* signoutUserFromAllDevicesSaga() {
  yield takeLatest(AuthActions.SIGNOUT_FROM_ALL_DEVICES, signoutUserFromAllDevices)
}

function* clickTermsSaga() {
  yield takeLatest(AuthActions.CLICK_TERMS, clickTerms)
}

function* clickPrivacySaga() {
  yield takeLatest(AuthActions.CLICK_PRIVACY, clickPrivacy)
}

function* clickAboutSaga() {
  yield takeLatest(AuthActions.CLICK_ABOUT, clickAbout)
}

function* authAnalyticsSaga() {
  yield takeEvery(Object.values(AuthActions), authAnalytics)
}

function* verifyPinSaga() {
  yield takeLatest(AuthActions.VERIFY_PIN, verifyPin)
}

export const AuthEffects = [
  authAnalyticsSaga(),
  getOtpSaga(),
  startTimerSaga(),
  verifyOtpSaga(),
  navigateToDashboardSaga(),
  syncWithLocalStorageSaga(),
  signoutUserSaga(),
  signoutUserFromAllDevicesSaga(),
  clickTermsSaga(),
  clickPrivacySaga(),
  clickAboutSaga(),
  verifyPinSaga()
]
