import { ITranslate } from '../../constants'

export interface INotificationPayload {
  bodyText: string
  actionText?: string
  type?: NotificationType
  onActionClick?: () => void
}

export interface INotification extends INotificationPayload {
  id: number
}

export interface INotificationComponent extends INotificationPayload {
  id: number
  translate: ITranslate
}
export enum NotificationType {
  NEUTRAL,
  INFO,
  SUCCESS,
  ERROR
}

export interface INotificationState {
  notifications: INotification[]
}
