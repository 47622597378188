import { Action } from '../../infra/types'
import { InventoryActions } from './inventory.actions'
import { InventoryState } from './inventory.state'
import { IInventoryState } from './inventory.types'

export const InventoryReducer = (
  state: IInventoryState = InventoryState,
  action: Action<any>
): IInventoryState => {
  switch (action.type) {
    case InventoryActions.HANDLE_LOADER_TOGGLE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...action.payload
        }
      }
    }
    case InventoryActions.BULK_UPLOAD_INVENTORY_ITEMS_SUCCESS: {
      return {
        ...state,
        document: {
          documentId: action.payload
        }
      }
    }
    case InventoryActions.SET_INVENTORY_MODALS: {
      return {
        ...state,
        modals: { ...state.modals, [action.payload.modalName]: action.payload.status }
      }
    }
    default: {
      return state
    }
  }
}
