import { Action } from 'infra/types'
import { ordersAction } from 'state/new-summary/orders/orders.action'
import { InitialOrderStateValues } from 'state/new-summary/orders/orders.state'
import { IOrdersState, ORDERS_ENTITY } from 'state/new-summary/orders/orders.types'
import { getToday } from 'utils/time'

export const OrdersReducer = (
  state: IOrdersState = InitialOrderStateValues,
  action: Action<any>
): IOrdersState => {
  switch (action.type) {
    case ordersAction.FETCH_ORDERS: {
      return {
        ...state,
        sortOption: action.payload.sortOption,
        isCreateBillFromOrders: false,
        isCreateProformaFromOrder: false
      }
    }
    case ordersAction.SET_ORDER_LOADERS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.loaderName]: action.payload.status
        }
      }
    }
    case ordersAction.SET_ORDERS_ACTIONS_FILTER: {
      return {
        ...state,
        pendingOrdersFilter: action.payload.filter,
        sortOption: action.payload.sortOption
      }
    }
    case ordersAction.SET_APPROVED_ORDERS_ACTIONS_FILTER: {
      return {
        ...state,
        approvalOrdersFilter: action.payload.filter,
        sortOption: action.payload.sortOption
      }
    }
    case ordersAction.SET_PROFORMA_INVOICES_ACTIONS_FILTER: {
      return {
        ...state,
        proformaOrdersFilter: action.payload.filter,
        sortOption: action.payload.sortOption
      }
    }
    case ordersAction.SET_PENDING_ORDERS_SELECTED: {
      return {
        ...state,
        showPendingOrdersApproveBtn: action.payload.status,
        selectedOrderIds: action.payload.orderIds
      }
    }
    case ordersAction.SET_APPROVED_ORDERS_SELECTED: {
      return {
        ...state,
        showApprovalOrdersApproveBtn: action.payload.status,
        selectedApprovedOrderIds: action.payload.orderIds
      }
    }
    case ordersAction.SET_PROFORMA_INVOICES_SELECTED: {
      return {
        ...state,
        proformaInvoicesApproveBtn: action.payload.status,
        selectedProformaOrderIds: action.payload.orderIds,
        proformasHtmls: []
      }
    }
    case ordersAction.SET_ORDERS_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case ordersAction.SET_ORDERS_DATA: {
      return {
        ...state,
        pendingOrdersRowData: action.payload,
        searchString: ''
      }
    }
    case ordersAction.SET_APPROVED_ORDERS_DATA: {
      return {
        ...state,
        approvalOrdersRowData: action.payload,
        searchString: ''
      }
    }
    case ordersAction.SET_PROFORMA_INVOICES_DATA: {
      return {
        ...state,
        proformaInvoicesRowData: action.payload,
        searchString: ''
      }
    }
    case ordersAction.SET_CREATE_BILL_FROM_ORDERS: {
      return {
        ...state,
        isCreateBillFromOrders: action.payload
      }
    }
    case ordersAction.SET_CREATE_PROFORMA_FROM_ORDER: {
      return {
        ...state,
        isCreateProformaFromOrder: action.payload
      }
    }
    case ordersAction.SET_SELECTED_ORDER_DETAILS: {
      const {
        order: {
          customerName,
          customerAddress,
          customerId,
          customerMobile,
          customerCode,
          customerGST
        },
        isCreateProformaFromOrder
      } = action.payload
      return {
        ...state,
        selectedOrderDetails: action.payload.order,
        customerDetails: {
          customerName,
          customerAddress,
          customerId,
          customerMobile,
          customerCode,
          customerGST
        },
        invoiceDetails: {
          invoiceDate: getToday(),
          invoiceNumber: `${Math.floor(1000 + Math.random() * 9000)}`,
          invoicePrefix: isCreateProformaFromOrder ? 'PER' : 'INV',
          invoiceGST: ''
        },
        selectedDiscounts: [],
        selectedCharges: []
      }
    }
    case ordersAction.SET_ORDER_SEARCH_VALUE: {
      return {
        ...state,
        searchString: action.payload
      }
    }
    case ordersAction.SET_ITEM_DETAILS_DATA: {
      return {
        ...state,
        addedItems: action.payload
      }
    }
    case ordersAction.SET_SELECTED_DISCOUNTS: {
      return {
        ...state,
        selectedDiscounts: action.payload
      }
    }
    case ordersAction.SET_SELECTED_CHARGES: {
      return {
        ...state,
        selectedCharges: action.payload
      }
    }
    case ordersAction.SET_PAYABLE_AMOUNT: {
      return {
        ...state,
        payableAmount: action.payload
      }
    }
    case ordersAction.SET_SELECTED_ITEM_INDICES: {
      return {
        ...state,
        selectedItemIndices: action.payload
      }
    }
    case ordersAction.SET_ORDERED_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload
      }
    }
    case ordersAction.SET_ORDER_INVOICE_DETAILS: {
      return {
        ...state,
        invoiceDetails: action.payload
      }
    }
    case ordersAction.SET_ORDER_DISCOUNT_AMOUNT: {
      return {
        ...state,
        discountAmount: action.payload
      }
    }
    case ordersAction.SET_SELECTED_MULTIPLE_ORDER_DETAILS: {
      return {
        ...state,
        selectedMultipleOrders: action.payload
      }
    }
    case ordersAction.SET_ORDERS_ACTIVE_ENTITY: {
      return {
        ...state,
        activeOrdersEntity: action.payload
      }
    }
    case ordersAction.SET_SELECTED_PROFORMA_DETAILS: {
      return {
        ...state,
        selectedProformaInvoices: action.payload
      }
    }
    case ordersAction.SET_PRINT_PROFORMA_HTML: {
      return {
        ...state,
        proformasHtmls: action.payload
      }
    }
    case ordersAction.SET_PRINT_ORDERS_HTML: {
      return {
        ...state,
        ordersHtml: action.payload
      }
    }
    case ordersAction.CREATE_AND_INVOICE_ORDER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isCreatingBill: true
        }
      }
    }
    case ordersAction.CREATE_PROFORMA: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isCreatingBill: true
        }
      }
    }
    case ordersAction.SET_CREATE_BILL_FROM_PROFORMA: {
      return {
        ...state,
        isCreateBillFromProforma: action.payload
      }
    }
    case ordersAction.RESET_DEFAULT_VALUES: {
      return {
        ...state,
        isCreateBillFromProforma: false,
        isCreateBillFromOrders: false,
        isCreateProformaFromOrder: false,
        activeOrdersEntity: ORDERS_ENTITY.NONE
      }
    }
    default: {
      return state
    }
  }
}
