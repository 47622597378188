import { TRANSACTION_TYPE } from 'state/transaction/transaction.types'

export const formatCurrency = (currency: number) => {
  return new Intl.NumberFormat('en-IN').format(currency)
}

export const formatCurrencyWithRuppee = (currency: number | string) => {
  const amt = Math.abs(Number(currency))
  return new Intl.NumberFormat('en-IN').format(amt)
}

/**
 * Calculate the final balance after each transaction
 * @todo This will be done in reducer through a mixed content
 * timeline fashion, i.e using Array.reduce() in redux reducer
 * itself.
 *
 * @param balance The current amount
 * @param txnType Is it credit or payment?
 * @param accumulator Added values
 * @returns {number} The final balance amount
 */
export const getDeltaBalance = (
  balance: number,
  txnType: TRANSACTION_TYPE,
  accumulator: number
) => {
  if (txnType === TRANSACTION_TYPE.PAYMENT) {
    return accumulator + balance
  }
  return accumulator - balance
}
