import { takeCustomLatest } from 'state/common/common.effects'
import { applyTagsToBillEffect } from 'state/billing/billing.tags.effects'
import {
  addEditInventoryItemsEffect,
  createPurchaseInventoryEffect,
  deleteBillsEffect,
  deleteItemsEffect,
  fetchBillByIdEffect,
  fetchBillsEffect,
  fetchInventoryItemHistoryEffect,
  fetchInvoiceItemsEffect,
  uploadBillEffect,
  savePurchaseBillSettingsEffect,
  fetchFileSettingsEffect,
  updatePreviewBillEffect,
  fetchPurchaseAccountEffect,
  fetchPurchaseAssetAccountEffect,
  fetchPurchaseItemEffect,
  addExpenseEffect,
  getExpenseEffect,
  fetchInventoryItemsEffect,
  addEditInventoryV2ItemsEffect,
  uploadInventoryItemsEffect,
  getInventoryTransactionHistoryEffect,
  getPurchaseTransactionHistoryEffect,
  fetchBusinessDiscountsEffect,
  createBusinessDiscountEffect,
  fetchBusinessChargesEffect,
  createBusinessChargeEffect,
  addExpenseAccountSagaEffect,
  getAllExpenseAccountsEffect,
  fetchInventoryItemsOffersEffect,
  syncInventoryEffect,
  bulkUploadPurchaseItemsEffect,
  fetchInventoryUnitEffect,
  openBulkUploadPurchaseItemsLogDrawerEffect,
  retryBulkUploadPurchaseItemsEffect,
  downloadBulkUploadPurchaseItemsEffect
} from './billing.effects'
import { BillingActions, setBillingFlags, setBillingLoaders } from './billing.actions'

function* uploadBillsSaga() {
  yield takeCustomLatest(BillingActions.UPLOAD_BILLS, uploadBillEffect)
}

function* fetchBills() {
  yield takeCustomLatest(BillingActions.FETCH_BILLS, fetchBillsEffect)
}

function* deleteBillsSaga() {
  yield takeCustomLatest(BillingActions.DELETE_BILLS, deleteBillsEffect)
}

function* addEditInventoryV2ItemsSaga() {
  yield takeCustomLatest(BillingActions.ADD_EDIT_INVENTORY_V2_ITEM, addEditInventoryV2ItemsEffect)
}

function* addTagsToBillsSaga() {
  yield takeCustomLatest(BillingActions.ADD_TAGS_TO_BILL_ACTION, applyTagsToBillEffect)
}

function* savePurchaseBillSettingsSaga() {
  yield takeCustomLatest(BillingActions.SAVE_PURCHASE_BILL_SETTINGS, savePurchaseBillSettingsEffect)
}
function* fetchFileSettingsSaga() {
  yield takeCustomLatest(BillingActions.FETCH_FILE_SETTINGS, fetchFileSettingsEffect)
}

function* addEditInventoryItemsSaga() {
  yield takeCustomLatest(BillingActions.ADD_EDIT_INVENTORY_ITEM, addEditInventoryItemsEffect)
}

function* fetchInvoiceItemsSaga() {
  yield takeCustomLatest(BillingActions.FETCH_INVOICE_ITEMS, fetchInvoiceItemsEffect)
}

function* fetchInventoryItemsSaga() {
  yield takeCustomLatest(BillingActions.FETCH_INVENTORY_ITEMS, fetchInventoryItemsEffect)
}

function* fetchInventoryUnitSaga() {
  yield takeCustomLatest(BillingActions.FETCH_INVENTORY_UNIT, fetchInventoryUnitEffect)
}

function* fetchInventoryItemsOffersSaga() {
  yield takeCustomLatest(
    BillingActions.FETCH_INVENTORY_ITEMS_OFFER,
    fetchInventoryItemsOffersEffect
  )
}

function* uploadInventoryItemsSaga() {
  yield takeCustomLatest(BillingActions.UPLOAD_INVENTORY_ITEMS, uploadInventoryItemsEffect, () => {
    setBillingLoaders({ loaderName: 'uploadingInventory', status: false })
    setBillingFlags({ flagName: 'inventoryUploadType', status: '' })
  })
}

function* deleteItemsSaga() {
  yield takeCustomLatest(BillingActions.DELETE_ITEM, deleteItemsEffect, () => {
    setBillingLoaders({ loaderName: 'deletingItems', status: false })
  })
}

function* fetchBillByIdSaga() {
  yield takeCustomLatest(BillingActions.FETCH_BILL_BY_ID, fetchBillByIdEffect)
}

function* updatePreviewBillDataSaga() {
  yield takeCustomLatest(BillingActions.UPDATE_PREVIEW_BILL, updatePreviewBillEffect)
}

function* fetchInventoryItemHistorySaga() {
  yield takeCustomLatest(BillingActions.GET_ITEM_INVENTORY_HISTORY, fetchInventoryItemHistoryEffect)
}

function* fetchPurchaseItemSaga() {
  yield takeCustomLatest(BillingActions.GET_PURCHASE_INVENTORY_ITEMS, fetchPurchaseItemEffect)
}

function* fetchPurchaseAccountSaga() {
  yield takeCustomLatest(BillingActions.GET_PURCHASE_ACCOUNT, fetchPurchaseAccountEffect)
}

function* fetchPurchaseAssetAccountSaga() {
  yield takeCustomLatest(BillingActions.GET_PURCHASE_ASSET_ACCOUNT, fetchPurchaseAssetAccountEffect)
}

function* createPurchaseInventorySaga() {
  yield takeCustomLatest(BillingActions.CREATE_PURCHASE_INVENTORY, createPurchaseInventoryEffect)
}

function* addExpenseSaga() {
  yield takeCustomLatest(BillingActions.ADD_EXPENSE, addExpenseEffect)
}

function* getExpenseSaga() {
  yield takeCustomLatest(BillingActions.GET_EXPENSE, getExpenseEffect)
}

function* getInventoryTransactionHistorySaga() {
  yield takeCustomLatest(
    BillingActions.GET_INVENTORY_TRANSACTION_HISTORY,
    getInventoryTransactionHistoryEffect
  )
}

function* getPurchaseTransactionHistorySaga() {
  yield takeCustomLatest(
    BillingActions.GET_PURCHASE_TRANSACTION_HISTORY,
    getPurchaseTransactionHistoryEffect
  )
}

function* fetchBusinessDiscountsSaga() {
  yield takeCustomLatest(BillingActions.FETCH_BUSINESS_DISCOUNTS, fetchBusinessDiscountsEffect)
}

function* createBusinessDiscountSaga() {
  yield takeCustomLatest(BillingActions.CREATE_BUSINESS_DISCOUNT, createBusinessDiscountEffect)
}

function* fetchBusinessChargesSaga() {
  yield takeCustomLatest(BillingActions.FETCH_BUSINESS_CHARGES, fetchBusinessChargesEffect)
}

function* createBusinessChargeSaga() {
  yield takeCustomLatest(BillingActions.CREATE_BUSINESS_CHARGE, createBusinessChargeEffect)
}

function* addExpenseAccountSaga() {
  yield takeCustomLatest(BillingActions.ADD_EXPENSE_ACCOUNt, addExpenseAccountSagaEffect)
}

function* getAllExpenseAccountsSaga() {
  yield takeCustomLatest(BillingActions.GET_ALL_EXPENSE_ACCOUNTS, getAllExpenseAccountsEffect)
}

function* syncInventorySaga() {
  yield takeCustomLatest(BillingActions.SYNC_INVENTORY, syncInventoryEffect)
}

function* bulkUploadPurchaseItemsSaga() {
  yield takeCustomLatest(BillingActions.BULK_UPLOAD_PURCHASE_ITEMS, bulkUploadPurchaseItemsEffect)
}

function* openBulkUploadPurchaseItemsLogDrawerSaga() {
  yield takeCustomLatest(
    BillingActions.OPEN_BULK_UPLOAD_PURCHASE_ITEMS_LOG_DRAWER,
    openBulkUploadPurchaseItemsLogDrawerEffect
  )
}

function* retryBulkUploadPurchaseItemsSaga() {
  yield takeCustomLatest(
    BillingActions.RETRY_BULK_UPLOAD_PURCHASE_ITEMS,
    retryBulkUploadPurchaseItemsEffect
  )
}

function* downloadBulkUploadPurchaseItemsSaga() {
  yield takeCustomLatest(
    BillingActions.DOWNLOAD_BULK_UPLOAD_PURCHASE_ITEMS,
    downloadBulkUploadPurchaseItemsEffect
  )
}

export const BillingWatchers = [
  fetchInventoryUnitSaga(),
  uploadBillsSaga(),
  fetchBills(),
  deleteBillsSaga(),
  deleteItemsSaga(),
  savePurchaseBillSettingsSaga(),
  fetchFileSettingsSaga(),
  fetchBillByIdSaga(),
  updatePreviewBillDataSaga(),
  addEditInventoryItemsSaga(),
  fetchInvoiceItemsSaga(),
  fetchInventoryItemHistorySaga(),
  fetchPurchaseItemSaga(),
  fetchPurchaseAccountSaga(),
  fetchPurchaseAssetAccountSaga(),
  createPurchaseInventorySaga(),
  addExpenseSaga(),
  getExpenseSaga(),
  fetchInventoryItemsSaga(),
  addEditInventoryV2ItemsSaga(),
  uploadInventoryItemsSaga(),
  getInventoryTransactionHistorySaga(),
  getPurchaseTransactionHistorySaga(),
  fetchBusinessDiscountsSaga(),
  createBusinessDiscountSaga(),
  fetchBusinessChargesSaga(),
  createBusinessChargeSaga(),
  addTagsToBillsSaga(),
  addExpenseAccountSaga(),
  getAllExpenseAccountsSaga(),
  fetchInventoryItemsOffersSaga(),
  syncInventorySaga(),
  bulkUploadPurchaseItemsSaga(),
  openBulkUploadPurchaseItemsLogDrawerSaga(),
  retryBulkUploadPurchaseItemsSaga(),
  downloadBulkUploadPurchaseItemsSaga()
]
