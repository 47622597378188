import { all, call, delay, put, select } from 'redux-saga/effects'
import { deleteBillWithBillId } from 'services/Api/BillingApi'
import { Action } from 'infra/types'
import { IAppState } from 'infra/AppState'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import {
  catalogueOperations,
  fetchAllItems,
  fetchBillByIdApi,
  fetchBillsV3,
  fetchInventoryItems,
  inventoryOperations,
  fetchBusinessDiscounts,
  updateBillApi,
  uploadBill,
  uploadInventoryItemsApi,
  createBusinessDiscount,
  fetchBusinessCharges,
  createBusinessCharge,
  fetchInventoryItemsOffers,
  fetchInventoryUnitApi
} from 'services/Api/BillingApiFolder.ts/api'
import {
  BillByIdResponse,
  FETCH_BILL_RESPONSE,
  FETCH_INVENTORY_ITEMS_RESPONSE,
  INVENTORY_ITEM_REQUEST_TYPE,
  INVENTORY_OPERATION_TYPE,
  FETCH_BUSINESS_DISCOUNTS_RESPONSE,
  ITEMS_OPERATION_RESPONSE_TYPE,
  OPERATION_TYPE,
  PAYLOAD_ITEMS_TYPE,
  FETCH_BUSINESS_CHARGES_RESPONSE,
  INVENTORY_ITEM_TYPE
} from 'services/Api/BillingApiFolder.ts/type'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { printBills } from 'services/Api/growthExperimentApi'
import { newPaths } from 'routes'
import { Navigation } from 'services/navigation'
import {
  fetchFileSettingsReq,
  saveFileSettingsReq,
  createInventoryItem,
  createPurchaseInventory,
  getExpenseApi,
  getInventoryItems,
  getInventoryItemsHistory,
  getPurchaseAccount,
  getPurchaseAssetAccount,
  getPurchaseItems,
  getInventoryTransactionHistoryApi,
  addExpenseAccountApi,
  getExpenseAccountApi,
  addExpenseApiV1,
  syncInventoryMissingBill,
  addBulkPurchaseInventory,
  getBulkPurchaseFileInfo,
  retryBulkPurchaseInventory,
  getBulkPurchaseUnits
} from 'services/Api/StafflinkApi'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { TYPES } from 'pages/NewDashboard/components/Billing/components/AddExpenseItemDrawer/utils'
import { setAllBusinessOffers } from 'state/new-summary/root-drawers/items-offer-and-discount/items-offer-and-discount.actions'
import { PAGE_LIMIT } from 'pages/NewDashboard/components/Billing/InvoiceItem/InvoiceTransactionHistoryBody/util'
import {
  addSingleBill,
  resetBillContextAction,
  setBillingLoaders,
  setBillsData,
  setDrawers,
  setInvoiceItems,
  setInvoiceItemsHistory,
  setItemDetailsFromDrawer,
  setPreviousItemListAction,
  setPrintBillTemplate,
  setFileSettings,
  fetchFileSettings,
  setSavedPreviewBillTemplateAndId,
  setSelectedBillData,
  setUpdatedBillData,
  updatePreviewBillsData,
  updateSingleBill,
  setPurchaseAccount,
  setPurchaseAssetAccount,
  setPurchaseInventoryItems,
  setExpenseTransaction,
  addExpenseItem,
  setInventoryItems,
  setBillingFlags,
  resetEditInventoryItemDetails,
  fetchInventoryItems as fetchInventoryItemsAction,
  setInventoryTransactionHistoryAction,
  setInventoryApiCallAndResponse,
  setPurchaseTransactionHistoryAction,
  updatePurchaseTransactionHistoryAction,
  setBusinessDiscountsData,
  fetchBusinessDiscountsAction,
  setBusinessChargesData,
  fetchBusinessChargesAction,
  setExpenseAccount,
  fetchInventoryUnit,
  setInventoryUnits,
  setFailedBulkUploadData,
  bulkUploadPurchaseItemsStatus,
  getPurchaseTransactionHistoryAction,
  setBulkUploadPurchaseItemsLog,
  setBulkUploadFileRequestId,
  bulkUploadPurchaseItems,
  totalCountOfPurchaseItems
} from './billing.actions'
import {
  computeSGSTandCGSTPercent,
  constructRequestPayload,
  convertPaisaToRupee,
  convertRupeeToPaisa,
  formatBillsData,
  billingRoundFunction,
  formatBillData,
  formatInventoryItemsDataV2Extended,
  getCurrentDateFormatted,
  generateTimestamp,
  downloadBulkPurchaseFailedFile
} from './billing.helpers'
import { API_RESPONSE_TYPE, IApiResponse } from './../../constants'
import {
  BillParams,
  BillsType,
  ICreateBusinessChargeAction,
  ICreateBusinessDiscountAction,
  IFetchBillAction,
  IFetchBillsRequestPayload,
  IFileSettings,
  IRequestPayload,
  ISavePurchaseBillSettingsReq,
  IUploadAction,
  InventoryItemUploadType,
  ItemOperationAction
} from './billing.types'

export function* uploadBillEffect(action: Action<IUploadAction>) {
  const {
    payload: {
      customerDetails,
      invoiceDetails,
      payableAmount,
      shouldPrintBill,
      clearContextOnSave,
      shouldUpdateBill,
      isIGST
    }
  } = action
  const customerId = action.payload.customerId
  const {
    inventory: { inventoryItems }
  } = yield select((app: IAppState) => app.Billing)
  const { merchantProfile: { data: { businessGst = '' } = {} } = {} } = yield select(
    (app: IAppState) => app.Dashboard
  )
  const billNumber = invoiceDetails.invoicePrefix + invoiceDetails.invoiceNumber
  const requestPayload: IRequestPayload = constructRequestPayload(
    {
      ...action.payload
    },
    inventoryItems,
    businessGst,
    !!isIGST
  )

  const response: IApiResponse = yield call(
    shouldUpdateBill ? updateBillApi : uploadBill,
    requestPayload
  )

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(
      pushEvent(NEWDASHBOARD_EVENTS.WEB_BILLS_SAVE_BILL_FAILED, {
        item: action.payload
      })
    )
    if (response?.data?.response?.data?.code === 409) {
      throw new Error('Bill Number Exists', {
        cause: 'customError'
      })
    }
    if (response?.data?.response?.data?.code === 403) {
      if (response.data.response.data.error === 'ledger_account_unauthorised') {
        throw new Error('Bill Not Uploaded, Please Add Customer and retry', {
          cause: 'customError'
        })
      }
    }
    throw new Error('Bill Not Uploaded, Please retry', {
      cause: 'customError'
    })
  }

  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: shouldUpdateBill ? 'Bill Updated Successfully' : 'Bill Created Successfully'
    })
  )

  if (shouldUpdateBill) {
    yield put(
      updateSingleBill({
        billId: response.data.bill.bill_id,
        billNumber: billNumber,
        customerId: customerId,
        customerName: customerDetails.customerName,
        billDate: invoiceDetails.invoiceDate,
        amount: payableAmount,
        dueAmount: payableAmount
      })
    )
  } else {
    yield put(
      addSingleBill({
        billId: response.data.bill_id || '',
        billNumber: billNumber,
        customerId: customerId,
        customerName: customerDetails.customerName,
        billDate: invoiceDetails.invoiceDate,
        amount: payableAmount,
        dueAmount: payableAmount
      })
    )
  }

  if (clearContextOnSave) {
    yield put(resetBillContextAction(true))
  }
  if (shouldPrintBill) {
    const billResponse: IApiResponse = yield call(printBills, [response.data.bill_id])
    if (billResponse.data?.bills.length) {
      const billsHtml: string = billResponse?.data?.bills.map((bill: any) => bill?.content)
      yield put(setPrintBillTemplate(billsHtml))
    }
  }
  if (shouldUpdateBill) {
    yield put(setSelectedBillData(formatBillData(response?.data?.bill)))
    yield put(setUpdatedBillData(true))
    yield put(pushEvent(NEWDASHBOARD_EVENTS.WEB_BILLS_UPDATE_BILL_SUCCESS))
    if (action.payload.billId) {
      yield put(updatePreviewBillsData(action.payload.billId))
    }
    const { currentRoute } = yield select((app: IAppState) => app.Navigation)
    if (currentRoute.path.includes('edit-bill')) {
      Navigation().back()
    }
    return
  }
  yield put(pushEvent(NEWDASHBOARD_EVENTS.WEB_BILLS_SAVE_BILL_SUCCESS))
  yield put(setPreviousItemListAction(true))
  yield delay(1000)
  yield put(fetchInventoryItemsAction())
}

const SRC_FOR_BILLS_CREATED_FROM_BILLING_WEB = 7
const SRC_FOR_BILLS_CREATED_FROM_OTHER_SOURCE = 9
const SRC_FOR_BILLS_CREATED_FROM_LIVE_SALES = 10

export function* fetchBillsEffect(action: Action<IFetchBillAction>) {
  const { customerIds, billNumber, pageNumber, perPage } = action.payload
  const commonPayload: IFetchBillsRequestPayload = {
    ...(customerIds && { customer_ids: customerIds }),
    ...(billNumber && { bill_number: billNumber }),
    page_number: pageNumber || 1,
    per_page: perPage || 1000
  }

  const billingWebPayload = { ...commonPayload, src: SRC_FOR_BILLS_CREATED_FROM_BILLING_WEB }
  const otherSourcePayload = { ...commonPayload, src: SRC_FOR_BILLS_CREATED_FROM_OTHER_SOURCE }
  const livesalesSourcePayload = { ...commonPayload, src: SRC_FOR_BILLS_CREATED_FROM_LIVE_SALES }

  try {
    const [billingWebResponse, otherSourceResponse, livesalesSourceResponse]: [
      IApiResponse<FETCH_BILL_RESPONSE>,
      IApiResponse<FETCH_BILL_RESPONSE>,
      IApiResponse<FETCH_BILL_RESPONSE>
    ] = yield all([
      call(fetchBillsV3, billingWebPayload),
      call(fetchBillsV3, otherSourcePayload),
      call(fetchBillsV3, livesalesSourcePayload)
    ])
    if (
      billingWebResponse.type === API_RESPONSE_TYPE.FAILURE &&
      otherSourceResponse.type === API_RESPONSE_TYPE.FAILURE &&
      livesalesSourceResponse.type === API_RESPONSE_TYPE.FAILURE
    ) {
      throw new Error('Bills not loaded, please refresh', {
        cause: 'customError'
      })
    }

    let combinedBills: string | any[] = []
    if (billingWebResponse?.data?.bills) {
      combinedBills = [...combinedBills, ...billingWebResponse.data.bills]
    }
    if (otherSourceResponse?.data?.bills) {
      combinedBills = [...combinedBills, ...otherSourceResponse.data.bills]
    }
    if (livesalesSourceResponse?.data?.bills) {
      combinedBills = [...combinedBills, ...livesalesSourceResponse.data.bills]
    }

    if (combinedBills.length > 0) {
      const formattedBillsData = formatBillsData(combinedBills)
      yield put(setBillsData(formattedBillsData))
    }
  } catch (error) {
    console.error('Error fetching bills:', error)
  }
}

export function* fetchBusinessDiscountsEffect() {
  const response: IApiResponse<FETCH_BUSINESS_DISCOUNTS_RESPONSE> = yield call(
    fetchBusinessDiscounts
  )

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Discounts not loaded, please refresh', {
      cause: 'customError'
    })
  }
  yield put(setBusinessDiscountsData(response?.data?.discounts || []))
}

export function* createBusinessDiscountEffect(action: Action<ICreateBusinessDiscountAction>) {
  const response: IApiResponse = yield call(createBusinessDiscount, { name: action.payload.name })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    if (response?.data?.response?.data?.code === 409) {
      throw new Error('Business Discount already exists', {
        cause: 'customError'
      })
    }

    throw new Error('Could not create discount, please try again', {
      cause: 'customError'
    })
  }
  yield put(fetchBusinessDiscountsAction())
}

export function* fetchBusinessChargesEffect() {
  const response: IApiResponse<FETCH_BUSINESS_CHARGES_RESPONSE> = yield call(fetchBusinessCharges)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Charges not loaded, please refresh', {
      cause: 'customError'
    })
  }
  yield put(setBusinessChargesData(response?.data?.charges || []))
}

export function* createBusinessChargeEffect(action: Action<ICreateBusinessChargeAction>) {
  const response: IApiResponse = yield call(createBusinessCharge, { name: action.payload.name })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    if (response?.data?.response?.data?.code === 409) {
      throw new Error('Business Charge already exists', {
        cause: 'customError'
      })
    }

    throw new Error('Could not create charge, please try again', {
      cause: 'customError'
    })
  }
  yield put(fetchBusinessChargesAction())
}

export function* deleteBillsEffect(action: Action<string[]>) {
  const { payload } = action

  yield put(setBillingLoaders({ loaderName: 'deletingBills', status: true }))
  const response: IApiResponse[] = yield all(
    payload.map((billId) => call(deleteBillWithBillId, billId))
  )

  const { bills } = yield select((app: IAppState) => app.Billing)
  const billDeletionStatus: Record<string, boolean>[] = payload.map((billId, index) => ({
    [`${billId}`]: response[index].data.success
  }))
  const billsPostDeletion = bills.filter((bill: BillsType) =>
    billDeletionStatus.find((b: Record<string, boolean>) => Object.keys(b)[0] !== bill.billId)
  )

  yield put(setBillsData(billsPostDeletion))
  yield put(setBillingLoaders({ loaderName: 'deletingBills', status: false }))
  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: 'Bill Deleted Successfully !!!'
    })
  )

  const { currentRoute } = yield select((app: IAppState) => app.Navigation)
  if (currentRoute.path === newPaths.billingPreviewBill) {
    Navigation().to(newPaths.billingCreate)
  }
}

export function* addEditInventoryItemsEffect(action: Action<ItemOperationAction>) {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const { item } = action.payload
    const payload = {
      business_id: currentBusinessId,
      name: item.description,
      code: item.sku_id || '',
      opening_balance: item.opening_amount || '',
      unit: ''
    }
    const response: IApiResponse = yield call(createInventoryItem, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setDrawers({ drawerName: 'showAddInvoiceItemDrawer', status: false }))
      const res: IApiResponse = yield call(getInventoryItems, { business_id: currentBusinessId })
      yield put(setInvoiceItems(res.data.items || []))
    }
  } catch (error) {
    console.error(error)
  }
}

function* deleteItemFromInventory({ itemId }: { itemId: string }) {
  yield put(setBillingLoaders({ loaderName: 'deletingItemFromInventory', status: true }))
  try {
    const response: IApiResponse = yield call(inventoryOperations, {
      operation: INVENTORY_OPERATION_TYPE.DELETE_ITEM,
      item: {
        item_id: itemId
      }
    })
    const {
      inventory: { inventoryItems }
    } = yield select((app: IAppState) => app.Billing)

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Item deleted Successfully!'
        })
      )

      yield put(setInventoryItems(inventoryItems.filter((item: any) => item.itemId !== itemId)))
      yield put(
        setBillingFlags({ flagName: 'isItemDeletedSuccessfullyFromInventory', status: true })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Item deletion failed!!!'
        })
      )
    }
  } catch {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Something Went wrong!!!'
      })
    )
  } finally {
    yield put(setBillingLoaders({ loaderName: 'deletingItemFromInventory', status: false }))
    yield put(setDrawers({ drawerName: 'showAddItemDrawer', status: false }))
  }
}

export function* addEditInventoryV2ItemsEffect(action: Action<any>) {
  const { operation, item } = action.payload
  yield put(setBillingLoaders({ loaderName: 'addingItem', status: true }))

  if (operation === INVENTORY_OPERATION_TYPE.DELETE_ITEM) {
    yield deleteItemFromInventory({ itemId: item.itemId })
    return
  }

  let rateInPaisa = billingRoundFunction(convertRupeeToPaisa(item.rate))
  let netRateInPaisa = billingRoundFunction(
    rateInPaisa * (1 + (item.gstPercent + item.cessPercent) / 100)
  )
  let gstAmountInPaisa = billingRoundFunction((rateInPaisa * item.gstPercent) / 100)

  let taxAmountInPaisa = netRateInPaisa - rateInPaisa
  let cessAmountInPaisa = taxAmountInPaisa - gstAmountInPaisa

  let payload: {
    operation: INVENTORY_OPERATION_TYPE
    item: INVENTORY_ITEM_REQUEST_TYPE
  } = {
    operation: operation,
    item: {
      ...(operation === INVENTORY_OPERATION_TYPE.UPDATE_ITEM && {
        item_id: item.item_id,
        catalog_item_id: item.catalog_item_id
      }),
      description: item.description,
      rate: rateInPaisa,
      gst_amount: gstAmountInPaisa,
      gst_basis_pts: item.gstPercent * 100,
      cess_amount: cessAmountInPaisa,
      cess_basis_pts: item.cessPercent * 100,
      hsn_code: item.hsn_code,
      mrp: billingRoundFunction(convertRupeeToPaisa(item.mrp)),
      show_tax_included: false,
      category: item.category,
      quantity: item.quantity,
      sku: item.sku,
      ...(item.unit && { unit: item.unit }),
      ...(item.unit && item.secondaryUnits && { secondary_units: [item.secondaryUnits] })
    }
  }

  if (item.show_tax_included) {
    netRateInPaisa = rateInPaisa
    rateInPaisa = billingRoundFunction(
      netRateInPaisa / (1 + (item.gstPercent + item.cessPercent) / 100)
    )
    gstAmountInPaisa = billingRoundFunction((rateInPaisa * item.gstPercent) / 100)

    taxAmountInPaisa = netRateInPaisa - rateInPaisa
    cessAmountInPaisa = taxAmountInPaisa - gstAmountInPaisa

    payload = {
      operation: operation,
      item: {
        ...payload.item,
        show_tax_included: true,
        rate: rateInPaisa,
        gst_amount: gstAmountInPaisa,
        cess_amount: cessAmountInPaisa
      }
    }
  }

  const response: IApiResponse<{ item: INVENTORY_ITEM_TYPE }> = yield call(
    inventoryOperations,
    payload
  )

  if (response.type === API_RESPONSE_TYPE.SUCCESS) {
    yield put(fetchInventoryItemsAction())
    yield put(resetEditInventoryItemDetails())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.SUCCESS,
        bodyText: 'Item updated Successfully'
      })
    )

    // TODO: This should be done, only if items are getting added from billing page
    /*  This is not an accurate logic, simply added to reduce the issue
        Description: While adding item from from billing, quantity is always 1 
        by default, So whenever quantity is 1, we are not prefilling the fields assuming that
        it is created from billing. 
        IMP NOTE: If user adds item from inventory with quantity 1, the issue will persist. 
        Skipping this for now. Can be implemented later
    */
    const isFromBilling = item.quantity === 1 // temporary approach
    if (isFromBilling) {
      const { sgstPercent, cgstPercent } = computeSGSTandCGSTPercent(item.gstPercent)
      yield put(
        setItemDetailsFromDrawer({
          item: item.description,
          itemId: response?.data?.item?.catalog_item_id || '',
          hsnCode: item.hsn_code,

          discount: 0,
          netRate: convertPaisaToRupee(billingRoundFunction(netRateInPaisa)),
          mrp: item.mrp,
          rate: convertPaisaToRupee(billingRoundFunction(rateInPaisa)),
          sgstTaxPercent: sgstPercent,
          cgstTaxPercent: cgstPercent,
          gstTaxPercent: item.gstPercent,
          igstPercent: item.gstPercent,
          cessPercent: item.cessPercent,
          taxAmount: convertPaisaToRupee(billingRoundFunction(taxAmountInPaisa)),
          gstTotalAmount: convertPaisaToRupee(billingRoundFunction(gstAmountInPaisa)),
          quantity: 1
        })
      )
    }
  }

  yield put(setDrawers({ drawerName: 'showAddInventoryItemDrawer', status: false }))
  yield put(setBillingLoaders({ loaderName: 'addingItem', status: false }))
  yield put(setDrawers({ drawerName: 'showAddItemDrawer', status: false }))
}

export function* fetchInventoryItemsEffect() {
  const response: IApiResponse<FETCH_INVENTORY_ITEMS_RESPONSE> = yield call(fetchInventoryItems)

  if (response.type === API_RESPONSE_TYPE.SUCCESS) {
    const formattedData = formatInventoryItemsDataV2Extended(response.data.inv_items || [])
    yield put(setInventoryItems(formattedData))
  }
}

export function* fetchInventoryUnitEffect() {
  const response: IApiResponse<{ units: string[] }> = yield call(fetchInventoryUnitApi)

  if (response.type === API_RESPONSE_TYPE.SUCCESS) {
    yield put(setInventoryUnits({ units: response.data.units }))
  }
}

export function* fetchInventoryItemsOffersEffect() {
  const response: IApiResponse<any> = yield call(fetchInventoryItemsOffers)

  if (response.type === API_RESPONSE_TYPE.SUCCESS) {
    yield put(setAllBusinessOffers({ businessOffers: response.data.offers || [] }))
  }
}

export function* uploadInventoryItemsEffect(action: Action<InventoryItemUploadType>) {
  const { payload } = action
  yield put(setBillingLoaders({ loaderName: 'uploadingInventory', status: true }))
  yield put(setBillingFlags({ flagName: 'inventoryUploadType', status: payload.action }))

  const response: IApiResponse = yield call(uploadInventoryItemsApi, payload.formData)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something Went Wrong', {
      cause: 'customError'
    })
  }

  if (response.type === API_RESPONSE_TYPE.SUCCESS) {
    /* Refetch Inventory */
    yield put(fetchInventoryItemsAction())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.SUCCESS,
        bodyText: response?.data?.message || 'File Uploaded successfully!!!'
      })
    )

    if (
      response.data?.row_errs?.length ||
      response.data?.errs?.length ||
      response.data?.errs?.duplicate_rownums.length
    ) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Failed to add Few items in inventory!!!'
        })
      )
    }
  }

  yield put(setBillingFlags({ flagName: 'inventoryUploadType', status: '' }))
  yield put(setBillingLoaders({ loaderName: 'uploadingInventory', status: false }))
}

export function* fetchInvoiceItemsEffect() {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const response: IApiResponse = yield call(getInventoryItems, { business_id: currentBusinessId })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setInvoiceItems(response.data.items || []))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* fetchInventoryItemHistoryEffect(action: Action<string>) {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const response: IApiResponse = yield call(getInventoryItemsHistory, {
      business_id: currentBusinessId,
      item_id: action.payload
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setInvoiceItemsHistory(response.data.items))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* fetchPurchaseItemEffect() {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const response: IApiResponse = yield call(getPurchaseItems, {
      business_id: currentBusinessId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setPurchaseInventoryItems(response.data.items || []))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* fetchPurchaseAccountEffect() {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const response: IApiResponse = yield call(getPurchaseAccount, {
      business_id: currentBusinessId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setPurchaseAccount(response.data.accounts || []))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* fetchPurchaseAssetAccountEffect() {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const response: IApiResponse = yield call(getPurchaseAssetAccount, {
      business_id: currentBusinessId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const sortWithId = response.data.accounts.sort((a: any, b: any) => a.id - b.id)
      const sortWithType = sortWithId.sort((a: any, b: any) => a.type.localeCompare(b.type))
      yield put(setPurchaseAssetAccount(sortWithType || []))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* createPurchaseInventoryEffect(action: Action<any>) {
  try {
    const {
      amountPaid,
      itemNameId,
      modeOfPaymentId,
      quantity,
      supplierNameId,
      itemName,
      notes,
      purchaseDate
    } = action.payload.item
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const payload = {
      business_id: currentBusinessId,
      payment_account_id: modeOfPaymentId,
      purchase_account_id: supplierNameId,
      amount: Number((amountPaid * 100).toFixed(0)),
      item_id: itemNameId,
      quantity: quantity,
      purchase_date: purchaseDate,
      notes: notes
    }

    const response: IApiResponse = yield call(createPurchaseInventory, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Purchase Successfully Added !!!'
        })
      )
      yield put(
        updatePurchaseTransactionHistoryAction({
          transaction_id: '',
          quantity,
          code: itemName,
          bill_number: '',
          timestamp: new Date().getTime() / 1000,
          type: 'purchase',
          amount: Number((amountPaid * 100).toFixed(0)),
          transaction_date: purchaseDate,
          notes: notes
        })
      )
      yield put(setDrawers({ drawerName: 'showAddPurchaseItemDrawer', status: false }))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* getExpenseEffect() {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const payload = {
      business_id: currentBusinessId
    }

    const response: IApiResponse = yield call(getExpenseApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setExpenseTransaction(response.data.transactions || []))
    }
  } catch (error) {
    console.error(error)
  }
}

export function* addExpenseEffect(action: Action<any>) {
  try {
    const {
      amountPaid,
      modeOfPaymentId,
      staffNameId,
      particulars,
      payeeTypeId,
      staffName,
      modeOfPayment,
      source,
      expenseDate
    } = action.payload.item
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    interface Payload {
      business_id: string
      payment_account_id?: string
      amount: number
      particulars: string
      type?: string
      staff_id?: string
      expense_account_id?: string
      expense_date: number
    }
    const payload: Payload = {
      business_id: currentBusinessId,
      payment_account_id: modeOfPaymentId,
      amount: Number((amountPaid * 100).toFixed(0)),
      particulars: particulars,
      type: payeeTypeId || '',
      staff_id: staffNameId,
      expense_date: expenseDate
    }
    if (source === TYPES.GENERAL) {
      delete payload.staff_id
      payload.expense_account_id = staffNameId
    }
    if (source === TYPES.BANK_CHARGES) {
      payload.type = 'bank_charges'
      delete payload.staff_id
    }

    const response: IApiResponse = yield call(addExpenseApiV1, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addExpenseItem({
          business_id: '',
          expense_account_id: '',
          payment_account_id: '',
          payment_account_name: modeOfPayment,
          expense_account_name: staffName,
          amount: amountPaid * 100,
          type: source === TYPES.BANK_CHARGES ? 'bank_charges' : payeeTypeId,
          particulars: particulars,
          create_time: new Date().getTime() / 1000,
          expense_date: expenseDate
        })
      )
      yield put(setDrawers({ drawerName: 'showAddExpenseItemDrawer', status: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Expense Successfully Added !!!'
        })
      )
    }
  } catch (error) {
    console.error(error)
  }
}

export function* deleteItemsEffect(action: Action<{ itemIds: string[] }>) {
  const { itemIds } = action.payload

  yield put(setBillingLoaders({ loaderName: 'deletingItems', status: true }))
  const response: IApiResponse[] = yield all(
    itemIds.map((id) => {
      const payload: {
        operation: OPERATION_TYPE
        item: { item_id: string }
      } = {
        operation: OPERATION_TYPE.DELETE_ITEM,
        item: {
          item_id: id
        }
      }
      return call(catalogueOperations, payload)
    })
  )

  const totalFailed =
    response.filter((result) => result.type === API_RESPONSE_TYPE.FAILURE).length || 0

  if (totalFailed !== 0) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: `${totalFailed} items not deleted !!!`
      })
    )
  }

  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: `${itemIds.length - totalFailed} items deleted successfully !!!`
    })
  )

  const { resetSelectedRowRef } = yield select((app: IAppState) => app.Billing)
  resetSelectedRowRef.catalogTable?.current?.resetRowSelection({})
  yield put(setBillingLoaders({ loaderName: 'deletingItems', status: false }))
}

export function* savePurchaseBillSettingsEffect(action: Action<ISavePurchaseBillSettingsReq>) {
  const purchaseBillSettings = action.payload

  yield put(setBillingLoaders({ loaderName: 'savePurchaseBill', status: true }))
  const response: IApiResponse = yield call(saveFileSettingsReq, purchaseBillSettings)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Purchase Bill Settings not saved, Please retry later'
      })
    )
    return null
  }
  yield put(setBillingLoaders({ loaderName: 'savePurchaseBill', status: false }))
  yield put(setDrawers({ drawerName: 'showPurchaseBillDrawer', status: false }))
  yield put(setDrawers({ drawerName: 'showPaymentBulkUploadTemplate', status: false }))
  yield put(fetchFileSettings())
  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: 'Purchase Bill Settings saved successfully !!!'
    })
  )
}

export function* fetchFileSettingsEffect() {
  yield put(setBillingLoaders({ loaderName: 'fetchPurchaseBill', status: true }))
  const response: IApiResponse<{ all_settings: IFileSettings[] }> = yield call(fetchFileSettingsReq)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Purchase Bill Settings not saved, Please retry later'
      })
    )
    return null
  }
  const settings = response?.data?.all_settings?.find(
    (setting) => setting.name === 'default_purchase_bill_template' && setting.is_default
  )
  yield put(setBillingLoaders({ loaderName: 'fetchPurchaseBill', status: false }))
  yield put(setFileSettings(settings || null))
}

export function* fetchBillByIdEffect(action: Action<BillParams>) {
  const { billId } = action.payload
  const response: IApiResponse<BillByIdResponse> = yield call(fetchBillByIdApi, billId)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Not able to get Bill Details, Please Try Again!', {
      cause: 'apiError'
    })
  }

  if (response?.data?.bill?.bill_id) {
    const formattedBillsData = formatBillData(response.data?.bill)
    yield put(setSelectedBillData(formattedBillsData))
  }
}

export function* updatePreviewBillEffect(action: Action<string>) {
  const billId = action.payload
  const billResponse: IApiResponse = yield call(printBills, [billId])
  if (billResponse.data?.bills.length) {
    const billsHtml: string = billResponse?.data?.bills.map((bill: any) => bill?.content)
    yield put(
      setSavedPreviewBillTemplateAndId({
        previewSavedBillId: billId,
        previewSavedBillTemplate: billsHtml
      })
    )
  }
}

export function* getInventoryTransactionHistoryEffect(action: Action<any>) {
  try {
    const { limit, offset, item_id, startTime, endTime, type } = action.payload
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const payload = {
      business_id: currentBusinessId,
      item_id: item_id,
      limit: limit,
      offset: offset,
      type,
      start_time: startTime,
      end_time: endTime
    }

    const response: IApiResponse = yield call(getInventoryTransactionHistoryApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setInventoryTransactionHistoryAction(response.data.transactions || []))
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(
        setInventoryApiCallAndResponse({
          isAllDataIsFetched: false,
          isApiCall: false,
          isApiError: true
        })
      )
    }
  } catch (error) {
    yield put(
      setInventoryApiCallAndResponse({
        isAllDataIsFetched: false,
        isApiCall: false,
        isApiError: true
      })
    )
  }
}

export function* getPurchaseTransactionHistoryEffect(action: Action<any>) {
  try {
    const { purchaseTransactionHistory } = yield select((app: IAppState) => app.Billing)

    const { limit, offset, item_id } = action.payload
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const payload = {
      business_id: currentBusinessId,
      item_id: item_id,
      limit: limit,
      offset: offset,
      type: 'purchase'
    }

    const response: IApiResponse = yield call(getInventoryTransactionHistoryApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      // const existingTransactionIds = new Set(
      //   purchaseTransactionHistory.map((tx: any) => tx.transaction_id)
      // )
      // const newTransactions = (response.data.transactions || []).filter(
      //   (tx: any) => !existingTransactionIds.has(tx.transaction_id)
      // )
      yield put(setPurchaseTransactionHistoryAction(response.data.transactions || []))
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(
        setInventoryApiCallAndResponse({
          isAllDataIsFetched: false,
          isApiCall: false,
          isApiError: true
        })
      )
    }
  } catch (error) {
    yield put(
      setInventoryApiCallAndResponse({
        isAllDataIsFetched: false,
        isApiCall: false,
        isApiError: true
      })
    )
  }
}

export function* addExpenseAccountSagaEffect(action: Action<{ name: string }>) {
  try {
    const business_id: string = yield AuthStorageInstance.getCurrentBusinessId()
    const payload = {
      name: action.payload.name,
      business_id,
      type: 'general'
    }
    const response: IApiResponse = yield call(addExpenseAccountApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setDrawers({ drawerName: 'showAddExpenseAccountDrawer', status: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Expense Account Successfully Added !!!'
        })
      )
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Expense Account not added, Please retry later'
        })
      )
    }
  } catch (error) {}
}

export function* getAllExpenseAccountsEffect() {
  try {
    const business_id: string = yield AuthStorageInstance.getCurrentBusinessId()
    const payload = { business_id }
    const response: IApiResponse = yield call(getExpenseAccountApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setExpenseAccount(response.data.accounts || []))
    }
  } catch (error) {}
}

export function* syncInventoryEffect() {
  try {
    const business_id: string = yield AuthStorageInstance.getCurrentBusinessId()
    const currentYearDateAndMonth = getCurrentDateFormatted()
    const payload = {
      business_id,
      start_date: currentYearDateAndMonth,
      end_date: currentYearDateAndMonth
    }
    const response: IApiResponse = yield call(syncInventoryMissingBill, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Bills Synced Successfully !!!'
        })
      )
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Bills Synced Unsuccessfully !!!'
        })
      )
    }
  } catch (error) {}
}

export function* bulkUploadPurchaseItemsEffect(action: Action<any>) {
  try {
    yield put(bulkUploadPurchaseItemsStatus(true))
    const business_id: string = yield AuthStorageInstance.getCurrentBusinessId()
    const data = {
      business_id,
      units: action.payload.map((unit: any) => ({
        ...unit
      }))
    }
    yield put(setDrawers({ drawerName: 'showPaymentBulkUploadStatus', status: true }))
    const response: IApiResponse = yield call(addBulkPurchaseInventory, data)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setBulkUploadFileRequestId(response?.data?.batch_id || ''))
      const failedUnitsCount = response?.data?.failed_units?.length || 0
      const totalUploadUnits = action.payload.length || 0
      yield put(
        setFailedBulkUploadData({
          failedData: response.data.failed_units || [],
          status: false
        })
      )
      if (failedUnitsCount !== totalUploadUnits) {
        yield put(
          getPurchaseTransactionHistoryAction({ item_id: '', offset: 0, limit: PAGE_LIMIT })
        )
      }
      if (failedUnitsCount > 0) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: `Out of ${totalUploadUnits} units, ${failedUnitsCount} units failed to upload.`
          })
        )
      } else {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.SUCCESS,
            bodyText: `Total ${totalUploadUnits} units uploaded successfully.`
          })
        )
        yield put(setDrawers({ drawerName: 'showPaymentBulkUploadStatus', status: false }))
      }
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(bulkUploadPurchaseItemsStatus(false))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Bills Synced Unsuccessfully !!!'
        })
      )
    }
    if (response?.data?.response?.data?.code === 400) {
      yield put(bulkUploadPurchaseItemsStatus(false))
      yield put(setDrawers({ drawerName: 'showPaymentBulkUploadStatus', status: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.response?.data?.message || 'Bills Synced Unsuccessfully !!!'
        })
      )
    }
  } catch (error) {
    yield put(bulkUploadPurchaseItemsStatus(false))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Bills Synced Unsuccessfully !!!'
      })
    )
  }
}

export function* openBulkUploadPurchaseItemsLogDrawerEffect(action: Action<boolean>) {
  try {
    const business_id: string = yield AuthStorageInstance.getCurrentBusinessId()
    const response: IApiResponse = yield call(getBulkPurchaseFileInfo, { business_id })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setBulkUploadPurchaseItemsLog(response?.data || []))
      yield put(
        setDrawers({ drawerName: 'showPaymentBulkUploadFileLogDrawer', status: action.payload })
      )
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Something Went wrong!!!'
        })
      )
    }
  } catch (error) {}
}

export function* retryBulkUploadPurchaseItemsEffect(action: Action<any>) {
  try {
    const { bulkUploadFileRequestId } = yield select((app: IAppState) => app.Billing)
    yield put(setDrawers({ drawerName: 'showPaymentBulkUploadFileLogDrawer', status: false }))
    yield put(bulkUploadPurchaseItemsStatus(true))
    const business_id: string = yield AuthStorageInstance.getCurrentBusinessId()
    const data = {
      business_id,
      batch_id: bulkUploadFileRequestId
    }
    yield put(setDrawers({ drawerName: 'showPaymentBulkUploadStatus', status: true }))
    const response: IApiResponse = yield call(retryBulkPurchaseInventory, data)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setBulkUploadFileRequestId(response?.data?.batch_id || ''))
      const failedUnitsCount = response?.data?.failed_unit_count || 0
      const totalUploadUnits =
        response?.data?.processed_unit_count || 0 + response?.data?.pending_unit_count || 0
      yield put(totalCountOfPurchaseItems(totalUploadUnits))
      yield put(
        setFailedBulkUploadData({
          failedData: response.data.failed_units || [],
          status: false
        })
      )
      if (!response?.data?.failed_units || failedUnitsCount !== totalUploadUnits) {
        yield put(
          getPurchaseTransactionHistoryAction({ item_id: '', offset: 0, limit: PAGE_LIMIT })
        )
      }
      if (failedUnitsCount > 0) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: `Out of ${totalUploadUnits} units, ${failedUnitsCount} units failed to upload.`
          })
        )
      } else {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.SUCCESS,
            bodyText: `Total ${totalUploadUnits} units uploaded successfully.`
          })
        )
        yield put(setDrawers({ drawerName: 'showPaymentBulkUploadStatus', status: false }))
      }
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(bulkUploadPurchaseItemsStatus(false))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Bills Synced Unsuccessfully !!!'
        })
      )
    }
    if (response?.data?.response?.data?.code === 400) {
      yield put(bulkUploadPurchaseItemsStatus(false))
      yield put(setDrawers({ drawerName: 'showPaymentBulkUploadStatus', status: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.response?.data?.message || 'Bills Synced Unsuccessfully !!!'
        })
      )
    }
  } catch (error) {
    yield put(bulkUploadPurchaseItemsStatus(false))
    yield put(setDrawers({ drawerName: 'showPaymentBulkUploadStatus', status: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Bills Synced Unsuccessfully !!!'
      })
    )
  }
}

export function* downloadBulkUploadPurchaseItemsEffect(action: Action<any>) {
  try {
    const business_id: string = yield AuthStorageInstance.getCurrentBusinessId()
    const { bulkUploadFileRequestId, purchaseAssetAccount } = yield select(
      (app: IAppState) => app.Billing
    )
    const data = {
      business_id,
      batch_id: bulkUploadFileRequestId,
      filter_statuses: ['failed', 'pending']
    }
    const response: IApiResponse = yield call(getBulkPurchaseUnits, data)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const timestamp = generateTimestamp()
      const filename = `Failed Upload Purchase Items ${timestamp}`
      downloadBulkPurchaseFailedFile(purchaseAssetAccount, response.data.units, filename)
    }
  } catch (error) {}
}
