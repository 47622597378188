import { PaginationDetails } from 'services/Api/StaffLinkApiFolder/type'
import { CUSTOMER_PROFILE_SORT_OPTION, IDashboardState } from 'state/dashboard/dashboard.types'

export const getFormattedAccountIds = (bills: Array<any>) =>
  bills.map((__) => ({
    customerId: __.customer_id,
    billId: __.bill_id
  }))

export type FetchCustomerProfilesResponse = {
  paginationDetails: PaginationDetails
  customer_profiles: ICustomerProfileRowData[]
}

export type ICustomerProfileRowData = {
  id: string
  customer_name: string
  customer_mobile: string
  customer_gst: string
  added_on: string
  customer_code: string
  customer_address: string
  tally_name: string
  beats: string[]
  routes: string[]
  tags: string[]
}

export const formatCustomerProfiles = (
  data: FetchCustomerProfilesResponse,
  options: {
    sort_by: CUSTOMER_PROFILE_SORT_OPTION
    sort_descending: boolean
    search_string: string
  }
): IDashboardState['customerProfiles'] => {
  return {
    customers: data.customer_profiles,
    paginationData: {
      per_page: Number(data.paginationDetails.per_page),
      page: Number(data.paginationDetails.page),
      total_items: Number(data.paginationDetails.total_items)
    },
    ...options
  }
}
