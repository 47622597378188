import { Action } from 'infra/types'
import { LINK_STATUS } from './blindpay.types'
export const BlindpayActions = {
  FETCH_PAYMENT_DETAILS: 'blindpay/fetchPaymentDetails',
  FETCH_PAYMENT_DETAILS_SUCCESS: 'blindpay/fetchPaymentDetailsSuccess',
  FETCH_PAYMENT_DETAILS_FAILURE: 'blindpay/fetchPaymentDetailsFailure',

  SEND_OTP: 'blindpay/sendOtp',
  SEND_OTP_SUCCESS: 'blindpay/sendOtpSuccess',
  SEND_OTP_FAILURE: 'blindpay/sendOtpFailure',

  VERIFY_OTP: 'blindpay/verifyOtp',
  VERIFY_OTP_SUCCESS: 'blindpay/verifyOtpSuccess',
  VERIFY_OTP_FAILURE: 'blindpay/verifyOtpFailure',

  VERIFY_DESTINATION_DETAILS: 'blindpay/verifyDestinationDetails',
  VERIFY_DESTINATION_DETAILS_SUCCESS: 'blindpay/verifyDestinationDetailsSuccess',
  VERIFY_DESTINATION_DETAILS_FAILURE: 'blindpay/verifyDestinationDetailsFailure',

  SUBMIT_DESTINATION_DETAILS: 'blindpay/submitDestinationDetails',
  SUBMIT_DESTINATION_DETAILS_SUCCESS: 'blindpay/submitDestinationDetailsSuccess',
  SUBMIT_DESTINATION_DETAILS_FAILURE: 'blindpay/submitDestinationDetailsFailure',

  SESSION_EXPIRED: 'blindpay/sessionExpired',
  SESSION_RESTART: 'blindpay/sessionRestart',

  // Mixpanel Actions
  BLINDPAY_PAGE_VIEW: 'blindpay/blindpayPageView',
  CLAIM_PAYMENT: 'blindpay/claimPayment',
  ENTERING_OTP: 'blindpay/enteringOtp',
  RESEND_OTP: 'blindpay/resendOtp',
  CHANGE_DESTIANTION_TYPE: 'blindpay/changeDestinationType',
  CHANGE_DESTINATION_DETAILS: 'blindpay/changeDestinationDetails',
  ENTERING_DESTINATION_DETAILS: 'blindpay/enteringDestinationDetails',
  POST_CLAIM_PAGE_VIEW: 'blindpay/postClaimPageView'
}

export const fetchPaymentDetails = (txnId: string): Action<string> => ({
  type: BlindpayActions.FETCH_PAYMENT_DETAILS,
  payload: txnId
})

export const fetchPaymentDetailsSuccess = (payload: any): Action<any> => ({
  type: BlindpayActions.FETCH_PAYMENT_DETAILS_SUCCESS,
  payload
})

export const fetchPaymentDetailsFailure = (): Action<null> => ({
  type: BlindpayActions.FETCH_PAYMENT_DETAILS_FAILURE,
  payload: null
})

// Handle Failure fetchPaymentDetails

export const requestOtp = (blindPayId: string): Action<string> => ({
  type: BlindpayActions.SEND_OTP,
  payload: blindPayId
})

export const requestOtpSuccess = (payload: string): Action<string> => ({
  type: BlindpayActions.SEND_OTP_SUCCESS,
  payload
})

// Handle Failure sendOtp

export const verifyOtp = (payload: {
  otp: string
  otpId: string
  blindPayId: string
}): Action<{ otp: string; otpId: string; blindPayId: string }> => ({
  type: BlindpayActions.VERIFY_OTP,
  payload
})

export const verifyOtpSuccess = (payload: any): Action<any> => ({
  type: BlindpayActions.VERIFY_OTP_SUCCESS,
  payload
})

export const verifyOtpFailure = (): Action<null> => ({
  type: BlindpayActions.VERIFY_OTP_FAILURE,
  payload: null
})

export const verifyDestinationDetails = (payload: {
  paymentAddressType: string
  paymentAddress: string
}): Action<{ paymentAddressType: string; paymentAddress: string }> => ({
  type: BlindpayActions.VERIFY_DESTINATION_DETAILS,
  payload
})

export const verifyDestinationDetailsSuccess = (payload: any): Action<any> => ({
  type: BlindpayActions.VERIFY_DESTINATION_DETAILS_SUCCESS,
  payload
})

export const verifyDestinationDetailsFailure = (): Action<null> => ({
  type: BlindpayActions.VERIFY_DESTINATION_DETAILS_FAILURE,
  payload: null
})

export const submitDestinationDetails = (payload: {
  blindPayId: string
  paymentAddressType: string
  paymentAddress: string
}): Action<{ blindPayId: string; paymentAddressType: string; paymentAddress: string }> => ({
  type: BlindpayActions.SUBMIT_DESTINATION_DETAILS,
  payload
})

export const submitDestinationDetailsSuccess = (payload: any): Action<any> => ({
  type: BlindpayActions.SUBMIT_DESTINATION_DETAILS_SUCCESS,
  payload
})

export const sessionExpired = (): Action<null> => ({
  type: BlindpayActions.SESSION_EXPIRED,
  payload: null
})

export const sessionRestart = (): Action<null> => ({
  type: BlindpayActions.SESSION_RESTART,
  payload: null
})

export const blindpayPageView = (linkStatus: LINK_STATUS): Action<LINK_STATUS> => ({
  type: BlindpayActions.BLINDPAY_PAGE_VIEW,
  payload: linkStatus
})

export const claimPayment = (): Action<null> => ({
  type: BlindpayActions.CLAIM_PAYMENT,
  payload: null
})

export const enteringOtp = (): Action<null> => ({
  type: BlindpayActions.ENTERING_OTP,
  payload: null
})

export const resendOtp = (blindPayId: string): Action<string> => ({
  type: BlindpayActions.RESEND_OTP,
  payload: blindPayId
})

export const enteringDestinationDetails = (paymentAddressType: string): Action<string> => ({
  type: BlindpayActions.ENTERING_DESTINATION_DETAILS,
  payload: paymentAddressType
})

export const changeDestinationType = (destinationType: string): Action<string> => ({
  type: BlindpayActions.CHANGE_DESTIANTION_TYPE,
  payload: destinationType
})

export const changeDestinationDetails = (): Action<null> => ({
  type: BlindpayActions.CHANGE_DESTINATION_DETAILS,
  payload: null
})

export const postClaimPageView = (paymentStatus: string): Action<string> => ({
  type: BlindpayActions.POST_CLAIM_PAGE_VIEW,
  payload: paymentStatus
})
