import { all, call, put } from 'redux-saga/effects'
import { Action } from 'infra/types'
import { linkDelinkInvoiceTags } from 'services/Api/StafflinkApi'
import { INVOICE_TAG_ACTION_TYPE } from 'state/tags/tags.types'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { setApplyTagDrawer } from 'state/new-summary/root-drawers/apply-tags/apply-tags.actions'
import { setAddedBillsDrawer } from 'state/billing/billing.actions'

export function* applyTagsToBillEffect(
  action: Action<
    {
      billId: string
      customerId: string
      tagIds: string[]
    }[]
  >
): any {
  try {
    const responseData: IApiResponse[] = yield all(
      action.payload.map((item) =>
        call(linkDelinkInvoiceTags, {
          account_id: item.customerId,
          bill_tags_actions: [
            {
              action: INVOICE_TAG_ACTION_TYPE.LINK,
              bill_id: item.billId,
              tag_ids: item.tagIds
            }
          ]
        })
      )
    )

    const failureCount = responseData.reduce((acc, response) => {
      if (response.type === API_RESPONSE_TYPE.FAILURE) {
        acc = acc + 1
      }
      return acc
    }, 0)
    if (failureCount > 0) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Tags Partially applied !!!'
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Tags applied !!!'
        })
      )
    }
    yield put(setApplyTagDrawer({ drawerStatus: false }))
    yield put(setAddedBillsDrawer(true))
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Something Went Wrong !!!'
      })
    )
  }
}
