import { call, delay, put, select } from 'redux-saga/effects'
import { utils, writeFileXLSX } from 'xlsx'
import dayjs from 'dayjs'
import { IAppState } from 'infra/AppState'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import {
  bankDepositHistoryApi,
  cashDepositHistoryApi,
  createBankStatementTemplateApi,
  deleteBankStatementTxnsApi,
  fetchBankStatementDocumentApi,
  fetchBankStatementDocumentsApi,
  fetchBankStatementReconciledTxnsApi,
  fetchBankStatementTemplatesApi,
  getAccountTransactionsApi,
  updateAssetAccountApi,
  updateBankStatementTemplateApi
} from 'services/Api/StafflinkApi'
import { Action } from 'infra/types'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { getPurchaseAssetAccount, setPurchaseAssetAccount } from 'state/billing/billing.actions'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import { formatBankCashDepositHistory } from 'state/new-summary/bankStatement/formatter'
import { getFormattedSupplierTransactionBankReconciliation } from 'state/transaction/helpers/formatter'
import { ROLE } from 'state/transaction/transaction.types'
import {
  StatementReconTxnActionPayloadType,
  BankStatementDocumentState,
  BankStatementDocumentType,
  CreateBankStatementTemplatePayloadType,
  UpdateBankStatementTemplatePayloadType,
  BankStatementReconTxnType,
  IBankHistoryType
} from './bankStatement.types'
import {
  formatDocumentsData,
  formatBankStatementTemplatesData,
  formatBankStatementTemplateData,
  formatReconciledTxnsData,
  BANK_STATEMENT_DOCUMENT_STATUS_POLL_INTERVAL,
  BANK_STATEMENT_DOCUMENT_STATUS_POLL_COUNT,
  formatDocumentData,
  convertEpochToDate,
  filterDataByDateRange
} from './bankStatement.helpers'
import {
  fetchBankStatementReconciledTxns,
  setBankDepositHistoryAction,
  setBankStatementDocuments,
  setBankStatementReconciledTxns,
  setBankStatementTemplates,
  setCashDepositHistoryAction,
  setCastDepositTransactionHistoryAction,
  setDrawerAction,
  setFilterTime,
  setLoaderAction,
  setShowDownloadPopUpAction,
  setSingleBankStatementDocument,
  setSingleBankStatementTemplate,
  stopPollBankStatementDocumentStatus,
  storeFetchedTransactions
} from './bankStatement.actions'

export function* fetchBankStatementTemplatesEffect() {
  yield put(setLoaderAction({ id: 'bankStatementTemplate', value: true }))
  const response: IApiResponse = yield call(fetchBankStatementTemplatesApi)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went wrong while fetching the templates, Please Refresh', {
      cause: 'customError'
    })
  }
  yield put(setBankStatementTemplates(formatBankStatementTemplatesData(response.data.templates)))
  yield put(setLoaderAction({ id: 'bankStatementTemplate', value: false }))
}

export function* createBankStatementTemplateEffect(action: {
  payload: CreateBankStatementTemplatePayloadType
}) {
  yield put(setLoaderAction({ id: 'bankStatementTemplate', value: true }))
  const response: IApiResponse = yield call(createBankStatementTemplateApi, action.payload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went wrong while creating the template, Please Refresh', {
      cause: 'customError'
    })
  }
  yield put(setSingleBankStatementTemplate(formatBankStatementTemplateData(response.data.template)))
  yield put(setLoaderAction({ id: 'bankStatementTemplate', value: false }))
}

export function* updateBankStatementTemplateEffect(action: {
  payload: UpdateBankStatementTemplatePayloadType
}) {
  yield put(setLoaderAction({ id: 'bankStatementTemplate', value: true }))
  const response: IApiResponse = yield call(updateBankStatementTemplateApi, action.payload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went wrong while updating the template, Please Refresh', {
      cause: 'customError'
    })
  }
  yield put(setSingleBankStatementTemplate(formatBankStatementTemplateData(response.data.template)))
  yield put(setLoaderAction({ id: 'bankStatementTemplate', value: false }))
}

export function* fetchBankStatementDocumentsEffect() {
  yield put(setLoaderAction({ id: 'bankStatementDocument', value: true }))
  const response: IApiResponse = yield call(fetchBankStatementDocumentsApi)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went wrong while fetching the uploaded documents, Please Refresh', {
      cause: 'customError'
    })
  }
  yield put(setBankStatementDocuments(formatDocumentsData(response.data.documents)))
  yield put(setLoaderAction({ id: 'bankStatementDocument', value: false }))
}

export function* fetchBankStatementReconciledTxnsEffect(action: {
  payload: StatementReconTxnActionPayloadType
}) {
  const { startTime, endTime } = action.payload
  yield put(setLoaderAction({ id: 'bankStatementReconciledTxns', value: true }))
  const response: IApiResponse = yield call(fetchBankStatementReconciledTxnsApi, {
    start_time: startTime,
    end_time: endTime
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went wrong while fetching the bank statements, Please Refresh', {
      cause: 'customError'
    })
  }
  yield put(setBankStatementReconciledTxns(formatReconciledTxnsData(response.data.recon_txns)))
  yield put(setFilterTime(action.payload))
  yield put(setLoaderAction({ id: 'bankStatementReconciledTxns', value: false }))
}

export function* deleteBankStatementTxnsEffect(action: { payload: string[] }) {
  yield put(setLoaderAction({ id: 'bankStatementReconciledTxns', value: true }))
  const { transactions } = yield select((app: IAppState) => app.Reconcile.BankStatement)

  const response: IApiResponse = yield call(deleteBankStatementTxnsApi, action.payload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something went wrong while deleting the bank statement, Please Refresh', {
      cause: 'customError'
    })
  }
  yield put(
    setBankStatementReconciledTxns({
      reconciled: transactions.reconciledTransactions.filter(
        (txn: BankStatementReconTxnType) => !action.payload.includes(txn.id)
      ),
      unReconciled: transactions.unReconciledTransactions.filter(
        (txn: BankStatementReconTxnType) => !action.payload.includes(txn.id)
      )
    })
  )
  yield put(setLoaderAction({ id: 'bankStatementReconciledTxns', value: false }))
}

export function* startBankStatementDocumentStatusPollingEffect(action: Action<string>) {
  let statusPollCount = BANK_STATEMENT_DOCUMENT_STATUS_POLL_COUNT
  while (statusPollCount > 0) {
    statusPollCount--
    try {
      const response: IApiResponse = yield call(fetchBankStatementDocumentApi, [action.payload])
      if (response.type === API_RESPONSE_TYPE.FAILURE) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: 'Unable to retrieve last uploaded statement status, please try again.'
          })
        )
        yield put(stopPollBankStatementDocumentStatus())
        return
      }
      const { documents, filterTime } = yield select(
        (app: IAppState) => app.Reconcile.BankStatement
      )

      const uploadingDocument = documents.find(
        (doc: BankStatementDocumentType) => action.payload === doc.id
      )
      const uploadedDocument = response.data.documents[0]
      if (uploadedDocument.state !== uploadingDocument.state) {
        yield put(setSingleBankStatementDocument(formatDocumentData(uploadedDocument)))
      }
      // Polling can be stopped if required state is received
      if (uploadedDocument.state === BankStatementDocumentState.UPLOAD_FAILED) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: 'Document upload failed, try again later.'
          })
        )
        yield put(stopPollBankStatementDocumentStatus())
      } else if (uploadedDocument.state === BankStatementDocumentState.RECONCILIATION_COMPLETE) {
        // Upload successful
        yield put(
          addAutoFadeNotification({
            type: NotificationType.SUCCESS,
            bodyText: 'Document uploaded successfully.'
          })
        )
        yield put(fetchBankStatementReconciledTxns(filterTime))
        yield put(stopPollBankStatementDocumentStatus())
      }
      if (statusPollCount === 0) {
        yield put(stopPollBankStatementDocumentStatus())
      }
      yield delay(BANK_STATEMENT_DOCUMENT_STATUS_POLL_INTERVAL)
    } catch (error) {
      yield put(stopPollBankStatementDocumentStatus())
    }
  }
}

export function* editAssetAccountEffect(action: { payload: { account_id: string; name: string } }) {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const { editAssetId } = yield select((app: IAppState) => app.Reconcile.BankStatement)
    const { purchaseAssetAccount } = yield select((app: IAppState) => app.Billing)
    const payload = {
      business_id: currentBusinessId,
      account_id: editAssetId,
      name: action.payload.name
    }
    const response: IApiResponse = yield call(updateAssetAccountApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const updatedPurchaseAssetAccount = purchaseAssetAccount.map((item: any) =>
        item.id === editAssetId ? { ...item, name: action.payload.name } : item
      )

      yield put(setPurchaseAssetAccount(updatedPurchaseAssetAccount))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Edit Successfully !!!'
        })
      )
      yield put(setDrawerAction({ drawerName: 'showEditAssetAccount', status: false }))
    }
  } catch (error) {}
}

export function* getCashDepositHistoryEffect(action: Action<any>) {
  try {
    const { limit, offset } = action.payload
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const { depositHistory, bankHistoryType } = yield select(
      (app: IAppState) => app.Reconcile.BankStatement
    )
    const payload = {
      business_id: currentBusinessId,
      limit: limit,
      offset: offset,
      start_time: depositHistory.startTime,
      end_time: depositHistory.endTime
    }

    const response: IApiResponse = yield call(
      bankHistoryType === IBankHistoryType.BANK ? bankDepositHistoryApi : cashDepositHistoryApi,
      payload
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setCashDepositHistoryAction(response.data.deposits || []))
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(
        setCastDepositTransactionHistoryAction({
          isAllDataIsFetched: false,
          isApiCall: false,
          isApiError: true
        })
      )
    }
  } catch (error) {
    yield put(
      setCastDepositTransactionHistoryAction({
        isAllDataIsFetched: false,
        isApiCall: false,
        isApiError: true
      })
    )
  }
}

export function* downloadBankAndCashStatementEffect(action: Action<any>) {
  const { fileName } = action.payload
  const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
  const { depositHistory, bankHistoryType } = yield select(
    (app: IAppState) => app.Reconcile.BankStatement
  )
  const payload = {
    business_id: currentBusinessId,
    limit: 9999,
    offset: 0,
    start_time: depositHistory.startTime,
    end_time: depositHistory.endTime
  }

  const response: IApiResponse = yield call(
    bankHistoryType === IBankHistoryType.BANK ? bankDepositHistoryApi : cashDepositHistoryApi,
    payload
  )

  const data = formatBankCashDepositHistory(response.data.deposits || [], bankHistoryType)
  const ws = utils.json_to_sheet(data, { origin: 'A6' })
  const wb = utils.book_new()
  const excelHeading =
    bankHistoryType === IBankHistoryType.BANK ? 'Bank Deposit Summary' : 'Cash Deposit Summary'
  utils.sheet_add_aoa(ws, [[excelHeading], [], [`Printed On: ${dayjs().format('DD MMM, YYYY')}`]], {
    origin: 'A1'
  })

  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)
  yield put(setShowDownloadPopUpAction(false))
}

export function* fetchAccountTransactionEffect(
  action: Action<{
    id: string
    limit?: number
    start_time: number
    end_time: number
  }>
) {
  try {
    const payload = {
      id: action.payload.id,
      limit: action.payload.limit || 9999,
      start_time: 0,
      role: ROLE.SUPPLIER
    }
    yield put(setLoaderAction({ id: 'bankReconciliationTxns', value: true }))
    const response: IApiResponse = yield call(getAccountTransactionsApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      // let supplierSum = 0
      // const transactions = response.data.transactions
      //   .reverse()
      //   .sort((a: any, b: any) => Number(a.bill_date) - Number(b.bill_date))
      //   .map((supplierTransaction: any) => {
      //     const formattedSupplierData = getFormattedSupplierTransactionBankReconciliation(
      //       supplierTransaction,
      //       supplierSum
      //     )
      //     supplierSum = formattedSupplierData.outStandingAmount
      //     return formattedSupplierData
      //   })
      const filterNonDeletedTransactions = response.data.transactions.filter(
        (item: any) => !item.deleted
      )
      const filteredData = filterDataByDateRange(
        filterNonDeletedTransactions,
        action.payload.start_time,
        action.payload.end_time
      )
      // const closingBalance = filteredData[filteredData.length - 1].outStandingAmount
      // const openingBalanceId = filteredData[0].id
      // const openingBalanceIdIndex = transactions.findIndex(
      //   (item: any) => item.id === openingBalanceId
      // )
      // const openingBalance = transactions[openingBalanceIdIndex - 1].outStandingAmount

      const data = filteredData.map((item: any) => {
        return {
          id: item.id,
          type: item.type,
          amount: item.amount,
          bill_date: convertEpochToDate(item.bill_date),
          note: item.note,
          balance: item.outStandingAmount
        }
      })
      yield put(setLoaderAction({ id: 'bankReconciliationTxns', value: false }))
      yield put(
        storeFetchedTransactions({
          transaction: data,
          transactionOpeningBalance: 0,
          transactionClosingBalance: 0
        })
      )
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Please try again.'
        })
      )
      yield put(setLoaderAction({ id: 'bankReconciliationTxns', value: false }))
    }
  } catch (error) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Please try again.'
      })
    )
    yield put(setLoaderAction({ id: 'bankReconciliationTxns', value: false }))
  }
}
