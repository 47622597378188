import { takeLatest, takeEvery } from 'redux-saga/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import {
  handleFetchStaffLinkDataEffect,
  handleFetchPaymentStatus,
  getMerchatCustomerUsingPhoneNoEffect,
  handleCreateAutoDebitLinkEffect,
  handleUpdateTransactionEffect,
  handleCollectionSummaryEffect,
  createNewTransactionEffect,
  createNewMultipleTransactionEffect,
  handleDeleteTransactionEffect,
  handleFetchBillPaymentStatus,
  handleCancelInvoiceEffect,
  handleUndoCancelInvoiceEffect,
  handleCreateReplacementRecordEffect,
  handleUpdateReplacementRecordEffect,
  updateStaffNameEffect,
  handleUpdateMultipleTransactionEffect,
  setupStaffOrdersEffect,
  fetchBusinessAccountsEffect,
  fetchCatalogItemsEffect,
  createNewOrderEffect,
  createNewInvoiceEffect,
  getOrderHistoryEffect,
  updateStaffOrderEffect,
  staffSettleProformaEffect,
  fetchStaffBusinessEffect
} from './StaffLink.effects'
import { StaffLinkActions, StaffLinkAnalyticsActions } from './StaffLink.actions'
import StaffLinkAnalytics from './StaffLink.analytics'

/*
 * CHECK STATUS button click on QR drawer
 */
function* handleFetchStaffLinkDataSage() {
  yield takeLatest(StaffLinkActions.HANDLE_FETCH_STAFFLINK_DATA, handleFetchStaffLinkDataEffect)
}
function* paymentStatusSaga() {
  yield takeLatest(StaffLinkActions.REQUEST_PAYMENT_STATUS, handleFetchPaymentStatus)
}
function* handleCreateAutoDebitLinkSaga() {
  yield takeLatest(StaffLinkActions.CREATE_AUTO_DEBIT_LINK, handleCreateAutoDebitLinkEffect)
}
function* handleUpdateTransactionSaga() {
  yield takeLatest(StaffLinkActions.HANDLE_UPDATE_TRANSACTION, handleUpdateTransactionEffect)
}
function* handleUpdateMultipleTransactionSaga() {
  yield takeLatest(
    StaffLinkActions.HANDLE_UPDATE_MULTIPLE_TRANSACTION,
    handleUpdateMultipleTransactionEffect
  )
}
function* handleDeleteTransactionSaga() {
  yield takeLatest(StaffLinkActions.HANDLE_DELETE_TRANSACTION, handleDeleteTransactionEffect)
}
function* handleCancelInvoiceSaga() {
  yield takeLatest(StaffLinkActions.HANDLE_CANCEL_INVOICE, handleCancelInvoiceEffect)
}
function* handleUndoCancelInvoiceSaga() {
  yield takeLatest(StaffLinkActions.HANDLE_UNDO_CANCEL_INVOICE, handleUndoCancelInvoiceEffect)
}
function* handleCreateNewTransactionSaga() {
  yield takeLatest(StaffLinkActions.CREATE_NEW_TRANSACTION, createNewTransactionEffect)
}
function* handleCreateNewMultipleTransactionSaga() {
  yield takeLatest(
    StaffLinkActions.CREATE_NEW_MULTIPLE_TRANSACTION,
    createNewMultipleTransactionEffect
  )
}
function* handleCreateReplacementRecordSaga() {
  yield takeLatest(StaffLinkActions.CREATE_REPLACEMENT_RECORD, handleCreateReplacementRecordEffect)
}
function* handleUpdateReplacementRecordSaga() {
  yield takeLatest(
    StaffLinkActions.UPDATE_REPLACEMENT_RECORD_FETCH,
    handleUpdateReplacementRecordEffect
  )
}
function* getMerchatCustomerUsingPhoneNoSaga() {
  yield takeLatest(
    StaffLinkActions.GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER,
    getMerchatCustomerUsingPhoneNoEffect
  )
}
function* handleCollectionSummarySaga() {
  yield takeLatest(StaffLinkActions.FETCH_COLLECTION_SUMMARY, handleCollectionSummaryEffect)
}

/*
 * Events watcher
 */
function* staffLinkAnalyticsSaga() {
  yield takeEvery([...Object.values(StaffLinkAnalyticsActions)], StaffLinkAnalytics)
}

function* billPaymentStatusSaga() {
  yield takeLatest(StaffLinkActions.REQUEST_BILL_PAYMENT_STATUS, handleFetchBillPaymentStatus)
}

function* updateStaffNameSaga() {
  yield takeLatest(StaffLinkActions.UPDATE_STAFF_DETAILS, updateStaffNameEffect)
}

function* fetchBusinessAccountsSaga() {
  yield takeLatest(StaffLinkActions.FETCH_BUSINESS_ACCOUNTS, fetchBusinessAccountsEffect)
}

function* fetchCatalogItemsSaga() {
  yield takeCustomLatest(StaffLinkActions.FETCH_CATALOG_ITEMS, fetchCatalogItemsEffect)
}

function* createNewOrderSaga() {
  yield takeCustomLatest(StaffLinkActions.CREATE_NEW_ORDER, createNewOrderEffect)
}

function* getOrderHistorySaga() {
  yield takeCustomLatest(StaffLinkActions.GET_STAFF_ORDER_HISTORY, getOrderHistoryEffect)
}

function* updateStaffOrderSaga() {
  yield takeCustomLatest(StaffLinkActions.UPDATE_STAFF_ORDER_ACTION, updateStaffOrderEffect, () => {
    addAutoFadeNotification({
      type: NotificationType.ERROR,
      bodyText: 'Order Updated Failed! Please try again'
    })
  })
}

function* setupStaffOrdersSaga() {
  yield takeCustomLatest(StaffLinkActions.SETUP_STAFF_ORDERS, setupStaffOrdersEffect)
}

function* staffSettleProformaSaga() {
  yield takeCustomLatest(StaffLinkActions.STAFF_SETTLE_PROFORMA_ACTION, staffSettleProformaEffect)
}

function* createNewInvoiceSaga() {
  yield takeCustomLatest(StaffLinkActions.CREATE_NEW_INVOICE, createNewInvoiceEffect)
}

function* fetchStaffBusinessActionSaga() {
  yield takeCustomLatest(StaffLinkActions.FETCH_STAFF_BUSINESS_ACTION, fetchStaffBusinessEffect)
}

export const StaffLinkEffects = [
  paymentStatusSaga(),
  getMerchatCustomerUsingPhoneNoSaga(),
  staffLinkAnalyticsSaga(),
  handleCreateAutoDebitLinkSaga(),
  handleFetchStaffLinkDataSage(),
  handleUpdateTransactionSaga(),
  handleCollectionSummarySaga(),
  handleCreateNewTransactionSaga(),
  handleDeleteTransactionSaga(),
  billPaymentStatusSaga(),
  handleCancelInvoiceSaga(),
  handleUndoCancelInvoiceSaga(),
  handleCreateReplacementRecordSaga(),
  handleUpdateReplacementRecordSaga(),
  updateStaffNameSaga(),
  handleCreateNewMultipleTransactionSaga(),
  handleUpdateMultipleTransactionSaga(),
  setupStaffOrdersSaga(),
  fetchBusinessAccountsSaga(),
  fetchCatalogItemsSaga(),
  createNewOrderSaga(),
  getOrderHistorySaga(),
  updateStaffOrderSaga(),
  staffSettleProformaSaga(),
  createNewInvoiceSaga(),
  fetchStaffBusinessActionSaga()
]
