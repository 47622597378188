import { put, delay } from 'redux-saga/effects'
import { Action } from 'infra/types'
import { addNotification, deleteNotification } from './Notification.actions'
import { INotification } from './Notification.types'

export function* addAutoFadeNotification(action: Action<INotification>) {
  const notificationAction = addNotification(action.payload)
  yield put(notificationAction)
  yield delay(5000)
  yield put(deleteNotification(notificationAction.payload.id))
}
