import { select } from 'redux-saga/effects'
import { _getEntityRelatedAttributes } from 'state/transaction/transaction.analytics'
import { Action } from 'infra/types'
import { IAppState } from 'infra/AppState'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { NavigationActions } from 'state/navigation/navigation.actions'
import { ENTITY_TYPE, IFilterUnit } from 'shared/constants'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { DashboardActions } from './dashboard.actions'
import { IDashboardState, HELP_SECTION_INTERACTION, HELP_ITEM_METHOD } from './dashboard.types'

const _getEntityName = (entity: ENTITY_TYPE) => {
  return entity === ENTITY_TYPE.CUSTOMER ? 'Customer' : 'Supplier'
}

const _FieldNameMapping = {
  txnAlertEnabled: 'SMS Setting',
  lang: 'SMS Lang'
}

export default function* dashboardAnalytics(action: Action<any>): any {
  const applicationState: IAppState = yield select((state: IAppState) => state)
  const state: IDashboardState = applicationState.Dashboard

  switch (action.type) {
    case NavigationActions.ROUTE_CHANGE: {
      const { prevRoute } = applicationState.Navigation
      const currentRoute = action.payload
      if (currentRoute.pathName === 'settings') {
        yield Mixpanel.trackWithPreset('Settings Page Clicked', {})
      }
      if (currentRoute.pathName === 'addEntity') {
        yield Mixpanel.trackWithPreset('Add Relationship Started', {
          Relation: _getEntityName(currentRoute.navParams.entity)
        })
      }
      if (prevRoute?.pathName === 'addEntity') {
        yield Mixpanel.trackWithPreset('Add Relationship Screen Closed', {
          Relation: _getEntityName(currentRoute.navParams.entity)
        })
      }
      if (currentRoute.pathName === 'editMerchant') {
        yield Mixpanel.trackWithPreset('View Profile', {
          // Mobile: state.merchantProfile.data?.mobile,
          Relation: 'Merchant',
          Screen: prevRoute?.pathName === 'settings' ? 'Settings' : 'Homepage'
        })
      }
      if (currentRoute.pathName === 'editEntity') {
        yield Mixpanel.trackWithPreset('View Profile', {
          ..._getEntityRelatedAttributes(applicationState),
          Screen: 'Ledger'
        })
      }
      if (currentRoute.pathName === 'help') {
        yield Mixpanel.trackWithPreset('View Help', {
          Screen: 'Drawer'
        })
      }
      break
    }
    case DashboardActions.FETCH_BUSINESS_IDS: {
      yield Mixpanel.trackWithPreset('Home Screen Viewed', {})
      break
    }
    case DashboardActions.BROADCAST_EVENT: {
      yield Mixpanel.trackWithPreset(action.payload.eventName, action.payload.properties)
      break
    }
    case DashboardActions.FETCH_BUSINESS_IDS_SUCCESS: {
      yield Mixpanel.identify(action.payload.id)
      yield Mixpanel.register({
        merchant_id: action.payload.id
        // mobile: action.payload.mobile,
        // name: action.payload.name
      })
      yield Mixpanel.people.set({
        merchant_id: action.payload.id
        // mobile: action.payload.mobile,
        // name: action.payload.name
      })
      break
    }
    case DashboardActions.SHOW_BUSINESS_NAME_MODAL: {
      yield Mixpanel.trackWithPreset('Enter Name Screen', {
        Flow: 'login'
      })
      break
    }
    case DashboardActions.BUSINESS_NAME_SKIPPED: {
      yield Mixpanel.trackWithPreset('Skip', {
        Flow: 'login',
        Type: 'Manual'
      })
      break
    }
    case DashboardActions.REGISTER_MERCHANT_NAME: {
      yield Mixpanel.trackWithPreset('Name Entered', {
        Flow: 'login',
        Value: action.payload
      })
      break
    }
    case DashboardActions.UPDATE_SEARCH_STRING: {
      if (action.payload && action.payload.length === 1) {
        yield Mixpanel.trackWithPreset('Search Relationship', {
          Relation: state.currentTab,
          SearchKeyword: action.payload
        })
      }
      break
    }
    case DashboardActions.ADD_ENTITY_INTERACTED: {
      const { interaction, entityType } = action.payload
      yield Mixpanel.trackWithPreset(interaction, {
        Flow: 'Add Relationship',
        Relation: _getEntityName(entityType),
        Screen: `${_getEntityName(entityType)} Tab`
      })
      break
    }
    case DashboardActions.ADD_ENTITY_SUCCESS: {
      yield Mixpanel.trackWithPreset('Add Relationship Success', {
        Relation: _getEntityName(action.payload.entityType),
        Search: 'False',
        account_id: action.payload.accountId,
        // Mobile: action.payload.phoneNumber,
        is_common_ledger: action.payload.isRegistered,
        Contact: action.payload.fromContactList
      })
      break
    }
    case DashboardActions.DELETE_ENTITY_INITIATED: {
      yield Mixpanel.trackWithPreset('Delete Relationship Started', {
        ..._getEntityRelatedAttributes(applicationState),
        account_id: action.payload.id,
        merchant_id: state.merchantProfile.data?.id,
        Screen: 'Relationship'
      })
      break
    }
    case DashboardActions.DELETE_ENITITY: {
      const { entityType, id } = action.payload
      yield Mixpanel.trackWithPreset(
        `delete${
          entityType === ENTITY_TYPE.CUSTOMER ? 'customer' : 'supplier'
        }screen:delete clicked`,
        {
          ..._getEntityRelatedAttributes(applicationState),
          account_id: id,
          merchant_id: state.merchantProfile.data?.id,
          Screen: 'Relationship Profile'
        }
      )
      break
    }
    case DashboardActions.DELETE_ENTITY_CANCEL: {
      const { id } = action.payload
      yield Mixpanel.trackWithPreset('Delete Relationship Cancel', {
        ..._getEntityRelatedAttributes(applicationState),
        account_id: id,
        merchant_id: state.merchantProfile.data?.id,
        Screen: 'Relationship Profile'
      })
      break
    }
    case DashboardActions.DELETE_ENTITY_SUCCESS: {
      yield Mixpanel.trackWithPreset('Delete Relationship Confirm', {
        ..._getEntityRelatedAttributes(applicationState),
        account_id: action.payload.id,
        merchant_id: state?.merchantProfile.data?.id,
        Screen: 'Relationship'
      })
      break
    }
    case DashboardActions.OPEN_FILTERS: {
      yield Mixpanel.trackWithPreset('Select Filter', {
        Relation: state.currentTab,
        Flow: 'New'
      })
      break
    }
    case DashboardActions.CLEAR_FILTERS: {
      yield Mixpanel.trackWithPreset('Cancel Filter', {
        Relation: state.currentTab,
        Flow: 'New'
      })
      break
    }
    case DashboardActions.SAVE_FILTERS: {
      const selectedSort = action.payload[0].entries.find(
        (entry: IFilterUnit) => entry.selected
      )?.label
      yield Mixpanel.trackWithPreset('Update Filter', {
        Relation: state.currentTab,
        Flow: 'New',
        SortBy: selectedSort
      })
      break
    }
    case DashboardActions.EDIT_ENTITY_FIELD_START: {
      yield Mixpanel.trackWithPreset('Select Profile', {
        ..._getEntityRelatedAttributes(applicationState),
        Field: action.payload.key
      })
      break
    }
    case DashboardActions.EDIT_ENTITY_FIELD_END: {
      yield Mixpanel.trackWithPreset('Update Profile', {
        ..._getEntityRelatedAttributes(applicationState),
        Field: action.payload.key
      })
      break
    }
    case DashboardActions.EDIT_ENTITY: {
      if (['lang', 'txnAlertEnabled'].includes(action.payload.key)) {
        const mixpanelProps = {
          ..._getEntityRelatedAttributes(applicationState),
          //@ts-ignore
          Field: _FieldNameMapping[action.payload.key] || action.payload.key
        }
        if (action.payload.key === 'txnAlertEnabled') {
          mixpanelProps['Set Value'] = action.payload.value
          mixpanelProps['Existing Value'] = !action.payload.value
        }
        yield Mixpanel.trackWithPreset('Update Profile', mixpanelProps)
      }
      break
    }
    case DashboardActions.EDIT_MERCHANT_PROFILE_FIELD_START: {
      yield Mixpanel.trackWithPreset('Select Profile', {
        // Mobile: state.merchantProfile.data?.mobile,
        Relation: 'Merchant',
        Field: action.payload.key
      })
      break
    }
    case DashboardActions.EDIT_MERCHANT_PROFILE_FIELD_END: {
      yield Mixpanel.trackWithPreset('Update Profile', {
        // Mobile: state.merchantProfile.data?.mobile,
        Relation: 'Merchant',
        Field: action.payload.key
      })
      break
    }
    case DashboardActions.EDIT_MERCHANT_PROFILE: {
      if (['businessTypeId', 'categoryId'].includes(action.payload.key)) {
        yield Mixpanel.trackWithPreset('Update Profile', {
          // Mobile: state?.merchantProfile.data?.mobile,
          Relation: 'Merchant',
          Field: action.payload.key
        })
      }
      break
    }
    case DashboardActions.VIEW_HELP_TOPIC: {
      yield Mixpanel.trackWithPreset('View Help Topic', {
        Type: action.payload.id,
        Interaction: action.payload.interaction,
        Source: 'Help Screen'
      })
      break
    }
    case DashboardActions.VIEW_HELP_ITEM: {
      yield Mixpanel.trackWithPreset('View Help Item', {
        Format: 'text',
        Method: action.payload.method,
        Interaction: action.payload.interaction,
        Type: action.payload.id,
        Screen: action.payload.isMainScreen ? 'Help Main Screen' : 'NA',
        Source: 'Help Screen'
      })
      break
    }
    case DashboardActions.CONTACT_US_ON_WHATSAPP: {
      yield Mixpanel.trackWithPreset('View Help Item', {
        Format: 'text',
        Method: HELP_ITEM_METHOD.TEXT,
        Interaction: HELP_SECTION_INTERACTION.STARTED,
        Type: 'Chat with Support on WhatsApp',
        Screen: 'Help Main Screen',
        Source: 'Help Screen'
      })
      break
    }
    case DashboardActions.CHANGE_LIST_TAB: {
      yield Mixpanel.trackWithPreset('View Relationship', {
        merchant_id: state.merchantProfile.data?.id,
        Screen: 'Relationship Profile',
        Relation: action.payload === ENTITY_TYPE.CUSTOMER ? 'Customer' : 'Supplier',
        List: true
      })
      break
    }
    case NEWDASHBOARD_EVENTS.CLOSE_PAID_SOON_BANNER: {
      yield Mixpanel.trackWithPreset('web_close_paid_soon_banner', {
        merchant_id: state.merchantProfile.data?.id,
        Screen: 'Dashboard'
      })
      break
    }
    case NEWDASHBOARD_EVENTS.SUBMIT_FEEDBACK_PAID_SOON_BANNER: {
      yield Mixpanel.trackWithPreset('web_submit_feedback', {
        merchant_id: state.merchantProfile.data?.id,
        Screen: 'Dashboard',
        value: action.payload
      })
      break
    }
    case NEWDASHBOARD_EVENTS.OPEN_PAID_SOON_BANNER: {
      yield Mixpanel.trackWithPreset('web_open_paid_soon_banner', {
        merchant_id: state.merchantProfile.data?.id,
        Screen: 'Dashboard'
      })
      break
    }
    default: {
      return
    }
  }
}
