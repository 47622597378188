import React from 'react'
import { DownloadIcon } from '@okcredit/web-lib'
import { useDispatch } from 'react-redux'
import { utils, writeFile } from 'xlsx'
import { THEME } from 'components/theme'
import { DOCUMENT_TYPE, ITemplate } from 'state/new-summary/uploadSheet/uploadSheet.types'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import {
  DOCUMENT_TYPE_VS_EXTENSION,
  columnFieldsForDownload,
  tagColumnFieldsForDownload
} from '../../DocumentFormatConfigure/Components/TemplateDetails/TemplateDetails.helpers'
import TemplateItem from '../TemplateItem'
import { SelectedTemplatesStyles } from './styles'

const SelectedTemplates = ({ templates }: { templates: ITemplate[] }) => {
  const classes = SelectedTemplatesStyles()
  const dispatch = useDispatch()

  const handleDownloadTemplate = (template: ITemplate) => {
    dispatch(pushEvent(NEWDASHBOARD_EVENTS.DOWNLOAD_SELECTED_TEMPLATE))
    const {
      config: { columnMapping: columnDetails, sheetDetails },
      name,
      document_type
    } = template
    const fieldsForDownload = [...columnFieldsForDownload, ...tagColumnFieldsForDownload]
    const data = Object.entries(columnDetails)
      .filter(([headerKey, headerValue]) => {
        return fieldsForDownload.includes(headerKey) && headerValue
      })
      .map(([_, headerValue]) => headerValue)
    const ws = utils.aoa_to_sheet([])
    utils.sheet_add_aoa(ws, [data], { origin: { r: Number(sheetDetails.header) - 1, c: 0 } })
    const wb = utils.book_new()
    ws['!cols'] = getMaxColumnWidths([data])
    utils.book_append_sheet(wb, ws, sheetDetails.name || 'Sheet 1')
    writeFile(
      wb,
      `${name}.${
        DOCUMENT_TYPE_VS_EXTENSION[Number(document_type) as DOCUMENT_TYPE.EXCEL | DOCUMENT_TYPE.CSV]
      }`
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>Selected Templates</div>
      {templates.map((template) => {
        return (
          <TemplateItem
            key={template.id}
            name={template.name}
            documentType={template.document_type}
            onClick={() => handleDownloadTemplate(template)}
          >
            <DownloadIcon fill={THEME.brandGreen} size={20} />
          </TemplateItem>
        )
      })}
    </div>
  )
}

export default SelectedTemplates
