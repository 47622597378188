import { Action } from 'infra/types'
import { INudge } from './migration.types'

export const MigrationActions = {
  NUDGE_INIT: 'migration/nudgeInit',
  NUDGE_RESET: 'migration/nudgeReset'
}

export const initNudge = (payload: INudge): Action<INudge> => ({
  type: MigrationActions.NUDGE_INIT,
  payload
})

export const resetNudge = (): Action<null> => ({
  type: MigrationActions.NUDGE_RESET,
  payload: null
})
