import { Action } from 'infra/types'

export const assignStaffActions = {
  SET_DRAWER: 'assignStaff/setDrawers',
  SET_LIST_DETAILS: 'assignStaff/setListDetails',
  RESET_ASSIGN_STAFF_STATE: 'assignStaff/resetAssignStaffState'
}

export const setAssignStaffDrawers = (payload: {
  drawerName: 'showAssignStaffDrawer'
  status: boolean
}): Action<{
  drawerName: 'showAssignStaffDrawer'
  status: boolean
}> => ({
  type: assignStaffActions.SET_DRAWER,
  payload
})

export const setListDetails = (payload: {
  listId: string
  listUrl: string
  showAssignStaffDrawerStatus: boolean
}): Action<{
  listId: string
  listUrl: string
  showAssignStaffDrawerStatus: boolean
}> => ({
  type: assignStaffActions.SET_LIST_DETAILS,
  payload
})

export const resetAssignStaffState = (): Action<null> => ({
  type: assignStaffActions.RESET_ASSIGN_STAFF_STATE,
  payload: null
})
