import { Action } from 'infra/types'
import { DUE_CONFIG } from 'services/Api/StaffLinkApiFolder/type'
import { accountantSummaryActions } from './accountant-summary.actions'
import { IAccountantSummaryState } from './accountant-summary.types'

const ACCOUNTANT_SUMMARY_STATE: IAccountantSummaryState = {
  listId: '',
  listType: DUE_CONFIG.INVOICE_LEVEL_DUE_V2,
  drawers: {
    showSummaryDrawer: false
  }
}

export const AccountantSummaryReducer = (
  state: IAccountantSummaryState = ACCOUNTANT_SUMMARY_STATE,
  action: Action<any>
): IAccountantSummaryState => {
  switch (action.type) {
    case accountantSummaryActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case accountantSummaryActions.SET_LIST_DETAILS: {
      return {
        ...state,
        listId: action.payload.listId,
        listType: action.payload.listType,
        drawers: {
          ...state.drawers,
          showSummaryDrawer: action.payload.showSummaryDrawerStatus
        }
      }
    }
    case accountantSummaryActions.RESET_ACCOUNTANT_SUMMARY_STATE: {
      return ACCOUNTANT_SUMMARY_STATE
    }
    default: {
      return state
    }
  }
}
