import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export default createUseStyles({
  container: {
    backgroundColor: THEME.brandWhite,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px 10px 0 0',
    '& > *': {
      boxSizing: 'border-box'
    }
  },
  iconContainer: {
    backgroundColor: THEME.brandLightRedBorder,
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  },
  content: {
    padding: 24,
    '& > div': {
      textAlign: 'center'
    }
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: THEME.brandBlack,
    marginTop: 12
  },
  info: {
    fontSize: 16,
    color: THEME.infoGrey,
    marginTop: 8
  },
  footer: {
    display: 'flex',
    gap: 20,
    width: '100%',
    height: '8%',
    padding: '20px 40px 20px 20px',
    backgroundColor: THEME.brandWhite,
    borderTop: 'solid 1px #dae2e6'
  }
})
