// import { select } from 'redux-saga/effects'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { Action, IPayload } from './OrderQR.types'
import { OrderQRAnalyticsActions } from './OrderQR.actions'

const track = (event: string, paramObj: object = {}) => {
  Mixpanel.track(event, paramObj)
  // console.log(`%c${event}`, 'background: #222; color: #bada55', paramObj) // TODO: Comment this before going to prod
}

const identify = (merchantId: string) => {
  Mixpanel.identify(merchantId)
  // console.log(`%cIdentify called with: ${merchantId}`, 'background: #222; color: #bada55') // TODO: Comment this before going to prod
}

export default function* OrderQRAnalytics<T>(action: Action<T>) {
  const { merchantId }: IPayload = action.payload
  yield identify(`${merchantId}`) // Error, if not used like this
  const payload = { merchant_id: merchantId }
  switch (action.type) {
    // Event 1
    case OrderQRAnalyticsActions.TRACK_ORDERQR_HOME_PAGE: {
      yield track('loaded_qr_benefits_page', payload)
      break
    }

    // Event 2
    case OrderQRAnalyticsActions.TRACK_ORDERQR_BANK_POPUP: {
      yield track('clicked_get_qr', payload)
      break
    }

    // Event 3
    case OrderQRAnalyticsActions.TRACK_ORDERQR_CONFIRM_BANK: {
      yield track('confirm_destination_clicked', {
        merchant_id: merchantId,
        flow: 'order_qr'
      })
      break
    }

    // Event 4
    case OrderQRAnalyticsActions.TRACK_ORDERQR_ADDRESS_PAGE: {
      yield track('loaded_shipping_address_page', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 5
    case OrderQRAnalyticsActions.TRACK_ORDERQR_SHOP_NUMBER: {
      yield track('enter_shop_number', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 6
    case OrderQRAnalyticsActions.TRACK_ORDERQR_ADDRESS: {
      yield track('enter_address', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 7
    case OrderQRAnalyticsActions.TRACK_ORDERQR_LANDMARK: {
      yield track('enter_landmark', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 8
    case OrderQRAnalyticsActions.TRACK_ORDERQR_PINCODE: {
      yield track('enter_pincode', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 9
    case OrderQRAnalyticsActions.TRACK_ORDERQR_CITY: {
      yield track('enter_city', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 10
    case OrderQRAnalyticsActions.TRACK_ORDERQR_STATE: {
      yield track('enter_state', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 11
    case OrderQRAnalyticsActions.TRACK_ORDERQR_PREVIEWQR_PAGE: {
      yield track('loaded_preview_qr_page', {
        ...payload,
        flow: 'order_qr'
      })
      break
    }

    // Event 12
    case OrderQRAnalyticsActions.TRACK_ORDERQR_KYC_CARD: {
      yield track('increase_limit_clicked', {
        ...payload,
        flow: 'order_qr',
        screen: 'preview_order_qr'
      })
      break
    }

    // Event 13
    case OrderQRAnalyticsActions.TRACK_ORDERQR_CONFIRM_ORDER: {
      yield track('confirm_order_clicked', {
        ...payload,
        flow: 'order_qr',
        screen: 'preview_order_qr'
      })
      break
    }

    case OrderQRAnalyticsActions.TRACK_YOUR_QR_NOW_CLICK: {
      yield track('click_get_your_qr_now', payload)
      break
    }

    case OrderQRAnalyticsActions.TRACK_ORDERQR_PRINT_OR_DOWNLOAD: {
      yield track('click_print_download_qr', payload)
      break
    }

    case OrderQRAnalyticsActions.TRACK_PRE_ORDER_CLICK: {
      yield track('click_pre_order', payload)
      break
    }

    case OrderQRAnalyticsActions.TRACK_ORDER_DONE_CLICK: {
      yield track('click_done_status_page', payload)
      break
    }

    case OrderQRAnalyticsActions.TRACK_ORDER_STATUS_PAGE: {
      yield track('loaded_order_status_page', payload)
      break
    }
  }
}
