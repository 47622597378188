const readFile = (file: File) =>
  new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader()

    // Register event listeners
    reader.addEventListener('loadend', (e: any) => resolve(e.target.result))
    reader.addEventListener('error', reject)

    // Read file
    reader.readAsArrayBuffer(file)
  })

export const getAsByteArray = async (file: File) => {
  // @ts-ignore
  return new Uint8Array(await readFile(file))
}
