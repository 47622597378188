import { IUnitOffer, OFFER_RESPONSE } from 'services/Api/BillingApiFolder.ts/type'
import { NewItemDetailEntity } from 'state/billing/billing.types'
import {
  COLLECTION_LIST_TYPE,
  IBill,
  IReplacements,
  IReplacementsItem,
  CL_VERSIONS,
  COLLECTION_LIST_TAB,
  ICatalogItem,
  REPLACMENT_STATES
} from 'state/collectionList/collectionList.types'
import { ProformaRowData } from 'state/new-summary/orders/orders.types'

export type Action<T> = {
  type: string
  payload: T
  error?: {}
  history?: IHistoryObj
  params?: {
    staffLinkId?: string // used by StaffLink
    businessId?: string // used by StaffLink
  }
}

export interface IHistoryObj {
  push: Function
  replace?: Function
  goBack?: Function
}

export type TAutoDebitStatus = 'COMPLETE' | 'PENDING'

export interface ISetLinkIdAndMerchantIdAction {
  merchantId: string
  linkId: string
  version?: CL_VERSIONS
  isNewUser?: boolean
}

export interface IRootState {
  StaffLink: IStaffLinkState
}

export interface IAutoDebit {
  registration_url: string
  status: TAutoDebitStatus
}

export interface IStaffLinkDeductions {
  due_date: string
}
export interface ITransactions {
  id: string
  amount: number
  associated_account_id: string
  txn_type: number
  transaction_time: number
  notes: string
  collection_list_id: string
  approved_by_merchant: boolean
  is_bill_settled: boolean
  settlement_bill_ids: string[]
  staff_id?: string
  staff_name?: string
  items?: IRecordReplacement[]
  catalog_items?: ICatalogItem[]
  return_items?: ReturnableItem[]
}

export type ReturnableItem = {
  index: string
  quantity: string
}

export interface IStaffLinkAccountDetails {
  profile: {
    message_link: string
    qr_intent: string
  }
  balance: string
  account_id: string
  customer_name: string
  customer_mobile: string
  address: string
  registration: IAutoDebit
  deductions: IStaffLinkDeductions[]
  transactions: ITransactions[]
  bills: IBill[]
  replacements: IReplacements[]
  beats?: string[]
  routes?: string[]
  account_beats?: IAccountBeat[]
  account_routes?: IAccountRoute[]
}

export interface IStaffLinkBillDetails {
  approved_transactions: any[]
  approved_transactions_summary: any
  bill: any
  bill_beats: IBillBeat[]
  bill_cancel_params: any
  redeliver: boolean
  replacements: IReplacements[]
  transactions: ITransactions[]
}

export interface IAccountBeat {
  beat_id: string
  name: string
  account_id: string
}

export interface IBillBeat extends IAccountBeat {
  bill_id: string
}

export interface IAccountRoute {
  route_id: string
  name: string
  account_id: string
}

export interface IMerchantFeatures {
  schedule_payments?: boolean
  cl_view_v2?: boolean
  cash_deposit?: boolean
  return_items_v1?: boolean
  staff_take_orders?: boolean
  okdebit_order_management?: boolean
  staff_link_add_customer?: boolean
  okdebit_staff_invoice_v1?: boolean
  staff_settle_multiple_invoices?: boolean
}

export interface IStaffLinkState {
  staffLinkDetails: IStaffLinkDetails
  loading: boolean
  versionLoader: boolean
  pageError: string
  paymentStatus: string
  isPhoneNumberSearch: boolean
  searchedUser?: IStaffLinkAccountDetails
  isSearchingUser: boolean
  searchUserError: string
  linkId: string
  merchantId: string
  merchantFeatures: IMerchantFeatures
  transactionsError: string
  collectionSummary: ICollectionSummary | null
  transactionType: ITransactionType
  currentTransactionType: number
  selectedStaffBill: IBill | undefined
  dueConfig: COLLECTION_LIST_TYPE | undefined
  staffDetails: IStaffDetails
  isStaffBlockedToAccessList: boolean
  staffOrder: IStaffOrder
  businessDetails: IStaffBusinessDetails
  businessSettings: IStaffBusinessSettings
}

export interface IDueConfig {
  config: COLLECTION_LIST_TYPE
  assignment_date: string
}

export interface ICollectionList {
  associated_account_ids: string[]
  business_id: string
  create_time: string
  id: string
  is_active: boolean
  name: string
  update_time: string
  url: string
  due_config: IDueConfig
  usage_type: COLLECTION_LIST_TAB
}

export interface IPendingDeliveryAttempts {
  id: string
  bill_id: string
  business_id: string
  staff_uid: string
  list_id: string
  created_at: string
  is_approved: boolean
  action_pending: string
  status: string
  updated_at: string
  approved_at: string
}

export interface IStaffLinkDetails {
  link_id?: string
  merchant_name?: string
  merchant_mobile?: string
  account_details?: IStaffLinkAccountDetails[]
  bill_details?: IStaffLinkBillDetails[]
  collection_list: ICollectionList
  currentVersion?: CL_VERSIONS
  history_due_configs?: {
    previous_config: IDueConfig
    past_config?: IDueConfig
    current_config?: IDueConfig
  }
  pending_delivery_attempts?: IPendingDeliveryAttempts[]
}

export interface IStaffDetails {
  data?: {
    id?: string
    mobile?: string
    name?: string
  } | null
  isLoading: boolean
  isDrawerOpen: boolean
}

export interface IStaffOrder {
  customers: IBusinessAccount[] | null
  items: IStaffInventoryItems[] | null
  cartItems: ICartItem[]
  loaders: {
    customers?: boolean
  }
  selectedCustomer: IBusinessAccount | null
  selectedOrder: IStaffOrderItem | null
  selectedProforma: StaffProformaRowData | null
  orderHistory: OrderHistory
  staffPlacedOrderSuccess: boolean
}

export interface IStaffOrderItem {
  accountId: string
  amount: number
  createTime: number
  updatedTime: number
  createdById: string
  createdByType: number
  id: string
  status: number
  items: ItemDetailsLite[]
  orderNumber: string
}

export interface StaffProformaRowData extends Omit<ProformaRowData, 'orderDetails'> {
  orderDetails: IStaffOrderItem
}

export interface OrderHistory {
  pending?: IStaffOrderItem[]
  approved?: IStaffOrderItem[]
  cancelled?: IStaffOrderItem[]
  rejected?: IStaffOrderItem[]
  invoiced?: IStaffOrderItem[]
  proformaInvoiced?: StaffProformaRowData[]
}

export enum OrderHistoryEnum {
  pending = 'pending',
  approved = 'approved',
  cancelled = 'cancelled',
  rejected = 'rejected',
  invoiced = 'invoiced',
  proformaInvoiced = 'proformaInvoiced'
}

export interface IBusinessAccount {
  id: string
  name: string
  balance: number
  mobile: string
  gst: string
  supply_state_code: string
}

export interface ICartItem {
  catalogue_item_id: string
  item_id: string
  quantity: number
  amount: number
  image?: string
  rate: number
  discountName?: string
  itemName?: string
  itemNetRate?: number
  mrp?: number
  tax?: {
    percentage: number
    amount: number
  }
  discount: {
    name: string
    amount: number
  }
  actualItemValues?: IStaffInventoryItems
}

export interface ICollectionsShortSummary {
  total_accounts: string
  total_cash_collections: string
  total_cash_collections_amount: string
  total_cash_collections_account: string
  total_online_collections: string
  total_online_collections_amount: string
  total_online_collections_account: string
  total_neft_collections: string
  total_neft_collections_account: string
  total_neft_collections_amount: string
  total_cheque_collections: string
  total_cheque_collections_account: string
  total_cheque_collections_amount: string
  total_return_collections: string
  total_return_collections_account: string
  total_return_collections_amount: string
  total_damaged_collections: string
  total_damaged_collections_account: string
  total_damaged_collections_amount: string
  total_other_collections: string
  total_other_collections_account: string
  total_other_collections_amount: string
}

export interface ICollectionSummaryItem {
  id: string
  name: string
  due: number
  payment: number
  balance: number
  transactionTime: number
  transactionType: string
  is_approved: boolean

  account_due: string
  account_id: string
  account_transaction_id: string
  buyer_name: string
  staff_id: string
  staff_mobile: string
  staff_name: string
  transaction_amount: string
  transaction_id: string
}

export interface ICollectionSummary {
  collections?: ICollectionSummaryItem[]
  collections_summary?: ICollectionsShortSummary
}

export interface ITransactionType {
  NA: number // 0
  CASH: number // 1
  ONLINE: number // 2
  CREDIT_DUES: number // 3
  CHEQUE: number // 4
  NEFT: number // 5
  RETURN: number // 6
  DAMAGED: number // 7
  UPI: number // 8
  WALLET: number // 9
  QR: number //10
}

export interface IParams {
  accountId: string
  businessId: string
  timestamp: bigint
}

export interface IPayload {
  merchantId?: string
  listId?: string
  customerCount?: number
  pendingDuesCustomerCount?: number
  pendingDuesAmount?: string
  type?: string
  accountId?: string
  cashbackMessageVisible?: string
  status?: string
  currentUrl?: string
}

export interface IRegisterAutoDebitLinkReq {
  merchantId: string
  accountId: string
  date: number
  amount: number
}

export interface IScheduleAutoDebitLinkReq {
  merchantId: string
  accountId: string
  date: number
  amount: number
  frequency: 'ONCE'
}

export interface ITransactionsApi {
  merchantId: string
  accountId: string
  linkId: string
  amount: number
}

export interface INewCashCollectionReq {
  amount: number
  associated_account_id: string
  business_id: string
  collection_list_id: string
  notes: string
  txn_type: number
  settlement_bill_ids: Array<string>
  is_bill_settled: boolean
  catalogItems?: ICatalogItem[]
}

export interface IChequeDetails {
  cheque_num?: string
  cheque_date?: number
}

export interface INewCashCollectionApi {
  accountId: string
  amount: number
  advanceAmount?: number
  txnType: number
  notes: string | IChequeDetails
  dueAmount?: number
  txnDate?: number
  chequeDetails?: {
    cheque_number: string
    cheque_date: number
    collected_on: number
    amount: number
    state: number
  }
  items?: IRecordReplacement[]
  catalogItems?: ICatalogItem[]
  paymentAccountId?: string
  returnItems?: IReturnItem[]
  settlementBillIds?: string[]
}

export interface IRecordReplacement {
  name: string
  quantity: string
  notes?: string
  state: REPLACMENT_STATES
}

export interface INewReplacementRecordAction {
  accountId: string
  billId: string
  amount: number
  replacementItems: IRecordReplacement[]
  catalogItems?: ICatalogItem[]
}

export interface IUpdateReplacementRecordAction {
  replacementId: string
  merchantId: string
  accountId: string
  status: number
  amount: string | number
  billId: string
  replacementItems: IReplacementsItem[]
  catalogItems?: ICatalogItem[]
}

export interface INewReplacementRecordReq {
  accountId: string
  amount: number
  merchantId: number
  billId: string
  replacementItems: IRecordReplacement[]
  catalogItems: ICatalogItem[]
  staffLinkId: string
}

export interface IUpdateCashCollectionApi {
  cashTxnId: string
  amount: number
  merchantId: string
}

export interface IUpdateMultipleTxnApi {
  id: string
  business_id: string
  amount?: number
  is_deleted?: boolean
}

export interface IDeleteTransectionApi {
  txnId: string
  merchantId: string
}

export interface ICancelInvoiceApi {
  linkId: string
  merchantId: string
  accountId: string
  billId: string
}

export interface IUndoCancelInvoiceApi {
  billId: string
  merchantId: string
}

export interface INewAutoDebitLinkToUsingAccountId {
  dueDate: number
  accountId: string
  regLink: string
}

export interface ITransactionsAction {
  amount: number
  accountId: string
}

export interface IUpdateCashCollectionAction {
  amount: number
  cashTxnId: string
  accountId: string
  txnType: number
  dueAmount?: number
}

export enum UPDATE_MULTIPLE_TXN_ACTION_TYPE {
  UPDATE_AMOUNT = 'update_amount',
  REMOVE = 'remove'
}

export interface IUpdateMultipleTxnAction {
  accountId?: string
  txnType?: number
  updateItems: {
    notes?: string | IChequeDetails
    txnId: string
    actionType: UPDATE_MULTIPLE_TXN_ACTION_TYPE
    amount?: number
    advanceAmount?: number
  }[]
  chequeDetails?: {
    cheque_number: string
    cheque_date: number
    collected_on: number
    amount: number
    state: number
  }
  items?: IRecordReplacement[]
  catalogItems?: ICatalogItem[]
  returnItems?: IReturnItem[]
}

export interface IDeleteTransectionAction {
  txnId: string
}

export interface IUndoCancelInvoiceAction {
  billId: string
}

export interface ICancelInvoiceAction {
  accountId: string
  billId: string
}

export interface ICollectionSummaryRequest {
  merchantId: string
  linkId: string
  version?: number
}

export enum LIST_SORT_TYPES {
  INVOICE = 'invoice',
  ACCOUNT = 'account'
}

interface IReturnItem {
  index: number
  quantity: number
}

export type IReturnablePayloadType = {
  billId: string
  businessId: string
}

export type CreateOrderPayloadType = {
  businessId: string
  customerId?: string
  amount: number
  items: ICartItem[]
}

export enum OrderStatus {
  DEFAULT = 0,
  PENDING = 1,
  APPROVED = 2,
  PROFORMA_CREATED = 3,
  INVOICED = 4,
  CANCELLED = 5,
  REJECTED = 6,
  OUT_FOR_DELIVERY = 7,
  COMPLETED = 8,
  PROFORMA_APPROVED = -2
}

export interface ItemDetailsLite {
  itemName: string
  quantity: number
  amount: number
  createTime: number
  id: string
  invoicedQuantity?: number
  image?: string
  skuId?: string
  rate: number
  discount?: {
    name: string
    amount: number
  }
}

export interface ITaxComponents {
  kind: number
  amount: number
  basis_pts: number
}

export interface OrderHistoryResponse {
  account_id: string
  amount: number
  create_time: number
  update_time: number
  created_by_id: string
  created_by_type: number
  id: string
  status: OrderStatus
  inventory_items: Array<{
    amount: number
    catalog_item_id: string
    create_time: number
    update_time: number
    inventory_item_id: string
    proforma_invoiced_quantity: number
    invoiced_quantity: number
    info: {
      name: string
      rate: number
      quantity: number
      tax: number
      amount: number
      catalog_item_id: string
      discount: string
      tax_components: ITaxComponents[]
      unit_offer: IUnitOffer
    }
    quantity: number
  }>
  proforma_ids: string[]
  order_number: string
}

export interface IStaffInventoryItems extends NewItemDetailEntity {
  inventoryItemId: string
  amountPayable: number
  image?: string
  offer?: OFFER_RESPONSE[]
}

export type CreateStaffBillPayload = {
  bill: {
    customer_id: string
    buyer_info: {
      name: string
      address: string
      mobile: string
    }
    add_to_ledger: boolean
  }
}

export interface IStaffBusinessDetails {
  name: string
  businessGst: string
}

export interface IStaffBusinessSettings {
  loading: boolean
  ready: boolean
  // Bill settings
  bill: {
    // This tells us wheather we should allow staff to edit the rate
    canStaffEditRate: boolean
  }
  order: {
    // This tells us wheather we should allow staff to restrict quantity greater than inventory
    restrictStaffLowInv: boolean
  }
  offer: {
    apply_multiple: boolean
  }
}
