import { Action } from 'infra/types'
import { IMoveToAnotherListState } from './moveToAnotherList.types'
import { moveToAnotherListActions } from './moveToAnotherList.actions'

const MOVE_TO_ANOTHER_LIST_DRAWER_STATE: IMoveToAnotherListState = {
  listId: '',
  isBalanceDue: false,
  drawers: {
    moveToAnotherListDrawer: false
  }
}

export const MoveToAnotherListReducer = (
  state: IMoveToAnotherListState = MOVE_TO_ANOTHER_LIST_DRAWER_STATE,
  action: Action<any>
): IMoveToAnotherListState => {
  switch (action.type) {
    case moveToAnotherListActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case moveToAnotherListActions.SET_MOVE_TO_ANOTHER_LIST_DRAWER_DATA: {
      return {
        ...state,
        listId: action.payload.listId,
        isBalanceDue: action.payload.isBalanceDue,
        bills: action.payload.bills,
        accountIds: action.payload.accountIds,
        drawers: {
          ...state.drawers,
          moveToAnotherListDrawer: action.payload.drawerStatus
        }
      }
    }
    case moveToAnotherListActions.RESET_MOVE_TO_ANOTHER_LIST_DRAWER_STATE: {
      return MOVE_TO_ANOTHER_LIST_DRAWER_STATE
    }
    default: {
      return state
    }
  }
}
