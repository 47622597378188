import { takeLatest } from 'redux-saga/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import { InventoryActions } from './inventory.actions'
import {
  bulkUploadInventoryItemsEffect,
  downloadPurchaseSummaryEffect,
  downloadExpenseSummaryEffect
} from './inventory.effects'

function* bulkUploadCustomersSaga() {
  yield takeLatest(InventoryActions.BULK_UPLOAD_INVENTORY_ITEMS, bulkUploadInventoryItemsEffect)
}

function* downloadPurchaseSummarySaga() {
  yield takeCustomLatest(
    InventoryActions.DOWNLOAD_PURCHASE_ITEMS_SUMMARY,
    downloadPurchaseSummaryEffect
  )
}

function* downloadExpenseSummarySaga() {
  yield takeCustomLatest(
    InventoryActions.DOWNLOAD_EXPENSE_ITEM_SUMMARY,
    downloadExpenseSummaryEffect
  )
}

export const InventoryWatchers = [
  bulkUploadCustomersSaga(),
  downloadPurchaseSummarySaga(),
  downloadExpenseSummarySaga()
]
