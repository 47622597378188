export const keyMapper = {
  groupName: 'Group Name',
  customerName: 'Customer Name',
  customerCode: 'Customer Code',
  invoiceNumber: 'Invoice Number',
  invoiceAmount: 'Invoice Amount',
  replacement: 'Replacement',
  replacementItem: 'Replacement Item',
  quantity: 'Quantity',
  replacementAmount: 'Replacement Amount',
  invoiceDate: 'Invoice Date'
}
