import { AxiosError } from 'axios'
import { call, put } from 'redux-saga/effects'
import { Action } from 'infra/types'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { AgentDashboardApi } from 'services/Api/AgentDashboardApi'

import { getErrorMessage } from 'services/Api/Errors'
import {
  addOnboardingDataForCustomerSuccess,
  addRevisitDataSuccessAction,
  clearOnboardingFormDataAction,
  clearRevisitFormDataAction,
  fetchChecklistSuccessAction,
  fetchDailySummarySuccessAction,
  fetchOnboardedMerchantsListSuccessAction,
  getOnboardingMetaDataSuccess,
  onboardFormLoadingAction
} from './agent-dashboard.actions'
import {
  IOnboardFormData,
  IOnboardingListRequestParams,
  IRevisitFormData
} from './agent-dashboard.types'

export function* getAgentMetaDataEffect() {
  try {
    const response: IApiResponse = yield call(AgentDashboardApi.getAgentDashboardMetaData)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getOnboardingMetaDataSuccess(response.data))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.response.data.error
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}

export function* postOnboardMerchantDataEffect(action: Action<IOnboardFormData>) {
  yield put(onboardFormLoadingAction(true))
  try {
    const response: IApiResponse = yield call(
      AgentDashboardApi.postOnboardMerchantData,
      action.payload
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(addOnboardingDataForCustomerSuccess(response.data))
    } else {
      window?.androidObj?.makeToast(response.data.response.data.error)
      yield put(clearOnboardingFormDataAction())
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
    yield put(clearOnboardingFormDataAction())
  }
}

export function* getCheckListDataEffect(action: Action<string>) {
  try {
    const response: IApiResponse = yield call(AgentDashboardApi.getCheckListData, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        fetchChecklistSuccessAction(
          action.payload === '1' ? 'merchant-problems' : 'onboarding',
          response.data
        )
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.response.data.error
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}

export function* getOnboardedMerchantsListEffect(action: Action<IOnboardingListRequestParams>) {
  try {
    const response: IApiResponse = yield call(
      AgentDashboardApi.getOnboardedMerchantsList,
      action.payload
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        fetchOnboardedMerchantsListSuccessAction(
          response.data.onboardings,
          response.data.page_details.total_count,
          action.payload.page
        )
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.response.data.error
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}

export function* getDailySummaryDataEffect() {
  try {
    const response: IApiResponse = yield call(AgentDashboardApi.getDailySummaryData)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(fetchDailySummarySuccessAction(response.data))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.response.data.error
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}

export function* postRevisitFormDataEffect(action: Action<IRevisitFormData>) {
  try {
    const response: IApiResponse = yield call(AgentDashboardApi.postRevisitFormData, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(addRevisitDataSuccessAction(response.data))
    } else {
      window?.androidObj?.makeToast(response.data.response.data.error)
      yield put(clearRevisitFormDataAction())
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
    yield put(clearRevisitFormDataAction())
  }
}
