import { call, put, select } from 'redux-saga/effects'
import {
  bulkApproveTxnCollectionStaffLink,
  fetchPendingActionBillDetailsApi,
  deleteMultipleTransectionReq
} from 'services/Api/StafflinkApi'
import { holdMultipleListsReq } from 'services/Api/BillingApi'
import { Action } from 'infra/types'
import { IAppState } from 'infra/AppState'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { SORT_ORDER_TYPE } from 'state/collectionSummary/collectionSummary.types'
import { API_RESPONSE_TYPE, IApiResponse } from '../../../constants'
import { formatPendingActions } from './approve.helpers'
import {
  APPROVE_FILTER_TYPE,
  FetchPendingActionAPIPayload,
  FetchPendingActionPayload,
  FetchPendingActionResponse,
  TransactionId,
  SORT_OPTION
} from './approve.types'
import {
  setPaginationData,
  setPendingActionData,
  setPendingActionFilters,
  refetchPendingActions,
  fetchPendingActions,
  setSummeryTotal,
  setFetchingLoaders
} from './approve.actions'

export function* fetchAllPendingActionsEffect(action: Action<FetchPendingActionPayload>) {
  const {
    startTime,
    endTime,
    filterType,
    page,
    perPage,
    searchString,
    sortOption,
    sortOrder,
    selectedListsWithPendingActions
  } = action.payload
  yield fetchNewPendingActions({
    startTime,
    endTime,
    filterType,
    page,
    perPage,
    searchString,
    sortOption,
    sortOrder,
    selectedListsWithPendingActions
  })
}

export function* refetchAllPendingActionsEffect() {
  const {
    filter: { startTime, endTime, filterType },
    currentPage,
    pageSize,
    sortOption,
    sortOrder,
    selectedListsWithPendingActions
  } = yield select((app: IAppState) => app.Approve)

  yield fetchNewPendingActions({
    startTime,
    endTime,
    filterType,
    page: currentPage,
    perPage: pageSize,
    sortOption,
    sortOrder,
    selectedListsWithPendingActions
  })
}

function* fetchNewPendingActions(payload: FetchPendingActionPayload) {
  const {
    startTime,
    endTime,
    filterType,
    page,
    perPage,
    searchString,
    sortOption,
    sortOrder,
    selectedListsWithPendingActions
  } = payload
  yield put(setFetchingLoaders(true))
  const requestPayload: FetchPendingActionAPIPayload = {
    filters: {
      search_string: searchString,
      ...(filterType !== APPROVE_FILTER_TYPE.ALL && {
        transaction_interval: {
          start_time: startTime,
          end_time: endTime
        }
      }),
      ...(selectedListsWithPendingActions?.length && { list_ids: selectedListsWithPendingActions })
    },
    ...(sortOption &&
      sortOrder && {
        sort_options: {
          sort_by: sortOption,
          sort_order: sortOrder === SORT_ORDER_TYPE.descending ? 1 : 0
        }
      }),
    page: page,
    per_page: perPage
  }

  const response: IApiResponse = yield call(fetchPendingActionBillDetailsApi, requestPayload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setFetchingLoaders(false))
    throw new Error('Problem while fetching pending actions, please try after sometime', {
      cause: 'customError'
    })
  }

  const formattedData = formatPendingActions(response?.data)
  const paginationData = (response?.data as FetchPendingActionResponse).pagination_details
  yield put(setPendingActionData(formattedData))
  yield put(setSummeryTotal(response?.data?.summary_total))
  yield put(
    setPaginationData({
      pageSize: Number(paginationData.per_page),
      currentPage: Number(paginationData.page),
      totalEntries: Number(paginationData.total_items)
    })
  )
  yield put(
    setPendingActionFilters({ filter: { startTime, endTime, filterType }, sortOption, sortOrder })
  )

  const { resetSelectedRowRef } = yield select((app: IAppState) => app.Approve)
  resetSelectedRowRef?.actionPendingTable?.current?.resetRowSelection({})
  yield put(setFetchingLoaders(false))
}

export function* approvePendingActionEffect(action: Action<TransactionId[]>) {
  const { payload } = action

  const { merchantProfile } = yield select((app: IAppState) => app.Dashboard)
  const merchantId = merchantProfile?.data?.id

  const response: IApiResponse = yield call(bulkApproveTxnCollectionStaffLink, payload, merchantId)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Approving Operation Failed', {
      cause: 'customError'
    })
  }

  if (response?.data?.errors?.length > 0 && response?.data?.errors?.length <= payload.length) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.INFO,
        bodyText: 'Transactions Partially Approved'
      })
    )
  }
  const { resetSelectedRowRef } = yield select((app: IAppState) => app.Approve)
  resetSelectedRowRef?.actionPendingTable?.current?.resetRowSelection({})
  yield put(refetchPendingActions())
}

export function* cancelPendingTxnsActionEffect(action: Action<TransactionId[]>) {
  const { payload } = action

  const { merchantProfile } = yield select((app: IAppState) => app.Dashboard)
  const merchantId = merchantProfile?.data?.id

  const response: IApiResponse = yield call(deleteMultipleTransectionReq, {
    txnIds: payload,
    merchantId
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Cancel Action Failed', {
      cause: 'customError'
    })
  }

  if (response?.data?.errors?.length > 0 && response?.data?.errors?.length <= payload.length) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.INFO,
        bodyText: 'Transactions Partially Canceled'
      })
    )
  }

  const { pageSize, currentPage, filter, sortOption, sortOrder, resetSelectedRowRef } =
    yield select((app: IAppState) => app.Approve)
  resetSelectedRowRef?.actionPendingTable?.current?.resetRowSelection({})
  yield put(
    fetchPendingActions({
      ...filter,
      page: currentPage,
      perPage: pageSize,
      ...(sortOption &&
        sortOrder && {
          sortOption,
          sortOrder
        })
    })
  )
}

export function* holdInvoiceActionEffect(action: Action<TransactionId[]>) {
  const { payload } = action

  const response: IApiResponse = yield call(holdMultipleListsReq, payload, true)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Hold Invoices Action Failed', {
      cause: 'customError'
    })
  }

  if (response?.data?.errors?.length > 0 && response?.data?.errors?.length <= payload.length) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.INFO,
        bodyText: 'Some Invoices are on hold.'
      })
    )
  }

  const { pageSize, currentPage, filter, sortOption, sortOrder, resetSelectedRowRef } =
    yield select((app: IAppState) => app.Approve)
  if (response?.data?.errors === 0) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.INFO,
        bodyText: 'Selected Invoices successfully on hold.'
      })
    )
  }
  resetSelectedRowRef?.actionPendingTable?.current?.resetRowSelection({})
  yield put(
    fetchPendingActions({
      ...filter,
      page: currentPage,
      perPage: pageSize,
      ...(sortOption &&
        sortOrder && {
          sortOption,
          sortOrder
        })
    })
  )
}
