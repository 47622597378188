import axios from 'axios'
import { getEnvConfig } from 'utils/getEnvConfig'

const GOOGLE_MAP_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
const GOOGLE_MAP_KEY = getEnvConfig('GOOGLE_MAPS_KEY')

export const getFormattedAddressFromCoordinates = async (coordinates: any) => {
  try {
    const { lat, lng } = coordinates
    const latlng = [lat, lng].join(',')
    const res = await axios.get(GOOGLE_MAP_URL, {
      params: {
        latlng,
        key: GOOGLE_MAP_KEY
      }
    })
    return res.data.results[0].formatted_address
  } catch (err) {
    console.log(err)
  }
}

export const getCurrentCoordinates = async (accuracy = false) => {
  try {
    const pos: any = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        timeout: accuracy ? undefined : 10000,
        enableHighAccuracy: accuracy
      })
    })

    return {
      lng: pos.coords.longitude,
      lat: pos.coords.latitude
    }
  } catch (err) {
    // @ts-ignore
    return { err: err.code }
  }
}

export const getCurrentAddress = async () => {
  const coords = await getCurrentCoordinates()
  if (!coords.err) {
    const address = await getFormattedAddressFromCoordinates(coords)
    return address
  }
}
