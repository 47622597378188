import { takeCustomLatest } from 'state/common/common.effects'
import { CollectionListActions } from './collection-list.actions'
import {
  addAccountIdToOrderSettingListEffect,
  fetchCollectionListWithId,
  fetchCollectionListsEffect
} from './collection-list.effect'

function* fetchCollectionListSaga() {
  yield takeCustomLatest(CollectionListActions.FETCH_COLLECTION_LIST, fetchCollectionListsEffect)
}

function* fetchSpecificListSaga() {
  yield takeCustomLatest(CollectionListActions.FETCH_SPECIFIC_LIST, fetchCollectionListWithId)
}

function* addAccountIdToOrderSettingListSaga() {
  yield takeCustomLatest(
    CollectionListActions.ADD_ACCOUNT_ID_TO_ORDER_SETTING_LIST,
    addAccountIdToOrderSettingListEffect
  )
}

export const CollectionListWatchers = [
  fetchCollectionListSaga(),
  fetchSpecificListSaga(),
  addAccountIdToOrderSettingListSaga()
]
