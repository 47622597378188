import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { renderRoutes } from 'react-router-config'
import { useHistory, useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { initA2hsPrompt } from 'services/a2hs'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { getEnvConfig } from 'utils/getEnvConfig'
import { handleInitialLeadgen } from 'state/dashboard/dashboard.actions'
import { NavigationWatcher } from 'pages/Navigation'
import NewDashboard from 'pages/NewDashboard'
import WithValidation from 'WithValidation'
import favicon from '../shared/assets/favicon-okc.ico'
import { MobileRoutes, DesktopRoutes } from './routes'
import './App.module.css'
import './assets/fonts/NotoSans-SemiBold.ttf'
import { IPlatform } from './services/Tracking/Mixpanel.types'
import { IAppState } from './infra/AppState'
import { IAppProps } from './App.types'

const styles = createUseStyles({
  wrapper: {
    height: '100%'
  }
})

const App: React.FC<IAppProps> = ({ isMobileDevice = false }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const classes = styles()
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        window.location.pathname.includes('orderQR') ||
        window.location.pathname.includes('onboarding') ||
        window.location.pathname.includes('revisit') ||
        window.location.pathname.includes('reminder') ||
        window.location.pathname.includes('okcoins') ||
        window.location.pathname.includes('baiadvert') ||
        window.location.pathname.includes('defaulterinfo')
      ) {
        // Only attach the androidObj incase of OrderQR. Add checks here if more webview projects come in here
        window.androidObj = function AndroidClass() {}
      }
    }
  }, [])

  const history = useHistory()
  const isMobile = useSelector((state: IAppState) => state.Device.isMobile) || isMobileDevice
  const APP_TITLE = 'OkCredit | Merchant Web'

  // eslint-disable-next-line no-unused-expressions
  if (typeof window !== 'undefined') {
    window._history_ = history
    initA2hsPrompt()
  }
  Mixpanel.init(getEnvConfig('MIXPANEL_KEY'))
  /**
   * If client doesn't have hydrated isMobile (from server) value
   * use isMobileDevice (from client or navigator.userAgent)
   */
  if (isMobile) {
    Mixpanel.setPlatform(IPlatform.MOBILE)
  }
  React.useEffect(() => {
    const { search } = location
    dispatch(
      handleInitialLeadgen({
        queryString: search || '',
        history
      })
    )
  }, [])

  return (
    <div id="wrapper" className={classes.wrapper}>
      <Helmet
        defaultTitle={APP_TITLE}
        titleTemplate="%s – Desktop Web - OKC"
        link={[{ rel: 'icon', type: 'image/ico', href: favicon }]}
      >
        <meta name="description" content="Merchant Web Platform" />
      </Helmet>
      {isMobile ? null : <NewDashboard />}
      {renderRoutes(isMobile ? MobileRoutes : DesktopRoutes)}
      <NavigationWatcher />
    </div>
  )
}

export default WithValidation(App)
