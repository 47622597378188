import { ICustomer, IMerchantProfile, ISupplier } from 'state/dashboard/dashboard.types'
import { Action } from 'infra/types'
import { ENTITY_TYPE } from 'shared/constants'
import { IDispatchCallback } from 'services/Api/types'
import {
  ICreateTransaction,
  ICustomerTransaction,
  ISupplierTransaction,
  ITransactionAction,
  ITransactionBill,
  ITransactionFilter,
  TRANSACTION_REPORT_ACTION,
  TRANSACTION_TYPE,
  IUpdateRouteBeatAction,
  ITransPagy,
  IDoubleEntry
} from './transaction.types'

export const TransactionsActions = {
  SELECT_ENTITY: 'transactions/selectEntity',
  RESET_SELECTED_ENTITY: 'transactions/resetSelectedEntity',
  FETCH_REPORT_TRANSACTIONS: 'transactions/fetchReportTransactions',
  FETCH_REPORT_TRANSACTIONS_SUCCESS: 'transactions/fetchReportTransactionsSuccess',
  FETCH_REPORT_TRANSACTIONS_FAILURE: 'transactions/fetchReportTransactionsFailure',

  ADD_TRANSACTION_INITIATED: 'transactions/addTransactionInitiated',
  ADD_TRANSACTION_COMPLETED: 'transactions/addTransactionCompleted',
  ADD_TRANSACTION_ABORTED: 'transactions/addTransactionAborted',
  ADD_TRANSACTION_SUCCESS: 'transactions/addTransactionSuccess',
  ADD_TRANSACTION_FAILURE: 'transactions/addTransactionFailure',
  ADD_TRANSACTION_INTERACTED: 'transaction/addTransactionInteracted',

  DELETE_TRANSACTION_INITIATED: 'transaction/deleteTransactionInitiated',
  DELETE_TRANSACTION_COMPLETED: 'transactions/deleteTransactionCompleted',
  DELETE_TRANSACTION_ABORTED: 'transactions/deleteTransactionAborted',

  SEND_REMINDER_TO_CUSTOMER: 'transactions/sendReminderToCustomer',
  SEND_REMINDER_TO_SUPPLIER: 'transactions/sendReminderToSupplier',
  SHOW_NOTIFICATION_FOR_COLLECTION_DELETION: 'transactions/showNotificationForCollectionDeletion',

  GENERATE_TRANSACTIONS_REPORT: 'transactions/generateTransactionsReport',
  GENERATE_TRANSACTIONS_REPORT_SUCCESS: 'transactions/generateTransactionsReportSuccess',
  GENERATE_TRANSACTIONS_REPORT_FAILURE: 'transactions/generateTransactionsReportFailure',

  GET_TRANSACTION: 'transactions/getTransaction',
  GET_TRANSACTION_SUCCESS: 'transactions/getTransactionSuccess',

  ADD_TRANSACTION_IMAGE: 'transactions/addImage',
  ADD_TRANSACTION_IMAGE_SUCCESS: 'transactions/addImageSuccess',

  DELETE_TRANSACTION_IMAGE: 'transactions/deleteImage',
  DELETE_TRANSACTION_IMAGE_SUCCESS: 'transactions/deleteImageSuccess',

  RESET_TRANSACTION_BILLS: 'transactions/resetTransactionBills',

  SET_TRANSACTIONS_LOADING: 'transactions/setTransactionsLoading',

  EDIT_TRANSACTION: 'transactions/editTransaction',
  TOGGLE_EDIT_TRANSACTION_AMOUNT: 'transactions/editTransactionAmount',
  SET_TRANSACTION_ERROR_MSG: 'transactions/SET_TRANSACTION_ERROR_MSG',

  FETCH_ACCOUNTS_ROUTES_AND_BEATS: 'transactions/fetchAccountsRoutesAndBeats',
  FETCH_ACCOUNTS_ROUTES_AND_BEATS_SUCCESS: 'transactions/fetchAccountsRoutesAndBeatsSuccess',
  UPDATE_ACCOUNTS_ROUTES_AND_BEATS: 'transactions/updateAccountsRoutesAndBeats',
  SET_ROUTES_AND_BEATS_LOADER: 'transactions/setRoutesAndBeatsLoader',
  FETCH_LATEST_TRANSACTIONS: 'transactions/fetchLatestTransactions',
  FETCH_LATEST_TRANSACTIONS_SUCCESS: 'transactions/fetchLatestTransactionsSuccess',
  FETCH_LATEST_TRANSACTIONS_FAILURE: 'transactions/fetchLatestTransactionsFailure',
  FETCH_DOUBLE_TRANSACTIONS: 'transactions/fetchDoubleTransactions',
  SAVE_DOUBLE_ENTRY_TRANSACTION_DETAILS: 'transactions/saveDoubleEntryTransactionDetails'
}

export const getTransaction = (transactionId: string): Action<string> => ({
  type: TransactionsActions.GET_TRANSACTION,
  payload: transactionId
})

export const getTransactionSuccess = (payload: ICustomerTransaction) => ({
  type: TransactionsActions.GET_TRANSACTION_SUCCESS,
  payload
})

export const selectEntity = (
  type: ENTITY_TYPE,
  id: string
): Action<{
  type: ENTITY_TYPE
  id: string
}> => ({
  type: TransactionsActions.SELECT_ENTITY,
  payload: {
    type,
    id
  }
})

export const resetSelectedEntity = (): Action<null> => ({
  type: TransactionsActions.RESET_SELECTED_ENTITY,
  payload: null
})

export const fetchReportTransactions = (payload: {
  entityType: ENTITY_TYPE
  entityId: string
  filter?: ITransactionFilter
}): Action<{
  entityType: ENTITY_TYPE
  entityId: string
  filter?: ITransactionFilter
}> => ({
  type: TransactionsActions.FETCH_REPORT_TRANSACTIONS,
  payload
})

export const fetchReportTransactionsSuccess = (payload: {
  entityType: ENTITY_TYPE
  entityId: string
  //TODO(change this)
  data: any
  filter?: any
  source?: string
}): Action<any> => ({
  type: TransactionsActions.FETCH_REPORT_TRANSACTIONS_SUCCESS,
  payload
})

export const fetchReportTransactionsFailure = (
  entityType: ENTITY_TYPE,
  entityId: string,
  source?: string
): Action<{ entityType: ENTITY_TYPE; entityId: string; source?: string }> => ({
  type: TransactionsActions.FETCH_REPORT_TRANSACTIONS_FAILURE,
  payload: {
    entityType,
    entityId,
    source
  }
})

export const deleteTransactionInitiated = (
  transactionId: string,
  entityType: ENTITY_TYPE,
  entityId: string
): Action<{
  transactionId: string
  entityType: ENTITY_TYPE
  entityId: string
}> => ({
  type: TransactionsActions.DELETE_TRANSACTION_INITIATED,
  payload: { transactionId, entityType, entityId }
})

export const deleteTransactionAborted = (
  transactionId: string
): Action<{ transactionId: string }> => ({
  type: TransactionsActions.DELETE_TRANSACTION_ABORTED,
  payload: { transactionId }
})

export const deleteTransactionCompleted = (
  payload: ITransactionAction
): Action<ITransactionAction> => ({
  type: TransactionsActions.DELETE_TRANSACTION_COMPLETED,
  payload
})

export const sendReminderToCustomer = (data: {
  customer: ICustomer
  merchant: IMerchantProfile
}): Action<{
  customer: ICustomer
  merchant: IMerchantProfile
}> => ({
  type: TransactionsActions.SEND_REMINDER_TO_CUSTOMER,
  payload: data
})

export const sendReminderToSupplier = (data: {
  supplier: ISupplier
  merchant: IMerchantProfile
}): Action<{
  supplier: ISupplier
  merchant: IMerchantProfile
}> => ({
  type: TransactionsActions.SEND_REMINDER_TO_SUPPLIER,
  payload: data
})

export const showNotificationForCollectionDeletion = (transactionId: string): Action<string> => ({
  type: TransactionsActions.SHOW_NOTIFICATION_FOR_COLLECTION_DELETION,
  payload: transactionId
})

export const addTransactionInitiated = (payload: {
  entityType: ENTITY_TYPE
  entityId: string
  type: TRANSACTION_TYPE
  amount?: number
  source?: string
}): Action<Object> => ({
  type: TransactionsActions.ADD_TRANSACTION_INITIATED,
  payload
})

export const addTransactionCompleted = (
  payload: ICreateTransaction & IDispatchCallback
): Action<ICreateTransaction & IDispatchCallback> => ({
  type: TransactionsActions.ADD_TRANSACTION_COMPLETED,
  payload
})

export const addTransactionSuccess = (
  payload: ICustomerTransaction & ISupplierTransaction
): Action<ICustomerTransaction & ISupplierTransaction> => ({
  type: TransactionsActions.ADD_TRANSACTION_SUCCESS,
  payload
})

export const addTransactionFailure = (): Action<null> => ({
  type: TransactionsActions.ADD_TRANSACTION_FAILURE,
  payload: null
})

export const addTransactionInteracted = (payload: any) => {
  return {
    type: TransactionsActions.ADD_TRANSACTION_INTERACTED,
    payload
  }
}

export const generateTransactionReport = (payload: {
  entityType: ENTITY_TYPE
  entityId: string
  filter?: ITransactionFilter
  language?: string
  sourceAction: TRANSACTION_REPORT_ACTION
}): Action<any> => ({
  type: TransactionsActions.GENERATE_TRANSACTIONS_REPORT,
  payload
})

export const generateTransactionReportSuccess = (payload: string): Action<string> => ({
  type: TransactionsActions.GENERATE_TRANSACTIONS_REPORT_SUCCESS,
  payload
})

export const generateTransactionReportFailure = (): Action<null> => ({
  type: TransactionsActions.GENERATE_TRANSACTIONS_REPORT_FAILURE,
  payload: null
})

export const addTransactionImage = (payload: {
  image: Omit<ITransactionBill, 'id'>
  entityType: any
  entityId: any
}): Action<Object> => ({
  type: TransactionsActions.ADD_TRANSACTION_IMAGE,
  payload
})

export const addTransactionImageSuccess = (payload: {
  transactionId: string
  imageId: string
}): Action<Object> => ({
  type: TransactionsActions.ADD_TRANSACTION_IMAGE_SUCCESS,
  payload
})

export const deleteTransactionImage = (payload: {
  imageId: string
  transactionId: string
  entityType: any
  entityId: any
}): Action<Object> => ({
  type: TransactionsActions.DELETE_TRANSACTION_IMAGE,
  payload
})

export const deleteTransactionImageSuccess = (payload: {
  transactionId: string
  imageId: string
}): Action<Object> => ({
  type: TransactionsActions.DELETE_TRANSACTION_IMAGE_SUCCESS,
  payload
})

export const resetTransactionBills = (): Action<null> => ({
  type: TransactionsActions.RESET_TRANSACTION_BILLS,
  payload: null
})

export const setTransactionsLoading = (payload: boolean): Action<boolean> => ({
  type: TransactionsActions.SET_TRANSACTIONS_LOADING,
  payload
})

export const editTransaction = (payload: any): Action<any> => ({
  type: TransactionsActions.EDIT_TRANSACTION,
  payload
})

export const toggleEditTransactionAmount = (payload: boolean): Action<boolean> => ({
  type: TransactionsActions.TOGGLE_EDIT_TRANSACTION_AMOUNT,
  payload
})

export const setTransactionError = (msg: string): Action<string> => ({
  type: TransactionsActions.SET_TRANSACTION_ERROR_MSG,
  payload: msg
})

export const fetchAccountsRoutesAndBeats = (accountId: string): Action<string> => ({
  type: TransactionsActions.FETCH_ACCOUNTS_ROUTES_AND_BEATS,
  payload: accountId
})

export const fetchAccountsRoutesAndBeatsSuccess = (payload: {
  routes: string
  beats: string
}): Action<{
  routes: string
  beats: string
}> => ({
  type: TransactionsActions.FETCH_ACCOUNTS_ROUTES_AND_BEATS_SUCCESS,
  payload
})

export const updateAccountsRoutesAndBeats = (
  payload: IUpdateRouteBeatAction
): Action<IUpdateRouteBeatAction> => ({
  type: TransactionsActions.UPDATE_ACCOUNTS_ROUTES_AND_BEATS,
  payload
})

export const setRoutesAndBeatsLoader = (payload: boolean): Action<boolean> => ({
  type: TransactionsActions.SET_ROUTES_AND_BEATS_LOADER,
  payload
})

export const fetchLatestTransactions = (payload: {
  entityType: ENTITY_TYPE
  entityId: string
  source?: string
  filter?: ITransactionFilter
  limit?: number
  endTime?: number
}): Action<{
  entityType: ENTITY_TYPE
  entityId: string
  source?: string
  filter?: ITransactionFilter
  limit?: number
  endTime?: number
}> => ({
  type: TransactionsActions.FETCH_LATEST_TRANSACTIONS,
  payload
})

export const fetchLatestTransactionsSuccess = (payload: {
  entityType: ENTITY_TYPE
  entityId: string
  //TODO(change this)
  data: any
  filter?: any
  source?: string
}): Action<any> => ({
  type: TransactionsActions.FETCH_LATEST_TRANSACTIONS_SUCCESS,
  payload: {
    transaction: payload
  }
})

export const fetchLatestTransactionsFailure = (
  entityType: ENTITY_TYPE,
  entityId: string,
  source?: string
): Action<{ entityType: ENTITY_TYPE; entityId: string; source?: string }> => ({
  type: TransactionsActions.FETCH_LATEST_TRANSACTIONS_FAILURE,
  payload: {
    entityType,
    entityId,
    source
  }
})

export const fetchDoubleTransactionsAction = (payload: {
  transactionId: string
  accountId: string
}): Action<{ transactionId: string; accountId: string }> => ({
  type: TransactionsActions.FETCH_DOUBLE_TRANSACTIONS,
  payload
})

export const saveDoubleEntryTransactionDetails = (payload: IDoubleEntry): Action<IDoubleEntry> => ({
  type: TransactionsActions.SAVE_DOUBLE_ENTRY_TRANSACTION_DETAILS,
  payload
})
