import { IInventoryState } from './inventory.types'

export const InventoryState: IInventoryState = {
  loaders: {
    bulkUploadItems: false
  },
  document: null,
  modals: {
    downloadPurchaseSummary: false,
    downloadInventoryHisory: false,
    downloadExpenseItems: false,
    summaryFilterDrawer: false
  }
}
