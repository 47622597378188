export interface IDefaultProps {
  props?: any
}

export enum TRANSACTION_TYPE {
  NA = 0,
  CASH = 1,
  ONLINE = 2,
  CREDIT_DUES = 3,
  CHEQUE = 4,
  NEFT = 5,
  RETURN = 6,
  DAMAGED = 7,
  UPI = 8,
  OTHERS = 8,
  WALLET = 9
}

export const enum CL_VERSIONS {
  PREVIOUS_ASSIGNMENT = 0,
  PAST_ASSIGNMENT = 1,
  CURRENT_ASSIGNMENT = 2
}

export enum BILL_STATUS {
  PENDING = 0,
  PAID = 1,
  PARTIALLY_PAID = 2,
  REMOVED = 3,
  HOLD = 4
}
