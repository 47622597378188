import { call, put, select } from 'redux-saga/effects'
import { generatePath } from 'react-router'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { getErrorMessage } from 'services/Api/Errors'
import { IApiResponse, API_RESPONSE_TYPE } from 'shared/constants'
import { Action } from 'infra/types'
import { IAppState } from 'infra/AppState'
import {
  createNewCollectionListV2Api,
  updateStaffLink,
  updateStaffLinkV2
} from 'services/Api/StafflinkApi'

import {
  addToMultipleCollectionListFromDocument,
  createDocumentTemplateConfig,
  getAllUploadDocuments,
  getDefaultDocumentTemplateConfig,
  getDocumentBills,
  getDocumentTemplateConfig,
  updateDefaultTemplateConfig
} from 'services/Api/growthExperimentApi'

import { resetAllOnboardingDrawers } from 'state/onboarding/onboarding.actions'
import { signout } from 'state/auth/auth.actions'
import {
  COLLECTION_LIST_TYPE,
  COLLECTION_LIST_TAB
} from 'state/collectionList/collectionList.types'
import { Navigation } from 'services/navigation'
import { newPaths } from 'routes'
import { deleteUploadedFileWithDataApi } from 'services/Api/GrowthExperimentApiFolder/api'
import { fetchSupplyListAction } from '../list/supply-list.actions'
import { refetchListAndPopulateStore } from '../list/list.actions'
import {
  ICollectionListFromDocument,
  IMultipleCollectionListFromDocument
} from './uploadSheet.types'
import {
  fetchDocumentsListSuccess,
  fetchTemplatesListSuccess,
  setCurrentTemplateS,
  fetchTemplatesList,
  handleLoaderToggle,
  handleDrawerToggle,
  setCurrentDocumentSelected,
  fetchDocumentsList
} from './uploadSheet.actions'
import { getFormattedGlobalTemplates, getFormattedCurrentTemplates } from './uploadSheet.helpers'

export function* fetchDocumentsListEffect(action: Action<{ refetch: boolean } | null>) {
  try {
    const {
      RootDrawers: { UploadSheet }
    } = yield select((app: IAppState) => app)
    const paginationData = UploadSheet?.pagination?.documents
    const { refetch } = action.payload || {}
    if (paginationData?.isAllDataLoaded && !refetch) {
      return null
    }
    const nextPage = refetch ? 1 : (paginationData?.currentPage || 0) + 1
    const pageLimit = refetch ? 20 : paginationData?.pageLimit || 20
    const response: IApiResponse = yield call(getAllUploadDocuments, nextPage, pageLimit)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        fetchDocumentsListSuccess(response.data.Docs, {
          currentPage: nextPage,
          pageLimit,
          isAllDataLoaded:
            response.data.totalDocs <=
            response.data.Docs.length + UploadSheet?.documentsList?.length,
          total: response.data.totalDocs
        })
      )
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch Collection lists'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* collectionListFromDocumentEffect(action: Action<ICollectionListFromDocument>) {
  try {
    const {
      RootDrawers: { UploadSheet }
    } = yield select((app: IAppState) => app)
    if (!UploadSheet?.currentDocumentSelected?.documentId) {
      return null
    }
    const {
      isCreateNew,
      newListName,
      collectionListId,
      isReplaceOldItemsinCl,
      collectionListDueConfig
    } = action.payload
    const response: IApiResponse = yield call(
      getDocumentBills,
      UploadSheet?.currentDocumentSelected?.documentId
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (!response?.data?.bill_details?.length) {
        return null
      }
      const bills = response?.data?.bill_details.map(
        ({ account_id, bill_id }: { account_id: string; bill_id: string }) => ({
          bill_id,
          customer_id: account_id
        })
      )
      if (isCreateNew) {
        const response: IApiResponse = yield call(createNewCollectionListV2Api, {
          bills,
          name: newListName,
          due_config: {
            config: parseInt(COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2)
          },
          usage_type: COLLECTION_LIST_TAB.SUPPLY_LIST
        })
        if (response.type === API_RESPONSE_TYPE.SUCCESS) {
          yield put(
            handleDrawerToggle({
              documentToCollectionList: false,
              newCollectionListNameDrawer: false,
              uploadedFiles: false
            })
          )
          yield put(
            addAutoFadeNotification({
              type: NotificationType.SUCCESS,
              bodyText: `Successfully created list ${newListName}`
            })
          )
          yield put(fetchSupplyListAction({}))
          Navigation().to(
            generatePath(newPaths.supplyListSpecificId, {
              listId: response.data?.collection_list?.id
            })
          )
        } else {
          yield put(
            addAutoFadeNotification({
              type: NotificationType.ERROR,
              bodyText: 'Failed to create list, please try again later'
            })
          )
        }
      } else if (collectionListId) {
        const { currentRoute: { path } = { path: '' } } = yield select(
          (app: IAppState) => app.Navigation
        )
        if (isReplaceOldItemsinCl) {
          if (`${collectionListDueConfig}` === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2) {
            yield call(updateStaffLinkV2, {
              collection_list: { id: collectionListId, associated_account_ids: [], bills },
              action: 'replace_bills'
            })
          } else {
            yield call(updateStaffLink, collectionListId, null, 'remove_associations')
          }
        }
        if (`${collectionListDueConfig}` === COLLECTION_LIST_TYPE.INVOICE_LEVEL_DUE_V2) {
          if (!isReplaceOldItemsinCl) {
            yield call(updateStaffLinkV2, {
              collection_list: {
                id: collectionListId,
                associated_account_ids: [],
                bills: bills
              },
              action: 'add_bills'
            })
          }
        } else {
          yield call(
            updateStaffLink,
            collectionListId,
            bills.map((bill: any) => bill.customer_id),
            'edit_associations'
          )
        }
        yield put(
          handleDrawerToggle({
            documentToCollectionList: false,
            addToStaffLinkActionTypeDrawer: false,
            uploadedFiles: false
          })
        )
        yield put(fetchSupplyListAction({}))
        if (path.includes(collectionListId)) {
          yield put(refetchListAndPopulateStore({ listId: collectionListId }))
        } else {
          Navigation().to(generatePath(newPaths.supplyListSpecificId, { listId: collectionListId }))
        }
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText:
            response?.data?.message || 'Unable to add to staff link, please try again later.'
        })
      )
    }
    yield put(handleLoaderToggle({ collectionListFromDocument: false }))
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
    yield put(handleLoaderToggle({ collectionListFromDocument: false }))
  }
}

export function* fetchTemplatesListEffect() {
  try {
    const response: IApiResponse = yield call(getDocumentTemplateConfig)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const formattedGlobalTemplates = getFormattedGlobalTemplates(response.data?.global_template)
      yield put(
        fetchTemplatesListSuccess({
          templatesList: response.data.templates,
          globalTemplatesList: formattedGlobalTemplates
        })
      )
      const currentTemplates = getFormattedCurrentTemplates(
        formattedGlobalTemplates,
        response?.data?.templates
      )
      yield put(setCurrentTemplateS(currentTemplates))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(handleLoaderToggle({ fetchTemplatesList: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch templates'
        })
      )
    }
  } catch (e) {
    yield put(handleLoaderToggle({ fetchTemplatesList: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* fetchDefaultTemplateEffect() {
  try {
    const response: IApiResponse = yield call(getDefaultDocumentTemplateConfig)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const formattedGlobalTemplates = getFormattedGlobalTemplates(response.data?.global_template)
      const currentTemplates = getFormattedCurrentTemplates(
        formattedGlobalTemplates,
        response?.data?.templates
      )
      yield put(setCurrentTemplateS(currentTemplates))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to fetch default template'
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* createNewTemplateEffect(action: Action<any>) {
  try {
    const { name, sheetDetails, columnMapping, document_type, columnFormula, amountRoundingType } =
      action.payload

    const response: IApiResponse = yield call(createDocumentTemplateConfig, {
      name,
      document_type,
      sheetDetails,
      columnMapping,
      ...(columnFormula?.applyInvoiceNumberFormula && {
        columnFormula: { invoiceNumber: '^\\S+\\s(.+)$' }
      }),
      amountRoundingType
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Template Configuration Created'
        })
      )
      yield put(fetchTemplatesList())
      yield put(handleLoaderToggle({ createTemplate: false }))
      yield put(handleDrawerToggle({ templateDetails: false, newTemplateDrawer: false }))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(handleLoaderToggle({ createTemplate: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to create new template'
        })
      )
    }
  } catch (e) {
    yield put(handleLoaderToggle({ createTemplate: true }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* updateDefaultTemplateEffect(action: Action<any>) {
  try {
    const { templateId, document_type } = action.payload

    const response: IApiResponse = yield call(updateDefaultTemplateConfig, {
      document_type,
      templateId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Default Template Updated'
        })
      )
      yield put(fetchTemplatesList())
      yield put(handleLoaderToggle({ updateDefaultTemplate: false }))
      yield put(handleDrawerToggle({ templateDetails: false }))
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(handleLoaderToggle({ updateDefaultTemplate: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to update default template'
        })
      )
    }
  } catch (e) {
    yield put(handleLoaderToggle({ updateDefaultTemplate: true }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
  }
}

export function* addToMultipleCollectionListFromDocumentEffect(
  action: Action<IMultipleCollectionListFromDocument>
) {
  try {
    const {
      RootDrawers: { UploadSheet }
    } = yield select((app: IAppState) => app)

    if (!UploadSheet?.currentDocumentSelected?.documentId) {
      return null
    }
    const { routes, action: updateAction } = action.payload
    yield put(handleLoaderToggle({ collectionListFromDocument: true }))
    const response: IApiResponse = yield call(
      addToMultipleCollectionListFromDocument,
      UploadSheet?.currentDocumentSelected?.documentId,
      routes,
      updateAction
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(handleLoaderToggle({ collectionListFromDocument: false }))
      yield put(
        handleDrawerToggle({
          documentToCollectionList: false,
          addToStaffLinkActionTypeDrawer: false,
          uploadedFiles: false
        })
      )
      yield put(setCurrentDocumentSelected(null))
      yield put(resetAllOnboardingDrawers())
      Navigation().to(newPaths.supplyList)
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to add to staff link, contact support'
        })
      )
      yield put(handleLoaderToggle({ collectionListFromDocument: false }))
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
    yield put(handleLoaderToggle({ collectionListFromDocument: false }))
  }
}

export function* deleteUploadedFileWithDataEffect(action: Action<string>) {
  const documentId = action.payload
  yield put(handleLoaderToggle({ collectionListFromDocument: true }))
  const response: IApiResponse = yield call(deleteUploadedFileWithDataApi, {
    document_id: documentId
  })
  if (response.type === API_RESPONSE_TYPE.SUCCESS) {
    yield put(handleLoaderToggle({ collectionListFromDocument: false }))
    yield put(handleDrawerToggle({ deleteUploadedFile: false }))
    yield put(fetchDocumentsList({ refetch: true }))
  } else {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: response?.data?.response?.data?.error || 'Unable to delete! Please try again.'
      })
    )
    yield put(handleDrawerToggle({ deleteUploadedFile: false }))
  }
}
