import { IStaffBusinessSettings } from 'experiments/StaffLink/StaffLink.types'
import { IAbExp, IAbExperiment } from './experiment.types'

export const getFormattedAbProfile = (data: any): IAbExp => {
  return {
    collectionListOfInvoicesV1: data?.profile?.features?.collection_list_of_invoices_v1 || false,
    clQuickSummary: data?.profile?.features?.quick_summary || false,
    clViewV2: data?.profile?.features?.cl_view_v2 || false,
    collectionListSummaryV2: data?.profile?.features?.collection_list_summary_v2 || false,
    dynamicStatements: data?.profile?.features?.dynamic_statements || false,
    allowAppControls: data?.profile?.features?.allow_app_controls || false,
    tallyConnect: data?.profile?.features?.tally_connect || false,
    paidSoonBanner: data?.profile?.features?.paid_soon_banner || false,
    billingWeb: data?.profile?.features?.billing_web || false,
    okTermLoanAppBlock: data?.profile?.features?.oktermloan_app_block || false,
    staffLink: data?.profile?.features?.staff_link || false,
    editBillTransactionWeb: data?.profile?.features?.edit_bill_transaction_web || false,
    cashDeposit: data?.profile?.features?.cash_deposit || false,
    onDemandDistribution: data?.profile?.features?.on_demand_distribution || false,
    returnItemsV1: data?.profile?.features?.return_items_v1 || false,
    downtime: data?.profile?.features?.downtime || false,
    okDebitOrderManagement: data?.profile?.features?.okdebit_order_management || false,
    previewUpload: data?.profile?.features?.preview_upload || false,
    settleCollections: data?.profile?.features?.settle_collections || false
  }
}

export const getFormattedAbExperiment = (data: any): IAbExperiment => {
  return {
    'activation_web-all-paid_soon_banner':
      data?.profile?.experiments['activation_web-all-paid_soon_banner'] || {}
  }
}

export const getFormattedBusinessSettings = (data: any): IStaffBusinessSettings => {
  return {
    loading: false,
    bill: {
      canStaffEditRate: data.bill?.can_staff_edit_rate || false
    },
    order: {
      restrictStaffLowInv: data.order?.restrict_staff_low_inv || false
    },
    offer: {
      apply_multiple: data.offer?.apply_multiple || false
    },
    ready: true
  }
}
