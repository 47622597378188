import { ILanguageEntity } from 'shared/constants'

export interface IAuthData {
  accessToken: string
  refreshToken: string
  appLock: boolean
  expiresIn: number
  newUser: boolean
}
export interface IUserData {
  phoneNumber: number | null
  otp: string | null
  pin: string | null
  isLoggedIn: boolean
  language?: ILanguageEntity
  otpId: string | null
  authData?: IAuthData
  isNewUser: boolean
}

export enum COUNTDOWN_STATUS {
  STARTED,
  NOT_STARTED
}
export interface IAuthState {
  isAuthDialogOpen: boolean
  isAuthPasswordDialogOpen: boolean
  isSuccessDialogOpen: boolean
  isSelectAccountDialogOpen: boolean
  countdownStatus: COUNTDOWN_STATUS
  userData: IUserData
  otpTimer: number
  authDevise?: string | null
}

export interface IStorageListener {
  (url: string): void
}

export interface IUserState {
  isLoggedIn: boolean
  isNewUser: boolean
}
