import { takeCustomEvery, takeCustomLatest } from 'state/common/common.effects'
import {
  editChequeDetailsEffect,
  fetchReceivedChequesEffect,
  fetchOtherChequesEffect,
  updateChequeDetailsEffect
} from './cheque.effects'
import { chequeActions } from './cheque.actions'

function* fetchReceivedChequeSaga() {
  yield takeCustomLatest(chequeActions.fetchReceivedChequesAction, fetchReceivedChequesEffect)
}

function* fetchOtherChequeSaga() {
  yield takeCustomEvery(chequeActions.fetchOtherChequesAction, fetchOtherChequesEffect)
}

function* updateChequeDetailsSaga() {
  yield takeCustomEvery(chequeActions.updateChequeDetails, updateChequeDetailsEffect)
}

function* editChequeChequeDetailSaga() {
  yield takeCustomLatest(chequeActions.editChequeDetails, editChequeDetailsEffect)
}

export const ChequeWatchers = [
  fetchReceivedChequeSaga(),
  fetchOtherChequeSaga(),
  updateChequeDetailsSaga(),
  editChequeChequeDetailSaga()
]
