import { Action } from 'infra/types'
import {
  ICollectionListSummaryData,
  ICollectionListInvoiceSummaryData,
  ICollectionListSuccess,
  ISelectedCollectionList,
  ISetSortAndFilterCollectionAction,
  IDrawers,
  IUpdateCollectionListDueConfigAction,
  ICollectionList,
  ActionWithPagination,
  IDocumentsType,
  IPagination,
  ICurrentDocumentSelected,
  ICollectionListFromDocument,
  ILoaders,
  IFetchCollectionListSummary,
  ISelectedSettingsForAddTxn,
  ICurrentTemplate,
  ITxnAcceptOrRemove,
  IReplacements,
  ITransactions,
  ISaveFiltersCreateNewList,
  IRemoveBill,
  IHelpers,
  IBillsList,
  ICreateNewCollectionList,
  COLLECTION_LIST_TYPE_TAB,
  IFetchSingleCollectionListAction,
  ISelectedInvoiceToShift,
  IQuickSummary,
  COLLECTION_LIST_TAB,
  ICombinedCollectionListSuccess,
  ICollectionListNameAndUsageTypeChangeAction,
  CL_VERSIONS,
  IFetchClV2Action,
  IStaffMember,
  COLLECTION_LIST_TYPE,
  IPendingAction
} from './collectionList.types'

export const CollectionListActions = {
  FETCH_COLLECTION_LISTS: 'collectionList/FETCH_COLLECTION_LISTS',
  FETCH_COLLECTION_LISTS_SUCCESS: 'collectionList/FETCH_COLLECTION_LISTS_SUCCESS',
  FETCH_SINGLE_COLLECTION_LISTS: 'collectionList/FETCH_SINGLE_COLLECTION_LISTS',
  FETCH_SINGLE_COLLECTION_LISTS_SUCCESS: 'collectionList/FETCH_SINGLE_COLLECTION_LISTS_SUCCESS',
  CLOSE_COLLECTION_LIST: 'collectionList/CLOSE_COLLECTION_LIST',
  COLLECTION_LIST_ACCOUNT_CLICK: 'collectionList/COLLECTION_LIST_ACCOUNT_CLICK',
  COLLECTION_LIST_SET_ACCOUNT: 'collectionList/COLLECTION_LIST_SET_ACCOUNT',
  COLLECTION_LIST_ADD_ACCOUNT: 'collectionList/COLLECTION_LIST_ADD_ACCOUNT',
  COLLECTION_LIST_REMOVE_ACCOUNT: 'collectionList/COLLECTION_LIST_REMOVE_ACCOUNT',
  COLLECTION_LIST_SET_ACCOUNT_TO_NEW_LIST: 'collectionList/COLLECTION_LIST_SET_ACCOUNT_TO_NEW_LIST',
  COLLECTION_LIST_ADD_ACCOUNT_TO_NEW_LIST: 'collectionList/COLLECTION_LIST_ADD_ACCOUNT_TO_NEW_LIST',
  COLLECTION_LIST_REMOVE_ACCOUNT_TO_NEW_LIST:
    'collectionList/COLLECTION_LIST_REMOVE_ACCOUNT_TO_NEW_LIST',
  COLLECTION_LIST_REMOVE_ALL_ACCOUNTS_FROM_NEW_LIST:
    'collectionList/COLLECTION_LIST_REMOVE_ALL_ACCOUNTS_FROM_NEW_LIST',
  SET_SORT_FILTER_COLLECTION_LIST: 'collectionList/SET_SORT_FILTER_COLLECTION_LIST',
  HANDLE_DRAWER_TOGGLE: 'collectionList/HANDLE_DRAWER_TOGGLE',
  REMOVE_COLLECTION_LIST: 'collectionList/REMOVE_COLLECTION_LIST',
  REMOVE_COLLECTION_LIST_SUCCESS: 'collectionList/REMOVE_COLLECTION_LIST_SUCCESS',
  REMOVE_BILL: 'collectionList/REMOVE_BILL',
  REMOVE_BILL_SUCCESS: 'collectionList/REMOVE_BILL_SUCCESS',
  COLLECTION_LIST_NAME_CHANGE: 'collectionList/COLLECTION_LIST_NAME_CHANGE',
  UPDATE_COLLECTION_LIST: 'collectionList/UPDATE_COLLECTION_LIST',
  UPDATE_COLLECTION_LIST_DUE_CONFIG: 'collectionList/UPDATE_COLLECTION_LIST_DUE_CONFIG',
  UPDATE_COLLECTION_LIST_DUE_CONFIG_TO_NEW_LIST:
    'collectionList/UPDATE_COLLECTION_LIST_DUE_CONFIG_TO_NEW_LIST',
  CREATE_NEW_LIST_BTN_CLICK: 'collectionList/CREATE_NEW_LIST_BTN_CLICK',
  // CREATE_COLLECTION_LIST: 'collectionList/CREATE_COLLECTION_LIST',
  CREATE_COLLECTION_LIST_SUCCESS: 'collectionList/CREATE_COLLECTION_LIST_SUCCESS',
  SHIFT_ACCOUNT_CLICK: 'collectionList/SHIFT_ACCOUNT_CLICK',
  SET_SHIFT_ACCOUNT: 'collectionList/SET_SHIFT_ACCOUNT',
  SHIFT_INVOICE_CLICK: 'collectionList/SHIFT_INVOICE_CLICK',
  SET_SHIFT_INVOICE: 'collectionList/SET_SHIFT_INVOICE',
  ADD_TXN_TO_ACCOUNT_CLICK: 'collectionList/ADD_TXN_TO_ACCOUNT_CLICK',
  HANDLE_TXN_ACCEPT_OR_REMOVE: 'collectionList/HANDLE_TXN_ACCEPT_OR_REMOVE',
  HANDLE_REMOVE_TXN_POPUP: 'collectionList/HANDLE_REMOVE_TXN_POPUP',

  FETCH_DOCUMENTS_LIST: 'collectionList/FETCH_DOCUMENTS_LIST',
  FETCH_DOCUMENTS_LIST_SUCCESS: 'collectionList/FETCH_DOCUMENTS_LIST_SUCCESS',
  DOCUMENTS_LIST_REFRESH: 'collectionList/DOCUMENTS_LIST_REFRESH',
  DOCUMENTS_LIST_REFRESH_SUCCESS: 'collectionList/DOCUMENTS_LIST_REFRESH_SUCCESS',
  HANDLE_CLOSE_NEW_COLLECTION_LIST: 'collectionList/HANDLE_CLOSE_NEW_COLLECTION_LIST',
  UPDATE_NEW_COLLECTION_LIST_NAME: 'collectionList/UPDATE_NEW_COLLECTION_LIST_NAME',
  UPDATE_NEW_COLLECTION_LIST_TYPE: 'collectionList/UPDATE_NEW_COLLECTION_LIST_TYPE',
  CURRENT_DOCUMENT_SELECTED: 'collectionList/CURRENT_DOCUMENT_SELECTED',
  COLLECTION_LIST_FROM_DOCUMENT: 'collectionList/COLLECTION_LIST_FROM_DOCUMENT',
  FETCH_CL_QUICK_SUMMARY_SUCCESS: 'collectionList/FETCH_CL_QUICK_SUMMARY_SUCCESS',
  ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT:
    'collectionList/ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT',
  HANDLE_LOADER_TOGGLE: 'collectionList/HANDLE_LOADER_TOGGLE',
  HANDLE_HELPER_TOGGLE: 'collectionList/HANDLE_HELPER_TOGGLE',
  VIEW_REPLACEMENTS: 'collectionList/VIEW_REPLACEMENTS',
  SAVE_FILTERS_CREATE_NEW_LIST: 'collectionList/SAVE_FILTERS_CREATE_NEW_LIST',
  SELECT_ALL_COLLECTION_LIST_CLICK: 'collectionList/SELECT_ALL_COLLCECTION_LIST_CLICK',
  FETCH_STAFF_MEMBERS: 'collectionList/FETCH_STAFF_MEMBERS',
  FETCH_STAFF_MEMBERS_SUCCESS: 'collectionList/FETCH_STAFF_MEMBERS_SUCCESS',
  UPDATE_STAFF_MEMBER: 'collectionList/UPDATE_STAFF_MEMBER',

  // CREATE_COLLECTION_LIST_FROM_DOCUMENT_ACCOUNTS:
  //   'collectionList/CREATE_COLLECTION_LIST_FROM_DOCUMENT_ACCOUNTS',
  // ADD_DOCUMENTS_ACCOUNTS_TO_COLLECTION_LIST:
  //   'collectionList/ADD_DOCUMENTS_ACCOUNTS_TO_COLLECTION_LIST',

  // list summary action
  FETCH_LIST_SUMMARY: 'collectionList/FETCH_LIST_SUMMARY',
  FETCH_LIST_SUMMARY_SUCCESS: 'collectionList/FETCH_LIST_SUMMARY_SUCCESS',
  FETCH_LIST_SUMMARY_INVOICE_SUCCESS: 'collectionList/FETCH_LIST_SUMMARY_INVOICE_SUCCESS',
  SELECT_CUSTOMER_FOR_ACTION: 'collectionList/SELECT_CUSTOMER_FOR_ACTION',
  OPEN_MOVE_LIST_DRAWER: 'collectionList/OPEN_MOVE_LIST_DRAWER',
  RESET_COLLECTION_LIST_TO_SHIFT: 'collectionList/RESET_COLLECTION_LIST_TO_SHIFT',
  COLLECTION_LIST_REMOVE_ACCOUNTS: 'collectionList/COLLECTION_LIST_REMOVE_ACCOUNTS',

  // Import excel templates
  FETCH_TEMPLATES_LIST: 'collectionList/FETCH_TEMPLATES_LIST',
  FETCH_TEMPLATES_LIST_SUCCESS: 'collectionList/FETCH_TEMPLATES_LIST_SUCCESS',
  FETCH_DEFAULT_TEMPLATE: 'collectionList/FETCH_DEFAULT_TEMPLATE',
  CREATE_TEMPLATE: 'collectionList/CREATE_TEMPLATE',
  UPDATE_DEFAULT_TEMPLATE: 'collectionList/UPDATE_DEFAULT_TEMPLATE',
  SET_CURRENT_TEMPLATES: 'collectionList/SET_CURRENT_TEMPLATES',

  // Import file to bulk upload customers
  BULK_UPLOAD_ACCOUNTS: 'collectionList/BULK_UPLOAD_ACCOUNTS',
  BULK_UPLOAD_ACCOUNTS_SUCCESS: 'collectionList/BULK_UPLOAD_ACCOUNTS_SUCCESS',
  START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS: 'collectionList/START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS',
  STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS: 'collectionList/STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS',
  BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS: 'collectionList/BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS',

  FETCH_COLLECTION_LIST_BILLS: 'collectionList/FETCH_COLLECTION_LIST_BILLS',
  FETCH_COLLECTION_LIST_BILLS_SUCCESS: 'collectionList/FETCH_COLLECTION_LIST_BILLS_SUCCESS',
  FETCH_COLLECTION_LIST_BILLS_FAILED: 'collectionList/FETCH_COLLECTION_LIST_BILLS_FAILED',
  COLLECTION_LIST_BILL_CLICK: 'collectionList/COLLECTION_LIST_BILL_CLICK',
  COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_CLICK:
    'collectionList/COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_CLICK',
  COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_SUCCESS:
    'collectionList/COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_SUCCESS',
  COLLECTION_LIST_BILL_ALL_SELECT: 'collectionList/COLLECTION_LIST_BILL_ALL_SELECT',
  COLLECTION_LIST_REMOVE_ALL_BILLS_FROM_NEW_LIST:
    'collectionList/COLLECTION_LIST_REMOVE_ALL_BILLS_FROM_NEW_LIST',
  CREATE_NEW_COLLECTION_LIST_V2: 'collectionList/CREATE_NEW_COLLECTION_LIST_V2',
  FETCH_COLLECTION_LISTS_V2: 'collectionList/FETCH_COLLECTION_LISTS_V2',
  COLLECTION_LIST_ADD_BILLS: 'collectionList/COLLECTION_LIST_ADD_BILLS',
  COLLECTION_LIST_REMOVE_BILLS: 'collectionList/COLLECTION_LIST_REMOVE_BILLS',
  SET_COLLECTION_LIST_TYPE_TAB: 'collectionList/SET_COLLECTION_LIST_TYPE_TAB',
  UPDATE_COLLECTION_LIST_V2_DUE_CONFIG: 'collectionList/UPDATE_COLLECTION_LIST_V2_DUE_CONFIG',
  SET_SELECTED_LIST_TAB: 'collectionList/SET_SELECTED_LIST_TAB',
  FETCH_ALL_COLLECTION_LISTS: 'collectionList/FETCH_ALL_COLLECTION_LISTS',
  FETCH_ALL_COLLECTION_LISTS_SUCCESS: 'collectionList/FETCH_ALL_COLLECTION_LISTS_SUCCESS',
  FETCH_ALL_PENDING_ACTIONS: 'collectionList/FETCH_ALL_PENDING_ACTIONS',
  FETCH_ALL_PENDING_ACTIONS_SUCCESS: 'collectionList/FETCH_ALL_PENDING_ACTIONS_SUCCESS'
}

export const handleCloseNewCollectionList = (): Action<null> => ({
  type: CollectionListActions.HANDLE_CLOSE_NEW_COLLECTION_LIST,
  payload: null
})

export const updateNewCollectionListName = (name: string): Action<string> => ({
  type: CollectionListActions.UPDATE_NEW_COLLECTION_LIST_NAME,
  payload: name
})

export const updateNewCollectionListType = (
  type: COLLECTION_LIST_TYPE
): Action<COLLECTION_LIST_TYPE> => ({
  type: CollectionListActions.UPDATE_NEW_COLLECTION_LIST_TYPE,
  payload: type
})

export const updateCollectionList = (payload: ICollectionList): Action<ICollectionList> => ({
  type: CollectionListActions.UPDATE_COLLECTION_LIST,
  payload
})

export const fetchCollectionLists = (): Action<null> => ({
  type: CollectionListActions.FETCH_COLLECTION_LISTS,
  payload: null
})

export const fetchCollectionListSuccess = (
  payload: ICollectionListSuccess
): Action<ICollectionListSuccess> => ({
  type: CollectionListActions.FETCH_COLLECTION_LISTS_SUCCESS,
  payload
})

export const handleLoaderToggle = (payload: ILoaders): Action<ILoaders> => ({
  type: CollectionListActions.HANDLE_LOADER_TOGGLE,
  payload
})

export const helperToggle = (payload: IHelpers): Action<IHelpers> => ({
  type: CollectionListActions.HANDLE_HELPER_TOGGLE,
  payload
})

export const fetchSingleCollectionLists = (
  collectionListId: string,
  type?: string
): Action<IFetchSingleCollectionListAction> => ({
  type: CollectionListActions.FETCH_SINGLE_COLLECTION_LISTS,
  payload: {
    collectionListId,
    type
  }
})
export const fetchSingleCollectionListSuccess = (
  payload: ISelectedCollectionList
): Action<ISelectedCollectionList> => ({
  type: CollectionListActions.FETCH_SINGLE_COLLECTION_LISTS_SUCCESS,
  payload
})

export const createNewListBtnClick = (payload?: {
  isOnboarding?: boolean
}): Action<{ isOnboarding?: boolean } | undefined> => ({
  type: CollectionListActions.CREATE_NEW_LIST_BTN_CLICK,
  payload: payload
})

export const removeCollectionList = (): Action<null> => ({
  type: CollectionListActions.REMOVE_COLLECTION_LIST,
  payload: null
})
export const removeCollectionListSuccess = (collectionListId: string): Action<string> => ({
  type: CollectionListActions.REMOVE_COLLECTION_LIST_SUCCESS,
  payload: collectionListId
})

export const removeBill = (payload: IRemoveBill): Action<IRemoveBill> => ({
  type: CollectionListActions.REMOVE_BILL,
  payload
})

export const removeBillSuccess = (payload: IRemoveBill): Action<IRemoveBill> => ({
  type: CollectionListActions.REMOVE_BILL_SUCCESS,
  payload
})

export const closeCollectionList = (): Action<null> => ({
  type: CollectionListActions.CLOSE_COLLECTION_LIST,
  payload: null
})

export const collectionListAccountClick = (accoutId: string[]): Action<string[]> => ({
  type: CollectionListActions.COLLECTION_LIST_ACCOUNT_CLICK,
  payload: accoutId
})

export const collectionListSetAccount = (payload: string[]): Action<string[]> => ({
  type: CollectionListActions.COLLECTION_LIST_SET_ACCOUNT,
  payload
})

export const collectionListAddAccount = (accoutId: string[]): Action<string[]> => ({
  type: CollectionListActions.COLLECTION_LIST_ADD_ACCOUNT,
  payload: accoutId
})

export const collectionListRemoveAccount = (accoutId: string[]): Action<string[]> => ({
  type: CollectionListActions.COLLECTION_LIST_REMOVE_ACCOUNT,
  payload: accoutId
})

export const collectionListAddBills = (data: {
  bill_id: string
  customer_id: string
}): Action<{ bill_id: string; customer_id: string }> => ({
  type: CollectionListActions.COLLECTION_LIST_ADD_BILLS,
  payload: data
})

export const collectionListRemoveBills = (data: {
  bill_id: string
  customer_id: string
}): Action<{ bill_id: string; customer_id: string }> => ({
  type: CollectionListActions.COLLECTION_LIST_REMOVE_BILLS,
  payload: data
})

export const collectionListRemoveAccounts = (accoutId: Array<string>): Action<Array<string>> => ({
  type: CollectionListActions.COLLECTION_LIST_REMOVE_ACCOUNTS,
  payload: accoutId
})

export const collectionListSetAccountToNewList = (payload: string[]): Action<string[]> => ({
  type: CollectionListActions.COLLECTION_LIST_SET_ACCOUNT_TO_NEW_LIST,
  payload
})

export const collectionListAddAccountToNewList = (accoutId: string[]): Action<string[]> => ({
  type: CollectionListActions.COLLECTION_LIST_ADD_ACCOUNT_TO_NEW_LIST,
  payload: accoutId
})

export const shiftAccountClick = (accoutId: string): Action<string> => ({
  type: CollectionListActions.SHIFT_ACCOUNT_CLICK,
  payload: accoutId
})

export const setShiftAccount = (accoutId: string[]): Action<string[]> => ({
  type: CollectionListActions.SET_SHIFT_ACCOUNT,
  payload: accoutId
})

export const setShiftInvoice = (
  invoice: ISelectedInvoiceToShift[]
): Action<ISelectedInvoiceToShift[]> => ({
  type: CollectionListActions.SET_SHIFT_INVOICE,
  payload: invoice
})

export const shiftInvoiceClick = (
  invoice: ISelectedInvoiceToShift
): Action<ISelectedInvoiceToShift> => ({
  type: CollectionListActions.SHIFT_INVOICE_CLICK,
  payload: invoice
})

export const addTxnToAccountClick = (
  payload: ISelectedSettingsForAddTxn
): Action<ISelectedSettingsForAddTxn> => ({
  type: CollectionListActions.ADD_TXN_TO_ACCOUNT_CLICK,
  payload
})

export const collectionListRemoveAccountToNewList = (accoutId: string[]): Action<string[]> => ({
  type: CollectionListActions.COLLECTION_LIST_REMOVE_ACCOUNT_TO_NEW_LIST,
  payload: accoutId
})

// export const createCollectionList = (accoutId: string): Action<string> => ({
//   type: CollectionListActions.CREATE_COLLECTION_LIST,
//   payload: accoutId
// })
export const createCollectionListSuccess = (payload: ICollectionList): Action<ICollectionList> => ({
  type: CollectionListActions.CREATE_COLLECTION_LIST_SUCCESS,
  payload
})

export const setFilterAndSortCollectionList = (
  payload: ISetSortAndFilterCollectionAction
): Action<ISetSortAndFilterCollectionAction> => ({
  type: CollectionListActions.SET_SORT_FILTER_COLLECTION_LIST,
  payload
})

export const handleDrawerToggle = (payload: IDrawers): Action<IDrawers> => ({
  type: CollectionListActions.HANDLE_DRAWER_TOGGLE,
  payload
})

export const handleTxnAcceptOrRemove = (
  payload: ITxnAcceptOrRemove
): Action<ITxnAcceptOrRemove> => ({
  type: CollectionListActions.HANDLE_TXN_ACCEPT_OR_REMOVE,
  payload
})

export const handleRemoveTxnPopup = (payload: ITransactions): Action<ITransactions> => ({
  type: CollectionListActions.HANDLE_REMOVE_TXN_POPUP,
  payload
})

export const viewReplacements = (payload: IReplacements): Action<IReplacements> => ({
  type: CollectionListActions.VIEW_REPLACEMENTS,
  payload
})
export const collectionListNameAndUsageTypeChange = (
  payload: ICollectionListNameAndUsageTypeChangeAction
): Action<ICollectionListNameAndUsageTypeChangeAction> => ({
  type: CollectionListActions.COLLECTION_LIST_NAME_CHANGE,
  payload
})

export const selectAllCollectionListClick = (
  payload: string[] | null | undefined
): Action<string[] | null | undefined> => ({
  type: CollectionListActions.SELECT_ALL_COLLECTION_LIST_CLICK,
  payload
})

export const updateCollectionListDueConfig = (
  payload: IUpdateCollectionListDueConfigAction
): Action<IUpdateCollectionListDueConfigAction> => ({
  type: CollectionListActions.UPDATE_COLLECTION_LIST_DUE_CONFIG,
  payload
})

export const updateCollectionListDueConfigToNewList = (
  payload: IUpdateCollectionListDueConfigAction
): Action<IUpdateCollectionListDueConfigAction> => ({
  type: CollectionListActions.UPDATE_COLLECTION_LIST_DUE_CONFIG_TO_NEW_LIST,
  payload
})

export const fetchDocumentsList = (): Action<null> => ({
  type: CollectionListActions.FETCH_DOCUMENTS_LIST,
  payload: null
})

export const fetchDocumentsListSuccess = (
  documentsList: IDocumentsType[],
  pagination: IPagination
): ActionWithPagination<IDocumentsType[]> => ({
  type: CollectionListActions.FETCH_DOCUMENTS_LIST_SUCCESS,
  payload: documentsList,
  pagination
})

export const documentsListRefresh = (): Action<null> => ({
  type: CollectionListActions.DOCUMENTS_LIST_REFRESH,
  payload: null
})

export const documentsListRefreshSuccess = (
  payload: IDocumentsType[]
): Action<IDocumentsType[]> => ({
  type: CollectionListActions.DOCUMENTS_LIST_REFRESH_SUCCESS,
  payload
})

export const setCurrentDocumentSelected = (
  payload: ICurrentDocumentSelected | null
): Action<ICurrentDocumentSelected | null> => ({
  type: CollectionListActions.CURRENT_DOCUMENT_SELECTED,
  payload
})

export const collectionListFromDocument = (
  payload: ICollectionListFromDocument | null
): Action<ICollectionListFromDocument | null> => ({
  type: CollectionListActions.COLLECTION_LIST_FROM_DOCUMENT,
  payload
})

export const addToMultipleCollectionListFromDocument = (payload: any): Action<any> => ({
  type: CollectionListActions.ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT,
  payload
})

export const fetchCollectionListSummary = (
  payload: IFetchCollectionListSummary
): Action<IFetchCollectionListSummary> => ({
  type: CollectionListActions.FETCH_LIST_SUMMARY,
  payload
})

export const fetchCollectionListSummarySuccess = (
  data: ICollectionListSummaryData
): Action<ICollectionListSummaryData> => ({
  type: CollectionListActions.FETCH_LIST_SUMMARY_SUCCESS,
  payload: data
})

export const fetchCollectionListInvoiceSummarySuccess = (
  data: ICollectionListInvoiceSummaryData
): Action<ICollectionListInvoiceSummaryData> => ({
  type: CollectionListActions.FETCH_LIST_SUMMARY_INVOICE_SUCCESS,
  payload: data
})

export const selectCustomerListForAction = (list: Array<string>) => ({
  type: CollectionListActions.SELECT_CUSTOMER_FOR_ACTION,
  payload: list
})

export const openMovetoList = (): Action<null> => ({
  type: CollectionListActions.OPEN_MOVE_LIST_DRAWER,
  payload: null
})

export const resetShiftingIdsList = (): Action<null> => ({
  type: CollectionListActions.RESET_COLLECTION_LIST_TO_SHIFT,
  payload: null
})

export const fetchTemplatesList = (): Action<null> => ({
  type: CollectionListActions.FETCH_TEMPLATES_LIST,
  payload: null
})

export const fetchClQuickSummarySuccess = (payload: IQuickSummary): Action<IQuickSummary> => ({
  type: CollectionListActions.FETCH_CL_QUICK_SUMMARY_SUCCESS,
  payload
})

export const fetchTemplatesListSuccess = (payload: {
  templatesList: any[]
  globalTemplatesList: any[]
}): Action<{
  templatesList: any[]
  globalTemplatesList: any[]
}> => ({
  type: CollectionListActions.FETCH_TEMPLATES_LIST_SUCCESS,
  payload
})

export const setCurrentTemplateS = (
  payload: ICurrentTemplate[] | null
): Action<ICurrentTemplate[] | null> => ({
  type: CollectionListActions.SET_CURRENT_TEMPLATES,
  payload
})

export const fetchDefaultTemplate = (): Action<null> => ({
  type: CollectionListActions.FETCH_DEFAULT_TEMPLATE,
  payload: null
})

export const saveFiltersCreateNewList = (
  payload: ISaveFiltersCreateNewList
): Action<ISaveFiltersCreateNewList> => ({
  type: CollectionListActions.SAVE_FILTERS_CREATE_NEW_LIST,
  payload
})

export const createNewTemplate = (payload: {
  name: string
  document_type: number
  sheetDetails: {
    name: string
    header: string
    isItemDatails: string
    no_bill_rows: boolean
  }
  columnFormula?: {
    applyInvoiceNumberFormula?: boolean
  }
  columnMapping: {
    customerName: string
    customerCode: string
    invoiceNumber: string
    amount: string
    invoiceDate: string
    saleType: string
    saleRoute: string
    mobileNumber: string
    paymentReconBillNumber: string
    accountBeat: string
    accountRoute: string
    advanceColumns?: {
      orderNumber: string
      orderDate: string
      soldTo: string
      shipTo: string
      billTo: string
      itemNumber: string
      productCode: string
      productDescription?: string // optional
      hsn: string
      promo?: string // optional
      uom?: string // optional
      itemType: string
      quantityCartons: string
      quantityUnits: string
      mrp: string
    }
  }
  amountRoundingType: number
}): Action<{
  name: string
  document_type: number
  sheetDetails: {
    name: string
    header: string
    isItemDatails: string
    no_bill_rows: boolean
  }
  columnFormula?: {
    applyInvoiceNumberFormula?: boolean
  }
  columnMapping: {
    customerName: string
    customerCode: string
    invoiceNumber: string
    amount: string
    invoiceDate: string
    saleType: string
    saleRoute: string
    mobileNumber: string
    paymentReconBillNumber: string
    accountBeat: string
    accountRoute: string
  }
  amountRoundingType: number
}> => ({
  type: CollectionListActions.CREATE_TEMPLATE,
  payload
})

export const updateDefaultTemplate = (payload: {
  templateId?: string
  document_type: number
}): Action<{
  templateId?: string
  document_type: number
}> => ({
  type: CollectionListActions.UPDATE_DEFAULT_TEMPLATE,
  payload
})

export const bulkUploadCustomersAction = (payload: any): Action<any> => ({
  type: CollectionListActions.BULK_UPLOAD_ACCOUNTS,
  payload
})

export const bulkUploadCustomersSuccessAction = (payload: any): Action<any> => ({
  type: CollectionListActions.BULK_UPLOAD_ACCOUNTS_SUCCESS,
  payload
})

export const startPollBulkUploadAccountsStatus = (payload: string): Action<string> => ({
  type: CollectionListActions.START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS,
  payload
})

export const stopPollBulkUploadAccountsStatus = (): Action<null> => ({
  type: CollectionListActions.STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS,
  payload: null
})

export const bulkUploadCustomersStatusSuccessAction = (payload: string): Action<string> => ({
  type: CollectionListActions.BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS,
  payload
})

export const fetchCollectionListBillDetails = (
  startTime?: number,
  endTime?: number
): Action<{
  startTime?: number
  endTime?: number
}> => ({
  type: CollectionListActions.FETCH_COLLECTION_LIST_BILLS,
  payload: {
    startTime,
    endTime
  }
})

export const fetchCollectionListBillDetailsSuccess = (data: IBillsList): Action<IBillsList> => ({
  type: CollectionListActions.FETCH_COLLECTION_LIST_BILLS_SUCCESS,
  payload: data
})

export const fetchCollectionListBillDetailsFailed = (): Action<null> => ({
  type: CollectionListActions.FETCH_COLLECTION_LIST_BILLS_FAILED,
  payload: null
})

export const collectionListBillClick = (
  customerId: string,
  billId?: string
): Action<{ bill_id?: string; customer_id: string }> => ({
  type: CollectionListActions.COLLECTION_LIST_BILL_CLICK,
  payload: {
    customer_id: customerId,
    ...(billId && { bill_id: billId })
  }
})

export const collectionListAllBillsOfAccountClick = (
  customerId: string,
  areAllInvoicesSelected: boolean
): Action<{ customerId?: string; areAllInvoicesSelected: boolean }> => ({
  type: CollectionListActions.COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_CLICK,
  payload: {
    customerId: customerId,
    areAllInvoicesSelected: areAllInvoicesSelected
  }
})

export const collectionListAllBillsOfAccountSuccess = (payload: {
  bills: { customer_id: string; bill_id: string }[]
  apiAction: string
}): Action<{
  bills: { customer_id: string; bill_id: string }[]
  apiAction: string
}> => ({
  type: CollectionListActions.COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_SUCCESS,
  payload
})

export const selectAllBillsCollectionListClick = (
  data: Array<{ bill_id: string; customer_id: string }>
): Action<Array<{ bill_id: string; customer_id: string }>> => ({
  type: CollectionListActions.COLLECTION_LIST_BILL_ALL_SELECT,
  payload: data
})

export const createNewCollectionListV2 = (
  listPayload: ICreateNewCollectionList
): Action<ICreateNewCollectionList> => ({
  type: CollectionListActions.CREATE_NEW_COLLECTION_LIST_V2,
  payload: listPayload
})

export const fetchSingleCollectionListsV2 = (
  collectionListId: string,
  version?: CL_VERSIONS
): Action<IFetchClV2Action> => ({
  type: CollectionListActions.FETCH_COLLECTION_LISTS_V2,
  payload: {
    collectionListId,
    version
  }
})

export const collectionListRemoveAllAccountsFromNewList = (): Action<null> => ({
  type: CollectionListActions.COLLECTION_LIST_REMOVE_ALL_ACCOUNTS_FROM_NEW_LIST,
  payload: null
})

export const collectionListRemoveAllBillsFromNewList = (): Action<null> => ({
  type: CollectionListActions.COLLECTION_LIST_REMOVE_ALL_BILLS_FROM_NEW_LIST,
  payload: null
})

export const setCollectionListTypeTab = (
  payload: COLLECTION_LIST_TYPE_TAB
): Action<COLLECTION_LIST_TYPE_TAB> => ({
  type: CollectionListActions.SET_COLLECTION_LIST_TYPE_TAB,
  payload
})

export const updateCollectionListV2DueConfig = (
  payload: IUpdateCollectionListDueConfigAction
): Action<IUpdateCollectionListDueConfigAction> => ({
  type: CollectionListActions.UPDATE_COLLECTION_LIST_V2_DUE_CONFIG,
  payload
})

export const setSelectedListTab = (payload: COLLECTION_LIST_TAB): Action<COLLECTION_LIST_TAB> => ({
  type: CollectionListActions.SET_SELECTED_LIST_TAB,
  payload
})

export const fetchAllCollectionLists = (): Action<null> => ({
  type: CollectionListActions.FETCH_ALL_COLLECTION_LISTS,
  payload: null
})

export const fetchAllCollectionListSuccess = (
  payload: ICombinedCollectionListSuccess
): Action<ICombinedCollectionListSuccess> => ({
  type: CollectionListActions.FETCH_ALL_COLLECTION_LISTS_SUCCESS,
  payload
})

export const fetchAllPendingActions = (): Action<null> => ({
  type: CollectionListActions.FETCH_ALL_PENDING_ACTIONS,
  payload: null
})

export const fetchAllPendingActionsSuccess = (
  payload: IPendingAction[]
): Action<IPendingAction[]> => ({
  type: CollectionListActions.FETCH_ALL_PENDING_ACTIONS_SUCCESS,
  payload
})

export const fetchStaffMembers = (): Action<null> => ({
  type: CollectionListActions.FETCH_STAFF_MEMBERS,
  payload: null
})

export const fetchStaffMembersSuccess = (payload: IStaffMember[]): Action<IStaffMember[]> => ({
  type: CollectionListActions.FETCH_STAFF_MEMBERS_SUCCESS,
  payload
})

export const updateStaffMemberName = (payload: {
  id: string
  name: string
}): Action<{ id: string; name: string }> => ({
  type: CollectionListActions.UPDATE_STAFF_MEMBER,
  payload
})
