import { Action } from 'infra/types'
import { LIST_USAGE_TYPE } from 'services/Api/StaffLinkApiFolder/type'
import { IListSettingsState } from './list-settings.types'
import { listSettingsDrawerActions } from './list-settings.actions'

const LIST_SETTINGS: IListSettingsState = {
  newListName: '',
  usageType: LIST_USAGE_TYPE.COLLECTION,
  drawers: {
    showListSettingsDrawer: false,
    editListSettingsDrawer: false
  }
}

export const ListSettingsReducer = (
  state: IListSettingsState = LIST_SETTINGS,
  action: Action<any>
): IListSettingsState => {
  switch (action.type) {
    case listSettingsDrawerActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case listSettingsDrawerActions.SET_LIST_SETTINGS_DRAWER: {
      return {
        ...state,
        listId: action.payload.listId || state.listId,
        newListName: action.payload.newListName || state.newListName,
        configType: action.payload.configType || state.configType,
        usageType: action.payload.usageType || state.usageType,
        requiredData: action.payload.requiredData || state.requiredData,
        filterByKey: action.payload.filterByKey || state.filterByKey,
        filterByValues: action.payload.filterByValues || state.filterByValues,
        successCallbackRef: action.payload.successCallbackRef || state.successCallbackRef,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.drawerStatus
        }
      }
    }
    case listSettingsDrawerActions.RESET_LIST_SETTINGS_STATE: {
      return LIST_SETTINGS
    }
    default: {
      return state
    }
  }
}
