import { ITagsState } from './tags.types'

export const TagsState: ITagsState = {
  loading: false,
  accountTags: [],
  normalisedTags: {
    byIds: {},
    allIds: []
  },
  showEditTagDialog: false,
  drawers: {
    invoiceTagSelection: false
  }
}
