import { Action } from 'infra/types'

export const NEW_DASHBOARD_EVENTS_ACTIONS = {
  SEND: 'newDashboard/Event'
}

export const pushEvent = (
  eventName: string,
  eventProperties?: Record<string, any>
): Action<any> => {
  return {
    type: NEW_DASHBOARD_EVENTS_ACTIONS.SEND,
    payload: { eventName, eventProperties }
  }
}
