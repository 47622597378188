import { getEnvConfig } from 'utils/getEnvConfig'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor,
  businessIdInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const MERCHANT_PROFILE_ENDPOINT = '/one'
const MERCHANT_GET_CUSTOMERS_ENDPOINT = '/customer?deleted=false'
const MERCHANT_GET_SUPPLIERS_ENDPOINT = '/sc/suppliers'
const BUSINESS_CATEGORIES_ENDPOINT = '/categories'
const MERCHANT_CUSTOMER_ENDPOINT = '/customer'
const MERCHANT_SUPPLIER_ENDPOINT = '/sc/suppliers'
const MERCHANT_CUSTOMER_TRANSACTION_ENDPOINT = '/transaction'
const MERCHANT_SUPPLIER_TRANSACTION_ENDPOINT = '/sc/transactions'
const SYNC_TRANSACTION_ENDPOINT = 'sync-transactions'
const MERCHANT_CUSTOMER_SUPPLIER_TRANSACTION_ENDPOINT = '/new/GetTransactions'
const MERCHANT_CONTACT_ENDPOINT = '/contacts/network-by-merchant-id'
const MERCHANT_HELP_ENDPOINT = '/help'
const GENERATE_USER_STATEMENT_ENDPOINT = '/generate-report'
const TRANSACTION_IMAGE = '/new/transaction-images'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('MERCHANT_ENDPOINT') || ''

const api = new ApiService(endpoint, headers)
const apiV2 = new ApiService(endpoint.replace('v1', 'v2'), headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

apiV2.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
apiV2.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const registerMerchantName = (name: string) => {
  return api.patchRequest(MERCHANT_PROFILE_ENDPOINT, {
    name,
    update_name: true
  })
}

export const getMerchantProfile = () => {
  return api.getRequest(MERCHANT_PROFILE_ENDPOINT, {})
}

export const patchMerchantProfile = (payload: object) => {
  return api.patchRequest(MERCHANT_PROFILE_ENDPOINT, payload)
}

export const getCustomersList = () => {
  return api.getRequest(MERCHANT_GET_CUSTOMERS_ENDPOINT, {})
}

export const getSuppliersList = () => {
  return api.getRequest(MERCHANT_GET_SUPPLIERS_ENDPOINT, {})
}

export const patchCustomer = (customerId: string, payload: object) => {
  return api.putRequest(`${MERCHANT_CUSTOMER_ENDPOINT}/${customerId}`, payload)
}

export const patchSupplier = (supplierId: string, payload: object) => {
  return api.patchRequest(`${MERCHANT_SUPPLIER_ENDPOINT}/${supplierId}`, payload)
}

export const deleteCustomer = (customerId: string) => {
  return api.deleteRequest(`${MERCHANT_CUSTOMER_ENDPOINT}/${customerId}`)
}

export const deleteSupplier = (supplierId: string) => {
  return api.deleteRequest(`${MERCHANT_SUPPLIER_ENDPOINT}/${supplierId}`)
}

export const getBusinessCategories = () => {
  return api.getRequest(BUSINESS_CATEGORIES_ENDPOINT, {})
}

export const getCustomerTransactions = ({ id = '', start_time = 0, end_time, orderBy }: any) => {
  return api.postRequest(MERCHANT_CUSTOMER_SUPPLIER_TRANSACTION_ENDPOINT, {
    txn_req: {
      type: 1,
      role: 1,
      account_id: id,
      order_by: orderBy || 3,
      start_time,
      include_start_time: true,
      ...(end_time ? { end_time } : {})
    }
  })
}

export const getSupplierTransactions = ({ id = '', start_time = 0, end_time, orderBy }: any) => {
  return api.postRequest(MERCHANT_CUSTOMER_SUPPLIER_TRANSACTION_ENDPOINT, {
    txn_req: {
      type: 1,
      role: 2,
      account_id: id,
      order_by: orderBy || 3,
      start_time,
      include_start_time: true,
      ...(end_time ? { end_time } : {})
    }
  })
}

export const addCustomer = (name: string, mobile?: string) => {
  const sendObject = mobile ? { mobile, description: name } : { description: name }
  return api.postRequest(MERCHANT_CUSTOMER_ENDPOINT, sendObject)
}

export const addCustomerDetails = (payload: {
  name: string
  mobile?: string
  customerCode?: string
  gstNumber?: string
  address?: string
}) => {
  const requestPayload = {
    description: payload.name,
    ...(payload?.mobile && { mobile: payload.mobile }),
    ...(payload?.customerCode && { customer_code: payload.customerCode }),
    ...(payload?.gstNumber && { gst_number: payload.gstNumber }),
    ...(payload?.address && { address: payload.address })
  }

  return api.postRequest(MERCHANT_CUSTOMER_ENDPOINT, requestPayload)
}

export const addSupplier = (name: string, mobile?: string) => {
  const sendObject = mobile ? { mobile, name } : { name }
  return api.postRequest(MERCHANT_SUPPLIER_ENDPOINT, sendObject)
}

export const createCustomerTransaction = (payload: object) => {
  return api.postRequest(MERCHANT_CUSTOMER_TRANSACTION_ENDPOINT, payload)
}

export const createSupplierTransaction = (supplierId: string, payload: object) => {
  return api.postRequest(`${MERCHANT_SUPPLIER_ENDPOINT}/${supplierId}/transactions`, payload)
}

export const deleteTransactionForCustomer = (transactionId: string) => {
  return api.deleteRequest(`${MERCHANT_CUSTOMER_TRANSACTION_ENDPOINT}/${transactionId}`)
}

export const deleteTransactionForSupplier = (tranasactionId: string) => {
  return api.deleteRequest(`${MERCHANT_SUPPLIER_TRANSACTION_ENDPOINT}/${tranasactionId}`)
}

export const getContactsFromMerchant = () => {
  return api.getRequest(MERCHANT_CONTACT_ENDPOINT, {})
}

export const getCustomerData = (id: string) => {
  return api.getRequest(`${MERCHANT_CUSTOMER_ENDPOINT}/${id}`, {})
}

export const getTransactionData = (id: string) => {
  return api.getRequest(`${MERCHANT_CUSTOMER_TRANSACTION_ENDPOINT}/${id}`, {})
}

export const getHelpSection = (merchantId: string) => {
  return api.getRequest(`${MERCHANT_HELP_ENDPOINT}?merchant_id=${merchantId}`, {})
}

export const generateTransactionReport = (payload: {
  merchant_id: string
  account_id: string
  start_time?: number
  end_time?: number
  type: number
  lang: string
}) => api.postRequest(GENERATE_USER_STATEMENT_ENDPOINT, payload)

export const addTransactionImage = (payload: any) => {
  return api.postRequest(TRANSACTION_IMAGE, payload)
}

export const removeTransactionImage = (payload: string) => {
  return api.deleteRequest(`${TRANSACTION_IMAGE}/${payload}`)
}

export const editTransaction = (payload: object) => {
  return api.postRequest(SYNC_TRANSACTION_ENDPOINT, payload)
}

export const MerchantApi = {
  registerMerchantName,
  getMerchantProfile,
  patchMerchantProfile,
  getCustomersList,
  getSuppliersList,
  patchCustomer,
  patchSupplier,
  getBusinessCategories,
  addCustomer,
  addSupplier,
  getCustomerTransactions,
  getSupplierTransactions,
  deleteCustomer,
  deleteSupplier,
  createCustomerTransaction,
  createSupplierTransaction,
  deleteTransactionForCustomer,
  deleteTransactionForSupplier,
  getContactsFromMerchant,
  getCustomerData,
  getTransactionData,
  getHelpSection,
  generateTransactionReport,
  addTransactionImage,
  removeTransactionImage,
  editTransaction
}
