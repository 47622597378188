import { Action } from 'infra/types'
import { returnsActions } from './returns.actions'
import { IReturnsDrawerState } from './returns.types'

const RETURNS_DRAWER_STATE: IReturnsDrawerState = {
  drawers: {
    showReturnsDrawer: false
  }
}

export const ReturnsReducer = (
  state: IReturnsDrawerState = RETURNS_DRAWER_STATE,
  action: Action<any>
): IReturnsDrawerState => {
  switch (action.type) {
    case returnsActions.SET_RETURNS_DRAWER_DATA: {
      return {
        ...state,
        drawers: {
          showReturnsDrawer: action.payload.status
        },
        selectedReturnsData: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}
