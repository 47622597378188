import { Action } from 'infra/types'
import { IDispatchCallback, IOTPInfo, IPinInfo } from 'services/Api/types'
import { IAuthData, IUserState, IStorageListener } from './auth.types'

export const AuthActions = {
  AUTH_PAGE_LOADED: 'auth/authLoaded',
  CHANGE_LANGUAGE: 'auth/changeLanguage',
  ENTER_PHONE_NUMBER: 'auth/enterPhoneNumber',
  ENTER_OTP: 'auth/enterOtp',
  OPEN_AUTH_DIALOG: 'auth/openAuthDialog',
  CLOSE_AUTH_DIALOG: 'auth/closeAuthDialog',
  AUTH_ERROR: 'auth/authError',

  // Get OTP
  GET_OTP: 'auth/getOtp',
  GET_OTP_SUCCESS: 'auth/getOtpSuccess',
  GET_OTP_FAILURE: 'auth/getOtpFailure',

  // Vetify OTP
  VERIFY_OTP: 'auth/verifyOtp',
  VERIFY_OTP_SUCCESS: 'auth/verifyOtpSuccess',
  VERIFY_OTP_FAILURE: 'auth/verifyOtpFailure',

  START_TIMER: 'auth/startTimer',
  END_TIMER: 'auth/endTimer',
  UPDATE_TIMER: 'auth/updateTimer',
  SHOW_SUCCESS_MODAL: 'auth/showSuccessModal',
  SHOW_SELECT_ACCOUNT_MODAL: 'auth/SHOW_SELECT_ACCOUNT_MODAL',
  CLOSE_SELECT_ACCOUNT_MODAL: 'auth/CLOSE_SELECT_ACCOUNT_MODAL',
  NAVIGATE_TO_DASHBOARD: 'auth/navigateToDashboard',
  NAVIGATE_TO_DASHBOARD_SUCCESS: 'auth/navigateToDashboardSuccess',
  SYNC_WITH_LOCAL_STORAGE: 'auth/syncWithLocalStorage',
  SYNC_WITH_LOCAL_STORAGE_SUCCESS: 'auth/syncWithLocalStorageSuccess',
  USER_LOGGED_IN: 'auth/userLoggedIn',
  USER_LOGGED_OUT: 'auth/userLoggedOut',
  SET_USER_LANGUAGE: 'auth/setUserLanguage',
  OPEN_SIGNOUT_MODAL: 'auth/openSignoutModal',
  SIGNOUT: 'auth/signout',
  SIGNOUT_CANCEL: 'auth/signoutCancel',
  SIGNOUT_FROM_ALL_DEVICES: 'auth/signoutFromAllDevices',
  SIGNOUT_FROM_ALL_DEVICES_CANCEL: 'auth/signoutFromAllDevicesCancel',
  CLICK_TERMS: 'auth/clickTerms',
  CLICK_PRIVACY: 'auth/clickPrivacy',
  CLICK_ABOUT: 'auth/clickAbout',
  EDIT_MOBILE: 'auth/editMobile',
  OPEN_LANGUAGE_DROPDOWN: 'auth/openLanguageDropdown',
  OPEN_AUTH_PASSWORD_DIALOG: 'auth/OPEN_AUTH_PASSWORD_DIALOG',
  VERIFY_PIN: 'auth/VERIFY_PIN',
  ENTER_PIN: 'auth/ENTER_PIN'
}

export const openLanguageDropdown = (page = 'Onboarding Page'): Action<string> => ({
  type: AuthActions.OPEN_LANGUAGE_DROPDOWN,
  payload: page
})

export const editMobile = (phoneNumber: number | null): Action<number | null> => ({
  type: AuthActions.EDIT_MOBILE,
  payload: phoneNumber
})
export const clickTerms = (): Action<null> => ({
  type: AuthActions.CLICK_TERMS,
  payload: null
})

export const clickPrivacy = (): Action<null> => ({
  type: AuthActions.CLICK_PRIVACY,
  payload: null
})

export const clickAbout = (): Action<null> => ({
  type: AuthActions.CLICK_ABOUT,
  payload: null
})

export const authLoaded = (): Action<null> => ({
  type: AuthActions.AUTH_PAGE_LOADED,
  payload: null
})

export const openSignoutModal = (): Action<null> => ({
  type: AuthActions.OPEN_SIGNOUT_MODAL,
  payload: null
})

export const signout = (): Action<null> => ({
  type: AuthActions.SIGNOUT,
  payload: null
})

export const cancelSignout = (): Action<null> => ({
  type: AuthActions.SIGNOUT_CANCEL,
  payload: null
})

export const signoutFromAllDevices = (merchantId: string): Action<string> => ({
  type: AuthActions.SIGNOUT_FROM_ALL_DEVICES,
  payload: merchantId
})

export const cancelSignoutFromAllDevices = (): Action<null> => ({
  type: AuthActions.SIGNOUT_FROM_ALL_DEVICES_CANCEL,
  payload: null
})

export const setUserLanguage = (language: string): Action<any> => ({
  type: AuthActions.SET_USER_LANGUAGE,
  payload: language
})

export const navigateToDashboard = (replace: any): Action<any> => ({
  type: AuthActions.NAVIGATE_TO_DASHBOARD,
  payload: replace
})

export const navigateToDashboardSuccess = (): Action<null> => ({
  type: AuthActions.NAVIGATE_TO_DASHBOARD_SUCCESS,
  payload: null
})

export const showSuccessModal = (): Action<null> => ({
  type: AuthActions.SHOW_SUCCESS_MODAL,
  payload: null
})

export const showSelectAccountModal = (): Action<null> => ({
  type: AuthActions.SHOW_SELECT_ACCOUNT_MODAL,
  payload: null
})

export const closeSelectAccountModal = (): Action<null> => ({
  type: AuthActions.CLOSE_SELECT_ACCOUNT_MODAL,
  payload: null
})

export const startTimer = (): Action<null> => ({
  type: AuthActions.START_TIMER,
  payload: null
})

export const endTimer = (): Action<null> => ({
  type: AuthActions.END_TIMER,
  payload: null
})

export const updateTimer = (elapsed: number): Action<number> => ({
  type: AuthActions.UPDATE_TIMER,
  payload: elapsed
})

export const sendOtp = (phoneNumber: number): Action<number> => ({
  type: AuthActions.GET_OTP,
  payload: phoneNumber
})

export const sendOtpSuccess = (otpId: string): Action<string> => ({
  type: AuthActions.GET_OTP_SUCCESS,
  payload: otpId
})

export const sendOtpFailure = (otpId: string): Action<string> => ({
  type: AuthActions.GET_OTP_FAILURE,
  payload: otpId
})

export const verifyReceivedOtp = (payload: IOTPInfo & IDispatchCallback): Action<IOTPInfo> => ({
  type: AuthActions.VERIFY_OTP,
  payload
})

export const verifyReceivedOtpSuccess = (authData: IAuthData): Action<IAuthData> => ({
  type: AuthActions.VERIFY_OTP_SUCCESS,
  payload: authData
})

export const verifyReceivedOtpFailure = (otpId: string): Action<string> => ({
  type: AuthActions.VERIFY_OTP_FAILURE,
  payload: otpId
})

export const changeUserLanguage = (
  languageId: number,
  screen: string = 'Onboarding Page'
): Action<{ languageId: number; screen: string }> => ({
  type: AuthActions.CHANGE_LANGUAGE,
  payload: {
    languageId,
    screen
  }
})

export const enterPhoneNumber = (phoneNumber: number): Action<number> => ({
  type: AuthActions.ENTER_PHONE_NUMBER,
  payload: phoneNumber
})

export const enterOTP = (otp: string): Action<string> => ({
  type: AuthActions.ENTER_OTP,
  payload: otp
})

export const openAuthDialog = (): Action<null> => ({
  type: AuthActions.OPEN_AUTH_DIALOG,
  payload: null
})

export const closeAuthDialog = (): Action<null> => ({
  type: AuthActions.CLOSE_AUTH_DIALOG,
  payload: null
})

export const userLoggedIn = (newUser: boolean): Action<boolean> => ({
  type: AuthActions.USER_LOGGED_IN,
  payload: newUser
})

export const userLoggedOut = (): Action<null> => ({
  type: AuthActions.USER_LOGGED_OUT,
  payload: null
})

export const syncWithLocalStorage = (
  push?: IStorageListener
): Action<IStorageListener | undefined> => ({
  type: AuthActions.SYNC_WITH_LOCAL_STORAGE,
  payload: push || undefined
})

export const syncWithLocalStorageSuccess = (
  isLoggedIn: boolean,
  isNewUser: boolean
): Action<IUserState> => ({
  type: AuthActions.SYNC_WITH_LOCAL_STORAGE_SUCCESS,
  payload: {
    isLoggedIn,
    isNewUser
  }
})

export const authError = (message: string): Action<string> => ({
  type: AuthActions.AUTH_ERROR,
  payload: message
})

export const openAuthPasswordDialog = (open: boolean): Action<boolean> => ({
  type: AuthActions.OPEN_AUTH_PASSWORD_DIALOG,
  payload: open
})

export const verifyReceivedPin = (payload: IPinInfo & IDispatchCallback): Action<IPinInfo> => ({
  type: AuthActions.VERIFY_PIN,
  payload
})

export const enterPIN = (pin: string): Action<string> => ({
  type: AuthActions.ENTER_PIN,
  payload: pin
})
