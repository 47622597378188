import { BankWiseResponse, TransformedData } from 'services/Api/StaffLinkApiFolder/type'
import { IStaffSummaryResponse, IStaffSummaryDataFormatedData } from './types'

export const formatStaffSummaryData = (
  data: IStaffSummaryResponse
): IStaffSummaryDataFormatedData => {
  const items =
    data?.staffs?.map((item) => {
      const collected = Number(item.collected) / 100
      const toBank = Number(item.bank_deposited) / 100
      const toBusiness = Number(item.cash_deposited) / 100
      const openingBalance = Number(item.opening_balance) / 100

      let withStaff
      if (openingBalance < 0) {
        withStaff = collected + Math.abs(openingBalance) - (toBank + toBusiness)
      } else {
        withStaff = collected - openingBalance - (toBank + toBusiness)
      }
      return {
        name: item?.name || '-',
        collected,
        toBank,
        toBusiness,
        withStaff: withStaff < 0 ? Math.abs(withStaff) : -withStaff,
        openingBalance
      }
    }) || []
  const summaryCollected = Number(data.total_collected) / 100
  const summaryToBank = Number(data.total_bank_deposited) / 100
  const summaryToBusiness = Number(data.total_cash_deposited) / 100
  return {
    summaryList: [
      {
        name: 'Total Cash',
        collected: summaryCollected,
        toBank: summaryToBank,
        toBusiness: summaryToBusiness,
        withStaff: summaryCollected - (summaryToBank + summaryToBusiness)
      }
    ],
    items
  }
}

export const formatStaffSummaryDataForSheet = (data: IStaffSummaryResponse): any => {
  const items =
    data?.staffs?.map((item) => {
      const collected = Number(item.collected) / 100
      const toBank = Number(item.bank_deposited) / 100
      const toBusiness = Number(item.cash_deposited) / 100
      const openingBalance = Number(item.opening_balance) / 100

      let withStaff
      if (openingBalance < 0) {
        withStaff = collected + Math.abs(openingBalance) - (toBank + toBusiness)
      } else {
        withStaff = collected - openingBalance - (toBank + toBusiness)
      }
      return {
        Name: item?.name || '-',
        'Opening Balance': openingBalance,
        Collected: collected,
        'To Bank': toBank,
        'To Business': toBusiness,
        'With Staff': withStaff < 0 ? Math.abs(withStaff) : -withStaff
      }
    }) || []
  const summaryCollected = Number(data.total_collected) / 100
  const summaryToBank = Number(data.total_bank_deposited) / 100
  const summaryToBusiness = Number(data.total_cash_deposited) / 100
  return {
    summaryList: [
      {
        Name: 'Total Cash',
        Collected: summaryCollected,
        'To Bank': summaryToBank,
        'To Business': summaryToBusiness,
        'With Staff': summaryCollected - (summaryToBank + summaryToBusiness)
      }
    ],
    items
  }
}
function getAllSources(data: BankWiseResponse['staff_data']) {
  const allSources: Array<string> = []
  for (const key in data) {
    const sourceData = data[key].source_data || [] // Handle cases without source_data
    sourceData.forEach((item) => allSources.push(item.source))
  }
  return allSources
}

export const getFormattedBankDataForSheet = (data: BankWiseResponse['staff_data']) => {
  const transformedData: TransformedData = {}
  for (const key in data) {
    const sourceData = data[key].source_data
    transformedData[key] = {
      name: key,
      ...sourceData.reduce((acc, item) => {
        acc[item.source] = Number(item.total_amount) / 100
        return acc
      }, {} as Record<string, number>)
    }
  }
  const sourceArray = Object.values(transformedData)
  const allHeaders = getAllSources(data)
  sourceArray.forEach((item: any) => {
    allHeaders.forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(item, key)) {
        item[key] = 0
      }
    })
  })
  return Object.values(sourceArray)
}
