import { all, call, delay, put, select } from 'redux-saga/effects'
import { utils, writeFileXLSX } from 'xlsx'
import { IApiResponse, API_RESPONSE_TYPE } from 'shared/constants'
import { Action } from 'infra/types'
import {
  createOrderBillApi,
  createOrderProformaApi,
  createOrderProformaSettlementApi,
  fetchOrdersApi,
  listCatalogueItemsApi,
  updateMultipleOrdersApi,
  updateOrdersApi,
  updateProformaStatusApi
} from 'services/Api/StaffLinkApiFolder/api'
import {
  CreateInvoicePayload,
  FetchMultipleCatalogueItems,
  FetchOrdersPayload,
  IOrdersState,
  ORDERS_ENTITY,
  OrderItems,
  OrderStatus,
  OrdersRowData,
  ProformaRowData,
  SettlementPayload,
  TransactionTypeEnumMapper,
  UpdateMultipleOrderPayload,
  UpdateOrderPayload,
  UpdateProformaStatusPayload
} from 'state/new-summary/orders/orders.types'
import {
  IOrderBillRequestPayload,
  IOrderProformaRequestPayload,
  UpdateOrderApiPaylod,
  fetchOrdersPayload
} from 'services/Api/StaffLinkApiFolder/type'
import {
  constructOrderBillRequestPayload,
  constructOrderProformaRequestPayload,
  getFormattedDownloadDataProforma,
  getFormattedItemDetails,
  getFormattedItemDetailsForDownload,
  getFormattedOrders,
  getFormattedProformaInvoices,
  getItemQuantitiesFromItems
} from 'state/new-summary/orders/orders.formatter'
import {
  createInvoiceFromProformaAction,
  fetchOrdersAction,
  setActiveOrderEntity,
  setApprovedOrdersActionFilters,
  setApprovedOrdersDataAction,
  setCreateBillFromOrders,
  setCreateBillFromProforma,
  setCreateProformaFromOrder,
  setOrderItemsDataAction,
  setOrdersDataAction,
  setOrdersDrawer,
  setOrderSelectedChargesAction,
  setOrderSelectedDiscountsAction,
  setOrdersHtmlAction,
  setOrdersLoaders,
  setPendingOrdersActionFilters,
  setProformaInvoicesActionFilters,
  setProformaInvoicesDataAction,
  setProformaInvoicesHtmlAction,
  setSelectedMultipleOrdersAction,
  setSelectedOrderAction,
  updateOrdersAction
} from 'state/new-summary/orders/orders.action'
import { IAppState } from 'infra/AppState'
import { newPaths } from 'routes'
import { IDashboardState } from 'state/dashboard/dashboard.types'
import {
  IBillingState,
  IPreviewOrderRequestPayload,
  IUploadAction,
  ItemsDataType,
  NewItemDetailEntity
} from 'state/billing/billing.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { Navigation } from 'services/navigation'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import { FETCH_INVENTORY_ITEMS_RESPONSE } from 'services/Api/BillingApiFolder.ts/type'
import { printBills } from 'services/Api/growthExperimentApi'
import { setPrintBillTemplate } from 'state/billing/billing.actions'
import { printOrdersApi, printProformaApi } from 'services/Api/GrowthExperimentApiFolder/api'
import { convertDayToEpocCurrent } from 'utils/dateTransformer'
import {
  billingRoundFunction,
  constructRequestPayload,
  convertPaisaToRupee,
  convertRupeeToPaisa,
  formatInventoryItemsDataV2Extended
} from 'state/billing/billing.helpers'
import { IRootState } from 'experiments/StaffLink/StaffLink.types'

export function* fetchOrdersEffect(action: Action<FetchOrdersPayload>) {
  const { startTime, endTime, filterType, sortOption, statuses, staffIds } = action.payload
  const payload: fetchOrdersPayload = {
    start_time: startTime,
    end_time: endTime,
    staff_id: staffIds,
    statuses: statuses?.length ? statuses : [OrderStatus.PENDING]
  }
  const response: IApiResponse = yield call(fetchOrdersApi, payload)
  const {
    customers: { byIds }
  }: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while fetching pending orders, please try after sometime', {
      cause: 'customError'
    })
  }
  const formattedOrders = getFormattedOrders(response.data.orders, byIds)
  yield put(setCreateBillFromProforma(false))
  if (action.payload.statuses?.includes(OrderStatus.APPROVED)) {
    yield put(setApprovedOrdersDataAction(formattedOrders))
    yield put(
      setApprovedOrdersActionFilters({ filter: { startTime, endTime, filterType }, sortOption })
    )
    yield put(setOrdersLoaders({ loaderName: 'isFetchingApprovedOrders', status: false }))
    const { resetSelectedRowRef } = yield select((app: IAppState) => app.Orders)
    resetSelectedRowRef?.ordersApprovalTable?.current?.resetRowSelection({})
    return
  }
  if (action.payload.statuses?.includes(OrderStatus.PROFORMA_CREATED)) {
    const formattedProformaInvoices = getFormattedProformaInvoices(
      response.data.orders,
      response.data.proforma_invoices,
      byIds
    )
    yield put(setProformaInvoicesDataAction(formattedProformaInvoices))
    yield put(
      setProformaInvoicesActionFilters({ filter: { startTime, endTime, filterType }, sortOption })
    )
    yield put(setOrdersLoaders({ loaderName: 'isFetchingProforma', status: false }))
    const { resetSelectedRowRef } = yield select((app: IAppState) => app.Orders)
    resetSelectedRowRef?.proformaInvoicesTable?.current?.resetRowSelection({})
    return
  }
  yield put(setOrdersDataAction(formattedOrders))
  yield put(
    setPendingOrdersActionFilters({ filter: { startTime, endTime, filterType }, sortOption })
  )
  yield put(setOrdersLoaders({ loaderName: 'isFetchingOrders', status: false }))
  const { resetSelectedRowRef } = yield select((app: IAppState) => app.Orders)
  resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
}

export function* fetchItemDetailsEffect(
  action: Action<{ item: OrdersRowData; isCreateProformaFromOrder: boolean }>
) {
  const { isCreateProformaFromOrder, item: selectedOrder } = action.payload
  const itemIds = selectedOrder.items.map((_) => {
    return _.itemId
  })
  const response: IApiResponse = yield call(listCatalogueItemsApi, {
    inventory_item_ids: itemIds
  })
  const { bills }: IBillingState = yield select((app: IAppState) => app.Billing)
  const { businessSettings } = yield select((app: IAppState) => app.Dashboard)

  const formattedItems = getFormattedItemDetails(
    response?.data.inv_items,
    selectedOrder.items,
    selectedOrder.customer,
    !!businessSettings?.offer?.apply_multiple
  ).filter((_) => !!_.quantity)

  yield put(setSelectedOrderAction({ order: selectedOrder, isCreateProformaFromOrder, bills }))
  yield put(setOrderItemsDataAction(formattedItems))
  if (isCreateProformaFromOrder) {
    yield put(setCreateProformaFromOrder(true))
  } else {
    yield put(setCreateBillFromOrders(true))
  }
  yield put(setActiveOrderEntity(ORDERS_ENTITY.INVOICE))
  Navigation().to(newPaths.createInvoiceOrder)
}

export function* updateMultipleOrdersEffect(action: Action<UpdateMultipleOrderPayload>) {
  const response: IApiResponse = yield call(updateMultipleOrdersApi, action.payload.updatePayload)
  const { resetSelectedRowRef, pendingOrdersFilter, sortOption }: IOrdersState = yield select(
    (app: IAppState) => app.Orders
  )
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setOrdersDrawer({ id: 'rejectMultipleOrders', value: false }))
    resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
    throw new Error('Problem while rejecting multiple orders, please try after sometime', {
      cause: 'customError'
    })
  }
  yield put(
    fetchOrdersAction({
      ...pendingOrdersFilter,
      statuses: action.payload.fetchStatuses,
      sortOption
    })
  )
  resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
  yield put(setOrdersDrawer({ id: 'rejectMultipleOrders', value: false }))
}

export function* updateOrdersEffect(action: Action<UpdateOrderPayload>) {
  const resultPayload: UpdateOrderApiPaylod = {
    ...action.payload,
    order: {
      id: action.payload?.order?.id,
      ...(action.payload?.order?.inventory_items
        ? { inventory_items: action.payload?.order?.inventory_items }
        : {}),
      ...(action.payload?.order?.status ? { status: action.payload?.order?.status } : {}),
      ...(action.payload?.order?.bills
        ? {
            bills: [
              {
                bill_id: action.payload?.order?.bills?.billId || ''
              }
            ]
          }
        : {})
    }
  }
  const response: IApiResponse = yield call(updateOrdersApi, resultPayload)
  const { resetSelectedRowRef, pendingOrdersFilter, sortOption }: IOrdersState = yield select(
    (app: IAppState) => app.Orders
  )
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setOrdersDrawer({ id: 'rejectOrder', value: false }))
    resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
    throw new Error('Problem while rejecting multiple orders, please try after sometime', {
      cause: 'customError'
    })
  }
  // TODO: handle getOrders post update
  yield put(
    fetchOrdersAction({
      ...pendingOrdersFilter,
      statuses: action.payload.fetchStatuses,
      sortOption
    })
  )
  resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
  yield put(setOrdersDrawer({ id: 'rejectOrder', value: false }))
  if (action?.payload?.order?.status === OrderStatus.APPROVED) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.SUCCESS,
        bodyText: 'Order Accepted Successfully!'
      })
    )
  }
  if (action?.payload?.order?.status === OrderStatus.INVOICED) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.SUCCESS,
        bodyText: 'Order Invoiced Successfully!'
      })
    )
    Navigation().replace(newPaths.ordersApproved)
  }
  if (action?.payload?.order?.status === OrderStatus.REJECTED) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.SUCCESS,
        bodyText: 'Orders Rejected Successfully!'
      })
    )
  }
  if (action?.payload?.order?.status === OrderStatus.PROFORMA_CREATED) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.SUCCESS,
        bodyText: 'Proforma Created Successfully!'
      })
    )
    Navigation().replace(newPaths.ordersApproved)
  }
}

export function* createOrderBillEffect(action: Action<CreateInvoicePayload>) {
  const {
    resetSelectedRowRef,
    selectedOrderDetails,
    customerDetails,
    addedItems,
    discountAmount,
    invoiceDetails,
    selectedDiscounts,
    selectedCharges,
    payableAmount,
    selectedItemIndices
  }: IOrdersState = yield select((app: IAppState) => app.Orders)
  const { merchantProfile: { data: { businessGst = '' } = {} } = {} } = yield select(
    (app: IAppState) => app.Dashboard
  )
  const customer = selectedOrderDetails?.customer

  const filteredItems = addedItems.filter((_, i) => selectedItemIndices.includes(i))

  if (filteredItems.length === 0) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.INFO,
        bodyText: 'Please select items to create Invoice'
      })
    )
    yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
    return
  }

  const data: IUploadAction & { proformaId?: string } = {
    customerId: selectedOrderDetails?.accountId || '',
    customerDetails: {
      customerName: customer?.customerName || '',
      customerAddress: customer?.customerAddress || '',
      customerMobile: customer?.customerMobile || '',
      customerGst: customer?.customerGST || '',
      customerStateSupplyCode: customer?.customerStateSupplyCode || ''
    },
    invoiceDetails: {
      invoiceNumber: invoiceDetails?.invoiceNumber || '',
      invoiceDate: convertDayToEpocCurrent(invoiceDetails.invoiceDate || 0),
      invoicePrefix: invoiceDetails?.invoicePrefix || ''
    },
    itemDetails: addedItems.filter((_, i) => selectedItemIndices.includes(i)),
    discountAmount,
    payableAmount,
    discounts: selectedDiscounts,
    charges: selectedCharges
  }

  const { proformaId, id: orderId, shouldPrintBill } = action.payload.order
  const requestPayload: IOrderBillRequestPayload = constructOrderBillRequestPayload(
    data,
    addedItems,
    businessGst
  )

  if (requestPayload.bill_info?.items.length === 0) {
    yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
    throw new Error('Items Cannot be empty!', {
      cause: 'customError'
    })
  }
  // TODO: Should uncomment this if order should be updated
  // Keeping it for now to avoid more changes if this is required.
  // const haveQuantityChanged = addedItems.filter((_) => {
  //   return (
  //     _.quantity + (_.invoicedQuantity || 0) + (_.proformaInvoicedQuantity || 0) >
  //     Number(
  //       selectedOrderDetails?.items.find((selItem) => selItem.itemId === _.inventoryItemId)
  //         ?.quantity || 0
  //     )
  //   )
  // })

  // if (haveQuantityChanged.length > 0) {
  //   yield put(
  //     updateOrdersAction({
  //       action: OrderApiActionEnum.items,
  //       order: {
  //         id: selectedOrderDetails?.id || '',
  //         inventory_items: requestPayload.bill_info?.items.map((_) => {
  //           const findItem = addedItems.find((addedItem) => addedItem.itemId === _.catalog_item_id)
  //           const updatedQuantity =
  //             _.quantity +
  //             (findItem?.invoicedQuantity || 0) +
  //             (findItem?.proformaInvoicedQuantity || 0)
  //           return {
  //             ..._,
  //             quantity: updatedQuantity
  //             // amount: (findItem?.netRate || 1) * updatedQuantity
  //           }
  //         })
  //       }
  //     })
  //   )
  // }

  const response: IApiResponse = yield call(createOrderBillApi, {
    bill: requestPayload,
    order_id: orderId,
    ...(proformaId && { proforma_id: proformaId })
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
    resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
    throw new Error('Problem while approving order, please try after sometime', {
      cause: 'customError'
    })
  }
  if (shouldPrintBill && !proformaId) {
    // Will handle after BE is done
    const printBillResponse: IApiResponse = yield call(printBills, [response?.data?.bill?.bill_id])
    if (printBillResponse.data?.bills.length) {
      const billsHtml: string = printBillResponse?.data?.bills.map((bill: any) => bill?.content)
      yield put(setPrintBillTemplate(billsHtml))
    }
  }

  if (proformaId) {
    yield put(setActiveOrderEntity(ORDERS_ENTITY.INVOICE))
  }

  resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
  yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: 'Invoice created successfully!'
    })
  )
  if (!shouldPrintBill) {
    Navigation().replace(proformaId ? newPaths.proformaInvoice : newPaths.ordersApproved)
  } else {
    yield delay(1000)
    Navigation().replace(proformaId ? newPaths.proformaInvoice : newPaths.ordersApproved)
  }
}

export function* createOrderProformaEffect(action: Action<UpdateOrderPayload>) {
  const {
    resetSelectedRowRef,
    selectedOrderDetails,
    customerDetails,
    addedItems,
    discountAmount,
    invoiceDetails,
    selectedDiscounts,
    selectedCharges,
    payableAmount,
    selectedItemIndices
  }: IOrdersState = yield select((app: IAppState) => app.Orders)
  const { merchantProfile: { data: { businessGst = '' } = {} } = {} } = yield select(
    (app: IAppState) => app.Dashboard
  )
  const customer = selectedOrderDetails?.customer

  const filteredItems = addedItems.filter((_, i) => selectedItemIndices.includes(i))

  if (filteredItems.length === 0) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.INFO,
        bodyText: 'Please select items to create Invoice'
      })
    )
    yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
    return
  }

  const data: IUploadAction = {
    customerId: selectedOrderDetails?.accountId || '',
    customerDetails: {
      customerName: customer?.customerName || '',
      customerAddress: customer?.customerAddress || '',
      customerMobile: customer?.customerMobile || '',
      customerGst: customer?.customerGST || '',
      customerStateSupplyCode: customer?.customerStateSupplyCode || ''
    },
    invoiceDetails: {
      invoiceNumber: invoiceDetails?.invoiceNumber || '',
      invoiceDate: convertDayToEpocCurrent(invoiceDetails.invoiceDate || 0),
      invoicePrefix: invoiceDetails?.invoicePrefix || ''
    },
    itemDetails: addedItems.filter((_, i) => selectedItemIndices.includes(i)),
    discountAmount,
    payableAmount,
    discounts: selectedDiscounts,
    charges: selectedCharges
  }
  const requestPayload: IOrderProformaRequestPayload = constructOrderProformaRequestPayload(
    data,
    addedItems,
    businessGst
  )
  if (addedItems.length === 0) {
    yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
    throw new Error('Items Cannot be empty!', {
      cause: 'customError'
    })
  }
  const response: IApiResponse = yield call(createOrderProformaApi, {
    proforma_invoice: requestPayload,
    order_id: action.payload.order.id
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
    resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
    throw new Error('Problem while creating proforma from order, please try after sometime', {
      cause: 'customError'
    })
  }
  if (action.payload.order.shouldPrintBill) {
    const printBillResponse: IApiResponse = yield call(printProformaApi, [
      response?.data?.proforma_invoice?.proforma_id
    ])
    if (printBillResponse.data?.proformas.length) {
      const proformaHtml: string = printBillResponse?.data?.proformas.map(
        (proforma: any) => proforma?.content
      )
      yield put(setPrintBillTemplate(proformaHtml))
    }
  }

  resetSelectedRowRef?.ordersPendingTable?.current?.resetRowSelection({})
  yield put(setOrdersLoaders({ loaderName: 'isCreatingBill', status: false }))
  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: 'Proforma created successfully!'
    })
  )
  if (!action.payload.order.shouldPrintBill) {
    Navigation().replace(newPaths.ordersApproved)
  }
}

export function* printProformaEffect(action: Action<string[]>) {
  const printProformaResponse: IApiResponse = yield call(printProformaApi, action.payload)
  if (printProformaResponse.data?.proformas.length) {
    const proformaHtml: string[] = printProformaResponse?.data?.proformas.map(
      (proforma: any) => proforma?.content
    )
    yield put(setProformaInvoicesHtmlAction(proformaHtml))
  }
}

export function* shareProformaEffect(action: Action<string[]>) {
  const shareProformaResponse: IApiResponse = yield call(printProformaApi, action.payload)
  if (shareProformaResponse.data?.proformas.length) {
    const pdfHtml: string[] = shareProformaResponse?.data?.proformas?.map(
      (proforma: any) => proforma?.content
    )
    yield put(setProformaInvoicesHtmlAction(pdfHtml))
  }
}

export function* downloadPickListEffect(
  action: Action<{
    inventoryItems: OrderItems[]
    orderCount?: number
  }>
) {
  const {
    merchantProfile: { data: merchantData }
  }: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const { inventoryItems, orderCount } = action.payload
  const itemId_vs_quantities = getItemQuantitiesFromItems(inventoryItems)
  const itemIds = Object.keys(itemId_vs_quantities)
  const response: IApiResponse<FETCH_INVENTORY_ITEMS_RESPONSE> = yield call(listCatalogueItemsApi, {
    inventory_item_ids: itemIds
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while fetching pick list, please try after sometime', {
      cause: 'customError'
    })
  }
  if (!response.data?.items?.length) {
    throw new Error('No Items Present in selected Order', {
      cause: 'customError'
    })
  }
  const data = getFormattedItemDetailsForDownload(response.data.items || [], itemId_vs_quantities)
  if (data.length) {
    const ws = utils.json_to_sheet(data, { origin: 'A6' })
    utils.sheet_add_aoa(
      ws,
      [
        ['Business Name', merchantData?.name],
        ['Business Address', merchantData?.address],
        ['GST No.', merchantData?.businessGst],
        ['Order Count', orderCount || 1],
        ...(data.length === 0 ? [[], [], ['No items found']] : [])
      ],
      { origin: 'A1' }
    )
    const wb = utils.book_new()
    ws['!cols'] = getMaxColumnWidths(data)
    utils.book_append_sheet(wb, ws, 'Sheet 1')
    writeFileXLSX(wb, 'Pick_List.xlsx')
    return
  }
  throw new Error('No Pending Items Present in this Order', {
    cause: 'customError'
  })
}

export function* downloadProformaPickListEffect(
  action: Action<{
    inventoryItems: ItemsDataType[]
    orderCount?: number
  }>
) {
  const {
    merchantProfile: { data: merchantData }
  }: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const { inventoryItems, orderCount } = action.payload
  const data = getFormattedDownloadDataProforma(inventoryItems)
  if (data.length) {
    const ws = utils.json_to_sheet(data, { origin: 'A6' })
    utils.sheet_add_aoa(
      ws,
      [
        ['Business Name', merchantData?.name],
        ['Business Address', merchantData?.address],
        ['GST No.', merchantData?.businessGst],
        ['Order Count', orderCount || 1],
        ...(data.length === 0 ? [[], [], ['No items found']] : [])
      ],
      { origin: 'A1' }
    )
    const wb = utils.book_new()
    ws['!cols'] = getMaxColumnWidths(data)
    utils.book_append_sheet(wb, ws, 'Sheet 1')
    writeFileXLSX(wb, 'Pick_List.xlsx')
    return
  }
}

export function* fetchItemsDetailsCatalogueEffects(action: Action<FetchMultipleCatalogueItems>) {
  yield put(setOrdersLoaders({ loaderName: 'isFetchingSelectedCatalogue', status: true }))
  yield put(setSelectedMultipleOrdersAction(action.payload))
  const selectedOrderCatalogueList = action.payload.items.flatMap((order) =>
    order.items.map((item) => item.itemId)
  )
  const response: IApiResponse = yield call(listCatalogueItemsApi, {
    inventory_item_ids: selectedOrderCatalogueList
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while fetching pick list, please try after sometime', {
      cause: 'customError'
    })
  }
  if (!response.data?.inv_items?.length) {
    throw new Error('No Items Present in selected Orders', {
      cause: 'customError'
    })
  }
  const { businessSettings } = yield select((app: IAppState) => app.Dashboard)
  const selectedCatalogues = action.payload.items.flatMap((order) => order.items)
  const formattedItems = getFormattedItemDetails(
    response?.data.inv_items,
    selectedCatalogues,
    action.payload.items?.[0].customer,
    !!businessSettings?.offer?.apply_multiple
  )
  yield put(setOrderItemsDataAction(formattedItems))
  yield put(setOrdersLoaders({ loaderName: 'isFetchingSelectedCatalogue', status: false }))
}

export function* createInvoiceFromProformaEffect(action: Action<ProformaRowData>) {
  const {
    bills,
    inventory: { inventoryItems }
  }: IBillingState = yield select((app: IAppState) => app.Billing)
  yield put(setActiveOrderEntity(ORDERS_ENTITY.PROFORMA_INVOICED))
  yield put(setSelectedOrderAction({ order: action.payload.orderDetails, bills }))
  yield put(setCreateBillFromOrders(true))
  // performa Items, should contain appliedOfferId & applicableOfferIds
  // applicableOfferIds -> inventory -> items
  // discount Amount -> applicableOfferIds -> appliedOfferId

  const newArray = action.payload.proformaItems.map((proformaItem) => {
    const itemDetailsFromInventory = inventoryItems.find(
      (item) => item.itemId === proformaItem.inventoryItemId
    )
    const offers = itemDetailsFromInventory?.offer
    let requiredOffer = undefined
    if (offers) {
      requiredOffer = offers.find((off) => {
        let offerAmount = 0
        const discountObject = off?.info?.discount_on_rate

        if (discountObject?.is_percent) {
          offerAmount = billingRoundFunction(
            convertRupeeToPaisa(proformaItem.rate || 0) *
              (convertPaisaToRupee((discountObject as any).basis_pts) / 100)
          )
        } else {
          offerAmount = discountObject?.amount || 0
        }

        return convertPaisaToRupee(offerAmount) === proformaItem.discount
      })
    }

    return {
      ...proformaItem,
      applicableOfferDetails: itemDetailsFromInventory?.offer,
      appliedOfferId: requiredOffer?.id || ''
    }
  })
  yield put(setOrderItemsDataAction(newArray as NewItemDetailEntity[]))
  yield put(setOrderSelectedDiscountsAction(action.payload.selectedDiscounts))
  yield put(setOrderSelectedChargesAction(action.payload.selectedCharges))
  yield put(setCreateBillFromProforma(true))
  Navigation().to(newPaths.createInvoiceOrder)
}

export function* createOrderProformaSettlementEffect(action: Action<SettlementPayload>) {
  const { selectedProformaInvoices }: IOrdersState = yield select((app: IAppState) => app.Orders)
  const requestPayload = {
    proforma_id: action.payload.proformaId,
    settlement_info: {
      ...(action.payload?.chequeNumber ? { cheque_number: action.payload?.chequeNumber } : {}),
      ...(action.payload?.chequeDate ? { cheque_date: action.payload?.chequeDate } : {})
    },
    settlement_type: TransactionTypeEnumMapper[`${action.payload.settlementType}`],
    amount: action.payload?.amount,
    settlement_date: action.payload?.settlementDate,
    ...(action.payload?.chequeNumber ? { cheque_number: action.payload?.chequeNumber } : {})
  }
  const response: IApiResponse = yield call(createOrderProformaSettlementApi, requestPayload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while Adding the transaction! Try Again.', {
      cause: 'customError'
    })
  }
  yield put(setOrdersDrawer({ id: 'orderCollectionDrawer', value: false }))
  yield put(createInvoiceFromProformaAction(selectedProformaInvoices[0]))
}

export function* updateProformaStatusEffect(action: Action<UpdateProformaStatusPayload[]>) {
  const { proformaOrdersFilter, sortOption }: IOrdersState = yield select(
    (app: IAppState) => app.Orders
  )
  const apiCalls = action.payload.map((item) => {
    const requestPayload = {
      proforma_id: item.proformaId,
      status: item.status,
      bill_id: item.billId
    }
    return call(updateProformaStatusApi, requestPayload)
  })
  const responses: IApiResponse[] = yield all(apiCalls)

  // if (response.type === API_RESPONSE_TYPE.FAILURE) {
  //   throw new Error('Problem while deleting the proforma! Try Again.', {
  //     cause: 'customError'
  //   })
  // }
  yield put(setOrdersDrawer({ id: 'deleteProforma', value: false }))
  yield put(setOrdersDrawer({ id: 'deleteMultipleProformas', value: false }))

  yield put(
    fetchOrdersAction({
      ...proformaOrdersFilter,
      sortOption,
      statuses: [OrderStatus.PROFORMA_CREATED]
    })
  )
}

export function* printOrdersEffect(action: Action<string[]>) {
  const { pendingOrdersRowData, approvalOrdersRowData }: IOrdersState = yield select(
    (app: IAppState) => app.Orders
  )
  const {
    merchantProfile: { data: merchantData }
  }: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const selectedOrderDetails = [...pendingOrdersRowData, ...approvalOrdersRowData].filter((order) =>
    action.payload.includes(order.id)
  )
  const selectedOrderCatalogueList = selectedOrderDetails.flatMap((order) =>
    order.items.map((item) => item.itemId)
  )
  const response: IApiResponse = yield call(listCatalogueItemsApi, {
    inventory_item_ids: selectedOrderCatalogueList
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while fetching pick list, please try after sometime', {
      cause: 'customError'
    })
  }
  if (!response.data?.items?.length) {
    throw new Error('No Items Present in selected Orders', {
      cause: 'customError'
    })
  }
  const allItems = formatInventoryItemsDataV2Extended(response.data.inv_items || [])
  const { businessSettings } = yield select((app: IAppState) => app.Dashboard)
  const billReq = selectedOrderDetails.map((selectedOrder) => {
    const formattedItems = getFormattedItemDetails(
      response?.data.inv_items,
      selectedOrder?.items,
      undefined,
      !!businessSettings?.offer?.apply_multiple
    ).filter((_) => _.quantity !== 0)
    const { customerName, customerAddress, customerMobile, customerGST } = selectedOrder?.customer
    const data: IUploadAction = {
      customerId: selectedOrder?.accountId || '',
      customerDetails: {
        customerName,
        customerAddress,
        customerMobile,
        customerGst: customerGST
      },
      invoiceDetails: {
        invoiceNumber: selectedOrder?.orderNumber,
        invoiceDate: selectedOrder.orderDate,
        invoicePrefix: ''
      },
      itemDetails: formattedItems,
      discountAmount: 0,
      payableAmount: formattedItems.reduce((prev, curr) => prev + curr.amountPayable, 0),
      discounts: [],
      charges: []
    }
    const isIGST = false
    return {
      ...constructRequestPayload(data, allItems, merchantData?.businessGst, !!isIGST),
      src: 9 as any
    }
  })
  const payloadForOrderPrint: IPreviewOrderRequestPayload = {
    use_default_template: true,
    use_placeholder_qr: true,
    bill_reqs: billReq
  }
  const printOrderResponse: IApiResponse = yield call(printOrdersApi, payloadForOrderPrint)
  if (printOrderResponse.data?.orders.length) {
    const ordersHtml: string[] = printOrderResponse?.data?.orders.map(
      (order: any) => order?.content
    )
    yield put(setOrdersHtmlAction(ordersHtml))
  }
}

export function* fetchSelectedItemsDetailsEffects(
  action: Action<{
    itemIds: string[]
    orderItem?: OrdersRowData
    proformaItem?: ProformaRowData
  }>
) {
  const { itemIds, proformaItem } = action.payload
  yield put(setOrdersLoaders({ loaderName: 'isFetchingSelectedCatalogue', status: true }))
  yield put(
    setSelectedMultipleOrdersAction({
      items: [action.payload.proformaItem as any],
      source: 'proforma'
    })
  )
  const response: IApiResponse = yield call(listCatalogueItemsApi, {
    inventory_item_ids: itemIds
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while fetching pick list, please try after sometime', {
      cause: 'customError'
    })
  }
  if (!response.data?.inv_items?.length) {
    throw new Error('No Items Present in selected Orders', {
      cause: 'customError'
    })
  }
  const { businessSettings } = yield select((app: IAppState) => app.Dashboard)
  const formattedItems = getFormattedItemDetails(
    response?.data.inv_items,
    proformaItem?.proformaItems as any,
    proformaItem?.orderDetails.customer,
    !!businessSettings?.offer?.apply_multiple
  )
  yield put(setOrderItemsDataAction(formattedItems))
  yield put(setOrdersLoaders({ loaderName: 'isFetchingSelectedCatalogue', status: false }))
}
