import { takeCustomLatest } from 'state/common/common.effects'
import {
  ApprovedCollectionSummaryActions,
  setLoaderAction
} from './approvedCollectionSummary.actions'
import {
  downloadApprovedCollectionsSummaryEffect,
  getApprovedCollectionGroupSummaryEffect,
  getApprovedCollectionsSummaryEffect
} from './approvedCollectionSummary.effects'

function* getApprovedCollectionsSummarySaga() {
  yield takeCustomLatest(
    ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTIONS_SUMMARY,
    getApprovedCollectionsSummaryEffect,
    () => setLoaderAction({ id: 'listSummary', value: false })
  )
}

function* getApprovedCollectionGroupSummarySaga() {
  yield takeCustomLatest(
    ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTION_GROUP_SUMMARY,
    getApprovedCollectionGroupSummaryEffect,
    () => setLoaderAction({ id: 'listSummary', value: false })
  )
}

function* downloadApprovedCollectionsSummarySaga() {
  yield takeCustomLatest(
    ApprovedCollectionSummaryActions.DOWNLOAD_APPROVED_COLLECTIONS_SUMMARY,
    downloadApprovedCollectionsSummaryEffect
  )
}

export const ApprovedCollectionSummaryWatchers = [
  getApprovedCollectionsSummarySaga(),
  getApprovedCollectionGroupSummarySaga(),
  downloadApprovedCollectionsSummarySaga()
]
