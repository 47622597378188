import ApiService from 'services/Api/ApiService'
import { getEnvConfig } from 'utils/getEnvConfig'
import {
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor
} from 'services/Api/apiInterceptor'
import {
  CL_VERSIONS,
  ICatalogItem,
  IReplacementsItem
} from 'state/collectionList/collectionList.types'

import { IOrderBillRequestPayload } from 'services/Api/StaffLinkApiFolder/type'
import {
  IParams,
  IRegisterAutoDebitLinkReq,
  IScheduleAutoDebitLinkReq,
  IUpdateCashCollectionApi,
  ICollectionSummaryRequest,
  INewCashCollectionReq,
  IDeleteTransectionApi,
  ICancelInvoiceApi,
  IUndoCancelInvoiceApi,
  INewReplacementRecordReq,
  IUpdateMultipleTxnApi,
  IReturnablePayloadType,
  CreateOrderPayloadType
} from './StaffLink.types'

import { apiRequestInterceptor } from './StaffLink.helpers'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

/*
 * If origin is localhost, make request to original api else, make request to proxied api
 */
let base_url: string
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    base_url = getEnvConfig('PAYMENT_STATUS_BASE_URL')
  } else {
    base_url = `${window.location.origin}/api/collection`
  }
}

let staffLinkProxy: string = ''
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    staffLinkProxy = getEnvConfig('STAFF_LINK_BASE_URL')
  } else {
    staffLinkProxy = `${window.location.origin}/api/staff-link`
  }
}

let accountsProxy: string = ''
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    accountsProxy = getEnvConfig('MERCHANT_ENDPOINT')
  } else {
    accountsProxy = `${window.location.origin}/api/account`
  }
}

let payments_base_url: string
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    payments_base_url = getEnvConfig('MERCHANT_UPI_BASE_URL')
  } else {
    payments_base_url = `${window.location.origin}/api/payment2/v1`
  }
}

const StaffLinkProxyApi = new ApiService(staffLinkProxy, headers)
StaffLinkProxyApi.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))

const AccountsProxyApi = new ApiService(accountsProxy, headers)
AccountsProxyApi.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))

const CollectionsApi = () => new ApiService(base_url, headers)
const AbProfileApi = () => new ApiService(getEnvConfig('AB_ENDPOINT'), headers)
const PaymentsApi = () => new ApiService(payments_base_url, headers)

const CollectionAuthAPI = CollectionsApi()
CollectionAuthAPI.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
CollectionAuthAPI.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

const PaymentsAuthAPI = PaymentsApi()
PaymentsAuthAPI.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
PaymentsAuthAPI.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const fetchMerchantProfileReq = (businessId: string): Promise<any> => {
  return AbProfileApi().postRequest('/v1/GetProfile', JSON.stringify({ merchantId: businessId }))
}

export const addCustomerByStaff = (payload: {
  collection_list_id: string
  business_id: string
  customer_name: string
}) => {
  return StaffLinkProxyApi.postRequest('/staff/v1/AddCustomer', payload)
}

/*
 * @method  POST
 * @desc    Load StaffLink details API call. Primary call on staff/{businessId}/{staffLinkId:} page load.
 * @access  Public
 */
export const fetchStaffLinkDetails = (businessId: string, staffLinkId: string): Promise<any> => {
  // To do
  // If transaction is added with type cash, Cheque, NEFT then to get the data new api has to be called that is /GetCollectionListDetails
  return StaffLinkProxyApi.postRequest(
    '/v1/staff/GetCollectionListDetails?page=1&limit=1000',
    JSON.stringify({ collection_list_id: staffLinkId, business_id: businessId })
  )
}

/*
 * @method  POST
 * @desc    Load Merchants customer data, with phone no and customerId
 * @access  Public
 */
export const fetchMerchatCustomerUsingPhoneNo = (payload: {
  businessId: string
  phoneNumber: string
}): Promise<any> => {
  return StaffLinkProxyApi.postRequest(
    '/v1/GetCustomerDetails',
    JSON.stringify({ business_id: payload.businessId, customer_mobile: payload.phoneNumber })
  )
}

export const fetchAccountAssetsDeposit = (business_id: string): Promise<any> => {
  return StaffLinkProxyApi.postRequest(
    '/bookkeeping/v1/AssetAccounts',
    JSON.stringify({ business_id })
  )
}

export const getPendingActinsBankDeposits = (payload: {
  business_id: string
  staff_id: string
}) => {
  return StaffLinkProxyApi.postRequest(
    '/bookkeeping/v1/PendingStaffBankDeposits',
    JSON.stringify(payload)
  )
}
export const getPendingActinsCashDeposits = (payload: {
  business_id: string
  staff_id: string
}) => {
  return StaffLinkProxyApi.postRequest(
    '/bookkeeping/v1/PendingStaffCashDeposits',
    JSON.stringify(payload)
  )
}

export const initiateBankDeposit = (payload: {
  business_id: string
  bank_account_id: string
  source_type: string
  source_account_id: string
  amount: number
  depositor_type: string
  depositor_name: string
  depositor_id: string
  staff_id: string
  receipt_url: string
}): Promise<any> => {
  return StaffLinkProxyApi.postRequest(
    '/bookkeeping/v1/InitiateBankDeposit',
    JSON.stringify(payload)
  )
}

export const initiateCashDeposit = (payload: {
  business_id: string
  staff_id: string
  amount: number
}): Promise<any> => {
  return StaffLinkProxyApi.postRequest(
    '/bookkeeping/v1/InitiateCashDeposit',
    JSON.stringify(payload)
  )
}

/*
 * @method  POST
 * @desc    Fetch payment status PROCESSING, SUCCESS
 * @access  Public
 *
 * If res has data, it means the payment is SUCCESS.
 * If there is 500 or { "collections": [] } in res, it means the payment is still PROCESSING.
 * Note: There is no failed status from backend as of now.
 * Original API(without Backend Proxy) base URL: https://collection.staging.okcredit.io/v1/ListCustomerCollectionsInternal
 */
export const fetchPaymentStatus = (params: IParams): Promise<any> => {
  const { accountId, businessId, timestamp } = params
  return CollectionsApi().postRequest(
    '/v1/ListCustomerCollectionsInternal',
    JSON.stringify({
      customer_id: accountId,
      business_id: businessId,
      start_time: timestamp // timestamp of last few hours
    })
  )
}

export const registerAutoDebitLinkReq = (params: IRegisterAutoDebitLinkReq): Promise<any> => {
  const { merchantId, accountId, date, amount } = params
  return CollectionsApi().postRequest(
    '/v2/GetAutoDebitRegistrationLink',
    JSON.stringify({
      amount,
      customer_id: accountId,
      business_id: merchantId,
      date // timestamp of last few hours
    })
  )
}

export const scheduleAutoDebitLinkReq = (params: IScheduleAutoDebitLinkReq): Promise<any> => {
  const { merchantId, accountId, date, amount, frequency } = params
  return CollectionsApi().postRequest(
    '/v2/ScheduleAutoDebit',
    JSON.stringify({
      amount,
      customer_id: accountId,
      business_id: merchantId,
      frequency,
      date
    })
  )
}

export const updateCashCollectionReq = (params: IUpdateCashCollectionApi): Promise<any> => {
  const { amount, cashTxnId, merchantId } = params
  const body = {
    transaction: {
      id: cashTxnId,
      amount,
      business_id: merchantId
    }
  }
  return StaffLinkProxyApi.postRequest('/v1/staff/UpdateTransaction', body)
}

export const updateMultipleTxnReq = (payload: IUpdateMultipleTxnApi[]): Promise<any> => {
  const body = {
    transactions: payload
  }
  return StaffLinkProxyApi.putRequest('/v1/staff/UpdateMultipleTransactions', body)
}

export const deleteTransectionReq = (params: IDeleteTransectionApi): Promise<any> => {
  const { txnId, merchantId } = params
  const body = {
    transaction: {
      id: txnId,
      business_id: merchantId,
      is_deleted: true
    }
  }
  return StaffLinkProxyApi.postRequest('/v1/staff/UpdateTransaction', body)
}

export const cancelInvoiceReq = (params: ICancelInvoiceApi): Promise<any> => {
  const { accountId, merchantId, billId, linkId } = params
  const body = {
    collection_list_id: linkId,
    business_id: merchantId,
    associated_account_id: accountId,
    bill_id: billId,
    cancel_requested: true
  }
  return StaffLinkProxyApi.postRequest('/v1/staff/bills/cancel/request', body)
}

export const undoCancelInvoiceReq = (params: IUndoCancelInvoiceApi): Promise<any> => {
  const { billId, merchantId } = params
  const body = {
    bill_id: billId,
    business_id: merchantId,
    request_deleted: true
  }
  return StaffLinkProxyApi.postRequest('/v1/staff/bills/cancel/request', body)
}

export const fetchCollectionSummary = (params: ICollectionSummaryRequest): Promise<any> => {
  const { merchantId, linkId } = params
  return StaffLinkProxyApi.postRequest(
    '/v1/staff/GetCollectionSummary',
    JSON.stringify({
      business_id: merchantId,
      collection_list_id: linkId
    })
  )
}

export const fetchCollectionSummaryV2 = (params: ICollectionSummaryRequest): Promise<any> => {
  const { merchantId, linkId, version } = params
  return StaffLinkProxyApi.postRequest(
    '/v2/staff/GetCollectionSummary',
    JSON.stringify({
      business_id: merchantId,
      collection_list_id: linkId,
      version
    })
  )
}

export const createNewTransactionReq = (params: INewCashCollectionReq): Promise<any> => {
  const {
    amount,
    associated_account_id,
    business_id,
    collection_list_id,
    notes,
    txn_type,
    is_bill_settled,
    settlement_bill_ids
  } = params

  const body = {
    transaction: {
      business_id,
      collection_list_id,
      associated_account_id,
      txn_type,
      notes,
      amount,
      is_bill_settled,
      settlement_bill_ids
    }
  }
  return StaffLinkProxyApi.postRequest('/v1/staff/CreateTransaction', JSON.stringify(body))
}

export const createNewMultipleTransactionReq = (params: INewCashCollectionReq[]): Promise<any> => {
  const body = {
    transactions: params
  }
  return StaffLinkProxyApi.postRequest('/v1/staff/CreateMultipleTransactions', JSON.stringify(body))
}

export const createNewMultipleTransactionV2Req = (
  params: INewCashCollectionReq[]
): Promise<any> => {
  const body = {
    transactions: params
  }
  return StaffLinkProxyApi.postRequest('/staff/v2/CreateMultipleTransactions', JSON.stringify(body))
}

export const createReplacementRecordReq = (params: INewReplacementRecordReq): Promise<any> => {
  const { amount, accountId, merchantId, replacementItems, catalogItems, billId } = params

  const body = {
    replacement: {
      account_id: accountId,
      business_id: merchantId,
      items: replacementItems.map((itm) => ({
        name: itm.name,
        quantity: Number(itm.quantity)
      })),
      ...(catalogItems && {
        catalog_items: catalogItems.map((itm) => ({
          ...itm,
          quantity: Number(itm.quantity)
        }))
      }),
      amount,
      bill_id: billId || ''
    }
  }
  return StaffLinkProxyApi.postRequest('/v1/staff/replacements', JSON.stringify(body))
}

export const getBillQRIntent = (payload: {
  bills?: string[]
  accountId?: string
  merchantId: string
  staffId: string
  staffLinkId: string
  isOnDemandCollection?: boolean
}) => {
  const { bills, accountId, merchantId, staffLinkId, staffId, isOnDemandCollection } = payload
  return CollectionAuthAPI.postRequest('v1/InitiateBillTransaction', {
    mode: 'QR_INTENT',
    payment_method: 'upi',
    bills: bills,
    ...(!isOnDemandCollection ? { account_id: accountId } : {}),
    merchant_id: merchantId,
    labels: {
      reference_id: staffLinkId,
      reference_type: 'collection_list',
      staff_id: staffId,
      ...(isOnDemandCollection ? { account_id: accountId } : {})
    }
  })
}

export const getBillPaymentLink = (payload: {
  bills: string[]
  accountId: string
  merchantId: string
  staffId: string
  staffLinkId: string
}) => {
  return CollectionAuthAPI.postRequest('v1/GetBillPaymentLink', {
    bills: payload.bills,
    account_id: payload.accountId,
    merchant_id: payload.merchantId,
    labels: {
      reference_id: payload.staffLinkId,
      reference_type: 'collection_list',
      staff_id: payload.staffId
    }
  })
}

export const fetchBillPaymentStatus = (payload: { transactionId: string }) => {
  return PaymentsAuthAPI.getRequest(`/payment/${payload.transactionId}`, {})
}

export const updateReplacementRecordReq = (data: {
  replacementId: string
  merchantId: string
  accountId: string
  status: number
  amount: string
  billId: string
  staffLinkId: string
  replacementItems: IReplacementsItem[]
  catalogItems: ICatalogItem[]
}) => {
  return StaffLinkProxyApi.putRequest('/v1/staff/replacements', {
    replacement: {
      id: data.replacementId,
      business_id: data.merchantId,
      account_id: data.accountId,
      bill_id: data.billId,
      amount: Number(data.amount),
      status: data.status,
      items: data.replacementItems.map((itm) => ({
        name: itm.name,
        quantity: Number(itm.quantity)
      })),
      ...(data.catalogItems && {
        catalog_items: data.catalogItems.map((itm) => ({
          ...itm,
          quantity: Number(itm.quantity)
        }))
      })
    }
  })
}

export const fetchStaffDetails = (merchantId: string) => {
  return StaffLinkProxyApi.postRequest('/v1/staff/GetOrCreateStaff', {
    business_id: merchantId
  })
}

export const updateStaffDetails = (payload: { merchantId: string; name: string }) => {
  return StaffLinkProxyApi.putRequest('/v1/staff/UpdateStaff', {
    staff: {
      business_id: payload.merchantId,
      name: payload.name
    }
  })
}

export const fetchStaffLinkBillDetails = (
  businessId: string,
  staffLinkId: string,
  version: CL_VERSIONS
): Promise<any> => {
  return StaffLinkProxyApi.postRequest(
    '/v2/staff/GetCollectionListBillDetails?page=1&limit=1000',
    JSON.stringify({ collection_list_id: staffLinkId, business_id: businessId, version })
  )
}

export const fetchStaffLinkShortDetail = (
  businessId: string,
  staffLinkId: string
): Promise<any> => {
  return StaffLinkProxyApi.postRequest(
    '/v2/staff/GetCollectionList',
    JSON.stringify({ collection_list_id: staffLinkId, business_id: businessId })
  )
}

export const fetchWalletDetailsReq = (accountId: string, merchantId: string): Promise<any> => {
  return StaffLinkProxyApi.getRequest(
    `/v1/GetWalletDetails?account_id=${accountId}&business_id=${merchantId}`,
    {}
  )
}

export const fetchInventoryItemsApi = (searchQuery: string, merchantId: string): Promise<any> => {
  return StaffLinkProxyApi.getRequest(
    `/v1/staff/catalog/search?bid=${merchantId}&q=${searchQuery}`,
    {}
  )
}

export const recordDeliveryFailureApi = (data: {
  businessId: string
  billId: string
  listId: string
}): Promise<any> => {
  return StaffLinkProxyApi.postRequest('/v3/staff/RecordDeliveryFailure', {
    business_id: data.businessId,
    bill_id: data.billId,
    list_id: data.listId
  })
}

export const retractDeliveryFailureApi = (data: {
  businessId: string
  billId: string
  listId: string
}): Promise<any> => {
  return StaffLinkProxyApi.postRequest('/v3/staff/RetractDeliveryFailure', {
    business_id: data.businessId,
    bill_id: data.billId,
    list_id: data.listId
  })
}

export const getBillReturnables = (payload: IReturnablePayloadType) => {
  return StaffLinkProxyApi.postRequest('v1/staff/bill/returnables', {
    bill_id: payload.billId,
    business_id: payload.businessId
  })
}

export const setStaffName = (name: string) => {
  return AccountsProxyApi.patchRequest('/one', {
    name,
    update_name: true
  })
}

export const fetchBusinessAccounts = ({
  businessId,
  list_id
}: {
  businessId: string
  list_id: string
}): Promise<any> => {
  return StaffLinkProxyApi.postRequest('/web/orders/staff/v1/ListBusinessAccounts', {
    business_id: businessId,
    list_id
  })
}

export const fetchCatalogItems = (businessId: string): Promise<any> => {
  return StaffLinkProxyApi.postRequest('/web/orders/staff/v1/ListInventoryItems', {
    business_id: businessId
  })
}

export const createNewOrder = (payload: CreateOrderPayloadType): Promise<any> => {
  return StaffLinkProxyApi.postRequest('/web/orders/staff/v1/CreateOrder', {
    order: payload
  })
}

export const getStaffOrdersApi = (
  payload: Pick<CreateOrderPayloadType, 'customerId' | 'businessId'>
): Promise<any> => {
  const reqPayload = {
    business_id: payload.businessId,
    account_id: payload.customerId,
    start_time: undefined,
    end_time: undefined
  }
  return StaffLinkProxyApi.postRequest('/web/orders/staff/v1/GetOrders', reqPayload)
}

export const updateStaffOrderApi = (payload: any): Promise<any> => {
  return StaffLinkProxyApi.putRequest('/web/orders/staff/v1/UpdateOrder', payload)
}

export const staffCreateProformaSettlementApi = (payload: any): Promise<any> => {
  return StaffLinkProxyApi.postRequest(
    '/web/orders/staff/v1/CreateProformaInvoiceSettlement',
    payload
  )
}

export const createNewInvoiceApi = (payload: IOrderBillRequestPayload): Promise<any> => {
  return StaffLinkProxyApi.postRequest('/staff/v1/CreateBill', payload)
}

export const getStaffBusinessUser = async (businessId: any) => {
  return StaffLinkProxyApi.postRequest('/v1/staff/GetBusinessUser', { business_id: businessId })
}

export const fetchAccountInvoicesAPI = (accountId: string, businessId: string): Promise<any> => {
  return StaffLinkProxyApi.postRequest('/staff/v1/GetPendingBillsByAccount', {
    account_id: accountId,
    business_id: businessId
  })
}
