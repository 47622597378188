import { getEnvConfig } from 'utils/getEnvConfig'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const api = new ApiService(getEnvConfig('AUTH_API_ENDPOINT'), headers)
const AUTH_LOGOUT_ALL = '/logout'

api.requestInterceptor(apiRequestInterceptor, (error: any) => {
  console.error(error)
})
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

const logoutFromAll = (merchantId: string) => {
  const data = {
    merchant_id: merchantId,
    type: 0
  }
  return api.postRequest(AUTH_LOGOUT_ALL, data)
}

export const AuthApiWithToken = {
  logoutFromAll
}
