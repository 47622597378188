import { put, call, select } from '@redux-saga/core/effects'
import { utils, writeFileXLSX } from 'xlsx'
import dayjs from 'dayjs'
import {
  downloadPendingDueForGroupId,
  fetchAllPendingDueInvoices,
  fetchPendingDueForGroupId,
  fetchPendingDueForOverallInnerGroup,
  fetchPendingDueGroups,
  fetchPendingDueOverallGroups
} from 'services/Api/pendingDueApi'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import { updateGroupNameApi } from 'services/Api/StafflinkApi'
import { IAppState } from 'infra/AppState'
import {
  IPendingDueOverallSummaryRequestPayload,
  IPenddingDueSummaryRequestPayload,
  IPendingDueGroupElement,
  IPendingDueGroupIdDataRequestPayload,
  IPendingDueOverallGroupInnerDataRequestPayload,
  IPendingDueSummary,
  detailedDueSortBy
} from './pendingDueSummary.types'
import {
  setEditNameLoader,
  setModal,
  setPendingDueFilter,
  setPendingDueForOverallGroup,
  setPendingDueGroupData,
  setPendingDueGroupId,
  setPendingDueOverallGroupData,
  setPendingSummaryLoader,
  setUpdatedGroupName
} from './pendingDueSummary.actions'
import { API_RESPONSE_TYPE, IApiResponse } from './../../constants'
import {
  computeTotalPendingDue,
  convertResponseToExcelFormat,
  convertResponseToGroupWiseFormat,
  convertResponseToStoreFormat,
  convertResponseToWalletSheetFormat,
  getSortedBills,
  transformResponse,
  transformSingleResponse
} from './pendingDueSummary.helpers'

export function* fetchPendingDueSummaryEffect(action: {
  type: string
  payload: {
    filter: number
    pageSize: number
    pageNumber: number
    sortBy: 'group_name' | 'invoice_count' | 'pending_due'
    sortDescending?: boolean
  }
}) {
  yield put(
    setPendingSummaryLoader({
      isPendingDueSummaryLoading: true
    })
  )
  const { groupDuePageSize } = yield select((app: IAppState) => app.NewSummary.PendingDueSummary)
  const { filter, pageNumber, sortBy, sortDescending = false } = action.payload
  const requestPayload: IPenddingDueSummaryRequestPayload = {
    end_time: filter,
    page_no: pageNumber,
    page_size: groupDuePageSize,
    ...(sortBy && { sort_by: sortBy, sort_descending: !!sortDescending }),
    ...(filter === dayjs().endOf('day').unix() && { include_total_wallet_balance: true })
  }
  const response: IApiResponse = yield call(fetchPendingDueGroups, requestPayload)
  const groupDueCurrentPage = pageNumber
  const groupDuetotalEntries = response.data?.total_count || 50
  const responsePendingDueGroupData: IPendingDueGroupElement[] = transformResponse(response.data)
  yield put(
    setPendingDueGroupData({
      pendingDueGroupData: responsePendingDueGroupData,
      groupDueCurrentPage,
      groupDuetotalEntries
    })
  )
  yield put(
    setPendingSummaryLoader({
      isPendingDueSummaryLoading: false
    })
  )
  yield put(setPendingDueFilter({ filter }))
}

export function* fetchPendingDueGroupIdEffect(action: {
  payload: {
    groupId: string
    filter: number
    pageNumber: number
    sortBy: detailedDueSortBy
    pending_days_gte?: number
    pending_days_lte?: number
    search_string?: string
    bills_count_gt?: number
  }
  type: string
}) {
  const {
    groupId,
    filter,
    pageNumber,
    sortBy,
    pending_days_gte,
    pending_days_lte,
    search_string,
    bills_count_gt
  } = action.payload

  yield put(
    setPendingSummaryLoader({
      isPendingDueInnerLoading: true
    })
  )

  const { detailedDuePageSize } = yield select((app: IAppState) => app.NewSummary.PendingDueSummary)

  const requestPayload: IPendingDueGroupIdDataRequestPayload = {
    group_id: groupId,
    end_time: filter,
    ...(pending_days_gte && pending_days_gte !== -1 && { pending_days_gte }),
    ...(pending_days_lte && pending_days_lte !== -1 && { pending_days_lte }),
    page_no: pageNumber,
    page_size: detailedDuePageSize,
    ...(search_string && { search_string }),
    ...(sortBy && { sort_by: sortBy }),
    ...(filter === dayjs().endOf('day').unix() && { include_total_wallet_balance: true }),
    ...((bills_count_gt || bills_count_gt === 0) && bills_count_gt !== -1 && { bills_count_gt })
  }

  const response: IApiResponse = yield call(fetchPendingDueForGroupId, requestPayload)
  const selectedGroup = {
    invoiceCount: response.data.group.invoice_count,
    pendingDue: response.data.group.pending_due,
    detailedDueGroupName: response.data.group.group_name,
    ...(response.data.group.total_wallet_balance && {
      walletbalance: response.data.group.total_wallet_balance
    })
  }

  const convertedData = convertResponseToStoreFormat(response.data.bills)
  yield put(
    setPendingDueGroupId({
      filter,
      pendingDueGroupIdData: convertedData,
      detailedDueSortBy: sortBy,
      detailedDueTotalInvoiceCount: selectedGroup!.invoiceCount,
      detailedDueTotalPendingDue: selectedGroup!.pendingDue / 100,
      detailedDueGroupName: selectedGroup.detailedDueGroupName,
      ...(selectedGroup?.walletbalance && {
        detailedDueWalletBalance: selectedGroup.walletbalance / 100
      }),
      ...((pending_days_gte || pending_days_gte === 0) &&
        pending_days_lte && {
          pendingDueRangeFilter: {
            lte: pending_days_lte,
            gte: pending_days_gte
          }
        }),
      detailedDueCurrentPage: pageNumber,
      detailedDuetotalEntries: Number(selectedGroup!.invoiceCount),
      ...((bills_count_gt || bills_count_gt === 0) && bills_count_gt !== -1
        ? { billsCountGt: bills_count_gt }
        : { billsCountGt: -1 })
    })
  )
  yield put(
    setPendingSummaryLoader({
      isPendingDueInnerLoading: false
    })
  )
}

export function* downloadPendingDueGroupIdEffect(action: {
  payload: {
    groupId: string
    filter: number
    pageSize: number
    pageNumber: number
    sortBy: detailedDueSortBy
    pending_days_gte?: number
    pending_days_lte?: number
    fileName: string
  }
  type: string
}) {
  const {
    groupId,
    filter,
    // pageSize,
    // pageNumber,
    sortBy,
    // pending_days_gte,
    // pending_days_lte,
    fileName
  } = action.payload

  const requestPayload: IPendingDueGroupIdDataRequestPayload = {
    group_id: groupId,
    end_time: filter,
    // ...(pending_days_gte && { pending_days_gte: pending_days_gte }),
    // ...(pending_days_lte && { pending_days_lte: pending_days_lte }),
    page_no: 1,
    page_size: 99999,
    ...(sortBy && { sort_by: sortBy }),
    ...(filter === dayjs().endOf('day').unix() && { include_total_wallet_balance: true })
  }

  const response: IApiResponse = yield call(downloadPendingDueForGroupId, requestPayload)
  const sortedBills = getSortedBills(response.data.bills)

  const data = convertResponseToExcelFormat(sortedBills)
  const ws = utils.json_to_sheet(data, { origin: 'A10' })
  const wb = utils.book_new()
  const excelHeading = `Pending Due Group Summary (${dayjs.unix(filter).format('DD MMM, YYYY')})`

  utils.sheet_add_aoa(
    ws,
    [[excelHeading], [], [`Printed On: ${dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')}`]],
    {
      origin: 'A1'
    }
  )

  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  if (response.data.accounts?.length > 0) {
    const walletBalance = convertResponseToWalletSheetFormat(response.data.accounts)
    if (walletBalance.length > 0) {
      const walletBalanceSheet = utils.json_to_sheet(walletBalance, { origin: 'A10' })
      utils.sheet_add_aoa(
        walletBalanceSheet,
        [[`Opening Wallet Balance Summary (${dayjs().format('DD MMM, YYYY')})`]],
        {
          origin: 'A1'
        }
      )
      if (data.length) {
        walletBalanceSheet['!cols'] = getMaxColumnWidths(walletBalance)
      }
      utils.book_append_sheet(wb, walletBalanceSheet, 'Opening Wallet Balance')
    }
  }

  writeFileXLSX(wb, `${fileName}.xlsx`)

  yield put(
    setModal({
      modalName: 'downloadSummary',
      status: false
    })
  )
}

export function* downloadPendingDueGroupEffect(action: {
  type: string
  payload: { filter: number; fileName: string; selectedSheetType: string }
}) {
  const { filter, selectedSheetType } = action.payload
  const request = {
    end_time: filter,
    ...(filter === dayjs().endOf('day').unix() && { include_total_wallet_balance: true })
  }
  const response: IApiResponse = yield call(fetchAllPendingDueInvoices, request)
  const sortedBills = getSortedBills(response.data.bills)
  const data = convertResponseToExcelFormat(sortedBills)
  const ws = utils.json_to_sheet(data, { origin: 'A10' })
  const wb = utils.book_new()
  const excelHeading = `Pending Due Group Summary (${dayjs.unix(filter).format('DD MMM, YYYY')})`
  const totalPendingDue = computeTotalPendingDue(data)

  utils.sheet_add_aoa(
    ws,
    [
      [excelHeading],
      [],
      [`Printed On: ${dayjs().format('DD MMM, YYYY')}`],
      [`Total Pending Due : ${Math.round(totalPendingDue)}`]
    ],
    {
      origin: 'A1'
    }
  )

  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  if (selectedSheetType === 'multi-sheets') {
    const groupWiseData = convertResponseToGroupWiseFormat(sortedBills)
    Object.values(groupWiseData).forEach((groupData) => {
      const worksheet = utils.json_to_sheet(groupData, { origin: 'A10' })
      const totalPendingDueData = computeTotalPendingDue(groupData)
      utils.sheet_add_aoa(
        worksheet,
        [[`${groupData[0]['Group Name']} Total Pending Due : ${Math.round(totalPendingDueData)}`]],
        {
          origin: 'A1'
        }
      )
      utils.book_append_sheet(wb, worksheet, groupData[0]['Group Name'])
    })
  }

  if (response.data.accounts?.length > 0) {
    const walletBalance = convertResponseToWalletSheetFormat(response.data.accounts)

    if (walletBalance.length > 0) {
      const walletBalanceSheet = utils.json_to_sheet(walletBalance, { origin: 'A10' })
      utils.sheet_add_aoa(
        walletBalanceSheet,
        [[`Opening Wallet Balance Summary (${dayjs().format('DD MMM, YYYY')})`]],
        {
          origin: 'A1'
        }
      )
      if (data.length) {
        walletBalanceSheet['!cols'] = getMaxColumnWidths(walletBalance)
      }
      utils.book_append_sheet(wb, walletBalanceSheet, 'Opening Wallet Balance')
    }
  }

  writeFileXLSX(wb, `${action.payload.fileName}.xlsx`)

  yield put(
    setModal({
      modalName: 'downloadSummaryGroup',
      status: false
    })
  )
}

export function* editGroupNameEffect(action: {
  type: string
  payload: {
    staffLinkId: string
    updatedName: string
  }
}) {
  yield put(
    setEditNameLoader({
      editNameLoading: true
    })
  )

  const { staffLinkId, updatedName } = action.payload
  const response: IApiResponse = yield updateGroupNameApi({
    groupId: staffLinkId,
    groupName: updatedName
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Name Updation Failed', {
      cause: 'customError'
    })
  }

  yield put(
    setUpdatedGroupName({
      updatedName: response.data.statement_group.name
    })
  )
  yield put(
    setModal({
      modalName: 'editGroupName',
      status: false
    })
  )

  yield put(
    setEditNameLoader({
      editNameLoading: false
    })
  )
}

export function* fetchPendingDueOverallSummaryEffect(action: {
  type: string
  payload: {
    filter: number
    pageSize: number
    pageNumber: number
    sortBy: 'group_name' | 'invoice_count' | 'pending_due'
    sortDescending?: boolean
  }
}) {
  const { filter } = action.payload
  const requestPayload: IPendingDueOverallSummaryRequestPayload = {
    end_time: filter,
    ...(filter === dayjs().endOf('day').unix() && { include_total_wallet_balance: true })
  }
  const response: IApiResponse = yield call(fetchPendingDueOverallGroups, requestPayload)
  const groupDuetotalEntries = response.data?.total_count || 50
  const responsePendingDueGroupData: IPendingDueGroupElement = transformSingleResponse(
    response.data
  )
  yield put(
    setPendingDueOverallGroupData({
      overallPendingDueSummary: responsePendingDueGroupData,
      groupDuetotalEntries
    })
  )
  yield put(
    setPendingSummaryLoader({
      isPendingDueSummaryLoading: false
    })
  )
  yield put(setPendingDueFilter({ filter }))
}

export function* fetchPendingDueOverallInnerGroupEffect(action: {
  payload: {
    groupId: string
    filter: number
    pageNumber: number
    sortBy: detailedDueSortBy
    pending_days_gte?: number
    pending_days_lte?: number
    search_string?: string
    bills_count_gt?: number
  }
  type: string
}) {
  const {
    filter,
    pageNumber,
    sortBy,
    pending_days_gte,
    pending_days_lte,
    search_string,
    bills_count_gt
  } = action.payload

  const { detailedDuePageSize, overallPendingDueSummary }: IPendingDueSummary = yield select(
    (app: IAppState) => app.NewSummary.PendingDueSummary
  )

  const requestPayload: IPendingDueOverallGroupInnerDataRequestPayload = {
    // group_id: groupId,
    end_time: filter,
    ...(pending_days_gte && pending_days_gte !== -1 && { pending_days_gte }),
    ...(pending_days_lte && pending_days_lte !== -1 && { pending_days_lte }),
    page_no: pageNumber,
    page_size: detailedDuePageSize,
    ...(search_string && { search_string }),
    ...(sortBy && { sort_by: sortBy }),
    ...(filter === dayjs().endOf('day').unix() && { include_total_wallet_balance: true }),
    ...((bills_count_gt || bills_count_gt === 0) && bills_count_gt !== -1 && { bills_count_gt })
  }

  const response: IApiResponse = yield call(fetchPendingDueForOverallInnerGroup, requestPayload)
  const selectedGroup = {
    invoiceCount: Number(overallPendingDueSummary?.invoiceCount || 0),
    pendingDue: overallPendingDueSummary?.pendingDue,
    detailedDueGroupName: '',
    walletbalance: overallPendingDueSummary?.walletBalance
  }

  const convertedData = convertResponseToStoreFormat(response.data.bills)
  yield put(
    setPendingDueForOverallGroup({
      filter,
      pendingDueGroupIdData: convertedData,
      detailedDueSortBy: sortBy,
      detailedDueTotalInvoiceCount: selectedGroup!.invoiceCount,
      detailedDueTotalPendingDue: Number(selectedGroup!.pendingDue || 0),
      detailedDueGroupName: selectedGroup.detailedDueGroupName,
      ...(selectedGroup?.walletbalance && {
        detailedDueWalletBalance: selectedGroup.walletbalance
      }),
      ...((pending_days_gte || pending_days_gte === 0) &&
        pending_days_lte && {
          pendingDueRangeFilter: {
            lte: pending_days_lte,
            gte: pending_days_gte
          }
        }),
      detailedDueCurrentPage: pageNumber,
      detailedDuetotalEntries: Number(selectedGroup!.invoiceCount),
      ...((bills_count_gt || bills_count_gt === 0) && bills_count_gt !== -1
        ? { billsCountGt: bills_count_gt }
        : { billsCountGt: -1 })
    })
  )
  yield put(
    setPendingSummaryLoader({
      isOverallPendingDueSummaryLoading: false
    })
  )
}
