import dayjs from 'dayjs'
import { payeeType } from 'pages/NewDashboard/components/Billing/components/AddExpenseItemDrawer/utils'
import {
  ExpenseItemsResponseType,
  InventoryType,
  PurchaseItemsResponseType
} from 'state/inventory/inventory.types'

export const formatPurchaseDataForFile = (
  transactions: Array<PurchaseItemsResponseType>,
  type: InventoryType
): any =>
  transactions
    ?.filter((_) => !_.deleted)
    ?.map((_) => ({
      Date: dayjs(Number(_.transaction_date) * 1000).format('DD MMM YYYY'),
      'Item Code': _.code,
      Amount: Number(_.amount) / 100,
      Quantity: _.quantity,
      Type: _.type.toUpperCase(),
      Notes: _.notes,
      ...(type !== InventoryType.purchase ? { 'Bill Number': _.bill_number } : {})
    })) || []

export const formatExpenseItemForFile = (transactions: Array<ExpenseItemsResponseType>) =>
  transactions.map((_) => ({
    Date: dayjs(Number(_.create_time) * 1000).format('DD MMM, YYYY'),
    'Payee Name': _.expense_account_name,
    Amount: Number(_.amount) / 100,
    'Payment Account': _.payment_account_name,
    Type: payeeType.find((payee) => payee.id === _.type)?.name || '',
    Notes: _.particulars
  }))
