import { createUseStyles } from 'react-jss'

export const ThreeDotsComponentStyles = createUseStyles({
  preText: {
    fontWeight: 600,
    fontSize: 14,
    paddingRight: 4
  },
  wrapperDiv: {
    display: 'flex',
    alignItems: 'center'
  }
})
