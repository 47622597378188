import { takeCustomLatest } from 'state/common/common.effects'
import { pendingDueSummaryActions, setEditNameLoader } from './pendingDueSummary.actions'
import {
  downloadPendingDueGroupEffect,
  downloadPendingDueGroupIdEffect,
  editGroupNameEffect,
  fetchPendingDueGroupIdEffect,
  fetchPendingDueOverallInnerGroupEffect,
  fetchPendingDueOverallSummaryEffect,
  fetchPendingDueSummaryEffect
} from './pendingDueSummary.effects'

function* fetchPendingDueGroupSummarySaga() {
  yield takeCustomLatest(
    pendingDueSummaryActions.FETCH_PENDING_DUE_GROUP_SUMMARY,
    fetchPendingDueSummaryEffect
  )
}

function* fetchPendingDueGroupIdSaga() {
  yield takeCustomLatest(
    pendingDueSummaryActions.FETCH_PENDING_DUE_GROUP_ID,
    fetchPendingDueGroupIdEffect
  )
}

function* downloadPendingDueGroupIdSaga() {
  yield takeCustomLatest(pendingDueSummaryActions.DOWNLOAD_SUMMARY, downloadPendingDueGroupIdEffect)
}

function* editGroupNameSaga() {
  yield takeCustomLatest(pendingDueSummaryActions.EDIT_GROUP_NAME, editGroupNameEffect, () =>
    setEditNameLoader({ editNameLoading: false })
  )
}

function* downloadGroupLevelSummary() {
  yield takeCustomLatest(
    pendingDueSummaryActions.DOWNLOAD_GROUP_LEVEL_SUMMARY,
    downloadPendingDueGroupEffect
  )
}

function* fetchPendingOverallGroupSummarySaga() {
  yield takeCustomLatest(
    pendingDueSummaryActions.FETCH_PENDING_OVERALL_GROUP_SUMMARY,
    fetchPendingDueOverallSummaryEffect
  )
}

function* fetchPendingDueOverallGroupIdSaga() {
  yield takeCustomLatest(
    pendingDueSummaryActions.FETCH_PENDING_DUE_OVERALL_INNER_GROUP,
    fetchPendingDueOverallInnerGroupEffect
  )
}

export const PendingDueSummaryWatchers = [
  fetchPendingDueGroupSummarySaga(),
  fetchPendingDueGroupIdSaga(),
  downloadPendingDueGroupIdSaga(),
  editGroupNameSaga(),
  downloadGroupLevelSummary(),
  fetchPendingOverallGroupSummarySaga(),
  fetchPendingDueOverallGroupIdSaga()
]
