import { getEnvConfig } from 'utils/getEnvConfig'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const BUSINESS_TYPES_ENDPOINT = '/business-types'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const api = new ApiService(getEnvConfig('MERCHANT_ENDPOINT').replace('v1', 'v2'), headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const getBusinessTypes = () => {
  return api.getRequest(BUSINESS_TYPES_ENDPOINT, {})
}

export const MerchantApiV2 = {
  getBusinessTypes
}
