import React from 'react'
import { style } from './style'

const RedDot = ({ dotSize }: { dotSize?: number }) => {
  const classes = style({ dotSize })
  return (
    <div className={classes.parent}>
      <div className={classes.redDot} />
    </div>
  )
}

export default RedDot
