import { Action } from 'infra/types'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { FetchCustomerProfilesAPIPayload } from 'services/Api/StaffLinkApiFolder/type'
import { linkDelinkAccountTagsPayload } from 'state/tags/tags.types'
import { ENTITY_TYPE, IFilterEntity } from '../../constants'
import { IDispatchCallback, IFilteredCustomersPayload } from '../../services/Api/types'
import {
  ICustomer,
  IMerchantProfile,
  ISupplier,
  IMerchantProfileBusinessCategory,
  IMerchantProfileBusinessType,
  IPhonebookContact,
  IMerchantProfileEdited,
  IHelpSection,
  HELP_ITEM_METHOD,
  HELP_SECTION_INTERACTION,
  IEntityPayload,
  IEntityEdit,
  IBroadcastAction,
  IHelpTopic,
  IInitialLeadgenAction,
  IAccountByBills,
  ILoaders,
  ICustomerProfileDrawers,
  BusinessManagerType,
  IDashboardState,
  addAccountBeatRoutePayload
} from './dashboard.types'
import { IEntityUpdateObject } from './helpers/CustomerSupplierProfile'

export const DashboardActions = {
  //merchant registration actions
  BUSINESS_NAME_SKIPPED: 'dashboard/businessNameSkipped',
  REGISTER_MERCHANT_NAME: 'dashboard/enterBusinessName',
  REGISTER_MERCHANT_NAME_SUCCESS: 'dashboard/enterBusinessNameSuccess',
  REGISTER_MERCHANT_NAME_FAILURE: 'dashboard/enterBusinessNameFailure',
  SHOW_BUSINESS_NAME_MODAL: 'dashboard/showBusinessNameModal',
  BUSINESS_NAME_MODAL_OPTED_OUT: 'dashboard/businessNameModalOptedOut',
  FETCH_LOCAL_DATA: 'dashboard/fetchLocalData',
  FETCH_LOCAL_DATA_SUCCESS: 'dashboard/fetchLocalDataSuccess',

  FETCH_BUSINESS_TYPES: 'dashboard/fetchBusinessTypes',
  FETCH_BUSINESS_SETTINGS: 'dashboard/fetchBusinessSettings',
  SET_BUSINESS_SETTINGS: 'dashboard/setBusinessSettings',
  FETCH_BUSINESS_TYPES_SUCCESS: 'dashboard/fetchBusinessTypesSuccess',
  FETCH_BUSINESS_CATEGORIES: 'dashboard/fetchBusinessCategories',
  FETCH_BUSINESS_CATEGORIES_SUCCESS: 'dashboard/fetchBusinessCategoriesSuccess',

  //Merchant profile actions
  FETCH_MERCHANT_PROFILE: 'dashboard/fetchMerchantProfile',
  FETCH_MERCHANT_PROFILE_SUCCESS: 'dashboard/fetchMerchantProfileSuccess',
  FETCH_MERCHANT_PROFILE_FAILURE: 'dashboard/fetchMerchantProfileFailure',
  EDIT_MERCHANT_PROFILE: 'dashboard/editMerchantProfile',
  EDIT_MERCHANT_PROFILE_FIELD_START: 'dashboard/editMerchantProfileFieldStart',
  EDIT_MERCHANT_PROFILE_FIELD_END: 'dashboard/editMerchantProfileFieldEnd',
  PATCH_MERCHANT_PROFILE: 'dashboard/patchMerchantProfile',
  PATCH_MERCHANT_ADDRESS_WITH_CURRENT_LOCATION: 'dashboard/patchMerchantAddressWithCurrentLocation',

  //filter actions
  CHANGE_LIST_TAB: 'dashboard/changeEntityType',
  OPEN_FILTERS: 'dashboard/openFilters',
  CLEAR_FILTERS: 'dashboard/clearFilters',
  SAVE_FILTERS: 'dashboard/saveFilters',
  SAVE_FILTERS_ON_TAGS: 'dashboard/SAVE_FILTERS_ON_TAGS',
  UPDATE_SEARCH_STRING: 'dashboard/updateSearchString',
  UPDATE_SEARCH_STRING_ON_TAGS: 'dashboard/UPDATE_SEARCH_STRING_ON_TAGS',

  //entity actions
  FETCH_ENTITY_LIST: 'dashboard/fetchEntityList',
  FETCH_ENTITY_LIST_SUCCESS: 'dashboard/fetchEntityListSuccess',
  FETCH_ENTITY_LIST_FAILURE: 'dashboard/fetchEntityListFailure',
  EDIT_ENTITY: 'dashboard/editEntity',
  EDIT_ENTITY_FIELD_START: 'dashboard/editEntityFieldStart',
  EDIT_ENTITY_FIELD_END: 'dashboard/editEntityFieldEnd',
  PATCH_ENTITY: 'dashboard/patchEntity',
  PATCH_ENTITY_SUCCESS: 'dashboard/patchEntitySuccess',
  DELETE_ENTITY_INITIATED: 'dashboard/deleteEntityInitiated',
  DELETE_ENITITY: 'dashboard/deleteEntity',
  DELETE_ENTITY_CANCEL: 'dashboard/deleteEntityCancel',
  DELETE_ENTITY_SUCCESS: 'dashboard/deleteEntitySuccess',
  ADD_ENTITY: 'dashboard/addEntity',
  ADD_ENTITY_INTERACTED: 'dashboard/addEntityInteracted',
  ADD_ENTITY_SUCCESS: 'dashboard/addEntitySuccess',
  ADD_ENTITY_FAILURE: 'dashboard/addEntityFailure',

  //Phonebook actions
  FETCH_PHONEBOOK_CONTACTS: 'dashboard/fetchPhonebookContacts',
  FETCH_PHONEBOOK_CONTACTS_SUCCESS: 'dashboard/fetchPhonebookContactsSuccess',
  FETCH_PHONEBOOK_CONTACTS_FAILURE: 'dashboard/fetchPhonebookContactsFailure',

  //Get single customer data
  GET_CUSTOMER_DATA: 'dashboard/getCustomerData',
  GET_CUSTOMER_DATA_SUCCESS: 'dashboard/getCustomerDataSuccess',

  //HelpSection actions
  FETCH_HELP_SECTIONS: 'dashboard/fetchHelpQuestions',
  FETCH_HELP_SECTIONS_SUCCESS: 'dashboard/fetchHelpSectionsSuccess',
  FETCH_HELP_SECTIONS_FAILURE: 'dashboard/fetchHelpSectionsFailure',
  VIEW_HELP_TOPIC: 'dashboard/viewHelpTopic',
  VIEW_HELP_ITEM: 'dashboard/viewHelpItem',
  CONTACT_US_ON_WHATSAPP: 'dashboard/contactUsOnWhatsApp',

  // Generic mixpanel action
  BROADCAST_EVENT: 'dashboard/broadcastEvent',

  // Leadgen Actions
  HANDLE_INITIAL_LEADGEN: 'dashboard/HANDLE_INITIAL_LEADGEN',
  SET_SHOW_LEADGEN: 'dashboard/SET_SHOW_LEADGEN',

  //fetch filtered by tags customer list
  FETCH_FILTERED_CUSTOMER_LIST: 'dashboard/FETCH_FILTERED_CUSTOMER_LIST',
  FETCH_FILTERED_CUSTOMER_LIST_SUCCESS: 'dashboard/FETCH_FILTERED_CUSTOMER_LIST_SUCCESS',
  FETCH_FILTERED_CUSTOMER_LIST_FAILURE: 'dashboard/FETCH_FILTERED_CUSTOMER_LIST_FAILURE',

  SAVE_SEARCH_BY_FILTERS: 'dashboard/SAVE_SEARCH_BY_FILTERS',
  FETCH_SEARCH_BY_INVOICE_DATA: 'dashboard/FETCH_SEARCH_BY_INVOICE_DATA',
  FETCH_SEARCH_BY_INVOICE_DATA_SUCCESS: 'dashboard/FETCH_SEARCH_BY_INVOICE_DATA_SUCCESS',
  FETCH_SEARCH_BY_INVOICE_DATA_FAILURE: 'dashboard/FETCH_SEARCH_BY_INVOICE_DATA_FAILURE',

  // Business Account Actions
  FETCH_BUSINESS_IDS: 'dashboard/FETCH_BUSINESS_IDS',
  FETCH_BUSINESS_IDS_SUCCESS: 'dashboard/FETCH_BUSINESS_IDS_SUCCESS',
  SET_BUSINESS_DETAILS: 'dashboard/SET_BUSINESS_DETAILS',
  SET_AS_STAFF_BUSINESS_DETAILS: 'dashboard/SET_AS_STAFF_BUSINESS_DETAILS',
  CREATE_BUSINESS: 'dashboard/CREATE_BUSINESS',
  CREATE_BUSINESS_SUCCESS: 'dashboard/CREATE_BUSINESS_SUCCESS',
  SET_BUSINESS_ACCOUNTS_DRAWER: 'dashboard/SET_BUSINESS_ACCOUNTS_DRAWER',
  SET_BUSINESS_ACCOUNTS_LOADER: 'dashboard/SET_BUSINESS_ACCOUNTS_LOADER',
  SELECT_BUSINESS_ACCOUNT: 'dashboard/SELECT_BUSINESS_ACCOUNT',
  SWITCH_BUSINESS_ACCOUNT: 'dashboard/SWITCH_BUSINESS_ACCOUNT',
  FETCH_BUSINESS_MANAGERS: 'dashboard/FETCH_BUSINESS_MANAGERS',
  ADD_BUSINESS_MANAGER: 'dashboard/ADD_BUSINESS_MANAGER',
  SET_BUSINESS_MANAGERS: 'dashboard/SET_BUSINESS_MANAGERS',
  DELETE_BUSINESS_MANAGER: 'dashboard/DELETE_BUSINESS_MANAGER',

  // Bulk Upload Accounts Actions
  BULK_UPLOAD_ACCOUNTS: 'dashboard/BULK_UPLOAD_ACCOUNTS',
  BULK_UPLOAD_ACCOUNTS_SUCCESS: 'dashboard/BULK_UPLOAD_ACCOUNTS_SUCCESS',
  START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS: 'dashboard/START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS',
  STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS: 'dashboard/STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS',
  BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS: 'dashboard/BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS',
  SET_LOADER: 'dashboard/SET_LOADER',
  SET_CUSTOMER_PROFILES_DRAWER: 'dashboard/SET_CUSTOMER_PROFILES_DRAWER',
  CHECK_FOR_GST_NUMBER: 'dashboard/CHECK_FOR_GST_NUMBER',

  /** Paid Soon Banner Events */
  CHECK_FOR_PAID_BANNER_SOON: NEWDASHBOARD_EVENTS.CHECK_FOR_PAID_BANNER_SOON,
  CLOSE_PAID_SOON_BANNER: NEWDASHBOARD_EVENTS.CLOSE_PAID_SOON_BANNER,
  SUBMIT_FEEDBACK_PAID_SOON_BANNER: NEWDASHBOARD_EVENTS.SUBMIT_FEEDBACK_PAID_SOON_BANNER,
  OPEN_PAID_SOON_BANNER: NEWDASHBOARD_EVENTS.OPEN_PAID_SOON_BANNER,

  FETCH_CUSTOMER_PROFILES: 'dashboard/fetchCustomerProfiles',
  SET_CUSTOMER_PROFILES_DATA: 'dashboard/setCustomerProfilesData',
  BULK_ADD_ACCOUNT_TAGS: 'dashboard/bulkAddAccountTags',
  BULK_ADD_ACCOUNT_BEAT_ROUTE: 'dashboard/bulkAddAccountBeatRoute'
}

export const fetchCustomerProfiles = (
  payload: FetchCustomerProfilesAPIPayload
): Action<FetchCustomerProfilesAPIPayload> => {
  return {
    type: DashboardActions.FETCH_CUSTOMER_PROFILES,
    payload
  }
}

export const setCustomerProfilesData = (
  payload: IDashboardState['customerProfiles']
): Action<IDashboardState['customerProfiles']> => {
  return {
    type: DashboardActions.SET_CUSTOMER_PROFILES_DATA,
    payload
  }
}

export const bulkAddAccountTags = (
  payload: linkDelinkAccountTagsPayload
): Action<linkDelinkAccountTagsPayload> => {
  return {
    type: DashboardActions.BULK_ADD_ACCOUNT_TAGS,
    payload
  }
}

export const bulkAddAccountBeatRoute = (
  payload: addAccountBeatRoutePayload
): Action<addAccountBeatRoutePayload> => {
  return {
    type: DashboardActions.BULK_ADD_ACCOUNT_BEAT_ROUTE,
    payload
  }
}

export const checkForGSTNumberAction = (gst_number: string): Action<string> => ({
  type: DashboardActions.CHECK_FOR_GST_NUMBER,
  payload: gst_number
})

export const broadcastEvent = (
  eventName: string,
  payload: Record<string, any>
): Action<IBroadcastAction> => ({
  type: DashboardActions.BROADCAST_EVENT,
  payload: {
    eventName,
    properties: payload
  }
})

//merchant registration action creators
export const businessNameSkipped = () => ({
  type: DashboardActions.BUSINESS_NAME_SKIPPED,
  payload: null
})

export const fetchLocalDataSuccess = (businessNameOptOut: boolean): Action<boolean> => ({
  type: DashboardActions.FETCH_LOCAL_DATA_SUCCESS,
  payload: businessNameOptOut
})

export const fetchLocalData = (): Action<null> => ({
  type: DashboardActions.FETCH_LOCAL_DATA,
  payload: null
})

export const showBusinessNameModal = (): Action<null> => ({
  type: DashboardActions.SHOW_BUSINESS_NAME_MODAL,
  payload: null
})

export const businessNameOptOut = (): Action<null> => ({
  type: DashboardActions.BUSINESS_NAME_MODAL_OPTED_OUT,
  payload: null
})

export const registerMerchantNameSuccesss = (): Action<null> => ({
  type: DashboardActions.REGISTER_MERCHANT_NAME_SUCCESS,
  payload: null
})

export const registerMerchantName = (name: string): Action<string> => ({
  type: DashboardActions.REGISTER_MERCHANT_NAME,
  payload: name
})

export const fetchBusinessCategoriesAction = (): Action<null> => ({
  type: DashboardActions.FETCH_BUSINESS_CATEGORIES,
  payload: null
})

export const fetchBusinessCategoriesSuccess = (list: IMerchantProfileBusinessCategory[]) => ({
  type: DashboardActions.FETCH_BUSINESS_CATEGORIES_SUCCESS,
  payload: list
})

export const fetchBusinessTypesAction = (): Action<null> => ({
  type: DashboardActions.FETCH_BUSINESS_TYPES,
  payload: null
})

export const fetchBusinessSettingsAction = (): Action<null> => ({
  type: DashboardActions.FETCH_BUSINESS_SETTINGS,
  payload: null
})

export const setBusinessSettingsAction = (payload: any): Action<any> => ({
  type: DashboardActions.SET_BUSINESS_SETTINGS,
  payload
})

export const fetchBusinessTypesSuccess = (list: IMerchantProfileBusinessType[]) => ({
  type: DashboardActions.FETCH_BUSINESS_TYPES_SUCCESS,
  payload: list
})

//merchant profile action creators
export const fetchMerchantProfile = (): Action<null> => ({
  type: DashboardActions.FETCH_MERCHANT_PROFILE,
  payload: null
})

export const fetchMerchantProfileSuccess = (
  merchantProfile: IMerchantProfile
): Action<IMerchantProfile> => ({
  type: DashboardActions.FETCH_MERCHANT_PROFILE_SUCCESS,
  payload: merchantProfile
})

export const fetchMerchantProfileFailure = (): Action<null> => ({
  type: DashboardActions.FETCH_MERCHANT_PROFILE_FAILURE,
  payload: null
})

export const editMerchantProfile = (
  key: string,
  value: any
): Action<{ key: string; value: any }> => ({
  type: DashboardActions.EDIT_MERCHANT_PROFILE,
  payload: {
    key,
    value
  }
})

export const editMerchantProfileFieldStart = (key: string): Action<{ key: string }> => ({
  type: DashboardActions.EDIT_MERCHANT_PROFILE_FIELD_START,
  payload: {
    key
  }
})

export const editMerchantProfileFieldEnd = (
  key: string,
  value: any
): Action<{ key: string; value: any }> => ({
  type: DashboardActions.EDIT_MERCHANT_PROFILE_FIELD_END,
  payload: {
    key,
    value
  }
})

export const updateMerchantProfile = (payload: IMerchantProfile): Action<IMerchantProfile> => ({
  type: DashboardActions.PATCH_MERCHANT_PROFILE,
  payload
})

export const patchMerchantAddressWithCurrentLocation = (): Action<null> => ({
  type: DashboardActions.PATCH_MERCHANT_ADDRESS_WITH_CURRENT_LOCATION,
  payload: null
})

//entity action creators

export const addEntity = (payload: IEntityPayload & IDispatchCallback) => ({
  type: DashboardActions.ADD_ENTITY,
  payload: {
    ...payload,
    fromContactList: payload.fromContactList || false
  }
})

export const addEntityInteracted = ({
  interaction,
  entityType
}: {
  interaction: string
  entityType: ENTITY_TYPE
}) => {
  return {
    type: DashboardActions.ADD_ENTITY_INTERACTED,
    payload: {
      interaction,
      entityType
    }
  }
}

export const addEntitySuccess = (
  entityType: ENTITY_TYPE,
  phoneNumber: string,
  fromContactList: boolean = false,
  isRegistered: boolean = false,
  accountId: string
): Action<{
  phoneNumber: string
  fromContactList: boolean
  isRegistered: boolean
  accountId: string
  entityType: ENTITY_TYPE
}> => ({
  type: DashboardActions.ADD_ENTITY_SUCCESS,
  payload: {
    phoneNumber,
    fromContactList,
    isRegistered,
    accountId,
    entityType
  }
})

export const addEntityFailure = (): Action<null> => ({
  type: DashboardActions.ADD_ENTITY_FAILURE,
  payload: null
})

export const fetchEntityList = (entityType: ENTITY_TYPE): Action<ENTITY_TYPE> => ({
  type: DashboardActions.FETCH_ENTITY_LIST,
  payload: entityType
})

export const fetchEntityListSuccess = (
  entityType: ENTITY_TYPE,
  entities: ICustomer[] | ISupplier[]
): Action<{ entityType: ENTITY_TYPE; entities: ICustomer[] | ISupplier[] }> => ({
  type: DashboardActions.FETCH_ENTITY_LIST_SUCCESS,
  payload: {
    entities,
    entityType
  }
})

export const fetchEntityListFailure = (entityType: ENTITY_TYPE): Action<ENTITY_TYPE> => ({
  type: DashboardActions.FETCH_ENTITY_LIST_FAILURE,
  payload: entityType
})

export const editEntity = (payload: IEntityUpdateObject): Action<IEntityUpdateObject> => ({
  type: DashboardActions.EDIT_ENTITY,
  payload
})

export const editEntityFieldStart = (payload: { key: string }): Action<{ key: string }> => ({
  type: DashboardActions.EDIT_ENTITY_FIELD_START,
  payload
})

export const editEntityFieldEnd = (payload: {
  key: string
  value: any
}): Action<{ key: string; value: any }> => ({
  type: DashboardActions.EDIT_ENTITY_FIELD_END,
  payload
})

export const updateEntity = (payload: ISupplier | ICustomer): Action<ISupplier | ICustomer> => ({
  type: DashboardActions.PATCH_ENTITY,
  payload
})

export const deleteEntityInitiated = (entityType: ENTITY_TYPE, id: string) => ({
  type: DashboardActions.DELETE_ENTITY_INITIATED,
  payload: {
    entityType,
    id
  }
})

export const deleteEntity = (payload: IEntityEdit & IDispatchCallback): Action<IEntityEdit> => ({
  type: DashboardActions.DELETE_ENITITY,
  payload
})

export const deleteEntityCancel = (entityType: ENTITY_TYPE, id: string) => ({
  type: DashboardActions.DELETE_ENTITY_CANCEL,
  payload: {
    entityType,
    id
  }
})

export const deleteEntitySuccess = (entityType: ENTITY_TYPE, id: string) => ({
  type: DashboardActions.DELETE_ENTITY_SUCCESS,
  payload: {
    entityType,
    id
  }
})

//filter action creators
export const openFilters = (): Action<null> => ({
  type: DashboardActions.OPEN_FILTERS,
  payload: null
})

export const updateSearchString = (searchString: string): Action<string> => ({
  type: DashboardActions.UPDATE_SEARCH_STRING,
  payload: searchString
})

export const clearFilters = (): Action<null> => ({
  type: DashboardActions.CLEAR_FILTERS,
  payload: null
})

export const saveFilters = (filters: IFilterEntity[]): Action<IFilterEntity[]> => ({
  type: DashboardActions.SAVE_FILTERS,
  payload: filters
})

export const changeListTab = (selected: ENTITY_TYPE): Action<ENTITY_TYPE> => ({
  type: DashboardActions.CHANGE_LIST_TAB,
  payload: selected
})

//phonebook action creators
export const fetchPhonebookContacts = (): Action<null> => ({
  type: DashboardActions.FETCH_PHONEBOOK_CONTACTS,
  payload: null
})

export const fetchPhonebookContactsSuccess = (
  contacts: IPhonebookContact[]
): Action<IPhonebookContact[]> => ({
  type: DashboardActions.FETCH_PHONEBOOK_CONTACTS_SUCCESS,
  payload: contacts
})

export const fetchPhonebookContactsFailure = (): Action<null> => ({
  type: DashboardActions.FETCH_PHONEBOOK_CONTACTS_FAILURE,
  payload: null
})

//help section action creators
export const fetchHelpSections = (merchantId: string) => ({
  type: DashboardActions.FETCH_HELP_SECTIONS,
  payload: merchantId
})

export const fetchHelpSectionsSuccess = (helpSection: IHelpSection[]) => ({
  type: DashboardActions.FETCH_HELP_SECTIONS_SUCCESS,
  payload: helpSection
})

export const fetchHelpSectionsFailure = (): Action<null> => ({
  type: DashboardActions.FETCH_HELP_SECTIONS_FAILURE,
  payload: null
})

export const viewHelpTopic = (payload: IHelpTopic): Action<IHelpTopic> => ({
  type: DashboardActions.VIEW_HELP_TOPIC,
  payload
})

export const viewHelpItem = (payload: {
  method: HELP_ITEM_METHOD
  interaction: HELP_SECTION_INTERACTION
  id: string
  isMainScreen: boolean
}): Action<{
  method: HELP_ITEM_METHOD
  interaction: HELP_SECTION_INTERACTION
  id: string
  isMainScreen: boolean
}> => ({
  type: DashboardActions.VIEW_HELP_ITEM,
  payload
})

export const contactUsOnWhatsApp = (): Action<null> => ({
  type: DashboardActions.CONTACT_US_ON_WHATSAPP,
  payload: null
})

export const getCustomerData = (customerId: string): Action<string> => ({
  type: DashboardActions.GET_CUSTOMER_DATA,
  payload: customerId
})

export const getCustomerDataSuccess = (payload: ICustomer): Action<ICustomer> => ({
  type: DashboardActions.GET_CUSTOMER_DATA_SUCCESS,
  payload
})

export const handleInitialLeadgen = (
  payload: IInitialLeadgenAction
): Action<IInitialLeadgenAction> => ({
  type: DashboardActions.HANDLE_INITIAL_LEADGEN,
  payload
})

export const setShowLeadgenBanner = (payload: boolean): Action<boolean> => ({
  type: DashboardActions.SET_SHOW_LEADGEN,
  payload
})

export const fetchFilteredCustomerList = (
  payload: IFilteredCustomersPayload
): Action<IFilteredCustomersPayload> => ({
  type: DashboardActions.FETCH_FILTERED_CUSTOMER_LIST,
  payload
})

export const fetchFilteredCustomerListSuccess = (
  payload: ICustomer | undefined
): Action<ICustomer | undefined> => ({
  type: DashboardActions.FETCH_FILTERED_CUSTOMER_LIST_SUCCESS,
  payload
})

export const fetchFilteredCustomerListFailure = (payload: null): Action<null> => ({
  type: DashboardActions.FETCH_FILTERED_CUSTOMER_LIST_FAILURE,
  payload
})

export const saveFiltersOnTags = (filters: IFilterEntity[]): Action<IFilterEntity[]> => ({
  type: DashboardActions.SAVE_FILTERS_ON_TAGS,
  payload: filters
})

export const updateSearchStringForTags = (searchString: string): Action<string> => ({
  type: DashboardActions.UPDATE_SEARCH_STRING_ON_TAGS,
  payload: searchString
})

export const saveSearchByValue = (filters: IFilterEntity[]): Action<IFilterEntity[]> => ({
  type: DashboardActions.SAVE_SEARCH_BY_FILTERS,
  payload: filters
})

export const handleSearchByValue = (): Action<null> => ({
  type: DashboardActions.FETCH_SEARCH_BY_INVOICE_DATA,
  payload: null
})

export const handleSearchByValueSuccess = (
  accounts: Array<IAccountByBills>
): Action<Array<IAccountByBills>> => ({
  type: DashboardActions.FETCH_SEARCH_BY_INVOICE_DATA_SUCCESS,
  payload: accounts
})

export const handleSearchByValueFailure = (): Action<null> => ({
  type: DashboardActions.FETCH_SEARCH_BY_INVOICE_DATA_FAILURE,
  payload: null
})

export const fetchBusinessIdsAction = (payload: {
  isSigningIn: boolean
}): Action<{ isSigningIn: boolean }> => ({
  type: DashboardActions.FETCH_BUSINESS_IDS,
  payload
})

export const setAsStaffBusinessDetailsAction = (payload: any): Action<any> => ({
  type: DashboardActions.SET_AS_STAFF_BUSINESS_DETAILS,
  payload
})

export const fetchBusinessIdsSuccessAction = (payload: {
  businessIds: string[]
  individualUser: IMerchantProfile
  asStaffBusinessIds?: string[]
}): Action<{
  businessIds: string[]
  individualUser: IMerchantProfile
  asStaffBusinessIds?: string[]
}> => ({
  type: DashboardActions.FETCH_BUSINESS_IDS_SUCCESS,
  payload
})

export const setBusinessDetailsAction = (payload: any): Action<any> => ({
  type: DashboardActions.SET_BUSINESS_DETAILS,
  payload
})

export const setBusinessAccountsLoaderAction = (payload: boolean): Action<boolean> => ({
  type: DashboardActions.SET_BUSINESS_ACCOUNTS_LOADER,
  payload
})

export const handleBusinessAccountsDrawerToggle = (payload: {
  newBusinessName: boolean
}): Action<{
  newBusinessName: boolean
}> => ({
  type: DashboardActions.SET_BUSINESS_ACCOUNTS_DRAWER,
  payload
})

export const createBusinessAction = (name: string): Action<string> => ({
  type: DashboardActions.CREATE_BUSINESS,
  payload: name
})

export const createBusinessSuccessAction = (payload: any): Action<any> => ({
  type: DashboardActions.CREATE_BUSINESS_SUCCESS,
  payload
})

export const switchBusinessAccountAction = (payload: string): Action<string> => ({
  type: DashboardActions.SWITCH_BUSINESS_ACCOUNT,
  payload
})

export const selectBusinessAccountAction = (payload: {
  businessId: string
  isAdmin: boolean
}): Action<{ businessId: string; isAdmin: boolean }> => ({
  type: DashboardActions.SELECT_BUSINESS_ACCOUNT,
  payload
})

export const deleteBusinessManagerAction = (payload: {
  userId: string
  mobile: string
}): Action<{
  userId: string
  mobile: string
}> => ({
  type: DashboardActions.DELETE_BUSINESS_MANAGER,
  payload
})

export const fetchBusinessManagersAction = (payload: {
  businessId: string
}): Action<{ businessId: string }> => ({
  type: DashboardActions.FETCH_BUSINESS_MANAGERS,
  payload
})

export const addBusinessManagerAction = (payload: {
  mobile: string
}): Action<{
  mobile: string
}> => ({
  type: DashboardActions.ADD_BUSINESS_MANAGER,
  payload
})

export const setBusinessManagersAction = (
  managers: BusinessManagerType[]
): Action<BusinessManagerType[]> => ({
  type: DashboardActions.SET_BUSINESS_MANAGERS,
  payload: managers
})

export const bulkUploadCustomersAction = (payload: any): Action<any> => ({
  type: DashboardActions.BULK_UPLOAD_ACCOUNTS,
  payload
})

export const bulkUploadCustomersSuccessAction = (payload: any): Action<any> => ({
  type: DashboardActions.BULK_UPLOAD_ACCOUNTS_SUCCESS,
  payload
})

export const startPollBulkUploadAccountsStatus = (payload: string): Action<string> => ({
  type: DashboardActions.START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS,
  payload
})

export const stopPollBulkUploadAccountsStatus = (): Action<null> => ({
  type: DashboardActions.STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS,
  payload: null
})

export const bulkUploadCustomersStatusSuccessAction = (payload: string): Action<string> => ({
  type: DashboardActions.BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS,
  payload
})

export const setLoaderAction = (payload: {
  id: keyof ILoaders
  value: boolean
}): Action<{ id: keyof ILoaders; value: boolean }> => ({
  type: DashboardActions.SET_LOADER,
  payload
})

export const setDrawerAction = (payload: {
  id: keyof ICustomerProfileDrawers
  value: boolean
  replace?: boolean
}): Action<{ id: keyof ICustomerProfileDrawers; value: boolean; replace?: boolean }> => ({
  type: DashboardActions.SET_CUSTOMER_PROFILES_DRAWER,
  payload
})

export const closePaidSoonBannerAction = (): Action<null> => ({
  type: NEWDASHBOARD_EVENTS.CLOSE_PAID_SOON_BANNER,
  payload: null
})

export const openPaidSoonBannerAction = (): Action<null> => ({
  type: NEWDASHBOARD_EVENTS.OPEN_PAID_SOON_BANNER,
  payload: null
})

export const submitFeedbackPaidSoonBanner = (feedback: string): Action<string> => ({
  type: NEWDASHBOARD_EVENTS.SUBMIT_FEEDBACK_PAID_SOON_BANNER,
  payload: feedback
})

export const checkForPaidBannerSoon = (): Action<null> => {
  return {
    type: NEWDASHBOARD_EVENTS.CHECK_FOR_PAID_BANNER_SOON,
    payload: null
  }
}
