import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { getErrorMessage } from 'services/Api/Errors'
import { broadcastEvent } from 'state/dashboard/dashboard.actions'

function* errorWrapper(sagaFunction: any, action: any, failureCallback?: any) {
  try {
    yield sagaFunction(action)
  } catch (e: any) {
    yield put(
      broadcastEvent('error', {
        route: window?.location?.href,
        message: getErrorMessage(e).message
      })
    )
    if (failureCallback) {
      yield put(failureCallback())
    }
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* takeCustomLatest(actionType: string, effect: any, failureCallback?: any) {
  yield takeLatest(actionType, function* (action: any) {
    yield errorWrapper(effect, action, failureCallback)
  })
}

export function* takeCustomEvery(actionType: string, effect: any, failureCallback?: any) {
  yield takeEvery(actionType, function* (action: any) {
    yield errorWrapper(effect, action, failureCallback)
  })
}
