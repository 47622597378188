import { takeCustomLatest } from 'state/common/common.effects'
import { approveAction } from './approve.actions'
import {
  approvePendingActionEffect,
  fetchAllPendingActionsEffect,
  refetchAllPendingActionsEffect,
  cancelPendingTxnsActionEffect,
  holdInvoiceActionEffect
} from './approve.effect'

function* fetchPendingActionsSaga() {
  yield takeCustomLatest(approveAction.FETCH_PENDING_ACTIONS, fetchAllPendingActionsEffect)
}

function* approvePendingActionSaga() {
  yield takeCustomLatest(approveAction.APPROVE_PENDING_ACTION, approvePendingActionEffect)
}

function* cancelPendingTxnsActionSaga() {
  yield takeCustomLatest(approveAction.CANCEL_PENDING_ACTION, cancelPendingTxnsActionEffect)
}

function* refetchAllPendingActionsSaga() {
  yield takeCustomLatest(approveAction.REFETCH_APPROVE_ACTION, refetchAllPendingActionsEffect)
}

function* holdInvoiceActionSaga() {
  yield takeCustomLatest(approveAction.HOLD_INVOICE_ACTION, holdInvoiceActionEffect)
}

export const ApproveWatchers = [
  fetchPendingActionsSaga(),
  approvePendingActionSaga(),
  refetchAllPendingActionsSaga(),
  cancelPendingTxnsActionSaga(),
  holdInvoiceActionSaga()
]
