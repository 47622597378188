import * as process from 'process'

/* eslint-disable security/detect-object-injection */
export const getEnvConfig = (key: string) => {
  if (typeof window !== 'undefined') {
    return window._env_[key]
  }
  // eslint-disable-next-line dot-notation
  return process['env'][key]
}
