import dayjs from 'dayjs'
import {
  getCollectionGroupInvoiceSummaryV2Api,
  getCollectionGroupInvoiceSummaryV3Api,
  getCollectionListInvoiceSummaryV2Api,
  getCollectionListInvoiceSummaryV3Api,
  getOverallInvoiceSummaryApi,
  getOverallSummaryApi
} from 'services/Api/StafflinkApi'
import {
  IGroupViewData,
  IListGroupViewDataInvoice,
  IListViewData,
  IListViewRowData,
  IListViewShortSummaryData,
  ITransactionDetails
} from './collectionSummary.types'

const formatListViewRowData = (data: any): IListViewRowData => {
  return {
    id: data?.id,
    listName: data?.name,
    openingBalance: Number(data?.summary_stats?.opening_balance) / 100,
    addedCredit: Number(data?.summary_stats?.added_credit) / 100,
    totalCollections: Number(data?.summary_stats?.total_collections) / 100,
    pendingDue: Number(data?.summary_stats?.pending_dues) / 100,
    cash: Number(data?.summary_stats?.cash_payments) / 100,
    neft: Number(data?.summary_stats?.neft_payments) / 100,
    online: Number(data?.summary_stats?.online_payments) / 100,
    cheque: Number(data?.summary_stats?.cheque_payments) / 100,
    return: Number(data?.summary_stats?.return_payments) / 100,
    damage: Number(data?.summary_stats?.damaged_payments) / 100,
    others: Number(data?.summary_stats?.other_payments || 0) / 100,
    advanceUsed: Number(data?.summary_stats?.wallet_payments || 0) / 100,
    advanceCollected: Number(data?.summary_stats?.advance_collected || 0) / 100,
    ...(!!data?.total_wallet_balance && {
      walletBalance: Number(data?.total_wallet_balance || 0) / 100
    })
  }
}

export const formatOverallSummaryRowData = (data: any): IListViewRowData => {
  return {
    id: 'all-collections',
    listName: 'All Collections',
    openingBalance: Number(data?.overall_business_collection_summary?.total_opening_balance) / 100,
    addedCredit: Number(data?.overall_business_collection_summary?.total_added_credit) / 100,
    totalCollections: Number(data?.overall_business_collection_summary?.total_collections) / 100,
    pendingDue: Number(data?.overall_business_collection_summary?.total_pending_dues) / 100,
    cash: Number(data?.overall_business_collection_summary?.total_cash_payments) / 100,
    neft: Number(data?.overall_business_collection_summary?.total_neft_payments) / 100,
    online: Number(data?.overall_business_collection_summary?.total_online_payments) / 100,
    cheque: Number(data?.overall_business_collection_summary?.total_cheque_payments) / 100,
    return: Number(data?.overall_business_collection_summary?.total_returns) / 100,
    damage: Number(data?.overall_business_collection_summary?.total_damages) / 100,
    others: Number(data?.overall_business_collection_summary?.total_other_payments || 0) / 100,
    advanceUsed:
      Number(data?.overall_business_collection_summary?.total_wallet_payments || 0) / 100,
    advanceCollected:
      Number(data?.overall_business_collection_summary?.total_advance_collected || 0) / 100,
    ...(!!data?.overall_business_collection_summary.total_wallet_balance && {
      walletBalance: Number(data?.total_wallet_balance || 0) / 100
    })
  }
}

export const formatListViewShortSummaryData = (data: any): IListViewShortSummaryData => {
  return {
    openingBalance: Number(data?.total_opening_balance) / 100,
    addedCredit: Number(data?.total_added_credit) / 100,
    totalCollections: Number(data?.total_collections) / 100,
    pendingDue: Number(data?.total_pending_dues) / 100,
    cash: Number(data?.total_cash_payments) / 100,
    neft: Number(data?.total_neft_payments) / 100,
    online: Number(data?.total_online_payments) / 100,
    cheque: Number(data?.total_cheque_payments) / 100,
    return: Number(data?.total_returns) / 100,
    damage: Number(data?.total_damages) / 100,
    others: Number(data?.total_other_payments || 0) / 100,
    advanceUsed: Number(data?.total_wallet_payments || 0) / 100,
    advanceCollected: Number(data?.total_advance_collected || 0) / 100,
    ...(!!data?.total_wallet_balance && {
      walletBalance: Number(data?.total_wallet_balance || 0) / 100
    })
  }
}

export const getFormattedCollectionListInvoiceV2Summary = (
  data: any,
  isDynamicStatementEnabled: boolean
): IListViewData => {
  const { collection_lists, pagination_details, overall_stats, groups } = data
  const formattedRowData = isDynamicStatementEnabled
    ? groups.map(formatListViewRowData)
    : collection_lists.map(formatListViewRowData)

  return {
    rowData: formattedRowData,
    shortSummaryData: formatListViewShortSummaryData(overall_stats),
    pagination: {
      total: Number(pagination_details?.total_items),
      page: Number(pagination_details?.page),
      perPage: Number(pagination_details?.per_page),
      totalItems: Number(pagination_details?.total_items | 0)
    }
  }
}

export const getFormattedOverallInvoice = (data: any): IListViewData => {
  const { overall_business_collection_summary, pagination_details } = data

  return {
    rowData: [formatOverallSummaryRowData(data)],
    shortSummaryData: formatListViewShortSummaryData(overall_business_collection_summary),
    pagination: {
      total: Number(pagination_details?.total_items),
      page: Number(pagination_details?.page),
      perPage: Number(pagination_details?.per_page),
      totalItems: Number(pagination_details?.total_items | 0)
    }
  }
}

// Group view data helper functions & formatter
const formatGroupInvoices = (data: any): IListGroupViewDataInvoice => {
  return {
    latestSettlementDate: data.latest_settlement_date,
    invoiceNumber: data.invoice_number,
    invoiceAmount: Number(data.invoice_amount) / 100,
    invoiceDate: data.invoice_date,
    totalCollections: Number(data?.total_collections) / 100,
    pendingDue: Number(data?.total_pending_dues) / 100,
    cash: Number(data?.total_cash_payments) / 100,
    neft: Number(data?.total_neft_payments) / 100,
    online: Number(data?.total_online_payments) / 100,
    cheque: Number(data?.total_cheque_payments) / 100,
    return: Number(data?.total_returns) / 100,
    damage: Number(data?.total_damages) / 100,
    customerName: data?.customer_name,
    customerCode: data?.customer_code,
    customerId: data.customer_id,
    invoiceId: data.invoice_id,
    others: Number(data?.total_other_payments || 0) / 100,
    advanceUsed: Number(data?.total_wallet_payments || 0) / 100,
    advanceCollected: Number(data?.total_advance_collected || 0) / 100
  }
}

export const getFormattedCollectionGroupInvoiceV2Summary = (data: any): IGroupViewData => {
  const { invoices, pagination_details } = data
  return {
    rowData: invoices.map(formatGroupInvoices),
    pagination: {
      total: Number(pagination_details?.total_items),
      page: Number(pagination_details?.page),
      perPage: Number(pagination_details?.per_page),
      totalItems: Number(pagination_details?.total_items | 0)
    }
  }
}

export const formatTransactionDetails = (response: any): Array<ITransactionDetails> => {
  return response.transactions.map((data: any) => ({
    transactionTime: Number(data?.transaction_time) * 1000,
    id: data?.id,
    amount: Number(data?.amount) / 100,
    accountTxnId: data?.account_txn_id,
    txnType: data?.txn_type,
    notes: {
      payerName: data?.notes?.payer_name,
      paymentTransactionId: data?.notes?.payment_txn_id,
      paymentChannel: data?.notes?.payment_channel,
      pspPaymentId: data?.notes?.psp_payment_id,
      payerVpa: data?.notes?.payer_vpa,
      timestamp: data?.notes?.timestamp,
      utr: data?.notes?.utr,
      settlementUtr: data?.notes?.settlement_utr,
      settlementStatus: data?.notes?.settlment_status
    }
  }))
}

export const getContentAndFontColor = (advanceUsed: number, advanceCollected: number) => {
  let content = 0
  let fontColor: 'default' | 'primary' | 'secondary' | 'danger' | undefined = 'default'

  if (advanceUsed && advanceCollected) {
    content = advanceCollected - advanceUsed
    fontColor = advanceCollected > advanceUsed ? 'primary' : 'danger'
  } else if (advanceUsed) {
    content = advanceUsed
    fontColor = 'danger'
  } else if (advanceCollected) {
    content = advanceCollected
    fontColor = 'primary'
  }

  return {
    content,
    fontColor
  }
}

export const getFormattedShortSummaryData = (data: IListViewRowData, invoiceCount: number) => {
  return {
    'Invoice Count': invoiceCount,
    'Opening Balance': data.openingBalance,
    Cash: data.cash,
    'QR / Link': data.online,
    Cheque: data.cheque,
    NEFT: data.neft,
    Damage: data.damage,
    Return: data.return,
    Others: data.others,
    Advance: data.advanceCollected,
    'Total Collection': data.totalCollections,
    'Pending Due': data.pendingDue
  }
}

export const getFormattedGroupViewDataData = (data: IListGroupViewDataInvoice[]) =>
  data.map((item) => ({
    'Customer Name': item.customerName,
    'Invoice Date': dayjs(Number(item.invoiceDate) * 1000).format('DD-MMM-YYYY'),
    'Invoice Number': item.invoiceNumber,
    'Invoice Amount': item.invoiceAmount,
    Cash: item.cash,
    'QR/Link': item.online,
    Cheque: item.cheque,
    NEFT: item.neft,
    Damage: item.damage,
    Return: item.return,
    Others: item.others,
    Advance: item.advanceCollected,
    'Total Collection': item.totalCollections,
    'Latest Settlement Date': dayjs(Number(item.latestSettlementDate) * 1000).format('DD-MMM-YYYY'),
    'Pending Due': item.pendingDue
  }))

export const summaryDataGroupApi = (listId: string, isDynamicStatementEnabled: boolean) => {
  if (listId === 'all-collections') {
    return getOverallInvoiceSummaryApi
  }
  if (isDynamicStatementEnabled) {
    return getCollectionGroupInvoiceSummaryV3Api
  }
  return getCollectionGroupInvoiceSummaryV2Api
}

export const summaryDataListApi = (listId: string, isDynamicStatementEnabled: boolean) => {
  if (listId === 'all-collections') {
    return getOverallSummaryApi
  }
  if (isDynamicStatementEnabled) {
    return getCollectionListInvoiceSummaryV3Api
  }
  return getCollectionListInvoiceSummaryV2Api
}
