import { THEME } from 'components/theme'

export const NotificationStyle = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: THEME.brandWhite,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '5px',
    margin: '0 85px',
    borderRadius: '6px',
    height: '100%'
  },
  cardHeaderContainer: {
    display: 'flex',
    height: '100%'
  },
  cardHeader: {
    marginLeft: '22px',
    width: '-webkit-fill-available',
    paddingRight: '10px'
  },
  cardFooter: {
    display: 'flex',
    fontSize: '14px'
  },
  varticalLine: {
    height: 'auto',
    color: THEME.brandRed,
    marginRight: '0'
  },
  actionButton: {
    background: 'none',
    color: THEME.brandWhite,
    border: 'none',
    marginRight: '-6px',
    fontSize: '14px',
    fontWeight: 'bold',
    ['&:focus']: {
      outline: 'none'
    },
    cursor: 'pointer'
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 25px 15px 20px'
  },
  displayIcon: {
    marginLeft: '20px',
    marginTop: '16px'
  },
  crossIcon: {
    padding: '20px',
    cursor: 'pointer'
  }
}
