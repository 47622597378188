import { ICreditNoteState, SORT_BY } from 'state/new-summary/creditNote/creditNote.types'

export const DEFAULT_CREDIT_PAGE_LEN = 20

export const CreditNoteStateValues: ICreditNoteState = {
  loaders: {
    isFetchingCreditNote: false
  },
  creditNoteList: null,
  searchString: '',
  cnSortBy: {
    type: SORT_BY.DATE,
    isAsc: true
  },
  paginationDetails: {
    page: 1,
    perPage: DEFAULT_CREDIT_PAGE_LEN,
    totalItems: 0,
    totalPages: 1
  },
  creditNoteHtmlContent: []
}
