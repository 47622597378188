export const getUserInitials = (name: string | undefined) => {
  if (!name) {
    return ''
  }
  try {
    const nameArr = name.split(' ').filter((_) => _ !== '')
    if (nameArr.length > 1) {
      return `${nameArr[0][0].toUpperCase()}${nameArr[1][0].toUpperCase()}`
    }
    return nameArr[0][0].toUpperCase()
  } catch {
    return ''
  }
}
