export interface ICashByStaffState {
  modals: {
    downloadCashByStaff: boolean
    downloadCashByStaffBankWise: boolean
  }
  filter: CashByStaffFilter
}

export type CashByStaffFilter = {
  startTime: number
  startDate: number
  endTime: number
}

export interface ModalStatus {
  modalName: ModalNameEnum
  status: boolean
}

export enum ModalNameEnum {
  downloadCashByStaff = 'downloadCashByStaff',
  downloadCashByStaffBankWise = 'downloadCashByStaffBankWise'
}

export type DownloadCashByStaffType = {
  filter: CashByStaffFilter
  fileName: string
  selectedSheetType?: string
}

export type DownloadBankWiseType = {
  filter: CashByStaffFilter
  fileName: string
}
