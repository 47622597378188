import { Action } from 'infra/types'
import {
  IUnidentidfiedCollectionState,
  UNIDENTIFIED_COLLECTION_FILTER_TYPE
} from './unidentifiedCollection.types'
import { unidentifiedCollectionAction } from './unidentifiedCollection.actions'

const APPROVE_INITIAL_STATE: IUnidentidfiedCollectionState = {
  filter: {
    startTime: 0,
    filterType: UNIDENTIFIED_COLLECTION_FILTER_TYPE.DEFAULT
  },
  unidentifiedCollections: [],
  filteredUnidentifiedCollections: [],
  selectedCollection: null,
  totalEntries: 0,
  showBillsDrawer: false,
  isFetchBankDeposits: false,
  isFetchCashDeposits: false,
  pendingCashDeposits: [],
  pendingBankDeposits: [],
  isFetchingData: true
}

export const UnidentifiedCollectionReducer = (
  state: IUnidentidfiedCollectionState = APPROVE_INITIAL_STATE,
  action: Action<any>
): IUnidentidfiedCollectionState => {
  switch (action.type) {
    case unidentifiedCollectionAction.SET_COLLECTION_ACTION_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case unidentifiedCollectionAction.SET_UNIDENTIFIED_COLLECTION_DATA: {
      return {
        ...state,
        unidentifiedCollections: action.payload,
        filteredUnidentifiedCollections: action.payload,
        totalEntries: action.payload.length
      }
    }
    case unidentifiedCollectionAction.SET_SELECTED_COLLECTION: {
      return {
        ...state,
        selectedCollection: action.payload,
        showBillsDrawer: true
      }
    }
    case unidentifiedCollectionAction.SET_SHOW_BILL_DRAWER: {
      return {
        ...state,
        showBillsDrawer: action.payload
      }
    }
    case unidentifiedCollectionAction.FETCH_CASH_DEPOSIT: {
      return {
        ...state,
        isFetchCashDeposits: true,
        isFetchBankDeposits: false
      }
    }
    case unidentifiedCollectionAction.FETCH_BANK_DEPOSIT: {
      return {
        ...state,
        isFetchBankDeposits: true,
        isFetchCashDeposits: false
      }
    }
    case unidentifiedCollectionAction.RESET_BANK_AND_CASH_DEPOSIT: {
      return {
        ...state,
        isFetchBankDeposits: false,
        isFetchCashDeposits: false
      }
    }
    case unidentifiedCollectionAction.HANDLE_SEARCH: {
      if (action.payload === '') {
        return {
          ...state,
          filteredUnidentifiedCollections: state.unidentifiedCollections
        }
      }
      return {
        ...state,
        filteredUnidentifiedCollections: state.unidentifiedCollections.filter(
          (item) =>
            item.from.toLocaleLowerCase().includes(action.payload.toLowerCase()) ||
            item.payer.toLocaleLowerCase().includes(action.payload.toLowerCase())
        )
      }
    }

    case unidentifiedCollectionAction.SAVE_PENDING_CASH_DEPOSIT: {
      return {
        ...state,
        pendingCashDeposits: action.payload
      }
    }

    case unidentifiedCollectionAction.SAVE_PENDING_BANK_DEPOSIT: {
      return {
        ...state,
        pendingBankDeposits: action.payload
      }
    }

    case unidentifiedCollectionAction.SET_FETCHING_LOADERS: {
      return {
        ...state,
        isFetchingData: action.payload
      }
    }
    default: {
      return state
    }
  }
}
