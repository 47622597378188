import React from 'react'
import { createUseStyles } from 'react-jss'
import FixedHeaderStyles from './FixedHeader.styles'
import { FixedHeaderProps } from './FixedHeader.types'

const styles = createUseStyles(FixedHeaderStyles)

export const FixedHeader: React.FC<FixedHeaderProps> = (props) => {
  const classes = styles()
  const { children, height, ...restProps } = props

  return (
    <header role="banner" className={classes.header} {...restProps}>
      <div style={{ height }} />
      <div className={classes.container}>{children}</div>
    </header>
  )
}
