import { getEnvConfig } from 'utils/getEnvConfig'
import ApiService from './ApiService'
import { GRANT_TYPE, IOTPInfo, IPinInfo } from './types'

const headers = {
  'content-type': 'application/json',
  accept: '*/*'
}

const api = new ApiService(getEnvConfig('AUTH_API_ENDPOINT'), headers)

const OTP_REQUEST_ENDPOINT = '/v1.0/otp:request'
const OTP_VERIFY_ENDPOINT = '/v1.0/otp:verify'
const AUTH_TOKEN_ENDPOINT = '/v1.0/auth'

const authGetOtp = (phoneNumber: number) => {
  const sendData = {
    mobile: phoneNumber.toString(),
    mode: 0
  }
  return api.postRequest(OTP_REQUEST_ENDPOINT, sendData)
}

export const authVerifyOtp = (payload: IOTPInfo) => {
  const sendData = {
    mode: 0,
    otp_id: payload.otpId,
    otp: payload.otp.toString()
  }
  return api.postRequest(OTP_VERIFY_ENDPOINT, sendData)
}

const authAuthenticateToken = (token: string) => {
  const data = {
    grant_type: 'otp',
    assertion: token
  }
  return api.postRequest(AUTH_TOKEN_ENDPOINT, data)
}

const authRefreshToken = (refreshToken: string) => {
  const data = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  }
  return api.postRequest(AUTH_TOKEN_ENDPOINT, data)
}

export const authVerifyPin = (payload: IPinInfo) => {
  const sendData = {
    grant_type: GRANT_TYPE.PASSWORD,
    username: `${payload.number}`,
    password: payload.pin
  }
  return api.postRequest(AUTH_TOKEN_ENDPOINT, sendData)
}

export const AuthApi = {
  authGetOtp,
  authVerifyOtp,
  authAuthenticateToken,
  authRefreshToken,
  authVerifyPin
}
