import { ISummaryType } from 'pages/Statements/Statements.constants'

export interface IDownloadDialog {
  className?: string
  defaultFileName?: string
  onClose: () => void
  onSave: (arg0: {
    fileName: string
    format: string
    summaryType: ISummaryType
    selectedSheetType: 'single-sheet' | 'multi-sheets' | string
    selectedBusinessType: BUSINESS_TYPE
  }) => void
  allowInvoiceLevelSummary?: boolean
  showSummaryType?: boolean
  defaultSummaryType?: ISummaryType
  disableSave?: boolean
  showExcelSheetType?: boolean
  excelSheetType?: {
    label: string
    value: string
  }[]
  showMultiBusinessDownload?: boolean
}

export const fileFormatOptions = [
  // '.pdf', TODO: Will support in future release
  '.xlsx'
]

export enum BUSINESS_TYPE {
  CURRENT,
  ALL
}

export const businessOptions = [
  {
    label: 'Current Business',
    value: BUSINESS_TYPE.CURRENT
  },
  {
    label: 'All Businesses',
    value: BUSINESS_TYPE.ALL
  }
]
