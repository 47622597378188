import React, { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { paths } from 'routes'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { useAbExperiment } from 'utils/useAbExperiment'

const WithValidation = (WrappedComponent: FC) => {
  const ComponentWithValidation = (props: any) => {
    const { okTermLoanAppBlock, ready } = useAbExperiment()
    const isLoggedIn = AuthStorageInstance.getIsLoggedIn()
    const history = useHistory()
    const location = useLocation()
    const path = location.pathname !== paths.defaulterLink

    useEffect(() => {
      const shouldRedirect = ready && okTermLoanAppBlock && isLoggedIn && path
      if (shouldRedirect) {
        history.replace(paths.defaulterLink)
      }
    }, [ready, okTermLoanAppBlock])

    return <WrappedComponent {...props} />
  }

  return ComponentWithValidation
}

export default WithValidation
