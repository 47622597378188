import { call, put, select } from 'redux-saga/effects'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { getFormattedCreditNoteDetail } from 'state/new-summary/creditNote/creditNote.helper'
import {
  fetchCreditNotesListSuccessAction,
  saveCreditNotePaginationDetails,
  setCreditNotesLoaderAction,
  setPreviewCreditNoteEntityAction
} from 'state/new-summary/creditNote/creditNote.actions'
import { fetchCreditNoteListApi } from 'services/Api/BillingApiFolder.ts/api'
import { IAppState } from 'infra/AppState'
import { ICustomer, IDashboardState } from 'state/dashboard/dashboard.types'
import { FetchCreditNotePayload, SORT_BY } from 'state/new-summary/creditNote/creditNote.types'
import { Action } from 'infra/types'
import { creditNotePreviewEntityApi } from 'services/Api/GrowthExperimentApiFolder/api'
import { printBills } from 'services/Api/growthExperimentApi'
import { getDeNormalizedList } from 'utils/dataNormalizer'

const DEFAULT_ITEMS_COUNT = 20

export function* fetchCreditNoteListEffect(action: Action<FetchCreditNotePayload>) {
  const details = action.payload
  const { merchantProfile, customers }: IDashboardState = yield select(
    (app: IAppState) => app.Dashboard
  )
  const denormalizedCustomers: ICustomer[] = getDeNormalizedList(customers)
  let customerIds: string[] = []
  if (details?.searchString) {
    customerIds = denormalizedCustomers
      .filter((customer) =>
        customer.description.toLowerCase().includes((details.searchString || '').toLowerCase())
      )
      .map((customer) => customer.id)
  }
  const payload = {
    business_id: merchantProfile?.data?.id || '',
    filter_details: {
      page: details?.page,
      per_page: details?.perPage,
      sort_by: details?.sortDetails?.sortBy,
      sort_descending: details?.sortDetails?.isAsc || false,
      SearchString: details?.searchString,
      ...(customerIds.length && { customer_ids: customerIds })
    }
  }
  const response: IApiResponse = yield call(fetchCreditNoteListApi, payload)
  yield put(
    fetchCreditNotesListSuccessAction(
      getFormattedCreditNoteDetail(response.data.credit_note_details || [], customers.byIds)
    )
  )
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setCreditNotesLoaderAction({ id: 'isFetchingCreditNote', value: false }))
    throw new Error('Problem while fetching credit note, please try after sometime', {
      cause: 'customError'
    })
  }
  yield put(
    saveCreditNotePaginationDetails({
      pagination: {
        page: response.data.pagination_details?.page,
        perPage: response.data.pagination_details?.per_page || DEFAULT_ITEMS_COUNT,
        totalItems: response.data.pagination_details?.total_items,
        totalPages: response.data.pagination_details?.total_pages
      },
      sort: {
        type: details?.sortDetails?.sortBy || SORT_BY.NONE,
        isAsc: details?.sortDetails?.isAsc || false
      }
    })
  )
  yield put(setCreditNotesLoaderAction({ id: 'isFetchingCreditNote', value: false }))
}

export function* fetchCreditNoteEntityEffect(
  action: Action<{ ids: Array<string>; isInvoice?: boolean }>
) {
  const { merchantProfile }: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const isInvoice = action.payload.isInvoice || false
  let reqPayload
  if (isInvoice) {
    reqPayload = action.payload.ids
  } else {
    reqPayload = {
      business_id: merchantProfile?.data?.id || '',
      credit_note_ids: action.payload.ids
    }
  }
  const printPreviewResponse: IApiResponse = yield call(
    isInvoice ? printBills : (creditNotePreviewEntityApi as any),
    reqPayload
  )
  if (isInvoice && printPreviewResponse.data?.bills.length) {
    const creditNoteHtml: string[] = printPreviewResponse?.data?.bills.map(
      (creditNote: any) => creditNote?.content
    )
    yield put(setPreviewCreditNoteEntityAction(creditNoteHtml))
  } else if (printPreviewResponse.data?.credit_notes.length) {
    const creditNoteHtml: string[] = printPreviewResponse?.data?.credit_notes.map(
      (creditNote: any) => creditNote?.content
    )
    yield put(setPreviewCreditNoteEntityAction(creditNoteHtml))
  }
}
