import { getEnvConfig } from 'utils/getEnvConfig'
import { CL_VERSIONS } from 'utils/common/common.types'
import { FetchOrdersApiResponse, OrdersResponse } from 'state/new-summary/orders/orders.types'
import ApiService from '../ApiService'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor,
  businessIdInterceptor
} from '../apiInterceptor'
import { IApiResponse } from '../../../constants'
import {
  BankWiseApiPayload,
  BankWiseResponse,
  CREATE_LIST_RESPONSE,
  CREATE_LIST_V2_RESPONSE,
  FETCH_COLLECTION_LIST_V2_RESPONSE,
  FETCH_LIST_API_RESPONSE,
  FETCH_PERMISSION_RESPONSE,
  FILTER_OPTION,
  FetchCustomerProfilesAPIPayload,
  IAutoAssignConfig,
  LIST_USAGE_TYPE,
  ListCatalogueItemsPayload,
  UPDATE_BILL_AMOUNT_RESPONSE,
  UpdateOrderApiPaylod,
  UpdateProformaStatusApiPaylod,
  fetchOrdersPayload
} from './type'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

let endpoint: string = ''
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    endpoint = getEnvConfig('STAFF_LINK_BASE_URL')
  } else {
    endpoint = `${window.location.origin}/api/staff-link`
  }
}

const api = new ApiService(endpoint, headers)
api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

const FETCH_COLLECTION_LIST_V2 = '/v2/GetCollectionListBillDetails'
const BULK_CANCEL_INVOICE = '/v1/bills/bulk-cancel'
const CREATE_LIST_V2 = '/v2/CreateCollectionListOfBills'
const CREATE_LIST = '/v1/CreateCollectionList'
const GET_BILLS_BY_TAGS = 'v1/FilterBillsByTags'
const UPDATE_LIST = '/v1/UpdateCollectionList'
const UPDATE_LIST_V2 = '/v2/UpdateCollectionListOfBills'
const FETCH_LIST = '/v3/GetCL'
const UPDATE_MULTIPLE_TRANSACTION = '/v1/UpdateMultipleTransactions?updated_by=accountant'
const STAFF_ACCESS_LIST = '/v1/ListStaffAccess'
const ALL_CUSTOMER_PROFILES = '/v1/ListCustomerProfiles'
const UPDATED_BILL_AMOUNT = '/web/v1/update-bill-amount'
const CASH_BY_STAFF_BANK_WISE = '/v1/BankwiseReport'
const FETCH_ORDERS = '/web/orders/v1/GetOrders'
const UPDATE_ORDERS = '/web/orders/v1/UpdateOrder'
const UPDATE_MULTIPLE_ORDERS = '/web/orders/v1/UpdateMultipleOrders'
const LIST_CATALOGUE_ITEMS = '/web/orders/v1/ListInventoryItems'
const CREATE_ORDER_BILL = '/web/orders/v1/CreateBill'
const CREATE_ORDER_PROFORMA = '/web/orders/v1/CreateProformaInvoice'
const CREATE_ORDER_PROFORMA_SETTLEMENT = '/web/orders/v1/CreateProformaInvoiceSettlement'
const UPDATE_PROFORMA_STATUS = '/web/orders/v1/UpdateProformaInvoiceStatus'

const USER_PERMISSIONING = '/permissions/v1/UserPermissions'
const GET_SELECTED_ORDER_SETTING_ACCOUNT_IDS = '/web/orders/v1/cl/setting/accounts/list'
const ADD_SELECTED_ORDER_SETTING_ACCOUNT_IDS = '/web/orders/v1/cl/setting/accounts'
const DESELECT_ORDER_SETTING_ACCOUNT_IDS = '/web/orders/v1/cl/setting/accounts/inactive'

export const fetchUserPermission = (payload: {
  user_id: string
}): Promise<IApiResponse<FETCH_PERMISSION_RESPONSE>> => {
  return api.getRequest(USER_PERMISSIONING, {}, { 'okc-user-id': payload.user_id })
}

export const fetchList = (
  listId: string,
  version?: CL_VERSIONS
): Promise<IApiResponse<FETCH_LIST_API_RESPONSE>> => {
  return api.postRequest(FETCH_LIST, {
    list_id: listId,
    ...((version || version === CL_VERSIONS.PREVIOUS_ASSIGNMENT) && { version })
  })
}

/** To Remove this API once, new API is integrated */
export const fetchSingleCollectionListV2Api = (
  businessId: string,
  collectionListId: string,
  version: CL_VERSIONS
): Promise<IApiResponse<FETCH_COLLECTION_LIST_V2_RESPONSE>> => {
  return api.postRequest(`${FETCH_COLLECTION_LIST_V2}`, {
    business_id: businessId,
    collection_list_id: collectionListId,
    version
  })
}

export const bulkCancelInvoiceReq = (
  billIds: string[]
): Promise<
  IApiResponse<{
    errors: any[]
    message: 'SUCCESS' | 'FAILURE'
    status: '200' | string
  }>
> => {
  return api.postRequest(BULK_CANCEL_INVOICE, { bill_ids: billIds })
}

export const createNewListV2Api = (payload: {
  name: string
  bills: {
    bill_id: string
    customer_id: string
  }[]
  due_config: {
    config: number
  }
  usage_type: number
}): Promise<IApiResponse<CREATE_LIST_V2_RESPONSE>> => {
  return api.postRequest(CREATE_LIST_V2, payload)
}

export const createNewList = (payload: {
  name: string
  associated_account_ids: string[]
  due_config: {
    config: number
  }
  usage_type: number
}): Promise<IApiResponse<CREATE_LIST_RESPONSE>> => {
  return api.postRequest(CREATE_LIST, payload)
}

export const fetchBillsByTags = (payload: {
  tag_ids: string[]
  filter_option: FILTER_OPTION
}): Promise<
  IApiResponse<{
    bill_ids: string[]
  }>
> => {
  return api.postRequest(GET_BILLS_BY_TAGS, payload)
}

export const updateListV2 = (payload: {
  collection_list: {
    id: string
    name?: string
    usage_type?: LIST_USAGE_TYPE
    associated_account_ids?: string[] | undefined
    bills?: { bill_id: string; customer_id: string }[] | undefined
    business_id?: string
    due_config?: {
      config: number
    }
    auto_assign_config?: IAutoAssignConfig[]
    settings?: {
      all_pending_invoices?: boolean
    }
  }
  action:
    | 'add_bills'
    | 'remove_bills'
    | 'replace_bills'
    | 'due_config'
    | 'move_bills'
    | 'edit_name2'
    | 'update_filters'
    | 'mark_inactive'
    | 'update_usage_type'
    | 'update_settings'
}): Promise<IApiResponse<FETCH_COLLECTION_LIST_V2_RESPONSE>> => {
  return api.postRequest(UPDATE_LIST_V2, payload)
}

export const updateList = (payload: {
  collection_list: {
    id: string
    associated_account_ids: string[]
  }
  action: 'edit_associations' | 'remove_associations'
}) => {
  return api.postRequest(UPDATE_LIST, payload)
}

export const approveBulkCollectionStaffLinkV2 = (
  transactions: Array<{
    id: string
    business_id: string
    approved_by_merchant: boolean
  }>
) => {
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions
  })
}

export const deleteBulkTransactions = (
  transactions: Array<{
    id: string
    business_id: string
    is_deleted: boolean
  }>
) => {
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions
  })
}

export const moveList = (payload: {
  collectionListId: string
  accountIds: string[]
  targetCollectionListId: string
}) => {
  return api.postRequest(UPDATE_LIST, {
    collection_list: {
      id: payload.collectionListId,
      associated_account_ids: payload.accountIds
    },
    action: 'move_associated_accounts',
    target_collection_list_id: payload.targetCollectionListId
  })
}

export const moveListV2 = (payload: {
  collectionListId: string
  bills: { bill_id: string; customer_id: string }[]
  targetCollectionListId: string
}) => {
  return api.postRequest(UPDATE_LIST_V2, {
    collection_list: {
      id: payload.collectionListId,
      bills: payload.bills
    },
    action: 'move_bills',
    target_collection_list_id: payload.targetCollectionListId
  })
}

export const getStaffAccessListApi = (payload: {
  staffUid?: string
  collectionListId?: string
}) => {
  const { staffUid, collectionListId } = payload
  const queryParams = new URLSearchParams()
  if (staffUid) {
    queryParams.append('staff_uid', staffUid)
  }
  if (collectionListId) {
    queryParams.append('resource_id', collectionListId)
  }
  const url = STAFF_ACCESS_LIST + (queryParams.toString() ? `?${queryParams.toString()}` : '')
  return api.getRequest(url, {})
}

export const getAllCustomerProfilesApi = (payload: FetchCustomerProfilesAPIPayload) => {
  return api.postRequest(ALL_CUSTOMER_PROFILES, payload)
}

export const updateBillAmount = ({
  billId,
  amountInPaisa,
  billVersion
}: {
  billId: string
  amountInPaisa: number
  billVersion: number
}): Promise<IApiResponse<UPDATE_BILL_AMOUNT_RESPONSE>> => {
  return api.postRequest(UPDATED_BILL_AMOUNT, {
    bill_id: billId,
    amount: amountInPaisa,
    bill_version: billVersion
  })
}

export const getBankWiseSummaryApi = (
  payload: BankWiseApiPayload
): Promise<IApiResponse<BankWiseResponse>> => {
  return api.postRequest(CASH_BY_STAFF_BANK_WISE, payload)
}

export const fetchOrdersApi = (
  payload: fetchOrdersPayload
): Promise<IApiResponse<FetchOrdersApiResponse>> => {
  return api.postRequest(FETCH_ORDERS, payload)
}

export const updateOrdersApi = (
  payload: UpdateOrderApiPaylod
): Promise<IApiResponse<OrdersResponse>> => {
  return api.putRequest(UPDATE_ORDERS, payload)
}

export const updateProformaStatusApi = (payload: UpdateProformaStatusApiPaylod) => {
  return api.postRequest(UPDATE_PROFORMA_STATUS, payload)
}

export const updateMultipleOrdersApi = (
  payload: Array<UpdateOrderApiPaylod>
): Promise<IApiResponse<Array<UpdateOrderApiPaylod>>> => {
  return api.putRequest(UPDATE_MULTIPLE_ORDERS, { update_orders: payload })
}

export const listCatalogueItemsApi = (
  payload: ListCatalogueItemsPayload
): Promise<IApiResponse<any>> => {
  return api.postRequest(LIST_CATALOGUE_ITEMS, payload)
}

export const createOrderBillApi = (payload: any): Promise<IApiResponse<any>> => {
  return api.postRequest(CREATE_ORDER_BILL, payload)
}

export const createOrderProformaApi = (payload: any): Promise<IApiResponse<any>> => {
  return api.postRequest(CREATE_ORDER_PROFORMA, payload)
}

export const createOrderProformaSettlementApi = (payload: any): Promise<IApiResponse<any>> => {
  return api.postRequest(CREATE_ORDER_PROFORMA_SETTLEMENT, payload)
}

export const fetchSelectedOrderSettingAccountIds = (listId: string): Promise<IApiResponse<any>> => {
  return api.postRequest(GET_SELECTED_ORDER_SETTING_ACCOUNT_IDS, {
    list_id: listId
  })
}
export const addSelectedOrderSettingAccountIds = (
  listId: string,
  accountIds: string[]
): Promise<IApiResponse<any>> => {
  return api.postRequest(ADD_SELECTED_ORDER_SETTING_ACCOUNT_IDS, {
    list_id: listId,
    account_ids: accountIds
  })
}

export const deSelectedOrderSettingAccountIds = (
  listId: string,
  accountIds: string[]
): Promise<IApiResponse<any>> => {
  return api.postRequest(DESELECT_ORDER_SETTING_ACCOUNT_IDS, {
    list_id: listId,
    account_ids: accountIds
  })
}
