import { Action } from 'infra/types'
import { CL_VERSIONS } from 'utils/common/common.types'
import {
  FETCH_COLLECTION_LIST_V2_RESPONSE,
  FETCH_LIST_API_RESPONSE,
  IReplacement
} from 'services/Api/StaffLinkApiFolder/type'
import {
  IApproveTable,
  INoActionsTable,
  IPendingTable,
  IRescheduledDeliverieTable,
  ISelectedSettingsForAddTxn
} from './list.types'
import { ICollectionList, INewCollectionListState } from './collection-list.types'
export const CollectionListActions = {
  FETCH_SPECIFIC_LIST: 'collectionList/fetchSpecificList',
  FETCH_COLLECTION_LIST: 'collectionList/fetchCollectionList',
  SET_COLLECTION_LIST: 'collectionList/setCollectionList',
  SET_TABLE_DATA: 'collectionList/setTableData',
  SET_ON_HOLD_BILL_DATA: 'collectionList/setOnHoldBillData',
  SET_SUMMARY_DATA: 'collectionList/setSummaryData',
  SET_ALL_ASSIGNMENT_FILTER: 'collectionList/setAllAssignmentFilter',
  SET_SELECTED_ASSIGNMENT_FILTER: 'collectionList/setSelectedAssignmentFilter',
  SET_SELECTED_LIST: 'collectionList/setSelectedList',
  SET_DRAWER: 'collectionList/setDrawers',
  SET_LOADER: 'collectionList/setLoaders',
  ADD_TXN_TO_COLLECTION: 'collectionList/addTxnToCollection',
  SET_REPLACEMENT_DRAWER_DATA: 'collectionList/setReplacementDrawerData',
  SET_BEATS_FOR_TABLE: 'collectionList/setBeatsTableData',
  SET_EXISTING_ACCOUNT_IDS_OR_BILLS: 'collectionList/setExistingAccountIdsOrBills',
  SET_COLLECTION_LIST_STORE: 'collectionList/setStore',
  SET_BEAT_FILTER_DRAWER_DATA: 'collectionList/setBeatFilterDrawerData',
  RESET_BEAT_FILTER_DRAWER_DATA: 'collectionList/resetBeatFilterDrawerData',
  SET_ROUTE_FILTER_DRAWER_DATA: 'collectionList/setRouteFilterDrawerData',
  RESET_ROUTE_FILTER_DRAWER_DATA: 'collectionList/resetRouteFilterDrawerData',
  ADD_ACCOUNT_ID_TO_ORDER_SETTING_LIST: 'collectionList/addAccountIdToOrderSettingList',
  UPDATE_ACCOUNT_ID_TO_ORDER_SETTING_LIST: 'collectionList/updateAccountIdToOrderSettingList'
}

export const fetchCollectionListAction = (payload: any): Action<any> => {
  return {
    type: CollectionListActions.FETCH_COLLECTION_LIST,
    payload
  }
}

export const addTxnToCollectionClick = (
  payload: ISelectedSettingsForAddTxn
): Action<ISelectedSettingsForAddTxn> => ({
  type: CollectionListActions.ADD_TXN_TO_COLLECTION,
  payload
})

export const fetchSpecificListAction = (payload: {
  listId: string
  version?: CL_VERSIONS
}): Action<{ listId: string; version?: CL_VERSIONS }> => {
  return {
    type: CollectionListActions.FETCH_SPECIFIC_LIST,
    payload
  }
}

export const setReplacementDrawerWithData = (payload: {
  status: boolean
  data?: IReplacement
}): Action<{ status: boolean; data?: IReplacement }> => {
  return {
    type: CollectionListActions.SET_REPLACEMENT_DRAWER_DATA,
    payload
  }
}

export const setCollectionLists = (payload: ICollectionList[]): Action<ICollectionList[]> => ({
  type: CollectionListActions.SET_COLLECTION_LIST,
  payload
})

export const setSelectedList = (
  payload: FETCH_COLLECTION_LIST_V2_RESPONSE['collection_list']
): Action<FETCH_COLLECTION_LIST_V2_RESPONSE['collection_list']> => ({
  type: CollectionListActions.SET_SELECTED_LIST,
  payload
})

export const setCollectionDrawers = (payload: {
  drawerName: keyof INewCollectionListState['drawers']
  status: boolean
}): Action<{
  drawerName: keyof INewCollectionListState['drawers']
  status: boolean
}> => ({
  type: CollectionListActions.SET_DRAWER,
  payload
})

export const setCollectionLoaders = (payload: {
  loaderName: keyof INewCollectionListState['loaders']
  status: boolean
}): Action<{
  loaderName: keyof INewCollectionListState['loaders']
  status: boolean
}> => ({
  type: CollectionListActions.SET_LOADER,
  payload
})

export const setTablesData = (payload: {
  pendingTable: IPendingTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
}): Action<{
  pendingTable: IPendingTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
}> => ({
  type: CollectionListActions.SET_TABLE_DATA,
  payload
})

export const setonHoldBillData = (payload: {
  onHoldBillsData: INewCollectionListState['onHoldBillsData']
}): Action<{
  onHoldBillsData: INewCollectionListState['onHoldBillsData']
}> => ({
  type: CollectionListActions.SET_ON_HOLD_BILL_DATA,
  payload
})

export const setBeatsTableData = (payload: {
  beatsforNoActionTable: string[]
  beatsforApproveTable: string[]
  beatsforPendingActionTable: string[]
}): Action<{
  beatsforNoActionTable: string[]
  beatsforApproveTable: string[]
  beatsforPendingActionTable: string[]
}> => ({
  type: CollectionListActions.SET_BEATS_FOR_TABLE,
  payload
})

export const setSummaryData = (
  payload: INewCollectionListState['summaryData']
): Action<INewCollectionListState['summaryData']> => ({
  type: CollectionListActions.SET_SUMMARY_DATA,
  payload
})

export const setAllAssignmentFilter = (
  payload: Record<CL_VERSIONS, number>
): Action<Partial<Record<CL_VERSIONS, number>>> => ({
  type: CollectionListActions.SET_ALL_ASSIGNMENT_FILTER,
  payload
})

export const setSelectedAssignmentFilter = (payload: CL_VERSIONS): Action<CL_VERSIONS> => ({
  type: CollectionListActions.SET_SELECTED_ASSIGNMENT_FILTER,
  payload
})

export const setExistingAccountIdsOrBills = (payload: {
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
}): Action<{
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
}> => ({
  type: CollectionListActions.SET_EXISTING_ACCOUNT_IDS_OR_BILLS,
  payload
})

export const setCollectionListStore = (payload: {
  allAssignmentFilter: Record<CL_VERSIONS, number>
  selectedAssignmentFilter: CL_VERSIONS
  pendingTable: IPendingTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
  rescheduledDeliveries: IRescheduledDeliverieTable[]
  onHoldBillsData: INewCollectionListState['onHoldBillsData']
  summaryData: INewCollectionListState['summaryData']
  selectedList: FETCH_LIST_API_RESPONSE['list']
  beatsforNoActionTable: string[]
  beatsforApproveTable: string[]
  beatsforPendingActionTable: string[]
  routesforNoActionTable: string[]
  routesforApproveTable: string[]
  routesforPendingActionTable: string[]
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
  addedCredit?: number
  listPendingDue?: number
  orderSettingAccountIds: string[]
}): Action<{
  allAssignmentFilter: Partial<Record<CL_VERSIONS, number>>
  selectedAssignmentFilter: CL_VERSIONS
  pendingTable: IPendingTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
  rescheduledDeliveries: IRescheduledDeliverieTable[]
  onHoldBillsData: INewCollectionListState['onHoldBillsData']
  summaryData: INewCollectionListState['summaryData']
  selectedList: FETCH_LIST_API_RESPONSE['list']
  beatsforNoActionTable: string[]
  beatsforApproveTable: string[]
  beatsforPendingActionTable: string[]
  routesforNoActionTable: string[]
  routesforApproveTable: string[]
  routesforPendingActionTable: string[]
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
  addedCredit?: number
  listPendingDue?: number
  orderSettingAccountIds: string[]
}> => ({
  type: CollectionListActions.SET_COLLECTION_LIST_STORE,
  payload
})

export const setBeatFilterDrawerData = (payload: any) => ({
  type: CollectionListActions.SET_BEAT_FILTER_DRAWER_DATA,
  payload
})

export const resetBeatFilterDrawerData = () => ({
  type: CollectionListActions.RESET_BEAT_FILTER_DRAWER_DATA
})

export const setRouteFilterDrawerData = (payload: any) => ({
  type: CollectionListActions.SET_ROUTE_FILTER_DRAWER_DATA,
  payload
})

export const resetRouteFilterDrawerData = () => ({
  type: CollectionListActions.RESET_ROUTE_FILTER_DRAWER_DATA
})

export const addAccountIdToOrderSettingListAction = (payload: {
  accountId: string[]
  listId: string
}): Action<{ accountId: string[]; listId: string }> => {
  return {
    type: CollectionListActions.ADD_ACCOUNT_ID_TO_ORDER_SETTING_LIST,
    payload
  }
}

export const updateAccountIdToOrderSettingListAction = (payload: {
  accountId: string[]
}): Action<{ accountId: string[] }> => {
  return {
    type: CollectionListActions.UPDATE_ACCOUNT_ID_TO_ORDER_SETTING_LIST,
    payload
  }
}
