import { ENTITY_ROLE } from 'state/dashboard/dashboard.types'
import { sortByBillDateAndCreatedTime } from 'pages/Transactions/components/TransactionReport/TransactionReport.helper'
import {
  ICustomerTransaction,
  ISupplierTransaction,
  ITransPagy,
  ITransactionsReport,
  TRANSACTION_STATE,
  TRANSACTION_TYPE
} from '../transaction.types'
import { ENTITY_TYPE } from '../../../constants'

export const getFormattedCustomerTransaction = (
  _: any,
  sum?: number
): ICustomerTransaction & { outStandingAmount: number } => {
  const amountInRupee = Number(_.amount) / 100
  if (
    typeof sum !== 'undefined' &&
    _.transaction_state !== TRANSACTION_STATE.PROCESSING &&
    !_.deleted
  ) {
    sum = Number(_.type) === TRANSACTION_TYPE.PAYMENT ? sum + amountInRupee : sum - amountInRupee
  }
  return {
    accountId: _.account_id,
    amount: amountInRupee,
    amountUpdated: _.amount_updated,
    billedTime: Number(_.bill_date),
    createdTime: Number(_.create_time),
    customerId: _.customer_id,
    collectionId: _.collection_id,
    deleted: _.deleted,
    id: _.id,
    note: _.note,
    updatedTime: Number(_.update_time),
    transactionState: _.transaction_state,
    transactionCategory: _.tx_category,
    transactionType: Number(_.type),
    deletedTime: _.delete_time,
    deletedByCustomer: _.deleter_role === ENTITY_ROLE.BUYER,
    createdByCustomer: _.creator_role === ENTITY_ROLE.BUYER,
    images: _.images,
    referenceSource: Number(_.reference_source),
    referenceId: _.reference_id,
    billNumber: _.bill_number,
    outStandingAmount: sum || 0
  }
}

export const getFormattedSupplierTransaction = (
  _: any,
  sum?: number
): ISupplierTransaction & { outStandingAmount: number } => {
  const amountInRupee = Number(_.amount) / 100
  if (
    typeof sum !== 'undefined' &&
    _.transaction_state !== TRANSACTION_STATE.PROCESSING &&
    !_.deleted
  ) {
    sum = Number(_.type) === TRANSACTION_TYPE.PAYMENT ? sum + amountInRupee : sum - amountInRupee
  }
  return {
    accountId: _.account_id,
    amount: amountInRupee,
    amountUpdated: _.amount_updated,
    billedTime: Number(_.bill_date),
    createdTime: Number(_.create_time),
    collectionId: _.collection_id,
    deleted: _.deleted,
    id: _.id,
    customerId: _.customer_id,
    note: _.note,
    updatedTime: Number(_.update_time),
    transactionState: _.transaction_state,
    transactionCategory: _.tx_category,
    transactionType: Number(_.type),
    deletedTime: _.delete_time,
    deletedBySupplier: _.deleter_role === ENTITY_ROLE.SELLER,
    createdBySupplier: _.creator_role === ENTITY_ROLE.SELLER,
    images: _.images,
    referenceSource: Number(_.reference_source),
    referenceId: _.reference_id,
    billNumber: _.bill_number,
    outStandingAmount: sum || 0
  }
}

export const getFormattedSupplierTransactionBankReconciliation = (
  _: any,
  sum?: number
): any & { outStandingAmount: number } => {
  const amountInRupee = Number(_.amount) / 100
  if (
    typeof sum !== 'undefined' &&
    _.transaction_state !== TRANSACTION_STATE.PROCESSING &&
    !_.deleted
  ) {
    sum = Number(_.type) === TRANSACTION_TYPE.PAYMENT ? sum + amountInRupee : sum - amountInRupee
  }
  return {
    account_id: _.account_id,
    amount: amountInRupee * 100,
    amount_updated: _.amount_updated,
    bill_date: _.bill_date,
    create_time: Number(_.create_time),
    collection_id: _.collection_id,
    deleted: _.deleted,
    id: _.id,
    customer_id: _.customer_id,
    note: _.note,
    update_time: Number(_.update_time),
    transaction_state: _.transaction_state,
    tx_category: _.tx_category,
    type: _.type,
    delete_time: _.delete_time,
    deletedBySupplier: _.deleter_role === ENTITY_ROLE.SELLER,
    createdBySupplier: _.creator_role === ENTITY_ROLE.SELLER,
    images: _.images,
    referenceSource: Number(_.reference_source),
    referenceId: _.reference_id,
    bill_number: _.bill_number,
    outStandingAmount: sum || 0
  }
}

export const getFormattedTransactionReport = (
  _: any,
  entityType: ENTITY_TYPE
): ITransactionsReport => {
  return _.reduce(
    (transactionReport: ITransactionsReport, transaction: any) => {
      const isPayment = Number(transaction.type) === TRANSACTION_TYPE.PAYMENT
      const { paymentCount, creditCount, payment, credit } = transactionReport
      return {
        ...transactionReport,
        paymentCount: isPayment && !transaction.deleted ? paymentCount + 1 : paymentCount,
        creditCount: !isPayment && !transaction.deleted ? creditCount + 1 : creditCount,
        payment: isPayment && !transaction.deleted ? payment + transaction.amount / 100 : payment,
        credit: !isPayment && !transaction.deleted ? credit + transaction.amount / 100 : credit,
        transactions: sortByBillDateAndCreatedTime([
          ...transactionReport.transactions,
          entityType === ENTITY_TYPE.CUSTOMER
            ? getFormattedCustomerTransaction(transaction)
            : getFormattedSupplierTransaction(transaction)
        ])
      }
    },
    {
      paymentCount: 0,
      creditCount: 0,
      payment: 0,
      credit: 0,
      transactions: [],
      reportUrl: ''
    }
  )
}
