import { convertPaisaToRupee } from 'state/billing/billing.helpers'
import { ICustomer } from 'state/dashboard/dashboard.types'
import { CreditNoteStatusEnum, ICreditNote } from 'state/new-summary/creditNote/creditNote.types'

export const getFormattedCreditNoteDetail = (
  creditNoteDetails: any,
  customers: {
    [id: string]: ICustomer
  }
): ICreditNote[] => {
  return creditNoteDetails.map((data: any) => {
    return {
      creditNoteId: data.cnId,
      creditNoteNumber: data.cnNumber,
      customer: {
        customerName: customers[data.customer_id]?.description,
        customerAddress: '',
        customerId: data?.customer_id,
        customerMobile: '',
        customerCode: '',
        customerGST: '',
        customerStateSupplyCode: ''
      },
      date: Number(data.date) / 1000,
      createdAgainst: data.created_against?.bill_number,
      createdAgainstId: data.created_against?.bill_id || '',
      amount: convertPaisaToRupee(data.amount),
      settleAgainst: data.settled_against?.map((bill: any) => ({
        billId: bill.bill_id,
        billNumber: bill.bill_number,
        hasLineItems: !!bill.item_level
      })),
      status: Number(data.status) || CreditNoteStatusEnum.PENDING,
      haveLineItem: data.cn_item_level || false,
      haveAgainstBill: data.created_against?.item_level || false,
      remainingAmount: convertPaisaToRupee(data.remaining_amount || 0)
    }
  })
}
