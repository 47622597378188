import { combineReducers } from 'redux'
import { BillsReducer } from 'state/bills/bills.reducers'
import { TransactionsReducers } from 'state/transaction/transaction.reducers'
import { AuthReducers } from 'state/auth/auth.reducers'
import { DashboardReducers } from 'state/dashboard/dashboard.reducers'
import { MigrationReducers } from 'state/migration/migration.reducers'
import { NotificationReducer } from 'pages/Notification/Notification.reducers'
import { NavigationReducers } from 'state/navigation/navigation.reducers'
import { BlindpayReducer } from 'state/blindpay/blindpay.reducers'
import { DeviceReducer } from 'state/device/device.reducers'
import { StaffLinkReducers } from 'experiments/StaffLink/StaffLink.reducers'
import { OrderQRReducers } from 'experiments/OrderQR/OrderQR.reducers'
import { AgentDashboardReducer } from 'state/agent-dashboard/agent-dashboard.reducers'
import { TagsReducer } from 'state/tags/tags.reducers'
import { BeatsReducer } from 'state/beats/beats.reducers'
import { CollectionListReducer } from 'state/collectionList/collectionList.reducers'
import { StatementsReducer } from 'state/statements/statements.reducers'
import { ExpReducers } from 'state/experiment/experiment.reducer'
import { CollectionSummaryReducer } from 'state/collectionSummary/collectionSummary.reducers'
import { ApprovedCollectionSummaryReducer } from 'state/new-summary/approvedCollectionSummary/approvedCollectionSummary.reducers'
import { PendingDueSummaryReducer } from 'state/pendingDueSummary/pendingDueSummary.reducers'
import { InventoryReducer } from 'state/inventory/inventory.reducers'
import { OnboardingReducer } from 'state/onboarding/onboarding.reducers'
import { BillingReducer } from 'state/billing/billing.reducers'
import { SupplySummaryReducer } from 'state/new-summary/supply/supply.reducer'
import { ApproveReducer } from 'state/new-summary/approve/approve.reducer'
import { SupplyListReducer } from 'state/new-summary/list/supply-list.reducer'
import { CreateNewListReducer } from 'state/new-summary/root-drawers/create-new-list/create-new-list.reducer'
import { ListSettingsReducer } from 'state/new-summary/root-drawers/list-settings/list-settings.reducer'
import { TagFilterReducer } from 'state/new-summary/root-drawers/tag-filter/tag-filter.reducer'
import { UnidentifiedCollectionReducer } from 'state/new-summary/unidentifiedCollection/unidentifiedCollection.reducer'
import { UploadSheetReducer } from 'state/new-summary/uploadSheet/uploadSheet.reducer'
import { NewCollectionListReducer } from 'state/new-summary/list/collection-list.reducer'
import { AssignStaffReducer } from 'state/new-summary/root-drawers/assign-staff/assign-staff.reducer'
import { AccountantSummaryReducer } from 'state/new-summary/root-drawers/accountant-summary/accountant-summary.reducer'
import { ReplacementReducer } from 'state/new-summary/root-drawers/replacement/replacement.reducer'
import { WhatsappSuccessReducer } from 'state/new-summary/root-drawers/whatsapp-success/whatsapp-success.reducer'
import { AddToCollectionReducer } from 'state/new-summary/root-drawers/addToCollection/addToCollection.reducer'
import { MoveToAnotherListReducer } from 'state/new-summary/root-drawers/moveToAnotherList/moveToAnotherList.reducer'
import { CreateEditCustomerReducer } from 'state/new-summary/root-drawers/create-edit-customer/create-edit-customer.reducer'
import { BankStatementReducer } from 'state/new-summary/bankStatement/bankStatement.reducer'
import { ChequeReducer as NewChequeReducer } from 'state/new-summary/cheque/cheque.reducer'
import { ReturnsReducer } from 'state/new-summary/root-drawers/returns/returns.reducer'
import { DamageReducer } from 'state/new-summary/root-drawers/damage/damage.reducer'
import { CashByStaffReducer } from 'state/summary/cashByStaff/cashByStaff.reducer'
import { OrdersReducer } from 'state/new-summary/orders/orders.reducer'
import { BeatFilterReducer } from 'state/new-summary/root-drawers/beat-filter/beat-filter.reducer'
import { ApplyTagsReducer } from 'state/new-summary/root-drawers/apply-tags/apply-tags.reducer'
import { ItemOfferAndDiscountsReducer } from 'state/new-summary/root-drawers/items-offer-and-discount/items-offer-and-discount.reducer'
import { CreditNotesReducer } from 'state/new-summary/creditNote/creditNote.reducer'

const createRootReducer = () =>
  combineReducers({
    Auth: AuthReducers,
    Dashboard: DashboardReducers,
    Notification: NotificationReducer,
    Transactions: TransactionsReducers,
    Navigation: NavigationReducers,
    Blindpay: BlindpayReducer,
    Migration: MigrationReducers,
    Device: DeviceReducer,
    StaffLink: StaffLinkReducers,
    OrderQR: OrderQRReducers,
    AgentDashboard: AgentDashboardReducer,
    Tags: TagsReducer,
    Beats: BeatsReducer,
    CollectionList: CollectionListReducer,
    Statements: StatementsReducer,
    Bills: BillsReducer,
    Experiment: ExpReducers,
    Onboarding: OnboardingReducer,
    Billing: BillingReducer,
    Inventory: InventoryReducer,

    NewSummary: NewSummaryReducer,
    Approve: ApproveReducer,
    SupplyList: SupplyListReducer,
    NewCollectionList: NewCollectionListReducer,
    RootDrawers: RootDrawerReducer,
    UnidentifiedCollection: UnidentifiedCollectionReducer,
    Reconcile: ReconcileReducer,
    Orders: OrdersReducer,
    CreditNote: CreditNotesReducer
  })

const NewSummaryReducer = combineReducers({
  SupplySummary: SupplySummaryReducer,
  PendingDueSummary: PendingDueSummaryReducer,
  CollectionSummary: CollectionSummaryReducer,
  CashByStaff: CashByStaffReducer,
  ApprovedCollectionSummary: ApprovedCollectionSummaryReducer
})

const ReconcileReducer = combineReducers({
  BankStatement: BankStatementReducer,
  Cheque: NewChequeReducer
})

const RootDrawerReducer = combineReducers({
  CreateNewList: CreateNewListReducer,
  ListSettings: ListSettingsReducer,
  TagFilter: TagFilterReducer,
  BeatFilter: BeatFilterReducer,
  UploadSheet: UploadSheetReducer,
  AssignStaff: AssignStaffReducer,
  AccountantSummary: AccountantSummaryReducer,
  Replacement: ReplacementReducer,
  WhatsappSuccess: WhatsappSuccessReducer,
  CreateEditCustomer: CreateEditCustomerReducer,
  AddToCollection: AddToCollectionReducer,
  MoveToAnotherList: MoveToAnotherListReducer,
  Returns: ReturnsReducer,
  Damage: DamageReducer,
  ApplyTags: ApplyTagsReducer,
  ItemOfferAndDiscounts: ItemOfferAndDiscountsReducer
})

export default createRootReducer
