import { getLanguage, getLanguageFromKey } from 'utils/getLanguage'
import { Action } from '../../infra/types'
import { AuthActions } from './auth.actions'
import { AuthState } from './auth.state'
import { IAuthState } from './auth.types'

export const AuthReducers = (state: IAuthState = AuthState, action: Action<any>): IAuthState => {
  switch (action.type) {
    // Verify OTP
    case AuthActions.VERIFY_OTP: {
      return {
        ...state,
        authDevise: null
      }
    }

    case AuthActions.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        authDevise: null
      }
    }

    case AuthActions.VERIFY_OTP_FAILURE: {
      return {
        ...state,
        authDevise: 'OTP Incorrect'
      }
    }

    case AuthActions.NAVIGATE_TO_DASHBOARD: {
      return {
        ...state,
        otpTimer: 0
      }
    }
    case AuthActions.SET_USER_LANGUAGE: {
      return {
        ...state,
        userData: {
          ...state.userData,
          language: getLanguageFromKey(action.payload)
        }
      }
    }
    case AuthActions.CHANGE_LANGUAGE: {
      return {
        ...state,
        userData: {
          ...state.userData,
          language: getLanguage(action.payload.languageId)
        }
      }
    }
    case AuthActions.ENTER_PHONE_NUMBER: {
      return {
        ...state,
        userData: {
          ...state.userData,
          phoneNumber: action.payload
        }
      }
    }
    case AuthActions.ENTER_OTP: {
      return {
        ...state,
        userData: {
          ...state.userData,
          otp: action.payload
        }
      }
    }
    case AuthActions.ENTER_PIN: {
      return {
        ...state,
        userData: {
          ...state.userData,
          pin: action.payload
        }
      }
    }
    case AuthActions.OPEN_AUTH_DIALOG: {
      return {
        ...state,
        isAuthDialogOpen: true
      }
    }
    case AuthActions.OPEN_AUTH_PASSWORD_DIALOG: {
      return {
        ...state,
        isAuthPasswordDialogOpen: action.payload
      }
    }
    case AuthActions.CLOSE_AUTH_DIALOG: {
      return {
        ...state,
        isAuthDialogOpen: false
      }
    }
    case AuthActions.START_TIMER: {
      return {
        ...state,
        otpTimer: 15
      }
    }
    case AuthActions.END_TIMER: {
      return {
        ...state,
        otpTimer: 0
      }
    }
    case AuthActions.UPDATE_TIMER: {
      return {
        ...state,
        otpTimer: action.payload
      }
    }
    case AuthActions.GET_OTP_SUCCESS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          otpId: action.payload
        }
      }
    }
    case AuthActions.SHOW_SUCCESS_MODAL: {
      return {
        ...state,
        isSuccessDialogOpen: true,
        isAuthDialogOpen: false
      }
    }
    case AuthActions.SHOW_SELECT_ACCOUNT_MODAL: {
      return {
        ...state,
        isSelectAccountDialogOpen: true,
        isAuthDialogOpen: false
      }
    }
    case AuthActions.CLOSE_SELECT_ACCOUNT_MODAL: {
      return {
        ...state,
        isSelectAccountDialogOpen: false
      }
    }
    case AuthActions.USER_LOGGED_IN: {
      return {
        ...state,
        userData: {
          ...state.userData,
          isLoggedIn: true,
          isNewUser: action.payload
        }
      }
    }
    case AuthActions.USER_LOGGED_OUT: {
      return {
        ...state,
        userData: {
          ...state.userData,
          authData: undefined,
          isLoggedIn: false
        }
      }
    }
    case AuthActions.SYNC_WITH_LOCAL_STORAGE_SUCCESS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          isLoggedIn: action.payload.isLoggedIn,
          isNewUser: action.payload.isNewUser
        }
      }
    }
    default: {
      return state
    }
  }
}
