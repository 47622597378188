import React, { ReactNode } from 'react'
import { useHistory, useLocation } from 'react-router'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { newPaths } from 'routes'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { TabsContainerStyles } from './styles'

const TabsContainer = ({
  tabs
}: {
  tabs: {
    icon: ReactNode
    title: string
    routeName: string
  }[]
}) => {
  const classes = TabsContainerStyles()
  return (
    <div className={classes.container}>
      {tabs.map((tab, index) => {
        return <IconTitle key={`tab_${tab.title}_icons_${index}`} tab={tab} />
      })}
    </div>
  )
}

const pathNameToEventMapper = {
  [newPaths.approve]: NEWDASHBOARD_EVENTS.APPROVE_TAB,
  [newPaths.supplyList]: NEWDASHBOARD_EVENTS.SUPPLY_TAB,
  [newPaths.collectionList]: NEWDASHBOARD_EVENTS.COLLECTION_TAB,
  [newPaths.summary]: NEWDASHBOARD_EVENTS.SUMMARY_TAB,
  [newPaths.ledger]: NEWDASHBOARD_EVENTS.LEDGER_TAB,
  [newPaths.reconcile]: NEWDASHBOARD_EVENTS.RECONCILE_TAB,
  [newPaths.billing]: NEWDASHBOARD_EVENTS.BILLING_TAB,
  [newPaths.chequeManagement]: NEWDASHBOARD_EVENTS.CHEQUE_TAB,
  [newPaths.settings]: NEWDASHBOARD_EVENTS.SETTINGS_TAB,
  [newPaths.manageAccounts]: NEWDASHBOARD_EVENTS.PROFILE_TAB,
  [newPaths.editMerchant]: NEWDASHBOARD_EVENTS.EDIT_MERCHANT,
  [newPaths.help]: NEWDASHBOARD_EVENTS.HELP_TAB
}

const IconTitle = ({
  tab
}: {
  tab: {
    icon: ReactNode
    title: string
    routeName: string
  }
}) => {
  const history = useHistory()
  const classes = TabsContainerStyles()
  const location = useLocation()
  const dispatch = useDispatch()

  const handleIconClick = (routeName: string) => {
    history.push(routeName)
    if (pathNameToEventMapper[routeName]) {
      dispatch(pushEvent(pathNameToEventMapper[routeName]))
    }
  }

  const isSelected = location?.pathname.split('/').at(2) === tab.routeName?.split('/').at(2)

  return (
    <div className={classes.iconTitleContainer} onClick={() => handleIconClick(tab.routeName)}>
      <div
        className={classNames(classes.iconContainer, {
          [classes.activeIconContainer]: isSelected
        })}
      >
        {tab.icon}
      </div>
      <span
        className={classNames(classes.title, {
          [classes.activeText]: isSelected
        })}
      >
        {tab.title}
      </span>
    </div>
  )
}

export default TabsContainer
