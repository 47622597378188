export enum LINK_STATUS {
  ACTIVE = 'ACTIVE',
  CLAIMED = 'CLAIMED',
  EXPIRED = 'EXPIRED',
  INITIATED = 'INITIATED'
}
export interface IPaymentDetail {
  amount: number
  senderName: string
  senderMobile: string
  receiverMobile: string
  paidAt: number
  expiryTime: number
  accountId: string
  senderMerchantId: string
  linkStatus: LINK_STATUS | null
}

export interface IVerfiedPaymentAddress {
  name: string
  isValid: boolean
  isVerifying: boolean
}

export interface IBlindpayState {
  paymentDetail: IPaymentDetail
  otpId: string
  txnToken: string
  expiryTime: number
  verifiedPaymentAddress: IVerfiedPaymentAddress
  paymentId: string
  sessionExpired: boolean
  isLoading: boolean
  authDevise?: string | null
}
