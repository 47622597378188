import dayjs from 'dayjs'
import { IDateFilter, ISummaryType } from 'pages/Statements/Statements.constants'
import { keyMapper } from 'utils/keyMapper'
import {
  CollectionInvoiceList,
  IInvoiceDownloadData,
  InvoiceWithTransactions,
  IOwnerSummaryOverall,
  ISummaryStatForInvoiceAccountSummary,
  ISummaryStatForInvoiceDownload,
  ISummaryStatForInvoiceSummary,
  OnlineCollectionTypeResponse,
  ReplacementDataType,
  ReplacementRowJson,
  RowJSON,
  Transaction,
  VIEW
} from './statements.types'

export const INVOICE_RESULTS_PER_PAGE = 16

// DEPRECATED formatSummaryStat function
const formatSummaryStat = (summaryStat: any) => {
  return {
    id: summaryStat?.id,
    name: summaryStat?.name,
    customerCode: summaryStat?.customer_code,
    accountCount: Number(summaryStat?.number_of_accounts),
    totalCredit: Number(summaryStat?.total_credits) / 100,
    totalPayment: Number(summaryStat?.total_payments) / 100,
    netBalanceDue: Number(summaryStat?.net_balance_due) / 100,
    totalCash: Number(summaryStat?.total_cash_or_neft_payments) / 100,
    totalOnline: Number(summaryStat?.total_online_payments) / 100,
    totalCheque: Number(summaryStat?.total_cheque_payments) / 100,
    totalReturn: Number(summaryStat?.total_return_payments) / 100,
    totalDamage: Number(summaryStat?.total_damaged_payments) / 100,
    transactions: summaryStat?.transactions
  }
}

// UNUSED as BALANCE_DUE is disabled
const formatColectionSummaryStat = (summaryStat: any) => {
  const {
    total_credits,
    total_payments,
    net_balance_due,
    total_cash_or_neft_payments,
    total_online_payments,
    total_cheque_payments,
    total_return_payments,
    total_damaged_payments
  } = summaryStat.summary_stats
  return {
    id: summaryStat?.id,
    name: summaryStat?.name,
    customerCode: summaryStat?.customer_code,
    accountCount: Number(summaryStat?.number_of_accounts),
    pendingActionRequired: summaryStat?.pending_action_required,
    pendingReplacements: summaryStat?.pending_replacements,
    totalCredit: Number(total_credits) / 100,
    totalPayment: Number(total_payments) / 100,
    netBalanceDue: Number(net_balance_due) / 100,
    totalCash: Number(total_cash_or_neft_payments) / 100,
    totalOnline: Number(total_online_payments) / 100,
    totalCheque: Number(total_cheque_payments) / 100,
    totalReturn: Number(total_return_payments) / 100,
    totalDamage: Number(total_damaged_payments) / 100,
    transactions: []
  }
}

const formatColectionInvoiceSummaryStat = (summaryStat: any): ISummaryStatForInvoiceSummary => {
  const {
    total_dues,
    total_payments,
    balance_due,
    total_cash_payments,
    total_neft_payments,
    total_online_payments,
    total_cheque_payments,
    total_returns,
    total_damages
  } = summaryStat.summary_stats
  return {
    id: summaryStat?.id,
    name: summaryStat?.name,
    invoiceCount: Number(summaryStat?.number_of_invoices),
    pendingActionRequired: summaryStat?.pending_action_required,
    pendingReplacements: summaryStat?.pending_replacements,
    totalCredit: Number(total_dues) / 100,
    totalPayment: Number(total_payments) / 100,
    netBalanceDue: Number(balance_due) / 100,
    totalCash: Number(total_cash_payments) / 100,
    totalNEFT: Number(total_neft_payments) / 100,
    totalOnline: Number(total_online_payments) / 100,
    totalCheque: Number(total_cheque_payments) / 100,
    totalReturn: Number(total_returns) / 100,
    totalDamage: Number(total_damages) / 100
  }
}

const formatColectionAccountInvoiceSummaryStat = (
  summaryStat: any
): ISummaryStatForInvoiceAccountSummary => {
  const {
    total_dues,
    total_payments,
    balance_due,
    total_cash_payments,
    total_neft_payments,
    total_online_payments,
    total_cheque_payments,
    total_returns,
    total_damages,
    total_invoices
  } = summaryStat.summary_stats
  return {
    id: summaryStat?.id,
    name: summaryStat?.name,
    customerCode: summaryStat?.customer_code,
    invoiceCount: Number(total_invoices),
    totalCredit: Number(total_dues) / 100,
    totalPayment: Number(total_payments) / 100,
    netBalanceDue: Number(balance_due) / 100,
    totalCash: Number(total_cash_payments) / 100,
    totalNEFT: Number(total_neft_payments) / 100,
    totalOnline: Number(total_online_payments) / 100,
    totalCheque: Number(total_cheque_payments) / 100,
    totalReturn: Number(total_returns) / 100,
    totalDamage: Number(total_damages) / 100
  }
}

// DEPRECATED formatOwnerSummaryOverall function
const formatOwnerSummaryOverall = (ownerSummaryOverall: any) => {
  return {
    totalCredit: Number(ownerSummaryOverall?.total_credits) / 100,
    totalPayment: Number(ownerSummaryOverall?.total_payments) / 100,
    netBalanceDue: Number(ownerSummaryOverall?.net_balance_due) / 100,
    accountIds: ownerSummaryOverall?.accounts || []
  }
}

// UNUSED formatCollectionListOwnerSummaryOverall function
const formatCollectionListOwnerSummaryOverall = (ownerSummaryOverall: any) => {
  return {
    totalCredit: Number(ownerSummaryOverall?.total_credits) / 100,
    totalPayment: Number(ownerSummaryOverall?.total_payments) / 100,
    netBalanceDue: Number(ownerSummaryOverall?.net_balance_due) / 100,
    accountIds: ownerSummaryOverall?.accounts || []
  }
}

// UNUSED formatCollectionListInvoiceSummaryOverallStats function
export const formatCollectionListInvoiceSummaryOverallStats = (ownerSummaryOverall: any) => {
  return {
    totalCredit: Number(ownerSummaryOverall?.total_dues) / 100,
    totalPayment: Number(ownerSummaryOverall?.total_payments) / 100,
    netBalanceDue: Number(ownerSummaryOverall?.balance_due) / 100,
    accountIds: ownerSummaryOverall?.accounts || [] // TODO
  }
}

export const formatCollectionListSummaryOverallStats = (
  ownerSummaryOverall: any
): IOwnerSummaryOverall => {
  return {
    totalCredit: Number(ownerSummaryOverall?.total_dues) / 100,
    totalPayment: Number(ownerSummaryOverall?.total_payments) / 100,
    netBalanceDue: Number(ownerSummaryOverall?.balance_due) / 100,
    accountCount: Number(ownerSummaryOverall?.total_accounts) // TODO
  }
}

// DEPRECATED getFormattedOwnerSummary function
export const getFormattedOwnerSummary = (data: any) => {
  const { level, summary_stats, owner_summary_overall, total } = data
  return {
    level,
    summaryStats: summary_stats.map(formatSummaryStat),
    ownerSummaryOverall: formatOwnerSummaryOverall(owner_summary_overall),
    total
  }
}

// UNUSED as BALANCE_DUE is disabled
export const getFormattedCollectionListSummary = (data: any, append?: boolean) => {
  const { level = 0, collection_lists, pagination_details } = data
  return {
    level,
    append,
    summaryStats: collection_lists.map(formatColectionSummaryStat),
    summaryType: ISummaryType.BALANCE_DUE,
    pagination: {
      total: Number(pagination_details?.total_items),
      page: Number(pagination_details?.page),
      perPage: Number(pagination_details?.per_page)
    }
  }
}

// UNUSED as BALANCE_DUE is disabled
export const getSingleFormattedCollectionListSummary = (data: any, append?: boolean) => {
  const { level = 1, accounts, pagination_details } = data
  return {
    level,
    append,
    summaryStats: accounts.map(formatColectionSummaryStat),
    summaryType: ISummaryType.BALANCE_DUE,
    pagination: {
      total: Number(pagination_details?.total_items),
      page: Number(pagination_details?.page),
      perPage: Number(pagination_details?.per_page)
    }
  }
}

export const getFormattedCollectionListInvoiceSummary = (data: any, append?: boolean) => {
  const { level = 0, collection_lists, pagination_details } = data
  return {
    level,
    append,
    summaryStats: collection_lists.map(formatColectionInvoiceSummaryStat),
    summaryType: ISummaryType.INVOICE_DUE,
    pagination: {
      total: Number(pagination_details?.total_items),
      page: Number(pagination_details?.page),
      perPage: Number(pagination_details?.per_page)
    }
  }
}

export const getSingleFormattedCollectionListInvoiceSummary = (data: any, append?: boolean) => {
  const { level = 1, accounts, pagination_details, statement_group } = data
  return {
    level,
    append,
    summaryStats: accounts.map(formatColectionAccountInvoiceSummaryStat),
    summaryType: ISummaryType.INVOICE_DUE,
    pagination: {
      total: Number(pagination_details?.total_items),
      page: Number(pagination_details?.page),
      perPage: Number(pagination_details?.per_page)
    },
    statementGroupDetails: statement_group
  }
}

export const getFormattedUnapprovedTransactions = (data: any) => {
  return data.transactions
}

/*
 *   Takes array of transactions and businessId and
 *   returns the payload for Bulk Collection
 */
export const getBulkCollectionPayload = (transactions: any[], businessId: string) => {
  return transactions
    .filter((transaction) => !transaction.approved_by_merchant)
    .map((transaction: any) => ({
      id: transaction.id,
      approved_by_merchant: true,
      business_id: businessId
    }))
}

export const getExcelHeading = (activeFilter: IDateFilter, title: string): string => {
  if (activeFilter.startTime && activeFilter.endTime) {
    if (activeFilter.label === 'Today') {
      return `${title} (${dayjs.unix(activeFilter.startTime).format('DD MMM, YYYY')})`
    }
    return `${title} (${dayjs.unix(activeFilter.startTime).format('DD MMM, YYYY')} - ${dayjs
      .unix(activeFilter.endTime)
      .format('DD MMM, YYYY')})`
  }
  return title
}

// UNUSED as BALANCE_DUE is disabled
export const formatForBalanceExcelExport = (data: any[], view: VIEW) => {
  return data.map((item) => ({
    Name: item.name,
    ...(view === VIEW.GROUP_SUMMARY_VIEW ? { 'No. of customers': item.accountCount } : {}),
    ...(view === VIEW.CUSTOMERS_SUMMARY_VIEW ? { 'Customer Code': item.customerCode } : {}),
    Credit: item.totalCredit,
    Payment: item.totalPayment,
    'Cash/NEFT': item.totalCash,
    'QR/Link': item.totalOnline,
    Cheque: item.totalCheque,
    Return: item.totalReturn,
    Damage: item.totalDamage,
    'Net Balance Due': item.netBalanceDue
  }))
}

export const getMaxColumnWidths = (data: any[]) => {
  if (data.length) {
    const widthArr = Object.keys(data?.[0]).map((key) => {
      return { wch: key.length + 2 }
    })
    for (const element of data) {
      const value = Object.values(element)
      value.forEach((elem: any, index: number) => {
        const widthWch = widthArr[index]?.wch
        if (elem && elem.length > widthWch && widthArr[index]?.wch) {
          widthArr[index].wch = elem.length
        }
      })
    }
  }
  return []
}

const formatSummaryStatsForInvoice = (data: any[]): ISummaryStatForInvoiceDownload[] => {
  return data.reduce((acc1, collectionList) => {
    return collectionList?.accounts?.reduce((acc2: any, account: any) => {
      return account?.invoices?.reduce((acc3: any, invoice: any) => {
        acc3.push({
          groupName: collectionList?.name,
          customerName: account?.name,
          customerCode: account?.customer_code,
          beats: account?.beats?.join(', '),
          routes: account?.routes?.join(', '),
          invoiceDate: invoice?.date,
          invoiceNumber: invoice?.number,
          invoiceAmount: Number(invoice?.amount) / 100,
          invoicePaid: Number(invoice?.paid_amount) / 100,
          totalCash: Number(invoice?.summary_stats?.total_cash_payments) / 100,
          totalNEFT: Number(invoice?.summary_stats?.total_neft_payments) / 100,
          totalOnline: Number(invoice?.summary_stats?.total_online_payments) / 100,
          totalCheque: Number(invoice?.summary_stats?.total_cheque_payments) / 100,
          totalReturn: Number(invoice?.summary_stats?.total_return_payments) / 100,
          totalDamage: Number(invoice?.summary_stats?.total_damaged_payments) / 100,
          invoiceDue: Number(invoice?.due_amount) / 100
        })
        return acc3
      }, acc2)
    }, acc1)
  }, [])
}

export const getFormattedInvoiceLevelDownloadData = (
  data: any,
  isDynamicStatementEnabled: boolean
): IInvoiceDownloadData => {
  const { groups, summary, collection_lists } = data
  return {
    summaryStats: formatSummaryStatsForInvoice(
      isDynamicStatementEnabled ? groups : collection_lists
    ),
    totalInvoiceAmount: Number(summary?.total_dues) / 100,
    totalInvoicePaid: Number(summary?.total_payments) / 100,
    totalInvoiceDue: Number(summary?.balance_due) / 100
  }
}

export const formatForInvoiceExcelExport = (data: any[], view: VIEW) => {
  return data.map((item) => ({
    ...(view === VIEW.GROUP_SUMMARY_VIEW ? { 'Group Name': item.groupName } : {}),
    'Customer Name': item.customerName,
    'Customer Code': item.customerCode,
    'Account Beat': item.beats,
    'Account Route': item.routes,
    'Invoice Date': dayjs.unix(item?.invoiceDate).format('DD MMM, YYYY'),
    'Invoice Number': item.invoiceNumber,
    'Invoice Amount': item.invoiceAmount,
    'Invoice Paid': item.invoicePaid,
    Cash: item.totalCash,
    NEFT: item.totalNEFT,
    'QR/Link': item.totalOnline,
    Cheque: item.totalCheque,
    Return: item.totalReturn,
    Damage: item.totalDamage,
    'Invoice Due': item.invoiceDue
  }))
}

export const getFormattedAccountAllocationDetails = (data: any) => {
  return {
    allocatedAccounts: data?.allocated_accounts || [],
    unallocatedAccounts: data?.un_allocated_accounts || []
  }
}
const getRowObject = ({
  list_name,
  total_cash_payments,
  total_online_payments,
  total_cheque_payments,
  total_neft_payments,
  total_damages,
  total_returns,
  total_collections,
  total_pending_dues,
  customer_name,
  customer_code,
  invoice_date,
  invoice_number,
  invoice_amount,
  latest_settlement_date,
  total_wallet_payments,
  total_other_payments,
  total_advance_collected,
  businessName
}: {
  list_name: string
  total_cash_payments: string
  total_online_payments: string
  total_cheque_payments: string
  total_neft_payments: string
  total_damages: string
  total_returns: string
  total_collections: string
  total_pending_dues: string
  customer_name: string
  customer_code: string
  invoice_date: string
  invoice_number: string
  invoice_amount: string
  latest_settlement_date: string
  total_wallet_payments: string
  total_other_payments: string
  total_advance_collected: string
  businessName?: string
}) => {
  return {
    ...(businessName && { 'Business Name': businessName }),
    'Group Name': list_name,
    'Customer Name': customer_name,
    'Customer Code': customer_code,
    'Invoice Date': dayjs.unix(Number(invoice_date)).format('DD MMM, YYYY'),
    'Invoice Number': invoice_number,
    'Invoice Amount': Number(invoice_amount) / 100,
    Cash: Number(total_cash_payments) / 100,
    'QR / Link': Number(total_online_payments) / 100,
    Cheque: Number(total_cheque_payments) / 100,
    NEFT: Number(total_neft_payments) / 100,
    Damage: Number(total_damages) / 100,
    Return: Number(total_returns) / 100,
    Others: Number(total_other_payments) / 100,
    'Advance Used': Number(total_wallet_payments) / 100,
    'Advance Collected': Number(total_advance_collected) / 100,
    'Latest Settlement Date': dayjs.unix(Number(latest_settlement_date)).format('DD MMM, YYYY'),
    'Total Collections': Number(total_collections) / 100,
    'Pending Due': Number(total_pending_dues) / 100,
    // Fields added only for QR transactions
    'Txn Amount': 0,
    UTR: '',
    'Paid By': '',
    'Transaction Date': ''
  }
}

export const convertCollectionDataInRowFormat = (
  collectionList: CollectionInvoiceList[],
  businessName?: string,
  showOnlineTxnType?: 'settled' | 'unsettled'
) => {
  const rowJson: RowJSON[] = []
  collectionList.forEach((collectionInvoiceList: CollectionInvoiceList) => {
    const { invoices, list_name } = collectionInvoiceList
    if (invoices?.length === 0) {
      return
    }
    invoices.forEach((invoiceTransaction: InvoiceWithTransactions) => {
      const { invoice, transactions } = invoiceTransaction
      const {
        customer_name,
        customer_code,
        invoice_date,
        invoice_number,
        invoice_amount,
        total_cash_payments,
        total_neft_payments,
        total_cheque_payments,
        total_online_payments,
        total_returns,
        total_damages,
        total_collections,
        total_pending_dues,
        latest_settlement_date,
        total_wallet_payments,
        total_other_payments,
        total_advance_collected
      } = invoice
      let rowObject = getRowObject({
        list_name,
        customer_name,
        customer_code,
        invoice_date,
        invoice_number,
        invoice_amount,
        total_cash_payments,
        total_neft_payments,
        total_cheque_payments,
        total_online_payments,
        total_returns,
        total_damages,
        total_collections,
        total_pending_dues,
        latest_settlement_date,
        total_wallet_payments,
        total_other_payments,
        total_advance_collected,
        businessName
      })

      if (transactions.length === 0 && showOnlineTxnType === 'unsettled') {
        return
      } else if (transactions.length > 0) {
        let transactionsToShow = transactions
        if (showOnlineTxnType === 'settled') {
          transactionsToShow = transactions.filter((txn) => txn.online_bill_settled)
        } else if (showOnlineTxnType === 'unsettled') {
          transactionsToShow = transactions.filter((txn) => !txn.online_bill_settled)
          const totalOnlineCollection = transactionsToShow.reduce(
            (acc, item) => acc + Number(item?.amount) / 100,
            0
          )
          rowObject = {
            ...rowObject,
            Cash: 0,
            'QR / Link': totalOnlineCollection,
            Cheque: 0,
            NEFT: 0,
            Damage: 0,
            Return: 0,
            Others: 0,
            'Advance Used': 0,
            'Advance Collected': 0,
            'Total Collections': totalOnlineCollection
          }
        }
        if (transactionsToShow.length === 0 && showOnlineTxnType === 'settled') {
          rowJson.push(rowObject)
        } else if (transactionsToShow.length) {
          transactionsToShow.forEach((txn: Transaction, index: number) => {
            rowJson.push({
              ...(index >= 1 ? ({} as RowJSON) : rowObject),
              'Txn Amount': Number(txn.amount) / 100,
              UTR: txn.notes.utr,
              'Settlement UTR': txn?.notes?.settlement_utr,
              'Paid By': txn.notes.payer_vpa,
              'Transaction Date': dayjs(Number(txn.transaction_time) * 1000).format(
                'DD MMM YYYY, hh:mm a'
              )
            })
          })
        }
      } else {
        rowJson.push(rowObject)
      }
    })
  })
  return rowJson
}

export const formatData = ({
  collection_lists,
  businessName,
  showOnlineTxnType
}: {
  collection_lists: CollectionInvoiceList[]
  businessName?: string
  showOnlineTxnType?: 'settled' | 'unsettled'
}) => {
  const formattedCollectionList = convertCollectionDataInRowFormat(
    collection_lists,
    businessName,
    showOnlineTxnType
  )
  return [...formattedCollectionList]
}

export const formatOnlineCollectionData = (data: OnlineCollectionTypeResponse) => {
  return data.map((item) => ({
    'Merchant Name': item.business_name,
    'Customer Name': item.customer_name,
    'QR Payment Amount': Number(item.amount) / 100,
    UTR: item.utr,
    'Transaction Date': dayjs.unix(Number(item.transaction_date)).format('DD MMM, YYYY'),
    'Paid By': item.paid_by,
    Provider: item.payment_provider
  }))
}

export const formatReplacementSummary = (data: ReplacementDataType[]): ReplacementRowJson[] => {
  const finalArray: ReplacementRowJson[] = []
  data.forEach((entry: ReplacementDataType) => {
    entry.items.forEach((item, index) => {
      if (index === 0) {
        finalArray.push({
          [keyMapper.groupName]: entry.group,
          [keyMapper.customerName]: entry.customer_name,
          [keyMapper.customerCode]: entry.customer_code,
          [keyMapper.invoiceDate]: dayjs.unix(Number(entry.invoice_date)).format('DD MMM, YYYY'),
          [keyMapper.invoiceNumber]: entry.invoice_number,
          [keyMapper.invoiceAmount]: Number(entry.invoice_amount) / 100,
          [keyMapper.replacement]: entry.status,
          [keyMapper.replacementItem]: item.name,
          [keyMapper.quantity]: item.quantity,
          [keyMapper.replacementAmount]: Number(entry.replacementAmount) / 100
        } as ReplacementRowJson)
        return
      }

      finalArray.push({
        [keyMapper.groupName]: '',
        [keyMapper.customerName]: '',
        [keyMapper.customerCode]: '',
        [keyMapper.invoiceDate]: '',
        [keyMapper.invoiceNumber]: '',
        [keyMapper.invoiceAmount]: '',
        [keyMapper.replacement]: entry.status,
        [keyMapper.replacementItem]: item.name,
        [keyMapper.quantity]: item.quantity,
        [keyMapper.replacementAmount]: ''
      } as ReplacementRowJson)
    })
  })

  return finalArray
}
