import { ApprovedIcon, DownloadIcon, SyncIcon } from '@okcredit/web-lib'
import { THEME } from 'components/theme'
import { COLLECTION_FILTER_TYPE } from 'pages/NewDashboard/components/Summary/components/CollectionContainer/CollectionHeader/constants'

export enum SORT_TYPE {
  NAME = 'name',
  LATEST = 'latest',
  AMOUNT = 'amount'
}

export const sortTypeOptions = [
  {
    id: SORT_TYPE.NAME,
    label: 'Name'
  },
  {
    id: SORT_TYPE.LATEST,
    label: 'Latest'
  },
  {
    id: SORT_TYPE.AMOUNT,
    label: 'Amount'
  }
]

export enum SECTION_TYPE {
  OWNER_SUMMARY = 'collectionSummary'
}

export const SECTION_TYPE_VS_TITLE = {
  [SECTION_TYPE.OWNER_SUMMARY]: 'Collection Summary'
}

export interface IDateFilter {
  startTime?: number
  endTime?: number
  label?: string
  filterType?: COLLECTION_FILTER_TYPE
}

export const DOWNLOAD_STATUS_VS_ICON = {
  default: {
    label: 'Download',
    icon: DownloadIcon,
    color: THEME.brandTintGreen
  },
  downloading: {
    label: 'Downloading',
    icon: SyncIcon,
    color: '#f9aa33'
  },
  downloaded: {
    label: 'Downloaded',
    icon: ApprovedIcon,
    color: THEME.brandTintGreen
  }
}

export enum ISummaryType {
  BALANCE_DUE,
  INVOICE_DUE
}

export const summaryOptions = [
  // {
  //   label: 'Balance Due',
  //   value: ISummaryType.BALANCE_DUE
  // },
  {
    label: 'Invoice Due',
    value: ISummaryType.INVOICE_DUE
  }
]
