import { PAGE_LIMIT } from 'pages/NewDashboard/components/Billing/InvoiceItem/InvoiceTransactionHistoryBody/util'
import { Action } from '../../infra/types'
import { BillingActions } from './billing.actions'
import { BillingState } from './billing.state'
import { IBillingState } from './billing.types'

export const BillingReducer = (
  state: IBillingState = BillingState,
  action: Action<any>
): IBillingState => {
  switch (action.type) {
    case BillingActions.SET_EDIT_ITEM: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showAddItemDrawer: true
        },
        editItemDetails: action.payload.editItemDetails
      }
    }
    case BillingActions.SET_EDIT_INVENTORY_ITEM: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showAddInventoryItemDrawer: true
        },
        inventory: {
          ...state.inventory,
          editInventoryItem: action.payload.editInventoryItem
        }
      }
    }
    case BillingActions.RESET_EDIT_INVENTORY_ITEM: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showAddInventoryItemDrawer: false
        },
        inventory: {
          ...state.inventory,
          editInventoryItem: undefined
        }
      }
    }
    case BillingActions.SET_ADDED_ITEM_DETAILS_FROM_DRAWER: {
      return {
        ...state,
        addedItemsDetailsFromDrawer: action.payload
      }
    }
    case BillingActions.RESET_ADDED_ITEM_DETAILS_FROM_DRAWER: {
      return {
        ...state,
        addedItemsDetailsFromDrawer: undefined
      }
    }
    case BillingActions.SET_ADDED_CUSTOMER_DETAILS_FROM_DRAWER: {
      return {
        ...state,
        addedCustomerDetailsFromDrawer: action.payload
      }
    }
    case BillingActions.RESET_ADDED_CUSTOMER_DETAILS_FROM_DRAWER: {
      return {
        ...state,
        addedCustomerDetailsFromDrawer: undefined
      }
    }
    case BillingActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case BillingActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.loaderName]: action.payload.status
        }
      }
    }
    case BillingActions.SET_FLAGS: {
      return {
        ...state,
        flags: {
          ...state.flags,
          [action.payload.flagName]: action.payload.status
        }
      }
    }
    case BillingActions.SET_PREVIOUS_ITEM_LIST: {
      return {
        ...state,
        resetPreviousItemList: action.payload
      }
    }
    case BillingActions.SET_ADDED_BILLS_DRAWER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          showAddedBillsDrawers: action.payload
        }
      }
    }
    // case BillingActions.ADD_ITEMS: {
    //   const {
    //     payload: { items }
    //   } = action
    //   return {
    //     ...state,
    //     items
    //   }
    // }
    case BillingActions.RESET_BILL_CONTEXT: {
      return {
        ...state,
        resetBillContext: action.payload
      }
    }
    case BillingActions.SET_BILLS_DATA: {
      return {
        ...state,
        bills: action.payload
      }
    }
    case BillingActions.ADD_SINGLE_BILL: {
      return {
        ...state,
        bills: [action.payload, ...state.bills]
      }
    }
    case BillingActions.UPDATE_SINGLE_BILL: {
      const billsData = [...state.bills]
      const billIndex = billsData.findIndex((bill) => bill.billId === action.payload.billId)
      billsData[billIndex] = action.payload
      return {
        ...state,
        bills: billsData
      }
    }
    case BillingActions.RESET_EDIT_ITEM: {
      return {
        ...state,
        editItemDetails: {
          item: '',
          hsnCode: '',
          mrp: undefined,
          rate: undefined,
          gstTaxPercent: 0,
          cessPercent: 0,
          isTaxIncludedInRate: false,
          gstPercent: 0,
          // quantity: 0,
          // sku: '',
          // cgstTaxPercent: 0,
          // sgstTaxPercent: 0,
          // taxAmount: 0,
          itemId: ''
          // discount: 0,
          // netRate: 0
        }
      }
    }
    case BillingActions.SET_PRINT_BILL_TEMPLATE: {
      return {
        ...state,
        printBillTemplate: action.payload
      }
    }
    case BillingActions.SET_SAVED_PREVIEW_BILL_TEMPLATE_AND_ID: {
      return {
        ...state,
        previewSavedBillTemplate: action.payload.previewSavedBillTemplate,
        previewSavedBillId: action.payload.previewSavedBillId
        // hasCreditNote: action.payload.hasCreditNote
      }
    }
    case BillingActions.SET_File_SETTINGS: {
      return {
        ...state,
        purchaseBillSettings: action.payload
      }
    }
    case BillingActions.SET_SELECTED_BILL_DATA: {
      return {
        ...state,
        selectedBill: action.payload
      }
    }
    case BillingActions.SET_IS_UPDATED_BILL_DATA: {
      return {
        ...state,
        isUpdatedBillData: action.payload
      }
    }
    case BillingActions.SET_INVOICE_ITEMS: {
      return {
        ...state,
        invoiceItems: action.payload
      }
    }
    case BillingActions.SET_INVENTORY_ITEMS: {
      return {
        ...state,
        inventory: {
          ...state.inventory,
          inventoryItems: action.payload
        }
      }
    }
    case BillingActions.SET_INVENTORY_UNITS: {
      return {
        ...state,
        inventory: {
          ...state.inventory,
          units: action.payload.units
        }
      }
    }
    case BillingActions.EDIT_INVOICE_ITEMS: {
      return {
        ...state,
        editInvoiceItems: action.payload.item,
        drawers: { ...state.drawers, showAddInvoiceItemDrawer: true }
      }
    }
    case BillingActions.SET_INVOICE_ITEMS_HISTORY: {
      return {
        ...state,
        invoiceItemsHistory: action.payload
      }
    }
    case BillingActions.SET_PURCHASE_INVENTORY_ITEMS: {
      return {
        ...state,
        purchaseItems: action.payload
      }
    }

    case BillingActions.SET_PURCHASE_ACCOUNT: {
      return {
        ...state,
        purchaseAccount: action.payload
      }
    }
    case BillingActions.SET_PURCHASE_ASSET_ACCOUNT: {
      return {
        ...state,
        purchaseAssetAccount: action.payload
      }
    }
    case BillingActions.SET_EXPENSE_TRANSACTION: {
      return {
        ...state,
        expenseTransaction: action.payload
      }
    }

    case BillingActions.ADD_EXPENSE_ITEM: {
      return {
        ...state,
        expenseTransaction: [...state.expenseTransaction, action.payload]
      }
    }

    case BillingActions.SET_INVENTORY_API_CALL_AND_RESPONSE: {
      return {
        ...state,
        inventoryApiCallAndResponse: action.payload
      }
    }

    case BillingActions.GET_INVENTORY_TRANSACTION_HISTORY: {
      return {
        ...state,
        inventoryApiCallAndResponse: {
          isApiCall: true,
          isAllDataIsFetched: action.payload === null,
          isApiError: false
        }
      }
    }

    case BillingActions.RESET_INVENTORY_TRANSACTION_HISTORY: {
      return {
        ...state,
        InventoryTransactionHistory: []
      }
    }

    case BillingActions.UPDATE_PURCHASE_TRANSACTION_HISTORY: {
      return {
        ...state,
        purchaseTransactionHistory: Array.isArray(action.payload)
          ? [...action.payload, ...state.purchaseTransactionHistory]
          : [action.payload, ...state.purchaseTransactionHistory]
      }
    }

    case BillingActions.SET_PURCHASE_TRANSACTION_HISTORY: {
      return {
        ...state,
        inventoryApiCallAndResponse: {
          isApiCall: false,
          isAllDataIsFetched: action.payload.length < PAGE_LIMIT,
          isApiError: false
        },
        purchaseTransactionHistory: action.payload
      }
    }

    case BillingActions.SET_INVENTORY_TRANSACTION_HISTORY: {
      return {
        ...state,
        inventoryApiCallAndResponse: {
          isApiCall: false,
          isAllDataIsFetched: action.payload.length < PAGE_LIMIT,
          isApiError: false
        },
        InventoryTransactionHistory: action.payload
      }
    }
    case BillingActions.SET_DISCOUNTS_DATA: {
      return {
        ...state,
        discounts: action.payload
      }
    }
    case BillingActions.SET_CHARGES_DATA: {
      return {
        ...state,
        charges: action.payload
      }
    }
    case BillingActions.BOOKKEEPING_API_LOADER: {
      return {
        ...state,
        bookingApiLoader: action.payload
      }
    }

    case BillingActions.SET_EXPENSE_ACCOUNTS: {
      return {
        ...state,
        expenseAccounts: action.payload
      }
    }

    case BillingActions.SET_FAILED_BULK_UPLOAD_DATA: {
      return {
        ...state,
        failedBulkUploadData: action.payload
      }
    }

    case BillingActions.BULK_UPLOAD_PURCHASE_ITEMS_STATUS: {
      return {
        ...state,
        failedBulkUploadData: {
          ...state.failedBulkUploadData,
          status: action.payload
        }
      }
    }

    case BillingActions.BULK_UPLOAD_PURCHASE_ITEMS: {
      return {
        ...state,
        bulkUploadFile: action.payload
      }
    }

    case BillingActions.SET_BULK_UPLOAD_PURCHASE_ITEMS_LOG: {
      return {
        ...state,
        bulkUploadFileLog: action.payload
      }
    }

    case BillingActions.SET_BULK_UPLOAD_FILE_REQUEST_ID: {
      return {
        ...state,
        bulkUploadFileRequestId: action.payload
      }
    }

    case BillingActions.TOTAL_COUNT_OF_PURCHASE_ITEMS: {
      return {
        ...state,
        totalCountOfBulkPurchaseItems: action.payload
      }
    }

    default: {
      return state
    }
  }
}
