import { takeCustomLatest } from 'state/common/common.effects'
import { refetchListWithIdAndPopulateStore } from './list.effect'
import { listActions } from './list.actions'

function* refetchListSaga() {
  yield takeCustomLatest(
    listActions.REFETCH_LIST_AND_POPULATE_STORE,
    refetchListWithIdAndPopulateStore
  )
}

export const ListWatchers = [refetchListSaga()]
