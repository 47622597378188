import { all } from 'redux-saga/effects'
import { TransactionsEffects } from 'state/transaction/transaction.watcher'
import { AuthEffects } from 'state/auth/auth.watcher'
import { DashboardEffects } from 'state/dashboard/dashboard.watcher'
import { NotificationEffects } from 'pages/Notification/Notification.watcher'
import { BlindpayEffects } from 'state/blindpay/blindpay.watcher'
import { AgentDashboardEffects } from 'state/agent-dashboard/agent-dashboard.watcher'
import { StaffLinkEffects } from 'experiments/StaffLink/StaffLink.watchers'
import { OrderQREffects } from 'experiments/OrderQR/OrderQR.watchers'
import { TagsEffects } from 'state/tags/tags.watcher'
import { BeatsEffects } from 'state/beats/beats.watcher'
import { CollectionListEffects } from 'state/collectionList/collectionList.watcher'
import { StatementsWatchers } from 'state/statements/statements.watcher'
import { BillsWatchers } from 'state/bills/bills.watcher'
import { ExperimentWatchers } from 'state/experiment/experiment.watcher'
import { CollectionSummaryWatchers } from 'state/collectionSummary/collectionSummary.watcher'
import { ApprovedCollectionSummaryWatchers } from 'state/new-summary/approvedCollectionSummary/approvedCollectionSummary.watcher'
import { PendingDueSummaryWatchers } from 'state/pendingDueSummary/pendingDueSummary.watcher'
import { BillingWatchers } from 'state/billing/billing.watcher'
import { InventoryWatchers } from 'state/inventory/inventory.watcher'
import { SupplyStatementGroupWatchers } from 'state/new-summary/supply/supply.watcher'
import { ApproveWatchers } from 'state/new-summary/approve/approve.watcher'
import { SupplyListWatchers } from 'state/new-summary/list/supply-list.watchers'
import { UnidentifiedCollectionWatchers } from 'state/new-summary/unidentifiedCollection/unidentifiedCollection.watcher'
import { UploadSheetWatchers } from 'state/new-summary/uploadSheet/uploadSheet.watchers'
import { CollectionListWatchers } from 'state/new-summary/list/collection-list.watchers'
import { ListWatchers } from 'state/new-summary/list/list.watchers'
import { EventsWatchers } from 'state/new-summary/events/events.watcher'
import { BankStatementWatchers } from 'state/new-summary/bankStatement/bankStatement.watcher'
import { CreateCustomerWatchers } from 'state/new-summary/root-drawers/create-edit-customer/create-edit-customer.watchers'
import { ChequeWatchers } from 'state/new-summary/cheque/cheque.watcher'
import { CashByStaffWatchers } from 'state/summary/cashByStaff/cashByStaff.watcher'
import { OrdersWatcher } from 'state/new-summary/orders/orders.watcher'
import { CreditNotesWatcher } from 'state/new-summary/creditNote/creditNote.watcher'

export default function* rootSaga() {
  yield all([
    ...AuthEffects,
    ...DashboardEffects,
    ...TransactionsEffects,
    ...NotificationEffects,
    ...BlindpayEffects,
    ...StaffLinkEffects,
    ...OrderQREffects,
    ...AgentDashboardEffects,
    ...TagsEffects,
    ...CollectionListEffects,
    ...StatementsWatchers,
    ...BillsWatchers,
    ...ExperimentWatchers,
    ...CollectionSummaryWatchers,
    ...PendingDueSummaryWatchers,
    ...ApprovedCollectionSummaryWatchers,
    ...BillingWatchers,
    ...InventoryWatchers,
    ...SupplyStatementGroupWatchers,
    ...ApproveWatchers,
    ...SupplyListWatchers,
    ...UploadSheetWatchers,
    ...CollectionListWatchers,
    ...UnidentifiedCollectionWatchers,
    ...UploadSheetWatchers,
    ...ListWatchers,
    ...EventsWatchers,
    ...BankStatementWatchers,
    ...CreateCustomerWatchers,
    ...ChequeWatchers,
    ...CashByStaffWatchers,
    ...OrdersWatcher,
    ...BeatsEffects,
    ...CreditNotesWatcher
  ])
}
