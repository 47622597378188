import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const HeaderStyles = createUseStyles({
  headerContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: '#cbd7dc',
    paddingLeft: '13px',
    boxSizing: 'border-box',
    zoom: '95%'
  },
  verticalHeaderContainer: {
    display: 'flex',
    width: '100%',
    height: '93%'
  },
  childrenContainer: {
    border: 'solid 1px #b4babc',
    width: '95.6%',
    height: '98%',
    backgroundColor: 'white',
    borderRadius: '5px',
    overflow: 'scroll'
  }
})

export const TopHeaderStyles = createUseStyles({
  topHeaderContainer: {
    height: '7%',
    backgroundColor: '#cbd7dc'
  },
  innerContainer: {
    width: '99%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logoStyles: {
    height: '40px',
    width: '40px'
  },
  businessName: {
    fontSize: '24px',
    fontWeight: 600
  },
  logoBusinessContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    marginLeft: 8
  }
})

export const VerticalHeaderStyles = createUseStyles({
  container: {
    backgroundColor: '#cbd7dc',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
    padding: '10px 5px 10px 0',
    overflow: 'scroll'
  }
})

export const TabsContainerStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  },
  iconTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  iconContainer: {
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeIconContainer: {
    backgroundColor: '#95bda0',
    borderRadius: '50%'
  },
  title: {
    fontSize: '12px',
    textAlign: 'center',
    color: '#212121'
  },
  activeText: {
    color: THEME.brandGreen,
    fontWeight: 'bold'
  }
})
