import React, { useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { DoneCircleIcon } from '@okcredit/web-lib'
import { THEME } from 'components/theme'
import { createBusinessAction } from 'state/dashboard/dashboard.actions'
import { NewBusinessInputStyles } from './styles'

const NewBusinessInput = () => {
  const classes = NewBusinessInputStyles()
  const dispatch = useDispatch()
  const [newBusinessName, setNewBusinessName] = useState('')
  const [errorInput, setErrorInput] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(createBusinessAction(newBusinessName))
    setNewBusinessName('')
  }

  const handleNewBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 45) {
      setErrorInput('Max character limit reached')
      return null
    }
    if (errorInput) {
      setErrorInput('')
    }
    setNewBusinessName(e.target.value)
  }

  return (
    <>
      <div className={classes.inputNewBusinessContainer}>
        <div className={classNames(classes.businessItem, classes.bgGreen)}>
          Add Another Business
        </div>
        <form onSubmit={handleSubmit}>
          <div className={classes.businessItem}>
            <input
              type="text"
              placeholder="Enter Business Name"
              className={classes.businessInput}
              onChange={handleNewBusinessNameChange}
              value={newBusinessName}
            />
            <button className={classes.submitBtn} type="submit" disabled={!newBusinessName}>
              <DoneCircleIcon
                fill={newBusinessName ? THEME.brandGreen : THEME.brandDarkGrey2}
                size={24}
              />
            </button>
          </div>
        </form>
      </div>
      {errorInput && <div className={classes.errorInput}>{errorInput}</div>}
    </>
  )
}

export default NewBusinessInput
