/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put, select } from '@redux-saga/core/effects'
import { Action } from 'infra/types'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { fetchBillsByCustomer } from 'services/Api/BillingApi'
import { getStatementGroupInvoicesApi } from 'services/Api/StafflinkApi'
import { IExpState } from 'state/experiment/experiment.types'
import { IAppState } from 'infra/AppState'
import { IStatementsState } from 'state/statements/statements.types'
import { IMerchantProfile } from 'state/dashboard/dashboard.types'
import { Navigation } from 'services/navigation'
import { setLoaderAction, getBillsByCustomerSuccessAction } from './bills.actions'
import { API_RESPONSE_TYPE, IApiResponse } from './../../constants'
import { getFormattedBills } from './bills.helpers'

export function* getBillsByCustomerEffect(
  action: Action<{
    customer_id: string
    per_page?: number
    page?: number
    bill_number?: string
    replaceExisting?: boolean
    from_date?: number
    to_date?: number
    isStatement?: boolean
    redirectTo?: string
  }>
) {
  const {
    customer_id,
    per_page,
    page,
    bill_number,
    from_date,
    to_date,
    replaceExisting = true,
    isStatement = false,
    redirectTo = ''
  } = action.payload
  let response: IApiResponse
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    const StatementsState: IStatementsState = yield select((state: IAppState) => {
      return state.Statements
    })
    const experimentState: IExpState = yield select((state: IAppState) => {
      return state.Experiment
    })
    const isDynamicStatementEnabled = experimentState.ab.dynamicStatements
    yield put(setLoaderAction({ id: 'fetchBillsByCustomer', value: replaceExisting }))
    if (isStatement && isDynamicStatementEnabled) {
      response = yield call(getStatementGroupInvoicesApi, {
        account_id: customer_id,
        group_id: StatementsState?.currentCollectionListId,
        business_id: merchantProfile.id,
        ...(from_date || from_date === 0 ? { start_time: from_date } : {}),
        ...(to_date || to_date === 0 ? { end_time: to_date } : {})
        // ...(bill_number ? { bill_number } : {}),
      })
    } else {
      response = yield call(fetchBillsByCustomer, {
        customer_id,
        ...(per_page ? { per_page } : {}),
        ...(page ? { page } : {}),
        ...(bill_number ? { bill_number } : {}),
        ...(from_date || from_date === 0 ? { from_date } : {}),
        ...(to_date || to_date === 0 ? { to_date } : {})
      })
    }
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        getBillsByCustomerSuccessAction({
          ...getFormattedBills(response?.data?.bills, replaceExisting),
          ...(isStatement && isDynamicStatementEnabled ? { hasMore: false } : {})
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch invoices. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'fetchBillsByCustomer', value: false }))
    if (redirectTo) {
      Navigation().to(redirectTo)
    }
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'fetchBillsByCustomer', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}
