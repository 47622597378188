import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, generatePath } from 'react-router'
import { Button, EditFillIcon } from '@okcredit/web-lib'
import { IAppState } from 'infra/AppState'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { getUserInitials } from 'utils/getUserInitials'
import { THEME } from 'components/theme'
import { newPaths } from 'routes'
import { BodyHeaderStyles } from './styles'

const BodyHeader = () => {
  const { merchantProfile } = useSelector((app: IAppState) => app.Dashboard)
  const classes = BodyHeaderStyles()
  const history = useHistory()
  const currentBusinessId = AuthStorageInstance.getCurrentBusinessId() || merchantProfile?.data?.id

  const handleManageAccountClick = (e: any) => {
    e.stopPropagation()
    history.push({
      pathname: generatePath(newPaths.editMerchant, { id: currentBusinessId }),
      search: ''
    })
  }

  return (
    <div className={classes.selectorBodyHeader}>
      {merchantProfile?.data?.profileImage ? (
        <img
          src={merchantProfile?.data?.profileImage}
          alt={merchantProfile?.data?.name}
          className={classes.profileImage}
        />
      ) : (
        <div className={classes.profileImage}>{getUserInitials(merchantProfile?.data?.name)}</div>
      )}
      <div className={classes.businessName}>{merchantProfile?.data?.name}</div>
      <Button
        size="medium"
        variant="outlined"
        color="primary"
        startIcon={<EditFillIcon fill={THEME.brandGreen} size={24} />}
        onClick={handleManageAccountClick}
      >
        Manage Business
      </Button>
    </div>
  )
}

export default BodyHeader
