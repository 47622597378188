import { call, race, take, takeLatest } from 'redux-saga/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import { CollectionListActions, handleLoaderToggle } from './collectionList.actions'
import {
  fetchCollectionListSummary,
  fetchCollectionListEffect,
  fetchSingleCollectionListEffect,
  collectionListClickEffect,
  removeCollectionListEffect,
  collectionListNameAndUsageChangeEffect,
  updateCollectionListDueConfigEffect,
  createCollectionListEffect,
  fetchDocumentsListEffect,
  documentsListRefreshSagaEffect,
  collectionListFromDocumentEffect,
  selectAllCollectionListClickEffect,
  selectAllBillCollectionListClickEffect,
  fetchTemplatesListEffect,
  fetchDefaultTemplateEffect,
  createNewTemplateEffect,
  updateDefaultTemplateEffect,
  addToMultipleCollectionListFromDocumentEffect,
  bulkUploadCustomersEffect,
  startBulkUploadCustomersStatusPollingEffect,
  removeBillEffect,
  fetchCollectionBillsDetailsEffects,
  createNewCollectionListV2Effects,
  fetchCollectionListV2Effects,
  collectionListEditEffect,
  updateCollectionListV2DueConfigEffect,
  fetchClQuickSimmaryEffect,
  fetchAllCollectionListEffect,
  fetchStaffMembersEffect,
  toggleAllBillsOfAccountEffect,
  fetchAllPendingActionsEffect
} from './collectionList.effects'

function* fetchCollectionListsSaga() {
  yield takeLatest(CollectionListActions.FETCH_COLLECTION_LISTS, fetchCollectionListEffect)
}

function* fetchSingleCollectionListsSaga() {
  yield takeLatest(
    CollectionListActions.FETCH_SINGLE_COLLECTION_LISTS,
    fetchSingleCollectionListEffect
  )
}

function* fetchClQuickSummarySaga() {
  yield takeLatest(
    CollectionListActions.FETCH_SINGLE_COLLECTION_LISTS_SUCCESS,
    fetchClQuickSimmaryEffect
  )
}

function* createCollectionListSaga() {
  yield takeLatest(
    CollectionListActions.UPDATE_COLLECTION_LIST_DUE_CONFIG_TO_NEW_LIST,
    createCollectionListEffect
  )
}

function* removeCollectionListSaga() {
  yield takeLatest(CollectionListActions.REMOVE_COLLECTION_LIST, removeCollectionListEffect)
}

function* updateCollectionListDueConfigSaga() {
  yield takeLatest(
    CollectionListActions.UPDATE_COLLECTION_LIST_DUE_CONFIG,
    updateCollectionListDueConfigEffect
  )
}

function* collectionListNameAndUsageChangeSaga() {
  yield takeCustomLatest(
    CollectionListActions.COLLECTION_LIST_NAME_CHANGE,
    collectionListNameAndUsageChangeEffect
  )
}

function* collectionListClickSaga() {
  yield takeLatest(CollectionListActions.COLLECTION_LIST_ACCOUNT_CLICK, collectionListClickEffect)
}

function* removeBillSaga() {
  yield takeLatest(CollectionListActions.REMOVE_BILL, removeBillEffect)
}

function* selectAllCollectionListClickSaga() {
  yield takeLatest(
    CollectionListActions.SELECT_ALL_COLLECTION_LIST_CLICK,
    selectAllCollectionListClickEffect
  )
}

function* selectAllBillCollectionListClickSaga() {
  yield takeLatest(
    CollectionListActions.COLLECTION_LIST_BILL_ALL_SELECT,
    selectAllBillCollectionListClickEffect
  )
}

function* fetchDocumentsListSaga() {
  yield takeLatest(CollectionListActions.FETCH_DOCUMENTS_LIST, fetchDocumentsListEffect)
}

function* documentsListRefreshSaga() {
  yield takeLatest(CollectionListActions.DOCUMENTS_LIST_REFRESH, documentsListRefreshSagaEffect)
}

function* collectionListFromDocumentSaga() {
  yield takeLatest(
    CollectionListActions.COLLECTION_LIST_FROM_DOCUMENT,
    collectionListFromDocumentEffect
  )
}

function* multipleCollectionListFromDocumentSaga() {
  yield takeLatest(
    CollectionListActions.ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT,
    addToMultipleCollectionListFromDocumentEffect
  )
}

function* fetchCollectionListSummarySaga() {
  yield takeLatest(CollectionListActions.FETCH_LIST_SUMMARY, fetchCollectionListSummary)
}

function* fetchTemplatesListSaga() {
  yield takeLatest(CollectionListActions.FETCH_TEMPLATES_LIST, fetchTemplatesListEffect)
}

function* fetchDefaultTemplateSaga() {
  yield takeLatest(CollectionListActions.FETCH_DEFAULT_TEMPLATE, fetchDefaultTemplateEffect)
}

function* createNewTemplateSaga() {
  yield takeLatest(CollectionListActions.CREATE_TEMPLATE, createNewTemplateEffect)
}

function* updateDefaultTemplateSaga() {
  yield takeLatest(CollectionListActions.UPDATE_DEFAULT_TEMPLATE, updateDefaultTemplateEffect)
}

function* bulkUploadCustomersSaga() {
  yield takeLatest(CollectionListActions.BULK_UPLOAD_ACCOUNTS, bulkUploadCustomersEffect)
}

function* startBulkUploadCustomersStatusPollingSaga() {
  while (true) {
    //@ts-ignore
    const action = yield take(CollectionListActions.START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS)
    yield race([
      call(startBulkUploadCustomersStatusPollingEffect, action),
      take(CollectionListActions.STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS)
    ])
  }
}

function* fetchCollectionBillsDetailsSaga() {
  yield takeLatest(
    CollectionListActions.FETCH_COLLECTION_LIST_BILLS,
    fetchCollectionBillsDetailsEffects
  )
}

function* createNewCollectionListV2Saga() {
  yield takeLatest(
    CollectionListActions.CREATE_NEW_COLLECTION_LIST_V2,
    createNewCollectionListV2Effects
  )
}

function* fetchCollectionListsV2Saga() {
  yield takeLatest(CollectionListActions.FETCH_COLLECTION_LISTS_V2, fetchCollectionListV2Effects)
}

function* collectionListEditSaga() {
  yield takeLatest(CollectionListActions.COLLECTION_LIST_BILL_CLICK, collectionListEditEffect)
}

function* toggleAllBillsOfAccountSaga() {
  yield takeLatest(
    CollectionListActions.COLLECTION_LIST_ALL_BILLS_OF_ACCOUNT_CLICK,
    toggleAllBillsOfAccountEffect
  )
}

function* updateCollectionListV2DueConfigSaga() {
  yield takeLatest(
    CollectionListActions.UPDATE_COLLECTION_LIST_V2_DUE_CONFIG,
    updateCollectionListV2DueConfigEffect
  )
}

function* fetchAllCollectionListsSaga() {
  yield takeLatest(CollectionListActions.FETCH_ALL_COLLECTION_LISTS, fetchAllCollectionListEffect)
}

function* fetchStaffMembersSaga() {
  yield takeLatest(CollectionListActions.FETCH_STAFF_MEMBERS, fetchStaffMembersEffect)
}

function* fetchAllPendingActionsSaga() {
  yield takeCustomLatest(
    CollectionListActions.FETCH_ALL_PENDING_ACTIONS,
    fetchAllPendingActionsEffect,
    () => {
      handleLoaderToggle({ allPendingActions: false })
    }
  )
}

export const CollectionListEffects = [
  fetchCollectionListSummarySaga(),
  fetchCollectionListsSaga(),
  fetchSingleCollectionListsSaga(),
  collectionListClickSaga(),
  removeCollectionListSaga(),
  collectionListNameAndUsageChangeSaga(),
  updateCollectionListDueConfigSaga(),
  createCollectionListSaga(),
  fetchDocumentsListSaga(),
  documentsListRefreshSaga(),
  collectionListFromDocumentSaga(),
  selectAllCollectionListClickSaga(),
  selectAllBillCollectionListClickSaga(),
  fetchTemplatesListSaga(),
  fetchDefaultTemplateSaga(),
  createNewTemplateSaga(),
  updateDefaultTemplateSaga(),
  multipleCollectionListFromDocumentSaga(),
  bulkUploadCustomersSaga(),
  startBulkUploadCustomersStatusPollingSaga(),
  removeBillSaga(),
  fetchCollectionBillsDetailsSaga(),
  createNewCollectionListV2Saga(),
  fetchCollectionListsV2Saga(),
  collectionListEditSaga(),
  toggleAllBillsOfAccountSaga(),
  updateCollectionListV2DueConfigSaga(),
  fetchClQuickSummarySaga(),
  fetchAllCollectionListsSaga(),
  fetchStaffMembersSaga(),
  fetchAllPendingActionsSaga()
]
