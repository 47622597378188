import { takeCustomLatest } from 'state/common/common.effects'
import {
  cashByStaffActions,
  setCashByStaffModalsAction
} from 'state/summary/cashByStaff/cashByStaff.actions'
import {
  downloadBankWiseSummaryEffect,
  downloadCashByStaffEffect
} from 'state/summary/cashByStaff/cashByStaff.effects'
import { ModalNameEnum } from 'state/summary/cashByStaff/cashByStaff.types'

function* downloadCashByStaffSummary() {
  yield takeCustomLatest(
    cashByStaffActions.DOWNLOAD_CASH_BY_STAFF_SUMMARY,
    downloadCashByStaffEffect
  )
}

function* downloadBankWiseSummary() {
  yield takeCustomLatest(
    cashByStaffActions.DOWNLOAD_BANK_WISE_SUMMARY,
    downloadBankWiseSummaryEffect,
    () =>
      setCashByStaffModalsAction({
        modalName: ModalNameEnum.downloadCashByStaffBankWise,
        status: false
      })
  )
}

export const CashByStaffWatchers = [downloadCashByStaffSummary(), downloadBankWiseSummary()]
