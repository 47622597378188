import { getEnvConfig } from 'utils/getEnvConfig'
import ApiService from './ApiService'
import { apiRequestInterceptor } from './apiInterceptor'

const GET_AB_PROFILE_ENDPOINT_V1 = '/v1/GetProfile'
const GET_AB_PROFILE_ENDPOINT_V2 = '/v2/GetProfile'

const abBase = getEnvConfig('AB_ENDPOINT') || ''

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const apiV1 = new ApiService(abBase, headers)
const apiV2 = new ApiService(abBase, headers)
apiV2.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))

export const getAbProfileV1Api = (businessId: string): Promise<any> => {
  return apiV1.postRequest(GET_AB_PROFILE_ENDPOINT_V1, { merchantId: businessId })
}

export const getAbProfileV2Api = ({
  businessId,
  individualUserId
}: {
  businessId: string
  individualUserId: string
}) =>
  apiV2.getRequest(
    GET_AB_PROFILE_ENDPOINT_V2,
    {},
    { 'OKC-Business-Id': businessId, 'Okc-User-Id': individualUserId }
  )
