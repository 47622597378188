import { select } from 'redux-saga/effects'
import { Action } from 'infra/types'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { IAppState } from 'infra/AppState'

const track = (event: string, paramObj: object = {}) => {
  Mixpanel.track(event, paramObj)
}

export function* newDashBoardEventsEffect(action: Action<any>) {
  const { merchantProfile: { data: { id } = { id: '' } } = {} } = yield select(
    (app: IAppState) => app.Dashboard
  )
  const { currentRoute: { path } = { path: '' } } = yield select((app: IAppState) => app.Navigation)

  if (!id) {
    return
  }
  const { eventName, eventProperties } = action.payload
  const payload = { merchant_id: id, path_name: path, ...(eventProperties && eventProperties) }

  yield track(eventName, payload)
}
