/* eslint-disable security/detect-object-injection */
import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

import CloseIcon from '../../assets/icons/icon-close.svg'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import DrawerStyles from './Drawer.styles'
import { IDrawer } from './Drawer.types'

const styles = createUseStyles(DrawerStyles)

interface RefObject<T> {
  readonly current: T | null
}

export const Drawer: React.FC<IDrawer> = ({
  title,
  slideFrom = 'right',
  className = '',
  overlayClassName = '',
  overlayComponent,
  children,
  showCloseIcon = false,
  onRequestClose = () => {},
  overlay = true,
  shouldCloseOnOutsideClick = true
}) => {
  const classes = styles()
  const [isOpen, setOpenState] = useState(false)
  const elementRef: RefObject<any> = useRef(null)

  const CloseOnOutsideClickFN = () => useOutsideClick(elementRef, onRequestClose)

  if (shouldCloseOnOutsideClick) {
    CloseOnOutsideClickFN()
  }

  useEffect(() => {
    setOpenState(true)
    return () => {
      setOpenState(false)
    }
  }, [])

  const drawer = (
    <>
      <div
        className={classNames({
          [overlayClassName]: true,
          [classes.overlay]: overlay
        })}
      >
        {overlayComponent && overlayComponent}
      </div>
      <div
        ref={elementRef}
        className={classNames({
          [classes.main]: true,
          [className]: true,
          [classes.slideFromLeft]: slideFrom === 'left' && !isOpen,
          [classes.slideFromRight]: slideFrom === 'right' && !isOpen,
          [classes.isOpen]: isOpen,
          //@ts-ignore
          [classes[slideFrom]]: true
        })}
      >
        <>
          {showCloseIcon && (
            <div className={classes.header}>
              <img
                src={CloseIcon}
                width={'24px'}
                height={'24px'}
                className={classes.closeIcon}
                onClick={onRequestClose}
                alt="Close icon"
              />
              {title && <div className={classes.title}>{title}</div>}
            </div>
          )}
          {children}
        </>
      </div>
    </>
  )
  if (typeof document !== 'undefined') {
    const modalElement = document.getElementById('app-modal')
    if (modalElement) {
      return ReactDOM.createPortal(drawer, modalElement)
    }
  }
  return null
}
