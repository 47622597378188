import { INavigationState } from './navigation.types'

export const NavigationState: INavigationState = {
  currentRoute: {
    pathName: '',
    path: '',
    queryParams: {},
    navParams: {}
  }
}
