import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const BellNotificationStyles = createUseStyles({
  container: {
    position: 'relative'
  },
  iconButton: {
    background: THEME.brandWhite
  },
  popupContainerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999
  },
  popupContainer: {
    width: '36rem',
    position: 'absolute',
    top: '7%',
    left: '25%',
    backgroundColor: THEME.brandWhite,
    borderRadius: '12px',
    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.16)',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  popupHeader: {
    backgroundColor: THEME.brandDarkGrey2,
    padding: '16px 8px',
    fontWeight: 600,
    fontSize: 16,
    borderRadius: '12px 12px 0 0'
  },
  popupContent: {
    padding: '8px 12px 12px 8px',
    fontSize: 14
  }
})
