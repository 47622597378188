import { call, race, take } from 'redux-saga/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import { bankStatementActions, setLoaderAction } from './bankStatement.actions'
import {
  createBankStatementTemplateEffect,
  updateBankStatementTemplateEffect,
  fetchBankStatementTemplatesEffect,
  fetchBankStatementDocumentsEffect,
  fetchBankStatementReconciledTxnsEffect,
  startBankStatementDocumentStatusPollingEffect,
  deleteBankStatementTxnsEffect,
  editAssetAccountEffect,
  getCashDepositHistoryEffect,
  downloadBankAndCashStatementEffect,
  fetchAccountTransactionEffect
} from './bankStatement.effects'

function* fetchBankStatementTemplatesSaga() {
  yield takeCustomLatest(
    bankStatementActions.fetchBankStatementTemplatesAction,
    fetchBankStatementTemplatesEffect,
    () => setLoaderAction({ id: 'bankStatementTemplate', value: false })
  )
}

function* createBankStatementTemplateSaga() {
  yield takeCustomLatest(
    bankStatementActions.createBankStatementTemplateAction,
    createBankStatementTemplateEffect,
    () => setLoaderAction({ id: 'bankStatementTemplate', value: false })
  )
}

function* updateBankStatementTemplateSaga() {
  yield takeCustomLatest(
    bankStatementActions.updateBankStatementTemplateAction,
    updateBankStatementTemplateEffect,
    () => setLoaderAction({ id: 'bankStatementTemplate', value: false })
  )
}

function* fetchBankStatementDocumentsSaga() {
  yield takeCustomLatest(
    bankStatementActions.fetchBankStatementDocumentsAction,
    fetchBankStatementDocumentsEffect,
    () => setLoaderAction({ id: 'bankStatementDocument', value: false })
  )
}

function* fetchBankStatementReconciledTxnsSaga() {
  yield takeCustomLatest(
    bankStatementActions.fetchBankStatementReconciledTxnsAction,
    fetchBankStatementReconciledTxnsEffect,
    () => setLoaderAction({ id: 'bankStatementReconciledTxns', value: false })
  )
}

function* deleteBankStatementTxnsSaga() {
  yield takeCustomLatest(
    bankStatementActions.deleteBankStatementTxnsAction,
    deleteBankStatementTxnsEffect,
    () => setLoaderAction({ id: 'bankStatementReconciledTxns', value: false })
  )
}

function* startBankStatementDocumentStatusPollingSaga() {
  while (true) {
    //@ts-ignore
    const action = yield take(bankStatementActions.startPollBankStatementDocumentStatus)
    yield race([
      call(startBankStatementDocumentStatusPollingEffect, action),
      take(bankStatementActions.stopPollBankStatementDocumentStatus)
    ])
  }
}

function* editAssetAccountSaga() {
  yield takeCustomLatest(bankStatementActions.editAssetAccount, editAssetAccountEffect)
}

function* getCashDepositHistorySaga() {
  yield takeCustomLatest(bankStatementActions.getCashDepositHistory, getCashDepositHistoryEffect)
}

function* downloadBankAndCashStatementSaga() {
  yield takeCustomLatest(
    bankStatementActions.downloadBankAndCashStatement,
    downloadBankAndCashStatementEffect
  )
}

function* fetchAccountTransactionSaga() {
  yield takeCustomLatest(
    bankStatementActions.fetchAccountTransaction,
    fetchAccountTransactionEffect
  )
}

export const BankStatementWatchers = [
  fetchBankStatementTemplatesSaga(),
  createBankStatementTemplateSaga(),
  updateBankStatementTemplateSaga(),
  fetchBankStatementDocumentsSaga(),
  fetchBankStatementReconciledTxnsSaga(),
  deleteBankStatementTxnsSaga(),
  startBankStatementDocumentStatusPollingSaga(),
  editAssetAccountSaga(),
  getCashDepositHistorySaga(),
  downloadBankAndCashStatementSaga(),
  fetchAccountTransactionSaga()
]
