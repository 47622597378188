import { call, put, select } from 'redux-saga/effects'
import { fetchChequeDetails, updateChequeDetailsApi } from 'services/Api/ChequeApi'
import { IAppState } from 'infra/AppState'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import {
  ChequeState,
  IFetchReceivedActionPayload,
  IFetchChequeRequestPayload,
  UpdateChequeDetailsType,
  UpdateChequeRequestPayload,
  Cheque,
  IFetchOtherActionPayload
} from './cheque.types'
import { CheuqueStateToStoreMapper, DEFAULT_PAGE_SIZE, formatChequesData } from './cheque.helpers'
import {
  resetEditChequeDrawer,
  setByChequeDateFilterAction,
  setCheques,
  setReceivedCheques,
  setReceivedSortedBy,
  setDepositedSortedBy,
  setFilterTime,
  setOtherCheques,
  setClearedSortedBy,
  fetchReceivedCheques,
  fetchOtherCheques
} from './cheque.actions'

export function* fetchReceivedChequesEffect(action: {
  payload: IFetchReceivedActionPayload
  type: string
}) {
  const { searchString, sortOptions, byChequeDate, page, perPage } = action.payload
  const requestPayload: IFetchChequeRequestPayload = {
    page: page || 1,
    per_page: perPage || DEFAULT_PAGE_SIZE,
    filters: {
      cheque_state: ChequeState.RECEIVED,
      ...(searchString && { search_string: searchString }),
      ...(byChequeDate?.startTime &&
        byChequeDate?.endTime && {
          cheque_date_interval: {
            start_time: byChequeDate.startTime,
            end_time: byChequeDate.endTime
          }
        })
    },
    ...(sortOptions && {
      sort_options: {
        sort_by: sortOptions.sortBy,
        sort_order: sortOptions.sortOrder
      }
    })
  }
  const response: IApiResponse = yield call(fetchChequeDetails, requestPayload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Cheque not Loaded, Please Refresh', {
      cause: 'customError'
    })
  }
  const receivedChequesData = formatChequesData(response.data)
  yield put(setReceivedCheques(receivedChequesData))
  yield put(
    setByChequeDateFilterAction({
      byChequeDateFilterType: 'receivedByChequeDateFilter',
      filterValue: byChequeDate?.startTime && byChequeDate.endTime ? byChequeDate : undefined
    })
  )
  yield put(setReceivedSortedBy({ sortBy: sortOptions?.sortBy ? sortOptions.sortBy : '' }))
}

export function* fetchOtherChequesEffect(action: { payload: IFetchOtherActionPayload }) {
  const { filterTime, chequeState, searchString, sortOptions, byChequeDate, page, perPage } =
    action.payload
  const requestPayload: IFetchChequeRequestPayload = {
    page: page || 1,
    per_page: perPage || DEFAULT_PAGE_SIZE,
    filters: {
      cheque_state: chequeState,
      collected_on_interval: {
        start_time: filterTime?.startTime || 0,
        end_time: filterTime?.endTime || 0
      },
      ...(byChequeDate && {
        cheque_date_interval: {
          start_time: byChequeDate.startTime,
          end_time: byChequeDate.endTime
        }
      }),
      ...(searchString && { search_string: searchString })
    },
    ...(sortOptions && {
      sort_options: {
        sort_by: sortOptions.sortBy,
        sort_order: sortOptions.sortOrder
      }
    })
  }

  const response: IApiResponse = yield call(fetchChequeDetails, requestPayload)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Cheque not Loaded, Please Refresh', {
      cause: 'customError'
    })
  }

  const formattedData = formatChequesData(response.data)

  yield put(
    setOtherCheques({
      ...formattedData,
      chequeType: CheuqueStateToStoreMapper[chequeState]
    })
  )

  yield put(
    setFilterTime({
      startTime: filterTime?.startTime || 0,
      endTime: filterTime?.endTime || 0,
      filterType: filterTime?.filterType
    })
  )

  if (chequeState === ChequeState.DEPOSITED) {
    yield put(
      setByChequeDateFilterAction({
        byChequeDateFilterType: 'depositedByChequeDateFilter',
        filterValue: byChequeDate?.startTime && byChequeDate.endTime ? byChequeDate : undefined
      })
    )

    yield put(setDepositedSortedBy({ sortBy: sortOptions?.sortBy ? sortOptions.sortBy : '' }))
  } else {
    yield put(
      setByChequeDateFilterAction({
        byChequeDateFilterType: 'clearedByChequeDateFilter',
        filterValue: byChequeDate?.startTime && byChequeDate.endTime ? byChequeDate : undefined
      })
    )
    yield put(setClearedSortedBy({ sortBy: sortOptions?.sortBy ? sortOptions.sortBy : '' }))
  }
}

export function* updateChequeDetailsEffect(action: {
  payload: {
    data: (UpdateChequeDetailsType & { state: ChequeState })[]
    type: 'receivedCheque' | 'depositedCheque' | 'clearedCheque'
  }
}) {
  const requestPayload: UpdateChequeRequestPayload = {
    cheque_details: action.payload.data.map((element) => ({
      ...element,
      cheque_date: Number(element.cheque_date),
      collected_on: Number(element.collected_on)
    }))
  }

  const response: IApiResponse = yield call(updateChequeDetailsApi, requestPayload)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Cheque Updation Failed', {
      cause: 'customError'
    })
  }

  const { data, type } = action.payload
  const {
    pagination: {
      [type]: { currentPage, pageSize, totalEntries }
    },
    filterTime
  } = yield select((app: IAppState) => app.Reconcile.Cheque)
  const totalPages = Math.ceil(totalEntries / pageSize)
  const itemsInLastPage = totalEntries % pageSize
  const isLastPage = totalPages === currentPage
  const pageToBeFetched =
    isLastPage && data.length === itemsInLastPage ? Math.max(currentPage - 1, 1) : currentPage
  if (type === 'receivedCheque') {
    yield put(fetchReceivedCheques({ page: pageToBeFetched }))
  } else if (type === 'depositedCheque') {
    yield put(
      fetchOtherCheques({ chequeState: ChequeState.DEPOSITED, filterTime, page: pageToBeFetched })
    )
  } else if (type === 'clearedCheque') {
    yield put(
      fetchOtherCheques({ chequeState: ChequeState.CLEARED, filterTime, page: pageToBeFetched })
    )
  }
}

export function* editChequeDetailsEffect(action: {
  payload: {
    data: UpdateChequeDetailsType & { state: ChequeState }
    type: 'receivedCheque' | 'depositedCheque' | 'clearedCheque'
  }
}) {
  const { data, type } = action.payload
  const requestPayload: UpdateChequeRequestPayload = {
    cheque_details: [data]
  }

  const response: IApiResponse = yield call(updateChequeDetailsApi, requestPayload)

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Cheque Updation Failed', {
      cause: 'customError'
    })
  }
  const storeData: Cheque[] = yield select((app: IAppState) => app.Reconcile.Cheque[type])
  const { resetSelectedRowRef } = yield select((app: IAppState) => app.Reconcile.Cheque)
  const updatedData = storeData.map((cheque: Cheque) => {
    if (cheque.chequeId === data.cheque_id) {
      return {
        ...cheque,
        chequeDate: data.cheque_date,
        chequeNo: data.cheque_number
      } as Cheque
    }

    return cheque
  })

  yield put(
    setCheques({
      data: updatedData,
      chequeType: type
    })
  )
  resetSelectedRowRef.receivedTable?.current?.resetRowSelection({})
  yield put(resetEditChequeDrawer())
  yield put(
    addAutoFadeNotification({
      type: NotificationType.SUCCESS,
      bodyText: 'Cheque updated Successfully'
    })
  )
}
