import { Action } from 'infra/types'
import { supplyAction } from './supply.actions'
import { FILTER_TYPE, ISupplyState } from './supply.types'
import { DEFAULT_TIME_FILTER } from './supply.constants'

/**
 * @description
 * 
 * We use following Mapping from API for supply tab (both Group level and inner Group Level)
 * 
    total_dues: 'Credit',
    total_payments: 'Total Payment',
    balance_due: 'Invoice Due',
    total_damages: 'Damages',
    total_cash_payments: 'Cash',
    total_neft_payments: 'NEFT',
    total_cheque_payments: 'Cheque',
    total_wallet_payments: 'Advance Used',
    total_online_payments: 'QR / Link',
    total_returns: 'Returns',
    total_invoices: 'Invoice Count',
    total_other_payments: 'Others'
 * 
 */

const SUPPLY_INITIAL_STATE: ISupplyState = {
  isFetchingStatementGroups: false,
  statementGroupTableData: [],
  specificGroupSummaryTotal: {
    invoiceDue: 0,
    credit: 0,
    totalPayments: 0,
    damages: 0,
    cash: 0,
    neft: 0,
    cheque: 0,
    advanceUsed: 0,
    qrLink: 0,
    returns: 0,
    others: 0
  },
  statementGroupFilter: {
    startTime: DEFAULT_TIME_FILTER,
    endTime: DEFAULT_TIME_FILTER,
    filterType: FILTER_TYPE.DEFAULT
  },
  statementGroupPagination: {
    currentPageNumber: 1,
    perPage: 50
  },
  specificGroupName: '',
  specificStatementGroup: undefined,
  specificGroupTableData: [],
  invoiceDetails: [],
  drawers: { downloadStatementSummary: false, downloadGroupStatementSummary: false },
  downloadStatementStatus: 'uninitiated',
  downloadGroupStatementStatus: 'uninitiated'
}

export const SupplySummaryReducer = (
  state: ISupplyState = SUPPLY_INITIAL_STATE,
  action: Action<any>
): ISupplyState => {
  switch (action.type) {
    case supplyAction.IS_FETCHING_STATEMENT_GROUP: {
      return {
        ...state,
        isFetchingStatementGroups: action.payload
      }
    }
    case supplyAction.SET_STATEMENT_GROUPS_DATA: {
      return {
        ...state,
        statementGroupTableData: action.payload
      }
    }
    case supplyAction.SET_SORT_BY: {
      const { sortBy, sortOrder } = action.payload
      return {
        ...state,
        sortBy,
        sortOrder
      }
    }
    case supplyAction.SET_INNER_TABLE_SORT_BY: {
      const { sortBy, sortOrder } = action.payload
      return {
        ...state,
        innerTableSortOption: {
          sortBy,
          sortOrder
        }
      }
    }
    case supplyAction.SET_SPECIFIC_GROUP_SUMMARY: {
      return {
        ...state,
        specificGroupSummaryTotal: action.payload
      }
    }
    case supplyAction.SET_SPECIFIC_GROUP_TABLE_DATA: {
      return {
        ...state,
        specificGroupTableData: action.payload
      }
    }
    case supplyAction.SET_SPECIFIC_GROUP_NAME: {
      return {
        ...state,
        specificGroupName: action.payload
      }
    }
    case supplyAction.SET_STATEMENT_GROUP_FILTER_PAGINATION_DATA: {
      const {
        payload: { startTime, endTime, currentPageNumber, perPage, filterType }
      } = action
      return {
        ...state,
        statementGroupFilter: {
          startTime,
          endTime,
          filterType
        },
        statementGroupPagination: {
          currentPageNumber,
          perPage
        }
      }
    }
    case supplyAction.SET_STATEMENT_DOWNLOAD_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          downloadStatementSummary: action.payload
        }
      }
    }
    case supplyAction.SET_GROUP_STATEMENT_DOWNLOAD_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          downloadGroupStatementSummary: action.payload
        }
      }
    }
    case supplyAction.SET_DOWNLOAD_STATEMENT_STATUS: {
      return {
        ...state,
        downloadStatementStatus: action.payload
      }
    }
    case supplyAction.SET_INVOICES_DATA: {
      return {
        ...state,
        invoiceDetails: action.payload
      }
    }
    case supplyAction.SET_SPECIFIC_STATEMENT_GROUP: {
      return {
        ...state,
        specificStatementGroup: action.payload
      }
    }
    case supplyAction.UNSET_SPECIFIC_STATEMENT_GROUP: {
      return {
        ...state,
        specificStatementGroup: undefined
      }
    }
    case supplyAction.CLEAR_GROUP_SPECIFIC_DATA: {
      return {
        ...state,
        specificGroupName: '',
        specificStatementGroup: undefined
      }
    }
    default: {
      return state
    }
  }
}
