/* eslint-disable security/detect-non-literal-fs-filename */
import { AxiosRequestConfig } from 'axios'

import { AuthApi } from 'services/Api/AuthApi'

import { keyedObjectWithId } from 'utils/dataNormalizer'
import {
  convertPaisaToRupee,
  formatInventoryItemsDataV2Extended
} from 'state/billing/billing.helpers'
import { FetchOrdersApiResponse, ProformaResponse } from 'state/new-summary/orders/orders.types'
import { getFormattedProformaData } from 'state/new-summary/orders/orders.formatter'
import { FETCH_INVENTORY_ITEMS_RESPONSE } from 'services/Api/BillingApiFolder.ts/type'
import {
  IStaffInventoryItems,
  ITransactionType,
  ItemDetailsLite,
  OrderHistory,
  OrderHistoryResponse,
  OrderStatus,
  StaffProformaRowData
} from './StaffLink.types'
import { TransactionTypeEnum, AuthLocalStoragerEnum, LocalStorageEnum } from './StaffLink.constants'

/*
 *   Takes a string and generates initials with first letter of first two words in string.
 *   If empty string is passed it returns 'OK'
 *   @arg: String
 *   @return: String
 *   Used in Avatar
 */
export const generateInitials = (str: string = '') => {
  const splitName = str
    .split(' ')
    .map((word) => word.split('')[0])
    .join('')
    .toUpperCase()
  if (splitName.length === 0) {
    return 'OK'
  }
  if (splitName.length < 2) {
    return splitName[0]
  }
  return `${splitName[0]}${splitName[1]}`
}

/*
 * Refresh current page
 */
export const refreshPage = () => {
  if (typeof window !== 'undefined') {
    window.location.reload()
  }
}

/*
 * Open phone's default caller app
 */
export const openPhoneCaller = (phone: string) => {
  try {
    if (typeof window !== 'undefined') {
      window.open(`tel:${phone}`)
    }
  } catch (error) {
    console.error('Phone Dialer failed with: ', error)
  }
}

/*
 * Whatsapp intent with pre-filled phone and message
 */
export const openWhatsApp = (phone: string | undefined, text: string) => {
  try {
    const message = encodeURI(text)
    if (typeof window !== 'undefined') {
      if (phone) {
        // Fire share intent to specific number
        window.open(`https://api.whatsapp.com/send?phone=+91${phone}&text=${message}`)
      } else {
        // Fire general whatsapp share intent without number
        window.open(`https://api.whatsapp.com/send?&text=${message}`)
      }
      // window.open(`sms:${phone}?body=${message}`)
    }
  } catch (error) {
    console.error('Phone Dialer failed with: ', error)
  }
}

/*
 * Takes args and returns a text message template.
 * Configure the template here.
 */
export const generateText = (
  balance: number,
  paymentLink: string,
  merchantPhone: string | undefined,
  merchantName: string | undefined
) => {
  const bal = Math.abs(balance)

  return `${merchantName} (${merchantPhone}) is requesting payment. Due amount is ₹ ${bal}.\n\nClick on the link to pay with your favorite UPI app. \n\nSecure OkCredit Payment link: ${paymentLink}`
}

/*
 * Takes the the size of array of customers with balance < 0 and returns a string accordingly
 * Used in staffLink header
 */
export const getHeaderMessage = (length: number) => {
  let finalString
  if (length === 0) {
    finalString = 'You have completed collection from all the customers'
  } else if (length === 1) {
    finalString = `You have ${length} customer in the list to collect from`
  } else {
    finalString = `You have ${length} customers in the list to collect from`
  }
  return finalString
}

/*
 * Takes object and returns it if the customer_name property contains the searchTerm
 * Searches with name and phone
 * Used in staffLink/index
 */
export const filterItems = (item: any, searchTerm: string) => {
  if (searchTerm === '') {
    return true
  }
  const searchText = searchTerm.trim()?.toLowerCase()
  return (
    item?.customer_name?.toLowerCase().includes(searchText) ||
    item?.bills?.[0]?.bill?.bill_number?.toLowerCase().includes(searchText) ||
    item?.customer_mobile?.toLowerCase().includes(searchText) ||
    item?.customer_code?.toLowerCase().includes(searchText)
  )
}

export const isStaging = () => {
  if (typeof window !== 'undefined') {
    if (
      window?.location?.origin?.includes('staging') ||
      window?.location?.origin?.includes('localhost')
    ) {
      return true
    }
    return false
  }
  return true
}

export const getPassbookReditectUrl = (accountId: string) => {
  return `https://account${isStaging() ? '.staging' : ''}.okcredit.in/account/${accountId}`
}

export const getAuthLoginUrl = ({
  businessId,
  staffLinkId
}: {
  businessId: string
  staffLinkId: string
}) => {
  return `https://payments-web${
    isStaging() ? '.staging' : ''
  }.okcredit.in/otp/phone?s=staffLinkAuth&pathId=${businessId}/${staffLinkId}`
}

export const getDateAfterNDays = (n: number) => {
  try {
    const today = new Date()
    const nDayLater = (new Date().setDate(today.getDate() + n) / 1000) | 0
    return nDayLater
  } catch (error) {
    return 0
  }
}

export const precisionRound = (number: number, precision: number): number => {
  const factor = 10 ** precision
  const n = precision < 0 ? number : 0.01 / factor + number
  return Math.round(n * factor) / factor
}

export const checkCollectionType = (type: number, transactionType: ITransactionType): string => {
  try {
    switch (type) {
      case transactionType.CASH:
        return TransactionTypeEnum.CASH
      case transactionType.NEFT:
        return TransactionTypeEnum.NEFT
      case transactionType.CHEQUE:
        return TransactionTypeEnum.CHEQUE
      case transactionType.RETURN:
        return TransactionTypeEnum.RETURN
      case transactionType.DAMAGED:
        return TransactionTypeEnum.DAMAGED
      case transactionType.WALLET:
        return TransactionTypeEnum.WALLET
      case transactionType.UPI:
        return TransactionTypeEnum.OTHER
      case transactionType.QR:
        return TransactionTypeEnum.QR
      default:
        return TransactionTypeEnum.CASH
    }
  } catch (_) {
    return ''
  }
}
export const collectionTabClassType: ('warn' | 'danger' | 'success' | 'default')[] = [
  'default',
  'success',
  'success',
  'success',
  'success',
  'success',
  'danger',
  'danger',
  'success',
  'success'
]
export const getLocalStorageInstance = () => {
  if (typeof window === 'undefined') {
    return {}
  }
  const localStorage = window.localStorage

  return {
    get: (key: string) => localStorage.getItem(key),
    set: (key: string, value: string) => localStorage.setItem(key, value),
    removeItem: (key: string) => localStorage.removeItem(key),
    clearItems: (keys: string[]) => keys.forEach((key) => localStorage.removeItem(key))
  }
}

export const setNewTokensToLocalStorage = (
  accessToken: string,
  expiry: number,
  refreshToken: string
) => {
  const localStorage = getLocalStorageInstance()
  if (!localStorage?.set) {
    return null
  }
  const currentTime = new Date()
  const expiresIn = currentTime.getTime() + (expiry - 60) * 1000
  localStorage.set(AuthLocalStoragerEnum.staffLinkAccessToken, accessToken)
  localStorage.set(AuthLocalStoragerEnum.staffLinkExpiresIn, `${expiresIn}`)
  localStorage.set(AuthLocalStoragerEnum.staffLinkRefreshToken, refreshToken)
}

export const setStaffNumberToLocal = (staffPhoneNumber: string) => {
  const localStorage = getLocalStorageInstance()
  if (!localStorage?.set) {
    return null
  }
  localStorage.set(LocalStorageEnum.staffPhoneNumber, staffPhoneNumber)
}

export const getStaffNumberFromLocal = () => {
  const localStorage = getLocalStorageInstance()
  if (!localStorage?.set) {
    return null
  }
  return localStorage.get(LocalStorageEnum.staffPhoneNumber)
}

export const removeStaffNumberFromLocal = () => {
  const localStorage = getLocalStorageInstance()
  if (!localStorage?.set) {
    return null
  }
  localStorage.removeItem(LocalStorageEnum.staffPhoneNumber)
}

export const handleAuthLogout = () => {
  const localStorage = getLocalStorageInstance()
  if (!localStorage?.get || !localStorage?.clearItems) {
    return null
  }
  localStorage.clearItems([
    AuthLocalStoragerEnum.staffLinkExpiresIn,
    AuthLocalStoragerEnum.staffLinkAccessToken,
    AuthLocalStoragerEnum.staffLinkRefreshToken,
    LocalStorageEnum.staffPhoneNumber
  ])
  const businessId = localStorage.get(AuthLocalStoragerEnum.staffLinkBusinessId)
  const staffLinkId = localStorage.get(AuthLocalStoragerEnum.staffLinkId)
  if (businessId && staffLinkId) {
    window.location.href = getAuthLoginUrl({ businessId, staffLinkId })
  }
}

export const apiRequestInterceptor = async (req: AxiosRequestConfig) => {
  try {
    const localStorage = getLocalStorageInstance()
    if (!localStorage?.get) {
      return null
    }

    const token = localStorage?.get(AuthLocalStoragerEnum.staffLinkAccessToken)
    if (token) {
      const tokenExpiry = Number(localStorage?.get(AuthLocalStoragerEnum.staffLinkExpiresIn))
      const isTokenExpired = tokenExpiry ? tokenExpiry < new Date().getTime() : true
      if (isTokenExpired) {
        const refreshToken = localStorage?.get(AuthLocalStoragerEnum.staffLinkRefreshToken)
        if (refreshToken) {
          const refreshApiResponse = await AuthApi.authRefreshToken(refreshToken)
          if (
            refreshApiResponse?.data &&
            refreshApiResponse?.data?.response &&
            refreshApiResponse?.data?.response?.status >= 400
          ) {
            handleAuthLogout()
          }
          const { access_token, refresh_token, expires_in } = refreshApiResponse.data
          setNewTokensToLocalStorage(access_token, parseInt(expires_in), refresh_token)
          req.headers.Authorization = `Bearer ${access_token}`
          req.headers['content-type'] = 'application/json'
        } else {
          handleAuthLogout()
        }
      } else {
        req.headers.Authorization = token ? `Bearer ${token}` : ''
      }
    } else {
      handleAuthLogout()
    }
  } catch (err) {
    handleAuthLogout()
    console.log('Something went wrong with req interceptor: ', err)
  }
  return req
}

export const formatStaffLinkInvoices = (data: any) => {
  const formattedAccountDetails = data.account_details?.map((accountDetail: any) => ({
    ...accountDetail,
    bills: [],
    approved_transactions: [],
    transactions: [],
    replacements: [],
    deductions: [],
    approved_transactions_summary: {}
  }))
  const keyedAccountDetails = keyedObjectWithId(formattedAccountDetails)
  data?.bill_details?.forEach((billDetail: any) => {
    const billReplacements = billDetail?.replacements ? [billDetail?.replacements] : []
    const billTransactions = billDetail?.transactions || []
    const billApprovedTransactions = billDetail?.approved_transactions || []
    const accountDetails = keyedAccountDetails[billDetail?.bill?.customer_id]
    keyedAccountDetails[billDetail?.bill?.customer_id] = {
      ...accountDetails,
      account_id: accountDetails?.id || '',
      customer_name: accountDetails?.name || '',
      balance: accountDetails?.balance || '',
      customer_mobile: accountDetails?.customer_mobile || '',
      approved_transactions_summary: {
        ...accountDetails?.approved_transactions_summary,
        [billDetail?.bill?.bill_id]: billDetail?.approved_transactions_summary
      },
      bills: accountDetails?.bills
        ? [
            ...accountDetails?.bills,
            {
              bill: billDetail?.bill,
              bill_cancel_params: billDetail?.bill_cancel_params,
              redeliver: billDetail?.redeliver,
              bill_beats: billDetail?.bill_beats
            }
          ]
        : [{ bill: billDetail?.bill, bill_cancel_params: billDetail?.bill_cancel_params }],
      transactions: accountDetails?.transactions
        ? [...accountDetails?.transactions, ...billTransactions]
        : billTransactions,
      approved_transactions: accountDetails?.approved_transactions
        ? [...accountDetails?.transactions, ...billApprovedTransactions]
        : billApprovedTransactions,
      replacements: accountDetails?.replacements
        ? [...accountDetails?.replacements, ...billReplacements]
        : billReplacements,
      deductions: []
    }
  })
  const account_details = Object.values(keyedAccountDetails)
  return {
    ...data,
    account_details: account_details,
    accountIds: account_details?.map((itm: any) => itm.id)
  }
}

export const formattedOrderHistoryData = (
  items: OrderHistoryResponse['inventory_items'],
  localItems: IStaffInventoryItems[],
  status: OrderStatus
): ItemDetailsLite[] => {
  return items.map((item) => {
    const findItem = localItems?.find((_) => _.inventoryItemId === item.inventory_item_id)
    const quantityResolved =
      status === OrderStatus.INVOICED
        ? Math.abs((item?.proforma_invoiced_quantity || 0) - (item?.invoiced_quantity || 0))
        : Math.abs((item?.invoiced_quantity || 0) + (item?.proforma_invoiced_quantity || 0))
    return {
      itemName: findItem?.item || '',
      quantity: item?.quantity,
      amount: convertPaisaToRupee(item?.amount),
      createTime: item?.create_time,
      id: item?.inventory_item_id,
      invoicedQuantity: quantityResolved,
      rate: convertPaisaToRupee(item.info?.rate || 0)
    }
  })
}

const getFormattedIndividualOrder = (
  order: OrderHistoryResponse,
  localItems: IStaffInventoryItems[]
) => {
  return {
    accountId: order?.account_id,
    amount: convertPaisaToRupee(order?.amount),
    createTime: Number(order?.create_time) * 1000,
    updatedTime: Number(order?.update_time) * 1000,
    createdById: order?.created_by_id,
    createdByType: order?.created_by_type,
    id: order?.id,
    status: order?.status,
    items: formattedOrderHistoryData(order?.inventory_items, localItems, order?.status),
    proformaIds: order.proforma_ids || [],
    orderNumber: order.order_number
  }
}

export const getOrderByStatus = (
  orders: Array<OrderHistoryResponse>,
  status: OrderStatus,
  localItems: IStaffInventoryItems[]
) => {
  if (!orders?.length) {
    return []
  }
  return orders
    .filter((_) => _.status === status)
    .map((order: OrderHistoryResponse) => getFormattedIndividualOrder(order, localItems))
    .sort((a, b) => b.createTime - a.createTime)
}

export const getFormattedProformaInvoices = (
  orders: Array<OrderHistoryResponse>,
  proformas: FetchOrdersApiResponse['proforma'],
  localItems: IStaffInventoryItems[]
): Array<StaffProformaRowData> => {
  if (!orders || orders.length === 0) {
    return []
  }
  return orders.flatMap((order) => {
    const orderDetails = getFormattedIndividualOrder(order, localItems)
    const proforma = proformas || []
    const proformaDetails = proforma?.filter(
      (proformaItem) =>
        orderDetails.proformaIds.includes(proformaItem.proforma_id) &&
        orderDetails.status === OrderStatus.PROFORMA_CREATED &&
        proformaItem.status === 1
    )

    return proformaDetails?.map((proformaItem) => ({
      orderDetails,
      ...getFormattedProformaData(proformaItem as ProformaResponse)
    }))
  })
}

export const getFormattedOrderHistoryItems = (
  orderHistory: Array<OrderHistoryResponse>,
  proformaInvoices: Array<ProformaResponse>,
  localItems: IStaffInventoryItems[]
): OrderHistory => {
  return {
    pending: getOrderByStatus(orderHistory, OrderStatus.PENDING, localItems),
    approved: [
      ...getOrderByStatus(orderHistory, OrderStatus.APPROVED, localItems),
      ...getOrderByStatus(orderHistory, OrderStatus.PROFORMA_CREATED, localItems)
    ],
    invoiced: getOrderByStatus(orderHistory, OrderStatus.INVOICED, localItems),
    cancelled: getOrderByStatus(orderHistory, OrderStatus.CANCELLED, localItems),
    rejected: getOrderByStatus(orderHistory, OrderStatus.REJECTED, localItems),
    proformaInvoiced: getFormattedProformaInvoices(orderHistory, proformaInvoices, localItems)
  }
}

export const getStaffFormattedItems = (
  items: FETCH_INVENTORY_ITEMS_RESPONSE['inv_items']
): Array<any> => {
  return {
    ...formatInventoryItemsDataV2Extended(items)
  }
}

export const getStaffFormattedBusinessDetails = (data: any) => {
  return {
    name: data?.name,
    businessGst: data?.gst_number
  }
}
