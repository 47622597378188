import { Action } from 'infra/types'
import { assignStaffActions } from './assign-staff.actions'
import { IAssignStaffState } from './assign-staff.types'

const ASSIGN_STAFF_STATE: IAssignStaffState = {
  listId: '',
  listUrl: '',
  drawers: {
    showAssignStaffDrawer: false
  }
}

export const AssignStaffReducer = (
  state: IAssignStaffState = ASSIGN_STAFF_STATE,
  action: Action<any>
): IAssignStaffState => {
  switch (action.type) {
    case assignStaffActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case assignStaffActions.SET_LIST_DETAILS: {
      return {
        ...state,
        listId: action.payload.listId,
        listUrl: action.payload.listUrl,
        drawers: {
          ...state.drawers,
          showAssignStaffDrawer: action.payload.showAssignStaffDrawerStatus
        }
      }
    }
    case assignStaffActions.RESET_ASSIGN_STAFF_STATE: {
      return ASSIGN_STAFF_STATE
    }
    default: {
      return state
    }
  }
}
