interface INormalisedList {
  allIds: string[]
  byIds: { [id: string]: any }
}

export const getNormalizedList = (list: [{ id: string }]) => {
  const normalisedList: INormalisedList = {
    byIds: {},
    allIds: []
  }

  list.forEach((_) => {
    normalisedList.byIds[_.id] = _
    normalisedList.allIds.push(_.id)
  })

  return normalisedList
}

export const getDeNormalizedList = (list: INormalisedList, selectedIds?: string[]) => {
  return (selectedIds || list.allIds).map((_) => list.byIds[_])
}

export const keyedObjectWithId = (list: Array<any>) => {
  const obj = {} as any
  list?.forEach((element: any) => {
    obj[element.id] = element
  })
  return obj
}
