import React from 'react'
import { Button, CloseIcon, TrashIcon } from '@okcredit/web-lib'
import { THEME } from 'components/theme'
import ConfirmDeleteDialogStyles from './style'

export const ConfirmDeleteDialog = ({
  businessName,
  managerMobile,
  handleClose,
  handleConfirm
}: {
  businessName: string
  managerMobile: string
  handleClose: () => void
  handleConfirm: () => void
}) => {
  const classes = ConfirmDeleteDialogStyles()
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          <TrashIcon size={24} fill={THEME.brandRed} />
        </div>
        <div className={classes.title}>Do you really want to remove the business manager ?</div>
        <div className={classes.info}>
          <b>{managerMobile}</b> will not have any access to business <b>{businessName}</b>
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          size="medium"
          variant="outlined"
          color="default"
          onClick={handleClose}
          startIcon={<CloseIcon />}
          fullWidth
        >
          Not Yet
        </Button>
        <Button
          size="medium"
          color="secondary"
          onClick={handleConfirm}
          fullWidth
          startIcon={<TrashIcon fill={THEME.brandWhite} />}
        >
          Remove Manager
        </Button>
      </div>
    </div>
  )
}
