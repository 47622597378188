import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const BusinessSelectorStyles = createUseStyles({
  container: {
    position: 'relative'
  },
  selectorHeader: {
    fontSize: '24px',
    padding: '4px 52px 4px 16px',
    border: `solid 1px ${THEME.brandGreyPrimary}`,
    borderRadius: '21px',
    backgroundColor: THEME.brandWhite,
    fontWeight: 'bold',
    cursor: 'pointer',
    position: 'relative'
  },
  selectorIcon: {
    position: 'absolute',
    right: '4px',
    top: '4.5px',
    transform: 'rotate(180deg)',
    transition: 'all 0.2s ease-in-out'
  },
  selectorIconOpen: {
    transform: 'rotate(0)'
  },
  selectorContainerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999
  },
  selectorContainer: {
    position: 'absolute',
    top: '7%',
    left: '77px',
    minWidth: '480px',
    backgroundColor: THEME.brandWhite,
    borderRadius: '8px',
    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.16)',
    zIndex: 1,
    padding: '16px 8px'
  },
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    overflowY: 'hidden',
    zIndex: '99999'
  },
  spinner: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    left: 'calc(50% - 24px)'
  },
  spinneText: {
    position: 'absolute',
    top: 'calc(50% + 48px)',
    width: '100%',
    textAlign: 'center'
  }
})

export const BodyHeaderStyles = createUseStyles({
  selectorBodyHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  profileImage: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    background: THEME.brandMediumGreen,
    color: THEME.brandWhite,
    paddingTop: '30px',
    textAlign: 'center',
    boxSizing: 'border-box',
    fontSize: '24px'
  },
  businessName: {
    fontSize: '24px',
    color: THEME.brandBlack,
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 'bold',
    marginBottom: '8px'
  }
})

export const BusinessListStyles = createUseStyles({
  selectBusinessContainer: {
    marginTop: '16px',
    border: `solid 1px ${THEME.brandGreyPrimary}`,
    borderRadius: '8px'
  },
  inputNewBusinessContainer: {
    marginTop: '16px',
    border: `solid 1px ${THEME.brandMediumGreen}`,
    borderRadius: '8px'
  },
  bgGrey: {
    backgroundColor: THEME.brandGreyPrimary
  },
  bgGreen: {
    backgroundColor: THEME.brandLightGreen
  },
  businessItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: THEME.brandBlack,
    padding: '16px 8px',
    fontWeight: 'bold',
    fontSize: '16px',
    borderRadius: '8px 8px 0 0',
    borderBottom: `solid 1px ${THEME.brandGreyPrimary}`,
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none'
    }
  }
})

export const NewBusinessInputStyles = createUseStyles({
  inputNewBusinessContainer: {
    marginTop: '16px',
    border: `solid 1px ${THEME.brandMediumGreen}`,
    borderRadius: '8px'
  },
  bgGreen: {
    backgroundColor: THEME.brandLightGreen
  },
  businessItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: THEME.brandBlack,
    padding: '16px 8px',
    fontWeight: 'bold',
    fontSize: '16px',
    borderRadius: '8px 8px 0 0',
    borderBottom: `solid 1px ${THEME.brandGreyPrimary}`,
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  businessInput: {
    border: 'none',
    outline: 'none',
    fontSize: '16px',
    width: '100%'
  },
  submitBtn: {
    outline: 'none',
    border: 'none',
    background: 'transparent'
  },
  errorInput: {
    fontSize: '12px',
    color: THEME.brandRed,
    marginTop: '4px'
  }
})
