import { Action } from '../../infra/types'
import { StatementsActions } from './statements.actions'
import { StatementsState } from './statements.state'
import { IStatementsState } from './statements.types'

export const StatementsReducer = (
  state: IStatementsState = StatementsState,
  action: Action<any>
): IStatementsState => {
  switch (action.type) {
    case StatementsActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case StatementsActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case StatementsActions.HANDLE_TXN_ACCEPT_OR_REMOVE: {
      return {
        ...state,
        selectedDataForTxnAdvanceAndRemove: action.payload,
        drawers: {
          ...state.drawers,
          txnAdvanceAndRemovePopup: true
        }
      }
    }
    case StatementsActions.GET_OWNER_SUMMARY_SUCCESS: {
      return {
        ...state,
        ownerSummary: {
          groupSummary:
            action.payload.level === 0 ? action.payload : state.ownerSummary.groupSummary,
          customersSummary:
            action.payload.level === 1 ? action.payload : state.ownerSummary.customersSummary
        },
        loaders: {
          ...state.loaders,
          txnAdvanceAndRemoveAction: false
        },
        drawers: {
          ...state.drawers,
          txnAdvanceAndRemovePopup: false
        }
      }
    }
    // TODO: Can keep one case for balance and invoice due
    case StatementsActions.GET_COLLECTION_LIST_SUMMARY_SUCCESS: {
      const { append, summaryStats, ...rest } = action.payload
      let newGroupSummary
      if (append) {
        newGroupSummary = {
          ...rest,
          ownerSummaryOverall: state.ownerSummary.groupSummary?.ownerSummaryOverall,
          summaryStats: [...(state.ownerSummary.groupSummary?.summaryStats || []), ...summaryStats]
        }
      } else {
        newGroupSummary = action.payload
      }
      return {
        ...state,
        ownerSummary: {
          ...state.ownerSummary,
          groupSummary: newGroupSummary
        }
      }
    }

    case StatementsActions.GET_COLLECTION_LIST_INVOICE_SUMMARY_SUCCESS: {
      const { append, summaryStats, ...rest } = action.payload
      let newGroupSummary
      if (append) {
        newGroupSummary = {
          ...rest,
          ownerSummaryOverall: state.ownerSummary.groupSummary?.ownerSummaryOverall,
          summaryStats: [...(state.ownerSummary.groupSummary?.summaryStats || []), ...summaryStats]
        }
      } else {
        newGroupSummary = action.payload
      }
      return {
        ...state,
        ownerSummary: {
          ...state.ownerSummary,
          groupSummary: newGroupSummary
        }
      }
    }
    // TODO: Can keep one case for balance and invoice due
    case StatementsActions.GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY_SUCCESS: {
      const { append, summaryStats, ...rest } = action.payload
      let newCustomersSummary
      if (append) {
        newCustomersSummary = {
          ...rest,
          ownerSummaryOverall: state.ownerSummary.customersSummary?.ownerSummaryOverall,
          summaryStats: [
            ...(state.ownerSummary.customersSummary?.summaryStats || []),
            ...summaryStats
          ]
        }
      } else {
        newCustomersSummary = action.payload
      }
      return {
        ...state,
        ownerSummary: {
          ...state.ownerSummary,
          customersSummary: newCustomersSummary
        }
      }
    }

    case StatementsActions.GET_SINGLE_COLLECTION_LIST_SUMMARY_SUCCESS: {
      const { append, summaryStats, ...rest } = action.payload
      let newCustomersSummary
      if (append) {
        newCustomersSummary = {
          ...rest,
          ownerSummaryOverall: state.ownerSummary.customersSummary?.ownerSummaryOverall,
          summaryStats: [...[state.ownerSummary.customersSummary?.summaryStats], ...summaryStats]
        }
      } else {
        newCustomersSummary = action.payload
      }
      return {
        ...state,
        ownerSummary: {
          ...state.ownerSummary,
          customersSummary: newCustomersSummary
        }
      }
    }
    case StatementsActions.FETCH_UNAPPROVED_TRANSACTION_SUMMARY_SUCCESS: {
      return {
        ...state,
        unapprovedTxns: action.payload
      }
    }
    case StatementsActions.SET_CURRENT_VIEW: {
      return {
        ...state,
        currentView: action.payload
      }
    }
    case StatementsActions.SET_CURRENT_SUMMARY_TYPE: {
      return {
        ...state,
        currentSummaryType: action.payload
      }
    }
    case StatementsActions.SET_CURRENT_COLLECTION_LIST_ID: {
      return {
        ...state,
        currentCollectionListId: action.payload
      }
    }
    case StatementsActions.FETCH_ACCOUNT_ALLOCATION_DETAILS_SUCCESS: {
      return {
        ...state,
        accountAllocationDetails: action.payload
      }
    }
    case StatementsActions.GET_FINAL_BALANCE_DOWNLOAD_DATA: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          download: true
        },
        downloadData: {
          ...state.downloadData,
          status: 'downloading'
        }
      }
    }
    case StatementsActions.GET_INVOICE_LEVEL_DOWNLOAD_DATA: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          download: true
        },
        downloadData: {
          ...state.downloadData,
          status: 'downloading'
        }
      }
    }
    case StatementsActions.SET_DOWNLOAD_STATUS: {
      return {
        ...state,
        downloadData: {
          ...state.downloadData,
          status: action.payload
        }
      }
    }
    case StatementsActions.SET_V2_SUMMARY_DOWNLOAD_DRAWER_STATUS: {
      return {
        ...state,
        v2SummaryDownloadDrawer: action.payload
      }
    }
    case StatementsActions.SET_REPLACEMENT_SUMMARY_DOWNLOAD_DRAWER_STATUS: {
      return {
        ...state,
        replacementSummaryDownloadDrawer: action.payload
      }
    }
    case StatementsActions.FETCH_PENDING_REPLACEMENT_LIST_SUCCESS: {
      return {
        ...state,
        replacements: action.payload
      }
    }
    default: {
      return state
    }
  }
}
