import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const DefaulterPageStyles = createUseStyles({
  root: {
    backgroundColor: THEME.brandWhite,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: '0 0 14px'
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    flexGrow: 1,
    padding: 16
  },
  hentry: {
    display: 'block',
    maxWidth: 270,
    height: 'auto',
    marginBottom: 10
  },
  desc: {
    fontSize: 14,
    lineHeight: '1.6',
    margin: '0 0 16px',
    color: THEME.brandBlack
  },
  errorSection: {
    padding: '0 16px 16px',
    background: '#fff',
    textAlign: 'center',
    '& p': {
      fontSize: 14,
      margin: '5px 0 10px'
    }
  },
  timesIcon: {
    marginTop: 16
  },
  supportBtn: {
    marginTop: 2
  },
  langContainer: {
    padding: 16,
    marginTop: 56,
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    margin: '0px 8px',
    width: 88,
    display: 'inline-block',
    textAlign: 'center',
    fontSize: 14
  }
})
