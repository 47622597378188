import { IBill } from 'state/collectionList/collectionList.types'
import { isToday } from '../CustomerListItem/CustomerListItem.helpers'
import { TRANSACTION_TYPE } from './CollectionSummaryDrawer.types'

export const formatDate = (timeStamp: Date) => {
  try {
    const newDate = new Date(timeStamp).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
    const [monthAndDay, year] = newDate.split(',')
    const [month, day] = monthAndDay.split(' ')
    const returnData = `${day} ${month} ${year.trim()}`
    return returnData
  } catch (error) {
    return ''
  }
}

export const formatDateWithTime = (timeStamp: number) => {
  try {
    const newDate = new Date(timeStamp).toLocaleDateString('en-us', {
      month: 'short',
      day: 'numeric',
      minute: 'numeric',
      hour: 'numeric',
      hour12: true
    })
    const [monthAndDay, time] = newDate.split(',')
    const [month, day] = monthAndDay.split(' ')
    const returnData = `${day} ${month}, ${time}`
    return returnData
  } catch (error) {
    return ''
  }
}

export const getTransactionTypeLabel = (transactionType: TRANSACTION_TYPE) => {
  switch (transactionType) {
    case TRANSACTION_TYPE.CASH:
      return 'Cash'
    case TRANSACTION_TYPE.ONLINE:
      return 'Online'
    case TRANSACTION_TYPE.NEFT:
      return 'NEFT'
    case TRANSACTION_TYPE.CHEQUE:
      return 'Cheque'
    case TRANSACTION_TYPE.DAMAGED:
      return 'Damage'
    case TRANSACTION_TYPE.RETURN:
      return 'Return'
    case TRANSACTION_TYPE.UPI:
      return 'OTHER'
    case TRANSACTION_TYPE.WALLET:
      return 'Advance'
    default:
      return ''
  }
}

export const getTotalCreditByDay = (
  dueCustomerList: Array<any>
): { newCredit: number; oldCredit: number; total: number } => {
  // Initialize a variable to store the total sum
  let newSum = 0
  let oldSum = 0

  // Loop through each item in the items array
  for (const item of dueCustomerList) {
    // Check if the item has a bills array
    if (Array.isArray(item.bills)) {
      // Use reduce to sum the elements within the bills array (or 0 if empty)
      newSum += item.bills
        .filter((bill: IBill) => Number(bill.bill.total_amount || 0) < 0)
        .reduce((acc: number, bill: IBill) => {
          if (isToday(bill.bill?.bill_date)) {
            return acc + Math.abs(Number(bill.bill.total_amount || 0))
          }
          return acc
        }, 0)
      oldSum += item.bills
        .filter((bill: IBill) => Number(bill?.bill?.total_amount) < 0 && bill.bill.status !== 3)
        .reduce((acc: number, bill: IBill) => {
          if (!isToday(bill.bill?.bill_date)) {
            return (
              acc +
              Math.abs(
                Number(bill?.bill?.total_amount || 0) + Number(bill?.bill?.total_paid_amount || 0)
              )
            )
          }
          return acc
        }, 0)
    }
  }
  const total = newSum / 100 + oldSum / 100
  return {
    newCredit: newSum / 100,
    oldCredit: oldSum / 100,
    total
  }
}
