import { Action } from 'infra/types'
import { PAGE_LIMIT } from 'pages/NewDashboard/components/Billing/InvoiceItem/InvoiceTransactionHistoryBody/util'
import { bankStatementActions } from './bankStatement.actions'
import {
  BankStatementDocumentState,
  BankStatementDocumentType,
  BankStatementFilter,
  IBankStatementState,
  IBankHistoryType
} from './bankStatement.types'

const BANK_STATEMENT_INITIAL_STATE: IBankStatementState = {
  templates: [],
  documents: [],
  pollingDocument: null,
  transactions: {
    reconciledTransactions: [],
    unReconciledTransactions: [],
    filters: {
      transactionType: [],
      bank: []
    },
    selectedFilters: {
      transactionType: [],
      bank: []
    }
  },
  loaders: {
    bankStatementTemplate: false,
    bankStatementDocument: false,
    bankStatementDocumentStatus: false,
    bankStatementReconciledTxns: false,
    bankReconciliationTxns: false
  },
  drawers: {
    downloadBankStatement: false,
    templates: false,
    uploadedFiles: false,
    showLogAssetAccountDrawer: false,
    showEditAssetAccount: false
  },
  editAssetId: '',
  depositHistory: {
    limit: PAGE_LIMIT,
    startTime: 0,
    endTime: 0,
    offset: 0
  },
  cashDepositHistory: [],
  bankHistoryType: IBankHistoryType.CASH,
  bankHistoryImage: '',
  downloadStatementPopup: false,
  uploadedBankStatementData: [],
  transactionData: [],
  uploadedFileDate: {
    startTime: 0,
    endTime: 0
  },
  computedBankTransactionAction: {
    common: [],
    unmatchedLedger: [],
    unmatchedBank: [],
    bankStatementClosingBalance: 0
  },
  transactionOpeningBalance: 0,
  transactionClosingBalance: 0
}

export const BankStatementReducer = (
  state: IBankStatementState = BANK_STATEMENT_INITIAL_STATE,
  action: Action<any>
): IBankStatementState => {
  switch (action.type) {
    case bankStatementActions.setLoader: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case bankStatementActions.setDrawer: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case bankStatementActions.setFilterTime: {
      return {
        ...state,
        filterTime: action.payload
      }
    }
    case bankStatementActions.setBankStatementTemplatesAction: {
      return {
        ...state,
        templates: action.payload
      }
    }
    case bankStatementActions.setSingleBankStatementTemplateAction: {
      const templatesData = state.templates
      const template = action.payload
      const index = templatesData.findIndex((item) => item.id === template.id)

      const newTemplatesData =
        index === -1
          ? [...templatesData, template]
          : [...templatesData.slice(0, index), template, ...templatesData.slice(index + 1)]

      return {
        ...state,
        templates: newTemplatesData
      }
    }
    case bankStatementActions.setBankStatementDocumentsAction: {
      const document = action.payload.find((doc: BankStatementDocumentType) =>
        [
          BankStatementDocumentState.DB_UPLOADING,
          BankStatementDocumentState.UPLOADED,
          BankStatementDocumentState.RECONCILIATION_IN_PROGRESS
        ].includes(doc.state)
      )
      return {
        ...state,
        documents: action.payload,
        pollingDocument: document ? document : null
      }
    }
    case bankStatementActions.setSingleBankStatementDocumentAction: {
      const documentsData = state.documents
      const document = action.payload
      const index = documentsData.findIndex((item) => item.id === document.id)

      const newDocumentsData =
        index === -1
          ? [...documentsData, document]
          : [...documentsData.slice(0, index), document, ...documentsData.slice(index + 1)]
      return {
        ...state,
        documents: newDocumentsData,
        pollingDocument: document
      }
    }
    case bankStatementActions.setBankStatementReconciledTxnsAction: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          reconciledTransactions: action.payload.reconciled,
          unReconciledTransactions: action.payload.unReconciled
        }
      }
    }
    case bankStatementActions.setBankStatementFilterAction: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filters: {
            ...state.transactions.filters,
            ...action.payload
          }
        }
      }
    }
    case bankStatementActions.setBankStatementSelectedFilterAction: {
      const { type, payload } = action.payload
      let selectedFilter = state.transactions.selectedFilters?.[type as keyof BankStatementFilter]
      if (selectedFilter.length === 0) {
        selectedFilter = [payload]
      } else {
        selectedFilter = selectedFilter.includes(payload)
          ? selectedFilter.filter((str) => str !== payload)
          : [...selectedFilter, payload]
      }
      return {
        ...state,
        transactions: {
          ...state.transactions,
          selectedFilters: {
            ...state.transactions.selectedFilters,
            [type]: selectedFilter
          }
        }
      }
    }
    case bankStatementActions.resetBankStatementSelectedFilterAction: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          selectedFilters: {
            transactionType: [],
            bank: []
          }
        }
      }
    }
    case bankStatementActions.stopPollBankStatementDocumentStatus: {
      return {
        ...state,
        pollingDocument: null
      }
    }

    case bankStatementActions.setEditAssetAccount: {
      return {
        ...state,
        editAssetId: action.payload
      }
    }

    case bankStatementActions.updateDepositHistoryState: {
      return {
        ...state,
        depositHistory: { ...state.depositHistory, ...action.payload }
      }
    }
    case bankStatementActions.setCashDepositHistory: {
      return {
        ...state,
        cashDepositApiCallAndResponse: {
          isApiCall: false,
          isAllDataIsFetched: action.payload.length < PAGE_LIMIT,
          isApiError: false
        },
        cashDepositHistory:
          state.cashDepositHistory.length > 0
            ? action.payload
            : [...state.cashDepositHistory, ...[...action.payload]]
      }
    }

    case bankStatementActions.getCashDepositHistory: {
      return {
        ...state,
        cashDepositApiCallAndResponse: {
          isApiCall: true,
          isAllDataIsFetched: false,
          isApiError: false
        }
      }
    }

    case bankStatementActions.setCastDepositTransactionHistoryAction: {
      return {
        ...state,
        cashDepositApiCallAndResponse: action.payload
      }
    }

    case bankStatementActions.setBankHistoryType: {
      return {
        ...state,
        bankHistoryType: action.payload
      }
    }

    case bankStatementActions.setBankHistoryImage: {
      return {
        ...state,
        bankHistoryImage: action.payload
      }
    }

    case bankStatementActions.setShowDownloadPopUp: {
      return {
        ...state,
        downloadStatementPopup: action.payload
      }
    }

    case bankStatementActions.storeExcelTransaction: {
      return {
        ...state,
        uploadedBankStatementData: action.payload
      }
    }

    case bankStatementActions.storeFetchedTransactions: {
      return {
        ...state,
        transactionData: action.payload.transaction,
        transactionOpeningBalance: action.payload.transactionOpeningBalance,
        transactionClosingBalance: action.payload.transactionClosingBalance
      }
    }

    case bankStatementActions.fetchAccountTransaction: {
      return {
        ...state,
        uploadedFileDate: {
          startTime: action.payload.start_time,
          endTime: action.payload.end_time
        }
      }
    }

    case bankStatementActions.saveComputedBankTransactionAction: {
      return {
        ...state,
        computedBankTransactionAction: action.payload
      }
    }

    case bankStatementActions.resetBankReconciliationData: {
      return {
        ...state,
        uploadedFileDate: {
          startTime: 0,
          endTime: 0
        },
        transactionData: [],
        uploadedBankStatementData: [],
        computedBankTransactionAction: {
          common: [],
          unmatchedLedger: [],
          unmatchedBank: [],
          bankStatementClosingBalance: 0
        }
      }
    }

    default: {
      return state
    }
  }
}
