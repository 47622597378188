/* eslint-disable security/detect-non-literal-fs-filename */
import { TRANSACTION_REPORT_ACTION } from 'state/transaction/transaction.types'
import getWhatsappLink from 'utils/getWhatsappLink'

export const showReport = (url: string, action: TRANSACTION_REPORT_ACTION) => {
  if (action === TRANSACTION_REPORT_ACTION.DOWNLOAD) {
    const a = document.createElement('a')
    a.href = url
    document.body.appendChild(a)
    a.click()
    a.remove()
  } else if (action === TRANSACTION_REPORT_ACTION.SHARE) {
    window.open(getWhatsappLink('', url))
  } else if (action === TRANSACTION_REPORT_ACTION.PRINT) {
    import(/* webpackChunkName: "printJS" */ 'print-js').then((printJS) =>
      printJS.default({ printable: url, type: 'pdf', showModal: true })
    )
  }
}
