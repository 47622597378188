// import thunk from 'redux-thunk';
import { legacy_createStore as createStore, applyMiddleware, compose, Store } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'
import { createLogger } from 'redux-logger'
import createRootReducer from './rootReducer'

type StoreParams = {
  initialState?: { [key: string]: any }
  middleware?: any[]
}

const configureStore = ({ initialState }: StoreParams) => {
  const sagaMiddleware = createSagaMiddleware()

  const devtools =
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] })

  const composeEnhancers = devtools || compose
  const logger = createLogger({
    predicate: () => process.env.NODE_ENV !== 'production'
  })
  const middleWares = applyMiddleware(sagaMiddleware, logger)
  const stateObj = {
    store: createStore(createRootReducer(), initialState, composeEnhancers(middleWares)) as Store,
    runSaga: sagaMiddleware.run,
    close: () => {}
  }

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer.ts', () =>
        stateObj.store.replaceReducer(require('./rootReducer').default)
      )
      module.hot.accept('./rootEffects.ts', () =>
        stateObj.store.replaceReducer(require('./rootEffects').default)
      )
    }
  }
  stateObj.close = () => stateObj.store.dispatch(END)

  return stateObj
}

export default configureStore
