export const enum TransactionTypeEnum {
  CASH = 'Cash',
  ONLINE = 'Online',
  CREDIT_DUES = 'Credit Dues',
  CHEQUE = 'Cheque',
  NEFT = 'NEFT',
  RETURN = 'Return',
  DAMAGED = 'Damage',
  WALLET = 'Advance',
  OTHER = 'OTHER',
  QR = 'By QR'
}

export const enum AuthLocalStoragerEnum {
  staffLinkAccessToken = 'stafflink_access_token',
  staffLinkRefreshToken = 'stafflink_refresh_token',
  staffLinkExpiresIn = 'stafflink_expires_in',
  staffLinkBusinessId = 'stafflink_business_id',
  staffLinkId = 'stafflink_id'
}

export const enum LocalStorageEnum {
  staffPhoneNumber = 'staff_phone_number'
}
