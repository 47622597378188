import { Action } from 'infra/types'
import {
  DownloadExpenseSummaryType,
  DownloadPurchaseSummaryType,
  ILoaders,
  InventoryItemModalStatus
} from './inventory.types'

export const InventoryActions = {
  HANDLE_LOADER_TOGGLE: 'inventory/HANDLE_LOADER_TOGGLE',
  //set modals action
  SET_INVENTORY_MODALS: 'inventory/SET_INVENTORY_MODALS',

  BULK_UPLOAD_INVENTORY_ITEMS: 'inventory/BULK_UPLOAD_INVENTORY_ITEMS',
  BULK_UPLOAD_INVENTORY_ITEMS_SUCCESS: 'inventory/BULK_UPLOAD_INVENTORY_ITEMS_SUCCESS',

  //download actions
  DOWNLOAD_PURCHASE_ITEMS_SUMMARY: 'inventory/DOWNLOAD_PURCHASE_ITEMS_SUMMARY',
  DOWNLOAD_EXPENSE_ITEM_SUMMARY: 'inventory/DOWNLOAD_EXPENSE_ITEM_SUMMARY'
}

export const handleLoaderToggle = (payload: ILoaders): Action<ILoaders> => ({
  type: InventoryActions.HANDLE_LOADER_TOGGLE,
  payload
})

export const bulkUploadInventoryItemsAction = (payload: FormData): Action<FormData> => ({
  type: InventoryActions.BULK_UPLOAD_INVENTORY_ITEMS,
  payload
})

export const bulkUploadInventoryItemsSuccessAction = (payload: any): Action<any> => ({
  type: InventoryActions.BULK_UPLOAD_INVENTORY_ITEMS_SUCCESS,
  payload
})

export const setInventoryModalsAction = (
  payload: InventoryItemModalStatus
): Action<InventoryItemModalStatus> => ({
  type: InventoryActions.SET_INVENTORY_MODALS,
  payload
})

export const downloadPurchaseItemSummaryAction = (
  payload: DownloadPurchaseSummaryType
): Action<DownloadPurchaseSummaryType> => ({
  type: InventoryActions.DOWNLOAD_PURCHASE_ITEMS_SUMMARY,
  payload
})

export const downloadExpenseItemSummaryAction = (
  payload: DownloadExpenseSummaryType
): Action<DownloadExpenseSummaryType> => ({
  type: InventoryActions.DOWNLOAD_EXPENSE_ITEM_SUMMARY,
  payload
})
