import { Action } from 'infra/types'
import { whatsappSuccessActions } from './whatsapp-success.actions'
import { IWhatsappSuccessState } from './whatsapp-success.types'

const WHATSAPP_SUCCESS_STATE: IWhatsappSuccessState = {
  drawers: {
    showWhatsappSuccessDrawer: false
  }
}

export const WhatsappSuccessReducer = (
  state: IWhatsappSuccessState = WHATSAPP_SUCCESS_STATE,
  action: Action<any>
): IWhatsappSuccessState => {
  switch (action.type) {
    case whatsappSuccessActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    default: {
      return state
    }
  }
}
