import { Action } from 'infra/types'
import { IOnboarding } from './onboarding.types'
import { onboardingAction } from './onboarding.actions'

const defaultOnboardingReducerState: IOnboarding = {
  loaders: {
    showFileSyncDrawer: false,
    addtoSupplyListDrawer: false,
    showListNameDrawer: false
  }
}

export const OnboardingReducer = (
  state: IOnboarding = defaultOnboardingReducerState,
  action: Action<any>
) => {
  switch (action.type) {
    case onboardingAction.SHOW_SYNC_FILE_DRAWER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          showFileSyncDrawer: action.payload
        }
      }
    }
    case onboardingAction.SHOW_ADD_TO_SUPPLY_LIST_DRAWER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          addtoSupplyListDrawer: action.payload
        }
      }
    }
    case onboardingAction.SHOW_LIST_NAME_DRAWER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          showListNameDrawer: action.payload
        }
      }
    }
    case onboardingAction.SET_ACCOUNT_IDS: {
      return {
        ...state,
        accountIds: action.payload
      }
    }
    case onboardingAction.RESET_ALL_DRAWERS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          showFileSyncDrawer: false,
          addtoSupplyListDrawer: false,
          showListNameDrawer: false
        }
      }
    }
    default:
      return state
  }
}
