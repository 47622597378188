import { Action } from 'infra/types'
import { IDateFilter, ISummaryType } from 'pages/Statements/Statements.constants'
import { ISummaryV2DateFilter } from 'pages/CollectionSummary/CollectionSummary.types'
import { FILTER_OPTION } from 'services/Api/types'
import { BUSINESS_TYPE } from 'pages/Statements/components/DownloadDialog/DownloadDialog.constants'
import { SOURCE } from 'state/new-summary/supply/supply.constants'
import { VIEW, ITxnAcceptOrRemove, IUpdateReplacementRecordAction } from './statements.types'

export const StatementsActions = {
  SET_LOADER: 'statements/setLoader',
  SET_DRAWER: 'statements/setDrawer',
  CREATE_GROUP: 'statements/createGroup',
  GET_OWNER_SUMMARY: 'statements/getOwnerSummary',
  GET_OWNER_SUMMARY_SUCCESS: 'statements/getOwnerSummarySuccess',
  UPDATE_GROUP: 'statements/updateGroup',
  REMOVE_GROUP: 'statements/removeGroup',
  SET_CURRENT_VIEW: 'statements/setCurrentView',
  SET_CURRENT_SUMMARY_TYPE: 'statements/SET_CURRENT_SUMMARY_TYPE',
  SET_CURRENT_COLLECTION_LIST_ID: 'statements/SET_CURRENT_COLLECTION_LIST_ID',
  ADD_TO_LEDGER: 'statements/addToLedger',
  BULK_ADD_TO_LEDGER: 'statements/bulkAddToLedger',
  UPDATE_OWNER_SUMMARY: 'statements/UPDATE_OWNER_SUMMARY',
  GET_COLLECTION_LIST_SUMMARY: 'statements/GET_COLLECTION_LIST_SUMMARY',
  GET_COLLECTION_LIST_SUMMARY_SUCCESS: 'statements/GET_COLLECTION_LIST_SUMMARY_SUCCESS',
  GET_SINGLE_COLLECTION_LIST_SUMMARY: 'statements/GET_SINGLE_COLLECTION_LIST_SUMMARY',
  GET_SINGLE_COLLECTION_LIST_SUMMARY_SUCCESS:
    'statements/GET_SINGLE_COLLECTION_LIST_SUMMARY_SUCCESS',
  GET_COLLECTION_LIST_INVOICE_SUMMARY: 'statements/GET_COLLECTION_LIST_INVOICE_SUMMARY',
  GET_COLLECTION_LIST_INVOICE_SUMMARY_SUCCESS:
    'statements/GET_COLLECTION_LIST_SUMMARY_INVOICE_SUCCESS',
  GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY:
    'statements/GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY',
  GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY_SUCCESS:
    'statements/GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY_SUCCESS',
  FETCH_UNAPPROVED_TRANSACTION_SUMMARY: 'statements/FETCH_UNAPPROVED_TRANSACTION_SUMMARY',
  FETCH_UNAPPROVED_TRANSACTION_SUMMARY_SUCCESS:
    'statements/FETCH_UNAPPROVED_TRANSACTION_SUMMARY_SUCCESS',
  HANDLE_TXN_ACCEPT_OR_REMOVE: 'collectionList/HANDLE_TXN_ACCEPT_OR_REMOVE',
  FETCH_ACCOUNT_ALLOCATION_DETAILS: 'statements/FETCH_ACCOUNT_ALLOCATION_DETAILS',
  FETCH_ACCOUNT_ALLOCATION_DETAILS_SUCCESS: 'statements/FETCH_ACCOUNT_ALLOCATION_DETAILS_SUCCESS',
  FETCH_PENDING_REPLACEMENT_LIST: 'statements/FETCH_PENDING_REPLACEMENT_LIST',
  FETCH_PENDING_REPLACEMENT_LIST_SUCCESS: 'statements/FETCH_PENDING_REPLACEMENT_LIST_SUCCESS',
  UPDATE_PENDING_REPLACEMENTS: 'statements/UPDATE_PENDING_REPLACEMENTS',
  UPDATE_PENDING_REPLACEMENTS_SUCCESS: 'statements/UPDATE_PENDING_REPLACEMENTS_SUCCESS',
  APPEND_COLLECTION_LIST_SUMMARY_SUCCESS: 'statements/APPEND_COLLECTION_LIST_SUMMARY_SUCCESS',
  GET_FINAL_BALANCE_DOWNLOAD_DATA: 'statements/getFinalBalanceDownloadData',
  GET_INVOICE_LEVEL_DOWNLOAD_DATA: 'statements/getInvoiceLevelDownloadData',
  GET_V2_INVOICE_LEVEL_DOWNLOAD_DATA: 'statements/getV2InvoiceLevelDownloadData',
  SET_DOWNLOAD_STATUS: 'statements/setDownloadStatus',
  SET_V2_SUMMARY_DOWNLOAD_DRAWER_STATUS: 'statements/setV2SummaryDownloadDrawerStatus',
  SET_REPLACEMENT_SUMMARY_DOWNLOAD_DRAWER_STATUS:
    'collectionList/setReplacementSummaryDownloadDrawer',
  CREATE_GROUP_BY_TAGS: 'statements/createGroupByTags',
  UPDATE_GROUP_BY_TAGS: 'statements/updateGroupByTags',
  REMOVE_GROUP_BY_TAGS: 'statements/removeGroupByTags',
  REPLACEMENT_LEVEL_SUMMARY_DOWNLOAD: 'collectionList/replacementLevelSummaryDownload'
}

export const handleTxnAcceptOrRemove = (
  payload: ITxnAcceptOrRemove
): Action<ITxnAcceptOrRemove> => ({
  type: StatementsActions.HANDLE_TXN_ACCEPT_OR_REMOVE,
  payload
})

export const createGroupAction = (payload: {
  name: string
  associated_account_ids: string[]
  type: number
  filter: IDateFilter | ISummaryV2DateFilter
  source?: SOURCE
}): Action<{
  name: string
  associated_account_ids: string[]
  type: number
  filter: IDateFilter | ISummaryV2DateFilter
  source?: SOURCE
}> => ({
  type: StatementsActions.CREATE_GROUP,
  payload
})

export const setLoaderAction = (payload: {
  id: string
  value: boolean
}): Action<{ id: string; value: boolean }> => ({
  type: StatementsActions.SET_LOADER,
  payload
})

export const setDrawerAction = (payload: {
  id: string
  value: boolean
}): Action<{ id: string; value: boolean }> => ({
  type: StatementsActions.SET_DRAWER,
  payload
})

export const getOwnerSummaryAction = (payload: {
  startTime?: number
  endTime?: number
  collectionListId?: string
}): Action<{
  startTime?: number
  endTime?: number
  collectionListId?: string
}> => ({
  type: StatementsActions.GET_OWNER_SUMMARY,
  payload
})

export const getOwnerSummarySuccessAction = (payload: any): Action<any> => ({
  type: StatementsActions.GET_OWNER_SUMMARY_SUCCESS,
  payload
})

export const updateGroupAction = (payload: {
  collectionListId: string
  name?: string
  addedAccountIds: Array<string>
  removedAccountIds: Array<string>
  filter: IDateFilter | ISummaryV2DateFilter
  source?: SOURCE
}): Action<{
  collectionListId: string
  name?: string
  addedAccountIds: Array<string>
  removedAccountIds: Array<string>
  filter: IDateFilter | ISummaryV2DateFilter
  source?: SOURCE
}> => ({
  type: StatementsActions.UPDATE_GROUP,
  payload
})

export const removeGroupAction = (payload: any): Action<any> => ({
  type: StatementsActions.REMOVE_GROUP,
  payload
})

export const setCurrentViewAction = (payload: VIEW): Action<VIEW> => ({
  type: StatementsActions.SET_CURRENT_VIEW,
  payload
})

export const setCurrentSummaryTypeAction = (payload: ISummaryType): Action<ISummaryType> => ({
  type: StatementsActions.SET_CURRENT_SUMMARY_TYPE,
  payload
})

export const setCurrentCollectionListIdAction = (
  payload: string | null
): Action<string | null> => ({
  type: StatementsActions.SET_CURRENT_COLLECTION_LIST_ID,
  payload
})

export const addToLedgerAction = (payload: {
  filter: IDateFilter
  transaction: any
}): Action<{
  filter: IDateFilter
  transaction: any
}> => ({
  type: StatementsActions.ADD_TO_LEDGER,
  payload
})

export const approveAllTransactionsAction = (payload: {
  filter: IDateFilter
  transactions: any[]
}): Action<{
  filter: IDateFilter
  transactions: any[]
}> => ({
  type: StatementsActions.BULK_ADD_TO_LEDGER,
  payload
})

export const updateOwnerSummaryAction = (payload: {
  startTime?: number
  endTime?: number
}): Action<{
  startTime?: number
  endTime?: number
}> => ({
  type: StatementsActions.UPDATE_OWNER_SUMMARY,
  payload
})

export const getCollectionListSummary = (payload: {
  page: number
  perPage: number
  startTime?: number
  endTime?: number
  collectionListId?: string
  append?: boolean
  scrollBack?: boolean
}): Action<{
  startTime?: number
  endTime?: number
  collectionListId?: string
  page: number
  perPage: number
  append?: boolean
  scrollBack?: boolean
}> => ({
  type: StatementsActions.GET_COLLECTION_LIST_SUMMARY,
  payload
})

export const getCollectionListSummarySuccessAction = (payload: any): Action<any> => ({
  type: StatementsActions.GET_COLLECTION_LIST_SUMMARY_SUCCESS,
  payload
})

export const getCollectionListAccountSummary = (payload: {
  startTime?: number
  endTime?: number
  collectionListId?: string
  page: number
  perPage: number
  append?: boolean
}): Action<{
  startTime?: number
  endTime?: number
  collectionListId?: string
  page: number
  perPage: number
  append?: boolean
}> => ({
  type: StatementsActions.GET_SINGLE_COLLECTION_LIST_SUMMARY,
  payload
})

export const getCollectionListAccountSummarySuccess = (payload: any): Action<any> => ({
  type: StatementsActions.GET_SINGLE_COLLECTION_LIST_SUMMARY_SUCCESS,
  payload
})

export const getCollectionListInvoiceSummaryAction = (payload: {
  startTime?: number
  endTime?: number
  append?: boolean
  page?: number
  perPage?: number
  scrollBack?: boolean
}): Action<{
  startTime?: number
  endTime?: number
  append?: boolean
  page?: number
  perPage?: number
  scrollBack?: boolean
}> => ({
  type: StatementsActions.GET_COLLECTION_LIST_INVOICE_SUMMARY,
  payload
})

export const getCollectionListInvoiceSummarySuccessAction = (payload: any): Action<any> => ({
  type: StatementsActions.GET_COLLECTION_LIST_INVOICE_SUMMARY_SUCCESS,
  payload
})

export const getCollectionListAccountInvoiceSummaryAction = (payload: {
  startTime?: number
  endTime?: number
  append?: boolean
  page?: number
  perPage?: number
  collectionListId: string
}): Action<{
  startTime?: number
  endTime?: number
  append?: boolean
  page?: number
  perPage?: number
  collectionListId: string
}> => ({
  type: StatementsActions.GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY,
  payload
})

export const getCollectionListAccountInvoiceSummarySuccessAction = (payload: any): Action<any> => ({
  type: StatementsActions.GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY_SUCCESS,
  payload
})

export const getUnapprovedTransactionsAction = (payload: {
  startTime?: number
  endTime?: number
  collectionListId?: string
}): Action<{
  startTime?: number
  endTime?: number
  collectionListId?: string
}> => ({
  type: StatementsActions.FETCH_UNAPPROVED_TRANSACTION_SUMMARY,
  payload
})

export const getUnapprovedTransactionsSuccess = (payload: any): Action<any> => ({
  type: StatementsActions.FETCH_UNAPPROVED_TRANSACTION_SUMMARY_SUCCESS,
  payload
})

export const getAccountAllocationDetails = (
  collectionListId?: string
): Action<string | undefined> => ({
  type: StatementsActions.FETCH_ACCOUNT_ALLOCATION_DETAILS,
  payload: collectionListId
})

export const getAccountAllocationDetailsSuccess = (payload: any): Action<any> => ({
  type: StatementsActions.FETCH_ACCOUNT_ALLOCATION_DETAILS_SUCCESS,
  payload
})

export const getFinalBalanceDownloadDataAction = (payload: {
  activeFilter: IDateFilter
  collectionListId?: string
  fileName: string
  format: string
  currentView: VIEW
  title: string
}): Action<{
  activeFilter: IDateFilter
  collectionListId?: string
  fileName: string
  format: string
  currentView: VIEW
  title: string
}> => ({
  type: StatementsActions.GET_FINAL_BALANCE_DOWNLOAD_DATA,
  payload
})

export const getInvoiceLevelDownloadDataAction = (payload: {
  activeFilter: IDateFilter
  collectionListId?: string
  fileName: string
  format: string
  currentView: VIEW
  title: string
}): Action<{
  activeFilter: IDateFilter
  collectionListId?: string
  fileName: string
  format: string
  currentView: VIEW
  title: string
}> => ({
  type: StatementsActions.GET_INVOICE_LEVEL_DOWNLOAD_DATA,
  payload
})

export const getV2InvoiceLevelDownloadDataAction = (payload: {
  collectionListId?: string
  fileName: string
  format: string
  title: string
  businessType: BUSINESS_TYPE
  activeFilter: IDateFilter | ISummaryV2DateFilter
}): Action<{
  collectionListId?: string
  fileName: string
  format: string
  title: string
  businessType: BUSINESS_TYPE
}> => ({
  type: StatementsActions.GET_V2_INVOICE_LEVEL_DOWNLOAD_DATA,
  payload
})

export const getReplacementSummaryDownloadDataAction = (payload: {
  fileName: string
  format: string
  title: string
}): Action<{
  fileName: string
  format: string
  title: string
}> => ({
  type: StatementsActions.REPLACEMENT_LEVEL_SUMMARY_DOWNLOAD,
  payload
})

export const setDownloadStatus = (payload: any): Action<any> => ({
  type: StatementsActions.SET_DOWNLOAD_STATUS,
  payload
})

export const setV2SummaryDownloadDrawerStatus = (payload: any): Action<any> => ({
  type: StatementsActions.SET_V2_SUMMARY_DOWNLOAD_DRAWER_STATUS,
  payload
})

export const setReplacementSummaryDownloadStatus = (payload: any): Action<any> => ({
  type: StatementsActions.SET_REPLACEMENT_SUMMARY_DOWNLOAD_DRAWER_STATUS,
  payload
})

export const getPendingReplacementListAction = (payload: {
  startTime?: number
  endTime?: number
  collectionListId: string
}): Action<{
  startTime?: number
  endTime?: number
  collectionListId: string
}> => ({
  type: StatementsActions.FETCH_PENDING_REPLACEMENT_LIST,
  payload
})

export const getPendingReplacementListSuccess = (payload: any): Action<any> => ({
  type: StatementsActions.FETCH_PENDING_REPLACEMENT_LIST_SUCCESS,
  payload
})

export const updatePendingReplacementAction = (
  data: IUpdateReplacementRecordAction
): Action<IUpdateReplacementRecordAction> => ({
  type: StatementsActions.UPDATE_PENDING_REPLACEMENTS,
  payload: data
})

export const updatePendingReplacementSuccess = (payload: any): Action<any> => ({
  type: StatementsActions.UPDATE_PENDING_REPLACEMENTS_SUCCESS,
  payload
})

export const createGroupByTagsAction = (payload: {
  name: string
  tags: string[]
  filterOption?: FILTER_OPTION
  filter: IDateFilter
  source: 'collectionSummary' | 'statements' | 'pendingDueSummary' | SOURCE
}): Action<{
  name: string
  tags: string[]
  filterOption?: FILTER_OPTION
  filter: IDateFilter
  source: 'collectionSummary' | 'statements' | 'pendingDueSummary' | SOURCE
}> => ({
  type: StatementsActions.CREATE_GROUP_BY_TAGS,
  payload
})

export const updateGroupByTagsAction = (payload: {
  groupId: string
  groupName?: string
  tagIds?: Array<string>
  filterOption?: FILTER_OPTION
  filter: IDateFilter
  source: 'collectionSummary' | 'statements' | 'pendingDueSummary' | SOURCE
}): Action<{
  groupId: string
  groupName?: string
  tagIds?: Array<string>
  filterOption?: FILTER_OPTION
  filter: IDateFilter
  source: 'collectionSummary' | 'statements' | 'pendingDueSummary' | SOURCE
}> => ({
  type: StatementsActions.UPDATE_GROUP_BY_TAGS,
  payload
})

export const removeGroupByTagsAction = (payload: any): Action<any> => ({
  type: StatementsActions.REMOVE_GROUP_BY_TAGS,
  payload
})
