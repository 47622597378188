import { call, put, select } from 'redux-saga/effects'
import { Action } from 'infra/types'
import { fetchList as fetchListWithUsageType } from 'services/Api/StafflinkApi'
import { CL_VERSIONS } from 'utils/common/common.types'
import { IAppState } from 'infra/AppState'
import { IExpState } from 'state/experiment/experiment.types'
import { IApiResponse } from '../../../constants'
import { setSupplyListStore, setSupplyLists, setSupplyLoaders } from './supply-list.actions'
import { fetchListWithId, fetchOrderSettingAccountIds } from './list.effect'
import { getVersion } from './list.helpers'

export function* fetchSupplyListsEffect() {
  const response: IApiResponse = yield call(fetchListWithUsageType, 1)
  const formattedSupplyList = response.data.collections_lists
    .map((list: any) => ({
      listName: list.name,
      listId: list.id,
      hasPendingActions: list?.summary?.pending_actions?.length > 0,
      dueConfig: list.due_config.config
    }))
    .sort(
      (
        a: {
          listName: string
          listId: string
          hasPendingActions: boolean
        },
        b: {
          listName: string
          listId: string
          hasPendingActions: boolean
        }
      ) => (b.hasPendingActions ? 1 : 0) - (a.hasPendingActions ? 1 : 0)
    )

  yield put(setSupplyLists(formattedSupplyList))
}

export function* fetchSupplyListWithId(action: Action<{ listId: string; version?: CL_VERSIONS }>) {
  yield put(setSupplyLoaders({ loaderName: 'isFetchingListData', status: true }))
  const { listId, version: versionFromPayload } = action.payload
  const { selectedAssignmentFilter } = yield select((app: IAppState) => app.SupplyList)
  const {
    ab: { okDebitOrderManagement }
  }: IExpState = yield select((app: IAppState) => app.Experiment)
  const version = getVersion(versionFromPayload, selectedAssignmentFilter)
  const {
    noActionsTableData,
    approveTableData,
    pendingTableData,
    onHoldBillsData,
    assignmentFilter,
    summaryData,
    list,
    existingAccountIds,
    existingBills,
    rescheduledDeliveries,
    addedCredit,
    listPendingDue
  }: Record<string, any> = yield fetchListWithId({
    listId,
    version: version
  })

  let orderSettingAccountIds: string[] = []
  if (okDebitOrderManagement) {
    orderSettingAccountIds = yield fetchOrderSettingAccountIds({ listId })
  }

  yield put(
    setSupplyListStore({
      pendingTable: pendingTableData,
      noActionsTable: noActionsTableData,
      approveTable: approveTableData,
      rescheduledDeliveries: rescheduledDeliveries,
      onHoldBillsData,
      allAssignmentFilter: assignmentFilter,
      summaryData,
      selectedList: list,
      selectedAssignmentFilter: version,
      existingAccountIds,
      existingBills,
      addedCredit,
      listPendingDue,
      orderSettingAccountIds
    })
  )

  yield put(setSupplyLoaders({ loaderName: 'isFetchingListData', status: false }))
}
