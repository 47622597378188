import { getNormalizedList } from 'utils/dataNormalizer'
import { Action } from '../../infra/types'
import { ITransactionsState } from './transaction.types'
import { TransactionState } from './transaction.state'
import { TransactionsActions } from './transaction.actions'

export const TransactionsReducers = (
  state: ITransactionsState = TransactionState,
  action: Action<any>
): ITransactionsState => {
  switch (action.type) {
    case TransactionsActions.SELECT_ENTITY: {
      return {
        ...state,
        currentEntity: {
          type: action.payload.type,
          id: action.payload.id
        }
      }
    }
    case TransactionsActions.RESET_SELECTED_ENTITY: {
      return {
        ...state,
        currentEntity: undefined
      }
    }
    case TransactionsActions.RESET_TRANSACTION_BILLS: {
      return {
        ...state,
        transactionBills: {
          currentAction: ''
        }
      }
    }
    case TransactionsActions.DELETE_TRANSACTION_IMAGE: {
      return {
        ...state,
        transactionBills: {
          ...state.transactionBills,
          currentAction: 'removal'
        }
      }
    }
    case TransactionsActions.DELETE_TRANSACTION_IMAGE_SUCCESS: {
      return {
        ...state,
        transactionBills: {
          currentAction: '',
          lastDeletedBill: {
            transactionId: action.payload.transactionId,
            imageId: action.payload.imageId
          }
        }
      }
    }
    case TransactionsActions.ADD_TRANSACTION_IMAGE: {
      return {
        ...state,
        transactionBills: {
          ...state.transactionBills,
          currentAction: 'addition'
        }
      }
    }
    case TransactionsActions.ADD_TRANSACTION_IMAGE_SUCCESS: {
      return {
        ...state,
        transactionBills: {
          currentAction: '',
          lastAddedBill: {
            transactionId: action.payload.transactionId,
            imageId: action.payload.imageId
          }
        }
      }
    }
    case TransactionsActions.FETCH_REPORT_TRANSACTIONS: {
      return {
        ...state,
        currentTransactionReport: {
          ...state.currentTransactionReport,
          paymentCount: 0,
          creditCount: 0,
          payment: 0,
          credit: 0,
          transactions: [],
          loading: true,
          reportUrl: ''
        }
      }
    }
    case TransactionsActions.FETCH_REPORT_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        currentTransactionReport: {
          ...state.currentTransactionReport,
          ...action.payload.data,
          loading: false
        }
      }
    }
    case TransactionsActions.FETCH_REPORT_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        currentTransactionReport: {
          ...state.currentTransactionReport,
          loading: false
        }
      }
    }
    case TransactionsActions.ADD_TRANSACTION_COMPLETED: {
      return {
        ...state,
        currentTransactions: {
          ...state.currentTransactions,
          loading: true
        }
      }
    }
    case TransactionsActions.ADD_TRANSACTION_SUCCESS: {
      return {
        ...state,
        currentTransactions: {
          ...state.currentTransactions,
          loading: false
        }
      }
    }
    case TransactionsActions.ADD_TRANSACTION_FAILURE: {
      return {
        ...state,
        currentTransactions: {
          ...state.currentTransactions,
          loading: false
        }
      }
    }
    case TransactionsActions.GENERATE_TRANSACTIONS_REPORT: {
      return {
        ...state,
        currentTransactionReport: {
          ...state.currentTransactionReport,
          isReportUrlLoading: true
        }
      }
    }
    case TransactionsActions.GENERATE_TRANSACTIONS_REPORT_SUCCESS: {
      return {
        ...state,
        currentTransactionReport: {
          ...state.currentTransactionReport,
          isReportUrlLoading: false,
          reportUrl: action.payload
        }
      }
    }
    case TransactionsActions.GENERATE_TRANSACTIONS_REPORT_FAILURE: {
      return {
        ...state,
        currentTransactionReport: {
          ...state.currentTransactionReport,
          isReportUrlLoading: false,
          reportUrl: ''
        }
      }
    }
    case TransactionsActions.GET_TRANSACTION_SUCCESS: {
      return {
        ...state,
        currentTxnData: action.payload
      }
    }
    case TransactionsActions.SET_TRANSACTIONS_LOADING: {
      return {
        ...state,
        currentTransactions: {
          ...state.currentTransactions,
          loading: action.payload
        }
      }
    }
    case TransactionsActions.TOGGLE_EDIT_TRANSACTION_AMOUNT: {
      return {
        ...state,
        isEditTransactionAmountOpen: action.payload
      }
    }
    case TransactionsActions.SET_TRANSACTION_ERROR_MSG: {
      return {
        ...state,
        createTransactionError: action.payload
      }
    }
    case TransactionsActions.FETCH_ACCOUNTS_ROUTES_AND_BEATS: {
      return {
        ...state,
        routes: [],
        beats: [],
        fetchingRoutesAndBeats: true
      }
    }
    case TransactionsActions.UPDATE_ACCOUNTS_ROUTES_AND_BEATS: {
      return {
        ...state,
        fetchingRoutesAndBeats: true
      }
    }
    case TransactionsActions.FETCH_ACCOUNTS_ROUTES_AND_BEATS_SUCCESS: {
      return {
        ...state,
        routes: action?.payload?.routes || [],
        beats: action?.payload?.beats || [],
        fetchingRoutesAndBeats: false
      }
    }
    case TransactionsActions.SET_ROUTES_AND_BEATS_LOADER: {
      return {
        ...state,
        fetchingRoutesAndBeats: action?.payload || false
      }
    }
    case TransactionsActions.FETCH_LATEST_TRANSACTIONS: {
      return {
        ...state,
        currentTransactions: {
          allIds: Number(action.payload?.endTime) > 1 ? state.currentTransactions.allIds : [],
          byIds: Number(action.payload?.endTime) > 1 ? state.currentTransactions.byIds : {},
          loading: true
        }
      }
    }
    case TransactionsActions.FETCH_LATEST_TRANSACTIONS_SUCCESS: {
      const normData = getNormalizedList(action.payload.transaction.data)
      return {
        ...state,
        currentTransactions: {
          ...state.currentTransactions,
          loading: false,
          allIds: state.currentTransactions.allIds.concat(
            normData.allIds.filter((val) => !state.currentTransactions.allIds.includes(val))
          ),
          byIds: {
            ...state.currentTransactions.byIds,
            ...normData.byIds
          }
        }
      }
    }
    case TransactionsActions.FETCH_LATEST_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        currentTransactions: {
          byIds: {},
          allIds: [],
          loading: false
        }
      }
    }

    case TransactionsActions.SAVE_DOUBLE_ENTRY_TRANSACTION_DETAILS: {
      return {
        ...state,
        doubleEntry: {
          reference: action.payload.reference,
          account_name: action.payload.account_name,
          deleted: action.payload.deleted
        }
      }
    }
  }
  return state
}
