import dayjs from 'dayjs'
import { utils, writeFileXLSX } from 'xlsx'
import {
  BillByIdResponse,
  BillFromResponse,
  BillItemType,
  FETCH_BILL_RESPONSE,
  FETCH_INVENTORY_ITEMS_RESPONSE,
  OFFER_RESPONSE
} from 'services/Api/BillingApiFolder.ts/type'
import {
  BILL_DEFAULT_NUMBER,
  BILL_NUMBER_PREFIX,
  BillDataType,
  SelectedChargeType,
  SelectedDiscountType
} from 'pages/NewDashboard/components/Billing/hooks/constant'
import { convertEpochToDay } from 'utils/dateTransformer'
import { StatesCodeMapper } from 'shared/constants'
import {
  BillCharges,
  BillDiscounts,
  CustomerDetails,
  IBillingState,
  IRequestPayload,
  IUploadAction,
  InventoryItemType,
  ItemType,
  ItemsDataType,
  NewItemDetailEntity,
  TAX_TYPE,
  TaxComponentType
} from './billing.types'

export const formatInventoryItemsDataV2Extended = (
  inventoryitems: FETCH_INVENTORY_ITEMS_RESPONSE['inv_items']
): InventoryItemType => {
  if (!inventoryitems?.length) {
    return []
  }
  return inventoryitems?.map(({ item, offers }) => {
    const { item_id, catalog_info, created_at, quantity, tags, catalog_item_id } = item
    return {
      itemId: item_id,
      description: catalog_info.name,
      unit: catalog_info.unit,
      secondaryUnits: catalog_info.secondary_units,
      offer: offers,
      addedOn: created_at,
      mrp: Number(catalog_info.mrp || 0) / 100,
      rate: Number(catalog_info.rate || 0) / 100,
      gst_amount: convertPaisaToRupee(Number(catalog_info?.gst?.amount || 0)),
      gst_percent: convertPaisaToRupee(Number(catalog_info?.gst?.basis_pts || 0)),
      cess_amount: convertPaisaToRupee(Number(catalog_info?.cess?.amount || 0)),
      cess_percent: convertPaisaToRupee(Number(catalog_info?.cess?.basis_pts || 0)),
      productCode: catalog_info.product_code,
      hsnCode: catalog_info.hsn_code,
      image: catalog_info.image || '',
      sku: catalog_info.sku,
      fullDescription: catalog_info.full_description,
      showTaxIncluded: !!catalog_info.meta_info?.show_tax_included,
      quantity,
      category: tags,
      catalogueItemId: catalog_item_id
    }
  })
}

export const formatBillsData = (bills: FETCH_BILL_RESPONSE['bills']): IBillingState['bills'] => {
  return bills.map((bill: BillFromResponse) => ({
    creditNoteIds: bill.credit_note_ids,
    billId: bill.bill_id,
    billNumber: bill.bill_number,
    customerId: bill.customer_id,
    customerName: bill.customer_name,
    billDate: bill.bill_date,
    amount: bill.amount / 100,
    dueAmount: bill.due_amount / 100,
    status: bill.status || 0
  }))
}

export const matchStateOfSupplyWithBusinessStateCode = (
  businessGst: string,
  customerStateSupplyCode: string
) => {
  return businessGst && businessGst.slice(0, 2) !== customerStateSupplyCode
}

export const matchCustomerGstStateCodeWithBusinessStateCode = (
  businessGst: string,
  customerGST: string
) => {
  return businessGst && businessGst.slice(0, 2) !== customerGST.slice(0, 2)
}

export const constructRequestPayload = (
  data: IUploadAction,
  allItems: InventoryItemType,
  businessGst?: string,
  isIGST?: boolean
): IRequestPayload => {
  const {
    customerId,
    customerDetails,
    invoiceDetails,
    itemDetails,
    payableAmount,
    billId,
    billVersion,
    requestId,
    discounts,
    charges
  } = data

  const billNumber = invoiceDetails.invoicePrefix + invoiceDetails.invoiceNumber
  return {
    ...(requestId && { request_id: requestId }),
    ...(billId && { bill_id: billId }),
    ...(billVersion && { bill_version: billVersion }),
    src: 7,
    customer_id: customerId,
    customer: {
      name: customerDetails.customerName,
      address: customerDetails.customerAddress,
      ...(customerDetails.customerGst && { gst_number: customerDetails.customerGst }),
      mobile: customerDetails.customerMobile
    },
    add_to_ledger: true,
    created_at: dayjs().valueOf(),
    bill_info: {
      discounts: discounts
        ?.filter((discount) => discount.amount > 0)
        .map((discount) => ({
          id: discount.id,
          name: discount.name || OLD_BILL_DISCOUNT_NAME,
          amount: convertRupeeToPaisa(discount.amount),
          pre_tax: discount.isPreTax,
          is_percent: discount.isPercent,
          basis_pts: billingRoundFunction(discount.basis * 100)
        })),
      add_charges: charges
        ?.filter((charge) => charge.amount > 0)
        .map((charge) => ({
          id: charge.id,
          name: charge.name,
          amount: convertRupeeToPaisa(charge.amount),
          is_percent: charge.isPercent,
          basis_pts: billingRoundFunction(charge.basis * 100)
        })),
      bill_number: billNumber,
      bill_date: invoiceDetails.invoiceDate,
      total_amount: convertRupeeToPaisa(payableAmount),
      items: itemDetails.map((item) => {
        const { appliedOfferId = '', applicableOfferDetails = [] } = item

        // TODO: if applicable offer contains expired offers, than remove them
        const { unitOffers } = handleOfferInPayload({
          applicableOfferDetails,
          appliedOfferId,
          customerId,
          item: {
            discountInRupee: item.discount || 0,
            rateInRupee: item.rate || 0,
            quantity: item.quantity
          }
        })

        const allItemDetails = allItems.find(
          (storeItem) => storeItem.catalogueItemId === item.itemId
        )

        const totalRateWithoutDiscount = convertRupeeToPaisa(item.rate || 0) * item.quantity
        const lineItemDiscount = convertRupeeToPaisa(item.discount || 0) * item.quantity

        const pretaxTotal = totalRateWithoutDiscount - lineItemDiscount
        const cgstValue = billingRoundFunction(pretaxTotal * ((item.cgstTaxPercent || 0) / 100))
        const sgstValue = billingRoundFunction(pretaxTotal * ((item.sgstTaxPercent || 0) / 100))
        const igstValue = isIGST
          ? billingRoundFunction(pretaxTotal * ((item.igstPercent || 0) / 100))
          : 0
        const cessValue = billingRoundFunction(pretaxTotal * ((item.cessPercent || 0) / 100))
        const totalTax = cessValue + (isIGST ? igstValue : cgstValue + sgstValue)
        const lineItemAmount = pretaxTotal + totalTax
        return {
          sku: allItemDetails?.sku || item?.skuId || '',
          name: item.item,
          unit: allItemDetails?.unit || '',
          rate: convertRupeeToPaisa(item.rate || 0),
          mrp: convertRupeeToPaisa(item.mrp || 0),
          quantity: item.quantity,
          tax: totalTax,
          discount: lineItemDiscount,
          amount: lineItemAmount,
          ...(item.sgstTaxPercent || item.cgstTaxPercent || item.cessPercent || item.igstPercent
            ? {
                tax_components: [
                  ...(isIGST
                    ? [
                        ...(item.igstPercent
                          ? [
                              {
                                kind: TAX_TYPE.IGST,
                                basis_pts: billingRoundFunction(item.igstPercent * 100),
                                amount: igstValue
                              }
                            ]
                          : [{ kind: TAX_TYPE.IGST, basis_pts: 0, amount: 0 }])
                      ]
                    : [
                        ...[
                          ...(item.sgstTaxPercent && !isIGST
                            ? [
                                {
                                  basis_pts: billingRoundFunction(item.sgstTaxPercent * 100),
                                  kind: TAX_TYPE.SGST,
                                  amount: sgstValue
                                }
                              ]
                            : [{ kind: TAX_TYPE.SGST, basis_pts: 0, amount: 0 }]),
                          ...(item.cgstTaxPercent && !isIGST
                            ? [
                                {
                                  basis_pts: billingRoundFunction(item.cgstTaxPercent * 100),
                                  kind: TAX_TYPE.CGST,
                                  amount: cgstValue
                                }
                              ]
                            : [{ kind: TAX_TYPE.CGST, basis_pts: 0, amount: 0 }])
                        ]
                      ]),
                  ...(item.cessPercent
                    ? [
                        {
                          basis_pts: item.cessPercent * 100,
                          kind: TAX_TYPE.CESS,
                          amount: cessValue
                        }
                      ]
                    : [{ kind: TAX_TYPE.CESS, basis_pts: 0, amount: 0 }])
                ]
              }
            : { tax_components: [{ kind: TAX_TYPE.SGST, basis_pts: 0, amount: 0 }] }),
          ...(item.hsnCode && { hsn_code: item.hsnCode }),
          catalog_item_id: item.itemId,
          ...(unitOffers.length && {
            unit_offers: unitOffers
          })
        }
      }),
      ...(customerDetails.customerGst &&
        matchCustomerGstStateCodeWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerGst
        ) && {
          supply_state: {
            code: customerDetails.customerGst.slice(0, 2),
            name: StatesCodeMapper[customerDetails.customerGst.slice(0, 2)]
          }
        }),
      ...(customerDetails.customerStateSupplyCode &&
        matchStateOfSupplyWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerStateSupplyCode
        ) && {
          supply_state: {
            code: customerDetails.customerStateSupplyCode,
            name: StatesCodeMapper[customerDetails.customerStateSupplyCode]
          }
        })
    },
    ...(businessGst && {
      labels: {
        business_gst: businessGst
      }
    })
  }
}

export const handleOfferInPayload = ({
  appliedOfferId,
  applicableOfferDetails,
  customerId,
  item: { discountInRupee, rateInRupee, quantity }
}: {
  appliedOfferId: string
  applicableOfferDetails: OFFER_RESPONSE[]
  customerId: string
  item: {
    discountInRupee: number
    rateInRupee: number
    quantity: number
  }
}) => {
  let unitOffers = []
  const allAppliedIds = appliedOfferId.split(',')
  unitOffers = applicableOfferDetails.filter((of) => allAppliedIds.includes(of.id)) || []

  if (unitOffers.length === 0) {
    return { unitOffers: [] }
  }

  const { summedDiscount } = sumupAllOffers({
    allApplicableOffers: unitOffers,
    itemQuantity: quantity,
    itemRate: rateInRupee
  })

  if (summedDiscount !== discountInRupee) {
    return { unitOffers: [] }
  }

  // if (appliedOfferId) {
  //   appliedOfferOject = (applicableOfferDetails || []).find((off) => off.id === appliedOfferId)
  //   if (appliedOfferOject) {
  //     let matchedSuccess = isDiscAmountMatchingWithAppliedOffer(
  //       discountInRupee,
  //       appliedOfferOject,
  //       rateInRupee || 0,
  //       customerId
  //     )

  //     if (!matchedSuccess) {
  //       matchedSuccess = isDiscAmountMatchingWithAnyMinQuantityOffer(
  //         discountInRupee,
  //         appliedOfferOject,
  //         rateInRupee || 0,
  //         quantity,
  //         customerId
  //       )
  //     }

  //     shouldSendUnitOffer = !!(appliedOfferOject && matchedSuccess)

  //     if (!shouldSendUnitOffer) {
  //       const anotherOfferWithSameDiscountAmount = findOfferMatchingDiscountAmount(
  //         discountInRupee,
  //         applicableOfferDetails,
  //         rateInRupee || 0,
  //         customerId
  //       )

  //       if (anotherOfferWithSameDiscountAmount) {
  //         shouldSendUnitOffer = true
  //         appliedOfferOject = anotherOfferWithSameDiscountAmount
  //       }
  //     }
  //   }
  // }

  return { unitOffers }
}

// const isDiscAmountMatchingWithAnyMinQuantityOffer = (
//   discountInputInRupee: number,
//   offerObject: OFFER_RESPONSE,
//   itemRateInRupee: number,
//   quantity: number,
//   billedCustomerId?: string
// ) => {
//   const discountObject = offerObject?.info?.discount_on_rate
//   if (offerObject && 'customer_ids' in offerObject && billedCustomerId) {
//     if (!(offerObject.customer_ids || [])?.includes(billedCustomerId)) {
//       return false
//     }
//   }

//   return (
//     discountObject?.min_quantity_discounts?.some((condition) => {
//       let conditionDiscountAmountInRupee = 0
//       if (condition?.is_percent) {
//         conditionDiscountAmountInRupee = convertPaisaToRupee(
//           billingRoundFunction(
//             convertRupeeToPaisa(itemRateInRupee || 0) *
//               (convertPaisaToRupee((condition as any).basis_pts) / 100)
//           )
//         )
//       } else {
//         conditionDiscountAmountInRupee = convertPaisaToRupee(condition?.amount) || 0
//       }

//       return (
//         condition.min_quantity === quantity &&
//         conditionDiscountAmountInRupee === discountInputInRupee
//       )
//     }) || false
//   )
// }

// const isDiscAmountMatchingWithAppliedOffer = (
//   discountInputInRupee: number,
//   offerObject: OFFER_RESPONSE,
//   itemRateInRupee: number,
//   billedCustomerId?: string
// ) => {
//   const discountObject = offerObject?.info?.discount_on_rate
//   let computedDiscount = 0

//   if (offerObject && 'customer_ids' in offerObject && billedCustomerId) {
//     if (!(offerObject.customer_ids || [])?.includes(billedCustomerId)) {
//       return false
//     }
//   }

//   if (discountObject) {
//     if (discountObject?.is_percent) {
//       computedDiscount = convertPaisaToRupee(
//         billingRoundFunction(
//           convertRupeeToPaisa(itemRateInRupee || 0) *
//             (convertPaisaToRupee((discountObject as any).basis_pts) / 100)
//         )
//       )
//     } else {
//       computedDiscount = convertPaisaToRupee(discountObject?.amount) || 0
//     }
//   }

//   return discountInputInRupee === computedDiscount
// }

// // Parked, this logic needs to be implemented later
// const findOfferMatchingDiscountAmount = (
//   discountInputInRupee: number,
//   applicableOffers: OFFER_RESPONSE[],
//   itemRateInRupee: number,
//   billedCustomerId?: string
// ) => {
//   let matchedDiscountObject = undefined

//   applicableOffers.forEach((offer) => {
//     const discountEntity = offer
//     const discountObject = offer?.info?.discount_on_rate
//     let computedDiscount = 0

//     let skipBelowLogic = false
//     if (discountEntity && 'customer_ids' in discountEntity && billedCustomerId) {
//       if (!(discountEntity.customer_ids || [])?.includes(billedCustomerId)) {
//         skipBelowLogic = true
//       }
//     }

//     if (!skipBelowLogic) {
//       if (discountObject) {
//         if (discountObject?.is_percent) {
//           computedDiscount = convertPaisaToRupee(
//             billingRoundFunction(
//               convertRupeeToPaisa(itemRateInRupee || 0) *
//                 (convertPaisaToRupee((discountObject as any).basis_pts) / 100)
//             )
//           )
//         } else {
//           computedDiscount = convertPaisaToRupee(discountObject?.amount) || 0
//         }
//       }

//       if (discountInputInRupee === computedDiscount) {
//         matchedDiscountObject = offer
//       }
//     }
//   })

//   return matchedDiscountObject
// }

export const constructRequestPayloadForCreatingCreditNote = (
  data: IUploadAction,
  allItems: InventoryItemType,
  businessGst?: string,
  isIGST?: boolean
): any => {
  const {
    customerId,
    customerDetails,
    invoiceDetails,
    itemDetails,
    payableAmount,
    billId,
    requestId,
    discounts,
    charges
  } = data
  //
  const billNumber = invoiceDetails.invoicePrefix + invoiceDetails.invoiceNumber
  return {
    ...(customerId && {
      customer_id: customerId,
      customer: {
        name: customerDetails.customerName,
        address: customerDetails.customerAddress,
        ...(customerDetails.customerGst && { gst_number: customerDetails.customerGst }),
        mobile: customerDetails.customerMobile
      }
    }),
    ...(requestId && { request_id: requestId }),
    ...(billId && { bill_id: billId }),
    cn_info: {
      discounts: discounts
        ?.filter((discount) => discount.amount > 0)
        .map((discount) => ({
          id: discount.id,
          name: discount.name || OLD_BILL_DISCOUNT_NAME,
          amount: convertRupeeToPaisa(discount.amount),
          pre_tax: discount.isPreTax,
          is_percent: discount.isPercent,
          basis_pts: billingRoundFunction(discount.basis * 100)
        })),
      add_charges: charges
        ?.filter((charge) => charge.amount > 0)
        .map((charge) => ({
          id: charge.id,
          name: charge.name,
          amount: convertRupeeToPaisa(charge.amount),
          is_percent: charge.isPercent,
          basis_pts: billingRoundFunction(charge.basis * 100)
        })),
      cn_number: billNumber,
      cn_date: invoiceDetails.invoiceDate,
      total_amount: convertRupeeToPaisa(payableAmount),
      items: itemDetails.map((item) => {
        const allItemDetails = allItems.find(
          (storeItem) => storeItem.catalogueItemId === item.itemId
        )
        const totalRateWithoutDiscount = convertRupeeToPaisa(item.rate || 0) * item.quantity
        const lineItemDiscount = convertRupeeToPaisa(item.discount || 0) * item.quantity
        const pretaxTotal = totalRateWithoutDiscount - lineItemDiscount
        const cgstValue = billingRoundFunction(pretaxTotal * ((item.cgstTaxPercent || 0) / 100))
        const sgstValue = billingRoundFunction(pretaxTotal * ((item.sgstTaxPercent || 0) / 100))
        const igstValue = isIGST
          ? billingRoundFunction(pretaxTotal * ((item.igstPercent || 0) / 100))
          : 0
        const cessValue = billingRoundFunction(pretaxTotal * ((item.cessPercent || 0) / 100))
        const totalTax = cessValue + (isIGST ? igstValue : cgstValue + sgstValue)
        const lineItemAmount = pretaxTotal + totalTax
        return {
          sku: allItemDetails?.sku || item?.skuId || '',
          name: item.item,
          unit: allItemDetails?.unit || '',
          rate: convertRupeeToPaisa(item.rate || 0),
          mrp: convertRupeeToPaisa(item.mrp || 0),
          quantity: item.quantity,
          tax: totalTax,
          discount: lineItemDiscount,
          amount: lineItemAmount,
          ...(item.sgstTaxPercent || item.cgstTaxPercent || item.cessPercent || item.igstPercent
            ? {
                tax_components: [
                  ...(isIGST
                    ? [
                        ...(item.igstPercent
                          ? [
                              {
                                kind: TAX_TYPE.IGST,
                                basis_pts: billingRoundFunction(item.igstPercent * 100),
                                amount: igstValue
                              }
                            ]
                          : [{ kind: TAX_TYPE.IGST, basis_pts: 0, amount: 0 }])
                      ]
                    : [
                        ...[
                          ...(item.sgstTaxPercent && !isIGST
                            ? [
                                {
                                  basis_pts: billingRoundFunction(item.sgstTaxPercent * 100),
                                  kind: TAX_TYPE.SGST,
                                  amount: sgstValue
                                }
                              ]
                            : [{ kind: TAX_TYPE.SGST, basis_pts: 0, amount: 0 }]),
                          ...(item.cgstTaxPercent && !isIGST
                            ? [
                                {
                                  basis_pts: billingRoundFunction(item.cgstTaxPercent * 100),
                                  kind: TAX_TYPE.CGST,
                                  amount: cgstValue
                                }
                              ]
                            : [{ kind: TAX_TYPE.CGST, basis_pts: 0, amount: 0 }])
                        ]
                      ]),
                  ...(item.cessPercent
                    ? [
                        {
                          basis_pts: item.cessPercent * 100,
                          kind: TAX_TYPE.CESS,
                          amount: cessValue
                        }
                      ]
                    : [{ kind: TAX_TYPE.CESS, basis_pts: 0, amount: 0 }])
                ]
              }
            : { tax_components: [{ kind: TAX_TYPE.SGST, basis_pts: 0, amount: 0 }] }),
          ...(item.hsnCode && { hsn_code: item.hsnCode }),
          catalog_item_id: item.itemId
        }
      }),
      ...(customerDetails.customerGst &&
        matchCustomerGstStateCodeWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerGst
        ) && {
          supply_state: {
            code: customerDetails.customerGst.slice(0, 2),
            name: StatesCodeMapper[customerDetails.customerGst.slice(0, 2)]
          }
        }),
      ...(customerDetails.customerStateSupplyCode &&
        matchStateOfSupplyWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerStateSupplyCode
        ) && {
          supply_state: {
            code: customerDetails.customerStateSupplyCode,
            name: StatesCodeMapper[customerDetails.customerStateSupplyCode]
          }
        })
    }
  }
}

export const computeTaxPercentFromRateAndTaxValue = (rate: number, taxValue: number) => {
  const taxPercent = billingRoundFunction(
    (convertRupeeToPaisa(taxValue || 0) / convertRupeeToPaisa(rate || 0)) * 100
  )
  return { taxPercent }
}

export const computeSGSTandCGSTPercent = (taxPercent: number) => {
  const splittedValue = Number((taxPercent / 2).toFixed(2))
  return { sgstPercent: splittedValue, cgstPercent: splittedValue }
}

type ItemInputType = Omit<ItemType, 'taxIncluded'> & {
  quantity: number
  category: string[]
  catalogueItemId: string
  offer?: OFFER_RESPONSE[]
}

export const chooseBestOffer = (
  offer: OFFER_RESPONSE[] | undefined,
  itemRate: number,
  itemQuantity: number,
  customerDetails?: CustomerDetails,
  applyAllApplicableOffers?: boolean
) => {
  let discount = 0
  let appliedOfferId = ''
  if (!offer) {
    return { discount, appliedOfferId, discountPercent: 0 }
  }

  let offers = offer?.filter((off) => off.type === 1)
  offers = offers?.filter((off) => {
    if (!('end_at' in off)) {
      return off
    }
    const { start_at, end_at } = off
    const currentTimeInMillisecond = dayjs().valueOf()
    if (currentTimeInMillisecond >= start_at && end_at && currentTimeInMillisecond <= end_at) {
      return off
    }
  })

  const filteredOffers = customerDetails?.customerId
    ? offers.filter(
        (off) =>
          off.customer_ids === null ||
          off.customer_ids === undefined ||
          off.customer_ids?.length === 0 ||
          off.customer_ids?.includes(customerDetails?.customerId)
      )
    : offers.filter(
        (off) =>
          off.customer_ids === null ||
          off.customer_ids === undefined ||
          off.customer_ids?.length === 0
      )

  filteredOffers.forEach((off) => {
    const discountObject = off.info?.['discount_on_rate']

    if (discountObject) {
      let updatedDiscount = 0
      if (discountObject?.is_percent) {
        updatedDiscount = convertPaisaToRupee(
          billingRoundFunction(
            convertRupeeToPaisa(itemRate || 0) *
              (convertPaisaToRupee((discountObject as any).basis_pts) / 100)
          )
        )
      } else {
        updatedDiscount = convertPaisaToRupee(discountObject?.amount) || 0
      }

      // to test
      if (discountObject.min_quantity_discounts?.length) {
        discountObject.min_quantity_discounts.forEach((condition) => {
          let minQuantityComputedDiscount = 0

          if (itemQuantity >= condition.min_quantity && itemQuantity !== 1) {
            if (condition?.is_percent) {
              minQuantityComputedDiscount = convertPaisaToRupee(
                billingRoundFunction(
                  convertRupeeToPaisa(itemRate || 0) *
                    (convertPaisaToRupee((condition as any).basis_pts) / 100)
                )
              )
            } else {
              minQuantityComputedDiscount = convertPaisaToRupee(condition?.amount) || 0
            }
          }

          if (minQuantityComputedDiscount >= updatedDiscount) {
            updatedDiscount = minQuantityComputedDiscount
          }
        })
      }

      if ((itemRate || 0) <= updatedDiscount) {
        updatedDiscount = 0 // Offer cannot be applied if, discountAmount exceeds or is equal to the rate
        appliedOfferId = ''
      }

      if (updatedDiscount > discount) {
        discount = updatedDiscount
        appliedOfferId = off.id
      }
    }
  })

  const discountInPaisa = convertRupeeToPaisa(discount)
  let discountPercent = convertPaisaToRupee(
    billingRoundFunction(
      convertRupeeToPaisa((discountInPaisa / convertRupeeToPaisa(itemRate || 0)) * 100)
    )
  )

  let multiOffers
  if (applyAllApplicableOffers) {
    multiOffers = sumupAllOffers({
      allApplicableOffers: filteredOffers,
      itemQuantity,
      itemRate
    })

    discount = multiOffers?.summedDiscount
    discountPercent = multiOffers?.summedDiscountPercent
    appliedOfferId = multiOffers?.allAppliedOfferIds.join(',') // To discuss with Harshith once
  }

  return {
    discount,
    appliedOfferId,
    discountPercent,
    multiOffers
  }
}

export const sumupAllOffers = ({
  allApplicableOffers,
  itemRate,
  itemQuantity
}: {
  allApplicableOffers: OFFER_RESPONSE[]
  itemRate: number
  itemQuantity: number
}) => {
  let summedDiscount = 0
  const allAppliedOfferIds: string[] = []

  allApplicableOffers.forEach((off) => {
    const discountObject = off.info?.['discount_on_rate']

    if (discountObject) {
      let requiredDiscount = 0

      if (discountObject?.is_percent) {
        requiredDiscount = convertPaisaToRupee(
          billingRoundFunction(
            convertRupeeToPaisa(itemRate || 0) *
              (convertPaisaToRupee((discountObject as any).basis_pts) / 100)
          )
        )
      } else {
        requiredDiscount = convertPaisaToRupee(discountObject?.amount) || 0
      }

      if (discountObject.min_quantity_discounts?.length) {
        discountObject.min_quantity_discounts.forEach((condition) => {
          if (itemQuantity >= condition.min_quantity && itemQuantity !== 1) {
            if (condition?.is_percent) {
              requiredDiscount = convertPaisaToRupee(
                billingRoundFunction(
                  convertRupeeToPaisa(itemRate || 0) *
                    (convertPaisaToRupee((condition as any).basis_pts) / 100)
                )
              )
            } else {
              requiredDiscount = convertPaisaToRupee(condition?.amount) || 0
            }
          }
        })
      }

      if (summedDiscount + requiredDiscount < itemRate) {
        summedDiscount += requiredDiscount
        allAppliedOfferIds.push(off.id)
      }
    }
  })
  const summedDiscountInPaisa = billingRoundFunction(convertRupeeToPaisa(summedDiscount))
  const summedDiscountPercent = convertPaisaToRupee(
    billingRoundFunction(
      convertRupeeToPaisa((summedDiscountInPaisa / convertRupeeToPaisa(itemRate || 0)) * 100)
    )
  )
  return {
    summedDiscount: convertPaisaToRupee(summedDiscountInPaisa),
    allAppliedOfferIds,
    summedDiscountPercent
  }
}

export const getOfferBasisQuantity = ({
  applicableOfferDetails,
  customerId,
  item: { rate, quantity, discount }
}: {
  applicableOfferDetails: OFFER_RESPONSE[]
  customerId: string
  item: {
    rate: number
    quantity: number
    discount: number
  }
}): {
  appliedOfferId: undefined | string
  discountAmount: undefined | number
  discountPercent: undefined | number
} => {
  const offers = applicableOfferDetails || []
  const filteredOffers = customerId
    ? offers.filter(
        (off) =>
          off.customer_ids === null ||
          off.customer_ids === undefined ||
          off.customer_ids?.length === 0 ||
          off.customer_ids?.includes(customerId)
      )
    : offers.filter(
        (off) =>
          off.customer_ids === null ||
          off.customer_ids === undefined ||
          off.customer_ids?.length === 0
      )

  const offersWithMinQuantity = filteredOffers.filter(
    (off) => off.info?.discount_on_rate?.min_quantity_discounts
  )

  let appliedOfferId = undefined
  let discountAmount: any = undefined
  if (offersWithMinQuantity.length) {
    offersWithMinQuantity.forEach((offer) => {
      offer.info?.discount_on_rate?.min_quantity_discounts?.forEach((condition) => {
        if (quantity >= condition.min_quantity) {
          let conditionDiscountAmountInRupee = 0
          if (condition?.is_percent) {
            conditionDiscountAmountInRupee = convertPaisaToRupee(
              billingRoundFunction(
                convertRupeeToPaisa(rate || 0) *
                  (convertPaisaToRupee((condition as any).basis_pts) / 100)
              )
            )
          } else {
            conditionDiscountAmountInRupee = convertPaisaToRupee(condition?.amount) || 0
          }

          if (
            (Number(discount) || 0) <= conditionDiscountAmountInRupee &&
            (discountAmount < conditionDiscountAmountInRupee || discountAmount === undefined) &&
            conditionDiscountAmountInRupee < rate
          ) {
            appliedOfferId = offer.id
            discountAmount = conditionDiscountAmountInRupee
          }
        }
      })
    })
  }

  const discountInPaisa = convertRupeeToPaisa(discountAmount || 0)
  const discountPercent =
    convertPaisaToRupee(
      billingRoundFunction(
        convertRupeeToPaisa((discountInPaisa / convertRupeeToPaisa(rate || 0)) * 100)
      )
    ) || 0

  return { appliedOfferId, discountAmount, discountPercent }
}

export const constructObjectForPostDiscountType = (
  item: ItemInputType,
  discount: number,
  appliedOfferId: string,
  isDiscountInPercent: boolean,
  discountPercent: number
): Omit<NewItemDetailEntity, 'quantity'> => {
  const { taxPercent: gstPercent } = computeTaxPercentFromRateAndTaxValue(
    item.rate,
    item.gst_amount || 0
  )
  const { taxPercent: cessPercent } = computeTaxPercentFromRateAndTaxValue(
    item.rate,
    item.cess_amount || 0
  )

  const { sgstPercent, cgstPercent } = computeSGSTandCGSTPercent(gstPercent)

  const rateInPaisa = convertRupeeToPaisa(item.rate || 0)
  const netRateInPaisa = rateInPaisa * (1 + (gstPercent + cessPercent) / 100)

  const taxAmountInPaisa = netRateInPaisa - rateInPaisa
  const netRateInPaisaWithOnlyGst = rateInPaisa * (1 + gstPercent / 100)

  const gstTaxAmountInPaisa = netRateInPaisaWithOnlyGst - rateInPaisa

  let updatedDiscountAmount = discount
  let updatedDiscountPercent = discountPercent
  if (updatedDiscountPercent) {
    updatedDiscountAmount = convertPaisaToRupee(
      billingRoundFunction(netRateInPaisa * (updatedDiscountPercent / 100))
    )
  } else {
    updatedDiscountPercent = convertPaisaToRupee(
      billingRoundFunction(convertPaisaToRupee(updatedDiscountAmount) / netRateInPaisa) * 100
    )
  }

  const unitsData = getUnitsData(item)
  return {
    item: item.description,
    itemId: item.catalogueItemId,
    hsnCode: item.hsnCode,
    mrp: item.mrp || 0,
    rate: item.rate,
    sgstTaxPercent: sgstPercent,
    cgstTaxPercent: cgstPercent,
    gstTaxPercent: gstPercent,
    igstPercent: gstPercent,
    cessPercent,
    discount: updatedDiscountAmount,
    discountType: 'post',
    isDiscountInPercent,
    discountPercent,
    appliedOfferId,
    applicableOfferDetails: item.offer,
    netRate: convertPaisaToRupee(billingRoundFunction(netRateInPaisa)),
    taxAmount: convertPaisaToRupee(billingRoundFunction(taxAmountInPaisa)),
    gstTotalAmount: convertPaisaToRupee(billingRoundFunction(gstTaxAmountInPaisa)),
    ...(unitsData && unitsData),
    isTaxIncludedInRate: !!item.showTaxIncluded
  }
}

const getUnitsData = (item: ItemInputType) => {
  const allPossibleUnits = getAllPossibleUnits(item)
  if (allPossibleUnits.length) {
    return {
      allPossibleUnits,
      selectedUnit: allPossibleUnits[0].name,
      selectedUnitFactor: allPossibleUnits[0].factor
    }
  }
}

const getAllPossibleUnits = (item: ItemInputType) => {
  if (item.unit && item.secondaryUnits && item.secondaryUnits?.length > 0) {
    return [{ name: item.unit, factor: 1 }, ...item.secondaryUnits]
  }

  return []
}

export const createItemsObjectForDropDown = (
  item: ItemInputType,
  isIGST: boolean,
  customerDetails: CustomerDetails,
  itemLevelDiscountInfo: { discountType: 'pre' | 'post'; isDiscountInPercent: boolean } = {
    discountType: 'pre',
    isDiscountInPercent: false
  },
  applyAllApplicableOffers: boolean = false
): Omit<NewItemDetailEntity, 'quantity'> => {
  const {
    discount, // bestDiscountAmount
    appliedOfferId, // bestDiscountAppliedOfferID
    discountPercent // bestDiscountPercent
  } = chooseBestOffer(item.offer, item.rate, 1, customerDetails, applyAllApplicableOffers)

  const { discountType, isDiscountInPercent } = itemLevelDiscountInfo

  if (discountType === 'post') {
    return constructObjectForPostDiscountType(
      item,
      discount,
      appliedOfferId,
      isDiscountInPercent,
      discountPercent || 0
    )
  }

  const effectiveRate = item.rate - discount
  const { taxPercent: gstPercent } = computeTaxPercentFromRateAndTaxValue(
    item.rate,
    item.gst_amount || 0
  )
  const { taxPercent: cessPercent } = computeTaxPercentFromRateAndTaxValue(
    item.rate,
    item.cess_amount || 0
  )

  const { sgstPercent, cgstPercent } = computeSGSTandCGSTPercent(gstPercent)

  const rateInPaisa = convertRupeeToPaisa(effectiveRate)
  const netRateInPaisa = rateInPaisa * (1 + (gstPercent + cessPercent) / 100)

  const netRateInPaisaWithOnlyGst = rateInPaisa * (1 + gstPercent / 100)
  const taxAmountInPaisa = netRateInPaisa - rateInPaisa
  const gstTaxAmountInPaisa = netRateInPaisaWithOnlyGst - rateInPaisa

  const unitsData = getUnitsData(item)

  return {
    item: item.description,
    itemId: item.catalogueItemId,
    hsnCode: item.hsnCode,
    mrp: item.mrp || 0,
    rate: item.rate,
    sgstTaxPercent: sgstPercent,
    cgstTaxPercent: cgstPercent,
    gstTaxPercent: gstPercent,
    igstPercent: gstPercent,
    cessPercent,
    discount,
    discountType,
    isDiscountInPercent,
    discountPercent,
    appliedOfferId,
    applicableOfferDetails: item.offer,
    netRate: convertPaisaToRupee(billingRoundFunction(netRateInPaisa)),
    taxAmount: convertPaisaToRupee(billingRoundFunction(taxAmountInPaisa)),
    gstTotalAmount: convertPaisaToRupee(billingRoundFunction(gstTaxAmountInPaisa)),
    ...(unitsData && unitsData),
    isTaxIncludedInRate: !!item.showTaxIncluded
  }
}

export const getinputFieldRegex = (e: any) => {
  let inputValue = e.target.value

  inputValue = inputValue.replace(/[^\d.]/g, '') // Remove non-digit characters except '.'
  inputValue = inputValue.replace(/^\./, '') // Remove leading '.'
  inputValue = inputValue.replace(/\.(?=.*\.)/g, '') // Remove multiple '.' occurrences except the first one
  inputValue = inputValue.replace(/^(\d+\.\d{2}).*$/, '$1') // Limit to two decimal places
  // Check if the input is a number and remove leading zeros if present
  if (!isNaN(Number(inputValue))) {
    inputValue = inputValue.replace(/^0+(?=\d)/, '') // Remove leading zeros if the input is numeric
  }

  e.target.value = inputValue
  return e
}

export const convertRupeeToPaisa = (rupeeValue: number) => {
  return billingRoundFunction(rupeeValue * 100)
}

export const convertPaisaToRupee = (paisaValue: number) => {
  return paisaValue / 100
}

export const billingRoundFunction = (value: number) => {
  return Math.round(value)
}

const getTaxPercentage = (taxComponent: Array<TaxComponentType>, taxType: TAX_TYPE): number => {
  let taxVal
  switch (taxType) {
    case TAX_TYPE.SGST:
      taxVal = taxComponent?.find((_) => _.kind === TAX_TYPE.SGST)
      return Number(taxVal?.basis_pts || 0) / 100
    case TAX_TYPE.CESS:
      taxVal = taxComponent?.find((_) => _.kind === TAX_TYPE.CESS)
      return Number(taxVal?.basis_pts || 0) / 100
    case TAX_TYPE.IGST:
      taxVal = taxComponent?.find((_) => _.kind === TAX_TYPE.IGST)
      return Number(taxVal?.basis_pts || 0) / 100
    default:
      taxVal = taxComponent?.find((_) => !_.kind)
      return Number(taxVal?.basis_pts || 0) / 100
  }
}

export const getTaxValue = (taxComponent: Array<TaxComponentType>, taxType: TAX_TYPE): number => {
  let taxVal
  switch (taxType) {
    case TAX_TYPE.SGST:
      taxVal = taxComponent?.find((_) => _.kind === TAX_TYPE.SGST)
      return Number(taxVal?.amount || 0) / 100
    case TAX_TYPE.CESS:
      taxVal = taxComponent?.find((_) => _.kind === TAX_TYPE.CESS)
      return Number(taxVal?.amount || 0) / 100
    case TAX_TYPE.IGST:
      taxVal = taxComponent?.find((_) => _.kind === TAX_TYPE.IGST)
      return Number(taxVal?.amount || 0) / 100
    default:
      taxVal = taxComponent?.find((_) => !_.kind)
      return Number(taxVal?.amount || 0) / 100
  }
}

const extractGstAmountFromTaxComponents = (taxComponent: TaxComponentType[]) => {
  return taxComponent.reduce((acc: number, tax: TaxComponentType) => {
    return (
      acc +
      (tax.kind === TAX_TYPE.SGST || !tax.kind || tax.kind === TAX_TYPE.IGST ? tax.amount || 0 : 0)
    )
  }, 0)
}

export const formatBillItemsData = (items: Array<BillItemType>): Array<ItemsDataType> => {
  return items?.map((item) => {
    return {
      itemId: item.catalog_item_id,
      amount: convertPaisaToRupee(item.amount / item.quantity),
      hsnCode: item.hsn_code,
      mrp: convertPaisaToRupee(item.mrp || 0),
      quantity: item.quantity,
      rate: convertPaisaToRupee(item.rate),
      taxComponents: item.tax_components,
      taxAmount: convertPaisaToRupee(billingRoundFunction(item.tax / item.quantity)),
      gstTotalAmount: convertPaisaToRupee(
        billingRoundFunction(extractGstAmountFromTaxComponents(item.tax_components) / item.quantity)
      ),
      discount: convertPaisaToRupee((item.discount || 0) / item.quantity),
      sgstTaxPercent: getTaxPercentage(item.tax_components, TAX_TYPE.SGST),
      cgstTaxPercent: getTaxPercentage(item.tax_components, TAX_TYPE.CGST),
      igstPercent: getTaxPercentage(item.tax_components, TAX_TYPE.IGST),
      cessPercent: getTaxPercentage(item.tax_components, TAX_TYPE.CESS),
      item: item.name,
      netRate: convertPaisaToRupee(billingRoundFunction(item.amount / item.quantity)),
      amountPayable: convertPaisaToRupee(item.amount),
      inventoryItemId: item.inventory_item_id
    }
  })
}

export const formatSelectedDiscounts = (discounts: BillDiscounts[]): SelectedDiscountType[] => {
  return discounts.map((discount) => ({
    id: discount.id,
    name: discount.name,
    isPercent: discount.is_percent,
    isPreTax: discount.pre_tax,
    amount: convertPaisaToRupee(discount.amount),
    basis: discount.basis_pts / 100
  }))
}

export const formatSelectedCharges = (charges: BillCharges[]): SelectedChargeType[] => {
  return charges.map((charge) => ({
    id: charge.id,
    name: charge.name,
    isPercent: charge.is_percent,
    amount: convertPaisaToRupee(charge.amount),
    basis: charge.basis_pts / 100
  }))
}

const getSplitOfInvoiceNoAndPrefix = (invoice: string) => {
  const firstNumberIndex = invoice.search(/\d/) // Find the index of the first number
  if (firstNumberIndex !== -1) {
    // If a number is found
    const prefix = invoice.slice(0, firstNumberIndex) // Extract the part before the first number
    const number = invoice.slice(firstNumberIndex) // Extract the part including and after the first number
    return {
      prefix,
      number
    }
  }
  return {
    prefix: BILL_NUMBER_PREFIX,
    number: BILL_DEFAULT_NUMBER
  }
}

const getDiscountAmountFromArray = (discount: Array<BillDiscounts>) => {
  if (discount?.length > 0) {
    const calcDiscount = discount?.reduce((prev, curr) => {
      return Number(prev) + convertPaisaToRupee(curr?.amount)
    }, 0)
    return calcDiscount
  }
  return 0
}

export const formatBillData = (
  bill: BillByIdResponse['bill'],
  creatingCreditNote = false
): BillDataType => {
  const { bill_info, buyer, bill_id, bill_version } = bill
  const {
    bill_number,
    bill_date,
    items,
    total_amount,
    discounts = [],
    add_charges = []
  } = bill_info

  const isIGST = items.some((item) =>
    item.tax_components.some((taxComponent) => taxComponent.kind === TAX_TYPE.IGST)
  )

  const data = {
    customerDetails: {
      customerId: buyer.id,
      customerName: buyer.name,
      customerAddress: buyer.address,
      customerMobile: buyer.mobile,
      customerGST: buyer?.gst_number || '',
      customerStateSupplyCode: bill_info?.supply_state?.code
    },
    invoiceDetails: {
      invoiceNumber: getSplitOfInvoiceNoAndPrefix(bill_number).number,
      invoiceDate: convertEpochToDay(bill_date / 1000),
      // billingTodo: gst no. to be added
      invoiceGST: '',
      invoicePrefix: creatingCreditNote ? 'CN' : getSplitOfInvoiceNoAndPrefix(bill_number).prefix
    },
    addedItemDetails: formatBillItemsData(items),
    discountAmount: getDiscountAmountFromArray(discounts),
    payableAmount: convertPaisaToRupee(total_amount),
    billId: bill_id,
    billVersion: bill_version,
    selectedDiscounts: formatSelectedDiscounts(discounts),
    selectedCharges: formatSelectedCharges(add_charges),
    invoiceTotalAmount: 0,
    isIGST
  }
  return data
}

export const OLD_BILL_DISCOUNT_NAME = '_unnamedUI'

export const getBillEntity = (isInvoice: boolean, isProforma?: boolean): string => {
  if (isProforma) {
    return 'Proforma'
  }
  return isInvoice ? 'Invoice' : 'Bill'
}

export const getCurrentDateFormatted = (): string => {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const epochToExcelDate = (epochTime: number) => {
  const excelEpoch = 25569
  const millisecondsPerDay = 86400000
  const epochTimeMs = epochTime * 1000
  const serialDate = epochTimeMs / millisecondsPerDay + excelEpoch

  return serialDate
}

export const convertExcelDateToEpoch = (serial: number) => {
  const epochStart = Date.UTC(1900, 0, 1) // Excel epoch start in UTC
  const days = serial - 2 // Excel incorrectly considers 1900 as a leap year
  const epochTime = epochStart + days * 86400 * 1000 // 86400 seconds in a day
  return epochTime / 1000 // Return epoch time in seconds
}

export const generateTimestamp = () => {
  try {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    return `${year}${month}${day}${hours}${minutes}`
  } catch (error) {
    return ''
  }
}

export const downloadBulkPurchaseFailedFile = (
  purchaseAssetAccount: any,
  record = [],
  fileName: string
) => {
  const filterPurchaseAssetAccountForBank = purchaseAssetAccount.filter(
    (item: any) => item.type === 'bank'
  )

  const accountIdToNumber = filterPurchaseAssetAccountForBank.reduce((acc: any, account: any) => {
    acc[account.id] = account.account_number.toString()
    return acc
  }, {})

  const getAccountNumber = (payment_account_id: string) => {
    return accountIdToNumber[payment_account_id] || ''
  }

  const formatExpenseItemForFile = (record: any) =>
    record.map((item: any) => ({
      Date: epochToExcelDate(item.purchase_date),
      'Transaction ID': item.reference_number,
      'Net Amount': item.amount / 100,
      Description: item.notes,
      'Bank Account': getAccountNumber(item.payment_account_id),
      Error: item.error_message
    }))

  const data = formatExpenseItemForFile(record)
  const ws = utils.json_to_sheet(data)

  ws['!cols'] = [
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 }
  ]

  Object.keys(ws).forEach((key) => {
    if (key.startsWith('A') && ws[key].t === 'n') {
      ws[key].z = 'm/d/yy'
    }
  })

  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)
}
