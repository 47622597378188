// import { CartActions } from 'pages/Cart/Cart.actions';
import { REPlACEMENT_RECORD_TYPES } from 'state/collectionList/collectionList.types'
import { ExpActions } from 'state/experiment/experiment.action'
import { Action, IStaffLinkState, ITransactions } from './StaffLink.types'
import { StaffLinkState } from './StaffLink.state'
import { StaffLinkActions } from './StaffLink.actions'

export const StaffLinkReducers = (
  state: IStaffLinkState = StaffLinkState,
  action: Action<any>
): IStaffLinkState => {
  switch (action.type) {
    case StaffLinkActions.SET_LINKID_AND_MERCHANTID_STATE: {
      return {
        ...state,
        merchantId: action.payload.merchantId,
        linkId: action.payload.linkId
      }
    }
    case StaffLinkActions.HANDLE_FETCH_STAFFLINK_DATA: {
      return {
        ...state,
        versionLoader: true
      }
    }
    case StaffLinkActions.SET_STAFF_DETAILS: {
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          data: action.payload
        }
      }
    }
    case StaffLinkActions.SET_BUSINESS_ACCOUNTS: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          customers: action.payload
        }
      }
    }
    case StaffLinkActions.SET_CATALOG_ITEMS: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          items: action.payload
        }
      }
    }
    case StaffLinkActions.SET_CART_ITEMS: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          cartItems: action.payload
        }
      }
    }
    case StaffLinkActions.UPDATE_STAFF_DETAILS: {
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          isLoading: true
        }
      }
    }
    case StaffLinkActions.UPDATE_STAFF_DETAILS_SUCCESS: {
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          data: action.payload,
          isLoading: false
        }
      }
    }
    case StaffLinkActions.UPDATE_STAFF_DETAILS_FAILURE: {
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          isLoading: false
        }
      }
    }
    case StaffLinkActions.SET_STAFF_DETAILS_DRAWER: {
      return {
        ...state,
        staffDetails: {
          ...state.staffDetails,
          isDrawerOpen: action.payload
        }
      }
    }
    case StaffLinkActions.SET_TRANSACTION_ERROR: {
      return {
        ...state,
        transactionsError: action.payload
      }
    }
    case StaffLinkActions.SET_STAFF_LINK_DETAILS_STATE: {
      return {
        ...state,
        staffLinkDetails: action.payload,
        versionLoader: false
      }
    }
    case StaffLinkActions.SET_MERCHANT_FEATURES: {
      return {
        ...state,
        merchantFeatures: action.payload
      }
    }
    case StaffLinkActions.SET_LOADING_STATE: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case StaffLinkActions.SET_PAGE_ERROR_STATE: {
      return {
        ...state,
        pageError: action.payload
      }
    }
    case StaffLinkActions.SET_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatus: action.payload
      }
    }
    case StaffLinkActions.SET_IS_PHONE_NUMBER_SEARCH: {
      return {
        ...state,
        isPhoneNumberSearch: action.payload
      }
    }
    case StaffLinkActions.GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER: {
      return {
        ...state,
        isPhoneNumberSearch: true,
        searchedUser: undefined,
        isSearchingUser: true,
        searchUserError: ''
      }
    }
    case StaffLinkActions.GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        isPhoneNumberSearch: true,
        searchedUser: action.payload,
        isSearchingUser: false,
        searchUserError: ''
      }
    }
    case StaffLinkActions.GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_ERROR: {
      return {
        ...state,
        isPhoneNumberSearch: true,
        isSearchingUser: false,
        searchedUser: undefined,
        searchUserError: action.payload
      }
    }
    case StaffLinkActions.SET_AUTO_DEBBIT_LINK_ACCOUNT_ID: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.accountId) {
          return {
            ...item,
            registration: {
              registration_url: action.payload.regLink
            },
            deductions: [
              {
                due_date: action.payload.dueDate
              }
            ]
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...action.payload.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.SET_TRANSACTION: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.associated_account_id) {
          const newTransections = [...item.transactions]
          newTransections.push(action.payload)
          return {
            ...item,
            transactions: newTransections
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.SET_MULTILE_TRANSACTIONS: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (
          action.payload.some((txn: ITransactions) => txn.associated_account_id === item.account_id)
        ) {
          const newTransections = [
            ...item.transactions,
            ...action.payload.filter(
              (txn: ITransactions) => txn.associated_account_id === item.account_id
            )
          ]
          return {
            ...item,
            transactions: newTransections
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.SET_REPLACEMENT_RECORD: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.account_id) {
          const newReplacements = [...item?.replacements]
          newReplacements.push(action.payload)
          return {
            ...item,
            replacements: newReplacements
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.UPDATE_REPLACEMENT_RECORD: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.account_id) {
          let newReplacements = [...item.replacements]
          if (action.payload.status === REPlACEMENT_RECORD_TYPES.DELETE) {
            newReplacements = item?.replacements.filter(
              (replacement) => replacement.id !== action.payload.id
            )
          } else {
            newReplacements = item?.replacements?.map((replacement) => {
              if (replacement.id === action?.payload?.id) {
                return action.payload
              }
              return replacement
            })
          }
          return {
            ...item,
            replacements: newReplacements
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.UPDATE_TRANSACTION: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.associated_account_id) {
          const updatedTransections = item.transactions.map((txn) => {
            if (txn.id === action.payload.id) {
              return action.payload
            }
            return txn
          })
          return {
            ...item,
            transactions: updatedTransections
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.UPDATE_MULTIPLE_TRANSACTION: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (
          action.payload.some((txn: ITransactions) => txn.associated_account_id === item.account_id)
        ) {
          const updatedTransections = item.transactions.map((txn) => {
            const updatedTxn = action.payload.find((item: ITransactions) => item.id === txn.id)
            if (updatedTxn && updatedTxn.is_deleted) {
              return null
            } else if (updatedTxn) {
              return updatedTxn
            }
            return txn
          })
          return {
            ...item,
            transactions: updatedTransections.filter((itm) => itm)
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.CANCEL_INVOICE: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.associated_account_id) {
          const formattedBills = item.bills.map((bill) => {
            if (bill.bill.bill_id === action.payload.bill_id) {
              return {
                ...bill,
                bill_cancel_params: action.payload
              }
            }
            return bill
          })
          return {
            ...item,
            bills: formattedBills
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.UNDO_CANCEL_INVOICE: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.associated_account_id) {
          const formattedBills = item.bills.map((bill) => {
            if (bill.bill.bill_id === action.payload.bill_id) {
              return {
                ...bill,
                bill_cancel_params: null
              }
            }
            return bill
          })
          return {
            ...item,
            bills: formattedBills
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.DELETE_TRANSACTION: {
      const newAccountDetails = state.staffLinkDetails.account_details?.map((item) => {
        if (item.account_id === action.payload.associated_account_id) {
          const filteredTxns = item.transactions.filter((txn) => txn.id !== action.payload.id)
          return {
            ...item,
            transactions: filteredTxns
          }
        }
        return item
      })
      return {
        ...state,
        staffLinkDetails: {
          ...state.staffLinkDetails,
          account_details: newAccountDetails
        }
      }
    }
    case StaffLinkActions.FETCH_COLLECTION_SUMMARY: {
      return {
        ...state,
        collectionSummary: null
      }
    }
    case StaffLinkActions.SET_COLLECTION_SUMMARY: {
      return {
        ...state,
        collectionSummary: action.payload
      }
    }
    case StaffLinkActions.SAVE_SELECTED_BILL: {
      return {
        ...state,
        selectedStaffBill: action.payload.bill,
        dueConfig: action.payload.dueConfig
      }
    }
    case StaffLinkActions.SET_IS_STAFF_BLOCKED_TO_ACCESS_LIST: {
      return {
        ...state,
        loading: false,
        isStaffBlockedToAccessList: action.payload
      }
    }
    case StaffLinkActions.SAVE_ORDER_SELECTED_CUSTOMER: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          selectedCustomer: action?.payload
        }
      }
    }
    case StaffLinkActions.SAVE_ORDER_HISTORY_DETAILS: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          orderHistory: action?.payload
        }
      }
    }
    case StaffLinkActions.SAVE_ORDER_SELECTED_ITEM: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          selectedOrder: action?.payload
        }
      }
    }
    case StaffLinkActions.SAVE_PROFORMA_SELECTED_ITEM: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          selectedProforma: action?.payload
        }
      }
    }
    case StaffLinkActions.SAVE_STAFF_BUSINESS_DETAILS: {
      return {
        ...state,
        businessDetails: action.payload
      }
    }
    case ExpActions.GET_STAFF_BUSINESS_SETTINGS: {
      return {
        ...state,
        businessSettings: {
          ...state.businessSettings,
          loading: true,
          ready: false
        }
      }
    }
    case ExpActions.GET_STAFF_BUSINESS_SETTINGS_SUCCESS: {
      return {
        ...state,
        businessSettings: action.payload
      }
    }
    case StaffLinkActions.STAFF_PLACED_ORDER_SUCCESS_ACTION: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          staffPlacedOrderSuccess: action.payload
        }
      }
    }
    case StaffLinkActions.CREATE_NEW_ORDER: {
      return {
        ...state,
        staffOrder: {
          ...state.staffOrder,
          staffPlacedOrderSuccess: false
        }
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
