import { Action } from 'infra/types'

export const moveToAnotherListActions = {
  SET_DRAWER: 'moveToAnotherListActions/setDrawers',
  SET_MOVE_TO_ANOTHER_LIST_DRAWER_DATA: 'moveToAnotherListActions/setAddtoCollectionDrawersData',
  RESET_MOVE_TO_ANOTHER_LIST_DRAWER_STATE: 'moveToAnotherListActions/resetDrawerData'
}

export const setMoveToAnotherListDrawers = (payload: {
  drawerName: 'showMoveToAnotherListDrawer'
  status: boolean
}): Action<{
  drawerName: 'showMoveToAnotherListDrawer'
  status: boolean
}> => ({
  type: moveToAnotherListActions.SET_DRAWER,
  payload
})

export const setMoveToAnotherListDrawerWithData = (payload: {
  drawerStatus: boolean
  listId: string
  isBalanceDue: boolean
  bills?: {
    billId: string
    customerId: string
  }[]
  accountIds?: string[]
}): Action<{
  drawerStatus: boolean
  listId: string
  isBalanceDue: boolean
  bills?: {
    billId: string
    customerId: string
  }[]
  accountIds?: string[]
}> => {
  return {
    type: moveToAnotherListActions.SET_MOVE_TO_ANOTHER_LIST_DRAWER_DATA,
    payload
  }
}

export const resetMoveToAnotherListDrawerState = (): Action<null> => ({
  type: moveToAnotherListActions.RESET_MOVE_TO_ANOTHER_LIST_DRAWER_STATE,
  payload: null
})
