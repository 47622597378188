import { getEnvConfig } from 'utils/getEnvConfig'
import {
  IPreviewBillRequestPayload,
  IPreviewOrderRequestPayload
} from 'state/billing/billing.types'
import { IPreviewProformaRequestPayload } from 'services/Api/StaffLinkApiFolder/type'
import ApiService from '../ApiService'
import {
  apiRequestInterceptor,
  businessIdInterceptor,
  apiResponseSuccessInterceptor,
  apiResponseErrorInterceptor
} from '../apiInterceptor'
import { IApiResponse } from '../../../constants'
import { IDeleteFilePayload, PREVIEW_BILL_RESPONSE, SYNC_REQUEST_PAYLOAD } from './type'

const PREVIEW_BILL = '/external/bill-preview'
const PREVIEW_PROFORMA = '/external/proforma-preview'
const SYNC_FILE = '/external/syncFile'
const DELETE_UPLOADED_FILE = '/external/ledger/delete'
const PRINT_PROFORMA = '/external/proforma-print'
const PRINT_ORDERS = '/external/v2/order-print'
const PREVIEW_CREDIT_NOTE = '/external/credit-note-print'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('GROWTH_ENDPOINT') || ''

const api = new ApiService(endpoint, headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const previewBill = (
  payload: IPreviewBillRequestPayload
): Promise<IApiResponse<PREVIEW_BILL_RESPONSE>> => {
  return api.postRequest(PREVIEW_BILL, payload)
}

export const syncFile = (payload: SYNC_REQUEST_PAYLOAD): Promise<IApiResponse<any>> => {
  return api.postRequest(SYNC_FILE, payload)
}

export const deleteUploadedFileWithDataApi = (payload: IDeleteFilePayload) => {
  return api.postRequest(DELETE_UPLOADED_FILE, payload)
}

export const previewProformaApi = (
  payload: IPreviewProformaRequestPayload
): Promise<IApiResponse<PREVIEW_BILL_RESPONSE>> => {
  return api.postRequest(PREVIEW_PROFORMA, payload)
}

export const printProformaApi = (proformaIds: string[]) => {
  return api.postRequest(PRINT_PROFORMA, { proforma_ids: proformaIds })
}

export const printOrdersApi = (payload: IPreviewOrderRequestPayload) => {
  return api.postRequest(PRINT_ORDERS, payload)
}

export const creditNotePreviewEntityApi = (payload: {
  business_id: string
  credit_note_ids: string[]
}) => {
  return api.postRequest(PREVIEW_CREDIT_NOTE, payload)
}
