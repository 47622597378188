import { DOCUMENT_TYPE } from 'state/new-summary/uploadSheet/uploadSheet.types'

export const sheetDetailsFormDetails = [
  {
    label: 'Sheet Name',
    placeholder: 'Sheet Name',
    fieldName: 'name'
  },
  {
    label: 'Header Row Number',
    placeholder: 'Header Row Number',
    fieldName: 'header'
  }
]

export const columnDetailsFormDetails = [
  {
    label: 'Invoice Number',
    placeholder: 'Invoice Number',
    fieldName: 'invoiceNumber'
  },
  {
    label: 'Mobile Number',
    placeholder: 'Mobile Number',
    fieldName: 'mobileNumber'
  },
  {
    label: 'Customer Name',
    placeholder: 'Customer Name',
    fieldName: 'customerName'
  },
  {
    label: 'Amount',
    placeholder: 'Amount',
    fieldName: 'amount'
  },
  {
    label: 'Customer Code',
    placeholder: 'Customer Code',
    fieldName: 'customerCode'
  },
  {
    label: 'Invoice Date (Optional)',
    placeholder: 'Invoice Date',
    fieldName: 'invoiceDate'
  },
  {
    label: 'Sale Type (Optional)',
    placeholder: 'Sale Type',
    fieldName: 'saleType'
  }
]

export enum templateFormatType {
  MARG = 'MARG',
  PACE = 'PACE',
  TALLY = 'TALLY',
  P_AND_G = 'P&G',
  OTHERS = 'Others'
}

export const templateFormatOptions = [
  {
    label: templateFormatType.MARG,
    value: templateFormatType.MARG
  },
  {
    label: templateFormatType.PACE,
    value: templateFormatType.PACE
  },
  {
    label: templateFormatType.TALLY,
    value: templateFormatType.TALLY
  },
  {
    label: templateFormatType.P_AND_G,
    value: templateFormatType.P_AND_G
  },
  {
    label: templateFormatType.OTHERS,
    value: templateFormatType.OTHERS
  }
]

export const fileFormatOptions = [
  {
    label: '.xls, .xlsx',
    value: DOCUMENT_TYPE.EXCEL
  },
  {
    label: '.csv',
    value: DOCUMENT_TYPE.CSV
  }
]

export const itemDatailsOptions = [
  {
    label: 'Yes',
    value: 'YES'
  },
  {
    label: 'No',
    value: 'NO'
  }
]

export const DOCUMENT_TYPE_VS_LABEL: Record<DOCUMENT_TYPE.EXCEL | DOCUMENT_TYPE.CSV, string> = {
  [DOCUMENT_TYPE.EXCEL]: 'Excel',
  [DOCUMENT_TYPE.CSV]: 'csv'
}

export enum AMOUNT_ROUNDING_TYPE {
  NO_ROUNDING = 0,
  HALF_ROUND_UP = 1,
  ROUND_DOWN = 2,
  ROUND_UP = 3,
  BANKERS_ROUNDING = 4
}

export const amountRoundingTypeOptions = [
  {
    label: 'No Rounding',
    value: AMOUNT_ROUNDING_TYPE.NO_ROUNDING
  },
  {
    label: 'Half Round Up',
    value: AMOUNT_ROUNDING_TYPE.HALF_ROUND_UP,
    subText:
      '* Rounded to the nearest integer, and rounded up if exactly halfway between two integers'
  },
  {
    label: 'Round Down',
    value: AMOUNT_ROUNDING_TYPE.ROUND_DOWN,
    subText: '* Floor Rounding - round down to the nearest integer'
  },
  {
    label: 'Round Up',
    value: AMOUNT_ROUNDING_TYPE.ROUND_UP,
    subText: '* Ceiling Rounding - round up to the nearest integer'
  },
  {
    label: "Banker's Rounding",
    value: AMOUNT_ROUNDING_TYPE.BANKERS_ROUNDING,
    subText: '* Rounded to the nearest even integer '
  }
]
