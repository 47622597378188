import { CustomerDetails } from 'state/billing/billing.types'

export interface ICreditNote {
  creditNoteId: string
  creditNoteNumber: string
  customer: CustomerDetails
  date: number
  createdAgainst: string
  createdAgainstId: string
  amount: number
  settleAgainst: {
    billId: string
    billNumber: string
    hasLineItems: boolean
  }[]
  status: CreditNoteStatusEnum
  haveLineItem: boolean
  haveAgainstBill: boolean
  remainingAmount: number
}

export enum CreditNoteStatusEnum {
  PENDING = 0,
  SETTLED = 1,
  DELETED_INVALID = 2,
  PARTIALLY_SETTLED = 3
}

export const CreditNoteEnumMapper = {
  [CreditNoteStatusEnum.PENDING]: 'Pending',
  [CreditNoteStatusEnum.SETTLED]: 'Settled',
  [CreditNoteStatusEnum.DELETED_INVALID]: 'Deleted/Invalid',
  [CreditNoteStatusEnum.PARTIALLY_SETTLED]: 'Partially Settled'
}

export interface ICreditNoteState {
  creditNoteList: Array<ICreditNote> | null
  loaders: {
    isFetchingCreditNote: boolean
  }
  searchString: string
  paginationDetails: CreditNoteListPagination
  cnSortBy: {
    type: SORT_BY
    isAsc: boolean
  }
  creditNoteHtmlContent: Array<string>
}

export interface CreditNoteListPagination {
  page: number
  perPage: number
  totalItems: number
  totalPages: number
}

export enum SORT_BY {
  DATE = 'Date',
  CN_NUMBER = 'CnNumber',
  AMOUNT = 'Amount',
  REMAINING_AMOUNT = 'RemainingAmount',
  NONE = ''
}

export interface FetchCreditNotePayload {
  page: number
  perPage: number
  sortDetails: {
    sortBy?: SORT_BY
    isAsc?: boolean
  }
  searchString?: string
}
