import { IBillingState } from './billing.types'

export const BillingState: IBillingState = {
  loaders: {
    // showPreviewDrawer: false,
    showAddedBillsDrawers: false,
    addingItem: false,
    deletingBills: false,
    deletingItems: false,
    savePurchaseBill: false,
    fetchPurchaseBill: false,
    uploadPurchaseBillFile: false,
    deletingItemFromInventory: false,
    uploadingInventory: false
  },
  drawers: {
    showAddExpenseItemDrawer: false,
    showAddItemDrawer: false,
    showPurchaseBillDrawer: false,
    showFeedbackDrawer: false,
    showAddInvoiceItemDrawer: false,
    showAddPurchaseItemDrawer: false,
    showEditMerchantProfileDrawer: false,
    showAddInventoryItemDrawer: false,
    showDiscountDrawer: false,
    showChargeDrawer: false,
    showAddExpenseAccountDrawer: false,
    showPaymentBulkUploadTemplate: false,
    showPaymentBulkUploadStatus: false,
    showPaymentBulkUploadFileLogDrawer: false
  },
  flags: {
    isItemDeletedSuccessfullyFromInventory: undefined
  },
  // items: [],
  bills: [],
  resetPreviousItemList: false,
  printBillTemplate: '',
  resetBillContext: false,
  resetSelectedRowRef: {
    catalogTable: null
  },
  purchaseBillSettings: null,
  selectedBill: null,
  isUpdatedBillData: false,
  invoiceItems: [],
  editInvoiceItems: [],
  invoiceItemsHistory: [],
  purchaseItems: [],
  purchaseAccount: [],
  purchaseAssetAccount: [],
  expenseTransaction: [],

  inventory: {
    units: [],
    inventoryItems: []
  },
  InventoryTransactionHistory: [],
  purchaseTransactionHistory: [],
  inventoryApiCallAndResponse: {
    isApiCall: false,
    isAllDataIsFetched: false,
    isApiError: false
  },
  discounts: [],
  charges: [],
  bookingApiLoader: {
    addExpenseAccount: false,
    addExpense: false,
    addPurchase: false
  },
  expenseAccounts: [
    {
      business_id: '',
      account_id: '',
      name: '',
      balance: 0,
      reference_id: '',
      type: ''
    }
  ],
  failedBulkUploadData: {
    failedData: [],
    status: false
  },
  bulkUploadFile: [],
  bulkUploadFileLog: [],
  bulkUploadFileRequestId: '',
  totalCountOfBulkPurchaseItems: 0
}
