import { Action } from 'infra/types'
import { ICreateNewListState } from './create-new-list.types'
import { createNewListDrawerActions } from './create-new-list.actions'

const CREATE_NEW_LIST_STATE: ICreateNewListState = {
  newListName: '',
  drawers: {
    showCreateNewListDrawer: false,
    showListCustomerForOrderSetting: false
  }
}

export const CreateNewListReducer = (
  state: ICreateNewListState = CREATE_NEW_LIST_STATE,
  action: Action<any>
): ICreateNewListState => {
  switch (action.type) {
    case createNewListDrawerActions.SET_CREATE_NEW_LIST_DRAWER: {
      return {
        ...state,
        newListName: action.payload.newListName || state.newListName,
        updateListProps: undefined,
        drawers: {
          ...state.drawers,
          showCreateNewListDrawer: action.payload.drawerStatus
        }
      }
    }

    case createNewListDrawerActions.RESET_CREATE_NEW_LIST_DRAWER: {
      return {
        ...state,
        newListName: '',
        updateListProps: undefined,
        drawers: {
          ...state.drawers,
          showCreateNewListDrawer: false,
          showListCustomerForOrderSetting: false
        }
      }
    }
    case createNewListDrawerActions.SET_UPDATE_LIST_DRAWER: {
      return {
        ...state,
        updateListProps: {
          existingUsageType: action.payload.existingUsageType,
          existingListName: action.payload.existingListName,
          existingListType: action.payload.existingListType,
          isClearAndAdd: action?.payload?.isClearAndAdd || false
        },
        drawers: {
          ...state.drawers,
          showCreateNewListDrawer: action.payload.drawerStatus
        }
      }
    }

    case createNewListDrawerActions.SHOW_LIST_CUSTOMER_FOR_ORDER_SETTING: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showListCustomerForOrderSetting: action.payload.isShowCustomerListForOrderSetting
        }
      }
    }
    default: {
      return state
    }
  }
}
