import React, { useEffect } from 'react'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { useSelector } from 'react-redux'
import { IAppState } from 'infra/AppState'
import { IAuthState } from 'state/auth/auth.types'

// Auth
import authTranslationAFH from './locales/afh/auth.json'
import authTranslationBN from './locales/bn/auth.json'
import authTranslationEN from './locales/en/auth.json'
import authTranslationGU from './locales/gu/auth.json'
import authTranslationHI from './locales/hi/auth.json'
import authTranslationKN from './locales/kn/auth.json'
import authTranslationML from './locales/ml/auth.json'
import authTranslationMR from './locales/mr/auth.json'
import authTranslationPA from './locales/pa/auth.json'
import authTranslationTA from './locales/ta/auth.json'
import authTranslationTE from './locales/te/auth.json'

// Dashboard Pages
import dashboardTranslationAFH from './locales/afh/dashboard.json'
import dashboardTranslationBN from './locales/bn/dashboard.json'
import dashboardTranslationEN from './locales/en/dashboard.json'
import dashboardTranslationGU from './locales/gu/dashboard.json'
import dashboardTranslationHI from './locales/hi/dashboard.json'
import dashboardTranslationKN from './locales/kn/dashboard.json'
import dashboardTranslationML from './locales/ml/dashboard.json'
import dashboardTranslationMR from './locales/mr/dashboard.json'
import dashboardTranslationPA from './locales/pa/dashboard.json'
import dashboardTranslationTA from './locales/ta/dashboard.json'
import dashboardTranslationTE from './locales/te/dashboard.json'

// Transactions
import transactionsTranslationAFH from './locales/afh/transactions.json'
import transactionsTranslationBN from './locales/bn/transactions.json'
import transactionsTranslationEN from './locales/en/transactions.json'
import transactionsTranslationGU from './locales/gu/transactions.json'
import transactionsTranslationHI from './locales/hi/transactions.json'
import transactionsTranslationKN from './locales/kn/transactions.json'
import transactionsTranslationML from './locales/ml/transactions.json'
import transactionsTranslationMR from './locales/mr/transactions.json'
import transactionsTranslationPA from './locales/pa/transactions.json'
import transactionsTranslationTA from './locales/ta/transactions.json'
import transactionsTranslationTE from './locales/te/transactions.json'

// Migrations
import migrationTranslationAFH from './locales/afh/migration.json'
import migrationTranslationBN from './locales/bn/migration.json'
import migrationTranslationEN from './locales/en/migration.json'
import migrationTranslationGU from './locales/gu/migration.json'
import migrationTranslationHI from './locales/hi/migration.json'
import migrationTranslationKN from './locales/kn/migration.json'
import migrationTranslationML from './locales/ml/migration.json'
import migrationTranslationMR from './locales/mr/migration.json'
import migrationTranslationPA from './locales/pa/migration.json'
import migrationTranslationTA from './locales/ta/migration.json'
import migrationTranslationTE from './locales/te/migration.json'

// Order QR
import orderQrTranslationEN from './locales/en/OrderQRR.json'
import orderQrTranslationAFH from './locales/afh/OrderQR.json'
import orderQrTranslationBN from './locales/bn/OrderQR.json'
import orderQrTranslationGU from './locales/gu/OrderQR.json'
import orderQrTranslationHI from './locales/hi/OrderQR.json'
import orderQrTranslationKN from './locales/kn/OrderQR.json'
import orderQrTranslationML from './locales/ml/OrderQR.json'
import orderQrTranslationMR from './locales/mr/OrderQR.json'
import orderQrTranslationPA from './locales/pa/OrderQR.json'
import orderQrTranslationTA from './locales/ta/OrderQR.json'
import orderQrTranslationTE from './locales/te/OrderQR.json'

//okcoins
import OKCoinsTranslationAFH from './locales/afh/okcoins.json'
import OKCoinsTranslationBN from './locales/bn/okcoins.json'
import OKCoinsTranslationEN from './locales/en/okcoins.json'
import OKCoinsTranslationGU from './locales/gu/okcoins.json'
import OKCoinsTranslationHI from './locales/hi/okcoins.json'
import OKCoinsTranslationKN from './locales/kn/okcoins.json'
import OKCoinsTranslationML from './locales/ml/okcoins.json'
import OKCoinsTranslationMR from './locales/mr/okcoins.json'
import OKCoinsTranslationPA from './locales/pa/okcoins.json'
import OKCoinsTranslationTA from './locales/ta/okcoins.json'
import OKCoinsTranslationTE from './locales/te/okcoins.json'

const langOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'cookie'],

  // // keys or params to lookup language from
  // lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  // lookupSessionStorage: 'i18nextLng',
  // lookupFromPathIndex: 0,
  // lookupFromSubdomainIndex: 0,

  // // cache user language on
  caches: ['localStorage', 'cookie']
}

const resources = {
  // namespaces
  afh: {
    auth: authTranslationAFH,
    dashboard: dashboardTranslationAFH,
    transactions: transactionsTranslationAFH,
    migration: migrationTranslationAFH,
    orderQr: orderQrTranslationAFH,
    okcoins: OKCoinsTranslationAFH
  },
  bn: {
    auth: authTranslationBN,
    dashboard: dashboardTranslationBN,
    transactions: transactionsTranslationBN,
    migration: migrationTranslationBN,
    orderQr: orderQrTranslationBN,
    okcoins: OKCoinsTranslationBN
  },
  en: {
    auth: authTranslationEN,
    dashboard: dashboardTranslationEN,
    transactions: transactionsTranslationEN,
    migration: migrationTranslationEN,
    orderQr: orderQrTranslationEN,
    okcoins: OKCoinsTranslationEN
  },
  gu: {
    auth: authTranslationGU,
    dashboard: dashboardTranslationGU,
    transactions: transactionsTranslationGU,
    migration: migrationTranslationGU,
    orderQr: orderQrTranslationGU,
    okcoins: OKCoinsTranslationGU
  },
  hi: {
    auth: authTranslationHI,
    dashboard: dashboardTranslationHI,
    transactions: transactionsTranslationHI,
    migration: migrationTranslationHI,
    orderQr: orderQrTranslationHI,
    okcoins: OKCoinsTranslationHI
  },
  kn: {
    auth: authTranslationKN,
    dashboard: dashboardTranslationKN,
    transactions: transactionsTranslationKN,
    migration: migrationTranslationKN,
    orderQr: orderQrTranslationKN,
    okcoins: OKCoinsTranslationKN
  },
  ml: {
    auth: authTranslationML,
    dashboard: dashboardTranslationML,
    transactions: transactionsTranslationML,
    migration: migrationTranslationML,
    orderQr: orderQrTranslationML,
    okcoins: OKCoinsTranslationML
  },
  mr: {
    auth: authTranslationMR,
    dashboard: dashboardTranslationMR,
    transactions: transactionsTranslationMR,
    migration: migrationTranslationMR,
    orderQr: orderQrTranslationMR,
    okcoins: OKCoinsTranslationMR
  },
  pa: {
    auth: authTranslationPA,
    dashboard: dashboardTranslationPA,
    transactions: transactionsTranslationPA,
    migration: migrationTranslationPA,
    orderQr: orderQrTranslationPA,
    okcoins: OKCoinsTranslationPA
  },
  ta: {
    auth: authTranslationTA,
    dashboard: dashboardTranslationTA,
    transactions: transactionsTranslationTA,
    migration: migrationTranslationTA,
    orderQr: orderQrTranslationTA,
    okcoins: OKCoinsTranslationTA
  },
  te: {
    auth: authTranslationTE,
    dashboard: dashboardTranslationTE,
    transactions: transactionsTranslationTE,
    migration: migrationTranslationTE,
    orderQr: orderQrTranslationTE,
    okcoins: OKCoinsTranslationTE
  }
}

i18next.use(LanguageDetector).init({
  detection: langOptions,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  },
  react: {
    // Must be false until Suspense is supported on the server side
    useSuspense: false,
    wait: true
  },
  debug: process.env.NODE_ENV === 'development' && __BROWSER__,
  fallbackLng: 'en',
  fallbackNS: ['auth', 'dashboard'],
  // This option is necessary to tell i18next to try loading missing resources via
  // i18next-xhr-backend, otherwise no calls will be made if resources are defined.
  partialBundledLanguages: true,
  resources,
  parseMissingKeyHandler: (missing: any) => {
    if (process.env.NODE_ENV === 'development' && __BROWSER__) {
      console.warn('MISSING TRANSLATION:', missing)
    }
    return missing
  }
})

i18next.languages = ['en', 'hi']

const I18N: React.FC<any> = ({ children }) => {
  const authData: IAuthState = useSelector((app: IAppState) => app.Auth)

  useEffect(() => {
    if (authData.userData.language) {
      i18next.changeLanguage(authData.userData.language.key)
    }
  }, [authData.userData.language])
  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
}

export default React.memo(I18N)

export const intlInstance = {
  translate: () => {
    return i18next.t.bind(i18next)
  }
}
