import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IAppState } from 'infra/AppState'
import { Notification } from './Notification'
import { INotification } from './Notification.types'

export const NotificationList: React.FC = () => {
  const notifications = useSelector((appState: IAppState) => appState.Notification.notifications)
  const { t } = useTranslation()
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        margin: '65px 0',
        zIndex: 2000,
        left: '50%',
        transform: 'translate(-50%)'
      }}
    >
      {notifications.map((notification: INotification) => {
        return <Notification {...notification} key={notification.id} translate={t} />
      })}
    </div>
  )
}
