import { IPaymentDetail } from '../blindpay.types'
export const paymentDetailsFormatter = (data: any): IPaymentDetail => {
  return {
    amount: data.amount,
    senderMobile: data.sender_mobile,
    senderName: data.sender_name,
    receiverMobile: data.receiver_mobile,
    paidAt: Number(data.paid_at),
    expiryTime: Number(data.expiry_time),
    accountId: data.account_id,
    senderMerchantId: data.sender_merchant_id,
    linkStatus: data.link_status
  }
}
