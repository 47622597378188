import { FILTER_OPTION, IBillReturnItem } from 'services/Api/StaffLinkApiFolder/type'

export enum COLLECTIONS_LIST_TYPE {
  COLLECTION = 0,
  SUMMARY = 1
}

export enum BILL_STATUS {
  PENDING = 0,
  PAID = 1,
  PARTIALLY_PAID = 2,
  REMOVED = 3,
  HOLD = 4
}

export interface IChequeDetails {
  cheque_num?: string
  cheque_date?: number
}

export enum TRANSACTION_TYPE {
  NA = 0,
  CASH = 1,
  ONLINE = 2,
  CREDIT_DUES = 3,
  CHEQUE = 4,
  NEFT = 5,
  RETURN = 6,
  DAMAGED = 7,
  UPI = 8,
  WALLET = 9
}

export interface ITransactions {
  account_txn_id: string
  amount: string
  approved_by_merchant: boolean
  associated_account_id: string
  business_id: string
  collection_list_id: string
  id: string
  notes: string
  transaction_time: string
  txn_type: TRANSACTION_TYPE
}

export interface IApprovedTxn {
  amount: string
  id: string
  txn_type: TRANSACTION_TYPE
}

export interface IBill {
  bill: {
    account_id: string
    bill_id: string
    bill_number: string
    status: number
    total_amount: string
    transaction_id: string
    total_paid_amount: string
    latest_settlement_date: string
    type: number
    updated_at: string
    deleted_at: string
    bill_date: string
    bill_info_items?: IBillReturnItem[]
  }
  bill_cancel_params: null | {
    cancel_requested: boolean
    cancel_requested_at: string
    is_cancelled: boolean
    cancelled_at: string
    request_deleted: boolean
    request_deleted_at: string
    collection_list_id: string
  }
  redeliver: boolean
  bill_beats?: {
    beat_id: string
    name: string
    account_id: string
    bill_id: string
  }[]
}

export enum REPLACMENT_STATES {
  UNKNOWN = 0,
  NOT_APPROVED = 1,
  CREDIT_NOTE_ADDED = 2,
  AMOUNT_SETTLED = 3,
  REPLACEMENT_SENT = 4,
  OTHER = 5
}

export interface IReplacementsItem {
  name: string
  quantity: string
  notes?: string
  state: REPLACMENT_STATES
}

export interface ICatalogItem {
  id: string
  name: string
  quantity: number
}

export enum REPlACEMENT_RECORD_TYPES {
  PENDING = 0,
  REPLACE = 1,
  DELETE = 2
}

export interface IReplacements {
  id: string
  business_id: string
  account_id: string
  staff_id: string
  bill_id: string
  amount: string
  status: REPlACEMENT_RECORD_TYPES
  items: IReplacementsItem[]
  catalog_items: ICatalogItem[]
  created_at: string
  updated_at: string
}

export interface IBillV2 {
  bill: {
    address: string
    bill_id: string
    bill_number: string
    status: number
    total_amount: string
    transaction_id: string
    total_paid_amount: string
    type: number
    updated_at: string
    deleted_at: string
    bill_date: string
    customer_id: string
  }
  replacements: IReplacements[]
  transactions: ITransactions[]
}

export interface IApprovedTransactionsSummary {
  total_cash_or_neft_payments: string
  total_cash_payments: string
  total_cheque_payments: string
  total_damaged_payments: string
  total_neft_payments: string
  total_online_payments: string
  total_return_payments: string
  total_wallet_payments: string
}

export interface ISingleCollectionList {
  account_id: string
  approved_transactions_summary?: {
    [id: string]: IApprovedTransactionsSummary
  }
  aproved_transactions_item?: IApprovedTransactionsSummary
  address: string
  balance: string | number
  customer_mobile: string
  customer_name: string
  transactions: ITransactions[]
  bills?: IBill[]
  replacements: IReplacements[]
  approved_transactions: IApprovedTxn[]
}

export enum COLLECTION_LIST_SORT_TYPE {
  NAME = 'name',
  LATEST = 'latest',
  AMOUNT = 'amount'
}

export enum COLLECTION_LIST_FILTER_TYPE {
  TAGS = 'tags', // For backward compatability
  TAGS_OR = 'tagsOR',
  TAGS_AND = 'tagsAND'
}

export interface IFilters {
  sort_by?: COLLECTION_LIST_SORT_TYPE
  filter_by: {
    key: FILTER_OPTION
    values: string[]
  }[]
}

export interface IDueConfigRaw {
  assignment_date: string
  config: COLLECTION_LIST_TYPE
}

export interface IQuickSummary {
  id: string
  merchant_id: string
  due_amount: string
  unsettled_bills_count: string
  action_required_bills_count: string
  pending_actions: {
    action: number
    replacement_id: string
    transaction_id: string
    bill_id: string
  }[]
}

export interface IAutoAssignConfig {
  field: 'tags' | 'bill_date'
  value: string
  condition: 'in' | 'in_last'
  operator?: 'or' | 'and'
}

export interface ICollectionList {
  associated_account_ids: string[]
  business_id: string
  create_time: string
  id: string
  is_active: boolean
  name: string
  update_time: string
  url: string
  usage_type: COLLECTION_LIST_TAB
  type?: COLLECTIONS_LIST_TYPE
  filters?: IFilters[]
  due_config?: IDueConfigRaw
  summary?: IQuickSummary
  auto_assign_config?: IAutoAssignConfig[]
}

export interface ICollectionListSuccess {
  allCollectionList: ICollectionList[]
  summaryCollectionList: ICollectionList[]
  collectionList: ICollectionList[]
}

export interface ICombinedCollectionListSuccess {
  combinedCollectionList: ICollectionList[]
}

export interface ICustomDate {
  day: number
  month: number
  year: number
}

export interface IDueConfig {
  type: COLLECTION_LIST_TYPE
  // startTime: ICustomDate | null
  // endTime: ICustomDate | null
}

export const enum CL_VERSIONS {
  PREVIOUS_ASSIGNMENT = 0,
  PAST_ASSIGNMENT = 1,
  CURRENT_ASSIGNMENT = 2
}

export interface IPendingDeliveryAttempts {
  id: string
  bill_id: string
  business_id: string
  staff_uid: string
  list_id: string
  created_at: string
  is_approved: boolean
  action_pending: string
  status: string
  updated_at: string
  approved_at: string
}

export interface ISelectedCollectionList {
  holdedAccountDetails: ISingleCollectionList[]
  accountDetails: ISingleCollectionList[]
  accountIds: string[]
  dueConfig: IDueConfig
  filters: IFilters
  totalAmountDue: number
  id: string
  name: string
  url: string
  billDetails: IBillV2[]
  version: CL_VERSIONS
  pendingDeliveryAttempts: IPendingDeliveryAttempts[]
  historyDueConfigs: {
    previous_config: IDueConfigRaw
    past_config?: IDueConfigRaw
    current_config?: IDueConfigRaw
  }
  autoAssignConfig?: {
    tags: string[]
    tagsOperator: 'or' | 'and'
    startDate: number
    endDate: number
  }
}

export interface IDateSelect {
  from: ICustomDate | null
  to: ICustomDate | null
}

export enum COLLECTION_LIST_TYPE {
  UNKNOWN = '0',
  BALANCE_DUE = '1',
  CREDIT_DUE = '2',
  BALANCE_DUE_FILTER = '3',
  INVOICE_LEVEL_DUE = '4',
  INVOICE_LEVEL_DUE_V2 = '5',
  AUTO_TAG_LIST = '6',
  CUSTOMER_LIST = '7'
}

export interface IUpdateCollectionListDueConfigAction {
  listType: COLLECTION_LIST_TYPE
  typeMetaData?: IDateSelect
}

export interface ILoaders {
  collectionList?: boolean
  singleCollectionList?: boolean
  singleCollectionListId?: string | null
  selectCollectionList?: boolean
  collectionListSummary?: boolean
  saveCollectionListDueConfig?: boolean
  fetchDocumentsList?: boolean
  collectionListFromDocument?: boolean
  acceptingCollectionTxn?: boolean
  shiftingAccountToOtherCollectionList?: boolean
  creatingTxnForAccountInCollectionList?: boolean
  approveCancelInvoice?: boolean
  fetchTemplatesList?: boolean
  createTemplate?: boolean
  updateDefaultTemplate?: boolean
  txnAdvanceAndRemoveAction?: boolean
  recordReplacement?: boolean
  removeTxnPopup?: boolean
  savingFilters?: boolean
  removeCollectionList?: boolean
  bulkUploadCustomers?: boolean
  allPendingActions?: boolean
}

export interface IHelpers {
  isChangeInAccountSelection?: boolean
}

export interface ICollectionListNameAndUsageTypeChangeAction {
  collectionListId: string
  newName?: string
  usageType?: number
  typeMetaData?: any
  listType?: any
}

export interface ITxnAcceptOrRemove {
  merchantId: string
  txnId: string
  amount: string
}

export interface IDrawers {
  collectionListSelector?: boolean
  collectionListDetails?: boolean
  filters?: boolean
  collectionListDueConfig?: boolean
  collectionListShiftAccount?: boolean
  collectionListAddTxn?: boolean
  collectionListSummary?: boolean
  successDrawer?: boolean
  documentList?: boolean
  documentToCollectionList?: boolean
  documentListConfiguration?: boolean
  templateDetails?: boolean
  txnAdvanceAndRemovePopup?: boolean
  recordReplacement?: boolean
  removeTxnPopup?: boolean
  removeCollectionListPopup?: boolean
  viewAllReplacement?: boolean
  addToStaffLinkActionTypeDrawer?: boolean
  autoTagList?: boolean
  addedStaffDrawer?: boolean
  assignStaffDrawer?: boolean
  isOnboarding?: boolean
  actionPendingDrawer?: boolean
}

export interface IFilterCollectionListOptions {
  byTagORIds?: string[]
  byTagANDIds?: string[]
}

export interface ISortCollectionListOptions {
  sortBy?: COLLECTION_LIST_SORT_TYPE
}

export interface ISetSortAndFilterCollectionAction {
  filter?: IFilterCollectionListOptions
  sort?: ISortCollectionListOptions
}

export interface IDocumentTractions {
  load_failed: number
  sync_failed: number
  sync_progress: number
  synced: number
  total: number
  duplicates: number
}

export interface IFileMeta {
  route_meta: {
    is_available: boolean
    routes?: {
      name: string
      total_accounts: number
      is_synced: boolean
      id: string
      bills: number
    }[]
  }
}

export enum FILE_KIND {
  GST_DATA = 'GST_DATA',
  INVOICES = 'INVOICES',
  INVOICE_LINE_ITEMS = 'INVOICE_LINE_ITEMS'
}

export interface IDocumentsType {
  UpdatedAt: string
  createdAt: string
  document_id: string
  document_status: number
  error_meta: object
  file_name: string
  transactions: IDocumentTractions
  file_meta?: IFileMeta
  file_kind?: FILE_KIND
}

export interface ICreateNewList {
  listName: string
  creating: boolean
  accountIds: string[]
  dueConfig: IDueConfig
  sortBy?: COLLECTION_LIST_SORT_TYPE
  filterBy?: {
    key: COLLECTION_LIST_FILTER_TYPE
    values: string[]
  }[]
  selectedBills: {
    bill_id: string
    customer_id: string
  }[]
}

export interface ISaveFiltersCreateNewList {
  sortBy: COLLECTION_LIST_SORT_TYPE
  filterBy: {
    key: COLLECTION_LIST_FILTER_TYPE
    values: string[]
  }[]
}

export interface IPagination {
  currentPage: number
  pageLimit: number
  isAllDataLoaded: boolean
}

export interface IPaginationState {
  documents?: IPagination
}

export interface IListTransaction {
  transactionId: string
  transactionType: number
  transactionAmount: number
  transactionTime: string | number
}

export interface ICollectionData {
  accountId: string
  buyerName: string
  accountDue: number
  accountTransactionId: string
  transaction: Array<IListTransaction>
  balance: number
}

export interface ICollectionSummary {
  totalCashCollections: number
  totalCashCollectionsAmount: number
  totalCashCollectionsAccount: number
  totalOnlineCollections: number
  totalOnlineCollectionsAmount: number
  totalOnlineCollectionsAccount: number
  totalChequeCollections: number
  totalChequeCollectionsAmount: number
  totalChequeCollectionsAccount: number
  totalNeftCollections: number
  totalNeftCollectionsAmount: number
  totalNeftCollectionsAccount: number
  totalDamagedCollections: number
  totalDamagedCollectionsAmount: number
  totalDamagedCollectionsAccount: number
  totalAccounts: number
}

export enum LIST_TRANSACTION_TYPE {
  NA = 0,
  CASH = 1,
  ONLINE = 2,
  CREDIT_DUES = 3,
  CHEQUE = 4,
  NEFT = 5,
  RETURN = 6,
  DAMAGED = 7,
  UPI = 8,
  WALLET = 9
}

export interface ICollectionListFromDocument {
  isCreateNew: boolean
  collectionListId?: string
  isReplaceOldItemsinCl?: boolean
}

export interface IMultipleCollectionListFromDocument {
  routes: string[]
  action: number
}

export interface ICurrentDocumentSelected {
  documentId: string
  name: string
  routes?: {
    name: string
    total_accounts: number
    bills: number
    is_synced: boolean
  }[]
  isOnboarding?: boolean
}

export interface ICurrentTemplate {
  id: string
  name: string
  document_type: DOCUMENT_TYPE
}

export interface IOverallSummary {
  total_credits: number
  total_payments: number
  net_balance_due: number
  total_damages: number
  accounts: string[]
}

export interface ISelectedSettingsForAddTxn {
  accountId: string
  billId?: string
  remainingAmount: string | number
  billDate: string
}

export interface ISummaryStats {
  id: number
  name: number
  net_balance_due: number
  number_of_accounts: number
  total_cash_or_neft_payments: number
  total_cheque_payments: number
  total_credits: number
  total_online_payments: number
  total_payments: number
  total_return_payments: number
  total_damaged_payments: number
  total_cash_payments: number
  total_neft_payments: number
  transactions: string[]
}

export interface ICollectionListSummaryData {
  overallSummary: IOverallSummary
  summaryStats: ISummaryStats[]
}

export interface IOverallSummaryInvoice {
  total_dues: string
  total_payments: string
  balance_due: string
  total_damages: string
  total_cash_payments: string
  total_cheque_payments: string
  total_invoices: string
  total_neft_payments: string
  total_online_payments: string
  total_returns: string
  total_wallet_payments: string
}

export interface IInvoiceSummary {
  number: string
  date: string
  amount: string
  paid_amount: string
  due_amount: string
  bill_status: BILL_STATUS
  summary_stats: {
    total_cash_payments: string
    total_neft_payments: string
    total_cheque_payments: string
    total_return_payments: string
    total_damaged_payments: string
    total_online_payments: string
    total_cash_or_neft_payments: string
    total_wallet_payments: string
  }
}

export interface ISummaryStatsInvoice {
  id: string
  name: string
  customer_code: string
  invoices: IInvoiceSummary[]
  usable_wallet_balance: string
}

export interface ICollectionListInvoiceSummaryData {
  overallSummary: IOverallSummaryInvoice
  summaryStats: ISummaryStatsInvoice[]
}

export interface IAccountsDocument {
  documentId: string
  document_status?: number
}

export interface IBIllListData {
  billId: string
  billNumber: string
  billDate: string
  customerId: string
  billDueAmount: number
  totalAmount: number
  customerName: string
  customerAddress?: string
}

export interface IBillsList {
  data: Array<IBIllListData>
  pagination: number
}

export enum COLLECTION_LIST_TYPE_TAB {
  ACCOUNT = 0,
  INVOICE = 1
}

export enum COLLECTION_LIST_TAB {
  COLLECTION_LIST = 0,
  SUPPLY_LIST = 1
}

export type ISelectedInvoiceToShift = Pick<IBillV2['bill'], 'bill_id' | 'customer_id'>

export interface IStaffMember {
  id: string
  businessId: string
  name: string
  mobile: string
  isActive: boolean
  uid: string
}
export interface ICollectionListState {
  listSummary?: ICollectionListSummaryData | undefined
  listSummaryInvoice?: ICollectionListInvoiceSummaryData | undefined

  // CollectionList
  allCollectionList: ICollectionList[]
  summaryCollectionList: ICollectionList[]
  collectionList: ICollectionList[]
  selectedCollectionList: ISelectedCollectionList | null
  filtersCollectionList: IFilterCollectionListOptions
  sortCollectionList: ISortCollectionListOptions
  createNewList?: ICreateNewList | null
  selectedIdsToShiftAccount: string[]
  selectedInvoicesToShift: ISelectedInvoiceToShift[]
  selectedSettingsForAddTxn: ISelectedSettingsForAddTxn | null
  selectedDataForTxnAdvanceAndRemove: ITxnAcceptOrRemove | null
  selectedReplacementRecords: IReplacements | null
  selectedTxnToRemove: ITransactions | null

  // Documents
  documentsList: IDocumentsType[]
  currentDocumentSelected: ICurrentDocumentSelected | null
  templatesList: any[]
  globalTemplatesList: any[]
  currentTemplates: ICurrentTemplate[]
  accountsDocument: IAccountsDocument | null
  // Common
  loaders: ILoaders
  drawers: IDrawers
  helpers: IHelpers
  pagination: IPaginationState | null
  collectionListSelected: Array<string>
  collectionListBills: IBillsList | undefined
  selectedCollectionListTypeTab: COLLECTION_LIST_TYPE_TAB
  selectedListTab: COLLECTION_LIST_TAB
  combinedCollectionList: ICollectionList[]
  staffMemberList: IStaffMember[] | null
  allPendingActions: IPendingAction[] | null
}

export type ActionWithPagination<T> = {
  type: string
  payload: T
  pagination: IPagination
  error?: {}
}

export interface ITxnFiltersTimeStamps {
  startTime: number
  endTime: number
}

export interface IFetchCollectionListSummary {
  collectionListId: string
  startTime?: number
  endTime?: number
  applyTxnFilter?: boolean
  txnFiltersTimeStamps?: ITxnFiltersTimeStamps
}

// TODO: Use this wherever document_type is used
export enum DOCUMENT_TYPE {
  UNKNOWN_FILE_TYPE = 0,
  XML = 1,
  EXCEL = 2,
  CSV = 3
}

export interface IRemoveBill {
  billIds: string[]
  accountIds: string[]
}

export interface ICreateNewCollectionList {
  listType: COLLECTION_LIST_TYPE
  typeMetaData?: IDateSelect
  collectionListTypeTab?: COLLECTION_LIST_TYPE_TAB
}

export interface IFetchSingleCollectionListAction {
  collectionListId: string
  type?: string
  version?: string
}

export interface IFetchClV2Action {
  collectionListId: string
  version?: CL_VERSIONS
}

export interface IPendingAction extends ISingleCollectionList {
  collection_list_id?: string
}
