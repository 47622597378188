export interface IDocument {
  documentId: string
  document_status?: number
}

export interface ILoaders {
  bulkUploadItems?: boolean
}

export interface IInventoryState {
  loaders: ILoaders
  document: IDocument | null
  modals: {
    downloadPurchaseSummary: boolean
    downloadInventoryHisory: boolean
    downloadExpenseItems: boolean
    summaryFilterDrawer: boolean
  }
}

export interface InventoryItemModalStatus {
  modalName: InventoryItemModalEnum
  status: boolean
}

export enum InventoryItemModalEnum {
  downloadPurchaseSummary = 'downloadPurchaseSummary',
  downloadInventoryHisory = 'downloadInventoryHisory',
  downloadExpenseItems = 'downloadExpenseItems',
  summaryFilterDrawer = 'summaryFilterDrawer'
}

export enum InventoryType {
  purchase = 'purchase',
  NA = ''
}

export type DownloadPurchaseSummaryType = {
  itemId: string
  limit: number
  offset: number
  type: string
  fileName: string
  startTime?: number
  endTime?: number
}

export type PurchaseItemsResponseType = {
  amount: number
  bill_number: string
  code: string
  quantity: number
  timestamp: number
  transaction_id: string
  type: InventoryType
  deleted: boolean
  transaction_date: number
  notes: string
}

export type ExpenseItemsResponseType = {
  business_id: string
  expense_account_id: string
  payment_account_id: string
  payment_account_name: string
  expense_account_name: string
  amount: number
  type: string
  particulars: string
  create_time: number
}

export type DownloadExpenseSummaryType = {
  fileName: string
}
