import { Action } from 'infra/types'
import {
  CashByStaffFilter,
  DownloadBankWiseType,
  DownloadCashByStaffType,
  ModalStatus
} from 'state/summary/cashByStaff/cashByStaff.types'

export const cashByStaffActions = {
  SET_CASH_BY_STAFF_MODALS: 'pendingDue/setCashByStaffModals',
  DOWNLOAD_CASH_BY_STAFF_SUMMARY: 'pendingDue/DOWNLOAD_CASH_BY_STAFF_SUMMARY',
  SET_CASH_BY_STAFF_ACTIVE_FILTER: 'pendingDue/SET_CASH_BY_STAFF_ACTIVE_FILTER',
  DOWNLOAD_BANK_WISE_SUMMARY: 'pendingDue/DOWNLOAD_BANK_WISE_SUMMARY'
}

export const setCashByStaffModalsAction = (payload: ModalStatus): Action<ModalStatus> => ({
  type: cashByStaffActions.SET_CASH_BY_STAFF_MODALS,
  payload
})

export const downloadCashByStaffSummaryAction = (
  payload: DownloadCashByStaffType
): Action<DownloadCashByStaffType> => ({
  type: cashByStaffActions.DOWNLOAD_CASH_BY_STAFF_SUMMARY,
  payload
})

export const setCashByStaffFilterAction = (
  payload: CashByStaffFilter
): Action<CashByStaffFilter> => ({
  type: cashByStaffActions.SET_CASH_BY_STAFF_ACTIVE_FILTER,
  payload
})

export const downloadBankWiseSummaryAction = (
  payload: DownloadBankWiseType
): Action<DownloadBankWiseType> => ({
  type: cashByStaffActions.DOWNLOAD_BANK_WISE_SUMMARY,
  payload
})
