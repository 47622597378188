import { BillsType } from 'state/billing/billing.types'
import { ICreditNote } from 'state/new-summary/creditNote/creditNote.types'
import { BILL_DEFAULT_NUMBER, BILL_NUMBER_PREFIX, GST_NUMBER_LENGTH } from '../hooks/constant'

function validateGST(inputGST: string): boolean {
  const gst = inputGST.toUpperCase()
  // Regular expression to match GSTIN format
  const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/

  // Check if the provided GSTIN matches the regular expression
  return gst.length === GST_NUMBER_LENGTH && gstRegex.test(gst)
}

function validateMobileNumber(mobileNumber: string): boolean {
  // Regular expression to match valid mobile number formats
  const mobileNumberRegex = /^[5-9][0-9]{9}$/

  // Check if the provided mobile number matches the regular expression
  return mobileNumberRegex.test(mobileNumber)
}

function validateHSNCode(hsnCode: string): boolean {
  try {
    const allowedLengths = [2, 4, 6, 8]
    return !allowedLengths.includes(hsnCode.length)
  } catch (error) {
    return false
  }
}

const getLargestInvNumber = (bills: BillsType[], a?: number) => {
  const invoiceLength = bills.length
    ? getInvoiceNumberLength(bills[0].billNumber)
    : BILL_DEFAULT_NUMBER.length
  const returnData = bills.length ? Number(getNumber(bills[0].billNumber)) + (a || 1) : 1 // Assuming 1 as default if no bills
  const paddedNumber = String(returnData).padStart(invoiceLength, '0') // Pad with leading zeros to ensure length 4
  return paddedNumber
}

export const getLargestCNNumber = (creditNotes: ICreditNote[]) => {
  const invoiceLength = creditNotes.length
    ? getInvoiceNumberLength(creditNotes[0].creditNoteNumber)
    : BILL_DEFAULT_NUMBER.length
  const returnData = creditNotes.length ? Number(getNumber(creditNotes[0].creditNoteNumber)) + 1 : 1 // Assuming 1 as default if no bills
  const paddedNumber = String(returnData).padStart(invoiceLength, '0') // Pad with leading zeros to ensure length 4
  return paddedNumber
}

const getInvoiceNumberLength = (string_value: string) => {
  const numberString = string_value.match(/\d+/)
  return numberString ? numberString[0].length : BILL_DEFAULT_NUMBER.length
}

export const getInvoicePrefix = (bills: BillsType[]) => {
  const returnData = bills.length ? getString(bills[0].billNumber) : BILL_NUMBER_PREFIX
  return returnData
}

const getNumber = (string_value: string) => {
  const numberString = string_value.match(/\d+/)
  return numberString ? Number(numberString[0]) : BILL_DEFAULT_NUMBER
}

const getString = (string_value: string) => {
  const stringWithoutNumbers = string_value.replace(/\d+/g, '')
  return stringWithoutNumbers
}

export const getIsIndependentCreditNote = (billId?: string) => {
  return billId === 'independent'
}

export { validateGST, validateMobileNumber, validateHSNCode, getLargestInvNumber }
