import { takeLatest, debounce, takeEvery, race, call, take } from 'redux-saga/effects'
import { NavigationActions } from 'state/navigation/navigation.actions'
import { ExpActions } from 'state/experiment/experiment.action'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { takeCustomLatest } from 'state/common/common.effects'
import { DashboardActions } from './dashboard.actions'
import dashboardAnalytics from './dashboard.analytics'

import {
  setMerchantName,
  fetchAndSetLocalData,
  businessNameOptOut,
  fetchMerchantProfile,
  patchMerchantProfile,
  fetchPhonebookContacts,
  patchMerchantAddressWithCurrentLocation,
  getCustomerDataEffect,
  fetchHelpSections,
  fetchEntities,
  patchEntity,
  deleteEntity,
  addEntity,
  contactUsOnWhatsApp,
  handleInitialLeadgenEffect,
  fetchFilteredByTagsCustomers,
  fetchSearchByValueAccounts,
  createBusinessEffect,
  switchBusinessAccountEffect,
  getBusinessIdsEffect,
  selectBusinessAccountEffect,
  fetchBusinessCategories,
  fetchBusinessTypes,
  checkForPaidBannerSoon,
  postPaidBannerSoonFeedback,
  closePaidBannerSoonBanner,
  startBulkUploadCustomersStatusPollingEffect,
  bulkUploadCustomersEffect,
  addBusinessManagerEffect,
  fetchBusinessManagersEffect,
  deleteBusinessManagerEffect,
  fetchCustomerProfilesEffect,
  bulkAddAccountTagsEffect,
  bulkAddAccountBeatRouteEffect,
  fetchBusinessSettingsEffect
} from './dashboard.effects'

function* getCustomerDataSaga() {
  yield takeLatest(DashboardActions.GET_CUSTOMER_DATA, getCustomerDataEffect)
}

function* registerMerchantNameSaga() {
  yield takeLatest(DashboardActions.REGISTER_MERCHANT_NAME, setMerchantName)
}

function* fetchAndSetLocalDataSaga() {
  yield takeLatest(DashboardActions.FETCH_LOCAL_DATA, fetchAndSetLocalData)
}

function* businessNameOptOutSaga() {
  yield takeLatest(DashboardActions.BUSINESS_NAME_MODAL_OPTED_OUT, businessNameOptOut)
}

function* fetchMerchantProfileSaga() {
  yield takeLatest(DashboardActions.FETCH_MERCHANT_PROFILE, fetchMerchantProfile)
}

function* debouncedPatchMerchantProfileSaga() {
  yield debounce(1000, DashboardActions.EDIT_MERCHANT_PROFILE, patchMerchantProfile)
}

function* fetchEntitiesSaga() {
  yield takeEvery(DashboardActions.FETCH_ENTITY_LIST, fetchEntities)
}

function* debouncedPatchEntitySaga() {
  yield debounce(1000, DashboardActions.EDIT_ENTITY, patchEntity)
}

function* deleteEntitySaga() {
  yield takeLatest(DashboardActions.DELETE_ENITITY, deleteEntity)
}

function* addEntitySaga() {
  yield takeLatest(DashboardActions.ADD_ENTITY, addEntity)
}

function* fetchPhonebookContactsSaga() {
  yield takeLatest(DashboardActions.FETCH_PHONEBOOK_CONTACTS, fetchPhonebookContacts)
}

function* patchMerchantAddressWithCurrentLocationSaga() {
  yield takeLatest(
    DashboardActions.PATCH_MERCHANT_ADDRESS_WITH_CURRENT_LOCATION,
    patchMerchantAddressWithCurrentLocation
  )
}

function* dashboardAnalyticsSaga() {
  yield takeEvery(
    [...Object.values(DashboardActions), NavigationActions.ROUTE_CHANGE],
    dashboardAnalytics
  )
}

function* fetchHelpSectionsSaga() {
  yield takeLatest(DashboardActions.FETCH_HELP_SECTIONS, fetchHelpSections)
}

function* contactUsOnWhatsAppSaga() {
  yield takeLatest(DashboardActions.CONTACT_US_ON_WHATSAPP, contactUsOnWhatsApp)
}

function* handleInitialLeadgenSaga() {
  yield takeLatest(DashboardActions.HANDLE_INITIAL_LEADGEN, handleInitialLeadgenEffect)
}

function* fetchFilteredByTagsCustomersSaga() {
  yield takeLatest(DashboardActions.FETCH_FILTERED_CUSTOMER_LIST, fetchFilteredByTagsCustomers)
}

function* fetchSearchByValueAccountsSaga() {
  yield takeLatest(DashboardActions.FETCH_SEARCH_BY_INVOICE_DATA, fetchSearchByValueAccounts)
}

function* getBusinessIdsSaga() {
  yield takeLatest(DashboardActions.FETCH_BUSINESS_IDS, getBusinessIdsEffect)
}

function* fetchBusinessSettingsSaga() {
  yield takeLatest(DashboardActions.FETCH_BUSINESS_SETTINGS, fetchBusinessSettingsEffect)
}

function* createBusinessSaga() {
  yield takeLatest(DashboardActions.CREATE_BUSINESS, createBusinessEffect)
}

function* switchBusinessSaga() {
  yield takeLatest(DashboardActions.SWITCH_BUSINESS_ACCOUNT, switchBusinessAccountEffect)
}

function* selectBusinessSaga() {
  yield takeLatest(DashboardActions.SELECT_BUSINESS_ACCOUNT, selectBusinessAccountEffect)
}

function* fetchBusinessManagersSaga() {
  yield takeCustomLatest(DashboardActions.FETCH_BUSINESS_MANAGERS, fetchBusinessManagersEffect)
}

function* deleteBusinessManagerSaga() {
  yield takeCustomLatest(DashboardActions.DELETE_BUSINESS_MANAGER, deleteBusinessManagerEffect)
}

function* addBusinessManagerSaga() {
  yield takeCustomLatest(DashboardActions.ADD_BUSINESS_MANAGER, addBusinessManagerEffect)
}

function* fetchBusinessCategoriesSaga() {
  yield takeLatest(DashboardActions.FETCH_BUSINESS_CATEGORIES, fetchBusinessCategories)
}

function* fetchBusinessTypesSaga() {
  yield takeLatest(DashboardActions.FETCH_BUSINESS_TYPES, fetchBusinessTypes)
}

function* bulkUploadCustomersSaga() {
  yield takeLatest(DashboardActions.BULK_UPLOAD_ACCOUNTS, bulkUploadCustomersEffect)
}

function* startBulkUploadCustomersStatusPollingSaga() {
  while (true) {
    //@ts-ignore
    const action = yield take(DashboardActions.START_POLL_BULK_UPLOAD_ACCOUNTS_STATUS)
    yield race([
      call(startBulkUploadCustomersStatusPollingEffect, action),
      take(DashboardActions.STOP_POLL_BULK_UPLOAD_ACCOUNTS_STATUS)
    ])
  }
}

function* checkForPaidBannerSoonSaga() {
  yield takeLatest(ExpActions.GET_AB_PROFILE_SUCCESS, checkForPaidBannerSoon)
}

function* postPaidBannerSoonFeedbackSaga() {
  yield takeLatest(NEWDASHBOARD_EVENTS.SUBMIT_FEEDBACK_PAID_SOON_BANNER, postPaidBannerSoonFeedback)
}

function* closePaidBannerSoonBannerSaga() {
  yield takeLatest(NEWDASHBOARD_EVENTS.CLOSE_PAID_SOON_BANNER, closePaidBannerSoonBanner)
}

function* fetchCustomerProfilesSaga() {
  yield takeCustomLatest(DashboardActions.FETCH_CUSTOMER_PROFILES, fetchCustomerProfilesEffect)
}

function* bulkAddAccountTagsSaga() {
  yield takeCustomLatest(DashboardActions.BULK_ADD_ACCOUNT_TAGS, bulkAddAccountTagsEffect)
}

function* bulkAddAccountBeatRouteSaga() {
  yield takeCustomLatest(
    DashboardActions.BULK_ADD_ACCOUNT_BEAT_ROUTE,
    bulkAddAccountBeatRouteEffect
  )
}

export const DashboardEffects = [
  fetchCustomerProfilesSaga(),
  bulkAddAccountTagsSaga(),
  bulkAddAccountBeatRouteSaga(),
  getCustomerDataSaga(),
  registerMerchantNameSaga(),
  fetchAndSetLocalDataSaga(),
  businessNameOptOutSaga(),
  fetchMerchantProfileSaga(),
  debouncedPatchMerchantProfileSaga(),
  fetchEntitiesSaga(),
  debouncedPatchEntitySaga(),
  addEntitySaga(),
  deleteEntitySaga(),
  fetchPhonebookContactsSaga(),
  patchMerchantAddressWithCurrentLocationSaga(),
  dashboardAnalyticsSaga(),
  fetchHelpSectionsSaga(),
  contactUsOnWhatsAppSaga(),
  handleInitialLeadgenSaga(),
  fetchFilteredByTagsCustomersSaga(),
  fetchSearchByValueAccountsSaga(),
  getBusinessIdsSaga(),
  selectBusinessSaga(),
  createBusinessSaga(),
  switchBusinessSaga(),
  fetchBusinessManagersSaga(),
  addBusinessManagerSaga(),
  deleteBusinessManagerSaga(),
  fetchBusinessCategoriesSaga(),
  fetchBusinessTypesSaga(),
  bulkUploadCustomersSaga(),
  startBulkUploadCustomersStatusPollingSaga(),
  checkForPaidBannerSoonSaga(),
  postPaidBannerSoonFeedbackSaga(),
  closePaidBannerSoonBannerSaga(),
  fetchBusinessSettingsSaga()
]
