import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const OtherTemplatesStyles = createUseStyles({
  container: {
    border: `solid 1px ${THEME.brandGreyPrimary}`,
    borderRadius: '8px'
  },
  templateContainer: {
    maxHeight: 300,
    overflow: 'scroll'
  },
  header: {
    padding: '16px 8px',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: '8px 8px 0 0',
    borderBottom: `solid 1px ${THEME.brandGreyPrimary}`,
    backgroundColor: THEME.brandGreyPrimary
  }
})
