import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BellIcon, CloseIcon, IconButton } from '@okcredit/web-lib'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { THEME } from 'components/theme'
import { BellNotificationStyles } from './styles'

const message =
  'We have been experiencing downtime since 11:00 AM. Resolving this issue is our top priority, and we are working to fix it as quickly as possible.'

const BellNotification = () => {
  const classes = BellNotificationStyles()
  const dispatch = useDispatch()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  const handleTogglePopup = () => {
    setIsPopupOpen((status) => !status)
  }

  const handleOpenPopup = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault()
    handleTogglePopup()
    dispatch(pushEvent(NEWDASHBOARD_EVENTS.BELL_ICON_CLICKED))
  }

  return (
    <div className={classes.container}>
      <IconButton className={classes.iconButton} onClick={handleOpenPopup} size="small">
        {isPopupOpen ? <CloseIcon size={20} /> : <BellIcon fill={THEME.brandRed} size={20} />}
      </IconButton>
      {isPopupOpen ? <PopupContent handleTogglePopup={handleTogglePopup} /> : null}
    </div>
  )
}

const PopupContent = ({ handleTogglePopup }: { handleTogglePopup: () => void }) => {
  const classes = BellNotificationStyles()
  return (
    <div className={classes.popupContainerOverlay} onClick={handleTogglePopup}>
      <div
        className={classes.popupContainer}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <div className={classes.popupHeader}>Downtime</div>
        <div className={classes.popupContent}>{message}</div>
      </div>
    </div>
  )
}

export default BellNotification
