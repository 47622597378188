import { Action } from 'infra/types'
import { CL_VERSIONS } from 'utils/common/common.types'
import { FETCH_LIST_API_RESPONSE } from 'services/Api/StaffLinkApiFolder/type'
import {
  IApproveTable,
  INoActionsTable,
  IPendingTable,
  ISelectedSettingsForAddTxn,
  IRescheduledDeliverieTable
} from './list.types'
import { ISupplyList, ISupplyListState } from './supply-list.types'

export const SupplyListActions = {
  FETCH_SPECIFIC_LIST: 'supplylist/fetchSpecificList',
  FETCH_SUPPLY_LIST: 'supplylist/fetchSupplyList',
  SET_SUPPLY_LIST: 'supplylist/setSupplyList',
  SET_TABLE_DATA: 'supplylist/setTableData',
  SET_ON_HOLD_BILL_DATA: 'supplylist/setOnHoldBillData',
  SET_SUMMARY_DATA: 'supplylist/setSummaryData',
  SET_ALL_ASSIGNMENT_FILTER: 'supplylist/setAllAssignmentFilter',
  SET_SELECTED_ASSIGNMENT_FILTER: 'supplylist/setSelectedAssignmentFilter',
  SET_SELECTED_LIST: 'supplyList/setSelectedList',
  SET_DRAWER: 'supplylist/setDrawers',
  ADD_TXN_TO_COLLECTION: 'supplyList/addTxnToCollection',
  SET_EXISTING_ACCOUNT_IDS_OR_BILLS: 'collectionList/setExistingAccountIdsOrBills',
  SET_LOADER: 'supplyList/setLoaders',
  SET_SUPPLY_LIST_STORE: 'supplyList/setStore'
}

export const fetchSupplyListAction = (payload: any): Action<any> => {
  return {
    type: SupplyListActions.FETCH_SUPPLY_LIST,
    payload
  }
}

export const addTxnToCollectionClick = (
  payload: ISelectedSettingsForAddTxn
): Action<ISelectedSettingsForAddTxn> => ({
  type: SupplyListActions.ADD_TXN_TO_COLLECTION,
  payload
})

export const fetchSpecificListAction = (payload: {
  listId: string
  version?: CL_VERSIONS
}): Action<{ listId: string; version?: CL_VERSIONS }> => {
  return {
    type: SupplyListActions.FETCH_SPECIFIC_LIST,
    payload
  }
}

export const setSupplyLists = (payload: ISupplyList[]): Action<ISupplyList[]> => ({
  type: SupplyListActions.SET_SUPPLY_LIST,
  payload
})

export const setSelectedList = (
  payload: FETCH_LIST_API_RESPONSE['list']
): Action<FETCH_LIST_API_RESPONSE['list']> => ({
  type: SupplyListActions.SET_SELECTED_LIST,
  payload
})

export const setSupplyDrawers = (payload: {
  drawerName: 'addToCollectionDrawer' | 'shiftToAnotherList' | 'showWhatsappSuccessDrawer'
  status: boolean
}): Action<{
  drawerName: 'addToCollectionDrawer' | 'shiftToAnotherList' | 'showWhatsappSuccessDrawer'
  status: boolean
}> => ({
  type: SupplyListActions.SET_DRAWER,
  payload
})

export const setTablesData = (payload: {
  pendingTable: IPendingTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
}): Action<{
  pendingTable: IPendingTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
}> => ({
  type: SupplyListActions.SET_TABLE_DATA,
  payload
})

export const setonHoldBillData = (payload: {
  onHoldBillsData: ISupplyListState['onHoldBillsData']
}): Action<{
  onHoldBillsData: ISupplyListState['onHoldBillsData']
}> => ({
  type: SupplyListActions.SET_ON_HOLD_BILL_DATA,
  payload
})

export const setSummaryData = (
  payload: ISupplyListState['summaryData']
): Action<ISupplyListState['summaryData']> => ({
  type: SupplyListActions.SET_SUMMARY_DATA,
  payload
})

export const setAllAssignmentFilter = (
  payload: Record<CL_VERSIONS, number>
): Action<Partial<Record<CL_VERSIONS, number>>> => ({
  type: SupplyListActions.SET_ALL_ASSIGNMENT_FILTER,
  payload
})

export const setSelectedAssignmentFilter = (payload: CL_VERSIONS): Action<CL_VERSIONS> => ({
  type: SupplyListActions.SET_SELECTED_ASSIGNMENT_FILTER,
  payload
})

export const setExistingAccountIdsOrBills = (payload: {
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
}): Action<{
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
}> => ({
  type: SupplyListActions.SET_EXISTING_ACCOUNT_IDS_OR_BILLS,
  payload
})

export const setSupplyLoaders = (payload: {
  loaderName: keyof ISupplyListState['loaders']
  status: boolean
}): Action<{
  loaderName: keyof ISupplyListState['loaders']
  status: boolean
}> => ({
  type: SupplyListActions.SET_LOADER,
  payload
})

export const setSupplyListStore = (payload: {
  allAssignmentFilter: Record<CL_VERSIONS, number>
  selectedAssignmentFilter: CL_VERSIONS
  pendingTable: IPendingTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
  onHoldBillsData: ISupplyListState['onHoldBillsData']
  summaryData: ISupplyListState['summaryData']
  selectedList: FETCH_LIST_API_RESPONSE['list']
  rescheduledDeliveries: IRescheduledDeliverieTable[]
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
  addedCredit?: number
  listPendingDue?: number
  orderSettingAccountIds?: string[]
}): Action<{
  allAssignmentFilter: Partial<Record<CL_VERSIONS, number>>
  selectedAssignmentFilter: CL_VERSIONS
  pendingTable: IPendingTable[]
  rescheduledDeliveries: IRescheduledDeliverieTable[]
  noActionsTable: INoActionsTable[]
  approveTable: IApproveTable[]
  onHoldBillsData: ISupplyListState['onHoldBillsData']
  summaryData: ISupplyListState['summaryData']
  selectedList: FETCH_LIST_API_RESPONSE['list']
  existingAccountIds?: string[]
  existingBills?: {
    bill_id: string
    customer_id: string
  }[]
  addedCredit?: number
  listPendingDue?: number
  orderSettingAccountIds?: string[]
}> => ({
  type: SupplyListActions.SET_SUPPLY_LIST_STORE,
  payload
})
