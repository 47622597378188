import { getLanguageFromKey } from 'utils/getLanguage'
import { validatePhoneNumber } from 'utils/validators/phoneNumberValidator'
import { validateGST } from 'pages/NewDashboard/components/Billing/Helper'
import { ICustomer, ISupplier } from '../dashboard.types'
import { ENTITY_TYPE } from '../../../constants'

export interface IEntityUpdateObject {
  key: string
  value: any
  entityType: ENTITY_TYPE
  entityId: string
}

export const getFormattedSupplierProfile = (supplier: any): ISupplier => {
  const lang = getLanguageFromKey(supplier.lang)

  return {
    address: supplier.address,
    balance: Number(supplier.balance) / 100,
    createdAt: Number(supplier.create_time),
    id: supplier.id,
    lang,
    mobile: supplier.mobile,
    name: supplier.name,
    profileImage: supplier.profile_image,
    isRegistered: supplier.registered,
    txnAlertEnabled: supplier.txn_alert_enabled,
    entityType: ENTITY_TYPE.SUPPLIER,
    state: supplier.state,
    blockedBySupplier: supplier.blocked_by_supplier,
    isAddTransactionRestricted: supplier.add_transaction_restricted,
    edited: {
      values: {
        name: supplier.name,
        mobile: supplier.mobile,
        address: supplier.address,
        txnAlertEnabled: supplier.txn_alert_enabled,
        customer_gst: supplier?.gst_number || '',
        lang
      },
      errors: {}
    }
  }
}

export const getFormattedCustomerProfile = (customer: any): ICustomer => {
  const lang = getLanguageFromKey(customer.lang)
  return {
    customerGST: customer.gst_number,
    accountUrl: customer.account_url,
    address: customer.address,
    balance: customer.balance,
    balanceV2: customer.balance_v2,
    createdAt: Number(customer.created_at),
    description: customer.description,
    email: customer.email,
    id: customer.id,
    lang,
    lastActivityAt: Number(customer.last_activity),
    lastPaymentAt: Number(customer.last_payment),
    mobile: customer.mobile,
    profileImage: customer.profile_image,
    isRegistered: customer.registered,
    userId: customer.user_id,
    txnAlertEnabled: customer.txn_alert_enabled,
    entityType: ENTITY_TYPE.CUSTOMER,
    state: customer.state,
    blockedByCustomer: customer.blocked_by_customer,
    supply_state_code: customer.supply_state_code || '',
    isAddTransactionRestricted: customer.add_transaction_restricted,
    edited: {
      values: {
        name: customer.description,
        mobile: customer.mobile,
        address: customer.address,
        txnAlertEnabled: customer.txn_alert_enabled,
        lang,
        customerCode: customer.customer_code,
        customer_gst: customer?.gst_number || '',
        supply_state_code: customer.supply_state_code || ''
      },
      errors: {}
    },
    customerCode: customer.customer_code
  }
}

enum EntityProfileField {
  MOBILE = 'mobile',
  NAME = 'name',
  CUSTOMER_GST = 'customer_gst'
}

export const validateEntityProfileField = ({
  key,
  value
}: {
  key: EntityProfileField
  value: any
}): boolean => {
  let isValid = true

  switch (key) {
    case EntityProfileField.MOBILE:
      isValid = validatePhoneNumber(value)
      break
    case EntityProfileField.NAME:
      isValid = value.length
      break
    case EntityProfileField.CUSTOMER_GST:
      isValid = validateGST(value)
  }
  return isValid
}

export const getPatchCustomerPayload = (customer: ICustomer) => {
  const { values, errors } = customer.edited

  return {
    ...{
      ...(!errors.name ? { description: values.name } : {}),
      ...(!errors.mobile ? { mobile: values.mobile } : {}),
      ...(!errors.address ? { address: values.address } : {}),
      ...(values.customer_gst && validateGST(values.customer_gst)
        ? { gst_number: values.customer_gst }
        : {}),
      ...(values.supply_state_code ? { supply_state_code: values.supply_state_code } : {}),
      ...(values.txnAlertEnabled !== customer.txnAlertEnabled
        ? { txn_alert_enabled: values.txnAlertEnabled, update_txn_alert_enabled: true }
        : {}),
      lang: values.lang?.key
    }
  }
}

export const getPatchSupplierPayload = (supplier: ISupplier) => {
  const { values, errors } = supplier.edited

  const payload: any = {
    supplier: {
      ...(!errors.name ? { name: values.name } : {}),
      ...(!errors.mobile ? { mobile: values.mobile } : {}),
      ...(!errors.address ? { address: values.address } : {}),
      ...(values.customer_gst && validateGST(values.customer_gst)
        ? { gst_number: values.customer_gst }
        : {}),
      lang: values.lang?.key
    }
  }

  if (values.txnAlertEnabled !== supplier.txnAlertEnabled) {
    payload.supplier.txn_alert_enabled = values.txnAlertEnabled
    payload.update_txn_alert_enabled = true
  }

  return payload
}

export const getResetCustomerProfile = (customer: ICustomer): ICustomer => {
  return {
    ...customer,
    edited: {
      values: {
        name: customer.description,
        mobile: customer.mobile,
        address: customer.address,
        txnAlertEnabled: customer.txnAlertEnabled,
        lang: customer.lang
      },
      errors: {}
    }
  }
}

export const getResetSupplierProfile = (supplier: ISupplier): ISupplier => {
  return {
    ...supplier,
    edited: {
      values: {
        name: supplier.name,
        mobile: supplier.mobile,
        address: supplier.address,
        txnAlertEnabled: supplier.txnAlertEnabled,
        lang: supplier.lang
      },
      errors: {}
    }
  }
}

export const CustomerSupplierProfileHelpers = {
  getFormattedCustomerProfile,
  getFormattedSupplierProfile,
  getPatchCustomerPayload,
  getPatchSupplierPayload
}
