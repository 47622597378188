import { Action } from 'infra/types'
import { CL_VERSIONS } from 'utils/common/common.types'
import { DUE_CONFIG } from 'services/Api/StaffLinkApiFolder/type'
import { IAddToCollectionState } from './addToCollection.types'
import { addToCollectionActions } from './addToCollection.actions'

const ADD_COLLECTION_DRAWER_STATE: IAddToCollectionState = {
  selectedAssignmentFilter: CL_VERSIONS.CURRENT_ASSIGNMENT,
  config: DUE_CONFIG.INVOICE_LEVEL_DUE_V2,
  selectedSettingsForAddTxn: null,
  drawers: {
    addToCollectionDrawer: false
  },
  loaders: {
    addToCollectionDrawer: false
  }
}

export const AddToCollectionReducer = (
  state: IAddToCollectionState = ADD_COLLECTION_DRAWER_STATE,
  action: Action<any>
): IAddToCollectionState => {
  switch (action.type) {
    case addToCollectionActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...action.payload
        }
      }
    }
    case addToCollectionActions.SET_ADD_TO_COLLECTION_DRAWER_DATA: {
      return {
        ...state,
        config: action.payload.config,
        selectedAssignmentFilter: action.payload.selectedAssignmentFilter,
        selectedSettingsForAddTxn: action.payload.selectedSettingsForAddTxn,
        drawers: {
          ...state.drawers,
          addToCollectionDrawer: true
        }
      }
    }
    case addToCollectionActions.RESET_ADD_TO_COLLECTION_DRAWER_STATE: {
      return ADD_COLLECTION_DRAWER_STATE
    }
    default: {
      return state
    }
  }
}
