import { AxiosError } from 'axios'
import { intlInstance } from './../../i18n/IntlProvider'

export interface IErrorMessage {
  message: string
  code: number
}
export const getErrorMessage = (error: AxiosError): IErrorMessage => {
  const translate = intlInstance.translate()
  console.log('Error====>', error)
  if (error.cause === 'customError') {
    return {
      message: translate(
        error.message || 'auth:Something went wrong! Please try again in sometime.'
      ),
      code: 1
    }
  }
  if (error.response) {
    const { status, data } = error.response
    if (status === 401) {
      // Unauthorized
      return {
        message: translate('auth:The request resource is not accessible. Unauthorized!'),
        code: status
      }
    } else if (status === 500) {
      // Internal Server Error
      return {
        message: translate(
          'auth:We are facing some difficulty completing your request. Please try again! '
        ),
        code: status
      }
    } else if (status === 503) {
      // Service Not Up|| Service  Reloading
      return {
        message: translate(
          'auth:We are currently facing some downtime. Hold tight! We are working on it. Please try in sometime'
        ),
        code: status
      }
    } else if (status === 404) {
      // Not Found
      return {
        message: translate('auth:Resource was not found. Please try again'),
        code: status
      }
    } else if (status === 409) {
      if (data) {
        if (data.error === 'email_exists') {
          return {
            message: translate('auth:Email Taken'),
            code: status
          }
        } else if (data.error === 'customer_exists' || data.error === 'supplier_exists') {
          return {
            message: translate('auth:Number Taken'),
            code: status
          }
        }
      }
    } else if (status) {
      return {
        message: translate('auth:Something went wrong! Please try again in sometime.'),
        code: status
      }
    } else {
      // Failed Without status code - Something Went Wrong
      return {
        message: translate('auth:Something went wrong! Please try again in sometime.'),
        code: 0
      }
    }
  }
  // Failed Without status code - CORS ERROR
  return {
    message: translate('auth:Something went wrong! Please try again in sometime.'),
    code: 1
  }
}
