import { call, put, select } from 'redux-saga/effects'
import { Action } from 'infra/types'
import { IAppState } from 'infra/AppState'

import {
  addBillToCollectionsApi,
  createInitiateBankDepositApi,
  createInitiateCashDepositApi,
  fetchUnidentifiedCollectionApi,
  updateBankDepositApi,
  updateCashDepositApi
} from 'services/Api/StafflinkApi'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { API_RESPONSE_TYPE, IApiResponse } from '../../../constants'
import { formatUnidentifiedCollectionsActions } from './unidentifiedCollection.helpers'
import {
  FetchUnidentifiedCollectionActionPayload,
  IAddBillToCollectionsAction,
  IAddBillToCollectionsRequest
} from './unidentifiedCollection.types'
import {
  setUnidentifiedCollectionActionData,
  fetchUnidentifiedCollectionActions,
  setCollectionActionFilters,
  setShowBillDrawer,
  fetchCashDeposit,
  fetchBankDeposit,
  setFetchingLoaders
} from './unidentifiedCollection.actions'

export function* fetchUnidentifiedCollectionEffect(
  action: Action<FetchUnidentifiedCollectionActionPayload>
) {
  yield put(setFetchingLoaders(true))
  const { startTime, filterType } = action.payload

  const response: IApiResponse = yield call(fetchUnidentifiedCollectionApi, {
    ...(filterType !== 'All' && { start_time: startTime })
  })
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setFetchingLoaders(false))
    throw new Error('Problem while fetching unidentified Collections, please try after sometime', {
      cause: 'customError'
    })
  }

  const formattedData = formatUnidentifiedCollectionsActions(response.data.merchantCollections)
  yield put(setUnidentifiedCollectionActionData(formattedData))
  yield put(setCollectionActionFilters({ filter: { startTime, filterType } }))
  yield put(setFetchingLoaders(false))
}

export function* addBillToCollectionsEffect(action: Action<IAddBillToCollectionsAction>) {
  const { billId, customerId } = action.payload

  const { Dashboard, UnidentifiedCollection } = yield select((app: IAppState) => app)
  const merchantId = Dashboard.merchantProfile?.data?.id
  const { paymentId, amount } = UnidentifiedCollection.selectedCollection

  if (!merchantId || !paymentId) {
    throw new Error('Please select, colection first', {
      cause: 'customError'
    })
  }

  const payload: IAddBillToCollectionsRequest = {
    bill_id: billId,
    payment_amount: parseInt(`${Number(amount) * 100}`),
    account_id: customerId,
    payment_id: paymentId,
    business_id: merchantId
  }
  const response: IApiResponse = yield call(addBillToCollectionsApi, payload)
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while adding bill to collection, please try after sometime', {
      cause: 'customError'
    })
  }
  yield put(fetchUnidentifiedCollectionActions(UnidentifiedCollection.filter))
  yield put(setShowBillDrawer(false))
}

export function* createInitiateBankDepositEffect(action: Action<any>) {
  try {
    const { Dashboard } = yield select((app: IAppState) => app)
    const merchantId = Dashboard.merchantProfile?.data?.id
    const isStaff = action.payload.staffId
    const payload = {
      business_id: merchantId,
      bank_account_id: action.payload.modeOfPaymentId,
      source_type: action.payload.assetType,
      source_account_id: action.payload.assetId,
      amount: Number(action.payload.amount) * 100,
      depositor_type: isStaff ? 'staff' : 'accountant',
      depositor_id: isStaff ? action.payload.staffId : '',
      depositor_name: isStaff ? action.payload.staffName : '',
      deposit_date: action.payload.depositedDate,
      notes: action.payload.notes
    }
    const response: IApiResponse = yield call(createInitiateBankDepositApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(fetchBankDeposit())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Bank deposit initiated successfully'
        })
      )
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      throw new Error('Please try after sometime', {
        cause: 'customError'
      })
    }
  } catch (error) {}
}

export function* createInitiateCashDepositEffect(action: Action<any>) {
  try {
    const { Dashboard } = yield select((app: IAppState) => app)
    const merchantId = Dashboard.merchantProfile?.data?.id
    const payload = {
      business_id: merchantId,
      staff_id: action.payload.staffId,
      amount: Number(action.payload.amount) * 100,
      deposit_date: action.payload.depositedDate,
      notes: action.payload.notes
    }
    const response: IApiResponse = yield call(createInitiateCashDepositApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Cash deposit initiated successfully'
        })
      )
      yield put(fetchCashDeposit())
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      throw new Error('Please try after sometime', {
        cause: 'customError'
      })
    }
  } catch (error) {}
}

export function* editBankDepositEffect(action: Action<any>) {
  try {
    const { Dashboard } = yield select((app: IAppState) => app)
    const merchantId = Dashboard.merchantProfile?.data?.id
    const payload = {
      business_id: merchantId,
      bank_deposit_id: action.payload.bankDepositID,
      deposit_date: action.payload.item.depositedDate,
      notes: action.payload.item.notes
    }
    const response: IApiResponse = yield call(updateBankDepositApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Cash deposit initiated successfully'
        })
      )
      yield put(fetchBankDeposit())
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      throw new Error('Please try after sometime', {
        cause: 'customError'
      })
    }
  } catch (error) {}
}

export function* editCashDepositEffect(action: Action<any>) {
  try {
    const { Dashboard } = yield select((app: IAppState) => app)
    const merchantId = Dashboard.merchantProfile?.data?.id
    const payload = {
      business_id: merchantId,
      cash_deposit_id: action.payload.cashDepositID,
      deposit_date: action.payload.item.depositedDate,
      notes: action.payload.item.notes
    }
    const response: IApiResponse = yield call(updateCashDepositApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Cash deposit initiated successfully'
        })
      )
      yield put(fetchCashDeposit())
    }
    if (response.type === API_RESPONSE_TYPE.FAILURE) {
      throw new Error('Please try after sometime', {
        cause: 'customError'
      })
    }
  } catch (error) {}
}
