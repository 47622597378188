import { ICartItem, IStaffInventoryItems } from 'experiments/StaffLink/StaffLink.types'
import {
  billingRoundFunction,
  chooseBestOffer,
  convertPaisaToRupee,
  convertRupeeToPaisa,
  handleOfferInPayload
} from 'state/billing/billing.helpers'
import { NewItemDetailEntity } from 'state/billing/billing.types'
import { getTaxComponents } from 'state/new-summary/orders/orders.formatter'
import { OrderItem } from 'state/new-summary/orders/orders.types'

export const getStaffFormattedItemsPayload = (
  itemDetails: (NewItemDetailEntity & {
    inventoryItemId?: string
  })[],
  allItems: Array<OrderItem['addedItemsItem']>,
  customerId: string,
  isIGST: boolean
) => {
  return itemDetails.map((item) => {
    const allItemDetails = allItems.find(
      (storeItem: OrderItem['addedItemsItem']) => storeItem.catalogueItemId === item.itemId
    ) as OrderItem['addedItemsItem']

    const { appliedOfferId = '', applicableOfferDetails = [] } = item

    const { unitOffers } = handleOfferInPayload({
      applicableOfferDetails,
      appliedOfferId,
      customerId,
      item: {
        discountInRupee: item.discount || 0,
        rateInRupee: item.rate || 0,
        quantity: item.quantity
      }
    })
    const totalRateWithoutDiscount = convertRupeeToPaisa(item.rate || 0) * item.quantity
    const lineItemDiscount = convertRupeeToPaisa(item.discount || 0) * item.quantity
    const pretaxTotal = totalRateWithoutDiscount - lineItemDiscount
    const cgstValue = billingRoundFunction(pretaxTotal * ((item.cgstTaxPercent || 0) / 100))
    const sgstValue = billingRoundFunction(pretaxTotal * ((item.sgstTaxPercent || 0) / 100))
    const igstValue = isIGST
      ? billingRoundFunction(pretaxTotal * ((item.igstPercent || 0) / 100))
      : 0
    const cessValue = billingRoundFunction(pretaxTotal * ((item.cessPercent || 0) / 100))
    const totalTax = cessValue + (isIGST ? igstValue : cgstValue + sgstValue)

    const lineItemAmount = pretaxTotal + totalTax
    return {
      catalog_item_id: item.itemId,
      quantity: item.quantity,
      amount: lineItemAmount,
      info: {
        sku: item.skuId || '',
        hsn_code: item.hsnCode || '',
        name: item.item,
        unit: allItemDetails?.unit || '',
        rate: convertRupeeToPaisa(item.rate || 0),
        mrp: convertRupeeToPaisa(item.mrp || 0),
        quantity: item.quantity,
        tax: totalTax,
        discount: lineItemDiscount,
        amount: lineItemAmount,
        tax_components: getTaxComponents(item, cgstValue, sgstValue, igstValue, cessValue, isIGST),
        ...(item.hsnCode && { hsn_code: item.hsnCode }),
        catalog_item_id: item.itemId,
        ...(unitOffers.length && {
          unit_offers: unitOffers
        })
      }
    }
  })
}

export const getUpdatedCartValues = (
  cartItem: ICartItem,
  item: IStaffInventoryItems,
  customerDetails: any,
  applyAllApplicableOffers?: boolean
) => {
  const { gstTaxPercent = 0, cessPercent = 0 } = item
  const { discount: calcDiscount = 0, appliedOfferId = '' } = chooseBestOffer(
    item.applicableOfferDetails,
    cartItem.rate,
    cartItem.quantity,
    customerDetails,
    applyAllApplicableOffers
  )
  const effectiveRate = cartItem.rate - calcDiscount
  const effectiveRateInPaisa = convertRupeeToPaisa(effectiveRate)
  const netRateInPaisa = effectiveRateInPaisa * (1 + (gstTaxPercent + cessPercent) / 100)
  const taxAmountInPaisa = netRateInPaisa - effectiveRateInPaisa
  return {
    unitNetRate: convertPaisaToRupee(billingRoundFunction(netRateInPaisa)),
    tax: {
      percentage: (item.gstTaxPercent || 0) + (item.cessPercent || 0),
      amount: convertPaisaToRupee(billingRoundFunction(taxAmountInPaisa))
    },
    amount: cartItem?.quantity * netRateInPaisa,
    discount: {
      name: item.applicableOfferDetails?.find((_) => _.id === appliedOfferId)?.name || '',
      amount: calcDiscount
    }
  }
}
