import { TRANSACTION_TYPE, DOCUMENT_TYPE } from 'state/collectionList/collectionList.types'

export interface IOTPInfo {
  otp: string
  otpId?: string | null
  number?: number | null
}

export enum IDispatchType {
  GO_BACK = 1
}
export interface IDispatchCallback {
  redirectTo?: string | IDispatchType
}

export enum FILTER_OPTION {
  AND = 'and',
  OR = 'or'
}

export interface IFilteredCustomersPayload {
  tag_ids: Array<string>
  business_id: string
  filter_option?: FILTER_OPTION
}

export interface IPinInfo {
  pin: string
  number?: number | null
}

export enum GRANT_TYPE {
  PASSWORD = 'password'
}

export interface ITransactionPayload {
  account_id: string
  collection_list_id: string
  transaction_type: TRANSACTION_TYPE
  bill_id: string
  start_time: number
  end_time: number
}

export type IStaffAccessResponse = Array<{
  staff_uid: string
  resource_type: number
  resource_id: string
  has_access: boolean
  business_id: string
  created_at: string
  updated_at: string
}>

export type IUpdateStaffAccessPayload = Array<
  Pick<IStaffAccessResponse[0], 'staff_uid' | 'resource_type' | 'resource_id' | 'has_access'>
>

export interface IUploadCollectionFile {
  document: number[]
  document_type: DOCUMENT_TYPE
  document_extension: string
  business_id: string
  file_name: string
}

export enum TP_SOURCE_TYPE {
  TP_UNKNOWN = 0,
  TP_TALLY = 1,
  TP_MARG = 2,
  TP_PACE = 3
}

export type ThirdPartyFieldValueType = {
  particular: string
  voucherType: string
}

export enum ThirdPartyPaymentType {
  advance = 'advance',
  cash = 'cash',
  cheque = 'cheque',
  damaged = 'damaged',
  neft = 'neft',
  others = 'others',
  qr = 'qr',
  return = 'return'
}

export type ThirdPartyConfigType = {
  [key in ThirdPartyPaymentType]: ThirdPartyFieldValueType
}

export enum ThirdPartyCreditTypes {
  sales_ledger = 'sales_ledger',
  cgst_ledger = 'cgst_ledger',
  sgst_ledger = 'sgst_ledger',
  cess_ledger = 'cess_ledger',
  round_off_ledger = 'round_off_ledger',
  godown = 'godown',
  batch_name = 'batch_name',
  default_unit = 'default_unit',
  applicable_from = 'applicable_from'
}

export type IThirdPartyCreditExportsType = {
  [key in ThirdPartyCreditTypes]: string
}

export interface IUpdateRouteBeatRequest {
  id: string
  add_beats?: string[]
  delete_beats?: string[]
  add_routes?: string[]
  delete_routes?: string[]
}

export type ADD_NEW_CUSTOMER_VIA_BILL_RESPONSE = {
  customer: {
    id: string
    status: number
    description: string
    created_at: number
    updated_at: number
    account_url: string
    add_transaction_restricted: boolean
    state: number
    restrict_contact_sync: boolean
    display_txn_alert_setting: boolean
  }
  created: boolean
}
