import { Action } from 'infra/types'
import { IDrawers, IInvoiceTag, IlinkDelinkInvoiceTagPayload } from './tags.types'

export const TagsActions = {
  FETCH_TAGS: 'tags/fetchTags',
  FETCH_TAGS_SUCCESS: 'tags/fetchTagsSuccess',

  CREATE_TAG: 'tags/createTag',
  CREATE_TAG_SUCCESS: 'tags/createTagSuccess',
  CREATE_TAG_FAILURE: 'tags/createTagFailure',

  UPDATE_TAG: 'tags/updateTag',
  UPDATE_TAG_SUCCESS: 'tags/updateTagSuccess',
  UPDATE_TAG_FAILURE: 'tags/updateTagFailure',

  FETCH_ACCOUNT_TAGS: 'tags/fetchAccountTags',
  FETCH_ACCOUNT_TAGS_SUCCESS: 'tags/fetchAccountTagsSuccess',

  LINK_DELINK_ACCOUNT_TAGS: 'tags/linkDelinkAccountTags',
  LINK_DELINK_ACCOUNT_TAGS_SUCCESS: 'tags/linkDelinkAccountTagsSuccess',
  LINK_DELINK_ACCOUNT_TAGS_FAILURE: 'tags/linkDelinkAccountTagsFailure',

  FETCH_INVOICE_TAGS: 'tags/fetchInvoiceTags',
  FETCH_INVOICE_TAGS_SUCCESS: 'tags/fetchInvoiceTagsSuccess',

  LINK_DELINK_INVOICE_TAGS: 'tags/linkDelinkInvoiceTags',
  LINK_DELINK_INVOICE_TAGS_SUCCESS: 'tags/linkDelinkInvoiceTagsSuccess',

  TOGGLE_EDIT_TAG_DIALOG: 'tags/toggleEditTagDialog',
  HANDLE_DRAWER_TOGGLE: 'tags/HANDLE_DRAWER_TOGGLE'
}

export const getTags = (): Action<null> => ({
  type: TagsActions.FETCH_TAGS,
  payload: null
})

export const getTagsSuccess = (payload: any): Action<any> => ({
  type: TagsActions.FETCH_TAGS_SUCCESS,
  payload
})

export const createTag = (payload: any): Action<any> => ({
  type: TagsActions.CREATE_TAG,
  payload
})

export const createTagSuccess = (payload: any): Action<any> => ({
  type: TagsActions.CREATE_TAG_SUCCESS,
  payload
})

export const createTagFailure = (): Action<null> => ({
  type: TagsActions.CREATE_TAG_FAILURE,
  payload: null
})

export const updateTag = (payload: any): Action<any> => ({
  type: TagsActions.UPDATE_TAG,
  payload
})

export const updateTagSuccess = (payload: any): Action<any> => ({
  type: TagsActions.UPDATE_TAG_SUCCESS,
  payload
})

export const updateTagFailure = (): Action<null> => ({
  type: TagsActions.UPDATE_TAG_FAILURE,
  payload: null
})

export const getAccountTags = (): Action<null> => ({
  type: TagsActions.FETCH_ACCOUNT_TAGS,
  payload: null
})

export const getAccountTagsSuccess = (payload: any): Action<any> => ({
  type: TagsActions.FETCH_ACCOUNT_TAGS_SUCCESS,
  payload
})

export const linkDelinkAccountTags = (payload: any): Action<any> => ({
  type: TagsActions.LINK_DELINK_ACCOUNT_TAGS,
  payload
})

export const linkDelinkAccountTagsSuccess = (): Action<null> => ({
  type: TagsActions.LINK_DELINK_ACCOUNT_TAGS_SUCCESS,
  payload: null
})

export const linkDelinkAccountTagsFailure = (): Action<null> => ({
  type: TagsActions.LINK_DELINK_ACCOUNT_TAGS_FAILURE,
  payload: null
})

export const toggleEditTagDialog = (payload: boolean): Action<boolean> => ({
  type: TagsActions.TOGGLE_EDIT_TAG_DIALOG,
  payload
})

export const getInvoiceTags = (payload: {
  transactionId: string
  accountId: string
}): Action<{ transactionId: string; accountId: string }> => ({
  type: TagsActions.FETCH_INVOICE_TAGS,
  payload
})

export const getInvoiceTagsSuccess = (payload: IInvoiceTag[]): Action<IInvoiceTag[]> => ({
  type: TagsActions.FETCH_INVOICE_TAGS_SUCCESS,
  payload
})

export const linkDelinkInvoiceTags = (
  payload: IlinkDelinkInvoiceTagPayload
): Action<IlinkDelinkInvoiceTagPayload> => ({
  type: TagsActions.LINK_DELINK_INVOICE_TAGS,
  payload
})

export const linkDelinkInvoiceTagsSuccess = (payload: IInvoiceTag[]): Action<IInvoiceTag[]> => ({
  type: TagsActions.LINK_DELINK_INVOICE_TAGS_SUCCESS,
  payload
})

export const handleDrawerToggle = (payload: IDrawers): Action<IDrawers> => ({
  type: TagsActions.HANDLE_DRAWER_TOGGLE,
  payload
})
