export const NEWDASHBOARD_EVENTS = {
  PAGE_LOADED: 'newDashBoard/website_loaded',
  LEDGER_TAB: 'newDashBoard/ledger_tab',
  RECONCILE_TAB: 'newDashBoard/reconcile_tab',
  BILLING_TAB: 'web_bills_clicked',
  CREDIT_NOTE_TAB: 'credit_note_clicked',
  CHEQUE_TAB: 'newDashBoard/cheque_tab',
  PROFILE_TAB: 'newDashBoard/profile_tab',
  EDIT_MERCHANT: 'newDashBoard/edit_merchant_tab',
  SETTINGS_TAB: 'newDashBoard/settings_tab',
  HELP_TAB: 'newDashBoard/help_tab',
  APPROVE_TAB: 'newDashBoard/approve_tab',
  SUPPLY_TAB: 'newDashBoard/supply_tab',
  COLLECTION_TAB: 'newDashBoard/collection_tab',
  SUMMARY_TAB: 'newDashBoard/summary_tab',
  SEARCH_BAR_FOCUSED: 'newDashBoard/search_bar_focused',
  TABLE_SORT_CLICKED: 'newDashBoard/table__column_sort',
  OPENDROPDOWN: 'newDashBoard/openDropDown',
  THREE_DOTS_CLICKED: 'newDashBoard/threeDotsClicked',

  /** Upload Supply Sheet Events */
  UPLOAD_SUPPLY_SHEET_CLICKED: 'newDashBoard/upload_supply_sheet_clicked',
  UPLOAD_SUPPLY_SHEET_SUCCESS: 'newDashBoard/upload_supply_sheet_success',
  DOWNLOAD_SAMPLE_FILE: 'newDashBoard/download_sample_file',
  SELECT_TEMPLATE: 'newDashBoard/select_template',
  VIEW_UPLOADED_FILES: 'newDashBoard/view_uploaded_files',
  TEMPLATE_CLICKED: 'newDashBoard/template_clicked',
  CREATE_NEW_TEMPLATE_CLICKED: 'newDashBoard/create_new_template_clicked',
  DOWNLOAD_TEMPLATE: 'newDashBoard/downloadTemplate',
  USE_THIS_TEMPLATE: 'newDashBoard/useThisTemplate',
  BACK_TO_TEMPLATE_SELECTION: 'newDashBoard/back_to_template_selection',
  EDIT_ICON_CLICKED: 'newDashBoard/edit_icon',
  TRASH_ICON_CLICKED: 'newDashBoard/trash_icon',
  ADD_ITEM_DETAILS: 'newDashBoard/edit_icon_clicked',
  CANCEL_DELETE: 'newDashBoard/cancel_delete',
  DELETION_FAILED: 'newDashBoard/deletion_failed',
  DELETION_SUCCESS: 'newDashBoard/deletion_success',
  DELETE_CLICKED: 'newDashBoard/delete_clicked',
  VIEW_ERROR: 'newDashBoard/view_error_clicked',
  ADD_TO_MULTISTAFF: 'newDashBoard/add_to_multistaff_clicked',
  ADD_TO_STAFF: 'newDashBoard/add_to_staff_clicked',
  ADD_TO_STAFF_ERROR_DRAWER: 'newDashBoard/add_to_staff_error_drawer_clicked',
  THREE_DOTS_VIEW_ERROR: 'newDashBoard/three_dots_view_error_clicked',
  THREE_DOTS_ADD_TO_MULTISTAFF: 'newDashBoard/three_dots_add_to_multistaff_clicked',
  THREE_DOTS_ADD_TO_STAFF: 'newDashBoard/three_dots_add_to_staff_clicked',
  THREE_DOTS_UPLOAD_GST_QR: 'newDashBoard/upload_gst_qr',
  INCORRECT_FILE_LAYOUT: 'newDashBoard/incorrect_file_layout_clicked',
  TEMPLATE_FORMAT_CHANGE: 'newDashBoard/template_format_change',
  EDIT_TEMPLATE_CTA: 'newDashBoard/edit_template_cta',
  SAVE_TEMPLATE_CTA: 'newDashBoard/save_template_cta',
  APPLY_FORMULA: 'newDashBoard/apply_formula',
  CLOSE_ITEM_DETAILS: 'newDashBoard/close_item_details',
  DOWNLOAD_ERROR_FILE_SUCCESS: 'newDashBoard/download_error_file_success',
  DOWNLOAD_ERROR_FILE_FAILED: 'newDashBoard/download_error_file_failed',
  DOWNLOAD_ERROR_CLICKED: 'newDashBoard/download_error_file_clicked',
  UPLOAD_SUPPLY_SHEET_ARROW_CLICKED: 'newDashBoard/upload_supply_sheet_arrow_clicked',
  ADD_NEW_TEMPLATE_CLICKED: 'newDashBoard/add_new_template_clicked',
  DOWNLOAD_SELECTED_TEMPLATE: 'newDashBoard/downloadSelectedTemplate',

  /** List Creation Events */
  CREATE_NEW_LIST: 'newDashBoard/create_new_list',
  CREATE_NEW_LIST_CONFIRM: 'newDashBoard/create_new_list_confirm',
  UPDATE_LIST_CONFIRM: 'newDashBoard/update_list_confirm',
  SWITCH_TO_BALANCE_DUE: 'newDashBoard/switch_to_balance_due',
  SWITCH_TO_PENDING_INVOICES: 'newDashBoard/switch_to_pending_invoices',
  SWITCH_TO_CUSTOMER_LIST: 'newDashBoard/switch_to_customers_list',

  /** List More (Three Dots) Options Events */
  COPY_LINK_CLICKED: 'newDashBoard/copy_link_clicked',
  PRINT_ASSIGNMENT_CLICKED: 'newDashBoard/print_assignment_clicked',
  ADD_NEW_INVOICE_CLICKED: 'newDashBoard/add_new_invoice_clicked',
  ADD_NEW_CUSTOMER_CLICKED: 'newDashBoard/add_new_customer_clicked',
  ASSIGN_STAFF_CLICKED: 'newDashBoard/assign_staff_clicked',
  SHARE_ON_WHATSAPP_CLICKED: 'newDashBoard/share_on_whatsapp_clicked',
  VIEW_SUMMARY_CLICKED: 'newDashBoard/view_summary_clicked',
  LIST_SETTINGS_CLICKED: 'newDashBoard/list_settings_clicked',
  CLEAR_AND_ADD_NEW_INVOICES_CLICKED: 'newDashBoard/clear_and_add_new_invoices_clicked',
  AUTO_ASSIGN_VIA_TAGS_CLICKED: 'newDashBoard/auto_assign_via_tags_clicked',
  GET_ALL_INVOICES_OF_ACCOUNT_CLICKED: 'newDashBoard/get_all_invoices_of_account_clicked',

  /** List Other Events */
  LIST_NAME_CLICKED: 'newDashBoard/list_name_clicked', // To open list settings from list header on click of list name

  /** Assign Staff Drawer Events */
  STAFF_SEARCH_BAR_FOCUSED: 'newDashBoard/staff_search_bar_focused',
  CONFIRM_STAFF_ASSIGNMENT_CLICKED: 'newDashBoard/confirm_staff_assignment_focused',

  /** Accountant Summary Drawer Events */
  DOWNLOAD_ACCOUNTANT_SUMMARY_CLICKED: 'newDashBoard/download_accountant_summary_clicked',
  DOWNLOAD_ACCOUNTANT_SUMMARY_CONFIRM_CLICKED:
    'newDashBoard/download_accountant_summary_confirm_clicked',

  /** List Settings Drawer Events */
  LIST_SETTINGS_SAVE_CLICKED: 'newDashBoard/list_settings_save_clicked',

  /** Approve Tab Events */
  UNIDENTIFIED_COLLECTION_CLICKED: 'newDashBoard/unidentified_collection_clicked',
  ACTION_PENDING_CLICKED: 'newDashBoard/action_pending_clicked',
  APPROVE_FILTER: 'newDashBoard/approve_filter',
  UNIDENTIFIED_COLLECTION_FILTER: 'newDashBoard/unidentified_collection_filter',
  BULK_APPROVE_INVOICES: 'newDashBoard/bulk_approve_invoices',
  ORDERS_TAKEN: 'newDashBoard/orders_taken',
  ORDERS_APPROVED: 'newDashBoard/orders_approved',
  PROFORMA_INVOICE: 'newDashBoard/proforma_invoice',

  /** Orders Tab Events */
  ORDERS_PENDING_FILTER: 'newDashBoard/orders_pending_filter',
  ORDERS_APPROVAL_FILTER: 'newDashBoard/orders_approval_filter',
  PROFORMA_INVOICE_FILTER: 'newDashBoard/proforma_invoice_filter',

  /** Summary Tab Events */
  SUPPLY_SUMMARY: 'newDashBoard/supply_sumamry',
  COLLECTION_SUMMARY: 'newDashBoard/collection_summary',
  OVERALL_SUMMARY: 'newDashBoard/overall_summary',
  PENDING_DUE: 'newDashBoard/pending_due',
  CASH_BY_STAFF: 'newDashBoard/cash_by_staff',
  APPROVED: 'newDashBoard/approved',
  SUMMARY_FILTER: 'newDashBoard/summary_filter',
  SUPPLY_SUMMARY_FILTER: 'newDashBoard/supply_summary_filter',
  PENDING_DUE_SUMMARY: 'newDashBoard/pending_due_summary',
  PRINT_THIS_PAGE: 'newDashBoard/print_this_page',
  DOWNLOAD_STATEMENT_SUMMARY: 'newDashBoard/download_statement_summary',
  DOWNLOAD_STATEMENT_SUMMARY_SAVE: 'newDashBoard/download_statement_summary_save',
  DOWNLOAD_STATEMENT_SUMMARY_CLOSE: 'newDashBoard/download_statement_summary_close',
  DOWNLOAD_GROUP_STATEMENT_SUMMARY_SAVE: 'newDashBoard/download_statement_summary_save',
  DOWNLOAD_GROUP_STATEMENT_SUMMARY_CLOSE: 'newDashBoard/download_statement_summary_close',
  DOWNLOAD_GROUP_SUMMARY: 'newDashBoard/download_group_summary',
  DOWNLOAD_SUMMARY: 'newDashBoard/download_summary',
  DOWNLOAD_COLLECTION_SINGLE_GROUP_SUMMARY: 'newDashBoard/download_collection_single_group_summary',
  DOWNLOAD_CASH_BY_STAFF: 'newDashBoard/download_cash_by_staff',
  DOWNLOAD_CASH_BY_STAFF_BANK_WISE: 'newDashBoard/download_cash_by_staff_bank_wise',
  DOWNLOAD_PURCHASE_ITEMS_SUMMARY: 'newDashBoard/download_purchase_items_summary',
  DOWNLOAD_INVENTORY_HISTORY_SUMMARY: 'newDashBoard/download_inventory_history_summary',
  DOWNLOAD_COLLECTION_SUMMARY_CLOSE: 'newDashBoard/download_collection_summary_close',
  DOWNLOAD_COLLECTION_SUMMARY_SAVE: 'newDashBoard/download_collection_summary_save',
  DOWNLOAD_EXPENSES: 'newDashBoard/download_expenses',
  REPLACEMENTS: 'newDashBoard/download_replacement_summary',
  DOWNLOAD_REPLACEMENT_SUMMARY_SAVE: 'newDashBoard/download_replacement_summary_save',
  DOWNLOAD_REPLACEMENT_SUMMARY_CLOSE: 'newDashBoard/download_replacement_summary_close',
  DOWNLOAD_TALLY: 'newDashBoard/download_tally',
  EDIT_GROUP: 'newDashBoard/edit_group',
  EDIT_GROUP_NAME: 'newDashBoard/edit_group_name',
  DELETE_GROUP: 'newDashBoard/delete_group',
  CREATE_NEW_GROUP: 'newDashBoard/create_new_group',
  BACK_ARROW: 'newDashBoard/back_arrow',
  GROUP_DRAWER_NO_TAG_CLICKED: 'newDashBoard/group_drawer_no_tag_clicked',
  GROUP_DRAWER_APPLY: 'newDashBoard/group_drawer_apply',
  GROUP_DRAWER_SUBMITTED: 'newDashBoard/group_drawer_submitted',

  /** Reconcile Tab Events */
  COLLECTED_CASH: 'newDashBoard/collected_cash',
  RECEIVED_CHEQUE: 'newDashBoard/received_cheque',
  DEPOSITED_CHEQUE: 'newDashBoard/deposited_cheque',
  BANK_STATEMENT: 'newDashBoard/bank_statement',
  BANK_ASSET: 'newDashBoard/bank_asset',
  DEPOSIT_HISTORY: 'newDashBoard/deposit_history',
  BANK_STATEMENT_RECONCILED: 'newDashBoard/bank_statement_reconciled',

  /** Tag Events */
  ACCOUNT_TAGS_CLICKED: 'newDashBoard/account_tags_clicked',
  ACCOUNT_TAGS_VIEWED: 'newDashBoard/account_tags_viewed',
  UPDATE_ACCOUNT_TAG: 'newDashBoard/update_account_tag',
  INVOICE_TAGS_CLICKED: 'newDashBoard/invoice_tags_clicked',
  INVOICE_BEATS_CLICKED: 'newDashBoard/invoice_beats_clicked',
  INVOICE_TAGS_VIEWED: 'newDashBoard/invoice_tags_viewed',
  INVOICE_BEATS_VIEWED: 'newDashBoard/invoice_beats_viewed',
  UPDATE_INVOICE_TAG: 'newDashBoard/update_invoice_tag',
  UPDATE_INVOICE_BEAT: 'newDashBoard/update_invoice_beat',
  TAG_MANAGEMENT_CLICKED: 'newDashBoard/tag_management_clicked',
  TAG_MANAGEMENT_VIEWED: 'newDashBoard/tag_management_viewed',
  CREATE_TAG: 'newDashBoard/create_tag',
  CREATE_BEAT: 'newDashBoard/create_beat',
  DELETE_TAG: 'newDashBoard/delete_tag',
  EDIT_TAG_NAME: 'newDashBoard/edit_tag_name',

  /** Paid Soon Banner Events */
  CHECK_FOR_PAID_BANNER_SOON: 'newDashBoard/check_for_paid_banner_soon',
  CLOSE_PAID_SOON_BANNER: 'newDashBoard/close_paid_soon_banner',
  SUBMIT_FEEDBACK_PAID_SOON_BANNER: 'newDashBoard/submit_feedback_paid_soon_banner',
  OPEN_PAID_SOON_BANNER: 'newDashBoard/open_paid_soon_banner',

  /** Return/Damage Link and Drawer Events Events */
  RETURN_LINK_CLICKED: 'newDashBoard/return_link_clicked',
  APPROVE_RETURN_DRAWER_VIEWED: 'newDashBoard/approve_return_drawer_viewed',
  APPROVE_RETURN_SUBMITTED: 'newDashBoard/approve_return_submitted',
  DAMAGE_LINK_CLICKED: 'newDashBoard/damage_link_clicked',
  APPROVE_DAMAGE_DRAWER_VIEWED: 'newDashBoard/approve_damage_drawer_viewed',
  APPROVE_DAMAGE_SUBMITTED: 'newDashBoard/approve_damage_submitted',

  /** Bell Notification Events */
  BELL_ICON_CLICKED: 'newdashboard/bell_icon_clicked',

  /** Billing Tab Events */
  BILLING_TAB_CREATE_NEW: 'web_bills_create_bills_clicked',
  BILLING_TAB_CATALOGUE: 'web_bill_catalogue_clicked',
  WEB_BILLS_CLICKED: 'web_bills_clicked',
  WEB_BILLS_CREATE_BILLS_SCREEN_VIEWED: 'web_bills_create_bills_screen_viewed',
  WEB_BILLS_CREATE_BILLS_CLICKED: 'web_bills_create_bills_clicked',
  WEB_BILLS_ADD_CUSTOMER_NAME_CLICKED: 'web_bills_add_customer_name_clicked',
  WEB_BILLS_CUSTOMER_ADDED: 'web_bills_customer_added',
  WEB_BILLS_CREATE_NEW_CUSTOMER_CLICKED: 'web_bills_create_new_customer_clicked',
  WEB_BILLS_CREATE_NEW_CUSTOMER_STARTED: 'web_bills_create_new_customer_started',
  WEB_BILLS_CREATE_NEW_CUSTOMER_NAME_CLICKED: 'web_bills_create_new_customer_name_clicked',
  WEB_BILLS_CREATE_NEW_CUSTOMER_CODE_CLICKED: 'web_bills_create_new_customer_code_clicked',
  WEB_BILLS_CREATE_NEW_CUSTOMER_MOBILE_CLICKED: 'web_bills_create_new_customer_mobile_clicked',
  WEB_BILLS_CREATE_NEW_CUSTOMER_ADDRESS_CLICKED: 'web_bills_create_new_customer_address_clicked',
  WEB_BILLS_CREATE_NEW_CUSTOMER_GST_CLICKED: 'web_bills_create_new_customer_gst_clicked',
  WEB_BILLS_CREATE_NEW_CUSTOMER_ADDITION_SAVED_DETAILS_CLICKED:
    'web_bills_create_new_customer_addition_saved_details_clicked',
  WEB_BILLS_NEW_CUSTOMER_CREATED: 'web_bills_new_customer_created',
  WEB_BILLS_ADD_ITEM_NAME_CLICKED: 'web_bills_add_item_name_clicked',
  WEB_BILLS_ADD_ITEM_FROM_LIST_SELECTED: 'web_bills_add_item_from_list_selected',
  WEB_BILLS_ADD_NEW_ITEM_CLICKED: 'web_bills_add_new_item_clicked',
  WEB_BILLS_ADD_NEW_ITEM_STARTED: 'web_bills_add_new_item_started',
  WEB_BILLS_ADD_NEW_ITEM_ITEM_NAME_CLICKED: 'web_bills_add_new_item_item_name_clicked',
  WEB_BILLS_ADD_NEW_ITEM_HSN_CLICKED: 'web_bills_add_new_item_hsn_clicked',
  WEB_BILLS_ADD_NEW_ITEM_MRP_CLICKED: 'web_bills_add_new_item_mrp_clicked',
  WEB_BILLS_ADD_NEW_ITEM_RATE_CLICKED: 'web_bills_add_new_item_rate_clicked',
  WEB_BILLS_ADD_NEW_ITEM_GST_CLICKED: 'web_bills_add_new_item_gst_clicked',
  WEB_BILLS_ADD_NEW_ITEM_GST_BRACKET: 'web_bills_add_new_item_gst_bracket',
  WEB_BILLS_ADD_NEW_ITEM_CESS_BRACKET: 'web_bills_add_new_item_cess_bracket',
  WEB_BILLS_ADD_NEW_ITEMS_SAVE_DETAILS_CLICKED: 'web_bills_add_new_items_save_details_clicked',
  WEB_BILLS_ADD_NEW_ITEMS_SAVE_DETAILS_SUCCESS: 'web_bills_add_new_items_save_details_success',
  WEB_BILLS_ADD_NEW_ITEMS_SAVE_DETAILS_FAILED: 'web_bills_add_new_items_save_details_failed',
  WEB_BILLS_WEB_BILLS_ITEM_ADDED: 'web_bills_web_bills_item_added',
  WEB_BILLS_CUSTOMER_MOBILE_CLICKED: 'web_bills_customer_mobile_clicked',
  WEB_BILLS_CUSTOMER_ADDRESS_CLICKED: 'web_bills_customer_address_clicked',
  WEB_BILLS_INVOICE_PREFIX_CLICKED: 'web_bills_invoice_prefix_clicked',
  WEB_BILLS_INVOICE_NUMBER_CLICKED: 'web_bills_invoice_number_clicked',
  WEB_BILLS_INVOICE_DATE_CLICKED: 'web_bills_invoice_date_clicked',
  WEB_BILLS_DISCOUNT_CLICKED: 'web_bills_discount_clicked',
  WEB_BILLS_PREVIEW_CLICKED: 'web_bills_preview_clicked',
  WEB_BILLS_PREVIEW_VIEWED: 'web_bills_preview_viewed',
  WEB_BILLS_PREVIEW_API_FAILED: 'web_bills_preview_api_failed',
  WEB_BILLS_PREVIEW_API_SUCCESS: 'web_bills_preview_api_success',
  WEB_BILLS_MORE_OPTIONS: 'web_bills_more_options',
  WEB_BILLS_BILL_DISCOUNT: 'web_bills_bill_discount',
  WEB_BILLS_SAVE_BILL_CLICKED: 'web_bills_save_bill_clicked',
  WEB_BILLS_SAVE_BILL_SUCCESS: 'web_bills_save_bill_success',
  WEB_BILLS_SAVE_BILL_FAILED: 'web_bills_save_bill_failed',
  WEB_BILL_CLICKED_SAVED_BILLS: 'web_bill_clicked_saved_bills',
  WEB_BILL_CLICKED_SHARE_FEEDBACK: 'web_bill_clicked_share_feedback',
  WEB_BILL_CLICKED_FEEDBACK_SAVE_CLICKED: 'web_bill_clicked_feedback_save_clicked',
  WEB_BILL_CLICKED_FEEDBACK_SAVED_SUCCESSFULLY: 'web_bill_clicked_feedback_saved_successfully',
  WEB_BILL_CLICKED_FEEDBACK_SAVE_FAILED: 'web_bill_clicked_feedback_saving_failed',
  WEB_BILL_CLICKED_SHARE_FEEDBACK_DRAWER_BACK_ARROW_CLICKED:
    'web_bill_clicked_share_feedback_drawer_back_arrow',
  WEB_BILLS_SAVED_BILLS_SCREEN_VIEWED: 'web_bills_saved_bills_screen_viewed',
  WEB_BILLS_SAVED_BILLS_SCREEN_DELETED_BILLS_CLICKED:
    'web_bills_saved_bills_screen_deleted_bills_clicked',
  WEB_BILLS_SAVED_BILLS_SCREEN_PRINT_BILLS_CLICKED:
    'web_bills_saved_bills_screen_print_bills_clicked',
  WEB_BILLS_SAVED_BILLS_SCREEN_PRINT_BILLS_SUCCESS:
    'web_bills_saved_bills_screen_print_bills_success',
  WEB_BILLS_SAVED_BILLS_SCREEN_PRINT_BILLS_FAILED:
    'web_bills_saved_bills_screen_print_bills_failed',
  WEB_BILLS_ADD_ITEM_HSN_CODE_VALIDATED: 'web_bills_add_item_hsn_code_validated',
  WEB_BILLS_ITEM_DELETED: 'web_bills_item_deleted',
  WEB_BILLS_DISCOUNT_TYPE_CLICKED: 'web_bills_discount_type_clicked',
  WEB_BILLS_DOWNLOAD_SAMPLE_FILE_CLICKED: 'web_bills_download_sample_file_clicked',
  WEB_BILLS_DOWNLOAD_INVENTORY_SAMPLE_FILE_CLICKED:
    'web_bills_download_inventory_sample_file_clicked',
  WEB_BILLS_UPLOAD_CATALOGUE_CLICKED: 'web_bills_upload_catalogue_clicked',
  WEB_BILLS_UPLOAD_ADD_INVENTORY_CLICKED: 'web_bills_upload_add_catalogue_clicked',
  WEB_BILLS_UPLOAD_REPLACE_INVENTORY_CLICKED: 'web_bills_upload_replace_catalogue_clicked',
  WEB_BILL_CLICKED_ADD_ITEM: 'web_bill_clicked_add_item',
  WEB_BILLS_EDIT_BILLS_SCREEN_VIEWED: 'web_bills_edit_bills_screen_viewed',
  WEB_BILLS_UPDATE_BILL_SUCCESS: 'web_bills_update_bill_success',
  WEB_BILLING_TAB_INVOICE_ITEMS: 'web_billing_tab_invoice_items',
  WEB_BILLING_TAB_PURCHASE_ITEMS: 'web_billing_tab_purchase_items',
  WEB_BILLING_TAB_INVENTORY: 'web_billing_tab_inventory',
  WEB_BILLS_ADD_GST_OPEN_DRAWER: 'web_bills_add_gst_open_drawer',
  WEB_BILLS_ADD_BUSINESS_GST_OPEN_DRAWER: 'web_bills_add_business_gst_open_drawer',
  WEB_BILLS_ADD_DISCOUNT_CLICKED: 'web_bills_add_discount_clicked',
  WEB_BILLS_ADDED_DISCOUNT_CLICKED: 'web_bills_added_discount_clicked',
  WEB_BILLS_DISCOUNT_DRAWER_VIEWED: 'web_bills_discount_drawer_viewed',
  WEB_BILLS_CREATE_NEW_DISCOUNT_CLICKED: 'web_bills_create_new_discount_clicked',
  WEB_BILLS_CREATE_NEW_DISCOUNT_SAVED: 'web_bills_create_new_discount_saved',
  WEB_BILLS_DISCOUNT_CHANGES_SUBMITTED: 'web_bills_discount_changes_submitted'
}
