import { SetStateAction } from 'react'
import { Action, ActionWithHistory, IHistory } from 'infra/types'
import {
  CreateInvoicePayload,
  FetchMultipleCatalogueItems,
  FetchOrdersPayload,
  IOrdersState,
  IPendingOrdersRowData,
  ORDERS_ENTITY,
  OrderItem,
  OrderItems,
  OrdersRowData,
  PendingOrdersFilterType,
  ProformaRowData,
  SORT_OPTION,
  SettlementPayload,
  UpdateMultipleOrderPayload,
  UpdateOrderPayload,
  UpdateProformaStatusPayload
} from 'state/new-summary/orders/orders.types'
import {
  BillsType,
  CustomerDetails,
  InvoiceDetails,
  ItemsDataType,
  NewItemDetailEntity
} from 'state/billing/billing.types'
import {
  SelectedChargeType,
  SelectedDiscountType
} from 'pages/NewDashboard/components/Billing/hooks/constant'

export const ordersAction = {
  FETCH_ORDERS: 'orders/FETCH_ORDERS',
  SET_ORDERS_ACTIONS_FILTER: 'orders/SET_ORDERS_ACTIONS_FILTER',
  SET_PENDING_ORDERS_SELECTED: 'orders/SET_PENDING_ORDERS_SELECTED',
  SET_ORDERS_DRAWER: 'orders/SET_ORDERS_DRAWER',
  SET_ORDERS_DATA: 'orders/SET_ORDERS_DATA',
  SET_ORDER_LOADERS: 'orders/SET_ORDER_LOADERS',
  UPDATE_ORDERS: 'orders/UPDATE_ORDERS',
  SET_CREATE_BILL_FROM_ORDERS: 'orders/SET_CREATE_BILL_FROM_ORDERS',
  SET_SELECTED_ORDER_DETAILS: 'orders/SET_SELECTED_ORDER_DETAILS',
  FETCH_SELECTED_ORDER_DETAILS: 'orders/FETCH_SELECTED_ORDER_DETAILS',
  FETCH_SELECTED_ITEMS_DETAILS: 'orders/FETCH_SELECTED_ITEMS_DETAILS',
  SET_ORDER_SEARCH_VALUE: 'orders/SET_ORDER_SEARCH_VALUE',
  SET_ITEM_DETAILS_DATA: 'orders/SET_ITEM_DETAILS_DATA',
  SET_SELECTED_ITEM_INDICES: 'orders/SET_SELECTED_ITEM_INDICES',
  SET_PAYABLE_AMOUNT: 'orders/SET_PAYABLE_AMOUNT',
  SET_SELECTED_DISCOUNTS: 'orders/SET_SELECTED_DISCOUNTS',
  SET_SELECTED_CHARGES: 'orders/SET_SELECTED_CHARGES',
  SET_ORDERED_CUSTOMER_DETAILS: 'orders/SET_ORDERED_CUSTOMER_DETAILS',
  SET_ORDER_INVOICE_DETAILS: 'orders/SET_ORDER_INVOICE_DETAILS',
  SET_ORDER_DISCOUNT_AMOUNT: 'orders/SET_ORDER_DISCOUNT_AMOUNT',
  UPDATE_MULTIPLE_ORDERS: 'orders/UPDATE_MULTIPLE_ORDERS',
  CREATE_AND_INVOICE_ORDER: 'orders/CREATE_AND_INVOICE_ORDER',
  SET_APPROVED_ORDERS_DATA: 'orders/SET_APPROVED_ORDERS_DATA',
  SET_APPROVED_ORDERS_ACTIONS_FILTER: 'orders/SET_APPROVED_ORDERS_ACTIONS_FILTER',
  SET_APPROVED_ORDERS_SELECTED: 'orders/SET_APPROVED_ORDERS_SELECTED',
  DOWNLOAD_PICK_LIST: 'orders/DOWNLOAD_PICK_LIST',
  FETCH_ITEM_DETAILS_BY_ORDER: 'orders/FETCH_ITEM_DETAILS_BY_ORDER',
  SET_SELECTED_MULTIPLE_ORDER_DETAILS: 'orders/SET_SELECTED_MULTIPLE_ORDER_DETAILS',
  SET_CREATE_PROFORMA_FROM_ORDER: 'orders/SET_CREATE_PROFORMA_FROM_ORDER',
  CREATE_PROFORMA: 'orders/CREATE_PROFORMA',
  SET_PROFORMA_INVOICES_SELECTED: 'orders/SET_PROFORMA_INVOICES_SELECTED',
  SET_PROFORMA_INVOICES_ACTIONS_FILTER: 'orders/SET_PROFORMA_INVOICES_ACTIONS_FILTER',
  SET_PROFORMA_INVOICES_DATA: 'orders/SET_PROFORMA_INVOICES_DATA',
  SET_ORDERS_ACTIVE_ENTITY: 'orders/SET_ORDERS_ACTIVE_ENTITY',
  CREATE_INVOICE_PROFORMA: 'orders/CREATE_INVOICE_PROFORMA',
  SET_SELECTED_PROFORMA_DETAILS: 'orders/SET_SELECTED_PROFORMA_DETAILS',
  CREATE_ORDER_PROFORMA_SETTLEMENT: 'orders/CREATE_ORDER_PROFORMA_SETTLEMENT',
  UPDATE_PROFORMA_STATUS: 'orders/UpdateProformaStatusPaylod',
  PRINT_PROFORMA_MULTIPLE: 'orders/PRINT_PROFORMA_MULTIPLE',
  SET_PRINT_PROFORMA_HTML: 'orders/SET_PRINT_PROFORMA_HTML',
  DOWNLOAD_PROFORMA_PICK_LIST: 'orders/DOWNLOAD_PROFORMA_PICK_LIST',
  PRINT_ORDERS_MULTIPLE: 'orders/PRINT_ORDERS_MULTIPLE',
  SET_PRINT_ORDERS_HTML: 'orders/SET_PRINT_ORDERS_HTML',
  SET_CREATE_BILL_FROM_PROFORMA: 'orders/SET_CREATE_BILL_FROM_PROFORMA',
  SHARE_PROFORMA_MULTIPLE: 'orders/SHARE_PROFORMA_MULTIPLE',
  RESET_DEFAULT_VALUES: 'orders/RESET_DEFAULT_VALUES'
}

export const fetchOrdersAction = (payload: FetchOrdersPayload): Action<FetchOrdersPayload> => ({
  type: ordersAction.FETCH_ORDERS,
  payload
})

export const setPendingOrdersActionFilters = (payload: {
  filter: PendingOrdersFilterType
  sortOption?: SORT_OPTION
}): Action<{ filter: PendingOrdersFilterType }> => {
  return {
    type: ordersAction.SET_ORDERS_ACTIONS_FILTER,
    payload
  }
}

export const setPendingOrdersSelected = (payload: {
  status: boolean
  orderIds: number[]
}): Action<{ status: boolean; orderIds: number[] }> => {
  return {
    type: ordersAction.SET_PENDING_ORDERS_SELECTED,
    payload
  }
}

export const setOrdersDrawer = (payload: {
  id: keyof IOrdersState['drawers']
  value: boolean
}): Action<{
  id: string
  value: boolean
}> => ({
  type: ordersAction.SET_ORDERS_DRAWER,
  payload
})

export const setOrdersDataAction = (
  payload: Array<IPendingOrdersRowData>
): Action<Array<IPendingOrdersRowData>> => ({
  type: ordersAction.SET_ORDERS_DATA,
  payload
})

export const setOrdersLoaders = (payload: {
  loaderName: keyof IOrdersState['loaders']
  status: boolean
}): Action<{
  loaderName: keyof IOrdersState['loaders']
  status: boolean
}> => ({
  type: ordersAction.SET_ORDER_LOADERS,
  payload
})

export const updateOrdersAction = (payload: UpdateOrderPayload): Action<UpdateOrderPayload> => ({
  type: ordersAction.UPDATE_ORDERS,
  payload
})

export const updateProformaStatusAction = (
  payload: UpdateProformaStatusPayload[]
): Action<UpdateProformaStatusPayload[]> => ({
  type: ordersAction.UPDATE_PROFORMA_STATUS,
  payload
})

export const updateMultipleOrdersAction = (
  payload: UpdateMultipleOrderPayload
): Action<UpdateMultipleOrderPayload> => ({
  type: ordersAction.UPDATE_MULTIPLE_ORDERS,
  payload
})

export const setCreateBillFromOrders = (payload: boolean): Action<boolean> => ({
  type: ordersAction.SET_CREATE_BILL_FROM_ORDERS,
  payload
})

export const setCreateBillFromProforma = (payload: boolean): Action<boolean> => ({
  type: ordersAction.SET_CREATE_BILL_FROM_PROFORMA,
  payload
})

export const setCreateProformaFromOrder = (payload: boolean): Action<boolean> => ({
  type: ordersAction.SET_CREATE_PROFORMA_FROM_ORDER,
  payload
})

export const setSelectedOrderAction = (payload: {
  order: OrdersRowData
  bills?: BillsType[]
  isCreateProformaFromOrder?: boolean
}): Action<{
  order: OrdersRowData
  bills?: BillsType[]
  isCreateProformaFromOrder?: boolean
}> => ({
  type: ordersAction.SET_SELECTED_ORDER_DETAILS,
  payload
})

export const fetchSelectedOrderItemsAction = (
  payload: IPendingOrdersRowData,
  isCreateProformaFromOrder?: boolean
): Action<{ item: IPendingOrdersRowData; isCreateProformaFromOrder?: boolean }> => ({
  type: ordersAction.FETCH_SELECTED_ORDER_DETAILS,
  payload: {
    item: payload,
    isCreateProformaFromOrder
  }
})

export const setOrderSearchValueAction = (
  payload: SetStateAction<string>
): Action<SetStateAction<string>> => ({
  type: ordersAction.SET_ORDER_SEARCH_VALUE,
  payload
})

export const setOrderItemsDataAction = (
  payload: Array<NewItemDetailEntity>
): Action<Array<NewItemDetailEntity>> => ({
  type: ordersAction.SET_ITEM_DETAILS_DATA,
  payload
})

export const setOrderBillPayableAmount = (payload: number): Action<number> => ({
  type: ordersAction.SET_PAYABLE_AMOUNT,
  payload
})

export const setSelectedItemIndices = (payload: number[]): Action<number[]> => ({
  type: ordersAction.SET_SELECTED_ITEM_INDICES,
  payload
})

export const setOrderSelectedDiscountsAction = (
  payload: SelectedDiscountType[]
): Action<SelectedDiscountType[]> => ({
  type: ordersAction.SET_SELECTED_DISCOUNTS,
  payload
})

export const setOrderSelectedChargesAction = (
  payload: SelectedChargeType[]
): Action<SelectedChargeType[]> => ({
  type: ordersAction.SET_SELECTED_CHARGES,
  payload
})

export const setCustomerDetailsAction = (
  customerDetails: CustomerDetails
): Action<CustomerDetails> => ({
  type: ordersAction.SET_ORDERED_CUSTOMER_DETAILS,
  payload: customerDetails
})

export const setOrderInvoiceDetailsAction = (
  invoiceDetails: InvoiceDetails
): Action<InvoiceDetails> => ({
  type: ordersAction.SET_ORDER_INVOICE_DETAILS,
  payload: invoiceDetails
})

export const setOrderDiscountAmount = (discount: number): Action<number> => ({
  type: ordersAction.SET_ORDER_DISCOUNT_AMOUNT,
  payload: discount
})

export const createOrderBillAction = (
  payload: CreateInvoicePayload
): Action<CreateInvoicePayload> => ({
  type: ordersAction.CREATE_AND_INVOICE_ORDER,
  payload
})

export const createOrderProformaAction = (
  payload: CreateInvoicePayload
): Action<CreateInvoicePayload> => ({
  type: ordersAction.CREATE_PROFORMA,
  payload
})

export const setApprovedOrdersDataAction = (
  payload: Array<IPendingOrdersRowData>
): Action<Array<IPendingOrdersRowData>> => ({
  type: ordersAction.SET_APPROVED_ORDERS_DATA,
  payload
})

export const setApprovedOrdersActionFilters = (payload: {
  filter: PendingOrdersFilterType
  sortOption?: SORT_OPTION
}): Action<{ filter: PendingOrdersFilterType }> => {
  return {
    type: ordersAction.SET_APPROVED_ORDERS_ACTIONS_FILTER,
    payload
  }
}

export const setApprovedOrdersSelected = (payload: {
  status: boolean
  orderIds: number[]
}): Action<{ status: boolean; orderIds: number[] }> => {
  return {
    type: ordersAction.SET_APPROVED_ORDERS_SELECTED,
    payload
  }
}

export const downloadPickList = (payload: {
  inventoryItems: OrderItems[]
  orderCount?: number
}): Action<{
  inventoryItems: OrderItems[]
  orderCount?: number
}> => {
  return {
    type: ordersAction.DOWNLOAD_PICK_LIST,
    payload
  }
}

export const downloadProformaPickList = (payload: {
  inventoryItems: ItemsDataType[]
  orderCount?: number
}): Action<{
  inventoryItems: ItemsDataType[]
  orderCount?: number
}> => {
  return {
    type: ordersAction.DOWNLOAD_PROFORMA_PICK_LIST,
    payload
  }
}

export const fetchItemsCatalogueByOrdersAction = (
  payload: FetchMultipleCatalogueItems
): Action<FetchMultipleCatalogueItems> => ({
  type: ordersAction.FETCH_ITEM_DETAILS_BY_ORDER,
  payload
})

export const setSelectedMultipleOrdersAction = (
  payload: FetchMultipleCatalogueItems
): Action<FetchMultipleCatalogueItems> => ({
  type: ordersAction.SET_SELECTED_MULTIPLE_ORDER_DETAILS,
  payload
})

export const setProformaInvoicesSelected = (payload: {
  status: boolean
  orderIds: string[]
}): Action<{ status: boolean; orderIds: string[] }> => {
  return {
    type: ordersAction.SET_PROFORMA_INVOICES_SELECTED,
    payload
  }
}

export const setProformaInvoicesActionFilters = (payload: {
  filter: PendingOrdersFilterType
  sortOption?: SORT_OPTION
}): Action<{ filter: PendingOrdersFilterType }> => {
  return {
    type: ordersAction.SET_PROFORMA_INVOICES_ACTIONS_FILTER,
    payload
  }
}

export const setProformaInvoicesDataAction = (
  payload: Array<ProformaRowData>
): Action<Array<ProformaRowData>> => ({
  type: ordersAction.SET_PROFORMA_INVOICES_DATA,
  payload
})

export const setActiveOrderEntity = (payload: ORDERS_ENTITY): Action<ORDERS_ENTITY> => ({
  type: ordersAction.SET_ORDERS_ACTIVE_ENTITY,
  payload
})

export const createInvoiceFromProformaAction = (
  payload: ProformaRowData
): Action<ProformaRowData> => ({
  type: ordersAction.CREATE_INVOICE_PROFORMA,
  payload
})

export const setSelectedProformaDetails = (
  payload: ProformaRowData[]
): Action<ProformaRowData[]> => ({
  type: ordersAction.SET_SELECTED_PROFORMA_DETAILS,
  payload
})

export const createOrderProformaSettlementAction = (
  payload: SettlementPayload
): Action<SettlementPayload> => ({
  type: ordersAction.CREATE_ORDER_PROFORMA_SETTLEMENT,
  payload
})

export const printProformaAction = (payload: string[]): Action<string[]> => ({
  type: ordersAction.PRINT_PROFORMA_MULTIPLE,
  payload
})

export const shareProformaAction = (payload: string[]): Action<string[]> => ({
  type: ordersAction.SHARE_PROFORMA_MULTIPLE,
  payload
})

export const setProformaInvoicesHtmlAction = (payload: string[]): Action<string[]> => ({
  type: ordersAction.SET_PRINT_PROFORMA_HTML,
  payload
})

export const printOrdersAction = (payload: string[]): Action<string[]> => ({
  type: ordersAction.PRINT_ORDERS_MULTIPLE,
  payload
})

export const setOrdersHtmlAction = (payload: string[]): Action<string[]> => ({
  type: ordersAction.SET_PRINT_ORDERS_HTML,
  payload
})

export const fetchOrderItemsAction = (payload: {
  itemIds: string[]
  orderItem?: IPendingOrdersRowData
  proformaItem?: ProformaRowData
}): Action<{
  itemIds: string[]
  orderItem?: IPendingOrdersRowData
  proformaItem?: ProformaRowData
}> => ({
  type: ordersAction.FETCH_SELECTED_ITEMS_DETAILS,
  payload
})

export const resetDefaultValuesAction = (): Action<null> => ({
  type: ordersAction.RESET_DEFAULT_VALUES,
  payload: null
})
