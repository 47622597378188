import dayjs from 'dayjs'
import {
  IBankHistoryType,
  ICashDepositHistory
} from 'state/new-summary/bankStatement/bankStatement.types'

enum BankType {
  inventory = 'Inventory',
  cash = 'Cash',
  payment_gateway = 'Payment Gateway',
  bank = 'Bank',
  mcash = 'M-Cash',
  upi = 'UPI'
}

export const formatBankCashDepositHistory = (
  depositData: Array<ICashDepositHistory>,
  type: IBankHistoryType
) => {
  if (type === IBankHistoryType.CASH) {
    return depositData.map((_) => ({
      'Deposit Date': _.deposit_date
        ? dayjs(Number(_.deposit_date) * 1000).format('DD MMM, YYYY')
        : '-',
      'Depositor Name': _.depositor_name,
      Amount: _.amount / 100,
      'Depositor Type': _.depositor_type,
      Notes: _.notes,
      Status: _.approved
    }))
  }
  return depositData.map((_) => ({
    'Deposit Date': _.deposit_date
      ? dayjs(Number(_.deposit_date) * 1000).format('DD MMM, YYYY')
      : '-',
    'Depositor Name': _.depositor_name,
    'Bank Name': _.bank_name,
    'Account Number': _.account_number,
    'IFSC CODE': _.ifsc,
    Amount: _.amount / 100,
    'Depositor Type': _.depositor_type,
    type: _.source_type in BankType ? BankType[_.source_type] : '-',
    Notes: _.notes,
    Status: _.approved
  }))
}
