import dayjs from 'dayjs'
import { StatesCodeMapper } from 'shared/constants'
import { INVENTORY_ITEM_TYPE_V2, OFFER_RESPONSE } from 'services/Api/BillingApiFolder.ts/type'
import {
  IOrderBillRequestPayload,
  IOrderProformaRequestPayload
} from 'services/Api/StaffLinkApiFolder/type'
import {
  OLD_BILL_DISCOUNT_NAME,
  billingRoundFunction,
  chooseBestOffer,
  computeSGSTandCGSTPercent,
  computeTaxPercentFromRateAndTaxValue,
  convertPaisaToRupee,
  convertRupeeToPaisa,
  formatBillItemsData,
  formatSelectedCharges,
  formatSelectedDiscounts,
  handleOfferInPayload,
  matchCustomerGstStateCodeWithBusinessStateCode,
  matchStateOfSupplyWithBusinessStateCode
} from 'state/billing/billing.helpers'
import {
  CustomerDetails,
  IUploadAction,
  ItemsDataType,
  NewItemDetailEntity,
  TAX_TYPE
} from 'state/billing/billing.types'
import { ICustomer } from 'state/dashboard/dashboard.types'
import {
  FetchOrdersApiResponse,
  IPendingOrdersRowData,
  ItemsResponse,
  OrderItem,
  OrderItems,
  OrderedByMapper,
  OrdersResponse,
  OrdersRowData,
  ProformaResponse,
  ProformaRowData
} from 'state/new-summary/orders/orders.types'
import { convertDayToEpocStart } from 'utils/dateTransformer'

const formattedItems = (items: ItemsResponse[]) => {
  return items.map((_) => ({
    itemId: _.inventory_item_id,
    inventoryItemId: _.inventory_item_id,
    catalogueItemId: _.catalog_item_id,
    quantity: _.quantity,
    createTime: _.create_time,
    updateTime: _.update_time,
    amount: _.amount,
    proformaInvoicedQuantity: _.proforma_invoiced_quantity,
    invoicedQuantity: _.invoiced_quantity,
    info: _.info
      ? {
          name: _.info.name,
          rate: convertPaisaToRupee(Number(_.info.rate || 0)),
          quantity: Number(_.info.quantity),
          tax: convertPaisaToRupee(_.info.tax || 0),
          amount: convertPaisaToRupee(_.info.amount),
          catalogItemId: _.info.catalog_item_id,
          discount: convertPaisaToRupee(_.info.discount),
          taxComponents: _.info.tax_components,
          unitOffer: _.info.unit_offer
        }
      : null
  }))
}

export const getFormattedOrderData = (
  order: OrdersResponse,
  customerInfo: { [id: string]: ICustomer }
): OrdersRowData => {
  return {
    orderId: order.id,
    orderNumber: order.order_number,
    orderAmount: order.amount,
    orderDate: order.create_time,
    orderedBy: order.created_by_id,
    orderedByType: order.created_by_type,
    customerName: customerInfo?.[order.account_id]?.description || '',
    customerId: order.account_id,
    customerBalance: customerInfo[order.account_id]?.balance || undefined,
    customer: {
      customerName: customerInfo?.[order.account_id]?.description || '',
      customerId: order.account_id,
      customerMobile: customerInfo?.[order.account_id]?.mobile || '',
      customerAddress: customerInfo?.[order.account_id]?.address || '',
      customerGST: customerInfo?.[order.account_id]?.customerGST || '',
      customerCode: customerInfo?.[order.account_id]?.customerCode || '',
      customerStateSupplyCode: customerInfo?.[order.account_id]?.supply_state_code || ''
    },
    accountId: order.account_id || '',
    id: order.id,
    items: formattedItems(order.inventory_items),
    proformaIds: order.proforma_ids,
    updateTime: order.update_time
  }
}

export const getFormattedOrders = (
  data: FetchOrdersApiResponse['orders'],
  customerInfo: { [id: string]: ICustomer }
): Array<IPendingOrdersRowData> => {
  if (!data || data.length === 0) {
    return []
  }
  return data.map((order) => getFormattedOrderData(order, customerInfo))
}

export const getUpdatedItemValuesFilled = () => {}

export const getFormattedProformaData = (
  proforma: ProformaResponse
): Omit<ProformaRowData, 'orderDetails'> => {
  return {
    proformaId: proforma.proforma_id,
    proformaAmount: convertPaisaToRupee(proforma.proforma_amount),
    proformaDate: proforma.proforma_date,
    buyerId: proforma.buyer_id,
    buyerInfo: {
      name: proforma.buyer_info?.name,
      address: proforma.buyer_info?.address,
      gstNumber: proforma.buyer_info?.gst_number,
      mobile: proforma.buyer_info?.mobile
    },
    buyerType: proforma.buyer_type,
    referenceId: proforma.reference_id,
    referenceSource: proforma.reference_source,
    sellerId: proforma.seller_id,
    sellerInfo: proforma.seller_info,
    sellerType: proforma.seller_type,
    status: proforma.status,
    proformaNumber: proforma.proforma_number,
    selectedDiscounts: formatSelectedDiscounts(proforma.proforma_info.discounts || []),
    selectedCharges: formatSelectedCharges(proforma.proforma_info.add_charges || []),
    proformaItems: formatBillItemsData(proforma.proforma_info.items)
  }
}

export const getFormattedProformaInvoices = (
  orders: FetchOrdersApiResponse['orders'],
  proforma: FetchOrdersApiResponse['proforma'],
  customerInfo: { [id: string]: ICustomer }
): Array<ProformaRowData> => {
  if (!orders || orders.length === 0) {
    return []
  }
  return orders.flatMap((order) => {
    const orderDetails = getFormattedOrderData(order, customerInfo)
    const proformaDetails = proforma.filter((proformaItem) =>
      orderDetails.proformaIds.includes(proformaItem.proforma_id)
    )

    return proformaDetails.map((proformaItem) => ({
      orderDetails,
      ...getFormattedProformaData(proformaItem as ProformaResponse)
    }))
  })
}

export const getFormattedItemDetails = (
  items: { item: INVENTORY_ITEM_TYPE_V2; offers?: OFFER_RESPONSE[] }[],
  selectedOrderItems: IPendingOrdersRowData['items'],
  customerDetails?: CustomerDetails,
  applyAllApplicableOffers?: boolean
): Array<NewItemDetailEntity & { inventoryItemId: string; amountPayable: number }> => {
  return items.map((_item) => {
    const item = _item.item
    const proformaInvoicedQuantity =
      selectedOrderItems.find((selectedItem) => selectedItem.itemId === item.item_id)
        ?.proformaInvoicedQuantity || 0
    const invoicedQuantity =
      selectedOrderItems.find((selectedItem) => selectedItem.itemId === item.item_id)
        ?.invoicedQuantity || 0
    const updatedQuantity = selectedOrderItems.reduce((prev, curr) => {
      if (curr.inventoryItemId === item.item_id) {
        return prev + curr.quantity
      }
      return prev
    }, 0)
    const updatedRate =
      selectedOrderItems.find((_selectedOrder) => _selectedOrder?.itemId === item.item_id)?.info
        ?.rate || 0
    const totalProcessedQuantity = proformaInvoicedQuantity + invoicedQuantity
    const calcQuantity = updatedQuantity - totalProcessedQuantity
    const quantity = calcQuantity < 0 ? 0 : calcQuantity
    const {
      catalog_info: {
        rate: rateInPaisa,
        gst: { basis_pts: gstBasisPoint } = { basis_pts: 0 },
        cess: { basis_pts: cessBasisPoint } = { basis_pts: 0 },
        image,
        sku: skuId
      }
    } = item
    const rate = updatedRate || convertPaisaToRupee(rateInPaisa)

    const { discount, appliedOfferId } = chooseBestOffer(
      _item.offers,
      rate,
      quantity,
      customerDetails,
      applyAllApplicableOffers
    )
    const effectiveRate = rate - discount
    const effectiveRateInPaisa = convertRupeeToPaisa(effectiveRate)

    const gstAmount = effectiveRateInPaisa * (gstBasisPoint / 100 / 100)
    const cessAmount = effectiveRateInPaisa * (cessBasisPoint / 100 / 100)

    const gstAmountInRupee = convertPaisaToRupee(gstAmount)
    const cessAmountInRupee = convertPaisaToRupee(cessAmount)
    const { taxPercent: gstPercent } = computeTaxPercentFromRateAndTaxValue(
      effectiveRate,
      gstAmountInRupee
    )
    const { taxPercent: cessPercent } = computeTaxPercentFromRateAndTaxValue(
      effectiveRate,
      cessAmountInRupee
    )

    const { sgstPercent, cgstPercent } = computeSGSTandCGSTPercent(gstPercent)

    const netRateInPaisa = effectiveRateInPaisa * (1 + (gstPercent + cessPercent) / 100)
    const netRateInPaisaWithOnlyGst = effectiveRateInPaisa * (1 + gstPercent / 100)

    const taxAmountInPaisa = netRateInPaisa - effectiveRateInPaisa
    const gstTaxAmountInPaisa = netRateInPaisaWithOnlyGst - effectiveRateInPaisa
    const amount = billingRoundFunction(netRateInPaisa) * Number(quantity || 1)

    const obj = {
      item: item.catalog_info.name,
      itemId: item.catalog_item_id, // this is supposed to be catalogue_item_id
      inventoryItemId: item.item_id,
      hsnCode: item.catalog_info.hsn_code,
      mrp: convertPaisaToRupee(item.catalog_info.mrp || 0),
      rate,

      discount,
      netRate: convertPaisaToRupee(billingRoundFunction(netRateInPaisa)),
      sgstTaxPercent: sgstPercent,
      cgstTaxPercent: cgstPercent,
      igstPercent: gstPercent,
      gstTaxPercent: gstPercent,
      cessPercent: cessPercent,
      taxAmount: convertPaisaToRupee(taxAmountInPaisa), // gst + cess
      gstTotalAmount: convertPaisaToRupee(billingRoundFunction(gstTaxAmountInPaisa)), //only gst
      quantity,
      amountPayable: convertPaisaToRupee(amount),
      proformaInvoicedQuantity,
      invoicedQuantity,
      image,
      skuId,
      applicableOfferDetails: _item.offers,
      appliedOfferId
    }

    return obj
  })
}

export const getTaxComponents = (
  item: NewItemDetailEntity & { inventoryItemId?: string },
  cgstValue: number,
  sgstValue: number,
  igstValue: number,
  cessValue: number,
  isIGST?: boolean
) => {
  if (item.sgstTaxPercent || item.cgstTaxPercent || item.cessPercent || item.igstPercent) {
    return [
      ...(isIGST
        ? [
            ...(item.igstPercent
              ? [
                  {
                    kind: TAX_TYPE.IGST,
                    basis_pts: billingRoundFunction(item.igstPercent * 100),
                    amount: igstValue
                  }
                ]
              : [{ kind: TAX_TYPE.IGST, basis_pts: 0, amount: 0 }])
          ]
        : [
            ...[
              ...(item.sgstTaxPercent && !isIGST
                ? [
                    {
                      basis_pts: billingRoundFunction(item.sgstTaxPercent * 100),
                      kind: TAX_TYPE.SGST,
                      amount: sgstValue
                    }
                  ]
                : [{ kind: TAX_TYPE.SGST, basis_pts: 0, amount: 0 }]),
              ...(item.cgstTaxPercent && !isIGST
                ? [
                    {
                      basis_pts: billingRoundFunction(item.cgstTaxPercent * 100),
                      kind: TAX_TYPE.CGST,
                      amount: cgstValue
                    }
                  ]
                : [{ kind: TAX_TYPE.CGST, basis_pts: 0, amount: 0 }])
            ]
          ]),
      ...(item.cessPercent
        ? [
            {
              basis_pts: item.cessPercent * 100,
              kind: TAX_TYPE.CESS,
              amount: cessValue
            }
          ]
        : [{ kind: TAX_TYPE.CESS, basis_pts: 0, amount: 0 }])
    ]
  }
  return [{ kind: TAX_TYPE.SGST, basis_pts: 0, amount: 0 }]
}

export const getIsIGST = (
  businessGst: string,
  customerGst?: string,
  customerStateSupplyCode?: string
) => {
  if (
    (customerStateSupplyCode &&
      matchStateOfSupplyWithBusinessStateCode(businessGst, customerStateSupplyCode)) ||
    (customerGst && matchCustomerGstStateCodeWithBusinessStateCode(businessGst, customerGst))
  ) {
    return true
  }
  return false
}

export const getFormattedItemsPayload = (
  itemDetails: (NewItemDetailEntity & {
    inventoryItemId?: string
  })[],
  allItems: Array<OrderItem['addedItemsItem']>,
  customerId: string,
  isIGST: boolean
) => {
  return itemDetails.map((item) => {
    const allItemDetails = allItems.find(
      (storeItem: OrderItem['addedItemsItem']) => storeItem.itemId === item.itemId
    ) as OrderItem['addedItemsItem']

    const { appliedOfferId = '', applicableOfferDetails = [] } = item

    const { unitOffers } = handleOfferInPayload({
      applicableOfferDetails,
      appliedOfferId,
      customerId,
      item: {
        discountInRupee: item.discount || 0,
        rateInRupee: item.rate || 0,
        quantity: item.quantity
      }
    })
    const totalRateWithoutDiscount = convertRupeeToPaisa(item.rate || 0) * item.quantity
    const lineItemDiscount = convertRupeeToPaisa(item.discount || 0) * item.quantity
    const pretaxTotal = totalRateWithoutDiscount - lineItemDiscount
    const cgstValue = billingRoundFunction(pretaxTotal * ((item.cgstTaxPercent || 0) / 100))
    const sgstValue = billingRoundFunction(pretaxTotal * ((item.sgstTaxPercent || 0) / 100))
    const igstValue = isIGST
      ? billingRoundFunction(pretaxTotal * ((item.igstPercent || 0) / 100))
      : 0
    const cessValue = billingRoundFunction(pretaxTotal * ((item.cessPercent || 0) / 100))
    const totalTax = cessValue + (isIGST ? igstValue : cgstValue + sgstValue)

    const lineItemAmount = pretaxTotal + totalTax
    return {
      sku: allItemDetails?.sku || item?.skuId || '',
      inventory_item_id: item.inventoryItemId,
      catalog_item_id: item.itemId,
      quantity: item.quantity,
      amount: lineItemAmount,
      name: item.item,
      unit: allItemDetails?.unit || '',
      rate: convertRupeeToPaisa(item.rate || 0),
      mrp: convertRupeeToPaisa(item.mrp || 0),
      tax: totalTax,
      discount: lineItemDiscount,
      tax_components: getTaxComponents(item, cgstValue, sgstValue, igstValue, cessValue, isIGST),
      ...(item.hsnCode && { hsn_code: item.hsnCode }),
      ...(unitOffers.length && {
        unit_offers: unitOffers
      })
    }
  })
}

export const constructOrderBillRequestPayload = (
  data: IUploadAction,
  allItems: Array<OrderItem['addedItemsItem']>,
  businessGst?: string
): IOrderBillRequestPayload => {
  const {
    customerId,
    customerDetails,
    invoiceDetails,
    itemDetails,
    // discountAmount,
    payableAmount,
    billId,
    billVersion,
    requestId,
    discounts,
    charges
  } = data
  const billNumber = invoiceDetails.invoicePrefix + invoiceDetails.invoiceNumber
  const isIGST = getIsIGST(
    businessGst || '',
    customerDetails.customerGst,
    customerDetails.customerStateSupplyCode
  )
  return {
    ...(requestId && { request_id: requestId }),
    ...(billId && { bill_id: billId }),
    ...(billVersion && { bill_version: billVersion }),
    customer_id: customerId,
    buyer_info: {
      name: customerDetails.customerName,
      address: customerDetails.customerAddress,
      ...(customerDetails.customerGst && { gst_number: customerDetails.customerGst }),
      mobile: customerDetails.customerMobile
    },
    add_to_ledger: true,
    created_at: dayjs().valueOf(),
    bill_number: billNumber,
    bill_date: invoiceDetails.invoiceDate,
    total_amount: convertRupeeToPaisa(payableAmount),
    bill_info: {
      discounts: discounts
        ?.filter((discount) => discount.amount > 0)
        .map((discount) => ({
          id: discount.id,
          name: discount.name || OLD_BILL_DISCOUNT_NAME,
          amount: convertRupeeToPaisa(discount.amount),
          pre_tax: discount.isPreTax,
          is_percent: discount.isPercent,
          basis_pts: billingRoundFunction(discount.basis * 100)
        })),
      add_charges: charges
        ?.filter((charge) => charge.amount > 0)
        .map((charge) => ({
          id: charge.id,
          name: charge.name,
          amount: convertRupeeToPaisa(charge.amount),
          is_percent: charge.isPercent,
          basis_pts: billingRoundFunction(charge.basis * 100)
        })),
      items: getFormattedItemsPayload(itemDetails, allItems, customerId, isIGST),
      ...(customerDetails.customerGst &&
        matchCustomerGstStateCodeWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerGst
        ) && {
          supply_state: {
            code: customerDetails.customerGst.slice(0, 2),
            name: StatesCodeMapper[customerDetails.customerGst.slice(0, 2)]
          }
        }),
      ...(customerDetails.customerStateSupplyCode &&
        matchStateOfSupplyWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerStateSupplyCode
        ) && {
          supply_state: {
            code: customerDetails.customerStateSupplyCode,
            name: StatesCodeMapper[customerDetails.customerStateSupplyCode]
          }
        })
    },
    ...(businessGst && {
      labels: {
        business_gst: businessGst
      }
    })
  }
}

export const getFormattedItemDetailsForDownload = (
  data: INVENTORY_ITEM_TYPE_V2[],
  itemId_vs_quantities: Record<string, number>
) => {
  return data
    .filter((_) => itemId_vs_quantities[_.item_id] !== 0)
    .map((item) => ({
      'Item Name': item.catalog_info.name,
      'Product Code': item.catalog_info.sku,
      Description: item.catalog_info.full_description,
      Quantity: itemId_vs_quantities[item.item_id]
    }))
}

export const getFormattedDownloadDataProforma = (data: ItemsDataType[]) => {
  const itemsUnique: any = {}
  for (const item of data) {
    if (item.itemId in itemsUnique) {
      itemsUnique[item.itemId].Quantity += item.quantity
    } else {
      itemsUnique[item.itemId] = {
        'Item Name': item.item,
        Quantity: item.quantity,
        'HSN Code': item.hsnCode,
        Rate: item.rate,
        GST: item.taxAmount
      }
    }
  }
  return Object.values(itemsUnique)
}

const getDifferenceQuantity = (q1: number, q2: number) => {
  return Math.abs(q1 - q2)
}

export const getItemQuantitiesFromItems = (items: OrderItems[]): Record<string, number> => {
  const itemQuantities: Record<string, number> = {}

  items.forEach((item) => {
    const completedQuantity =
      (item?.invoicedQuantity || 0) + (item?.proformaInvoicedQuantity || 0) || 0
    if (itemQuantities[item.itemId]) {
      itemQuantities[item.itemId] += getDifferenceQuantity(completedQuantity, item.quantity)
    } else {
      itemQuantities[item.itemId] = getDifferenceQuantity(completedQuantity, item.quantity)
    }
  })
  return itemQuantities
}

export const constructOrderProformaRequestPayload = (
  data: IUploadAction,
  allItems: Array<OrderItem['addedItemsItem']>,
  businessGst?: string
): IOrderProformaRequestPayload => {
  const {
    customerId,
    customerDetails,
    invoiceDetails,
    itemDetails,
    // discountAmount,
    payableAmount,
    // billId,
    billVersion,
    requestId,
    discounts,
    charges
  } = data
  const isIGST = getIsIGST(
    businessGst || '',
    customerDetails.customerGst,
    customerDetails.customerStateSupplyCode
  )
  const proformaNumber = invoiceDetails.invoicePrefix + invoiceDetails.invoiceNumber
  return {
    ...(requestId && { request_id: requestId }),
    // ...(billId && { bill_id: billId }),
    ...(billVersion && { bill_version: billVersion }),
    customer_id: customerId,
    buyer_info: {
      name: customerDetails.customerName,
      address: customerDetails.customerAddress,
      ...(customerDetails.customerGst && { gst_number: customerDetails.customerGst }),
      mobile: customerDetails.customerMobile
    },
    // add_to_ledger: true,
    // created_at: dayjs().valueOf(),
    proforma_number: proformaNumber,
    proforma_date: invoiceDetails.invoiceDate || 0,
    proforma_amount: convertRupeeToPaisa(payableAmount),
    // TODO: Move the object creation to a separate function and utilise in  constructOrderBillRequestPayload and constructOrderProformaRequestPayload
    proforma_info: {
      discounts: discounts
        ?.filter((discount) => discount.amount > 0)
        .map((discount) => ({
          id: discount.id,
          name: discount.name || OLD_BILL_DISCOUNT_NAME,
          amount: convertRupeeToPaisa(discount.amount),
          pre_tax: discount.isPreTax,
          is_percent: discount.isPercent,
          basis_pts: billingRoundFunction(discount.basis * 100)
        })),
      add_charges: charges
        ?.filter((charge) => charge.amount > 0)
        .map((charge) => ({
          id: charge.id,
          name: charge.name,
          amount: convertRupeeToPaisa(charge.amount),
          is_percent: charge.isPercent,
          basis_pts: billingRoundFunction(charge.basis * 100)
        })),
      items: getFormattedItemsPayload(itemDetails, allItems, customerId, isIGST),
      ...(customerDetails.customerGst &&
        matchCustomerGstStateCodeWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerGst
        ) && {
          supply_state: {
            code: customerDetails.customerGst.slice(0, 2),
            name: StatesCodeMapper[customerDetails.customerGst.slice(0, 2)]
          }
        }),
      ...(customerDetails.customerStateSupplyCode &&
        matchStateOfSupplyWithBusinessStateCode(
          businessGst || '',
          customerDetails.customerStateSupplyCode
        ) && {
          supply_state: {
            code: customerDetails.customerStateSupplyCode,
            name: StatesCodeMapper[customerDetails.customerStateSupplyCode]
          }
        })
    },
    ...(businessGst && {
      labels: {
        business_gst: businessGst
      }
    })
  }
}
