import { getNormalizedList } from 'utils/dataNormalizer'
import { Action } from '../../infra/types'
import { TagsActions } from './tags.actions'
import { TagsState } from './tags.state'
import { ITagsState } from './tags.types'

export const TagsReducer = (state: ITagsState = TagsState, action: Action<any>): ITagsState => {
  switch (action.type) {
    case TagsActions.FETCH_TAGS_SUCCESS: {
      const normalisedTags = getNormalizedList(action.payload)
      return {
        ...state,
        normalisedTags: normalisedTags
      }
    }
    case TagsActions.CREATE_TAG: {
      return {
        ...state,
        loading: true
      }
    }
    case TagsActions.CREATE_TAG_SUCCESS: {
      const { byIds, allIds } = state.normalisedTags
      const normalisedNewTags = getNormalizedList(action.payload)
      return {
        ...state,
        loading: false,
        normalisedTags: {
          ...state.normalisedTags,
          byIds: { ...byIds, ...normalisedNewTags.byIds },
          allIds: allIds.concat(normalisedNewTags.allIds)
        }
      }
    }
    case TagsActions.CREATE_TAG_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case TagsActions.UPDATE_TAG: {
      return {
        ...state,
        loading: true
      }
    }
    case TagsActions.UPDATE_TAG_SUCCESS: {
      return {
        ...state,
        loading: false,
        normalisedTags: {
          ...state.normalisedTags,
          byIds: {
            ...state.normalisedTags.byIds,
            [action.payload.id]: action.payload
          }
        }
      }
    }
    case TagsActions.UPDATE_TAG_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case TagsActions.FETCH_ACCOUNT_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        accountTags: action.payload
      }
    }
    case TagsActions.LINK_DELINK_ACCOUNT_TAGS: {
      return {
        ...state,
        loading: true
      }
    }
    case TagsActions.LINK_DELINK_ACCOUNT_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }
    case TagsActions.LINK_DELINK_ACCOUNT_TAGS_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case TagsActions.TOGGLE_EDIT_TAG_DIALOG: {
      return {
        ...state,
        showEditTagDialog: action.payload
      }
    }
    case TagsActions.FETCH_INVOICE_TAGS_SUCCESS: {
      return {
        ...state,
        currentInvoiceTags: action.payload
      }
    }
    case TagsActions.LINK_DELINK_INVOICE_TAGS_SUCCESS: {
      return {
        ...state,
        currentInvoiceTags: action.payload
      }
    }
    case TagsActions.HANDLE_DRAWER_TOGGLE: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}
