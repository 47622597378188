import { Path, createBrowserHistory } from 'history'
import { IDispatchType } from 'services/Api/types'

/**
 * Redirect to a specific page after a saga-dispatch is complete.
 * Alternatively, history.goBack() can also be used.
 * @todo Find a better way of handling post-dispatch redirection
 * @param {Path | IDispatchType} location - This can be either a
 * relative path or a navigation intent
 */
export function forwardTo(location: Path | IDispatchType, forceRefresh: boolean = true) {
  const history = createBrowserHistory({
    forceRefresh
  })
  if (location === IDispatchType.GO_BACK) {
    history.goBack()
  } else {
    history.push(location)
  }
}
