// import { getEnvConfig } from 'utils/getEnvConfig'
import { DOCUMENT_TYPE } from 'state/collectionList/collectionList.types'
import { TemplateConfig } from 'state/new-summary/uploadSheet/uploadSheet.types'
import { IUploadCollectionFile } from './types'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor,
  businessIdInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

let endpoint: string = ''
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    endpoint = 'https://ge.staging.okcredit.in'
  } else {
    endpoint = `${window.location.origin}/api/growth-experiments`
  }
}

const UPLOAD_FILE = '/external/ledger'
const UPLOAD_GST_FILE = '/external/gst_qr'
const GET_ALL_UPLOAD_DOCUMENTS = '/external/ledger'
const GET_DOCUMENTS_ACCOUNTS = '/external/accounts'
const GET_DOCUMENTS_BILLS = '/web/v1/external/bills'
const GET_DOCUMENTS_STATUS = '/external/ledger/status'
const GET_DOCUMENTS_TEMPLATE_CONFIG = '/external/ledger/template'
const CREATE_DOCUMENTS_TEMPLATE_CONFIG = '/external/ledger/template'
const UPDATE_DEFAULT_TEMPLATE_CONFIG = '/external/ledger/default-template'
const UPDATE_TEMPLATE_CONFIG = '/web/v1/import/update-template'
const REMOVE_TEMPLATE_CONFIG = '/web/v1/import/delete-template'
const CREATE_ERROR_FILE = '/external/ledger/errorFile'
const GET_ERROR_FILE = '/external/ledger/errorFile/download'
const REST_DOCUMENTS_TEMPLATE_CONFIG = '/external/ledger/template/reset'
const ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT = '/external/ledger/upload-route'
const BULK_UPLOAD_ACCOUNTS = '/external/import/account'
const GET_BULK_UPLOAD_ACCOUNTS_STATUS = '/external/import/account/status'
const PRINT_BILLS = '/external/print-bill'
const PRINT_SUPPLIER_BILLS = '/external/print-supplier-bill'
const PRINT_BILL_CL = '/external/print-bill-cl'
const DOCUMENT_ERRORS = '/external/ledger/errors'
const SET_TEMPLATE_ON_DOCUMENT = '/external/SetTemplateOnDoc'
const GET_TEMPLATE_BY_ID = '/external/FetchTemplateById'
const UPDATE_DOCUMENT_PREFERRED_IDXS = '/external/DocumentResolveFieldConflicts'
const UPDATE_TEMPLATE_PREFERRED_IDXS = '/external/TemplateMergePreferredIdxs'

const api = new ApiService(endpoint, headers)
api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const uploadStaffLinkFile = (formData: IUploadCollectionFile) => {
  return api.postRequest(UPLOAD_FILE, formData)
}

export const uploadGstLinkFile = (formData: IUploadCollectionFile) => {
  return api.postRequest(UPLOAD_GST_FILE, formData)
}

export const printBills = (billIds: string[]) => {
  return api.postRequest(PRINT_BILLS, { bill_ids: billIds })
}

export const printSupplierBills = (billId: string, supplierId: string) => {
  return api.postRequest(PRINT_SUPPLIER_BILLS, { bill_id: billId, account_id: supplierId })
}

export const printBillsCL = (billIds: string[], listId: string) => {
  return api.postRequest(PRINT_BILL_CL, { bill_ids: billIds, list_id: listId })
}

export const getAllUploadDocuments = (page: number = 1, limit?: number) => {
  return api.getRequest(`${GET_ALL_UPLOAD_DOCUMENTS}?page=${page}&per_page=${limit || 10}`, {})
}

export const getDocumentAccounts = (documentId: string) => {
  return api.getRequest(`${GET_DOCUMENTS_ACCOUNTS}?document_id=${documentId}`, {})
}

export const getDocumentBills = (documentId: string) => {
  return api.getRequest(`${GET_DOCUMENTS_BILLS}?document_id=${documentId}`, {})
}

export const getDocumentStatus = (documentId: string) => {
  return api.getRequest(`${GET_DOCUMENTS_STATUS}?document_id=${documentId}`, {})
}

export const getDocumentErrorFile = (errorDocumentId: string) => {
  return api.getRequest(
    `${GET_ERROR_FILE}?error_document_id=${errorDocumentId}`,
    {},
    {},
    { responseType: 'arraybuffer' }
  )
}
export const createDocumentErrorFile = (documentId: string) => {
  return api.getRequest(`${CREATE_ERROR_FILE}?document_id=${documentId}`, {})
}

export const getDocumentTemplateConfig = () => {
  return api.getRequest(GET_DOCUMENTS_TEMPLATE_CONFIG, {})
}

export const getDefaultDocumentTemplateConfig = () => {
  return api.getRequest(`${GET_DOCUMENTS_TEMPLATE_CONFIG}?is_default=true`, {})
}

export const createDocumentTemplateConfig = (data: {
  name: string // name of template
  document_type: number
  sheetDetails: {
    name: string // sheet name
    header: string
    no_bill_rows: boolean
  }
  columnFormula?: {
    invoiceNumber: string
  }
  columnMapping: {
    customerName: string
    customerCode: string
    invoiceNumber: string
    amount: string
    invoiceDate: string
    saleType: string
    saleRoute: string
    mobileNumber: string
    paymentReconBillNumber: string
    accountBeat: string
    accountRoute: string
  }
  isDefault?: boolean
  columnPreferredIdxs?: TemplateConfig['columnPreferredIdxs']
  amountRoundingType: number
}) => {
  return api.postRequest(CREATE_DOCUMENTS_TEMPLATE_CONFIG, {
    document_type: data.document_type,
    name: data.name,
    is_default: data.isDefault || true,
    config: {
      ...(data.columnFormula && { columnFormula: data.columnFormula }),
      sheetDetails: {
        ...data.sheetDetails,
        header: parseInt(data?.sheetDetails?.header),
        no_bill_rows: data.sheetDetails?.no_bill_rows
      },
      columnMapping: data.columnMapping,
      amount_rounding_type: data.amountRoundingType,
      ...(data.columnPreferredIdxs && { columnPreferredIdxs: data.columnPreferredIdxs })
    }
  })
}

export const updateDocumentTemplateConfig = (data: {
  templateId: string
  isDefault: boolean
  sheetDetails: {
    name: string // sheet name
    header: string
  }
  columnFormula?: {
    invoiceNumber: string
  }
  columnMapping: {
    customerName: string
    customerCode: string
    invoiceNumber: string
    amount: string
    invoiceDate: string
    saleType: string
    saleRoute: string
    mobileNumber: string
    paymentReconBillNumber: string
    accountBeat: string
    accountRoute: string
  }
  columnPreferredIdxs?: TemplateConfig['columnPreferredIdxs']
  amountRoundingType?: number
}) => {
  return api.postRequest(UPDATE_TEMPLATE_CONFIG, {
    template_id: data.templateId,
    is_default: data.isDefault,
    config: {
      sheetDetails: {
        ...data.sheetDetails,
        header: parseInt(data?.sheetDetails?.header)
      },
      columnMapping: data.columnMapping,
      ...(data.columnFormula && { columnFormula: data.columnFormula }),
      amount_rounding_type: data.amountRoundingType,
      ...(data.columnPreferredIdxs && { columnPreferredIdxs: data.columnPreferredIdxs })
    }
  })
}

export const removeDocumentTemplateConfig = (templateId: string) => {
  return api.postRequest(REMOVE_TEMPLATE_CONFIG, {
    template_id: templateId
  })
}

export const updateDefaultTemplateConfig = (data: {
  templateId?: string
  document_type: number
}) => {
  return api.putRequest(UPDATE_DEFAULT_TEMPLATE_CONFIG, {
    document_type: data.document_type,
    ...(data?.templateId ? { template_id: data.templateId } : {})
  })
}

export const resetDocumentTemplateConfig = (templateId: string) => {
  return api.postRequest(REST_DOCUMENTS_TEMPLATE_CONFIG, {
    template_id: templateId
  })
}

export const addToMultipleCollectionListFromDocument = (
  documentId: string,
  routes: string[],
  action: number
) => {
  return api.postRequest(ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT, {
    document_id: documentId,
    routes,
    action
  })
}

export const bulkUploadCustomersApi = (payload: {
  document: number[]
  document_type: DOCUMENT_TYPE
  document_extension: string
  file_name: string
}) => {
  return api.postRequest(BULK_UPLOAD_ACCOUNTS, payload)
}

export const getBulkUploadCustomersStatusApi = (documentId: string) => {
  return api.getRequest(`${GET_BULK_UPLOAD_ACCOUNTS_STATUS}?document_id=${documentId}`, {})
}

export const getDocumentErrorsApi = (payload: { documentId: string }) => {
  const { documentId } = payload
  return api.getRequest(`${DOCUMENT_ERRORS}?doc_id=${documentId}`, {})
}

export const setTemplateOnDocumentApi = (payload: { documentId: string; templateId: string }) => {
  return api.postRequest(SET_TEMPLATE_ON_DOCUMENT, {
    doc_id: payload.documentId,
    tpl_id: payload.templateId
  })
}

export const getDocumentTemplateById = (payload: { templateId: string }) => {
  return api.postRequest(GET_TEMPLATE_BY_ID, {
    tpl_id: payload.templateId
  })
}

export const resolveDocumentFieldConflicts = (payload: {
  documentId: string
  preferredIdxs: TemplateConfig['columnPreferredIdxs']
}) => {
  return api.postRequest(UPDATE_DOCUMENT_PREFERRED_IDXS, {
    doc_id: payload.documentId,
    preferred_idxs: payload.preferredIdxs
  })
}

export const updateTemplatePreferredIdxs = (payload: {
  templateId: string
  preferredIdxs: TemplateConfig['columnPreferredIdxs']
}) => {
  return api.postRequest(UPDATE_TEMPLATE_PREFERRED_IDXS, {
    tpl_id: payload.templateId,
    preferred_idxs: payload.preferredIdxs
  })
}
