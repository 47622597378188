import { Action } from 'infra/types'
import { ISummaryV2DateFilter } from 'pages/CollectionSummary/CollectionSummary.types'
import {
  IDownloadApprovedCollectionSummaryActionPayload,
  IFetchApprovedCollectionGroupSummaryActionPayload,
  IFetchApprovedCollectionSummaryActionPayload,
  IFetchPayload,
  IGroupViewData,
  SORT_ORDER_TYPE
} from './approvedCollectionSummary.types'

export const ApprovedCollectionSummaryActions = {
  GET_APPROVED_COLLECTIONS_SUMMARY: 'approvedCollectionSummary/GET_APPROVED_COLLECTIONS_SUMMARY',
  GET_APPROVED_COLLECTIONS_SUMMARY_SUCCESS:
    'approvedCollectionSummary/GET_APPROVED_COLLECTIONS_SUMMARY_SUCCESS',
  DOWNLOAD_APPROVED_COLLECTIONS_SUMMARY:
    'approvedCollectionSummary/DOWNLOAD_APPROVED_COLLECTIONS_SUMMARY',

  GET_APPROVED_COLLECTION_GROUP_SUMMARY:
    'approvedCollectionSummary/GET_APPROVED_COLLECTION_GROUP_SUMMARY',
  GET_APPROVED_COLLECTION_GROUP_SUMMARY_SUCCESS:
    'approvedCollectionSummary/GET_APPROVED_COLLECTION_GROUP_SUMMARY_SUCCESS',

  SET_SUMMARY_ACTIVE_FILTER: 'approvedCollectionSummary/SET_SUMMARY_ACTIVE_FILTER',
  SET_DRAWER: 'approvedCollectionSummary/SET_DRAWER',
  SET_LOADER: 'approvedCollectionSummary/SET_LOADER',
  SET_SORT_BY: 'approvedCollectionSummary/SET_SORT_BY'
}

export const setLoaderAction = (payload: {
  id: string
  value: boolean
}): Action<{ id: string; value: boolean }> => ({
  type: ApprovedCollectionSummaryActions.SET_LOADER,
  payload
})

export const getApprovedCollectionSummaryAction = (
  payload: IFetchApprovedCollectionSummaryActionPayload
): Action<IFetchApprovedCollectionSummaryActionPayload> => ({
  type: ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTIONS_SUMMARY,
  payload
})

export const getApprovedCollectionSummarySuccessAction = (
  payload: IFetchPayload
): Action<IFetchPayload> => ({
  type: ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTIONS_SUMMARY_SUCCESS,
  payload
})

export const getApprovedCollectionGroupSummaryAction = (
  payload: IFetchApprovedCollectionGroupSummaryActionPayload
): Action<IFetchApprovedCollectionGroupSummaryActionPayload> => ({
  type: ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTION_GROUP_SUMMARY,
  payload
})

export const getApprovedCollectionGroupSummarySuccessAction = (
  payload: IGroupViewData
): Action<IGroupViewData> => ({
  type: ApprovedCollectionSummaryActions.GET_APPROVED_COLLECTION_GROUP_SUMMARY_SUCCESS,
  payload
})

export const setSummaryActiveFilter = (
  filter: ISummaryV2DateFilter | null
): Action<ISummaryV2DateFilter | null> => ({
  type: ApprovedCollectionSummaryActions.SET_SUMMARY_ACTIVE_FILTER,
  payload: filter
})

export const setSortById = (
  sortBy: string,
  sortOrder: null | SORT_ORDER_TYPE
): Action<{
  sortBy: string
  sortOrder: null | SORT_ORDER_TYPE
}> => ({
  type: ApprovedCollectionSummaryActions.SET_SORT_BY,
  payload: { sortBy, sortOrder }
})

export const setDrawerAction = (payload: {
  id: string
  value: boolean
}): Action<{ id: string; value: boolean }> => ({
  type: ApprovedCollectionSummaryActions.SET_DRAWER,
  payload
})

export const downloadApprovedCollectionGroupSummaryAction = (
  payload: IDownloadApprovedCollectionSummaryActionPayload
): Action<IDownloadApprovedCollectionSummaryActionPayload> => ({
  type: ApprovedCollectionSummaryActions.DOWNLOAD_APPROVED_COLLECTIONS_SUMMARY,
  payload
})
