import { Action } from 'infra/types'

export const onboardingAction = {
  SHOW_SYNC_FILE_DRAWER: 'onboarding/showSyncFileDrawer',
  SHOW_ADD_TO_SUPPLY_LIST_DRAWER: 'onboarding/showAddToSupplyListDrawer',
  SHOW_LIST_NAME_DRAWER: 'onboarding/showListNameDrawer',
  SET_ACCOUNT_IDS: 'onboarding/setAccountIds',
  RESET_ALL_DRAWERS: 'onboarding/resetAllDrawers'
}

export const setShowSyncFileDrawer = (payload: boolean): Action<any> => {
  return {
    type: onboardingAction.SHOW_SYNC_FILE_DRAWER,
    payload
  }
}

export const setAddToSupplyListDrawer = (payload: boolean): Action<any> => {
  return {
    type: onboardingAction.SHOW_ADD_TO_SUPPLY_LIST_DRAWER,
    payload
  }
}

export const setShowListNameDrawer = (payload: boolean): Action<any> => {
  return {
    type: onboardingAction.SHOW_LIST_NAME_DRAWER,
    payload
  }
}

export const setAccountIds = (payload: Array<string>): Action<any> => {
  return {
    type: onboardingAction.SET_ACCOUNT_IDS,
    payload
  }
}

export const resetAllOnboardingDrawers = () => {
  return {
    type: onboardingAction.RESET_ALL_DRAWERS
  }
}
