import { takeCustomLatest } from 'state/common/common.effects'
import { creditNoteActions } from 'state/new-summary/creditNote/creditNote.actions'
import {
  fetchCreditNoteEntityEffect,
  fetchCreditNoteListEffect
} from 'state/new-summary/creditNote/creditNote.effects'

function* fetchCreditNoteListSaga() {
  yield takeCustomLatest(creditNoteActions.FETCH_CREDIT_NOTES_LIST, fetchCreditNoteListEffect)
}

function* fetchCreditNotePreviewEntitySaga() {
  yield takeCustomLatest(
    creditNoteActions.FETCH_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS,
    fetchCreditNoteEntityEffect
  )
}

export const CreditNotesWatcher = [fetchCreditNoteListSaga(), fetchCreditNotePreviewEntitySaga()]
