import { createContext } from 'react'
import {
  CustomerDetails,
  HandleBillSavePropsType,
  InvoiceDetails,
  NewItemDetailEntity
} from 'state/billing/billing.types'
import { getToday } from 'utils/time'

export const FALLBACK_EMPTY_VALUES = -2
export const DISABLED_MAX_INPUT_PROP = -3 // To disable max input prop
export const DISABLED_MIN_INPUT_PROP = -3 // To disable min input prop
export const DEFAULT_CUSTOMER = {
  customerId: '',
  customerName: '',
  customerMobile: '',
  customerAddress: '',
  customerGST: '',
  customerCode: ''
}

export const BILL_NUMBER_PREFIX = 'INV'
export const BILL_DEFAULT_NUMBER = '0001'
export const GST_NUMBER_LENGTH = 15

export const DEFAULT_BILL_DETAILS = {
  customerDetails: DEFAULT_CUSTOMER,
  invoiceDetails: {
    invoiceNumber: BILL_DEFAULT_NUMBER,
    invoiceDate: getToday(),
    invoicePrefix: BILL_NUMBER_PREFIX
  },
  addedItemDetails: [],
  invoiceTotalAmount: 0,
  discountAmount: 0,
  payableAmount: 0,
  isEditingBill: false,
  isIGST: false,
  selectedDiscounts: [],
  selectedCharges: []
}

export type BillDataType = {
  customerDetails: CustomerDetails
  invoiceDetails: InvoiceDetails
  addedItemDetails: (NewItemDetailEntity & {
    amountPayable: number
  })[]
  invoiceTotalAmount: number
  discountAmount: number
  payableAmount: number
  isEditingBill?: boolean
  creatingCreditNote?: boolean
  isIGST?: boolean
  billId?: string
  billVersion?: number
  selectedDiscounts: SelectedDiscountType[]
  selectedCharges: SelectedChargeType[]
  selectedItemIndex?: number[]
}

export type SelectedDiscountType = {
  id: string
  name: string
  isPercent: boolean
  isPreTax: boolean
  amount: number
  basis: number // percentage times 100
}

export type SelectedChargeType = {
  id: string
  name: string
  isPercent: boolean
  amount: number
  basis: number
}

export const BillDetailsContext = createContext<{
  billDetails: BillDataType
  updateBillDetails: (data: BillDataType) => null
  handleBillSave: (prop?: HandleBillSavePropsType) => null
}>({
  billDetails: DEFAULT_BILL_DETAILS,
  updateBillDetails: (_data: BillDataType) => null,
  handleBillSave: (_?: { shouldPrintBill?: boolean; clearContextOnSave?: boolean }) => null
})

export const DEFAULT_ITEM_DETAILS = {
  item: '',
  itemId: '',
  hsnCode: '',
  mrp: FALLBACK_EMPTY_VALUES,
  rate: FALLBACK_EMPTY_VALUES,

  discount: FALLBACK_EMPTY_VALUES,
  netRate: FALLBACK_EMPTY_VALUES,
  gstTaxPercent: FALLBACK_EMPTY_VALUES,
  sgstTaxPercent: FALLBACK_EMPTY_VALUES,
  cgstTaxPercent: FALLBACK_EMPTY_VALUES,
  igstPercent: FALLBACK_EMPTY_VALUES,
  cessPercent: FALLBACK_EMPTY_VALUES,
  taxAmount: FALLBACK_EMPTY_VALUES,
  gstTotalAmount: FALLBACK_EMPTY_VALUES,
  quantity: FALLBACK_EMPTY_VALUES,

  selectedUnit: '',
  selectedUnitFactor: 1
}

export enum CUSTOMER_KEY_TYPE {
  customerName = 'customerName',
  customerMobile = 'customerMobile',
  customerAddress = 'customerAddress'
}

export enum CUSTOMER_INVOICE_LABELS {
  prefix = 'Prefix',
  invoiceNumber = 'Number',
  invoiceDate = 'Date'
}

export const MAX_ITEM_NAME_LENGTH = 150
export const SKU_LENGTH = 128
export const MAX_MRP_LENGTH = 8
export const MAX_HSN_LENGTH = 8
export const MAX_CUSTOMER_NAME = 100
export const MAX_GST_LENGTH = 15

export type EditBillHeaderType = {
  setConfirmToSave: (open: boolean) => void
}

export type PreviewBillHeaderType = {
  setConfirmToDelete: (open: boolean) => void
}
