import { Action } from 'infra/types'
import { IDamage } from 'services/Api/StaffLinkApiFolder/type'

export const damageActions = {
  SET_DAMAGE_DRAWER_DATA: 'damage/setDamageDrawersData'
}

export const setDamageDrawerWithData = (payload: {
  status: boolean
  data?: IDamage
}): Action<{ status: boolean; data?: IDamage }> => {
  return {
    type: damageActions.SET_DAMAGE_DRAWER_DATA,
    payload
  }
}
