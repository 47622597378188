import { Action } from 'infra/types'
import { INotification, INotificationPayload } from './Notification.types'

export const NotificationActions = {
  ADD_NOTIFICATION: 'notification/addNotification',
  DELETE_NOTIFICATION: 'notification/deleteNotification',
  ADD_AUTO_FADE_NOTIFICATION: 'notification/addAutoFadeNotification'
}

export const addNotification = (notification: INotificationPayload): Action<INotification> => ({
  type: NotificationActions.ADD_NOTIFICATION,
  payload: {
    ...notification,
    id: Math.floor(Math.random() * 1000)
  }
})

export const deleteNotification = (id: number): Action<number> => ({
  type: NotificationActions.DELETE_NOTIFICATION,
  payload: id
})

export const addAutoFadeNotification = (
  notification: INotificationPayload
): Action<INotification> => ({
  type: NotificationActions.ADD_AUTO_FADE_NOTIFICATION,
  payload: { ...notification, id: Math.floor(Math.random() * 1000) }
})
