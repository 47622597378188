import { Action } from 'infra/types'

export const whatsappSuccessActions = {
  SET_DRAWER: 'whatsappSuccess/setDrawers'
}

export const setWhatsappSuccessDrawers = (payload: {
  drawerName: 'showWhatsappSuccessDrawer'
  status: boolean
}): Action<{
  drawerName: 'showWhatsappSuccessDrawer'
  status: boolean
}> => ({
  type: whatsappSuccessActions.SET_DRAWER,
  payload
})
