/* eslint-disable security/detect-object-injection */
import { call, put, select } from 'redux-saga/effects'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { getErrorMessage } from 'services/Api/Errors'
import { IAppState } from 'infra/AppState'
import { getAbProfileV1Api } from 'services/Api/abServiceApi'
import { Action } from 'infra/types'
import { getStaffBusinessSettingsApi } from 'services/Api/BillingApiFolder.ts/api'
import {
  getFormattedAbExperiment,
  getFormattedAbProfile,
  getFormattedBusinessSettings
} from './formatter'
import {
  getAbProfileFail,
  getAbProfileSuccess,
  getStaffBusinessSettingsSuccess
} from './experiment.action'

export function* getAbProfileSagaEffects() {
  try {
    const { merchantProfile } = yield select((state: IAppState) => {
      return state.Dashboard
    })
    const response: IApiResponse = yield call(getAbProfileV1Api, merchantProfile?.data?.id)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        getAbProfileSuccess(
          getFormattedAbProfile(response.data),
          getFormattedAbExperiment(response.data)
        )
      )
    } else {
      const message = response.data?.error
      yield put(getAbProfileFail(message || 'An Error Occurred'))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* getStaffBusinessSettingsEffects(action: Action<string>) {
  try {
    const businessId = action.payload
    const response: IApiResponse = yield call(getStaffBusinessSettingsApi, businessId)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        getStaffBusinessSettingsSuccess(getFormattedBusinessSettings(response.data.settings))
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}
