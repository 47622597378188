import { IBillsList } from './collectionList.types'

export const getFormattedBills = (resData: any, accounByIds: any): IBillsList => {
  const bills = resData.bills.map((__: any) => ({
    billId: __.bill_id,
    billNumber: __.bill_number,
    billDate: __.bill_date,
    customerId: __.customer_id,
    billDueAmount: __.bill_due_amount,
    totalAmount: __.bill_amount,
    customerName: accounByIds?.[__?.customer_id]?.description || '',
    customerAddress: accounByIds?.[__?.customer_id]?.address || ''
  }))
  return {
    data: bills,
    pagination: resData.pagination as number
  }
}
