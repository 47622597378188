import { COLLECTION_LIST_TYPE } from 'state/collectionList/collectionList.types'
import { ActionWithPagination, IUploadSheetState } from './uploadSheet.types'
import { UploadSheetActions } from './uploadSheet.actions'

const UPLOAD_SHEET_INITIAL_STATE: IUploadSheetState = {
  documentsList: [],
  currentDocumentSelected: null,
  accountsDocument: null,
  templatesList: [],
  globalTemplatesList: [],
  currentTemplates: [],
  pagination: null,
  loaders: {
    fetchDocumentsList: false,
    collectionListFromDocument: false,
    fetchTemplatesList: false,
    createTemplate: false,
    updateDefaultTemplate: false
  },
  drawers: {
    documentList: false,
    documentToCollectionList: false,
    documentListConfiguration: false,
    templateDetails: false,
    errorDetails: false,
    uploadedFiles: false,
    addToStaffLinkActionTypeDrawer: false,
    uploadedFilesPreview: false
  },
  uploadFileJsonData: null,
  uploadTempFile: null
}

export const UploadSheetReducer = (
  state: IUploadSheetState = UPLOAD_SHEET_INITIAL_STATE,
  action: ActionWithPagination<any>
): IUploadSheetState => {
  switch (action.type) {
    case UploadSheetActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...action.payload
        }
      }
    }
    case UploadSheetActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          ...action.payload
        }
      }
    }
    case UploadSheetActions.FETCH_DOCUMENTS_LIST_SUCCESS: {
      return {
        ...state,
        documentsList:
          action.pagination.currentPage === 1
            ? action.payload
            : [...state.documentsList, ...action.payload],
        pagination: {
          ...state.pagination,
          documents: action.pagination
        },
        loaders: {
          ...state.loaders,
          fetchDocumentsList: false
        }
      }
    }
    case UploadSheetActions.COLLECTION_LIST_FROM_DOCUMENT: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          collectionListFromDocument: true
        },
        ...(action.payload?.isCreateNew && {
          createNewList: {
            creating: true,
            accountIds: [],
            dueConfig: {
              type: COLLECTION_LIST_TYPE.BALANCE_DUE
            }
          }
        })
      }
    }
    case UploadSheetActions.CURRENT_DOCUMENT_SELECTED: {
      return {
        ...state,
        currentDocumentSelected: action.payload,
        drawers: {
          ...state.drawers,
          documentToCollectionList: true
        }
      }
    }
    case UploadSheetActions.CURRENT_ERROR_DOCUMENT_SELECTED: {
      return {
        ...state,
        currentDocumentSelected: action.payload,
        drawers: {
          ...state.drawers,
          errorDetails: true
        }
      }
    }
    case UploadSheetActions.RESET_CURRENT_DOCUMENT_SELECTED: {
      return {
        ...state,
        currentDocumentSelected: null,
        drawers: {
          ...state.drawers,
          documentToCollectionList: false,
          errorDetails: false
        }
      }
    }
    case UploadSheetActions.FETCH_TEMPLATES_LIST: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          fetchTemplatesList: true
        }
      }
    }
    case UploadSheetActions.FETCH_TEMPLATES_LIST_SUCCESS: {
      return {
        ...state,
        templatesList: action.payload?.templatesList,
        globalTemplatesList: action.payload?.globalTemplatesList,
        loaders: {
          ...state.loaders,
          fetchTemplatesList: false
        }
      }
    }
    case UploadSheetActions.CREATE_TEMPLATE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          createTemplate: true
        }
      }
    }
    case UploadSheetActions.UPDATE_DEFAULT_TEMPLATE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          updateDefaultTemplate: true
        }
      }
    }
    case UploadSheetActions.SET_CURRENT_TEMPLATES: {
      return {
        ...state,
        currentTemplates: action.payload
      }
    }
    case UploadSheetActions.SAVE_FILE_HTML_DATA: {
      return {
        ...state,
        uploadFileJsonData: action.payload
      }
    }
    case UploadSheetActions.SAVE_RAW_FILE: {
      return {
        ...state,
        uploadTempFile: action.payload
      }
    }
    default: {
      return state
    }
  }
}
