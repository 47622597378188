/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put, select } from '@redux-saga/core/effects'
import { utils, writeFileXLSX } from 'xlsx'
import dayjs from 'dayjs'
import { Action } from 'infra/types'
import {
  getApprovedCollectionsApi,
  getApprovedCollectionSummaryApi
} from 'services/Api/StafflinkApi'

import { IDashboardState, IMerchantProfile } from 'state/dashboard/dashboard.types'
import { IAppState } from 'infra/AppState'
import { API_RESPONSE_TYPE, IApiResponse } from 'shared/constants'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import {
  getApprovedCollectionGroupSummarySuccessAction,
  getApprovedCollectionSummarySuccessAction,
  setDrawerAction,
  setLoaderAction,
  setSortById,
  setSummaryActiveFilter
} from './approvedCollectionSummary.actions'
import {
  formatApprovedTransactionsGroupData,
  formatDownloadData,
  formatOverallApprovedTransactionsData
} from './approvedCollectionSummary.helpers'
import {
  IDownloadApprovedCollectionSummaryActionPayload,
  IFetchApprovedCollectionGroupSummaryActionPayload,
  IFetchApprovedCollectionSummaryActionPayload,
  SORT_ORDER_TYPE
} from './approvedCollectionSummary.types'

export function* getApprovedCollectionsSummaryEffect(
  action: Action<IFetchApprovedCollectionSummaryActionPayload>
) {
  const { startTime, endTime, sortBy, sortOrder, page, perPage, filterType } = action.payload
  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })

  yield put(setLoaderAction({ id: 'listSummary', value: true }))
  const response: IApiResponse = yield call(getApprovedCollectionSummaryApi, {
    start_date: startTime,
    end_date: endTime,
    business_id: merchantProfile.id,
    filters: {
      page,
      per_page: perPage,
      ...(sortBy &&
        sortOrder && {
          sort_by: sortBy,
          sort_descending: sortOrder === SORT_ORDER_TYPE.descending
        })
    }
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Could not fetch summary. Please try again after some time.', {
      cause: 'customError'
    })
  }

  const formattedData = formatOverallApprovedTransactionsData(response?.data)
  yield put(
    getApprovedCollectionSummarySuccessAction({
      listViewData: formattedData.overallListData,
      overallSummaryData: formattedData.overallSummaryData,
      pagination: formattedData.pagination
    })
  )

  yield put(
    setSummaryActiveFilter({
      startTime,
      endTime,
      filterType
    })
  )

  yield put(setSortById(sortBy || '', sortOrder || null))
  yield put(setLoaderAction({ id: 'listSummary', value: false }))
}

export function* getApprovedCollectionGroupSummaryEffect(
  action: Action<IFetchApprovedCollectionGroupSummaryActionPayload>
) {
  const { startTime, endTime, page, perPage, listId, filterType, sortBy, sortOrder } =
    action.payload

  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })
  const dashboardState: IDashboardState = yield select((state: IAppState) => {
    return state.Dashboard
  })
  const { customers, suppliers } = dashboardState
  const entities = { ...customers.byIds, ...suppliers.byIds }
  yield put(setLoaderAction({ id: 'groupSummary', value: true }))

  const response: IApiResponse = yield call(getApprovedCollectionsApi, {
    start_date: startTime,
    end_date: endTime,
    business_id: merchantProfile.id,
    filters: {
      page,
      per_page: perPage,
      ...(sortBy &&
        sortOrder && {
          sort_by: sortBy,
          sort_descending: sortOrder === SORT_ORDER_TYPE.descending
        })
    },
    ...(listId !== 'all-collections' ? { list_id: listId } : {})
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something Went Wrong', {
      cause: 'customError'
    })
  }
  const formattedSummary = formatApprovedTransactionsGroupData(response.data, entities)
  yield put(
    getApprovedCollectionGroupSummarySuccessAction({
      rowData: formattedSummary.groupViewData,
      overallSummaryData: formattedSummary.overallSummaryData,
      pagination: formattedSummary.pagination,
      ...(sortBy &&
        sortOrder && {
          sortBy,
          sortOrder
        })
    })
  )
  yield put(
    setSummaryActiveFilter({
      startTime,
      endTime,
      filterType
    })
  )
  yield put(setLoaderAction({ id: 'groupSummary', value: false }))
}

export function* downloadApprovedCollectionsSummaryEffect(
  action: Action<IDownloadApprovedCollectionSummaryActionPayload>
) {
  const { startTime, endTime, fileName } = action.payload
  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })

  const response: IApiResponse = yield call(getApprovedCollectionSummaryApi, {
    start_date: startTime,
    end_date: endTime,
    business_id: merchantProfile.id,
    filters: {
      page: 1,
      per_page: 99999
    }
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Could not download summary. Please try again after some time.', {
      cause: 'customError'
    })
  }

  const { formattedOverallData, formattedListData } = formatDownloadData(response?.data)

  const excelHeading = 'Approved Collection Summary'

  const combinedData = [
    [excelHeading],
    [`Printed On: ${dayjs().format('DD MMM, YYYY')}`],
    [],
    ['Overall Summary'],
    ...formattedOverallData,
    [],
    [],
    ['List Summary'],
    ...formattedListData
  ]

  const wb = utils.book_new()
  const ws = utils.aoa_to_sheet(combinedData)

  if (combinedData.length) {
    ws['!cols'] = getMaxColumnWidths(combinedData)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)

  yield put(setDrawerAction({ id: 'downloadDialog', value: false }))
}
