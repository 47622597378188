import { IBill } from 'state/collectionList/collectionList.types'

export const checkIfCompleted = (
  selectedStaffBill: IBill | undefined,
  dueAmount: number,
  amount: number,
  accountId: string
) => {
  const completedTrans = localStorage.getItem('completed_transaction')
  const markedTransaction = JSON.parse(completedTrans || '[]') as Array<string>
  const updatedList = markedTransaction
  if (selectedStaffBill?.bill?.bill_id && Number(dueAmount) === Number(amount)) {
    if (!markedTransaction.includes(selectedStaffBill?.bill?.bill_id)) {
      updatedList.push(selectedStaffBill?.bill?.bill_id)
    }
    localStorage.setItem('completed_transaction', JSON.stringify(updatedList))
    return
  }
  if (!markedTransaction.includes(accountId) && Number(dueAmount) === Number(amount)) {
    updatedList.push(accountId)
  }
  localStorage.setItem('completed_transaction', JSON.stringify(updatedList))
}

export const getMarkAsCompletedTransactionsFromLS = () => {
  try {
    const completedTrans = localStorage.getItem('completed_transaction')
    const markedTransaction = JSON.parse(completedTrans || '[]')
    return markedTransaction
  } catch {
    return []
  }
}
