import { Action } from 'infra/types'
import { SORT_ORDER_TYPE } from 'state/collectionSummary/collectionSummary.types'
import {
  ApproveFilterType,
  FetchPendingActionPayload,
  IApproveState,
  IReplacement,
  ISummaryTotal,
  SORT_OPTION,
  TransactionId
} from './approve.types'

export const approveAction = {
  FETCH_PENDING_ACTIONS: 'approve/fetchPendingActions',
  SET_PENDING_ACTIONS_FILTER: 'approve/setPendingActionFilter',
  SET_PENDING_ACTIONS_DATA: 'approve/setPendingActiondata',
  SET_PAGINATION_DATA: 'approve/setPaginationdata',
  APPROVE_PENDING_ACTION: 'approve/approvePendingAction',
  SET_REPLACEMENT_DRAWER_DATA: 'approve/setReplamentDrawerData',
  APPROVE_ALL: 'approve/approveAll',
  REFETCH_APPROVE_ACTION: 'approve/refetch',
  CANCEL_PENDING_ACTION: 'approve/cancelPendingAction',
  HOLD_INVOICE_ACTION: 'approve/holdInvoiceAction',
  SHOW_FILTER_BY_COLLECTION_LIST_DRAWER: 'approve/showFilterByCollectionListDrawer',
  SET_SELECTED_LISTS_WITH_PENDING_ACTIONS: 'approve/setSelectedListsWithPendingActions',
  SET_SUMMARY_TOTAL: 'approve/setSummaryTotal',
  SET_FETCHING_LOADERS: 'approve/setFetchingLoaders'
}

export const setFetchingLoaders = (payload: boolean): Action<boolean> => {
  return {
    type: approveAction.SET_FETCHING_LOADERS,
    payload
  }
}

export const refetchPendingActions = (): Action<null> => {
  return {
    type: approveAction.REFETCH_APPROVE_ACTION,
    payload: null
  }
}

export const fetchPendingActions = (
  payload: FetchPendingActionPayload
): Action<FetchPendingActionPayload> => {
  return {
    type: approveAction.FETCH_PENDING_ACTIONS,
    payload
  }
}

export const setReplacementDrawerWithData = (payload: {
  status: boolean
  data?: IReplacement
}): Action<{ status: boolean; data?: IReplacement }> => {
  return {
    type: approveAction.SET_REPLACEMENT_DRAWER_DATA,
    payload
  }
}

export const setApproveAll = (payload: {
  status: boolean
  txnIds?: TransactionId[]
  billIds?: string[]
}): Action<{ status: boolean; txnIds?: TransactionId[]; billIds?: string[] }> => {
  return {
    type: approveAction.APPROVE_ALL,
    payload
  }
}

export const approvePendingActions = (payload: TransactionId[]): Action<TransactionId[]> => {
  return {
    type: approveAction.APPROVE_PENDING_ACTION,
    payload
  }
}

export const cancelPendingTxnsActions = (payload: TransactionId[]): Action<TransactionId[]> => {
  return {
    type: approveAction.CANCEL_PENDING_ACTION,
    payload
  }
}

export const holdInvoiceAction = (payload: TransactionId[]): Action<TransactionId[]> => {
  return {
    type: approveAction.HOLD_INVOICE_ACTION,
    payload
  }
}

export const setPendingActionFilters = (payload: {
  filter: ApproveFilterType
  sortOption?: SORT_OPTION
  sortOrder?: SORT_ORDER_TYPE
}): Action<{ filter: ApproveFilterType }> => {
  return {
    type: approveAction.SET_PENDING_ACTIONS_FILTER,
    payload
  }
}

export const setPendingActionData = (
  payload: IApproveState['pendingActions']
): Action<IApproveState['pendingActions']> => {
  return {
    type: approveAction.SET_PENDING_ACTIONS_DATA,
    payload
  }
}

export const setPaginationData = (payload: {
  pageSize: number
  currentPage: number
  totalEntries: number
}): Action<{ pageSize: number; currentPage: number; totalEntries: number }> => {
  return {
    type: approveAction.SET_PAGINATION_DATA,
    payload
  }
}

export const setShowFilterByCollectionListDrawer = (payload: boolean): Action<boolean> => {
  return {
    type: approveAction.SHOW_FILTER_BY_COLLECTION_LIST_DRAWER,
    payload
  }
}

export const setSelectedListsWithPendingActions = (payload: string[]): Action<string[]> => {
  return {
    type: approveAction.SET_SELECTED_LISTS_WITH_PENDING_ACTIONS,
    payload
  }
}

export const setSummeryTotal = (payload: ISummaryTotal): Action<ISummaryTotal> => {
  return {
    type: approveAction.SET_SUMMARY_TOTAL,
    payload
  }
}
