import { ITransactionsState } from './transaction.types'

export const TransactionState: ITransactionsState = {
  currentTransactions: {
    allIds: [],
    byIds: {},
    loading: false
  },
  currentTransactionReport: {
    paymentCount: 0,
    creditCount: 0,
    payment: 0,
    credit: 0,
    transactions: [],
    loading: false,
    isReportUrlLoading: false,
    reportUrl: ''
  },
  transactionBills: {
    currentAction: ''
  },
  isEditTransactionAmountOpen: false,
  createTransactionError: '',
  fetchingRoutesAndBeats: false,
  routes: [],
  beats: [],
  doubleEntry: {
    reference: '',
    account_name: '',
    deleted: false
  }
}
