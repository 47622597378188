import { Action } from 'infra/types'
import { creditNoteActions } from 'state/new-summary/creditNote/creditNote.actions'
import { CreditNoteStateValues } from 'state/new-summary/creditNote/creditNote.state'
import { ICreditNoteState } from 'state/new-summary/creditNote/creditNote.types'

export const CreditNotesReducer = (
  state: ICreditNoteState = CreditNoteStateValues,
  action: Action<any>
): ICreditNoteState => {
  switch (action.type) {
    case creditNoteActions.FETCH_CREDIT_NOTES_LIST: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isFetchingCreditNote: true
        }
      }
    }
    case creditNoteActions.FETCH_CREDIT_NOTES_LIST_SUCCESS: {
      return {
        ...state,
        creditNoteList: action.payload
      }
    }
    case creditNoteActions.SET_CREDIT_NOTES_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case creditNoteActions.SET_CREDIT_NOTES_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.payload
      }
    }
    case creditNoteActions.SET_CREDIT_NOTES_LIST_PAGINATION: {
      return {
        ...state,
        paginationDetails: action.payload.pagination,
        cnSortBy: action.payload.sort
      }
    }
    case creditNoteActions.SET_PREVIEW_CREDIT_NOTE_ENTITY_CONTENTS: {
      return {
        ...state,
        creditNoteHtmlContent: action.payload
      }
    }
    default: {
      return state
    }
  }
}
