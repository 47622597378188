import loadable from '@loadable/component'
import { TRANSACTION_TYPE } from 'state/transaction/transaction.types'
import lazyRetry from 'utils/lazyRetry'

const BASE_PATH = '/new-summary'

export const newPaths = {
  base: BASE_PATH,

  ledger: `${BASE_PATH}/ledger`,
  customersProfile: `${BASE_PATH}/ledger/customers`,
  billing: `${BASE_PATH}/billing`,
  chequeManagement: `${BASE_PATH}/cheque-management`,

  settings: `${BASE_PATH}/settings`,
  help: `${BASE_PATH}/help`,
  manageAccounts: `${BASE_PATH}/manage-accounts`,
  editMerchant: `${BASE_PATH}/manage-accounts/merchant/edit/:id`,
  managerDetails: `${BASE_PATH}/manage-accounts/merchant/edit/:id/manager/:userId`,
  tags: `${BASE_PATH}/tags`,

  collection: `${BASE_PATH}/collection`,
  summary: `${BASE_PATH}/new-summary`,

  supplyList: `${BASE_PATH}/supply-list`,
  supplyListOnDemandDistribution: `${BASE_PATH}/supply-list/:dueConfig/:listId`,
  supplyListSpecificId: `${BASE_PATH}/supply-list/:listId`,

  collectionList: `${BASE_PATH}/collection-list`,
  collectionListSpecificId: `${BASE_PATH}/collection-list/:listId`,

  reconcile: `${BASE_PATH}/reconcile`,
  collectedCash: `${BASE_PATH}/reconcile/collected-cash`,
  receivedCheque: `${BASE_PATH}/reconcile/received-cheque`,
  depositedCheque: `${BASE_PATH}/reconcile/deposited-cheque`,
  bankStatement: `${BASE_PATH}/reconcile/bank-statement`,
  bankAsset: `${BASE_PATH}/reconcile/bank-asset`,
  depositHistory: `${BASE_PATH}/reconcile/deposit-history`,
  bankReconciliation: `${BASE_PATH}/reconcile/bank-reconciliation`,

  supply: `${BASE_PATH}/new-summary/supply`,
  supplyGroup: `${BASE_PATH}/new-summary/supply/:groupId`,
  supplyGroupInvoices: `${BASE_PATH}/new-summary/supply/:groupId/:customerId`,

  collectionSummary: `${BASE_PATH}/new-summary/collection-summary`,
  collectionGroupSummary: `${BASE_PATH}/new-summary/collection-summary/:groupId`,

  pendingDue: `${BASE_PATH}/new-summary/pending-due`,
  pendingDueGroup: `${BASE_PATH}/new-summary/pending-due/:groupId`,
  pendingDueOverallGroup: `${BASE_PATH}/new-summary/pending-due/overall`,

  overallSummary: `${BASE_PATH}/new-summary/overall-summary`,
  cashByStaff: `${BASE_PATH}/new-summary/cash-by-staff`,
  overallGroupSummary: `${BASE_PATH}/new-summary/overall-summary/:groupId`,

  approvedSummary: `${BASE_PATH}/new-summary/approved-summary`,
  approvedGroupSummary: `${BASE_PATH}/new-summary/approved-summary/:groupId`,

  approve: `${BASE_PATH}/approve`,
  approveUnidentifiedCollection: `${BASE_PATH}/approve/unidentified-collections`,
  depositCash: `${BASE_PATH}/approve/deposit-cash`,
  ordersTaken: `${BASE_PATH}/approve/orders-taken`,
  createInvoiceOrder: `${BASE_PATH}/approve/orders-accepted/invoice`,
  ordersApproved: `${BASE_PATH}/approve/orders-accepted`,
  previewInvoice: `${BASE_PATH}/approve/orders-accepted/invoice/preview`,
  proformaInvoice: `${BASE_PATH}/approve/proforma-invoice`,
  cashManagement: `${BASE_PATH}/cash-management`,
  editEntity: `${BASE_PATH}/:entity(customer|supplier)/edit/:id`,
  addTransaction: `${BASE_PATH}/:entity(customer|supplier)/:id/transaction/add/:type(${Object.values(
    TRANSACTION_TYPE
  ).join('|')})`,
  addEntity: `${BASE_PATH}/:entity(customer|supplier)/add`,
  addEntitySuccess: `${BASE_PATH}/:entity(customer|supplier)/add/success`,
  accountTags: `${BASE_PATH}/:entity(customer|supplier)/edit/:id/tags`,
  accountBeats: `${BASE_PATH}/:entity(customer|supplier)/edit/:id/beats`,
  accountRoutes: `${BASE_PATH}/:entity(customer|supplier)/edit/:id/routes`,
  transactionDetails: `${BASE_PATH}/transaction/:transactionId`,

  // billing
  billingCreate: `${BASE_PATH}/billing/create-bill`,
  billingCatalogue: `${BASE_PATH}/billing/catalogue`,
  billingPreviewBill: `${BASE_PATH}/billing/preview-bill`,
  billingPurchaseBill: `${BASE_PATH}/billing/purchase-bill`,
  billingEdit: `${BASE_PATH}/billing/edit-bill/:billId`,
  billingCreditNote: `${BASE_PATH}/billing/credit-note/:billId`,
  billInvoiceItem: `${BASE_PATH}/billing/inventory`,
  inventory: `${BASE_PATH}/billing/inventory-v2`,
  defaultInventoryHistory: `${BASE_PATH}/billing/inventory-v2/history/:itemId`,
  billPurchaseItem: `${BASE_PATH}/billing/purchase-item`,
  billExpenseItem: `${BASE_PATH}/billing/expense-item`,
  inventoryItemHistory: `${BASE_PATH}/billing/inventory/history/:itemId`,

  // credit note
  creditNote: `${BASE_PATH}/billing/credit-note`,
  previewCreditNoteEntity: `${BASE_PATH}/billing/preview/credit-note/:id`
}

export const paths = {
  dashboard: '/',
  payments: '/payments',
  cashManagement: '/cash-management',
  statements: '/statements',
  summary: '/summary',
  billing: '/billing',
  collectionSummary: '/collection-summary',
  collectionSummaryDetails: '/collection-summary/:id',
  pendingDueSummary: '/pending-due-summary',
  pendingDueDetails: '/pending-due-summary/:id',
  collectionList: '/payments/collection-list',
  help: '/help',
  settings: '/settings',
  tags: '/tags',
  transactionDetails: '/transaction/:transactionId',
  bills: '/transaction/:transactionId/bills',
  editEntity: '/:entity(customer|supplier)/edit/:id',
  accountTags: '/:entity(customer|supplier)/edit/:id/tags',
  accountBeats: '/:entity(customer|supplier)/edit/:id/beats',
  accountRoutes: '/:entity(customer|supplier)/edit/:id/routes',
  addEntity: '/:entity(customer|supplier)/add',
  addEntitySuccess: '/:entity(customer|supplier)/add/success',
  deleteEntity: '/:entity(customer|supplier)/delete/:id',
  editMerchant: '/merchant/edit/:id',
  addTransaction: `/:entity(customer|supplier)/:id/transaction/add/:type(${Object.values(
    TRANSACTION_TYPE
  ).join('|')})`,
  manageAccounts: '/manage-accounts',
  chequeManagement: '/cheque-management',
  defaulterLink: '/app-blocked'
}

export const blindpayPaths = {
  introduction: '/easypay/:blindPayId',
  destinationDetails: '/easypay/:blindPayId/destination'
}

const MobileHome = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "MobileHomeChunk" */ './pages/MobileHome'))
)

const TransactionList = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "TransactionListChunk" */ './pages/TransactionList'))
)

const Settings = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "SettingsChunk" */ './pages/Settings'))
)

const EditTransaction = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "EditTransactionChunk" */ './pages/EditTransaction'))
)

const Account = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "AccountChunk" */ './pages/Account'))
)

const CustomerProfile = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "CustomerProfileChunk" */ './pages/CustomerProfile'))
)

const Help = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "HelpChunk" */ './pages/Help'))
)

const MobileAuth = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "MobileAuthChunk" */ './pages/MobileLogin'))
)

const MerchantProfile = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "MerchantProfileChunk" */ './pages/MerchantProfile'))
)

const Auth = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "AuthChunk" */ './pages/Auth'))
)

const Dashboard = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "DashboardChunk" */ './pages/Dashboard'))
)

const MobileDashboard = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "MobileDashboardChunk" */ './pages/MobileDashboard'))
)

const SelectBusinessDetail = loadable(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "SelectBusinessDetailChunk" */ './pages/SelectBusinessDetail')
  )
)

const Welcome = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "WelcomeChunk" */ './pages/Welcome'))
)

const SelectLanguage = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "SelectLanguageChunk" */ './pages/SelectLanguage'))
)

const PageNotFound = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "PageNotFoundChunk" */ './pages/PageNotFound'))
)

const PlatformUnsupported = loadable(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "PaltformUnsupportedChunk" */ './pages/PlatformUnsupported')
  )
)

const BlindPay = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "BlindPayChunk" */ './pages/BlindPay'))
)

const BlindPayVerifyOTP = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "VerifyOTPChunk" */ './pages/BlindPay/VerifyOTP'))
)

const BlindPaySuccess = loadable(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "BlindPaySuccessChunk" */ './pages/BlindPay/InfoScreens/Success')
  )
)

const BlindPayClaimed = loadable(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "BlindPayClaimedChunk" */ './pages/BlindPay/InfoScreens/Claimed')
  )
)

const BlindPayExpired = loadable(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "BlindPayExpiredChunk" */ './pages/BlindPay/InfoScreens/Expired')
  )
)

const StaffLink = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "StaffLinkChunk" */ './experiments/StaffLink'))
)
const OnlineBills = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "StaffLinkChunk" */ './experiments/OnlineBills'))
)

/* OrderQR start */
const OrderQR = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "OrderQRChunk" */ './experiments/OrderQR'))
)

const AddAddress = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AddAddressChunk" */ './experiments/OrderQR/pages/AddShippingAddress'
      )
  )
)

const ConfirmOrder = loadable(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "ConfirmOrderChunk" */ './experiments/OrderQR/pages/ConfirmOrder')
  )
)

const QROrderStatus = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "QROrderStatusChunk" */ './experiments/OrderQR/pages/QROrderStatus'
      )
  )
)
/* OrderQR end */

const OnboardingDashboard = loadable(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OnboardingDashboardChunk" */ './pages/OnboardingDashboard')
  )
)

const OnboardingForm = loadable(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OnboardingDashboardChunk" */ './pages/OnboradingForm')
  )
)

const RevisitedForm = loadable(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "OnboardingDashboardChunk" */ './pages/RevisitedForm')
  )
)

const ReminderForm = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "ReminderForm" */ './pages/ReminderForm'))
)

const OkCoins = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "OKCoins" */ './experiments/OkCoin/pages/OkCoins'))
)
const OkCoinsResults = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "OKCoins" */ './experiments/OkCoin/pages/Results'))
)

const Advertisement = loadable(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Advertisement" */ './experiments/Advertisement/pages/index')
  )
)

const DefaulterInfo = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "Advertisement" */ './experiments/DefaulterInfo/pages/DefaulterInfo'
      )
  )
)

const DefaulterPage = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "DefaulterPage" */ './pages/DefaulterPage'))
)

/* StaffLink order start */
const staffLinkSelectCustomer = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "staffLinkSelectCustomer" */ './experiments/StaffLinkOrder/pages/StaffLinkSelectCustomer/StaffLinkSelectCustomer'
      )
  )
)

const staffLinkAddOrder = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "staffLinkAddOrder" */ './experiments/StaffLinkOrder/pages/StaffLinkAddOrder/StaffLinkAddOrder'
      )
  )
)

const staffLinkViewCardOrder = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "staffLinkViewCardOrder" */ './experiments/StaffLinkOrder/pages/StaffLinkViewCardOrder/StaffLinkViewCardOrder'
      )
  )
)

const staffLinkOrderHistory = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "staffLinkOrderHistory" */ './experiments/StaffLinkOrder/pages/StaffLinkOrderHistory/StaffLinkOrderHistory'
      )
  )
)

const staffLinkOrderCartHistory = loadable(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "staffLinkOrderCartHistory" */ './experiments/StaffLinkOrder/pages/StaffLinkOrderHistory/StaffLinkOrderDetails'
      )
  )
)
/* StaffLink order end */

export const CommonRoutes = [
  {
    ...BlindPayVerifyOTP,
    path: '/easypay/:blindPayId/otpVerify',
    exact: true
  },
  {
    ...BlindPaySuccess,
    path: '/easypay/:blindPayId/success',
    exact: true
  },
  {
    ...BlindPayClaimed,
    path: '/easypay/:blindPayId/claimed',
    exact: true
  },
  {
    ...BlindPayExpired,
    path: '/easypay/:blindPayId/expired',
    exact: true
  },
  {
    ...BlindPay,
    path: [...Object.values(blindpayPaths)], // txn id will be there in url
    exact: true
  },
  /*
   * Experiment: StaffLink routes.
   * Refer shared/experiments/StaffLink/Readme.md for more info
   */
  {
    ...StaffLink,
    path: '/staff/:businessId/:staffLinkId', // businessId = merchantId
    exact: true
  },
  {
    ...OnlineBills,
    path: '/online-bills/:billId',
    exact: true
  },
  /*
   * Experiment: OrderQR routes.
   * Refer shared/experiments/OrderQR/Readme.md for more info
   */
  {
    ...OrderQR,
    path: '/orderQR',
    exact: true
  },
  {
    ...AddAddress,
    path: '/orderQR/addAddress',
    exact: true
  },
  {
    ...ConfirmOrder,
    path: '/orderQR/confirmOrder',
    exact: true
  },
  {
    ...QROrderStatus,
    path: '/orderQR/orderStatus',
    exact: true
  },
  {
    ...OnboardingDashboard,
    path: '/dashboard/onboarding',
    exact: true
  },
  {
    ...OnboardingForm,
    path: '/dashboard/onboarding/form',
    exact: true
  },
  {
    ...RevisitedForm,
    path: '/dashboard/revisit/form/:merchantId',
    exact: true
  },
  {
    ...ReminderForm,
    path: '/reminder',
    exact: true
  },
  {
    ...Advertisement,
    path: '/baiadvert',
    exact: true
  },
  {
    ...DefaulterInfo,
    path: '/defaulterinfo',
    exact: true
  },
  {
    ...staffLinkSelectCustomer,
    path: '/staff-order/customer',
    exact: true
  },
  {
    ...staffLinkAddOrder,
    path: '/staff-order/customer/:customerId',
    exact: true
  },
  {
    ...staffLinkViewCardOrder,
    path: '/staff-order/customer/:customerId/cart',
    exact: true
  },
  {
    ...staffLinkOrderHistory,
    path: '/staff-order/orders/:customerId',
    exact: true
  },
  {
    ...staffLinkOrderCartHistory,
    path: '/staff-order/order/:orderId',
    exact: true
  }
]

export const MobileRoutes = [
  {
    ...MerchantProfile,
    path: '/profile',
    exact: true
  },
  {
    ...MobileHome,
    path: '/',
    exact: true
  },
  {
    ...MobileAuth,
    path: '/login'
  },
  {
    ...SelectBusinessDetail,
    path: '/select-business',
    exact: true
  },
  {
    ...Welcome,
    path: '/welcome',
    exact: true
  },
  {
    ...EditTransaction,
    path: '/dashboard/transactions/:transactionId',
    exact: true
  },
  {
    ...MobileDashboard,
    path: ['/dashboard', '/dashboard/compose/:entityType?'],
    exact: true
  },
  {
    ...TransactionList,
    path: `/dashboard/customers/:accountId/:action(${Object.values(TRANSACTION_TYPE).join('|')})?`,
    exact: true
  },
  {
    ...Settings,
    path: '/settings',
    exact: true
  },
  {
    ...Account,
    path: '/account',
    exact: true
  },
  {
    ...CustomerProfile,
    path: '/dashboard/customer-profile/:accountId',
    exact: true
  },
  {
    ...Help,
    path: '/help/:accountId',
    exact: true
  },
  {
    ...SelectLanguage,
    path: '/language',
    exact: true
  },
  {
    ...OnboardingDashboard,
    path: '/dashboard/onboarding',
    exact: true
  },
  {
    ...OnboardingForm,
    path: '/dashboard/onboarding/form',
    exact: true
  },
  {
    ...RevisitedForm,
    path: '/dashboard/revisit/form/:merchantId',
    exact: true
  },
  {
    ...OkCoins,
    path: '/okcoins',
    exact: true
  },
  {
    ...OkCoinsResults,
    path: '/okcoins/results',
    exact: true
  },
  {
    ...DefaulterPage,
    path: '/app-blocked',
    exact: true
  },
  ...CommonRoutes,
  {
    ...PageNotFound
  }
]

export const DesktopRoutes = [
  {
    ...PlatformUnsupported,
    path: '/not-supported'
  },
  {
    ...Auth,
    path: '/login',
    exact: true
  },
  {
    ...Dashboard,
    path: [...Object.values(paths)],
    exact: true
  },
  ...CommonRoutes
  // {
  //   ...PageNotFound
  // }
]
