export const BalancePrefixTypes = {
  '0': '',
  '1': 'Balance',
  '2': 'Credit',
  '3': 'Balance',
  '4': 'Pending Invoice Amount',
  '5': 'Pending Invoice Amount',
  '6': 'Pending Invoice Amount'
}

export const transactionType = {
  NA: 0,
  CASH: 1,
  ONLINE: 2,
  CREDIT_DUES: 3,
  CHEQUE: 4,
  NEFT: 5,
  RETURN: 6,
  DAMAGED: 7,
  UPI: 8,
  WALLET: 9,
  OTHER: 8
}
