import { IPaymentDetail, IBlindpayState } from './blindpay.types'

const paymentDetail: IPaymentDetail = {
  amount: 0,
  senderName: '',
  senderMobile: '',
  receiverMobile: '',
  paidAt: 0,
  expiryTime: 0,
  accountId: '',
  senderMerchantId: '',
  linkStatus: null
}

export const BlindpayState: IBlindpayState = {
  paymentDetail,
  otpId: '',
  txnToken: '',
  expiryTime: 0,
  verifiedPaymentAddress: {
    name: '',
    isValid: false,
    isVerifying: false
  },
  paymentId: '',
  sessionExpired: false,
  isLoading: false,
  authDevise: null
}
