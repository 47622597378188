import { Action } from 'infra/types'
import { FILTER_OPTION } from 'services/Api/StaffLinkApiFolder/type'
import { ITagFilterState } from './tag-filter.types'
import { tagFilterDrawerActions } from './tag-filter.actions'

const TAG_FILTER: ITagFilterState = {
  selectedTagsFilter: [],
  selectedTagsFilterType: FILTER_OPTION.OR,
  buttonType: '',
  drawers: {
    showTagFilterDrawer: false
  },
  showSave: true
}

export const TagFilterReducer = (
  state: ITagFilterState = TAG_FILTER,
  action: Action<any>
): ITagFilterState => {
  switch (action.type) {
    case tagFilterDrawerActions.SET_TAG_FILTER_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showTagFilterDrawer: action.payload.drawerStatus
        }
      }
    }
    case tagFilterDrawerActions.RESET_TAG_FILTER: {
      return {
        selectedTagsFilter: [],
        selectedTagsFilterType: FILTER_OPTION.OR,
        buttonType: '',
        drawers: {
          showTagFilterDrawer: false
        },
        showSave: true
      }
    }
    case tagFilterDrawerActions.SET_SELECTED_TAGS_DATA: {
      return {
        ...state,
        drawers: {
          showTagFilterDrawer: action.payload.drawerStatus || state.drawers.showTagFilterDrawer
        },
        selectedTagsFilter: action.payload.selectedTagsFilter,
        selectedTagsFilterType: action.payload.selectedTagsFilterType,
        buttonType: action.payload.buttonType || state.buttonType,
        showSave: action.payload.showSave === undefined ? state.showSave : action.payload.showSave
      }
    }
    default: {
      return state
    }
  }
}
