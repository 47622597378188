import { Action } from 'infra/types'
import { FILTER_OPTION } from 'services/Api/types'

export const tagFilterDrawerActions = {
  SET_TAG_FILTER_DRAWER: 'tagFilterDrawer/setTagFilterDrawer',
  SET_SELECTED_TAGS_DATA: 'tagFilterDrawer/setSelectedTagsData',
  RESET_TAG_FILTER: 'tagFilterDrawer/resetTagFilter'
}

export const setTagFilterDrawer = (payload: {
  drawerStatus: boolean
}): Action<{
  drawerStatus: boolean
}> => {
  return {
    type: tagFilterDrawerActions.SET_TAG_FILTER_DRAWER,
    payload
  }
}

export const resetTagFilterState = () => {
  return {
    type: tagFilterDrawerActions.RESET_TAG_FILTER
  }
}

export const setSelectedTagsDataAndButtonType = (payload: {
  selectedTagsFilter: string[]
  selectedTagsFilterType: FILTER_OPTION
  buttonType?: 'save_and_apply' | 'apply'
  drawerStatus?: boolean
  showSave?: boolean
}): Action<{
  selectedTagsFilter: string[]
  selectedTagsFilterType: FILTER_OPTION
  buttonType?: 'save_and_apply' | 'apply'
  drawerStatus?: boolean
  showSave?: boolean
}> => {
  return {
    type: tagFilterDrawerActions.SET_SELECTED_TAGS_DATA,
    payload
  }
}
