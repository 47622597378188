import { SUMMARY_FILTER_OPTION } from 'pages/CollectionSummary/CollectionSummary.constants'
import { ISummaryV2DateFilter } from 'pages/CollectionSummary/CollectionSummary.types'
import { COLLECTION_FILTER_TYPE } from 'pages/NewDashboard/components/Summary/components/CollectionContainer/CollectionHeader/constants'
import { TRANSACTION_TYPE } from 'state/collectionList/collectionList.types'

interface IPagination {
  total: number
  page: number
  perPage: number
  totalItems: number
}

export interface ILoaders {
  listSummary?: boolean
}

export interface IListViewShortSummaryData {
  openingBalance: number
  addedCredit: number
  totalCollections: number
  pendingDue: number
  cash: number
  neft: number
  online: number
  cheque: number
  return: number
  damage: number
  others: number
  advanceUsed: number
  advanceCollected: number
  walletBalance?: number
}

export interface IListViewRowData extends IListViewShortSummaryData {
  id: string
  listName: string
}

export interface IListGroupViewDataInvoice {
  latestSettlementDate: string
  invoiceDate: string
  invoiceNumber: string
  invoiceAmount: number
  totalCollections: number
  pendingDue: number
  cash: number
  neft: number
  online: number
  cheque: number
  return: number
  damage: number
  customerCode: string
  customerId: string
  customerName: string
  invoiceId: string
  others: number
  advanceUsed: number
  advanceCollected: number
}

export interface IListViewData {
  rowData: IListViewRowData[]
  shortSummaryData: IListViewShortSummaryData
  pagination: IPagination
}

export interface IFetchPayload {
  data: IListViewData
  invoiceCollectionFilter: SUMMARY_FILTER_OPTION
}

export interface IGroupViewData {
  rowData: IListGroupViewDataInvoice[]
  pagination: IPagination
}

export interface IFilter {
  filter_condition: 'or' | 'and'
  tags: string[]
}

export interface IStatementGroup {
  business_id: string
  create_time: string
  filters: null | IFilter
  id: string
  name: string
  update_time: string
}
export interface ITransactionDetails {
  transactionTime: number
  id: string
  amount: number
  accountTxnId: string
  notes: {
    utr: string
    paymentTransactionId: string
    paymentChannel: string
    pspPaymentId: string
    payerVpa: string
    payerName: string
    timestamp: string
    settlementUtr?: string
    settlementStatus?: string
  }
  txnType: TRANSACTION_TYPE
}
export type SORT_ORDER = {
  ascending: 1
  descending: 0
}

export enum SORT_ORDER_TYPE {
  ascending = 'ascending',
  descending = 'descending'
}

export interface ICollectionSummaryState {
  loaders: {
    listSummary: boolean
    groupSummary: boolean
    transactionDetails: boolean
    allCollectionSummary: boolean
  }
  listViewData: IListViewData | null
  overallSummaryData: IListViewRowData | null
  activeFilter: ISummaryV2DateFilter | null
  groupViewData: IGroupViewData | null
  invoiceCollectionFilter:
    | SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
    | SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS
    | SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
  selectedSummary: IListViewRowData | undefined
  statementGroup: IStatementGroup | null
  selectedTransaction: Array<ITransactionDetails> | undefined
  drawers: {
    transactionDetails: boolean
    downloadDialogOpen: boolean
    replacementDialogOpen: boolean
    paymentsToTallyDrawer: boolean
    creditsToTallyDrawer: boolean
    purchaseSummaryDrawer: boolean
  }
  modals: {
    downloadSingleGroupModal: boolean
  }
  sortBy?: string
  sortOrder?: null | SORT_ORDER_TYPE

  innerTableSortOption?: {
    sortBy?: string
    sortOrder?: SORT_ORDER_TYPE
  }
}

export interface IFetchCollectionListInvoiceSummaryV2ActionPayload {
  startTime: number
  endTime: number
  page: number
  perPage: number
  invoiceCollectionFilter:
    | SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
    | SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
    | SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS
  sortBy?: null | string
  sortOrder?: null | SORT_ORDER_TYPE
  includeWalletBalance?: boolean
  filterType: COLLECTION_FILTER_TYPE
}

export interface IFetchCollectionGroupInvoiceSummaryV2ActionPayload {
  startTime: number
  endTime: number
  page: number
  perPage: number
  listId: string
  invoiceCollectionFilter:
    | SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
    | SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
    | SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS
  sortBy?: string
  sortOrder?: SORT_ORDER_TYPE | null
  filterType: COLLECTION_FILTER_TYPE
}

export interface ITransactionAction {
  accountId: string
  listId: string
  invoiceId: string
  startTime: number
  endTime: number
}

export interface CollectionSummaryModalStatus {
  modalName: CollectionSummaryModalNameEnum
  status: boolean
}

export enum CollectionSummaryModalNameEnum {
  downloadSingleGroupModal = 'downloadSingleGroupModal'
}

export interface IDownloadCollectionSingleSummaryPayload
  extends IFetchCollectionGroupInvoiceSummaryV2ActionPayload {
  fileName: string
}
