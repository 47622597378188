import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Button, CloseIcon, Spinner, UploadIcon } from '@okcredit/web-lib'
import { IAppState } from 'infra/AppState'
import { IconArrowDropWithBg } from 'assets/icons'
import { THEME } from 'components/theme'
import {
  fetchTemplatesList,
  handleDrawerToggle
} from 'state/new-summary/uploadSheet/uploadSheet.actions'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { ITemplate } from 'state/new-summary/uploadSheet/uploadSheet.types'
import useInfoList from '../../useInfoList'
import SelectedTemplates from './SelectedTemplates'
import { TemplateSelectorStyles } from './styles'
import OtherTemplates from './OtherTemplates'

const TemplateSelectorWrapper = () => {
  const classes = TemplateSelectorStyles()
  const dispatch = useDispatch()
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false)
  const { handleUploadDocument } = useInfoList()

  const handleToggleSelector = () => {
    setIsSelectorOpen((status) => !status)
  }

  const handleOpenSelector = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault()
    handleToggleSelector()
    dispatch(pushEvent(NEWDASHBOARD_EVENTS.UPLOAD_SUPPLY_SHEET_ARROW_CLICKED))
  }

  return (
    <div className={classes.container}>
      <div className={classes.uploadButton}>
        <label>
          <UploadIcon size={20} fill="white" />
          <input
            value=""
            type="file"
            onClick={() => dispatch(pushEvent(NEWDASHBOARD_EVENTS.UPLOAD_SUPPLY_SHEET_CLICKED))}
            onChange={handleUploadDocument}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <span>Upload Supply Sheet</span>
          <img
            src={IconArrowDropWithBg}
            alt="Arrow Drop"
            height="32px"
            width="32px"
            className={classNames(classes.selectorIcon, {
              [classes.selectorIconOpen]: isSelectorOpen
            })}
            onClick={handleOpenSelector}
          />
        </label>
      </div>
      {isSelectorOpen ? <TemplateSelector handleToggleSelector={handleToggleSelector} /> : null}
    </div>
  )
}

const TemplateSelector = ({ handleToggleSelector }: { handleToggleSelector: () => void }) => {
  const classes = TemplateSelectorStyles()
  const dispatch = useDispatch()
  const { merchantProfile } = useSelector((app: IAppState) => app.Dashboard)
  const {
    templatesList,
    globalTemplatesList,
    loaders: {
      fetchTemplatesList: fetchTemplatesListLoader,
      updateDefaultTemplate: updateDefaultTemplateLoader
    }
  } = useSelector((app: IAppState) => app.RootDrawers.UploadSheet)

  useEffect(() => {
    if (merchantProfile.data?.id) {
      dispatch(fetchTemplatesList())
    }
  }, [merchantProfile.data?.id])

  const { selectedTemplates, otherTemplates } = useMemo(() => {
    const selected: ITemplate[] = []
    const others: ITemplate[] = []
    const templates = [...globalTemplatesList, ...templatesList]
    templates.forEach((template) => {
      if (template.is_default) {
        selected.push(template)
      } else {
        others.push(template)
      }
    })
    return {
      selectedTemplates: selected,
      otherTemplates: others
    }
  }, [globalTemplatesList, templatesList])

  const handleAddNew = () => {
    dispatch(pushEvent(NEWDASHBOARD_EVENTS.ADD_NEW_TEMPLATE_CLICKED))
    handleToggleSelector()
    dispatch(handleDrawerToggle({ newTemplateDrawer: true }))
  }

  return (
    <div className={classes.selectorContainerOverlay} onClick={handleToggleSelector}>
      <div
        className={classes.selectorContainer}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <SelectedTemplates templates={selectedTemplates} />
        {otherTemplates.length ? <OtherTemplates templates={otherTemplates} /> : null}
        <Button
          fullWidth
          startIcon={<CloseIcon className={classes.addIcon} fill={THEME.brandGreen} />}
          variant="outlined"
          style={{ marginTop: 8 }}
          onClick={handleAddNew}
        >
          Add New Template
        </Button>
        {(fetchTemplatesListLoader || updateDefaultTemplateLoader) && (
          <div className={classes.overlay}>
            <Spinner size={48} className={classes.spinner} color={THEME.brandGreen} />
          </div>
        )}
      </div>
    </div>
  )
}

export default TemplateSelectorWrapper
