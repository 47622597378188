import { Action } from 'infra/types'
import {
  FetchUnidentifiedCollectionActionPayload,
  IAddBillToCollectionsAction,
  IUnidentidfiedCollectionState,
  IUnidentifiedCollectionActionsRowData,
  UnidentifiedCollectionFilterType
} from './unidentifiedCollection.types'

export const unidentifiedCollectionAction = {
  FETCH_UNIDENTIFIED_COLLECTION: 'approve/fetchUnidentifiedCollection',
  SET_UNIDENTIFIED_COLLECTION_DATA: 'approve/setUnidentifiedCollectionActionData',
  SET_SELECTED_COLLECTION: 'approve/setSelectedCollection',
  SET_SHOW_BILL_DRAWER: 'approve/setShowBillDrawer',
  ADD_BILL_TO_COLLECTION: 'approve/addBillToCollections',
  HANDLE_SEARCH: 'approve/handleSearch',
  SET_COLLECTION_ACTION_FILTER: 'approve/setCollectionActionFilter',
  INITIATE_BANK_DEPOSIT: 'approve/initiateBankDeposit',
  INITIATE_CASH_DEPOSIT: 'approve/initiateCashDeposit',
  FETCH_BANK_DEPOSIT: 'approve/fetchBankDeposit',
  FETCH_CASH_DEPOSIT: 'approve/fetchCashDeposit',
  RESET_BANK_AND_CASH_DEPOSIT: 'approve/resetBankAndCashDeposit',
  SAVE_PENDING_CASH_DEPOSIT: 'approve/savePendingCashDeposit',
  SAVE_PENDING_BANK_DEPOSIT: 'approve/savePendingBankDeposit',
  EDIT_BANK_DEPOSIT: 'approve/editBankDeposit',
  EDIT_CASH_DEPOSIT: 'approve/editCashDeposit',
  SET_FETCHING_LOADERS: 'approve/setFetchingLoaders'
}

export const setFetchingLoaders = (payload: boolean): Action<boolean> => {
  return {
    type: unidentifiedCollectionAction.SET_FETCHING_LOADERS,
    payload
  }
}

export const fetchUnidentifiedCollectionActions = (
  payload: FetchUnidentifiedCollectionActionPayload
): Action<FetchUnidentifiedCollectionActionPayload> => {
  return {
    type: unidentifiedCollectionAction.FETCH_UNIDENTIFIED_COLLECTION,
    payload
  }
}

export const setUnidentifiedCollectionActionData = (
  payload: IUnidentidfiedCollectionState['unidentifiedCollections']
): Action<IUnidentidfiedCollectionState['unidentifiedCollections']> => {
  return {
    type: unidentifiedCollectionAction.SET_UNIDENTIFIED_COLLECTION_DATA,
    payload
  }
}

export const setSelectedCollection = (
  payload: IUnidentifiedCollectionActionsRowData
): Action<IUnidentifiedCollectionActionsRowData> => {
  return {
    type: unidentifiedCollectionAction.SET_SELECTED_COLLECTION,
    payload
  }
}

export const setShowBillDrawer = (payload: boolean): Action<boolean> => {
  return {
    type: unidentifiedCollectionAction.SET_SHOW_BILL_DRAWER,
    payload
  }
}

export const addBillToCollections = (
  payload: IAddBillToCollectionsAction
): Action<IAddBillToCollectionsAction> => {
  return {
    type: unidentifiedCollectionAction.ADD_BILL_TO_COLLECTION,
    payload
  }
}

export const handleSearch = (payload: string): Action<string> => {
  return {
    type: unidentifiedCollectionAction.HANDLE_SEARCH,
    payload
  }
}

export const setCollectionActionFilters = (payload: {
  filter: UnidentifiedCollectionFilterType
}): Action<{ filter: UnidentifiedCollectionFilterType }> => {
  return {
    type: unidentifiedCollectionAction.SET_COLLECTION_ACTION_FILTER,
    payload
  }
}

export const createInitiateBankDepositAction = (payload: any) => {
  return {
    type: unidentifiedCollectionAction.INITIATE_BANK_DEPOSIT,
    payload
  }
}

export const createInitiateCashDepositAction = (payload: any) => {
  return {
    type: unidentifiedCollectionAction.INITIATE_CASH_DEPOSIT,
    payload
  }
}

export const fetchCashDeposit = () => {
  return {
    type: unidentifiedCollectionAction.FETCH_CASH_DEPOSIT
  }
}

export const fetchBankDeposit = () => {
  return {
    type: unidentifiedCollectionAction.FETCH_BANK_DEPOSIT
  }
}

export const resetBankAndCashDeposit = () => {
  return {
    type: unidentifiedCollectionAction.RESET_BANK_AND_CASH_DEPOSIT
  }
}

export const savePendingCashDeposits = (payload: any) => {
  return {
    type: unidentifiedCollectionAction.SAVE_PENDING_CASH_DEPOSIT,
    payload
  }
}

export const savePendingBankDeposits = (payload: any) => {
  return {
    type: unidentifiedCollectionAction.SAVE_PENDING_BANK_DEPOSIT,
    payload
  }
}

export const editBankDepositAction = (payload: any) => {
  return {
    type: unidentifiedCollectionAction.EDIT_BANK_DEPOSIT,
    payload
  }
}

export const editCashDepositAction = (payload: any) => {
  return {
    type: unidentifiedCollectionAction.EDIT_CASH_DEPOSIT,
    payload
  }
}
