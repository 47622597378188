import { takeEvery, takeLatest } from 'redux-saga/effects'
import { BlindpayActions } from './blindpay.actions'
import {
  fetchPaymentDetails,
  verifyOtp,
  requestOtp,
  verifyDestinationDetails,
  submitDestinationDetails
} from './blindpay.effects'
import blindPayAnalytics from './blindpay.analytics'

function* fetchPaymentDetailsSaga() {
  yield takeLatest(BlindpayActions.FETCH_PAYMENT_DETAILS, fetchPaymentDetails)
}

function* requestOtpSaga() {
  yield takeLatest(BlindpayActions.SEND_OTP, requestOtp)
}

function* verifyOtpSaga() {
  yield takeLatest(BlindpayActions.VERIFY_OTP, verifyOtp)
}

function* resendOtpSaga() {
  yield takeLatest(BlindpayActions.RESEND_OTP, requestOtp)
}

function* verifyDestinationDetailsSaga() {
  yield takeLatest(BlindpayActions.VERIFY_DESTINATION_DETAILS, verifyDestinationDetails)
}

function* submitDestinationDetailsSaga() {
  yield takeLatest(BlindpayActions.SUBMIT_DESTINATION_DETAILS, submitDestinationDetails)
}

function* blindPayAnalyticsSaga() {
  yield takeEvery([...Object.values(BlindpayActions)], blindPayAnalytics)
}

export const BlindpayEffects = [
  fetchPaymentDetailsSaga(),
  requestOtpSaga(),
  verifyOtpSaga(),
  resendOtpSaga(),
  verifyDestinationDetailsSaga(),
  submitDestinationDetailsSaga(),
  blindPayAnalyticsSaga()
]
