import { Action } from 'infra/types'
import {
  IPendingDueGroupElement,
  IPendingDueIdData,
  detailedDueSortBy
} from './pendingDueSummary.types'

export const pendingDueSummaryActions = {
  FETCH_PENDING_DUE_GROUP_SUMMARY: 'pendingDue/fetchPendingDueGroupSummary',
  PENDING_DUE_SELECTED_FILTER: 'pendingDue/activePendingFilter',
  SET_PENDING_DUE_GROUP_DATA: 'pendingDue/setPendingDueData',
  FETCH_PENDING_DUE_GROUP_ID: 'pendingDue/fetchPendingDueGroupId',
  SET_PENDING_DUE_GROUP_ID_DATA: 'pendingDue/setPendingDueGroupIdData',
  SET_DRAWER: 'pendingDue/setDrawer',
  DOWNLOAD_SUMMARY: 'pendingDue/downloadSummary',
  EDIT_GROUP_NAME: 'pendingDue/editGroupName',
  EDIT_GROUP_NAME_LOADER: 'pendingDue/editGroupNameLoader',
  DOWNLOAD_GROUP_LEVEL_SUMMARY: 'pendingDue/downloadGroupLevelSummary',
  SET_PENDING_DUE_SUMMARY_LOADING: 'pendingDue/isPendingDueSummaryLoading',
  UPDATE_GROUP_NAME: 'pendingDue/updateGroupName',
  RESET_DETAILED_GROUP_DATA: 'pendingDue/resetDetailedGroupData',
  FETCH_PENDING_OVERALL_GROUP_SUMMARY: 'pendingDue/FETCH_PENDING_OVERALL_GROUP_SUMMARY',
  SET_PENDING_DUE_OVERALL_GROUP_DATA: 'pendingDue/SET_PENDING_DUE_OVERALL_GROUP_DATA',
  FETCH_PENDING_DUE_OVERALL_INNER_GROUP: 'pendingDue/FETCH_PENDING_DUE_OVERALL_INNER_GROUP',
  SET_PENDING_DUE_OVERALL_INNER_GROUP: 'pendingDue/SET_PENDING_DUE_OVERALL_INNER_GROUP',
  DOWNLOAD_OVERALL_SUMMARY: 'pendingDue/DOWNLOAD_OVERALL_SUMMARY'
}

export const fetchPendingDueGroupSummaryAction = (payload: {
  filter: number
  pageNumber: number
  sortBy: 'group_name' | 'invoice_count' | 'pending_due'
  sortDescending?: boolean
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.FETCH_PENDING_DUE_GROUP_SUMMARY,
    payload
  }
}

export const setPendingDueFilter = (payload: { filter: number }) => ({
  type: pendingDueSummaryActions.PENDING_DUE_SELECTED_FILTER,
  payload
})

export const setPendingDueGroupData = (payload: {
  pendingDueGroupData: IPendingDueGroupElement[]
  groupDueCurrentPage: number
  groupDuetotalEntries: number
}) => ({
  type: pendingDueSummaryActions.SET_PENDING_DUE_GROUP_DATA,
  payload
})

export const fetchPendingDueForGroupId = (payload: {
  groupId: string
  filter: number
  pageNumber: number
  sortBy: detailedDueSortBy
  pending_days_gte?: number
  pending_days_lte?: number
  search_string?: string
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.FETCH_PENDING_DUE_GROUP_ID,
    payload
  }
}

export const resetDetailedGroupDataAction = () => {
  return {
    type: pendingDueSummaryActions.RESET_DETAILED_GROUP_DATA
  }
}

export const setPendingDueGroupId = (payload: {
  pendingDueGroupIdData: IPendingDueIdData[]
  detailedDueSortBy: detailedDueSortBy
  detailedDueTotalInvoiceCount: number
  detailedDueTotalPendingDue: number
  pendingDueRangeFilter?: {
    lte: number
    gte: number
  }
  detailedDueCurrentPage: number
  detailedDuetotalEntries: number
  detailedDueGroupName: string
  billsCountGt: number
  filter: number
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.SET_PENDING_DUE_GROUP_ID_DATA,
    payload
  }
}

export const setModal = (payload: { modalName: string; status: boolean }): Action<any> => {
  return {
    type: pendingDueSummaryActions.SET_DRAWER,
    payload
  }
}

export const editGroupName = (payload: {
  staffLinkId: string
  updatedName: string
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.EDIT_GROUP_NAME,
    payload
  }
}

export const downloadPendingDueGroupIdSummary = (payload: {
  groupId: string
  filter: number
  pageNumber: number
  sortBy: detailedDueSortBy
  pending_days_gte?: number
  pending_days_lte?: number
  fileName: string
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.DOWNLOAD_SUMMARY,
    payload
  }
}

export const downloadPendingDueGroupSummary = (payload: {
  filter: number
  fileName: string
  selectedSheetType: string
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.DOWNLOAD_GROUP_LEVEL_SUMMARY,
    payload
  }
}

export const setPendingSummaryLoader = (payload: {
  isPendingDueSummaryLoading?: boolean
  isOverallPendingDueSummaryLoading?: boolean
  isPendingDueInnerLoading?: boolean
}) => {
  return {
    type: pendingDueSummaryActions.SET_PENDING_DUE_SUMMARY_LOADING,
    payload
  }
}

export const setEditNameLoader = (payload: { editNameLoading: boolean }) => {
  return {
    type: pendingDueSummaryActions.EDIT_GROUP_NAME_LOADER,
    payload
  }
}

export const setUpdatedGroupName = (payload: { updatedName: string }) => {
  return {
    type: pendingDueSummaryActions.UPDATE_GROUP_NAME,
    payload
  }
}

export const fetchPendingDueOverallSummaryAction = (payload: {
  filter: number
  pageNumber: number
  sortBy: 'group_name' | 'invoice_count' | 'pending_due'
  sortDescending?: boolean
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.FETCH_PENDING_OVERALL_GROUP_SUMMARY,
    payload
  }
}

export const setPendingDueOverallGroupData = (payload: {
  overallPendingDueSummary: IPendingDueGroupElement
  groupDuetotalEntries: number
}) => ({
  type: pendingDueSummaryActions.SET_PENDING_DUE_OVERALL_GROUP_DATA,
  payload
})

export const fetchPendingDueForOverallGroup = (payload: {
  groupId: string
  filter: number
  pageNumber: number
  sortBy: detailedDueSortBy
  pending_days_gte?: number
  pending_days_lte?: number
  search_string?: string
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.FETCH_PENDING_DUE_OVERALL_INNER_GROUP,
    payload
  }
}

export const setPendingDueForOverallGroup = (payload: {
  pendingDueGroupIdData: IPendingDueIdData[]
  detailedDueSortBy: detailedDueSortBy
  detailedDueTotalInvoiceCount: number
  detailedDueTotalPendingDue: number
  pendingDueRangeFilter?: {
    lte: number
    gte: number
  }
  detailedDueCurrentPage: number
  detailedDuetotalEntries: number
  detailedDueGroupName: string
  billsCountGt: number
  filter: number
}): Action<any> => {
  return {
    type: pendingDueSummaryActions.SET_PENDING_DUE_OVERALL_INNER_GROUP,
    payload
  }
}
