import { Action } from 'infra/types'
import { DUE_CONFIG } from 'services/Api/StaffLinkApiFolder/type'
import { ISelectedSettingsForAddTxn } from 'state/new-summary/list/list.types'
import { CL_VERSIONS } from 'utils/common/common.types'
import { ILoaders } from './addToCollection.types'

export const addToCollectionActions = {
  SET_LOADER: 'addToCollectionActions/setLoader',
  SET_ADD_TO_COLLECTION_DRAWER_DATA: 'addToCollectionActions/setAddtoCollectionDrawersData',
  RESET_ADD_TO_COLLECTION_DRAWER_STATE: 'addToCollectionActions/resetDrawerData'
}

export const setAddToCollectionDrawerWithData = (payload: {
  status: boolean
  selectedAssignmentFilter: CL_VERSIONS
  config: DUE_CONFIG
  selectedSettingsForAddTxn: ISelectedSettingsForAddTxn | null
}): Action<{
  status: boolean
  selectedAssignmentFilter: CL_VERSIONS
  config: DUE_CONFIG
  selectedSettingsForAddTxn: ISelectedSettingsForAddTxn | null
}> => {
  return {
    type: addToCollectionActions.SET_ADD_TO_COLLECTION_DRAWER_DATA,
    payload
  }
}

export const resetAddToCollectionDrawer = (): Action<null> => ({
  type: addToCollectionActions.RESET_ADD_TO_COLLECTION_DRAWER_STATE,
  payload: null
})

export const handleLoaderToggle = (payload: ILoaders): Action<ILoaders> => ({
  type: addToCollectionActions.SET_LOADER,
  payload
})
