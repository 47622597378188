import ApiService from 'services/Api/ApiService'
import { getProxyBaseUrl } from 'utils/getBaseUrl'

const baseHeaders = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const merchantendpoint = getProxyBaseUrl('MERCHANT_UPI_BASE_URL') + '/api/payment2/v1' || '' // Non proxied api: https://payment2.staging.okcredit.io/v1/destination/businessId
const endpoint = getProxyBaseUrl('ORDER_QR_BASE_URL') + '/api/collection/v1/' || '' // Non proxied API: https://collection.staging.okcredit.io/v1/SetActiveDestination
const paymentApiEndPoint = getProxyBaseUrl('ORDER_QR_BASE_URL') + '/api/payment2/v1/' || ''
const kycendpoint = getProxyBaseUrl('KYC_STATUS_BASE_URL') + '/api/risk-engine-v2/v2' || '' // Non proxied API: https://risk-engine-v2.staging.okcredit.in/v2/UserRiskInfo’

const MERCHANT_UPI_ID_ENDPOINT = '/destination'
const SETTING_BANK_ID_ENDPOINT = '/SetActiveDestination'
const ADD_SHIPPING_ADDRESS_ENDPOINT = '/StoreMerchantAddress'
const GET_SHIPPING_ADDRESS_ENDPOINT = '/GetMerchantPhysicalAddress'
const GET_KYC_STATUS_ENDPOINT = '/UserRiskInfo'
const GET_MERCHANT_PROFILE_ENDPOINT = '/GetMerchantProfile'
const GET_RETRIEVE_SUBSCRIPTION_ENDPOINT = '/RetrieveSubscription'
const GET_CREATE_SUBSCRIPTION_ENDPOINT = '/CreateSubscription'
const GET_QR_VPA_ENDPOINT = '/FetchQrVpa'

// GET MERCHANT DETAILS

const getUpiId = (payload: { businessId: string; authToken: string; authV3: boolean }) => {
  let headers
  if (payload.authV3) {
    headers = {
      ...baseHeaders,
      'OKC-BUSINESS-ID': payload.businessId
    }
  } else {
    headers = {
      ...baseHeaders,
      Authorization: `Bearer ${payload.authToken}`
    }
  }
  const api = new ApiService(merchantendpoint, headers)
  // const api = new ApiService(getEnvConfig('MERCHANT_UPI_BASE_URL'), headers)
  return api.getRequest(MERCHANT_UPI_ID_ENDPOINT + '/' + payload.businessId, {})
}

// CONFIRM BANK DETAILS

const setUpi = (payload: {
  authToken: string
  businessId: string
  upiId: string
  type: string
  authV3: boolean
}) => {
  let headers
  if (payload.authV3) {
    headers = {
      ...baseHeaders,
      'OKC-BUSINESS-ID': payload.businessId
    }
  } else {
    headers = {
      ...baseHeaders,
      Authorization: `Bearer ${payload.authToken}`
    }
  }
  // const api = new ApiService(getEnvConfig('ORDER_QR_BASE_URL'), headers)
  const api = new ApiService(endpoint, headers)

  return api.postRequest(SETTING_BANK_ID_ENDPOINT, {
    businessId: payload.businessId,
    destination: {
      type: payload.type,
      payment_address: payload.upiId
    }
  })
}

// CONFIRM SHIPPING ADDRESS AND PLACE ORDER

const addAddress = (payload: {
  authToken: string
  authV3: boolean
  shippingAddress: {
    merchant_id: string
    shop_number: string
    street_address: string
    landmark: string
    pincode: string
    city: string
    state: string
    source: string
    status: string
    marked_delivered_by: string
    tracking_number: string
    create_time: string
    expected_delivery_date: number
  }
}) => {
  let headers
  if (payload.authV3) {
    headers = {
      ...baseHeaders,
      'OKC-BUSINESS-ID': payload.shippingAddress.merchant_id
    }
  } else {
    headers = {
      ...baseHeaders,
      Authorization: `Bearer ${payload.authToken}`
    }
  }

  // const api = new ApiService(getEnvConfig('ORDER_QR_BASE_URL'), headers)
  const api = new ApiService(endpoint, headers)

  return api.postRequest(ADD_SHIPPING_ADDRESS_ENDPOINT, {
    merchant_id: payload.shippingAddress.merchant_id,
    shop_number: payload.shippingAddress.shop_number,
    street_address: payload.shippingAddress.street_address,
    landmark: payload.shippingAddress.landmark,
    pincode: payload.shippingAddress.pincode,
    city: payload.shippingAddress.city,
    state: payload.shippingAddress.state,
    source: payload.shippingAddress.source,
    status: payload.shippingAddress.status,
    marked_delivered_by: payload.shippingAddress.marked_delivered_by,
    tracking_number: payload.shippingAddress.tracking_number,
    create_time: payload.shippingAddress.create_time,
    expected_delivery_date: payload.shippingAddress.expected_delivery_date
  })
}

// GET SHIPPING ADDRESS IF PRESENT
const getShippingAddress = (payload: {
  businessId: string
  authToken: string
  authV3: boolean
}) => {
  let headers
  if (payload.authV3) {
    headers = {
      ...baseHeaders,
      'OKC-BUSINESS-ID': payload.businessId
    }
  } else {
    headers = {
      ...baseHeaders,
      Authorization: `Bearer ${payload.authToken}`,
      'OKC-BUSINESS-ID': payload.businessId
    }
  }
  const api = new ApiService(endpoint, headers)
  // const api = new ApiService(getEnvConfig('ORDER_QR_BASE_URL'), headers)
  return api.getRequest(GET_SHIPPING_ADDRESS_ENDPOINT, {})
}

// GET KYC STATUS
const getKycStatus = (payload: { businessId: string; authV3: boolean }) => {
  let headers
  if (payload.authV3) {
    headers = {
      ...baseHeaders,
      'OKC-BUSINESS-ID': payload.businessId
    }
  } else {
    headers = {
      ...baseHeaders
    }
  }

  // const api = new ApiService(getEnvConfig('KYC_STATUS_BASE_URL'), headers)
  const api = new ApiService(kycendpoint, headers)
  return api.postRequest(GET_KYC_STATUS_ENDPOINT, {
    user_id: payload.businessId,
    user_type: 'MERCHANT',
    service_name: 'collection'
  })
}

const getMerchantProfile = (payload: {
  businessId: string
  authToken: string
  authV3: boolean
}) => {
  let headers
  if (payload.authV3) {
    headers = {
      ...baseHeaders,
      'OKC-BUSINESS-ID': payload.businessId
    }
  } else {
    headers = {
      ...baseHeaders,
      Authorization: `Bearer ${payload.authToken}`
    }
  }
  // const api = new ApiService(getEnvConfig('ORDER_QR_BASE_URL'), headers)
  const api = new ApiService(endpoint, headers)
  return api.postRequest(GET_MERCHANT_PROFILE_ENDPOINT, {
    businessId: payload.businessId
  })
}

const getRetrieveSubscription = (payload: {
  merchant_id: string
  authToken: string
  authV3: boolean
  plan_name: string
}) => {
  let headers
  if (payload.authV3) {
    headers = {
      ...baseHeaders,
      'OKC-BUSINESS-ID': payload.merchant_id
    }
  } else {
    headers = {
      ...baseHeaders,
      Authorization: `Bearer ${payload.authToken}`,
      'OKC-BUSINESS-ID': payload.merchant_id
    }
  }
  // const api = new ApiService(getEnvConfig('MERCHANT_UPI_BASE_URL'), headers)
  const api = new ApiService(paymentApiEndPoint, headers)
  return api.postRequest(GET_RETRIEVE_SUBSCRIPTION_ENDPOINT, {
    merchant_id: payload.merchant_id,
    plan_name: payload.plan_name
  })
}

const getCreateSubscription = (payload: {
  merchant_id: string
  authToken: string
  authV3: boolean
  plan_name: string
  success_redirect_url: string
  failure_redirect_url: string
}) => {
  const headers = {
    ...baseHeaders,
    'OKC-BUSINESS-ID': payload.merchant_id,
    ...(!payload.authV3 && {
      Authorization: `Bearer ${payload.authToken}`
    })
  }
  const api = new ApiService(paymentApiEndPoint, headers)
  return api.postRequest(GET_CREATE_SUBSCRIPTION_ENDPOINT, {
    merchant_id: payload.merchant_id,
    plan_name: payload.plan_name,
    success_redirect_url: payload.success_redirect_url,
    failure_redirect_url: payload.failure_redirect_url
  })
}

const getQrVpa = (payload: { merchant_id: string; authToken: string; authV3: boolean }) => {
  const headers = {
    ...baseHeaders,
    'OKC-BUSINESS-ID': payload.merchant_id,
    ...(!payload.authV3 && {
      Authorization: `Bearer ${payload.authToken}`
    })
  }

  const api = new ApiService(endpoint, headers)
  return api.postRequest(GET_QR_VPA_ENDPOINT, {
    business_id: payload.merchant_id
  })
}

export const OrderQRApi = {
  getUpiId,
  setUpi,
  addAddress,
  getShippingAddress,
  getKycStatus,
  getMerchantProfile,
  getRetrieveSubscription,
  getCreateSubscription,
  getQrVpa
}
