/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put } from '@redux-saga/core/effects'
import { utils, writeFileXLSX } from 'xlsx'
import dayjs from 'dayjs'
import { Action } from 'infra/types'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { getErrorMessage } from 'services/Api/Errors'
import { bulkUploadInventoryItemsApi } from 'services/Api/BillingApi'
import {
  DownloadExpenseSummaryType,
  DownloadPurchaseSummaryType,
  InventoryItemModalEnum
} from 'state/inventory/inventory.types'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import { getExpenseApi, getInventoryTransactionHistoryApi } from 'services/Api/StafflinkApi'
import { formatExpenseItemForFile, formatPurchaseDataForFile } from 'state/inventory/formatter'
import { handleLoaderToggle, setInventoryModalsAction } from './inventory.actions'
import { API_RESPONSE_TYPE, IApiResponse } from './../../constants'

export function* bulkUploadInventoryItemsEffect(action: Action<any>) {
  try {
    yield put(handleLoaderToggle({ bulkUploadItems: true }))
    const response: IApiResponse = yield call(bulkUploadInventoryItemsApi, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      // yield put(bulkUploadInventoryItemsSuccessAction(response?.data?.document_id)) // TODO: BE not sending document id currently
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: response?.data?.message || 'Items added successfully'
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to upload document, please contact support'
        })
      )
    }
    yield put(handleLoaderToggle({ bulkUploadItems: false }))
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
    yield put(handleLoaderToggle({ bulkUploadItems: false }))
  }
}

export function* downloadPurchaseSummaryEffect(action: Action<DownloadPurchaseSummaryType>) {
  const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
  const { itemId, limit, offset, type, fileName, startTime, endTime } = action.payload
  const requestPayload = {
    business_id: currentBusinessId,
    item_id: itemId,
    limit,
    offset,
    type,
    start_time: startTime,
    end_time: endTime
  }
  const response: IApiResponse = yield call(getInventoryTransactionHistoryApi, requestPayload)
  const data = formatPurchaseDataForFile(response.data.transactions, type as any)
  const ws = utils.json_to_sheet(data, { origin: 'A6' })
  const wb = utils.book_new()
  const excelHeading = 'Inventory Item Summary'
  utils.sheet_add_aoa(ws, [[excelHeading], [], [`Printed On: ${dayjs().format('DD MMM, YYYY')}`]], {
    origin: 'A1'
  })

  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)
  yield put(
    setInventoryModalsAction({
      modalName: InventoryItemModalEnum.downloadPurchaseSummary,
      status: false
    })
  )
  yield put(
    setInventoryModalsAction({
      modalName: InventoryItemModalEnum.downloadInventoryHisory,
      status: false
    })
  )
}

export function* downloadExpenseSummaryEffect(action: Action<DownloadExpenseSummaryType>) {
  const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
  const { fileName } = action.payload
  const requestPayload = {
    business_id: currentBusinessId
  }
  const response: IApiResponse = yield call(getExpenseApi, requestPayload)
  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Failed to get Expense Items', {
      cause: 'customError'
    })
  }
  const data = formatExpenseItemForFile(response.data.transactions)
  const ws = utils.json_to_sheet(data, { origin: 'A6' })
  const wb = utils.book_new()
  const excelHeading = 'Purchase Item Summary'
  utils.sheet_add_aoa(ws, [[excelHeading], [], [`Printed On: ${dayjs().format('DD MMM, YYYY')}`]], {
    origin: 'A1'
  })

  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)
  yield put(
    setInventoryModalsAction({
      modalName: InventoryItemModalEnum.downloadExpenseItems,
      status: false
    })
  )
}
