import { COLLECTION_LIST_TYPE } from 'state/collectionList/collectionList.types'

export interface IUploadSheetState {
  documentsList: IDocumentsType[]
  currentDocumentSelected: ICurrentDocumentSelected | null
  templatesList: ITemplate[]
  globalTemplatesList: ITemplate[]
  currentTemplates: ICurrentTemplate[]
  accountsDocument: IAccountsDocument | null
  loaders: ILoaders
  drawers: IDrawers
  pagination: IPaginationState | null
  accountIds?: Array<string>
  uploadFileJsonData?: Array<string> | null
  uploadTempFile?: File | null
}

export interface ILoaders {
  fetchDocumentsList?: boolean
  collectionListFromDocument?: boolean
  fetchTemplatesList?: boolean
  createTemplate?: boolean
  updateDefaultTemplate?: boolean
}

export interface IDrawers {
  documentList?: boolean
  documentToCollectionList?: boolean
  documentListConfiguration?: boolean
  templateDetails?: boolean
  uploadedFiles?: boolean
  showListNameDrawer?: boolean
  errorDetails?: boolean
  addToStaffLinkActionTypeDrawer?: boolean
  newCollectionListNameDrawer?: boolean
  newTemplateDrawer?: boolean
  deleteUploadedFile?: boolean
  uploadedFilesPreview?: boolean
}

export interface IAccountsDocument {
  documentId: string
  document_status?: number
}

export enum DOCUMENT_TYPE {
  UNKNOWN_FILE_TYPE = 0,
  XML = 1,
  EXCEL = 2,
  CSV = 3
}

export interface IDocumentTractions {
  load_failed: number
  sync_failed: number
  sync_progress: number
  synced: number
  total: number
  duplicates: number
}

export interface IFileMeta {
  route_meta: {
    is_available: boolean
    routes?: {
      name: string
      total_accounts: number
      is_synced: boolean
      id: string
      bills: number
    }[]
  }
}

export enum FILE_KIND {
  UNKNOWN = 0,
  LEDGER = 1,
  LEDGER_ITEMS = 2,
  GST = 3
}

export interface IDocumentsType {
  UpdatedAt: string
  createdAt: string
  document_id: string
  document_type: DOCUMENT_TYPE
  document_status: number
  error_meta: {
    missing_headers: string[]
    err_row_no: number[]
    duplicate_row_no: number[]
    err_code: number
    err_message: string
    repeating_titles: Record<string, { xs: number[] }>
  }
  file_name: string
  transactions: IDocumentTractions
  file_meta?: IFileMeta
  file_kind?: FILE_KIND
  tpl_id: string
}

export interface ICurrentDocumentSelected {
  documentId: string
  name: string
  routes?: {
    name: string
    total_accounts: number
    bills: number
    is_synced: boolean
  }[]
  isOnboarding?: boolean
}

export interface ICurrentErrorDocumentSelected {
  documentId: string
  name: string
  routes?: {
    name: string
    total_accounts: number
  }[]
}

export interface ICurrentTemplate {
  id: string
  name: string
  document_type: DOCUMENT_TYPE
}

export type ActionWithPagination<T> = {
  type: string
  payload: T
  pagination: IPagination
  error?: {}
}

export interface IPagination {
  currentPage: number
  pageLimit: number
  isAllDataLoaded: boolean
  total?: number
}

export interface IPaginationState {
  documents?: IPagination
}

export interface ICollectionListFromDocument {
  isCreateNew: boolean
  newListName?: string
  collectionListId?: string
  collectionListDueConfig?: COLLECTION_LIST_TYPE
  isReplaceOldItemsinCl?: boolean
}

export interface IMultipleCollectionListFromDocument {
  routes: string[]
  action: number
}

export type AdvanceColumnsType = {
  orderNumber: string
  orderDate: string
  soldTo: string
  shipTo: string
  billTo: string
  itemNumber: string
  productCode: string
  productDescription?: string
  hsn: string
  promo?: string
  uom?: string
  itemType: string
  quantity: string
  sgst: string
  cgst: string
  mrp: string
}

type ColumnMapping = {
  accountBeat: string
  accountRoute: string
  accountTag: string
  advanceColumns: AdvanceColumnsType | null
  amount: string
  customerCode: string
  customerName: string
  invoiceDate: string
  invoiceNumber: string
  invoiceTag: string
  mobileNumber: string
  paymentReconBillNumber: string
  saleRoute: string
  saleType: string
}

export type TemplateConfig = {
  amount_rounding_type: number
  sheetDetails: {
    name: string
    header: string
    no_bill_rows: boolean
    isItemDatails: boolean
  }
  columnFormula: {
    invoiceNumber: string
  } | null
  columnMapping: ColumnMapping
  columnPreferredIdxs?: {
    [K in keyof ColumnMapping | keyof AdvanceColumnsType]?: number
  }
}

export type TemplateResponseType = {
  id: string
  name: string
  is_default: boolean
  config: TemplateConfig
  document_type: DOCUMENT_TYPE
  is_deleted?: boolean
}

export interface ITemplate extends TemplateResponseType {
  isGlobal?: boolean
}
