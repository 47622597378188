import React from 'react'
import { useHeader } from 'pages/NewDashboard/hooks/useHeader'
import { HeaderStyles } from './styles'
import TopHeader from './TopHeader'
import VerticalHeader from './VerticalHeader'

const Header = ({ children }: { children: React.ReactNode }) => {
  const classes = HeaderStyles()
  const { loading, ready } = useHeader()
  return (
    <div className={classes.headerContainer}>
      <TopHeader />
      <div className={classes.verticalHeaderContainer}>
        <VerticalHeader />
        <div className={classes.childrenContainer}>{!loading && ready && children}</div>
      </div>
    </div>
  )
}

export default Header
