if (typeof window !== 'undefined') {
  window.deferredPrompt = null
}

export const initA2hsPrompt = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      window.deferredPrompt = e
    })
  }
}

export const showA2hsBanner = async (
  trackPromptShown: () => void = () => {},
  trackPromptNotShown: () => void = () => {},
  trackSuccess: () => void = () => {},
  trackDismiss: () => void = () => {}
): Promise<void> => {
  // Show the install prompt
  console.log('A2HS: Prompt show attempt : ', window.deferredPrompt)
  if (window.deferredPrompt) {
    console.log('A2HS: Prompt Show Again')
    window.deferredPrompt.prompt()
    trackPromptShown()
    // Wait for the user to respond to the prompt
    const choiceResult = await window.deferredPrompt.userChoice
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the install prompt')
      trackSuccess()
    } else {
      console.log('User dismissed the install prompt')
      trackDismiss()
    }
  } else {
    // eslint-disable-next-line no-alert
    alert('App is already installed')
    trackPromptNotShown()
  }
}
