import { Action } from 'infra/types'
import { IReplacement } from 'services/Api/StaffLinkApiFolder/type'

export const replacementActions = {
  SET_REPLACEMENT_DRAWER_DATA: 'replacement/setReplacementDrawersData'
}

export const setReplacementDrawerWithData = (payload: {
  status: boolean
  data?: IReplacement
}): Action<{ status: boolean; data?: IReplacement }> => {
  return {
    type: replacementActions.SET_REPLACEMENT_DRAWER_DATA,
    payload
  }
}
