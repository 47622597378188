import { getEnvConfig } from 'utils/getEnvConfig'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor,
  businessIdInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const REPAYMENT_ENDPOINT = '/repaymentLink'
const LOAN_ENDPOINT = '/loan'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('LOAN_API_ENDPOINT') || ''

const api = new ApiService(endpoint, headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const getRepaymentLink = () => {
  return api.getRequest(REPAYMENT_ENDPOINT, {})
}

export const getLatestLoan = () => {
  return api.getRequest(LOAN_ENDPOINT, {})
}
