import { Component } from 'react'

const lazyRetry = (componentImport: () => Promise<any>): Promise<any> =>
  new Promise((resolve, reject) => {
    componentImport()
      .then((component: Component) => {
        if (component === undefined) {
          return window.location.reload()
        }
        resolve(component)
      })
      .catch((error: any) => {
        if (typeof window !== 'undefined') {
          window.location.reload()
        }
        reject(error)
      })
  })

export default lazyRetry
