import { Action } from 'infra/types'
import { BillDataType } from 'pages/NewDashboard/components/Billing/hooks/constant'
import { INVENTORY_OPERATION_TYPE } from 'services/Api/BillingApiFolder.ts/type'
import {
  BillParams,
  BillsType,
  CustomerDetails,
  IBillingState,
  IFetchBillAction,
  IFileSettings,
  IInventoryApiCallAndResponse,
  ISavePurchaseBillSettingsReq,
  IUploadAction,
  InventoryItemDetailsType,
  InventoryItemUploadType,
  ItemOperationAction,
  ItemType,
  NewItemDetailEntity,
  CatalogueItemDetailsType,
  IBookingApiLoader,
  IExpenseAccounts,
  IFailedBulkUploadData,
  IBulkUploadFileLog
} from './billing.types'

export const BillingActions = {
  SET_PREVIEW_DRAWER: 'billing/setPreviewDrawer',
  SET_ADDED_BILLS_DRAWER: 'billing/setAddedBillsDrawer',
  FETCH_ITEMS: 'billing/fetchItems',
  ADD_ITEMS: 'billing/addItems',
  UPLOAD_BILLS: 'billing/uploadBills',
  FETCH_BILLS: 'billing/fetchBills',
  SET_BILLS_DATA: 'billing/setBillsData',
  DELETE_BILLS: 'billing/deleteBills',
  ADD_SINGLE_BILL: 'billing/addSingleBill',
  UPDATE_SINGLE_BILL: 'billing/updateSingleBill',
  SET_PREVIOUS_ITEM_LIST: 'billing/resetItemList',
  SET_DRAWER: 'addItemActions/setDrawers',
  SET_LOADER: 'addItemActions/setLoaders',
  SET_FLAGS: 'addItemActions/setFlags',
  SET_EDIT_ITEM: 'addItemActions/setEditItem',
  RESET_EDIT_ITEM: 'addItemActions/resetEditItem',

  SET_EDIT_INVENTORY_ITEM: 'addItemActions/setEditInventoryItem',
  RESET_EDIT_INVENTORY_ITEM: 'addItemActions/resetEditInventoryItem',

  SET_ADDED_ITEM_DETAILS_FROM_DRAWER: 'addItemActions/setItemDetailsFromDrawer',
  RESET_ADDED_ITEM_DETAILS_FROM_DRAWER: 'addItemActions/resetItemDetailsFromDrawer',
  SET_ADDED_CUSTOMER_DETAILS_FROM_DRAWER: 'addItemActions/setCustomerDetailsFromDrawer',
  RESET_ADDED_CUSTOMER_DETAILS_FROM_DRAWER: 'addItemActions/resetCustomerDetailsFromDrawer',
  SET_PRINT_BILL_TEMPLATE: 'billing/setPrintBillTemplate',
  RESET_BILL_CONTEXT: 'billing/resetBillContext',
  SET_SAVED_PREVIEW_BILL_TEMPLATE_AND_ID: 'billing/setSavedPreviewBillTemplate',
  DELETE_ITEM: 'billing/deleteItem',
  SAVE_PURCHASE_BILL_SETTINGS: 'billing/savePurchaseBillSettings',
  FETCH_FILE_SETTINGS: 'billing/fetchFileSettings',
  SET_File_SETTINGS: 'billing/setFileSettings',
  FETCH_BILL_BY_ID: 'billing/fetchBillById',
  SET_SELECTED_BILL_DATA: 'billing/setSelectedBillData',
  SET_IS_UPDATED_BILL_DATA: 'billing/SET_IS_UPDATED_BILL_DATA',
  UPDATE_PREVIEW_BILL: 'billing/UPDATE_PREVIEW_BILL',
  ADD_EDIT_INVENTORY_ITEM: 'billing/addEditInventoryItem',
  ADD_EDIT_INVENTORY_V2_ITEM: 'billing/addEditInventoryv2Item',
  FETCH_INVOICE_ITEMS: 'billing/fetchInvoiceItems',
  FETCH_INVENTORY_ITEMS: 'billing/fetchInventoryItems',
  FETCH_INVENTORY_UNIT: 'billing/fetchInventoryUnit',
  FETCH_INVENTORY_ITEMS_OFFER: 'billing/fetchInventoryItemsOffer',
  UPLOAD_INVENTORY_ITEMS: 'billing/uploadInventoryItems',
  SET_INVOICE_ITEMS: 'billing/setInvoiceItems',
  SET_INVENTORY_ITEMS: 'billing/setInventoryItems',
  SET_INVENTORY_UNITS: 'billing/setInventoryUnits',
  EDIT_INVOICE_ITEMS: 'billing/editInvoiceItems',
  GET_ITEM_INVENTORY_HISTORY: 'billing/getItemInventoryHistory',
  SET_INVOICE_ITEMS_HISTORY: 'billing/setInvoiceItemsHistory',
  GET_PURCHASE_INVENTORY_ITEMS: 'billing/getPurchaseInventoryItems',
  SET_PURCHASE_INVENTORY_ITEMS: 'billing/setPurchaseInventoryItems',
  GET_PURCHASE_ACCOUNT: 'billing/getPurchaseAccount',
  SET_PURCHASE_ACCOUNT: 'billing/setPurchaseAccount',
  GET_PURCHASE_ASSET_ACCOUNT: 'billing/getPurchaseAssetAccount',
  SET_PURCHASE_ASSET_ACCOUNT: 'billing/setPurchaseAssetAccount',
  CREATE_PURCHASE_INVENTORY: 'billing/createPurchaseInventory',
  ADD_EXPENSE: 'billing/addExpense',
  GET_EXPENSE: 'billing/getExpense',
  SET_EXPENSE_TRANSACTION: 'billing/setExpenseTransaction',
  ADD_EXPENSE_ITEM: 'billing/addExpenseItem',
  GET_INVENTORY_TRANSACTION_HISTORY: 'billing/getInventoryTransactionHistory',
  SET_INVENTORY_TRANSACTION_HISTORY: 'billing/setInventoryTransactionHistory',
  SET_INVENTORY_API_CALL_AND_RESPONSE: 'billing/setInventoryApiCallAndResponse',
  RESET_INVENTORY_TRANSACTION_HISTORY: 'billing/resetInventoryTransactionHistory',
  GET_PURCHASE_TRANSACTION_HISTORY: 'billing/getPurchaseTransactionHistory',
  SET_PURCHASE_TRANSACTION_HISTORY: 'billing/setPurchaseTransactionHistory',
  UPDATE_PURCHASE_TRANSACTION_HISTORY: 'billing/updatePurchaseTransactionHistory',
  ADD_TAGS_TO_BILL_ACTION: 'billing/addTagsToBillAction',
  ADD_EXPENSE_ACCOUNt: 'billing/addExpenseAccount',
  BOOKKEEPING_API_LOADER: 'billing/bookingApiLoader',
  GET_ALL_EXPENSE_ACCOUNTS: 'billing/getAllExpenseAccounts',
  SET_EXPENSE_ACCOUNTS: 'billing/setExpenseAccounts',
  FETCH_BUSINESS_DISCOUNTS: 'billing/fetchBusinessDiscounts',
  SET_DISCOUNTS_DATA: 'billing/setDiscountsData',
  CREATE_BUSINESS_DISCOUNT: 'billing/createBusinessDiscount',
  FETCH_BUSINESS_CHARGES: 'billing/fetchBusinessCharges',
  SET_CHARGES_DATA: 'billing/setChargesData',
  CREATE_BUSINESS_CHARGE: 'billing/createBusinessCharge',
  SYNC_INVENTORY: 'billing/syncInventory',
  BULK_UPLOAD_PURCHASE_ITEMS: 'billing/bulkUploadPurchaseItems',
  SET_FAILED_BULK_UPLOAD_DATA: 'billing/setFailedBulkUploadData',
  BULK_UPLOAD_PURCHASE_ITEMS_STATUS: 'billing/bulkUploadPurchaseItemsStatus',
  OPEN_BULK_UPLOAD_PURCHASE_ITEMS_LOG_DRAWER: 'billing/openBulkUploadPurchaseItemsLogDrawer',
  SET_BULK_UPLOAD_PURCHASE_ITEMS_LOG: 'billing/setBulkUploadPurchaseItemsLog',
  SET_BULK_UPLOAD_FILE_REQUEST_ID: 'billing/setBulkUploadFileRequestId',
  RETRY_BULK_UPLOAD_PURCHASE_ITEMS: 'billing/retryBulkUploadPurchaseItems',
  DOWNLOAD_BULK_UPLOAD_PURCHASE_ITEMS: 'billing/downloadBulkUploadPurchaseItems',
  TOTAL_COUNT_OF_PURCHASE_ITEMS: 'billing/totalCountOfPurchaseItems'
}

export const applyTagsToBillsAction = (
  payload: { billId: string; customerId: string; tagIds: string[] }[]
): Action<{ billId: string; customerId: string; tagIds: string[] }[]> => ({
  type: BillingActions.ADD_TAGS_TO_BILL_ACTION,
  payload
})

export const addEditInventoryV2Item = (payload: any): Action<any> => {
  return {
    type: BillingActions.ADD_EDIT_INVENTORY_V2_ITEM,
    payload
  }
}

export const deleteInventoryV2Item = (payload: {
  operation: INVENTORY_OPERATION_TYPE.DELETE_ITEM
  item: { itemId: string }
}): Action<{
  operation: INVENTORY_OPERATION_TYPE.DELETE_ITEM
  item: { itemId: string }
}> => {
  return {
    type: BillingActions.ADD_EDIT_INVENTORY_V2_ITEM,
    payload
  }
}

export const addEditInventoryItem = (payload: ItemOperationAction): Action<ItemOperationAction> => {
  return {
    type: BillingActions.ADD_EDIT_INVENTORY_ITEM,
    payload
  }
}

export const deleteItemFromCatalog = (payload: {
  itemIds: string[]
}): Action<{ itemIds: string[] }> => ({
  type: BillingActions.DELETE_ITEM,
  payload
})

export const setItemDetailsFromDrawer = (
  payload: NewItemDetailEntity
): Action<NewItemDetailEntity> => ({
  type: BillingActions.SET_ADDED_ITEM_DETAILS_FROM_DRAWER,
  payload
})

export const resetItemDetailsFromDrawer = (): Action<any> => ({
  type: BillingActions.RESET_ADDED_ITEM_DETAILS_FROM_DRAWER,
  payload: undefined
})

export const setCustomerDetailsFromDrawer = (
  payload: CustomerDetails
): Action<CustomerDetails> => ({
  type: BillingActions.SET_ADDED_CUSTOMER_DETAILS_FROM_DRAWER,
  payload
})

export const resetCustomerDetailsFromDrawer = (): Action<any> => ({
  type: BillingActions.RESET_ADDED_CUSTOMER_DETAILS_FROM_DRAWER,
  payload: undefined
})

export const setEditItemDetails = (payload: {
  editItemDetails: CatalogueItemDetailsType
}): Action<{ editItemDetails: CatalogueItemDetailsType }> => ({
  type: BillingActions.SET_EDIT_ITEM,
  payload
})

export const setEditInventoryItemDetails = (payload: {
  editInventoryItem: InventoryItemDetailsType & {
    itemId: string
    catalogueItemId: string
  }
}): Action<{
  editInventoryItem: InventoryItemDetailsType & {
    itemId: string
    catalogueItemId: string
  }
}> => ({
  type: BillingActions.SET_EDIT_INVENTORY_ITEM,
  payload
})

export const resetEditInventoryItemDetails = (): Action<undefined> => ({
  type: BillingActions.RESET_EDIT_INVENTORY_ITEM,
  payload: undefined
})

export const setDrawers = (payload: {
  drawerName:
    | 'showAddExpenseItemDrawer'
    | 'showAddItemDrawer'
    | 'showFeedbackDrawer'
    | 'showAddInvoiceItemDrawer'
    | 'showAddPurchaseItemDrawer'
    | 'showPurchaseBillDrawer'
    | 'showEditMerchantProfileDrawer'
    | 'showAddInventoryItemDrawer'
    | 'showAddExpenseAccountDrawer'
    | 'showDiscountDrawer'
    | 'showChargeDrawer'
    | 'showPaymentBulkUploadTemplate'
    | 'showPaymentBulkUploadStatus'
    | 'showPaymentBulkUploadFileLogDrawer'
  status: boolean
}): Action<{
  drawerName:
    | 'showAddExpenseItemDrawer'
    | 'showAddItemDrawer'
    | 'showFeedbackDrawer'
    | 'showAddInvoiceItemDrawer'
    | 'showAddPurchaseItemDrawer'
    | 'showPurchaseBillDrawer'
    | 'showEditMerchantProfileDrawer'
    | 'showEditMerchantProfileDrawer'
    | 'showAddInventoryItemDrawer'
    | 'showAddExpenseAccountDrawer'
    | 'showDiscountDrawer'
    | 'showChargeDrawer'
    | 'showPaymentBulkUploadTemplate'
    | 'showPaymentBulkUploadStatus'
    | 'showPaymentBulkUploadFileLogDrawer'
  status: boolean
}> => ({
  type: BillingActions.SET_DRAWER,
  payload
})

export const setPreviousItemListAction = (payload: boolean): Action<boolean> => {
  return {
    type: BillingActions.SET_PREVIOUS_ITEM_LIST,
    payload
  }
}

// remove this
export const setPreviewDrawer = (payload: boolean): Action<boolean> => {
  return {
    type: BillingActions.SET_PREVIEW_DRAWER,
    payload
  }
}

export const setAddedBillsDrawer = (payload: boolean): Action<boolean> => {
  return {
    type: BillingActions.SET_ADDED_BILLS_DRAWER,
    payload
  }
}

export const fetchItemsAction = () => {
  return {
    type: BillingActions.FETCH_ITEMS
  }
}

// export const addItems = (payload: {
//   items: IBillingState['items']
// }): Action<{ items: IBillingState['items'] }> => {
//   return {
//     type: BillingActions.ADD_ITEMS,
//     payload
//   }
// }

export const uploadBillsAction = (payload: IUploadAction): Action<IUploadAction> => {
  return {
    type: BillingActions.UPLOAD_BILLS,
    payload
  }
}

export const fetchBillsAction = (payload: IFetchBillAction): Action<IFetchBillAction> => {
  return {
    type: BillingActions.FETCH_BILLS,
    payload
  }
}

export const setBillsData = (payload: IBillingState['bills']): Action<IBillingState['bills']> => {
  return {
    type: BillingActions.SET_BILLS_DATA,
    payload
  }
}

export const deleteBillsAction = (payload: string[]): Action<string[]> => {
  return {
    type: BillingActions.DELETE_BILLS,
    payload
  }
}

export const resetBillContextAction = (payload: boolean): Action<boolean> => {
  return {
    type: BillingActions.RESET_BILL_CONTEXT,
    payload
  }
}

export const addSingleBill = (payload: BillsType): Action<BillsType> => {
  return {
    type: BillingActions.ADD_SINGLE_BILL,
    payload
  }
}
export const updateSingleBill = (payload: BillsType): Action<BillsType> => {
  return {
    type: BillingActions.UPDATE_SINGLE_BILL,
    payload
  }
}

export const setBillingLoaders = (payload: {
  loaderName: keyof IBillingState['loaders']
  status: boolean
}): Action<{
  loaderName: keyof IBillingState['loaders']
  status: boolean
}> => ({
  type: BillingActions.SET_LOADER,
  payload
})

export const setBillingFlags = (payload: {
  flagName: keyof IBillingState['flags']
  status: boolean | string
}): Action<{
  flagName: keyof IBillingState['flags']
  status: boolean | string
}> => ({
  type: BillingActions.SET_FLAGS,
  payload
})

export const resetEditItemAction = () => {
  return {
    type: BillingActions.RESET_EDIT_ITEM
  }
}

export const setPrintBillTemplate = (payload: string): Action<string> => {
  return {
    type: BillingActions.SET_PRINT_BILL_TEMPLATE,
    payload
  }
}

export const setSavedPreviewBillTemplateAndId = (payload: {
  previewSavedBillTemplate: string
  previewSavedBillId: string
  // hasCreditNote?: boolean
}): Action<{
  previewSavedBillTemplate: string
  previewSavedBillId: string
  // hasCreditNote?: boolean
}> => {
  return {
    type: BillingActions.SET_SAVED_PREVIEW_BILL_TEMPLATE_AND_ID,
    payload
  }
}

export const fetchFileSettings = (): Action<null> => ({
  type: BillingActions.FETCH_FILE_SETTINGS,
  payload: null
})

export const setFileSettings = (payload: IFileSettings | null): Action<IFileSettings | null> => ({
  type: BillingActions.SET_File_SETTINGS,
  payload
})

export const savePurchaseBillSettings = (
  payload: ISavePurchaseBillSettingsReq
): Action<ISavePurchaseBillSettingsReq> => ({
  type: BillingActions.SAVE_PURCHASE_BILL_SETTINGS,
  payload
})

export const fetchBillByIdAction = (payload: BillParams): Action<BillParams> => {
  return {
    type: BillingActions.FETCH_BILL_BY_ID,
    payload
  }
}

export const fetchInvoiceItems = () => {
  return {
    type: BillingActions.FETCH_INVOICE_ITEMS
  }
}

export const fetchInventoryItems = () => {
  return {
    type: BillingActions.FETCH_INVENTORY_ITEMS
  }
}

export const fetchInventoryUnit = () => {
  return {
    type: BillingActions.FETCH_INVENTORY_UNIT
  }
}

export const fetchInventoryItemsOffers = () => {
  return {
    type: BillingActions.FETCH_INVENTORY_ITEMS_OFFER
  }
}

export const uploadInventoryItemsAction = (
  payload: InventoryItemUploadType
): Action<InventoryItemUploadType> => {
  return {
    type: BillingActions.UPLOAD_INVENTORY_ITEMS,
    payload
  }
}

export const setInventoryItems = (
  payload: (Omit<ItemType, 'tax' | 'taxIncluded'> & {
    quantity: number
    category: string[]
  })[]
): Action<
  (Omit<ItemType, 'tax' | 'taxIncluded'> & {
    quantity: number
    category: string[]
  })[]
> => {
  return {
    type: BillingActions.SET_INVENTORY_ITEMS,
    payload
  }
}

export const setInventoryUnits = (payload: { units: string[] }): Action<{ units: string[] }> => {
  return {
    type: BillingActions.SET_INVENTORY_UNITS,
    payload
  }
}

export const setInvoiceItems = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_INVOICE_ITEMS,
    payload
  }
}

export const setSelectedBillData = (payload: BillDataType): Action<BillDataType> => {
  return {
    type: BillingActions.SET_SELECTED_BILL_DATA,
    payload
  }
}

export const editInvoiceItems = (payload: any): Action<any> => {
  return {
    type: BillingActions.EDIT_INVOICE_ITEMS,
    payload
  }
}

export const setUpdatedBillData = (payload: boolean): Action<boolean> => {
  return {
    type: BillingActions.SET_IS_UPDATED_BILL_DATA,
    payload
  }
}

export const getItemInventoryHistory = (payload: any): Action<any> => {
  return {
    type: BillingActions.GET_ITEM_INVENTORY_HISTORY,
    payload
  }
}

export const updatePreviewBillsData = (payload: string): Action<string> => {
  return {
    type: BillingActions.UPDATE_PREVIEW_BILL,
    payload
  }
}

export const setInvoiceItemsHistory = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_INVOICE_ITEMS_HISTORY,
    payload
  }
}

export const getPurchaseInventoryItems = (payload: any): Action<any> => {
  return {
    type: BillingActions.GET_PURCHASE_INVENTORY_ITEMS,
    payload
  }
}

export const setPurchaseInventoryItems = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_PURCHASE_INVENTORY_ITEMS,
    payload
  }
}

export const getPurchaseAccount = (payload: any): Action<any> => {
  return {
    type: BillingActions.GET_PURCHASE_ACCOUNT,
    payload
  }
}

export const setPurchaseAccount = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_PURCHASE_ACCOUNT,
    payload
  }
}

export const getPurchaseAssetAccount = (payload: any): Action<any> => {
  return {
    type: BillingActions.GET_PURCHASE_ASSET_ACCOUNT,
    payload
  }
}

export const setPurchaseAssetAccount = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_PURCHASE_ASSET_ACCOUNT,
    payload
  }
}

export const createPurchaseInventory = (payload: any): Action<any> => {
  return {
    type: BillingActions.CREATE_PURCHASE_INVENTORY,
    payload
  }
}

export const addExpense = (payload: any): Action<any> => {
  return {
    type: BillingActions.ADD_EXPENSE,
    payload
  }
}

export const getExpense = () => {
  return {
    type: BillingActions.GET_EXPENSE
  }
}

export const setExpenseTransaction = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_EXPENSE_TRANSACTION,
    payload
  }
}

export const addExpenseItem = (payload: any): Action<any> => {
  return {
    type: BillingActions.ADD_EXPENSE_ITEM,
    payload
  }
}

export const getInventoryTransactionHistoryAction = (payload: {
  item_id: string
  limit: number
  offset: number
  startTime: number
  endTime: number
  type: string
}): Action<any> => {
  return {
    type: BillingActions.GET_INVENTORY_TRANSACTION_HISTORY,
    payload
  }
}

export const setInventoryTransactionHistoryAction = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_INVENTORY_TRANSACTION_HISTORY,
    payload
  }
}

export const setInventoryApiCallAndResponse = (
  payload: IInventoryApiCallAndResponse
): Action<any> => {
  return {
    type: BillingActions.SET_INVENTORY_API_CALL_AND_RESPONSE,
    payload
  }
}

export const resetInventoryTransactionHistory = () => {
  return {
    type: BillingActions.RESET_INVENTORY_TRANSACTION_HISTORY
  }
}

export const getPurchaseTransactionHistoryAction = (payload: {
  item_id: string
  limit: number
  offset: number
}): Action<any> => {
  return {
    type: BillingActions.GET_PURCHASE_TRANSACTION_HISTORY,
    payload
  }
}

export const setPurchaseTransactionHistoryAction = (payload: any): Action<any> => {
  return {
    type: BillingActions.SET_PURCHASE_TRANSACTION_HISTORY,
    payload
  }
}

export const updatePurchaseTransactionHistoryAction = (payload: any): Action<any> => {
  return {
    type: BillingActions.UPDATE_PURCHASE_TRANSACTION_HISTORY,
    payload
  }
}

export const fetchBusinessDiscountsAction = (): Action<null> => {
  return {
    type: BillingActions.FETCH_BUSINESS_DISCOUNTS,
    payload: null
  }
}

export const setBusinessDiscountsData = (
  payload: IBillingState['discounts']
): Action<IBillingState['discounts']> => {
  return {
    type: BillingActions.SET_DISCOUNTS_DATA,
    payload
  }
}

export const addExpenseAccount = (payload: { name: string }): Action<any> => {
  return {
    type: BillingActions.ADD_EXPENSE_ACCOUNt,
    payload
  }
}

export const createBusinessDiscountAction = (payload: {
  name: string
}): Action<{ name: string }> => {
  return {
    type: BillingActions.CREATE_BUSINESS_DISCOUNT,
    payload
  }
}

export const fetchBusinessChargesAction = (): Action<null> => {
  return {
    type: BillingActions.FETCH_BUSINESS_CHARGES,
    payload: null
  }
}

export const setBusinessChargesData = (
  payload: IBillingState['charges']
): Action<IBillingState['charges']> => {
  return {
    type: BillingActions.SET_CHARGES_DATA,
    payload
  }
}

export const bookingApiLoader = (payload: IBookingApiLoader): Action<IBookingApiLoader> => {
  return {
    type: BillingActions.BOOKKEEPING_API_LOADER,
    payload
  }
}

export const createBusinessChargeAction = (payload: { name: string }): Action<{ name: string }> => {
  return {
    type: BillingActions.CREATE_BUSINESS_CHARGE,
    payload
  }
}

export const getAllExpenseAccounts = () => {
  return {
    type: BillingActions.GET_ALL_EXPENSE_ACCOUNTS
  }
}

export const setExpenseAccount = (payload: IExpenseAccounts): Action<IExpenseAccounts> => {
  return {
    type: BillingActions.SET_EXPENSE_ACCOUNTS,
    payload
  }
}

export const syncInventory = () => {
  return {
    type: BillingActions.SYNC_INVENTORY
  }
}

export const bulkUploadPurchaseItems = (payload: any) => {
  return {
    type: BillingActions.BULK_UPLOAD_PURCHASE_ITEMS,
    payload
  }
}

export const setFailedBulkUploadData = (
  payload: IFailedBulkUploadData
): Action<IFailedBulkUploadData> => {
  return {
    type: BillingActions.SET_FAILED_BULK_UPLOAD_DATA,
    payload
  }
}

export const bulkUploadPurchaseItemsStatus = (payload: boolean): Action<boolean> => {
  return {
    type: BillingActions.BULK_UPLOAD_PURCHASE_ITEMS_STATUS,
    payload
  }
}

export const openBulkUploadPurchaseItemsLogDrawer = (payload: boolean): Action<boolean> => {
  return {
    type: BillingActions.OPEN_BULK_UPLOAD_PURCHASE_ITEMS_LOG_DRAWER,
    payload
  }
}

export const setBulkUploadPurchaseItemsLog = (
  payload: IBulkUploadFileLog
): Action<IBulkUploadFileLog> => {
  return {
    type: BillingActions.SET_BULK_UPLOAD_PURCHASE_ITEMS_LOG,
    payload
  }
}

export const setBulkUploadFileRequestId = (payload: string): Action<string> => {
  return {
    type: BillingActions.SET_BULK_UPLOAD_FILE_REQUEST_ID,
    payload
  }
}

export const retryBulkUploadPurchaseItems = () => {
  return {
    type: BillingActions.RETRY_BULK_UPLOAD_PURCHASE_ITEMS
  }
}

export const downloadBulkUploadPurchaseItems = () => {
  return {
    type: BillingActions.DOWNLOAD_BULK_UPLOAD_PURCHASE_ITEMS
  }
}

export const totalCountOfPurchaseItems = (payload: number): Action<number> => {
  return {
    type: BillingActions.TOTAL_COUNT_OF_PURCHASE_ITEMS,
    payload
  }
}
