import 'regenerator-runtime/runtime'
import * as React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { loadableReady } from '@loadable/component'
import IntlProvider from '../shared/i18n/IntlProvider'
import rootSaga from '../shared/infra/rootEffects'
import configureStore from '../shared/infra/store'
import App from '../shared/App'
import createHistory from '../shared/infra/history'
import { isMobileAgent } from '../shared/utils/userAgent'

const history = createHistory()

const stateObj = configureStore({
  initialState: window.__PRELOADED_STATE__ || {}
})
// Create/use the store
// history MUST be passed here if you want syncing between server on initial route
const store = stateObj.store

// TODO(platform): Move the init code to a function like initiate()
if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
  navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' }).then((reg) => {
    console.log('Service Worker running with registration' + reg.scope)
  })
}

const isMobileDevice = 'userAgent' in navigator ? isMobileAgent(navigator.userAgent) : false

stateObj.runSaga(rootSaga)

loadableReady(() => {
  hydrate(
    <Provider store={store}>
      <Router history={history}>
        <IntlProvider>
          <HelmetProvider>
            <App isMobileDevice={isMobileDevice} />
          </HelmetProvider>
        </IntlProvider>
      </Router>
    </Provider>,
    document.getElementById('app'),
    () => {
      // We don't need the static css any more once we have launched our application.
      const ssStyles = document.getElementById('server-side-styles')
      ssStyles?.parentNode?.removeChild(ssStyles)
    }
  )
})

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept()
  }

  if (!window.store) {
    window.store = store
  }
}
