import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const TemplateSelectorStyles = createUseStyles({
  container: {
    position: 'relative'
  },
  uploadButton: {
    boxSizing: 'border-box',
    background: THEME.brandGreen,
    fontSize: 16,
    padding: '4px 4px 4px 12px',
    border: `solid 1px ${THEME.brandGreyPrimary}`,
    borderRadius: '21px',
    backgroundColor: THEME.brandGreen,
    color: THEME.brandWhite,
    fontWeight: 'bold',
    cursor: 'pointer',
    '& > label': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'space-between'
    },
    '& > label > input': {
      visibility: 'hidden',
      height: '0',
      width: '0'
    },
    '& > label > span': {
      margin: '0px 16px 0px 8px'
    }
  },
  selectorIcon: {
    transform: 'rotate(180deg)',
    transition: 'all 0.2s ease-in-out'
  },
  selectorIconOpen: {
    transform: 'rotate(0)'
  },
  selectorContainerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999
  },
  selectorContainer: {
    position: 'absolute',
    top: '7%',
    right: 60,
    minWidth: '480px',
    backgroundColor: THEME.brandWhite,
    borderRadius: '8px',
    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.16)',
    zIndex: 1,
    padding: '16px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 8
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute'
  },
  spinner: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    left: 'calc(50% - 24px)'
  },
  addIcon: {
    transform: 'rotate(45deg)'
  }
})
