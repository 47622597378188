import { THEME } from 'components/theme'
import DrawerStyles from '../drawer.styles'

export const SwitchAccountStyles = {
  ...DrawerStyles,
  mainC: {
    minWidth: '440px'
  },
  container: {
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  content: {
    padding: 16
  },
  footer: {
    padding: 16,
    width: '100%'
  },
  labelField: {
    width: 'calc(100% - 40px)'
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },

    '&[type="number"]': {
      MozAppearance: 'textfield'
    }
  },
  addIcon: {
    transform: 'rotate(45deg)'
  }
}
