import { getEnvConfig } from 'utils/getEnvConfig'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const BUSINESS_FEATURE_FEEDBACK = '/v1.0/business-feature-feedback'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const api = new ApiService(getEnvConfig('PAID_SOON_BANNER_BASE_URL'), headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const getIsFeedbackDone = () => {
  return api.getRequest(BUSINESS_FEATURE_FEEDBACK + '?feature=subscription_notification', {})
}

export const postFeedBack = (data: any) => {
  return api.postRequest(BUSINESS_FEATURE_FEEDBACK, data)
}
