import {
  ICurrentDocumentSelected,
  IDocumentsType
} from 'state/new-summary/uploadSheet/uploadSheet.types'

export interface IStaticDoc {
  className: string
  mainTxt: string
  mainHelper: string
  statusTxt: string
  statusHelperTxt?: string
}

export interface IProps {
  merchantId: string
  document: IDocumentsType
  staticDoc?: IStaticDoc
  onReupload?: () => void
  handleDownloadSampleFile?: () => void
  onRefresh: () => void
  onAssignToList?: (document: ICurrentDocumentSelected) => void
  handleViewErrorDetails?: () => void
  handleUploadGSTQRCode: (e: any) => void
  handleDeleteFileWithData: (document: IDocumentsType) => void
}

export enum DOCS_STATUS_TYPE {
  SYNCING = 'syncing',
  SUCCESS = 'success',
  FAILED = 'failed',
  WRONG_INPUT = 'wrongInput',
  DELETING = 'deleting',
  DELETED = 'deleted'
}

export enum UPLOAD_TYPES {
  GST = 'gst',
  STAFF_FILE = 'staffFile'
}

export enum DOCUMENT_STATUS {
  CREATED = 0,
  UPLOADED = 1,
  DB_CREATED = 2,
  SYNC_STARTED = 3,
  SYNC_COMPLETE = 4,
  SYNC_FAILED = 5,
  READ_FAILED = 6,
  DELETE_IN_PROGRESS = 7,
  DELETED = 8
}
