import {
  BillCharges,
  BillDiscounts,
  BillsType,
  ItemType,
  ItemsDataType,
  TaxComponentType
} from 'state/billing/billing.types'
import { BILL_STATUS } from 'utils/common/common.types'

export enum OPERATION_TYPE {
  ADD_ITEM = 1,
  UPDATE_ITEM = 2,
  DELETE_ITEM = 3
}

export enum INVENTORY_OPERATION_TYPE {
  ADD_ITEM = 0,
  UPDATE_ITEM = 1,
  UPDATE_QUANTITY = 2,
  DELETE_ITEM = 3
}

export type PAYLOAD_ITEMS_TYPE = {
  item_id?: string
  description: string
  unit?: 'Nos'
  rate: number
  gst_amount: number
  gst_basis_pts: number
  cess_amount: number
  cess_basis_pts: number
  product_code?: string
  image?: string
  hsn_code: string
  mrp: number
  tax_included?: boolean
  sku_id?: string
  opening_amount?: string
  show_tax_included?: boolean
}

export type ITEMS_OPERATION_REQUEST_PAYLOAD = {
  op_type: OPERATION_TYPE
  item: PAYLOAD_ITEMS_TYPE | { item_id: string }
}

export type INVENTORY_ITEM_REQUEST_TYPE =
  | (Omit<PAYLOAD_ITEMS_TYPE, 'tax_included' | 'tax'> & {
      show_tax_included: boolean
      category: string[]
      quantity: number
      sku: string
    })
  | {
      item_id: string
    }

export type INVENTORY_ITEMS_OPERATION_REQUEST_PAYLOAD = {
  request_id: string
  op_type: INVENTORY_OPERATION_TYPE
  item: INVENTORY_ITEM_REQUEST_TYPE
}

export type ITEMS_OPERATION_RESPONSE_TYPE = {
  item: ItemsResponseType
}

// to remove if not used anywhere else
export type INVENTORY_ITEM_TYPE = Omit<ItemsResponseType, 'tax' | 'tax_included'> & {
  quantity: number
  category: string[]
  catalog_item_id: string
}

/* Inventory API V2 type */

interface CatalogInfo {
  name: string
  full_description: string
  sku: string
  hsn_code: string
  product_code: string
  unit: string
  secondary_units?: { name: string; factor: number }[]
  rate: number
  mrp: number
  image?: string
  gst?: {
    basis_pts: number
    amount: number
  }
  cess?: {
    basis_pts: number
    amount: number
  }
  meta_info?: {
    show_tax_included?: boolean
  }
}

export type INVENTORY_ITEM_TYPE_V2 = {
  catalog_info: CatalogInfo
  item_id: string
  catalog_item_id: string
  created_at: number
  updated_at: number
  business_id: string
  quantity: number
  tags: string[]
}

type MinQuantityDiscounts =
  | {
      is_percent?: false
      amount: number
      min_quantity: number
    }
  | {
      is_percent: true
      basis_pts: number
      min_quantity: number
    }

type DiscountOnRate =
  | {
      is_percent?: false
      amount: number
      min_quantity_discounts?: MinQuantityDiscounts[]
    }
  | {
      is_percent: true
      basis_pts: number
      min_quantity_discounts?: MinQuantityDiscounts[]
    }

interface Info {
  discount_on_rate?: DiscountOnRate
}

export interface OFFER_RESPONSE {
  data: any
  id: string
  created_at: number
  updated_at: number
  business_id: string
  name: string
  type: number
  info?: Info
  start_at: number
  end_at?: number
  customer_ids?: null | string[]
}

export type FETCH_INVENTORY_ITEMS_RESPONSE = {
  items: INVENTORY_ITEM_TYPE_V2[] // This key is kept to support staff link order maangement,
  inv_items: {
    item: INVENTORY_ITEM_TYPE_V2
    offers?: OFFER_RESPONSE[]
  }[]
}

export interface FetchItemResponseType {
  items: ItemsResponseType[]
}

export type ItemsResponseType = {
  item_id: string
  business_id: string
  description: string
  unit: string
  added_on: string
  tax_included: boolean
  gst_amount: string | number
  cess_amount: string | number
  product_code: string
  hsn_code: string
  image: string
  created_at: string
  updated_at: string
  deleted_at: string
  sku: string
  full_description: string
  rate: number
  price: number // this field is not suppose to be used as definition is not clear as of now
  mrp: number
  show_tax_included?: boolean
}

type CatalogInfoOld = {
  name: string
  sku: string
  hsn_code: string
  product_code: string
  rate: number
  mrp: number
  gst: {
    basis_pts: number
    amount: number
  }
  cess: Record<string, unknown>
  meta_info: {
    show_tax_included: boolean
  }
}

export const LOG_TYPE = {
  0: 'MANUAL UPDATE',
  1: 'SALE',
  2: 'PURCHASE',
  3: 'RETURN',
  4: 'DISCARD',
  5: 'EDIT BILL'
}

export enum LOG_SOURCE {
  UNKNOWN = 0,
  MANUAL = 1,
  BILL = 2,
  UPLOAD = 3,
  IMPORT = 4,
  PURCHASE = 5
}

type Log = {
  timestamp: number
  action: keyof typeof LOG_TYPE
  source: LOG_SOURCE
  source_ref_id: string
  updated_quantity: number
  quantity_change: number
  meta_info?:
    | Record<string, any>
    | {
        labels?: {
          bill_number: string
        }
      }
}

export type InventoryItemsResponseType = {
  catalog_info: CatalogInfo
  item_id: string
  catalog_item_id: string
  created_at: number
  updated_at: number
  app_added_on: number
  business_id: string
  quantity: number
}

export type InventoryItemLogsResponseType = {
  item: InventoryItemsResponseType
  logs?: Log[]
}

export type CREATE_BILL_RESPONSE = {
  bill_id: string
  success: boolean
}

export type FETCH_BILL_RESPONSE = {
  bills: BillFromResponse[]
}

export type BillFromResponse = {
  credit_note_ids?: string[]
  bill_id: string
  bill_number: string
  customer_id: string
  customer_name: string
  bill_date: number
  amount: number
  due_amount: number
  status?: BILL_STATUS /* This is optional because for status: 0, BE doesn't send the key */
}

export type FETCH_BUSINESS_DISCOUNTS_RESPONSE = {
  discounts: DiscountFromResponse[]
}

export type DiscountFromResponse = {
  id: string
  name: string
}

export type FETCH_BUSINESS_CHARGES_RESPONSE = {
  charges: ChargeFromResponse[]
}

export type ChargeFromResponse = {
  id: string
  name: string
}

export type BillItemType = {
  mrp: number
  tax: number
  gst: number
  cess: number
  name: string
  rate: number
  amount: number
  quantity: number
  hsn_code: string
  tax_components: Array<TaxComponentType>
  catalog_item_id: string
  discount: number
  sgst_tax_percent: number
  cgst_tax_percent: number
  inventory_item_id?: string
}

export type BillInfo = {
  bill_number: string
  bill_date: number
  total_amount: number
  note: string
  items: Array<BillItemType>
  discounts: Array<BillDiscounts>
  add_charges: Array<BillCharges>
  supply_state?: {
    code: string
    name: string
  }
}

export type BuyerInfo = {
  id: string
  name: string
  type: number
  address: string
  mobile: string
  gst_number?: string
}

export interface SelectedBillType extends BillsType {
  selectedItems: Array<ItemsDataType>
}

export type BillByIdResponse = {
  bill: {
    bill_id: string
    created_at: number
    updated_at: number
    deleted_at: number
    ref_source: number
    ref_id: string
    bill_version: number
    bill_info: BillInfo
    buyer: BuyerInfo
  }
}

export interface IUnitOffer {
  name: string
  offer_id: string
  type: 1
  info: Info
}
