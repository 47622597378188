import { SORT_ORDER_TYPE } from 'state/collectionSummary/collectionSummary.types'

export interface ISupplyState {
  isFetchingStatementGroups: boolean
  statementGroupTableData: IStatementGroupTable[]
  specificGroupSummaryTotal: SpecificGroupSummary
  specificGroupTableData: ISpecificGroupTableData[]
  specificGroupName: string
  specificStatementGroup?: IStatementGroup
  statementGroupFilter: {
    startTime: number
    endTime: number
    filterType: FILTER_TYPE
  }
  statementGroupPagination: {
    currentPageNumber: number
    perPage: number
  }
  drawers: { downloadStatementSummary: boolean; downloadGroupStatementSummary: boolean }
  downloadStatementStatus: 'uninitiated' | 'initiated' | 'completed'
  downloadGroupStatementStatus: 'uninitiated' | 'initiated' | 'completed'
  invoiceDetails: IBillsData[]
  sortBy?: string
  sortOrder?: null | SORT_ORDER_TYPE

  innerTableSortOption?: {
    sortBy?: string
    sortOrder?: SORT_ORDER_TYPE
  }
}

export interface IFilter {
  filter_condition: 'or' | 'and'
  tags: string[]
}

export interface IStatementGroup {
  business_id: string
  create_time: string
  filters: null | IFilter
  id: string
  name: string
  update_time: string
}

export interface IBillsData {
  invoiceNumber: string
  invoiceDate: string
  invoiceAmountPayable: number
  invoiceAmountDue: number
  invoiceStatus: string
}

export enum FILTER_TYPE {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  CUSTOM = 'Custom',
  DEFAULT = ''
}

export interface ISpecificGroupTableData extends ISuplyDataFormat {
  customerCode: string
  customerId: string
  customerName: string
}

export interface SpecificGroupSummary extends ISuplyDataFormat {}

export type SummaryStats = {
  total_dues: string
  total_payments: string
  balance_due: string
  total_damages: string
  total_cash_payments: string
  total_neft_payments: string
  total_cheque_payments: string
  total_wallet_payments: string
  total_online_payments: string
  total_returns: string
  total_invoices: string
  total_other_payments: string
}

export type CollectionList = {
  summary_stats: SummaryStats
  number_of_invoices: string
  id: string
  name: string
  pending_action_required: boolean
  pending_replacements: boolean
}

export type OverallStats = {
  total_dues: string
  total_payments: string
  balance_due: string
  total_accounts: string
}

export type ResponseData = {
  collection_lists: CollectionList[]
  overall_stats: OverallStats
}

export interface ISuplyDataFormat {
  invoiceDue: number
  credit: number
  totalPayments: number
  damages: number
  cash: number
  neft: number
  cheque: number
  advanceUsed: number
  qrLink: number
  returns: number
  others: number
}

export interface IStatementGroupTable extends ISuplyDataFormat {
  groupId: string
  groupName: string
}

export type Account = {
  summary_stats: SummaryStats
  customer_code: string
  id: string
  name: string
}

export type StatementGroup = {
  id: string
  business_id: string
  name: string
  filters: {
    tags: string[]
    filter_condition: string
  }
  create_time: string
  update_time: string
}

export type GroupSpecificData = {
  accounts: Account[]
  statement_group: StatementGroup
  pending_action_required: boolean
  pending_replacement_required: boolean
  overall_stats: OverallStats
}

export type FetchStatementGroupPayload = {
  startTime: number
  endTime: number
  filterType: FILTER_TYPE
  sortBy?: string
  sortOrder?: SORT_ORDER_TYPE | null
}

export type ResponseBill = {
  bill_date: string
  bill_number: string
  bill_amount: string
  bill_due_amount: string
  status: string
}
