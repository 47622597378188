import { select } from 'redux-saga/effects'
import dayjs from 'dayjs'
import { IAppState } from 'infra/AppState'
import { Action } from 'infra/types'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { INavigationState } from '../navigation/navigation.types'
import { BlindpayActions } from './blindpay.actions'
import { IBlindpayState, LINK_STATUS } from './blindpay.types'
export default function* blindPayAnalytics(action: Action<any>): any {
  const {
    Blindpay: blindPayState,
    Navigation
  }: { Blindpay: IBlindpayState; Navigation: INavigationState } = yield select(
    (state: IAppState): IAppState => state
  )

  const timeLeftToClaim = dayjs
    .unix(blindPayState.paymentDetail.expiryTime)
    .diff(dayjs(), 's', true)

  switch (action.type) {
    case BlindpayActions.FETCH_PAYMENT_DETAILS_SUCCESS: {
      const { senderName, senderMobile, senderMerchantId } = action.payload
      yield Mixpanel.identify(senderMerchantId)
      yield Mixpanel.register({
        merchant_id: senderMerchantId
        // mobile: senderMobile,
        // name: senderName
      })
      yield Mixpanel.people.set({
        merchant_id: senderMerchantId
        // mobile: senderMobile,
        // name: senderName
      })
      break
    }
    case BlindpayActions.BLINDPAY_PAGE_VIEW: {
      if (Navigation.currentRoute.pathName === 'introduction') {
        yield Mixpanel.track('easy_pay_page_view', {
          account_id: blindPayState.paymentDetail.accountId,
          merchant_id: blindPayState.paymentDetail.senderMerchantId,
          source: 'NA',
          easy_pay_payment_time: blindPayState.paymentDetail.paidAt,
          time_left_to_claim:
            action.payload === LINK_STATUS.ACTIVE ? timeLeftToClaim : action.payload.toLowerCase()
        })
      }
      break
    }
    case BlindpayActions.CLAIM_PAYMENT: {
      yield Mixpanel.track('easy_pay_claim_payment', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        source: 'NA',
        time_left_to_claim: timeLeftToClaim
      })
      break
    }
    case BlindpayActions.ENTERING_OTP: {
      yield Mixpanel.track('easy_pay_otp_enter', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId
      })
      break
    }
    case BlindpayActions.VERIFY_OTP_FAILURE: {
      yield Mixpanel.track('easy_pay_otp_status', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        status: 'incorrect'
      })
      break
    }
    case BlindpayActions.VERIFY_OTP_SUCCESS: {
      yield Mixpanel.track('easy_pay_otp_status', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        status: 'correct'
      })
      break
    }
    case BlindpayActions.RESEND_OTP: {
      yield Mixpanel.track('easy_pay_resend_otp', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId
      })
      break
    }
    case BlindpayActions.CHANGE_DESTIANTION_TYPE: {
      yield Mixpanel.track('change_collection_type', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        type: action.payload,
        screen: 'easy_pay_collection_adoption'
      })
      break
    }
    case BlindpayActions.ENTERING_DESTINATION_DETAILS: {
      yield Mixpanel.track('collection_info_enter', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        type: action.payload,
        screen: 'easy_pay_collection_adoption'
      })
      break
    }
    case BlindpayActions.VERIFY_DESTINATION_DETAILS: {
      yield Mixpanel.track('collection_info_complete', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        type: action.payload.paymentAddressType,
        screen: 'easy_pay_collection_adoption'
      })
      break
    }
    case BlindpayActions.VERIFY_DESTINATION_DETAILS_SUCCESS: {
      yield Mixpanel.track('payment_address_validate', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        type: action.payload.paymentAddressType,
        is_valid: action.payload.isValid
      })
      break
    }
    case BlindpayActions.SUBMIT_DESTINATION_DETAILS: {
      yield Mixpanel.track('collection_info_confirm', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        type: action.payload.paymentAddressType,
        screen: 'easy_pay_collection_adoption'
      })
      break
    }
    case BlindpayActions.SUBMIT_DESTINATION_DETAILS_SUCCESS: {
      yield Mixpanel.track('collection_info_added', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        type: action.payload.paymentAddressType,
        screen: 'web_collection_adoption'
      })
      break
    }
    case BlindpayActions.CHANGE_DESTINATION_DETAILS: {
      yield Mixpanel.track('collection_info_change', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId
      })
      break
    }
    case BlindpayActions.POST_CLAIM_PAGE_VIEW: {
      yield Mixpanel.track('easy_pay_post_claim_page_view', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        source: 'NA',
        easy_pay_payment_time: blindPayState.paymentDetail.paidAt,
        status: action.payload.toLowerCase()
      })
      break
    }
    case BlindpayActions.SESSION_RESTART: {
      yield Mixpanel.track('easy_pay_session_time_out_click', {
        account_id: blindPayState.paymentDetail.accountId,
        merchant_id: blindPayState.paymentDetail.senderMerchantId,
        source: 'NA'
      })
      break
    }
  }
}
