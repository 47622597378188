import { call, put, take, fork, cancel, select } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import { Action } from 'infra/types'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { AuthApi } from 'services/Api/AuthApi'
import { AuthApiWithToken } from 'services/Api/AuthApiWithToken'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { getErrorMessage, IErrorMessage } from 'services/Api/Errors'
import { IDispatchCallback } from 'services/Api/types'
import { IApiResponse, API_RESPONSE_TYPE, IUserAuthData } from 'shared/constants'
import { forwardTo } from 'shared/state/common'
import { LinkManager } from 'shared/utils'
import { fetchBusinessIdsAction } from 'state/dashboard/dashboard.actions'
import {
  AuthActions,
  endTimer,
  openAuthDialog,
  updateTimer,
  startTimer,
  sendOtpSuccess,
  verifyReceivedOtpSuccess,
  navigateToDashboardSuccess,
  userLoggedIn,
  userLoggedOut,
  syncWithLocalStorageSuccess,
  sendOtpFailure,
  verifyReceivedOtpFailure
} from './auth.actions'
import { IAuthState } from './auth.types'

export function* getOtp(action: Action<number>) {
  try {
    const response: IApiResponse = yield call(AuthApi.authGetOtp, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(sendOtpSuccess(response.data.otp_id))
      yield put(openAuthDialog())
      yield put(startTimer())
    } else {
      yield put(sendOtpFailure(response.data.message))
      const errorMessage: IErrorMessage = getErrorMessage(response.data)

      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: errorMessage.message
        })
      )
    }
  } catch (e: any) {
    const errorMessage: IErrorMessage = getErrorMessage(e)
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: errorMessage.message
      })
    )
  }
}

export function* verifyOtp(action: Action<{ otpId: string; otp: string } & IDispatchCallback>) {
  try {
    const { redirectTo, otpId } = action.payload
    const response: IApiResponse = yield call(AuthApi.authVerifyOtp, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const aRes: IApiResponse = yield call(AuthApi.authAuthenticateToken, response.data.token)
      const currentTime = new Date()
      if (aRes.type === API_RESPONSE_TYPE.SUCCESS) {
        const authData: IUserAuthData = aRes.data
        const expiry = currentTime.getTime() + (authData.expires_in - 60) * 1000
        yield put(
          verifyReceivedOtpSuccess({
            accessToken: authData.access_token,
            refreshToken: authData.refresh_token,
            newUser: authData.new_user,
            expiresIn: expiry,
            appLock: authData.app_lock
          })
        )
        AuthStorageInstance.setAccessToken(authData.access_token)
        AuthStorageInstance.setRefreshToken(authData.refresh_token)
        AuthStorageInstance.setFirstTimeUser(authData.new_user)
        AuthStorageInstance.setAccessToken(authData.access_token)
        AuthStorageInstance.setExpiresIn(expiry)
        AuthStorageInstance.setIsLoggedIn(true)
        yield logInUser(authData.new_user)
        yield put(fetchBusinessIdsAction({ isSigningIn: true }))
        if (redirectTo) {
          yield call(forwardTo, redirectTo)
        }
      } else {
        yield put(verifyReceivedOtpFailure(otpId))
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: 'Verification of Authentication Token Failed. Please try logging in again!'
          })
        )
      }
    } else {
      yield put(verifyReceivedOtpFailure(otpId))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Otp Verification Failed. Please try again!'
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

const countdown = (secs: number) => {
  return eventChannel((emitter: Function) => {
    const counter = setInterval(() => {
      secs -= 1
      if (secs > 0) {
        emitter(secs)
      } else {
        // this causes the channel to close
        emitter(END)
      }
    }, 1000)
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(counter)
    }
  })
}

function* countdownTimer(secs: number) {
  //@ts-ignore
  const callback = yield call(countdown, secs)
  const { Auth }: { Auth: IAuthState } = yield select()
  try {
    while (true) {
      if (Auth.otpTimer === 0) {
        yield put(endTimer())
      }
      //@ts-ignore
      const timerElapsed = yield take(callback)
      yield put(updateTimer(timerElapsed))
    }
  } finally {
    yield put(endTimer())
  }
}

export function* startCountDownTimer() {
  //@ts-ignore
  const task = yield fork(countdownTimer, 15)

  const { type } = yield take([AuthActions.END_TIMER])
  if (type === AuthActions.END_TIMER) {
    yield cancel(task)
    yield put(endTimer())
  }
}

export function* navigateToDashboard(action: Action<any>) {
  try {
    yield put(navigateToDashboardSuccess())
    action.payload('/')
  } catch (error) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Navigation to Dashboard Page Failed. Please try again!'
      })
    )
  }
}

export function* logInUser(newUser: boolean) {
  yield put(userLoggedIn(newUser))
}

export function* changeLanguage() {}

export function* syncWithLocalStorage(action: Action<any>) {
  const isLoggedIn: boolean = AuthStorageInstance.getIsLoggedIn()
  const isNewUser: boolean = AuthStorageInstance.getFirstTimeUser()

  yield put(syncWithLocalStorageSuccess(isLoggedIn, isNewUser))
  if (!isLoggedIn && action.payload) {
    action.payload(LinkManager.getLoginLink())
  }
}

export function* signoutUser() {
  AuthStorageInstance.clear()
  yield window.location.replace(LinkManager.getLoginLink())
  yield put(userLoggedOut())
}

export function* clickTerms() {
  yield window.open('https://okcredit.in/terms', '_newtab')
}

export function* clickPrivacy() {
  yield window.open('https://okcredit.in/privacy', '_newtab')
}

export function* clickAbout() {
  yield window.open('https://okcredit.in', '_newtab')
}

export function* signoutUserFromAllDevices(action: Action<string>) {
  try {
    const response: IApiResponse = yield call(AuthApiWithToken.logoutFromAll, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      AuthStorageInstance.clear()
      yield window.location.replace(LinkManager.getLoginLink())
      yield put(userLoggedOut())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText:
            'Could not sign you out from all devices. You can try again or signout from this app instead!',
          actionText: 'Signout Instead',
          onActionClick: () => {
            AuthStorageInstance.clear()
            window.location.replace(LinkManager.getLoginLink())
          }
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message,
        actionText: 'Signout Instead',
        onActionClick: () => {
          AuthStorageInstance.clear()
          window.location.replace(LinkManager.getLoginLink())
        }
      })
    )
  }
}

export function* verifyPin(action: Action<{ pin: string; number: number } & IDispatchCallback>) {
  try {
    const { redirectTo } = action.payload
    const response: IApiResponse = yield call(AuthApi.authVerifyPin, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const currentTime = new Date()
      const authData: IUserAuthData = response.data
      const expiry = currentTime.getTime() + (authData.expires_in - 60) * 1000
      yield put(
        verifyReceivedOtpSuccess({
          accessToken: authData.access_token,
          refreshToken: authData.refresh_token,
          newUser: authData.new_user,
          expiresIn: expiry,
          appLock: authData.app_lock
        })
      )
      AuthStorageInstance.setAccessToken(authData.access_token)
      AuthStorageInstance.setRefreshToken(authData.refresh_token)
      AuthStorageInstance.setFirstTimeUser(authData.new_user)
      AuthStorageInstance.setAccessToken(authData.access_token)
      AuthStorageInstance.setExpiresIn(expiry)
      AuthStorageInstance.setIsLoggedIn(true)
      yield logInUser(authData.new_user)
      yield put(fetchBusinessIdsAction({ isSigningIn: true }))
      if (redirectTo) {
        yield call(forwardTo, redirectTo)
      }
    } else {
      // yield put(verifyReceivedOtpFailure(otpId))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Pin Verification Failed. Please try again!'
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}
