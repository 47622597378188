/* eslint-disable security/detect-object-injection */
import { Action } from 'infra/types'
import { getNormalizedList, getDeNormalizedList } from 'utils/dataNormalizer'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { ENTITY_TYPE, InitialFilterValues } from '../../constants'
import { DashboardActions } from './dashboard.actions'
import { DashboardState } from './dashboard.state'
import { ICustomer, IDashboardState, IMerchantProfile, ISupplier } from './dashboard.types'
import { applyFilters, applyFiltersOnCustomers } from './helpers/applyFilters'
import { applySearch } from './helpers/applySearch'
import { validateMerchantProfileField } from './helpers/MerchantProfile'
import { validateEntityProfileField } from './helpers/CustomerSupplierProfile'

export const DashboardReducers = (
  state: IDashboardState = DashboardState,
  action: Action<any>
): IDashboardState => {
  switch (action.type) {
    case DashboardActions.FETCH_LOCAL_DATA_SUCCESS: {
      return {
        ...state,
        merchantRegistration: {
          ...state.merchantRegistration,
          optedOut: action.payload
        }
      }
    }
    case DashboardActions.SET_BUSINESS_SETTINGS: {
      return {
        ...state,
        businessSettings: action.payload
      }
    }
    case DashboardActions.ADD_ENTITY: {
      return {
        ...state,
        entityAction: {
          ...state.entityAction,
          loading: true
        }
      }
    }
    case DashboardActions.ADD_ENTITY_SUCCESS: {
      return {
        ...state,
        entityAction: {
          ...state.entityAction,
          loading: false
        }
      }
    }
    case DashboardActions.REGISTER_MERCHANT_NAME: {
      return {
        ...state,
        merchantRegistration: {
          ...state.merchantRegistration,
          loading: true,
          optedOut: true
        }
      }
    }
    case DashboardActions.REGISTER_MERCHANT_NAME_SUCCESS: {
      return {
        ...state,
        merchantRegistration: {
          loading: false,
          show: false,
          optedOut: true
        }
      }
    }
    case DashboardActions.REGISTER_MERCHANT_NAME_FAILURE: {
      return {
        ...state,
        merchantRegistration: {
          ...state.merchantRegistration,
          loading: false,
          optedOut: true
        }
      }
    }
    case DashboardActions.SHOW_BUSINESS_NAME_MODAL: {
      return {
        ...state,
        merchantRegistration: {
          ...state.merchantRegistration,
          show: true
        }
      }
    }
    case DashboardActions.BUSINESS_NAME_MODAL_OPTED_OUT: {
      return {
        ...state,
        merchantRegistration: {
          ...state.merchantRegistration,
          show: false,
          optedOut: true
        }
      }
    }
    case DashboardActions.FETCH_BUSINESS_TYPES_SUCCESS: {
      return {
        ...state,
        businessTypes: action.payload
      }
    }
    case DashboardActions.FETCH_BUSINESS_CATEGORIES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        // @todo: Others category throws error from backend
        businessCategories: Array.isArray(payload) ? payload.filter((_) => _.type !== 2) : []
      }
    }
    case DashboardActions.FETCH_MERCHANT_PROFILE: {
      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          loading: true
        }
      }
    }
    case DashboardActions.FETCH_MERCHANT_PROFILE_SUCCESS: {
      const merchantProfile = action.payload

      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          data: merchantProfile,
          edited: {
            values: {
              name: merchantProfile.name,
              mobile: merchantProfile.mobile,
              address: merchantProfile.address,
              email: merchantProfile.email,
              contactName: merchantProfile.contactName,
              about: merchantProfile.about,
              businessTypeId: merchantProfile.businessTypeId,
              categoryId: merchantProfile.categoryId,
              gst: merchantProfile.businessGst
            },
            errors: {}
          },
          loading: false
        }
      }
    }
    case DashboardActions.FETCH_MERCHANT_PROFILE_FAILURE: {
      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          loading: false
        }
      }
    }
    case DashboardActions.EDIT_MERCHANT_PROFILE: {
      const { key, value } = action.payload
      let errorMsg = ''
      if (key === 'name' && value.length > 45) {
        errorMsg = 'Max character limit reached'
        return {
          ...state,
          merchantProfile: {
            ...state.merchantProfile,
            ...(state.merchantProfile.edited
              ? {
                  edited: {
                    values: {
                      ...state.merchantProfile.edited.values
                    },
                    errors: {
                      ...state.merchantProfile.edited.errors,
                      [key]: errorMsg
                    }
                  }
                }
              : {}),
            loading: true
          }
        }
      }
      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          ...(state.merchantProfile.edited
            ? {
                edited: {
                  values: {
                    ...state.merchantProfile.edited.values,
                    [key]: value
                  },
                  errors: {
                    ...state.merchantProfile.edited.errors,
                    [key]: !validateMerchantProfileField({ key, value })
                  }
                }
              }
            : {}),
          loading: true
        }
      }
    }
    case DashboardActions.PATCH_MERCHANT_PROFILE: {
      const profile = action.payload
      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          data: {
            ...state.merchantProfile.data,
            ...profile
          },
          edited: {
            values: {
              ...state.merchantProfile.edited?.values,
              name: profile.name,
              mobile: profile.mobile,
              address: profile.address,
              email: profile.email,
              contactName: profile.contactName,
              about: profile.about,
              businessTypeId: profile.businessTypeId,
              categoryId: profile.categoryId
            },
            errors: {}
          },
          loading: false
        },
        businessAccounts: {
          ...state.businessAccounts,
          businessDetails: {
            ...state.businessAccounts.businessDetails,
            [profile.id]: profile
          }
        }
      }
    }
    case DashboardActions.UPDATE_SEARCH_STRING: {
      const customers = getDeNormalizedList(state.customers) as ICustomer[]
      const suppliers = getDeNormalizedList(state.suppliers) as ISupplier[]
      const searchResults = applySearch(action.payload, state.currentTab, customers, suppliers)
      const filteredSearchResults = applyFilters(
        state.filters,
        searchResults.customers,
        searchResults.suppliers
      )

      const filteredCustomersIds = filteredSearchResults.customers?.map((_) => _.id)
      const filteredSuppliersIds = filteredSearchResults.suppliers?.map((_) => _.id)

      return {
        ...state,
        searchString: action.payload,
        customers: {
          ...state.customers,
          filtered: filteredCustomersIds
        },
        suppliers: {
          ...state.suppliers,
          filtered: filteredSuppliersIds
        }
      }
    }
    case DashboardActions.UPDATE_SEARCH_STRING_ON_TAGS: {
      return {
        ...state,
        searchString: action.payload
      }
    }
    case DashboardActions.CLEAR_FILTERS: {
      const customers = getDeNormalizedList(state.customers) as ICustomer[]
      const suppliers = getDeNormalizedList(state.suppliers) as ISupplier[]
      const searchResults = applySearch(state.searchString, state.currentTab, customers, suppliers)
      const filteredCustomersIds = searchResults.customers?.map((_) => _.id)
      const filteredSuppliersIds = searchResults.suppliers?.map((_) => _.id)

      return {
        ...state,
        filters: InitialFilterValues,
        customers: {
          ...state.customers,
          filtered: filteredCustomersIds
        },
        suppliers: {
          ...state.suppliers,
          filtered: filteredSuppliersIds
        }
      }
    }
    case DashboardActions.SAVE_FILTERS: {
      const filteredCustomers = getDeNormalizedList(
        state.customers,
        state.customers.filtered || []
      ) as ICustomer[]
      const filteredSuppliers = getDeNormalizedList(
        state.suppliers,
        state.suppliers.filtered || []
      ) as ISupplier[]
      const filteredData = applyFilters(action.payload, filteredCustomers, filteredSuppliers)
      const filteredCustomersIds = filteredData.customers?.map((_) => _.id)
      const filteredSuppliersIds = filteredData.suppliers?.map((_) => _.id)
      return {
        ...state,
        filters: action.payload,
        customers: {
          ...state.customers,
          filtered: filteredCustomersIds
        },
        suppliers: {
          ...state.suppliers,
          filtered: filteredSuppliersIds
        }
      }
    }
    case DashboardActions.SAVE_FILTERS_ON_TAGS: {
      const filteredData = applyFiltersOnCustomers(action.payload, state.customersByTags)
      return {
        ...state,
        customersByTags: filteredData.customers,
        tagsFilter: action.payload
      }
    }
    case DashboardActions.CHANGE_LIST_TAB: {
      return {
        ...state,
        currentTab: action.payload,
        searchString: ''
      }
    }
    case DashboardActions.FETCH_ENTITY_LIST: {
      const entityType = action.payload

      return {
        ...state,
        ...(entityType === ENTITY_TYPE.CUSTOMER
          ? {
              customers: {
                ...state.customers,
                loading: true
              }
            }
          : {
              suppliers: {
                ...state.suppliers,
                loading: true
              }
            })
      }
    }
    case DashboardActions.FETCH_ENTITY_LIST_SUCCESS: {
      const isCustomer = action.payload.entityType === ENTITY_TYPE.CUSTOMER
      const entities = action.payload.entities
      const normalisedEntities = getNormalizedList(entities)

      if (isCustomer) {
        const filteredData = applyFilters(
          state.filters,
          entities,
          state.suppliers.filtered?.length
            ? getDeNormalizedList(state.suppliers, state.suppliers.filtered)
            : undefined
        )
        const filteredIds = filteredData.customers?.map((_) => _.id)
        return {
          ...state,
          customers: {
            ...normalisedEntities,
            filtered: filteredIds,
            loading: false
          }
        }
      }
      const filteredData = applyFilters(
        state.filters,
        state.customers.filtered?.length
          ? getDeNormalizedList(state.customers, state.customers.filtered)
          : undefined,
        entities
      )
      const filteredIds = filteredData.suppliers?.map((_) => _.id)
      return {
        ...state,
        suppliers: {
          ...normalisedEntities,
          filtered: filteredIds,
          loading: false
        }
      }
    }
    case DashboardActions.FETCH_ENTITY_LIST_FAILURE: {
      const entityType = action.payload

      return {
        ...state,
        ...(entityType === ENTITY_TYPE.CUSTOMER
          ? {
              customers: {
                ...state.customers,
                loading: false
              }
            }
          : {
              suppliers: {
                ...state.suppliers,
                loading: false
              }
            })
      }
    }
    case DashboardActions.EDIT_ENTITY: {
      const { key, value, entityType, entityId } = action.payload
      const newState = { ...state }
      if (entityType === ENTITY_TYPE.CUSTOMER) {
        newState.customers = {
          ...state.customers,
          byIds: {
            ...state.customers.byIds,
            ...(state.customers.byIds[entityId]
              ? {
                  [entityId]: {
                    ...state.customers?.byIds[entityId],
                    edited: {
                      values: {
                        ...state.customers.byIds[entityId].edited.values,
                        [key]: value
                      },
                      errors: {
                        ...state.customers.byIds[entityId].edited.errors,
                        [key]: !validateEntityProfileField({ key, value })
                      }
                    }
                  }
                }
              : {})
          }
        }
      } else if (state.suppliers) {
        newState.suppliers = {
          ...state.suppliers,
          byIds: {
            ...state.suppliers.byIds,
            ...(state.suppliers.byIds[entityId]
              ? {
                  [entityId]: {
                    ...state.suppliers.byIds[entityId],
                    edited: {
                      values: {
                        ...state.suppliers.byIds[entityId].edited.values,
                        [key]: value
                      },
                      errors: {
                        ...state.suppliers.byIds[entityId].edited.errors,
                        [key]: !validateEntityProfileField({ key, value })
                      }
                    }
                  }
                }
              : {})
          }
        }
      }
      return newState
    }
    case DashboardActions.PATCH_ENTITY: {
      const { entityType, id: entityId } = action.payload
      const newState = { ...state }

      if (entityType === ENTITY_TYPE.CUSTOMER) {
        newState.customers = {
          ...state.customers,
          byIds: {
            ...state.customers.byIds,
            [entityId]: action.payload
          }
        }
      } else if (state.suppliers) {
        newState.suppliers = {
          ...state.suppliers,
          byIds: {
            ...state.suppliers.byIds,
            [entityId]: action.payload
          }
        }
      }
      return newState
    }
    case DashboardActions.DELETE_ENTITY_SUCCESS: {
      const { entityType, id } = action.payload
      const filter = (_: string) => _ !== id

      return {
        ...state,
        ...(entityType === ENTITY_TYPE.CUSTOMER
          ? {
              customers: {
                ...state.customers,
                allIds: state.customers.allIds.filter(filter),
                filtered: state.customers.filtered?.filter(filter)
              }
            }
          : {
              suppliers: {
                ...state.suppliers,
                allIds: state.suppliers.allIds.filter(filter),
                filtered: state.suppliers.filtered?.filter(filter)
              }
            })
      }
    }
    case DashboardActions.FETCH_PHONEBOOK_CONTACTS: {
      return {
        ...state,
        phonebookContacts: {
          ...state.phonebookContacts,
          loading: true
        }
      }
    }
    case DashboardActions.FETCH_PHONEBOOK_CONTACTS_SUCCESS: {
      return {
        ...state,
        phonebookContacts: {
          data: action.payload,
          loading: false
        }
      }
    }
    case DashboardActions.FETCH_PHONEBOOK_CONTACTS_FAILURE: {
      return {
        ...state,
        phonebookContacts: {
          ...state.phonebookContacts,
          loading: false
        }
      }
    }
    case DashboardActions.FETCH_HELP_SECTIONS: {
      return {
        ...state,
        helpSections: {
          ...state.helpSections,
          loading: true
        }
      }
    }
    case DashboardActions.FETCH_HELP_SECTIONS_SUCCESS: {
      return {
        ...state,
        helpSections: {
          data: action.payload,
          loading: false
        }
      }
    }
    case DashboardActions.FETCH_HELP_SECTIONS_FAILURE: {
      return {
        ...state,
        helpSections: {
          ...state.helpSections,
          loading: false
        }
      }
    }
    case DashboardActions.GET_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state,
        currentCustomer: action.payload
      }
    }
    case DashboardActions.SET_SHOW_LEADGEN: {
      return {
        ...state,
        showLeadgenBanner: action.payload
      }
    }
    case DashboardActions.FETCH_FILTERED_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        customersByTags: action.payload
      }
    }
    case DashboardActions.SAVE_SEARCH_BY_FILTERS: {
      return {
        ...state,
        searchFilters: action.payload
      }
    }
    case DashboardActions.FETCH_SEARCH_BY_INVOICE_DATA_SUCCESS: {
      return {
        ...state,
        billsFiltered: action.payload
      }
    }
    case DashboardActions.SET_BUSINESS_ACCOUNTS_LOADER: {
      return {
        ...state,
        businessAccounts: {
          ...state.businessAccounts,
          loading: action.payload
        }
      }
    }
    case DashboardActions.SET_BUSINESS_ACCOUNTS_DRAWER: {
      return {
        ...state,
        businessAccounts: {
          ...state.businessAccounts,
          drawers: {
            ...state.businessAccounts.drawers,
            ...action.payload
          }
        }
      }
    }
    case DashboardActions.FETCH_BUSINESS_IDS_SUCCESS: {
      return {
        ...state,
        businessAccounts: {
          ...state.businessAccounts,
          businessIds: action.payload.businessIds,
          individualUser: action.payload.individualUser,
          asStaffBusinessIds: action.payload.asStaffBusinessIds,
          permittedWorkflows: action.payload.permittedWorkflows
        }
      }
    }
    case DashboardActions.SET_BUSINESS_DETAILS: {
      return {
        ...state,
        businessAccounts: {
          ...state.businessAccounts,
          businessDetails: action.payload
        }
      }
    }
    case DashboardActions.SET_AS_STAFF_BUSINESS_DETAILS: {
      return {
        ...state,
        businessAccounts: {
          ...state.businessAccounts,
          asStaffBusinessDetails: action.payload
        }
      }
    }
    case DashboardActions.CREATE_BUSINESS_SUCCESS: {
      const businessId = action.payload.id
      return {
        ...state,
        businessAccounts: {
          ...state.businessAccounts,
          businessIds: [...state.businessAccounts.businessIds, businessId],
          businessDetails: {
            ...state.businessAccounts.businessDetails,
            [businessId]: action.payload
          }
        }
      }
    }
    case DashboardActions.SET_BUSINESS_MANAGERS: {
      return {
        ...state,
        businessManagers: action.payload
      }
    }
    case DashboardActions.BULK_UPLOAD_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accountsDocument: {
          documentId: action.payload
        }
      }
    }
    case DashboardActions.BULK_UPLOAD_ACCOUNTS_STATUS_SUCCESS: {
      if (!state.accountsDocument) {
        return state
      }
      return {
        ...state,
        accountsDocument: {
          ...state.accountsDocument,
          document_status: action.payload
        }
      }
    }
    case DashboardActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.id]: action.payload.value
        }
      }
    }
    case DashboardActions.SET_CUSTOMER_PROFILES_DRAWER: {
      return {
        ...state,
        customerProfiles: {
          ...state.customerProfiles,
          drawers: {
            ...state.customerProfiles.drawers,
            [action.payload.id]: {
              status: action.payload.value,
              replace: action.payload.replace || false
            }
          }
        }
      }
    }
    case NEWDASHBOARD_EVENTS.CLOSE_PAID_SOON_BANNER: {
      return {
        ...state,
        paidSoonBanner: {
          ...state.paidSoonBanner,
          isShow: false
        }
      }
    }
    case NEWDASHBOARD_EVENTS.OPEN_PAID_SOON_BANNER: {
      return {
        ...state,
        paidSoonBanner: {
          ...state.paidSoonBanner,
          isShow: true
        }
      }
    }
    case DashboardActions.SET_CUSTOMER_PROFILES_DATA: {
      return {
        ...state,
        customerProfiles: {
          ...state.customerProfiles,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}
