/* eslint-disable curly */

import * as piexif from 'piexifjs'
import { paths } from 'routes'
import { getEnvConfig } from './getEnvConfig'

/**
 * Convert query string to a usable json object.
 * @example
 * // ?q=foo&k=bar becomes
 * // { q: 'foo', k: 'bar' }
 *
 * @template T The TS interface for the reduced object
 * @param {string} str The window.location.search value.
 * @returns {object} A reduced plain object parsed from
 * the query string
 */
export function getQuery<T>(str: string): T {
  if (!str) return {} as T
  return str
    .substring(1)
    .split('&')
    .map((x) => x.split('='))
    .reduce((acc, q) => ({ ...acc, [q[0]]: q[1] }), {}) as T
}

export interface IAccountParam {
  accountId: string
  action: string
}

export const pageBackAndroid = () => {
  if (window?.androidObj?.pageBack) {
    window.androidObj?.pageBack()
  }
}

export type PaymentTypeProtocol = 'foreclosure' | 'autopay_due'

export const LinkManager = {
  getBusinessLink() {
    return '/select-business'
  },
  getWelcomeLink() {
    return '/welcome'
  },
  getLoginLink() {
    return '/login'
  },
  getDashboardLink() {
    return '/dashboard'
  },
  getProfileLink() {
    return '/profile'
  },
  getCustomerComposeLink() {
    return '/dashboard/compose/customer'
  },
  getTransactionsLink(customerId: string) {
    return `/dashboard/customers/${customerId}`
  },
  getTransactionComposeLink(customerId: string, action: number | string) {
    return `/dashboard/customers/${customerId}/${action}`
  },
  getTransactionLink(transactionId: string) {
    return `/dashboard/transactions/${transactionId}`
  },
  getAccountLink() {
    return '/account'
  },
  getSettingsLink() {
    return '/settings'
  },
  getHelpLink(accountId?: string) {
    return `/help/${accountId}`
  },
  getCustomerProfileLink(customerId: string) {
    return `/dashboard/customer-profile/${customerId}`
  },
  getOnboardingDashboardLink() {
    return '/dashboard/onboarding'
  },
  getOnboardingFormLink() {
    return '/dashboard/onboarding/form'
  },
  getRevisitFormLink(merchantId: string) {
    return `/dashboard/revisit/form/${merchantId}`
  },
  getListSummaryLink() {
    return '/payments/list-summary'
  },
  getDefaulterPageLink() {
    return paths.defaulterLink
  },
  getLoanRepaymentLink(linkId: string, amount?: number, paymentType?: PaymentTypeProtocol) {
    return (
      getEnvConfig('LOAN_REPAYMENT_LINK') +
      'pay/' +
      linkId +
      (amount ? `?amount=${amount}` : '') +
      (paymentType ? `&paymentType=${paymentType}` : '')
    )
  }
}

const validImageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
export const validateImageMimeType = (file: File) => {
  return file && validImageMimeTypes.includes(file.type)
}

export const precisionRound = (number: number, precision: number): number => {
  const factor = 10 ** precision
  const n = precision < 0 ? number : 0.01 / factor + number
  return Math.round(n * factor) / factor
}

const dataUrlToFile = (dataUrl: string, fileName: string): File => {
  // Convert the base64 string to a Uint8Array
  const byteString = atob(dataUrl.split(',')[1])
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: mimeString })
  return file
}

export const extractExifFromFile = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'))
      return
    }

    const fileName = file.name
    const reader = new FileReader()

    reader.onload = (e: any) => {
      try {
        const base64String = e.target.result
        const imageWithoutExif = removeExifData(base64String)
        const newFile = dataUrlToFile(imageWithoutExif, fileName)
        resolve(newFile)
      } catch {
        resolve(file)
      }
    }

    reader.onerror = () => {
      resolve(file)
    }

    reader.readAsDataURL(file)
  })
}

const removeExifData = (base64String: string) => {
  /* PNG images doesn't store Exif data */
  if (base64String.includes('png') || base64String.includes('webp')) {
    return base64String
  }

  const exifObj = piexif.load(base64String)
  const modifiedExifObj = {}
  const exifBytes = piexif.dump(modifiedExifObj)
  return piexif.insert(exifBytes, base64String)
}
