import { select } from '@redux-saga/core/effects'
import dayjs from 'dayjs'
import { IAppState } from 'infra/AppState'
import { Action } from 'infra/types'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { NavigationActions } from 'state/navigation/navigation.actions'
import { ENTITY_TYPE } from '../../constants'
import { TransactionsActions } from './transaction.actions'
import {
  ITransactionFilter,
  TRANSACTION_REPORT_ACTION,
  TRANSACTION_TYPE,
  ITransactionsReport,
  TransactionSource
} from './transaction.types'

export const _getEntityRelatedAttributes: any = (state: IAppState) => {
  const { navParams } = state.Navigation.currentRoute
  const currentEntity = state.Transactions.currentEntity
  const entityType = currentEntity ? currentEntity.type : navParams.entity
  const entityId = currentEntity ? currentEntity.id : navParams.id

  if (entityType && entityId) {
    const entity =
      state.Dashboard[entityType === ENTITY_TYPE.CUSTOMER ? 'customers' : 'suppliers'].byIds[
        entityId
      ]
    if (entity) {
      return {
        Relation: entityType === ENTITY_TYPE.CUSTOMER ? 'Customer' : 'Supplier',
        // Mobile: entity.mobile,
        is_common_ledger: entity.isRegistered,
        account_id: entity.id
      }
    }
  }

  return {}
}

const _getAddTransactionAttributes: any = (state: IAppState) => {
  const { type } = state.Navigation.currentRoute.navParams

  return {
    Type: Number(type) === TRANSACTION_TYPE.CREDIT ? 'Credit' : 'Payment'
  }
}

const _getTransactionAttributes: any = (state: IAppState, { transactionId }: any) => {
  const transaction = state.Transactions.currentTransactions.byIds[transactionId]

  if (transaction) {
    return {
      'Transaction Id': transaction.id,
      Type: transaction.transactionType === TRANSACTION_TYPE.CREDIT ? 'Credit' : 'Payment'
    }
  }

  return {}
}

const _getTransactionReportAttributes: any = (filter: ITransactionFilter) => {
  const startTime = (filter && filter.startTime) || 0
  const endTime = (filter && filter.endTime) || dayjs().unix()

  return {
    Value: filter.label || 'Overall',
    date_range: [
      dayjs.unix(startTime).format('DD MMM YYYY'),
      dayjs.unix(endTime).format('DD MMM YYYY')
    ]
  }
}

export default function* transactionsAnalytics(action: Action<any>): any {
  const state: IAppState = yield select((state: IAppState) => state)
  const merchantId = state.Dashboard.merchantProfile.data?.id || ''
  const payload = action.payload

  switch (action.type) {
    case NavigationActions.ROUTE_CHANGE: {
      const currentRoute = payload
      if (currentRoute.pathName === 'addTransaction') {
        yield Mixpanel.trackWithPreset('Add Transaction Started', {
          Type:
            Number(currentRoute.navParams.type) === TRANSACTION_TYPE.CREDIT ? 'Credit' : 'Payment',
          ..._getEntityRelatedAttributes(state)
        })
      }
      break
    }
    case TransactionsActions.ADD_TRANSACTION_INITIATED: {
      const { entityType, entityId, source, type, amount } = action.payload
      if (source === 'settlement') {
        yield Mixpanel.trackWithPreset(
          `delete${
            entityType === ENTITY_TYPE.CUSTOMER ? 'customer' : 'supplier'
          }screen:settlement clicked`,
          {
            ..._getEntityRelatedAttributes(state),
            account_id: entityId,
            merchant_id: state.Dashboard.merchantProfile.data?.id,
            Screen: 'Relationship Profile',
            TxType: type === TRANSACTION_TYPE.CREDIT ? 'Credit' : 'Payment',
            Amount: amount
          }
        )
      }
      break
    }
    case TransactionsActions.ADD_TRANSACTION_INTERACTED:
      yield Mixpanel.trackWithPreset(payload.interaction, {
        ..._getEntityRelatedAttributes(state),
        ..._getAddTransactionAttributes(state),
        Flow: 'Add Transaction',
        ...(payload.note ? { Content: payload.note } : {})
      })
      break
    case TransactionsActions.ADD_TRANSACTION_COMPLETED:
      yield Mixpanel.trackWithPreset('Click Add Transaction Confirm', {
        ..._getEntityRelatedAttributes(state),
        ..._getAddTransactionAttributes(state),
        Screen: 'Transaction',
        Notes: payload.note,
        Amount: payload.amount,
        'Bill Date': `${payload.date.day}/${payload.date.month - 1}/${payload.date.year}`
      })
      break
    case TransactionsActions.ADD_TRANSACTION_SUCCESS: {
      yield Mixpanel.trackWithPreset('Add Transaction Confirm', {
        ..._getEntityRelatedAttributes(state),
        ..._getAddTransactionAttributes(state),
        Screen: 'Transaction',
        'Bill Date': dayjs.unix(payload.bill_date).format('DD MMM YYYY'),
        Notes: payload.note,
        Amount: payload.amount,
        Transaction_id: payload.id
      })
      break
    }
    case TransactionsActions.DELETE_TRANSACTION_INITIATED:
      yield Mixpanel.trackWithPreset('Delete Transaction', {
        ..._getEntityRelatedAttributes(state),
        ..._getTransactionAttributes(state, payload),
        Screen: 'Relationship Profile'
      })
      break
    case TransactionsActions.DELETE_TRANSACTION_ABORTED:
      yield Mixpanel.trackWithPreset('Delete Transaction Cancel', {
        ..._getEntityRelatedAttributes(state),
        ..._getTransactionAttributes(state, payload),
        Screen: 'Relationship Profile'
      })
      break
    case TransactionsActions.DELETE_TRANSACTION_COMPLETED:
      yield Mixpanel.trackWithPreset('Delete TxScreen : Confirm', {
        ..._getEntityRelatedAttributes(state),
        ..._getTransactionAttributes(state, payload),
        Screen: 'Relationship Profile'
      })
      break
    case TransactionsActions.SEND_REMINDER_TO_CUSTOMER: {
      yield Mixpanel.trackWithPreset('Send Reminder', {
        ..._getEntityRelatedAttributes(state),
        Type: 'Whatsapp',
        merchant_id: action.payload.merchant.id,
        account_id: action.payload.customer.id,
        // Mobile: action.payload.customer.mobile,
        Screen: 'Relationship',
        Enabled: 'TRUE'
      })
      break
    }
    case TransactionsActions.SEND_REMINDER_TO_SUPPLIER: {
      yield Mixpanel.trackWithPreset('Send Reminder', {
        ..._getEntityRelatedAttributes(state),
        Type: 'Whatsapp',
        merchant_id: action.payload.merchant.id,
        account_id: action.payload.supplier.id,
        // Mobile: action.payload.merchant.mobile,
        Screen: 'Relationship',
        Enabled: 'TRUE'
      })
      break
    }
    case TransactionsActions.SELECT_ENTITY: {
      yield Mixpanel.trackWithPreset('View Relationship', {
        ..._getEntityRelatedAttributes(state),
        Screen: 'Relationship Profile',
        merchant_id: merchantId,
        List: false
      })
      break
    }
    case TransactionsActions.FETCH_REPORT_TRANSACTIONS: {
      if (payload.source === TransactionSource.REPORT && payload.filter.startTime === 0) {
        yield Mixpanel.trackWithPreset('acct_report_click', {
          ..._getEntityRelatedAttributes(state),
          'Collection Adopted': 'en'
        })
      }
      break
    }
    case TransactionsActions.FETCH_REPORT_TRANSACTIONS_SUCCESS: {
      if (payload.source === TransactionSource.REPORT) {
        const { filter, data }: { filter: ITransactionFilter; data: ITransactionsReport } = payload
        yield Mixpanel.trackWithPreset(
          filter?.startTime === 0 ? 'acct_report_preview_load' : 'acct_report_date_update',
          {
            ..._getEntityRelatedAttributes(state),
            ..._getTransactionReportAttributes(filter),
            no_result: !data.transactions.length,
            'Collection Adopted': 'en',
            Screen: `${
              state.Transactions.currentEntity?.type === ENTITY_TYPE.CUSTOMER
                ? 'Customer'
                : 'Supplier'
            } report Screen`
          }
        )
      }
      break
    }
    case TransactionsActions.GENERATE_TRANSACTIONS_REPORT: {
      const report = state.Transactions.currentTransactionReport
      let eventName = ''
      switch (payload.sourceAction) {
        case TRANSACTION_REPORT_ACTION.PRINT:
          eventName = 'acct_report_print'
          break
        case TRANSACTION_REPORT_ACTION.DOWNLOAD:
          eventName = 'acct_report_download'
          break
        case TRANSACTION_REPORT_ACTION.SHARE:
          eventName = 'Send Report'
      }
      yield Mixpanel.trackWithPreset(eventName, {
        ..._getEntityRelatedAttributes(state),
        ..._getTransactionReportAttributes(payload.filter),
        'Due Amount': (report.credit - report.payment).toFixed(2),
        'Collection Adopted': 'en',
        Screen: `${
          state.Transactions.currentEntity?.type === ENTITY_TYPE.CUSTOMER ? 'Customer' : 'Supplier'
        } report Screen`
      })
      break
    }
    default: {
      return
    }
  }
  return
}
