import {
  COLLECTION_LIST_TAB,
  COLLECTION_LIST_TYPE_TAB,
  ICollectionListState
} from './collectionList.types'

export const CollectionListState: ICollectionListState = {
  listSummary: undefined,
  listSummaryInvoice: undefined,

  allCollectionList: [],
  summaryCollectionList: [],
  collectionList: [],
  selectedCollectionList: null,
  filtersCollectionList: {},
  sortCollectionList: {},
  createNewList: null,
  selectedIdsToShiftAccount: [],
  selectedInvoicesToShift: [],
  selectedSettingsForAddTxn: null,
  selectedDataForTxnAdvanceAndRemove: null,
  helpers: {},

  documentsList: [],

  loaders: {
    collectionList: true,
    fetchDocumentsList: true,
    allPendingActions: false
  },
  drawers: {},
  pagination: null,
  currentDocumentSelected: null,
  selectedReplacementRecords: null,
  selectedTxnToRemove: null,
  collectionListSelected: [],
  templatesList: [],
  globalTemplatesList: [],
  currentTemplates: [],
  accountsDocument: null,
  collectionListBills: undefined,
  selectedCollectionListTypeTab: COLLECTION_LIST_TYPE_TAB.INVOICE,
  selectedListTab: COLLECTION_LIST_TAB.SUPPLY_LIST,
  combinedCollectionList: [],
  staffMemberList: null,
  allPendingActions: null
}
