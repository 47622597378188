import { takeLatest } from 'redux-saga/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import { UploadSheetActions, handleDrawerToggle } from './uploadSheet.actions'
import {
  fetchDocumentsListEffect,
  collectionListFromDocumentEffect,
  fetchTemplatesListEffect,
  fetchDefaultTemplateEffect,
  createNewTemplateEffect,
  updateDefaultTemplateEffect,
  addToMultipleCollectionListFromDocumentEffect,
  deleteUploadedFileWithDataEffect
} from './uploadSheet.effect'

function* fetchDocumentsListSaga() {
  yield takeLatest(UploadSheetActions.FETCH_DOCUMENTS_LIST, fetchDocumentsListEffect)
}

function* collectionListFromDocumentSaga() {
  yield takeLatest(
    UploadSheetActions.COLLECTION_LIST_FROM_DOCUMENT,
    collectionListFromDocumentEffect
  )
}

function* multipleCollectionListFromDocumentSaga() {
  yield takeLatest(
    UploadSheetActions.ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT,
    addToMultipleCollectionListFromDocumentEffect
  )
}

function* fetchTemplatesListSaga() {
  yield takeLatest(UploadSheetActions.FETCH_TEMPLATES_LIST, fetchTemplatesListEffect)
}

function* fetchDefaultTemplateSaga() {
  yield takeLatest(UploadSheetActions.FETCH_DEFAULT_TEMPLATE, fetchDefaultTemplateEffect)
}

function* createNewTemplateSaga() {
  yield takeLatest(UploadSheetActions.CREATE_TEMPLATE, createNewTemplateEffect)
}

function* updateDefaultTemplateSaga() {
  yield takeLatest(UploadSheetActions.UPDATE_DEFAULT_TEMPLATE, updateDefaultTemplateEffect)
}

function* deleteUploadedFileWithDataSaga() {
  yield takeCustomLatest(
    UploadSheetActions.DELETE_UPLOADED_FILE_WITH_DATA,
    deleteUploadedFileWithDataEffect,
    () => handleDrawerToggle({ deleteUploadedFile: false })
  )
}

export const UploadSheetWatchers = [
  fetchDocumentsListSaga(),
  collectionListFromDocumentSaga(),
  fetchTemplatesListSaga(),
  fetchDefaultTemplateSaga(),
  createNewTemplateSaga(),
  updateDefaultTemplateSaga(),
  multipleCollectionListFromDocumentSaga(),
  deleteUploadedFileWithDataSaga()
]
