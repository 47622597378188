import { getEnvConfig } from 'utils/getEnvConfig'
import {
  IPendingDueOverallSummaryRequestPayload,
  IPenddingDueSummaryRequestPayload,
  IPendingDueGroupIdDataRequestPayload,
  IPendingDueOverallGroupInnerDataRequestPayload
} from 'state/pendingDueSummary/pendingDueSummary.types'
import ApiService from './ApiService'
import { apiRequestInterceptor, businessIdInterceptor } from './apiInterceptor'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

let endpoint: string = ''
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    endpoint = getEnvConfig('STAFF_LINK_BASE_URL')
  } else {
    endpoint = `${window.location.origin}/api/staff-link`
  }
}

const api = new ApiService(endpoint, headers)
api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))

const FETCH_PENDING_DUE_SUMMARY = '/v1/GetPendingDueSummary'
const FETCH_PENDING_DUE_INVOICES = '/v1/GetPendingDueSummaryDownload'
const FETCH_PENDING_DUE_GROUP_ID = '/v1/GetPendingDueSummaryDetail'
const DOWNLOAD_PENDING_DUE_GROUP_ID = '/v1/GetPendingDueSummaryDetailDownload'
const FETCH_PENDING_DUE_OVERALL_SUMMARY = '/v1/GetBusinessPendingDueSummary'
const FETCH_PENDING_DUE_OVERALL_INNER = '/v1/GetBusinessPendingDueSummaryDetails'

export const fetchPendingDueGroups = (payload: IPenddingDueSummaryRequestPayload) => {
  return api.postRequest(FETCH_PENDING_DUE_SUMMARY, payload)
}

export const fetchAllPendingDueInvoices = (payload: { end_time: number }) => {
  return api.postRequest(FETCH_PENDING_DUE_INVOICES, payload)
}

export const fetchPendingDueForGroupId = (payload: IPendingDueGroupIdDataRequestPayload) => {
  return api.postRequest(FETCH_PENDING_DUE_GROUP_ID, payload)
}

export const downloadPendingDueForGroupId = (payload: IPendingDueGroupIdDataRequestPayload) => {
  return api.postRequest(DOWNLOAD_PENDING_DUE_GROUP_ID, payload)
}

export const fetchPendingDueOverallGroups = (payload: IPendingDueOverallSummaryRequestPayload) => {
  return api.postRequest(FETCH_PENDING_DUE_OVERALL_SUMMARY, payload)
}

export const fetchPendingDueForOverallInnerGroup = (
  payload: IPendingDueOverallGroupInnerDataRequestPayload
) => {
  return api.postRequest(FETCH_PENDING_DUE_OVERALL_INNER, payload)
}
