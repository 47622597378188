import * as Effects from 'redux-saga/effects'
import { API_RESPONSE_TYPE, IApiResponse } from '../../constants'
import { Action } from './OrderQR.types'
import {
  setMerchantDetails,
  setUpdateUpiSuccess,
  setShippingAddress,
  setKycStatus,
  setBankAccountStatus,
  setCurrentLimit,
  setPageErrorState,
  fetchedAddress,
  confirmOrderStatus,
  SetMerchantProfileData,
  setMerchantRetrieveSubscription,
  setMerchantCreateSubscription,
  GetQrVpa,
  SetQrVpa
} from './OrderQR.actions'
import { OrderQRApi } from './OrderQR.apis'

const put: any = Effects.put
const call: any = Effects.call

export function* handleGetUpiId(action: Action<string>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.getUpiId, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setBankAccountStatus(res.data.status.toString()))
      yield put(setMerchantDetails(res.data.destination))
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleUpdateUpiId(action: Action<string>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.setUpi, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setUpdateUpiSuccess(true))
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleGetAddress(action: Action<string>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.getShippingAddress, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(fetchedAddress(true))
      if (res.data.shop_number) {
        yield put(confirmOrderStatus(true))
        yield put(setShippingAddress(res.data))
      }
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleGetKycStatus(action: Action<string>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.getKycStatus, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      if (res.data.kyc_status === 'COMPLETE') {
        yield put(setKycStatus(true))
      } else {
        yield put(setCurrentLimit(res.data.trial_limit.total_limit))
      }
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleConfirmOrder(action: Action<object>) {
  try {
    console.log(action?.payload)
    const res: IApiResponse = yield call(OrderQRApi.addAddress, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(confirmOrderStatus(true))
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleGetMerchantProfile(action: Action<object>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.getMerchantProfile, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(SetMerchantProfileData(res.data))
    } else {
      yield put(setPageErrorState(res?.data?.message || 'Error'))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleGetRetrieveSubscription(action: Action<object>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.getRetrieveSubscription, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setMerchantRetrieveSubscription(res.data))
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleGetCreateSubscription(action: Action<object>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.getCreateSubscription, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield call(redirectToPaymentPage, res.data.link_id)
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

export function* handleGetQrVpa(action: Action<object>) {
  try {
    const res: IApiResponse = yield call(OrderQRApi.getQrVpa, action?.payload)
    if (res.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(SetQrVpa(res.data))
    } else {
      yield put(setPageErrorState(res.data.message))
    }
  } catch (error) {
    yield put(setPageErrorState(error))
  }
}

function redirectToPaymentPage(link_id: string) {
  if (window?.location) {
    const { origin } = window.location
    if (origin.includes('localhost') || origin.includes('staging')) {
      window.location.href = `https://payments-web.staging.okcredit.in/pay/${link_id}`
    } else {
      window.location.href = `https://payments-web.okcredit.in/pay/${link_id}`
    }
  }
}
