import React, { ReactNode } from 'react'
import { DOCUMENT_TYPE } from 'state/new-summary/uploadSheet/uploadSheet.types'
import { DOCUMENT_TYPE_VS_LABEL } from '../constants'
import { TemplateItemStyles } from './styles'

const TemplateItem = ({
  name,
  documentType,
  onClick,
  children
}: {
  name: string
  documentType: DOCUMENT_TYPE
  onClick?: () => void
  children: ReactNode
}) => {
  const classes = TemplateItemStyles()

  return (
    <div className={classes.listItem} onClick={onClick}>
      <div>
        {name}{' '}
        <span className={classes.extension}>
          ({DOCUMENT_TYPE_VS_LABEL[documentType as DOCUMENT_TYPE.EXCEL | DOCUMENT_TYPE.CSV]})
        </span>
      </div>
      {children}
    </div>
  )
}

export default TemplateItem
