/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put, select } from '@redux-saga/core/effects'
import { Action } from 'infra/types'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { IAppState } from 'infra/AppState'
import {
  getAllBeats,
  createBeatRoute,
  getAllInvoiceBeats,
  linkDeLinkInvoiceBeats
} from 'services/Api/StafflinkApi'
import { API_RESPONSE_TYPE, IApiResponse } from '../../constants'
import {
  createBeatFailure,
  createBeatSuccess,
  getInvoiceBeatsSuccess,
  getBeatsSuccess,
  handleDrawerToggle,
  linkDelinkInvoiceBeatsSuccess
} from './beats.actions'
import { formatInvoiceBeats } from './beats.helpers'
import { ILinkDelinkInvoiceBeatsPayload } from './beats.types'

export function* getBeatsEffect() {
  try {
    const response: IApiResponse = yield call(getAllBeats)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getBeatsSuccess(response?.data))
    } else {
      console.log()
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e?.message
      })
    )
  }
}

export function* createBeatRouteEffect(action: Action<{ beats?: string[]; routes?: string[] }>) {
  try {
    const { beats, routes } = action.payload
    const { Dashboard } = yield select((app: IAppState) => app)
    const merchantId: string = Dashboard?.merchantProfile?.data?.id
    const response: IApiResponse = yield call(createBeatRoute, {
      business_id: merchantId,
      beats,
      routes
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(createBeatSuccess(response?.data))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Beat created successfully.'
        })
      )
    } else {
      yield put(createBeatFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not create the beat. Please try again later.'
        })
      )
    }
  } catch (e: any) {
    yield put(createBeatFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e?.message
      })
    )
  }
}

export function* getInvoiceBeatsEffect(
  action: Action<{ transactionId: string; accountId: string }>
) {
  try {
    const { transactionId, accountId } = action.payload
    const { Dashboard } = yield select((app: IAppState) => app)
    const merchantId: string = Dashboard?.merchantProfile?.data?.id

    const response: IApiResponse = yield call(getAllInvoiceBeats, {
      business_id: merchantId,
      account_id: accountId,
      account_transaction_id: transactionId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getInvoiceBeatsSuccess(formatInvoiceBeats(response?.data?.bill_beats)))
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e?.message
      })
    )
  }
}

export function* linkDelinkInvoiceBeatsEffect(action: Action<ILinkDelinkInvoiceBeatsPayload>) {
  try {
    const response: IApiResponse = yield call(linkDeLinkInvoiceBeats, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(linkDelinkInvoiceBeatsSuccess(response?.data?.bill_beats))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Updated beat(s) successfully.'
        })
      )
      yield put(handleDrawerToggle({ invoiceBeatSelection: false }))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not update beat(s). Please try again later.'
        })
      )
      return
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e?.message
      })
    )
  }
}
