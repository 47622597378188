import { Action } from 'infra/types'
import { createEditCustomerActions } from './create-edit-customer.actions'
import { ICreateEditCustomerState } from './create-edit-customer.types'

const INTIAL_STATE: ICreateEditCustomerState = {
  drawers: {
    showCreateEditCustomerDrawer: false
  },
  loaders: {
    isCreatingCustomer: false
  }
}

export const CreateEditCustomerReducer = (
  state: ICreateEditCustomerState = INTIAL_STATE,
  action: Action<any>
): ICreateEditCustomerState => {
  switch (action.type) {
    case createEditCustomerActions.SET_DRAWER: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.drawerName]: action.payload.status
        }
      }
    }
    case createEditCustomerActions.SET_DRAWER_WITH_EDIT_DETAILS: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          showCreateEditCustomerDrawer: true
        },
        editCustomerDetails: action.payload.editCustomerDetails
      }
    }
    case createEditCustomerActions.SET_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.payload.loaderName]: action.payload.status
        }
      }
    }
    case createEditCustomerActions.RESET_CUSTOMER_DETAILS: {
      return {
        ...state,
        editCustomerDetails: {
          customerId: '',
          customerName: '',
          customerMobile: '',
          customerAddress: '',
          customerCode: '',
          customerGst: ''
        }
      }
    }
    default: {
      return state
    }
  }
}
