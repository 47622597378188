import { IHelpSection, IHelpItems, IInstructions } from '../dashboard.types'

export const getFormattedHelpSection = (helpSection: any): IHelpSection => {
  return {
    id: helpSection.id,
    title: helpSection.title,
    icon: helpSection.icon,
    displayType: helpSection.display_type,
    helpItems:
      (Array.isArray(helpSection.help_items) &&
        helpSection.help_items.map(
          (item: any): IHelpItems => ({
            id: item.id,
            title: item.title,
            subTitle: item.sub_title,
            videoType: item.video_type,
            videoUrl: item.video_url,
            instructions:
              (Array.isArray(item.instructions) &&
                item.instructions.map(
                  (instruction: any): IInstructions => ({
                    id: instruction.id,
                    title: instruction.title,
                    type: instruction.type,
                    imageUrl: instruction.image_url
                  })
                )) ||
              []
          })
        )) ||
      []
  }
}
