import { v4 as uuidv4 } from 'uuid'
import { getEnvConfig } from 'utils/getEnvConfig'
import {
  ICreateBusinessChargeAction,
  ICreateBusinessDiscountAction,
  IFetchBillsRequestPayload,
  IRequestPayload,
  InventoryItemUploadType
} from 'state/billing/billing.types'
import ApiService from '../ApiService'
import {
  apiRequestInterceptor,
  businessIdInterceptor,
  apiResponseSuccessInterceptor,
  apiResponseErrorInterceptor
} from '../apiInterceptor'
import { IApiResponse } from '../../../constants'
import {
  BillByIdResponse,
  CREATE_BILL_RESPONSE,
  FETCH_BILL_RESPONSE,
  FETCH_INVENTORY_ITEMS_RESPONSE,
  INVENTORY_ITEMS_OPERATION_REQUEST_PAYLOAD,
  INVENTORY_ITEM_REQUEST_TYPE,
  INVENTORY_OPERATION_TYPE,
  FETCH_BUSINESS_DISCOUNTS_RESPONSE,
  ITEMS_OPERATION_REQUEST_PAYLOAD,
  ITEMS_OPERATION_RESPONSE_TYPE,
  InventoryItemLogsResponseType,
  InventoryItemsResponseType,
  OPERATION_TYPE,
  PAYLOAD_ITEMS_TYPE,
  FETCH_BUSINESS_CHARGES_RESPONSE
} from './type'

const FETCH_ALL_ITEMS = '/web/stock/v1/list-catalog'
const CATALOGUE_ITEM_OPERATIONS = '/web/stock/v1/sync-catalog-operations'
const INVENTORY_ITEM_OPERATIONS = 'web/stock/v1/sync-inventory-operation'
const UPLOAD_INVENTORY_ITEMS = '/web/stock/v1/inventory-upload'
const UPLOAD_BILL = '/web/v3/bill'
const FETCH_SALES_BILLS = '/web/v3/fetch-sale-bills'
const SHARE_BILLING_FEEDBACK = '/web/v1/feedback'
const FETCH_BILL_BY_ID = '/web/v1/bill'
const UPDATE_BILL_BY_ID = '/web/v1/update-bill'
const FETCH_INVENTORY_ITEM = '/web/stock/v2/list-inventory'
const FETCH_INVENTORY_UNIT = 'web/stock/v1/business-catalog-units'
const FETCH_INVENTORY_ITEM_OFFER = '/web/stock/v1/list-offer'
const INVENTORY_ITEM_LOGS = '/web/stock/v2/inventory-item-logs'
const FETCH_DISCOUNTS = 'web/v1/business-bill-discounts'
const CREATE_BUSINESS_DISCOUNT = 'web/v1/business-bill-discount'
const FETCH_CHARGES = 'web/v1/business-bill-charges'
const CREATE_BUSINESS_CHARGE = 'web/v1/business-bill-charge'
const CREATE_CREDIT_NOTE = 'web/v1/credit-note'
const CREATE_OFFER = 'web/stock/v1/create-offer'
const APPLY_OFFER = 'web/stock/v1/inventory-offer-map'
const DELETE_OFFER = 'web/stock/v1/delete-offers'
const CREATE_CATALOG_UNIT = 'web/stock/v1/create-catalog-unit'
const FETCH_CREDIT_NOTES = '/web/v1/get-credit-notes'
const FETCH_PREVIEW_CREDIT_NOTES_ENTITY = '/web/v1/preview/get-credit-notes'
const GET_BUSINESS_SETTINGS = '/unauth/v1/business-settings'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('BILLING_ENDPOINT') || ''

const api = new ApiService(endpoint, headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const fetchBusinessSettingsApi = ({ merchantId }: { merchantId: string }) => {
  return api.getRequest(`${GET_BUSINESS_SETTINGS}?bid=${merchantId}`, {})
}

export const createCatalogUnit = (payload: { unit: string }): Promise<IApiResponse> => {
  return api.postRequest(CREATE_CATALOG_UNIT, payload)
}

export const createOffers = (payload: any) => {
  return api.postRequest(CREATE_OFFER, payload)
}

export const applyOfferOnItem = (payload: any) => {
  return api.postRequest(APPLY_OFFER, payload)
}

export const deleteOffer = (payload: { offer_ids: string[] }) => {
  return api.postRequest(DELETE_OFFER, payload)
}

export const fetchAllItems = () => {
  return api.getRequest(FETCH_ALL_ITEMS, {})
}

export const fetchInventoryItems = (): Promise<IApiResponse<FETCH_INVENTORY_ITEMS_RESPONSE>> => {
  return api.getRequest(FETCH_INVENTORY_ITEM, {})
}

export const fetchInventoryUnitApi = (): Promise<IApiResponse<{ units: string[] }>> => {
  return api.getRequest(FETCH_INVENTORY_UNIT, {})
}

export const fetchInventoryItemsOffers = (): Promise<IApiResponse<any>> => {
  return api.getRequest(FETCH_INVENTORY_ITEM_OFFER, {})
}

export const catalogueOperations = ({
  operation,
  item
}: {
  operation: OPERATION_TYPE
  item: PAYLOAD_ITEMS_TYPE | { item_id: string }
}): Promise<IApiResponse<ITEMS_OPERATION_RESPONSE_TYPE>> => {
  const payload: ITEMS_OPERATION_REQUEST_PAYLOAD = {
    op_type: operation,
    item
  }

  return api.postRequest(CATALOGUE_ITEM_OPERATIONS, payload)
}

export const inventoryOperations = ({
  operation,
  item
}: {
  operation: INVENTORY_OPERATION_TYPE
  item: INVENTORY_ITEM_REQUEST_TYPE
}): Promise<IApiResponse<{ item: InventoryItemsResponseType }>> => {
  const payload: INVENTORY_ITEMS_OPERATION_REQUEST_PAYLOAD = {
    request_id: uuidv4(),
    op_type: operation,
    item
  }

  return api.postRequest(INVENTORY_ITEM_OPERATIONS, payload)
}

export const uploadInventoryItemsApi = (payload: InventoryItemUploadType['formData']) => {
  return api.postRequest(UPLOAD_INVENTORY_ITEMS, payload, {
    'Content-Type': 'multipart/form-data'
  })
}

export const uploadBill = (
  payload: IRequestPayload
): Promise<IApiResponse<CREATE_BILL_RESPONSE>> => {
  return api.postRequest(UPLOAD_BILL, payload)
}

export const fetchBillsV3 = (
  payload: IFetchBillsRequestPayload
): Promise<IApiResponse<FETCH_BILL_RESPONSE>> => {
  return api.postRequest(FETCH_SALES_BILLS, payload)
}

export const fetchBusinessDiscounts = (): Promise<
  IApiResponse<FETCH_BUSINESS_DISCOUNTS_RESPONSE>
> => {
  return api.getRequest(FETCH_DISCOUNTS, {})
}

export const createBusinessDiscount = (
  payload: ICreateBusinessDiscountAction
): Promise<IApiResponse<{ success: boolean }>> => {
  return api.postRequest(CREATE_BUSINESS_DISCOUNT, payload)
}

export const fetchBusinessCharges = (): Promise<IApiResponse<FETCH_BUSINESS_CHARGES_RESPONSE>> => {
  return api.getRequest(FETCH_CHARGES, {})
}

export const createBusinessCharge = (
  payload: ICreateBusinessChargeAction
): Promise<IApiResponse<{ success: boolean }>> => {
  return api.postRequest(CREATE_BUSINESS_CHARGE, payload)
}

export const shareFeedback = (payload: string): Promise<IApiResponse<{ success: boolean }>> => {
  return api.postRequest(SHARE_BILLING_FEEDBACK, { feedback: payload })
}

export const fetchBillByIdApi = (billId: string): Promise<IApiResponse<BillByIdResponse>> => {
  return api.getRequest(`${FETCH_BILL_BY_ID}?id=${billId}`, {})
}

export const updateBillApi = (
  payload: IRequestPayload
): Promise<IApiResponse<BillByIdResponse>> => {
  return api.postRequest(UPDATE_BILL_BY_ID, payload)
}

export const createCreditNoteApi = (payload: any) => {
  return api.postRequest(CREATE_CREDIT_NOTE, payload)
}

export const inventoryItemLogsApi = (
  itemId: string
): Promise<IApiResponse<InventoryItemLogsResponseType>> => {
  return api.getRequest(`${INVENTORY_ITEM_LOGS}?item_id=${itemId}`, {})
}

export const fetchCreditNoteListApi = (payload: {
  business_id: string
  filter_details: {
    page: number
    per_page: number
    sort_by?: string
    SearchString?: string
    customer_ids?: string[]
  }
}): Promise<IApiResponse<any>> => {
  return api.postRequest(FETCH_CREDIT_NOTES, payload)
}

export const getStaffBusinessSettingsApi = (payload: string) => {
  const api2 = new ApiService(endpoint, headers)
  return api2.getRequest(`${GET_BUSINESS_SETTINGS}?bid=${payload}`, {})
}
