/* eslint-disable security/detect-object-injection */
import { Action } from 'infra/types'
import { IDeviceState } from './device.types'
import { DeviceState } from './device.state'
import { DeviceActions } from './device.actions'

export const DeviceReducer = (
  state: IDeviceState = DeviceState,
  action: Action<any>
): IDeviceState => {
  switch (action.type) {
    case DeviceActions.UPDATE_DEVICE_TYPE: {
      return {
        ...state,
        isMobile: action.payload
      }
    }
    default: {
      return state
    }
  }
}
