import { IExpState } from './experiment.types'

const experimentKeyState = {
  ack_required: false,
  name: '',
  status: 1,
  variant: '',
  vars: {}
}

export const ExpState: IExpState = {
  ab: {
    loading: false,
    error: null,
    ready: false,
    collectionListOfInvoicesV1: false,
    clViewV2: false,
    clQuickSummary: false,
    collectionListSummaryV2: false,
    dynamicStatements: false,
    allowAppControls: false,
    tallyConnect: false,
    paidSoonBanner: false,
    okTermLoanAppBlock: false,
    returnItemsV1: false,
    staffLink: false,
    okDebitOrderManagement: false,
    billingWeb: false,
    editBillTransactionWeb: false,
    cashDeposit: false,
    onDemandDistribution: false,
    downtime: false
  },
  experiment: {
    'activation_web-all-paid_soon_banner': experimentKeyState
  }
}
