import { takeLatest } from 'redux-saga/effects'
import { takeCustomLatest } from 'state/common/common.effects'
import { getAbProfileSagaEffects, getStaffBusinessSettingsEffects } from './experiment.effects'
import { ExpActions } from './experiment.action'

function* getAbProfileSaga() {
  yield takeLatest(ExpActions.GET_AB_PROFILE_REQUEST, getAbProfileSagaEffects)
}

function* getStaffBusinessSettingsSaga() {
  yield takeCustomLatest(ExpActions.GET_STAFF_BUSINESS_SETTINGS, getStaffBusinessSettingsEffects)
}

export const ExperimentWatchers = [getAbProfileSaga(), getStaffBusinessSettingsSaga()]
