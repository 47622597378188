import { BoldArrowDownIcon, BoldArrowUpIcon } from '@okcredit/web-lib'
import dayjs from 'dayjs'
import { utils, writeFileXLSX } from 'xlsx'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import {
  ICustomerTransaction,
  ISupplierTransaction,
  ITransactionsReport,
  TRANSACTION_STATE,
  TRANSACTION_TYPE
} from 'state/transaction/transaction.types'
import { IDateFilter } from 'components/DateFilter/DateFilter.constants'
import { formatCurrency } from 'utils/formatCurrency'

export const customerTransactionTableColumns = [
  {
    id: 'date',
    label: 'Date'
  },
  {
    id: 'note',
    label: 'Notes'
  },
  {
    id: 'payment',
    label: 'Payments',
    icon: BoldArrowDownIcon
  },
  {
    id: 'credit',
    label: 'Credits',
    icon: BoldArrowUpIcon
  },
  {
    id: 'balance',
    label: 'Balance'
  }
]

export const supplierTransactionTableColumns = [
  {
    id: 'date',
    label: 'Date'
  },
  {
    id: 'note',
    label: 'Notes'
  },
  {
    id: 'credit',
    label: 'Credits',
    icon: BoldArrowDownIcon
  },
  {
    id: 'payment',
    label: 'Payments',
    icon: BoldArrowUpIcon
  },
  {
    id: 'balance',
    label: 'Balance'
  }
]

const formatData = (data: any[]) => {
  return data.map((item) => ({
    Date: dayjs.unix(Number(item.billedTime)).format('DD MMM, hh:mm a'),
    Notes: item.note,
    Payment: item.transactionType === TRANSACTION_TYPE.PAYMENT ? item.amount : '',
    Credit: item.transactionType === TRANSACTION_TYPE.CREDIT ? item.amount : '',
    'Balance (₹)': `${Number(item.outStandingAmount || 0).toFixed(2)}`
  }))
}

export const getExcelHeading = (activeFilter: IDateFilter, title: string): string => {
  const formatDate = (timestamp: number) => dayjs.unix(timestamp).format('DD MMM, YYYY')

  if (activeFilter.label === 'Today' && activeFilter.startTime) {
    return `${title} (${formatDate(activeFilter.startTime)})`
  }
  if (activeFilter.label === 'Overall' && activeFilter.endTime) {
    return `${title} (till ${formatDate(activeFilter.endTime)})`
  }
  if (activeFilter.startTime && activeFilter.endTime) {
    return `${title} (${formatDate(activeFilter.startTime)} - ${formatDate(activeFilter.endTime)})`
  }
  return title
}

export const addOutstandingAmountToTransactions = (
  mergedTransactionListArr: (ICustomerTransaction | ISupplierTransaction)[] = [],
  accountDetailsObj: { net_balance: any }
) => {
  if (mergedTransactionListArr.length === 0) {
    return []
  }
  const mergedTransactionList = JSON.parse(JSON.stringify(mergedTransactionListArr))
  mergedTransactionList[mergedTransactionList.length - 1].outStandingAmount =
    accountDetailsObj.net_balance
  let pendingBal = accountDetailsObj.net_balance
  for (let i = mergedTransactionList.length - 1; i > 0; i--) {
    const amount =
      mergedTransactionList[i].transactionType === TRANSACTION_TYPE.PAYMENT
        ? mergedTransactionList[i].amount
        : mergedTransactionList[i].amount * -1
    mergedTransactionList[i - 1].outStandingAmount =
      mergedTransactionList[i].outStandingAmount + amount
    pendingBal += amount
  }
  pendingBal +=
    mergedTransactionList[0].transactionType === TRANSACTION_TYPE.PAYMENT
      ? mergedTransactionList[0].amount
      : mergedTransactionList[0].amount * -1
  mergedTransactionList[0].outStandingAmount = pendingBal
  return mergedTransactionList
}

export const addOutstandingAmtToReport = (
  transaction: ISupplierTransaction | ICustomerTransaction,
  outstandingAmount: number
) => {
  if (!transaction.deleted) {
    if (transaction.transactionState !== TRANSACTION_STATE.PROCESSING) {
      outstandingAmount =
        transaction.transactionType === TRANSACTION_TYPE.PAYMENT
          ? outstandingAmount + transaction.amount
          : outstandingAmount - transaction.amount
    }
  }
  return outstandingAmount
}

export const sortByBillDateAndCreatedTime = (
  arr: (ISupplierTransaction | ICustomerTransaction)[]
) => {
  return arr.sort((a, b) => {
    const billDateA = new Date(a.billedTime)
    const billDateB = new Date(b.billedTime)

    if (billDateA < billDateB) {
      return -1
    } else if (billDateA > billDateB) {
      return 1
    }
    // Same billed date, sort by created time (assuming created time is a date object)
    return a.createdTime - b.createdTime
  })
}

export const downloadExcel = async ({
  fileName,
  transactions,
  filterTime,
  transactionsReport
}: {
  fileName: string
  transactions: ((ICustomerTransaction | ISupplierTransaction) & { outStandingAmount: number })[]
  filterTime: any
  transactionsReport: ITransactionsReport
}) => {
  const data = formatData(transactions)

  const excelHeading = getExcelHeading(filterTime, 'Report')
  const balanceLabel =
    filterTime.startTime && filterTime.endTime
      ? `Balance ${dayjs.unix(filterTime.startTime).format('DD MMM, YYYY')} - ${dayjs
          .unix(filterTime.endTime)
          .format('DD MMM, YYYY')}`
      : 'Net Balance Due'
  const balanceAmount = `₹ ${formatCurrency(
    Math.abs(transactionsReport.payment - transactionsReport.credit)
  )}`

  const worksheetData = [
    [excelHeading],
    [],
    [`Downloaded On: ${dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')}`],
    [],
    [
      `Total Payments (${transactionsReport.paymentCount})`,
      `Total Credits (${transactionsReport.creditCount})`,
      balanceLabel
    ],
    [
      `₹ ${formatCurrency(transactionsReport.payment)}`,
      `₹ ${formatCurrency(transactionsReport.credit)}`,
      balanceAmount
    ],
    data
  ]

  const wb = utils.book_new()
  const ws = utils.json_to_sheet(data, { origin: 'A10' })
  utils.sheet_add_aoa(ws, worksheetData, { origin: 'A1' })
  ws['!cols'] = getMaxColumnWidths(data)
  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)
}
