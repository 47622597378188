import { createUseStyles } from 'react-jss'
import { THEME } from 'components/theme'

export const UploadSupplySheetStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 16
  },
  infoContainer: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    backgroundColor: THEME.brandWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  uploadButton: {
    display: 'block',
    height: 40,
    boxSizing: 'border-box',
    border: `1px solid ${THEME.brandGreen}`,
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 20,
    background: THEME.brandGreen,
    color: THEME.brandWhite,
    padding: '9px 16px',
    cursor: 'pointer',
    '& > label': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    '& > label > input': {
      visibility: 'hidden',
      height: '0',
      width: '0'
    }
  },
  iconImg: {
    width: 48,
    height: 48
  }
})
