import { WORKFLOWS } from 'services/Api/StaffLinkApiFolder/type'
import { IUpdateRouteBeatRequest } from 'services/Api/types'
import { IFilterEntity, ILanguageEntity, ENTITY_TYPE } from 'shared/constants'
import { ICustomerProfileRowData } from 'state/dashboard/helpers/formatter'
import { RowSelectionType } from 'state/new-summary/list/list.types'

export interface IAccountSummary {
  balance: number
  customerCount: number
}

export interface IBroadcastAction {
  eventName: string
  properties?: {
    [key: string]: any
  }
}

export interface IHelpTopic {
  interaction: HELP_SECTION_INTERACTION
  id: string
}

export interface IEditedEntity {
  values: {
    name: string
    mobile: string
    address: string
    lang: ILanguageEntity
    txnAlertEnabled: boolean
    customerCode?: string
    customer_gst?: string
    supply_state_code?: string
  }
  errors: {
    name?: boolean
    mobile?: boolean
    address?: boolean
    customer_gst?: boolean
  }
}

export enum ENTITY_STATE {
  ACTIVE = 1,
  DELETED = 2,
  BLOCKED = 3
}

export enum ENTITY_ROLE {
  UNKNOWN = 0,
  SELLER = 1,
  BUYER = 2
}

export enum HELP_ITEM_METHOD {
  TEXT = 'text',
  FEEDBACK = 'feedback',
  VIDEO = 'video'
}

export enum HELP_SECTION_INTERACTION {
  OPEN = 'Open',
  CLOSED = 'Closed',
  STARTED = 'Started',
  THUMBS_UP = 'thumbs Up',
  THUMBS_DOWN = 'thumbs Down',
  SHARE_FEEDBACK = 'Share Feedback',
  SUBMIT_FEEDBACK = 'Submit Feedback',
  PAUSED = 'Paused',
  PLAY = 'Play'
}

export interface ISupplier {
  address: string
  balance: number
  createdAt: number
  id: string
  lang: ILanguageEntity
  mobile: string
  name: string
  profileImage: string
  isRegistered: boolean
  txnAlertEnabled: boolean
  entityType: ENTITY_TYPE
  edited: IEditedEntity
  state: ENTITY_STATE
  blockedBySupplier: boolean
  isAddTransactionRestricted: boolean
}

export interface ICustomer {
  customerGST: string
  accountUrl: string
  address: string
  balance: number
  balanceV2: string
  createdAt: number
  description: string
  email: string
  id: string
  lang: ILanguageEntity
  lastActivityAt: number
  lastPaymentAt: number
  mobile: string
  profileImage: string
  isRegistered: boolean
  userId: string
  txnAlertEnabled: boolean
  entityType: ENTITY_TYPE
  edited: IEditedEntity
  state: ENTITY_STATE
  blockedByCustomer: boolean
  isAddTransactionRestricted: boolean
  customerCode: string
  supply_state_code: string
}
export interface IEntityPayload {
  name: string
  mobile?: string
  fromContactList: boolean
  entityType: ENTITY_TYPE
}

export interface IMerchantProfileBusinessType {
  id: string
  name: string
  imageUrl: string
  title: string
  subTitle: string
}

export interface IMerchantProfileBusinessCategory {
  type: number
  id: string
  name: string
  imageUrl: string
  isPopular: boolean
  weight: number
}

export interface IMerchantProfile {
  address: string
  contactName: string
  email: string
  lang: string
  mobile: string
  name: string
  profileImage: string
  upiVpa: string
  id: string
  about: string
  businessTypeId: string
  categoryId: string
  businessGst?: string
}

export type BusinessManagerType = {
  created_at: string
  display_name: string
  mobile: string
  profile_image: string
  user_id: string
}

export interface IMerchantProfileEdited {
  values: {
    name?: string
    mobile?: string
    address?: string
    email?: string
    about?: string
    contactName?: string
    businessTypeId?: string
    categoryId?: string
    gst?: string
  }
  errors: {
    name?: boolean
    mobile?: boolean
    address?: boolean
    email?: boolean
    about?: boolean
    contactName?: boolean
    gst?: boolean
  }
}

interface IBaseEntities {
  allIds: string[]
  filtered?: string[]
  loading: boolean
}

export interface ISuppliers extends IBaseEntities {
  byIds: {
    [id: string]: ISupplier
  }
}

export interface ICustomers extends IBaseEntities {
  byIds: {
    [id: string]: ICustomer
  }
}

export interface IPhonebookContact {
  name: string
  mobile: string
}

export interface IInstructions {
  id: string
  title: string
  type: string
  imageUrl: string
}

export interface IHelpItems {
  id: string
  title: string
  subTitle: string
  videoType: string
  videoUrl: string
  instructions: IInstructions[]
  imgUrl?: string
  videoInIframe?: boolean
}

export interface IHelpSection {
  id: string
  title: string
  icon: string
  displayType: string
  helpItems: IHelpItems[]
}

export interface IEntityAction {
  loading: boolean
}

export interface IEntityEdit {
  entityType: ENTITY_TYPE
  id: string
}

export interface IAccountByBills {
  customerId: string
  billId: string
}

export interface IDashboardState {
  customers: ICustomers
  suppliers: ISuppliers
  entityAction: IEntityAction
  merchantRegistration: {
    loading: boolean
    show: boolean
    optedOut: boolean
  }
  merchantProfile: {
    loading: boolean
    data?: IMerchantProfile
    edited?: IMerchantProfileEdited
  }
  businessSettings: Record<string, any>
  businessTypes: IMerchantProfileBusinessType[]
  businessCategories: IMerchantProfileBusinessCategory[]
  filters: IFilterEntity[]
  currentTab: ENTITY_TYPE
  searchString: string
  phonebookContacts: {
    data: IPhonebookContact[]
    loading: boolean
  }
  helpSections: {
    data: IHelpSection[]
    loading: boolean
  }
  currentCustomer?: ICustomer | null
  showLeadgenBanner: boolean
  customersByTags: ICustomer[] | undefined
  tagsFilter: IFilterEntity[]
  searchFilters: IFilterEntity[]
  billsFiltered: Array<IAccountByBills> | undefined
  businessAccounts: {
    businessIds: string[]
    businessDetails?: Record<string, IMerchantProfile>
    individualUser?: IMerchantProfile
    permittedWorkflows?: WORKFLOWS[]
    asStaffBusinessIds: string[]
    asStaffBusinessDetails?: Record<string, IMerchantProfile>
    loading: boolean
    drawers: {
      newBusinessName: boolean
    }
  }
  paidSoonBanner: {
    isShow: boolean
    feedback: string
    isDone: boolean
  }
  accountsDocument: IAccountsDocument | null
  businessManagers: BusinessManagerType[]
  customerProfiles: {
    selectedCustomerIds?: string[]
    customers: ICustomerProfileRowData[]
    paginationData: {
      page: number
      total_items: number
      per_page: number
    }
    sort_by?: CUSTOMER_PROFILE_SORT_OPTION
    sort_descending?: boolean
    search_string?: string
    resetSelectedRowRef: {
      customersTable: RowSelectionType
    }
    drawers: ICustomerProfileDrawers
  }
  loaders: ILoaders
}

export interface ILoaders {
  bulkUploadCustomers?: boolean
  downloadCustomerProfiles?: boolean
}

export enum CustomerProfileBulkUpdateEntity {
  tag = 'tag',
  beat = 'beat',
  route = 'route'
}

export type ICustomerProfileDrawers = {
  [key in CustomerProfileBulkUpdateEntity]: { status: boolean; replace: boolean }
}

export interface IAccountsDocument {
  documentId: string
  document_status?: number
}

export interface IInitialLeadgenAction {
  queryString: string
  history?: {
    push: Function
  }
}

export interface PAID_SOON_BANNER_ACTION {
  CLOSE: 'CLOSE'
  SUBMIT: 'SUBMIT'
  START: 'START'
}

export enum CUSTOMER_PROFILE_SORT_OPTION {
  CUSTOMER_NAME = 'customer_name',
  CUSTOMER_MOBILE = 'customer_mobile',
  ADDED_ON = 'added_on'
}

export type addAccountBeatRoutePayload = {
  itemType: CustomerProfileBulkUpdateEntity.beat | CustomerProfileBulkUpdateEntity.route
  data: IUpdateRouteBeatRequest[]
}
