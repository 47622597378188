import { Action } from '../../infra/types'
import { NotificationActions } from './Notification.actions'
import { NotificationState } from './Notification.state'
import { INotificationState } from './Notification.types'

export const NotificationReducer = (
  state: INotificationState = NotificationState,
  action: Action<any>
): INotificationState => {
  switch (action.type) {
    case NotificationActions.ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      }
    }
    case NotificationActions.DELETE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter((notification) => {
          return notification.id !== action.payload
        })
      }
    }
    default: {
      return { ...state }
    }
  }
}
