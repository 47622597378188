import { createUseStyles } from 'react-jss'

export const style = createUseStyles({
  redDot: {
    backgroundColor: 'red',
    height: (props: { dotSize?: number }) => `${props.dotSize || 5}px`,
    width: (props: { dotSize?: number }) => `${props.dotSize || 5}px`,
    borderRadius: '50%',
    marginBottom: '-4px'
  },
  parent: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column'
  }
})
