import { AxiosRequestConfig } from 'axios'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { AuthLocalStoragerEnum } from 'experiments/StaffLink/StaffLink.constants'
import { LinkManager } from '../../utils'
import { AuthApi } from './AuthApi'

export const setNewTokensToLocalStorage = (
  accessToken: string,
  expiry: number,
  refreshToken: string
) => {
  const currentTime = new Date()
  const expiresIn = currentTime.getTime() + (expiry - 60) * 1000
  AuthStorageInstance.setAccessToken(accessToken)
  AuthStorageInstance.setExpiresIn(expiresIn)
  AuthStorageInstance.setRefreshToken(refreshToken)
}

export const apiRequestInterceptor = async (req: AxiosRequestConfig) => {
  try {
    console.log(
      '%c********* Intercepting Request:Success ***********',
      'font-weight:bold;color:white;background-color:black;'
    )
    const token = AuthStorageInstance.getAccessToken()
    if (token) {
      const tokenExpiry = AuthStorageInstance.getExpiresIn()
      const isTokenExpired = tokenExpiry ? tokenExpiry < new Date().getTime() : true
      if (isTokenExpired) {
        console.log(
          '%c********* Token Is Expired ***********',
          'font-weight:bold;color:tomato;background-color:white;'
        )
        const refreshToken = AuthStorageInstance.getRefreshToken()
        if (refreshToken) {
          const refreshApiResponse = await AuthApi.authRefreshToken(refreshToken)
          const { access_token, refresh_token, expires_in } = refreshApiResponse.data
          setNewTokensToLocalStorage(access_token, parseInt(expires_in), refresh_token)
          req.headers.Authorization = `Bearer ${access_token}`
          req.headers['content-type'] = 'application/json'
          console.log(
            '%c********* Token Refreshed Successfully ***********',
            'font-weight:bold;color:green;background-color:white;'
          )
        } else {
          // TODO(v0): Log user out
        }
      } else {
        console.log(
          '%c********* Token Not Expired ***********',
          'font-weight:bold;color:green;background-color:white;'
        )
        req.headers.Authorization = token ? `Bearer ${token}` : ''
      }
    } else {
      Mixpanel.reset()
      window.location.replace(LinkManager.getLoginLink())
    }
  } catch (err) {
    // TODO(v0): Log user out
    console.log('Something went wrong with req interceptor: ', err)
  }
  return req
}

export const apiRequestErrorInterceptor = async (_: any) => {
  console.log(
    '%c********* Intercepting Request:Error  ***********',
    'font-weight:bold;color:black;background-color:red;'
  )
}

export const apiResponseSuccessInterceptor = async (_: any) => {
  console.log(
    '%c********* Intercepting Response:Success ***********',
    'font-weight:bold;color:white;background-color:green;'
  )
  return _
}

export const apiResponseErrorInterceptor = async (error: any) => {
  console.log(
    '%c********* Intercepting Response:Error ***********',
    'font-weight:bold;color:black;background-color:pink;'
  )
  if (error.response.status === 401) {
    // In case of 401 unauthorised redirect to login screen
    console.log(
      '%c********* Intercepting 401 Response ***********',
      'font-weight:bold;color:black;background-color:red;'
    )
    AuthStorageInstance.clear()
    window.location.replace(LinkManager.getLoginLink())
  }
  return Promise.reject(error)
}

export const businessIdInterceptor = async (req: any) => {
  try {
    console.log(
      '%c********* Intercepting Request (Business Id):Success ***********',
      'font-weight:bold;color:white;background-color:black;'
    )
    const businessId = AuthStorageInstance.getCurrentBusinessId()
    if (businessId && !req.headers['OKC-Business-Id']) {
      req.headers['OKC-Business-Id'] = businessId
    }
  } catch (err) {
    console.log('Something went wrong with business-id req interceptor: ', err)
  }
  return req
}

export const staffBusinessIdInterceptor = async (req: any) => {
  try {
    console.log(
      '%c********* Intercepting Request (Business Id):Success ***********',
      'font-weight:bold;color:white;background-color:black;'
    )
    const staffBusinessId = localStorage?.getItem(AuthLocalStoragerEnum.staffLinkBusinessId)
    if (staffBusinessId && !req.headers['OKC-Business-Id']) {
      req.headers['OKC-Business-Id'] = staffBusinessId
    }
  } catch (err) {
    console.log('Something went wrong with business-id req interceptor: ', err)
  }
  return req
}
