import { Day } from '@hassanmojab/react-modern-calendar-datepicker'
import dayjs from 'dayjs'

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
export const convertEpochToDayTime = (epochTime: number) => {
  const date = new Date(Number(epochTime) * 1000)

  let hours = date.getHours()
  let minutes: string | number = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes

  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    hour: hours,
    minute: minutes,
    ampm
  }
}

export const convertEpochToDay = (epochTime: number) => {
  const date = new Date(Number(epochTime) * 1000)

  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    monthName: monthNames[date.getMonth()]
  }
}

export const convertDayToEpoc = (date: Day) => {
  return dayjs(new Date(date.year, date.month - 1, date.day))
    .endOf('day')
    .unix()
}

export const convertDayToMilliSecondsStart = (date: Day) => {
  return dayjs(new Date(date.year, date.month - 1, date.day))
    .startOf('day')
    .valueOf()
}

export const convertMillisecondsToDay = (milliseconds: number): Day => {
  const date = dayjs(milliseconds)

  return {
    year: date.year(),
    month: date.month() + 1,
    day: date.date()
  }
}

export const convertDayToMilliSecondsEnd = (date: Day) => {
  return dayjs(new Date(date.year, date.month - 1, date.day))
    .endOf('day')
    .valueOf()
}

export const convertDayToEpocStart = (date: Day) => {
  return dayjs(new Date(date.year, date.month - 1, date.day))
    .startOf('day')
    .unix()
}

export const convertDayToEpocCurrent = (date: Day) => {
  return dayjs(
    new Date(
      date.year,
      date.month - 1,
      date.day,
      dayjs().hour(),
      dayjs().minute(),
      dayjs().second(),
      dayjs().millisecond()
    )
  ).unix()
}
