import { BILL_STATUS } from 'utils/common/common.types'
import {
  BillCharges,
  BillDiscounts,
  BillInfoItemType,
  CustomerDetails,
  OrderInfoItemType
} from 'state/billing/billing.types'
import { CUSTOMER_PROFILE_SORT_OPTION } from 'state/dashboard/dashboard.types'
import { IAccountBeat, IBillBeat, IAccountRoute } from 'state/new-summary/list/list.types'
import {
  ItemsResponse,
  OrderApiActionEnum,
  OrderStatus,
  PROFORMA_STATUS
} from 'state/new-summary/orders/orders.types'
import { BillInfo } from 'services/Api/BillingApiFolder.ts/type'

export type WORKFLOWS = 'delete_transaction'

export type FETCH_PERMISSION_RESPONSE = {
  user_id: string
  business_id: string
  permitted_workflows: WORKFLOWS[]
  non_permitted_workflows: WORKFLOWS[]
}

export interface FETCH_COLLECTION_LIST_V2_RESPONSE {
  collection_list: CollectionList
  bill_details: BillDetail[]
  account_details: AccountDetail[]
  merchant_name: string
  merchant_mobile: string
  pagination_details: PaginationDetails
  history_due_configs: HistoryDueConfigs
}

interface DueConfig {
  config: DUE_CONFIG
  start_time: string
  end_time: string
}

export enum DUE_CONFIG {
  BALANCE_DUE = 1,
  CUSTOMER_LIST = 7,
  INVOICE_LEVEL_DUE_V2 = 5,
  AUTO_TAG_LIST = 6
}

export enum LIST_TYPE {
  UNKNOWN = '0',
  BALANCE_DUE = '1',
  CREDIT_DUE = '2',
  BALANCE_DUE_FILTER = '3',
  INVOICE_LEVEL_DUE = '4',
  INVOICE_LEVEL_DUE_V2 = '5',
  AUTO_TAG_LIST = '6'
}

interface Config {
  config: LIST_TYPE
  assignment_date: string
}

interface Filter {
  sort_by: string
  filter_by: { key: 'tagsOR' | 'tagsAND'; values: string[] }[]
}

interface Bill {
  customer_name: string
  bill_id: string
  bill_number: string
  bill_date: string
  customer_id: string
  transaction_id: string
  total_amount: string
  status: number
  bill_info: null
  due_date: string
  created_at: string
  updated_at: string
  total_paid_amount: string
  deleted_at: string
  latest_settlement_date: string
}

interface ApprovedTransaction {
  id: string
  amount: string
  txn_type: number
  txn_time: string
}

interface ApprovedTransactionsSummary {
  total_cash_payments: string
  total_neft_payments: string
  total_cheque_payments: string
  total_return_payments: string
  total_damaged_payments: string
  total_online_payments: string
  total_cash_or_neft_payments: string
  total_wallet_payments: string
  total_other_payments: string
}

interface BillDetail {
  bill: Bill
  transactions: Transaction[]
  approved_transactions_summary: ApprovedTransactionsSummary | null
  replacements: IReplacement
  bill_cancel_params: null
  approved_transactions: ApprovedTransaction[]
}

interface IReplacementItem {
  name: string
  quantity: string
  notes: string
  state: number
}

interface Transaction {
  id: string
  amount: string
  txn_type: number
  txn_time: string
}

export interface IAutoAssignConfig {
  field: 'tags' | 'bill_date'
  value: string
  condition: 'in' | 'in_last'
  operator?: 'or' | 'and'
}

interface CollectionList {
  id: string
  business_id: string
  name: string
  url: string
  associated_account_ids: string[]
  is_active: boolean
  create_time: string
  update_time: string
  due_config: DueConfig
  type: number
  filters: Filter
  bills: BillDetail[]
  summary: null
  usage_type: number
  auto_assign_config: IAutoAssignConfig[]
  assignment_date: string
}

interface AccountDetail {
  id: string
  name: string
  mobile: string
  address: string
  beats: any[]
}

export interface PaginationDetails {
  page: string
  limit: string
  total_items: string
  total_pages: string
  per_page: string
}

interface HistoryDueConfigs {
  current_config: Config
  previous_config: Config
  past_config: Config
  current_due_config: DueConfig
  previous_due_config: DueConfig
  past_due_config: DueConfig
}

export interface CREATE_LIST_RESPONSE {
  collection_list: {
    id: string
    business_id: string
    name: string
    url: string
    associated_account_ids: string[]
    is_active: boolean
    create_time: string
    update_time: string
    due_config: {
      config: number
      start_time: string
      end_time: string
    }
    type: number
    filters: null | any
    summary: null | any
    usage_type: number
    auto_assign_config: any[]
    assignment_date: string
  }
}

export interface CREATE_LIST_V2_RESPONSE {
  collection_list: CREATE_LIST_RESPONSE['collection_list'] & {
    bills: {
      bill_id: string
      bill_number: string
      bill_date: string
      customer_id: string
      transaction_id: string
      total_amount: string
      status: number
      bill_info: null | any
      due_date: string
      created_at: string
      updated_at: string
      total_paid_amount: string
      deleted_at: string
      latest_settlement_date: string
    }[]
  }
}
export enum FILTER_OPTION {
  AND = 'and',
  OR = 'or'
}

/* New Fetch List API response type */

export type FETCH_LIST_API_RESPONSE = {
  list: NewList
  versions: NewListVersion
  bills: NewBill[]
  accounts: NewAccount[]
  collections: NewCollection[] | null
  replacements: IReplacement[]
  unapproved_cancel_requests: IUnapprovedCancelRequest[]
  unapproved_cheques: null
  unapproved_delivery_attempts: null | IUnapprovedDeliveryAttempts[]
  bills_to_redeliver: string[]
  bill_beats?: IBillBeat[]
}

export interface IUnapprovedDeliveryAttempts {
  id: string
  bill_id: string
  business_id: string
  staff_uid: string
  list_id: string
  created_at: string
  is_approved: boolean
  action_pending: string
  status: string
  updated_at: string
  approved_at: string
}

export type IUnapprovedCancelRequest = {
  id: string
  business_id: string
  account_id: string
  list_id: string
  bill_id: string
  created_by: string
  creator_id: string
  cancel_requested: boolean
  requested_at: string
  is_cancelled: boolean
  cancelled_at: string | null
}

export interface IListSettings {
  all_pending_invoices: boolean
}

export interface NewList {
  business_id: string
  id: string
  url: string
  created_at: string
  updated_at: string
  is_active: boolean
  name: string
  due_config: DueConfig
  filters: Filter
  usage_type: LIST_USAGE_TYPE
  assignment_date: string
  auto_assign_config: IAutoAssignConfig[]
  settings: IListSettings
}
export enum LIST_USAGE_TYPE {
  SUPPLY = 1,
  COLLECTION = 0
}
interface NewListVersion {
  curr: number
  prev: number
  past: number
}

export interface NewAccount {
  id: string
  mobile: string
  name: string
  address: string
  beats: string[] | null
  bal: number
  account_beats: IAccountBeat[]
  account_routes: IAccountRoute[]
}

export interface NewBill {
  bill_id: string
  bill_number: string
  bill_date: number
  customer_id: string
  transaction_id: string
  total_amount: number
  status: number
  bill_info?: {
    items?: Pick<
      OrderInfoItemType['info'],
      'amount' | 'hsn_code' | 'name' | 'quantity' | 'tax' | 'tax_components' | 'unit'
    >[]
  }
  due_date: number
  created_at: number
  updated_at: number
  total_paid_amount: number
  latest_settlement_date: number
}

export interface NewCollection {
  id: string
  list_id: string
  business_id: string
  account_id: string
  ledger_id: string
  staff_id: string
  created_by: string
  bill_id: string
  note: string
  amount: number
  type: number
  is_approved: boolean
  is_bill_settled: boolean
  added_as_advance: boolean
  advance_amount: number
  items: any[]
  catalog_items: any[]
  return_items: any[]
  created_at: string
  updated_at: string
  transaction_time: string
}

export interface IReplacement {
  id: string
  business_id: string
  account_id: string
  staff_id: string
  bill_id: string
  amount: string
  status: number
  items: IReplacementItem[]
  catalog_items: any[]
  created_at: string
  updated_at: string
  staff_name: string
  staff_mobile: string
}

interface IReplacementItem {
  name: string
  quantity: string
  notes: string
  state: number
}

export interface IReturns extends Transaction {
  items?: IReturnItem[]
  catalog_items?: IReturnCatalogItem[]
  return_items?: IReturnItem[]
}

export interface IDamage extends IReturns {}
interface IReturnItem {
  name: string
  quantity: string
}

interface IReturnCatalogItem {
  name: string
  quantity: string
  id: string
  img: string
  desc: string
}

export type UPDATE_BILL_AMOUNT_RESPONSE = {
  bill: {
    bill_id: string
    amount: number
    due_amount: number
    status: BILL_STATUS
    bill_version: number
  }
}
export interface IBillReturnItem {
  name: string
  unit: string
  rate: string
  quantity: number
  tax: string
  amount: string
  TaxCategory: string
  hsn_code: string
  catalog_item_id: string
  mrp: string
  show_tax_included: boolean
  discount: string
  attrs: any
}

export interface FetchCustomerProfilesAPIPayload {
  page_no: number
  page_size: number
  search_string?: string
  sort_by?: CUSTOMER_PROFILE_SORT_OPTION
  sort_descending?: boolean
}

export interface BankWiseApiPayload {
  business_id: string
  start_date: number
  start_time: number
  end_time: number
}

export interface BankWiseResponse {
  staff_data: {
    [staffId: string]: {
      source_data: SourceData[]
    }
  }
}

type SourceData = {
  source: string
  total_amount: string
}

export type TransformedData = {
  [staffId: string]: {
    name: string
    [key: string]: string
  }
}

export interface fetchOrdersPayload {
  start_time: number
  end_time: number
  statuses: Array<OrderStatus>
  staff_id?: Array<string>
}

export interface UpdateOrderRequestPayload {
  status: OrderStatus
  id: string
  amount?: number
  items?: Array<ItemsResponse>
}

export interface ListCatalogueItemsPayload {
  inventory_item_ids: Array<string>
}

export interface CreateOrderBillPayload {
  customer_id: string
  customer: CustomerDetails
  add_to_ledger: boolean
  bill_info: BillInfo
}

export interface UpdateOrderApiPaylod {
  action: OrderApiActionEnum
  order: {
    status?: OrderStatus
    id: string
    bills?: Array<{
      bill_id: string
    }>
    inventory_items?: OrderInfoItemType[]
  }
}

export interface UpdateProformaStatusApiPaylod {
  proforma_id: string
  status: PROFORMA_STATUS
  bill_id?: string
}

export interface IOrderBillRequestPayload {
  request_id?: string
  bill_id?: string
  customer_id: string
  buyer_info: {
    name: string
    address: string
    gst_number?: string
    mobile: string
  }
  add_to_ledger: boolean
  created_at: number
  bill_number: string
  bill_date: number
  total_amount: number
  bill_info: {
    discounts?: Array<BillDiscounts>
    add_charges?: Array<BillCharges>
    bill_gst?: string
    note?: string
    items: BillInfoItemType[]
  }
  labels?: {
    business_gst: string
  }
}

export interface IOrderProformaRequestPayload {
  request_id?: string
  customer_id: string
  buyer_info: {
    name: string
    address: string
    gst_number?: string
    mobile: string
  }
  proforma_number: string
  proforma_date: number
  proforma_amount: number
  proforma_info: {
    discounts?: Array<BillDiscounts>
    add_charges?: Array<BillCharges>
    bill_gst?: string
    note?: string
    items: BillInfoItemType[]
  }
  labels?: {
    business_gst: string
  }
}

export interface IProformaRequestPayload {
  customer_id: string
  buyer_info: {
    name: string
    address: string
    mobile: string
    gst_number?: string
  }
  seller_info?: any
  proforma_number: string
  proforma_date: number
  proforma_amount: number
  proforma_info: {
    discounts?: Array<BillDiscounts>
    add_charges?: Array<BillCharges>
    items: OrderInfoItemType[]
  }
  reference_id?: string
}

export interface IPreviewProformaRequestPayload {
  use_default_template: boolean
  bill_id?: string
  use_placeholder_qr: boolean
  customer_code?: string
  proforma_req: IProformaRequestPayload
}
