import { Action } from 'infra/types'

export const BillsActions = {
  SET_LOADER: 'bills/setLoader',
  FETCH_BILLS_BY_CUSTOMER: 'bills/fetchBillsByCustomer',
  FETCH_BILLS_BY_CUSTOMER_SUCCESS: 'bills/fetchBillsByCustomerSuccess'
}

export const setLoaderAction = (payload: {
  id: string
  value: boolean
}): Action<{ id: string; value: boolean }> => ({
  type: BillsActions.SET_LOADER,
  payload
})

export const getBillsByCustomerAction = (payload: {
  customer_id: string
  per_page?: number
  page?: number
  bill_number?: string
  replaceExisting?: boolean
  from_date?: number
  to_date?: number
  isStatement?: boolean
  redirectTo?: string
}): Action<{
  customer_id: string
  per_page?: number
  page?: number
  bill_number?: string
  replaceExisting?: boolean
  from_date?: number
  to_date?: number
  isStatement?: boolean
  redirectTo?: string
}> => ({
  type: BillsActions.FETCH_BILLS_BY_CUSTOMER,
  payload
})

export const getBillsByCustomerSuccessAction = (payload: any): Action<any> => ({
  type: BillsActions.FETCH_BILLS_BY_CUSTOMER_SUCCESS,
  payload
})
