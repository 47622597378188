import { getEnvConfig } from 'utils/getEnvConfig'

import {
  COLLECTION_LIST_TYPE,
  IReplacementsItem,
  IFilters,
  COLLECTION_LIST_SORT_TYPE,
  COLLECTION_LIST_FILTER_TYPE,
  ISelectedInvoiceToShift,
  COLLECTION_LIST_TAB,
  CL_VERSIONS,
  IAutoAssignConfig,
  ICatalogItem
} from 'state/collectionList/collectionList.types'
import { TransactionTypeEnum } from 'pages/CollectionList/components/AddToTransectionDrawer/AddToTransectionDrawer.types'
import { transactionType } from 'pages/CollectionList/CollectionList.constants'
import { precisionRound } from 'shared/utils'
import { isInvoice } from 'state/collectionList/helper'
import { IlinkDelinkInvoiceTagPayload, linkDelinkAccountTagsPayload } from 'state/tags/tags.types'
import { SUMMARY_FILTER_OPTION } from 'pages/CollectionSummary/CollectionSummary.constants'
import { FetchPendingActionAPIPayload } from 'state/new-summary/approve/approve.types'
import { IAddBillToCollectionsRequest } from 'state/new-summary/unidentifiedCollection/unidentifiedCollection.types'
import {
  CreateBankStatementTemplatePayloadType,
  UpdateBankStatementTemplatePayloadType
} from 'state/new-summary/bankStatement/bankStatement.types'
import { ISavePurchaseBillSettingsReq } from 'state/billing/billing.types'
import { ILinkDelinkInvoiceBeatsPayload } from 'state/beats/beats.types'
import { ROLE } from 'state/transaction/transaction.types'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor,
  businessIdInterceptor
} from './apiInterceptor'

import ApiService from './ApiService'
import {
  FILTER_OPTION,
  IFilteredCustomersPayload,
  ITransactionPayload,
  IUpdateStaffAccessPayload,
  TP_SOURCE_TYPE,
  ThirdPartyConfigType,
  IUpdateRouteBeatRequest,
  IThirdPartyCreditExportsType
} from './types'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

let endpoint: string = ''
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    endpoint = getEnvConfig('STAFF_LINK_BASE_URL')
  } else {
    endpoint = `${window.location.origin}/api/staff-link`
  }
}

const FETCH_COLLECTION_LIST = '/v1/GetCollectionLists'
const FETCH_COLLECTION_DETAILS = '/v1/GetCollectionListDetails'
const FETCH_QUICK_SUMMARY = '/v1/GetCollectionListEventsSummary'
const SET_STAFF_LINK = '/v1/CreateCollectionList'
const UPDATE_STAFF_LINK = '/v1/UpdateCollectionList'
const UPDATE_STAFF_LINK_V2 = '/v2/UpdateCollectionListOfBills'
const UPDATE_TRANSACTION = '/v1/UpdateTransaction'
const UPDATE_MULTIPLE_TRANSACTION = '/v1/UpdateMultipleTransactions?updated_by=accountant'
const GET_WALLET_AMOUNT = '/v1/GetWalletDetails'
const UPDATE_REPLACEMENT = '/v1/replacements'
// const BULK_UPDATE_TRANSACTION = 'v1/BulkUpdateTransaction'
const GET_TAGS = '/v1/GetTags'
const CREATE_TAG = '/v1/CreateTags'
const UPDATE_TAG = '/v1/UpdateTag'
const GET_ACCOUNT_TAGS = '/v1/GetBusinessAccountTags'
const LINK_ACCOUNT_TAGS = '/v1/AccountTags'
const GET_INVOICE_TAGS = '/v1/GetBillTags'
const LINK_INVOICE_TAGS = '/v1/BillTags'
const GET_TAG_SUMMARY = '/v1/tag/summary'
const GET_BILLS_BY_TAGS = 'v1/FilterBillsByTags'
const FETCH_LIST_SUMMARY = '/v1/GetAccountantSummary'
const FETCH_LIST_INVOICE_SUMMARY = '/v1/AccountantInvoiceSummary'
const FETCH_LIST_INVOICE_SUMMARY_V2 = '/v2/GetClBillsAccountantSummary'
// const CREATE_TRANSACTION = '/v1/CreateTransaction'
const CREATE_MULTIPLE_TRANSACTION = '/v1/CreateMultipleTransactions?created_by=accountant'
const GET_OWNER_SUMMARY = 'v1/GetOwnerSummary'
const CANCEL_INVOICE = '/v1/bills/cancel'
const GET_COLLECTION_LIST_SUMMARY = '/v1/CollectionListSummary'
const GET_SINGLE_COLLECTION_LIST_SUMMARY = '/v1/CollectionListAccountSummary'
const GET_COLLECTION_LIST_SUMMARY_OVERALL_STATS = '/v1/CollectionListSummaryOverallStats'
const GET_COLLECTION_LIST_INVOICE_SUMMARY = '/v1/CollectionListInvoiceSummary'
const GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY = '/v1/CollectionListAccountInvoiceSummary'
const GET_COLLECTION_LIST_INVOICE_SUMMARY_OVERALL_STATS =
  '/v1/CollectionListInvoiceSummaryOverallStats'
const GET_UNAPPROVED_TXNS = '/v1/GetUnapprovedTransactions'
const GET_ACCOUNT_ALLOCATION_DETAILS = '/v1/GetAccountAllocationDetails'
const GET_REPLACEMENT_LIST = '/v1/GetPendingReplacements'
const GET_INVOICE_LEVEL_SUMMARY = '/v1/CollectionListInvoiceSummaryDownload'
const GET_V2_INVOICE_LEVEL_SUMMARY = '/v2/CollectionListSummaryDownload'
const GET_DYNAMIC_V2_INVOICE_LEVEL_SUMMARY = '/v3/SgV2SummaryDownload'
const GET_REPLACEMENT_DOWNLOAD_SUMMARY = '/v2/StatementGroupsReplacementSummaryDownload'
const GET_V2_INVOICE_LEVEL_SUMMARY_ALL_BUSINESS = '/v2/CLV2SummaryDownloadAllBusinesses'
const GET_DYNAMIC_V2_INVOICE_LEVEL_SUMMARY_ALL_BUSINESS = '/v2/SgV2SummaryDownloadAllBusinesses'
const STATEMENT_GROUP_SUMMARY = '/v1/StatementGroupsSummaryDownload'
const GET_FILTERED_CUSTOMER_LIST = '/v1/FilterAccountsByTags'
const CREATE_COLLECTION_LIST_V2 = '/v2/CreateCollectionListOfBills'
const FETCH_COLLECTION_LIST_V2 = '/v2/GetCollectionListBillDetails'
const GET_COLLECTION_LIST_INVOICE_SUMMARY_V2 = '/v2/CollectionListInvoiceSummary'
const GET_COLLECTION_LIST_INVOICE_SUMMARY_V3 = '/v2/StatementGroupsSummary'
const OVERALL_APPROVED_TRANSACTIONS = '/v1/summary/OverallApprovedTransactions'
const APPROVED_TRANSACTIONS = 'v1/summary/ApprovedTransactions'
const GET_OVERALL_SUMMARY = '/v1/OverallBusinessCollectionSummary'
const GET_OVERALL_INVOICE_SUMMARY_V3 = '/v1/OverallBusinessInvoicesSummary'
const GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2 = '/v2/CollectionListAccountInvoiceSummary'
const GET_COLLECTION_GROUP_INVOICE_SUMMARY_V3 = '/v2/StatementGroupsSummaryInvoices'
const STATEMENT_GROUP_BY_TAGS = '/v1/StatementGroups'
const GET_STATEMENT_GROUPS = '/v1/StatementGroupsSummary'
const GET_STATEMENT_GROUP_ACCOUNTS = '/v1/StatementGroupAccountInvoiceSummary'
const GET_STATEMENT_GROUP_INVOICES = '/v1/StatementGroupInvoices'
const GET_TRANSACTION_DETAILS = '/v1/GetCustomerTransactions'
const BULK_CANCEL_INVOICE = '/v1/bills/bulk-cancel'
const UNDO_CANCEL_INVOICE = '/v1/bills/cancel/request'
const STAFF_MEMBERS = '/v1/ListBusinessStaff'
const RENAME_STAFF = 'v1/RenameStaff'
const STAFF_ACCESS_LIST = '/v1/ListStaffAccess'
const UPDATE_STAFF_ACCESS = 'v1/ChangeStaffAccess'
const UPSERT_CASH_DEMONINATION = '/v1/UpsertCashDenominations'
const GET_DENOMINATIONS = '/v1/CashDenominations'
const FETCH_PENDING_ACTION_BILLS_DETAILS = '/v1/GetPendingActions'
const THIRD_PARTY_CONFIG = '/web/v1/config/third-party'
const DOWNLOAD_PAYMENTS_IN_THIRD_PARTY_FORMAT = '/web/v1/payments'
const EXPORT_BILLS_TALLY_V3 = '/v3/ExportBillsTally'
const GET_BANK_STATEMENT_TEMPLATES = '/v1/GetTemplates'
const CREATE_BANK_STATEMENT_TEMPLATE = '/v1/CreateTemplate'
const UPDATE_BANK_STATEMENT_TEMPLATE = '/v1/UpdateTemplate'
const UPLOAD_BANK_STATEMENT_FILE = '/v1/UploadDocument'
const GET_BANK_STATEMENT_FILES = '/v1/GetAllDocuments'
const GET_BANK_STATEMENT_RECONCILED_TXNS = 'v1/GetBankStatementReconTxns'
const BULK_UPDATE_BANK_STATEMENT_TXNS = 'v1/BulkUpdateBkStmtTransactions'
const GET_SINGLE_BANK_STATEMENT_FILE = '/v1/GetDocuments'
const GET_ROUTES_AND_BEATS = '/v1/RoutesAndBeats'
const ADD_BILL_TO_COLLECTION = '/v1/AddBillToCollection'
const FETCH_UNIDENTIFIED_COLLECTION = '/v1/ListMerchantCollections'
const REJECT_DELIVERY_FAILURE = '/v3/RejectDeliveryFailure'
const APPROVE_DELIVERY_FAILURE = '/v3/ApproveDeliveryFailure'
const UNMARD_REDELIVER = '/v3/UnmarkRedeliver'
const LISTS_WITH_PENDING_ACTIONS = '/v1/GetPendingActionsListIds'
const SAVE_FILE_SETTINGS = '/files/v1/PutFileSettings'
const FETCH_FILE_SETTINGS = '/files/v1/ListFileSettings'
const UPLOAD_PURCHASE_BILL_FILE = '/files/v1/UploadFile'
const EXPORT_PURCHASE_FILE = '/files/v1/ExportPurchases'
const FETCH_PURCHASE_FILES = '/files/v1/ListFiles'
const GET_ACCOUNT_TRANSACTIONS = 'v1/GetAccountTransactions'
const CREATE_ASSETS_ACCOUNTS = '/bookkeeping/v1/CreateAssetAccount'
const PENDING_BANK_DEPOSITS = '/bookkeeping/v1/PendingBankDeposits'
const PENDING_CASH_DEPOSITS = '/bookkeeping/v1/PendingCashDeposits'
const CASH_BALANCE_STATS = '/bookkeeping/v1/CashBalanceStats'
const APPROVE_BANK_DEPOSIT = '/bookkeeping/v1/ApproveBankDeposit'
const REJECT_BANK_DEPOSIT = '/bookkeeping/v1/DeclineBankDeposit'
const APPROVE_CASH_DEPOSIT = '/bookkeeping/v1/ApproveCashDeposit'
const REJECT_CASH_DEPOSIT = '/bookkeeping/v1/DeclineCashDeposit'
const APPROVE_TRANSACTION_AND_CREATE_BILL = '/v3/transactions/approve'
const CREATE_INVOICE_ITEM = '/bookkeeping/v1/CreateInventoryItem'
const GET_INVOICE_ITEMS = '/bookkeeping/v1/InventoryItems'
const GET_INVOICE_ITEM_HISTORY = '/bookkeeping/v1/InventoryItemHistory'
const GET_PURCHASE_ITEMS = '/bookkeeping/v1/InventoryItems'
const GET_PURCHASE_ACCOUNT = '/bookkeeping/v1/PurchaseAccounts'
const GET_PURCHASE_ASSET_ACCOUNT = '/bookkeeping/v1/AssetAccounts'
const CREATE_PURCHASE_ACCOUNT = '/bookkeeping/v1/CreatePurchaseAccount'
const CREATE_PURCHASE_INVENTORY = '/bookkeeping/v1/PurchaseInventory'
const CREATE_INITIATE_BANK_DEPOSIT = '/bookkeeping/v1/InitiateBankDeposit'
const CREATE_INITIATE_CASH_DEPOSIT = '/bookkeeping/v1/InitiateCashDeposit'
const ADD_EXPENSE_API = '/bookkeeping/v1/AddStaffExpense'
const GET_EXPENSE_API = '/bookkeeping/v1/ExpenseTransactions'
const GET_INVENTORY_TRANSACTION_HISTORY = '/bookkeeping/v1/InventoryTransactionHistory'
const GET_ALL_BEATS = '/v1/GetRoutesAndBeats'
const GET_ALL_INVOICE_BEATS = '/v1/GetBillRoutesAndBeats'
const CREATE_BEAT_ROUTE = '/v1/CreateRoutesAndBeats'
const LINK_DELINK_BEATS = '/v1/BillRoutesAndBeats'
const UPDATE_BANK_DEPOSIT = '/bookkeeping/v1/UpdateBankDeposit'
const UPDATE_CASH_DEPOSIT = '/bookkeeping/v1/UpdateCashDeposit'
const ADD_EXPENSE_ACCOUNT_API = '/bookkeeping/v1/CreateExpenseAccount'
const GET_EXPENSE_ACCOUNT_API = '/bookkeeping/v1/ExpenseAccounts'
const ADD_EXPENSE_API_V1 = '/bookkeeping/v1/AddExpense'
const UPDATE_ASSET_ACCOUNT = '/bookkeeping/v1/UpdateAssetAccount'
const BANK_DEPOSIT_HISTORY = '/bookkeeping/v1/BankDepositHistory'
const CASH_DEPOSIT_HISTORY = '/bookkeeping/v1/CashDepositHistory'
const ALL_BUSINESS_ONLINE_COLLECTIONS = 'v1/list-all-business-online-collections'
const SYNC_MISSING_BILLS = '/bookkeeping/v1/SyncMissingBills'
const GET_DOUBLE_ENTRY = 'bookkeeping/v1/DoubleEntry'
const ADD_BULK_PURCHASE_INVENTORY = 'bookkeeping/v1/BulkPurchaseInventory'
const GET_BULK_PURCHASE_FILE_INFO = 'bookkeeping/v1/BulkPurchases'
const RETRY_BULK_PURCHASE_INVENTORY = 'bookkeeping/v1/RetryBulkPurchaseInventory'
const GET_BULK_PURCHASE_UNITS = 'bookkeeping/v1/BulkPurchaseUnits'

const api = new ApiService(endpoint, headers)
api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const fetchUnidentifiedCollectionApi = (payload: { start_time?: number }): Promise<any> => {
  return api.postRequest(FETCH_UNIDENTIFIED_COLLECTION, payload)
}

export const addBillToCollectionsApi = (payload: IAddBillToCollectionsRequest): Promise<any> => {
  return api.postRequest(ADD_BILL_TO_COLLECTION, payload)
}

export const createStaffLinkApi = (
  name: string,
  accountIds: string[],
  listType: COLLECTION_LIST_TYPE,
  usageType: COLLECTION_LIST_TAB,
  // typeMetaData?: IDateSelect,
  sortBy?: COLLECTION_LIST_SORT_TYPE,
  filterBy?: {
    key: COLLECTION_LIST_FILTER_TYPE
    values: string[]
  }[]
) => {
  // let startTime = null
  // let endTime = null
  if (
    listType === COLLECTION_LIST_TYPE.CREDIT_DUE ||
    listType === COLLECTION_LIST_TYPE.BALANCE_DUE_FILTER ||
    isInvoice(listType)
  ) {
    // if (typeMetaData?.from) {
    //   const { from } = typeMetaData
    //   startTime = new Date(from.year, from.month - 1, from.day, 0, 0, 0).getTime() / 1000
    // }
    // if (typeMetaData?.to) {
    //   const { to } = typeMetaData
    //   endTime = new Date(to.year, to.month - 1, to.day, 23, 59, 59).getTime() / 1000
    // }
  }
  return api.postRequest(SET_STAFF_LINK, {
    name,
    associated_account_ids: accountIds,
    due_config: {
      config: parseInt(listType)
      // ...(startTime && { start_time: startTime }),
      // ...(endTime && { end_time: endTime })
    },
    usage_type: usageType,
    ...((sortBy || filterBy) && {
      filters: {
        ...(sortBy && { sort_by: sortBy }),
        ...(filterBy && { filter_by: filterBy })
      }
    })
  })
}

export const updateStaffLink = (
  staffLinkId: string,
  accountIds: string[] | null,
  action: string
) => {
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: staffLinkId,
      ...(accountIds?.length && { associated_account_ids: accountIds })
    },
    action
  })
}

export const updateStaffLinkV2 = (payload: {
  collection_list: {
    id: string
    associated_account_ids?: string[] | undefined
    bills?: { bill_id: string; customer_id: string }[] | undefined
    business_id?: string
    due_config?: {
      config: number
    }
    auto_assign_config?: IAutoAssignConfig[]
  }
  action: string
}) => {
  return api.postRequest(UPDATE_STAFF_LINK_V2, payload)
}

export const upsertCashDenominations = (payload: {
  denomination: number
  count: number
  collection_date: number
}) => {
  return api.postRequest(UPSERT_CASH_DEMONINATION, payload)
}

export const fetchCashDenominations = (collectionDate: number) => {
  return api.getRequest(`${GET_DENOMINATIONS}?collection_date=${collectionDate}`, {})
}

export const removeBillStaffLink = (data: {
  clId: string
  accountIds: string[]
  billIds: string[]
}) => {
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: data.clId,
      associated_account_ids: data.accountIds
    },
    action: 'ignore_invoices',
    invoices: data.billIds
  })
}

export const bulkCancelInvoiceReq = (billIds: string[]) => {
  return api.postRequest(BULK_CANCEL_INVOICE, { bill_ids: billIds })
}

export const undoCancelInvoiceReq = (billId: string) => {
  const body = {
    bill_id: billId,
    request_deleted: true
  }
  return api.postRequest(UNDO_CANCEL_INVOICE, body)
}

export const updateStaffLinkName = (staffLinkId: string, name: string) => {
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: staffLinkId,
      name
    },
    action: 'edit_name'
  })
}

export const updateUsageType = (staffLinkId: string, usageType: number) => {
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: staffLinkId,
      usage_type: usageType
    },
    action: 'update_usage_type'
  })
}

export const updateCollectionLinkFilters = (staffLinkId: string, filters: IFilters) => {
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: staffLinkId,
      filters
    },
    action: 'update_filters'
  })
}

export const updateCollectionListDueConfig = (
  collectionListId: string,
  listType: COLLECTION_LIST_TYPE
  // typeMetaData?: IDateSelect
) => {
  // let startTime = null
  // let endTime = null
  if (
    listType === COLLECTION_LIST_TYPE.CREDIT_DUE ||
    listType === COLLECTION_LIST_TYPE.BALANCE_DUE_FILTER ||
    isInvoice(listType)
  ) {
    // if (typeMetaData?.from) {
    //   const { from } = typeMetaData
    //   startTime = new Date(from.year, from.month - 1, from.day, 0, 0, 0).getTime() / 1000
    // }
    // if (typeMetaData?.to) {
    //   const { to } = typeMetaData
    //   endTime = new Date(to.year, to.month - 1, to.day, 23, 59, 59).getTime() / 1000
    // }
  }
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: collectionListId,
      due_config: {
        config: parseInt(listType)
        // ...(startTime && { start_time: startTime }),
        // ...(endTime && { end_time: endTime })
      }
    },
    action: 'due_config'
  })
}

export const removeCollectionList = (collectionListId: string) => {
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: collectionListId
    },
    action: 'mark_inactive'
  })
}

export const approveTxnCollectionStaffLink = (txnId: string, merchantId: string) => {
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions: [
      {
        id: txnId,
        business_id: merchantId,
        approved_by_merchant: true
      }
    ]
  })
}

export const bulkApproveTxnCollectionStaffLink = (txnIds: string[], merchantId: string) => {
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions: txnIds.map((txnId: string) => ({
      id: txnId,
      business_id: merchantId,
      approved_by_merchant: true
    }))
  })
}

export const approveTxnAndCreateAssociatedInvoice = (txnId: string, merchantId: string) => {
  return api.postRequest(APPROVE_TRANSACTION_AND_CREATE_BILL, {
    id: txnId,
    business_id: merchantId
  })
}

export const fetchWalletDetailsReq = (accountId: string, merchantId: string): Promise<any> => {
  return api.getRequest(
    `${GET_WALLET_AMOUNT}?account_id=${accountId}&business_id=${merchantId}`,
    {}
  )
}

export const addTxnToAdvanceCollectionLink = (txnId: string, merchantId: string) => {
  return api.postRequest(UPDATE_TRANSACTION, {
    transaction: {
      id: txnId,
      business_id: merchantId
    },
    add_as_advance: true
  })
}

// another function 'deleteTransectionReq' in line 548 has the same definition
export const removeStaffLinkTransaction = (data: {
  staffLinkTxnId: string
  merchantId: string
}) => {
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions: [
      {
        id: data?.staffLinkTxnId,
        business_id: data?.merchantId,
        is_deleted: true
      }
    ]
  })
}

export const updateReplacementRecord = (data: {
  replacementId: string
  merchantId: string
  accountId: string
  status: number
  amount: string
  billId: string
  collectionListId?: string
  replacementItems: IReplacementsItem[]
  catalogItems?: ICatalogItem[]
}) => {
  return api.putRequest(UPDATE_REPLACEMENT, {
    replacement: {
      id: data.replacementId,
      business_id: data.merchantId,
      account_id: data.accountId,
      bill_id: data.billId,
      amount: Number(data.amount),
      status: data.status,
      items: data.replacementItems.map((itm) => ({
        name: itm.name,
        quantity: Number(itm.quantity),
        state: itm.state,
        notes: itm.notes || ''
      })),
      ...(data.catalogItems && {
        catalog_items: data.catalogItems.map((itm) => ({
          ...itm,
          quantity: Number(itm.quantity)
        }))
      })
    }
  })
}

export const approveCancelInvoice = (billId: string) => {
  return api.postRequest(CANCEL_INVOICE, {
    bill_id: billId,
    is_cancelled: true
  })
}

export const shiftCollectionListAccountRequest = (dta: {
  collectionListId: string
  accountIdsToShift: string[]
  targetCollectionListId: string
}) => {
  return api.postRequest(UPDATE_STAFF_LINK, {
    collection_list: {
      id: dta.collectionListId,
      associated_account_ids: dta.accountIdsToShift
    },
    action: 'move_associated_accounts',
    target_collection_list_id: dta.targetCollectionListId
  })
}

export const shiftCollectionListInvoiceRequest = (dta: {
  collectionListId: string
  merchantId: string
  invoicesToShift: ISelectedInvoiceToShift[]
  targetCollectionListId: string
}) => {
  return api.postRequest(UPDATE_STAFF_LINK_V2, {
    collection_list: {
      id: dta.collectionListId,
      business_id: dta.merchantId,
      bills: dta.invoicesToShift
    },
    action: 'move_bills',
    target_collection_list_id: dta.targetCollectionListId
  })
}

// export const approveBulkCollectionStaffLink = (
//   transactions: Array<{
//     id: string
//     business_id: string
//     approved_by_merchant: boolean
//   }>
// ) => {
//   return api.postRequest(BULK_UPDATE_TRANSACTION, {
//     action: 'approve',
//     transactions
//   })
// }

export const approveBulkCollectionStaffLinkV2 = (
  transactions: Array<{
    id: string
    business_id: string
    approved_by_merchant: boolean
  }>
) => {
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions
  })
}

export const fetchSingleCollectionListApi = (businessId: string, collectionListId: string) => {
  return api.postRequest(`${FETCH_COLLECTION_DETAILS}?page=1&limit=1000`, {
    business_id: businessId,
    collection_list_id: collectionListId
  })
}

export const fetchClQuickSummaryApi = (businessId: string, collectionListIds: string[]) => {
  return api.postRequest(`${FETCH_QUICK_SUMMARY}`, {
    business_id: businessId,
    collection_list_ids: collectionListIds
  })
}

export const fetchCollectionListApi = (usageType: COLLECTION_LIST_TAB) => {
  return api.getRequest(`${FETCH_COLLECTION_LIST}?page=1&limit=1000&usage_type=${usageType}`, {})
}

// This will be new API's
export const fetchList = (usageType: COLLECTION_LIST_TAB) => {
  return api.getRequest(`${FETCH_COLLECTION_LIST}?page=1&limit=1000&usage_type=${usageType}`, {})
}

export const fetchTags = () => {
  return api.getRequest(GET_TAGS, {})
}

export const createTag = (payload: any) => {
  return api.postRequest(CREATE_TAG, payload)
}

export const updateTag = (payload: any) => {
  return api.postRequest(UPDATE_TAG, payload)
}

export const fetchAccountTags = () => {
  return api.postRequest(GET_ACCOUNT_TAGS, {})
}

export const linkDelinkAccountTags = (payload: linkDelinkAccountTagsPayload) => {
  return api.postRequest(LINK_ACCOUNT_TAGS, {
    account_tags_actions: payload
  })
}

export const fetchInvoiceTags = (payload: { transactionId: string; accountId: string }) => {
  return api.postRequest(GET_INVOICE_TAGS, {
    account_transaction_id: payload.transactionId,
    account_id: payload.accountId
  })
}

export const linkDelinkInvoiceTags = (payload: IlinkDelinkInvoiceTagPayload) => {
  return api.postRequest(LINK_INVOICE_TAGS, payload)
}

export const fetchTagSummary = (tagId: string) => {
  return api.getRequest(`${GET_TAG_SUMMARY}?tag_id=${tagId}`, {})
}

export const fetchBillsByTags = (payload: {
  tag_ids: string[]
  filter_option: FILTER_OPTION
  beatsFilter: {
    beat_ids: string[]
    filter_option: FILTER_OPTION
  }
}) => {
  return api.postRequest(GET_BILLS_BY_TAGS, payload)
}
export const fetchCollectionListInvoiceSummaryDetails = (payload: any) => {
  return api.postRequest(FETCH_LIST_INVOICE_SUMMARY, payload)
}

export const fetchCollectionListInvoiceSummaryV2Details = (payload: any) => {
  return api.postRequest(FETCH_LIST_INVOICE_SUMMARY_V2, payload)
}

export const fetchCollectionListSummaryDetails = (payload: any) => {
  return api.postRequest(FETCH_LIST_SUMMARY, payload)
}

export const createGroup = (payload: any) => {
  return api.postRequest(SET_STAFF_LINK, payload)
}

export const getOwnerSummary = (payload: any) => {
  return api.postRequest(GET_OWNER_SUMMARY, payload)
}

export const deleteTransectionReq = (params: {
  txnId: string
  merchantId: string
}): Promise<any> => {
  const { txnId, merchantId } = params
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions: [
      {
        id: txnId,
        business_id: merchantId,
        is_deleted: true
      }
    ]
  })
}

export const deleteMultipleTransectionReq = (params: {
  txnIds: string[]
  merchantId: string
}): Promise<any> => {
  const { txnIds, merchantId } = params
  return api.putRequest(UPDATE_MULTIPLE_TRANSACTION, {
    transactions: txnIds.map((txnId) => ({
      id: txnId,
      business_id: merchantId,
      is_deleted: true
    }))
  })
}

export const createAccountantTransection = (
  payload: {
    merchantId: string
    collectionListId: string | null
    associatedAccountId: string
    amount: number
    notes?: string
    advanceAmount?: number
    txnType: TransactionTypeEnum
    settlementBillIds?: string[]
    transactionTime?: number
    chequeDetails?: {
      cheque_number: string
      cheque_date: number
      collected_on: number
      amount: number
      state: number
    }
  }[]
) => {
  const apiPayload = payload.map((item) => {
    return {
      business_id: item.merchantId,
      collection_list_id: item.collectionListId,
      associated_account_id: item.associatedAccountId,
      txn_type: transactionType?.[item?.txnType],
      amount: precisionRound(Math.abs(Number(item.amount)) * 100, 2),
      advance_amount: precisionRound(Math.abs(Number(item.advanceAmount || 0)) * 100, 2),
      add_as_advance: !!item.advanceAmount || item.advanceAmount === 0,
      notes: item.notes,
      created_by: 2, // {DEFAULT = 0;STAFF = 1;ACCOUNTANT = 2;OWNER = 3;}
      ...(item?.settlementBillIds?.length && {
        settlement_bill_ids: item?.settlementBillIds,
        is_bill_settled: true
      }),
      ...(item?.transactionTime && {
        transaction_time: item?.transactionTime
      }),
      ...(item?.chequeDetails && { cheque_details: item?.chequeDetails })
    }
  })
  return api.postRequest(CREATE_MULTIPLE_TRANSACTION, {
    transactions: apiPayload
  })
}

export const getCollectionListSummaryApi = (payload: any) => {
  return api.postRequest(GET_COLLECTION_LIST_SUMMARY, payload)
}

export const getSingleCollectionListSummaryApi = (payload: any) => {
  return api.postRequest(GET_SINGLE_COLLECTION_LIST_SUMMARY, payload)
}

export const getCollectionListSummaryOverallStatsApi = (payload: any) => {
  return api.postRequest(GET_COLLECTION_LIST_SUMMARY_OVERALL_STATS, payload)
}

export const getCollectionListInvoiceSummaryApi = (payload: any) => {
  return api.postRequest(GET_COLLECTION_LIST_INVOICE_SUMMARY, payload)
}

export const getCollectionListInvoiceSummaryOverallStatsApi = (payload: any) => {
  return api.postRequest(GET_COLLECTION_LIST_INVOICE_SUMMARY_OVERALL_STATS, payload)
}

export const getSingleCollectionListInvoiceSummaryApi = (payload: any) => {
  return api.postRequest(GET_SINGLE_COLLECTION_LIST_INVOICE_SUMMARY, payload)
}

export const getUnapprovedTransactionsApi = (payload: any) => {
  return api.postRequest(GET_UNAPPROVED_TXNS, payload)
}

export const getAccountAllocationDetailsApi = (payload: any) => {
  return api.postRequest(GET_ACCOUNT_ALLOCATION_DETAILS, payload)
}

export const getReplacementListApi = (payload: any) => {
  return api.postRequest(GET_REPLACEMENT_LIST, payload)
}

export const updatePendingReplacementRecord = (data: {
  replacementId: string
  merchantId: string
  accountId: string
  status: number
  amount: string
  billId: string
  collectionListId: string
  replacementItems: IReplacementsItem[]
}) => {
  return api.putRequest(UPDATE_REPLACEMENT, {
    replacement: {
      id: data.replacementId,
      business_id: data.merchantId,
      account_id: data.accountId,
      bill_id: data.billId,
      amount: Number(data.amount),
      status: data.status,
      items: data.replacementItems.map((itm) => ({
        name: itm.name,
        quantity: Number(itm.quantity)
      }))
    }
  })
}

export const getFinalBalanceDownloadDataApi = (payload: any) => {
  return api.postRequest(GET_COLLECTION_LIST_SUMMARY, { ...payload, page: 1, per_page: 9999999 })
}

export const getFinalBalanceDownloadAccountDataApi = (payload: any) => {
  return api.postRequest(GET_SINGLE_COLLECTION_LIST_SUMMARY, {
    ...payload,
    page: 1,
    per_page: 9999999
  })
}

export const getInvoiceLevelDownloadDataApi = (payload: any) => {
  return api.postRequest(GET_INVOICE_LEVEL_SUMMARY, payload)
}

export const getV2InvoiceLevelDownloadDataApi = (isExperimentEnabled: boolean, payload: any) => {
  return api.postRequest(
    isExperimentEnabled ? GET_DYNAMIC_V2_INVOICE_LEVEL_SUMMARY : GET_V2_INVOICE_LEVEL_SUMMARY,
    payload
  )
}

export const getReplacementDowloadApi = (payload: any) => {
  return api.postRequest(GET_REPLACEMENT_DOWNLOAD_SUMMARY, payload)
}

export const getV2InvoiceLevelDownloadDataAllBusinessApi = (
  isExperimentEnabled: boolean,
  payload: any
) => {
  return api.postRequest(
    isExperimentEnabled
      ? GET_DYNAMIC_V2_INVOICE_LEVEL_SUMMARY_ALL_BUSINESS
      : GET_V2_INVOICE_LEVEL_SUMMARY_ALL_BUSINESS,
    payload
  )
}

export const getAllBusinessOnlineCollections = (payload: {
  start_time: number
  end_time: number
}) => {
  return api.postRequest(ALL_BUSINESS_ONLINE_COLLECTIONS, payload)
}

export const getInvoiceLevelGroupSummaryDataApi = (payload: any) => {
  return api.postRequest(STATEMENT_GROUP_SUMMARY, payload)
}

export const getFilteredCustomerList = (payload: IFilteredCustomersPayload) => {
  return api.postRequest(GET_FILTERED_CUSTOMER_LIST, payload)
}

export const createNewCollectionListV2Api = (payload: any) => {
  return api.postRequest(CREATE_COLLECTION_LIST_V2, payload)
}

export const fetchSingleCollectionListV2Api = (
  businessId: string,
  collectionListId: string,
  version: CL_VERSIONS
) => {
  return api.postRequest(`${FETCH_COLLECTION_LIST_V2}`, {
    business_id: businessId,
    collection_list_id: collectionListId,
    version
  })
}

export const fetchPendingActionBillDetailsApi = (payload: FetchPendingActionAPIPayload) => {
  return api.postRequest(FETCH_PENDING_ACTION_BILLS_DETAILS, payload)
}

export const getCollectionListInvoiceSummaryV2Api = (payload: {
  business_id: string
  start_time: number
  end_time: number
  page: number
  per_page: number
  collection_list_ids?: Array<string>
  filter:
    | SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
    | SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS
    | SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
  sortBy?: string
}) => {
  return api.postRequest(GET_COLLECTION_LIST_INVOICE_SUMMARY_V2, payload)
}
export const getCollectionListInvoiceSummaryV3Api = (payload: {
  business_id: string
  start_time: number
  end_time: number
  page: number
  per_page: number
  collection_list_ids?: Array<string>
  filter:
    | SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
    | SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS
    | SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
  sortBy?: string
}) => {
  return api.postRequest(GET_COLLECTION_LIST_INVOICE_SUMMARY_V3, payload)
}

export const getApprovedCollectionSummaryApi = (payload: {
  business_id: string
  start_date: number
  end_date: number
  filters?: {
    page: number
    per_page: number
    sort_by?: string
    sort_descending?: boolean
  }
}) => {
  return api.postRequest(OVERALL_APPROVED_TRANSACTIONS, payload)
}

export const getApprovedCollectionsApi = (payload: {
  business_id: string
  start_date: number
  end_date: number
  list_id?: string
  filters?: {
    page: number
    per_page: number
    sort_by?: string
    sort_descending?: boolean
  }
}) => {
  return api.postRequest(APPROVED_TRANSACTIONS, payload)
}

export const getOverallSummaryApi = (payload: { start_time: number; end_time: number }) => {
  return api.postRequest(GET_OVERALL_SUMMARY, {
    ...payload,
    filter: SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
  })
}

export const getCollectionGroupInvoiceSummaryV2Api = (payload: {
  business_id: string
  start_time: number
  end_time: number
  page: number
  per_page: number
  collection_list_id: string
  filter:
    | SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
    | SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS
    | SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
  sort_by?: string
  sort_order?: 1 | 0
}) => {
  return api.postRequest(GET_COLLECTION_GROUP_INVOICE_SUMMARY_V2, payload)
}

export const getCollectionGroupInvoiceSummaryV3Api = (payload: {
  business_id: string
  start_time: number
  end_time: number
  page: number
  per_page: number
  collection_list_id: string
  filter:
    | SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
    | SUMMARY_FILTER_OPTION.OLDER_COLLECTIONS
    | SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
  sort_by?: string
  sort_order?: 1 | 0
}) => {
  return api.postRequest(GET_COLLECTION_GROUP_INVOICE_SUMMARY_V3, {
    ...payload,
    group_id: payload.collection_list_id,
    collection_list_id: null
  })
}

export const getOverallInvoiceSummaryApi = (payload: {
  business_id: string
  start_time: number
  end_time: number
  page: number
  per_page: number
  sort_by?: string
  sort_order?: 1 | 0
}) => {
  return api.postRequest(GET_OVERALL_INVOICE_SUMMARY_V3, {
    ...payload,
    filter: SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
  })
}

export const createGroupByTagsApi = (payload: {
  name: string
  tags: string[]
  filterOption: FILTER_OPTION
}) => {
  return api.postRequest(STATEMENT_GROUP_BY_TAGS, {
    statement_group: {
      name: payload.name,
      filters: {
        tags: payload.tags,
        filter_condition: payload.filterOption
      }
    }
  })
}

export const updateGroupNameApi = (payload: { groupId: string; groupName: string }) => {
  return api.putRequest(STATEMENT_GROUP_BY_TAGS, {
    action: 'update_name',
    statement_group: {
      id: payload.groupId,
      name: payload.groupName
    }
  })
}

export const updateGroupFiltersApi = (payload: {
  groupId: string
  tagIds: string[]
  filterOption?: FILTER_OPTION
}) => {
  return api.putRequest(STATEMENT_GROUP_BY_TAGS, {
    action: 'update_filters',
    statement_group: {
      id: payload.groupId,
      filters: {
        tags: payload.tagIds,
        filter_condition: payload.filterOption
      }
    }
  })
}

export const removeGroupApi = (payload: { groupId: string }) => {
  return api.deleteRequest(`${STATEMENT_GROUP_BY_TAGS}?id=${payload.groupId}`)
}

export const getStatementGroupsApi = (payload: any) => {
  return api.postRequest(GET_STATEMENT_GROUPS, payload)
}

export const getStatementGroupAccountsApi = (payload: any) => {
  return api.postRequest(GET_STATEMENT_GROUP_ACCOUNTS, payload)
}

export const getStatementGroupInvoicesApi = (payload: any) => {
  return api.postRequest(GET_STATEMENT_GROUP_INVOICES, payload)
}

export const getTransactionDetailsApi = (payload: ITransactionPayload) => {
  return api.postRequest(GET_TRANSACTION_DETAILS, payload)
}

export const getStaffMembersApi = () => {
  return api.getRequest(STAFF_MEMBERS, {})
}

export const updateStaffNameApi = (payload: any) => {
  const { staffId, staffName } = payload
  return api.postRequest(`${RENAME_STAFF}?staff_id=${staffId}&name=${staffName}`, {})
}

export const getStaffAccessListApi = (payload: {
  staffUid?: string
  collectionListId?: string
}) => {
  const { staffUid, collectionListId } = payload
  const queryParams = new URLSearchParams()
  if (staffUid) {
    queryParams.append('staff_uid', staffUid)
  }
  if (collectionListId) {
    queryParams.append('resource_id', collectionListId)
  }
  const url = STAFF_ACCESS_LIST + (queryParams.toString() ? `?${queryParams.toString()}` : '')
  return api.getRequest(url, {})
}

export const updateStaffAccessApi = (payload: IUpdateStaffAccessPayload) => {
  return api.postRequest(UPDATE_STAFF_ACCESS, { accesses: payload })
}

export const getThirdPartyConfigsApi = () => {
  return api.getRequest(THIRD_PARTY_CONFIG, {})
}

export const saveThirdPartyConfigApi = (payload: {
  source: TP_SOURCE_TYPE
  config?: ThirdPartyConfigType
  credit_export?: IThirdPartyCreditExportsType
}) => {
  return api.patchRequest(THIRD_PARTY_CONFIG, payload)
}

export const downloadPaymentsForThirdPartyApi = (payload: {
  fromDate: number
  toDate: number
  source: TP_SOURCE_TYPE
}) => {
  const { fromDate, toDate, source } = payload
  return api.getRequest(
    `${DOWNLOAD_PAYMENTS_IN_THIRD_PARTY_FORMAT}?from_date=${fromDate}&to_date=${toDate}&source=${source}`,
    {}
  )
}

export const downloadCreditsForThirdPartyApi = (payload: {
  fromDate: number
  toDate: number
  source: TP_SOURCE_TYPE
}) => {
  const { fromDate, toDate } = payload
  return api.postRequest(EXPORT_BILLS_TALLY_V3, {
    from_date: fromDate,
    to_date: toDate
  })
}

export const fetchBankStatementTemplatesApi = () => {
  return api.postRequest(GET_BANK_STATEMENT_TEMPLATES, {})
}

export const createBankStatementTemplateApi = (payload: CreateBankStatementTemplatePayloadType) => {
  return api.postRequest(CREATE_BANK_STATEMENT_TEMPLATE, payload)
}

export const updateBankStatementTemplateApi = (payload: UpdateBankStatementTemplatePayloadType) => {
  return api.postRequest(UPDATE_BANK_STATEMENT_TEMPLATE, payload)
}

export const uploadBankStatementFileApi = (formData: {
  file: number[]
  template_id: string
  business_id: string
  file_name: string
}) => {
  return api.postRequest(UPLOAD_BANK_STATEMENT_FILE, formData)
}

export const fetchBankStatementDocumentsApi = () => {
  return api.postRequest(GET_BANK_STATEMENT_FILES, {})
}

export const fetchBankStatementReconciledTxnsApi = (payload: {
  start_time: number
  end_time: number
}) => {
  return api.postRequest(GET_BANK_STATEMENT_RECONCILED_TXNS, payload)
}

export const deleteBankStatementTxnsApi = (txnIds: string[]) => {
  return api.postRequest(BULK_UPDATE_BANK_STATEMENT_TXNS, {
    action: 1,
    bk_txn_ids: txnIds
  })
}

export const fetchBankStatementDocumentApi = (documentIds: string[]) => {
  return api.postRequest(GET_SINGLE_BANK_STATEMENT_FILE, {
    document_ids: documentIds
  })
}

export const getRoutesAndBeats = ({
  userId,
  accountId
}: {
  userId: string
  accountId?: string
}) => {
  let url = GET_ROUTES_AND_BEATS
  if (accountId) {
    url += `?acc_ids=${accountId}`
  }
  return api.getRequest(url, {}, { 'okc-user-id': userId })
}

export const updateRoutesAndBeats = ({
  data,
  userId
}: {
  data: IUpdateRouteBeatRequest[]
  userId: string
}) => {
  return api.postRequest(
    `${GET_ROUTES_AND_BEATS}`,
    {
      accs: data
    },
    { 'okc-user-id': userId }
  )
}

export const approveDeliveryFailureApi = (id: string) => {
  return api.postRequest(APPROVE_DELIVERY_FAILURE, { id })
}
export const rejectDeliveryFailureApi = (id: string) => {
  return api.postRequest(REJECT_DELIVERY_FAILURE, { id })
}
export const unmarkRedeliverApi = (billIds: string[]) => {
  return api.postRequest(UNMARD_REDELIVER, { ids: billIds })
}
export const getListWithPengingActions = () => {
  return api.getRequest(LISTS_WITH_PENDING_ACTIONS, {})
}

export const saveFileSettingsReq = (payload: ISavePurchaseBillSettingsReq) => {
  return api.postRequest(SAVE_FILE_SETTINGS, payload)
}

export const fetchFileSettingsReq = () => {
  return api.postRequest(FETCH_FILE_SETTINGS, {})
}

export const uploadPurchaseBillFileReq = (payload: FormData) => {
  return api.postRequest(UPLOAD_PURCHASE_BILL_FILE, payload)
}

export const exportPurchaseFileReq = (payload: {
  export_stock_items: boolean
  export_vouchers: boolean
  file_id: string
}) => {
  return api.postRequest(EXPORT_PURCHASE_FILE, payload)
}

export const getAllPurchaseFiles = () => {
  return api.postRequest(FETCH_PURCHASE_FILES, {})
}
export const getAccountTransactionsApi = ({
  id = '',
  start_time = 0,
  end_time,
  orderBy,
  role = ROLE.CUSTOMER,
  is_asc_order
}: any) => {
  return api.postRequest(GET_ACCOUNT_TRANSACTIONS, {
    type: 1,
    role,
    account_id: id,
    order_by: orderBy || 3,
    start_time,
    ...(end_time ? { end_time } : {}),
    ...(is_asc_order ? { is_asc_order } : {})
  })
}

export const approveBankDeposit = (payload: { business_id: string; bank_deposit_id: string }) => {
  return api.postRequest(APPROVE_BANK_DEPOSIT, payload)
}

export const rejectBankDeposit = (payload: { business_id: string; bank_deposit_id: string }) => {
  return api.postRequest(REJECT_BANK_DEPOSIT, payload)
}

export const approveCashDeposit = (payload: { business_id: string; cash_deposit_id: string }) => {
  return api.postRequest(APPROVE_CASH_DEPOSIT, payload)
}

export const rejectCashDeposit = (payload: { business_id: string; cash_deposit_id: string }) => {
  return api.postRequest(REJECT_CASH_DEPOSIT, payload)
}

export const getPendingActinsDepositBank = (businessId: string) => {
  return api.postRequest(PENDING_BANK_DEPOSITS, { business_id: businessId })
}

export const getPendingActinsDepositCash = (businessId: string) => {
  return api.postRequest(PENDING_CASH_DEPOSITS, { business_id: businessId })
}

export const getCashByStaffSummary = (payload: {
  business_id: string
  start_date: number
  start_time: number
  end_time: number
}) => {
  return api.postRequest(CASH_BALANCE_STATS, payload)
}

export const createAssetsAccounts = (payload: {
  name: string
  business_id: string
  type: string
  account_number?: string
  ifsc?: string
  bank_name?: string
  opening_balance?: number
}) => {
  return api.postRequest(CREATE_ASSETS_ACCOUNTS, payload)
}

export const createInventoryItem = (payload: {
  business_id: string
  name: string
  price?: string
  code: string
  opening_balance: string
  unit: string
}) => {
  return api.postRequest(CREATE_INVOICE_ITEM, payload)
}

export const getInventoryItems = (payload: { business_id: string }) => {
  return api.postRequest(GET_INVOICE_ITEMS, payload)
}

export const getInventoryItemsHistory = (payload: { business_id: string; item_id: string }) => {
  return api.postRequest(GET_INVOICE_ITEM_HISTORY, payload)
}

export const getPurchaseItems = (payload: { business_id: string }) => {
  return api.postRequest(GET_PURCHASE_ITEMS, payload)
}

export const getPurchaseAccount = (payload: { business_id: string }) => {
  return api.postRequest(GET_PURCHASE_ACCOUNT, payload)
}

export const getPurchaseAssetAccount = (payload: { business_id: string }) => {
  return api.postRequest(GET_PURCHASE_ASSET_ACCOUNT, payload)
}

export const createPurchaseAccount = (payload: {
  business_id: string
  name: string
  opening_balance: number
  type: string
}) => {
  return api.postRequest(CREATE_PURCHASE_ACCOUNT, payload)
}

export const createPurchaseInventory = (payload: {
  business_id: string
  payment_account_id: string
  purchase_account_id: string
  amount: number
  item_id: string
  quantity: number
  purchase_date: number
  notes: string
}) => {
  return api.postRequest(CREATE_PURCHASE_INVENTORY, payload)
}

export const createInitiateBankDepositApi = (payload: {
  business_id: string
  bank_account_id: string
  source_type: string
  source_account_id: string
  amount: number
  depositor_type: string
  depositor_id: string
  depositor_name: string
  deposit_date: number
  notes: string
}) => {
  return api.postRequest(CREATE_INITIATE_BANK_DEPOSIT, payload)
}

export const createInitiateCashDepositApi = (payload: {
  business_id: string
  staff_id: string
  amount: number
  deposit_date: number
  notes: string
}) => {
  return api.postRequest(CREATE_INITIATE_CASH_DEPOSIT, payload)
}

export const addExpenseApi = (payload: {
  business_id: string
  payment_account_id: string
  amount: number
  particulars: string
  type: string
  staff_id: string
}) => {
  return api.postRequest(ADD_EXPENSE_API, payload)
}

export const addExpenseApiV1 = (payload: {
  business_id: string
  payment_account_id?: string
  amount: number
  particulars: string
  type?: string
  staff_id?: string
  expense_account_id?: string
  expense_date: number
}) => {
  return api.postRequest(ADD_EXPENSE_API_V1, payload)
}

export const getExpenseApi = (payload: { business_id: string }) => {
  return api.postRequest(GET_EXPENSE_API, payload)
}

export const getInventoryTransactionHistoryApi = (payload: {
  business_id: string
  item_id: string
  limit: number
  offset: number
  type: string
  start_time?: number
  end_time?: number
}) => {
  return api.postRequest(GET_INVENTORY_TRANSACTION_HISTORY, payload)
}

export const getAllBeats = () => {
  return api.getRequest(GET_ALL_BEATS, {})
}
export const getAllInvoiceBeats = (payload: {
  business_id: string
  account_id: string
  account_transaction_id: string
}) => {
  return api.postRequest(GET_ALL_INVOICE_BEATS, payload)
}

export const createBeatRoute = (payload: {
  business_id: string
  beats?: string[]
  routes?: string[]
}) => {
  return api.postRequest(CREATE_BEAT_ROUTE, payload)
}

export const linkDeLinkInvoiceBeats = (payload: ILinkDelinkInvoiceBeatsPayload) => {
  return api.postRequest(LINK_DELINK_BEATS, payload)
}

export const updateBankDepositApi = (payload: {
  business_id: string
  bank_deposit_id: string
  deposit_date: number
  notes: string
}) => {
  return api.postRequest(UPDATE_BANK_DEPOSIT, payload)
}

export const updateCashDepositApi = (payload: {
  business_id: string
  cash_deposit_id: string
  deposit_date: number
  notes: string
}) => {
  return api.postRequest(UPDATE_CASH_DEPOSIT, payload)
}

export const addExpenseAccountApi = (payload: {
  business_id: string
  name: string
  type: string
}) => {
  return api.postRequest(ADD_EXPENSE_ACCOUNT_API, payload)
}

export const getExpenseAccountApi = (payload: { business_id: string }) => {
  return api.postRequest(GET_EXPENSE_ACCOUNT_API, payload)
}

export const updateAssetAccountApi = (payload: {
  business_id: string
  account_id: string
  name: string
}) => {
  return api.postRequest(UPDATE_ASSET_ACCOUNT, payload)
}

export const bankDepositHistoryApi = (payload: {
  business_id: string
  limit: number
  start_time: number
  end_time: number
  offset: number
}) => {
  return api.postRequest(BANK_DEPOSIT_HISTORY, payload)
}

export const cashDepositHistoryApi = (payload: {
  business_id: string
  limit: number
  start_time: number
  end_time: number
  offset: number
}) => {
  return api.postRequest(CASH_DEPOSIT_HISTORY, payload)
}

export const syncInventoryMissingBill = (payload: {
  business_id: string
  start_date: string
  end_date: string
}) => {
  return api.postRequest(SYNC_MISSING_BILLS, payload)
}

export const getDoubleEntry = (payeeType: {
  business_id: string
  account_id: string
  entry_id: string
}) => {
  return api.postRequest(GET_DOUBLE_ENTRY, payeeType)
}

export const addBulkPurchaseInventory = (payload: {
  business_id: string
  units: Array<{
    payment_account_id: string
    purchase_account_id: string
    amount: number
    item_id: string
    quantity: number
    process_id: string
  }>
}) => {
  return api.postRequest(ADD_BULK_PURCHASE_INVENTORY, payload)
}

export const getBulkPurchaseFileInfo = (payload: { business_id: string }) => {
  return api.postRequest(GET_BULK_PURCHASE_FILE_INFO, payload)
}
export const retryBulkPurchaseInventory = (payload: { business_id: string; batch_id: string }) => {
  return api.postRequest(RETRY_BULK_PURCHASE_INVENTORY, payload)
}

export const getBulkPurchaseUnits = (payload: {
  business_id: string
  batch_id: string
  filter_statuses: string[]
}) => {
  return api.postRequest(GET_BULK_PURCHASE_UNITS, payload)
}
