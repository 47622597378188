import dayjs from 'dayjs'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'

/*
 *   Takes a string and generates initials with first letter of first two words in string.
 *   If empty string is passed it returns 'OK'
 *   @arg: string
 *   @return: string
 *   Used in Avatar
 */
export const generateInitials = (str: string = '') => {
  const splitName = str
    .split(' ')
    .map((word) => word.split('')[0])
    .join('')
    .toUpperCase()
  if (splitName.length === 0) {
    return 'OK'
  }
  if (splitName.length < 2) {
    return splitName[0]
  }
  return `${splitName[0]}${splitName[1]}`
}

export const getIsAdmin = () => {
  let isAdmin: boolean
  try {
    isAdmin = AuthStorageInstance.getIsAdmin()
  } catch {
    isAdmin = true
  }
  return isAdmin
}

export const getDefaultCollectionListName = (
  listNameProp: { name: string }[],
  key: 'Collection' | 'Supply'
) => {
  const collectionListNames = listNameProp?.map((itm) => itm?.name?.toLocaleLowerCase())
  let listLength = listNameProp.length
  let listName = `${key} list ${listLength}`

  while (collectionListNames?.includes(listName)) {
    listName = `${key} list ${listLength}`
    listLength += 1
  }
  return listName
}

export const getInvoiceAge = (dateStamp: string | number) => {
  const invoiceDate = dayjs.unix(Number(dateStamp))
  const currentDate = dayjs()
  const difference = currentDate.diff(invoiceDate, 'day')
  return difference ? `${difference} days` : 'Today'
}

export const matchCaseInsensitive = (inputString: string, searchString: string) => {
  let result = ''
  for (const char of inputString) {
    if (searchString.toLowerCase().includes(char.toLowerCase())) {
      result += searchString[searchString.toLowerCase().indexOf(char.toLowerCase())]
    } else {
      result += char
    }
  }
  return result
}
