import { getEnvConfig } from 'utils/getEnvConfig'
import { apiResponseSuccessInterceptor } from './apiInterceptor'
import ApiService from './ApiService'
const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('BLINDPAY_ENDPOINT') || ''

const PAYMENT_DETAILS_ENDPOINT = '/'
const REQUEST_OTP_ENDPOINT = '/RequestBlindPayOtp'
const VERIFY_OTP_ENDPOINT = '/VerifyBlindPayOtp'
const VERIFY_DESTINATION_DETAILS_ENDPOINT = '/VerifyDestination'
const SUBMIT_DESTINATION_DETAILS_ENDPOINT = '/SubmitDestination'

const api = new ApiService(endpoint, headers)
api.responseInterceptor(apiResponseSuccessInterceptor, (error: any) => console.error(error))

const getPaymentDetails = (blindpayId: string) => {
  return api.getRequest(PAYMENT_DETAILS_ENDPOINT + blindpayId, {})
}

const requestOtp = (blindpayId: string) => {
  return api.postRequest(REQUEST_OTP_ENDPOINT, { blind_pay_id: blindpayId })
}

const verifyOtp = (payload: { otp: string; otpId: string; blindPayId: string }) => {
  return api.postRequest(VERIFY_OTP_ENDPOINT, {
    otp: payload.otp,
    otp_id: payload.otpId,
    blind_pay_id: payload.blindPayId
  })
}

const verifyDestinationDetails = (payload: {
  txnToken: string
  paymentAddressType: string
  paymentAddress: string
}) => {
  return api.postRequest(VERIFY_DESTINATION_DETAILS_ENDPOINT, {
    txn_token: payload.txnToken,
    payment_address_type: payload.paymentAddressType,
    payment_address: payload.paymentAddress
  })
}

const submitDestinationDetails = (payload: {
  txnToken: string
  blindPayId: string
  paymentAddressType: string
  paymentAddress: string
}) => {
  return api.postRequest(SUBMIT_DESTINATION_DETAILS_ENDPOINT, {
    txn_token: payload.txnToken,
    blind_pay_id: payload.blindPayId,
    payment_address_type: payload.paymentAddressType,
    payment_address: payload.paymentAddress
  })
}

export const BlindpayApi = {
  getPaymentDetails,
  requestOtp,
  verifyOtp,
  verifyDestinationDetails,
  submitDestinationDetails
}
