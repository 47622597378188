import axios, { AxiosInstance, AxiosStatic } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { getEnvConfig } from 'utils/getEnvConfig'
import { APP_CONSTANT, API_RESPONSE_TYPE, IApiResponse } from './../../constants'

export default class ApiService {
  private readonly library: AxiosStatic
  private readonly instance: AxiosInstance
  private retryCount: number = 0

  public constructor(baseURL: string, headers: { [key: string]: string }, getAxios = () => axios) {
    const newAppendHeader = {
      'X-Request-ID': uuidv4(),
      'X-App': APP_CONSTANT.APP_NAME
    }
    headers = { ...headers, ...newAppendHeader }
    this.library = getAxios()
    this.instance = this.library.create({
      baseURL,
      headers
    })

    // Add a request interceptor to set a new okc-request-id for every request
    this.instance.interceptors.request.use((config) => {
      config.headers['okc-request-id'] = uuidv4()
      return config
    })

    this.instance.interceptors.response.use(
      (response: any) => {
        return response
      },
      async (error: any) => {
        try {
          if (JSON.stringify(error).includes('503')) {
            return {
              data: undefined,
              type: API_RESPONSE_TYPE.FAILURE
            }
          }
          if (error?.response?.status === 500 && typeof window !== 'undefined') {
            if (
              // @ts-ignore
              // eslint-disable-next-line no-alert
              window.alert('Press ok to reload the page, and report the issue to OkCredit team')
            ) {
              window.location.reload()
            } else {
              window.location.reload()
            }
          }
          if (error.response.status === 401 && this.retryCount === 0) {
            this.retryCount = 1
            const refreshToken = AuthStorageInstance.getRefreshToken()
            const authInstance = this.library.create({
              baseURL: getEnvConfig('AUTH_API_ENDPOINT'),
              headers
            })
            const refreshApiResponse = await authInstance({
              method: 'POST',
              withCredentials: true,
              url: '/v1.0/auth',
              data: {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
              }
            })
            const { access_token, refresh_token, expires_in: expiry } = refreshApiResponse.data
            AuthStorageInstance.setAccessToken(access_token)
            const currentTime = new Date()
            const expiresIn = currentTime.getTime() + (expiry - 60) * 1000
            AuthStorageInstance.setExpiresIn(expiresIn)
            AuthStorageInstance.setRefreshToken(refresh_token)
            const retryinstance = this.library.create({
              baseURL
            })
            const finalResponse = await retryinstance({
              ...error.config,
              headers: {
                ...error?.config?.headers,
                Authorization: `Bearer ${access_token}`
              }
            })
            return {
              data: finalResponse.data,
              type: API_RESPONSE_TYPE.SUCCESS
            }
          }
        } catch {
          return Promise.reject(error)
        }

        return Promise.reject(error)
      }
    )
  }
  get(): AxiosInstance {
    return this.instance
  }

  requestInterceptor(interceptor: any, _: any): void {
    this.instance.interceptors.request.use(interceptor)
  }

  responseInterceptor(interceptor: any, _: any): void {
    this.instance.interceptors.response.use(interceptor)
  }

  async getRequest<T>(
    url: string,
    params: T,
    headers?: { [key: string]: string },
    options: any = {}
  ): Promise<IApiResponse> {
    try {
      const result = await this.instance({
        method: 'GET',
        withCredentials: true,
        url,
        params,
        ...(headers ? { headers } : {}),
        ...options
      })
      return {
        data: result.data,
        type: API_RESPONSE_TYPE.SUCCESS
      }
    } catch (error) {
      console.error('AXIOS GET REQUEST ERROR =>', error)
      return {
        data: error,
        type: API_RESPONSE_TYPE.FAILURE
      }
    }
  }

  async postRequest<T>(
    url: string,
    data: T,
    headers?: { [key: string]: string }
  ): Promise<IApiResponse> {
    try {
      const result = await this.instance({
        method: 'POST',
        withCredentials: true,
        url,
        data,
        ...(headers ? { headers } : {})
      })

      return {
        data: result.data,
        type: API_RESPONSE_TYPE.SUCCESS
      }
    } catch (error) {
      console.error('AXIOS POST REQUEST ERROR =>', error)
      return {
        data: error,
        type: API_RESPONSE_TYPE.FAILURE
      }
    }
  }

  async deleteRequest<T>(url: string, data?: T): Promise<IApiResponse> {
    try {
      const result = await this.instance({
        method: 'DELETE',
        url,
        data
      })
      return {
        data: result.data,
        type: API_RESPONSE_TYPE.SUCCESS
      }
    } catch (error) {
      console.error('AXIOS DELETE REQUEST ERROR =>', error)
      return {
        data: error,
        type: API_RESPONSE_TYPE.FAILURE
      }
    }
  }
  async patchRequest<T>(url: string, data: T): Promise<IApiResponse> {
    try {
      const result = await this.instance.patch(url, { ...data })
      return {
        data: result.data,
        type: API_RESPONSE_TYPE.SUCCESS
      }
    } catch (error) {
      console.error('AXIOS PATCH REQUEST ERROR =>', error)
      return {
        data: error,
        type: API_RESPONSE_TYPE.FAILURE
      }
    }
  }
  async putRequest<T>(url: string, data: T): Promise<IApiResponse> {
    try {
      const result = await this.instance.put(url, { ...data })
      return {
        data: result.data,
        type: API_RESPONSE_TYPE.SUCCESS
      }
    } catch (error) {
      console.error('AXIOS PUT REQUEST ERROR =>', error)
      return {
        data: error,
        type: API_RESPONSE_TYPE.FAILURE
      }
    }
  }
}
