import dayjs from 'dayjs'
import {
  Group,
  IPendingDueGroupElement,
  IPendingDueIdData,
  ResponseData,
  accountsWalletBalance,
  pendingDueInvoices
} from './pendingDueSummary.types'

export const convertResponseToStoreFormat = (
  pendingDueInvoices: pendingDueInvoices[]
): IPendingDueIdData[] => {
  return pendingDueInvoices.map((invoice: pendingDueInvoices) => ({
    invoiceNumber: invoice.bill_no,
    invoiceDate: invoice.bill_date,
    invoiceAmount: invoice.bill_amount / 100,
    pendingDue: invoice.pending_due / 100,
    pendingDays: invoice.pending_days,
    customerName: invoice.customer_name
  }))
}

export const getSortedBills = (
  pendingDueInvoices: (pendingDueInvoices & { group_name?: string })[]
) => {
  return pendingDueInvoices.sort(
    (
      a: pendingDueInvoices & { group_name?: string },
      b: pendingDueInvoices & { group_name?: string }
    ) => Number(b.pending_days) - Number(a.pending_days)
  )
}

export const convertResponseToExcelFormat = (
  pendingDueInvoices: (pendingDueInvoices & { group_name?: string })[]
) => {
  return [
    ...pendingDueInvoices.map((invoice: pendingDueInvoices & { group_name?: string }) => ({
      'Invoice Number': invoice.bill_no,
      'Invoice Date': dayjs.unix(invoice.bill_date).format('DD MMM, YYYY'),
      'Invoice Amount': invoice.bill_amount / 100,
      'Pending Due': invoice.pending_due / 100,
      'Pending Days': invoice.pending_days,
      'Customer Name': invoice.customer_name,
      'Customer Code': invoice.customer_code,
      ...(invoice?.group_name && { 'Group Name': invoice.group_name })
    }))
  ]
}

export const convertResponseToWalletSheetFormat = (accounts: accountsWalletBalance[]) => {
  return accounts.map((account: accountsWalletBalance) => ({
    'Customer Name': account.account_name,
    Groups: account.groups.join(','),
    'Opening Wallet Balance': Number(account.usable_wallet_balance) / 100
  }))
}

export const convertResponseToGroupWiseFormat = (
  pendingDueInvoices: (pendingDueInvoices & { group_name?: string })[]
) => {
  const updatedObj: Record<
    string,
    {
      'Group Name'?: string | undefined
      'Invoice Number': string
      'Invoice Date': string
      'Invoice Amount': number
      'Pending Due': number
      'Pending Days': number
      'Customer Name': string
      'Customer Code': string
    }[]
  > = {}

  pendingDueInvoices.forEach((invoice) => {
    if (!invoice?.group_name) {
      return
    }

    if (!updatedObj[invoice.group_name]) {
      updatedObj[invoice.group_name] = []
    }

    updatedObj[invoice.group_name] = [
      ...(updatedObj?.[invoice.group_name] || {}),
      {
        'Invoice Number': invoice.bill_no,
        'Invoice Date': dayjs.unix(invoice.bill_date).format('DD MMM, YYYY'),
        'Invoice Amount': invoice.bill_amount / 100,
        'Pending Due': invoice.pending_due / 100,
        'Pending Days': invoice.pending_days,
        'Customer Name': invoice.customer_name,
        'Customer Code': invoice.customer_code,
        ...(invoice?.group_name && { 'Group Name': invoice.group_name })
      }
    ]
  })

  return updatedObj
}

export const transformResponse = (response: ResponseData): IPendingDueGroupElement[] => {
  return response.groups.map(transformSingleResponse)
}

export const transformSingleResponse = (response: Group) => {
  return {
    listName: response.group_name,
    invoiceCount: Number(response.invoice_count),
    pendingDue: Number(response.pending_due) / 100,
    id: response.group_id,
    ...(response.total_wallet_balance && {
      walletBalance: Number(response.total_wallet_balance) / 100
    })
  }
}

export const computeTotalPendingDue = (
  data: {
    'Group Name'?: string
    'Invoice Number': string
    'Invoice Date': string
    'Invoice Amount': number
    'Pending Due': number
    'Pending Days': number
    'Customer Name': string
  }[]
) => {
  return data.reduce((totalDue, current) => {
    return totalDue + current['Pending Due']
  }, 0)
}
