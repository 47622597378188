import { THEME } from 'components/theme'

const DrawerStyles = {
  main: {
    width: '33%',
    top: 0,
    backgroundColor: THEME.brandBGLight
  },
  whiteBackground: {
    backgroundColor: THEME.brandWhite
  },
  paneHeader: {
    background: THEME.brandBGMedium,
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '8px 20px',
    color: THEME.brandBlack
  },
  field: {
    margin: '16px 20px',
    position: 'relative'
  },
  panel: {
    padding: '16px 20px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: THEME.brandWhite,
    cursor: 'pointer'
  },
  manager: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 20px',
    borderBottom: `1px solid ${THEME.brandLightGrey}`,
    cursor: 'pointer',
    fontSize: 14
  },
  managerIcon: {
    marginLeft: 8,
    marginRight: 12
  },
  panelSeparator: {
    height: '1px',
    width: 'calc(100% - 40px)',
    transform: 'translateX(20px)',
    backgroundColor: THEME.brandLightGrey
  },
  panelSeparatorBig: {
    height: '8px',
    backgroundColor: THEME.brandBGLight
  },
  panelTitle: {
    marginLeft: '12px'
  },
  panelRight: {
    marginLeft: 'auto'
  },
  panelLeft: {
    fontSize: '20px',
    color: THEME.brandMediumGrey,
    marginRight: '12px'
  },
  red: {
    color: THEME.brandRed,
    fill: THEME.brandRed
  },
  deleteIcon: {
    color: THEME.brandRed,
    marginRight: '17px'
  },
  gpsIcon: {
    position: 'absolute',
    top: '36px',
    right: '12px',
    color: THEME.brandDarkGreen,
    cursor: 'pointer'
  },
  tallyIcon: {
    height: '15px',
    width: 'auto',
    marginRight: '12px'
  }
}

export default DrawerStyles
