/**
 * Trigger android back press event.
 */
export const backPress = () => {
  try {
    // @ts-ignore
    if (window.androidObj && window.androidObj.backPress) {
      window.androidObj.backPress()
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * Get the current merchant id
 * @returns string | null
 */
export const getMerchantId = () => {
  try {
    if (typeof window !== 'undefined') {
      if (window.androidObj && window.androidObj.getMerchantId) {
        return window.androidObj.getMerchantId()
      }
    }
  } catch (_) {
    return null
  }
  return null
}

/**
 * Get the current merchant id
 * @returns string | null
 */
export const getBusinessId = () => {
  try {
    if (typeof window !== 'undefined') {
      if (window.androidObj && window.androidObj.getBusinessId) {
        return window.androidObj.getBusinessId()
      }
    }
  } catch (_) {
    return null
  }
  return null
}

/**
 * Get Android version code
 * @returns number
 */
export const getAndroidVersionCode = (): number => {
  try {
    // @ts-ignore
    if (window.androidObj && window.androidObj.getAndroidVersionCode) {
      return window.androidObj.getAndroidVersionCode() || 0
    }
  } catch (e) {
    return 0
  }
  return 0
}

/**
 * Navigate to a particular android activity or deeplink.
 * @param activity string
 */
export const navigateToAndroid = (activity: string) => {
  try {
    // @ts-ignore
    if (window.androidObj && window.androidObj.navigate) {
      window.androidObj.navigate(activity)
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * Send MixPanel event through android bridge.
 * @param eventName Mixpanel Event Name
 * @param props Any props
 * @param callback Fire a callback in case of failure
 */
export const trackAndroidEvent = (
  eventName: string,
  props: Record<string, any>,
  callback = function () {}
) => {
  try {
    if (window.androidObj && window.androidObj.trackEvent) {
      window.androidObj.trackEvent(eventName, JSON.stringify(props))
    } else {
      callback()
    }
  } catch (_) {
    callback()
  }
}

/**
 * return true is webView else false
 * @returns boolean
 */
export const isWebView = () => {
  try {
    if (typeof window !== 'undefined') {
      if (window.androidObj) {
        return true
      }
      return false
    }
    return false
  } catch (_) {
    return false
  }
}
