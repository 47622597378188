import { ICustomer } from 'state/dashboard/dashboard.types'
import { getTruncatedString } from './getTruncatedString'

/* eslint-disable max-len */
export const getWhatsAppLinkMsg = (message: string) => {
  const link = `https://web.whatsapp.com/send?text=${encodeURI(message)}`
  return link
}

const getWhatsappLink = (number: string, message: string) => {
  if (number) {
    const link = `https://web.whatsapp.com/send?text=${encodeURI(message)}&phone=91${number}`
    return link
  }
  //if number is undefined fire a general intent
  const link = `https://web.whatsapp.com/send?text=${encodeURI(message)}`
  return link
}

export const getCollectionListWhatsappLink = ({
  collectionListUrl,
  collectionListName
}: {
  collectionListUrl: string
  collectionListName: string
}) => {
  const message = `Hello,\nSharing ${collectionListName} collection list of the customers with pending dues. Please collect payment from them by showing QR or Sharing Payment Link.\n${collectionListUrl}`
  const link = `https://web.whatsapp.com/send?text=${encodeURI(message)}`
  return link
}

export const getReminderPayload = (customer: ICustomer, merchantName?: string): string => {
  const balance =
    customer.balance <= 0
      ? 'Credit:\nPayment reminder, please make payment as soon as possible.\n'
      : 'Advance:\nAccount update\n'
  const shopOwner = getTruncatedString(merchantName || '', 25)
  const amount = `${Math.abs(customer.balance)} ${customer.balance <= 0 ? 'Due' : 'Advance'}`
  return `Balance in ${balance}. Shop Owner: ${shopOwner}\n*Balance: ₹${amount}*\nVerified by OkCredit - Digital Udhaar Khata\n\nCheck details here:\n${customer.accountUrl}`
}

export const getWhatsappIntentLink = (values: {
  mobile: string
  customer: ICustomer
  merchantName?: string
}) => {
  return `whatsapp://send?phone=${values.mobile}&text=${encodeURIComponent(
    getReminderPayload(values.customer, values.merchantName)
  )}`
}

export default getWhatsappLink
