import { IDispatchCallback } from 'shared/services/Api/types'
import { ENTITY_TYPE } from 'shared/constants'

export enum REFERENCE_SOURCE {
  NA = 0,
  COLLECTION = 1,
  SUBSCRIPTION = 2,
  SUPPLIER_COLLECTION = 3,
  MERCHANT_COLLECTION = 4,
  PAYLATER = 5,
  GROWTH_EXT_TXN = 6,
  BILLING = 7,
  STAFF_LINK = 8,
  BOOK_KEEPING = 9,
  CREDIT_NOTE = 10
}

export enum TRANSACTION_STATE {
  PROCESSING = 0,
  CREATED = 1,
  DELETED = 2
}

export enum TRANSACTION_CATEGORY {
  NORMAL = 0,
  DISCOUNT = 1
}

export enum TRANSACTION_TYPE {
  CREDIT = 1,
  PAYMENT = 2
}

export enum TRANSACTION_SORT_ORDER {
  BY_CREATION_TIME = 3,
  BY_BILLED_TIME = 2
}
export enum ROLE {
  CUSTOMER = 1,
  SUPPLIER = 2
}

export interface ITransactionBill {
  create_time: string
  id: string
  request_id: string
  transaction_id: string
  url: string
}

export interface IImageFile {
  file: File
  tempUrl: string
  requestId: string
}

export interface IBaseTransaction {
  amount: number
  note: string
  transactionState: TRANSACTION_STATE
  transactionCategory: TRANSACTION_CATEGORY
  transactionType: TRANSACTION_TYPE
  images: Array<ITransactionBill>
  id: string
  customerId: string
  deleted: boolean
  deletedTime: number
  createdTime: number
  billedTime: number
  updatedTime: number
  collectionId: string
  accountId: string
  amountUpdated: boolean
  referenceSource: number
  referenceId?: string
  billNumber: string
  outStandingAmount?: number
}

export interface ISupplierTransaction extends IBaseTransaction {
  createdBySupplier: boolean
  deletedBySupplier: boolean
}

export interface ICustomerTransaction extends IBaseTransaction {
  createdByCustomer: boolean
  deletedByCustomer: boolean
}

export interface ICreateTransaction {
  entityId: string
  entityType: ENTITY_TYPE
  transactionType: TRANSACTION_TYPE
  amount: number
  date: {
    day: number
    month: number
    year: number
  }
  note?: string
  images?: any[]
  billNumber?: string
}

export interface ITransactionFilter {
  startTime?: number
  endTime?: number
  label?: string
}

export enum TRANSACTION_REPORT_ACTION {
  DOWNLOAD = 'download',
  PRINT = 'print',
  SHARE = 'share'
}
export interface ITransactionsReport {
  paymentCount: number
  creditCount: number
  payment: number
  credit: number
  transactions: ICustomerTransaction[] | ISupplierTransaction[] | []
  loading: boolean
  isReportUrlLoading: boolean
  reportUrl: string
}
export interface ITransactionsState {
  currentEntity?: {
    type: ENTITY_TYPE
    id: string
  }
  currentTransactions: {
    allIds: string[]
    byIds: {
      [id: string]: ICustomerTransaction | ISupplierTransaction
    }
    loading: boolean
  }
  currentTxnData?: ICustomerTransaction | null
  currentTransactionReport: ITransactionsReport
  transactionBills: {
    currentAction: 'addition' | 'removal' | ''
    lastDeletedBill?: {
      transactionId: string
      imageId: string
    }
    lastAddedBill?: {
      transactionId: string
      imageId: string
    }
  }
  isEditTransactionAmountOpen: boolean
  createTransactionError: string
  fetchingRoutesAndBeats: boolean
  routes: string[]
  beats: string[]
  doubleEntry: IDoubleEntry
}

export interface IDoubleEntry {
  deleted: boolean
  reference: string
  account_name: string
}

export interface ITransactionAction extends IDispatchCallback {
  transactionId: string
  entityType?: ENTITY_TYPE
  entityId?: string
}

export interface IUpdateRouteBeatAction {
  accountId: string
  addBeats?: string[]
  deleteBeats?: string[]
  addRoutes?: string[]
  deleteRoutes?: string[]
}

export interface ITransPagy {
  limit: number
  page: number
  perPage: number
  totalItems: number
  totalPages: number
}

export enum TransactionSource {
  REPORT = 'report'
}
