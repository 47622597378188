import { getEnvConfig } from 'utils/getEnvConfig'
import { IFetchBillsRequestPayload } from 'state/billing/billing.types'
import {
  apiRequestInterceptor,
  apiResponseErrorInterceptor,
  apiResponseSuccessInterceptor,
  businessIdInterceptor
} from './apiInterceptor'
import ApiService from './ApiService'

const DELETE_BILL_ENDPOINT = '/v1/delete-bill'
const FETCH_BILLS_BY_CUSTOMER_ENDPOINT = '/v1/bills/customer'
const GET_FILTERED_ACCOUNTS_BY_BILLS = '/web/v1/bills'
const GET_COLLECTION_LIST_BILLS = '/web/v1/list-pending-bills'
const ADD_TRANSACTION_WITH_BILL = '/web/v1/bill'
const BILLS_SETTLEMENTS_API = '/web/v1/bill-settlements'
const HOLD_MULTIPLE_LISTS = '/web/v1/bills/on-hold'
const FETCH_BILL = '/web/v2/fetch-sale-bills'

const BULK_UPLOAD_INVENTORY_ITEMS = '/web/v1/catalog/upload'
const GET_BULK_UPLOAD_INVENTORY_ITEMS_STATUS = '/web/v1/catalog/upload/status'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

const endpoint = getEnvConfig('BILLING_ENDPOINT') || ''

const api = new ApiService(endpoint, headers)

api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))
api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)

export const deleteBillWithTxnIdReq = (txnId: string) => {
  return api.postRequest(DELETE_BILL_ENDPOINT, {
    transaction_id: txnId
  })
}

export const deleteBillWithBillId = (billId: string) => {
  return api.postRequest(DELETE_BILL_ENDPOINT, {
    bill_id: billId
  })
}

export const fetchBillSettlements = (payload: {
  transactionType: 'CREDIT' | 'PAYMENT'
  accountsTxnId: string
}) => {
  return api.getRequest(
    `${BILLS_SETTLEMENTS_API}?transaction_kind=${payload.transactionType}&payment_reference_id=${payload.accountsTxnId}`,
    {}
  )
}
export const fetchBillsByCustomer = (payload: {
  customer_id: string
  per_page?: number
  page?: number
  bill_number?: string
  from_date?: number
  to_date?: number
}) => {
  const { customer_id, per_page, page, bill_number, from_date, to_date } = payload
  const url =
    FETCH_BILLS_BY_CUSTOMER_ENDPOINT +
    `?customer_id=${customer_id}` +
    (per_page ? `&per_page=${per_page}` : '') +
    (page ? `&page=${page}` : '') +
    (bill_number ? `&bill_number=${bill_number}` : '') +
    (from_date || from_date === 0 ? `&from_date=${from_date}` : '') +
    (to_date || to_date === 0 ? `&to_date=${to_date}` : '')
  return api.getRequest(url, {})
}

export const getFilteredAccountsByBills = (billNo: string) => {
  return api.getRequest(`${GET_FILTERED_ACCOUNTS_BY_BILLS}?bill_number=${billNo}`, {})
}

export const getCollectionBillsDetailsApi = ({
  startTime,
  endTime
}: {
  startTime?: number
  endTime?: number
}) => {
  const url =
    `${GET_COLLECTION_LIST_BILLS}?page=1&per_page=9999999` +
    (startTime || startTime === 0 ? `&start_time=${startTime}` : '') +
    (endTime || endTime === 0 ? `&end_time=${endTime}` : '')
  return api.getRequest(url, {})
}

export const createCustomerTransactionWithBills = (payload: object) => {
  return api.postRequest(ADD_TRANSACTION_WITH_BILL, payload)
}

export const holdMultipleListsReq = (billIds: string[], toHold: boolean) => {
  return api.postRequest(HOLD_MULTIPLE_LISTS, {
    bill_ids: billIds,
    on_hold: toHold
  })
}

export const fetchBills = (payload: IFetchBillsRequestPayload) => {
  return api.postRequest(FETCH_BILL, payload)
}

export const bulkUploadInventoryItemsApi = (payload: FormData) => {
  return api.postRequest(BULK_UPLOAD_INVENTORY_ITEMS, payload, {
    'Content-Type': 'multipart/form-data'
  })
}

export const getBulkUploadInventoryItemsStatusApi = (documentId: string) => {
  return api.getRequest(`${GET_BULK_UPLOAD_INVENTORY_ITEMS_STATUS}?document_id=${documentId}`, {})
}
