import {
  BILL_DEFAULT_NUMBER,
  BILL_NUMBER_PREFIX,
  DEFAULT_CUSTOMER
} from 'pages/NewDashboard/components/Billing/hooks/constant'
import {
  IOrdersState,
  ORDERS_ENTITY,
  PENDING_ORDERS_FILTER,
  SORT_OPTION
  // OrderedByType
} from 'state/new-summary/orders/orders.types'
import { getToday } from 'utils/time'

export const InitialOrderStateValues: IOrdersState = {
  pendingOrdersFilter: {
    startTime: 0,
    endTime: 0,
    filterType: PENDING_ORDERS_FILTER.DEFAULT
  },
  pendingOrdersRowData: [],
  pageSize: 50,
  currentPage: 1,
  totalEntries: 0,
  sortOption: SORT_OPTION.CUSTOMER_NAME,
  resetSelectedRowRef: {
    ordersPendingTable: null,
    ordersApprovalTable: null,
    proformaInvoicesTable: null
  },
  selectedOrderIds: [],
  showPendingOrdersApproveBtn: false,
  drawers: {
    orderPendingFilter: false,
    rejectOrder: false,
    rejectMultipleOrders: false,
    orderApprovalFilter: false,
    showOrderItemsPreview: false,
    orderCollectionDrawer: false,
    deleteProforma: false,
    deleteMultipleProformas: false
  },
  loaders: {
    isFetchingOrders: false,
    isFetchingApprovedOrders: false,
    isFetchingSelectedCatalogue: false,
    isFetchingProforma: false,
    isCreatingBill: false
  },
  isCreateBillFromOrders: false,
  isCreateProformaFromOrder: false,
  isCreateBillFromProforma: false,
  selectedOrderDetails: null,
  searchString: '',
  addedItems: [],
  selectedDiscounts: [],
  selectedCharges: [],
  selectedItemIndices: [],
  customerDetails: DEFAULT_CUSTOMER,
  invoiceDetails: {
    invoiceNumber: BILL_DEFAULT_NUMBER,
    invoiceDate: getToday(),
    invoicePrefix: BILL_NUMBER_PREFIX
  },
  payableAmount: 0,
  discountAmount: 0,
  showApprovalOrdersApproveBtn: false,
  approvalOrdersRowData: [],
  approvalOrdersFilter: {
    startTime: 0,
    endTime: 0,
    filterType: PENDING_ORDERS_FILTER.DEFAULT
  },
  selectedApprovedOrderIds: [],
  selectedMultipleOrders: null,
  selectedProformaOrderIds: [],
  proformaInvoicesRowData: [],
  proformaOrdersFilter: {
    startTime: 0,
    endTime: 0,
    filterType: PENDING_ORDERS_FILTER.DEFAULT
  },
  proformaInvoicesApproveBtn: false,
  activeOrdersEntity: ORDERS_ENTITY.INVOICE,
  selectedProformaInvoices: [],
  proformasHtmls: [],
  ordersHtml: []
}

export const OrdersState = InitialOrderStateValues
