// import { select } from 'redux-saga/effects'
import { Mixpanel } from 'services/Tracking/Mixpanel'
import { Action, IPayload } from './StaffLink.types'
import { StaffLinkAnalyticsActions } from './StaffLink.actions'

const track = (event: string, paramObj: object = {}) => {
  Mixpanel.track(event, paramObj)
  // console.log(`%c${event}`, 'background: #222; color: #bada55', paramObj) // TODO: Comment this before going to prod
}

const identify = (merchantId: string) => {
  Mixpanel.identify(merchantId)
  // console.log(`%cIdentify called with: ${merchantId}`, 'background: #222; color: #bada55') // TODO: Comment this before going to prod
}

export default function* StaffLinkAnalytics<T>(action: Action<T>) {
  switch (action.type) {
    // Event 1
    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_VIEW_PAGE: {
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount
      }: IPayload = action.payload

      yield identify(`${merchantId}`) // Error, if not used like this
      yield track('collections_list_view_page', {
        merchant_id: merchantId,
        list_id: listId,
        customer_count: customerCount,
        // total_due_amount: totalDueAmount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_REFRESH: {
      // Event 2
      const { merchantId, listId }: IPayload = action.payload
      yield track('collections_list_refresh', {
        merchant_id: merchantId,
        list_id: listId
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_SEARCH: {
      // Event 3
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount,
        type
      }: IPayload = action.payload
      yield track('collections_list_search', {
        merchant_id: merchantId,
        list_id: listId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount,
        type
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_SEARCH_START: {
      // Event 4
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount,
        type
      }: IPayload = action.payload
      yield track('collections_list_search_start', {
        merchant_id: merchantId,
        list_id: listId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount,
        type
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_CALL: {
      // Event 5
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount,
        type,
        accountId
      }: IPayload = action.payload
      yield track('collections_list_call', {
        merchant_id: merchantId,
        list_id: listId,
        type,
        account_id: accountId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_VIEW_QR: {
      // Event 6
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount,
        cashbackMessageVisible,
        accountId
      }: IPayload = action.payload
      yield track('collections_list_view_qr', {
        merchant_id: merchantId,
        list_id: listId,
        cashback_message_visible: cashbackMessageVisible,
        account_id: accountId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_SHARE_PAYMENT_LINK: {
      // Event 10
      const {
        merchantId,
        listId,
        cashbackMessageVisible,
        accountId,
        customerCount,
        pendingDuesAmount,
        pendingDuesCustomerCount
      }: IPayload = action.payload
      yield track('collections_list_share_payment_link', {
        merchant_id: merchantId,
        list_id: listId,
        cashback_message_visible: cashbackMessageVisible,
        account_id: accountId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_PAYMENT_NOTIFICATION_POPUP: {
      // Event 7
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount,
        status,
        accountId
      }: IPayload = action.payload
      yield track('collections_list_payment_notification_popup', {
        merchant_id: merchantId,
        list_id: listId,
        status,
        account_id: accountId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_CUSTOMER_VIEW_MORE: {
      // Event 8
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount,
        accountId
      }: IPayload = action.payload
      yield track('collections_list_customer_view_more', {
        merchant_id: merchantId,
        account_id: accountId,
        list_id: listId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_COLLECTIONS_COMPLETED: {
      // Event 9
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount
      }: IPayload = action.payload
      yield track('collections_list_collections_completed', {
        merchant_id: merchantId,
        list_id: listId,
        customer_count: customerCount,
        pending_dues_amount: pendingDuesAmount,
        pending_dues_customer_count: pendingDuesCustomerCount
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_CHECK_PAYMENT_STATUS: {
      // Event 11
      const { merchantId, listId, accountId }: IPayload = action.payload
      yield track('collections_list_check_payment_status', {
        merchant_id: merchantId,
        list_id: listId,
        account_id: accountId
      })
      break
    }

    case StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_VIEW_SOMETHING_WENT_WRONG_PAGE: {
      // Event 12
      const {
        merchantId,
        listId,
        customerCount,
        pendingDuesCustomerCount,
        pendingDuesAmount,
        currentUrl,
        errorMsg
      }: IPayload = action.payload
      yield identify(`${merchantId}`) // Error, if not used like this
      yield track('collections_list_view_something_went_wrong', {
        merchant_id: merchantId,
        list_id: listId,
        customer_count: customerCount,
        pending_dues_customer_count: pendingDuesCustomerCount,
        pending_dues_amount: pendingDuesAmount,
        current_url: currentUrl,
        error_msg: errorMsg
      })
      break
    }
    case StaffLinkAnalyticsActions.TRACK_OPEN_MENU_CLICK: {
      yield track('stafflink/open_menu', action.payload as any)
      break
    }
    case StaffLinkAnalyticsActions.TRACK_MENU_ACTION_CLICK: {
      yield track('stafflink/menu_action', action.payload as any)
      break
    }
    case StaffLinkAnalyticsActions.TRACK_SHORTCUT_ACTION_CLICK: {
      yield track('stafflink/shortcut_action', action.payload as any)
      break
    }
  }
}
