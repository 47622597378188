import { DOCUMENT_TYPE } from 'state/new-summary/uploadSheet/uploadSheet.types'
import {
  fileFormatOptions,
  itemDatailsOptions,
  templateFormatType
} from '../../DocumentFormatConfigure.helpers'

export const templateDetailsFormDetails = [
  {
    label: 'Template Name',
    placeholder: 'Template Name',
    fieldName: 'name'
  },
  {
    label: 'File Format',
    placeholder: '',
    fieldName: 'document_type',
    options: fileFormatOptions
  }
]

export const sheetDetailsExcelFormDetails = [
  {
    label: 'Sheet Name',
    placeholder: 'Sheet Name',
    fieldName: 'name'
  },
  {
    label: 'Header Row Number',
    placeholder: 'Header Row Number',
    fieldName: 'header'
  },
  {
    label: 'Contain Item Details',
    placeholder: '',
    fieldName: 'isItemDatails',
    options: itemDatailsOptions
  }
]

export const sheetDetailsCSVFormDetails = [
  {
    label: 'Header Row Number',
    placeholder: 'Header Row Number',
    fieldName: 'header'
  }
]

export const columnDetailsFormDetails = [
  {
    label: 'Invoice Number',
    placeholder: 'Invoice Number',
    fieldName: 'invoiceNumber'
  },
  {
    label: 'Mobile Number',
    placeholder: 'Mobile Number',
    fieldName: 'mobileNumber'
  },
  {
    label: 'Customer Name',
    placeholder: 'Customer Name',
    fieldName: 'customerName'
  },
  {
    label: 'Amount',
    placeholder: 'Amount',
    fieldName: 'amount'
  },
  {
    label: 'Customer Code',
    placeholder: 'Customer Code',
    fieldName: 'customerCode'
  },
  {
    label: 'Invoice Date (Optional)',
    placeholder: 'Invoice Date',
    fieldName: 'invoiceDate'
  },
  {
    label: 'Sale Type (Optional)',
    placeholder: 'Sale Type',
    fieldName: 'saleType'
  },
  {
    label: 'Sale Route (Optional)',
    placeholder: 'Sale Route',
    fieldName: 'saleRoute'
  },
  {
    label: 'Settlement ID (Optional)',
    placeholder: 'Settlement ID',
    fieldName: 'paymentReconBillNumber'
  },
  {
    label: 'Account Beat (Optional)',
    placeholder: 'Account Beat',
    fieldName: 'accountBeat'
  },
  {
    label: 'Account Route (Optional)',
    placeholder: 'Account Route',
    fieldName: 'accountRoute'
  },
  {
    label: 'Credit Note Numbers (Optional)',
    placeholder: 'Credit Note Numbers',
    fieldName: 'appliedCNs'
  }
]

export const tagColumnFields = [
  {
    label: 'Account Tag (Optional)',
    placeholder: 'Account Tag',
    fieldName: 'accountTag'
  },
  {
    label: 'Invoice Tag (Optional)',
    placeholder: 'Invoice Tag',
    fieldName: 'invoiceTag'
  }
]

export const beatColumnFields = [
  {
    label: 'Invoice Beat (Optional)',
    placeholder: 'Invoice Beat',
    fieldName: 'invoiceBeats'
  }
]

export const columnFieldsForDownload = [
  'amount',
  'customerCode',
  'customerName',
  'invoiceDate',
  'invoiceNumber',
  'mobileNumber',
  'saleRoute',
  'saleType',
  'paymentReconBillNumber',
  'accountBeat',
  'accountRoute',
  'appliedCNs'
]

export const tagColumnFieldsForDownload = ['accountTag', 'invoiceTag', 'invoiceBeats']

export const DOCUMENT_TYPE_VS_EXTENSION: Record<DOCUMENT_TYPE.EXCEL | DOCUMENT_TYPE.CSV, string> = {
  [DOCUMENT_TYPE.EXCEL]: 'xlsx',
  [DOCUMENT_TYPE.CSV]: 'csv'
}

export const templateFormatType_VS_prefilledFormDetails = {
  [templateFormatType.MARG]: {
    id: '',
    name: 'MARG',
    document_type: 2,
    config: {
      sheetDetails: {
        header: 8,
        name: 'MARG ERP 9+ Excel Report',
        no_bill_rows: false
      },
      columnFormula: null,
      columnMapping: {
        notes: '',
        amount: 'BILL VALUE',
        saleType: '',
        invoiceDate: 'DATE',
        customerCode: 'CODE',
        customerName: 'PARTY NAME',
        mobileNumber: 'MOBLIE',
        invoiceNumber: 'BILL NO.',
        advanceColumns: null
      }
    }
  },
  [templateFormatType.PACE]: {
    id: '',
    name: 'PACE',
    document_type: 2,
    config: {
      sheetDetails: {
        name: 'Sheet2',
        header: 1,
        no_bill_rows: false
      },
      columnMapping: {
        notes: '',
        amount: 'Amount Payable',
        address: '',
        saleType: '',
        saleRoute: 'Sales Route',
        invoiceTag: '',
        accountBeat: '',
        invoiceDate: '',
        accountRoute: '',
        customerCode: 'Customer Code',
        customerName: 'Customer Name',
        mobileNumber: 'Moblie Number',
        invoiceNumber: 'Doc No',
        paymentReconBillNumber: '',
        advanceColumns: null
      }
    }
  },
  [templateFormatType.TALLY]: {
    id: '',
    name: 'TALLY',
    document_type: 2,
    config: {
      sheetDetails: {
        name: 'Sales Register',
        header: 7,
        no_bill_rows: false
      },
      columnFormula: null,
      columnMapping: {
        notes: '',
        amount: 'Debit',
        address: '',
        saleType: '',
        saleRoute: '',
        accountTag: '',
        invoiceTag: '',
        accountBeat: '',
        invoiceDate: 'Date',
        accountRoute: '',
        customerCode: 'Code',
        customerName: 'Particulars',
        mobileNumber: 'Mob',
        invoiceNumber: 'Vch No.',
        paymentReconBillNumber: '',
        advanceColumns: null
      }
    }
  },
  [templateFormatType.P_AND_G]: {
    id: '',
    name: 'P&G',
    document_type: 2,
    config: {
      sheetDetails: {
        name: 'Product Sales Report OPTZ202408',
        header: 1,
        no_bill_rows: true
      },
      columnFormula: null,
      columnMapping: {
        amount: 'net_amount',
        saleType: ' ',
        saleRoute: 'dse_code',
        accountTag: ' ',
        appliedCNs: '',
        invoiceTag: 'dse_code',
        accountBeat: '',
        invoiceDate: 'document_date',
        accountRoute: '',
        customerCode: 'customer_code',
        customerName: ' customer_name',
        invoiceBeats: '',
        mobileNumber: 'Mobile',
        invoiceNumber: 'document_no',
        invoiceRoutes: '',
        paymentReconBillNumber: '',
        advanceColumns: {
          hsn: 'hsn_code',
          mrp: 'MRP',
          cess: 'cess_amount',
          cgst: 'cgst_amount',
          igst: 'igst_amount',
          rate: 'rate',
          sgst: 'sgst_amount',
          billTo: 'customer_name',
          shipTo: 'customer_name',
          soldTo: 'customer_name',
          discount: 'discount',
          itemType: 'category',
          itemUnit: 'quantity',
          quantity: 'quantity',
          orderDate: 'document_date',
          itemNumber: ' Item',
          orderNumber: 'document_no',
          productCode: 'pcode',
          productDescription: 'prod_name'
        }
      }
    }
  },
  [templateFormatType.OTHERS]: {
    id: '',
    name: '',
    document_type: 2,
    config: {
      sheetDetails: {
        name: '',
        header: '',
        no_bill_rows: false
      },
      columnMapping: {
        invoiceNumber: '',
        mobileNumber: '',
        customerName: '',
        amount: '',
        customerCode: '',
        invoiceDate: '',
        saleType: '',
        saleRoute: '',
        paymentReconBillNumber: '',
        accountTag: '',
        invoiceTag: '',
        accountBeat: '',
        accountRoute: '',
        advanceColumns: null
      }
    }
  }
}
