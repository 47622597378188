import { Action } from '../../infra/types'
import { NavigationActions } from './navigation.actions'
import { NavigationState } from './navigation.state'
import { INavigationState } from './navigation.types'

export const NavigationReducers = (
  state: INavigationState = NavigationState,
  action: Action<any>
): INavigationState => {
  switch (action.type) {
    case NavigationActions.ROUTE_CHANGE: {
      const { currentRoute } = state
      return {
        ...state,
        prevRoute: { ...currentRoute },
        currentRoute: { ...action.payload }
      }
    }
    default: {
      return state
    }
  }
}
