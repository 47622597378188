import { call, put, select } from 'redux-saga/effects'
import { generatePath } from 'react-router-dom'
import dayjs from 'dayjs'
import { Action } from 'infra/types'
import { BlindpayApi } from 'services/Api/BlindpayApi'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { NotificationType } from 'pages/Notification/Notification.types'
import { getErrorMessage } from 'services/Api/Errors'
import { IAppState } from 'infra/AppState'
import { Navigation } from 'services/navigation'
import { blindpayPaths } from 'routes'
import { IApiResponse, API_RESPONSE_TYPE } from '../../constants'
import { paymentDetailsFormatter } from './helpers/formatter'
import { IPaymentDetail, LINK_STATUS } from './blindpay.types'
import {
  blindpayPageView,
  fetchPaymentDetailsSuccess,
  fetchPaymentDetailsFailure,
  requestOtpSuccess,
  verifyDestinationDetailsSuccess,
  verifyDestinationDetailsFailure,
  verifyOtpSuccess,
  verifyOtpFailure,
  submitDestinationDetailsSuccess,
  postClaimPageView
} from './blindpay.actions'

export function* fetchPaymentDetails(action: Action<string>) {
  try {
    const response: IApiResponse = yield call(BlindpayApi.getPaymentDetails, action.payload)

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const paymentDetail: IPaymentDetail = paymentDetailsFormatter(response.data)
      yield put(fetchPaymentDetailsSuccess(paymentDetail))
      if (paymentDetail.linkStatus === LINK_STATUS.ACTIVE) {
        yield put(blindpayPageView(LINK_STATUS.ACTIVE))
      } else if (paymentDetail.linkStatus === LINK_STATUS.EXPIRED) {
        yield put(blindpayPageView(LINK_STATUS.EXPIRED))
        Navigation().to(`/easypay/${action.payload}/expired`)
      } else if (paymentDetail.linkStatus === LINK_STATUS.CLAIMED) {
        yield put(postClaimPageView(LINK_STATUS.CLAIMED))
        Navigation().to(`/easypay/${action.payload}/claimed`)
      }
    } else {
      if (response.data?.response?.status === 404 || response.data?.response?.status === 400) {
        Navigation().to('/404')
      }
      yield put(fetchPaymentDetailsFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e) {
    yield put(fetchPaymentDetailsFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* requestOtp(action: Action<string>) {
  try {
    const response: IApiResponse = yield call(BlindpayApi.requestOtp, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(requestOtpSuccess(response.data?.otp_id))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* verifyOtp(action: Action<{ otp: string; otpId: string; blindPayId: string }>) {
  try {
    const response: IApiResponse = yield call(BlindpayApi.verifyOtp, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        verifyOtpSuccess({
          txnToken: response.data?.txn_token,
          expiryTime: dayjs().add(Number(response.data?.expiry_time), 's')
        })
      )
      Navigation().to(
        generatePath(blindpayPaths.destinationDetails, { blindPayId: action.payload.blindPayId })
      )
    } else {
      yield put(verifyOtpFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* verifyDestinationDetails(
  action: Action<{ paymentAddressType: string; paymentAddress: string }>
) {
  try {
    const txnToken: string = yield select((state: IAppState) => state.Blindpay.txnToken)
    const response: IApiResponse = yield call(BlindpayApi.verifyDestinationDetails, {
      ...action.payload,
      txnToken
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        verifyDestinationDetailsSuccess({
          name: response.data?.name,
          isValid: response.data?.is_valid,
          paymentAddressType: action.payload.paymentAddressType
        })
      )
    } else {
      yield put(verifyDestinationDetailsFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e) {
    yield put(verifyDestinationDetailsFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* submitDestinationDetails(
  action: Action<{ blindPayId: string; paymentAddressType: string; paymentAddress: string }>
) {
  try {
    const txnToken: string = yield select((state: IAppState) => state.Blindpay.txnToken)
    const response: IApiResponse = yield call(BlindpayApi.submitDestinationDetails, {
      ...action.payload,
      txnToken
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS && response.data?.is_success) {
      yield put(
        submitDestinationDetailsSuccess({
          paymentId: response.data?.payment_id,
          paymentAddressType: action.payload.paymentAddressType
        })
      )
      yield put(postClaimPageView(LINK_STATUS.INITIATED))
      Navigation().to(`/easypay/${action.payload.blindPayId}/success`)
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}
