import { IOrderQRState } from './OrderQR.types'

export const OrderQRState: IOrderQRState = {
  merchantId: '',
  authToken: '',
  kycStatus: false,
  fetchedAddress: false,
  authV3: false,
  defLang: 'en',
  backClick: false,
  bankStatus: '',
  currentLimit: '',
  merchantDetails: {
    name: '',
    mobile: '',
    type: '',
    payment_address: ''
  },
  shippingAddress: {
    merchant_id: '',
    create_time: '',
    shop_number: '',
    street_address: '',
    landmark: '',
    pincode: '',
    city: '',
    state: '',
    status: '',
    marked_delivered_by: '',
    source: '',
    tracking_number: '',
    expected_delivery_date: 0
  },
  orderConfirmed: false,
  loading: false, // Tracks the global loading state
  pageError: '', // Tracks the global error message state
  updateUpiSuccess: false,
  merchantProfile: {
    business_id: '',
    destination: {
      name: '',
      mobile: '',
      type: '',
      payment_address: '',
      upi_vpa: '',
      email: ''
    },
    merchant_vpa: '',
    merchant_link: '',
    limit_type: '',
    limit: '',
    merchant_qr_enabled: false,
    remaining_limit: '',
    payment_provider: '',
    qr_intent: '',
    risk_category: '',
    kyc_status: ''
  },
  retrieveSubscription: {
    subscription: ''
  },
  subscription: {
    status: ''
  },
  qrVpa: {
    qr_vpa: ''
  }
}
