import { Action } from 'infra/types'
import { IReturns } from 'services/Api/StaffLinkApiFolder/type'

export const returnsActions = {
  SET_RETURNS_DRAWER_DATA: 'returns/setReturnsDrawersData'
}

export const setReturnsDrawerWithData = (payload: {
  status: boolean
  data?: IReturns
}): Action<{ status: boolean; data?: IReturns }> => {
  return {
    type: returnsActions.SET_RETURNS_DRAWER_DATA,
    payload
  }
}
