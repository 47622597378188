import React from 'react'
import { Redirect, Route, Switch, useHistory, generatePath } from 'react-router'
import loadable from '@loadable/component'
import { useSelector } from 'react-redux'
import { newPaths, paths } from 'routes'
import lazyRetry from 'utils/lazyRetry'
import { NotificationList } from 'pages/Notification/NotificationList'
import { IAppState } from 'infra/AppState'
import { ENTITY_TYPE } from 'shared/constants'
import DefaulterPage from 'pages/DefaulterPage'
import { useAbExperiment } from 'utils/useAbExperiment'
import ManagerDetails from 'pages/Dashboard/components/Drawers/ManagerDetails'
import Header from './components/Header'

const Summary = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "Summary" */ './components/Summary'))
)

const ApproveRoutes = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "ApproveRoutes" */ './components/ApproveRoutes'))
)

const SupplyList = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "SupplyList" */ './components/SupplyList'))
)

const CollectionList = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "CollectionList" */ './components/CollectionList'))
)

const Ledger = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "CollectionList" */ '../../pages/Ledger'))
)

const CustomerProfiles = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "CollectionList" */ './components/CustomerProfiles'))
)

const Reconcile = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "Reconcile" */ './components/Reconcile'))
)

const Billing = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "Billing" */ './components/Billing'))
)

const Help = loadable(() => lazyRetry(() => import('./components/HelpSection')))
const Settings = loadable(() => lazyRetry(() => import('../Dashboard/components/Drawers/Settings')))
const SwitchAccount = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/SwitchAccount'))
)

const MerchantProfileEditor = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/MerchantProfileEditor'))
)

const TagManagement = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/Tags/TagManagement'))
)

const AccountTags = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/Tags/AccountTags'))
)

const AccountBeats = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/Beats/AccountBeats'))
)

const AccountRoutes = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/Beats/AccountRoutes'))
)

const TransactionDetail = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/TransactionDetail'))
)

const AddCustomerSupplierPopup = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/AddCustomerSupplierPopup'))
)
const AddCustomerSupplierSuccessPopup = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/AddCustomerSupplierSuccessPopup'))
)
const AddTransaction = loadable(() =>
  lazyRetry(() => import('pages/Transactions/components/AddTransaction'))
)
const CustomerSupplierProfileEditor = loadable(() =>
  lazyRetry(() => import('../Dashboard/components/Drawers/CustomerSupplierProfileEditor'))
)

const CommonRootLevelDrawers = loadable(() =>
  lazyRetry(() => import(/* webpackChunkName: "Root Drawers" */ './components/CommonDrawers'))
)

const NewDashboard = () => {
  const history = useHistory()
  const { staffLink } = useAbExperiment()
  const navigationState = useSelector((appState: IAppState) => appState.Navigation)
  const entity = navigationState.currentRoute.navParams.entity || ENTITY_TYPE.CUSTOMER
  const id = navigationState.currentRoute.navParams.id || ''
  const onClose = () => {
    if (navigationState?.prevRoute?.pathName) {
      history.goBack()
    } else {
      history.push(generatePath(staffLink ? newPaths.approve : newPaths.ledger))
    }
  }
  return (
    <React.Fragment>
      <Route path={newPaths.base}>
        <Header>
          <Switch>
            <Route path={newPaths.approve} render={() => <ApproveRoutes />} />
            <Route path={newPaths.supplyList} render={() => <SupplyList />} />
            <Route path={newPaths.collectionList} render={() => <CollectionList />} />
            <Route path={newPaths.summary} render={() => <Summary />} />

            <Route path={newPaths.ledger} exact render={() => <Ledger />} />
            <Route path={newPaths.customersProfile} render={() => <CustomerProfiles />} />
            <Route path={newPaths.reconcile} render={() => <Reconcile />} />
            <Route path={newPaths.billing} render={() => <Billing />} />
            <Route path={newPaths.help} render={() => <Help onClose={onClose} />} />
            <Route path={newPaths.settings} render={() => <Settings onClose={onClose} />} />
            <Route exact path={newPaths.editMerchant} render={() => <MerchantProfileEditor />} />
            <Route
              path={newPaths.manageAccounts}
              render={() => <SwitchAccount onClose={onClose} />}
              exact
            />
            <Route
              path={newPaths.managerDetails}
              render={() => <ManagerDetails onClose={onClose} />}
              exact
            />
            <Route exact path={newPaths.tags} render={() => <TagManagement onClose={onClose} />} />
            <Route
              exact
              path={newPaths.editEntity}
              render={() => (
                <CustomerSupplierProfileEditor
                  onClose={onClose}
                  entityType={entity}
                  entityId={id}
                />
              )}
            />
            <Route
              exact
              path={newPaths.addTransaction}
              render={() => <AddTransaction onClose={onClose} />}
            />
            <Route
              exact
              path={newPaths.addEntity}
              render={() => <AddCustomerSupplierPopup entityType={entity} onClose={onClose} />}
            />
            <Route
              exact
              path={newPaths.addEntitySuccess}
              render={() => (
                <AddCustomerSupplierSuccessPopup entityType={entity} onClose={onClose} />
              )}
            />
            <Route
              exact
              path={newPaths.transactionDetails}
              render={() => <TransactionDetail onClose={onClose} />}
            />
            <Route
              exact
              path={newPaths.accountTags}
              render={() => <AccountTags onClose={onClose} />}
            />
            <Route
              exact
              path={newPaths.accountBeats}
              render={() => <AccountBeats onClose={onClose} />}
            />
            <Route
              exact
              path={newPaths.accountRoutes}
              render={() => <AccountRoutes onClose={onClose} />}
            />
            <Redirect to={staffLink ? newPaths.approve : newPaths.ledger} />
          </Switch>
        </Header>
        <NotificationList />
        <CommonRootLevelDrawers />
      </Route>
      <Route path={paths.defaulterLink}>
        <DefaulterPage />
      </Route>
    </React.Fragment>
  )
}

export default NewDashboard
