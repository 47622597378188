import { Day } from '@hassanmojab/react-modern-calendar-datepicker'
import {
  BillDataType,
  SelectedChargeType,
  SelectedDiscountType
} from 'pages/NewDashboard/components/Billing/hooks/constant'
import {
  OFFER_RESPONSE,
  OPERATION_TYPE,
  PAYLOAD_ITEMS_TYPE
} from 'services/Api/BillingApiFolder.ts/type'
import { BILL_STATUS } from 'utils/common/common.types'

export interface IBillingState {
  loaders: {
    // showPreviewDrawer: boolean
    showAddedBillsDrawers: boolean
    addingItem: boolean
    deletingBills: boolean
    deletingItems: boolean
    savePurchaseBill: boolean
    fetchPurchaseBill: boolean
    uploadPurchaseBillFile: boolean
    deletingItemFromInventory: boolean
    uploadingInventory: boolean
  }
  drawers: {
    showAddExpenseItemDrawer: boolean
    showAddItemDrawer: boolean
    showFeedbackDrawer: boolean
    showPurchaseBillDrawer: boolean
    showAddInvoiceItemDrawer: boolean
    showAddPurchaseItemDrawer: boolean
    showEditMerchantProfileDrawer: boolean
    showAddInventoryItemDrawer: boolean
    showDiscountDrawer: boolean
    showChargeDrawer: boolean
    showAddExpenseAccountDrawer: boolean
    showPaymentBulkUploadTemplate: boolean
    showPaymentBulkUploadStatus: boolean
    showPaymentBulkUploadFileLogDrawer: boolean
  }
  flags: {
    isItemDeletedSuccessfullyFromInventory?: boolean
    inventoryUploadType?: boolean | string
  }
  // items: ItemType[]
  bills: BillsType[]
  resetPreviousItemList: boolean
  addedItemsDetailsFromDrawer?: NewItemDetailEntity
  addedCustomerDetailsFromDrawer?: CustomerDetails
  editItemDetails?: CatalogueItemDetailsType
  printBillTemplate?: string
  resetBillContext?: boolean
  previewSavedBillTemplate?: string
  previewSavedBillId?: string
  // hasCreditNote?: boolean
  resetSelectedRowRef: {
    catalogTable: RowSelectionType
  }
  purchaseBillSettings: null | IFileSettings
  selectedBill: BillDataType | null
  isUpdatedBillData: boolean
  invoiceItems?: any
  editInvoiceItems?: any
  invoiceItemsHistory?: any
  purchaseItems?: any
  purchaseAccount?: any
  purchaseAssetAccount?: any
  expenseTransaction?: any

  inventory: {
    units: string[]
    inventoryItems: InventoryItemType
    editInventoryItem?: InventoryItemDetailsType & {
      itemId: string
      catalogueItemId: string
    }
  }
  InventoryTransactionHistory?: any
  purchaseTransactionHistory?: any
  inventoryApiCallAndResponse?: IInventoryApiCallAndResponse
  discounts: DiscountType[]
  charges: ChargeType[]
  bookingApiLoader: IBookingApiLoader
  expenseAccounts: IExpenseAccounts[]
  failedBulkUploadData: IFailedBulkUploadData
  bulkUploadFile: []
  bulkUploadFileLog: IBulkUploadFileLog[]
  bulkUploadFileRequestId: string
  totalCountOfBulkPurchaseItems: number
}

export interface IBulkUploadFileLog {
  batch_id: string
  processed_count: number
  failure_count: number
  pending_count: number
  create_time: number
}

export interface IFailedBulkUploadData {
  failedData: []
  status: boolean
}

export interface IExpenseAccounts {
  business_id: string
  account_id: string
  name: string
  balance: number
  reference_id: string
  type: string
}

export interface IBookingApiLoader {
  addExpenseAccount: boolean
  addExpense: boolean
  addPurchase: boolean
}
export enum AssetType {
  bank = 'bank',
  paymentGateway = 'payment_gateway',
  cash = 'cash'
}

export enum INVENTORY_UPLOAD_TYPE {
  ADD_QUANTITY = 'ADD_QUANTITY',
  REPLACE_QUANTITY = 'REPLACE_QUANTITY'
}

export interface IInventoryApiCallAndResponse {
  isApiCall: boolean
  isAllDataIsFetched: boolean
  isApiError: boolean
}

export type ExcludedKeys =
  | 'itemId'
  | 'taxAmount'
  | 'netRate'
  | 'discount'
  | 'sgstTaxPercent'
  | 'cgstTaxPercent'
export type ItemDetailsType = Omit<NewItemDetailEntity, ExcludedKeys> & {
  gstPercent: number
  isTaxIncludedInRate: boolean
  cessPercent: number
}

export type InventoryItemDetailsType = ItemDetailsType & {
  // itemCategory: string
  unit?: string
  secondaryUnit?: { name: string; factor: number }
  showSecondaryUnit?: boolean
  sku: string
}

export type CatalogueItemDetailsType = Omit<InventoryItemDetailsType, 'quantity' | 'sku'> & {
  itemId: string
}

export type InventoryItemEntity = Omit<ItemType, 'taxIncluded'> & {
  quantity: number
  category: string[]
  catalogueItemId: string
  offer?: OFFER_RESPONSE[]
}

export type InventoryItemType = InventoryItemEntity[]

type RowSelectionType = React.MutableRefObject<
  | {
      resetRowSelection: (_: any) => void
    }
  | undefined
  | null
> | null

export type ItemType = {
  itemId: string
  description: string
  unit: string
  secondaryUnits?: { name: string; factor: number }[]
  mrp: number
  rate: number
  addedOn: number
  taxIncluded: boolean
  gst_amount: number
  gst_percent: number
  cess_amount: number
  cess_percent: number
  productCode: string
  hsnCode: string
  image: string
  sku: string
  fullDescription: string
  showTaxIncluded: boolean
}

export interface IPreviewBillRequestPayload {
  use_default_template: boolean
  bill_id?: string
  use_placeholder_qr: boolean
  customer_code?: string
  bill_req: IRequestPayload
}

export interface IPreviewOrderRequestPayload {
  use_default_template: boolean
  bill_id?: string
  use_placeholder_qr: boolean
  customer_code?: string
  bill_reqs: IRequestPayload[]
}

export type BillDiscounts = {
  id: string
  name: string
  amount: number
  pre_tax: boolean
  is_percent: boolean
  basis_pts: number
}

export type BillCharges = {
  id: string
  name: string
  amount: number
  is_percent: boolean
  basis_pts: number
}

export interface IRequestPayload {
  request_id?: string
  bill_id?: string
  src: 7
  customer_id: string
  customer: {
    name: string
    address: string
    gst_number?: string
    mobile: string
  }
  add_to_ledger: boolean
  created_at: number
  bill_info: {
    discounts?: Array<BillDiscounts>
    add_charges?: Array<BillCharges>
    bill_number: string
    bill_date: number
    bill_gst?: string
    total_amount: number
    note?: string
    items: BillInfoItemType[]
    supply_state?: {
      code: string
      name: string
    }
  }
  labels?: {
    business_gst: string
  }
}

export type BillInfoItemType = {
  name: string
  unit: string
  rate: number
  mrp: number
  quantity: number
  tax: number
  amount: number
  tax_category?: string
  hsn_code?: string
  catalog_item_id: string
  tax_components?: TaxComponentType[]
}

export type OrderInfoItemType = {
  catalog_item_id: string
  quantity: number
  amount: number
  info: {
    name: string
    unit: string
    rate: number
    mrp: number
    quantity: number
    tax: number
    amount: number
    tax_category?: string
    hsn_code?: string
    catalog_item_id: string
    tax_components?: TaxComponentType[]
  }
}

export enum TAX_TYPE {
  CGST = 0,
  SGST = 1,
  IGST = 2,
  CESS = 3
}
export type TaxComponentType = {
  basis_pts: number // Tax Percent * 100
  kind: TAX_TYPE
  amount: number
}

export interface IFetchBillsRequestPayload {
  customer_ids?: Array<string>
  bill_number?: string
  page_number?: number
  per_page?: number
  src?: number
}

export interface IFetchBillAction {
  customerIds?: string[]
  billNumber?: string
  pageNumber?: number
  perPage?: number
}

export type BillsType = {
  billId: string
  billNumber: string
  customerId: string
  customerName: string
  customerAddress?: string
  customerMobile?: string
  billDate: number
  amount: number
  dueAmount?: number
  status?: BILL_STATUS
  creditNoteIds?: string[]
}

export type DiscountType = {
  id: string
  name: string
}

export type ChargeType = {
  id: string
  name: string
}

export interface IUploadAction {
  billId?: string
  billVersion?: number
  customerId: string
  customerDetails: {
    customerName: string
    customerAddress: string
    customerGst?: string
    customerMobile: string
    customerStateSupplyCode?: string
  }
  invoiceDetails: {
    invoiceNumber: string
    invoiceDate: number
    invoicePrefix: string
  }
  itemDetails: (NewItemDetailEntity & { inventoryItemId?: string })[]
  discountAmount: number
  payableAmount: number
  shouldPrintBill?: boolean
  clearContextOnSave?: boolean
  shouldUpdateBill?: boolean
  requestId?: string
  discounts?: SelectedDiscountType[]
  charges?: SelectedChargeType[]
  isIGST?: boolean
}

export type CustomerDetails = {
  customerId: string
  customerName: string
  customerMobile: string
  customerAddress: string
  customerGST?: string
  customerCode?: string
  customerStateSupplyCode?: string
}

export type InvoiceDetails = {
  invoiceNumber: string
  invoiceDate: Day
  invoiceGST?: string
  invoicePrefix: string
}

export type NewItemDetailEntity = {
  item: string
  itemId: string
  hsnCode?: string

  mrp?: number
  rate?: number

  discount: number

  discountPercent?: number
  isDiscountInPercent?: boolean
  discountType?: 'pre' | 'post'

  allPossibleUnits?: { name: string; factor: number }[]
  selectedUnit?: string
  selectedUnitFactor?: number

  netRate: number
  gstTaxPercent?: number
  sgstTaxPercent?: number
  cgstTaxPercent?: number
  igstPercent?: number
  cessPercent?: number
  taxAmount?: number
  gstTotalAmount?: number
  quantity: number
  skuId?: string
  openingAmount?: string
  isTaxIncludedInRate?: boolean
  applicableOfferDetails?: OFFER_RESPONSE[]
  appliedOfferId?: string
}

// Delete all associated types when deleting the hook
export type ItemDetails = {
  item: string
  quantity?: number
  sellingPrice?: number
  tds?: number
  amountPayable?: number
  itemId: string
}[]

export type ErrorState = {
  customerNameError: string
  customerMobileError: string
  customerInvoiceNoError: string
}

export type useCustomerFieldsType = {
  customerDetails: CustomerDetails
  setCustomerDetails: (customerDetails: CustomerDetails) => void
  errorState: ErrorState
  dropDownList: CustomerDetails[]
  handleCustomerClick: ({ index }: { index: number }) => void
}

export type useItemDetailsFieldsType = {
  itemDetails: ItemDetails
  setItemDetails: React.Dispatch<React.SetStateAction<ItemDetails>>
  setShowItemsDropDown: React.Dispatch<
    React.SetStateAction<{
      index: number
      show: boolean
    }>
  >
  addItem: () => void
  showItemsDropDown: {
    index: number
    show: boolean
  }
  itemsDropDownList: {
    item: string
    quantity: number
    sellingPrice: number
    tds: number
    amountPayable: number
    itemId: string
  }[]
  handleItemClick: (
    inputTabIndex: number,
    data: {
      value: string
      index: number
    }
  ) => void
  getUpdatedItem: ({
    quantity,
    sellingPrice,
    tds,
    itemId,
    itemIndex
  }: {
    quantity?: number | undefined
    sellingPrice?: number | undefined
    tds?: number | undefined
    itemId: string
    itemIndex: number
  }) => {
    item: string
    quantity?: number | undefined
    sellingPrice?: number | undefined
    tds?: number | undefined
    amountPayable?: number | undefined
    itemId: string
  }
  isDisabled: (itemId: string) => boolean
  errorState: ErrorState
}

export type ItemOperationAction = {
  operation: OPERATION_TYPE
  item: Omit<PAYLOAD_ITEMS_TYPE, 'tax' | 'gst' | 'cess'> & {
    gstPercent: number
    cessPercent: number
  }
}

export enum PurchaseBillSettingsTypes {
  SUPP_LEDGER = 'suppLedger',
  CGST_LEDGER = 'cgstLedger',
  SGST_LEDGER = 'sgstLedger',
  IGST_LEDGER = 'igstLedger',
  DISC_LEDGER = 'discLedger',
  ROUND_LEDGER = 'roundLedger',
  PURCHASE_LEDGER = 'purchaseLedger',
  ITEM_UNIT = 'itemUnit',
  NAME = 'name',
  MODE_OF_PAYMENT = 'paymentAccountID'
}

export enum PurchaseBillColTypes {
  DATE = 'date',
  BILL_NO = 'billNo',
  HSN = 'hsn',
  ITEM_NAME = 'itemName',
  BATCH = 'batch',
  QTY = 'qty',
  GROSS_AMT = 'grossAmt',
  OID = 'oid',
  GST_PERCENT = 'gstPercent',
  SUPP_LEDGER = 'suppLedger',
  CGST_LEDGER = 'cgstLedger',
  SGST_LEDGER = 'sgstLedger',
  IGST_LEDGER = 'igstLedger',
  DISC_LEDGER = 'discLedger',
  ROUND_LEDGER = 'roundLedger',
  PURCHASE_LEDGER = 'purchaseLedger',
  NOTES = 'notes',
  MODE_OF_PAYMENT = 'paymentAccountID'
}

export type IPurchaseBillSettingsCol = {
  [keys in PurchaseBillColTypes]: string
}

export type IPurchaseBillSettingsItems = {
  [keys in PurchaseBillSettingsTypes]: string
}

export interface IPurchaseBillSettings extends IPurchaseBillSettingsItems {
  isIGST: boolean
  cols: IPurchaseBillSettingsCol
}

export interface IFileSettings {
  id?: string
  name: string
  is_default: boolean
  settings: {
    sheetName: string
    purchase: IPurchaseBillSettings
  }
}

export interface ISavePurchaseBillSettingsReq {
  settings: IFileSettings
}
export interface BillParams {
  billId: string
}

export type InventoryItemUploadType = {
  formData: FormData
  action: 'REPLACE_QUANTITY' | 'ADD_QUANTITY'
}

export interface IFetchBillByIdRequestPayload {
  id: string
}

export type ItemsDataType = {
  itemId: string
  amount: number
  hsnCode: string
  mrp: number
  quantity: number
  netRate: number
  rate: number
  taxComponents: any
  taxAmount: number
  discount: number
  sgstTaxPercent: number
  cgstTaxPercent: number
  item: string
  amountPayable: number
  inventoryItemId?: string
}

export type HandleBillSavePropsType = {
  shouldPrintBill?: boolean
  clearContextOnSave?: boolean
  shouldUpdateBill?: boolean
}

export interface InventoryTransactionType {
  transaction_id: string
  quantity: number
  code: string
  bill_number: string
  timestamp: number
  type: string
  amount: number
}

export type MoreItemRefType = {
  resetDialog: () => null
}

export interface ICreateBusinessDiscountAction {
  name: string
}

export interface ICreateBusinessChargeAction {
  name: string
}

type OptionalKeysWithTrueOrUndefined<T> = {
  [K in keyof T]?:
    | {
        inputFieldType: string
        min?: number
        max?: number
      }
    | undefined
}

export type EditableAddedItemFieldsType = OptionalKeysWithTrueOrUndefined<
  NewItemDetailEntity & {
    amountPayable: number
  }
>

export type DisabledFunctionalitiesType = {
  disableAddItemsInputFields: {
    itemName: boolean
    rate: boolean
    hsnCode: boolean
    discount: boolean
    gstPercent: boolean
    cess: boolean
    quantity: boolean
    addItemCta: boolean
  }
  disableAddDiscountCharges: {
    discount: boolean
    charges: boolean
  }
  disabledItemFields: {
    discount: boolean
    quantity: boolean
    selection: boolean
  }
}
