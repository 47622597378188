import { convertEpochToDayTime } from 'utils/dateTransformer'

import {
  IFetchUnidentifiedCollectionActionResponse,
  IUnidentidfiedCollectionState,
  IUnidentifiedCollectionActionsRowData
} from './unidentifiedCollection.types'

export const formatUnidentifiedCollectionsActions = (
  data: IFetchUnidentifiedCollectionActionResponse[]
): IUnidentidfiedCollectionState['unidentifiedCollections'] => {
  const formattedResponse: IUnidentifiedCollectionActionsRowData[] = data.map((item) => {
    const dateObj = convertEpochToDayTime(Number(item.create_time))
    return {
      date: `${dateObj.day}/${dateObj.month}/${dateObj.year}, ${dateObj.hour}:${dateObj.minute} ${dateObj.ampm}`,
      from: `UPI/${extractText(item.payment_id)}/${extractText(item.payment_source)}`,
      payer: extractText(item.payment_from) || '-',
      amount: Number(item.amount) / 100,
      paymentId: extractText(item.payment_id),
      status: Number(item.status)
    }
  })
  return formattedResponse
}

const extractText = (inputStr: string) => {
  if (!inputStr) {
    return ''
  }
  if (inputStr.at(0) === '"') {
    return inputStr.slice(1, -1)
  }
  return inputStr
}
