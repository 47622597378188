export interface IUnidentidfiedCollectionState {
  filter: UnidentifiedCollectionFilterType
  unidentifiedCollections: IUnidentifiedCollectionActionsRowData[]
  filteredUnidentifiedCollections: IUnidentifiedCollectionActionsRowData[]
  selectedCollection: IUnidentifiedCollectionActionsRowData | null
  totalEntries: number
  showBillsDrawer: boolean
  isFetchBankDeposits: boolean
  isFetchCashDeposits: boolean
  pendingCashDeposits: any
  pendingBankDeposits: any
  isFetchingData: boolean
}

export interface IUnidentifiedCollectionActionsRowData {
  date: string
  from: string
  payer: string
  amount: number
  paymentId: string
  status: UnidentifiedCollectionStatusesEnum
}

export enum UNIDENTIFIED_COLLECTION_FILTER_TYPE {
  ALL = 'All',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM = 'Custom',
  DEFAULT = ''
}

export type UnidentifiedCollectionFilterType = {
  startTime: number
  filterType: UNIDENTIFIED_COLLECTION_FILTER_TYPE
}

export interface FetchUnidentifiedCollectionActionPayload
  extends UnidentifiedCollectionFilterType {}

export enum SORT_OPTION {
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  INVOICE_DATE = 'INVOICE_DATE',
  INVOICE_AMOUNT = 'INVOICE_AMOUNT'
}

export interface IPaymentLabels {
  cardNumberLast4: string
  paymentInstrument: string
}

export interface IPaymentOrigin {
  name: string
  mobile: string
  type: string
  payment_address: string
}

export interface IPayment {
  provider: string
  id: string
  UTR: string
  reference: string
  status: string
  request_type: string
  amount: string
  description: string
  labels: IPaymentLabels
  origin: IPaymentOrigin
  fee: string
  tax: string
  payment_link: string
  surcharge: string
  account_id: string
}

export enum UnidentifiedCollectionStatusesEnum {
  UNKNOWN = 0,
  ACTIVE = 1,
  PAID = 2,
  EXPIRED = 3,
  CANCELLED = 4,
  COMPLETE = 5,
  FAILED = 6,
  REFUNDED = 7, // new status to be handled
  REFUND_INITIATED = 8,
  PAYOUT_FAILED = 9,
  MIGRATED = 10, // When collection migrated from supplier to customer or vice versa.
  PAYOUT_INITIATED = 11,
  UN_CLAIMED = 12,
  REFUND_FAILED = 13,
  BLINDPAY_PAID = 14, // blind pay use case
  DELETED = 15
}

export interface IFetchUnidentifiedCollectionActionResponse {
  id: string
  create_time: string
  update_time: string
  status: number
  amount: string
  fee: string
  payment: IPayment
  merchant_id: string
  customer_id: string
  discount: string
  payment_id: string
  error_code: string
  error_description: string
  tags: string
  business_id: string
  surcharge: string
  payment_from: string
  payment_utr: string
  payout_utr: string
  payment_source: string
  payment_destination: string
  payment_to: string
}

export enum APPROVE_SECTION_TABLE_TYPES {
  PENDING_ACTIONS = 'pendingActions',
  UNIDENTIFIED_COLLECTION = 'undefinedCollection'
}

export interface IAddBillToCollectionsAction {
  customerId: string
  billId: string
}
export interface IAddBillToCollectionsRequest {
  payment_id: string
  payment_amount: number
  business_id: string
  account_id: string
  bill_id: string
}
