import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IExpState } from 'state/experiment/experiment.types'
import { IAppState } from 'infra/AppState'
import { getAbProfile } from 'state/experiment/experiment.action'
import { useWaitForMerchantId } from './useWaitForMerchantId'

interface IAbDelegate {
  /**
   * If collectionListOfInvoicesV1 is enabled for this merchant
   */
  collectionListOfInvoicesV1: boolean
  clQuickSummary: boolean
  clViewV2: boolean
  /**
   * If Collection List Summary V2 is enabled
   */
  collectionListSummaryV2: boolean
  /**
   * If on Demand Distribution is enabled (Coca Cola use case)
   */
  onDemandDistribution: boolean
  /**
   * If Dynamic Statement is enabled
   */
  dynamicStatements: boolean
  /**
   * If staff are allowed staff mobile app access
   */
  allowAppControls: boolean
  /**
   * If tally connect is enabled
   */
  tallyConnect: boolean
  /**
   * Billing on web
   */
  billingWeb: boolean
  /**
   * If the user is defaulter
   */
  okTermLoanAppBlock: boolean
  /**
   * If return items v1 is enabled
   */
  returnItemsV1: boolean
  /**
   * If Api is still fetching
   */
  staffLink: boolean
  loading: boolean
  ready: boolean
  editTransactionWeb: boolean
  cashDeposit: boolean
  downtime: boolean
  /**
   * Enable this For Order Management
   */
  okDebitOrderManagement: boolean
  previewUpload: boolean
  // Unidentified collection CTA changes needs this flag
  settleCollections: boolean
}

/**
 * Custom hook for conducting A/B experiments throught
 * the app.
 * @returns IAbDelegate
 */
export function useAbExperiment(): IAbDelegate {
  const dispatch = useDispatch()
  const { merchantId, individualUserId } = useWaitForMerchantId()
  const {
    ab: {
      collectionListOfInvoicesV1,
      loading,
      ready,
      clQuickSummary,
      collectionListSummaryV2,
      clViewV2,
      dynamicStatements,
      allowAppControls,
      tallyConnect,
      billingWeb,
      okTermLoanAppBlock,
      staffLink,
      editBillTransactionWeb: editTransactionWeb,
      cashDeposit,
      onDemandDistribution,
      returnItemsV1,
      downtime,
      okDebitOrderManagement,
      previewUpload,
      settleCollections
    }
  }: IExpState = useSelector((app: IAppState) => app.Experiment)

  useEffect(() => {
    if (merchantId && !loading && !ready && individualUserId) {
      dispatch(getAbProfile())
    }
  }, [merchantId, loading, ready, individualUserId])

  return {
    loading,
    ready,
    collectionListOfInvoicesV1,
    clQuickSummary,
    clViewV2,
    collectionListSummaryV2,
    dynamicStatements,
    allowAppControls,
    tallyConnect,
    billingWeb,
    okTermLoanAppBlock,
    staffLink,
    editTransactionWeb,
    cashDeposit,
    onDemandDistribution,
    returnItemsV1,
    downtime,
    okDebitOrderManagement,
    previewUpload,
    settleCollections
  }
}
