import { MoreVertIcon } from '@okcredit/web-lib'
import React, { FunctionComponent, forwardRef, useImperativeHandle, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pushEvent } from 'state/new-summary/events/events.actions'
import { NEWDASHBOARD_EVENTS } from 'state/new-summary/events/events.types'
import { THEME } from 'components/theme'
import DropDownModalLayout from '../SpecificComponents/DropDownModalLayout'
import RedDot from '../RedDot'
import { listItemType } from './type'
import { ThreeDotsComponentStyles } from './style'

const ThreeDotsComponent = forwardRef(
  (
    {
      listItem,
      dotSize = 25,
      showRedDot = false,
      showMoreText = true,
      customComponent
    }: {
      listItem: listItemType
      dotSize?: number
      showRedDot?: boolean
      showMoreText?: boolean
      customComponent?: string | FunctionComponent<{ onClick: () => void }>
    },
    ref
  ) => {
    const [display, setDisplay] = useState<'none' | 'block'>('none')
    const dispatch = useDispatch()

    const classes = ThreeDotsComponentStyles()

    const resetDialog = () => {
      setDisplay('none')
    }

    useImperativeHandle(
      ref,
      () => {
        return { resetDialog }
      },
      []
    )

    const closeModal = () => {
      setDisplay('none')
    }

    return (
      <DropDownModalLayout display={display} listItem={listItem} closeModal={closeModal}>
        <>
          {showRedDot && <RedDot />}
          {customComponent ? (
            <>
              {React.createElement(customComponent, {
                onClick: () => setDisplay(display === 'block' ? 'none' : 'block')
              })}
            </>
          ) : (
            <div
              className={classes.wrapperDiv}
              onClick={() => {
                dispatch(pushEvent(NEWDASHBOARD_EVENTS.THREE_DOTS_CLICKED))
                setDisplay(display === 'block' ? 'none' : 'block')
              }}
            >
              {showMoreText ? <span className={classes.preText}>More</span> : null}
              <MoreVertIcon size={dotSize} fill={THEME.brandBlack} />
            </div>
          )}
        </>
      </DropDownModalLayout>
    )
  }
)

ThreeDotsComponent.displayName = 'ThreeDotsComponent'
export default ThreeDotsComponent
