/* eslint-disable security/detect-object-injection */
import { all, call, delay, put, select } from 'redux-saga/effects'
import { generatePath } from 'react-router'
import { resetSelectedEntity, selectEntity } from 'state/transaction/transaction.actions'
import { Action } from 'infra/types'
import { MerchantApi } from 'services/Api/MerchantApi'
import { MerchantApiV2 } from 'services/Api/MerchantApiV2'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { forwardTo } from 'shared/state/common'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { getErrorMessage } from 'services/Api/Errors'
import { getCurrentAddress } from 'services/Api/GeoLocator'
import { Navigation } from 'services/navigation'
import { IAppState } from 'infra/AppState'
import getWhatsappLink from 'utils/getWhatsappLink'
import { IApiResponse, API_RESPONSE_TYPE, ENTITY_TYPE } from 'shared/constants'
import { IDispatchCallback, IFilteredCustomersPayload } from 'services/Api/types'
import { newPaths } from 'shared/routes'
import {
  getFilteredCustomerList,
  linkDelinkAccountTags,
  updateRoutesAndBeats
} from 'services/Api/StafflinkApi'
import { getFilteredAccountsByBills } from 'services/Api/BillingApi'
import { IdentityApi, updateBusinessUser } from 'services/Api/IdentityApi'
import { AuthApi } from 'services/Api/AuthApi'
import { setNewTokensToLocalStorage } from 'services/Api/apiInterceptor'
import {
  bulkUploadCustomersApi,
  getBulkUploadCustomersStatusApi
} from 'services/Api/growthExperimentApi'
import { DOCUMENT_TYPE } from 'state/new-summary/uploadSheet/uploadSheet.types'
import { getIsFeedbackDone, postFeedBack } from 'services/Api/PaidSoonBannerApi'
import { validateGST } from 'pages/NewDashboard/components/Billing/Helper'
import { fetchUserPermission, getAllCustomerProfilesApi } from 'services/Api/StaffLinkApiFolder/api'
import { DOCUMENT_STATUS } from 'pages/NewDashboard/components/CommonDrawers/UploadSheetDrawers/UploadSupplySheet/components/DocumentCard/DocumentCard.types'
import { FETCH_PERMISSION_RESPONSE } from 'services/Api/StaffLinkApiFolder/type'
import { linkDelinkAccountTagsPayload } from 'state/tags/tags.types'
import { getAccountTagsSuccess } from 'state/tags/tags.actions'
import { fetchBusinessSettingsApi } from 'services/Api/BillingApiFolder.ts/api'
import { showSelectAccountModal, showSuccessModal, signout } from '../auth/auth.actions'
import { MerchantProfileHelpers } from './helpers/MerchantProfile'
import {
  CustomerSupplierProfileHelpers,
  getFormattedCustomerProfile,
  getFormattedSupplierProfile,
  getResetCustomerProfile,
  getResetSupplierProfile,
  IEntityUpdateObject
} from './helpers/CustomerSupplierProfile'
import {
  registerMerchantNameSuccesss,
  fetchLocalDataSuccess,
  fetchMerchantProfileSuccess,
  updateMerchantProfile,
  showBusinessNameModal,
  fetchBusinessCategoriesSuccess,
  fetchBusinessTypesSuccess,
  fetchPhonebookContactsSuccess,
  editMerchantProfile,
  fetchHelpSectionsSuccess,
  businessNameOptOut as businessNameOptOutAction,
  fetchEntityListSuccess,
  updateEntity,
  deleteEntitySuccess,
  addEntitySuccess,
  fetchEntityList,
  fetchMerchantProfileFailure,
  fetchEntityListFailure,
  fetchPhonebookContactsFailure,
  fetchHelpSectionsFailure,
  getCustomerDataSuccess,
  setShowLeadgenBanner,
  fetchFilteredCustomerListSuccess,
  fetchFilteredCustomerListFailure,
  handleSearchByValueSuccess,
  handleSearchByValueFailure,
  setBusinessAccountsLoaderAction,
  setBusinessDetailsAction,
  fetchBusinessIdsSuccessAction,
  createBusinessSuccessAction,
  handleBusinessAccountsDrawerToggle,
  switchBusinessAccountAction,
  setAsStaffBusinessDetailsAction,
  selectBusinessAccountAction,
  bulkUploadCustomersSuccessAction,
  stopPollBulkUploadAccountsStatus,
  bulkUploadCustomersStatusSuccessAction,
  setLoaderAction,
  closePaidSoonBannerAction,
  openPaidSoonBannerAction,
  setBusinessManagersAction,
  fetchBusinessManagersAction,
  setCustomerProfilesData,
  fetchCustomerProfiles,
  setDrawerAction,
  setBusinessSettingsAction
} from './dashboard.actions'
import {
  ICustomer,
  IDashboardState,
  IEntityEdit,
  IMerchantProfile,
  IMerchantProfileEdited,
  IPhonebookContact,
  ISupplier,
  IInitialLeadgenAction,
  addAccountBeatRoutePayload,
  CustomerProfileBulkUpdateEntity
} from './dashboard.types'
import { getFormattedHelpSection } from './helpers/helpSection'
import { formatCustomerProfiles, getFormattedAccountIds } from './helpers/formatter'
import { formatBusinessDetail, formatBusinessDetailsResponse } from './helpers/businessAccounts'
import {
  BULK_UPLOAD_ACCOUNTS_STATUS_POLL_COUNT,
  BULK_UPLOAD_ACCOUNTS_STATUS_POLL_INTERVAL
} from './helpers/account'
import { isBetween2pmAnd4pm, saveLocalStorageData } from './helpers/paidSoonBanner'

export function* setMerchantName(action: Action<string>) {
  try {
    const response: IApiResponse = yield call(MerchantApi.registerMerchantName, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(registerMerchantNameSuccesss())
      yield put(businessNameOptOutAction())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* businessNameOptOut() {
  yield AuthStorageInstance.setOptoutBusinessName(true)
}

export function* fetchAndSetLocalData() {
  const businessNameOptOut: boolean = yield AuthStorageInstance.getOptoutBusinessName()
  const isNewUser: boolean = yield AuthStorageInstance.getFirstTimeUser()
  if (isNewUser) {
    yield put(showBusinessNameModal())
  }
  yield put(fetchLocalDataSuccess(businessNameOptOut))
}

export function* fetchEntities(action: Action<ENTITY_TYPE>) {
  const entityType = action.payload
  const isCustomer = entityType === ENTITY_TYPE.CUSTOMER

  try {
    const response: IApiResponse = yield call(
      isCustomer ? MerchantApi.getCustomersList : MerchantApi.getSuppliersList
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const entities = isCustomer ? response.data : response.data?.suppliers
      if (entities && entities.length) {
        const list = entities
          .filter((_: any) => !_.deleted)
          .map(
            isCustomer
              ? CustomerSupplierProfileHelpers.getFormattedCustomerProfile
              : CustomerSupplierProfileHelpers.getFormattedSupplierProfile
          )
        yield put(fetchEntityListSuccess(entityType, list))
      }
    } else if (response?.data?.response && response?.data?.response?.status === 401) {
      yield put(signout())
    } else {
      const currentPath: string = yield select((state: IAppState) => {
        return state.Navigation.currentRoute.pathName
      })
      if (currentPath !== '') {
        yield put(fetchEntityListFailure(entityType))
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: response.data.message
          })
        )
      }
    }
  } catch (e: any) {
    const currentPath: string = yield select((state: IAppState) => {
      return state.Navigation.currentRoute.pathName
    })
    if (currentPath !== '') {
      yield put(fetchEntityListFailure(entityType))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: getErrorMessage(e).message
        })
      )
    }
  }
}

export function* patchEntity(action: Action<IEntityUpdateObject>) {
  const { entityId, entityType } = action.payload
  const isCustomer = entityType === ENTITY_TYPE.CUSTOMER
  const entity: ICustomer & ISupplier = yield select(
    (state) => state.Dashboard[isCustomer ? 'customers' : 'suppliers'].byIds[entityId]
  )

  let apiService
  let payloadGenerator
  let formatter
  let resetter

  if (isCustomer) {
    apiService = MerchantApi.patchCustomer
    payloadGenerator = CustomerSupplierProfileHelpers.getPatchCustomerPayload
    formatter = getFormattedCustomerProfile
    resetter = getResetCustomerProfile
  } else {
    apiService = MerchantApi.patchSupplier
    payloadGenerator = CustomerSupplierProfileHelpers.getPatchSupplierPayload
    formatter = getFormattedSupplierProfile
    resetter = getResetSupplierProfile
  }
  //If there is some sort of a race condtion & the entity has been deleted
  if (
    !entity ||
    (entity.edited.values.customer_gst && !validateGST(entity.edited.values.customer_gst))
  ) {
    return
  }

  try {
    const response: IApiResponse = yield call(apiService, entityId, payloadGenerator(entity as any))
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(updateEntity(formatter(isCustomer ? response.data : response.data.supplier)))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: getErrorMessage(response.data).message
        })
      )
      yield put(updateEntity(resetter(entity)))
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* deleteEntity(action: Action<IEntityEdit & IDispatchCallback>) {
  const { id, entityType, redirectTo } = action.payload
  const isCustomer = entityType === ENTITY_TYPE.CUSTOMER
  try {
    const response: IApiResponse = yield call(
      MerchantApi[isCustomer ? 'deleteCustomer' : 'deleteSupplier'],
      id
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const currentPath: string = yield select((state: IAppState) => {
        return state.Navigation.currentRoute.pathName
      })
      if (redirectTo) {
        yield call(forwardTo, redirectTo)
      } else if (currentPath === 'editEntity') {
        Navigation().back()
      }
      yield put(deleteEntitySuccess(entityType, id))
      yield put(resetSelectedEntity())
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* fetchMerchantProfile() {
  try {
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    if (!currentBusinessId) {
      return
    }
    const response: IApiResponse = yield call(IdentityApi.getBusinessUser, currentBusinessId)

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(fetchMerchantProfileSuccess(formatBusinessDetail(response)))
      yield call(fetchBusinessCategories)
      yield call(fetchBusinessTypes)
    } else {
      const currentPath: string = yield select((state: IAppState) => {
        return state.Navigation.currentRoute.pathName
      })
      if (currentPath !== '') {
        yield put(fetchMerchantProfileFailure())
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: response.data.message
          })
        )
      }
    }
  } catch (e: any) {
    const currentPath: string = yield select((state: IAppState) => {
      return state.Navigation.currentRoute.pathName
    })
    if (currentPath !== '') {
      yield put(fetchMerchantProfileFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: getErrorMessage(e).message
        })
      )
    }
  }
}

export function* checkForPaidBannerSoon() {
  try {
    const checkIsBetween2pmAnd4pm: Boolean = yield call(isBetween2pmAnd4pm)
    const isExperimentEnable: boolean = yield call(checkForExperimentEnable)
    if (checkIsBetween2pmAnd4pm) {
      if (isExperimentEnable) {
        const checkForLocalStorage: Boolean = yield call(saveLocalStorageData)
        if (checkForLocalStorage) {
          const response: IApiResponse = yield call(getIsFeedbackDone)
          if (response.type === API_RESPONSE_TYPE.SUCCESS) {
            if (!response.data?.message) {
              yield put(openPaidSoonBannerAction())
            } else {
              window.localStorage.setItem('isBannerSeen', 'true')
            }
          }
          if (response.type === API_RESPONSE_TYPE.FAILURE) {
            window.localStorage.removeItem('isBannerSeen')
            window.localStorage.removeItem('lastBannerShownDate')
          }
        }
      }
    }
  } catch (e: any) {
    yield put(closePaidSoonBannerAction())
  }
}

function* checkForExperimentEnable() {
  try {
    const { Experiment } = yield select((app: IAppState) => app)
    return (
      Experiment?.experiment['activation_web-all-paid_soon_banner'].status === 1 &&
      Experiment?.experiment['activation_web-all-paid_soon_banner'].variant === 'test'
    )
  } catch (error) {
    return false
  }
}

export function* closePaidBannerSoonBanner() {
  try {
    window.localStorage.setItem('isBannerSeen', 'false')
    const currentDate = new Date().toISOString().slice(0, 10)
    window.localStorage.setItem('lastBannerShownDate', currentDate)
  } catch (e: any) {
    yield put(closePaidSoonBannerAction())
  }
}

export function* postPaidBannerSoonFeedback(action: Action<string>) {
  try {
    const payload = {
      feature: 'subscription_notification',
      message: action?.payload || ''
    }
    const response: IApiResponse = yield call(postFeedBack, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      window.localStorage.setItem('isBannerSeen', 'true')
      yield put(closePaidSoonBannerAction())
    }
  } catch (e: any) {
    yield put(closePaidSoonBannerAction())
  }
}

export function* patchMerchantProfile() {
  const merchantProfile: { edited: IMerchantProfileEdited; data: IMerchantProfile } = yield select(
    (state) => state.Dashboard.merchantProfile
  )
  const { edited: merchantProfileEdited, data: merchantProfileOriginal } = merchantProfile
  const payload = MerchantProfileHelpers.getMerchantProfilePatchPayloadV2(
    merchantProfileEdited,
    merchantProfileOriginal
  )

  try {
    const response: IApiResponse = yield call(updateBusinessUser, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (response.data.is_updated) {
        yield put(updateMerchantProfile(formatBusinessDetail(response.data)))
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: getErrorMessage(response.data).message
        })
      )
      yield put(updateMerchantProfile(merchantProfileOriginal))
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* getCustomerDataEffect(action: Action<string>) {
  try {
    const customerId = action.payload
    const response: IApiResponse = yield call(MerchantApi.getCustomerData, customerId)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getCustomerDataSuccess(getFormattedCustomerProfile(response.data)))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* patchMerchantAddressWithCurrentLocation() {
  //@ts-ignore
  const address = yield call(getCurrentAddress)
  if (!address) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: "Oops! We couldn't auto-detect your address."
      })
    )
  } else {
    yield put(editMerchantProfile('address', address))
  }
}

export function* fetchBusinessCategories() {
  try {
    const response: IApiResponse = yield call(MerchantApi.getBusinessCategories)

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (response.data && response.data.categories) {
        const { categories } = response.data
        yield put(
          fetchBusinessCategoriesSuccess(
            categories.map((category: any) => ({
              type: category.type,
              id: category.id,
              name: category.name,
              imageUrl: category.image_url,
              isPopular: category.is_popular,
              weight: category.weight
            }))
          )
        )
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* fetchBusinessTypes() {
  try {
    const response: IApiResponse = yield call(MerchantApiV2.getBusinessTypes)

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (response.data && response.data.business_type) {
        const business_types = response.data.business_type
        yield put(
          fetchBusinessTypesSuccess(
            business_types.map((business_type: any) => ({
              id: business_type.id,
              name: business_type.name,
              imageUrl: business_type.image_url,
              title: business_type.title,
              subTitle: business_type.sub_title
            }))
          )
        )
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

const checkExists = (mobileNumber: string, state: IDashboardState) => {
  const customers = Object.values(state.customers.byIds)
  const suppliers = Object.values(state.suppliers.byIds)
  let exists = false
  if (customers.length) {
    exists = customers.some((customer: ICustomer) => customer.mobile === mobileNumber)
  }
  if (!exists && suppliers.length) {
    exists = suppliers.some((supplier: ISupplier) => supplier.mobile === mobileNumber)
  }
  return exists
}

export function* addEntity(action: Action<any>) {
  try {
    const { mobile: mobileNumber, entityType, redirectTo } = action.payload
    const isCustomer = entityType === ENTITY_TYPE.CUSTOMER
    const dashboardState: IDashboardState = yield select((state) => state.Dashboard)
    if (checkExists(mobileNumber, dashboardState)) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'User already exists as either Customer or Supplier. Cannot add'
        })
      )
      return
    }
    const response: IApiResponse = yield call(
      isCustomer ? MerchantApi.addCustomer : MerchantApi.addSupplier,
      action.payload.name,
      action.payload.mobile
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        addEntitySuccess(
          entityType,
          response.data.mobile,
          action.payload.fromContactList,
          action.payload.isRegistered,
          response.data.id
        )
      )
      yield put(fetchEntityList(entityType))
      yield put(selectEntity(entityType, response.data.id))
      if (redirectTo) {
        yield call(forwardTo, redirectTo)
      } else {
        Navigation().replace(generatePath(newPaths.addEntitySuccess, { entity: entityType }))
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* fetchPhonebookContacts() {
  try {
    const response: IApiResponse = yield call(MerchantApi.getContactsFromMerchant)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (response.data && response.data.contacts && response.data.contacts.length > 0) {
        const contacts: IPhonebookContact[] = response.data.contacts.map(
          (_: any): IPhonebookContact => ({ name: _.name, mobile: _.mobile })
        )
        yield put(fetchPhonebookContactsSuccess(contacts))
      }
    } else {
      const currentPath: string = yield select((state: IAppState) => {
        return state.Navigation.currentRoute.pathName
      })
      if (currentPath !== '') {
        yield put(fetchPhonebookContactsFailure())
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: response.data.message
          })
        )
      }
    }
  } catch (e: any) {
    const currentPath: string = yield select((state: IAppState) => {
      return state.Navigation.currentRoute.pathName
    })
    if (currentPath !== '') {
      yield put(fetchPhonebookContactsFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: getErrorMessage(e).message
        })
      )
    }
  }
}

export function* fetchHelpSections(action: Action<string>) {
  try {
    const response: IApiResponse = yield call(MerchantApi.getHelpSection, action.payload)

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (Array.isArray(response.data.sections)) {
        const helpSections = response.data.sections.map(getFormattedHelpSection)
        yield put(fetchHelpSectionsSuccess(helpSections))
      }
    } else {
      yield put(fetchHelpSectionsFailure())
      console.log('Error with /api/account/help api')
      // yield put(
      //   addAutoFadeNotification({
      //     type: NotificationType.ERROR,
      //     bodyText: response.data.message
      //   })
      // )
    }
  } catch (e: any) {
    yield put(fetchHelpSectionsFailure())
    console.log('Error with /api/account/help api')
    // yield put(
    //   addAutoFadeNotification({
    //     type: NotificationType.ERROR,
    //     bodyText: getErrorMessage(e).message
    //   })
    // )
  }
}

export function contactUsOnWhatsApp() {
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open(getWhatsappLink('+91 82965 08123', ''))
}

export function* handleInitialLeadgenEffect(action: Action<IInitialLeadgenAction>) {
  if (typeof window === 'undefined') {
    return null
  }
  let showBannerState: string | null = 'false'
  let showLeadgen: boolean = false

  if (action.payload.queryString) {
    const queryParams = new window.URLSearchParams(action.payload.queryString)
    showBannerState = queryParams.get('lgl')
    if (showBannerState && action?.payload?.history) {
      queryParams.delete('lgl')
      action?.payload?.history.push({
        search: queryParams.toString()
      })
    }
  } else {
    showBannerState = window.localStorage.getItem('showLeadgenBanner')
  }
  if (showBannerState && showBannerState === 'true') {
    showLeadgen = true
    window.localStorage.setItem('showLeadgenBanner', 'true')
  } else {
    showLeadgen = false
    window.localStorage.removeItem('showLeadgenBanner')
  }
  yield put(setShowLeadgenBanner(showLeadgen))
}

export function* fetchFilteredByTagsCustomers(action: Action<IFilteredCustomersPayload>) {
  try {
    const response: IApiResponse = yield call(getFilteredCustomerList, action.payload)

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const entities = response.data.customers
      if (entities && entities.length) {
        const list = entities
          .filter((_: any) => !_.deleted)
          .map(CustomerSupplierProfileHelpers.getFormattedCustomerProfile)
        yield put(fetchFilteredCustomerListSuccess(list))
      } else {
        yield put(fetchFilteredCustomerListSuccess(undefined))
      }
    } else {
      yield put(fetchFilteredCustomerListFailure(null))
      console.log('Error with /v1/FilterAccountsByTags')
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not apply this filter. Please try again after some time.'
        })
      )
    }
  } catch (e: any) {
    yield put(fetchFilteredCustomerListFailure(null))
    console.log('Error with /v1/FilterAccountsByTags')
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Could not apply this filter. Please try again after some time.'
      })
    )
  }
}

export function* fetchSearchByValueAccounts() {
  try {
    const dashboardState: IDashboardState = yield select((state: IAppState) => {
      return state.Dashboard
    })
    const response: IApiResponse = yield call(
      getFilteredAccountsByBills,
      dashboardState.searchString
    )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const formattedAccounts = getFormattedAccountIds(response.data.bills)
      yield put(handleSearchByValueSuccess(formattedAccounts))
    } else {
      yield put(handleSearchByValueFailure())
      console.log('Error with /v1/FilterAccountsByTags')
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Bill Number does not exist. Please try with valid number'
        })
      )
    }
  } catch (error) {
    console.log('Error with /v1/fetchByHills')
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Could not search by value'
      })
    )
  }
}

export function* getBusinessIdsEffect(action: Action<{ isSigningIn: boolean }>) {
  try {
    const { isSigningIn } = action.payload
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const isAdmin: boolean = yield AuthStorageInstance.getIsAdmin()

    yield put(setBusinessAccountsLoaderAction(true))
    const response: IApiResponse = yield call(IdentityApi.getBusinessIds)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const businessIds: string[] = response?.data?.business_ids || []
      const businessesStaff: any[] = response?.data?.allowed_businesses || []
      const businessIdsAsStaff: string[] = businessesStaff.map((business) => business.id)
      if (businessIds.length) {
        const responses: IApiResponse[] = yield all(
          businessIds.map((businessId) => call(IdentityApi.getBusinessUser, businessId))
        )
        const formattedResponse = formatBusinessDetailsResponse(responses)
        yield put(setBusinessDetailsAction(formattedResponse))
      }
      if (businessIdsAsStaff.length) {
        const responses: IApiResponse[] = yield all(
          businessIdsAsStaff.map((businessId) => call(IdentityApi.getBusinessUser, businessId))
        )
        const formattedResponse = formatBusinessDetailsResponse(responses)
        yield put(setAsStaffBusinessDetailsAction(formattedResponse))
      }

      let permittedWorkflows
      if (response?.data?.individual_user?.user?.id) {
        const payload = {
          user_id: response.data.individual_user.user.id
        }
        const permissionResponse: IApiResponse<FETCH_PERMISSION_RESPONSE> = yield call(
          fetchUserPermission,
          payload
        )
        permittedWorkflows = permissionResponse.data.permitted_workflows
      }

      yield put(
        fetchBusinessIdsSuccessAction({
          businessIds: businessIds,
          individualUser: response?.data?.individual_user?.user,
          asStaffBusinessIds: businessIdsAsStaff,
          ...(permittedWorkflows && permittedWorkflows.length > 0 && { permittedWorkflows })
        })
      )
      if (isSigningIn) {
        if (businessIdsAsStaff.length === 0 && businessIds.length === 1) {
          yield put(selectBusinessAccountAction({ businessId: businessIds[0], isAdmin: true }))
        } else {
          yield put(showSelectAccountModal())
        }
      } else {
        yield put(selectBusinessAccountAction({ businessId: currentBusinessId, isAdmin }))
      }
    } else {
      console.log()
    }
    yield put(setBusinessAccountsLoaderAction(false))
  } catch (e: any) {
    console.log(e)
    yield put(setBusinessAccountsLoaderAction(false))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* fetchBusinessSettingsEffect() {
  const { Dashboard } = yield select((app: IAppState) => app)
  const merchantId: string = Dashboard?.merchantProfile?.data?.id

  const response: IApiResponse = yield call(fetchBusinessSettingsApi, { merchantId })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    yield put(setBusinessSettingsAction({}))
    return
  }
  yield put(setBusinessSettingsAction(response.data.settings))
}

export function* createBusinessEffect(action: Action<string>) {
  try {
    yield put(setBusinessAccountsLoaderAction(true))
    const response: IApiResponse = yield call(IdentityApi.createBusinessUser, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const formattedResponse = formatBusinessDetail(response?.data)
      yield put(createBusinessSuccessAction(formattedResponse))
      const refreshToken = AuthStorageInstance.getRefreshToken()
      if (refreshToken) {
        const refreshApiResponse: IApiResponse = yield call(AuthApi.authRefreshToken, refreshToken)
        const { access_token, refresh_token, expires_in } = refreshApiResponse.data
        setNewTokensToLocalStorage(access_token, parseInt(expires_in), refresh_token)
        yield put(switchBusinessAccountAction(formattedResponse.id))
      }
    } else {
      const errMsg =
        response?.data?.response?.data?.error === 'business_limit_reached'
          ? "Couldn't create business. Maximum business count limit reached"
          : 'Could not create business, please try again.'
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: errMsg
        })
      )
    }
    yield put(setBusinessAccountsLoaderAction(false))
    yield put(handleBusinessAccountsDrawerToggle({ newBusinessName: false }))
  } catch (e: any) {
    console.log(e)
    yield put(setBusinessAccountsLoaderAction(false))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* switchBusinessAccountEffect(action: Action<string>) {
  try {
    const newBusinessId = action.payload
    const { Experiment } = yield select((app: IAppState) => app)
    yield put(setBusinessAccountsLoaderAction(true))
    AuthStorageInstance.setCurrentBusinessId(newBusinessId)
    yield delay(1000)
    window.location.href = Experiment.ab.staff_link ? newPaths.approve : newPaths.ledger
  } catch (e: any) {
    console.log(e)
    yield put(setBusinessAccountsLoaderAction(false))
  }
}

export function* selectBusinessAccountEffect(
  action: Action<{ businessId: string; isAdmin: boolean }>
) {
  try {
    let merchantProfile
    const { businessId, isAdmin } = action.payload
    const dashboardState: IDashboardState = yield select((state) => state.Dashboard)
    yield put(setBusinessAccountsLoaderAction(true))
    AuthStorageInstance.setCurrentBusinessId(businessId)
    AuthStorageInstance.setIsAdmin(isAdmin)
    if (isAdmin) {
      merchantProfile = dashboardState?.businessAccounts?.businessDetails?.[businessId]
      yield put(fetchBusinessManagersAction({ businessId }))
    } else {
      merchantProfile = dashboardState?.businessAccounts?.asStaffBusinessDetails?.[businessId]
    }
    if (merchantProfile) {
      yield put(fetchMerchantProfileSuccess(merchantProfile))
      yield put(showSuccessModal())
    }
    yield put(setBusinessAccountsLoaderAction(false))
  } catch (e: any) {
    console.log(e)
    yield put(setBusinessAccountsLoaderAction(false))
  }
}

export function* deleteBusinessManagerEffect(
  action: Action<{
    userId: string
    mobile: string
  }>
) {
  const { userId, mobile } = action.payload
  const dashboardState: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const response: IApiResponse = yield call(IdentityApi.revokeBusinessManager, mobile)
  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Could not delete business manager. Please try again later.', {
      cause: 'customError'
    })
  }
  const updatedManagers = dashboardState.businessManagers.filter(
    (manager) => manager.user_id !== userId
  )
  yield put(setBusinessManagersAction(updatedManagers))
  Navigation().back()
}

export function* fetchBusinessManagersEffect(action: Action<{ businessId: string }>) {
  const { businessId } = action.payload
  const businessManagerResponse: IApiResponse = yield call(
    IdentityApi.getBusinessManager,
    businessId
  )
  if (businessManagerResponse.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Something went wrong while fetching business managers, please refresh.', {
      cause: 'customError'
    })
  }
  yield put(setBusinessManagersAction(businessManagerResponse?.data?.managers))
}

export function* addBusinessManagerEffect(
  action: Action<{
    mobile: string
  }>
) {
  const { mobile } = action.payload
  const dashboardState: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const businessId = dashboardState.merchantProfile?.data?.id
  if (!businessId) {
    return
  }
  const response: IApiResponse = yield call(IdentityApi.updateBusinessManager, mobile)
  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Could not add business manager. Please try again later.', {
      cause: 'customError'
    })
  }
  yield put(fetchBusinessManagersAction({ businessId }))
  Navigation().back()
}

export function* bulkUploadCustomersEffect(action: Action<any>) {
  try {
    yield put(setLoaderAction({ id: 'bulkUploadCustomers', value: true }))
    const response: IApiResponse = yield call(bulkUploadCustomersApi, {
      document: action.payload.document,
      document_extension: action.payload.documentExtension,
      file_name: action.payload.fileName,
      document_type: DOCUMENT_TYPE.EXCEL
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(bulkUploadCustomersSuccessAction(response?.data?.document_id))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response?.data?.message || 'Unable to upload document, please contact support'
        })
      )
    }
    yield put(setLoaderAction({ id: 'bulkUploadCustomers', value: false }))
  } catch (e) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as any).message
      })
    )
    yield put(setLoaderAction({ id: 'bulkUploadCustomers', value: false }))
  }
}

export function* startBulkUploadCustomersStatusPollingEffect(action: Action<any>) {
  let statusPollCount = BULK_UPLOAD_ACCOUNTS_STATUS_POLL_COUNT
  while (statusPollCount > 0) {
    statusPollCount--
    try {
      const response: IApiResponse = yield call(getBulkUploadCustomersStatusApi, action.payload)
      if (response.type === API_RESPONSE_TYPE.FAILURE) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: 'Document upload failed, please try again.'
          })
        )
        yield put(stopPollBulkUploadAccountsStatus())
        // TODO: Update document status in redux
        return
      }
      /*  DocumentStatus_CREATED            DocumentStatus = 0
    DocumentStatus_UPLOADED           DocumentStatus = 1
    DocumentStatus_DB_CREATED         DocumentStatus = 2
    DocumentStatus_SYNC_STARTED       DocumentStatus = 3
    DocumentStatus_SYNC_COMPLETE      DocumentStatus = 4
    DocumentStatus_SYNC_FAILED        DocumentStatus = 5
    DocumentStatus_READ_FAILED        DocumentStatus = 6
    DocumentStatus_DELETE_IN_PROGRESS DocumentStatus = 7
    DocumentStatus_DELETED            DocumentStatus = 8 */
      yield put(bulkUploadCustomersStatusSuccessAction(response?.data?.document_status))
      // Polling can be stopped if status is >=4
      if (response?.data?.document_status >= DOCUMENT_STATUS.SYNC_COMPLETE) {
        // Upload successful
        if (response?.data?.document_status === DOCUMENT_STATUS.SYNC_COMPLETE) {
          if (response?.data?.unSynced_rows === 0) {
            yield put(
              addAutoFadeNotification({
                type: NotificationType.SUCCESS,
                bodyText: 'Document uploaded successfully.'
              })
            )
          } else {
            yield put(
              addAutoFadeNotification({
                type: NotificationType.NEUTRAL,
                bodyText: `Failed to update ${response?.data?.unSynced_rows} rows`
              })
            )
          }
          yield put(fetchEntityList(ENTITY_TYPE.CUSTOMER))
        } else {
          yield put(
            addAutoFadeNotification({
              type: NotificationType.ERROR,
              bodyText:
                response?.data?.document_status === DOCUMENT_STATUS.READ_FAILED
                  ? 'Incorrect file format.'
                  : 'Document upload failed, please try again.'
            })
          )
        }
        yield put(stopPollBulkUploadAccountsStatus())
      }
      yield delay(BULK_UPLOAD_ACCOUNTS_STATUS_POLL_INTERVAL)
    } catch (error) {
      // TODO: Update document status in redux
      yield put(stopPollBulkUploadAccountsStatus())
    }
  }
}

export function* fetchCustomerProfilesEffect(action: Action<any>) {
  const response: IApiResponse = yield call(getAllCustomerProfilesApi, action.payload)
  const { sort_by, sort_descending, search_string } = action.payload
  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Problem while fetching customer profiles, please try after sometime', {
      cause: 'customError'
    })
  }

  const formattedData = formatCustomerProfiles(response?.data, {
    sort_by,
    sort_descending,
    search_string
  })
  yield put(setCustomerProfilesData(formattedData))
}

export function* bulkAddAccountTagsEffect(action: Action<linkDelinkAccountTagsPayload>) {
  const dashboardState: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const {
    customerProfiles: {
      paginationData,
      sort_by,
      sort_descending,
      search_string,
      resetSelectedRowRef
    }
  } = dashboardState
  const { per_page } = paginationData

  const response: IApiResponse = yield call(linkDelinkAccountTags, action.payload)
  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Could not add tag(s). Please try again later.', {
      cause: 'customError'
    })
  }
  yield delay(100)
  yield put(getAccountTagsSuccess(response?.data?.account_tags))
  yield put(
    fetchCustomerProfiles({
      page_no: 1,
      page_size: per_page,
      search_string,
      sort_by,
      sort_descending
    })
  )
  yield put(setDrawerAction({ id: CustomerProfileBulkUpdateEntity.tag, value: false }))
  resetSelectedRowRef?.customersTable?.current?.resetRowSelection({})
}

export function* bulkAddAccountBeatRouteEffect(action: Action<addAccountBeatRoutePayload>) {
  const dashboardState: IDashboardState = yield select((app: IAppState) => app.Dashboard)
  const {
    customerProfiles: {
      paginationData,
      sort_by,
      sort_descending,
      search_string,
      resetSelectedRowRef
    }
  } = dashboardState
  const { per_page } = paginationData

  const { data, itemType } = action.payload
  const payload = {
    data,
    userId: dashboardState.businessAccounts.individualUser?.id || ''
  }

  const response: IApiResponse = yield call(updateRoutesAndBeats, payload)
  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error(`Could not add ${itemType}(s). Please try again later.`, {
      cause: 'customError'
    })
  }
  yield delay(100)
  yield put(
    fetchCustomerProfiles({
      page_no: 1,
      page_size: per_page,
      search_string,
      sort_by,
      sort_descending
    })
  )
  yield put(setDrawerAction({ id: itemType, value: false }))
  resetSelectedRowRef?.customersTable?.current?.resetRowSelection({})
}
