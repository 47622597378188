import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faInfoCircle,
  faExclamationTriangle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { THEME } from 'components/theme'
import { NotificationStyle } from './Notification.styles'
import { INotificationComponent, NotificationType } from './Notification.types'
import { deleteNotification } from './Notification.actions'

const styles = createUseStyles(NotificationStyle)

export const Notification: React.FC<INotificationComponent> = ({
  id,
  bodyText,
  actionText,
  type = NotificationType.ERROR,
  onActionClick,
  translate
}) => {
  const classes = styles()
  let icon
  let backgroundColor

  switch (type) {
    case NotificationType.NEUTRAL:
      icon = null
      backgroundColor = THEME.brandDarkerGrey
      break
    case NotificationType.INFO:
      icon = faInfoCircle
      backgroundColor = THEME.brandBlue
      break
    case NotificationType.SUCCESS:
      icon = faCheckCircle
      backgroundColor = THEME.brandDarkGreen
      break
    case NotificationType.ERROR:
      icon = faExclamationTriangle
      backgroundColor = THEME.brandRed
      break
  }

  const dispatch = useDispatch()

  const onCancelClick = () => {
    dispatch(deleteNotification(id))
  }

  return (
    <div className={classes.container} style={{ backgroundColor: backgroundColor }}>
      <div className={classes.cardHeaderContainer}>
        {icon && <FontAwesomeIcon icon={icon} className={classes.displayIcon} />}
        <div className={classes.cardHeader}>
          <div>
            <p style={{ fontSize: '14px' }}>{translate(`transactions:${bodyText}`)}</p>
          </div>
          {actionText && onActionClick ? (
            <div style={{ alignSelf: 'center' }}>
              <button
                hidden={actionText === '' ? true : false}
                className={classes.actionButton}
                onClick={onActionClick}
              >
                {actionText}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.cardFooter}>
        <hr className={classes.varticalLine} />
        <a onClick={onCancelClick} className={classes.crossIcon}>
          <FontAwesomeIcon icon={faTimes} />
        </a>
      </div>
    </div>
  )
}
