import {
  IListViewRowData,
  IOverallApprovedTransactionsResponse,
  ISummaryResponse,
  IPagination,
  IApprovedTransactionsResponse,
  IListGroupViewRowData,
  ITransactionSummaryResponse
} from './approvedCollectionSummary.types'

const formatSummary = (data: ISummaryResponse, isOverall?: boolean): IListViewRowData => {
  return {
    id: isOverall ? 'all-collections' : data.list_id,
    listName: isOverall ? 'Overall' : data.list_name,
    totalCustomers: data.total_customers,
    totalInvoices: data.total_invoices,
    totalCollection: Number(data.total_collected_amount) / 100,
    cash: Number(data.total_cash_amount) / 100,
    neft: Number(data.total_neft_amount) / 100,
    online: Number(data.total_qr_amount) / 100,
    cheque: Number(data.total_cheque_amount) / 100,
    return: Number(data.total_returns_amount) / 100,
    damage: Number(data.total_damage_amount) / 100,
    others: Number(data.total_others_amount) / 100,
    advance: Number(data.total_advance_amount) / 100
  }
}

const formatTransactionSummary = (
  data: ITransactionSummaryResponse,
  entities: any
): IListGroupViewRowData => {
  return {
    listId: data.list_id,
    listName: data.list_name,
    customerId: data.customer_id,
    customerName: entities?.[data.customer_id]?.description || '',
    invoiceNumber: data.invoice_number,
    invoiceAmount: Number(data.invoice_amount) / 100,
    invoiceDate: data.invoice_date,
    latestSettlementDate: data.collection_date,
    cash: Number(data.cash_amount) / 100,
    neft: Number(data.neft_amount) / 100,
    online: Number(data.qr_amount) / 100,
    cheque: Number(data.cheque_amount) / 100,
    return: Number(data.returns_amount) / 100,
    damage: Number(data.damage_amount) / 100,
    others: Number(data.others_amount) / 100,
    advance: Number(data.advance_amount) / 100
  }
}

export const formatOverallApprovedTransactionsData = (
  data: IOverallApprovedTransactionsResponse
): {
  overallListData: IListViewRowData[]
  overallSummaryData: IListViewRowData
  pagination: IPagination
} => {
  const {
    pagination_details: { per_page, page, total_items },
    overall_summary,
    listwise_summary
  } = data
  return {
    overallSummaryData: formatSummary(overall_summary, true),
    overallListData: listwise_summary.map((list) => formatSummary(list)),
    pagination: {
      pageSize: Number(per_page),
      currentPage: Number(page),
      totalEntries: Number(total_items)
    }
  }
}

export const formatApprovedTransactionsGroupData = (
  data: IApprovedTransactionsResponse,
  entities: any
): {
  groupViewData: IListGroupViewRowData[]
  overallSummaryData: IListViewRowData
  pagination: IPagination
} => {
  const {
    pagination_details: { per_page, page, total_items },
    overall_summary,
    list_summaries
  } = data
  return {
    overallSummaryData: formatSummary(overall_summary),
    groupViewData: list_summaries.map((list) => formatTransactionSummary(list, entities)),
    pagination: {
      pageSize: Number(per_page),
      currentPage: Number(page),
      totalEntries: Number(total_items)
    }
  }
}

export const getContentAndFontColor = (advanceUsed: number, advance: number) => {
  let content = 0
  let fontColor: 'default' | 'primary' | 'secondary' | 'danger' | undefined = 'default'

  if (advanceUsed && advance) {
    content = advance - advanceUsed
    fontColor = advance > advanceUsed ? 'primary' : 'danger'
  } else if (advanceUsed) {
    content = advanceUsed
    fontColor = 'danger'
  } else if (advance) {
    content = advance
    fontColor = 'primary'
  }

  return {
    content,
    fontColor
  }
}

export const formatDownloadData = (data: IOverallApprovedTransactionsResponse) => {
  const format = (summary: any) => [
    summary.listName,
    summary.totalCustomers,
    summary.totalInvoices,
    summary.totalCollection,
    summary.cash,
    summary.neft,
    summary.online,
    summary.cheque,
    summary.return,
    summary.damage,
    summary.others,
    summary.advance
  ]

  const headers = [
    'List Name',
    'Total Customers',
    'Total Invoices',
    'Total Collection',
    'Cash',
    'NEFT',
    'Online',
    'Cheque',
    'Return',
    'Damage',
    'Others',
    'Advance'
  ]

  return {
    formattedOverallData: [headers, format(formatSummary(data.overall_summary, true))],
    formattedListData: [
      headers,
      ...data.listwise_summary.map((list) => format(formatSummary(list)))
    ]
  }
}
