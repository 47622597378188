import { takeLatest, takeEvery } from '@redux-saga/core/effects'
import { NavigationActions } from 'state/navigation/navigation.actions'
import { TransactionsActions } from './transaction.actions'
import {
  createTransaction,
  sendReminderToCustomer,
  sendReminderToSupplier,
  showNotificationForCollectionIdDeletion,
  getTxnDataEffect,
  generateTransactionReport,
  deleteTransaction,
  addTransaction,
  addTransactionImageEffect,
  removeTransactionImageEffect,
  editTransactionEffect,
  fetchAccountsRoutesAndBeatsEffect,
  updateAccountsRoutesAndBeatsEffect,
  fetchLatestTransactions,
  fetchDoubleTransactions,
  fetchReportTransactionsEffect
} from './transaction.effects'
import transactionsAnalytics from './transaction.analytics'

function* sendReminderToCustomerSaga() {
  yield takeLatest(TransactionsActions.SEND_REMINDER_TO_CUSTOMER, sendReminderToCustomer)
}

function* sendReminderToSupplierSaga() {
  yield takeLatest(TransactionsActions.SEND_REMINDER_TO_SUPPLIER, sendReminderToSupplier)
}

function* fetchReportTransactionsSaga() {
  yield takeLatest(TransactionsActions.FETCH_REPORT_TRANSACTIONS, fetchReportTransactionsEffect)
}

function* deleteTransactionSaga() {
  yield takeLatest(TransactionsActions.DELETE_TRANSACTION_COMPLETED, deleteTransaction)
}

function* addTransactionInitiatedSaga() {
  yield takeLatest(TransactionsActions.ADD_TRANSACTION_INITIATED, addTransaction)
}
function* addTransactionSaga() {
  yield takeLatest(TransactionsActions.ADD_TRANSACTION_COMPLETED, createTransaction)
}

function* editTransactionSaga() {
  yield takeLatest(TransactionsActions.EDIT_TRANSACTION, editTransactionEffect)
}

function* addTransactionImageSaga() {
  yield takeLatest(TransactionsActions.ADD_TRANSACTION_IMAGE, addTransactionImageEffect)
}

function* removeTransactionImageSaga() {
  yield takeLatest(TransactionsActions.DELETE_TRANSACTION_IMAGE, removeTransactionImageEffect)
}

function* showNotificationForCollectionIdDeletionSaga() {
  yield takeLatest(
    TransactionsActions.SHOW_NOTIFICATION_FOR_COLLECTION_DELETION,
    showNotificationForCollectionIdDeletion
  )
}

function* transactionsAnalyticsSaga() {
  yield takeEvery(
    [...Object.values(TransactionsActions), NavigationActions.ROUTE_CHANGE],
    transactionsAnalytics
  )
}

function* generateTransactionReportSaga() {
  yield takeLatest(TransactionsActions.GENERATE_TRANSACTIONS_REPORT, generateTransactionReport)
}

function* getTransactionDataSaga() {
  yield takeLatest(TransactionsActions.GET_TRANSACTION, getTxnDataEffect)
}

function* fetchAccountsRoutesAndBeatsSaga() {
  yield takeLatest(
    TransactionsActions.FETCH_ACCOUNTS_ROUTES_AND_BEATS,
    fetchAccountsRoutesAndBeatsEffect
  )
}

function* updateAccountsRoutesAndBeatsSaga() {
  yield takeLatest(
    TransactionsActions.UPDATE_ACCOUNTS_ROUTES_AND_BEATS,
    updateAccountsRoutesAndBeatsEffect
  )
}

function* fetchLatestTransactionsSaga() {
  yield takeLatest(TransactionsActions.FETCH_LATEST_TRANSACTIONS, fetchLatestTransactions)
}

function* fetchDoubleTransactionSaga() {
  yield takeEvery(TransactionsActions.FETCH_DOUBLE_TRANSACTIONS, fetchDoubleTransactions)
}

export const TransactionsEffects = [
  sendReminderToCustomerSaga(),
  sendReminderToSupplierSaga(),
  fetchReportTransactionsSaga(),
  addTransactionSaga(),
  deleteTransactionSaga(),
  showNotificationForCollectionIdDeletionSaga(),
  transactionsAnalyticsSaga(),
  getTransactionDataSaga(),
  generateTransactionReportSaga(),
  addTransactionInitiatedSaga(),
  addTransactionImageSaga(),
  removeTransactionImageSaga(),
  editTransactionSaga(),
  fetchAccountsRoutesAndBeatsSaga(),
  updateAccountsRoutesAndBeatsSaga(),
  fetchLatestTransactionsSaga(),
  fetchDoubleTransactionSaga()
]
