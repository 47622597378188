import { getEnvConfig } from 'utils/getEnvConfig'
import {
  IFetchChequeRequestPayload,
  UpdateChequeRequestPayload
} from 'state/new-summary/cheque/cheque.types'
import ApiService from './ApiService'
import { apiRequestInterceptor, businessIdInterceptor } from './apiInterceptor'

const headers = {
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*'
}

let endpoint: string = ''
if (typeof window !== 'undefined') {
  if (window.location.origin.toString().includes('localhost')) {
    endpoint = getEnvConfig('STAFF_LINK_BASE_URL')
  } else {
    endpoint = `${window.location.origin}/api/staff-link`
  }
}

const api = new ApiService(endpoint, headers)
api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
api.requestInterceptor(businessIdInterceptor, (error: any) => console.error(error))

const FETCH_CHEQUE_DETAILS = '/v1/FetchChequeDetails'
const UPDATE_CHEQUE_DETAILS = '/v1/BulkUpdateChequeDetails'

export const fetchChequeDetails = (payload: IFetchChequeRequestPayload) => {
  return api.postRequest(FETCH_CHEQUE_DETAILS, payload)
}

export const updateChequeDetailsApi = (payload: UpdateChequeRequestPayload) => {
  return api.putRequest(UPDATE_CHEQUE_DETAILS, payload)
}
