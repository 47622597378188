import { BillInfoItemType } from 'state/billing/billing.types'
import { SORT_ORDER_TYPE } from 'state/collectionSummary/collectionSummary.types'
import { TRANSACTION_TYPE } from 'utils/common/common.types'

export interface IApproveState {
  filter: ApproveFilterType
  pendingActions: IPendingActionsRowData[]
  pageSize: number
  currentPage: number
  totalEntries: number
  sortOption?: SORT_OPTION
  sortOrder?: SORT_ORDER_TYPE
  showReplacementDrawer: boolean
  showFilterByCollectionListDrawer: boolean
  selectedReplacementData?: IReplacement
  showApproveAllButton?: boolean
  selectedTransactionIds?: string[]
  selectedBillIds?: string[]
  selectedListsWithPendingActions?: string[]
  summaryTotal: ISummaryTotal
  isFetchingData: boolean
  resetSelectedRowRef: {
    actionPendingTable: RowSelectionType
  }
}

type RowSelectionType = React.MutableRefObject<
  | {
      resetRowSelection: (_: any) => void
    }
  | undefined
  | null
> | null

export type TransactionId = string
export interface IPendingActionsRowData {
  assignedTo: string
  customerName: string
  invoiceNumber: string
  invoiceDate: number
  invoiceAmount: number
  invoicePaidAmmount: number
  damages: Transaction
  cash: Transaction
  neft: Transaction
  cheque: Transaction
  advance: Transaction
  qrLink: Transaction
  returns: Transaction
  replace: IReplacement
  others: Transaction
  buttonType: BUTTON_TYPE
  cancelBillTxnId?: string
  deliveryAttemptId?: string
  billId: string
  beats?: string[]
  isSelectable: boolean
  collectedOn: number
}

/**@deprecated */
export enum BUTTON_TYPE {
  DELETE_BUTTON = 'deleteButton',
  DROPDOWN_BUTTON = 'dropdownButton',
  APPROVE_BUTTON = 'approveButton',
  REPLACEMENT_BUTTON = 'replacementButton',
  DELIVERY_BUTTON = 'deliveryButton'
}

export enum APPROVE_FILTER_TYPE {
  ALL = 'All',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM = 'Custom',
  DEFAULT = ''
}

export const DEFAULT_TIME_VALUE_FOR_ALL = -1
export type ApproveFilterType = {
  startTime: number
  endTime: number
  filterType: APPROVE_FILTER_TYPE
}

export interface FetchPendingActionPayload extends ApproveFilterType {
  page: number
  perPage: number
  searchString?: string
  sortOption?: SORT_OPTION
  sortOrder?: SORT_ORDER_TYPE
  selectedListsWithPendingActions?: string[]
}

export enum SORT_OPTION {
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  INVOICE_DATE = 'INVOICE_DATE',
  INVOICE_AMOUNT = 'INVOICE_AMOUNT',
  LIST_NAME = 'LIST_NAME',
  COLLECTED_ON = 'COLLECTED_ON',
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  NEFT = 'NEFT',
  DAMAGED = 'DAMAGED',
  RETURN = 'RETURN',
  REPLACEMENT_AMOUNT = 'REPLACEMENT_AMOUNT',
  OTHERS = 'OTHERS',
  ADVANCE = 'ADVANCE'
}

export interface FetchPendingActionAPIPayload {
  page: number
  per_page: number

  filters: {
    search_string?: string
    transaction_interval?: {
      start_time: number
      end_time: number
    }
  }
  sort_options?: {
    sort_by: SORT_OPTION
    sort_order: number
  }
}

export interface Transaction {
  id: string
  amount: string
  txn_type: number
  txn_time: string
}

export interface IDeliveryAttempts {
  id: string
  bill_id: string
  business_id: string
  staff_uid: string
  list_id: string
  created_at: string
  is_approved: boolean
  action_pending: boolean
  status: string
  updated_at: string
  approved_at: string
}

interface Bill {
  bill_id: string
  account_id: string
  list_id: string
  bill_number: string
  customer_name: string
  list_name: string
  bill_date: string
  latest_settlement_date: string
  total_paid_amount: string
  bill_amount: string
  transactions: Transaction[] // TODO: Fix type of transactions to handle return/damage etc
  cancel_bill_params: null | ICancelBillParam
  replacement?: IReplacement
  beats?: string[] // this is added only for supporting beats filter in collection list tab, becuase common tyypes were still not created, fix this
  delivery_attempts: IDeliveryAttempts[]
  latest_transaction_date: string
  bill_info?: {
    items?: Pick<
      BillInfoItemType,
      'amount' | 'hsn_code' | 'name' | 'quantity' | 'tax' | 'tax_components' | 'unit'
    >[]
  }
}

interface ICancelBillParam {
  id: string
  business_id: string
  associated_account_id: string
  collection_list_id: string
  bill_id: string
  created_by: number
  creator_id: string
  cancel_requested: boolean
  cancel_requested_at: string
  is_cancelled: boolean
  cancelled_at: string
  request_deleted: boolean
  request_deleted_at: string
  creator_name: string
  creator_mobile: string
}

export interface IReplacement {
  id: string
  business_id: string
  account_id: string
  staff_id: string
  bill_id: string
  amount: string
  status: number
  items: IReplacementItem[]
  catalog_items: any[]
  created_at: string
  updated_at: string
  staff_name: string
  staff_mobile: string
}

interface IReplacementItem {
  name: string
  quantity: string
  notes: string
  state: number
}

interface BalanceDueTransaction {
  id: string
  collection_list_id: string
  associated_account_id: string
  business_id: string
  amount: string
  transaction_time: string
  approved_by_merchant: boolean
  account_txn_id: string
  notes: string
  txn_type: number
  staff_id: string
  is_deleted: boolean
  delete_time: string
  created_by: number
  is_bill_settled: boolean
  settlement_bill_ids: string[]
  added_as_advance: boolean
  staff_name: string
  staff_mobile: string
  advance_amount: string
  add_as_advance: boolean
  cheque_details: {
    cheque_id: string
    cheque_number: string
    cheque_date: string
    collected_on: string
    amount: string
    state: number
    bills: any[]
    account_info: null | any
  }
  catalog_items: any[]
  items: any[]
}

interface PaginationDetails {
  page: string
  limit: string
  total_items: string
  total_pages: string
  per_page: string
}

export interface FetchPendingActionResponse {
  pending_actions: Bill[]
  balance_due_txns: BalanceDueTransaction[] // unused
  pagination_details: PaginationDetails // unused
}

export const TRANSACTION_TYPE_KEY_MAPPER: Record<number, string> = {
  [TRANSACTION_TYPE.DAMAGED]: 'damages',
  [TRANSACTION_TYPE.CASH]: 'cash',
  [TRANSACTION_TYPE.NEFT]: 'neft',
  [TRANSACTION_TYPE.CHEQUE]: 'cheque',
  [TRANSACTION_TYPE.ONLINE]: 'qrLink',
  [TRANSACTION_TYPE.RETURN]: 'returns',
  [TRANSACTION_TYPE.OTHERS]: 'others',
  [TRANSACTION_TYPE.WALLET]: 'advance'
}

export enum APPROVE_SECTION_TABLE_TYPES {
  PENDING_ACTIONS = 'pendingActions',
  UNIDENTIFIED_COLLECTION = 'undefinedCollection'
}

export interface ISummaryTotal {
  CASH: string
  CHEQUE: string
  DAMAGED: string
  NEFT: string
  OTHERS: string
  RETURN: string
  WALLET: string
}

export enum SummaryTotalKeys {
  Cash = 'cash',
  NEFT = 'neft',
  Cheque = 'cheque',
  Damages = 'damages',
  Returns = 'returns',
  Others = 'others',
  Advance = 'advance'
}
