import { ICustomer, ISupplier } from '../dashboard.types'
import { IFilterEntity, IFilterUnit, SORT_FILTERS } from '../../../constants'

export const applyFilters = (
  filters: IFilterEntity[],
  customers?: ICustomer[],
  suppliers?: ISupplier[]
) => {
  const selectedSort = filters[0].entries.find((entry: IFilterUnit) => entry.selected)?.id
  // const selectedFilter = filters[1].entries.find((entry: IFilterUnit) => entry.selected)?.id)
  if (selectedSort !== undefined) {
    switch (selectedSort) {
      case SORT_FILTERS.NAME: {
        const sortedCustomers = customers?.sort((customer1: ICustomer, customer2: ICustomer) => {
          if (customer1.description.toUpperCase() < customer2.description.toUpperCase()) {
            return -1
          }
          if (customer1.description > customer2.description) {
            return 1
          }
          return 0
        })
        const sortedSuppliers = suppliers?.sort((supplier1: ISupplier, supplier2: ISupplier) => {
          if (supplier1.name.toUpperCase() < supplier2.name.toUpperCase()) {
            return -1
          }
          if (supplier1.name.toUpperCase() > supplier2.name.toUpperCase()) {
            return 1
          }
          return 0
        })
        return {
          customers: sortedCustomers || [],
          suppliers: sortedSuppliers || []
        }
      }
      case SORT_FILTERS.AMOUNT: {
        const sortedCustomers = customers?.sort(
          (customer1: ICustomer, customer2: ICustomer) => customer1.balance - customer2.balance
        )
        const sortedSuppliers = suppliers?.sort(
          (supplier1: ISupplier, supplier2: ISupplier) =>
            Number(supplier1.balance) - Number(supplier2.balance)
        )
        return {
          customers: sortedCustomers || [],
          suppliers: sortedSuppliers || []
        }
      }
      // case SORT_FILTERS.DUE_DATE: {
      //   // TODO(v0): Implement this
      //   return {
      //     customers: defaultCustomers || [],
      //     suppliers: defaultSuppliers || []
      //   }
      // }
      default: {
        return {
          customers: customers || [],
          suppliers: suppliers || []
        }
      }
    }
  }
  return {
    customers,
    suppliers
  }
  // TODO(v0): Implement Due Date Filter
  // if (selectedFilter) {
  //   // apply filter
  // }
}

export const applyFiltersOnCustomers = (filters: IFilterEntity[], customers?: ICustomer[]) => {
  const selectedSort = filters[0].entries.find((entry: IFilterUnit) => entry.selected)?.id
  if (selectedSort !== undefined) {
    switch (selectedSort) {
      case SORT_FILTERS.NAME: {
        const sortedCustomers = customers?.sort((customer1: ICustomer, customer2: ICustomer) => {
          if (customer1.description.toUpperCase() < customer2.description.toUpperCase()) {
            return -1
          }
          if (customer1.description > customer2.description) {
            return 1
          }
          return 0
        })
        return {
          customers: sortedCustomers || []
        }
      }
      case SORT_FILTERS.AMOUNT: {
        const sortedCustomers = customers?.sort(
          (customer1: ICustomer, customer2: ICustomer) => customer1.balance - customer2.balance
        )
        return {
          customers: sortedCustomers || []
        }
      }
      case SORT_FILTERS.DUE_DATE: {
        const sortedCustomers = customers?.sort(
          (customer1: ICustomer, customer2: ICustomer) =>
            customer2.lastActivityAt - customer1.lastActivityAt
        )
        return {
          customers: sortedCustomers || []
        }
      }
      default: {
        return {
          customers: customers || []
        }
      }
    }
  }
  return {
    customers
  }
}
