import { Action } from 'infra/types'
import {
  IDrawers,
  ActionWithPagination,
  IDocumentsType,
  IPagination,
  ICurrentDocumentSelected,
  ICollectionListFromDocument,
  ILoaders,
  ICurrentTemplate,
  ICurrentErrorDocumentSelected,
  AdvanceColumnsType
} from './uploadSheet.types'

export const UploadSheetActions = {
  FETCH_DOCUMENTS_LIST: 'uploadSheet/FETCH_DOCUMENTS_LIST',
  FETCH_DOCUMENTS_LIST_SUCCESS: 'uploadSheet/FETCH_DOCUMENTS_LIST_SUCCESS',
  CURRENT_DOCUMENT_SELECTED: 'uploadSheet/CURRENT_DOCUMENT_SELECTED',
  COLLECTION_LIST_FROM_DOCUMENT: 'uploadSheet/COLLECTION_LIST_FROM_DOCUMENT',
  ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT:
    'uploadSheet/ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT',
  FETCH_TEMPLATES_LIST: 'uploadSheet/FETCH_TEMPLATES_LIST',
  FETCH_TEMPLATES_LIST_SUCCESS: 'uploadSheet/FETCH_TEMPLATES_LIST_SUCCESS',
  FETCH_DEFAULT_TEMPLATE: 'uploadSheet/FETCH_DEFAULT_TEMPLATE',
  CREATE_TEMPLATE: 'uploadSheet/CREATE_TEMPLATE',
  UPDATE_DEFAULT_TEMPLATE: 'uploadSheet/UPDATE_DEFAULT_TEMPLATE',
  SET_CURRENT_TEMPLATES: 'uploadSheet/SET_CURRENT_TEMPLATES',
  CURRENT_ERROR_DOCUMENT_SELECTED: 'uploadSheet/CURRENT_ERROR_DOCUMENT_SELECTED',
  SET_LOADER: 'uploadSheet/SET_LOADER',
  SET_DRAWER: 'uploadSheet/SET_DRAWER',
  RESET_CURRENT_DOCUMENT_SELECTED: 'uploadSheet/RESET_CURRENT_DOCUMENT_SELECTED',
  DELETE_UPLOADED_FILE_WITH_DATA: 'uploadSheet/DELETE_UPLOADED_FILE_WITH_DATA',
  SAVE_FILE_HTML_DATA: 'uploadSheet/SAVE_FILE_HTML_DATA',
  SAVE_RAW_FILE: 'uploadSheet/SAVE_RAW_FILE'
}

export const fetchDocumentsList = (payload?: {
  refetch: boolean
}): Action<{ refetch: boolean } | undefined> => ({
  type: UploadSheetActions.FETCH_DOCUMENTS_LIST,
  payload
})

export const fetchDocumentsListSuccess = (
  documentsList: IDocumentsType[],
  pagination: IPagination
): ActionWithPagination<IDocumentsType[]> => ({
  type: UploadSheetActions.FETCH_DOCUMENTS_LIST_SUCCESS,
  payload: documentsList,
  pagination
})

export const setCurrentDocumentSelected = (
  payload: ICurrentDocumentSelected | null
): Action<ICurrentDocumentSelected | null> => ({
  type: UploadSheetActions.CURRENT_DOCUMENT_SELECTED,
  payload
})

export const setCurrentErrorDocumentSelected = (
  payload: ICurrentErrorDocumentSelected | null
): Action<ICurrentErrorDocumentSelected | null> => ({
  type: UploadSheetActions.CURRENT_ERROR_DOCUMENT_SELECTED,
  payload
})

export const collectionListFromDocument = (
  payload: ICollectionListFromDocument | null
): Action<ICollectionListFromDocument | null> => ({
  type: UploadSheetActions.COLLECTION_LIST_FROM_DOCUMENT,
  payload
})

export const addToMultipleCollectionListFromDocument = (payload: any): Action<any> => ({
  type: UploadSheetActions.ADD_TO_MULTIPLE_COLLECTION_LIST_FROM_DOCUMENT,
  payload
})

// Keep end

export const fetchTemplatesList = (): Action<null> => ({
  type: UploadSheetActions.FETCH_TEMPLATES_LIST,
  payload: null
})

export const fetchTemplatesListSuccess = (payload: {
  templatesList: any[]
  globalTemplatesList: any[]
}): Action<{
  templatesList: any[]
  globalTemplatesList: any[]
}> => ({
  type: UploadSheetActions.FETCH_TEMPLATES_LIST_SUCCESS,
  payload
})

export const setCurrentTemplateS = (
  payload: ICurrentTemplate[] | null
): Action<ICurrentTemplate[] | null> => ({
  type: UploadSheetActions.SET_CURRENT_TEMPLATES,
  payload
})

export const fetchDefaultTemplate = (): Action<null> => ({
  type: UploadSheetActions.FETCH_DEFAULT_TEMPLATE,
  payload: null
})

export const createNewTemplate = (payload: {
  name: string
  document_type: number
  sheetDetails: {
    name: string
    header: string
  }
  columnFormula?: {
    applyInvoiceNumberFormula?: boolean
  }
  columnMapping: {
    customerName: string
    customerCode: string
    invoiceNumber: string
    amount: string
    invoiceDate: string
    saleType: string
    saleRoute: string
    mobileNumber: string
    paymentReconBillNumber: string
    accountBeat: string
    accountRoute: string
    advanceColumns?: AdvanceColumnsType
  }
  amountRoundingType: number
}): Action<{
  name: string
  document_type: number
  sheetDetails: {
    name: string
    header: string
  }
  columnFormula?: {
    applyInvoiceNumberFormula?: boolean
  }
  columnMapping: {
    customerName: string
    customerCode: string
    invoiceNumber: string
    amount: string
    invoiceDate: string
    saleType: string
    saleRoute: string
    mobileNumber: string
    paymentReconBillNumber: string
    accountBeat: string
    accountRoute: string
  }
  amountRoundingType: number
}> => ({
  type: UploadSheetActions.CREATE_TEMPLATE,
  payload
})

export const updateDefaultTemplate = (payload: {
  templateId?: string
  document_type: number
}): Action<{
  templateId?: string
  document_type: number
}> => ({
  type: UploadSheetActions.UPDATE_DEFAULT_TEMPLATE,
  payload
})

export const handleLoaderToggle = (payload: ILoaders): Action<ILoaders> => ({
  type: UploadSheetActions.SET_LOADER,
  payload
})

export const handleDrawerToggle = (payload: IDrawers): Action<IDrawers> => ({
  type: UploadSheetActions.SET_DRAWER,
  payload
})

export const resetCurrentErrorDocumentSelected = (): Action<null> => ({
  type: UploadSheetActions.RESET_CURRENT_DOCUMENT_SELECTED,
  payload: null
})

export const deleteUploadedFileWithDataAction = (payload: string): Action<string> => ({
  type: UploadSheetActions.DELETE_UPLOADED_FILE_WITH_DATA,
  payload
})

export const saveFileHtmlData = (payload: Array<string>): Action<Array<string>> => ({
  type: UploadSheetActions.SAVE_FILE_HTML_DATA,
  payload
})

export const saveRawFile = (payload: any): Action<any> => ({
  type: UploadSheetActions.SAVE_RAW_FILE,
  payload
})
