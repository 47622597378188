import { IReplacement } from 'services/Api/StaffLinkApiFolder/type'
import { TRANSACTION_TYPE } from 'utils/common/common.types'
export interface IOnHoldBillData {
  billId: string
  billNumber: string
  billAmount: number
}

export type SummaryKeys = 'cash' | 'cheque' | 'neft' | 'returns' | 'damages' | 'others' | 'qrLink'

export interface ISelectedSettingsForAddTxn {
  accountId: string
  billId?: string
  remainingAmount: string | number
  billDate: string
}

export interface IApproveTable
  extends Omit<
    TableColumns,
    | 'assignedTo'
    | 'replace'
    | 'cash'
    | 'neft'
    | 'cheque'
    | 'advance'
    | 'qrLink'
    | 'returns'
    | 'others'
    | 'damages'
  > {
  pendingDue: number
  beats?: string[]
  damages: Transaction[]
  cash: Transaction[]
  neft: Transaction[]
  cheque: Transaction[]
  advance: Transaction[]
  qrLink: Transaction[]
  returns: Transaction[]
  others: Transaction[]
  billId: string
  accountRoutes?: IAccountRoute[]
  accountBeats?: IAccountBeat[]
  billBeats?: IBillBeat[]
}
export interface INoActionsTable
  extends Pick<TableColumns, 'customerName' | 'invoiceNumber' | 'invoiceAmount' | 'buttonType'> {
  invoiceAge: string
  remainingAmount: number
  customerId: string
  billId: string
  beats?: string[]
  billDate?: string
  accountRoutes?: IAccountRoute[]
  accountBeats?: IAccountBeat[]
  billBeats?: IBillBeat[]
}

export interface IRescheduledDeliverieTable {
  customerName: string
  invoiceNumber: string
  invoiceAge: string
  invoiceAmount: number
  invoiceAmountPaid: number
  billDate: string
  customerId: string
  billId: string
}

export interface IPendingTable extends TableColumns {
  billId: string
  beats?: string[]
  accountId: string
  unapprovedDeliveryAttemptId?: string
  accountBeats?: IAccountBeat[]
  billBeats?: IBillBeat[]
  accountRoutes?: IAccountRoute[]
} // This type not to be imported from approve, create common type s code
// Following code is Copied code from approve type, do something for this redundant data - start
export interface TableColumns {
  assignedTo: string
  customerName: string
  invoiceNumber: string
  invoiceDate: number
  invoiceAmount: number
  invoiceAmountPaid: number
  damages: Transaction
  cash: Transaction
  neft: Transaction
  cheque: Transaction
  advance: Transaction
  qrLink: Transaction
  returns: Transaction
  replace: IReplacement
  others: Transaction
  buttonType: BUTTON_TYPE
  cancelBillTxnId?: string
}

export interface Transaction {
  id: string
  amount: string
  txn_type: number
  txn_time: string
}

// Remove this while creating common types

export enum BUTTON_TYPE {
  DELETE_BUTTON = 'deleteButton',
  DROPDOWN_BUTTON = 'dropdownButton',
  APPROVE_BUTTON = 'approveButton',
  REPLACEMENT_BUTTON = 'replacementButton',
  ADD_TO_COLLECTION = 'addToCollection',
  CANCEL_BUTTON = 'cancelButton',
  DELIVERY_BUTTON = 'deliveryButton',
  REMOVED_BUTTON = 'removedButton'
}

export const TRANSACTION_TYPE_KEY_MAPPER: Record<number, string> = {
  [TRANSACTION_TYPE.DAMAGED]: 'damages',
  [TRANSACTION_TYPE.CASH]: 'cash',
  [TRANSACTION_TYPE.NEFT]: 'neft',
  [TRANSACTION_TYPE.CHEQUE]: 'cheque',
  [TRANSACTION_TYPE.ONLINE]: 'qrLink',
  [TRANSACTION_TYPE.RETURN]: 'returns',
  [TRANSACTION_TYPE.OTHERS]: 'others',
  [TRANSACTION_TYPE.WALLET]: 'advance'
}

export enum LIST_TYPE {
  INVOICE_LEVEL_DUE_V2 = 5
}

export type RowSelectionType =
  | React.MutableRefObject<
      | {
          resetRowSelection: (_: any) => void
        }
      | undefined
      | null
    >
  | undefined
  | null

export type TableRefType = React.MutableRefObject<null> | null

export interface IAccountBeat {
  beat_id: string
  name: string
  account_id: string
}

export interface IBillBeat extends IAccountBeat {
  bill_id: string
}

export interface IAccountRoute {
  route_id: string
  name: string
  account_id: string
}

export const DEFAULT_BEAT = 'Unassigned'
export const DEFAULT_ROUTE = 'Unassigned'
