import { IInvoiceTag } from './tags.types'

export const formatInvoiceTags = (tags: IInvoiceTag[]) => {
  const accountTags: IInvoiceTag[] = []
  const invoiceTags: IInvoiceTag[] = []
  tags.forEach((tag) => {
    if (tag.account_transaction_id) {
      invoiceTags.push(tag)
    } else {
      accountTags.push(tag)
    }
  })
  return [...accountTags, ...invoiceTags]
}
