import { v4 as uuidv4 } from 'uuid'
import { ICreateTransaction, TRANSACTION_TYPE } from '../transaction.types'

const getTime = (date: ICreateTransaction['date']) => {
  return new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0).getTime()
}

export const getCreateSupplierTransactionPayload = (payload: ICreateTransaction) => {
  const { note, amount, date, transactionType, images } = payload

  return {
    request_id: uuidv4(),
    transaction: {
      payment: transactionType === TRANSACTION_TYPE.PAYMENT,
      amount: Number((amount * 100).toFixed(0)),
      bill_date: getTime(date) / 1000, //convert to unix
      ...(note && note.length ? { note: note } : {}),
      images
    }
  }
}

export const getCreateCustomerTransactionPayload = (payload: ICreateTransaction) => {
  const { note, amount, date, transactionType, entityId, images } = payload

  return {
    request_id: uuidv4(),
    customer_id: entityId,
    type: transactionType,
    amount_v2: (amount * 100).toFixed(0),
    bill_date: getTime(date) / 1000, //convert to unix
    ...(note && note.length ? { note: note } : {}),
    images
  }
}

export const getCreateCustomerBillTransactionPayload = (payload: ICreateTransaction) => {
  const { note, amount, date, entityId, images, billNumber } = payload

  return {
    customer_id: entityId,
    total_amount: Number((amount * 100).toFixed(0)),
    bill_date: getTime(date) / 1000, //convert to unix
    ...(note && note.length ? { note: note } : {}),
    images,
    bill_number: billNumber
  }
}
