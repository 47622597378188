import Storage, { IStorageType } from './Storage'

enum SessionData {
  SESSION_STARTED = 'session_started'
}

class SessionTokens extends Storage<SessionData> {
  private static instance?: SessionTokens

  private constructor() {
    super(IStorageType.SESSION_STORAGE)
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new SessionTokens()
    }
    return this.instance
  }

  public setSessionStarted(value: boolean) {
    this.set(SessionData.SESSION_STARTED, value.toString())
  }

  public getSessionStarted(): boolean {
    const sessionStarted = this.get(SessionData.SESSION_STARTED)
    if (sessionStarted) {
      return sessionStarted === 'true'
    }
    return false
  }

  public clear() {
    this.clearItems([SessionData.SESSION_STARTED])
  }
}

export const AuthSessionStorageInstance = SessionTokens.getInstance()
