import { Action } from 'infra/types'
import { IApproveState, APPROVE_FILTER_TYPE } from './approve.types'
import { approveAction } from './approve.actions'

const APPROVE_INITIAL_STATE: IApproveState = {
  filter: {
    startTime: 0,
    endTime: 0,
    filterType: APPROVE_FILTER_TYPE.DEFAULT
  },
  pendingActions: [],
  pageSize: 50,
  currentPage: 1,
  totalEntries: 0,
  showReplacementDrawer: false,
  showFilterByCollectionListDrawer: false,
  showApproveAllButton: false,
  selectedTransactionIds: [],
  selectedBillIds: [],
  resetSelectedRowRef: {
    actionPendingTable: null
  },
  selectedListsWithPendingActions: [],
  isFetchingData: true,
  summaryTotal: {
    CASH: '',
    CHEQUE: '',
    DAMAGED: '',
    NEFT: '',
    OTHERS: '',
    RETURN: '',
    WALLET: ''
  }
}

export const ApproveReducer = (
  state: IApproveState = APPROVE_INITIAL_STATE,
  action: Action<any>
): IApproveState => {
  switch (action.type) {
    case approveAction.SET_PENDING_ACTIONS_FILTER: {
      return {
        ...state,
        filter: action.payload.filter,
        sortOption: action.payload.sortOption,
        sortOrder: action.payload.sortOrder
      }
    }
    case approveAction.SET_REPLACEMENT_DRAWER_DATA: {
      return {
        ...state,
        showReplacementDrawer: action.payload.status,
        selectedReplacementData: action.payload.data
      }
    }
    case approveAction.APPROVE_ALL: {
      return {
        ...state,
        showApproveAllButton: action.payload.status,
        selectedTransactionIds: action.payload.txnIds,
        selectedBillIds: action.payload.billIds
      }
    }
    case approveAction.SET_PENDING_ACTIONS_DATA: {
      return {
        ...state,
        pendingActions: action.payload
      }
    }
    case approveAction.SHOW_FILTER_BY_COLLECTION_LIST_DRAWER: {
      return {
        ...state,
        showFilterByCollectionListDrawer: action.payload
      }
    }
    case approveAction.SET_SELECTED_LISTS_WITH_PENDING_ACTIONS: {
      return {
        ...state,
        selectedListsWithPendingActions: action.payload
      }
    }
    case approveAction.SET_PAGINATION_DATA: {
      const { pageSize, currentPage, totalEntries } = action.payload
      return {
        ...state,
        pageSize,
        currentPage,
        totalEntries
      }
    }
    case approveAction.SET_SUMMARY_TOTAL: {
      return {
        ...state,
        summaryTotal: action.payload
      }
    }
    case approveAction.SET_FETCHING_LOADERS: {
      return {
        ...state,
        isFetchingData: action.payload
      }
    }
    default: {
      return state
    }
  }
}
