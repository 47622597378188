import {
  Account,
  GroupSpecificData,
  IBillsData,
  ISpecificGroupTableData,
  IStatementGroupTable,
  ResponseBill,
  ResponseData,
  SpecificGroupSummary
} from './supply.types'

export function formatSpecificGroupsData(data: GroupSpecificData): ISpecificGroupTableData[] {
  const { accounts } = data

  return accounts.map((account: Account) => {
    const {
      customer_code,
      id,
      name,
      summary_stats: {
        balance_due,
        total_cash_payments,
        total_neft_payments,
        total_damages,
        total_returns,
        total_other_payments,
        total_wallet_payments,
        total_cheque_payments,
        total_online_payments,
        total_payments,
        total_dues
      }
    } = account

    const invoiceDue = Number(balance_due) / 100
    const credit = Number(total_dues) / 100
    const totalPayments = Number(total_payments) / 100
    const cash = Number(total_cash_payments) / 100
    const neft = Number(total_neft_payments) / 100
    const damages = Number(total_damages) / 100
    const advanceUsed = Number(total_wallet_payments) / 100
    const returns = Number(total_returns) / 100
    const others = Number(total_other_payments) / 100
    const cheque = Number(total_cheque_payments) / 100
    const qrLink = Number(total_online_payments) / 100

    return {
      customerCode: customer_code,
      customerId: id,
      customerName: name,
      invoiceDue,
      credit,
      totalPayments,
      damages,
      cash,
      neft,
      cheque,
      advanceUsed,
      qrLink,
      returns,
      others
    }
  })
}

export function formatGroupDataHeading(accounts: Account[]): SpecificGroupSummary {
  return accounts.reduce(
    (acc: SpecificGroupSummary, account: Account) => {
      const {
        summary_stats: {
          balance_due,
          total_cash_payments,
          total_neft_payments,
          total_damages,
          total_returns,
          total_other_payments,
          total_wallet_payments,
          total_cheque_payments,
          total_online_payments,
          total_payments,
          total_dues
        }
      } = account
      acc.invoiceDue = acc.invoiceDue + Number(balance_due) / 100
      acc.credit = acc.credit + Number(total_dues) / 100
      acc.totalPayments = acc.totalPayments + Number(total_payments) / 100
      acc.damages = acc.damages + Number(total_damages) / 100
      acc.cash = acc.cash + Number(total_cash_payments) / 100
      acc.neft = acc.neft + Number(total_neft_payments) / 100
      acc.cheque = acc.cheque + Number(total_cheque_payments) / 100
      acc.advanceUsed = acc.advanceUsed + Number(total_wallet_payments) / 100
      acc.qrLink = acc.qrLink + Number(total_online_payments) / 100
      acc.returns = acc.returns + Number(total_returns) / 100
      acc.others = acc.others + Number(total_other_payments) / 100

      return acc
    },
    {
      invoiceDue: 0,
      credit: 0,
      totalPayments: 0,
      damages: 0,
      cash: 0,
      neft: 0,
      cheque: 0,
      advanceUsed: 0,
      qrLink: 0,
      returns: 0,
      others: 0
    } as SpecificGroupSummary
  )
}

export function formatStatementGroupsData(data: ResponseData): IStatementGroupTable[] {
  const { collection_lists } = data

  return collection_lists.map((collectionList) => {
    const {
      id,
      name,
      summary_stats: {
        balance_due,
        total_cash_payments,
        total_neft_payments,
        total_damages,
        total_returns,
        total_other_payments,
        total_wallet_payments,
        total_cheque_payments,
        total_online_payments,
        total_payments,
        total_dues
      }
    } = collectionList

    const invoiceDue = Number(balance_due) / 100
    const credit = Number(total_dues) / 100
    const totalPayments = Number(total_payments) / 100
    const cash = Number(total_cash_payments) / 100
    const neft = Number(total_neft_payments) / 100
    const damages = Number(total_damages) / 100
    const advanceUsed = Number(total_wallet_payments) / 100
    const returns = Number(total_returns) / 100
    const others = Number(total_other_payments) / 100
    const cheque = Number(total_cheque_payments) / 100
    const qrLink = Number(total_online_payments) / 100

    return {
      groupId: id,
      groupName: name,
      invoiceDue,
      credit,
      totalPayments,
      damages,
      cash,
      neft,
      cheque,
      advanceUsed,
      qrLink,
      returns,
      others
    }
  })
}

export function formatInvoices(bills?: ResponseBill[]): IBillsData[] {
  if (!bills) {
    return []
  }

  return bills.map((bill: ResponseBill) => ({
    invoiceNumber: bill.bill_number,
    invoiceDate: bill.bill_date,
    invoiceAmountPayable: Number(bill.bill_amount) / 100,
    invoiceAmountDue: Number(bill.bill_due_amount || 0) / 100,
    invoiceStatus: bill.status
  }))
}
