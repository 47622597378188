import { getNormalizedList } from 'utils/dataNormalizer'
import { Action } from '../../infra/types'
import { BeatsActions } from './beats.actions'
import { BeatsState } from './beats.state'
import { IBeatsState } from './beats.types'

export const BeatsReducer = (state: IBeatsState = BeatsState, action: Action<any>): IBeatsState => {
  switch (action.type) {
    case BeatsActions.FETCH_BEATS_SUCCESS: {
      const normalisedBeats = getNormalizedList(action.payload.beats)
      const normalisedRoutes = getNormalizedList(action.payload.routes)
      return {
        ...state,
        normalisedBeats,
        normalisedRoutes
      }
    }
    case BeatsActions.CREATE_BEAT_ROUTE: {
      return {
        ...state,
        loading: true
      }
    }
    case BeatsActions.CREATE_BEAT_SUCCESS: {
      const { beats, routes } = action.payload
      const normalisedNewBeats = getNormalizedList(beats)
      const normalisedNewRoutes = getNormalizedList(routes)
      return {
        ...state,
        loading: false,
        normalisedBeats: {
          ...state.normalisedBeats,
          byIds: { ...state.normalisedBeats.byIds, ...normalisedNewBeats.byIds },
          allIds: state.normalisedBeats.allIds.concat(normalisedNewBeats.allIds)
        },
        normalisedRoutes: {
          ...state.normalisedBeats,
          byIds: { ...state.normalisedRoutes.byIds, ...normalisedNewRoutes.byIds },
          allIds: state.normalisedRoutes.allIds.concat(normalisedNewRoutes.allIds)
        }
      }
    }
    case BeatsActions.CREATE_BEAT_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case BeatsActions.FETCH_INVOICE_BEATS_SUCCESS: {
      return {
        ...state,
        currentInvoiceBeats: action.payload
      }
    }
    case BeatsActions.LINK_DELINK_INVOICE_BEATS_SUCCESS: {
      return {
        ...state,
        currentInvoiceBeats: action.payload
      }
    }
    case BeatsActions.HANDLE_DRAWER_TOGGLE: {
      return {
        ...state,
        drawers: {
          ...state.drawers,
          ...action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}
