/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put, select, fork } from '@redux-saga/core/effects'
import { utils, writeFileXLSX } from 'xlsx'
import dayjs from 'dayjs'
import { Action } from 'infra/types'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import {
  getCollectionGroupInvoiceSummaryV2Api,
  getCollectionGroupInvoiceSummaryV3Api,
  getCollectionListInvoiceSummaryV3Api,
  getCollectionListInvoiceSummaryV2Api,
  getTransactionDetailsApi,
  getOverallSummaryApi,
  getOverallInvoiceSummaryApi
} from 'services/Api/StafflinkApi'
import { IExpState } from 'state/experiment/experiment.types'

import { IMerchantProfile } from 'state/dashboard/dashboard.types'
import { IAppState } from 'infra/AppState'
import { ITransactionPayload } from 'services/Api/types'
import { TRANSACTION_TYPE } from 'state/collectionList/collectionList.types'
import { SUMMARY_FILTER_OPTION } from 'pages/CollectionSummary/CollectionSummary.constants'
import { setSpecificStatementGroup } from 'state/new-summary/supply/supply.actions'
import { getMaxColumnWidths } from 'state/statements/statements.helpers'
import { API_RESPONSE_TYPE, IApiResponse } from './../../constants'
import {
  fetchTransactionDetailsSuccessAction,
  getCollectionGroupInvoiceSummaryV2ActionSuccess,
  getCollectionListInvoiceSummaryV2SuccessAction,
  setCollectionSummaryModalsAction,
  setLoaderAction,
  setOverallSummaryData,
  setSortById,
  setStatementGroup,
  setSummaryActiveFilter
} from './collectionSummary.actions'
import {
  formatOverallSummaryRowData,
  formatTransactionDetails,
  getFormattedCollectionGroupInvoiceV2Summary,
  getFormattedCollectionListInvoiceV2Summary,
  getFormattedGroupViewDataData,
  getFormattedOverallInvoice,
  getFormattedShortSummaryData,
  summaryDataGroupApi,
  summaryDataListApi
} from './collectionSummary.helpers'
import {
  CollectionSummaryModalNameEnum,
  IDownloadCollectionSingleSummaryPayload,
  IFetchCollectionGroupInvoiceSummaryV2ActionPayload,
  IFetchCollectionListInvoiceSummaryV2ActionPayload,
  ITransactionAction,
  SORT_ORDER_TYPE
} from './collectionSummary.types'

/**
 *  @description
 * Code in the following effect assumes that
 * SAME_DAY_COLLECTION ==> Overall Tab
 * OLDER_DAY_COLLECTION ==> Collection Tab
 *
 * Later the requirement changed, where we have to always pass ALL_COLLECTION filter to overall summary APIs
 * 1. /v1/OverallBusinessCollectionSummary
 * 2. /v1/OverallBusinessInvoicesSummary
 *
 * Above APIs are called in overall tab, we discard the store value and
 * send default ALL_COLLECTION filter in these APIs
 *
 * NOTE: ALL_COLLECTION filter is also passed for v2/StatementGroup when it is called under overall tab
 */

const isOverallTab = (invoiceCollectionFilter: SUMMARY_FILTER_OPTION) => {
  return invoiceCollectionFilter === SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
}

function* fetchOverallSummary({ startTime, endTime }: { startTime: number; endTime: number }) {
  yield put(setLoaderAction({ id: 'allCollectionSummary', value: true }))
  const overallSummaryResponse: IApiResponse = yield call(getOverallSummaryApi, {
    start_time: startTime,
    end_time: endTime
  })
  if (overallSummaryResponse.type === API_RESPONSE_TYPE.SUCCESS) {
    const formattedOverallSummary = formatOverallSummaryRowData(overallSummaryResponse?.data)
    yield put(setOverallSummaryData(formattedOverallSummary))
  }
  yield put(setLoaderAction({ id: 'allCollectionSummary', value: false }))
}

function* fetchOverallList({
  startTime,
  endTime,
  page,
  perPage,
  invoiceCollectionFilter,
  sortBy,
  sortOrder,
  includeWalletBalance = false,
  filterType
}: IFetchCollectionListInvoiceSummaryV2ActionPayload) {
  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })
  const experimentState: IExpState = yield select((state: IAppState) => {
    return state.Experiment
  })
  const isDynamicStatementEnabled = experimentState.ab.dynamicStatements

  yield put(setLoaderAction({ id: 'listSummary', value: true }))
  const apiReq = isDynamicStatementEnabled
    ? getCollectionListInvoiceSummaryV3Api
    : getCollectionListInvoiceSummaryV2Api

  const response: IApiResponse = yield call(apiReq, {
    start_time: startTime,
    end_time: endTime,
    business_id: merchantProfile.id,
    page,
    per_page: perPage,
    filter:
      isOverallTab(invoiceCollectionFilter) && isDynamicStatementEnabled
        ? SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
        : invoiceCollectionFilter,
    ...(sortBy &&
      sortOrder && {
        sort_by: sortBy,
        sort_descending: sortOrder === SORT_ORDER_TYPE.descending
      }),
    ...(includeWalletBalance && { include_total_wallet_balance: true })
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Could not fetch summary. Please try again after some time.', {
      cause: 'customError'
    })
  }

  const formattedSummary = getFormattedCollectionListInvoiceV2Summary(
    response.data,
    isDynamicStatementEnabled
  )

  yield put(
    getCollectionListInvoiceSummaryV2SuccessAction({
      data: formattedSummary,
      invoiceCollectionFilter
    })
  )

  yield put(
    setSummaryActiveFilter({
      startTime,
      endTime,
      filterType
    })
  )

  yield put(setSortById(sortBy || '', sortOrder || null))
  yield put(setLoaderAction({ id: 'listSummary', value: false }))
}

export function* getCollectionListInvoiceSummaryV2Effect(
  action: Action<IFetchCollectionListInvoiceSummaryV2ActionPayload>
) {
  const { startTime, endTime, invoiceCollectionFilter } = action.payload
  if (isOverallTab(invoiceCollectionFilter)) {
    yield fork(fetchOverallSummary, { startTime, endTime })
  }
  yield fork(fetchOverallList, action.payload)
}

export function* getCollectionGroupInvoiceSummaryV2Effect(
  action: Action<IFetchCollectionGroupInvoiceSummaryV2ActionPayload>
) {
  const {
    startTime,
    endTime,
    page,
    perPage,
    listId,
    filterType,
    invoiceCollectionFilter = SUMMARY_FILTER_OPTION.ALL_COLLECTIONS,
    sortBy,
    sortOrder
  } = action.payload
  const experimentState: IExpState = yield select((state: IAppState) => {
    return state.Experiment
  })
  const isDynamicStatementEnabled = experimentState.ab.dynamicStatements

  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })
  yield put(setLoaderAction({ id: 'groupSummary', value: true }))

  const apiReq =
    listId === 'all-collections'
      ? getOverallInvoiceSummaryApi
      : isDynamicStatementEnabled
      ? getCollectionGroupInvoiceSummaryV3Api
      : getCollectionGroupInvoiceSummaryV2Api

  const response: IApiResponse = yield call(apiReq, {
    start_time: startTime,
    end_time: endTime,
    business_id: merchantProfile.id,
    page,
    per_page: perPage,
    collection_list_id: listId,
    filter:
      isOverallTab(invoiceCollectionFilter) && isDynamicStatementEnabled
        ? SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
        : invoiceCollectionFilter,
    ...(sortBy &&
      sortOrder && {
        sort_options: {
          sort_by: sortBy,
          sort_order: sortOrder === SORT_ORDER_TYPE.descending ? 1 : 0
        }
      })
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something Went Wrong', {
      cause: 'customError'
    })
  }
  const formattedSummary = getFormattedCollectionGroupInvoiceV2Summary(response.data)
  if (response.data.statement_group && isDynamicStatementEnabled) {
    yield put(setStatementGroup(response.data.statement_group))
    yield put(setSpecificStatementGroup(response.data.statement_group))
  }
  const api =
    listId === 'all-collections'
      ? getOverallSummaryApi
      : isDynamicStatementEnabled
      ? getCollectionListInvoiceSummaryV3Api
      : getCollectionListInvoiceSummaryV2Api
  const res: IApiResponse = yield call(api, {
    start_time: startTime,
    end_time: endTime,
    business_id: merchantProfile.id,
    page,
    per_page: perPage,
    filter:
      isOverallTab(invoiceCollectionFilter) && isDynamicStatementEnabled
        ? SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
        : invoiceCollectionFilter,
    ...(isDynamicStatementEnabled
      ? {
          group_ids: [listId]
        }
      : {
          collection_list_ids: [listId]
        })
  })

  let formattedData

  if (listId === 'all-collections') {
    formattedData = getFormattedOverallInvoice(res.data)
  } else {
    formattedData = getFormattedCollectionListInvoiceV2Summary(res.data, isDynamicStatementEnabled)
  }

  yield put(
    getCollectionGroupInvoiceSummaryV2ActionSuccess({
      groupViewData: formattedSummary,
      invoiceCollectionFilter,
      sortOptions: {
        sortBy,
        sortOrder
      },
      shortSummaryData: formattedData.rowData[0]
    })
  )
  if (!isDynamicStatementEnabled) {
    yield put(
      setSpecificStatementGroup({
        name: res.data.collection_lists?.[0]?.name,
        id: res.data.collection_lists?.[0]?.id
      })
    )
  }

  yield put(
    setSummaryActiveFilter({
      startTime,
      endTime,
      filterType
    })
  )

  yield put(setLoaderAction({ id: 'groupSummary', value: false }))
}

export function* fetchTransactionDetailsEffect(action: Action<ITransactionAction>) {
  try {
    const payload: ITransactionPayload = {
      account_id: action.payload.accountId,
      collection_list_id: action.payload.listId,
      transaction_type: TRANSACTION_TYPE.ONLINE,
      bill_id: action.payload.invoiceId,
      start_time: action.payload.startTime,
      end_time: action.payload.endTime
    }
    yield put(setLoaderAction({ id: 'transactionDetails', value: true }))
    const response: IApiResponse = yield call(getTransactionDetailsApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const formattedTransactionDetail = formatTransactionDetails(response.data)
      yield put(fetchTransactionDetailsSuccessAction(formattedTransactionDetail))
      yield put(setLoaderAction({ id: 'transactionDetails', value: false }))
    } else {
      yield put(setLoaderAction({ id: 'transactionDetails', value: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch transaction details. Please try again after some time.'
        })
      )
    }
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'transactionDetails', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* downloadCollectionGroupInvoiceSummaryEffect(
  action: Action<IDownloadCollectionSingleSummaryPayload>
) {
  const {
    startTime,
    endTime,
    page,
    perPage,
    listId,
    invoiceCollectionFilter = SUMMARY_FILTER_OPTION.ALL_COLLECTIONS,
    fileName
  } = action.payload
  const experimentState: IExpState = yield select((state: IAppState) => {
    return state.Experiment
  })
  const isDynamicStatementEnabled = experimentState.ab.dynamicStatements

  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })
  yield put(setLoaderAction({ id: 'groupSummary', value: true }))
  const apiReq = summaryDataGroupApi(listId, isDynamicStatementEnabled)
  const response: IApiResponse = yield call(apiReq, {
    start_time: startTime,
    end_time: endTime,
    business_id: merchantProfile.id,
    page,
    per_page: perPage,
    collection_list_id: listId,
    filter:
      isOverallTab(invoiceCollectionFilter) && isDynamicStatementEnabled
        ? SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
        : invoiceCollectionFilter
  })

  if (response.type === API_RESPONSE_TYPE.FAILURE) {
    throw new Error('Something Went Wrong', {
      cause: 'customError'
    })
  }

  const api = summaryDataListApi(listId, isDynamicStatementEnabled)

  const res: IApiResponse = yield call(api, {
    start_time: startTime,
    end_time: endTime,
    business_id: merchantProfile.id,
    page,
    per_page: perPage,
    filter:
      isOverallTab(invoiceCollectionFilter) && isDynamicStatementEnabled
        ? SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
        : invoiceCollectionFilter,
    ...(isDynamicStatementEnabled
      ? {
          group_ids: [listId]
        }
      : {
          collection_list_ids: [listId]
        })
  })
  const formattedSummary = getFormattedCollectionGroupInvoiceV2Summary(response.data)
  const formattedData =
    listId === 'all-collections'
      ? getFormattedOverallInvoice(res.data)
      : getFormattedCollectionListInvoiceV2Summary(res.data, isDynamicStatementEnabled)

  const formattedShortSummaryData = getFormattedShortSummaryData(
    formattedData.rowData[0],
    formattedSummary.rowData.length
  )
  const formattedGroupViewDataData = getFormattedGroupViewDataData(formattedSummary.rowData)

  const ws = utils.json_to_sheet(formattedGroupViewDataData, { origin: 'A15' })
  const wb = utils.book_new()
  const excelHeading = `Collection Summary - (${dayjs
    .unix(startTime)
    .format('DD MMM, YYYY')}) to (${dayjs.unix(endTime).format('DD MMM, YYYY')})`

  utils.sheet_add_aoa(
    ws,
    [
      [excelHeading],
      [],
      [`Printed On: ${dayjs().format('DD MMM, YYYY')}`],
      Object.keys(formattedShortSummaryData),
      Object.values(formattedShortSummaryData)
    ],
    {
      origin: 'A4'
    }
  )

  const combinedData = [...formattedGroupViewDataData, formattedShortSummaryData]

  if (formattedGroupViewDataData.length) {
    ws['!cols'] = getMaxColumnWidths(combinedData)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}.xlsx`)
  yield put(
    setCollectionSummaryModalsAction({
      modalName: CollectionSummaryModalNameEnum.downloadSingleGroupModal,
      status: false
    })
  )
}
