import { Action } from 'infra/types'
import { replacementActions } from './replacement.actions'
import { IReplacementDrawerState } from './replacement.types'

const REPLACEMENT_DRAWER_STATE: IReplacementDrawerState = {
  drawers: {
    showReplacementDrawer: false
  }
}

export const ReplacementReducer = (
  state: IReplacementDrawerState = REPLACEMENT_DRAWER_STATE,
  action: Action<any>
): IReplacementDrawerState => {
  switch (action.type) {
    case replacementActions.SET_REPLACEMENT_DRAWER_DATA: {
      return {
        ...state,
        drawers: {
          showReplacementDrawer: action.payload.status
        },
        selectedReplacementData: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}
