import {
  COLLECTION_LIST_TYPE,
  IBill,
  IReplacements
} from 'state/collectionList/collectionList.types'
import { SettlementPayload } from 'state/new-summary/orders/orders.types'
import { IMerchantProfile } from 'state/dashboard/dashboard.types'
import {
  Action,
  ISetLinkIdAndMerchantIdAction,
  INewAutoDebitLinkToUsingAccountId,
  IMerchantFeatures,
  IUpdateCashCollectionAction,
  ITransactions,
  INewCashCollectionApi,
  IDeleteTransectionAction,
  ICancelInvoiceAction,
  IUndoCancelInvoiceAction,
  INewReplacementRecordAction,
  IUpdateReplacementRecordAction,
  IUpdateMultipleTxnAction,
  ICartItem,
  CreateOrderPayloadType,
  IBusinessAccount,
  OrderHistory,
  IStaffOrderItem,
  IHistoryObj,
  StaffProformaRowData,
  IStaffInventoryItems,
  IStaffBusinessDetails
} from './StaffLink.types'

export const StaffLinkActions = {
  SET_STAFF_LINK_DETAILS_STATE: 'staffLink/SET_STAFF_LINK_DETAILS_STATE',
  SET_LINKID_AND_MERCHANTID_STATE: 'staffLink/SET_LINKID_AND_MERCHANTID_STATE',
  SET_LOADING_STATE: 'staffLink/SET_LOADING_STATE',
  SET_PAGE_ERROR_STATE: 'staffLink/SET_PAGE_ERROR_STATE',
  HANDLE_FETCH_STAFFLINK_DATA: 'staffLink/HANDLE_FETCH_STAFFLINK_DATA',
  REQUEST_PAYMENT_STATUS: 'staffLink/REQUEST_PAYMENT_STATUS',
  SET_PAYMENT_STATUS: 'staffLink/SET_PAYMENT_STATUS',
  GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER: 'staffLink/GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER',
  SET_IS_PHONE_NUMBER_SEARCH: 'staffLink/SET_IS_PHONE_NUMBER_SEARCH',
  CREATE_AUTO_DEBIT_LINK: 'staffLink/CREATE_AUTO_DEBIT_LINK',
  SET_AUTO_DEBBIT_LINK_ACCOUNT_ID: 'staffLink/SET_AUTO_DEBBIT_LINK_ACCOUNT_ID',
  SET_MERCHANT_FEATURES: 'staffLink/SET_MERCHANT_FEATURES',
  SET_TRANSACTION_ERROR: 'staffLink/SET_TRANSACTION_ERROR',
  HANDLE_UPDATE_TRANSACTION: 'staffLink/HANDLE_UPDATE_TRANSACTION',
  HANDLE_UPDATE_MULTIPLE_TRANSACTION: 'staffLink/HANDLE_UPDATE_MULTIPLE_TRANSACTION',
  SET_TRANSACTION: 'staffLink/SET_TRANSACTION',
  SET_MULTILE_TRANSACTIONS: 'staffLink/SET_MULTILE_TRANSACTIONS',
  UPDATE_TRANSACTION: 'staffLink/UPDATE_TRANSACTION',
  UPDATE_MULTIPLE_TRANSACTION: 'staffLink/UPDATE_MULTIPLE_TRANSACTION',
  GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_SUCCESS:
    'staffLink/GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_SUCCESS',
  GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_ERROR:
    'staffLink/GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_ERROR',
  FETCH_COLLECTION_SUMMARY: 'staffLink/FETCH_COLLECTION_SUMMARY',
  SET_COLLECTION_SUMMARY: 'staffLink/SET_COLLECTION_SUMMARY',
  CREATE_NEW_TRANSACTION: 'staffLink/CREATE_NEW_TRANSACTION',
  CREATE_NEW_MULTIPLE_TRANSACTION: 'staffLink/CREATE_NEW_MULTIPLE_TRANSACTION',
  DELETE_TRANSACTION: 'staffLink/DELETE_TRANSACTION',
  HANDLE_DELETE_TRANSACTION: 'staffLink/HANDLE_DELETE_TRANSACTION',
  CANCEL_INVOICE: 'staffLink/CANCEL_INVOICE',
  HANDLE_CANCEL_INVOICE: 'staffLink/HANDLE_CANCEL_INVOICE',
  UNDO_CANCEL_INVOICE: 'staffLink/UNDO_CANCEL_INVOICE',
  HANDLE_UNDO_CANCEL_INVOICE: 'staffLink/HANDLE_UNDO_CANCEL_INVOICE',
  SAVE_SELECTED_BILL: 'staffLink/SAVE_SELECTED_BILL',
  REQUEST_BILL_PAYMENT_STATUS: 'staffLink/REQUEST_BILL_PAYMENT_STATUS',
  CREATE_REPLACEMENT_RECORD: 'staffLink/CREATE_REPLACEMENT_RECORD',
  UPDATE_REPLACEMENT_RECORD_FETCH: 'staffLink/UPDATE_REPLACEMENT_RECORD_FETCH',
  UPDATE_REPLACEMENT_RECORD: 'staffLink/UPDATE_REPLACEMENT_RECORD',
  SET_REPLACEMENT_RECORD: 'staffLink/SET_REPLACEMENT_RECORD',
  SET_STAFF_DETAILS: 'staffLink/SET_STAFF_DETAILS',
  UPDATE_STAFF_DETAILS: 'staffLink/UPDATE_STAFF_DETAILS',
  UPDATE_STAFF_DETAILS_SUCCESS: 'staffLink/UPDATE_STAFF_DETAILS_SUCCESS',
  UPDATE_STAFF_DETAILS_FAILURE: 'staffLink/UPDATE_STAFF_DETAILS_FAILURE',
  SET_STAFF_DETAILS_DRAWER: 'staffLink/SET_STAFF_DETAILS_DRAWER',
  SET_IS_STAFF_BLOCKED_TO_ACCESS_LIST: 'staffLink/SET_IS_STAFF_BLOCKED_TO_ACCESS_LIST',
  SETUP_STAFF_ORDERS: 'staffLink/SETUP_STAFF_ORDERS',
  FETCH_BUSINESS_ACCOUNTS: 'staffLink/FETCH_BUSINESS_ACCOUNTS',
  SET_BUSINESS_ACCOUNTS: 'staffLink/SET_BUSINESS_ACCOUNTS',
  FETCH_CATALOG_ITEMS: 'staffLink/FETCH_CATALOG_ITEMS',
  SET_CATALOG_ITEMS: 'staffLink/SET_CATALOG_ITEMS',
  SET_CART_ITEMS: 'staffLink/SET_CART_ITEMS',
  CREATE_NEW_ORDER: 'staffLink/CREATE_NEW_ORDER',
  GET_STAFF_ORDER_HISTORY: 'staffLink/GET_STAFF_ORDER_HISTORY',
  SAVE_ORDER_SELECTED_CUSTOMER: 'staffLink/SAVE_ORDER_SELECTED_CUSTOMER',
  SAVE_ORDER_SELECTED_ITEM: 'staffLink/SAVE_ORDER_SELECTED_ITEM',
  SAVE_ORDER_HISTORY_DETAILS: 'staffLink/SAVE_ORDER_HISTORY_DETAILS',
  UPDATE_STAFF_ORDER_ACTION: 'staffLink/UPDATE_STAFF_ORDER_ACTION',
  SAVE_PROFORMA_SELECTED_ITEM: 'staffLink/SAVE_PROFORMA_SELECTED_ITEM',
  STAFF_SETTLE_PROFORMA_ACTION: 'staffLink/STAFF_SETTLE_PROFORMA_ACTION',
  CREATE_NEW_INVOICE: 'staffLinkOrders/CREATE_NEW_INVOICE',
  FETCH_STAFF_BUSINESS_ACTION: 'staffLink/FETCH_STAFF_BUSINESS_ACTION',
  SAVE_STAFF_BUSINESS_DETAILS: 'staffLink/SAVE_STAFF_BUSINESS_DETAILS',
  STAFF_PLACED_ORDER_SUCCESS_ACTION: 'staffLink/STAFF_PLACED_ORDER_SUCCESS_ACTION'
}

export const StaffLinkAnalyticsActions = {
  TRACK_COLLECTIONS_LIST_VIEW_PAGE: 'staffLink/TRACK_COLLECTIONS_LIST_VIEW_PAGE',
  TRACK_COLLECTIONS_LIST_REFRESH: 'staffLink/TRACK_COLLECTIONS_LIST_REFRESH',
  TRACK_COLLECTIONS_LIST_SEARCH: 'staffLink/TRACK_COLLECTIONS_LIST_SEARCH',
  TRACK_COLLECTIONS_LIST_SEARCH_START: 'staffLink/TRACK_COLLECTIONS_LIST_SEARCH_START',
  TRACK_COLLECTIONS_LIST_CALL: 'staffLink/TRACK_COLLECTIONS_LIST_CALL',
  TRACK_COLLECTIONS_LIST_VIEW_QR: 'staffLink/TRACK_COLLECTIONS_LIST_VIEW_QR',
  TRACK_COLLECTIONS_LIST_SHARE_PAYMENT_LINK: 'staffLink/TRACK_COLLECTIONS_LIST_SHARE_PAYMENT_LINK',
  TRACK_COLLECTIONS_LIST_PAYMENT_NOTIFICATION_POPUP:
    'staffLink/TRACK_COLLECTIONS_LIST_PAYMENT_NOTIFICATION_POPUP',
  TRACK_COLLECTIONS_LIST_CUSTOMER_VIEW_MORE: 'staffLink/TRACK_COLLECTIONS_LIST_CUSTOMER_VIEW_MORE',
  TRACK_COLLECTIONS_LIST_COLLECTIONS_COMPLETED:
    'staffLink/TRACK_COLLECTIONS_LIST_COLLECTIONS_COMPLETED',
  TRACK_COLLECTIONS_LIST_CHECK_PAYMENT_STATUS:
    'staffLink/TRACK_COLLECTIONS_LIST_CHECK_PAYMENT_STATUS',
  TRACK_COLLECTIONS_LIST_VIEW_SOMETHING_WENT_WRONG_PAGE:
    'staffLink/TRACK_COLLECTIONS_LIST_VIEW_SOMETHING_WENT_WRONG_PAGE',
  TRACK_OPEN_MENU_CLICK: 'staffLink/TRACK_OPEN_MENU_CLICK',
  TRACK_MENU_ACTION_CLICK: 'staffLink/TRACK_MENU_ACTION_CLICK',
  TRACK_SHORTCUT_ACTION_CLICK: 'staffLink/TRACK_SHORTCUT_ACTION_CLICK'
}

export const setTransaction = (data: ITransactions): Action<ITransactions> => ({
  type: StaffLinkActions.SET_TRANSACTION,
  payload: data
})

export const setIsStaffBlockedToAccessList = (data: boolean): Action<boolean> => ({
  type: StaffLinkActions.SET_IS_STAFF_BLOCKED_TO_ACCESS_LIST,
  payload: data
})

export const setMultipleTransactions = (data: ITransactions[]): Action<ITransactions[]> => ({
  type: StaffLinkActions.SET_MULTILE_TRANSACTIONS,
  payload: data
})

export const updateTransaction = (data: ITransactions): Action<ITransactions> => ({
  type: StaffLinkActions.UPDATE_TRANSACTION,
  payload: data
})

export const updateMultipleTransactions = (data: ITransactions[]): Action<ITransactions[]> => ({
  type: StaffLinkActions.UPDATE_MULTIPLE_TRANSACTION,
  payload: data
})

export const deleteTransaction = (data: ITransactions): Action<ITransactions> => ({
  type: StaffLinkActions.DELETE_TRANSACTION,
  payload: data
})

export const handleFetchStafflinkData = (
  data: ISetLinkIdAndMerchantIdAction
): Action<ISetLinkIdAndMerchantIdAction> => ({
  type: StaffLinkActions.HANDLE_FETCH_STAFFLINK_DATA,
  payload: data
})

export const setLinkIdandMerchantId = (
  data: ISetLinkIdAndMerchantIdAction
): Action<ISetLinkIdAndMerchantIdAction> => ({
  type: StaffLinkActions.SET_LINKID_AND_MERCHANTID_STATE,
  payload: data
})

export const setStaffDetails = (data: any): Action<any> => ({
  type: StaffLinkActions.SET_STAFF_DETAILS,
  payload: data
})

export const updateStaffDetailsAction = (data: string): Action<string> => ({
  type: StaffLinkActions.UPDATE_STAFF_DETAILS,
  payload: data
})

export const updateStaffDetailsSuccessAction = (data: string): Action<string> => ({
  type: StaffLinkActions.UPDATE_STAFF_DETAILS_SUCCESS,
  payload: data
})

export const updateStaffDetailsFailureAction = () => ({
  type: StaffLinkActions.UPDATE_STAFF_DETAILS_FAILURE,
  payload: null
})

export const setStaffDetailsDrawerAction = (data: boolean): Action<boolean> => ({
  type: StaffLinkActions.SET_STAFF_DETAILS_DRAWER,
  payload: data
})

export const setMerchantFeatures = (data: IMerchantFeatures): Action<IMerchantFeatures> => ({
  type: StaffLinkActions.SET_MERCHANT_FEATURES,
  payload: data
})

export const setStaffLinkDetailsState = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.SET_STAFF_LINK_DETAILS_STATE,
  payload: data
})

export const setLoadingState = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.SET_LOADING_STATE,
  payload: data
})

export const setPageErrorState = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.SET_PAGE_ERROR_STATE,
  payload: data
})

export const requestPaymentStatus = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.REQUEST_PAYMENT_STATUS,
  payload: data
})

export const setPaymentStatus = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.SET_PAYMENT_STATUS,
  payload: data
})

export const setIsPhoneNumberSearch = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.SET_IS_PHONE_NUMBER_SEARCH,
  payload: data
})

export const getMerchatCustomerUsingPhoneNo = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER,
  payload: data
})

export const getMerchatCustomerUsingPhoneNoSuccess = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_SUCCESS,
  payload: data
})

export const getMerchatCustomerUsingPhoneNoError = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.GET_MERCHANT_CUTOMER_USING_PHONE_NUMBER_ERROR,
  payload: data
})

export const handleUpdateTransaction = (
  data: IUpdateCashCollectionAction,
  history: { push: Function }
): Action<IUpdateCashCollectionAction> => ({
  type: StaffLinkActions.HANDLE_UPDATE_TRANSACTION,
  payload: data,
  history
})

export const handleUpdateMultipleTransaction = (
  data: IUpdateMultipleTxnAction,
  history: { push: Function }
): Action<IUpdateMultipleTxnAction> => ({
  type: StaffLinkActions.HANDLE_UPDATE_MULTIPLE_TRANSACTION,
  payload: data,
  history
})

export const handleDeleteTransaction = (
  data: IDeleteTransectionAction,
  history: { push: Function }
): Action<IDeleteTransectionAction> => ({
  type: StaffLinkActions.HANDLE_DELETE_TRANSACTION,
  payload: data,
  history
})

export const cancelInvoice = (data: any): Action<any> => ({
  type: StaffLinkActions.CANCEL_INVOICE,
  payload: data
})

export const handleCancelInvoice = (
  data: ICancelInvoiceAction,
  history: { push: Function }
): Action<ICancelInvoiceAction> => ({
  type: StaffLinkActions.HANDLE_CANCEL_INVOICE,
  payload: data,
  history
})

export const undoCancelInvoice = (data: any): Action<any> => ({
  type: StaffLinkActions.UNDO_CANCEL_INVOICE,
  payload: data
})

export const handleUndoCancelInvoice = (
  data: IUndoCancelInvoiceAction,
  history: { push: Function }
): Action<IUndoCancelInvoiceAction> => ({
  type: StaffLinkActions.HANDLE_UNDO_CANCEL_INVOICE,
  payload: data,
  history
})

export const handleCreateTransaction = (
  data: INewCashCollectionApi,
  history: { push: Function }
): Action<INewCashCollectionApi> => ({
  type: StaffLinkActions.CREATE_NEW_TRANSACTION,
  payload: data,
  history
})

export const handleCreateMultipleTransaction = (
  data: INewCashCollectionApi[],
  history: { push: Function }
): Action<INewCashCollectionApi[]> => ({
  type: StaffLinkActions.CREATE_NEW_MULTIPLE_TRANSACTION,
  payload: data,
  history
})

export const handleCreateReplacementRecord = (
  data: INewReplacementRecordAction,
  history: { push: Function }
): Action<INewReplacementRecordAction> => ({
  type: StaffLinkActions.CREATE_REPLACEMENT_RECORD,
  payload: data,
  history
})

export const handleUpdateReplacementRecord = (
  data: IUpdateReplacementRecordAction,
  history: { push: Function }
): Action<IUpdateReplacementRecordAction> => ({
  type: StaffLinkActions.UPDATE_REPLACEMENT_RECORD_FETCH,
  payload: data,
  history
})

export const setReplacementRecord = (data: IReplacements): Action<IReplacements> => ({
  type: StaffLinkActions.SET_REPLACEMENT_RECORD,
  payload: data
})

export const updateReplacementRecord = (data: IReplacements): Action<IReplacements> => ({
  type: StaffLinkActions.UPDATE_REPLACEMENT_RECORD,
  payload: data
})

export const handleCreateAutoDebitLink = (data: string): Action<string> => ({
  type: StaffLinkActions.CREATE_AUTO_DEBIT_LINK,
  payload: data
})

export const setTransactionError = (data: string): Action<string> => ({
  type: StaffLinkActions.SET_TRANSACTION_ERROR,
  payload: data
})

export const setNewAutoDebitLinkToUsingAccountId = (
  data: INewAutoDebitLinkToUsingAccountId
): Action<INewAutoDebitLinkToUsingAccountId> => ({
  type: StaffLinkActions.SET_AUTO_DEBBIT_LINK_ACCOUNT_ID,
  payload: data
})

export const handleCollectionSummary = (history: {
  push: Function
  goBack: Function
}): Action<{}> => ({
  type: StaffLinkActions.FETCH_COLLECTION_SUMMARY,
  payload: {},
  history
})

export const handleCollectionSummarySuccess = (payload: any): Action<Object> => ({
  type: StaffLinkActions.SET_COLLECTION_SUMMARY,
  payload
})

export const requestBillPaymentStatus = <T>(data: T): Action<T> => ({
  type: StaffLinkActions.REQUEST_BILL_PAYMENT_STATUS,
  payload: data
})

export const setupStaffOrdersAction = () => ({
  type: StaffLinkActions.SETUP_STAFF_ORDERS,
  payload: null
})

export const fetchBusinessAccountsAction = () => ({
  type: StaffLinkActions.FETCH_BUSINESS_ACCOUNTS,
  payload: null
})

export const setBusinessAccountsAction = (data: any): Action<any> => ({
  type: StaffLinkActions.SET_BUSINESS_ACCOUNTS,
  payload: data
})

export const fetchCatalogItemsAction = (customerId: string) => ({
  type: StaffLinkActions.FETCH_CATALOG_ITEMS,
  payload: customerId
})

export const setCatalogItemsAction = (
  data: IStaffInventoryItems[]
): Action<IStaffInventoryItems[]> => ({
  type: StaffLinkActions.SET_CATALOG_ITEMS,
  payload: data
})

export const setCartItemsAction = (data: ICartItem[]): Action<ICartItem[]> => ({
  type: StaffLinkActions.SET_CART_ITEMS,
  payload: data
})

export const createNewOrderAction = (
  payload: Pick<CreateOrderPayloadType, 'customerId' | 'amount' | 'items'>,
  history: { replace: Function }
) => ({
  type: StaffLinkActions.CREATE_NEW_ORDER,
  payload: payload,
  history
})

export const getStaffOrderHistoryAction = (
  payload: Pick<CreateOrderPayloadType, 'customerId'>
): Action<Pick<CreateOrderPayloadType, 'customerId'>> => ({
  type: StaffLinkActions.GET_STAFF_ORDER_HISTORY,
  payload
})

export const saveOrdersSelectedCustomer = (
  payload: IBusinessAccount
): Action<IBusinessAccount> => ({
  type: StaffLinkActions.SAVE_ORDER_SELECTED_CUSTOMER,
  payload
})

export const saveSelectedOrderItem = (
  payload: IStaffOrderItem | undefined
): Action<IStaffOrderItem | undefined> => ({
  type: StaffLinkActions.SAVE_ORDER_SELECTED_ITEM,
  payload
})

export const saveSelectedProformaItem = (
  payload: StaffProformaRowData | undefined
): Action<StaffProformaRowData | undefined> => ({
  type: StaffLinkActions.SAVE_PROFORMA_SELECTED_ITEM,
  payload
})

export const saveOrderHistoryDetails = (payload: OrderHistory): Action<OrderHistory> => ({
  type: StaffLinkActions.SAVE_ORDER_HISTORY_DETAILS,
  payload
})

export const updateStaffOrderAction = (
  payload: IStaffOrderItem,
  history: IHistoryObj
): Action<IStaffOrderItem> => ({
  type: StaffLinkActions.UPDATE_STAFF_ORDER_ACTION,
  payload,
  history
})

/*
 * Mixpanel event actions start
 */

export const trackCollectionsListViewPage = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_VIEW_PAGE,
  payload: data
})

export const trackCollectionsListRefresh = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_REFRESH,
  payload: data
})

export const trackCollectionsListSearch = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_SEARCH,
  payload: data
})

export const trackCollectionsListSearchStart = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_SEARCH_START,
  payload: data
})

export const trackCollectionsListCall = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_CALL,
  payload: data
})

export const trackCollectionsListViewQr = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_VIEW_QR,
  payload: data
})

export const trackCollectionsListSharePaymentLink = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_SHARE_PAYMENT_LINK,
  payload: data
})

export const trackCollectionsListPaymentNotificationPopup = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_PAYMENT_NOTIFICATION_POPUP,
  payload: data
})

export const trackCollectionsListCustomerViewMore = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_CUSTOMER_VIEW_MORE,
  payload: data
})

export const trackCollectionsListCollectionsCompleted = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_COLLECTIONS_COMPLETED,
  payload: data
})

export const trackCollectionsListCheckPaymentStatus = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_CHECK_PAYMENT_STATUS,
  payload: data
})

export const trackCollectionsListViewSomethingWentWrongPage = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_COLLECTIONS_LIST_VIEW_SOMETHING_WENT_WRONG_PAGE,
  payload: data
})

export const trackOpenMenuClick = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_OPEN_MENU_CLICK,
  payload: data
})

export const trackMenuActionClick = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_MENU_ACTION_CLICK,
  payload: data
})

export const trackShortcutActionClick = <T>(data: T): Action<T> => ({
  type: StaffLinkAnalyticsActions.TRACK_SHORTCUT_ACTION_CLICK,
  payload: data
})

export const saveSelectedBill = (
  bill: IBill | undefined,
  dueConfig: COLLECTION_LIST_TYPE | undefined
): Action<{ bill: IBill | undefined; dueConfig: COLLECTION_LIST_TYPE | undefined }> => ({
  type: StaffLinkActions.SAVE_SELECTED_BILL,
  payload: {
    bill,
    dueConfig
  }
})
export const createNewInvoiceAction = (
  payload: Pick<CreateOrderPayloadType, 'customerId' | 'amount' | 'items'>,
  history: { replace: Function }
) => ({
  type: StaffLinkActions.CREATE_NEW_INVOICE,
  payload: payload,
  history
})

export const fetchStaffBusinessUserAction = (payload: string): Action<string> => ({
  type: StaffLinkActions.FETCH_STAFF_BUSINESS_ACTION,
  payload: payload
})

export const saveBusinessDetailsAction = (
  payload: IStaffBusinessDetails
): Action<IStaffBusinessDetails> => ({
  type: StaffLinkActions.SAVE_STAFF_BUSINESS_DETAILS,
  payload
})

export const staffSettleProformaAction = (
  payload: Omit<SettlementPayload, 'settlementType'> & { currentTransactionType: number },
  history: IHistoryObj
): Action<Omit<SettlementPayload, 'settlementType'> & { currentTransactionType: number }> => ({
  type: StaffLinkActions.STAFF_SETTLE_PROFORMA_ACTION,
  payload,
  history
})

export const staffPlacedOrderSuccessAction = (payload: boolean): Action<boolean> => ({
  type: StaffLinkActions.STAFF_PLACED_ORDER_SUCCESS_ACTION,
  payload
})

/*
 * Mixpanel event actions end
 */
