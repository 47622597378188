import {
  IOnboardFormData,
  IOnboardingListRequestParams,
  IRevisitFormData
} from 'state/agent-dashboard/agent-dashboard.types'
import { getProxyBaseUrl } from 'utils/getBaseUrl'
import ApiService from './ApiService'

//======================================================== to be removed when there is common authv3 functions

/*
 * Returns whether AuthV3 is enabled from androidObj
 */
export const getIsAuthV3 = () => {
  // @ts-ignore
  if (typeof window !== 'undefined' && window.androidObj && window.androidObj.isAuthV3) {
    // @ts-ignore
    return window.androidObj.isAuthV3()
  }
}

/*
 * Returns business id from androidObj
 */
export const getBusinessId = () => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    if (window.androidObj && window.androidObj.getMerchantId) {
      // @ts-ignore
      return window.androidObj.getMerchantId()
    }
  }
  // const query = new URLSearchParams(window?.location?.search) // todo: remove after dev
  // const businessId = query.get('businessId') // todo: remove after dev
  // return businessId // todo: remove after dev
}

/*
 * Returns auth token from androidObj
 */
export const getAuthToken = () => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    if (window.androidObj && window.androidObj.getAuthToken) {
      // @ts-ignore
      return window.androidObj.getAuthToken()
    }
  }
  // const query = new URLSearchParams(window?.location?.search) // todo: remove after dev
  // const token = query.get('token') // todo: remove after dev
  // return token // todo: remove after dev
}

/*
 * Returns headers for axios
 */
export const getHeaders = () => {
  // @ts-ignore
  const baseHeaders = {
    'content-type': 'application/json',
    accept: 'application/json, text/plain, */*'
  }
  if (getIsAuthV3()) {
    return {
      ...baseHeaders,
      'OKC-BUSINESS-ID': getBusinessId()
    }
  }
  return {
    ...baseHeaders,
    Authorization: `Bearer ${getAuthToken()}`
  }
}

//======================================================== to be removed when there is common authv3 functions

const AGENT_DAHSBOARD_META_DATA = '/onboardings/metadata'
const AGENT_DAHSBOARD_ONBOARDING = '/onboarding'
const AGENT_DAHSBOARD_CHECKLIST = '/checklist'
const AGENT_DAHSBOARD_ONBOARDED_LIST = '/onboardings'
// const AGENT_DAHSBOARD_REVISIT_LIST = '/revisits'
const AGENT_DAHSBOARD_REVISIT = '/revisit'
const AGENT_DASHBOARD_DAILY_SUMMARY = '/summary'

const endpoint = getProxyBaseUrl('AGENT_DASHBOARD_ENDPOINT') + '/api/offline-marketing' || ''

const getAgentDashboardMetaData = () => {
  const api = new ApiService(endpoint, getHeaders())
  // api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
  // api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)
  return api.getRequest(AGENT_DAHSBOARD_META_DATA, {})
}

const postOnboardMerchantData = (payload: IOnboardFormData) => {
  const api = new ApiService(endpoint, getHeaders())
  // api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
  // api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)
  return api.postRequest(AGENT_DAHSBOARD_ONBOARDING, payload)
}

const getCheckListData = (type: string) => {
  const api = new ApiService(endpoint, getHeaders())
  // api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
  // api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)
  return api.getRequest(AGENT_DAHSBOARD_CHECKLIST, { type })
}

const getOnboardedMerchantsList = (payload: IOnboardingListRequestParams) => {
  const api = new ApiService(endpoint, getHeaders())
  // api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
  // api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)
  return api.getRequest(AGENT_DAHSBOARD_ONBOARDED_LIST, payload)
}

const getDailySummaryData = () => {
  const api = new ApiService(endpoint, getHeaders())
  // api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
  // api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)
  return api.getRequest(AGENT_DASHBOARD_DAILY_SUMMARY, {})
}

const postRevisitFormData = (payload: IRevisitFormData) => {
  const api = new ApiService(endpoint, getHeaders())
  // api.requestInterceptor(apiRequestInterceptor, (error: any) => console.error(error))
  // api.responseInterceptor(apiResponseSuccessInterceptor, apiResponseErrorInterceptor)
  return api.postRequest(AGENT_DAHSBOARD_REVISIT, payload)
}

export const AgentDashboardApi = {
  getAgentDashboardMetaData,
  postOnboardMerchantData,
  getCheckListData,
  getOnboardedMerchantsList,
  getDailySummaryData,
  postRevisitFormData
}
