import { ILanguageEntity, LANGUAGES } from 'shared/constants'

export const getLanguage = (id: number): ILanguageEntity => {
  const lang = LANGUAGES.find((language: ILanguageEntity) => language.id === id)
  return lang ? lang : LANGUAGES[0]
}

export const getLanguageFromKey = (key: string): ILanguageEntity => {
  const lang = LANGUAGES.find((language: ILanguageEntity) => language.key === key)
  return lang ? lang : LANGUAGES[0]
}
