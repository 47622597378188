import { Action } from 'infra/types'
import {
  Cheque,
  ChequeFilterTime,
  ChequeType,
  IFetchReceivedActionPayload,
  IFetchOtherActionPayload,
  UpdateChequeDetailsType,
  PaginationType
} from './cheque.types'

export const chequeActions = {
  fetchReceivedChequesAction: 'cheque/fetchReceivedCheques',
  fetchOtherChequesAction: 'cheque/fetchOtherChequesAction',
  setReceivedChequesAction: 'cheque/seReceivedCheques',
  setOtherChequesAction: 'cheque/setOtherCheques',
  setFilterTime: 'cheque/setFilterTime',
  updateChequeDetails: 'cheque/updateChequeDetails',
  editChequeDetails: 'cheque/editChequeDetails',
  setChequesAction: 'cheque/setCheques',
  setReceivedSortedBy: 'cheque/setReceivedSortedBy',
  setDepositedSortedBy: 'cheque/setDepositedSortBy',
  setClearedSortedBy: 'cheque/setClearedSortedBy',
  setByChequeDateFilter: 'cheque/setByChequeDateFilter',
  setEditChequeData: 'cheque/setEditChequeData',
  resetEditChequeState: 'cheque/resetEditChequeState'
}

export const setEditChequeDrawerWithData = (payload: {
  chequeDetail: Cheque
}): Action<{
  chequeDetail: Cheque
}> => {
  return {
    type: chequeActions.setEditChequeData,
    payload
  }
}

export const resetEditChequeDrawer = (): Action<null> => ({
  type: chequeActions.resetEditChequeState,
  payload: null
})

export const setByChequeDateFilterAction = (payload: {
  byChequeDateFilterType:
    | 'receivedByChequeDateFilter'
    | 'depositedByChequeDateFilter'
    | 'clearedByChequeDateFilter'
  filterValue: ChequeFilterTime | undefined
}) => {
  return {
    type: chequeActions.setByChequeDateFilter,
    payload
  }
}

export const fetchReceivedCheques = (payload: IFetchReceivedActionPayload) => {
  return {
    type: chequeActions.fetchReceivedChequesAction,
    payload
  }
}

export const fetchOtherCheques = (payload: IFetchOtherActionPayload) => {
  return {
    type: chequeActions.fetchOtherChequesAction,
    payload
  }
}

export const setReceivedCheques = (payload: { cheques: Cheque[]; pagination: PaginationType }) => {
  return {
    type: chequeActions.setReceivedChequesAction,
    payload
  }
}

export const setReceivedSortedBy = (payload: { sortBy: string }) => {
  return {
    type: chequeActions.setReceivedSortedBy,
    payload
  }
}

export const setDepositedSortedBy = (payload: { sortBy: string }) => {
  return {
    type: chequeActions.setDepositedSortedBy,
    payload
  }
}

export const setClearedSortedBy = (payload: { sortBy: string }) => {
  return {
    type: chequeActions.setClearedSortedBy,
    payload
  }
}

export const setOtherCheques = (payload: {
  cheques: Cheque[]
  chequeType: ChequeType
  pagination: PaginationType
}) => {
  return {
    type: chequeActions.setOtherChequesAction,
    payload
  }
}

export const setCheques = (payload: { data: Cheque[]; chequeType: ChequeType }) => {
  return {
    type: chequeActions.setChequesAction,
    payload
  }
}

export const setFilterTime = (payload: ChequeFilterTime) => {
  return {
    type: chequeActions.setFilterTime,
    payload
  }
}

export const updateChequeDetails = (payload: {
  data: UpdateChequeDetailsType[]
  type: 'receivedCheque' | 'depositedCheque' | 'clearedCheque'
}) => {
  return {
    type: chequeActions.updateChequeDetails,
    payload
  }
}

export const editChequeDetails = (payload: {
  data: UpdateChequeDetailsType
  type: 'receivedCheque' | 'depositedCheque' | 'clearedCheque'
}) => {
  return {
    type: chequeActions.editChequeDetails,
    payload
  }
}
