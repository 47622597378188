import React from 'react'
import { IconLogo } from 'assets/icons'
import BusinessSelector from 'pages/NewDashboard/components/BusinessSelector'
import BellNotification from 'pages/NewDashboard/components/Header/BellNotification'
import { useAbExperiment } from 'utils/useAbExperiment'
import UploadSupplySheet from '../CommonDrawers/UploadSheetDrawers/UploadSupplySheet'
import { TopHeaderStyles } from './styles'

const TopHeader = () => {
  const classes = TopHeaderStyles()
  const { downtime } = useAbExperiment()

  return (
    <div className={classes.topHeaderContainer}>
      <div className={classes.innerContainer}>
        <div className={classes.logoBusinessContainer}>
          <img className={classes.logoStyles} src={IconLogo} alt="OkCredit Logo" />
          <BusinessSelector />
          {downtime && <BellNotification />}
        </div>
        <UploadSupplySheet />
      </div>
    </div>
  )
}

export default TopHeader
