import { Action } from 'infra/types'
import { IAgentDashboardState } from './agent-dashboard.types'
import { AgentDashboardActions } from './agent-dashboard.actions'
import { AgentDashboardState } from './agent-dashboard.state'

export const AgentDashboardReducer = (
  state: IAgentDashboardState = AgentDashboardState,
  action: Action<any>
): IAgentDashboardState => {
  switch (action.type) {
    case AgentDashboardActions.GET_AGENT_DASHBOARD_META: {
      return {
        ...state,
        metaData: {
          loading: true,
          data: state.metaData.data
        }
      }
    }
    case AgentDashboardActions.GET_AGENT_DASHBOARD_META_SUCCESS: {
      return {
        ...state,
        metaData: {
          loading: false,
          data: action.payload
        }
      }
    }

    case AgentDashboardActions.POST_ONBOARDING_FORM_LOADING: {
      return {
        ...state,
        onboardFormLoading: {
          loading: action.payload,
          id: ''
        }
      }
    }

    case AgentDashboardActions.POST_ONBOARDING_DATA_SUCCESS: {
      return {
        ...state,
        onboardFormLoading: {
          loading: false,
          id: action.payload.id
        }
      }
    }

    case AgentDashboardActions.GET_CHECKLIST_DATA: {
      return {
        ...state,
        checkBoxList: {
          ...state.checkBoxList,
          loading: true
        }
      }
    }

    case AgentDashboardActions.GET_CHECKLIST_DATA_SUCCESS: {
      return {
        ...state,
        checkBoxList: {
          loading: false,
          data: {
            ...state.checkBoxList.data,
            ...action.payload
          }
        }
      }
    }

    case AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST: {
      return {
        ...state,
        onboardedMerchants: {
          loading: true,
          paginateLoader: false,
          data: {
            ...state.onboardedMerchants.data
          }
        }
      }
    }

    case AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST_PAGES: {
      return {
        ...state,
        onboardedMerchants: {
          paginateLoader: true,
          loading: false,
          data: {
            ...state.onboardedMerchants.data
          }
        }
      }
    }

    case AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST_SUCCESS: {
      const newMerchantData = { ...state.onboardedMerchants }
      let newMerchantList = []
      if (action.payload.page === 1) {
        newMerchantList = action.payload.onboardings
      } else {
        newMerchantList = newMerchantData.data.onboardings.concat(action.payload.onboardings)
      }

      return {
        ...state,
        onboardedMerchants: {
          loading: false,
          paginateLoader: false,
          data: {
            onboardings: newMerchantList,
            totalCount: action.payload.totalCount
          }
        }
      }
    }

    case AgentDashboardActions.CLEAR_ONBOARDED_MERCHANTS_LIST: {
      return {
        ...state,
        onboardedMerchants: {
          ...state.onboardedMerchants,
          data: {
            onboardings: [],
            totalCount: 0
          }
        }
      }
    }

    case AgentDashboardActions.GET_DAILY_SUMMARY_DATA: {
      return {
        ...state,
        summary: {
          loading: true
        }
      }
    }

    case AgentDashboardActions.GET_DAILY_SUMMARY_DATA_SUCCESS: {
      return {
        ...state,
        summary: {
          loading: false,
          data: action.payload
        }
      }
    }

    case AgentDashboardActions.POST_REVISIT_FORM_DATA: {
      return {
        ...state,
        revisitForm: {
          loading: true
        }
      }
    }

    case AgentDashboardActions.POST_REVISIT_FORM_DATA_SUCCESS: {
      return {
        ...state,
        revisitForm: {
          loading: false,
          id: action.payload
        }
      }
    }

    case AgentDashboardActions.SET_REVISIT_FILTER: {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          revisitsOnly: action.payload
        }
      }
    }

    case AgentDashboardActions.SET_STATUS_FILTER: {
      return {
        ...state,
        listFilter: {
          ...state.listFilter,
          statusFilter: action.payload
        }
      }
    }

    case AgentDashboardActions.CLEAR_ONBOARDING_FORM_LOADING: {
      return {
        ...state,
        onboardFormLoading: {
          loading: false
        }
      }
    }

    case AgentDashboardActions.CLEAR_REVISIT_FORM_LOADING: {
      return {
        ...state,
        revisitForm: {
          loading: false
        }
      }
    }
    default:
      return state
  }
}
