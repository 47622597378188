import { IAccountSummary, ICustomer } from '../dashboard.types'

/**
 * Calculates the account summary of the merchant by
 * reducing customer data.
 *
 * @param customers - Array of ICustomer
 * @returns IAccountSummary
 */
export const getAccountSummary = (customers: ICustomer[]): IAccountSummary => {
  const data = customers.reduce(
    (acc: IAccountSummary, curr: ICustomer) => {
      return {
        ...acc,
        balance: curr.blockedByCustomer ? curr.balance : acc.balance + curr.balance,
        customerCount: curr.blockedByCustomer ? acc.customerCount : acc.customerCount + 1
      }
    },
    {
      balance: 0,
      customerCount: 0
    }
  )
  return data
}

export const BULK_UPLOAD_ACCOUNTS_STATUS_POLL_INTERVAL = 1000
export const BULK_UPLOAD_ACCOUNTS_STATUS_POLL_COUNT = 15
