import Storage from './Storage'

enum AuthData {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  IS_FIRST_TIME_USER = 'new_user',
  PHONE_NUMBER = 'phone_number',
  EXPIRES_IN = 'expires_in',
  IS_LOGGED_IN = 'is_logged_in',
  BUSINESS_NAME_OPT_OUT = 'business_name_opt_out',
  IS_RETURNING_MOBILE_USER = 'is_returning_user',
  CURRENT_BUSINESS_ID = 'current_business_id',
  IS_ADMIN = 'is_admin',
  LAST_BANNER_SHOWN_DATE = 'lastBannerShownDate',
  IS_BANNER_SEEN = 'isBannerSeen'
}

class Tokens extends Storage<AuthData> {
  private static instance?: Tokens

  private constructor() {
    super()
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Tokens()
    }
    return this.instance
  }

  public getAccessToken() {
    return this.get(AuthData.ACCESS_TOKEN)
  }

  public setAccessToken(accessToken: string) {
    this.set(AuthData.ACCESS_TOKEN, accessToken)
  }

  public getRefreshToken() {
    return this.get(AuthData.REFRESH_TOKEN)
  }

  public setRefreshToken(refreshToken: string) {
    this.set(AuthData.REFRESH_TOKEN, refreshToken)
  }

  public getFirstTimeUser(): boolean {
    return this.get(AuthData.IS_FIRST_TIME_USER) === 'true'
  }

  public setFirstTimeUser(isFirstTimeUser: boolean) {
    this.set(AuthData.IS_FIRST_TIME_USER, isFirstTimeUser.toString())
  }

  public getPhoneNumber() {
    return this.get(AuthData.PHONE_NUMBER)
  }

  public setPhoneNumber(phoneNumber: number) {
    this.set(AuthData.PHONE_NUMBER, phoneNumber.toString())
  }

  public getExpiresIn() {
    const expiresIn = this.get(AuthData.EXPIRES_IN)
    if (expiresIn) {
      return parseInt(expiresIn)
    }
    return null
  }

  public setExpiresIn(expiresIn: number) {
    this.set(AuthData.EXPIRES_IN, expiresIn.toString())
  }

  public getIsLoggedIn() {
    const isLoggedIn = this.get(AuthData.IS_LOGGED_IN)
    if (isLoggedIn) {
      return isLoggedIn === 'true'
    }
    return false
  }

  public setIsLoggedIn(isLoggedIn: boolean) {
    this.set(AuthData.IS_LOGGED_IN, isLoggedIn.toString())
  }

  public getOptoutBusinessName(): boolean {
    const businessNameOptOut = this.get(AuthData.BUSINESS_NAME_OPT_OUT)
    if (businessNameOptOut) {
      return businessNameOptOut === 'true'
    }
    return false
  }
  public setOptoutBusinessName(optOutBusiness: boolean) {
    this.set(AuthData.BUSINESS_NAME_OPT_OUT, optOutBusiness.toString())
  }

  public setReturningUser(value: boolean) {
    this.set(AuthData.IS_RETURNING_MOBILE_USER, value.toString())
  }

  public getReturningUser(): boolean {
    const value = this.get(AuthData.IS_RETURNING_MOBILE_USER)
    if (value) {
      return value === 'true'
    }
    return false
  }

  public getCurrentBusinessId() {
    return this.get(AuthData.CURRENT_BUSINESS_ID)
  }

  public setCurrentBusinessId(businessId: string) {
    this.set(AuthData.CURRENT_BUSINESS_ID, businessId)
  }

  public getIsAdmin() {
    const isAdmin = this.get(AuthData.IS_ADMIN)
    if (isAdmin) {
      return isAdmin === 'true'
    }
    return false
  }

  public setIsAdmin(isAdmin: boolean) {
    this.set(AuthData.IS_ADMIN, isAdmin.toString())
  }

  public setPaidSoonSeenDate(date: string) {
    this.set(AuthData.LAST_BANNER_SHOWN_DATE, date)
  }

  public getPaidSoonSeenDate() {
    this.get(AuthData.LAST_BANNER_SHOWN_DATE)
  }

  public clearPaidSoonSeenDate() {
    this.clearItems([AuthData.LAST_BANNER_SHOWN_DATE])
  }

  public setIsBannerSeen(action: Boolean) {
    this.set(AuthData.IS_BANNER_SEEN, action.toString())
  }

  public getIsBannerSeen() {
    this.get(AuthData.IS_BANNER_SEEN)
  }

  public clearIsBannerSeen() {
    this.clearItems([AuthData.IS_BANNER_SEEN])
  }

  public clear() {
    this.clearItems([
      AuthData.ACCESS_TOKEN,
      AuthData.REFRESH_TOKEN,
      AuthData.EXPIRES_IN,
      AuthData.IS_LOGGED_IN,
      AuthData.IS_FIRST_TIME_USER,
      AuthData.IS_RETURNING_MOBILE_USER,
      AuthData.IS_ADMIN,
      AuthData.IS_BANNER_SEEN,
      AuthData.LAST_BANNER_SHOWN_DATE
    ])
  }
}

export const AuthStorageInstance = Tokens.getInstance()
