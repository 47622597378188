/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put, all, delay, select } from '@redux-saga/core/effects'
import { utils, writeFileXLSX } from 'xlsx'
import dayjs from 'dayjs'
import { Action } from 'infra/types'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import {
  createGroup,
  getOwnerSummary,
  updateStaffLink,
  updateStaffLinkName,
  removeCollectionList,
  approveBulkCollectionStaffLinkV2,
  approveTxnCollectionStaffLink,
  getCollectionListSummaryApi,
  getSingleCollectionListSummaryApi,
  getUnapprovedTransactionsApi,
  getAccountAllocationDetailsApi,
  getFinalBalanceDownloadDataApi,
  getFinalBalanceDownloadAccountDataApi,
  getInvoiceLevelDownloadDataApi,
  getInvoiceLevelGroupSummaryDataApi,
  getReplacementListApi,
  updatePendingReplacementRecord,
  getCollectionListInvoiceSummaryApi,
  getCollectionListInvoiceSummaryOverallStatsApi,
  getSingleCollectionListInvoiceSummaryApi,
  getCollectionListSummaryOverallStatsApi,
  createGroupByTagsApi,
  removeGroupApi,
  updateGroupNameApi,
  updateGroupFiltersApi,
  getStatementGroupsApi,
  getStatementGroupAccountsApi,
  getV2InvoiceLevelDownloadDataApi,
  getV2InvoiceLevelDownloadDataAllBusinessApi,
  getReplacementDowloadApi,
  getAllBusinessOnlineCollections
} from 'services/Api/StafflinkApi'
import { IDateFilter, ISummaryType } from 'pages/Statements/Statements.constants'
import { IMerchantProfile } from 'state/dashboard/dashboard.types'
import { IAppState } from 'infra/AppState'
import { fetchEntityList } from 'state/dashboard/dashboard.actions'
import { IExpState } from 'state/experiment/experiment.types'
import { FILTER_OPTION, IDispatchType } from 'services/Api/types'
import {
  getCollectionGroupInvoiceSummaryV2Action,
  getCollectionListInvoiceSummaryV2Action,
  setDrawerAction as setCollectionDrawerAction
} from 'state/collectionSummary/collectionSummary.actions'
import { SUMMARY_FILTER_OPTION } from 'pages/CollectionSummary/CollectionSummary.constants'
import { fetchPendingDueGroupSummaryAction } from 'state/pendingDueSummary/pendingDueSummary.actions'
import { BUSINESS_TYPE } from 'pages/Statements/components/DownloadDialog/DownloadDialog.constants'
import {
  fetchGroupDataAction,
  fetchStatementGroupAction,
  setSpecificStatementGroup
} from 'state/new-summary/supply/supply.actions'
import { forwardTo } from 'state/common'
import { SOURCE } from 'state/new-summary/supply/supply.constants'
import { ICollectionSummaryState } from 'state/collectionSummary/collectionSummary.types'
import { COLLECTION_FILTER_TYPE } from 'pages/NewDashboard/components/Summary/components/CollectionContainer/CollectionHeader/constants'
import {
  getCollectionListAccountSummarySuccess,
  getCollectionListSummarySuccessAction,
  getOwnerSummarySuccessAction,
  getUnapprovedTransactionsSuccess,
  setCurrentViewAction,
  setDrawerAction,
  setLoaderAction,
  updateOwnerSummaryAction,
  handleTxnAcceptOrRemove,
  getAccountAllocationDetailsSuccess,
  getCollectionListAccountSummary,
  setDownloadStatus,
  getPendingReplacementListSuccess,
  updatePendingReplacementSuccess,
  getCollectionListInvoiceSummarySuccessAction,
  getCollectionListAccountInvoiceSummarySuccessAction,
  getCollectionListInvoiceSummaryAction,
  getCollectionListAccountInvoiceSummaryAction,
  getCollectionListSummary,
  setCurrentCollectionListIdAction,
  setV2SummaryDownloadDrawerStatus,
  setReplacementSummaryDownloadStatus
} from './statements.actions'
import { API_RESPONSE_TYPE, IApiResponse, ENTITY_TYPE } from './../../constants'
import {
  getFormattedOwnerSummary,
  getBulkCollectionPayload,
  getFormattedCollectionListSummary,
  getSingleFormattedCollectionListSummary,
  getExcelHeading,
  formatForBalanceExcelExport,
  getMaxColumnWidths,
  getFormattedInvoiceLevelDownloadData,
  formatForInvoiceExcelExport,
  getFormattedCollectionListInvoiceSummary,
  getSingleFormattedCollectionListInvoiceSummary,
  INVOICE_RESULTS_PER_PAGE,
  formatCollectionListSummaryOverallStats,
  getFormattedAccountAllocationDetails,
  formatData,
  formatReplacementSummary,
  formatOnlineCollectionData
} from './statements.helpers'
import {
  IStatementsState,
  IUpdateReplacementRecordAction,
  ReplacementRowJson,
  RowJSON,
  VIEW
} from './statements.types'

export function* createGroupEffect(
  action: Action<{
    name: string
    associated_account_ids: string[]
    type: number
    filter: IDateFilter
    source?: SOURCE
  }>
) {
  const {
    filter: { startTime, endTime },
    source,
    ...payload
  } = action.payload

  const {
    SupplySummary: {
      statementGroupFilter: { startTime: supplyStartTime, endTime: supplyEndTime, filterType }
    },
    CollectionSummary: {
      invoiceCollectionFilter,
      activeFilter: { filterType: CollectionFilterType }
    },
    PendingDueSummary: { filter, groupDueCurrentPage, sortBy }
  } = yield select((app: IAppState) => app.NewSummary)

  try {
    yield put(setLoaderAction({ id: 'createGroup', value: true }))
    const response: IApiResponse = yield call(createGroup, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS && response?.data?.collection_list) {
      yield put(updateOwnerSummaryAction({ startTime, endTime }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Group created successfully.'
        })
      )

      if (source === SOURCE.SUPPLY) {
        const payload = {
          startTime: supplyStartTime,
          endTime: supplyEndTime,
          filterType
        }
        yield put(fetchStatementGroupAction(payload))
      }

      if (source === SOURCE.COLLECTION) {
        yield put(
          getCollectionListInvoiceSummaryV2Action({
            startTime: startTime || 0,
            endTime: endTime || 0,
            page: 1,
            perPage: 200,
            invoiceCollectionFilter,
            filterType: CollectionFilterType
          })
        )
      }

      if (source === SOURCE.PENDINGDUE) {
        yield put(
          fetchPendingDueGroupSummaryAction({
            filter,
            pageNumber: groupDueCurrentPage,
            sortBy: sortBy,
            sortDescending: true
          })
        )
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Group creation failed. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getOwnerSummaryEffect(
  action: Action<{
    startTime?: number
    endTime?: number
    collectionListId?: string
  }>
) {
  const { startTime, endTime, collectionListId } = action.payload
  try {
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: true }))
    const response: IApiResponse = yield call(getOwnerSummary, {
      ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
      ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
      ...(collectionListId ? { collection_list_id: collectionListId } : {})
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getOwnerSummarySuccessAction(getFormattedOwnerSummary(response?.data)))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch summary. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* updateOwnerSummaryEffect(
  action: Action<{
    startTime?: number
    endTime?: number
  }>
) {
  const { startTime, endTime } = action.payload
  try {
    const StatementsState: IStatementsState = yield select((state: IAppState) => {
      return state.Statements
    })
    const actionCreator =
      StatementsState?.currentSummaryType === ISummaryType.BALANCE_DUE
        ? getCollectionListSummary
        : getCollectionListInvoiceSummaryAction
    yield put(
      actionCreator({
        startTime,
        endTime,
        page: 1,
        perPage: INVOICE_RESULTS_PER_PAGE,
        append: false,
        scrollBack: true
      })
    )
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* updateGroupEffect(
  action: Action<{
    collectionListId: string
    name: string
    addedAccountIds: Array<string>
    removedAccountIds: Array<string>
    filter: IDateFilter
    source?: SOURCE
  }>
) {
  const {
    collectionListId,
    name,
    addedAccountIds,
    removedAccountIds,
    filter: { startTime, endTime },
    source
  } = action.payload

  try {
    const StatementsState: IStatementsState = yield select((state: IAppState) => {
      return state.Statements
    })
    yield put(setLoaderAction({ id: 'createGroup', value: true }))
    const responses: IApiResponse[] = yield all([
      ...(name ? [call(updateStaffLinkName, collectionListId, name)] : []),
      ...(removedAccountIds.length
        ? [call(updateStaffLink, collectionListId, removedAccountIds, 'remove_associations')]
        : []),
      ...(addedAccountIds.length
        ? [call(updateStaffLink, collectionListId, addedAccountIds, 'edit_associations')]
        : [])
    ])
    if (responses.every((response) => response.type === API_RESPONSE_TYPE.FAILURE)) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Failed to update group. Please try again after some time.'
        })
      )
    } else {
      yield put(updateOwnerSummaryAction({ startTime, endTime }))
      yield delay(1000)
      if (StatementsState?.currentSummaryType === ISummaryType.BALANCE_DUE) {
        yield put(
          getCollectionListAccountSummary({
            startTime,
            endTime,
            collectionListId,
            page: 1,
            perPage: INVOICE_RESULTS_PER_PAGE,
            append: false
          })
        )
      } else {
        yield put(
          getCollectionListAccountInvoiceSummaryAction({
            startTime,
            endTime,
            page: 1,
            perPage: INVOICE_RESULTS_PER_PAGE,
            append: false,
            collectionListId
          })
        )
      }
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: responses.every((response) => response.type === API_RESPONSE_TYPE.SUCCESS)
            ? 'Group updated successfully.'
            : 'Some update(s) failed. Please try again after some time.'
        })
      )

      const {
        SupplySummary: { statementGroupFilter },
        CollectionSummary: { activeFilter, invoiceCollectionFilter }
      } = yield select((app: IAppState) => app.NewSummary)

      const {
        Navigation: {
          currentRoute: { path }
        }
      } = yield select((app: IAppState) => app)

      if (source === SOURCE.SUPPLY) {
        const { filterType, endTime, startTime } = statementGroupFilter
        const payload = {
          startTime,
          endTime,
          filterType,
          groupId: path.split('/').at(-1),
          updatedGroupNameWhileEditing: name
        }
        yield put(fetchGroupDataAction(payload))
      }
      if (source === SOURCE.COLLECTION) {
        yield put(
          getCollectionGroupInvoiceSummaryV2Action({
            startTime: activeFilter?.startTime,
            endTime: activeFilter?.endTime,
            filterType: activeFilter?.filterType,
            page: 1,
            perPage: 20,
            listId: path.split('/').at(-1),
            invoiceCollectionFilter
          })
        )
      }
    }
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* removeGroupEffect(
  action: Action<{ collectionListId: string; source?: SOURCE.SUPPLY }>
) {
  const { collectionListId, source } = action.payload
  try {
    yield put(setLoaderAction({ id: 'createGroup', value: true }))
    const response: IApiResponse = yield call(removeCollectionList, collectionListId)
    if (response.type === API_RESPONSE_TYPE.SUCCESS && response?.data?.collection_list) {
      yield put(setCurrentViewAction(VIEW.GROUP_SUMMARY_VIEW))
      yield put(setCurrentCollectionListIdAction(null))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Group removed successfully.'
        })
      )
      if (source === SOURCE.SUPPLY || source === SOURCE.COLLECTION) {
        yield call(forwardTo, IDispatchType.GO_BACK, false)
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Group deletion failed. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'removeGroup', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* addToLedgerEffect(
  action: Action<{
    transaction: any
    filter: IDateFilter
  }>
) {
  try {
    const {
      filter: { startTime, endTime }
    } = action.payload
    yield put(setLoaderAction({ id: 'approveTransactions', value: true }))
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    const response: IApiResponse = yield call(
      approveTxnCollectionStaffLink,
      action.payload.transaction.id || '',
      merchantProfile?.id
    )
    if (response?.type === API_RESPONSE_TYPE.SUCCESS) {
      if (response?.data?.errors?.length) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText:
              response.data.errors?.[0]?.error ||
              'Problem while adding to ledger, try after sometime'
          })
        )
        return null
      }
      yield put(updateOwnerSummaryAction({ startTime, endTime }))
      yield put(fetchEntityList(ENTITY_TYPE.CUSTOMER))
    } else if (
      response?.data?.response?.data?.error === "Bill doesn't exist" &&
      response?.data?.response?.status === 404
    ) {
      yield put(
        handleTxnAcceptOrRemove({
          merchantId: merchantProfile?.id,
          txnId: action.payload.transaction.id || '',
          amount: action.payload.transaction.amount,
          startTime,
          endTime
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText:
            response?.data?.response?.data?.error ||
            'Problem while adding to ledger, try after sometime'
        })
      )
    }
    yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* approveAllTransactionsEffect(
  action: Action<{
    transactions: any
    filter: IDateFilter
  }>
) {
  try {
    const {
      filter: { startTime, endTime },
      transactions
    } = action.payload
    yield put(setLoaderAction({ id: 'approveTransactions', value: true }))
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })

    if (!merchantProfile.id) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Transaction(s) could not be added. Please try again after sometime.'
        })
      )
      yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
      return
    }

    const bulkCollectionPayload = getBulkCollectionPayload(transactions, merchantProfile.id)

    const response: IApiResponse = yield call(
      approveBulkCollectionStaffLinkV2,
      bulkCollectionPayload
    )

    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (response.data.errors.length) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.ERROR,
            bodyText: response?.data?.error || 'Problem while adding to ledger, try after sometime'
          })
        )
        return null
      }
      yield put(updateOwnerSummaryAction({ startTime, endTime }))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText:
            response?.data?.response?.data?.error ||
            'Problem while adding to ledger, try after sometime'
        })
      )
    }
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}
// Not being used as BALANCE_DUE is disabled
export function* getCollectionListSummaryEffect(
  action: Action<{
    startTime?: number
    endTime?: number
    collectionListId?: string
    page: number
    perPage: number
    append?: boolean
    scrollBack?: boolean
  }>
) {
  const { startTime, endTime, page, perPage, append, scrollBack } = action.payload
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    if (!scrollBack) {
      yield put(setLoaderAction({ id: 'getOwnerSummary', value: !append }))
    }
    const [collectionListResponse, overallStatsResponse]: [IApiResponse, IApiResponse] = yield all([
      call(getCollectionListSummaryApi, {
        ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
        ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
        business_id: merchantProfile.id,
        page,
        per_page: perPage
      }),
      ...(!append
        ? [
            call(getCollectionListSummaryOverallStatsApi, {
              ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
              ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
              business_id: merchantProfile.id
            })
          ]
        : [])
    ])

    if (collectionListResponse.type === API_RESPONSE_TYPE.SUCCESS) {
      let formattedSummary: any = getFormattedCollectionListSummary(
        collectionListResponse?.data,
        append
      )
      if (append) {
        yield put(setLoaderAction({ id: 'isAppending', value: false }))
      } else if (overallStatsResponse.type === API_RESPONSE_TYPE.SUCCESS) {
        formattedSummary = {
          ...formattedSummary,
          ownerSummaryOverall: formatCollectionListSummaryOverallStats(overallStatsResponse?.data)
        }
      }
      yield put(getCollectionListSummarySuccessAction(formattedSummary))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch summary. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}
// Not being used as BALANCE_DUE is disabled
export function* getSingleCollectionListSummaryEffect(
  action: Action<{
    startTime?: number
    endTime?: number
    collectionListId?: string
    page: number
    perPage: number
    append?: boolean
  }>
) {
  const { startTime, endTime, collectionListId, page, perPage, append } = action.payload
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: !append }))

    const [collectionListResponse, overallStatsResponse]: [IApiResponse, IApiResponse] = yield all([
      call(getSingleCollectionListSummaryApi, {
        ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
        ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
        collection_list_id: collectionListId,
        business_id: merchantProfile.id,
        page,
        per_page: perPage
      }),
      ...(!append
        ? [
            call(getCollectionListSummaryOverallStatsApi, {
              ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
              ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
              business_id: merchantProfile.id,
              collection_list_ids: [collectionListId]
            })
          ]
        : [])
    ])

    if (collectionListResponse.type === API_RESPONSE_TYPE.SUCCESS) {
      let formattedSummary: any = getSingleFormattedCollectionListSummary(
        collectionListResponse?.data,
        append
      )
      if (append) {
        yield put(setLoaderAction({ id: 'isAppending', value: false }))
      } else if (overallStatsResponse.type === API_RESPONSE_TYPE.SUCCESS) {
        formattedSummary = {
          ...formattedSummary,
          ownerSummaryOverall: formatCollectionListSummaryOverallStats(overallStatsResponse?.data)
        }
      }
      yield put(getCollectionListAccountSummarySuccess(formattedSummary))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch summary. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getCollectionListInvoiceSummaryEffect(
  action: Action<{
    startTime?: number
    endTime?: number
    append?: boolean
    page?: number
    perPage?: number
    scrollBack?: boolean
  }>
) {
  const { startTime, endTime, append, page, perPage, scrollBack = false } = action.payload
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    const experimentState: IExpState = yield select((state: IAppState) => {
      return state.Experiment
    })
    const isDynamicStatementEnabled = experimentState.ab.dynamicStatements
    if (!scrollBack) {
      yield put(setLoaderAction({ id: 'getOwnerSummary', value: !append }))
    }
    const [collectionListResponse, overallStatsResponse]: [IApiResponse, IApiResponse] = yield all([
      call(isDynamicStatementEnabled ? getStatementGroupsApi : getCollectionListInvoiceSummaryApi, {
        ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
        ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
        business_id: merchantProfile.id,
        page,
        per_page: perPage
      }),
      ...(!isDynamicStatementEnabled && !append
        ? [
            call(getCollectionListInvoiceSummaryOverallStatsApi, {
              ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
              ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
              business_id: merchantProfile.id
            })
          ]
        : [])
    ])
    if (collectionListResponse.type === API_RESPONSE_TYPE.SUCCESS) {
      let formattedSummary: any = getFormattedCollectionListInvoiceSummary(
        collectionListResponse?.data,
        append
      )
      if (append) {
        yield put(setLoaderAction({ id: 'isAppending', value: false }))
      } else if (
        isDynamicStatementEnabled ||
        overallStatsResponse.type === API_RESPONSE_TYPE.SUCCESS
      ) {
        formattedSummary = {
          ...formattedSummary,
          ownerSummaryOverall: formatCollectionListSummaryOverallStats(
            isDynamicStatementEnabled
              ? collectionListResponse?.data?.overall_stats
              : overallStatsResponse?.data
          )
        }
      }
      yield put(getCollectionListInvoiceSummarySuccessAction(formattedSummary))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch summary. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getCollectionListAccountInvoiceSummaryEffect(
  action: Action<{
    startTime?: number
    endTime?: number
    append?: boolean
    page?: number
    perPage?: number
    collectionListId: string
  }>
) {
  const { startTime, endTime, append, page, perPage, collectionListId } = action.payload
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    const experimentState: IExpState = yield select((state: IAppState) => {
      return state.Experiment
    })
    const isDynamicStatementEnabled = experimentState.ab.dynamicStatements
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: !append }))
    const [collectionListResponse, overallStatsResponse]: [IApiResponse, IApiResponse] = yield all([
      call(
        isDynamicStatementEnabled
          ? getStatementGroupAccountsApi
          : getSingleCollectionListInvoiceSummaryApi,
        {
          ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
          ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
          business_id: merchantProfile.id,
          page,
          per_page: perPage,
          ...(isDynamicStatementEnabled
            ? { group_id: collectionListId }
            : { collection_list_id: collectionListId })
        }
      ),
      ...(!isDynamicStatementEnabled && !append
        ? [
            call(getCollectionListInvoiceSummaryOverallStatsApi, {
              ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
              ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
              business_id: merchantProfile.id,
              collection_list_ids: [collectionListId]
            })
          ]
        : [])
    ])
    if (collectionListResponse.type === API_RESPONSE_TYPE.SUCCESS) {
      let formattedSummary: any = getSingleFormattedCollectionListInvoiceSummary(
        collectionListResponse?.data,
        append
      )
      if (append) {
        yield put(setLoaderAction({ id: 'isAppending', value: false }))
      } else if (isDynamicStatementEnabled) {
        formattedSummary = {
          ...formattedSummary,
          ownerSummaryOverall: formatCollectionListSummaryOverallStats(
            collectionListResponse?.data?.overall_stats
          )
        }
      } else if (overallStatsResponse.type === API_RESPONSE_TYPE.SUCCESS) {
        formattedSummary = {
          ...formattedSummary,
          ownerSummaryOverall: formatCollectionListSummaryOverallStats(overallStatsResponse?.data)
        }
      }
      yield put(getCollectionListAccountInvoiceSummarySuccessAction(formattedSummary))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch summary. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'getOwnerSummary', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getUnapprovedTransactionsSagaEffects(
  action: Action<{
    startTime?: number
    endTime?: number
    collectionListId?: string
  }>
) {
  const { startTime, endTime, collectionListId } = action.payload
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    const experimentState: IExpState = yield select((state: IAppState) => {
      return state.Experiment
    })
    const isDynamicStatementEnabled = experimentState.ab.dynamicStatements
    yield put(setLoaderAction({ id: 'approveTransactions', value: true }))
    const response: IApiResponse = yield call(getUnapprovedTransactionsApi, {
      ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
      ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
      ...(collectionListId
        ? { [isDynamicStatementEnabled ? 'group_id' : 'collection_list_id']: collectionListId }
        : {}),
      business_id: merchantProfile.id
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getUnapprovedTransactionsSuccess(response?.data.transactions))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch Transactions. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getAccountAllocationDetailsEffects(
  action: Action<{
    collectionListId?: string
  }>
) {
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    const collectionListId = action.payload
    const response: IApiResponse = yield call(getAccountAllocationDetailsApi, {
      business_id: merchantProfile.id,
      ...(collectionListId ? { collection_list_id: collectionListId } : {})
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(
        getAccountAllocationDetailsSuccess(getFormattedAccountAllocationDetails(response?.data))
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch Accounts. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'approveTransactions', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getReplacementListEffects(
  action: Action<{
    startTime?: number
    endTime?: number
    collectionListId: string
  }>
) {
  const { startTime, endTime, collectionListId } = action.payload
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    const experimentState: IExpState = yield select((state: IAppState) => {
      return state.Experiment
    })
    const isDynamicStatementEnabled = experimentState.ab.dynamicStatements
    yield put(setLoaderAction({ id: 'replacements', value: true }))
    const response: IApiResponse = yield call(getReplacementListApi, {
      ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
      ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
      ...{ [isDynamicStatementEnabled ? 'group_id' : 'collection_list_id']: collectionListId },
      business_id: merchantProfile.id
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getPendingReplacementListSuccess(response?.data.replacements))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch Pending Replacements. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'replacements', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'replacements', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* updatePendingReplacementEffects(action: Action<IUpdateReplacementRecordAction>) {
  try {
    yield put(setLoaderAction({ id: 'replacementAction', value: false }))
    const {
      accountId,
      amount,
      replacementItems,
      billId,
      merchantId,
      replacementId,
      activeFilter,
      collectionListId
    } = action.payload
    const response: IApiResponse = yield call(updatePendingReplacementRecord, {
      accountId,
      replacementId,
      amount: `${amount}`,
      replacementItems,
      billId,
      merchantId,
      status: 1,
      collectionListId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(updatePendingReplacementSuccess(response?.data))
      yield put(setLoaderAction({ id: 'replacementAction', value: true }))
      yield put(setDrawerAction({ id: 'replacements', value: false }))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Update Replacements Request Success'
        })
      )
      yield put(
        updateOwnerSummaryAction({
          startTime: activeFilter.startTime,
          endTime: activeFilter.endTime
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not update Pending Replacements. Please try again after some time.'
        })
      )
    }
  } catch (error: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: error.message
      })
    )
  }
}
// Not being used as BALANCE_DUE is disabled
export function* getFinalBalanceDownloadDataEffect(
  action: Action<{
    activeFilter: IDateFilter
    collectionListId?: string
    fileName: string
    format: string
    currentView: VIEW
    title: string
  }>
) {
  const { activeFilter, collectionListId, fileName, format, currentView, title } = action.payload
  const { startTime, endTime } = activeFilter
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })
    yield put(setLoaderAction({ id: 'download', value: true }))
    let downloadApi = getFinalBalanceDownloadDataApi
    let listFormatter = getFormattedCollectionListSummary
    if (currentView === VIEW.CUSTOMERS_SUMMARY_VIEW) {
      downloadApi = getFinalBalanceDownloadAccountDataApi
      listFormatter = getSingleFormattedCollectionListSummary
    }
    const [collectionListResponse, overallStatsResponse]: [IApiResponse, IApiResponse] = yield all([
      call(downloadApi, {
        ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
        ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
        business_id: merchantProfile.id,
        ...(collectionListId ? { collection_list_id: collectionListId } : {})
      }),
      call(getCollectionListSummaryOverallStatsApi, {
        ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
        ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
        business_id: merchantProfile.id
      })
    ])
    if (
      collectionListResponse.type === API_RESPONSE_TYPE.SUCCESS &&
      overallStatsResponse.type === API_RESPONSE_TYPE.SUCCESS
    ) {
      const { summaryStats } = listFormatter(collectionListResponse?.data)
      const { totalPayment, totalCredit, netBalanceDue } = formatCollectionListSummaryOverallStats(
        overallStatsResponse?.data
      )
      const data = formatForBalanceExcelExport(summaryStats, currentView)
      const ws = utils.json_to_sheet(data, { origin: 'A10' })
      const excelHeading = getExcelHeading(activeFilter, title)
      utils.sheet_add_aoa(
        ws,
        [
          [excelHeading],
          [],
          ['Statement Created On:', dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')],
          [],
          ['Total Payment', 'Total Credit', 'Net Balance Due'],
          [totalPayment, totalCredit, netBalanceDue]
        ],
        {
          origin: 'A1'
        }
      )
      const wb = utils.book_new()
      ws['!cols'] = getMaxColumnWidths(data)
      utils.book_append_sheet(wb, ws, 'Sheet 1')
      writeFileXLSX(wb, `${fileName}${format}`)
      yield put(setDownloadStatus('downloaded'))
      yield put(setLoaderAction({ id: 'download', value: false }))
      yield delay(3000)
      yield put(setDownloadStatus('default'))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch summary. Please try again after some time.'
        })
      )
      yield put(setDownloadStatus('default'))
      yield put(setLoaderAction({ id: 'download', value: false }))
    }
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'download', value: false }))
    yield put(setDownloadStatus('default'))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* getInvoiceLevelDownloadDataEffect(
  action: Action<{
    activeFilter: IDateFilter
    collectionListId?: string
    fileName: string
    format: string
    currentView: VIEW
    title: string
  }>
) {
  const { activeFilter, collectionListId, fileName, format, currentView, title } = action.payload
  const { startTime, endTime } = activeFilter
  try {
    const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
      return state.Dashboard.merchantProfile.data
    })

    const experimentState: IExpState = yield select((state: IAppState) => {
      return state.Experiment
    })
    const isDynamicStatementEnabled = experimentState.ab.dynamicStatements

    const apiReq = isDynamicStatementEnabled
      ? getInvoiceLevelGroupSummaryDataApi
      : getInvoiceLevelDownloadDataApi

    yield put(setLoaderAction({ id: 'download', value: true }))
    const response: IApiResponse = yield call(apiReq, {
      ...(startTime || startTime === 0 ? { start_time: startTime } : {}),
      ...(endTime || endTime === 0 ? { end_time: endTime } : {}),
      ...(collectionListId
        ? { [isDynamicStatementEnabled ? 'group_ids' : 'collection_list_ids']: [collectionListId] }
        : {}),
      business_id: merchantProfile.id
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const { summaryStats, totalInvoiceAmount, totalInvoicePaid, totalInvoiceDue } =
        getFormattedInvoiceLevelDownloadData(response?.data, isDynamicStatementEnabled)
      const data = formatForInvoiceExcelExport(summaryStats, currentView)
      const ws = utils.json_to_sheet(data, { origin: 'A10' })
      const excelHeading = getExcelHeading(activeFilter, title)
      utils.sheet_add_aoa(
        ws,
        [
          [excelHeading],
          [],
          ['Statement Created On:', dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')],
          [],
          ['Amount Payable', 'Amount Paid ', 'Amount Due'],
          [totalInvoiceAmount, totalInvoicePaid, totalInvoiceDue],
          ...(data.length === 0 ? [[], [], ['No invoices found']] : [])
        ],
        {
          origin: 'A1'
        }
      )
      const wb = utils.book_new()
      if (data.length) {
        ws['!cols'] = getMaxColumnWidths(data)
      }
      utils.book_append_sheet(wb, ws, 'Sheet 1')
      writeFileXLSX(wb, `${fileName}${format}`)
      yield put(setDownloadStatus('downloaded'))
      yield put(setLoaderAction({ id: 'download', value: false }))
      yield delay(3000)
      yield put(setDownloadStatus('default'))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Could not fetch summary. Please try again after some time.'
        })
      )
      yield put(setDownloadStatus('default'))
      yield put(setLoaderAction({ id: 'download', value: false }))
    }
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'download', value: false }))
    yield put(setDownloadStatus('default'))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

/**
 * START of V2 Download Summary
 */

const isOverallTab = (invoiceCollectionFilter: SUMMARY_FILTER_OPTION) => {
  return invoiceCollectionFilter === SUMMARY_FILTER_OPTION.SAME_DAY_COLLECTIONS
}

export function* getV2InvoiceDownload(
  action: Action<{
    activeFilter: IDateFilter
    collectionListId?: string
    fileName: string
    format: string
    currentView: VIEW
    title: string
    businessType?: BUSINESS_TYPE
  }>
) {
  const {
    payload: { fileName, format, activeFilter, title, businessType }
  } = action
  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })

  const { invoiceCollectionFilter }: ICollectionSummaryState = yield select((state: IAppState) => {
    return state.NewSummary.CollectionSummary
  })

  const { businessDetails } = yield select((state: IAppState) => {
    return state.Dashboard.businessAccounts
  })
  const experimentState: IExpState = yield select((state: IAppState) => {
    return state.Experiment
  })
  const isExperimentEnabled = experimentState.ab.dynamicStatements
  const isMultiBusinessDownload = businessType === BUSINESS_TYPE.ALL
  yield put(setV2SummaryDownloadDrawerStatus('initiated'))
  const API = isMultiBusinessDownload
    ? getV2InvoiceLevelDownloadDataAllBusinessApi
    : getV2InvoiceLevelDownloadDataApi
  const response: IApiResponse = yield call(API, isExperimentEnabled, {
    start_time: activeFilter.startTime,
    end_time: activeFilter.endTime,
    business_id: merchantProfile.id,
    page: 1,
    per_page: 9999999,
    filter:
      isOverallTab(invoiceCollectionFilter) && isExperimentEnabled
        ? SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
        : invoiceCollectionFilter
  })
  let onlineCollectionsResponse: IApiResponse | undefined = undefined
  if (
    isMultiBusinessDownload &&
    isExperimentEnabled &&
    (activeFilter.startTime || activeFilter.startTime === 0) &&
    activeFilter.endTime
  ) {
    onlineCollectionsResponse = yield call(getAllBusinessOnlineCollections, {
      start_time: activeFilter.startTime || 0,
      end_time: activeFilter.endTime || 0
    })
  }

  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Could not fetch summary. Please try again after some time.', {
      cause: 'customError'
    })
  }

  let data: RowJSON[] = []
  if (isMultiBusinessDownload) {
    data = response.data.businesses.reduce((acc: RowJSON[], item: any) => {
      const businessName = businessDetails[item.business_id]?.name
      const formattedData = formatData({
        collection_lists: item.collection_lists || item.groups,
        businessName,
        showOnlineTxnType: 'settled'
      })
      return acc.concat(formattedData)
    }, [])
  } else {
    data = formatData({
      collection_lists: response.data.collection_lists || response.data.groups,
      showOnlineTxnType: 'settled'
    })
  }
  const ws = utils.json_to_sheet(data, { origin: 'A10' })
  const wb = utils.book_new()
  const excelHeading = getExcelHeading(activeFilter, title)
  utils.sheet_add_aoa(
    ws,
    [
      [excelHeading],
      [],
      [`Statement Created On: ${dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')}`],
      []
    ],
    {
      origin: 'A1'
    }
  )

  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }
  utils.book_append_sheet(wb, ws, 'Sheet 1')

  let unsettledRowData: RowJSON[] = []

  if (isMultiBusinessDownload) {
    unsettledRowData = response.data.businesses.reduce((acc: RowJSON[], item: any) => {
      const businessName = businessDetails[item.business_id]?.name
      const formattedData = formatData({
        collection_lists: item.collection_lists || item.groups,
        businessName,
        showOnlineTxnType: 'unsettled'
      })
      return acc.concat(formattedData)
    }, [])
  } else {
    unsettledRowData = formatData({
      collection_lists: response.data.collection_lists || response.data.groups,
      showOnlineTxnType: 'unsettled'
    })
  }
  const ws2 = utils.json_to_sheet(unsettledRowData, { origin: 'A10' })
  utils.sheet_add_aoa(
    ws2,
    [
      [excelHeading],
      [],
      [`Statement Created On: ${dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')}`],
      [],
      ['Unsettled online transactions']
    ],
    {
      origin: 'A1'
    }
  )
  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }
  utils.book_append_sheet(wb, ws2, 'Sheet 2')

  if (isMultiBusinessDownload && isExperimentEnabled && onlineCollectionsResponse?.data) {
    const onlineCollectionData = formatOnlineCollectionData(
      onlineCollectionsResponse.data.transactions || []
    )
    const ws3 = utils.json_to_sheet(onlineCollectionData, { origin: 'A10' })
    utils.sheet_add_aoa(
      ws3,
      [
        [excelHeading],
        [],
        [`Statement Created On: ${dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')}`],
        [],
        ['Online Collections']
      ],
      {
        origin: 'A1'
      }
    )
    utils.book_append_sheet(wb, ws3, 'Sheet 3')
  }
  writeFileXLSX(wb, `${fileName}${format}`)
  yield put(setV2SummaryDownloadDrawerStatus('completed'))
  yield put(
    setCollectionDrawerAction({
      id: 'downloadDialogOpen',
      value: false
    })
  )
}
/**
 * End of V2 Download Summary
 */

/**
 * START of V2 Replacement Summary Download
 */
export function* getReplacementSummaryDownloadEffect(
  action: Action<{
    activeFilter: IDateFilter
    fileName: string
    format: string
    title: string
  }>
) {
  const {
    payload: { fileName, format, activeFilter, title }
  } = action
  const merchantProfile: IMerchantProfile = yield select((state: IAppState) => {
    return state.Dashboard.merchantProfile.data
  })

  const { invoiceCollectionFilter }: ICollectionSummaryState = yield select((state: IAppState) => {
    return state.NewSummary.CollectionSummary
  })

  yield put(setReplacementSummaryDownloadStatus('initiated'))

  const response: IApiResponse = yield call(getReplacementDowloadApi, {
    start_time: activeFilter.startTime,
    end_time: activeFilter.endTime,
    business_id: merchantProfile.id,
    page: 1,
    per_page: 9999999,
    filter: isOverallTab(invoiceCollectionFilter)
      ? SUMMARY_FILTER_OPTION.ALL_COLLECTIONS
      : invoiceCollectionFilter
  })
  if (response.type !== API_RESPONSE_TYPE.SUCCESS) {
    throw new Error('Could not fetch summary. Please try again after some time.', {
      cause: 'customError'
    })
  }

  const data: ReplacementRowJson[] = formatReplacementSummary(response.data.summary)
  const ws = utils.json_to_sheet(data, { origin: 'A10' })
  const wb = utils.book_new()
  const excelHeading = getExcelHeading(activeFilter, title)
  utils.sheet_add_aoa(
    ws,
    [
      [excelHeading],
      [],
      [`Replacement Statement Created On: ${dayjs.unix(dayjs().unix()).format('DD MMM, YYYY')}`],
      []
    ],
    {
      origin: 'A1'
    }
  )

  if (data.length) {
    ws['!cols'] = getMaxColumnWidths(data)
  }

  utils.book_append_sheet(wb, ws, 'Sheet 1')
  writeFileXLSX(wb, `${fileName}${format}`)
  yield put(setReplacementSummaryDownloadStatus('completed'))
  yield put(
    setCollectionDrawerAction({
      id: 'replacementDialogOpen',
      value: false
    })
  )
}
/**
 * End of V2 Replacement Summary Download
 */
/**
 * START of Dynamic Statement (Tags at Statement) Effects
 */

export function* createGroupByTagsEffect(
  action: Action<{
    name: string
    tags: string[]
    filterOption: FILTER_OPTION
    filter: IDateFilter
    source: 'collectionSummary' | 'statements' | 'pendingDueSummary' | SOURCE
  }>
) {
  const {
    filter: { startTime, endTime, filterType: payloadFilterType },
    ...payload
  } = action.payload

  const {
    SupplySummary: {
      statementGroupFilter: { startTime: supplyStartTime, endTime: supplyEndTime, filterType }
    },
    CollectionSummary: { invoiceCollectionFilter }
  } = yield select((app: IAppState) => app.NewSummary)

  try {
    yield put(setLoaderAction({ id: 'createGroup', value: true }))
    const response: IApiResponse = yield call(createGroupByTagsApi, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS && response?.data?.statement_group) {
      if (
        action.payload.source === 'collectionSummary' ||
        action.payload.source === SOURCE.COLLECTION
      ) {
        yield put(
          getCollectionListInvoiceSummaryV2Action({
            startTime: startTime || 0,
            endTime: endTime || 0,
            page: 1,
            perPage: 200,
            invoiceCollectionFilter,
            filterType: payloadFilterType!
          })
        )
      } else {
        yield put(updateOwnerSummaryAction({ startTime, endTime }))
      }
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Group created successfully.'
        })
      )

      const { filter, groupDueCurrentPage, sortBy } = yield select(
        (app: IAppState) => app.NewSummary.PendingDueSummary
      )
      if (action.payload.source === SOURCE.PENDINGDUE) {
        yield put(
          fetchPendingDueGroupSummaryAction({
            filter,
            pageNumber: groupDueCurrentPage,
            sortBy: sortBy,
            sortDescending: true
          })
        )
      }

      if (action.payload.source === SOURCE.SUPPLY) {
        const payload = {
          startTime: supplyStartTime,
          endTime: supplyEndTime,
          filterType
        }
        yield put(fetchStatementGroupAction(payload))
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText:
            response.data?.response?.data?.error ||
            'Group creation failed. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* removeGroupByTagsEffect(
  action: Action<{ groupId: string; source?: SOURCE.SUPPLY }>
) {
  try {
    yield put(setLoaderAction({ id: 'createGroup', value: true }))
    const response: IApiResponse = yield call(removeGroupApi, action.payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(setCurrentViewAction(VIEW.GROUP_SUMMARY_VIEW))
      yield put(setCurrentCollectionListIdAction(null))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: 'Group removed successfully.'
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Group deletion failed. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'removeGroup', value: false }))

    if (action.payload.source === SOURCE.SUPPLY || action.payload.source === SOURCE.COLLECTION) {
      yield call(forwardTo, IDispatchType.GO_BACK, false)
    }
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* updateGroupByTagsEffect(
  action: Action<{
    groupId: string
    groupName?: string
    tagIds?: Array<string>
    filterOption?: FILTER_OPTION
    filter: IDateFilter
    source: 'collectionSummary' | 'statements' | 'pendingDueSummary' | SOURCE
  }>
) {
  const {
    groupId,
    groupName,
    tagIds,
    filterOption,
    filter: { startTime, endTime, filterType },
    source
  } = action.payload

  const { invoiceCollectionFilter, activeFilter }: ICollectionSummaryState = yield select(
    (app: IAppState) => app.NewSummary.CollectionSummary
  )

  try {
    yield put(setLoaderAction({ id: 'createGroup', value: true }))
    const responses: IApiResponse[] = yield all([
      ...(groupName ? [call(updateGroupNameApi, { groupId, groupName })] : []),
      ...(tagIds ? [call(updateGroupFiltersApi, { groupId, tagIds, filterOption })] : [])
    ])
    if (responses.every((response) => response.type === API_RESPONSE_TYPE.FAILURE)) {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Failed to update group. Please try again after some time.'
        })
      )
    } else {
      yield put(updateOwnerSummaryAction({ startTime, endTime }))
      yield delay(1000)
      if (source === 'collectionSummary' || source === SOURCE.COLLECTION) {
        yield put(
          getCollectionGroupInvoiceSummaryV2Action({
            startTime: startTime || 0,
            endTime: endTime || 0,
            page: 1,
            perPage: INVOICE_RESULTS_PER_PAGE,
            listId: groupId,
            invoiceCollectionFilter,
            filterType: filterType || activeFilter?.filterType || COLLECTION_FILTER_TYPE.DEFAULT
          })
        )
      } else {
        yield put(
          getCollectionListAccountInvoiceSummaryAction({
            startTime,
            endTime,
            page: 1,
            perPage: INVOICE_RESULTS_PER_PAGE,
            append: false,
            collectionListId: groupId
          })
        )
      }
      if (source === SOURCE.SUPPLY) {
        yield put(setSpecificStatementGroup(responses[0].data.statement_group))
      }
      yield put(
        addAutoFadeNotification({
          type: NotificationType.SUCCESS,
          bodyText: responses.every((response) => response.type === API_RESPONSE_TYPE.SUCCESS)
            ? 'Group updated successfully.'
            : 'Some update(s) failed. Please try again after some time.'
        })
      )
    }
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
  } catch (e: any) {
    yield put(setLoaderAction({ id: 'createGroup', value: false }))
    yield put(setDrawerAction({ id: 'createGroup', value: false }))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

/**
 * END of Dynamic Statement (Tags at Statement) Effects
 */
