import { Action } from 'infra/types'

export const DeviceActions = {
  // Change device type: desktop or mobile
  UPDATE_DEVICE_TYPE: 'device/updateDeviceType'
}

/**
 * Dispatch on server if current device is mobile and
 * hydrate the same value on client side
 * @param payload If it is mobile device
 * @returns Action
 */
export const updateDeviceType = (payload: boolean): Action<boolean> => ({
  type: DeviceActions.UPDATE_DEVICE_TYPE,
  payload
})
