import { Action } from 'infra/types'
import {
  BankStatementDocumentType,
  BankStatementFilter,
  BankStatementReconTxnType,
  BankStatementTemplateType,
  FilterTime,
  CreateBankStatementTemplatePayloadType,
  ILoaders,
  StatementReconTxnActionPayloadType,
  UpdateBankStatementTemplatePayloadType,
  IBankStatementState,
  IDepositHistory,
  IBankHistoryType
} from './bankStatement.types'

export const bankStatementActions = {
  setLoader: 'bankStatement/setLoader',
  setDrawer: 'bankStatement/setDrawer',
  setFilterTime: 'bankStatement/setFilterTime',
  fetchBankStatementTemplatesAction: 'bankStatement/fetchBankStatementTemplates',
  setBankStatementTemplatesAction: 'bankStatement/setBankStatementTemplatesAction',
  createBankStatementTemplateAction: 'bankStatement/createBankStatementTemplateAction',
  updateBankStatementTemplateAction: 'bankStatement/updateBankStatementTemplateAction',
  setSingleBankStatementTemplateAction: 'bankStatement/setSingleBankStatementTemplateAction',
  fetchBankStatementDocumentsAction: 'bankStatement/fetchBankStatementDocumentsAction',
  setBankStatementDocumentsAction: 'bankStatement/setBankStatementDocumentsAction',
  setSingleBankStatementDocumentAction: 'bankStatement/setSingleBankStatementDocumentAction',
  fetchBankStatementReconciledTxnsAction: 'bankStatement/fetchBankStatementReconciledTxnsAction',
  deleteBankStatementTxnsAction: 'bankStatement/deleteBankStatementTxnsAction',
  setBankStatementReconciledTxnsAction: 'bankStatement/setBankStatementReconciledTxnsAction',
  setBankStatementFilterAction: 'bankStatement/setBankStatementFilterAction',
  setBankStatementSelectedFilterAction: 'bankStatement/setBankStatementSelectedFilterAction',
  resetBankStatementSelectedFilterAction: 'bankStatement/resetBankStatementSelectedFilterAction',
  startPollBankStatementDocumentStatus: 'bankStatement/startPollBankStatementDocumentStatus',
  stopPollBankStatementDocumentStatus: 'bankStatement/stopPollBankStatementDocumentStatus',
  editAssetAccount: 'bankStatement/editAssetAccount',
  setEditAssetAccount: 'bankStatement/setEditAssetAccount',
  getCashDepositHistory: 'bankStatement/getCashDepositHistory',
  getBankDepositHistory: 'bankStatement/getBankDepositHistory',
  setCashDepositHistory: 'bankStatement/setCashDepositHistory',
  setBankDepositHistory: 'bankStatement/setBankDepositHistory',
  updateDepositHistoryState: 'bankStatement/updateDepositHistoryState',
  setCastDepositTransactionHistoryAction: 'bankStatement/setCastDepositTransactionHistoryAction',
  setBankHistoryType: 'bankStatement/setBankHistoryType',
  setBankHistoryImage: 'bankStatement/setBankHistoryImage',
  setShowDownloadPopUp: 'bankStatement/setShowDownloadPopUp',
  downloadBankAndCashStatement: 'bankStatement/downloadBankAndCashStatement',
  fetchAccountTransaction: 'bankStatement/fetchAccountTransactions',
  storeExcelTransaction: 'bankStatement/storeExcelTransaction',
  storeFetchedTransactions: 'bankStatement/storeFetchedTransactions',
  saveComputedBankTransactionAction: 'bankStatement/saveComputedBankTransactionAction',
  resetBankReconciliationData: 'bankStatement/resetBankReconciliationData'
}

export const setFilterTime = (payload: FilterTime) => {
  return {
    type: bankStatementActions.setFilterTime,
    payload
  }
}

export const fetchBankStatementTemplates = () => {
  return {
    type: bankStatementActions.fetchBankStatementTemplatesAction,
    payload: null
  }
}

export const setBankStatementTemplates = (payload: BankStatementTemplateType[]) => {
  return {
    type: bankStatementActions.setBankStatementTemplatesAction,
    payload
  }
}

export const setSingleBankStatementTemplate = (payload: BankStatementTemplateType) => {
  return {
    type: bankStatementActions.setSingleBankStatementTemplateAction,
    payload
  }
}

export const createBankStatementTemplate = (payload: CreateBankStatementTemplatePayloadType) => {
  return {
    type: bankStatementActions.createBankStatementTemplateAction,
    payload
  }
}

export const updateBankStatementTemplate = (payload: UpdateBankStatementTemplatePayloadType) => {
  return {
    type: bankStatementActions.updateBankStatementTemplateAction,
    payload
  }
}

export const fetchBankStatementDocuments = () => {
  return {
    type: bankStatementActions.fetchBankStatementDocumentsAction,
    payload: null
  }
}

export const setBankStatementDocuments = (payload: BankStatementDocumentType[]) => {
  return {
    type: bankStatementActions.setBankStatementDocumentsAction,
    payload
  }
}

export const setSingleBankStatementDocument = (payload: BankStatementDocumentType) => {
  return {
    type: bankStatementActions.setSingleBankStatementDocumentAction,
    payload
  }
}

export const fetchBankStatementReconciledTxns = (payload: StatementReconTxnActionPayloadType) => {
  return {
    type: bankStatementActions.fetchBankStatementReconciledTxnsAction,
    payload: payload
  }
}

export const deleteBankStatementTxns = (payload: string[]) => {
  return {
    type: bankStatementActions.deleteBankStatementTxnsAction,
    payload: payload
  }
}

export const setBankStatementReconciledTxns = (payload: {
  reconciled: BankStatementReconTxnType[]
  unReconciled: BankStatementReconTxnType[]
}) => {
  return {
    type: bankStatementActions.setBankStatementReconciledTxnsAction,
    payload
  }
}

export const setBankStatementFilter = (payload: { [K in keyof BankStatementFilter]: string[] }) => {
  return {
    type: bankStatementActions.setBankStatementFilterAction,
    payload
  }
}

export const setBankStatementSelectedFilter = (payload: {
  type: keyof BankStatementFilter
  payload: string
}) => {
  return {
    type: bankStatementActions.setBankStatementSelectedFilterAction,
    payload
  }
}

export const resetBankStatementSelectedFilter = () => {
  return {
    type: bankStatementActions.resetBankStatementSelectedFilterAction,
    payload: null
  }
}

export const setLoaderAction = (payload: {
  id: keyof ILoaders
  value: boolean
}): Action<{ id: keyof ILoaders; value: boolean }> => ({
  type: bankStatementActions.setLoader,
  payload
})

export const setDrawerAction = (payload: {
  drawerName: keyof IBankStatementState['drawers']
  status: boolean
}): Action<{
  drawerName: keyof IBankStatementState['drawers']
  status: boolean
}> => ({
  type: bankStatementActions.setDrawer,
  payload
})

export const startPollBankStatementDocumentStatus = (payload: string): Action<string> => ({
  type: bankStatementActions.startPollBankStatementDocumentStatus,
  payload
})

export const stopPollBankStatementDocumentStatus = (): Action<null> => ({
  type: bankStatementActions.stopPollBankStatementDocumentStatus,
  payload: null
})

export const setEditAssetAccount = (payload: string): Action<string> => ({
  type: bankStatementActions.setEditAssetAccount,
  payload
})

export const editAssetAccount = (payload: { name: string }): Action<{ name: string }> => ({
  type: bankStatementActions.editAssetAccount,
  payload
})

export const getCashDepositHistoryAction = (payload: {
  business_id: string
  limit: number
  start_time: number
  end_time: number
  offset: number
}) => {
  return {
    type: bankStatementActions.getCashDepositHistory,
    payload: payload
  }
}

export const getBankDepositHistoryAction = (payload: {
  business_id: string
  limit: number
  start_time: number
  end_time: number
  offset: number
}) => {
  return {
    type: bankStatementActions.getBankDepositHistory,
    payload: payload
  }
}

export const setCashDepositHistoryAction = (payload: any) => {
  return {
    type: bankStatementActions.setCashDepositHistory,
    payload
  }
}

export const setBankDepositHistoryAction = (payload: any) => {
  return {
    type: bankStatementActions.setBankDepositHistory,
    payload
  }
}

export const updateDepositHistoryStateAction = (payload: IDepositHistory) => {
  return {
    type: bankStatementActions.updateDepositHistoryState,
    payload
  }
}

export const setCastDepositTransactionHistoryAction = (payload: any) => {
  return {
    type: bankStatementActions.setCastDepositTransactionHistoryAction,
    payload
  }
}

export const setBankHistoryType = (payload: IBankHistoryType) => {
  return {
    type: bankStatementActions.setBankHistoryType,
    payload
  }
}

export const setBankHistoryImage = (payload: string) => {
  return {
    type: bankStatementActions.setBankHistoryImage,
    payload
  }
}

export const setShowDownloadPopUpAction = (payload: boolean) => {
  return {
    type: bankStatementActions.setShowDownloadPopUp,
    payload
  }
}

export const downloadBankAndCashStatementAction = (payload: { fileName: string }) => {
  return {
    type: bankStatementActions.downloadBankAndCashStatement,
    payload: payload
  }
}

export const fetchAccountTransaction = (payload: {
  id: string
  limit?: number
  start_time: number
  end_time: number
}) => {
  return {
    type: bankStatementActions.fetchAccountTransaction,
    payload
  }
}

export const storeExcelTransaction = (payload: any) => {
  return {
    type: bankStatementActions.storeExcelTransaction,
    payload
  }
}

export const storeFetchedTransactions = (payload: {
  transaction: any
  transactionOpeningBalance: number
  transactionClosingBalance: any
}): Action<{
  transaction: any
  transactionOpeningBalance: any
  transactionClosingBalance: number
}> => {
  return {
    type: bankStatementActions.storeFetchedTransactions,
    payload
  }
}

export const saveComputedBankTransactionAction = (payload: any) => {
  return {
    type: bankStatementActions.saveComputedBankTransactionAction,
    payload
  }
}

export const resetBankReconciliationDataAction = () => {
  return {
    type: bankStatementActions.resetBankReconciliationData,
    payload: null
  }
}
