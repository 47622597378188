import { COUNTDOWN_STATUS, IAuthState, IUserData } from './auth.types'

export const userData: IUserData = {
  phoneNumber: null,
  otp: null,
  isLoggedIn: false,
  otpId: null,
  isNewUser: true,
  pin: null
}

export const AuthState: IAuthState = {
  isAuthDialogOpen: false,
  isAuthPasswordDialogOpen: false,
  isSuccessDialogOpen: false,
  isSelectAccountDialogOpen: false,
  countdownStatus: COUNTDOWN_STATUS.NOT_STARTED,
  userData,
  otpTimer: 15,
  authDevise: null
}
