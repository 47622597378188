import { IBill } from './bills.types'

export const getFormattedBills = (
  data: any,
  replaceExisting: boolean
): {
  bills: IBill[]
  hasMore: boolean
  replaceExisting: boolean
} => {
  if (!data) {
    return {
      bills: [],
      hasMore: false,
      replaceExisting
    }
  }
  const bills = data.map((_: any) => ({
    invoiceDate: Number(_.bill_date) * 1000,
    invoiceNumber: _.bill_number,
    invoiceId: _.bill_id,
    amountPayable: Number(_.bill_amount || 0) / 100,
    amountDue: Number(_.bill_due_amount || 0) / 100,
    billCreationSource: _.ref_source,
    billVersion: _.bill_version || 0,
    itemsPresent: _.items_present || false,
    status: _.status
  }))
  return {
    bills,
    hasMore: true,
    replaceExisting
  }
}
