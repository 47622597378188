import { takeLatest } from 'redux-saga/effects'
import { AgentDashboardActions } from './agent-dashboard.actions'
import {
  getAgentMetaDataEffect,
  getCheckListDataEffect,
  getDailySummaryDataEffect,
  getOnboardedMerchantsListEffect,
  postOnboardMerchantDataEffect,
  postRevisitFormDataEffect
} from './agent-dashboard.effects'

function* fetchAgentDashboardMetaData() {
  yield takeLatest(AgentDashboardActions.GET_AGENT_DASHBOARD_META, getAgentMetaDataEffect)
}

function* postOnboardMerchantData() {
  yield takeLatest(AgentDashboardActions.POST_ONBOARDING_DATA, postOnboardMerchantDataEffect)
}

function* getChecklistData() {
  yield takeLatest(AgentDashboardActions.GET_CHECKLIST_DATA, getCheckListDataEffect)
}

function* getOnboardedMerchantsList() {
  yield takeLatest(
    [
      AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST,
      AgentDashboardActions.GET_ONBOARDED_MERCHANTS_LIST_PAGES
    ],
    getOnboardedMerchantsListEffect
  )
}

function* getDailySummaryData() {
  yield takeLatest(AgentDashboardActions.GET_DAILY_SUMMARY_DATA, getDailySummaryDataEffect)
}

function* postRevisitFormData() {
  yield takeLatest(AgentDashboardActions.POST_REVISIT_FORM_DATA, postRevisitFormDataEffect)
}

export const AgentDashboardEffects = [
  fetchAgentDashboardMetaData(),
  postOnboardMerchantData(),
  getChecklistData(),
  getOnboardedMerchantsList(),
  getDailySummaryData(),
  postRevisitFormData()
]
