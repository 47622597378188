import dayjs from 'dayjs'
import { DOCUMENT_TYPE_VS_LABEL } from 'pages/CollectionList/components/DocumentFormatConfigure/DocumentFormatConfigure.helpers'
import { TAG_SELECTION_TYPE } from 'state/tags/tags.types'
import { keyedObjectWithId } from 'utils/dataNormalizer'
import {
  DOCUMENT_TYPE,
  ICollectionList,
  ICustomDate,
  IFilterCollectionListOptions,
  IPendingAction,
  ISelectedInvoiceToShift,
  ISingleCollectionList,
  IStaffMember,
  ITransactions
} from './collectionList.types'

export const sortByNameAccountDetailsSingleCollectionList = (
  customers: ISingleCollectionList[],
  isInvoice: boolean
): ISingleCollectionList[] => {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
  if (isInvoice) {
    return customers.sort((a, b) =>
      collator.compare(a.bills?.[0]?.bill?.bill_number || '', b.bills?.[0]?.bill?.bill_number || '')
    )
  }
  return customers.sort((a, b) => collator.compare(a.customer_name, b.customer_name))
}

export const getDateMonthYear = (timeStamp: any, isStart: boolean) => {
  const date = dayjs(new Date(Number(timeStamp * 1000)))
  const formattedDate = isStart ? date.startOf('day') : date.endOf('day')

  const day = formattedDate.date()
  const month = formattedDate.month() + 1
  const year = formattedDate.year()

  return { day, month, year }
}

export const timeStampToDateMonthYear = (tmStmp: string | number): ICustomDate | null => {
  if (!tmStmp) {
    return null
  }
  const timeStamp = Number(tmStmp) * 1000
  const dateObj = new Date(timeStamp)
  const day = dateObj.getDate()
  const month = dateObj.getMonth() + 1
  const year = dateObj.getFullYear()

  return {
    day,
    month,
    year
  }
}

export const getFormattedGlobalTemplates = (globalTemplates: any[]) => {
  return globalTemplates.map((template) => ({
    ...template,
    id: template.name,
    name: `Default ${
      DOCUMENT_TYPE_VS_LABEL[template.document_type as DOCUMENT_TYPE.EXCEL | DOCUMENT_TYPE.CSV]
    } Template`,
    isGlobal: true
  }))
}

export const getFormattedCurrentTemplates = (globalTemplates: any[], templates: any[]) => {
  return [...globalTemplates, ...templates]
    .filter((template) => template.is_default)
    .map((template) => ({
      id: template.id,
      document_type: template.document_type,
      name: template.name
    }))
}

export const getCurrentDate = (): ICustomDate => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  return {
    day,
    month: month + 1,
    year
  }
}

export const addOrRemoveId = (id: string, array: Array<string>) => {
  const index = array.indexOf(id)

  if (index === -1) {
    array.push(id)
  } else {
    array.splice(index, 1)
  }

  return array
}

export const addOrRemoveInvoice = (
  invoice: ISelectedInvoiceToShift,
  existingInvoices: ISelectedInvoiceToShift[]
) => {
  const billToRemove = existingInvoices.find(
    (existingInvoice) => existingInvoice.bill_id === invoice.bill_id
  )
  if (billToRemove) {
    return existingInvoices.filter(
      (existingInvoice) => existingInvoice.bill_id !== billToRemove.bill_id
    )
  }
  return [...existingInvoices, invoice]
}

export const getDefaultCollectionListName = (collectionList: ICollectionList[]) => {
  const collectionListNames = collectionList?.map((itm) => itm?.name?.toLocaleLowerCase())
  let listLength = collectionList.length
  let listName = `list ${listLength}`

  while (collectionListNames?.includes(listName)) {
    listName = `list ${listLength}`
    listLength += 1
  }
  return listName
}

export const getSelectedTagFilterDetails = (filterState: IFilterCollectionListOptions) => {
  const { byTagANDIds: selectedTagANDIds = [], byTagORIds: selectedTagORIds = [] } = filterState
  const isANDSelection = selectedTagANDIds.length > 0
  return {
    selectedTagIds: isANDSelection ? selectedTagANDIds : selectedTagORIds,
    selectedTagType: isANDSelection ? TAG_SELECTION_TYPE.AND : TAG_SELECTION_TYPE.OR
  }
}

export const BULK_UPLOAD_ACCOUNTS_STATUS_POLL_INTERVAL = 1000
export const BULK_UPLOAD_ACCOUNTS_STATUS_POLL_COUNT = 15

export const formatStaffMembers = (data: any): IStaffMember[] => {
  return data.map((staff: any) => ({
    id: staff.id,
    businessId: staff.business_id,
    name: staff.name,
    mobile: staff.mobile,
    isActive: staff.is_active,
    uid: staff.uid
  }))
}
/** @deprecated to remove this function and the code which uses it */
const formatBillDetails = (input: any) => {
  const updatedOutput: any = []
  input.forEach((inputItem: any) => {
    if (inputItem.bill_cancel_params) {
      updatedOutput.push(inputItem)
    } else {
      const clVsTxns: { [collection_list_id: string]: ITransactions[] } = {}
      inputItem.transactions.forEach((transaction: ITransactions) => {
        const { collection_list_id } = transaction
        clVsTxns[collection_list_id] = clVsTxns[collection_list_id] || []
        clVsTxns[collection_list_id].push(transaction)
      })
      Object.values(clVsTxns).forEach((item) => {
        updatedOutput.push({
          ...inputItem,
          transactions: item
        })
      })
    }
  })
  return updatedOutput
}

/** @deprecated to remove this function and the code which uses it */
export const formatPendingActions = (data: any): IPendingAction[] => {
  const formattedBillDetails = formatBillDetails(data.bill_details)
  const keyedAccountDetails = keyedObjectWithId(data.account_details)
  const formattedPendingActions = formattedBillDetails.map((billDetail: any) => {
    const account = keyedAccountDetails[billDetail?.bill?.customer_id]
    const { bill, bill_cancel_params, ...restBillDetail } = billDetail
    return {
      ...restBillDetail,
      bills: [{ bill, bill_cancel_params }],
      balance: `${
        Number(billDetail?.bill?.total_amount || 0) +
        Number(billDetail?.bill?.total_paid_amount || 0)
      }`,
      account_id: account?.id,
      customer_name: account?.name || '',
      customer_mobile: account?.mobile || '',
      collection_list_id:
        billDetail?.transactions?.[0]?.collection_list_id || bill_cancel_params?.collection_list_id,
      transactions: billDetail.transactions.filter((txn: any) =>
        txn.settlement_bill_ids.includes(billDetail.bill.bill_id)
      )
    }
  })
  return formattedPendingActions
}
