/* eslint-disable security/detect-non-literal-fs-filename */
import { call, put } from '@redux-saga/core/effects'
import { delay, race, select, take } from 'redux-saga/effects'
import { generatePath } from 'react-router'
import { AxiosError } from 'axios'
import { fetchEntityList } from 'state/dashboard/dashboard.actions'
import { ICustomer, IMerchantProfile, ISupplier } from 'state/dashboard/dashboard.types'
import { Action } from 'infra/types'
import { createCustomerTransactionWithBills, deleteBillWithTxnIdReq } from 'services/Api/BillingApi'
import {
  removeStaffLinkTransaction,
  getRoutesAndBeats,
  updateRoutesAndBeats,
  getAccountTransactionsApi,
  getDoubleEntry
} from 'services/Api/StafflinkApi'
import { MerchantApi } from 'services/Api/MerchantApi'
import { getTruncatedString } from 'utils/getTruncatedString'
import getWhatsappLink from 'utils/getWhatsappLink'
import { NotificationType } from 'pages/Notification/Notification.types'
import { addAutoFadeNotification } from 'pages/Notification/Notification.actions'
import { getErrorMessage } from 'services/Api/Errors'
import { Navigation } from 'services/navigation'
import { IDispatchCallback } from 'services/Api/types'
import { IAppState } from 'infra/AppState'
import { AuthStorageInstance } from 'services/Storage/AuthStorage'
import { newPaths } from '../../routes'
import { forwardTo } from '../common'
import { ENTITY_TYPE, IApiResponse, API_RESPONSE_TYPE } from './../../constants'
import {
  ICreateTransaction,
  ITransactionAction,
  ITransactionBill,
  ITransactionFilter,
  REFERENCE_SOURCE,
  TRANSACTION_REPORT_ACTION,
  TRANSACTION_SORT_ORDER,
  TRANSACTION_TYPE,
  IUpdateRouteBeatAction,
  ITransactionsState,
  ROLE
} from './transaction.types'
import {
  getCreateCustomerBillTransactionPayload,
  getCreateCustomerTransactionPayload,
  getCreateSupplierTransactionPayload
} from './helpers/addTransaction'
import {
  addTransactionSuccess,
  generateTransactionReportFailure,
  generateTransactionReportSuccess,
  fetchReportTransactionsFailure,
  fetchReportTransactionsSuccess,
  getTransactionSuccess,
  fetchLatestTransactions as fetchLatestTransactionsAction,
  TransactionsActions,
  addTransactionImageSuccess,
  deleteTransactionImageSuccess,
  setTransactionsLoading,
  addTransactionFailure,
  toggleEditTransactionAmount,
  setTransactionError,
  fetchAccountsRoutesAndBeatsSuccess,
  fetchAccountsRoutesAndBeats,
  setRoutesAndBeatsLoader,
  fetchLatestTransactionsSuccess,
  fetchLatestTransactionsFailure,
  saveDoubleEntryTransactionDetails
} from './transaction.actions'
import {
  getFormattedCustomerTransaction,
  getFormattedSupplierTransaction,
  getFormattedTransactionReport
} from './helpers/formatter'
import { showReport } from './helpers/report'

export function* addTransactionImageEffect(
  action: Action<{ image: Omit<ITransactionBill, 'id'>; entityType: ENTITY_TYPE; entityId: string }>
) {
  try {
    const { image, entityType, entityId } = action.payload
    const response: IApiResponse = yield call(MerchantApi.addTransactionImage, { image })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const { currentTransactions }: ITransactionsState = yield select(
        (state: IAppState) => state.Transactions
      )
      yield put(
        fetchLatestTransactionsAction({
          entityType,
          entityId
        })
      )
      yield put(
        addTransactionImageSuccess({
          transactionId: image.transaction_id,
          imageId: response.data.id
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}

export function* removeTransactionImageEffect(
  action: Action<{
    imageId: string
    transactionId: string
    entityType: ENTITY_TYPE
    entityId: string
  }>
) {
  try {
    const { imageId, transactionId, entityType, entityId } = action.payload
    const response: IApiResponse = yield call(MerchantApi.removeTransactionImage, imageId)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield delay(3000)
      const { currentTransactions }: ITransactionsState = yield select(
        (state: IAppState) => state.Transactions
      )
      yield put(
        fetchLatestTransactionsAction({
          entityType,
          entityId
        })
      )
      yield put(
        deleteTransactionImageSuccess({
          transactionId,
          imageId
        })
      )
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}

export function* getTxnDataEffect(action: Action<string>) {
  try {
    const transactionId = action.payload
    const response: IApiResponse = yield call(MerchantApi.getTransactionData, transactionId)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(getTransactionSuccess(getFormattedCustomerTransaction(response.data)))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function* showNotificationForCollectionIdDeletion() {
  try {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: 'Transaction cannot be deleted as it is an online payment transaction'
      })
    )
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* sendReminderToCustomer(
  action: Action<{ customer: ICustomer; merchant: IMerchantProfile }>
) {
  const balance =
    action.payload.customer.balance <= 0
      ? 'Credit:\nPayment reminder, please make payment as soon as possible.\n'
      : 'Advance:\nAccount update\n'
  const shopOwner = getTruncatedString(action.payload.merchant.name, 25)
  const amount = `${Math.abs(action.payload.customer.balance)} ${
    action.payload.customer.balance <= 0 ? 'Due' : 'Advance'
  }`
  try {
    yield window.open(
      getWhatsappLink(
        action.payload.customer.mobile,
        `Balance in ${balance}. Shop Owner: ${shopOwner}\n*Balance: ₹${amount}*\nVerified by OkCredit - Digital Udhaar Khata\n\nCheck details here:\n${action.payload.customer.accountUrl}`
      )
    )
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* sendReminderToSupplier(
  action: Action<{ supplier: ISupplier; merchant: IMerchantProfile }>
) {
  const balance = Number(action.payload.supplier.balance)
  const balanceText =
    balance <= 0
      ? 'Credit:\nPayment reminder, please make payment as soon as possible.\n'
      : 'Advance:\nAccount update\n'
  const shopOwner = getTruncatedString(action.payload.merchant.name, 25)
  const amount = `${balance} ${balance <= 0 ? 'Due' : 'Advance'}`
  try {
    yield window.open(
      getWhatsappLink(
        action.payload.supplier.mobile,
        `Balance in ${balanceText}. Shop Owner: ${shopOwner}\n*Balance: ₹${amount}*\nVerified by OkCredit - Digital Udhaar Khata
      }`
      )
    )
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* fetchReportTransactionsEffect(
  action: Action<{
    entityType: ENTITY_TYPE
    entityId: string
    filter?: ITransactionFilter
  }>
) {
  const { entityType, entityId, filter } = action.payload
  const isCustomer = entityType === ENTITY_TYPE.CUSTOMER
  try {
    const response: IApiResponse = yield call(getAccountTransactionsApi, {
      id: entityId,
      ...(filter && filter.startTime ? { start_time: filter.startTime } : {}),
      ...(filter && filter.endTime ? { end_time: filter.endTime } : {}),
      orderBy: TRANSACTION_SORT_ORDER.BY_BILLED_TIME,
      role: isCustomer ? ROLE.CUSTOMER : ROLE.SUPPLIER,
      is_asc_order: true
    })
    if (
      response.type === API_RESPONSE_TYPE.SUCCESS &&
      response.data &&
      Object.keys(response.data).length > 0
    ) {
      yield put(fetchAccountsRoutesAndBeats(entityId))
      if (response.data.transactions) {
        const reportData = getFormattedTransactionReport(response.data.transactions, entityType)
        yield put(fetchReportTransactionsSuccess({ ...action.payload, data: reportData }))
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
      yield put(fetchReportTransactionsFailure(entityType, entityId))
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
    yield put(fetchReportTransactionsFailure(entityType, entityId))
  }
}

export function* fetchAccountsRoutesAndBeatsEffect(action: Action<string>) {
  const accountId = action.payload
  try {
    const { Dashboard }: IAppState = yield select((state: IAppState) => state)
    const response: IApiResponse = yield call(getRoutesAndBeats, {
      userId: Dashboard.businessAccounts.individualUser?.id || '',
      accountId
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS && response.data) {
      if (response?.data?.accs?.length) {
        yield put(
          fetchAccountsRoutesAndBeatsSuccess({
            routes: response?.data?.accs?.[0]?.routes || [],
            beats: response?.data?.accs?.[0]?.beats || []
          })
        )
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message || 'Unable to fetch routes and beats'
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message || 'Unable to fetch routes and beats'
      })
    )
  }
  yield put(setRoutesAndBeatsLoader(false))
}
export function* updateAccountsRoutesAndBeatsEffect(action: Action<IUpdateRouteBeatAction>) {
  const { accountId, addBeats, addRoutes, deleteBeats, deleteRoutes } = action.payload
  try {
    const { Dashboard }: IAppState = yield select((state: IAppState) => state)
    const response: IApiResponse = yield call(updateRoutesAndBeats, {
      data: [
        {
          id: accountId,
          ...(addBeats ? { add_beats: addBeats } : {}),
          ...(deleteBeats ? { delete_beats: deleteBeats } : {}),
          ...(addRoutes ? { add_routes: addRoutes } : {}),
          ...(deleteRoutes ? { delete_routes: deleteRoutes } : {})
        }
      ],
      userId: Dashboard.businessAccounts.individualUser?.id || ''
    })
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield put(fetchAccountsRoutesAndBeats(accountId))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
  }
}

export function addTransaction(
  action: Action<{
    entityType: ENTITY_TYPE
    entityId: string
    type: TRANSACTION_TYPE
    amount?: number
    source?: string
  }>
) {
  const { entityType, entityId, type, amount } = action.payload
  Navigation().to(
    generatePath(newPaths.addTransaction, {
      entity: entityType,
      id: entityId,
      type
    }) + (amount ? `?amount=${amount}` : '')
  )
}

export function* createTransaction(action: Action<ICreateTransaction & IDispatchCallback>) {
  const { entityType, entityId, redirectTo, billNumber } = action.payload
  const isCustomer = entityType === ENTITY_TYPE.CUSTOMER
  const { Experiment } = yield select((app: IAppState) => app)
  try {
    const response: IApiResponse = isCustomer
      ? yield call(
          billNumber ? createCustomerTransactionWithBills : MerchantApi.createCustomerTransaction,
          billNumber
            ? getCreateCustomerBillTransactionPayload(action.payload)
            : getCreateCustomerTransactionPayload(action.payload)
        )
      : yield call(
          MerchantApi.createSupplierTransaction,
          entityId,
          getCreateSupplierTransactionPayload(action.payload)
        )
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      if (redirectTo) {
        yield call(forwardTo, redirectTo)
      } else {
        Navigation().replace(
          generatePath(Experiment.ab.staff_link ? newPaths.approve : newPaths.ledger)
        )
      }
      yield put(addTransactionSuccess(response.data))
      yield put(fetchEntityList(entityType))
      const { currentTransactions }: ITransactionsState = yield select(
        (state: IAppState) => state.Transactions
      )
      yield put(
        fetchLatestTransactionsAction({
          entityType,
          entityId
        })
      )
      yield delay(3000)
      yield put(fetchEntityList(entityType))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: 'Something went wrong. Please try again!'
        })
      )
      const errorMsg = response.data?.response?.data?.error || ''
      if (errorMsg.includes('exists')) {
        yield put(setTransactionError('This Invoice number already exists!'))
      }
      yield put(addTransactionFailure())
    }
  } catch (e: any) {
    yield put(addTransactionFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* deleteTransaction(action: Action<ITransactionAction>) {
  const { entityType, entityId, transactionId, redirectTo } = action.payload
  const isCustomer = entityType === ENTITY_TYPE.CUSTOMER

  try {
    yield put(setTransactionsLoading(true))

    const { Transactions }: IAppState = yield select((state: IAppState) => state)
    let response: IApiResponse | null = null

    if (
      isCustomer &&
      Transactions.currentTransactions.byIds?.[transactionId]?.referenceSource ===
        REFERENCE_SOURCE.BILLING
    ) {
      response = yield call(deleteBillWithTxnIdReq, transactionId)
    } else if (
      isCustomer &&
      Transactions.currentTransactions.byIds?.[transactionId]?.referenceSource ===
        REFERENCE_SOURCE.STAFF_LINK
    ) {
      response = yield call(removeStaffLinkTransaction, {
        staffLinkTxnId: Transactions.currentTransactions.byIds?.[transactionId]?.referenceId || '',
        merchantId: Transactions.currentTransactions.byIds?.[transactionId]?.accountId || ''
      })
    } else {
      response = yield call(
        isCustomer
          ? MerchantApi.deleteTransactionForCustomer
          : MerchantApi.deleteTransactionForSupplier,
        transactionId
      )
    }

    if (response?.type === API_RESPONSE_TYPE.SUCCESS) {
      if (redirectTo) {
        yield delay(3000)
        yield call(forwardTo, redirectTo)
      }
      if (response.data.success) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.SUCCESS,
            bodyText: `${
              isCustomer ? 'Customer' : 'Supplier'
            } transaction was deleted successfully!`
          })
        )
      }
      if (response.data.error) {
        yield put(
          addAutoFadeNotification({
            type: NotificationType.SUCCESS,
            bodyText: 'Unable to delete transaction'
          })
        )
      }
      if (entityType && entityId) {
        const { currentTransactions }: ITransactionsState = yield select(
          (state: IAppState) => state.Transactions
        )
        yield put(
          fetchLatestTransactionsAction({
            entityType,
            entityId
          })
        )
      }
      yield delay(3000)
      yield put(fetchEntityList(ENTITY_TYPE.SUPPLIER))
    } else {
      yield put(setTransactionsLoading(false))
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText:
            response?.data?.response?.data?.error ||
            response?.data?.message ||
            'Unable to delete transaction'
        })
      )
    }
  } catch (e: any) {
    yield put(setTransactionsLoading(false))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* generateTransactionReport(
  action: Action<{
    entityType: ENTITY_TYPE
    entityId: string
    filter?: ITransactionFilter
    sourceAction: TRANSACTION_REPORT_ACTION
    language: string
  }>
) {
  try {
    const { entityType, entityId, filter, sourceAction, language } = action.payload
    const type = entityType === ENTITY_TYPE.CUSTOMER ? 0 : 1
    const state: IAppState = yield select((state: IAppState) => state)
    const payload = {
      merchant_id: state.Dashboard.merchantProfile.data?.id as string,
      account_id: entityId,
      ...(filter && filter.startTime ? { start_time: filter.startTime } : {}),
      ...(filter && filter.endTime ? { end_time: filter.endTime } : {}),
      lang: language,
      type
    }
    const reportUrl = state.Transactions.currentTransactionReport.reportUrl

    if (reportUrl) {
      yield put(generateTransactionReportSuccess(reportUrl))
      showReport(reportUrl, sourceAction)
      return
    }
    const { response, reportAborted }: { response: IApiResponse; reportAborted: any } = yield race({
      response: call(MerchantApi.generateTransactionReport, payload),
      reportAborted: take([TransactionsActions.FETCH_REPORT_TRANSACTIONS])
    })

    if (reportAborted) {
      yield put(generateTransactionReportFailure())
      return
    }

    if (response.type === API_RESPONSE_TYPE.SUCCESS && response.data?.report?.report_url) {
      const reportUrl = response.data.report.report_url
      yield put(generateTransactionReportSuccess(reportUrl))
      showReport(reportUrl, sourceAction)
    } else {
      yield put(generateTransactionReportFailure())
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(generateTransactionReportFailure())
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: e.message
      })
    )
  }
}

export function* editTransactionEffect(
  action: Action<{
    payload: any
    entityType: ENTITY_TYPE
    entityId: string
  }>
) {
  try {
    const { entityType, entityId, payload } = action.payload
    yield put(setTransactionsLoading(true))
    const response: IApiResponse = yield call(MerchantApi.editTransaction, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      yield delay(3000)
      const { currentTransactions }: ITransactionsState = yield select(
        (state: IAppState) => state.Transactions
      )
      yield put(
        fetchLatestTransactionsAction({
          entityType,
          entityId
        })
      )
      yield put(toggleEditTransactionAmount(false))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
    }
    yield put(setTransactionsLoading(false))
  } catch (e: any) {
    yield put(setTransactionsLoading(false))
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}

export function* fetchLatestTransactions(
  action: Action<{
    entityType: ENTITY_TYPE
    entityId: string
    filter?: ITransactionFilter
    source?: string
    limit?: number
    endTime?: number
  }>
) {
  const { entityType, entityId, filter, limit, endTime } = action.payload
  const isCustomer = entityType === ENTITY_TYPE.CUSTOMER
  const formattedEndTime = endTime || (filter && filter.endTime)
  try {
    const response: IApiResponse = yield call(getAccountTransactionsApi, {
      id: entityId,
      ...(filter && filter.startTime ? { start_time: filter.startTime } : {}),
      ...(formattedEndTime ? { end_time: formattedEndTime } : {}),
      orderBy: TRANSACTION_SORT_ORDER.BY_CREATION_TIME,
      role: isCustomer ? ROLE.CUSTOMER : ROLE.SUPPLIER
    })
    if (
      response.type === API_RESPONSE_TYPE.SUCCESS &&
      response.data &&
      Object.keys(response.data).length > 0
    ) {
      yield put(fetchAccountsRoutesAndBeats(entityId))
      if (response.data.transactions) {
        let transactions
        let sum = 0
        let supplierSum = 0
        if (isCustomer) {
          transactions = response.data.transactions
            .reverse()
            .sort((a: any, b: any) => Number(a.bill_date) - Number(b.bill_date))
            .map((transaction: any) => {
              const formattedData = getFormattedCustomerTransaction(transaction, sum)
              sum = formattedData.outStandingAmount
              return formattedData
            })
        } else {
          transactions = response.data.transactions
            .reverse()
            .sort((a: any, b: any) => Number(a.bill_date) - Number(b.bill_date))
            .map((supplierTransaction: any) => {
              const formattedSupplierData = getFormattedSupplierTransaction(
                supplierTransaction,
                supplierSum
              )
              supplierSum = formattedSupplierData.outStandingAmount
              return formattedSupplierData
            })
        }
        yield put(fetchLatestTransactionsSuccess({ ...action.payload, data: transactions }))
      }
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.message
        })
      )
      yield put(fetchLatestTransactionsFailure(entityType, entityId))
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e).message
      })
    )
    yield put(fetchLatestTransactionsFailure(entityType, entityId))
  }
}

export function* fetchDoubleTransactions(
  action: Action<{
    transactionId: string
    accountId: string
  }>
) {
  try {
    const { transactionId, accountId } = action.payload
    const currentBusinessId: string = yield AuthStorageInstance.getCurrentBusinessId()
    const payload = {
      business_id: currentBusinessId,
      entry_id: transactionId,
      account_id: accountId
    }
    const response: IApiResponse = yield call(getDoubleEntry, payload)
    if (response.type === API_RESPONSE_TYPE.SUCCESS) {
      const { account_name, reference, deleted } = response.data
      yield put(saveDoubleEntryTransactionDetails({ reference, account_name, deleted }))
    } else {
      yield put(
        addAutoFadeNotification({
          type: NotificationType.ERROR,
          bodyText: response.data.response.data.message
        })
      )
    }
  } catch (e: any) {
    yield put(
      addAutoFadeNotification({
        type: NotificationType.ERROR,
        bodyText: getErrorMessage(e as AxiosError).message
      })
    )
  }
}
