import dayjs from 'dayjs'
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const getAutoDebutBannerDate = (dateStamp: string | number) => {
  try {
    const date = new Date(Number(dateStamp) * 1000)
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
  } catch (error) {
    return '01 Jan 2000'
  }
}

const formatAMPM = (date: Date) => {
  let hours = date.getHours()
  let minutes: string | number = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ampm
  return strTime
}

export const getTransactionsDateTime = (dateStamp: string | number) => {
  try {
    const formatedDate = getAutoDebutBannerDate(dateStamp)
    const date = new Date(Number(dateStamp) * 1000)
    return `${formatAMPM(date)}, ${formatedDate}`
  } catch (error) {
    return '00:00, 1 Jan 2000'
  }
}

export const showAutoDebitBanner = (dateStamp: string | number) => {
  try {
    const today = new Date()
    const mandateDate = Number(dateStamp) * 1000
    return Number(mandateDate) >= Number(today)
  } catch (error) {
    return false
  }
}

export const getInvoiceAge = (dateStamp: string | number) => {
  const invoiceDate = dayjs.unix(Number(dateStamp))
  const currentDate = dayjs()
  const difference = currentDate.diff(invoiceDate, 'day')
  return difference ? `${difference} days` : 'Today'
}

export const isInvoiceOld = (dateStamp: string | number) => {
  const invoiceDate = dayjs.unix(Number(dateStamp))
  const currentDate = dayjs()
  const difference = currentDate.diff(invoiceDate, 'day')
  return Math.abs(difference) > 7
}

export const isToday = (dateStamp: string | number) => {
  const invoiceDate = dayjs.unix(Number(dateStamp))
  const currentDate = dayjs()
  const difference = currentDate.diff(invoiceDate, 'day')
  return difference ? false : true
}
