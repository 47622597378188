import React from 'react'
import ThreeDotsComponent from 'pages/CommonSummary/components/ThreeDotsComponent'
import { IconFileCog } from 'assets/icons'
import useInfoList from './useInfoList'
import { UploadSupplySheetStyles } from './styles'
import TemplateSelector from './components/TemplateSelector'

const UploadSupplySheet = () => {
  const classes = UploadSupplySheetStyles()
  const { listItems, dialogRef } = useInfoList()

  return (
    <div className={classes.container}>
      <TemplateSelector />
      <ThreeDotsComponent
        listItem={listItems}
        ref={dialogRef}
        customComponent={({ onClick }) => (
          <div className={classes.infoContainer} onClick={onClick}>
            <img src={IconFileCog} alt="file-cog" height={22} width={22} />
          </div>
        )}
      />
    </div>
  )
}

export default UploadSupplySheet
