import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import {
  Button,
  CaretLeftIcon,
  Dialog,
  IconButton,
  TextField,
  TickIcon,
  TrashIcon
} from '@okcredit/web-lib'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Drawer } from 'components/Drawer'
import { IAppState } from 'infra/AppState'
import { THEME } from 'components/theme'
import { IDashboardState } from 'state/dashboard/dashboard.types'
import {
  addBusinessManagerAction,
  deleteBusinessManagerAction
} from 'state/dashboard/dashboard.actions'
import { validatePhoneNumber } from 'utils/validators/phoneNumberValidator'
import DrawerInnerContentSkeleton from 'pages/NewDashboard/components/CommonDrawers/DrawerSkeleton'
import { ISwitchAccountProps } from './ManagerDetails.types'
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog'
import { SwitchAccountStyles } from './ManagerDetails.styles'

const styles = createUseStyles(SwitchAccountStyles)

const ManagerDetails: React.FC<ISwitchAccountProps> = ({ onClose }) => {
  const classes = styles()
  const dispatch = useDispatch()
  const { userId } = useParams<{ userId: string }>()
  const dashboardState: IDashboardState = useSelector((state: IAppState) => state.Dashboard)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const { businessManagers, merchantProfile } = dashboardState
  const manager = businessManagers.find((manager) => manager.user_id === userId)
  const isNew = userId === 'add-manager'

  const [managerContact, setManagerContact] = useState(manager?.mobile || '')

  const handleSave = () => {
    dispatch(addBusinessManagerAction({ mobile: managerContact }))
  }

  const openDeleteDialog = () => {
    setShowDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false)
  }

  const handleDeleteConfirm = () => {
    if (!manager) {
      return
    }
    dispatch(deleteBusinessManagerAction({ userId: manager?.user_id, mobile: manager?.mobile }))
    closeDeleteDialog()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === 'E') {
      e.preventDefault()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 10) {
      setManagerContact(e.target.value)
    }
  }

  const getIsDisabled = () => {
    return !validatePhoneNumber(managerContact)
  }

  return (
    <Drawer
      showCloseIcon={false}
      className={cx(classes.main, classes.mainC)}
      onRequestClose={onClose}
    >
      <DrawerInnerContentSkeleton
        header={
          <div className={classes.header}>
            <IconButton onClick={onClose}>
              <CaretLeftIcon size={24} />
            </IconButton>
            <div>
              <span>Manager Details</span>
              {!isNew && (
                <IconButton onClick={openDeleteDialog}>
                  <TrashIcon size={24} fill={THEME.brandRed} />
                </IconButton>
              )}
            </div>
          </div>
        }
        footer={
          isNew ? (
            <div className={classes.footer}>
              <Button
                fullWidth
                onClick={handleSave}
                disabled={getIsDisabled()}
                startIcon={<TickIcon fill={THEME.brandWhite} />}
              >
                Save Details
              </Button>
            </div>
          ) : null
        }
        mainContainer={
          <div className={classes.content}>
            <TextField
              label="Manager Contact"
              value={managerContact}
              className={classes.labelField}
              type="number"
              inputProps={{ autoFocus: true }}
              classList={{ inputClass: classes.input }}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={!isNew}
            />
          </div>
        }
      />
      {showDeleteDialog && (
        <Dialog open={true} onBackdropClick={closeDeleteDialog} anchor="bottom">
          <ConfirmDeleteDialog
            managerMobile={manager?.mobile || ''}
            businessName={merchantProfile.data?.name || ''}
            handleClose={closeDeleteDialog}
            handleConfirm={handleDeleteConfirm}
          />
        </Dialog>
      )}
    </Drawer>
  )
}

export default ManagerDetails
