import { takeLatest } from '@redux-saga/core/effects'
import {
  getBeatsEffect,
  createBeatRouteEffect,
  getInvoiceBeatsEffect,
  linkDelinkInvoiceBeatsEffect
} from './beats.effects'
import { BeatsActions } from './beats.actions'

function* getBeatsSaga() {
  yield takeLatest(BeatsActions.FETCH_BEATS, getBeatsEffect)
}

function* createBeatRouteSaga() {
  yield takeLatest(BeatsActions.CREATE_BEAT_ROUTE, createBeatRouteEffect)
}

function* getInvoiceBeatsSaga() {
  yield takeLatest(BeatsActions.FETCH_INVOICE_BEATS, getInvoiceBeatsEffect)
}

function* linkDelinkInvoiceBeatsSaga() {
  yield takeLatest(BeatsActions.LINK_DELINK_INVOICE_BEATS, linkDelinkInvoiceBeatsEffect)
}

export const BeatsEffects = [
  getBeatsSaga(),
  createBeatRouteSaga(),
  getInvoiceBeatsSaga(),
  linkDelinkInvoiceBeatsSaga()
]
